import { type Map, type Set } from 'immutable'
import * as React from 'react'

import { ButtonNavLink } from 'components/common/button'

import { generateUrl } from 'com.batch.common/router'

import Attribute from './attribute'

import { type AppRecord, type AttributeRecord } from 'com.batch.redux/_records'

type AttributePickerProps = {
  app: AppRecord
  attributes: Map<string, AttributeRecord>
  canQuery: boolean
  canQueryAttribute: boolean
  canRetarget: boolean
  canRetargetInApp: boolean
  canUseAudience: boolean
  canUseGeoloc: boolean
  conditionsCount: number
  selectedSet: Set<AttributeRecord>
  toggleAttr: (arg1: AttributeRecord) => void
}
const MAX_CONDITIONS = 32
export const AttributePicker = ({
  app,
  attributes,
  canQuery,
  canQueryAttribute,
  canRetarget,
  canRetargetInApp,
  canUseAudience,
  canUseGeoloc,
  conditionsCount,
  selectedSet,
  toggleAttr,
}: AttributePickerProps): React.ReactElement => {
  const limitReached = conditionsCount + selectedSet.size >= MAX_CONDITIONS

  return (
    <div className="attributePicker">
      <div className="attributePicker__list">
        {!canQuery && (
          <div className="overlay-notpro">
            <h5 className="nodata__title">Free users can't use advanced targeting.</h5>
            <p className="nodata__msg" style={{ margin: '15px 0' }}>
              You need to have at least a Developer plan
              <br />
              to send campaign with advanced targeting
            </p>
            <ButtonNavLink
              kind="primary"
              intent="action"
              to={generateUrl('company_billing', { companyId: app.companyId })}
            >
              Upgrade plan
            </ButtonNavLink>
          </div>
        )}
        {attributes.size === 0 && (
          <div>
            <p className="text-muted text-center" style={{ paddingTop: '50px' }}>
              No additional targeting found
            </p>
            <p className="text-center">
              Please note that new data must first be enabled{' '}
              <a
                href={generateUrl('app_settings', {
                  companyId: app.companyId,
                  appId: app.id,
                  activeTab: 'custom-data',
                })}
              >
                via your app's settings
              </a>
            </p>
          </div>
        )}
        {attributes.valueSeq().map(attr => {
          return (
            <Attribute
              // eslint-disable-next-line react/jsx-no-bind
              handler={() => toggleAttr(attr)}
              attribute={attr}
              disabled={limitReached}
              locked={
                /* 
              @todo c'est faux d'un point de vue logique : event dépend de query-events, 
              mais dans les faits on a toujours query-attributes et query-events. le plus simple serait de faire 
              le ménage dans les features avec le product et les sales
              */
                (['attribute', 'tag', 'event'].includes(attr.category) && !canQueryAttribute) ||
                (['user_attribute', 'user_tag'].includes(attr.category) &&
                  !app.features.has('ingestion')) ||
                (attr.id === 'b.position' && !canUseGeoloc) ||
                (attr.id === 'bt.custom_audiences' && !canUseAudience) ||
                (attr.id === 'be.displayed' && !canRetargetInApp) ||
                (attr.category === 'batch_event' && !canRetarget)
              }
              key={attr.id}
              active={selectedSet.has(attr)}
            />
          )
        })}
      </div>
    </div>
  )
}
