import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { Switch } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Input, InputWrapper } from 'components/form'
import { colors } from 'components/styled/tokens'

import { validateJSON } from 'com.batch.common/utils'

import { getPushSettingsSelector } from '../store/push.selector'
import { updatePushSettings } from '../usecases/update-push-settings'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'

import { type PushSettingsRecord } from 'com.batch/message/models/message.records'

type Props = {
  stepMessageNodeId: string | null | undefined
}

const usePayloadField = (
  settings: PushSettingsRecord,
  platform: 'common' | 'ios' | 'android'
): {
  payload?: string
  isValid: boolean
} => {
  const payload = React.useMemo(() => {
    switch (platform) {
      case 'ios':
        return settings.get('iosOverride').get('customPayload')
      case 'android':
        return settings.get('androidOverride').get('customPayload')
      default:
        return settings.customPayload
    }
  }, [settings, platform])
  const isValid = React.useMemo(() => (payload ? validateJSON(payload) : true), [payload])

  return { payload, isValid }
}

const PayloadInputHint = ({ showPushWarning }: { showPushWarning: boolean }) => (
  <div style={{ textAlign: 'left' }}>
    <p>
      Valid JSON that your app will receive with the push notification.
      <br />
      The root of the JSON must be an Object, and can't have the reserved key com.batch. You can use{' '}
      {'{BATCH:TITLE}, {BATCH:BODY}, {BATCH:DEEPLINK} & {BATCH:CAMPAIGNID}'} variables, they will be
      replaced.
    </p>
    {showPushWarning && <p style={{ marginTop: 8 }}>This setting does not apply to Web Push.</p>}
  </div>
)

const PayloadInput = ({
  label,
  icon,
  payload,
  isValid,
  onChange,
  margin = true,
  hint,
}: {
  label: string
  icon?: 'android' | 'ios'
  payload?: string
  isValid: boolean
  onChange: (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  margin?: boolean
  hint: React.ReactNode
}) => {
  return (
    <InputWrapper
      style={{ marginTop: margin ? 20 : 0 }}
      label={
        <span>
          {icon && <Icon icon={icon} style={{ marginRight: 8 }} />}
          {label}
        </span>
      }
      hint={hint}
      hintMinSize={400}
    >
      <Input
        rows={icon ? 4 : 11}
        placeholder={'{\n  "valid": "JSON"\n}'}
        invalid={!isValid}
        value={payload}
        onChange={onChange}
        inputStyle={{ resize: 'none' }}
        aria-label={label}
      />
    </InputWrapper>
  )
}

export const PushPayloadField = ({ stepMessageNodeId }: Props): React.ReactElement => {
  const getConfig = useSelector(getMessageConfigSelector)
  const config = React.useMemo(() => {
    return getConfig({ stepMessageNodeId })
  }, [getConfig, stepMessageNodeId])
  const getSettings = useSelector(getPushSettingsSelector)
  const dispatch = useDispatch()

  const settings = React.useMemo(
    () => getSettings({ stepMessageNodeId }),
    [getSettings, stepMessageNodeId]
  )

  const [splitPerPlatform, setSplitPerPlatform] = React.useState(
    config.platforms.some(p => {
      switch (p) {
        case 'ios':
          return !!settings.getIn(['iosOverride', 'customPayload'])
        case 'android':
          return !!settings.getIn(['androidOverride', 'customPayload'])
        default:
          return false
      }
    })
  )

  const { payload: customPayload, isValid: isPayloadValid } = usePayloadField(settings, 'common')
  const { payload: iosCustomPayload, isValid: isIosPayloadValid } = usePayloadField(settings, 'ios')
  const { payload: androidCustomPayload, isValid: isAndroidPayloadValid } = usePayloadField(
    settings,
    'android'
  )

  const onUpdatePushSettings = React.useCallback(
    (settings: PushSettingsRecord) => {
      dispatch(updatePushSettings({ stepMessageNodeId, settings }))
    },
    [dispatch, stepMessageNodeId]
  )

  const updateSettings = React.useCallback(
    (field: 'ios' | 'android' | 'common') => (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
      switch (field) {
        case 'ios':
          onUpdatePushSettings(
            settings.setIn(['iosOverride', 'customPayload'], e.currentTarget.value)
          )
          break
        case 'android':
          onUpdatePushSettings(
            settings.setIn(['androidOverride', 'customPayload'], e.currentTarget.value)
          )
          break
        default:
          onUpdatePushSettings(settings.set('customPayload', e.currentTarget.value))
      }
    },
    [onUpdatePushSettings, settings]
  )

  const cleanPayloads = React.useCallback(
    (splitted: boolean) => {
      onUpdatePushSettings(
        splitted
          ? settings
              .setIn(['iosOverride', 'customPayload'], '')
              .setIn(['androidOverride', 'customPayload'], '')
          : settings.set('customPayload', '')
      )
    },
    [onUpdatePushSettings, settings]
  )

  const handleOnSplitPerPlatformChange = React.useCallback(() => {
    setSplitPerPlatform(s => !s)
    cleanPayloads(splitPerPlatform)
  }, [splitPerPlatform, cleanPayloads])

  // Si on deselect jusqu'à n'avoir qu'1 plateforme => disable split + clean
  React.useEffect(() => {
    if (config.platforms.filter(p => p !== 'webpush').size === 1 && splitPerPlatform) {
      setSplitPerPlatform(false)
      cleanPayloads(true)
    }
  }, [config.platforms, splitPerPlatform, cleanPayloads])

  return (
    <div style={{ position: 'relative' }}>
      {config.platforms.includes('ios') && config.platforms.includes('android') && (
        <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 5 }}>
          <Switch isActive={splitPerPlatform} onChange={handleOnSplitPerPlatformChange}>
            <span style={{ fontWeight: 400, color: colors.textLight }}>Split by platform</span>
          </Switch>
        </div>
      )}
      {!splitPerPlatform ? (
        <PayloadInput
          label="Payload"
          payload={customPayload}
          isValid={isPayloadValid}
          onChange={updateSettings('common')}
          margin={false}
          hint={<PayloadInputHint showPushWarning={config.platforms.has('webpush')} />}
        />
      ) : (
        <React.Fragment>
          <PayloadInput
            label="iOS Payload"
            icon="ios"
            payload={iosCustomPayload}
            isValid={isIosPayloadValid}
            onChange={updateSettings('ios')}
            margin={false}
            hint={<PayloadInputHint showPushWarning={false} />}
          />
          <PayloadInput
            label="Android Payload"
            icon="android"
            payload={androidCustomPayload}
            isValid={isAndroidPayloadValid}
            onChange={updateSettings('android')}
            hint={<PayloadInputHint showPushWarning={false} />}
          />
        </React.Fragment>
      )}
    </div>
  )
}
