import setTimeout from 'core-js/features/set-timeout'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useToggle } from 'components/_hooks'
import { useIsCurrentUserAllowedTo } from 'components/_hooks/use-allowed'
import { Button } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Select } from 'components/form'
import { HorizontalDivider, Separator } from 'components/styled/utils'

import {
  pickableLanguagesSelector,
  pickedLanguagesSelector,
  previewLanguageSelector,
} from 'com.batch/message/store/message.selector'
import { orchestrationMetaSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { type LanguageRecord } from 'com.batch.redux/_records'

import { saveDragDropHtml } from 'com.batch/email/usecases/update-content'
import {
  addLanguage,
  removeLanguage,
  setActiveLanguage,
} from 'com.batch/message/usecases/multilanguage'
import {
  DeleteLangButton,
  TopToolbarContainer,
} from 'com.batch/message-builder/ui/components/lang-picker/lang-picker.styles'

const langToString = (lang?: LanguageRecord | null) => lang?.label ?? ''

type LangPickerProps = {
  messageId: string
  channel: ChannelUntilCleanup
  templateId?: string
}
export const LangPicker = ({
  messageId,
  channel,
  templateId,
}: LangPickerProps): React.ReactElement => {
  const dispatch = useDispatch()
  const [showSelect, setShowSelect] = useState(false)
  const savingTemplateState = useToggle()
  const languages = useSelector(pickableLanguagesSelector({ messageId, channel }))
  const pickedLanguages = useSelector(pickedLanguagesSelector({ messageId, channel }))
  const activeLanguage = useSelector(previewLanguageSelector)

  const { state } = useSelector(orchestrationMetaSelector)
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])

  const onBlurSelect = React.useCallback(() => {
    setShowSelect(false)
  }, [])

  const handleShowSelectOnClick = React.useCallback(() => {
    setShowSelect(true)
  }, [])

  const handleSelectAddLang = React.useCallback(
    (lang?: LanguageRecord | null) => {
      // C'est un cas jamais possible le select ne peut pas être vide mais le type dit que si
      if (!lang) {
        return
      }

      setShowSelect(false)
      // Si un templateId est donné alors il faut sauvegarder le template email en cours d'édition avant de changer de langue
      if (templateId) {
        savingTemplateState.open()
        dispatch(saveDragDropHtml({ lang: activeLanguage, templateId, messageId }))
          .then(() => {
            dispatch(addLanguage({ messageId, channel, lang: lang.value }))
            dispatch(setActiveLanguage(lang.value))
            setTimeout(savingTemplateState.close, 2000)
          })
          .catch(err => {
            console.log(err)
            alert(
              'Template could not be saved. Saving your orchestration now might result in data loss. '
            )
            savingTemplateState.close()
          })
      } else {
        dispatch(addLanguage({ messageId, channel, lang: lang.value }))
        dispatch(setActiveLanguage(lang.value))
      }
    },
    [templateId, savingTemplateState, dispatch, activeLanguage, messageId, channel]
  )

  const handleClickSetActiveLang = React.useCallback(
    (lang: LanguageRecord) => () => {
      // Même chose que pour handleSelectAddLang
      if (templateId) {
        savingTemplateState.open()
        dispatch(saveDragDropHtml({ lang: activeLanguage, templateId, messageId }))
          .then(() => {
            dispatch(setActiveLanguage(lang.value))
            setTimeout(savingTemplateState.close, 2000)
          })
          .catch(err => {
            console.log(err)
            alert(
              'Template could not be saved. Saving your orchestration now might result in data loss. '
            )
            savingTemplateState.close()
          })
      } else {
        dispatch(setActiveLanguage(lang.value))
      }
    },
    [activeLanguage, dispatch, messageId, savingTemplateState, templateId]
  )

  const handleClickDeleteLang = React.useCallback(
    (lang: LanguageRecord) => () => {
      confirm({
        title: `Delete ${lang?.label ?? ''} language?`,
        message: <p>{lang?.label} template is about to be deleted.</p>,
        sensitive: true,
      }).then(
        () => {
          dispatch(removeLanguage({ messageId, channel, lang: lang.value }))

          if (lang.value === activeLanguage) {
            dispatch(setActiveLanguage('default'))
          }
        },
        () => {}
      )
    },
    [dispatch, messageId, channel, activeLanguage]
  )

  const canEditLanguages = React.useMemo(
    () => state !== 'COMPLETED' && userHasWritePermission,
    [state, userHasWritePermission]
  )

  return (
    <React.Fragment>
      <TopToolbarContainer data-testid="lang-picker">
        <Icon icon="translation" style={{ marginTop: 9, marginRight: 8 }} />
        {pickedLanguages.map((lang: LanguageRecord) => (
          <Button
            key={lang.value}
            disabled={savingTemplateState.value}
            addOn={lang.value !== 'default' && canEditLanguages ? 'suffix' : undefined}
            isActive={activeLanguage === lang.value}
            onClick={handleClickSetActiveLang(lang)}
          >
            {lang.label}
            {lang.value !== 'default' && canEditLanguages && (
              <DeleteLangButton onClick={handleClickDeleteLang(lang)} data-track="multilang-remove">
                <Icon icon="close" />
              </DeleteLangButton>
            )}
          </Button>
        ))}

        {!showSelect && canEditLanguages && (
          <React.Fragment>
            <Separator />
            <Tooltip tooltip="Add a language" placement="bottom">
              <Button
                onClick={handleShowSelectOnClick}
                style={{ height: 36, width: 38 }}
                data-track="multilang-add"
                disabled={savingTemplateState.value}
                data-testid="add-language-button"
              >
                <Icon icon="add" />
              </Button>
            </Tooltip>
          </React.Fragment>
        )}
        {showSelect && (
          <React.Fragment>
            <Separator />
            <Select
              autoFocus
              onBlur={onBlurSelect}
              onChange={handleSelectAddLang}
              options={languages}
              optionToString={langToString}
              placeholder="Select a language"
              isSearchable={true}
              menuOffset={40}
              value={null}
              style={{ width: 165, marginBottom: -1, marginTop: -1 }}
            />
          </React.Fragment>
        )}
      </TopToolbarContainer>
      <HorizontalDivider />
    </React.Fragment>
  )
}
