import styled, { css } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'
import { grayscale } from 'components/styled/tokens/schemes'

import { BaseThumb, InactiveEditorContainer } from 'com.batch/message-builder/ui/components/common'

export const AndroidContainer = styled.div`
  width: 386px;
  height: auto;
  ${p =>
    p.theme.isFocused
      ? css`
          background: ${grayscale['10']};
          transition: background-color 0.2s ease;
        `
      : css`
          background: ${grayscale['05']};
        `}

  border-radius: 14px;
  margin-top: ${p => (p.theme.abTestingEnabled ? 225 : 188)}px;
  margin-bottom: ${p => (p.theme.isExpanded ? 80 : 0)}px;
  padding: 10px;
  align-items: center;
  display: grid;

  ${IconContainer} {
    color: #a1a1a1;
    text-align: right;
  }
  ${p =>
    !p.theme.isExpanded
      ? css`
          grid-template-columns: 16px auto 1fr 32px;
          grid-template-rows: auto;
          grid-column-gap: 6px;
          grid-template-areas:
            'android app date .'
            'message message message ${p.theme.hasIcon ||
            p.theme.hasMedia ||
            p.theme.draggingState !== 'NONE'
              ? 'thumb'
              : 'message'}';
        `
      : css`
          grid-template-columns: 16px auto 1fr 32px;
          grid-template-rows: auto;
          grid-column-gap: 6px;
          grid-template-areas:
            'android app date .'
            'message message message message'
            'thumb thumb thumb thumb';
        `}
`

export const AndroidIcon = styled.div`
  width: 16px;
  height: 16px;
  background: tomato;
  grid-area: android;
  background: ${p => (!p.theme?.app?.icon ? '#ACB1B9' : 'unset')};

  ${props =>
    props.theme?.app?.icon &&
    css`
      background-size: cover;
      background-image: url(${props.theme?.app?.icon});
    `}
`

export const AndroidAppName = styled.div`
  color: ${colors.text};
  font-weight: 400;
  grid-area: app;
  font-size: 12px;
  margin-left: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const AndroidDateLabel = styled.div`
  color: ${colors.textLight};
  font-weight: 400;
  font-size: 12px;
`
export const AndroidMessageContainer = styled.div`
  grid-area: message;
  margin-top: 6px;
  margin-right: 4px;
  margin-bottom: ${p => (p.theme.isExpanded && p.theme.hasMedia ? '10px' : '0px')};
  color: ${colors.text};
`

export const AndroidTitle = styled.div`
  font-weight: 500;
  font-size: 14px;

  ${InactiveEditorContainer} {
    -webkit-line-clamp: ${p => (p.theme.isExpanded ? '2' : '1')};
    display: -webkit-box;
    word-break: break-word;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const AndroidBody = styled.div`
  margin-top: ${p => (p.theme.isFocused ? '7px' : 'unset')};
  transition: margin-top 0.2s ease;
  margin-top: ${p => (p.theme.isFocused ? 6 : -2)}px;
  ${InactiveEditorContainer} {
    word-break: break-word;
    -webkit-line-clamp: ${p => (p.theme.isExpanded ? '6' : '1')};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
type ThumbProps = {
  $url: string
}
export const Thumb = styled(BaseThumb)<ThumbProps>`
  position: relative;
  display: ${p => (!!p.$url || p.theme.draggingState !== 'NONE' ? 'block' : 'none')};
  ${p =>
    p.theme.isExpanded
      ? css`
          background: #e6e6e6;
          position: relative;
          min-height: 160px;
          width: 100%;
          padding: 10px;
          &:before {
            content: '';
            display: block;
            ${(p: ThumbProps) =>
              p.$url &&
              css`
                background-image: url(${p.$url});
              `}
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
          }
        `
      : css`
          &:before {
            content: '';
            display: block;
            ${(p: ThumbProps) =>
              p.$url &&
              css`
                background-image: url(${p.$url});
              `}
            background-size: cover;
            width: 32px;
            height: 32px;
          }
          display: ${p => (p.$url !== '' || p.theme.draggingState !== 'NONE' ? 'block' : 'none')};
          border-radius: 8px;
          overflow: hidden;
          position: relative;
          width: 32px;
          margin-top: 12px;
          height: 32px;
        `}
  grid-area: thumb;
`

export const ChromeIconContainer = styled.div`
  width: 16px;
  height: 16px;
`
