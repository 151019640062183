import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { ToggleContainer, ToggleButton, ToggleLink } from './tabletoggle.styles'
import * as themes from './tabletoggle.theme'

type TableToggleProps = {
  children: React.ReactNode
  width?: number
  kind?: 'normal' | 'filter'
  className?: string
  style?: {
    [key: string]: string | number
  }
}

type TableToggleItemProps = {
  children: React.ReactNode
  active?: boolean
  disabled?: boolean
  exact?: boolean
  to?: string
  isActive?: () => boolean
  tiny?: boolean
  sec?: boolean
  col?: boolean
  onClick?: (arg1?: any) => any
  className?: string
  style?: {
    [key: string]: string | number
  }
}

export const TableToggle = ({
  children,
  kind = 'normal',
  width,
  className,
  style,
}: TableToggleProps): React.ReactElement => {
  return (
    // @ts-expect-error peur de casser : its works
    <ThemeProvider theme={themes[kind]}>
      <ToggleContainer width={width} className={className} style={style}>
        {children}
      </ToggleContainer>
    </ThemeProvider>
  )
}

export const TableToggleItem = React.forwardRef<HTMLButtonElement, TableToggleItemProps>(
  (props: TableToggleItemProps, forwardedRef) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null)
    // this hook allows forwardedRef to point at buttonRef
    React.useImperativeHandle(forwardedRef, () => buttonRef.current as HTMLButtonElement, [
      buttonRef,
    ])
    const _onClick = React.useCallback(
      (e: React.SyntheticEvent<HTMLElement>) => {
        if (buttonRef.current) {
          buttonRef.current.blur()
        }
        if (props.onClick && !props.disabled) {
          props.onClick(e)
        }
      },
      [props]
    )
    const { children, active, disabled, tiny, style, col, to, exact, className } = props
    if (to) {
      return (
        <ToggleLink to={to} end={exact} active={active} onClick={_onClick} style={style}>
          {children}
        </ToggleLink>
      )
    }
    return (
      <ToggleButton
        style={style}
        active={active}
        disabled={disabled}
        column={col}
        ref={buttonRef}
        tiny={tiny}
        onClick={_onClick}
        className={className}
      >
        {children}
      </ToggleButton>
    )
  }
)
