import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Map, type Set } from 'immutable'
import { map } from 'lodash-es'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { CampaignFactory, type AppRecord } from 'com.batch.redux/_records'
import {
  type InAppWithVariantRecord,
  DataIdentifierFactory,
  PushDataFactory,
  CampaignDataFactory,
  InAppDataFactory,
  ClickDataFactory,
  ExitDataFactory,
  TriggerDataFactory,
  DayDataFactory,
  PushWithVariantFactory,
  type DayDataRecord,
  type PushDataRecord,
  type InAppDataRecord,
  type TriggerDataRecord,
  type CampaignDataRecord,
  type PushWithVariantRecord,
  InAppWithVariantFactory,
} from 'com.batch.redux/dataCampaign.records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { get } from 'com.batch.common/utils'

const getInt = (d: any, key: string, defaultValue: number = 0): number => {
  const val = parseInt(get(d, key, defaultValue))
  return isNaN(val) ? defaultValue : val
}

const sumPushData = (a: PushDataRecord, b: PushDataRecord) => {
  return a
    .set('sent', a.sent + b.sent)
    .set('sentKnownDi', a.sentKnownDi + b.sentKnownDi)
    .set('sentNotifOn', a.sentNotifOn + b.sentNotifOn)
    .set('open', a.open + b.open)
    .set('influencedOpen', a.influencedOpen + b.influencedOpen)
    .set('devSent', a.devSent + b.devSent)
    .set('devOpen', a.devOpen + b.devOpen)
    .set('reengaged', a.reengaged + b.reengaged)
    .set('unregistered', a.unregistered + b.unregistered)
}

const sumPushWithVariant = (a: PushWithVariantRecord, b: PushWithVariantRecord) => {
  return a
    .set('total', sumPushData(a.total, b.total))
    .set('a', sumPushData(a.a, b.a))
    .set('b', sumPushData(a.b, b.b))
}

const computePushRate = (data: PushDataRecord, app: AppRecord) => {
  let openAlg = 0
  let openRate = 0
  let reengageRate = 0
  let sentAlg = 0
  switch (app.openRateAlg) {
    case 'ACCURATE':
      openAlg = data.influencedOpen + data.open
      openRate = data.sentNotifOn ? openAlg / data.sentNotifOn : 0
      reengageRate = data.sentNotifOn ? data.reengaged / data.sentNotifOn : 0
      sentAlg = data.sentNotifOn ? data.sentNotifOn : data.sent
      break
    case 'ACCURATE_DIRECT':
      openAlg = data.open
      openRate = data.sentNotifOn ? openAlg / data.sentNotifOn : 0
      reengageRate = data.sentNotifOn ? data.reengaged / data.sentNotifOn : 0
      sentAlg = data.sentNotifOn ? data.sentNotifOn : data.sent
      break
    case 'LEGACY_DIRECT':
      openAlg = data.open
      openRate = data.sent ? openAlg / data.sent : 0
      reengageRate = data.sent ? data.reengaged / data.sent : 0
      sentAlg = data.sent ? data.sent : 0
      break
    default:
      openAlg = data.influencedOpen + data.open
      openRate = data.sent ? openAlg / data.sent : 0
      reengageRate = data.sent ? data.reengaged / data.sent : 0
      sentAlg = data.sent ? data.sent : 0
  }
  return data
    .set('openAlg', openAlg)
    .set('reengageRate', reengageRate)
    .set('openRate', openRate)
    .set('sentAlg', sentAlg)
}

const computePushVariantsRate = (push: PushWithVariantRecord, app: AppRecord) =>
  push
    .set('total', computePushRate(push.total, app))
    .set('a', !push ? PushDataFactory() : computePushRate(push.a, app))
    .set('b', !push ? PushDataFactory() : computePushRate(push.b, app))

const computeInAppRate = (inapp: InAppDataRecord) => {
  const webviewClicks = inapp.click.total - inapp.click.cta1 - inapp.click.cta2 - inapp.click.global

  return inapp
    .set('triggerRate', inapp.supplied ? inapp.display / inapp.supplied : 0)
    .set('clickRate', inapp.display ? inapp.click.total / inapp.display : 0)
    .set('closeRate', inapp.display ? inapp.close / inapp.display : 0)
    .setIn(['click', 'cta1Rate'], inapp.display ? inapp.click.cta1 / inapp.display : 0)
    .setIn(['click', 'cta2Rate'], inapp.display ? inapp.click.cta2 / inapp.display : 0)
    .setIn(['click', 'globalRate'], inapp.display ? inapp.click.global / inapp.display : 0)
    .setIn(['click', 'webview'], webviewClicks)
    .setIn(['click', 'webviewRate'], inapp.display ? webviewClicks / inapp.display : 0)
}
const computeInAppVariantsRate = (inapp: InAppWithVariantRecord) =>
  inapp
    .set('total', computeInAppRate(inapp.total))
    .set('a', !inapp ? InAppDataFactory() : computeInAppRate(inapp.a))
    .set('b', !inapp ? InAppDataFactory() : computeInAppRate(inapp.b))

export const computeRate = (data: DayDataRecord, app: AppRecord): DayDataRecord =>
  data
    .set('push', computePushVariantsRate(data.push, app))
    .set('inapp', !data.inapp ? null : computeInAppVariantsRate(data.inapp))

export const sumDayData = (a: DayDataRecord, b: DayDataRecord): DayDataRecord => {
  return DayDataFactory({
    period: a.period,
    push: sumPushWithVariant(a.push, b.push),
    id: b.id,
    errors: a.errors.mergeWith((ea, eb) => ea + eb, b.errors),
    skipped: a.skipped.mergeWith((sa, sb) => sa + sb, b.skipped),
  })
}
const parseIdentifier = (identifier: any) => {
  if (typeof identifier === 'undefined') return DataIdentifierFactory()
  return DataIdentifierFactory({
    value: identifier.value,
    kind:
      identifier.kind === 'group' ? 'groupId' : identifier.kind === 'token' ? 'token' : 'category',
    campaignInfo:
      identifier.kind === 'token'
        ? CampaignFactory({
            name: identifier.campaignName,
            start: dayjs.utc(identifier.startDate),
            tzAware: !!identifier.timezoneDependant,
          })
        : null,
  })
}

const getPushDataProps = (d: any, basePath: string) => {
  return {
    sent: getInt(d, `${basePath}sent`, 0),
    sentKnownDi: getInt(d, `${basePath}sentKnownDi`, 0),
    sentNotifOn: getInt(d, `${basePath}sentNotifOn`, 0),
    open: getInt(d, `${basePath}open`, 0),
    openRate: getInt(d, `${basePath}openRate`, 0),
    influencedOpen: getInt(d, `${basePath}influencedOpen`, 0),
    devSent: getInt(d, `${basePath}devSent`, 0),
    devOpen: getInt(d, `${basePath}devOpen`, 0),
    reengaged:
      getInt(d, `${basePath}reengaged`, 0) === null ? 0 : getInt(d, `${basePath}reengaged`, 0),
    unregistered: getInt(d, `${basePath}unregistered`, 0),
  }
}

const parsePushWithVariant = (d: any) => {
  const indexA = d.push?.byVariant?.findIndex((c: any) => c.variant !== '2') ?? -1
  const indexB = d.push?.byVariant?.findIndex((c: any) => c.variant === '2') ?? -1

  return PushWithVariantFactory({
    total: PushDataFactory(getPushDataProps(d, 'push.')),
    a:
      indexA === -1
        ? PushDataFactory()
        : PushDataFactory(getPushDataProps(d, `push.byVariant[${indexA}].`)),
    b:
      indexB === -1
        ? PushDataFactory()
        : PushDataFactory(getPushDataProps(d, `push.byVariant[${indexB}].`)),
  })
}

const parseArrayError = (d: any, key: string) =>
  Immutable.Map<string, number>(get(d, key, []).map(({ count, error }) => [error, count]))

const parseTrigger = (
  raw: any,
  pushSent: number,
  unregistered: number,
  skipped: number,
  errors: number
): TriggerDataRecord => {
  const td = TriggerDataFactory({
    entered: getInt(raw, 'entered', 0),
    exited: ExitDataFactory({
      push: getInt(raw, 'exited.push', 0),
      query: getInt(raw, 'exited.query', 0),
      event: getInt(raw, 'exited.event', 0),
      stop: getInt(raw, 'exited.stop', 0),
      noToken: getInt(raw, 'skippedNoPushToken', 0),
    }),
  })
  return td.set(
    'waiting',
    td.entered -
      pushSent -
      td.exited.query -
      td.exited.event -
      td.exited.stop -
      td.exited.noToken -
      unregistered -
      skipped -
      errors
  )
}

export const parseResult = (d: any, period: Dayjs): DayDataRecord => {
  const id = parseIdentifier(d.identifier)
  const push = parsePushWithVariant(d)
  const inapp = d['in-app'] ? parseInAppWithVariant(d) : null
  const skipped = parseArrayError(d, 'skipped')
  const sumSkipped = skipped.reduce((prev, skipped) => prev + skipped, 0)
  const errors = parseArrayError(d, 'errors')
  const sumErrors = errors.reduce((prev, error) => prev + error, 0)

  return DayDataFactory({
    period,
    push,
    inapp,
    trigger: d['trigger']
      ? parseTrigger(d['trigger'], push.total.sent, push.total.unregistered, sumSkipped, sumErrors)
      : null,
    errors,
    skipped,
    id,
  })
}

const getInAppDataProps = (raw: any, basePath: string) => {
  return InAppDataFactory({
    display: getInt(raw, `${basePath}display`),
    close: getInt(raw, `${basePath}close`),
    supplied: getInt(raw, `${basePath}suppliedUnique`),
    click: ClickDataFactory({
      withAction: getInt(raw, `${basePath}click.withAction`),
      analyticsIds: Immutable.Map(
        map(
          get(raw, `${basePath}click.analyticsIds`, {}),
          (value: number, key: string) => [key, value] as [string, number]
        )
      ),
      cta1: getInt(raw, `${basePath}click.cta1`),
      cta2: getInt(raw, `${basePath}click.cta2`),
      total: getInt(raw, `${basePath}click.total`),
      global: getInt(raw, `${basePath}click.gobal`), // typo coté data gobal instead of global
    }),
  })
}

const parseInAppWithVariant = (d: any) => {
  const indexA = d['in-app']?.byVariant?.findIndex((c: any) => c.variant !== '2') ?? -1
  const indexB = d['in-app']?.byVariant?.findIndex((c: any) => c.variant === '2') ?? -1

  return InAppWithVariantFactory({
    total: InAppDataFactory(getInAppDataProps(d, 'in-app.')),
    a:
      indexA === -1
        ? InAppDataFactory()
        : InAppDataFactory(getInAppDataProps(d, `in-app.byVariant[${indexA}].`)),
    b:
      indexB === -1
        ? InAppDataFactory()
        : InAppDataFactory(getInAppDataProps(d, `in-app.byVariant[${indexB}].`)),
  })
}

export const parseCampaignData = (d: any, app: AppRecord): CampaignDataRecord => {
  const push = computePushVariantsRate(parsePushWithVariant(d), app)
  const inapp = computeInAppVariantsRate(parseInAppWithVariant(d))
  const skipped = parseArrayError(d, 'skipped')
  const sumSkipped = skipped.reduce((prev, skipped) => prev + skipped, 0)
  const errors = parseArrayError(d, 'errors')
  const sumErrors = errors.reduce((prev, error) => prev + error, 0)

  return CampaignDataFactory({
    lastSend: !get(d, 'sendDate', null) ? null : dayjs(get(d, 'sendDate', null), 'YYYY-MM-DD'),
    push,
    inapp,
    trigger: d['trigger']
      ? parseTrigger(d['trigger'], push.total.sent, push.total.unregistered, sumSkipped, sumErrors)
      : TriggerDataFactory(),
    errors,
    skipped,
  })
}

export type fetchNotificationResolve = {
  data: List<DayDataRecord>
  from: Dayjs
  to: Dayjs
  category: 'marketing' | 'transactional' | 'summary'
}

export const fetchCampaignDetailAnalytics = ({
  app,
  token,
}: {
  app: AppRecord
  token: string
}): Promise<List<DayDataRecord>> => {
  return request
    .get(generateUrl('api_data_campaign_detail', { appId: app.id, token }))
    .then((data: any) => {
      return Immutable.List(
        data.map(raw => {
          const periodDay = dayjs.utc(raw.period, 'YYYY-MM-DD')
          return computeRate(parseResult(raw.details, periodDay), app)
        })
      )
    })
}

export const fetchCampaignsAnalytics = ({
  app,
  tokens,
}: {
  app: AppRecord
  tokens: Array<string>
}): Promise<Map<string, CampaignDataRecord>> => {
  return request
    .post(generateUrl('api_data_campaigns', { appId: app.id }), { tokens })
    .then((data: any) => {
      return Immutable.Map<string, CampaignDataRecord>(
        data.map(raw => {
          return [raw.identifier.value, parseCampaignData(raw, app)]
        })
      )
    })
}

export const fetchNotifications = ({
  app,
  from,
  to,
  category,
}: {
  app: AppRecord
  from: Dayjs
  to: Dayjs
  category: 'summary' | 'marketing' | 'transactional'
}): Promise<fetchNotificationResolve> => {
  return request
    .get(
      generateUrl(
        category === 'summary' ? 'api_data_notification_summary' : 'api_data_notification_details',
        {
          appId: app.id,
          from: from.format('YYYYMMDD'),
          to: to.format('YYYYMMDD'),
          category,
        }
      )
    )
    .then(
      ({ results }) => {
        const arr: Array<DayDataRecord> = []
        results.forEach(({ period, details }) => {
          details.forEach(d => {
            const periodDay = dayjs.utc(period, 'YYYY-MM-DD')
            arr.push(parseResult(d, periodDay))
          })
        })
        return {
          data: Immutable.List(arr.map(pd => computeRate(pd, app))),
          from,
          to,
          category,
        }
      },
      ({ error }) => {
        throw error
      }
    )
}

export const buildArrayOfCacheIds = (
  from: Dayjs,
  to: Dayjs,
  category: 'marketing' | 'transactional' | 'summary'
): Array<string> => {
  const arr: Array<string> = []
  let key = dayjs(from)
  while (key.isSameOrBefore(to)) {
    arr.push(key.format('YYYYMMDD') + '_' + category)
    key = dayjs(key).add(1, 'day')
  }
  return arr
}

export type fetchCustomAnalyticsResolve = {
  results: Array<any>
}

export const fetchCustomAnalytics = ({
  app,
  from,
  to,
  channel,
  schedulingType,
  exportAllCampaigns,
  exportMetrics,
  exportSplitBy,
  status,
  sources,
  when,
  labels,
  query,
}: {
  app: AppRecord
  from: Dayjs
  to: Dayjs
  channel: campaignType
  schedulingType: schedulingType
  exportAllCampaigns: boolean
  exportMetrics: Set<string>
  exportSplitBy: Set<string>
  status: Set<string>
  sources: Set<string>
  when: Set<string>
  labels: Set<number>
  query: string | null | undefined
}): Promise<fetchCustomAnalyticsResolve> => {
  return request
    .post<fetchCustomAnalyticsResolve>(
      generateUrl('api_data_custom_analytics', {
        appId: app.id,
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
        channel,
        schedulingType,
        allCampaigns: exportAllCampaigns,
        metrics: exportMetrics.toArray(),
        splitBy: exportSplitBy.toArray(),
        status: status.toArray(),
        sources: sources.toArray(),
        when: when.toArray(),
        labels: labels.toArray(),
        query,
      }),
      {}
    )
    .then(
      response => {
        return response
      },
      error => {
        return Promise.reject(error)
      }
    )
}

export const fetchEmailCustomAnalytics = async ({
  from,
  to,
  schedulingType,
  exportMetrics,
  exportSplitBy,
  exportStepDetails,
  project,
}: {
  from: Dayjs
  to: Dayjs
  schedulingType: schedulingType
  exportMetrics: Set<string>
  exportSplitBy: Set<string>
  exportStepDetails: boolean
  project: ProjectRecord
}): Promise<fetchCustomAnalyticsResolve> => {
  const url = generateUrl('api_project_stats_custom_analytics', { projectKey: project.projectKey })
  const body = {
    from: from.format('YYYY-MM-DD'),
    to: to.format('YYYY-MM-DD'),
    schedulingType,
    metrics: exportMetrics.toArray(),
    splitBy: exportSplitBy.toArray(),
    isStepExpected: exportStepDetails,
  }
  return await request.post<fetchCustomAnalyticsResolve>(url, body)
}
