import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import {
  PreviewScroll,
  PreviewScrollContent,
  PushPreviewToolbarContainer,
} from 'components/campaign/form/form.styles'
import { WrappedValidatedInput } from 'components/campaign/form/validated-input'
import PreviewToolbar from 'components/campaign/preview/preview-toolbar'
import { StandalonePreviewConnected } from 'components/campaign/preview/standalone-preview'
import { Switch } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { OptionThemeRenderer } from 'components/common/option-theme'
import { Select } from 'components/form'
import { VariantTitle } from 'components/styled/text'
import { Tester } from 'components/tester/tester'

import validators from 'com.batch.common/form.validators'

import { ContentInAppField } from './content-inapp-field'

import {
  type AppRecord,
  type CampaignABRecord,
  type LanguageRecord,
  type SdkSupportsRecord,
  type State,
  type Variant,
} from 'com.batch.redux/_records'
import { togglePushVariant, updateTheme, uploadError } from 'com.batch.redux/campaign.action'
import { activeLanguageSelector, currentCampaign } from 'com.batch.redux/campaign.selector'
import { updateInAppContent, updateInAppCta, updatePushSettings } from 'com.batch.redux/content'
import { pushSettingsSelector } from 'com.batch.redux/content.selector'
import {
  inAppContentForActiveLanguageSelector,
  invalidLangIdsSelector,
} from 'com.batch.redux/targeting.selector.composed'
import { changePreferedInstall } from 'com.batch.redux/template'
import { SelectThemeField } from 'com.batch.redux/theme'
import { type ThemeRecord } from 'com.batch.redux/theme.records'
import {
  InAppVariantFieldsSelector,
  InAppVariantsThemeSelector,
  ThemesSelector,
} from 'com.batch.redux/theme.selector'

const payloadValidator = [validators.json]

type ContentInAppProps = {
  app: AppRecord
  lang: LanguageRecord
  sdkSupports: SdkSupportsRecord
  isInApp: boolean
  abtesting: CampaignABRecord
}

const variants = ['a', 'b'] as const
const handleOptionToString = (t?: ThemeRecord | null) => (t?.name ?? '') + (t?.code ?? '')
export const ContentInApp = ({
  app,
  lang,
  sdkSupports,
  isInApp,
  abtesting,
}: ContentInAppProps): React.ReactElement => {
  // ---- REDUX STATE
  const variantsFields = useSelector(InAppVariantFieldsSelector)
  const fieldSelected = useSelector((state: State) => state.theme.selectedField)
  const settings = useSelector(pushSettingsSelector)
  const content = useSelector(inAppContentForActiveLanguageSelector)
  const installId = useSelector((state: State) => state.template.preferedInstallId)
  const installIds = useSelector((state: State) => state.template.pinnedInstallIds)
  const templateLoading = useSelector((state: State) => state.template.loading)
  const variantsThemes = useSelector(InAppVariantsThemeSelector)
  const appThemes = useSelector(ThemesSelector)
  const activeLanguage = useSelector(activeLanguageSelector)
  const invalidLangIds = useSelector(invalidLangIdsSelector)
  const campaign = useSelector(currentCampaign)

  // ---- REDUX ACTION CREATORS
  const dispatch = useDispatch()
  const focusThemeFieldBound = React.useCallback(
    ({ field, variant }) => dispatch(SelectThemeField({ field, variant })),
    [dispatch]
  )
  const updateInAppContentBound = React.useCallback(
    params => {
      return dispatch(updateInAppContent(params))
    },
    [dispatch]
  )
  const updateInAppCtaBound = React.useCallback(
    action => dispatch(updateInAppCta(action)),
    [dispatch]
  )

  const updatePushSettingsBound = React.useCallback(
    (field, value) => dispatch(updatePushSettings(field, value)),
    [dispatch]
  )
  const changePreferedInstallBound = React.useCallback(
    installId => {
      dispatch(changePreferedInstall(installId))
    },
    [dispatch]
  )
  const uploadErrorBound = React.useCallback(message => dispatch(uploadError(message)), [dispatch])

  const createUpdatePushSettingsBound = React.useCallback(
    field => (value: string) => updatePushSettingsBound(field, value),
    [updatePushSettingsBound]
  )

  const createOnThemeChange = React.useCallback(
    (variant: Variant) => (theme: ThemeRecord | null) => {
      if (theme) dispatch(updateTheme({ theme, variant }))
    },
    [dispatch]
  )

  const createTogglePushVariantBound = React.useCallback(
    variant => () => dispatch(togglePushVariant(variant)),
    [dispatch]
  )

  const themeMenuOffset = React.useMemo(() => {
    const longestThemeNameLength = appThemes
      .map(theme => theme.name)
      .reduce((longest: number, current: string) => {
        return Math.max(current.length, longest)
      }, 0)

    return longestThemeNameLength * 7
  }, [appThemes])

  const variantsWeights = React.useMemo(() => {
    const isVariantAActive = abtesting.activeVariants.has('a')
    const isVariantBActive = abtesting.activeVariants.has('b')
    const variantSize = abtesting.activeVariants.size === 2 ? 50 : 100

    return {
      a: isVariantAActive ? variantSize : 0,
      b: isVariantBActive ? variantSize : 0,
    }
  }, [abtesting])

  return (
    <React.StrictMode>
      {variants
        .filter(variant => variant === 'a' || (abtesting.enabled && campaign.type !== 'push'))
        .map(variant => (
          <FlexLine
            sameHeight
            key={variant}
            style={
              isInApp
                ? { borderTop: '1px solid #0F0F0F1F' }
                : { borderTop: '1px solid rgb(242, 243, 248)' }
            }
          >
            <FlexLineItem
              grow={1}
              container
              style={{ maxWidth: '440px', minWidth: '280px' }}
              key={`${lang.value}-${variant}`}
            >
              {abtesting.enabled && campaign.type !== 'push' && (
                <VariantTitle variant={variant} disabled={variantsWeights[variant] === 0}>
                  <Switch
                    onChange={createTogglePushVariantBound(variant)}
                    isActive={variantsWeights[variant] > 0}
                  >
                    <div className="styled-variant-text">
                      Version {variant.toUpperCase()} ({variantsWeights[variant]}%)
                    </div>
                  </Switch>
                </VariantTitle>
              )}

              {variantsFields.get(variant)?.map(f => {
                return (
                  <ContentInAppField
                    app={app}
                    content={content.get(variant)}
                    field={f}
                    key={`${lang.value}-${variant}-${f.id}`}
                    lang={lang.value}
                    sdkSupports={sdkSupports}
                    focusThemeField={focusThemeFieldBound}
                    updateInAppContent={updateInAppContentBound}
                    updateInAppCta={updateInAppCtaBound}
                    uploadError={uploadErrorBound}
                    forcedFocus={`${f.id}-${variant}` === fieldSelected}
                    variant={variant}
                  />
                )
              })}
              {isInApp && (
                <WrappedValidatedInput
                  onChange={createUpdatePushSettingsBound('payload')}
                  value={settings.payload}
                  touched={false}
                  valid={
                    payloadValidator
                      .map(validator => Boolean(validator(settings.payload)))
                      .reduce((a, b) => a && b, true) ?? true
                  }
                  label="Payload"
                  name="payload"
                  hint="Valid JSON that your app will receive with the in-app message. The root of the JSON must be an Object, and can't have the reserved key com.batch."
                  placeholder='{"valid":"JSON"}'
                  rows={8}
                  type="text"
                  monospaced
                />
              )}
            </FlexLineItem>

            <FlexLineItem
              grow={1}
              container
              bl
              style={{
                position: 'relative',
                minHeight: variantsFields.get(variant)?.find(f => f.id === 'webviewUrl')
                  ? 790
                  : 720,
                background: '#fafafc',
              }}
            >
              {variantsFields.get(variant) && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                >
                  {variantsFields.get(variant)?.filter(f => f.id === 'webviewUrl').size === 1 && (
                    <PushPreviewToolbarContainer
                      style={{
                        top: 53,
                        bottom: 'auto',
                        textAlign: 'center',
                        background: 'rgba(252, 252, 252, .4)',
                        color: '#707782',
                        fontSize: 13,
                        padding: 11,
                        borderTop: 0,
                        borderBottom: '1px solid HSL(228, 19%, 95%)',
                      }}
                    >
                      You can test interactions by clicking on the screen.
                    </PushPreviewToolbarContainer>
                  )}
                  {(installIds.size > 0 ||
                    variantsFields.get(variant)?.filter(f => f.id === 'webviewUrl').size === 1) && (
                    <PushPreviewToolbarContainer>
                      <PreviewToolbar
                        disableFullscreen
                        previewMode="landing"
                        previewWeb={''}
                        // eslint-disable-next-line react/jsx-no-bind
                        updatePreviewMode={() => {}}
                        // eslint-disable-next-line react/jsx-no-bind
                        updatePreviewWeb={() => {}}
                        hasLanding={false}
                        showIframeReloadButton={
                          variantsFields.get(variant)?.filter(f => f.id === 'webviewUrl').size === 1
                        }
                        installLoading={templateLoading}
                        isPush={false}
                        dropUp={false}
                        // eslint-disable-next-line react/jsx-no-bind
                        toggleFullScreen={() => {}}
                        isWeb={app.platform === 'webpush'}
                        changePreferedInstall={changePreferedInstallBound}
                        installIds={installIds}
                        installId={installId}
                        style={{ justifyContent: 'center' }}
                      />
                    </PushPreviewToolbarContainer>
                  )}

                  <FlexLine
                    style={{
                      padding: '8px 19px 8px 19px',
                      background: 'white',
                      borderBottom: '1px solid #0F0F0F1F',
                      flexWrap: 'wrap',
                    }}
                  >
                    <FlexLineItem grow={1}>
                      <Select
                        options={appThemes}
                        isSearchable
                        menuOffset={themeMenuOffset}
                        optionMenuShownCount={7}
                        optionToString={handleOptionToString}
                        optionFormatter={OptionThemeRenderer}
                        value={appThemes.find(t => t.code === variantsThemes[variant]?.code)}
                        style={{
                          width: 220,
                          height: 36,
                        }}
                        onChange={createOnThemeChange(variant)}
                      />
                    </FlexLineItem>
                    <FlexLineItem>
                      {campaign.type === 'in-app' && (
                        <Tester
                          isThemeTester={false}
                          valid={!invalidLangIds.has(activeLanguage?.value ?? 'default')}
                          btnText="Send test"
                          isEmbeddedInToolbar
                          variant={variant}
                        />
                      )}
                    </FlexLineItem>
                  </FlexLine>

                  <PreviewScroll style={{ top: 52 }}>
                    <PreviewScrollContent isWeb={false}>
                      <StandalonePreviewConnected
                        variant={variant}
                        previewMode="landing"
                        previewWeb=""
                        schedulingType="automations"
                      />
                    </PreviewScrollContent>
                  </PreviewScroll>
                </div>
              )}
            </FlexLineItem>
          </FlexLine>
        ))}
    </React.StrictMode>
  )
}
