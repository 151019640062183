import { get as _get } from 'lodash-es'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { Button, useBlurOnClickCallback } from 'components/common/button'
import Loader from 'components/common/loader-legacy'
import { Form, InputWrapper, FormActions } from 'components/form'
import { LinkArrow } from 'components/styled/text'

import { formatPrice } from 'com.batch.common/utils'

import { Plan } from './plan'
import { Wizard } from './wizard'

import { AccountSection, SectionTitle, SectionInfo, SectionForm } from '../account.styles'
import { type CompanyRecord } from 'com.batch.redux/_records'
import { updateAutoRenew, cancelNca } from 'com.batch.redux/billing.api'
import { enrichBillingInfo } from 'com.batch.redux/company'
import { showToastLegacy, showToast } from 'com.batch.redux/toaster'

type PlanSectionProps = {
  company: CompanyRecord
  planIsManagedOuside: boolean
}

export const PlanSection = ({
  company,
  planIsManagedOuside,
}: PlanSectionProps): React.ReactElement => {
  const dispatch = useDispatch()
  const billing = company.billing
  const [opened, setOpened] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  //  Blurr button 1 but not blur the second.
  const openBlur = useBlurOnClickCallback(() => {
    setOpened(true)
  }, [])

  React.useEffect(() => {
    dispatch(enrichBillingInfo(company))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id, dispatch])
  const downgradePrice = !billing.downgradingTo
    ? 0
    : _get(billing.downgradingTo, `${billing.currency}.${billing.downgradingCycle}`, 0)

  const handleOnClose = React.useCallback(() => {
    setOpened(false)
  }, [])

  const handleOnClickCancel = React.useCallback(() => {
    setLoading(true)
    dispatch(cancelNca(company)).then(
      () => window.location.reload(),
      error => {
        setLoading(false)
        console.warn(error)
        dispatch(showToastLegacy(error, '', 'error', 'danger'))
      }
    )
  }, [company, dispatch])

  const handleOnClickContactUs = React.useCallback(() => {
    if (typeof window.Intercom === 'function') {
      window.Intercom('showNewMessage')
    } else {
      window.location.href = 'mailto:support@batch.com'
    }
  }, [])

  return (
    <AccountSection>
      <SectionInfo>
        <SectionTitle>Plan</SectionTitle>
        <p>If you have any specific needs, please contact us.</p>
        <Wizard company={company} close={handleOnClose} opened={opened} />
        <LinkArrow
          style={{ marginTop: 12 }}
          intent="action"
          target="_blank"
          href="https://batch.com/pricing"
        >
          Learn more about our plans
        </LinkArrow>
      </SectionInfo>
      <SectionForm>
        <Loader loading={loading || billing.loading} overlay>
          <Form horizontal>
            <InputWrapper label="Current plan">
              <Plan
                plan={billing.plan}
                cancelUnpaidAt={billing.cancelUnpaidAt}
                autorenew={billing.autorenew || !!billing.downgradingTo}
                cycle={billing.cycle}
                currency={billing.currency}
                nextInvoice={billing.nextInvoice}
                trial={billing.trial}
                trialUntil={billing.trialUntil}
              />
            </InputWrapper>
            <FormActions>
              <Button
                kind="primary"
                intent="action"
                disabled={!!billing.downgradingTo || !billing.autorenew || planIsManagedOuside}
                onClick={openBlur}
                style={{ marginRight: 10 }}
              >
                Change plan
              </Button>
              {billing.plan.code !== 'free' && billing.plan.code !== 'free2' && (
                <Button
                  intent="neutral"
                  kind="inline"
                  disabled={!!billing.downgradingTo || planIsManagedOuside}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => {
                    setLoading(true)
                    updateAutoRenew(company, !billing.autorenew).then(
                      () => {
                        dispatch(showToast({ kind: 'success', message: 'Plan cancelled' }))
                        window.location.reload()
                      },
                      error => {
                        setLoading(false)
                        dispatch(showToastLegacy(error, '', 'error', 'danger'))
                      }
                    )
                  }}
                >
                  {billing.autorenew ? 'Cancel' : 'Resume'} current plan
                </Button>
              )}
            </FormActions>
            {!!billing.downgradingTo && (
              <FormActions>
                <p style={{ color: '#E38041' }}>
                  Your plan will downgrade to {billing.downgradingTo.name} (
                  {formatPrice(downgradePrice, billing.currency, true)}{' '}
                  {billing.vatRate &&
                    `+ VAT ${formatPrice(
                      (downgradePrice * billing.vatRate) / 100,
                      billing.currency,
                      true
                    )}`}
                  ) each {billing.downgradingCycle === 'monthly' ? 'month' : 'year'} starting on{' '}
                  {!!billing.nextInvoice && billing.nextInvoice.format('DD/MM/YYYY')}
                </p>
                <LinkArrow href="#" gray onClick={handleOnClickCancel}>
                  Cancel plan change
                </LinkArrow>
              </FormActions>
            )}
            {planIsManagedOuside && (
              <FormActions>
                <p style={{ color: '#898989' }}>
                  Your plan condition can't be modified directly here.
                </p>
                <LinkArrow href="#" onClick={handleOnClickContactUs}>
                  Please reach our team to fullfill your request
                </LinkArrow>
              </FormActions>
            )}
          </Form>
        </Loader>
      </SectionForm>
    </AccountSection>
  )
}
