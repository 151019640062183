// ====================== LIBS
import Immutable from 'immutable'
import * as React from 'react'

import { type InputProps } from './helper'

import { AudiencePicker } from 'com.batch/audience/ui/components/audience-picker'

export const InputAudience = ({
  condition,
  updateCondition,
  isDisabled,
  removeSelf,
}: InputProps): React.ReactElement => {
  const onChangeLocal = React.useCallback(
    value => {
      if (value.size === 0 && removeSelf) {
        removeSelf()
      } else {
        updateCondition(
          condition?.set(
            'value',
            condition?.value.set('stringList', !value ? Immutable.List() : Immutable.List(value))
          )
        )
      }
    },
    [condition, removeSelf, updateCondition]
  )

  const conditionValue = React.useMemo(() => {
    return condition?.get('value').stringList ?? Immutable.List<string>()
  }, [condition])

  return <AudiencePicker value={conditionValue} onChange={onChangeLocal} isDisabled={isDisabled} />
}
