import styled from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { texts, colors } from 'components/styled/tokens'

import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'

const getColorFromStatus = (status: SubscriptionStatus | 'IMPORTED'): string => {
  if (status === 'IMPORTED') return colors.text
  if (status === 'SUBSCRIBED') return colors.textSuccess
  if (status === 'UNSUBSCRIBED') return colors.textDanger
  return colors.textDisabled
}
type props = {
  subscriptionStatus: SubscriptionStatus | 'IMPORTED'
}
export const SubscriptionBlockContainer = styled.div<props>`
  display: flex;
  gap: 4px;
  cursor: pointer;
  font: ${texts.label.font};

  ${IconContainer}:first-child {
    color: ${p => getColorFromStatus(p.subscriptionStatus)};
  }
`
