import styled from 'styled-components'

import { HintIcon } from 'components/common/hint'
import { colors } from 'components/styled/tokens'

export const OpensCell = styled.div`
  position: relative;
  flex-shrink: 0;

  ${HintIcon} {
    color: ${colors.textDisabled};
    font-size: 0.8em;
    margin-right: 0;

    &:hover {
      color: ${colors.textLight};
    }
  }
`

export const HintContainer = styled.span`
  position: absolute;
  top: -1px;
  left: 100%;
`
