import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'
import { ThemeContext } from 'styled-components'

import { Button } from 'components/common/button'
import { trackEvent } from 'components/common/page-tracker'
import { Icon } from 'components/common/svg-icon'

import { type DateRange } from 'com.batch.common/dayjs.custom'

import { type AppRecord } from 'com.batch.redux/_records'
import { intentToFetchNotifications } from 'com.batch.redux/dataCampaign'
import {
  createLoadedSelector,
  createLoadingSelector,
  allDayDataSelector,
} from 'com.batch.redux/dataCampaign.selector'

type NotificationCsvProps = {
  app: AppRecord
  range: DateRange
}

export const NotificationCsv = ({ app, range }: NotificationCsvProps): React.ReactElement => {
  const theme = React.useContext(ThemeContext)

  // ====================== STATE
  const [loading, setLoading] = React.useState(false)
  const [clickId, setClickId] = React.useState(0)
  const [processedClickId, setProcessedClickId] = React.useState(0)

  // ====================== REDUX
  const dispatch = useDispatch()
  const data = useSelector(allDayDataSelector)
  const loadedMarketing = useSelector(createLoadedSelector('marketing'))
  const loadedTransac = useSelector(createLoadedSelector('transactional'))
  const loadingMarketing = useSelector(createLoadingSelector('marketing'))
  const loadingTransac = useSelector(createLoadingSelector('transactional'))

  React.useEffect(() => {
    if (loadedTransac && loadedMarketing && clickId !== processedClickId && loading) {
      setLoading(false)
      trackEvent('ANALYTICS_EXPORT_CLICKED', { channel_type: 'push' })
      setProcessedClickId(clickId)
      const rows: (string | number | undefined)[][] = [
        [
          'date',
          'kind',
          'time',
          'utc/local',
          'group or campaign',
          'sent',
          'sent opt-ins',
          'direct open',
          'influenced open',
          'reengaged users',
          'errors',
          'unregistered',
        ],
      ]
      const specialChars = ['+', '-', '=', '@', '\t', '\r']
      data
        .filter(d => d.id.kind === 'token')
        .forEach(d => {
          let campaignName =
            '"' + (d.id?.campaignInfo?.name?.replace(/"/g, '""') || 'Unknown campaign') + '"'
          if (campaignName[0] === '"' && specialChars.includes(campaignName[1])) {
            campaignName = campaignName.replace(campaignName[1], "'" + campaignName[1])
          }
          rows.push([
            d.period.format('DD/MM/YYYY'),
            'marketing',
            d.id?.campaignInfo?.start?.format('HH:mm'),
            d.id?.campaignInfo?.tzAware ? 'LOCAL' : 'UTC',
            campaignName,
            d.push.total.sent,
            d.push.total.sentNotifOn,
            d.push.total.open,
            d.push.total.influencedOpen,
            d.push.total.reengaged,
            d.errors.reduce((a, b) => a + b, 0),
            d.push.total.unregistered,
          ])
        })
      data
        .filter(d => d.id.kind === 'groupId')
        .forEach(d => {
          rows.push([
            d.period.format('DD/MM/YYYY'),
            'transac',
            'NA',
            'NA',
            '"' + d.id.value + '"',
            d.push.total.sent,
            d.push.total.sentNotifOn,
            d.push.total.open,
            d.push.total.influencedOpen,
            d.push.total.reengaged,
            d.errors.reduce((a, b) => a + b, 0),
            d.push.total.unregistered,
          ])
        })
      const csv = rows.map(row => row.join(',')).join('\n')
      const filename = `app_${app.id}_push_${range.from.format('YYYY-MM-DD')}_${range.to.format(
        'YYYY-MM-DD'
      )}.csv`
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      if (navigator && typeof navigator.msSaveBlob === 'function') {
        navigator.msSaveBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', filename)
          link.style.visibility = 'hidden'
          if (document && document.body) document.body.appendChild(link)
          link.click()
          if (document && document.body) document.body.removeChild(link)
        }
      }
    }
  }, [
    clickId,
    loadedMarketing,
    loadedTransac,
    processedClickId,
    loading,
    app.id,
    range.from,
    range.to,
    data,
  ])

  const onClick = React.useCallback(() => {
    dispatch(
      intentToFetchNotifications({
        category: 'marketing',
        from: range.from,
        to: range.to,
      })
    )
    dispatch(
      intentToFetchNotifications({
        category: 'transactional',
        from: range.from,
        to: range.to,
      })
    )
    setLoading(true)
    setClickId(clickId + 1)
  }, [clickId, dispatch, range.from, range.to])

  return (
    <Button
      intent="action"
      kind="secondary"
      onClick={onClick}
      isLoading={loading && (loadingMarketing || loadingTransac)}
      prefix="download"
      addOn="prefix"
      disabled={theme?.isLoading ?? false}
    >
      <Icon icon="download" />
      Download CSV
    </Button>
  )
}
