// @ts-nocheck

import { Map, List, fromJS, Set } from 'immutable'
import { first as _first, max as _max } from 'com.batch.common/utils'

import { dayjs } from 'com.batch.common/dayjs.custom'
import * as Types from 'com.batch.common/legacy-query-types'
import {
  CustomAudienceFactory,
  EstimateFactory,
  EstimateTokensFactory,
} from 'com.batch.redux/_records'
import { rawToRecord } from 'com.batch.redux/attribute.api'
import { initialState, generateConditionId } from 'com.batch.redux/targeting'
import functions from 'com.batch.redux/targeting.functions'
import operators from 'com.batch.redux/targeting.operators'
import { get } from 'com.batch.common/utils'

export const rawEstimateToRecord = (result: unknown) =>
  EstimateFactory({
    loading: false,
    error: false,
    installs: get(result, 'total', 0),
    matchingInstalls: get(result, 'matching_install', 0),
    all: EstimateTokensFactory({
      count: get(result, 'total_reachable', 0),
      biggestTimezone: _max(Object.values(get(result, 'total_reachable_by_offset', [0]))),
      optIns: get(result, 'total_reachable_notif_on', 0),
    }),
    matching: EstimateTokensFactory({
      count: get(result, 'matching', 0),
      biggestTimezone: _max(Object.values(get(result, 'matching_by_offset', [0]))),
      optIns: get(result, 'matching_notif_on', 0),
    }),
  })

// eslint-disable-next-line prettier/prettier
const mapTypeToInput = (inputType: unknown) => {
  switch (inputType) {
    case Types.AUDIENCE:
      return 'audience'
    case Types.BOOLEAN:
      return 'boolean'
    case Types.AGE:
      return 'agepicker'
    case Types.INTEGER:
      return 'number'
    case Types.FLOAT:
      return 'float'
    case Types.TAG:
      return Types.TAG
    case Types.DATE:
      return 'datepicker'
    default:
      return 'text'
  }
}

const getFunctionByValue = (value: unknown) => {
  return functions.find(func => func.get('value') === value)
}

const getOperatorByValue = (value: unknown) => {
  return operators.find(op => op.get('value') === value)
}

const addFunctions = (condition: unknown) => {
  const matchingFunctions = functions.filter(func =>
    func.get('for').includes(condition.getIn(['attribute', 'type']))
  )
  return condition.set('functions', matchingFunctions)
}

const getDefaultFunctionForAttribute = (attribute: unknown) => {
  const matchingFunctions = functions.filter(func =>
    func.get('for').includes(attribute.get('type'))
  )
  return matchingFunctions.size > 0 ? matchingFunctions.first() : null
}
const getDefaultOperatorForAttributeAndFunc = (attribute: unknown, func: unknown) => {
  const typeToMatch =
    func !== null && typeof func !== 'undefined' ? func.get('returns') : attribute.get('type')
  const attributeOperators = operators.filter(op => op.get('for').includes(typeToMatch))
  const defaultOperators = attributeOperators.filter(op => op.get('default', false) === true)
  return defaultOperators.size > 0 ? defaultOperators.first() : attributeOperators.first()
}

const addOperators = (condition: unknown) => {
  const typeToMatch =
    condition.get('func') !== null
      ? condition.getIn(['func', 'returns'], condition.getIn(['attribute', 'type']))
      : condition.getIn(['attribute', 'type'])

  // filter $exists for native boolean
  const id = condition.getIn(['attribute', 'id'])

  const attributeOperators = operators.filter(op => {
    const isExistOnNativeBoolean =
      (id === 'b.has_custom_id' || id === 'b.transaction_tracked') && op.get('api') === '$exists'
    return (
      op.get('for').includes(typeToMatch) &&
      (condition.get('func') === null || op.get('value') !== 'exists') &&
      !isExistOnNativeBoolean
    )
  })
  return condition.set('operators', attributeOperators)
}

const setInputType = (condition: unknown) => {
  const attribute = condition.get('attribute', Map())
  const operator = condition.get('operator')
  const func = condition.get('func')
  let inputType = mapTypeToInput(attribute.get('type', ''))
  if (func) {
    inputType = mapTypeToInput(func.get('returns'))
    if (inputType === Types.TAG) {
      inputType = operator.get('forcedInputType')
    }
  } else {
    if (operator && operator.get('forcedInputType', false)) {
      inputType = operator.get('forcedInputType')
    }
  }

  return condition.set('inputType', inputType)
}

const buildLogical = (root: unknown, conditions: unknown) => {
  const nbChild = root.get('child', Map()).size
  switch (nbChild) {
    case 0:
      return {}
    case 1: {
      const child = root.get('child').get(0)
      if (child.get('kind') === 'logical') {
        return buildLogical(child, conditions)
      } else {
        return buildCondition(conditions.get(child.get('id')))
      }
    }
    default:
      return {
        ['$' + root.get('value')]: root
          .get('child')
          .map(row => {
            if (row.get('kind') === 'logical') {
              return buildLogical(row, conditions)
            } else {
              return buildCondition(conditions.get(row.get('id')))
            }
          })
          .toJS(),
      }
  }
}

const getMatchingVariant = (variants: unknown, args: unknown) => {
  let matchWeight = 0
  let variant = variants.get(0)
  variants.forEach(v => {
    let weight = 0

    v.get('args').forEach(arg => {
      if (args.get(arg, false)) {
        weight++
      }
    })
    if (weight > matchWeight) {
      variant = v
      matchWeight = weight
    }
  })
  return variant
}

const buildCondition = (condition: unknown) => {
  let key = condition.getIn(['attribute', 'id'], '')
  const operator = condition.getIn(['operator', 'api'])
  const type = condition.getIn(['attribute', 'type'])
  let value = condition.get('value')
  if (key === 'bt.custom_audiences') {
    value = value.toJS().map(v => v.id)
  } else if (key === 'b.position') {
    value = true
    const radius = `${condition.getIn(['args', '__RADIUS__'], 500)}m`.replace('mm', 'm')
    const maxage = `${condition.getIn(['args', '__MAXAGE__'], 12)}h`.replace('hh', 'h')
    key = 'isNear(lat:__LAT__, lng:__LNG__, radius:__RADIUS__, expiration:__MAXAGE__)'
      .replace(
        '__LAT__',
        condition.getIn(
          ['args', '__POSITION__', 'lat'],
          condition.getIn(['args', '__POSITION__'], { lat: '' }).lat
        )
      )
      .replace(
        '__LNG__',
        condition.getIn(
          ['args', '__POSITION__', 'lng'],
          condition.getIn(['args', '__POSITION__'], { lng: '' }).lng
        )
      )
      .replace('__RADIUS__', radius)
      .replace('__MAXAGE__', maxage)
  } else {
    if (condition.get('func')) {
      let args = condition.get('args', new Map())
      if (args.get('__VALUE__', null) === null || args.get('__KEY__', null) === null) {
        args = args.delete('__VALUE__').delete('__KEY__')
      }
      if (args.get('__KEY__', '').substr(-8) === '|||label') {
        args = args.delete('__KEY__').set('__LABEL__', args.get('__VALUE__')).delete('__VALUE__')
      } else if (args.get('__KEY__', '') === 'tag|||tag') {
        args = args.delete('__KEY__').set('__TAG__', args.get('__VALUE__')).delete('__VALUE__')
      } else if (args.get('__KEY__') && args.get('__VALUE__', null) !== null) {
        // on a un truc uber foireux dans ce cas, avec EventDataAttributeKind|||EventDataAttributeName
        const tmp = args.get('__KEY__').split('|||')
        // for now we always quote value
        args = args.set('__KEY__', tmp[0])
        const currentValue = args.get('__VALUE__', '')
        args = args.set(
          '__VALUE__',
          `'${typeof currentValue === 'string' ? currentValue.replace(/'/g, "''") : currentValue}'`
        )
      }

      const variant = getMatchingVariant(condition.getIn(['func', 'variants'], new List()), args)
      condition = condition.set('args', args.set('__ATTR__', condition.getIn(['attribute', 'id'])))
      key = variant.get('api', '')
      variant.get('args', new List()).forEach(arg => {
        const val = condition.getIn(['args', arg], '')
        let cleanValue = val !== null && typeof val === 'object' ? val.value : val
        if (arg === '__LABEL__') {
          cleanValue = cleanValue.replace(/'/g, "''")
        }
        key = key.replace(arg, cleanValue)
      })
    } else if ((type === Types.FLOAT || type === Types.INTEGER) && operator !== '$exists') {
      value = value * 1
    }

    if (typeof value !== 'undefined' && value !== null) {
      if (Array.isArray(value)) {
        value = value.map(v =>
          key === 'b.city_code' || key === 'b.carrier_code' ? v.value * 1 : v.value
        )
      } else if (dayjs.isDayjs(value)) {
        value = value.format('X') * 1
      } else if (typeof value === 'object') {
        value = value.value
      }
    }
  }

  const apiOperator = condition.getIn(['operator', 'api'])
  const formatedCondition = {
    [key]: apiOperator
      ? {
          [apiOperator]: value,
        }
      : value,
  }
  return condition.get('negate', true) ? { $not: formatedCondition } : formatedCondition
}

const removeInvalidCondition = (root: unknown, conditions: unknown) => {
  return root.set(
    'child',
    root
      .get('child')
      .filter(c => {
        return c.get('kind') === 'logical' || conditions.getIn([c.get('id'), 'valid'])
      })
      .map(c => {
        if (c.get('kind') === 'condition') {
          return c
        } else {
          return removeInvalidCondition(c, conditions)
        }
      })
  )
}
// when we remove condition, we end up with $and: [0: valid condition, 1: {}]
// this removes those empty entries
const removeEmptyLogical = (query: unknown) => {
  Object.keys(query).forEach(k => {
    if (k === '$and' || k === '$or' || k === '$not') {
      if (Object.keys(query[k]).length === 0) {
        delete query[k]
      } else {
        if (Array.isArray(query[k])) {
          query[k] = query[k].filter(item => Object.keys(item).length > 0).map(removeEmptyLogical)
          // removes logical when they have only one child
          if (query[k].length === 1) {
            query = query[k][0]
          }
        } else {
          query[k] = removeEmptyLogical(query[k])
        }
      }
    }
  })
  return query
}

const buildQuery = (tree: unknown, conditions: unknown) => {
  const query = removeEmptyLogical(
    buildLogical(removeInvalidCondition(tree, conditions), conditions)
  )
  if (Object.keys(query).length === 0) return null
  return query
}

const validateCondition = (condition: unknown) => {
  const operator = condition.getIn(['operator', 'api'], null)
  const value = condition.get('value', null)
  const func = condition.getIn(['func', 'value'], false)
  const type = condition.getIn(['attribute', 'type'])
  const id = condition.getIn(['attribute', 'id'])
  const args = condition.get('args')
  if (type === Types.RETARGETING) return Boolean(condition.getIn(['args', '__TOKEN__']))
  if (operator === '$exists') return value === true || value === false
  if (id === 'b.position') return args.get('__POSITION__')
  return (
    (func !== 'countSince' || !!args.get('__PERIOD__', false)) &&
    (typeof value !== 'string' || value !== '') &&
    typeof value !== 'undefined' && // defined
    value !== null && // not null
    (id.substr(0, 3) !== 'be.' || !!args.get('__TOKEN__', false)) &&
    (!Array.isArray(value) || value.length > 0) && // not an empty array
    (type !== Types.INTEGER || !isNaN(parseInt(value))) // valid integer if integer type
  )
}

// takes a key and value, and return the parsed condition
export const getBaseCondition = (key: unknown, rest: unknown) => {
  // initial values
  let func = null,
    operator = null,
    value = null,
    attribute = null,
    negate = false

  const id = generateConditionId(),
    args: Record<string, any> = {}

  // if the key is the logical $not, this is a negated condition, and the key/value are downstairs
  if (key === '$not') {
    negate = true
    key = _first(Object.keys(rest.$not))
    rest = rest.$not
  }

  // no parenthesis = no function = attribute id is the key
  if (key.indexOf('(') === -1) {
    attribute = key
  } else {
    // lookup for the matching function / variant
    functions.forEach(f => {
      f.get('variants').forEach(v => {
        if (key.match(v.get('regex'))) {
          func = f
          const tmp = v.get('regex').exec(key)
          // extract args
          v.get('args').map((argName, argIndex) => {
            args[argName] = tmp[argIndex + 1]
            if (argName === '__LABEL__') args[argName] = args[argName].replace(/''/g, "'")
          })
        }
      })
    })
    // we always have __ATTR__ when whe have a function
    if (get(args, '__ATTR__', false)) {
      attribute = args.__ATTR__
      delete args.__ATTR__
    } else {
      // except for isNear, which uses b.position "fake" native attr
      attribute = 'b.position'
      args.__RADIUS__ = args.__RADIUS__ * 1
      args.__POSITION__ = {
        lat: args.__LAT__ * 1,
        lng: args.__LNG__ * 1,
      }
      delete args.__LAT__
      delete args.__LNG__
    }
    // remove quotes around value, add type to key
    const reg = /'(.{1,90})'/
    if (get(args, '__VALUE__', false)) {
      args.__KEY__ = `${args.__KEY__}|||attribute`
      if (args.__VALUE__.match(reg)) {
        args.__VALUE__ = reg.exec(args.__VALUE__)[1]
        // remove double quotes
        args.__VALUE__ = args.__VALUE__.replace(/''/g, "'")
      } else if (!isNaN(parseInt(args.__VALUE__))) {
        args.__VALUE__ = parseInt(args.__VALUE__)
      } else {
        args.__VALUE__ = !!args.__VALUE__
      }
    }
    if (get(args, '__LABEL__', false)) {
      args.__VALUE__ = args.__LABEL__
      delete args.__LABEL__
      args.__KEY__ = 'Label|||label'
    }
    if (get(args, '__TAG__', false)) {
      args.__VALUE__ = args.__TAG__
      delete args.__TAG__
      args.__KEY__ = 'tag|||tag'
    }
  }

  // now we get the operator and value
  const apiOperator = _first(Object.keys(rest[key]))
  // default case : we got an object, so we neeed to extract the operator
  if (typeof rest[key] === 'object') {
    operator = operators.find(
      ope => ope.get('api') === (apiOperator === '$containsAny' ? '$contains' : apiOperator)
    )
    value = _first(Object.values(rest[key]))
  } else {
    // no object means default $eq operator
    operator = operators.find(ope => ope.get('api') === '$eq')
    value = rest[key]
  }
  // not sure 'bout that
  // if (typeof value === 'string') value = value.replace(/''/g, '\'')

  if (
    typeof attribute === 'string' &&
    (attribute.slice(0, 2) === 't.' || attribute.slice(0, 3) === 'ut.') &&
    func === null
  ) {
    func = functions.find(f => f.get('value') === 'content')
  }

  const cond = fromJS({
    func,
    args,
    attribute,
    negate,
    valid: true,
    operator,
  }).set('value', value) // we set value afterwards cause value shall stay in js world (not immutable type)
  return {
    id,
    condition: cond,
  }
}

const isLogical = (key: unknown) => key === '$or' || key === '$and'

const guessAttributeKind = (id: unknown, operator: unknown, func: unknown, value: unknown) => {
  const [prefix] = id.split('.')
  if (prefix === 'bt.') {
    return Types.AUDIENCE
  }
  if (prefix === 't' || prefix === 'ut') {
    return Types.TAG
  }
  if (prefix === 'e') {
    return Types.EVENT
  }
  if (func && func.get('for').has(Types.DATE)) {
    return Types.DATE
  }
  if (operator.get('for').size === 1) {
    return operator.get('for').get(0)
  }
  if (typeof value === 'boolean') {
    return Types.BOOLEAN
  }
  if (typeof value === 'number' || !isNaN(value)) {
    if (`${value}`.length === `${parseInt(value)}`.length) {
      return Types.INTEGER
    } else {
      return Types.FLOAT
    }
  }
  return Types.STRING
}

export const parseQuery = (
  query: unknown,
  path = ['child'],
  tree = initialState.get('tree'),
  conditions = Map(),
  firstLevel = true
) => {
  for (const key in query) {
    if (firstLevel) {
      if (key === '$or') {
        tree = tree.set('value', 'or')
      } else {
        tree = tree.set('value', 'and')
      }
    }
    if (isLogical(key)) {
      // parse childs
      for (const indice in query[key]) {
        const child = query[key][indice]
        const childKey = _first(Object.keys(child))
        if (isLogical(childKey)) {
          // create a new logical
          const logicalId = generateConditionId()
          tree = tree.setIn(
            path,
            tree.getIn(path, new List()).push(
              Map({
                kind: 'logical',
                id: logicalId,
                child: List(),
                value: childKey === '$or' ? 'or' : 'and',
              })
            )
          )
          // we parse the subquery, and use the results to enrich current one
          const tmp = parseQuery(
            child,
            [...path, parseInt(indice), 'child'],
            tree,
            conditions,
            false
          )
          tree = tmp.tree
          conditions = tmp.conditions
        } else {
          // this is a condition, let's parse it
          const { id, condition } = getBaseCondition(childKey, child)
          tree = tree.setIn(path, tree.getIn(path, new List()).push(Map({ kind: 'condition', id })))
          conditions = conditions.set(id, condition)
        }
      }
    } else {
      const { id, condition } = getBaseCondition(key, query)
      tree = tree.set('child', tree.get('child').push(Map({ kind: 'condition', id })))
      conditions = conditions.set(id, fromJS(condition))
    }
  }
  return {
    tree: tree,
    conditions: conditions,
  }
}

// just a wrapper to replace attribute and values "id" by their respective immutable object
const parseAndEnrichQuery = (query: unknown, attributes: unknown, values: unknown) => {
  const { tree, conditions } = parseQuery(query)
  // we will send them with the action to be added to our state
  const missingAttributes: unknown = []
  const missingValues: unknown = []

  const richConditions = conditions.map(c => {
    const attrId = c.get('attribute')
    const simpleValue = c.get('value')
    let enrichedValue = ''
    const valuesList = values.getIn([attrId, 'values', '__default'], List())
    // replace attribute by real deal
    let matchingAttribute = attributes.find(a => a.get('id') === attrId)
    if (!matchingAttribute) {
      const newAttrType = guessAttributeKind(
        attrId,
        c.get('operator'),
        c.get('func'),
        c.get('value')
      )
      matchingAttribute = rawToRecord({
        hidden: false,
        overridenType: newAttrType,
        id: attrId,
      })
      missingAttributes.push(matchingAttribute)
    }
    c = c.set('attribute', matchingAttribute)

    // do not replace bool value
    if (typeof simpleValue === 'boolean') {
      return c
    }

    // do not replace when using a function (unless the fake content func)
    if (c.get('func', false) && c.getIn(['func', 'value'], false) !== 'content') {
      if (c.getIn(['func', 'value']) === 'date') {
        return c.set('value', dayjs.utc(dayjs.unix(c.get('value'))))
      } else if (c.getIn(['func', 'returns'], false) === Types.AGE) {
        const currVal = c.get('value')
        if (typeof currVal === 'string') {
          return c
        } else {
          return c.set('value', currVal.toString() + 'd')
        }
      } else {
        return c
      }
    }

    // do not replace when using a substring
    if (
      c.getIn(['operator', 'value']) === 'endsWith' ||
      c.getIn(['operator', 'value']) === 'startsWith'
    ) {
      return c
    }

    if (
      (matchingAttribute.get('type') === Types.INTEGER ||
        matchingAttribute.get('type') === Types.FLOAT) &&
      c.getIn(['operator', 'value']) !== 'exists' &&
      c.getIn(['operator', 'api']) !== '$notIn' &&
      c.getIn(['operator', 'api']) !== '$in'
    ) {
      c = c.set('value', c.get('value').toString())
      return c
    }
    if (matchingAttribute.get('type') === Types.AUDIENCE) {
      return c.set(
        'value',
        Set(c.get('value').map(id => CustomAudienceFactory({ id, name: id, partial: true })))
      )
    }

    if (Array.isArray(simpleValue)) {
      enrichedValue = simpleValue.map(v => {
        const found = valuesList.find(vr => vr.get('value') === v)
        if (found) {
          return found.toJS()
        } else {
          const newValue = {
            nb: 0,
            value: v,
            pcent: '0%',
            pretty: v,
          }
          missingValues.push({ attrId, value: newValue })
          return newValue
        }
      })
    } else {
      const found = valuesList.find(vr => vr.get('value') === simpleValue)
      if (found) {
        enrichedValue = found.toJS()
      } else {
        enrichedValue = {
          nb: 0,
          value: simpleValue,
          pcent: '0%',
          pretty: simpleValue,
        }
        missingValues.push({ attrId, value: enrichedValue })
      }
    }

    c = c.set('value', enrichedValue)

    return c
  })
  return {
    tree: tree,
    conditions: richConditions,
    missingAttributes,
    missingValues,
  }
}

export default {
  buildQuery,
  parseQuery: parseAndEnrichQuery,
  getFunctionByValue,
  getOperatorByValue,
  validateCondition,
  getDefaultOperatorForAttributeAndFunc,
  getDefaultFunctionForAttribute,
  formatCondition: condition => setInputType(addOperators(addFunctions(condition))),
}
