// @TODO faut rewrite, plein de trucs vont po
import { type Map } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { Button } from 'components/common/button'
import { FlexLineItem } from 'components/common/flexline'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Input, TabButton, TabButtonItem } from 'components/form'

import { CappingRow, CappingData } from './pressure.styles'

import {
  CappingFactory,
  type CappingCategoryRecord,
  type CappingRecord,
} from 'com.batch.redux/_records'
import * as appActions from 'com.batch.redux/app.action'

type PressureRowProps = {
  line: string
  inputRef?: any
  isEditing?: boolean
  isNew?: boolean
  isEmpty?: boolean
  cap?: CappingRecord
  category: CappingCategoryRecord
  errors: Map<string, string>
  setErrors: (arg1: Map<string, string>) => any
}
const initialCapping = CappingFactory().set('period', '1d').set('capping', 1)
const PressureLine = ({
  line,
  isEditing,
  isNew,
  category,
  cap,
  errors,
  setErrors,
}: PressureRowProps) => {
  const dispatch = useDispatch()

  // ====================== COMPONENT STATES
  const [newCapping, setNewCapping] = React.useState<CappingRecord>(initialCapping)

  // ====================== COMPONENT CONSTANTS
  const currentCapping: CappingRecord = !!cap && !isNew ? cap : newCapping

  const isEditingOrNew = isEditing || isNew
  const inputWidth = isEditingOrNew ? { width: 80 } : {}

  const period: number = parseInt(currentCapping.period.replace('d', '').replace('h', ''))
  const periodType: 'h' | 'd' = currentCapping.period.includes('h') ? 'h' : 'd'

  const cappingErrorKey = `capping_${line}_${currentCapping.id}`
  const periodErrorKey = `period_${line}_${currentCapping.id}`

  // ====================== COMPONENT CALLBACKS - manage errors
  const setPeriodError = React.useCallback(
    (t: 'd' | 'h', p: number) => {
      if (t === 'h' && (p > 24 || p < 1))
        setErrors(
          errors.set(periodErrorKey, 'Please provide a time frame between 1 hour and 24 hours.')
        )
      else if (t === 'd' && (p > 31 || p < 1))
        setErrors(
          errors.set(periodErrorKey, 'Please provide a time frame between 1 day and 31 days.')
        )
      else setErrors(errors.delete(periodErrorKey))
    },
    [periodErrorKey, setErrors, errors]
  )

  const setCappingError = React.useCallback(
    (c: number) => {
      if (c < 1) setErrors(errors.set(cappingErrorKey, 'Please provide a capping greater than 0.'))
      else setErrors(errors.delete(cappingErrorKey))
    },
    [setErrors, errors, cappingErrorKey]
  )

  // ====================== COMPONENT CALLBACKS - handle events
  const switchPeriod = React.useCallback(
    (t: 'd' | 'h') => {
      const p = period ? period : ''
      const newPeriod = p + t

      setPeriodError(t, period ? period : 0)

      if (!isNew) {
        dispatch(
          appActions.updatePressureCapping({
            categorieRecord: category,
            cappingRecord: currentCapping,
            capping:
              typeof currentCapping.capping !== 'string'
                ? currentCapping.capping
                : parseInt(currentCapping.capping),
            period: newPeriod,
          })
        )
      } else {
        setNewCapping(newCapping.set('period', newPeriod))
      }
    },
    [dispatch, isNew, category, currentCapping, period, newCapping, setPeriodError]
  )

  const onCappingNumberChange = React.useCallback(
    evt => {
      const val = evt.target.value.replace(/[^0-9]/, '')

      setCappingError(val ? val : 0)

      if (!isNew) {
        dispatch(
          appActions.updatePressureCapping({
            categorieRecord: category,
            cappingRecord: currentCapping,
            capping: val.replace(/^0+/, ''),
            period: currentCapping.period,
          })
        )
      } else {
        setNewCapping(currentCapping.set('capping', val.replace(/^0+/, '')))
      }
    },
    [category, currentCapping, isNew, dispatch, setCappingError]
  )

  const onPeriodChange = React.useCallback(
    evt => {
      const newPeriod = evt.target.value + periodType

      setPeriodError(periodType, evt.target.value ? evt.target.value : 0)

      if (!isNew) {
        dispatch(
          appActions.updatePressureCapping({
            categorieRecord: category,
            cappingRecord: currentCapping,
            capping:
              typeof currentCapping.capping !== 'string'
                ? currentCapping.capping
                : parseInt(currentCapping.capping),
            period: newPeriod.replace(/^0+/, ''),
          })
        )
      } else {
        setNewCapping(currentCapping.set('period', newPeriod.replace(/^0+/, '')))
      }
    },
    [category, currentCapping, isNew, dispatch, periodType, setPeriodError]
  )

  const handleNewRuleClick = React.useCallback(() => {
    if (!errors.has(cappingErrorKey) && !errors.has(periodErrorKey)) {
      dispatch(
        appActions.updatePressureCapping({
          categorieRecord: category,
          cappingRecord: currentCapping,
          capping: currentCapping.capping ? currentCapping.capping : 0,
          period: currentCapping.period,
        })
      )
      setNewCapping(initialCapping)
    }
  }, [errors, cappingErrorKey, periodErrorKey, dispatch, category, currentCapping])

  // ====================== RENDER
  return (
    <CappingRow>
      <FlexLineItem>Send no more than</FlexLineItem>

      <FlexLineItem {...inputWidth}>
        {isEditingOrNew ? (
          <Input
            type="number"
            allowedCharsRegex={/[0-9]/}
            value={currentCapping.capping}
            invalid={!isNew && errors.has(cappingErrorKey)}
            invalidMessage={!isNew ? errors.get(cappingErrorKey) : ''}
            placeholder="2..."
            onChange={onCappingNumberChange}
          />
        ) : (
          <CappingData>{currentCapping.capping}</CappingData>
        )}
      </FlexLineItem>

      <FlexLineItem>push notifications every</FlexLineItem>

      <FlexLineItem {...inputWidth}>
        {isEditingOrNew ? (
          <Input
            type="number"
            placeholder="1..."
            allowedCharsRegex={/[0-9]/}
            value={period ?? undefined}
            invalid={!isNew && errors.has(periodErrorKey)}
            invalidMessage={!isNew ? errors.get(periodErrorKey) : ''}
            onChange={onPeriodChange}
          />
        ) : (
          <CappingData>{period}</CappingData>
        )}
      </FlexLineItem>

      <FlexLineItem>
        {isEditingOrNew ? (
          <TabButton size="small">
            <TabButtonItem isActive={periodType === 'h'} onClick={() => switchPeriod('h')}>
              hours
            </TabButtonItem>
            <TabButtonItem isActive={periodType === 'd'} onClick={() => switchPeriod('d')}>
              days
            </TabButtonItem>
          </TabButton>
        ) : (
          <CappingData>
            {periodType === 'h' ? 'hour' : 'day'}
            {period > 1 && 's'}
          </CappingData>
        )}
      </FlexLineItem>

      <FlexLineItem grow={1}>&nbsp;</FlexLineItem>

      <FlexLineItem>
        {isEditing && !isNew && (
          <Button
            type="button"
            kind="discreet"
            intent="danger"
            style={{ height: 28, width: 28 }}
            onClick={() => {
              if (errors.has(cappingErrorKey)) setErrors(errors.delete(cappingErrorKey))
              if (errors.has(periodErrorKey)) setErrors(errors.delete(periodErrorKey))

              dispatch(
                appActions.removePressureCapping({
                  categorieRecord: category,
                  cappingRecord: currentCapping,
                })
              )
            }}
          >
            <Icon icon="delete" />
          </Button>
        )}
        {isNew && (
          <Tooltip tooltip="Please fill the input(s) to add this rule">
            <div>
              <Button
                type="button"
                kind="inline"
                intent="neutral"
                style={{ height: 28 }}
                onClick={handleNewRuleClick}
                disabled={errors.has(cappingErrorKey) || errors.has(periodErrorKey)}
              >
                Add this rule
              </Button>
            </div>
          </Tooltip>
        )}
      </FlexLineItem>
    </CappingRow>
  )
}

export const PressureRow: React.ComponentType<PressureRowProps> =
  React.memo<PressureRowProps>(PressureLine)
