import { type Map } from 'immutable'

import { type SenderIdentityRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'

import { type EmailMessageRecord } from 'com.batch/message/models/message.records'

export const removeDeletedSenderIdentitiesFromEmailContent = (
  email: Map<string, EmailMessageRecord>,
  senderIdentities: Map<number, SenderIdentityRecord>
): [Array<string>, Map<string, EmailMessageRecord>] => {
  const messageIds: Array<string> = []
  const cleanedEmailMap = email.map((message, messsageTypedId) => {
    const languageMap = message.localizedContent.map(mail => {
      if (!mail.senderIdentityId || senderIdentities.has(mail.senderIdentityId)) {
        return mail
      } else {
        messageIds.push(messsageTypedId)
        return mail.set('senderIdentityId', undefined)
      }
    })
    return message.set('localizedContent', languageMap)
  })
  return [messageIds, cleanedEmailMap]
}
