import { animated } from '@react-spring/web'
import styled from 'styled-components'

type CollapsePanelContainerProps = {
  $isStandalone: boolean
}
export const CollapsePanelContainer = styled(animated.div)<CollapsePanelContainerProps>`
  display: grid;
  margin-top: ${(props: { $isStandalone: boolean }) => (props.$isStandalone ? '0' : '8px')};

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`

export const CollapsePanelContent = styled('div')`
  overflow: hidden;
`
