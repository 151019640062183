import * as React from 'react'

import { Tooltip } from 'components/common/tooltip'

export type AccessNotAllowedProps = {
  accessNotAllowed?: boolean
  accessNotAllowedMsg?: string
}
type TooltipPermissionProps = AccessNotAllowedProps & {
  children: React.ReactNode
}

export const TooltipPermission = ({
  accessNotAllowed = false,
  accessNotAllowedMsg,
  children,
}: TooltipPermissionProps): React.ReactNode => {
  return accessNotAllowed ? (
    <Tooltip tooltip={accessNotAllowedMsg ?? 'This feature requires higher permissions level'}>
      <div style={{ cursor: 'not-allowed' }}>{children}</div>
    </Tooltip>
  ) : (
    children
  )
}
