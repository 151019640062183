import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Hint } from 'components/common/hint'
import { texts } from 'components/styled/tokens'
import { Separator } from 'components/styled/utils'

import { capitalize, kformat, percentage, pluralizeAndKformat } from 'com.batch.common/utils'

import { computeRate } from 'com.batch/orchestration-analytics/infra/parses/compute'
import { STAT_TYPE } from 'com.batch/orchestration-analytics/infra/types/stat-type'
import { type StatsRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { MetricBlock } from 'com.batch/shared/ui/component/metric-block/metric-block'
import { MetricBlockCard } from 'com.batch/shared/ui/component/metric-block/metric-block.styles'
import { Trend, TrendTooltip } from 'com.batch/shared/ui/component/trend/trend'

type Props = {
  stats: StatsRecord | null | undefined
  context: 'automations' | 'campaigns' | 'project'
  token?: string
}
export const EmailKeyMetrics = ({ stats, context, token }: Props): React.ReactElement => {
  const hasTrends = React.useMemo(() => context !== 'campaigns', [context])
  const { dateRange } = useGetAnalyticsFilters(token)

  const nonMachineOpenRate = React.useMemo(() => {
    if (stats) {
      const { open, mpp, delivered } = stats
      if (typeof open?.unique === 'number' && typeof mpp?.unique === 'number')
        return computeRate(open.unique - mpp.unique, delivered.value)
    }
    return 0
  }, [stats])

  return (
    <React.Fragment>
      <MetricBlockCard>
        <MetricBlock
          variant="success"
          label={capitalize(STAT_TYPE.DELIVERED)}
          value={kformat(stats?.delivered.value ?? 0)}
          subtitle={`out of ${kformat(stats?.sent.value ?? 0)} sent`}
          trend={
            dateRange && hasTrends ? (
              <Trend
                rate={stats?.delivered.trend ?? 0}
                tooltip={
                  <TrendTooltip
                    metricContent={
                      <span>
                        <strong>{kformat(stats?.delivered.previousPeriodValue ?? 0)}</strong>{' '}
                        delivered email
                        {stats?.delivered.previousPeriodValue ? '' : 's'}
                      </span>
                    }
                    dateRange={dateRange}
                  />
                }
              />
            ) : undefined
          }
        />
      </MetricBlockCard>
      <MetricBlockCard>
        <Grid gap={0} template="1fr 1px 1fr" alignItems="stretch">
          <MetricBlock
            variant="action"
            label={capitalize(STAT_TYPE.OPENED)}
            value={
              <span>
                {percentage(stats?.open.rate ?? 0, 2, false, false)}
                <span style={texts.metric}>%</span>
              </span>
            }
            subtitle={
              <span>
                {pluralizeAndKformat('unique open', stats?.open.unique ?? 0)}{' '}
                <Hint
                  size={12}
                  icon="help"
                  placement="bottom"
                  style={{ paddingLeft: 0 }}
                  minTooltipWidth={270}
                  arrow={false}
                >
                  <p style={{ textAlign: 'start' }}>
                    Opened excluding machine opens: {percentage(nonMachineOpenRate, 2)}
                    <br />
                    Machine opens: {percentage(stats?.mpp.rate, 2)}
                  </p>
                </Hint>
              </span>
            }
            trend={
              dateRange && hasTrends ? (
                <Trend
                  rate={stats?.open.trend ?? 0}
                  tooltip={
                    <TrendTooltip
                      metricContent={
                        <span>
                          <strong>{percentage(stats?.open.previousPeriodRate ?? 0, 2)}</strong>{' '}
                          opened emails
                        </span>
                      }
                      dateRange={dateRange}
                    />
                  }
                />
              ) : undefined
            }
          />
          <Separator _margin="12px 0 12px 0" fullHeight />
          <MetricBlock
            variant="feature"
            label={capitalize(STAT_TYPE.CLICKED)}
            value={
              <span>
                {percentage(stats?.click.rate ?? 0, 2, false, false)}
                <span style={texts.metric}>%</span>
              </span>
            }
            subtitle={pluralizeAndKformat('unique click', stats?.click.unique ?? 0)}
            hint={<p style={{ whiteSpace: 'nowrap' }}>Excluding Batch unsubscribe link clicks</p>}
            trend={
              dateRange && hasTrends ? (
                <Trend
                  rate={stats?.click.trend ?? 0}
                  tooltip={
                    <TrendTooltip
                      metricContent={
                        <span>
                          <strong>{percentage(stats?.click.previousPeriodRate ?? 0, 2)}</strong>{' '}
                          clicked emails
                        </span>
                      }
                      dateRange={dateRange}
                    />
                  }
                />
              ) : undefined
            }
          />
        </Grid>
      </MetricBlockCard>
      <MetricBlockCard>
        <Grid gap={0} template="1fr 1px 1fr" alignItems="stretch">
          <MetricBlock
            variant="danger"
            label={capitalize(STAT_TYPE.BOUNCED)}
            value={
              <span>
                {percentage(stats?.bounce.rate ?? 0, 2, false, false)}
                <span style={texts.metric}>%</span>
              </span>
            }
            subtitle={`${kformat(stats?.bounce.value ?? 0)} unique bounces`}
            hint={<p style={{ whiteSpace: 'nowrap' }}>Based on emails sent</p>}
            trend={
              dateRange && hasTrends ? (
                <Trend
                  rate={stats?.bounce.trend ?? 0}
                  isGrowthPositive={false}
                  tooltip={
                    <TrendTooltip
                      metricContent={
                        <span>
                          <strong>{percentage(stats?.bounce.previousPeriodRate ?? 0, 2)}</strong>{' '}
                          bounced emails
                        </span>
                      }
                      dateRange={dateRange}
                    />
                  }
                />
              ) : undefined
            }
          />
          <Separator _margin="12px 0 12px 0" fullHeight />
          <MetricBlock
            variant="neutral"
            label={capitalize(STAT_TYPE.UNSUBSCRIBED)}
            value={percentage(stats?.unsubscribe.rate ?? 0, 2)}
            subtitle={`${kformat(stats?.unsubscribe.unique ?? 0)} unique unsub`}
            trend={
              dateRange && hasTrends ? (
                <Trend
                  rate={stats?.unsubscribe.trend ?? 0}
                  isGrowthPositive={false}
                  tooltip={
                    <TrendTooltip
                      metricContent={
                        <span>
                          <strong>
                            {percentage(stats?.unsubscribe.previousPeriodRate ?? 0, 2)}
                          </strong>{' '}
                          unsubscribed emails
                        </span>
                      }
                      dateRange={dateRange}
                    />
                  }
                />
              ) : undefined
            }
          />
        </Grid>
      </MetricBlockCard>
    </React.Fragment>
  )
}
