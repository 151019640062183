import * as React from 'react'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { Button, PermissionButton } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { TableRow } from 'components/common/table'
import { TableCell, TableCellActions } from 'components/common/table/table.styles'
import { Input } from 'components/form'
import { Ellipsis } from 'components/styled/text'

type AllowedDevOriginsEditorProps = {
  value: string
  isLast?: boolean
  isEditing?: boolean
  edit?: () => any
  editClose?: () => any
  deleteLine?: () => any
  editCode?: (arg1: () => any) => any
  save: (localValue: string) => any
}

export const AllowedDevOriginsEditor = ({
  value,
  isEditing,
  edit,
  editClose,
  deleteLine,
  save,
}: AllowedDevOriginsEditorProps): React.ReactElement => {
  const [localValue, updateLocalValue] = React.useState<string>(value)

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()

      save(localValue)
      editClose?.()
    },
    [editClose, localValue, save]
  )

  const onLocalValueChange = React.useCallback(evt => updateLocalValue(evt.target.value.trim()), [])

  const isAllowedToUpdate = useIsCurrentUserAllowedTo(['app', 'push:config:write'])

  return (
    <TableRow as={isEditing ? 'form' : 'div'} onSubmit={handleSubmit} isEditing={isEditing}>
      {isEditing ? (
        <TableCell kind="input">
          <Input autoFocus value={localValue} onChange={onLocalValueChange} />
        </TableCell>
      ) : (
        <TableCell>
          <Ellipsis title={value}>{value}</Ellipsis>
        </TableCell>
      )}

      <TableCellActions>
        {isEditing ? (
          <React.Fragment>
            <Button type="submit" style={{ width: 50 }}>
              Save
            </Button>
            <Button type="button" onClick={editClose}>
              <Icon icon="close" />
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PermissionButton type="button" onClick={edit} isAllowed={isAllowedToUpdate}>
              <Icon icon="edit" />
            </PermissionButton>
            <PermissionButton kind="inline" onClick={deleteLine} isAllowed={isAllowedToUpdate}>
              <Icon icon="delete" />
            </PermissionButton>
          </React.Fragment>
        )}
      </TableCellActions>
    </TableRow>
  )
}
