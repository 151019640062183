import styled from 'styled-components'

import { Button } from 'components/common/button'
import { Ellipsis } from 'components/styled/text'
import * as colors from 'components/styled/tokens/colors'

import { AuthPage, AuthStep, AuthStepFooter, Text } from '../auth.styles'

import { CopyableText } from 'com.batch/shared/ui/component/copy/copyable.styles'

export const LoginPage = styled(AuthPage)`
  display: flex;
  justify-content: center;
  padding: 128px 24px 48px 24px;

  .styled-addon-suf {
    -webkit-text-fill-color: ${colors.textLight};
  }

  ${AuthStep} {
    max-width: 320px;

    ${Text} {
      margin: 6px 0 0 0;
      &:first-of-type {
        margin-top: 12px;
      }
      &:last-of-type {
        margin-bottom: 38px;
      }
    }

    ${AuthStepFooter} {
      margin: 33px 0 0 0;
    }
  }
`

export const LogoutButtonContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 39px;
`

export const AuthError = styled.div`
  ${CopyableText} {
    color: ${colors.textLight};
    ${Ellipsis} {
      white-space: normal;
    }
  }
  ${Button} {
    padding: 0 0 0 6px;
    height: auto;
  }
`
