import styled from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import { colors, schemes } from 'components/styled/tokens'

type AvatarContentProps = {
  url: string
  loader: boolean
  color: string
}
export const AvatarContent = styled.span<AvatarContentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0 0 0.05em;
  mask: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='38' height='38' viewBox='0 0 38 38'%3E%3Cpath d='M19 0a19 19 0 1 0 0 38 19 19 0 1 0 0-38z'/%3E%3C/svg%3E%0A")
    0 0/100% 100%;
  color: ${schemes.lightlucent['100']};
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 1;
  background: ${p =>
    p.url ? `#fff url(${p.url})` : p.loader ? schemes.darklucent['30'] : p.color};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition:
    background-color 0.2s ease,
    background-image 0.2s ease;
`

export const AvatarLoader = styled(Icon).attrs({
  icon: 'spinner',
})`
  color: ${colors.textLight};
`

export const AvatarPlatform = styled(Icon)``
type AvatarContainerProps = {
  size: number
}
export const AvatarContainer = styled.span<AvatarContainerProps>`
  display: inline-block;
  position: relative;
  width: ${p => p.size}px;
  height: ${p => p.size}px;

  ${AvatarContent} {
    font-size: ${p => Math.floor(p.size * 0.4)}px;
  }

  & > & {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate3d(25%, 20%, 0);
  }
`

export const AppAvatarContainer = styled(AvatarContainer)<{
  size: number
}>`
  ${AvatarContent} {
    padding: 0.1em 0.05em 0 0;
    mask: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34'%3E%3Cpath d='M8 0h10c3 0 4 1 4 4 0 5 3 8 8 8 3 0 4 1 4 4v10a8 8 0 0 1-8 8H10.256c-3.567 0-4.86-.371-6.163-1.069a7.27 7.27 0 0 1-3.024-3.024C.37 28.604 0 27.311 0 23.744V8a8 8 0 0 1 8-8z'/%3E%3C/svg%3E%0A")
      0 0/100% 100%;
  }
  ${AvatarPlatform} {
    position: absolute;
    top: -0.1em;
    right: -0.2em;
    font-size: ${(p: { size: number }) => Math.floor(p.size * 0.28)}px;
  }
`
