import styled from 'styled-components'

import { LabelPlaceholder } from 'components/styled/placeholder'
import { schemes, colors } from 'components/styled/tokens'

type PushOverviewMetricValueProps = {
  small?: boolean
}
export const PushOverviewMetricValue = styled(LabelPlaceholder.withComponent('strong')).attrs({
  width: '2.5em',
})<PushOverviewMetricValueProps>`
  display: inline;
  font-size: ${props => (props.small ? 24 : 32)}px;
  font-weight: 300;
  color: ${colors.text};
  letter-spacing: 0.5px;
  padding: 0;
  margin: 0;
`

export const LocationMenu = styled.div`
  display: flex;
  padding: 12px 12px;
  position: relative;

  button {
    margin-right: 8px;
    position: relative;
    z-index: 12;
  }
  button i {
    display: block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 1px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 1px;
    height: calc(100% + 40px);
    content: '';
    z-index: 11;
    background: linear-gradient(
      180deg,
      ${schemes.grayscale['02']} 0%,
      ${schemes.grayscale['02']} 40%,
      ${schemes.darklucent['00']} 100%
    );
  }
`

export const FlagImage = styled.img`
  margin: -2px 10px 0 0;
`
