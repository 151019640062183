import styled from 'styled-components'

import {
  BoxSectionContainer,
  BoxTitle,
  BoxContainer,
  BoxBody,
} from 'components/common/box/box.styles'
import { colors } from 'components/styled/tokens'

export const TargetingWrapperTrigger = styled.div`
  padding: 8px 0px;
  > ${BoxSectionContainer} {
    border-top: none;
    border-bottom: none;
    padding: 0;
  }
  .audience__title {
    color: ${colors.text};
    text-transform: capitalize;
    padding: 28px 20px 0 20px;
    margin: 0px 0px;
    border-top: 1px solid ${colors.stroke};
    font-size: 1em;
    font-weight: 500;
    line-height: 16px;
  }
  .audience__reach {
    padding: 0 20px;
  }
  ${BoxTitle} {
    margin: 0px;
    font-size: 1em;
    font-weight: 500;
    line-height: 16px;
  }
`
export const TargetingWrapperCampaign = styled.div`
  > ${BoxContainer} > ${BoxBody} {
    & > ${BoxSectionContainer} {
      margin: 0;
      border: none;
      padding: 0;
    }
  }
`
