import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { ContentOption } from 'components/campaign/form/content-option'
import { LandingSwitch } from 'components/campaign/landing-switch'
import {
  Box,
  BoxBody,
  BoxHeader,
  BoxSection,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { LegacyLangPicker } from 'components/project/campaign/common/legacy-lang-picker/legacy-lang-picker'

import { ContentInApp } from './content-inapp'
import { ContentPush } from './content-push'

import {
  type AppRecord,
  type CampaignRecord,
  LanguageFactory,
  type SdkSupportsRecord,
} from 'com.batch.redux/_records'
import {
  addTranslation,
  removeTranslation,
  setActiveTranslation,
  setCampaignLanding,
  togglePushAbTesting,
} from 'com.batch.redux/campaign.action'
import {
  activeLanguageSelector,
  activeVariantIdSelector,
  pickableLanguagesSelector,
  pickedLanguagesSelector,
} from 'com.batch.redux/campaign.selector'
import { invalidLangIdsSelector } from 'com.batch.redux/targeting.selector.composed'
import { type ThemeRecord } from 'com.batch.redux/theme.records'

type Props = {
  sdkSupports: SdkSupportsRecord
  campaign: CampaignRecord
  themes: List<ThemeRecord>
  app: AppRecord
}

const defaultLanguage = LanguageFactory({ value: 'default' })

export const Content = ({ sdkSupports, campaign, themes, app }: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const activeLanguage = useSelector(activeLanguageSelector)
  const invalidLangIds = useSelector(invalidLangIdsSelector)
  const pickableLanguages = useSelector(pickableLanguagesSelector)
  const previewedVariant = useSelector(activeVariantIdSelector)
  const selectedLanguages = useSelector(pickedLanguagesSelector)
  const [showLanding, setShowLanding] = React.useState(campaign.type === 'in-app')
  // not really a state, we just need to init once
  const [abTestingInitiallyEnabled] = React.useState(campaign.abtesting.enabled)
  const onToggleViewMode = React.useCallback((mode: 'push' | 'landing') => {
    setShowLanding(mode === 'landing')
  }, [])
  const onToggleLandingActive = React.useCallback(() => {
    dispatch(setCampaignLanding(!campaign.hasLanding))
    if (campaign.hasLanding && showLanding) {
      setShowLanding(false)
    }
    if (!campaign.hasLanding && !showLanding) {
      setShowLanding(true)
    }
  }, [campaign.hasLanding, dispatch, showLanding])
  const onAddTranslation = React.useCallback(
    lang => {
      dispatch(addTranslation(lang))
    },
    [dispatch]
  )
  const onRemoveTranslation = React.useCallback(
    lang => {
      dispatch(removeTranslation(lang))
    },
    [dispatch]
  )
  const onSetActiveTranslation = React.useCallback(
    lang => {
      dispatch(setActiveTranslation(lang))
    },
    [dispatch]
  )
  const onTogglePushABTesting = React.useCallback(
    (b: boolean) => {
      dispatch(togglePushAbTesting(b))
    },
    [dispatch]
  )
  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle title="Message" />
        <HeaderBoxActions>
          <ContentOption
            hasAbTesting={campaign.abtesting.enabled}
            abTestingInitiallyEnabled={abTestingInitiallyEnabled}
            type={campaign.type}
            togglePushAbTesting={onTogglePushABTesting}
            valid={!invalidLangIds.has(activeLanguage?.value ?? 'default')}
            campaignId={campaign.id ? campaign.id : null}
          />
        </HeaderBoxActions>
      </BoxHeader>
      <BoxBody>
        <React.Fragment>
          <BoxSection style={{ padding: '8px 10px 0 10px' }}>
            <LegacyLangPicker
              pickableLanguages={pickableLanguages}
              pickedLanguages={selectedLanguages}
              activeTranslation={activeLanguage}
              validLanguagesId={Immutable.Set(
                selectedLanguages.map(l => l.value).filter(lid => !invalidLangIds.includes(lid))
              )}
              addTranslation={onAddTranslation}
              removeTranslation={onRemoveTranslation}
              setActiveTranslation={onSetActiveTranslation}
              defaultLanguageId={app?.defaultLanguageId}
            />
          </BoxSection>
          {campaign.type === 'push' && (app.platform === 'ios' || app.platform === 'android') && (
            <LandingSwitch
              app={app}
              toggleMode={onToggleViewMode}
              hasThemes={typeof themes !== 'undefined' && themes.size > 0}
              hasLandingFeature={app.features.has('inapp-messaging')}
              toggleLandingActive={onToggleLandingActive}
              isLandingVisible={showLanding}
              isLandingActive={campaign.hasLanding}
            />
          )}
          {showLanding === false && campaign.type === 'push' && (
            <ContentPush
              abtesting={campaign.abtesting}
              app={app}
              lang={activeLanguage ?? defaultLanguage}
              campaignHasLanding={campaign.hasLanding}
              previewedVariant={previewedVariant}
            />
          )}
          {(showLanding || campaign.type === 'in-app') && (
            <ContentInApp
              app={app}
              abtesting={campaign.abtesting}
              lang={activeLanguage ?? defaultLanguage}
              sdkSupports={sdkSupports}
              isInApp={campaign.type === 'in-app'}
            />
          )}
        </React.Fragment>
      </BoxBody>
    </Box>
  )
}
