import styled from 'styled-components'

import { colors } from 'components/styled/tokens'

type FormattingBtnProps = {
  active?: boolean
}
export const FormattingBtn = styled.button<FormattingBtnProps>`
  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-left: -1px;
    border-left: 1px solid ${colors.stroke};
  }
  :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: none;
    margin-right: -1px;
    border-right: 1px solid ${colors.stroke};
  }
  :only-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  margin-bottom: ${props => (props.active ? '-1px' : 'inherit')};
  border: none;
  border-right: 1px solid ${colors.stroke};
  font-size: 13px;
  outline: none;
  cursor: pointer;
  display: inline-block;
  background: ${props => (props.active ? colors.opacifyAction : colors.fillBackground)};
  box-shadow: ${props =>
    props.active
      ? `0px 1px 3px rgba(0, 0, 0, 0.04), inset 0px -2px 0px ${colors.fillAction}`
      : 'none'};
  display: inline-flex;
  flex-direction: column;
  flex: 1;
  padding: 15px 5px;
  align-items: stretch;
  max-width: 220px;
  span {
    text-align: center;
    padding: 3px 0px;
    color: ${props => (props.active ? colors.text : colors.text)};
    :first-child {
      font-weight: 500;
      padding: 3px 0px 0 0px;
    }
    :last-child {
      font-size: 12px;
      color: ${colors.textLight};
    }
  }
`

export const FormattingToolbar = styled.div`
  display: flex;
  border: 1px solid ${colors.stroke};
  border-radius: 4px;
  background: ${colors.fillBackground};
`
type PlaceholderProps = {
  height: number
  padding?: number
}
export const Placeholder = styled.div<PlaceholderProps>`
  height: ${props => props.height}px;
  padding: ${props => (props.padding ? props.padding + 'px' : 'inherit')};
  color: ${colors.textLight};
  text-align: center;
  background: ${colors.fillBackground};
  font-weight: normal;
  border-radius: 4px;
`
