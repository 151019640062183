import * as React from 'react'

import { Alert } from 'components/common/alert'
import Highlight from 'components/common/highlight'

type SetupReactNativeProps = {
  apiKey: string
  platform: Platforms
  hasDevApiKeyFeature: boolean
}
const SetupReactNative = ({
  apiKey,
  platform,
  hasDevApiKeyFeature,
}: SetupReactNativeProps): React.ReactElement => {
  return (
    <React.Fragment>
      <Alert icon="help" kind="info">
        Note: Batch doesn't work with Expo, as it depends on native components which are not
        supported by Expo. Please use standard React Native, or eject to the bare workflow.
      </Alert>

      <i>This guide assumes that you've already followed the iOS and Android prerequisites.</i>

      <h3>Integrating the SDK</h3>
      <p>Install the plugin, using yarn:</p>
      <Highlight language="bash">yarn add @batch.com/react-native-batch</Highlight>

      <h5>React Native {'>'}= 0.60.0</h5>
      <p>
        Recent versions of React Native automatically link native libraries. <br />
        Installing the pods is still required:
      </p>
      <Highlight language="bash">
        {`cd ios 
pod install
`}
      </Highlight>

      <p>You should see the following logs:</p>
      <Highlight language="swift">
        {`Auto-linking React Native module for target 'Sample': RNBatchPush
Analyzing dependencies
Downloading dependencies
Installing Batch (1.19.0)
Installing RNBatchPush (8.1.2)`}
      </Highlight>

      <h5>{'React Native < 0.60.0'}</h5>
      <p>Older versions of React Native do not support auto linking, it must be done manually:</p>
      <ul>
        <li>First, link the library:</li>
      </ul>

      <Highlight language="bash">react-native link @batch.com/react-native-plugin</Highlight>

      <ul>
        <li>
          <strong>cd</strong> into the <strong>ios</strong> folder
        </li>
        <li>
          Edit <strong>Podfile</strong>:
        </li>

        <li style={{ marginLeft: '3rem' }}>
          Find the line that begins with <strong>pod 'RNBatchPush'</strong> and delete it (it might
          not exist)
        </li>
        <li style={{ marginLeft: '3rem' }}>Add the following line:</li>
      </ul>

      <Highlight language="bash">
        pod 'RNBatchPush', :path ={'>'} '../node_modules/@batch.com/react-native-batch/iOS'`
      </Highlight>

      <ul>
        <li>Install the pods</li>
      </ul>

      <Highlight language="swift">cd ios pod install</Highlight>

      <h3>Setting up your APIKey</h3>

      {platform === 'ios' && (
        <React.Fragment>
          <ul>
            <li>
              On iOS, look for your <strong>Info.plist</strong> and add the following:
            </li>
          </ul>
          <Highlight language="xml">{`<key>BatchAPIKey</key>
<string>${apiKey}</string>
`}</Highlight>
        </React.Fragment>
      )}

      {platform === 'android' && (
        <React.Fragment>
          <ul>
            <li>
              On Android, open <strong>android/app/build.gradle</strong> and add:
            </li>
          </ul>
          <Highlight language="gradle">{`defaultConfig {
    ...
    resValue "string", "BATCH_API_KEY", "${apiKey}"
}`}</Highlight>
        </React.Fragment>
      )}
      {hasDevApiKeyFeature ? (
        <React.Fragment>
          <p>
            <strong>{apiKey}</strong> is your Batch Dev or Live API key. You'll find the API keys
            needed to set up the SDK in Settings → General:{' '}
          </p>
          <ul>
            <li>
              <strong>Dev API key</strong>: Use it for development or testing purposes. This API key
              won't trigger stats computation. Please take care not to ship an app on a Store with
              this API key.
            </li>
            <li>
              <strong>Live API key</strong>: Should be used in a production environment only and in
              the final version of your app on the Store.
            </li>
          </ul>
          <p>
            If you created different apps on the Batch dashboard for testing purposes, you can use
            the Live Api Key.
          </p>
        </React.Fragment>
      ) : (
        <p>
          <strong>{apiKey}</strong> is your Batch API key. You'll find the API key needed to set up
          the SDK in Settings → General:{' '}
        </p>
      )}

      {platform === 'android' && (
        <React.Fragment>
          <h4>Install Batch dependencies</h4>
          <p>Setup the required dependencies in Gradle scripts:</p>
          <Highlight language="gradle">
            {`// android/build.gradle

buildscript {
    ...
    ext {
        batchSdkVersion = '1.19+'
    }
    dependencies {
        ...
        classpath 'com.google.gms:google-services:4.3.4'
    }
}
`}
          </Highlight>

          <Highlight language="gradle">
            {`// android/app/build.gradle
dependencies {
    implementation platform('com.google.firebase:firebase-bom:25.12.0') // needed if you don't have @react-native-firebase/app
    implementation "com.google.firebase:firebase-messaging" // needed if you don't have @react-native-firebase/messaging
    implementation "com.batch.android:batch-sdk: rootProject.ext.batchSdkVersion"
    ...
}
apply plugin: 'com.google.gms.google-services'
`}
          </Highlight>

          <h4>Configure auto-linking</h4>

          <p>
            Create or adapt the <strong>react-native.config.js</strong> file at the root of your
            project:
          </p>

          <Highlight language="javascript">
            {`// react-native.config.js

module.exports = {
  dependencies: {
    '@batch.com/react-native-batch': {
      platforms: {
        android: {
          packageInstance: 'new RNBatchPackage(this.getApplication())',
        },
      },
    },
  },
};
`}
          </Highlight>

          <h4>Add your Firebase config</h4>

          <p>
            Add the <i>google-services.json</i> file to <strong>/android/app</strong>
          </p>

          <h4>Configure onNewIntent</h4>

          <p>
            Add the following in your <strong>MainActivity.java</strong>:
          </p>

          <Highlight language="java">
            {`// import android.content.Intent;
// import com.batch.android.Batch;

@Override
public void onNewIntent(Intent intent)
{
    Batch.onNewIntent(this, intent);
    super.onNewIntent(intent);
}
`}
          </Highlight>

          <h4>Small push notification icon</h4>

          <p>
            For better results on Android 5.0 and higher, it is recommended to add a Small Icon and
            Notification Color. An icon can be generated using Android Studio's asset generator: as
            it will be tinted and masked by the system, only the alpha channel matters and will
            define the shape displayed. It should be of 24x24dp size. If your notifications shows up
            in the system statusbar in a white shape, this is what you need to configure.
          </p>

          <p>This can be configured in the manifest as metadata in the application tag:</p>

          <Highlight language="android">
            {`<!-- Assuming there is a push_icon.png in your res/drawable-{dpi} folder -->

<manifest ...>
    <application ...>
        <meta-data
            android:name="com.batch.android.push.smallicon"
            android:resource="@drawable/push_icon" />

        <!-- Notification color. ARGB but the alpha value can only be FF -->
        <meta-data
            android:name="com.batch.android.push.color"
            android:value="#FF00FF00" />
`}
          </Highlight>
        </React.Fragment>
      )}

      {platform === 'ios' && (
        <React.Fragment>
          <h4>Enable Push Capabilities</h4>
          <p>
            Open the <strong>xcworkspace</strong> in the <strong>ios/</strong> folder. In the
            project window:
          </p>

          <ul>
            <li>Select your project in the sidebar</li>
            <li>
              Go to <strong>Signing & Capabilities</strong>
            </li>
            <li>
              Press on <strong>+ Capability</strong>
            </li>
            <li>
              Add <strong>Push Notifications</strong>
            </li>
          </ul>

          <h4>Start the SDK natively</h4>

          <p>
            Starting with 5.2.1, due to how iOS' app lifecycle works, Batch must be started
            natively.
          </p>

          <p>Add the following to your application delegate:</p>

          <Highlight language="swift">
            {`#import <RNBatchPush/RNBatch.h>
// or, use #import "RNBatch.h" if the framework import didn't work

- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions
{
    ...
    [RNBatch start];
    ...
    return YES;
}
`}
          </Highlight>

          <h4>Setup your UNUserNotificationCenterDelegate</h4>

          <ul>
            <li>
              If you use Firebase or another framework swizzling your AppDelegate, follow the{' '}
              <a href="https://doc.batch.com/ios/advanced/manual-integration" target="_blank">
                manual integration
              </a>{' '}
              guide.
            </li>
            <li>
              Otherwise, add the following in your <strong>AppDelegate.m</strong>:
            </li>
          </ul>
          <Highlight language="swift">
            {`- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions
{
    ...
    [RNBatch start];
    [BatchUNUserNotificationCenterDelegate registerAsDelegate];
    //[BatchUNUserNotificationCenterDelegate sharedInstance].showForegroundNotifications = true;
    ...
    return YES;
}
`}
          </Highlight>
          <p>
            If you want to show foreground notifications, add the{' '}
            <a
              href="https://doc.batch.com/ios/advanced/customizing-notifications#showing-foreground-notifications-ios-10-only"
              target="_blank"
            >
              {' '}
              relevant configuration
            </a>
            :
            <strong>
              [BatchUNUserNotificationCenterDelegate sharedInstance].showForegroundNotifications =
              true;
            </strong>{' '}
            after registering the delegate .
          </p>

          <h4>Enable push notifications</h4>
          <p>
            Add the following in your app code, ideally the first view a user sees when opening the
            app:
          </p>
          <Highlight language="javascript">
            {`import { BatchPush } from '@batch.com/react-native-batch';

// This method triggers iOS' notification authorization popup
// If you want to ask for notifications at a later date, don't execute this on the first run
// but make sure you call this on every app start after asking for the permission,
// so that Batch always gets the latest push token, preventing delivery issues.
BatchPush.registerForRemoteNotifications();
`}
          </Highlight>
        </React.Fragment>
      )}
      <h4>What's next</h4>

      <p>Here are a couple of extra steps you need to take before releasing your app:</p>

      {platform === 'android' && (
        <ul>
          <li>
            Mobile Landings: Make sure{' '}
            <a href="https://doc.batch.com/react-native/messaging" target="_blank">
              Mobile Landings
            </a>{' '}
            are set up correctly.
          </li>
          <li>
            Custom user identifier: Add support for{' '}
            <a href="https://doc.batch.com/react-native/custom-data/customid" target="_blank">
              custom user ID
            </a>{' '}
            if you are planning to use the{' '}
            <a href="https://doc.batch.com/api/transactional/send" target="_blank">
              Transactional
            </a>{' '}
            and{' '}
            <a href="https://doc.batch.com/api/custom-data-api/set-update" target="_blank">
              Custom Data APIs
            </a>
            .
          </li>
          <li>
            Batch HMS plug-in: If you want to set up a Huawei (HMS) integration, please follow this{' '}
            <a href="https://doc.batch.com/react-native/huawei" target="_blank">
              documentation
            </a>
            .
          </li>
          <li>
            Analytics:{' '}
            <a href="https://doc.batch.com/android/advanced/event-dispatchers">
              Add an event dispatcher
            </a>{' '}
            to automatically track your campaigns in your third-party analytics tool.
          </li>
        </ul>
      )}

      {platform === 'ios' && (
        <ul>
          <li>
            Rich notifications: Add support for{' '}
            <a
              href="https://doc.batch.com/ios/sdk-integration/rich-notifications-setup"
              target="_blank"
            >
              iOS 10 rich push notifications
            </a>
            .
          </li>
          <li>
            Mobile Landings: Make sure{' '}
            <a href="https://doc.batch.com/react-native/messaging" target="_blank">
              Mobile Landings
            </a>{' '}
            are set up correctly.
          </li>
          <li>
            Custom user identifier: Add support for{' '}
            <a href="https://doc.batch.com/react-native/custom-data/customid" target="_blank">
              custom user ID
            </a>{' '}
            if you are planning to use the{' '}
            <a href="https://doc.batch.com/api/transactional/send" target="_blank">
              Transactional
            </a>{' '}
            and{' '}
            <a href="https://doc.batch.com/api/custom-data-api/set-update" target="_blank">
              Custom Data APIs
            </a>
            .
          </li>
          <li>
            Analytics:{' '}
            <a href="https://doc.batch.com/ios/advanced/event-dispatchers">
              Add an event dispatcher
            </a>{' '}
            to automatically track your campaigns in your third-party analytics tool.
          </li>
        </ul>
      )}
    </React.Fragment>
  )
}

export default SetupReactNative
