import styled, { keyframes, type Keyframes } from 'styled-components'

import { FliperContainer } from 'components/common/fliper'
import { colors, schemes } from 'components/styled/tokens'

import { AuthStep, AuthStepFooter, AuthPage } from '../auth.styles'

export const RegisterBlockEntering: Keyframes = keyframes`
  0%{ opacity: 0; transform: scale(0.9); }
  100%{ opacity: 1; transform: scale(1); }
`

export const RegisterLink = styled.a``

export const RegisterProgress = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  user-select: none;
  transition: opacity 0.2s ease;

  ${FliperContainer} {
    font-size: 1em;
    font-family: 'SF Mono', 'Menlo', monospace;
    color: ${colors.text};
    font-weight: 500;
    text-align: center;
    width: 1.2em;

    span {
      width: 100%;
    }
  }
  hr {
    width: 1.2em;
    height: 1px;
    margin: 0.35em 0;
    padding: 0;
    background-color: ${colors.stroke};
    border-top: none;

    + ${FliperContainer} {
      color: ${colors.textDisabled};
    }
  }
`

export const RegisterSuccessLabel = styled.span`
  display: inline-flex;
  padding: 8px 10px 9px 10px;
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  background: linear-gradient(-4deg, #7cb351, #93c36f);
  box-shadow: 0 1px 3px ${schemes.darklucent['01']};
  border-radius: 5px;
  user-select: none;
`

export const RegisterBlock = styled.div`
  position: relative;
  padding: 22px 20px 25px 20px;
  border-radius: 8px;
  background-color: ${(p: { active?: boolean }) =>
    p.active ? schemes.darklucent['10'] : 'transparent'};
  animation: ${RegisterBlockEntering} 0.3s ease;
  animation-fill-mode: both;

  h3 {
    margin: 0 0 10px 0;
    font-size: 15px;
    font-weight: 600;
    color: ${colors.text};
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    color: ${colors.textNeutral};
  }
  &:after {
    position: absolute;
    top: -9px;
    left: 18px;
    width: 94px;
    content: '';
    border-bottom: 1px solid ${colors.stroke};
  }
`

export const RegisterStepFooter = styled(AuthStepFooter)``

export const RegisterStep = styled(AuthStep)`
  ${RegisterBlock} {
    margin: 0 -20px;

    &:first-of-type {
      margin: 28px -20px 0 -20px;

      &:after {
        display: none;
      }
    }
    &:nth-of-type(2) {
      animation-delay: 0.075s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.15s;
    }

    & + ${RegisterBlock} {
      margin: 18px -20px 0 -20px;
    }
  }
`

type RegisterWizardContainerProps = {
  noProgress: boolean
  currentStep: number
  total: number
}

export const RegisterWizardContainer = styled(AuthPage)<RegisterWizardContainerProps>`
  justify-content: center;
  align-items: flex-start;
  padding: 118px 28px;

  ${RegisterStep} {
    width: 100%;
    max-width: 420px;
  }
  ${RegisterProgress} {
    position: absolute;
    left: calc(50% - 248px);
    top: 143px;
    transform: translate3d(-100%, -50%, 0);
  }

  &:after {
    display: ${(p: RegisterWizardContainerProps) => (p.noProgress ? 'none' : 'block')};
    position: fixed;
    top: 0;
    left: 0;
    width: ${(p: RegisterWizardContainerProps) => (p.currentStep + 1) * (100 / p.total)}%;
    height: 2px;
    content: '';
    background-color: ${colors.text};
    transition: width 0.5s ease;
  }
`

export const RegisterLogOut = styled.div`
  position: absolute;
  top: 30px;
  right: 40px;

  .styled-button-prefix,
  .styled-button-prefix > i {
    width: 16px;
  }
`
