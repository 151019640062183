import styled from 'styled-components'

import { colors } from 'components/styled/tokens'

export const MessageActions = styled.div`
  display: flex;
  align-items: center;

  hr {
    height: 22px;
    margin: 0 8px;
    border-right: 1px solid ${colors.stroke};
  }
`
export const MessageLanguages = styled.div`
  min-height: 48px;
  padding: 10px 0 0 18px;
  background-color: ${colors.fill};
`
