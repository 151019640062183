import * as React from 'react'
import { ThemeContext } from 'styled-components'

import { EmptyField } from 'components/common/empty-states'
import { TrackingContext, trackEvent } from 'components/common/page-tracker'
import { Icon } from 'components/common/svg-icon'

import { PagerNav, EmptyPager } from './pager.styles'

type PagerProps = {
  size?: 'normal' | 'small'
  total: number
  page: number
  nbPerPage?: number
  selectPage: (page: number) => any
  forceEmpty?: boolean
  forceLoading?: boolean
  style?: {
    [key: string]: string | number
  }
}

type PageProps = {
  id: number
  active: boolean
  handler: () => any
}

const PagerRaw = ({
  size = 'normal',
  total,
  page,
  nbPerPage = 10,
  selectPage,
  forceEmpty = false,
  forceLoading = false,
  style,
}: PagerProps): React.ReactElement => {
  const { pagerEventCode, appId, campaignType } = React.useContext(TrackingContext)

  const nbPages = React.useMemo(() => Math.ceil(total / nbPerPage), [nbPerPage, total])

  const trackPagerEvent = React.useCallback(
    (change: 'direct' | 'next' | 'prev', targetPage: number) => {
      const params: {
        change_type: 'direct' | 'next' | 'prev'
        previous_page: number
        next_page: number
        total_pages: number
        app_id?: number
        campaign_type?: 'push' | 'in-app'
      } = {
        change_type: change,
        previous_page: page,
        total_pages: nbPages,
        next_page: targetPage,
      }
      if (appId) params.app_id = appId
      if (campaignType) params.campaign_type = campaignType

      if (pagerEventCode !== 'unset') trackEvent(pagerEventCode, params)
    },
    [appId, campaignType, page, pagerEventCode, nbPages]
  )

  const theme = React.useContext(ThemeContext)
  const isEmpty = (!!theme && theme.isEmpty) || forceEmpty
  const isLoading = !!theme && theme.isLoading

  const {
    pages,
    order,
  }: {
    pages: Array<PageProps>
    order: 'in' | 'out' | 'none'
  } = React.useMemo(() => {
    const pages: Array<PageProps> = []
    const nbPagesMax = 9
    const breakPoint = 4
    let order: 'in' | 'out' | 'none' = 'none'

    if (size === 'normal') {
      if (nbPages <= nbPagesMax || page < breakPoint || page > nbPages - breakPoint + 1) {
        const left = breakPoint + 1
        const right = nbPages - breakPoint
        order = nbPages <= nbPagesMax ? 'none' : 'out'

        for (let i = 0; i < nbPages; i++) {
          const currentPage = i + 1

          if (nbPages <= nbPagesMax || currentPage < left || currentPage > right) {
            pages.push({
              id: currentPage,
              active: currentPage === page,
              handler: () => {
                trackPagerEvent('direct', currentPage)

                selectPage(currentPage)
              },
            })
          }
        }
      } else {
        order = 'in'

        for (let e = 0; e < nbPages; e++) {
          const currentPage = e + 1

          if (
            currentPage === 1 ||
            (currentPage >= page - 2 && currentPage <= page + 2) ||
            currentPage === nbPages
          ) {
            pages.push({
              id: currentPage,
              active: currentPage === page,
              handler: () => {
                trackPagerEvent('direct', currentPage)
                selectPage(currentPage)
              },
            })
          }
        }
      }
    }
    return { pages, order }
  }, [nbPages, page, selectPage, size, trackPagerEvent])
  const onPrev = React.useCallback(() => {
    trackPagerEvent('prev', page !== 1 ? page - 1 : page)
    selectPage(page !== 1 ? page - 1 : page)
  }, [page, selectPage, trackPagerEvent])
  const onNext = React.useCallback(() => {
    trackPagerEvent('next', page !== nbPages ? page + 1 : page)
    selectPage(page !== nbPages ? page + 1 : page)
  }, [nbPages, page, selectPage, trackPagerEvent])
  return (
    <PagerNav style={style} isEmpty={isEmpty} isLoading={isLoading || forceLoading}>
      <li>
        <button
          className={`styled-icon-pagnation ${page === 1 ? 'styled-disabled-pagination' : ''}`}
          onClick={onPrev}
        >
          <Icon icon="chevron-left" size={13} />
        </button>
      </li>
      {size === 'normal' ? (
        isEmpty || forceLoading || (isLoading && pages.length === 0) ? (
          <EmptyPager _theme={isLoading || forceLoading ? 'loading' : 'empty'} />
        ) : (
          pages.map((p, i) => {
            return (
              <React.Fragment key={i}>
                <li key={p.id} className={p.active ? 'active' : ''}>
                  <button onClick={p.handler}>{p.id}</button>
                </li>
                {((order === 'out' && i === 3) ||
                  (order === 'in' && (i === 0 || i === pages.length - 2))) && (
                  <li className="styled-dots-pagintation">...</li>
                )}
              </React.Fragment>
            )
          })
        )
      ) : (
        <li className="styled-small-pagination">
          {isEmpty || forceLoading || (isLoading && pages.length === 0) ? (
            <React.Fragment>
              <EmptyField _width={15} _height={14} />
              <EmptyField _width={15} _height={14} style={{ margin: '0 12px' }} />
              <EmptyField _width={15} _height={14} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {page} of {nbPages}
            </React.Fragment>
          )}
        </li>
      )}
      <li>
        <button
          className={`styled-icon-pagnation ${page >= nbPages ? 'styled-disabled-pagination' : ''}`}
          onClick={onNext}
        >
          <Icon icon="chevron-right" size={13} />
        </button>
      </li>
    </PagerNav>
  )
}

export const Pager: React.ComponentType<PagerProps> = React.memo<PagerProps>(PagerRaw)
