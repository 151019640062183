import Immutable from 'immutable'

import { type ProviderModel } from 'com.batch.redux/adapters/project/models/esp-configuration'
import {
  EspConfigurationFactory,
  type EspConfigurationRecord,
  EspDomainFactory,
} from 'com.batch.redux/corelogic/records/esp-configuration.records'

export const parseEspConfigurationModelToRecords = (raw: ProviderModel): EspConfigurationRecord => {
  const domainsToRecord = raw.sparkpost.domains.map(domain =>
    EspDomainFactory({
      sendingDomain: domain.sendingDomain,
      bounceDomain: domain.bounceDomain,
      transmissionTypes: Immutable.Set(domain.transmissionTypes),
    })
  )

  return EspConfigurationFactory({
    subAccountId: raw.sparkpost.subaccountID,
    domains: Immutable.List(domainsToRecord),
  })
}
