import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'
import { useRoutes } from 'react-router-dom'

import { AppNew } from 'components/app/create'
import { PageTracker, trackingCategories, trackingPages } from 'components/common/page-tracker'

import { currentCompanySelector } from 'com.batch.redux/company'

export const CreateAppRoute = (): React.ReactElement | null => {
  const company = useSelector(currentCompanySelector)
  const element = useRoutes([
    {
      path: '/:companyId/apps/new',
      element: <AppNew step={1} company={company} />,
    },
    { path: '/:companyId/apps/new/:kind/url', element: <AppNew step={2} company={company} /> },
    { path: '/:companyId/apps/new/:kind/', element: <AppNew step={3} company={company} /> },
    {
      path: '/:companyId/apps/new/:kind/:appId/team',
      element: <AppNew step={4} company={company} />,
    },
    { path: '*', element: null },
  ])

  return element ? (
    <PageTracker category={trackingCategories.appSettings} name={trackingPages.new}>
      {element}
    </PageTracker>
  ) : null
}
