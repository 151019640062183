/* eslint-disable react/jsx-no-bind */
import * as React from 'react'

import { WrappedValidatedInput } from 'components/campaign/form/validated-input'
import { Choice } from 'components/common/choice-field'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import Hint from 'components/common/hint'
import { Checkbox, MediaUrl } from 'components/form'

import validators from 'com.batch.common/form.validators'

import { type AppRecord } from 'com.batch.redux/_records'
import { type uploadError } from 'com.batch.redux/campaign.action'
import { type updatePushSettings } from 'com.batch.redux/content'
import { type PushSettingsRecord } from 'com.batch.redux/content.records'

const payloadValidator = [validators.json]

type ContentPushSettingsProps = {
  app: AppRecord
  settings: PushSettingsRecord
  updatePushSettings: typeof updatePushSettings
  uploadError: typeof uploadError
}

const Priority = [
  {
    value: 'NORMAL',
    label: 'NORMAL',
  },
  {
    value: 'HIGH',
    label: 'HIGH',
  },
]

export const ContentPushSettings = ({
  app,
  settings,
  updatePushSettings,
}: ContentPushSettingsProps): React.ReactElement => {
  const platform = app.platform
  const iOS = platform === 'ios'
  const android = platform === 'android'
  const web = platform === 'webpush'

  const defaultValue = React.useMemo(
    () => (!settings.collapseKey || settings.collapseKey === '' ? 'default' : settings.collapseKey),
    [settings.collapseKey]
  )

  return (
    <FlexLine separator top>
      <FlexLineItem container grow={1} style={{ flexBasis: '55%' }}>
        {/* ICON =========================================== */}
        {(android || web) && (
          <div style={{ marginBottom: 32 }}>
            <MediaUrl
              onChange={media => {
                updatePushSettings('iconUrl', media.mediaUrl)
              }}
              value={{ mediaKind: 'image', mediaUrl: settings.iconUrl || '' }}
              label="Custom icon"
              platforms={[app.platform]}
              allowedMedia={['image']}
              showTemplateWizard={app.features.has('macro')}
              imageMinWidth={192}
              imageRequiredRatio={1}
              imageRecommandation={{
                message: 'Image must be square and at least 192px wide',
                docLinkText: 'Learn more',
                docLinkUrl:
                  app.platform === 'webpush'
                    ? 'https://doc.batch.com/web/sdk-integration/initial-setup#default-icon'
                    : 'https://doc.batch.com/android/advanced/customizing-notifications#setting-up-custom-push-icons',
              }}
            />
          </div>
        )}
        {/* PRIORITY ============================================== */}
        {(iOS || android) && (
          <Choice
            label={iOS ? 'APNS Priority' : 'GCM priority'}
            value={settings.priority}
            onChange={value => updatePushSettings('priority', value)}
            hint={
              iOS
                ? 'Default is high. Normal priority send the push message at a time that takes into account power considerations for the device. Notifications with this priority might be grouped and delivered in bursts. They are throttled, and in some cases are not delivered.'
                : "By default, Batch will use high priority to avoid delivery issues due to native (Doze) or constructor related (Samsung Smart Manager, etc) energy saving features. High priority Android notifications can drain your user's battery faster since they 'wake up' the device and open a network connection. Switch to Normal priority if your notifications are not time-sentitive."
            }
            choices={Priority}
          />
        )}
        {/* TTL ======================================================= */}
        <div className="form-group">
          <label htmlFor="expiration">
            Expiration (TTL)
            <Hint minTooltipWidth={240}>
              Set notification to expire after a given time period. If it was not delivered after
              this period, it will never be.
            </Hint>
          </label>
          <FlexLine>
            <FlexLineItem width={30}>
              <Checkbox
                onChange={() => {
                  updatePushSettings('hasExpiration', !settings.hasExpiration)
                }}
                name="hasExpiration"
                checked={settings.hasExpiration}
                id="expiration"
              />
            </FlexLineItem>
            <FlexLineItem width={100}>
              {settings.hasExpiration ? (
                <input
                  type="number"
                  className="form-control"
                  value={settings.expiration || ''}
                  onChange={evt => updatePushSettings('expiration', parseInt(evt.target.value))}
                />
              ) : (
                <input type="text" className="form-control" disabled />
              )}
            </FlexLineItem>
            <FlexLine>Hours</FlexLine>
          </FlexLine>
        </div>
      </FlexLineItem>
      <FlexLineItem container grow={1} style={{ paddingRight: '32px', flexBasis: '45%' }}>
        {/* CUSTOM PAYLOAD ======================================================= */}
        {app.platform !== 'webpush' && (
          <WrappedValidatedInput
            onChange={value => updatePushSettings('payload', value)}
            value={settings.payload}
            touched={false}
            valid={
              payloadValidator
                .map(validator => Boolean(validator(settings.payload)))
                .reduce((a, b) => a && b, true) ?? true
            }
            label="Payload"
            name="payload"
            hint="Valid JSON that your app will receive with the push notification. The root of the JSON must be an Object, and can't have the reserved key com.batch. You can use {BATCH:TITLE}, {BATCH:BODY}, {BATCH:DEEPLINK} & {BATCH:CAMPAIGNID} variables, they will be replaced."
            placeholder='{"valid":"JSON"}'
            rows={4}
            type={app.features.has('macro') ? 'template' : 'text'}
            monospaced
          />
        )}
        {/* COLLAPSEKEY ==================================================== */}
        {android && (
          <div className="form-group">
            <label htmlFor="collapse-key">
              GCM Collapse Key
              <Hint maxTooltipWidth={240}>
                Defines how notifications are managed when an offline device goes online. If
                enabled, the device will only show the most recent notification. If disabled, it
                will show all the notifications received when the device was offline. You should
                disable the collapse key if all your notifications matter (E.g. messages, etc). You
                can use up to 3 different collapse keys if you want users to get only one
                notification of each kind when coming online (E.g. marketing, alert, etc).
              </Hint>
            </label>
            <FlexLine>
              <FlexLineItem width={30}>
                <Checkbox
                  name="hasCollapseKey"
                  checked={settings.hasCollapseKey}
                  onChange={() => {
                    updatePushSettings('hasCollapseKey', !settings.hasCollapseKey)
                  }}
                  id="collapse-key"
                />
              </FlexLineItem>
              <FlexLineItem grow={1}>
                {settings.hasCollapseKey ? (
                  <input
                    type="text"
                    onChange={evt => updatePushSettings('collapseKey', evt.target.value)}
                    value={defaultValue}
                    className="form-control"
                  />
                ) : (
                  <input type="text" className="form-control" disabled />
                )}
              </FlexLineItem>
            </FlexLine>
          </div>
        )}
      </FlexLineItem>
    </FlexLine>
  )
}
