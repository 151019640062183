import { type State } from 'components/console/redux/console.records'
import {
  useDispatch as useOriginalDispatch,
  useSelector as useOriginalSelecor,
  type TypedUseSelectorHook,
} from 'react-redux'
import { type ThunkDispatch, type ThunkAction } from 'redux-thunk'
import { type Action } from 'redux'

export type DispatchOnlyBoundFn<P> = ThunkAction<P, State, any, Action>
export type ConsoleDispatch = ThunkDispatch<State, any, Action>

export const useDispatch: () => ConsoleDispatch = useOriginalDispatch
export const useSelector: TypedUseSelectorHook<State> = useOriginalSelecor
