import styled, { css } from 'styled-components'

import { Box } from 'components/common/box'
import { TableRow } from 'components/common/table'
import { TableTemplateCellWrapper } from 'components/common/table/table.styles'
import { OptionContainer } from 'components/form/fields/checkbox.styles'
import { OptionContainer as RadioContainer } from 'components/form/fields/radio.styles'
import { HeaderActions } from 'components/styled/blocs'
import * as colorsLegacy from 'components/styled/colors'
import { RouterLink } from 'components/styled/text'
import { colors, schemes } from 'components/styled/tokens'

import { FlexLine } from '../common/flexline'

export const CampaignBox = styled(Box)`
  overflow: hidden;
  tr {
    height: 44px;
  }
  td {
    padding: 0px 18px 0px 23px;
  }
`
type CampaignFilterGridProps = {
  columnCount: number
}
export const CampaignFilterGrid = styled.div<CampaignFilterGridProps>`
  background: ${schemes.darklucent['05']};
  display: grid;
  justify-items: stretch;
  grid-template-columns: repeat(${props => props.columnCount}, 1fr);
  grid-column-gap: 1px;
  margin-top: -1px;

  margin-left: -16px;
  margin-right: -16px;
  .filler,
  .filler-wide {
    background: #fff;
  }
  .filler {
    display: none;
  }
  & & {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 0px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
  @media (max-width: 1280px) {
    .filler {
      display: block;
    }
    & & {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 1px;
    }
  }
`
type FilterContainerProps = {
  spanCol?: boolean
}
export const FilterContainer = styled.div<FilterContainerProps>`
  padding: 0px 16px;
  background: #fff;

  ${props =>
    props.spanCol &&
    css`
      @media (max-width: 1280px) {
        .label-list {
          height: 244px !important;
        }
        grid-column: 3;
        grid-row: 1 / span 2;
      }
    `}
`
export const FilterBox = styled.div`
  position: relative;
  background: #fff;
  margin: 0 auto;
  .no-labels {
    color: ${colors.textLight};
  }
  .label-list {
    overflow: auto;
    height: 184px;
    margin-top: 14px;
    ${OptionContainer}:first-child {
      margin-top: 0;
    }
    ::-webkit-scrollbar {
      width: 6px;
      height: 37px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 6px;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: ${schemes.darklucent['30']};
    }
  }
  ${RadioContainer} {
    display: flex;
    margin-top: 14px;
  }
  ${OptionContainer} {
    display: flex;
    margin-top: 14px;
    display: flex;
    align-items: center;
    .styled-option-symbol,
    .styled-option-novalue {
      flex: 0 0 16px;
      width: 16px;
      height: 16px;
      padding: 2px 0 3px 1px;
      font-size: 8px;
    }
    .styled-option-label {
      letter-spacing: normal;
      font-size: 14px;
      font-weight: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .styled-option-symbol::after {
      width: 16px;
      height: 16px;
    }
  }
`

export const FilterBoxTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: ${colors.textNeutral};
`
type CampaignListHeaderProps = {
  openFilters?: boolean
}
export const CampaignListHeader = styled.div<CampaignListHeaderProps>`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 24px 0px 0px;
  max-width: 1134px;
  background: ${p => (p.openFilters ? colors.fill : 'transparent')};
  ${HeaderActions} {
    margin: 0 0 0 28px;
  }
  .separator-row {
    display: flex;
    flex-direction: row;
  }
  .campaign-header-row {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    margin-bottom: 16px;
  }

  @media (max-width: 1200px) {
    .close-filters-button {
      display: flex;
    }
  }
`

export const CampaignSearchContainer = styled.form`
  width: 100%;
  margin-right: 16px;
  // input {
  //   ::placeholder {
  //     color: ${colors.textLight};
  //     font-size: 13px;
  //     font-weight: 500;
  //   }
  // }
`

export const CampaignListTitle = styled.h4<any>`
  font-size: 30px;
  font-weight: 300;
  color: ${colors.text};
  flex: 1 0 auto;
  margin-bottom: 0;
`
export const SeparatorLine = styled.div`
  height: 1px;
  width: 100%;
  &:nth-child(1) {
    background: ${colorsLegacy.stroke.lighter};
  }
  background: ${schemes.darklucent['05']};
`

export const DateRangePickerContainer = styled.div`
  margin-top: 12px;
  input {
    padding-left: 12px;
    padding-right: 0px;
    &:nth-child(2) {
      margin-right: 10px;
    }
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  hr {
    &:nth-child(5) {
      display: none;
    }
  }
`

export const CampaignContainer = styled.div`
  display: block;
  flex-direction: column;
  max-width: 1134px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  background-color: ${colors.fill};
  padding: 40px 0px 0px;
  flex-wrap: wrap;
  width: 100%;
`
export const BackDropContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 100vh;
  background: rgba(0, 0, 0, 0.64);
`

export const CampaignsFilters = styled.div`
  width: 100%;
  background: ${colors.fill};
  display: block;
  padding: 0px 32px;
  /* box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.08); */
`

export const MultiFilters = styled.div`
  background: #fff;
  ${FilterContainer}:not(:last-child) {
    margin-bottom: 32px;
  }
`

export const CampaignFooter = styled.div`
  width: 100%;
  background-color: ${colors.fill};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  .button-footer-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }

  ${FlexLine} {
    border-top: 1px solid ${schemes.darklucent['05']};
  }
  height: 60px;
  @media (max-width: 1486px) {
    ${FlexLine} {
      flex-direction: row;
    }
  }
`
export const CampaignContent = styled.div`
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  width: 100%;
  ${TableRow} {
    min-height: 52px;
    ${TableTemplateCellWrapper}:first-child {
      margin-left: 10px;
    }
  }
`
type CampaignHeaderProps = {
  openFilters?: boolean
}
export const CampaignHeader = styled.div<CampaignHeaderProps>`
  width: 100%;
  background: ${p => (p.openFilters ? colors.fill : 'transparent')};
  display: block;
  /* height: 129px; */
  padding: 0px 32px;
  position: relative;
  z-index: 24;
  .campaigns-results {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .results {
    display: flex;
    font-size: 1em;
    padding-left: 8px;
    font-weight: normal;
    padding-top: 10px;
    color: ${schemes.grayscale['50']};
  }
`
export const CampaignTitleColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FilterButton = styled.button`
  border: none;
  color: ${colors.textAction};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: 10px;
  height: 36px;
  vertical-align: middle;
  border-radius: 5px;
  background: ${schemes.darklucent['10']};
  .avatar-number {
    width: 13px;
    height: 13px;
    border-radius: 50px;
    background: ${colors.fillAction};
    color: ${colors.fill};
    display: flex;
    justify-content: center;
    margin-right: 6px;
    align-items: center;
  }

  .number {
    font-size: 9px;
  }
  .plus {
  }
  &:hover {
    background: ${schemes.darklucent['20']};
  }
  &:focus {
    background: ${schemes.darklucent['30']};
  }
`
export const AvatarNumber = styled.div`
  display: block;
  width: 26px;
  height: 16px;
  border-radius: 50px;
  background: ${colors.fillAction};
  color: ${colors.fill};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
`

type CampaignNameLinkProps = {
  intent?: 'action' | 'neutral' | 'dark'
  disabled?: boolean
}
export const CampaignNameLink = styled(RouterLink)<CampaignNameLinkProps>`
  padding: 0 3px;
  margin: 0 -3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: inherit;
`
