import styled from 'styled-components'

import { BoxBody, BoxHeader, BoxTitle } from 'components/common/box'
import { schemes } from 'components/styled/tokens'
import { HorizontalDivider } from 'components/styled/utils'

import { ProfilebaseBox } from 'com.batch/profilebase/ui/pages/profilebase.syles'
import { Legend } from 'com.batch/shared/ui/component/charts/modular-donus-chart/legend/legend.styles'
import { MetricSubtitle } from 'com.batch/shared/ui/component/metric/metric.styles'

type MarketingSubscriptionBoxProps = {
  $color: string
}
export const MarketingSubscriptionBox = styled(ProfilebaseBox)<MarketingSubscriptionBoxProps>`
  ${BoxHeader} {
    padding: 0 16px;

    ${BoxTitle} {
      margin-left: 4px;
    }
  }

  ${BoxBody} {
    display: grid;
    grid-template-columns: 240px minmax(100px, 1fr);
    min-height: 208px;
    height: calc(100% - 56px);
  }
`

export const MetricsColumn = styled.div`
  padding: 0 0 16px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${HorizontalDivider} {
    margin: ${props => (props.theme.isLoading || props.theme.isEmpty ? '52' : '32')}px 0 16px 0;
    background: ${schemes.grayscale['100']};
    background: ${`linear-gradient(
      90deg,
      ${schemes.darklucent['20']} 0%,
      ${schemes.darklucent['20']} 25%,
      ${schemes.darklucent['00']} 100%
    )`};
  }

  ${MetricSubtitle} {
    max-width: 180px;
  }

  ${Legend} {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
