import { type Set } from 'immutable'
import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { type CappingCategoryRecord } from 'com.batch.redux/_records'

type Props = {
  labels: Set<CappingCategoryRecord>
}

export const ReviewLabels = ({ labels }: Props): React.ReactElement => (
  <span>
    {labels.map(label => {
      return label.enabled && label.cappings.size > 0 ? (
        <Tooltip
          tooltip="This label has capping rules defined. Your campaign might not be sent to all users"
          key={label.id}
        >
          <span className="label label--sec label--nott" title={label.name}>
            <Icon icon="danger" size={10} style={{ margin: '1px 6px -1px 2px' }} />
            {label.name}
          </span>
        </Tooltip>
      ) : (
        <span className="label label--sec label--nott" key={label.id} title={label.name}>
          {label.name}
        </span>
      )
    })}
  </span>
)
