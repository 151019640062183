import * as React from 'react'
import { useTheme } from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import {
  BrowserName,
  ContentContainer,
  Header,
  Message,
  Notif,
  Sender,
  SmallIcon,
  Thumb,
  Title,
} from 'components/project/campaign/push/preview/platform/web-win.styles'

import { MediaField } from './media-field'

import { EditableField } from 'com.batch/message-builder/ui/components/editable-field'

type WebWinPreviewProps = {
  messageId: string
}
export const WebWinPreview = ({ messageId }: WebWinPreviewProps): React.ReactElement => {
  const { app } = useTheme()
  const website = React.useMemo(() => {
    const tmp = app?.bundleId.replace('http://', '').replace('https://', '').split('/')
    if (tmp) {
      return tmp[0]
    }
  }, [app?.bundleId])

  return (
    <Notif>
      <MediaField messageId={messageId} field="media">
        {({ url, overlay }) => <Thumb $url={url}>{overlay}</Thumb>}
      </MediaField>
      <div style={{ padding: 10 }}>
        <Header>
          <img src="/medias/icons/chrome-colored.svg" style={{ width: 16, height: 16 }} />
          <BrowserName>Google Chrome</BrowserName>
          <Icon icon="close" />
        </Header>
        <ContentContainer>
          <MediaField messageId={messageId} field="icon">
            {({ url, overlay }) => <SmallIcon $url={url}>{overlay}</SmallIcon>}
          </MediaField>
          <Title>
            <EditableField field="pushTitle" messageId={messageId} />
          </Title>
          <Message>
            <EditableField field="pushBody" messageId={messageId} />
          </Message>
          <Sender>{website}</Sender>
        </ContentContainer>
      </div>
    </Notif>
  )
}
