import * as React from 'react'

import { TooltipPermission, type AccessNotAllowedProps } from 'components/common/tooltip-permission'

import { SegmentedControlContainer } from './toggle-button.styles'

type TabButtonProps = AccessNotAllowedProps & {
  grow?: boolean
  size?: 'normal' | 'small'
  invalid?: boolean
  disabled?: boolean
  children: React.ReactNode
  style?: {
    [key: string]: string | number
  }
}

export function TabButton({
  grow = false,
  size = 'normal',
  children,
  style,
  invalid,
  disabled,
  accessNotAllowed,
  accessNotAllowedMsg,
}: TabButtonProps): React.ReactElement {
  return (
    <TooltipPermission
      accessNotAllowed={accessNotAllowed ?? false}
      accessNotAllowedMsg={accessNotAllowedMsg}
    >
      <SegmentedControlContainer
        style={style}
        grow={grow}
        size={size}
        invalid={invalid}
        disabled={disabled || accessNotAllowed}
      >
        {children}
      </SegmentedControlContainer>
    </TooltipPermission>
  )
}
