import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useSearchParams } from 'react-router-dom'

import { type Dayjs, type DateRange } from 'com.batch.common/dayjs.custom'

import { setAnalyticsRange } from 'com.batch.redux/stat'
import { rangeSelector } from 'com.batch.redux/stat.selector.analytics'

type setRangeType = (arg1: { readonly from: Dayjs; readonly to: Dayjs }) => void
export const useRange = (): {
  range: DateRange
  setRange: setRangeType
} => {
  const dispatch = useDispatch()
  const range = useSelector(rangeSelector)
  const [searchParams, setSearchParams] = useSearchParams()

  const setRange = ({ from, to }: { readonly from: Dayjs; readonly to: Dayjs }) => {
    if (range.from.isSame(from, 'hour') && range.to.isSame(to, 'hour')) {
      return
    }
    searchParams.set('from', from.format('YYYYMMDD'))
    searchParams.set('to', to.format('YYYYMMDD'))
    setSearchParams(searchParams)

    return dispatch(setAnalyticsRange({ from, to }))
  }
  return { range, setRange }
}
