import { type Set } from 'immutable'
import * as React from 'react'

import { NotifWeb } from 'components/campaign/preview/notif-web'
import {
  NotifAppIcon,
  NotifAppName,
  NotifArea,
  NotifContent,
  NotifContentMediaThumb,
  NotifContentText,
  NotifContentTextMessage,
  NotifContentTextTitle,
  NotifContentVideoThumb,
  NotifDate,
  NotifHead,
  NotifMedia,
  ImageLoaderContainer,
  InnerNotifHeadContainer,
} from 'components/campaign/preview/preview.styles'
import { ImageLoader, ImageLoaderRenderer, type ImgState } from 'components/common/image-loader'
import { Icon } from 'components/common/svg-icon'

import { Grid } from '../../common/grid'
import { type AppRecord } from 'com.batch.redux/_records'

type NotifProps = {
  app: AppRecord
  icon: string
  image: string
  previewWeb: string
  iconSafari: string | null | undefined
  templatedFields: Set<string>
  expanded: boolean
  audio: string
  video: string
  title: string
  message: string
  style?: {
    [key: string]: string | number
  }
}

export const Notif = ({
  app,
  icon,
  image,
  expanded,
  iconSafari,
  previewWeb,
  message,
  title,
  audio,
  video,
  style,
  templatedFields,
}: NotifProps): React.ReactElement => {
  const appName = React.useMemo(() => {
    if (app.platform === 'webpush') {
      const tmp = app.bundleId.replace('http://', '').replace('https://', '').split('/')
      return tmp[0]
    }
    return app.name
  }, [app.bundleId, app.name, app.platform])
  const [browser, os] = previewWeb.split('-')
  const isAndroid = React.useMemo(
    () => app.platform === 'android' || previewWeb === 'android',
    [app.platform, previewWeb]
  )
  const hasMedia = React.useMemo(() => !!image || !!video, [image, video])

  // Styles rules for IosPush
  const customStylesIOS: React.CSSProperties = React.useMemo(() => {
    if (hasMedia && !expanded) {
      return {
        position: 'absolute',
        right: 20,
        top: 12,
      }
    }
    return {}
  }, [expanded, hasMedia])

  const renderIosThumbnail = React.useCallback(
    (imgState: ImgState) => (
      <NotifContentMediaThumb
        hasMedia={hasMedia}
        os="win"
        src={
          imgState === 'loading'
            ? '/medias/img/notif-preview/legacy/loading-spin.svg'
            : imgState === 'error'
              ? '/medias/img/notif-preview/legacy/placeholder_img.png'
              : imgState === 'dynamic'
                ? '/medias/img/notif-preview/legacy/placeholder_dynamic.png'
                : image
        }
      />
    ),
    [hasMedia, image]
  )
  const renderAndroidThumbnail = React.useCallback(
    (imgState: ImgState) => (
      <NotifContentMediaThumb
        hasIcon={!!icon}
        os="win"
        src={
          imgState === 'loading'
            ? '/medias/img/notif-preview/legacy/loading-spin.svg'
            : imgState === 'error'
              ? '/medias/img/notif-preview/legacy/placeholder_img.png'
              : imgState === 'dynamic'
                ? '/medias/img/notif-preview/legacy/placeholder_dynamic.png'
                : icon
                  ? icon
                  : image
        }
      />
    ),
    [icon, image]
  )
  const renderAndroidPreview = React.useCallback(
    (imgState: ImgState) => {
      const imgSrc: string =
        imgState === 'loading'
          ? '/medias/img/notif-preview/legacy/loading-spin.svg'
          : imgState === 'error'
            ? '/medias/img/notif-preview/legacy/placeholder_img.png'
            : imgState === 'dynamic'
              ? '/medias/img/notif-preview/legacy/placeholder_dynamic.png'
              : image
      return <NotifMedia hasMedia={imgState === 'valid'} imgSrc={imgSrc} />
    },
    [image]
  )

  const isiOS = React.useMemo(() => app.platform === 'ios', [app.platform])
  return app.platform === 'webpush' && !isAndroid ? (
    <NotifWeb
      os={os === 'mac' ? 'mac' : 'win'}
      browser={browser === 'firefox' ? 'firefox' : browser === 'safari' ? 'safari' : 'chrome'}
      title={title}
      style={style}
      iconSafari={iconSafari}
      message={message}
      website={appName}
      icon={icon}
      image={image}
    />
  ) : (
    <NotifArea hasMedia={hasMedia}>
      <NotifHead hasIcon={icon && !message ? true : false}>
        {/* Display for Android Push Preview Notification */}
        {isAndroid ? (
          <React.Fragment>
            <InnerNotifHeadContainer>
              <Icon
                icon={app.platform === 'webpush' ? 'chrome' : 'android'}
                color={app.platform === 'webpush' ? 'HSL(220, 17%, 34%)' : 'HSL(150, 51%, 43%)'}
                size={10}
              />
              <NotifAppName>{appName}</NotifAppName>
              &nbsp;•&nbsp;&nbsp;<NotifDate>now</NotifDate>&nbsp;&nbsp;
              <Icon
                icon={expanded ? 'chevron-up' : 'chevron-down'}
                size={10}
                color="HSL(150, 51%, 43%)"
              />
            </InnerNotifHeadContainer>
            <NotifContent hasMedia={!!image}>
              <NotifContentText>
                <NotifContentTextTitle
                  hasMedia={!!image}
                  hasMessage={!!message}
                  limit={((!!icon || (!!image && !expanded)) && isAndroid) || (!!image && isiOS)}
                >
                  {title}
                </NotifContentTextTitle>
                <NotifContentTextMessage hasIcon={!!icon} hasMedia={!!image}>
                  {isAndroid && !expanded ? message.replace(/\n/g, ' ') : message}
                </NotifContentTextMessage>
              </NotifContentText>
            </NotifContent>
          </React.Fragment>
        ) : (
          <NotifAppIcon icon={icon || app.icon} />
        )}

        {/* IOS */}
        {isiOS && (
          <NotifContent hasMedia={hasMedia}>
            <NotifContentText>
              <Grid template="1fr 2vmax">
                <NotifContentTextTitle
                  hasMedia={hasMedia}
                  hasMessage={message ? true : false}
                  limit={((!!icon || (!!image && !expanded)) && isAndroid) || (!!image && isiOS)}
                >
                  {title ? title : appName}
                </NotifContentTextTitle>
                <NotifDate style={customStylesIOS}>now</NotifDate>
              </Grid>
              <NotifContentTextMessage hasMedia={hasMedia}>
                {isAndroid && !expanded ? message.replace(/\n/g, ' ') : message}
              </NotifContentTextMessage>
            </NotifContentText>
          </NotifContent>
        )}
      </NotifHead>

      <ImageLoaderContainer hasIcon={!!icon} hasMedia={hasMedia}>
        {(image || templatedFields.includes('attachment')) && !expanded && isiOS && (
          <ImageLoaderRenderer
            imgSrc={image}
            isDynamic={templatedFields.includes('attachment')}
            render={renderIosThumbnail}
          />
        )}
        {video && isiOS && !expanded && (
          <NotifContentVideoThumb>
            <Icon icon="play" color="#fff" size={10} />
            <video src={video} />
          </NotifContentVideoThumb>
        )}
        {/* Android */}
        {(!!icon || !!image) && isAndroid && (!expanded || !image) && (
          <ImageLoaderRenderer
            imgSrc={icon ? icon : image}
            isDynamic={icon ? templatedFields.includes('icon') : templatedFields.includes('image')}
            render={renderAndroidThumbnail}
          />
        )}
      </ImageLoaderContainer>

      {/* Display for IOS Push Preview Notification */}

      {expanded && (image || audio || video) && !isAndroid && (
        <NotifMedia>
          {(image || templatedFields.includes('attachment')) && (
            <ImageLoader
              imgSrc={image}
              alt="Notification large image"
              isDynamic={templatedFields.includes('attachment')}
            />
          )}
          {audio && (
            <audio controls>
              <source src={audio} type="audio/mp3" />
            </audio>
          )}
          {video && (
            <video autoPlay loop>
              <source src={video} type="audio/mp4" />
            </video>
          )}
        </NotifMedia>
      )}
      {expanded && image && isAndroid && (
        <ImageLoaderRenderer
          imgSrc={image}
          isDynamic={templatedFields.includes('attachment')}
          render={renderAndroidPreview}
        />
      )}
    </NotifArea>
  )
}
