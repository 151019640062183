import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Map } from 'immutable'
import * as React from 'react'
import DayPicker, { type Modifier } from 'react-day-picker'
import { createPortal } from 'react-dom'

import {
  type dateShortcut,
  type allTimeRangeShortcutType,
  DatePickerShortcuts,
} from 'components/form/fields/date-picker/date-picker-shortcuts'

import { dayjs, type DateRange } from 'com.batch.common/dayjs.custom'

import {
  DatePickerPopoverContainer,
  DayElements,
  Caption,
  DatePickerNavbar,
  WeekdayElement,
} from './date-picker.styles'

type DateRangePickerPopoverProps = {
  position?: 'right' | 'left'
  shortcuts?: List<dateShortcut | allTimeRangeShortcutType>
  setRange?: (arg1: DateRange) => any
  from: Dayjs | null | undefined
  disabledDays?: (day: Date) => boolean
  to: Dayjs | null | undefined
  setFrom: (arg1?: Dayjs | null | undefined) => void
  setTo: (arg1?: Dayjs | null | undefined) => void
  close: (range?: Readonly<DateRange> | null | undefined) => void
  clearErrors: () => void
  setDay: (arg1: Dayjs) => void
  setInputs: (arg1: (arg1: Map<string, string>) => Map<string, string>) => void
}

export const DateRangePickerPopover = React.forwardRef<HTMLDivElement, DateRangePickerPopoverProps>(
  (
    {
      position,
      shortcuts,
      disabledDays,
      from,
      to,
      setRange,
      close,
      clearErrors,
      setDay,
      setInputs,
      setFrom,
      setTo,
    }: DateRangePickerPopoverProps,
    ref
  ): React.ReactNode => {
    let modalRoot = document.getElementById('select-root')
    if (!modalRoot) {
      modalRoot = document.createElement('div')
      modalRoot.setAttribute('id', 'select-root')
      document.body?.appendChild(modalRoot)
    }

    const activeDays = React.useMemo(() => {
      const tmp: Array<Dayjs> = []
      if (from) tmp.push(from)
      if (to) tmp.push(to)
      return Immutable.List(tmp)
    }, [from, to])

    const [dayOverview, setDayOverview] = React.useState<Dayjs | null | undefined>(null)

    const modifiers: {
      firstWeekDay?: (arg1: Date) => boolean
      lastWeekDay?: (arg1: Date) => boolean
      from?: Modifier
      to?: Modifier
      highlighted?:
        | ((arg1: Date) => boolean)
        | {
            from: Date
            to: Date
          }
      overview?: (arg1: Date) => boolean
    } = {}
    modifiers.firstWeekDay = (day: Date) => dayjs.utc(day).isoWeekday() === 1
    modifiers.lastWeekDay = (day: Date) => dayjs.utc(day).isoWeekday() === 7

    if (!!from && !!to) {
      modifiers.from = from.toDate()
      modifiers.to = to.toDate()
      modifiers.highlighted = {
        from: from.local().toDate(),
        to: to.startOf('day').local().toDate(),
      }
    } else if (!!from && !!dayOverview && !from.isSame(dayOverview, 'day')) {
      modifiers.from =
        !!dayOverview && !from.isSameOrAfter(dayOverview, 'day') ? from.toDate() : undefined
      modifiers.to =
        !!dayOverview && !from.isSame(dayOverview, 'day') ? dayOverview.toDate() : undefined

      modifiers.highlighted = (day: Date) =>
        dayjs.utc(day).isSameOrBefore(dayOverview, 'day') &&
        dayjs.utc(day).isSameOrAfter(from, 'day') &&
        from.isBefore(dayOverview)

      modifiers.overview = (day: Date) =>
        dayjs.utc(day).isSame(dayOverview, 'day') && dayOverview.isAfter(from, 'day')
    }
    const onDayClick = React.useCallback((d: Date) => setDay(dayjs.utc(d)), [setDay])
    const renderDay = React.useCallback((date: Date) => DayElements(date), [])
    const selectedDays = React.useCallback(
      (day: Date) => {
        const isSelected =
          (!!from && dayjs.utc(day).startOf('day').isSame(from, 'day')) ||
          (!!to && dayjs.utc(day).startOf('day').isSame(to, 'day'))
        return isSelected
      },
      [from, to]
    )
    const onDayMouseEnter = React.useCallback((day: Date) => setDayOverview(dayjs.utc(day)), [])
    const onDatePickerShortcutsReset = React.useCallback(() => {
      setInputs(inputs => inputs.set('from', '').set('to', ''))
      close()
    }, [close, setInputs])
    const onDatePickerShortcutsSetDay = React.useCallback(
      ({ from, to }) => {
        if (!!from && !!to) {
          clearErrors()
          setFrom(from)
          setTo(to)

          setInputs(inputs => {
            const newInputs = inputs
              .set('from', from.startOf('day').local().format('DD/MM/YYYY'))
              .set('to', to.startOf('day').local().format('DD/MM/YYYY'))
            return newInputs
          })
          const newRange = { from: from, to: to }
          if (setRange) {
            setRange(newRange)
          }
          close(newRange)
        }
      },
      [clearErrors, close, setFrom, setInputs, setRange, setTo]
    )
    return modalRoot
      ? createPortal(
          <DatePickerPopoverContainer
            width={shortcuts ? 684 : 540}
            position={position}
            isRange
            data-testid="DayPicker_dropdown"
            ref={ref}
          >
            <DayPicker
              disabledDays={disabledDays}
              captionElement={Caption}
              firstDayOfWeek={1}
              modifiers={modifiers}
              month={from ? from.toDate() : dayjs.utc().toDate()}
              numberOfMonths={2}
              onDayClick={onDayClick}
              renderDay={renderDay}
              navbarElement={DatePickerNavbar}
              selectedDays={selectedDays}
              weekdayElement={WeekdayElement}
              onDayMouseEnter={onDayMouseEnter}
            />
            {!!shortcuts && (
              <DatePickerShortcuts
                shortcuts={shortcuts}
                reset={onDatePickerShortcutsReset}
                setDay={onDatePickerShortcutsSetDay}
                activeDays={Immutable.List(activeDays)}
              />
            )}
          </DatePickerPopoverContainer>,
          modalRoot
        )
      : null
  }
)
