import Immutable, { type List, type RecordOf } from 'immutable'

type DataProps = {
  value: number
  rate: number
}
type DataRecord = RecordOf<DataProps>
const DataFactory = Immutable.Record({
  value: 0,
  rate: 0,
})

export type StepStatsProps = {
  delivered: DataRecord
  open: DataRecord
  click: DataRecord
  bounce: DataRecord
  unsubscribe: DataRecord
}
export const StepStatsFactory = Immutable.Record<StepStatsProps>({
  delivered: DataFactory(),
  open: DataFactory(),
  click: DataFactory(),
  bounce: DataFactory(),
  unsubscribe: DataFactory(),
})
export type StepStatsRecord = RecordOf<StepStatsProps>

export type OrchestrationStepProps = {
  channel: ChannelUntilCleanup | null | undefined
  step: string
  stats: StepStatsRecord
}
export const OrchestrationStepFactory = Immutable.Record<OrchestrationStepProps>({
  channel: null,
  step: '',
  stats: StepStatsFactory(),
})
export type OrchestrationStepRecord = RecordOf<OrchestrationStepProps>

export type OrchestrationStatsByStepRecord = List<OrchestrationStepRecord>
