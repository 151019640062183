import * as React from 'react'

import { Icon, type availableIcons } from 'components/common/svg-icon'

import { type AlertKind, AlertWrapper } from './alert.styles'

type Props = {
  kind: AlertKind
  icon?: availableIcons
  children: React.ReactNode
  prefix?: React.ReactNode
}

export const Alert = ({ kind, icon, children, prefix }: Props): React.ReactElement => {
  return (
    <AlertWrapper $kind={kind}>
      {icon && <Icon icon={icon} />}
      {prefix && prefix}
      <div>{children}</div>
    </AlertWrapper>
  )
}
