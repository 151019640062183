import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useToggle } from 'components/_hooks/'
import { ValidatedInput } from 'components/campaign/form/validated-input'
import { LabelPicker } from 'components/campaign/label-picker'
import { Ariane } from 'components/common/ariane'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'

import validators from 'com.batch.common/form.validators'

import { type CampaignRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { updateCampaignName } from 'com.batch.redux/content'
import { campaignNameSelector } from 'com.batch.redux/content.selector'
import { customErrorsSelector } from 'com.batch.redux/targeting.selector.composed'

const campaignNameValidator = [validators.required, validators.min3]

// ====================== WORDING
const subtitles = {
  automations: {
    NEW: 'new message',
    DRAFT: 'edit message',
    RUNNING: 'edit message',
    STOPPED: 'edit message',
    COMPLETED: 'review message',
  },
  campaigns: {
    NEW: 'new campaign',
    DRAFT: 'edit campaign',
    RUNNING: 'edit campaign',
    STOPPED: 'edit campaign',
    COMPLETED: 'review campaign',
  },
  email: {
    NEW: 'new campaign',
    DRAFT: 'edit campaign',
    RUNNING: 'edit campaign',
    STOPPED: 'edit campaign',
    COMPLETED: 'review campaign',
  },
}
const titles = {
  'in-app': 'In-App',
  push: 'Push',
  email: 'Email',
}

type Props = {
  activeStep: string | null | undefined
  campaignState: campaignStateType
  campaignType: campaignType
  schedulingType: schedulingType
  closeErrors: () => void
  errorReportOpened: boolean
  pickedLabels: CampaignRecord['pickedLabels']
  stuck: boolean
}

export const FormHead = ({
  stuck,
  campaignType,
  schedulingType,
  closeErrors,
  errorReportOpened,
  activeStep,
  campaignState,
  pickedLabels,
}: Props): React.ReactElement => {
  const errorPopoverState = useToggle()
  const labelPickerState = useToggle()
  const dispatch = useDispatch()
  const app = useSelector(currentAppSelector)
  const errors = useSelector(customErrorsSelector)
  const canAddLabels = React.useMemo(() => app.features.has('label'), [app.features])
  const campaignName = useSelector(campaignNameSelector)
  const isCompleted = React.useMemo(() => campaignState === 'COMPLETED', [campaignState])
  const onUpdateCampaignName = React.useCallback(
    (newName: string) => {
      dispatch(updateCampaignName(newName))
    },
    [dispatch]
  )
  return (
    <div className={`pf__head pf__head--sticky ${stuck ? 'pf__head--stuck' : ''}`}>
      <div className="pf__head__content">
        <h5 className="pf__head__chapo">
          <span style={{ flexGrow: 1 }}>
            <b>{titles[campaignType]}</b> / <span>{subtitles[schedulingType][campaignState]}</span>
            &nbsp;
          </span>
          <span>
            {errors.size > 0 && (
              <div className="campaignErrorLabel">
                <div
                  className="errorPopover"
                  style={{ display: errorPopoverState.value ? 'block' : 'none' }}
                >
                  <h3>You need to fix the following :</h3>
                  {errors.map((err, index) => (
                    <p key={index}>
                      <strong>
                        {err.topic}
                        {err.lang ? ` (${err.lang.label})` : ''}
                      </strong>{' '}
                      : {err.message}
                    </p>
                  ))}
                </div>
                <span
                  className="label label-warning"
                  onMouseEnter={errorPopoverState.open}
                  onMouseOut={errorPopoverState.close}
                >
                  {errors.size} check{errors.size > 1 && 's'}
                </span>
              </div>
            )}
          </span>
        </h5>
        <div className="pf__head__title">
          <div
            style={{
              paddingRight: '8px',
              minWidth: 0,
              flex: `0 0 ${isCompleted ? '100%' : '50%'}`,
              height: isCompleted ? '30px' : '44px',
            }}
          >
            <div style={{ position: 'relative' }}>
              <ValidatedInput
                placeholder={
                  schedulingType === 'campaigns' ? 'Name your campaign' : 'Name your automation'
                }
                data-testid="input-campaign-name"
                name="campaignName"
                type="text"
                onChange={onUpdateCampaignName}
                value={campaignName}
                valid={
                  campaignNameValidator
                    .map(validator => Boolean(validator(campaignName)))
                    .reduce((a, b) => a && b, true) ?? true
                }
                autoFocus
                touched={false}
                style={{ fontSize: 18, height: 43, paddingRight: 160 }}
              />
              {canAddLabels && (
                <div
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                  }}
                >
                  <Button style={{ height: 24 }} onClick={labelPickerState.open}>
                    {pickedLabels.size > 0
                      ? `Manage labels (${pickedLabels.size})`
                      : 'Associate labels'}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {!isCompleted && (
            <div className="pf__head__steps">
              <Ariane steps={['who', 'when', 'what', 'review']} activeStep={activeStep} />
            </div>
          )}
        </div>
      </div>
      <Popin
        opened={labelPickerState.value}
        close={labelPickerState.close}
        style={{ padding: 0, paddingBottom: 0 }}
      >
        <LabelPicker
          appId={app.id}
          pickedLabels={pickedLabels}
          schedulingType={schedulingType}
          onClose={labelPickerState.close}
        />
      </Popin>
      <Popin close={closeErrors} opened={errorReportOpened}>
        <Box>
          <BoxHeader>
            <HeaderBoxTitle title="You need to fix the following errors before saving" />
          </BoxHeader>
          <BoxBody $padding>
            {errors.map((err, index) => (
              <p key={index}>
                <strong>
                  {err.topic} {err.lang ? `(${err.lang.label})` : ''}
                </strong>{' '}
                : {err.message}
              </p>
            ))}
          </BoxBody>
          <BoxFooter>
            <FooterBoxActions>
              <Button kind="primary" intent="action" onClick={closeErrors}>
                OK, I got this
              </Button>
            </FooterBoxActions>
          </BoxFooter>
        </Box>
      </Popin>
    </div>
  )
}
