import { type Map } from 'immutable'
import * as React from 'react'
import { ThemeProvider, useTheme } from 'styled-components'

import { BoxHeader, BoxTitle, BoxBody } from 'components/common/box'
import { Skeleton } from 'components/common/empty-states'
import { EmptyTitle, Empty } from 'components/styled/empty'
import { LinkArrow } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { randomSize, kformat } from 'com.batch.common/utils'

import { type fetchingState } from 'com.batch.redux/_records'

import { formatChannelName } from 'com.batch/profilebase/infra/formats/format-channel-name'
import {
  type AnalyticsChannel,
  type channelThemeType,
} from 'com.batch/profilebase/infra/types/channel-type'
import { type ReachabilityTimeserieRecord } from 'com.batch/profilebase/models/reachability-timeserie.records'
import { SubscriptionGrowthBlockBox } from 'com.batch/profilebase/ui/components/subscription-growth-block/subscription-growth-block.styles'
import { PUSH_PLATFORM_FILTER } from 'com.batch/shared/constants/push-platform-filter.constants'
// import { type BarChartData } from 'com.batch/shared/infra/types/chart-data'
import { type PushPlatformFilterType } from 'com.batch/shared/infra/types/push-platform-filter.type'
import { BarChartProvider, BarChart } from 'com.batch/shared/ui/component/charts/bar-chart'
import { Metric } from 'com.batch/shared/ui/component/metric/metric'
import { STATUS } from 'constants/common'

type Props = {
  loadingState: fetchingState
  theme: channelThemeType
  subscriptionsByDay: Map<string, ReachabilityTimeserieRecord>
  cumulLastMonth: number
  channel: AnalyticsChannel
  platformFilter?: PushPlatformFilterType
}

export const SubscriptionGrowthBlock = ({
  loadingState,
  theme,
  subscriptionsByDay,
  cumulLastMonth,
  platformFilter,
  channel,
}: Props): React.ReactElement => {
  const { isLoading, isEmpty } = useTheme()

  const [dateOnView, setDateOnView] = React.useState<any>(null)

  const noNewSub = React.useMemo(
    () => !isEmpty && loadingState === STATUS.LOADED && cumulLastMonth === 0,
    [isEmpty, loadingState, cumulLastMonth]
  )

  const hasData = React.useMemo(() => !isLoading && !noNewSub, [isLoading, noNewSub])

  const groups = [{ color: theme.colors.plotChart, name: 'subs' }]
  const nbBars = 30

  const dateRange = React.useMemo(
    () =>
      Array(30)
        .fill(null)
        .map((_, i) => dayjs().subtract(i, 'day'))
        .reverse(),

    []
  )

  const data = React.useMemo(
    () =>
      Array.from({ length: nbBars }, (_, index) => index).map(i => {
        const date = dayjs
          .utc()
          .subtract(nbBars, 'day')
          .add(i + 1, 'day')
        const data = subscriptionsByDay.get(date.format('YYYY-MM-DD'), null)
        const sub = !hasData ? randomSize(40, 140) : data ? data.subscriptions : 0

        return {
          date: date,
          subs: {
            value: sub,
            rate: sub,
          },
        }
      }),
    [subscriptionsByDay, hasData]
  )

  const timelineOverWriteName = React.useMemo(
    () => [
      { date: data[0].date, name: '30 days ago' },
      { date: data[data.length - 1].date, name: 'Today' },
    ],
    [data]
  )

  const MetricsValue = React.useMemo(() => {
    const found = data.find(f => dateOnView && f.date.isSame(dateOnView, 'day'))
    return found
      ? [kformat(found.subs.value), `on the ${found.date.format('MMM Do, YYYY')}`]
      : [
          kformat(cumulLastMonth),
          `new ${
            channel === 'push'
              ? `${platformFilter ? platformFilter.title : PUSH_PLATFORM_FILTER[0].title}`
              : ''
          } subscriptions over last month`,
        ]
  }, [dateOnView, data, cumulLastMonth, platformFilter, channel])

  const channelName = React.useMemo(
    () => formatChannelName(channel, platformFilter),
    [channel, platformFilter]
  )

  return (
    <ThemeProvider theme={{ isEmpty: noNewSub || isEmpty }}>
      <SubscriptionGrowthBlockBox $color={theme.colors.text} $hasData={hasData}>
        {noNewSub && (
          <Empty>
            <EmptyTitle>No {channelName} subscriptions in the last 30 days</EmptyTitle>
            <LinkArrow
              href="https://doc.batch.com/guides/send-emails-with-batch/#collect-email-addresses"
              target="_blank"
            >
              {platformFilter ? platformFilter.title : channelName} SDK documentation
            </LinkArrow>
          </Empty>
        )}
        <BoxHeader>
          <BoxTitle>
            <Skeleton w={185} h={18} style={{ marginLeft: 4 }}>
              New {formatChannelName(channel, platformFilter)} subscriptions
            </Skeleton>
          </BoxTitle>
        </BoxHeader>

        <BoxBody>
          <Metric value={MetricsValue[0]} subtitle={MetricsValue[1]} align="row" />
          <div style={{ paddingTop: 5 }}>
            <BarChartProvider
              dateRange={dateRange}
              isEmpty={noNewSub || isEmpty}
              isLoading={loadingState === STATUS.LOADING}
              setDateOnView={setDateOnView}
            >
              <BarChart
                data={data}
                groups={groups}
                barPadding={0.18}
                showTimeline
                scaleLineKind="overTheMaxBar"
                timelineRenameDates={timelineOverWriteName}
              />
            </BarChartProvider>
          </div>
        </BoxBody>
      </SubscriptionGrowthBlockBox>
    </ThemeProvider>
  )
}
