import Immutable, { type List, type OrderedMap } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { Button, PermissionButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import dialog from 'components/common/dialog'
import { Wrapper, SettingsIDFADebugIcon, Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'
import { formatIdentifier, ucFirst, getPlatformDoc } from 'com.batch.common/utils'

import { DebugInstall } from './debug-install'
import { DebugResultSummary } from './debug.style'

import {
  TestDeviceFactory,
  type AppRecord,
  type TestDeviceRecord,
  type State,
} from 'com.batch.redux/_records'
import { currentAppCanPush } from 'com.batch.redux/app'
import { resetDebug } from 'com.batch.redux/stat'
import { DebugInstallFactory, type DebugInstallRecord } from 'com.batch.redux/stat.records'
import { saveDevice, sendTest } from 'com.batch.redux/testDevice'

type DebugUserProps = {
  app: AppRecord
  mode: lookupMode
  query: string
  refresh: (installId: string) => void
}

const fakeData = Immutable.List<DebugInstallRecord>().push(
  DebugInstallFactory({
    apiKey: '',
    customId: 'Jean Paul Grouve',
    installationId: '45E64A6546-87789797-454',
    platform: 'ios',
    timezone: 'Europe/Paris',
    notificationStatus: 'On (badge, sounds, alert)',
    device: TestDeviceFactory({
      value: '05326201e564711322c7c6e4a8700ac40f674bf3778fec799a84de7a73699e39',
    }),
    attributes: Immutable.OrderedMap([
      ['b.device_type', 'iPhone10,6'],
      ['b.install_data', dayjs().subtract(2, 'month')],
      ['b.last_visit_date', dayjs().subtract(2, 'day')],
      ['b.last_push_date', dayjs().subtract(2, 'day')],
      ['b.os_version', '11.2.1'],
      ['b.carrier_code', 20801],
      ['pushtoken', '05326201e564711322c7c6e4a8700ac40f674bf3778fec799a84de7a73699e39'],
      ['platform', 'iOS'],
      ['production', true],
      ['b.app_version', '4.0.4'],
      ['batch_sdk_version', '2.51'],
      ['b.language', 'fr'],
      ['b.region', 'FR'],
    ]),
  })
)

const loadingSelector = (state: State) => state.stat.userDebug.loading
const sendingSelector = (state: State) => state.testDevice.getIn([0, 'loading'], false)
const resultsSelector = (state: State) => state.stat.userDebug.results
const activeQuerySelector = (state: State) => state.stat.userDebug.query
const installIdsSelector = (state: State) => state.stat.userDebug.recentInstallationIds
const recentCustomIdsSelector = (state: State) => state.stat.userDebug.recentCustomIds
const currentAppCanPushSelector = (state: State) => currentAppCanPush(state)
const userDeviceSelector = (state: State) => state.testDevice
const userActiveModeSelector = (state: State) => state.stat.userDebug.mode

export const DebugUser = ({ query, mode, app, refresh }: DebugUserProps): React.ReactElement => {
  // ========================= REDUX
  const dispatch = useDispatch()
  const isLoading = useSelector(loadingSelector)
  const isSending = useSelector(sendingSelector) as boolean
  const userResults: List<DebugInstallRecord> = useSelector(resultsSelector)
  const userActiveQuery = useSelector(activeQuerySelector)
  const userInstallIds = useSelector(installIdsSelector)
  const userRecentCustomIds = useSelector(recentCustomIdsSelector)
  const userCanPush = useSelector(currentAppCanPushSelector)
  const userDevices: OrderedMap<number, TestDeviceRecord> = useSelector(userDeviceSelector)
  const userActiveMode: lookupMode = useSelector(userActiveModeSelector)
  React.useEffect(() => {
    dispatch(resetDebug())
  }, [dispatch])

  const onDeviceTest = React.useCallback(() => {
    const device = TestDeviceFactory({
      value: query,
      kind: mode,
    })
    confirm({
      title: 'Send a test push',
      confirm: 'Send',
      message: (
        <p>
          This will send a test notification to the device
          {userActiveMode !== 'installation_id' ? '(s)' : ''} with{' '}
          {formatIdentifier(app.platform, userActiveMode)}{' '}
          <code style={{ display: 'inline-block', lineHeight: '14px', margin: '4px 0 0 0' }}>
            {device.value}
          </code>
        </p>
      ),
    }).then(
      () => {
        return dispatch(
          sendTest({
            app: app,
            device: device,
            context: 'debug',
          })
        ).then(
          () => {},
          () => {}
        )
      },
      () => {}
    )
  }, [app, dispatch, mode, query, userActiveMode])

  const onDeviceSave = React.useCallback(() => {
    const device = TestDeviceFactory({
      value: query,
      kind: mode,
    })
    const firstInstall = userResults.first()
    let deviceType = ''
    let deviceBrand = ''
    let deviceName = ''
    if (firstInstall) {
      deviceType = firstInstall.attributes.get('b.device_type', '')
      deviceBrand = ucFirst(firstInstall.attributes.get('b.device_brand', ''))
    }
    if (app.platform === 'ios') {
      deviceName = deviceType
    }
    if (app.platform === 'android') {
      deviceName = `${deviceBrand} ${deviceType}`
    }

    dialog({
      confirm: 'Save',
      defaultValue: `${window.user.firstname || ''}${
        !!deviceName && mode !== 'custom_id' ? ' - ' + deviceName : ''
      } (${dayjs().format('DD/MM/YY')})`,
      label: 'Device name',
      title: 'Pick a name for this device.',
      message: (
        <div style={{ marginBottom: '10px' }}>
          <p>This will save the device so you can use it for campaign testing / previewing.</p>
        </div>
      ),
    }).then(
      (name: string) => {
        dispatch(saveDevice(device.set('name', name)))
      },
      () => {}
    )
  }, [app.platform, dispatch, mode, query, userResults])

  const data = React.useMemo(
    () =>
      !userActiveQuery || (userActiveQuery && userResults.size === 0) ? fakeData : userResults,
    [userActiveQuery, userResults]
  )

  const tokensCount = React.useMemo(
    () => data.filter((install: DebugInstallRecord) => install.device && install.device.value).size,
    [data]
  )

  const matchingDevices = React.useMemo(
    () =>
      userDevices.filter(
        d => d.kind === userActiveMode && d.value === userActiveQuery && d.id !== 0
      ),
    [userActiveMode, userActiveQuery, userDevices]
  )

  const firstMatchingDevice = React.useMemo(() => matchingDevices.first(), [matchingDevices])
  const isEmpty = React.useMemo(
    () => !userActiveQuery || (!!userActiveQuery && userResults.size === 0),
    [userActiveQuery, userResults.size]
  )

  const isAllowedToSave = useIsCurrentUserAllowedTo(['app', 'debug:write'])

  return (
    <Wrapper
      isEmpty={isEmpty}
      isLoading={isLoading}
      isOverlayShown={!isLoading && isEmpty}
      overlayProps={
        !userActiveQuery
          ? getEmptyStateProps({ app, installIds: userInstallIds, customIds: userRecentCustomIds })
          : {
              status: 'empty-page' as 'error' | 'empty-page' | 'empty' | 'error-page',
              title: `No matching ${formatIdentifier(app.platform, userActiveMode)} found`,
              description: 'There’s nothing matching your criteria, try something else',
              content: <SettingsIDFADebugIcon />,
            }
      }
    >
      <DebugResultSummary>
        <span>
          <Skeleton w={250} h={14}>
            <React.Fragment>
              We found {tokensCount} push token{tokensCount > 1 && 's'} on {data.size}{' '}
              {data.size > 1 && ' different '}installation{data.size > 1 && 's'}.
            </React.Fragment>
          </Skeleton>
        </span>

        <div>
          <PermissionButton
            kind="inlineDark"
            intent="neutral"
            onClick={onDeviceSave}
            disabled={tokensCount === 0 || matchingDevices.size > 0}
            isAllowed={isAllowedToSave}
          >
            {firstMatchingDevice
              ? `Test device "${firstMatchingDevice.name}" is saved`
              : 'Save as a test device'}
          </PermissionButton>
          {userCanPush && (
            <Button
              kind="inlineDark"
              intent="neutral"
              addOn="suffix"
              onClick={onDeviceTest}
              disabled={tokensCount === 0}
              isLoading={isSending}
              style={{ marginLeft: 8 }}
            >
              Send test push
              <Icon icon="send" />
            </Button>
          )}
        </div>
      </DebugResultSummary>
      {data.map((user, indice) => (
        <DebugInstall
          app={app}
          user={user}
          key={`install-${indice}`}
          startOpen={indice === 0}
          refresh={refresh}
        />
      ))}
    </Wrapper>
  )
}

const getEmptyStateProps = ({
  app,
  installIds,
  customIds,
}: {
  app: AppRecord
  installIds: Array<string>
  customIds: Array<string>
}) => {
  const ids = customIds.length > 0 ? [...customIds] : [...installIds]
  const kind: 'custom_id' | 'installation_id' =
    customIds.length > 0 ? 'custom_id' : 'installation_id'
  return {
    status: 'empty-page' as 'error' | 'empty-page' | 'empty' | 'error-page',
    title: 'Look up some devices',
    description: (
      <React.Fragment>
        You are able to reach any of your users device for debug purposes here. You just need to
        provide one of the available identifiers.
        {ids.length > 0 && (
          <React.Fragment>
            {' '}
            Try{' '}
            <a
              title={ids[0]}
              style={{
                color: '#2A3440',
                display: 'inline',
                marginRight: 0,
              }}
              className="fs-exclude"
              href={generateUrl('app_debug', {
                companyId: app.companyId,
                appId: app.id,
                [kind as string]: ids[0],
              })}
            >
              this one
            </a>
            {ids.length > 1 && (
              <React.Fragment>
                {' '}
                or{' '}
                <a
                  title={ids[1]}
                  style={{
                    color: '#2A3440',
                    display: 'inline',
                  }}
                  className="fs-exclude"
                  href={generateUrl('app_debug', {
                    companyId: app.companyId,
                    appId: app.id,
                    [kind as string]: ids[1],
                  })}
                >
                  that one
                </a>
              </React.Fragment>
            )}
            .
          </React.Fragment>
        )}
      </React.Fragment>
    ),
    content: <SettingsIDFADebugIcon />,
    links: [
      {
        name: 'Learn more about devices',
        href: 'https://doc.batch.com/dashboard/settings/app-settings#integration-debug-tool',
      },
      {
        name: 'Install the SDK',
        href: `https://doc.batch.com/${getPlatformDoc(app.platform)}/getting-started/prerequisites`,
      },
    ],
  }
}
