import { useCallback } from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import {
  type ReduxAction,
  type DispatchOnlyBoundFn,
  type DispatchBoundFn,
} from 'com.batch.redux/_records'

type Func = (
  ...args: Array<unknown>
) => DispatchOnlyBoundFn<any> | DispatchBoundFn<any> | ReduxAction<string, any>

// @deprecated use useDispatch normally, this was madness
export function useAction(action: Func) {
  const dispatch = useDispatch()
  return useCallback(
    (...params: Parameters<Func>) => {
      return dispatch(action(...params))
    },
    [dispatch, action]
  )
}
