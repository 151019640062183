import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'

import { capitalize, formatNumberWithCommas, percentage } from 'com.batch.common/utils'

import { bouncesColors } from 'com.batch/orchestration-analytics/constants/stat-type-colors'
import { type BounceCategoryRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import {
  BounceBadge,
  BounceStatLegend,
  BounceStatText,
} from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-report-block.styles'
import { type BarData, type BarChartData } from 'com.batch/shared/infra/types/chart-data'

type Props = {
  category: BounceCategoryRecord
  selectedBounce: BarChartData | null
}

export const BounceCategory = ({ category, selectedBounce }: Props): React.ReactElement => {
  const name = category.name.toLowerCase()
  const count = React.useMemo(() => {
    if (selectedBounce && Object.keys.hasOwnProperty.call(selectedBounce, name)) {
      const temp: BarData = selectedBounce[name]
      return temp.value
    }
    return category?.count ?? 0
  }, [selectedBounce, name, category?.count])
  const rate = React.useMemo(() => {
    if (selectedBounce) return selectedBounce[name].rate
    return category?.rate ?? 0
  }, [selectedBounce, name, category?.rate])
  return (
    <div>
      <BounceStatText>
        <Skeleton w={74} h={33}>
          {formatNumberWithCommas(count)}
        </Skeleton>
        <span>{count !== 0 ? percentage(rate, 2) : '0%'}</span>
        <BounceBadge color={bouncesColors[name]} />
      </BounceStatText>
      <BounceStatLegend>{capitalize(name)} bounce</BounceStatLegend>
    </div>
  )
}
