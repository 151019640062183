import React from 'react'

export const useDownloadSilently = (): ((url: string) => void) => {
  const downloadFile = React.useCallback((url: string) => {
    const a = document.createElement('a')
    a.href = url
    a.download = ''
    a.click()
    a.remove()
  }, [])

  return downloadFile
}
