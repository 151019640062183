import { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import {
  orchestrationAnalyticsDateRangeSelector,
  orchestrationAnalyticsFiltersSelector,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import {
  OrchestrationAnalyticsDateRangeFactory,
  type OrchestrationAnalyticsDateRangeRecord,
  type OrchestrationAnalyticsFilters,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import Immutable from 'immutable'
import { type DynamicDimension } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'

export const useGetAnalyticsFilters = (
  token?: string
): {
  filters: OrchestrationAnalyticsFilters
  dateRange: OrchestrationAnalyticsDateRangeRecord | null | undefined
} => {
  const getAnalyticsFilters = useSelector(orchestrationAnalyticsFiltersSelector)
  const filters = React.useMemo(
    () => (token ? getAnalyticsFilters(token) : Immutable.Map<DynamicDimension, List<string>>()),
    [getAnalyticsFilters, token]
  )
  const getAnalyticsDateRange = useSelector(orchestrationAnalyticsDateRangeSelector)
  const dateRange = React.useMemo(
    () => (token ? getAnalyticsDateRange(token) : OrchestrationAnalyticsDateRangeFactory()),
    [getAnalyticsDateRange, token]
  )

  return { filters, dateRange }
}
