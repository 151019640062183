import * as React from 'react'

import { PermissionButton } from 'components/common/button'

import { AddBlockContainer } from 'com.batch/settings/ui/components/settings-fcm/add-block/add-block.styles'

type Props = {
  onClick: () => void
  isAllowed: boolean
}

export const AddBlock = ({ onClick, isAllowed }: Props): React.ReactElement => {
  return (
    <AddBlockContainer>
      <h3>No Google (FCM) config yet</h3>
      <p>Set up your FCM configuration to start pushing</p>
      <PermissionButton intent="action" kind="primary" onClick={onClick} isAllowed={isAllowed}>
        Add configuration
      </PermissionButton>
    </AddBlockContainer>
  )
}
