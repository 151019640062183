import Immutable from 'immutable'

import { type SmsProviders } from 'com.batch/shared/infra/metadata-service/metadata-service.api'
import {
  SspConfigurationFactory,
  type SspConfigurationRecord,
} from 'com.batch/sms/infra/models/ssp-configuration.record'

export const parseSspConfiguration = (smsProviders: SmsProviders): SspConfigurationRecord => {
  const senders = Immutable.List(smsProviders?.alphanumericSenderIDs ?? [])
  const maxParts = smsProviders?.maxParts ?? 0

  return SspConfigurationFactory({
    senders,
    maxParts,
  })
}
