import * as React from 'react'

import { useToggle } from 'components/_hooks'

import { type ConditionRecord } from 'com.batch.redux/query/query.records'

const isInt = (value: string): boolean =>
  !isNaN(parseInt(value, 10)) && parseInt(value, 10).toString() === value
const isFloat = (value: string): boolean => !isNaN(parseFloat(value))

export function useNumberInputValidation(
  condition: ConditionRecord,
  updateCondition: (arg1: ConditionRecord) => void,
  allowDecimal: boolean = false
): {
  localValue: string
  onBlur: () => void
  isLocalInvalid: boolean
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
} {
  const value = condition.value.number
  const touchedState = useToggle()
  const [localValue, setLocalValue] = React.useState<string>(isNaN(value) ? '' : value.toString())
  const formatter = React.useCallback(
    (value: string) => (allowDecimal ? parseFloat(value) : parseInt(value, 10)),
    [allowDecimal]
  )
  const isInvalid = React.useMemo(
    () => !(allowDecimal ? isFloat(localValue) : isInt(localValue)),
    [allowDecimal, localValue]
  )
  const onChangeCallback = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      console.log('onChangeCallback', evt.target.value, isInt(evt.target.value))
      setLocalValue(evt.target.value)
      updateCondition(
        condition.set(
          'value',
          condition.value.set(
            'number',
            (allowDecimal ? isFloat(evt.target.value) : isInt(evt.target.value))
              ? formatter(evt.target.value)
              : NaN
          )
        )
      )
    },
    [allowDecimal, condition, formatter, updateCondition]
  )
  const onBlur = React.useCallback(() => {
    touchedState.open()
    if (isInvalid) {
      setLocalValue('')
    }
  }, [isInvalid, touchedState])
  return {
    localValue,
    onBlur,
    isLocalInvalid: touchedState.value && isInvalid,
    onChange: onChangeCallback,
  }
}
