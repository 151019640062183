import { get } from 'lodash-es'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Button, ButtonLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Feedback } from 'components/form'
import { LinkArrow } from 'components/styled/text'

import { generateUrl, trimUnsecureDomain } from 'com.batch.common/router'

import * as api from './api'
import { Register2FAIntro } from './register-2fa-intro'
import { Register2FARecovery } from './register-2fa-recovery'
import { Register2FASetup } from './register-2fa-setup'
import { RegWizard } from './register-containers'
import {
  RegisterStep,
  RegisterStepFooter,
  RegisterSuccessLabel,
  RegisterLogOut,
} from './register.styles'

import { Title } from '../auth.styles'

const svgToUri = (svgUri: string) => {
  const blob = new Blob([svgUri], { type: 'image/svg+xml' })
  return URL.createObjectURL(blob)
}

export const Register2FA = ({
  enforce2Fa,
  isInvite,
}: {
  enforce2Fa: boolean
  isInvite: boolean
}): React.ReactElement => {
  const navigate = useNavigate()
  const [step, setStep] = React.useState(0)
  const [loadingEnable, setLoadingEnable] = React.useState(false)
  const [loadingSkip, setLoadingSkip] = React.useState(false)
  const [errorMessages, setErrorMessages] = React.useState<{ message: string }[]>([])
  const [totpUri, setTotpUri] = React.useState<string>('')
  const [qrCodeUri, setQrCodeUri] = React.useState<string | null | undefined>(null)
  const [recoveryCodes, setRecoveryCodes] = React.useState<Array<string>>([])

  const onContinue = () => {
    if (step === 0) {
      setLoadingEnable(true)
      setErrorMessages([])

      api
        .generate2fa()
        .then(({ totpUri, qrCodeSvg }) => {
          setLoadingEnable(false)
          setTotpUri(totpUri)
          setQrCodeUri(svgToUri(qrCodeSvg))
          setStep(1)
        })
        .catch(messages => {
          setLoadingEnable(false)
          setErrorMessages(messages)
        })
    }
    if (step === 2) {
      const params = new URLSearchParams(location.search)
      const redirectTo = params.get('redirect-to')

      if (redirectTo) {
        window.location.href = trimUnsecureDomain(redirectTo)
      } else {
        navigate(isInvite ? '/register/congrats' : generateUrl('register_company'), {
          replace: true,
        })
      }
    }
  }

  const onValidate = (totpCode: string) => {
    return api.validate2fa({ totpUri, totpCode }).then(
      ({ codes }) => {
        setRecoveryCodes(codes)
        setStep(2)
        return true
      },
      messages => messages[0].message
    )
  }

  const onSkip = () => {
    if (enforce2Fa) {
      return
    }

    setLoadingSkip(true)
    setErrorMessages([])

    api
      .skip2fa()
      .then(() => {
        navigate(isInvite ? '/register/congrats' : generateUrl('register_company'), {
          replace: true,
        })
      })
      .catch(messages => {
        setLoadingSkip(false)
        setErrorMessages(messages)
      })
  }

  return (
    <React.Fragment>
      <RegisterLogOut>
        <ButtonLink
          kind="inline"
          addOn="prefix"
          href={generateUrl('logout', {
            _csrf_token: get(document.querySelector('head > meta[name=logout-csrf]'), 'content'),
          })}
        >
          <Icon icon="logout" />
          Log out
        </ButtonLink>
      </RegisterLogOut>

      <RegWizard
        step={isInvite ? 3 : 4}
        total={isInvite ? 3 : 5}
        animate
        noProgress={window.location.search.includes('redirect-to=')}
      >
        <RegisterStep>
          <Title style={{ position: 'relative', margin: '0 0 0 -6px' }}>
            Two-factor auth
            {step === 2 && (
              <RegisterSuccessLabel style={{ position: 'absolute', left: -2, top: -38 }}>
                Enabled
              </RegisterSuccessLabel>
            )}
          </Title>

          {step === 0 && <Register2FAIntro enforce2FA={enforce2Fa} />}
          {step === 1 && <Register2FASetup qrCodeUri={qrCodeUri} validate={onValidate} />}
          {step === 2 && <Register2FARecovery codes={recoveryCodes} />}

          {errorMessages.length > 0 && (
            <Feedback
              type="error"
              style={{ display: 'block', marginTop: '14px' }}
              message={
                <ul>
                  {errorMessages.map((message, index) => (
                    <li key={index}>{message.message}</li>
                  ))}
                </ul>
              }
            />
          )}

          <RegisterStepFooter>
            <Feedback
              type={loadingSkip ? 'loading' : 'insight'}
              message={
                <React.Fragment>
                  <Button
                    onClick={onContinue}
                    disabled={step === 1}
                    intent="action"
                    kind="secondary"
                    isLoading={loadingEnable}
                    style={{ margin: '0 18px 0 0' }}
                  >
                    {step === 0 || step === 1 ? 'Enable' : CodesButton}
                  </Button>
                  {!enforce2Fa && (
                    <LinkSkip onClick={onSkip} active={step <= 1} disabled={enforce2Fa}>
                      I will do it later
                    </LinkSkip>
                  )}
                </React.Fragment>
              }
              style={{ display: 'inline-flex', cursor: 'pointer' }}
            />
          </RegisterStepFooter>
        </RegisterStep>
      </RegWizard>
    </React.Fragment>
  )
}
type LinkSkipProps = {
  active: boolean
  disabled: boolean
}
export const LinkSkip = styled(LinkArrow)<LinkSkipProps>`
  opacity: ${p => (p.active ? 1 : 0)};
  pointer-events: ${p => (p.active ? 'all' : 'none')};
  transition: opacity 0.2s ease;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'inherit')};
`

export const CodesButton: React.ReactNode = (
  <div style={{ display: 'flex', alignItems: 'baseline' }}>
    Okay, I've saved these codes{' '}
    <span style={{ margin: '0 -6px 0 10px', fontSize: '14px', lineHeight: '12px' }}>👍</span>
  </div>
)
