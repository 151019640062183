import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { ThemeProvider } from 'styled-components'

import { Separator } from 'components/app/custom-data/custom-data.styles'
import { Alert } from 'components/common/alert'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button, Switch } from 'components/common/button'
import { ConditionLine, FlexLine, FlexLineItem } from 'components/common/flexline'
import Highlight from 'components/common/highlight'
import { Hint } from 'components/common/hint'
import Loader from 'components/common/loader-legacy'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { Tooltip } from 'components/common/tooltip'
import { FilterSearch } from 'components/filter'
import { LinkDoc } from 'components/styled/text'
import { AttributePicker } from 'components/targeting/attribute-picker'
import { CategoryFilter } from 'components/targeting/category-filter'
import { ClusterBtn } from 'components/targeting/cluster-btn'
import { Logical } from 'components/targeting/logical'
import { ReviewTargeting } from 'components/targeting/review-targeting'

import { config } from 'com.batch.common/config'
import { kformat } from 'com.batch.common/utils'

import { CustomAudiencePicker } from './custom-audience-picker'
import { LangRegionTargeting } from './lang-region-targeting'

import { updateAttributeFilterCat, updateAttributeFilterTerm } from '../../redux/attribute'
import { type AttributeRecord, type State } from 'com.batch.redux/_records'
import {
  currentAppSelector,
  appCanQueryNative,
  appCanUseGeoloc,
  appCanUseCustomAudiences,
  appCanQueryEventData,
  appCanQueryAttribute,
  appCanUseEventCountPeriod,
  appCanRetarget,
  appCanRetargetInApp,
  appCanUseCluster,
} from 'com.batch.redux/app'
import {
  attributesCategoriesSelector,
  targetingAttributeFilteredSelector,
  termSelector,
  usedAttributesValuesLoadingSelector,
  usedAttributesValuesSelector,
} from 'com.batch.redux/attribute.selector'
import { createAttributeValue } from 'com.batch.redux/attributeValue'
import { toggleJIT } from 'com.batch.redux/campaign.action'
import {
  currentCampaign,
  targetingRegionSelector,
  targetingLangSelector,
} from 'com.batch.redux/campaign.selector'
import {
  addCondition,
  addLogical,
  deleteCondition,
  deleteLogical,
  estimateSelector,
  fullTreeSelector,
  toggleCondition,
  toggleLogical,
  updateClusterActiveState,
  updateCustomAudiences,
  updateCondition,
  refreshEstimate,
  updateLangRegion,
} from 'com.batch.redux/targeting'
import {
  customAudiencesSelector,
  clustersTargeting,
  pickedLanguagesSelector,
  pickedRegionsSelector,
} from 'com.batch.redux/targeting.selector.composed'

const MAX_CONDITIONS = 31

export type EstimateMode = 'installs' | 'tokens'

type Props = {
  estimateMode: EstimateMode
  header: string
}
const conditionsCountSelector = (state: State): number =>
  state.targeting.get('conditions')?.size ?? 0
const queryErrorSelector = (state: State) => state.targeting.get('queryError', false)
const querySelector = (state: State) =>
  state.targeting.get('query') ? JSON.stringify(state.targeting.get('query'), null, 2) : ''
const awaitingParseSelector = (state: State) => state.targeting.get('awaitingParse', false)
export const TargetingForm: React.ComponentType<Props> = React.memo(
  ({ estimateMode, header }: Props) => {
    // redux state
    const campaign = useSelector(currentCampaign)
    const clusters = useSelector(clustersTargeting)
    const app = useSelector(currentAppSelector)
    const [platform, ttlRetargeting] = React.useMemo(
      () => [app.platform, app.ttlRetargeting ?? 14],
      [app]
    )
    const pickedLanguages = useSelector(pickedLanguagesSelector)
    const pickedRegions = useSelector(pickedRegionsSelector)
    const pickedAudiences = useSelector(customAudiencesSelector)
    const languages = useSelector(targetingLangSelector)
    const regions = useSelector(targetingRegionSelector)
    const tree = useSelector(fullTreeSelector)
    const queryError = useSelector(queryErrorSelector)
    const query = useSelector(querySelector)
    const loading = useSelector(awaitingParseSelector)
    const estimate = useSelector(estimateSelector)
    const conditionsCount = useSelector(conditionsCountSelector)

    const canQuery = useSelector(appCanQueryNative)
    const canQueryAttribute = useSelector(appCanQueryAttribute)
    const canRetarget = useSelector(appCanRetarget)
    const canRetargetInApp = useSelector(appCanRetargetInApp)
    const canQueryEventData = useSelector(appCanQueryEventData)
    const canUseCustomAudiences = useSelector(appCanUseCustomAudiences)
    const canUseCluster = useSelector(appCanUseCluster)
    const canUseGeoloc = useSelector(appCanUseGeoloc)
    const canUseEventCountPeriod = useSelector(appCanUseEventCountPeriod)

    const attributes = useSelector(targetingAttributeFilteredSelector)
    const attributesValues = useSelector(usedAttributesValuesSelector)
    const attributesValuesLoading = useSelector(usedAttributesValuesLoadingSelector)
    const filterTerm = useSelector(termSelector)
    const attributesCategories = useSelector(attributesCategoriesSelector)
    // redux dispatched actions
    const dispatch = useDispatch()
    const addConditionBound = React.useCallback(p => dispatch(addCondition(p)), [dispatch])
    const updateLangRegionBound = React.useCallback(
      (value: Array<langType>, isRegion: boolean) => dispatch(updateLangRegion(value, isRegion)),
      [dispatch]
    )
    const addLogicalBound = React.useCallback(p => dispatch(addLogical(p)), [dispatch])
    const deleteLogicalBound = React.useCallback(p => dispatch(deleteLogical(p)), [dispatch])
    const deleteConditionBound = React.useCallback(
      (p: string) => dispatch(deleteCondition(p)),
      [dispatch]
    )
    const toggleConditionBound = React.useCallback(
      (id: string) => dispatch(toggleCondition(id)),
      [dispatch]
    )
    const toggleLogicalBound = React.useCallback(
      p => {
        dispatch(toggleLogical(p))
      },
      [dispatch]
    )

    const createAttributeValueBound = React.useCallback(
      (a: string, b: string, c?: string) => {
        dispatch(createAttributeValue(a, b, c))
      },
      [dispatch]
    )
    const updateFilterCatBound = React.useCallback(
      (p: string | null) => {
        dispatch(updateAttributeFilterCat(p ?? ''))
      },
      [dispatch]
    )
    const updateFilterTermBound = React.useCallback(
      (p: string) => {
        dispatch(updateAttributeFilterTerm(p))
      },
      [dispatch]
    )
    const updateClusterActiveStateBound = React.useCallback(
      (cluster, isActive) => {
        dispatch(updateClusterActiveState(cluster, isActive))
      },
      [dispatch]
    )
    const updateCustomAudiencesBound = React.useCallback(
      p => dispatch(updateCustomAudiences(p)),
      [dispatch]
    )
    const updateConditionBound = React.useCallback(
      (p: { id: any; changes: any }) => dispatch(updateCondition(p)),
      [dispatch]
    )
    const refreshEstimateBound = React.useCallback(() => dispatch(refreshEstimate()), [dispatch])
    const toggleJITBound = React.useCallback(() => dispatch(toggleJIT()), [dispatch])

    // === END BOILERPLATE ===================

    const [pickerOpened, setPickerOpened] = React.useState(false)
    const [display, setDisplay] = React.useState('edit')
    const [pickedAttributes, setPickedAttributes] = React.useState<Set<AttributeRecord>>(
      Immutable.Set()
    )
    const [pickerPosition, setPickerPosition] = React.useState<any>(undefined)
    const createOnDispplayChange = React.useCallback((kind: string) => () => setDisplay(kind), [])
    const openPicker = React.useCallback((pickerPosition: string = 'root') => {
      setPickerOpened(true)
      setPickedAttributes(Immutable.Set())
      setPickerPosition(pickerPosition)
    }, [])

    const closePicker = React.useCallback(() => {
      setPickerOpened(false)
      setPickedAttributes(Immutable.Set())
      setPickerPosition(undefined)
    }, [])

    const addConditionForSelectedAttrs = React.useCallback(() => {
      pickedAttributes.forEach(attr => {
        addConditionBound({
          attribute: attr,
          logicalId: pickerPosition,
        })
      })
      closePicker()
    }, [addConditionBound, closePicker, pickedAttributes, pickerPosition])

    const onToggleAttr = React.useCallback(
      (attr: AttributeRecord) => {
        if (pickedAttributes.has(attr)) {
          setPickedAttributes(pickedAttributes.remove(attr))
        } else {
          setPickedAttributes(pickedAttributes.add(attr))
        }
      },
      [pickedAttributes]
    )

    const onKeyUp = React.useCallback(
      (event: KeyboardEvent) => {
        if (event.code === 'Escape') {
          closePicker()
        }
      },
      [closePicker]
    )

    React.useEffect(() => {
      window.addEventListener('keyup', onKeyUp)
      return () => {
        window.removeEventListener('keyup', onKeyUp)
      }
    }, [onKeyUp])

    const renderLangRegion = React.useCallback(
      (isLang: boolean) => {
        const options = isLang ? languages : regions
        const values = isLang ? pickedLanguages : pickedRegions
        return (
          <LangRegionTargeting
            loading={false}
            lang={isLang}
            hasQuery={!!query}
            updateLangRegion={updateLangRegionBound}
            // $FlowExpectedError
            options={options}
            // $FlowExpectedError
            values={values}
          />
        )
      },
      [languages, pickedLanguages, pickedRegions, query, regions, updateLangRegionBound]
    )

    const renderJIT = React.useCallback(() => {
      return (
        <FlexLine>
          <FlexLineItem
            grow={1}
            style={{
              borderBottom: '1px solid #E9E9E9',
              marginBottom: 16,
              paddingBottom: 11,
              paddingTop: 8,
            }}
          >
            <ThemeProvider theme={{ horizontal: true }}>
              <Switch isActive={campaign.inappJustInTime} onChange={toggleJITBound}>
                Re-evaluate targeting just before display{' '}
                <Hint minTooltipWidth={300}>
                  Use this option for scenarios with sensitive targeting conditions, prone to change
                  during user navigation. It will result in a slight delay in display. (SDK version
                  1.19 and above)
                </Hint>
              </Switch>
            </ThemeProvider>
          </FlexLineItem>
        </FlexLine>
      )
    }, [campaign.inappJustInTime, toggleJITBound])

    const renderEstimate = React.useCallback(() => {
      const mode = estimateMode
      const est = estimate.get(mode)
      const err = estimate.get('error', false)
      const matchingNotifOn = est.get(mode === 'tokens' ? 'matchingNotifOn' : 'matching', 0)
      const matching = est.get('matching', 0)

      return (
        <React.Fragment>
          {mode === 'installs' && app.features.has('inapp-just-in-time') && renderJIT()}
          <FlexLine>
            <FlexLineItem>
              <Tooltip
                tooltip={
                  err
                    ? 'Unable to estimate'
                    : `This campaign might reach ${
                        mode === 'tokens' && matchingNotifOn
                          ? matchingNotifOn.toLocaleString()
                          : matching.toLocaleString().toLocaleString()
                      } ${mode === 'tokens' && matchingNotifOn ? 'optin' : ''} users`
                }
              >
                <div>
                  <h3 className="audience__title">Estimated reach</h3>
                  <div className="audience__reach">
                    <Loader
                      overlay={false}
                      height={26}
                      size="tiny"
                      left
                      loading={estimate.get('loading')}
                    >
                      <strong>
                        {err ? 'Unable to estimate' : kformat(est.get('matching', ''))}{' '}
                        {!err && mode}
                      </strong>{' '}
                      /&nbsp;
                      {!err && kformat(est.get('total', ''))}{' '}
                      {err ? '' : mode === 'tokens' ? 'total push tokens' : 'total installs'}
                      {!!err && (
                        <Button intent="action" kind="primary" onClick={refreshEstimateBound}>
                          <span style={{ marginTop: '-2px' }}>Retry</span>
                        </Button>
                      )}
                    </Loader>
                  </div>
                </div>
              </Tooltip>
            </FlexLineItem>
          </FlexLine>
        </React.Fragment>
      )
    }, [app.features, estimate, estimateMode, refreshEstimateBound, renderJIT])

    const count = pickedAttributes.size
    const ratio =
      clusters.size < 6 ? 100 / clusters.size : clusters.size === 7 ? 25 : 200 / clusters.size

    const DisplayMode = (
      <TableToggle>
        <TableToggleItem
          style={{ margin: 0 }}
          onClick={createOnDispplayChange('edit')}
          active={display === 'edit'}
        >
          Edit
        </TableToggleItem>
        <TableToggleItem onClick={createOnDispplayChange('review')} active={display === 'review'}>
          Review
        </TableToggleItem>
        <TableToggleItem
          onClick={createOnDispplayChange('query')}
          active={display === 'query'}
          disabled={!query}
        >
          Query
        </TableToggleItem>
      </TableToggle>
    )
    return (
      <Box>
        <BoxHeader>
          <HeaderBoxTitle title={header} />
          <HeaderBoxActions>{DisplayMode}</HeaderBoxActions>
        </BoxHeader>

        {display === 'edit' && (
          <div>
            <ConditionLine>
              <FlexLineItem grow={1} style={{ margin: '18px 0px 10px 30px' }}>
                {canUseCluster ? (
                  <div className="clustContainer">
                    {clusters.map((c, i) => {
                      return (
                        <Tooltip tooltip={c.get('desc')} key={`tt-${c.get('code')}`}>
                          <ClusterBtn
                            code={c.get('code')}
                            label={c.get('name')}
                            ratio={clusters.size === 7 && i > 3 ? 33.3333 : ratio}
                            handler={updateClusterActiveStateBound}
                            active={c.get('active')}
                          />
                        </Tooltip>
                      )
                    })}
                  </div>
                ) : (
                  <div className="review__upgrade ng-scope" style={{ marginTop: 0 }}>
                    <span>
                      <i className="fa fa-lock mr-2x" />
                      <strong>Smart segments</strong> targeting is only available to Developer plan.
                    </span>{' '}
                    <a
                      className="btn btn--action"
                      href={config.billing.urls.overviewPage.replace(
                        '{companyId}',
                        app.companyId?.toString() ?? ''
                      )}
                    >
                      Upgrade now
                    </a>
                  </div>
                )}
              </FlexLineItem>
            </ConditionLine>
            {canUseCustomAudiences && (
              <CustomAudiencePicker
                appId={app.id}
                values={pickedAudiences}
                updateCustomAudiences={updateCustomAudiencesBound}
              />
            )}
            {renderLangRegion(false)}
            {renderLangRegion(true)}
            <Popin
              opened={pickerOpened}
              close={closePicker}
              style={{ maxWidth: '998px', width: '100%', minHeight: '600px' }}
            >
              <Box>
                <BoxHeader style={{ borderBottom: 'none' }}>
                  <HeaderBoxTitle title="Pick additional targetings" />
                  <HeaderBoxActions>
                    <LinkDoc
                      href="https://doc.batch.com/guides/custom-data.html"
                      intent="action"
                      target="_blank"
                    >
                      Help
                    </LinkDoc>
                    <Separator style={{ marginLeft: 0 }} />
                    <Button onClick={closePicker} style={{ width: 36 }}>
                      <Icon icon="close" />
                    </Button>
                  </HeaderBoxActions>
                </BoxHeader>
                <BoxBody>
                  <FlexLine sameHeight className="popin__content__targeting">
                    <FlexLineItem width={320}>
                      <CategoryFilter
                        app={app}
                        categories={attributesCategories}
                        updateCat={updateFilterCatBound}
                      />
                    </FlexLineItem>
                    <FlexLineItem grow={1}>
                      <div
                        className="tab-content"
                        style={{ height: '100%', width: '100%', overflowY: 'auto' }}
                      >
                        <FilterSearch
                          value={filterTerm}
                          placeholder="Search additional targetings..."
                          onChange={updateFilterTermBound}
                          expandable={false}
                          style={{ marginBottom: 10 }}
                        />
                        <AttributePicker
                          app={app}
                          attributes={attributes}
                          canQuery={canQuery}
                          canQueryAttribute={canQueryAttribute}
                          canUseAudience={canUseCustomAudiences}
                          canRetarget={canRetarget}
                          canRetargetInApp={canRetargetInApp}
                          canUseGeoloc={canUseGeoloc}
                          conditionsCount={conditionsCount}
                          selectedSet={pickedAttributes}
                          toggleAttr={onToggleAttr}
                        />
                      </div>
                    </FlexLineItem>
                  </FlexLine>
                </BoxBody>
                <BoxFooter isEditable>
                  <Button kind="inline" onClick={closePicker}>
                    Cancel
                  </Button>
                  <FooterBoxActions>
                    {conditionsCount >= MAX_CONDITIONS ? (
                      <span>Limit reached : max {MAX_CONDITIONS} conditions</span>
                    ) : null}

                    <Button
                      kind="primary"
                      intent="action"
                      onClick={addConditionForSelectedAttrs}
                      disabled={count === 0}
                    >
                      {`Add condition${count > 1 ? `s (${count})` : ''}`}
                    </Button>
                  </FooterBoxActions>
                </BoxFooter>
              </Box>
            </Popin>
            <Loader loading={loading} height={60}>
              {queryError ? (
                <div className="stepbox__content">
                  <Alert kind="error" icon="danger">
                    <p>We were unable to parse your query.</p>
                    <p style={{ marginBottom: '20px' }}>
                      This may be an error on your side, or you could be using features only
                      available on the API. You can still edit the campaign, we'll keep the query as
                      it currently is:
                    </p>
                    <Highlight language="js">{query}</Highlight>
                  </Alert>
                </div>
              ) : (
                <Logical
                  root={tree}
                  app={app}
                  ttlRetargeting={ttlRetargeting}
                  canUseEventCountPeriod={canUseEventCountPeriod}
                  canQueryEventData={canQueryEventData}
                  platform={platform}
                  logical={tree.get('value')}
                  openPicker={openPicker}
                  deleteLogical={deleteLogicalBound}
                  addLogical={addLogicalBound}
                  toggleLogical={toggleLogicalBound}
                  toggleCondition={toggleConditionBound}
                  attributesValues={attributesValues}
                  attributesValuesLoading={attributesValuesLoading}
                  createAttributeValue={createAttributeValueBound}
                  updateCondition={updateConditionBound}
                  deleteCondition={deleteConditionBound}
                />
              )}
            </Loader>
          </div>
        )}
        {display === 'review' && <ReviewTargeting />}
        {display === 'query' && (
          <div className="stepbox__content" style={{ minHeight: '240px' }}>
            <Highlight language="js">{query}</Highlight>
          </div>
        )}
        {query && clusters.filter(c => c.code === 'I' && c.active).size === 1 && (
          <div style={{ margin: '20px 20px 0 20px' }}>
            <Alert icon="danger" kind="warning">
              Your targeting uses both <strong>imported users</strong> and{' '}
              <strong>conditions</strong>. While this is supported, this can lead to unexpected
              results. Please make sure the estimated reach matches your expectations.
            </Alert>
          </div>
        )}
        <BoxFooter
          isEditable
          style={{
            height:
              estimateMode === 'installs' && app.features.has('inapp-just-in-time')
                ? 'auto'
                : '76px',
            display:
              estimateMode === 'installs' && app.features.has('inapp-just-in-time')
                ? 'block'
                : 'flex',
            padding: '10px 20px',
          }}
        >
          {renderEstimate()}
        </BoxFooter>
      </Box>
    )
  }
)
