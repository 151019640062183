import * as React from 'react'
import styled from 'styled-components'

import { Icon, type availableIcons } from 'components/common/svg-icon'

export type TagType = 'main' | 'label'
type TagProps = {
  type?: TagType
  icon?: availableIcons
  children: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLElement>) => any
  className?: string
  style?: any
}

export const Tag = React.forwardRef<HTMLSpanElement, TagProps>(
  ({ type = 'main', icon, children, onClick, className, style }: TagProps, ref) => {
    const color = Colors[type]
    return (
      <TagContainer
        rgbColor={color}
        onClick={onClick}
        ref={ref}
        className={className}
        style={style}
      >
        {icon && <IconStyled icon={icon} size={14} />}
        {children}
      </TagContainer>
    )
  }
)

export const Colors: { [key in TagType]: [number, number, number] } = {
  main: [197, 177, 50],
  label: [236, 25, 49],
}
const hsla = (h: number, s: number, l: number, a: number): string =>
  `hsla(${h}, ${s}%, ${l}%, ${a})`

type TagContainerProps = {
  rgbColor: [number, number, number]
  onClick?: any
}
export const TagContainer = styled.span<TagContainerProps>`
  display: inline-flex;
  align-items: center;
  padding: 5px 9px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: ${props => hsla(props.rgbColor[0], props.rgbColor[1], props.rgbColor[2] - 8, 1)};
  background-color: ${props => hsla(props.rgbColor[0], props.rgbColor[1], props.rgbColor[2], 0.08)};
  white-space: nowrap;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};

  & + & {
    margin: 0 0 0 3px;
  }
`

const IconStyled = styled(Icon)`
  margin: 0 9px 2px 2px;
`

export default Tag
