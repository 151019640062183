import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { getEmailContentForActiveLanguageSelector } from 'com.batch/email/store/email-automation.selector'
import { previewLanguageSelector } from 'com.batch/message/store/message.selector'
import {
  inlineEditorConfigSelector,
  isFieldActiveSelector,
} from 'com.batch/message-builder/store/inline-editor.selector'
import { getPushContentForActiveLanguageSelector } from 'com.batch/push/store/push.selector'
import { getSmsContentForActiveLanguageSelector } from 'com.batch/sms/store/sms.selector'
import { TemplateCacheSelector } from 'com.batch.redux/template'

import { updateEmailContent } from 'com.batch/email/usecases/update-email-content'
import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import { InlineEditor } from 'com.batch/message-builder/ui/components/inline-editor/inline-editor'
import {
  blurInlineEditorAction,
  setInlineEditor,
} from 'com.batch/message-builder/usecases/manage-inline-editor'
import { updatePushMessageContent } from 'com.batch/push/usecases/update-push-content'
import { updateSmsContent } from 'com.batch/sms/usecases/update-sms-content'
import {
  EmailTemplatesFactory,
  PushContentTemplatesFactory,
  SmsTemplatesFactory,
} from 'com.batch/message/models/message.records'

type EditableFieldProps = {
  messageId: string
  field: 'smsMessage' | 'pushTitle' | 'pushBody' | 'subject' | 'replyTo'
  preventAllCariageReturn?: boolean
  inlinePersonalizationButton?: boolean
  label?: string
}

export const EditableField = ({
  messageId,
  field,
  preventAllCariageReturn,
  inlinePersonalizationButton,
  label,
}: EditableFieldProps): React.ReactElement => {
  const dispatch = useDispatch()
  const inlineEditorConfig = useSelector(inlineEditorConfigSelector)
  const lang = useSelector(previewLanguageSelector)
  const templateCache = useSelector(TemplateCacheSelector)
  const isActive = useSelector(isFieldActiveSelector(field))
  const getSmsContent = useSelector(getSmsContentForActiveLanguageSelector)
  const getPushContent = useSelector(getPushContentForActiveLanguageSelector)
  const getEmailContent = useSelector(getEmailContentForActiveLanguageSelector)

  const onBlur = React.useCallback(
    selectionTuple => {
      dispatch(blurInlineEditorAction({ field, variant: 'a', selection: selectionTuple }))
    },
    [dispatch, field]
  )

  const setEditing = React.useCallback(
    (editing: MessageBuilderEditableField, caret: number) => {
      const newConfig = inlineEditorConfig
        .set('field', editing)
        .set('variant', 'a')
        .set('selection', [caret, caret])

      dispatch(setInlineEditor(newConfig))
    },
    [inlineEditorConfig, dispatch]
  )

  const updateContent = React.useCallback(
    (value: string) => {
      switch (field) {
        // SMS Fields
        case 'smsMessage':
          dispatch(
            updateSmsContent({
              messageId,
              lang,
              field,
              value,
              isInstant: false,
            })
          )
          break
        // Push fields
        case 'pushTitle':
        case 'pushBody':
          dispatch(
            updatePushMessageContent({
              messageId,
              lang,
              parent: 'content',
              field,
              value,
            })
          )
          break
        case 'subject':
        case 'replyTo':
          dispatch(
            updateEmailContent({
              messageId,
              lang,
              field,
              value,
            })
          )
          break
        default:
          return
      }
    },
    [field, lang, dispatch, messageId]
  )

  const pushContent = React.useMemo(() => getPushContent(messageId), [getPushContent, messageId])
  const smsContent = React.useMemo(() => getSmsContent(messageId), [getSmsContent, messageId])
  const emailContent = React.useMemo(() => getEmailContent(messageId), [getEmailContent, messageId])

  const onFocus = React.useCallback(() => {
    setEditing(field, 0)
  }, [field, setEditing])

  const onActivateAtPos = React.useCallback(
    (pos: number) => {
      let value
      switch (field) {
        case 'smsMessage':
          value = smsContent.get(field)
          break
        case 'pushTitle':
        case 'pushBody':
          value = pushContent.content.get(field)
          break
        case 'subject':
        case 'replyTo':
          value = emailContent.get(field)
          break
      }
      setEditing(field, value ? pos : 0)
    },
    [setEditing, field, smsContent, pushContent, emailContent]
  )

  const onChange = React.useCallback(
    (computedText: string) => {
      updateContent(computedText)
    },
    [updateContent]
  )

  const fieldValue = React.useMemo(() => {
    switch (field) {
      case 'smsMessage':
        return isActive
          ? smsContent.get(field)
          : smsContent.get('templates', SmsTemplatesFactory()).get(field) ?? ''
      case 'pushTitle':
      case 'pushBody':
        return isActive
          ? pushContent.content.get(field)
          : pushContent.content.get('templates', PushContentTemplatesFactory()).get(field) ?? ''
      case 'subject':
      case 'replyTo':
        return isActive
          ? emailContent.get(field) ?? ''
          : emailContent.get('templates', EmailTemplatesFactory()).get(field) ?? ''
      default:
        return ''
    }
  }, [field, isActive, smsContent, pushContent, emailContent])

  const placeholder = React.useMemo(() => {
    switch (field) {
      case 'pushTitle':
        return 'Click to add title'
      case 'subject':
        return 'Email subject'
      case 'replyTo':
        return 'support@yourcompany.com (optional)'
      default:
        return 'Click to add text'
    }
  }, [field])

  return (
    <InlineEditor
      messageId={messageId}
      selection={inlineEditorConfig.selection}
      onBlur={onBlur}
      canUseTemplating
      field={field}
      onFocus={onFocus}
      variant="a"
      isActive={isActive}
      onActivateAtPos={onActivateAtPos}
      onChange={onChange}
      placeholder={placeholder}
      value={fieldValue}
      templateCache={templateCache}
      preventAllCariageReturn={preventAllCariageReturn}
      inlinePersonalizationButton={inlinePersonalizationButton}
      label={label}
      hasCharCount={field === 'subject'}
    />
  )
}
