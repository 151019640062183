import {
  createPopper,
  type Instance,
  type OptionsGeneric,
  type StrictModifiers,
  type Modifier,
} from '@popperjs/core'
import * as React from 'react'

type ReactRef<T> = {
  current: T | null
}

/*
  very rough helper to use popper : pass 2 element ref & profit
  could be improved with more config
*/
export const usePopper = (
  opt: Partial<OptionsGeneric<StrictModifiers>>
): [ReactRef<any>, ReactRef<any>, Instance | null] => {
  const triggerRef = React.useRef<null | HTMLElement>(null)
  const popperRef = React.useRef<null | HTMLElement>(null)
  const popperInstanceRef = React.useRef<null | Instance>(null)
  React.useEffect(() => {
    if (triggerRef.current && popperRef.current) {
      popperInstanceRef.current = createPopper<StrictModifiers>(
        triggerRef.current,
        popperRef.current,
        opt
      )
    }
  }, [triggerRef, popperRef, opt])

  return [triggerRef, popperRef, popperInstanceRef.current]
}

export const sameWidth: Modifier<any, any> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    if ('offsetWidth' in state.elements.reference) {
      const reference = state.elements.reference as HTMLElement
      state.elements.popper.style.width = `${reference.offsetWidth}px`
    }
  },
}

export const minWidth: Modifier<any, any> = {
  name: 'minWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.minWidth = `${state.rects.reference.width + 20}px`
  },
  effect: ({ state }) => {
    if ('offsetWidth' in state.elements.reference) {
      const reference = state.elements.reference as HTMLElement
      state.elements.popper.style.minWidth = `${reference.offsetWidth + 20}px`
    }
  },
}
