/* eslint-disable react/jsx-no-bind */
import Immutable, { type List, type Map } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'
import { type State } from 'com.batch.redux/_records'
import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import {
  BoxBody,
  BoxFooter,
  BoxHeader,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button, PermissionButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Grid } from 'components/common/grid'
import { trackEvent } from 'components/common/page-tracker'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { FilterSearch, FilterSelectMulti } from 'components/filter'

import { ThemeThumb } from './theme-thumb'
import { ThemeFormatsList, AppOption, type ThemeFormat } from './theme-utils'
import { ThemeModalBox, Separator, ThemeFilterEmptyContainer } from './theme.styles'

import { type AppRecord } from 'com.batch.redux/_records'
import { type InAppContentRecord } from 'com.batch.redux/content.records'
import { attachThemes } from 'com.batch.redux/theme'
import { type ThemeRecord } from 'com.batch.redux/theme.records'

type OtherThemeModalProps = {
  app: AppRecord
  themes: List<ThemeRecord>
  data: InAppContentRecord
  opened: boolean
  close: () => any
}

export const OtherThemeModal = ({
  app,
  themes,
  data,
  opened,
  close,
}: OtherThemeModalProps): React.ReactElement => {
  const dispatch = useDispatch()

  // ====================== Redux states
  const apps = useSelector((state: State) => state.app.entities)

  // ====================== Component states
  const [searchTheme, setSearchTheme] = React.useState<string>('')
  const [filterApps, setfilterApps] = React.useState<List<AppRecord>>(Immutable.List())
  const [filterFormats, setFilterFormats] = React.useState<List<ThemeFormat>>(Immutable.List())

  const [themeToAttach, setThemeToAttach] = React.useState<Map<number | 'new', ThemeRecord>>(
    Immutable.Map()
  )

  React.useEffect(() => {
    if (!opened) {
      if (searchTheme) setSearchTheme('')
      if (filterApps.size > 0) setfilterApps(Immutable.List())
      if (filterFormats.size > 0) setFilterFormats(Immutable.List())
      if (themeToAttach.size > 0) setThemeToAttach(Immutable.Map())
    }
  }, [opened, searchTheme, filterApps, filterFormats, themeToAttach])

  // ====================== Component constants
  const filteredThemes = React.useMemo(() => {
    return themes.filter(
      theme =>
        theme.name.toLowerCase().includes(searchTheme.toLowerCase()) &&
        (filterFormats.size === 0 || filterFormats.some(f => f.value === theme.payloadVars.kind)) &&
        (filterApps.size === 0 || filterApps.some(f => theme.apps.includes(f.id)))
    )
  }, [themes, searchTheme, filterFormats, filterApps])

  const searchInProgress: boolean =
    searchTheme.length > 0 || filterApps.size > 0 || filterFormats.size > 0

  const filteredAppList = React.useMemo(() => {
    return apps.filter(a => a.id !== app.id && (a.platform === 'ios' || a.platform === 'android'))
  }, [apps, app])

  // ====================== Callbacks
  const attach = React.useCallback(() => {
    dispatch(attachThemes({ themes: themeToAttach, appId: app.id }))
      .then(() => {
        setThemeToAttach(Immutable.Map())
        close()
      })
      .catch(() => {})
  }, [app.id, close, dispatch, themeToAttach])

  const submit = React.useCallback(() => {
    const selectCampaignRunning: boolean = themeToAttach.some(theme => theme.nbCampaignsRunning > 0)

    if (selectCampaignRunning) {
      confirm({
        title: ` Attach ${themeToAttach.size} theme${themeToAttach.size > 1 ? 's' : ''}?`,
        message: (
          <React.Fragment>
            <p>
              Attaching a theme to this app allows you to use it on your campaigns, without creating
              a copy.
            </p>
            <p>
              To ensure In-App message consistency, you won't be able to edit a theme with running
              campaign(s).
            </p>
          </React.Fragment>
        ),
        confirm: 'Yes, attach it',
      }).then(() => attach())
    } else attach()
  }, [attach, themeToAttach])

  const isAllowedToAttachTheme = useIsCurrentUserAllowedTo(['app', 'themes:write'])

  const firstApp = React.useMemo(() => filterApps?.first(), [filterApps])
  const firstThemeFormat = React.useMemo(() => ThemeFormatsList?.first(), [])

  return (
    <Popin opened={opened} close={close}>
      <ThemeModalBox>
        <BoxHeader>
          <HeaderBoxTitle title="Browse theme from other apps" />
          <HeaderBoxActions>
            <FilterSearch
              placeholder="Search theme"
              value={searchTheme}
              onChange={v => {
                setSearchTheme(v)
                trackEvent('THEMES_FILTERED', {
                  filter_type: 'search',
                  filter_source: 'theme_modal',
                })
              }}
              expandedMaxWidth={240}
            />
            <FilterSelectMulti
              // style={{ width: '150px' }}
              placeholder="All apps"
              term={count => (count === 1 && firstApp ? firstApp.name : `${count} apps`)}
              options={filteredAppList}
              isSearchable
              value={filterApps}
              optionMenuHeight={40}
              optionToString={app => (app ? app.name + app.id : '')}
              onChange={opts => {
                if (opts) setfilterApps(opts)
                trackEvent('THEMES_FILTERED', {
                  filter_type: 'app',
                  filter_source: 'theme_modal',
                })
              }}
              optionFormatter={(app: AppRecord) => {
                const count = themes.filter(t => t.apps.has(app.id))
                return AppOption(app, count.size)
              }}
              menuOffset={90}
            />

            <FilterSelectMulti
              placeholder="All formats"
              term={count =>
                count === 1 && firstThemeFormat ? firstThemeFormat.label : `${count} apps`
              }
              optionToString={format => (format ? format.label : '')}
              options={ThemeFormatsList}
              value={filterFormats}
              onChange={opts => {
                if (opts) setFilterFormats(opts)
                trackEvent('THEMES_FILTERED', {
                  filter_type: 'format',
                  filter_source: 'theme_modal',
                })
              }}
              optionFormatter={opt => (
                <React.Fragment>
                  {opt.label} ({themes.filter(f => f.payloadVars.kind === opt.value).size})
                </React.Fragment>
              )}
            />
            <Separator style={{ marginLeft: 0 }} />
            <Button onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>

        <BoxBody $padding>
          {filteredThemes.size === 0 && searchInProgress ? (
            <ThemeFilterEmptyContainer kind="modal">
              <h3>No results</h3>
              <p>There’s nothing matching your criterias, try something else</p>
            </ThemeFilterEmptyContainer>
          ) : (
            <Grid template="200px 200px 200px 200px">
              {filteredThemes.map(theme => (
                <ThemeThumb
                  key={theme.id}
                  mode="modal"
                  theme={theme}
                  app={app}
                  data={data}
                  isChecked={themeToAttach.has(theme.id)}
                  check={() => {
                    if (themeToAttach.has(theme.id))
                      setThemeToAttach(themeToAttach.delete(theme.id))
                    else setThemeToAttach(themeToAttach.set(theme.id, theme))
                  }}
                />
              ))}
            </Grid>
          )}
        </BoxBody>

        <BoxFooter isEditable>
          <Button kind="inline" intent="neutral" onClick={close}>
            Cancel
          </Button>
          <div>
            <PermissionButton
              type="button"
              kind="primary"
              intent="action"
              disabled={themeToAttach.size === 0}
              onClick={submit}
              isAllowed={isAllowedToAttachTheme}
            >
              Attach theme{themeToAttach.size > 1 && 's'}
            </PermissionButton>
          </div>
        </BoxFooter>
      </ThemeModalBox>
    </Popin>
  )
}
