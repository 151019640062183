import { animated, config, useTransition } from '@react-spring/web'
import * as React from 'react'

import { type StateToggler } from 'components/_hooks'
import {
  BoxBody,
  BoxHeader,
  HeaderBoxTitle,
  HeaderBoxActions,
  BoxFooter,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import {
  DeleteCompanyModalContainer,
  StepsWrapper,
} from 'components/console/company/delete-company-modal/delete-company-modal.styles'
import { ResumeStep } from 'components/console/company/delete-company-modal/resume-step'
import { SuccessStep } from 'components/console/company/delete-company-modal/success-step'
import { TwoFactorAuthStep } from 'components/console/company/delete-company-modal/two-factor-auth-step'

import { type CompanyRecord } from 'com.batch.redux/_records'

type Props = {
  state: StateToggler
  company: CompanyRecord
}

export const DeleteCompanyModal = ({ state, company }: Props): React.ReactElement => {
  const [step, setStep] = React.useState<number>(1)
  const [code, setCode] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [companyIdValue, setCompanyIdValue] = React.useState<number>(0)
  const [error, setError] = React.useState<string>('')
  const [companyName, setCompanyName] = React.useState<string>('')

  const transitions = useTransition(step, {
    initial: {
      opacity: 1,
    },
    from: {
      opacity: 0,
    },
    enter: () => async (next: any) => {
      await new Promise((resolve: (result: Promise<undefined> | undefined) => void) =>
        setTimeout(resolve, 50)
      )
      await next({ opacity: 1 })
    },
    leave: { position: 'absolute', opacity: 0 },
    config: config.slow,
  })

  const handleOnCompanyIdChange = React.useCallback(
    e => {
      setCompanyIdValue(parseInt(e.target.value, 10))
    },
    [setCompanyIdValue]
  )

  const handleOnCompanyNameChange = React.useCallback(
    e => {
      setCompanyName(e.target.value)
    },
    [setCompanyName]
  )

  const handleOnClose = React.useCallback(() => {
    if (!isLoading) state.close()
  }, [isLoading, state])

  React.useEffect(() => {
    if (companyIdValue === company.id && companyName.toLowerCase() === company.name.toLowerCase()) {
      setStep(2)
    }
  }, [company, companyIdValue, companyName])

  React.useEffect(() => {
    if (!state.value) {
      setStep(1)
      setIsLoading(false)
      setCompanyIdValue(0)
      setCompanyName('')
      setCode('')
      setError('')
    }
  }, [state.value])

  const getStep = (item: number) => {
    switch (item) {
      case 3:
        return <SuccessStep close={state.close} />
      case 2:
        return (
          <TwoFactorAuthStep
            step={step}
            setStep={setStep}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            companyIdValue={companyIdValue}
            code={code}
            setCode={setCode}
            setError={setError}
            companyId={company.id}
            error={error}
          />
        )
      default:
        return (
          <ResumeStep
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isOpen={step === 1 && state.value}
            companyId={company.id}
            companyIdValue={companyIdValue}
            onCompanyIdValueChange={handleOnCompanyIdChange}
            companyName={companyName}
            onCompanyNameChange={handleOnCompanyNameChange}
          />
        )
    }
  }

  return (
    <Popin close={handleOnClose} opened={state.value} style={{ overflowY: 'hidden', padding: 0 }}>
      <DeleteCompanyModalContainer>
        <BoxHeader>
          <HeaderBoxTitle title="Delete the company" />
          <HeaderBoxActions>
            {step !== 3 && (
              <Button style={{ margin: 0 }} onClick={state.close} disabled={isLoading}>
                <Icon icon="close" />
              </Button>
            )}
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          {step !== 3 && (
            <p>
              This action will permanently delete the company{' '}
              <strong>
                {company.name} ({company.id})
              </strong>{' '}
              and all its data. Are you sure you want to proceed?
            </p>
          )}

          <StepsWrapper>
            {transitions((props, item) => (
              <animated.div style={props}>{getStep(item)}</animated.div>
            ))}
          </StepsWrapper>
        </BoxBody>
        <BoxFooter>
          {step !== 3 && (
            <Button kind="inline" intent="neutral" onClick={state.close} disabled={isLoading}>
              Cancel
            </Button>
          )}
        </BoxFooter>
      </DeleteCompanyModalContainer>
    </Popin>
  )
}
