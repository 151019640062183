import * as React from 'react'
import { ThemeProvider } from 'styled-components'

// import { FlexLine, FlexLineItem } from 'components/common/flexline'
// import { Content } from 'components/styled/blocs'
import {
  Link,
  ReviewNavContainer,
  ReviewNavMain,
  ReviewNavSecond,
} from 'components/campaign/review/review-screen.styles'

import { generateCampaignUrl } from 'com.batch.common/router'

import { type AppRecord, type CampaignRecord } from 'com.batch.redux/_records'

type ReviewMenuProps = {
  activeTab: 'review' | 'analytics'
  app: AppRecord
  fixed: boolean
  campaign: CampaignRecord
  children: Array<React.ReactNode>
}

const ReviewMenu = ({ app, campaign, children, fixed }: ReviewMenuProps): React.ReactElement => {
  const urlParams = {
    companyId: app.companyId,
    appId: app.id,
    campaignType: campaign.type,
    id: campaign.token,
    tab: 'analytics',
  }
  const analyticsUrl: string = generateCampaignUrl('data', campaign.schedulingType, urlParams)
  const reviewUrl: string = generateCampaignUrl('data', campaign.schedulingType, {
    ...urlParams,
    tab: 'review',
  })
  return (
    <ThemeProvider theme={{ fixed }}>
      <ReviewNavContainer>
        <ReviewNavMain>
          <Link to={reviewUrl}>Review</Link>
          <Link to={analyticsUrl}>Analytics</Link>
        </ReviewNavMain>
        <ReviewNavSecond>{children}</ReviewNavSecond>
      </ReviewNavContainer>
    </ThemeProvider>
  )
}
export default ReviewMenu
