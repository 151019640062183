import Immutable, { type OrderedMap, type List, type Set, type RecordOf } from 'immutable'
import { createSelector } from 'reselect'

import {
  DataPointFactory,
  DataSetFactory,
  type DataSetRecord,
} from 'components/charts/chart-helper'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { sumDayData, computeRate, buildArrayOfCacheIds } from './dataCampaign.api'
import {
  DayDataFactory,
  type DayDataProps,
  type DayDataRecord,
  type fetchingState,
} from './dataCampaign.records'
import { rangeSelector } from './stat.selector.analytics'

import { type State } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'

type extract<T> = (arg1: State) => T

const rootSelector = (state: State) => state.dataCampaign

export const allDayDataSelector: extract<List<DayDataRecord>> = createSelector(
  rootSelector,
  rangeSelector,
  (cd, range) => {
    const { from, to } = range
    // date range is not utc, we need to convert before comparing
    let fromLoopUTC = dayjs.utc(from.format('YYYY-MM-DD'))

    const arr: Array<RecordOf<DayDataProps>> = []
    // arr contient tous les jours qu'on souhaite afficher
    while (fromLoopUTC.isSameOrBefore(to, 'day')) {
      arr.push(DayDataFactory({ period: fromLoopUTC }))
      fromLoopUTC = fromLoopUTC.add(1, 'day')
    }
    // on donne la data qu'on a déjà
    const enriched = Immutable.List<RecordOf<DayDataProps>>(arr).reduce((acc, d) => {
      const matches = cd.data.filter(dd => dd.period.isSame(d.period, 'day'))
      if (matches.size > 0) {
        return acc.merge(matches)
      }
      return acc.push(d)
    }, Immutable.List<RecordOf<DayDataProps>>())
    return enriched
  }
)
export const loadedPartsSelector: extract<Set<string>> = createSelector(
  rootSelector,
  cd => cd.loaded
)
export const loadingPartsSelector: extract<Set<string>> = createSelector(
  rootSelector,
  cd => cd.loading
)

export const createLoadedSelector = (
  category: 'summary' | 'marketing' | 'transactional'
): extract<boolean> =>
  createSelector(rangeSelector, loadedPartsSelector, (range, loaded) => {
    return (
      Immutable.Set(buildArrayOfCacheIds(range.from, range.to, category)).subtract(loaded).size ===
      0
    )
  })

export const createLoadingSelector = (
  category: 'summary' | 'marketing' | 'transactional'
): extract<boolean> =>
  createSelector(rangeSelector, loadingPartsSelector, (range, loading) => {
    const neededAndLoading = loading.intersect(
      Immutable.Set(buildArrayOfCacheIds(range.from, range.to, category))
    )
    return neededAndLoading.size > 0
  })

export const createNotificationAggregateSelector = (
  filter: (arg1: DayDataRecord) => boolean
): extract<DayDataRecord> =>
  createSelector(allDayDataSelector, currentAppSelector, (list, app) => {
    return computeRate(
      list.filter(filter).reduce((acc, current) => {
        return sumDayData(acc, current)
      }, DayDataFactory()),
      app
    )
  })

export const createNotificationListSelector = (
  kind: 'groupId' | 'token' | 'category'
): extract<OrderedMap<string, DayDataRecord>> =>
  createSelector(allDayDataSelector, currentAppSelector, (list, app) => {
    let mapByKind: OrderedMap<string, DayDataRecord> = Immutable.OrderedMap()
    list
      .filter(pd => pd.id.kind === kind)
      .sort((a, b) => (a.period.isBefore(b.period) ? -1 : 1))
      .forEach(pd => {
        const key = kind === 'category' ? pd.period.format('YYYY-MM-DD') : pd.id.value
        if (mapByKind.has(key)) {
          mapByKind = mapByKind.set(
            key,
            computeRate(sumDayData(mapByKind.get(key, DayDataFactory()), pd), app)
          )
        } else {
          mapByKind = mapByKind.set(key, pd)
        }
      })
    return mapByKind
  })

const notificationByDaySelector = createNotificationListSelector('category')

export const sentDataPointSelector: extract<List<DataSetRecord>> = createSelector(
  notificationByDaySelector,
  mapByDay => {
    return Immutable.List([
      DataSetFactory({
        color: '#345DA8',
        label: 'Sent',
        data: mapByDay
          .map(pd =>
            DataPointFactory({
              value: pd.push.total.sent,
              date: pd.period,
            })
          )
          .toList(),
      }),
    ])
  }
)

export const isEmptySelector: extract<boolean> = createSelector(
  allDayDataSelector,
  data => data.reduce((acc, rec) => acc + rec.push.total.sent + rec.errors.size, 0) === 0
)

export const statusSelector: extract<fetchingState> = createSelector(
  rootSelector,
  data => data.status
)
