import styled, { css } from 'styled-components'

import { MetricContainer } from 'components/campaign/review/data/metric'

type PerformanceContainerProps = {
  nb: number
}
export const PerformanceContainer = styled.div<PerformanceContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 268px;
  z-index: 1;
  flex-grow: 1;
  padding: 26px;
  ${props =>
    props.nb < 4 &&
    css`
      @media (min-width: 1150px) {
        padding: 24px ${(4 - props.nb) * 5}vw;
      }
    `}
  background-color: transparent;
`
type PerformanceBlockProps = {
  multiple: boolean
  transition: boolean
}
export const PerformanceBlock = styled.div<PerformanceBlockProps>`
  ${props =>
    props.multiple
      ? css`
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          background-color: #fcfcfc;

          &:before,
          &:after {
            display: block;
            content: '';
            position: absolute;
            pointer-events: none;
          }
          &:after {
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fcfcfc;
          }
          &:before {
            top: -3px;
            left: -3px;
            right: -3px;
            bottom: -3px;
            border: 4px dashed #ebe8e8;
            border-radius: 4px;
          }
        `
      : !props.transition &&
        css`
          box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.02);
        `}
  flex: 0 0 ${props => (props.multiple ? '194' : '162')}px;
  padding: ${props => (props.multiple ? '18px 16px 16px 16px' : '0px')};

  > ${MetricContainer} {
    margin: -4px 1px 8px 1px;
  }
`

const Notch = 10
type PerformanceNumbersProps = {
  input: boolean
  output: boolean
  transition: boolean
}
export const PerformanceNumbers = styled.div<PerformanceNumbersProps>`
  position: relative;
  padding: 10px 20px;
  ${props =>
    props.transition &&
    css`
      label {
        padding-left: 25px;
        text-align: left;
      }
      ${MetricContainer} {
        text-align: center;
        margin-bottom: 110px;
      }
    `}
  ${props =>
    props.input && props.output && !props.transition
      ? css`
          background: radial-gradient(
              circle at 0px 50%,
              transparent 0,
              transparent ${Notch}px,
              #efeff1 ${Notch + 1}px,
              #ffffff ${Notch + 2}px,
              #ffffff calc(100% - ${Notch * 2}px),
              transparent 50%
            ),
            radial-gradient(
              circle at 100% 50%,
              transparent 0,
              transparent ${Notch}px,
              #efeff1 ${Notch + 1}px,
              #ffffff ${Notch + 2}px,
              #ffffff calc(100% - ${Notch * 2}px),
              transparent 50%
            );
        `
      : ''} 
      
    ${props =>
    props.input && !props.output
      ? css`
          background: radial-gradient(
            circle at 0px 50%,
            transparent 0,
            transparent ${Notch}px,
            #efeff1 ${Notch + 1}px,
            #ffffff ${Notch + 2}px,
            #ffffff calc(100% - ${Notch * 2}px),
            #ffffff 100%
          );
        `
      : ''}

    ${props =>
    !props.input && props.output
      ? css`
          background: radial-gradient(
            circle at 100% 50%,
            transparent 0,
            transparent ${Notch}px,
            #efeff1 ${Notch + 1}px,
            #fff ${Notch + 2}px,
            #fff calc(100% - ${Notch * 2}px),
            #fff 100%
          );
        `
      : ''}
  background-repeat: no-repeat;
  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(50% - ${Notch + 1}px);
    ${props =>
      props.transition
        ? ''
        : css`
            border: 1px solid #efeff1;
          `}
    pointer-events: none;
  }
  &:before {
    top: 0;
    border-bottom: none;
    border-radius: 2px 2px 0 0;
  }
  &:after {
    bottom: 0;
    border-top: none;
    border-radius: 0 0 2px 2px;
  }

  i {
    color: #c5c8cd;
    cursor: pointer;
    transition: color 0.1s ease;

    &:hover {
      color: #3e5067;
    }
  }

  & + div {
    margin-top: -2px;

    &:before {
      border-radius: 0 0 2px 2px;
    }
    &.styled-fix-border-hack:before {
      border-radius: 2px;
    }
  }

  > div {
    &:before,
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: calc(50% - ${Notch + 1}px);
      height: ${Notch * 2 + 2}px;
      width: 1px;
      border-left: 1px solid #efeff1;
    }
    &:before {
      left: 0;
      display: ${props => (props.input ? 'none' : 'block')};
    }
    &:after {
      right: 0;
      display: ${props => (props.output ? 'none' : 'block')};
    }
  }
`

export const Container = styled.div`
  position: relative;
`
export const Foreground = styled.svg`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
`

export const Background = styled.svg`
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.05;
`
