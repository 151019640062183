import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { type StateToggler } from 'components/_hooks'
import { filters } from 'components/campaign/campaign-list-utils'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  BoxSection,
  FooterBoxActions,
} from 'components/common/box'
import { Button, Switch } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Hint } from 'components/common/hint'
import { Loader } from 'components/common/loader/loader'
import { trackEvent } from 'components/common/page-tracker'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Checkbox, DateRangePicker, Radio } from 'components/form'

import { type DateRange, dayjs } from 'com.batch.common/dayjs.custom'
import * as predefinedRanges from 'com.batch.common/predefined-ranges'
import { capitalize } from 'com.batch.common/utils'

import { BoxedCheckbox } from './boxed-checkbox'
import {
  CustomInputWrapper,
  DatesWrapper,
  ExportModalContainer,
  LoaderText,
} from './export-modal-styles'

import { type CampaignActiveFiltersRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { fetchCustomAnalytics, fetchEmailCustomAnalytics } from 'com.batch.redux/dataCampaign.api'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { showToast } from 'com.batch.redux/toaster'

const ranges = Immutable.List([
  predefinedRanges.last7Days,
  predefinedRanges.lastMonth,
  predefinedRanges.last3Months,
  predefinedRanges.last6Months,
  predefinedRanges.lastYear,
])

type ExportModalProps = {
  toggleOpen: StateToggler
  schedulingType: schedulingType
  activeFilters: CampaignActiveFiltersRecord
  isEmail?: boolean
}

export const ExportModal = ({
  toggleOpen,
  schedulingType,
  activeFilters,
  isEmail = false,
}: ExportModalProps): React.ReactElement => {
  // ====================== GLOBAL STATE
  const app = useSelector(currentAppSelector)
  const dispatch = useDispatch()
  const project = useSelector(currentProjectSelector)

  // ====================== LOCAL STATE
  const [range, setRange] = React.useState<DateRange>(
    activeFilters.dateRange
      ? activeFilters.dateRange
      : isEmail
        ? { from: dayjs().endOf('day').subtract(29, 'day'), to: dayjs().endOf('day') }
        : { from: predefinedRanges.last7Days.from, to: predefinedRanges.last7Days.to }
  )
  const [exportAllCampaigns, setExportAllCampaigns] = React.useState<boolean>(false)
  const [exportMetrics, setExportMetrics] = React.useState<
    Set<'basicMetrics' | 'triggerMetrics' | 'additionalData'>
  >(Immutable.Set(['basicMetrics']))
  const [exportSplitBy, setExportSplitBy] = React.useState<Set<'versions' | 'campaigns' | 'days'>>(
    Immutable.Set(['campaigns'])
  )
  const [exportStepDetails, setExportStepDetails] = React.useState<boolean>(false)
  const [exportAsked, setExportAsked] = React.useState<boolean>(false)
  const [campaignType, setCampaignType] = React.useState<campaignType>('push')

  const closeAndDownloadExport = React.useCallback(
    results => {
      const blob = new Blob([results])
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        if (isEmail) {
          link.setAttribute(
            'download',
            `Email ${schedulingType} metrics ${range.from.format('YYYYMMDD')}-${range.to.format(
              'YYYYMMDD'
            )}.csv`
          )
        } else {
          link.setAttribute(
            'download',
            `Campaigns metrics app ${app.id} ${app.platform.replace(
              'webpush',
              'web'
            )} ${campaignType} ${range.from.format('YYYYMMDD')}-${range.to.format('YYYYMMDD')}.csv`
          )
        }
        link.style.visibility = 'hidden'
        if (document && document.body) document.body.appendChild(link)
        link.click()
        if (document && document.body) document.body.removeChild(link)
      }
      setExportAsked(false)
      toggleOpen.close()
    },
    [app, campaignType, range, isEmail, schedulingType, toggleOpen]
  )

  const closeAndDisplayToast = React.useCallback(
    message => {
      dispatch(showToast({ message }))
      setExportAsked(false)
      toggleOpen.close()
    },
    [dispatch, toggleOpen]
  )

  const closeAndDisplayError = React.useCallback(
    e => {
      const errorMessage =
        e.seconds > 40 && e.parseError
          ? 'Timeout, date range too long'
          : e.parseError
            ? 'Unexpected server response'
            : e.status === 413
              ? 'Response too large, date range too long'
              : 'Unexpected error ' + e.Bloberror
      closeAndDisplayToast(errorMessage)
    },
    [closeAndDisplayToast]
  )

  const trackCampaignExportClicked = React.useCallback(() => {
    if (isEmail) return

    trackEvent('CAMPAIGN_EXPORT_CLICKED', {
      channel_type: app.platform === 'webpush' ? 'webpush' : campaignType,
      date_range: range.to.diff(range.from, 'day'),
      campaign_info: exportMetrics.has('additionalData'),
      trigger_info: exportMetrics.has('triggerMetrics'),
      group_by: exportSplitBy
        .toArray()
        .map(group => (group === 'versions' ? 'variant' : group === 'days' ? 'day' : 'campaign')),
      applied_filters: [
        ...(activeFilters.labels.size > 0 ? ['labels'] : []),
        ...activeFilters.commons.map(filter => filter.category),
      ],
    })
  }, [activeFilters, app, campaignType, exportMetrics, exportSplitBy, range, isEmail])

  const onSubmitExportForm = React.useCallback(async () => {
    setExportAsked(true)
    trackCampaignExportClicked()

    try {
      const { results } = isEmail
        ? await fetchEmailCustomAnalytics({
            from: range.from,
            to: range.to,
            schedulingType,
            exportMetrics,
            exportSplitBy,
            exportStepDetails,
            project,
          })
        : await fetchCustomAnalytics({
            app,
            from: range.from,
            to: range.to,
            channel: campaignType,
            schedulingType,
            exportAllCampaigns,
            exportMetrics,
            exportSplitBy,
            status: activeFilters.commons.filter(f => f.category === 'status').map(f => f.name),
            sources: activeFilters.commons.filter(f => f.category === 'sources').map(f => f.name),
            when: activeFilters.commons.filter(f => f.category === 'when').map(f => f.name),
            labels: activeFilters.labels.filter(label => label !== 'new'),
            query: null, // Temporary disabled (waiting validation from product team)
            // query: activeFilters.query
          })
      if (results.length === 0) {
        closeAndDisplayToast(`No ${schedulingType} with data to export`)
        return
      }
      closeAndDownloadExport(results)
    } catch (e: any) {
      console.error(e)
      closeAndDisplayError(e)
    }
  }, [
    trackCampaignExportClicked,
    isEmail,
    range.from,
    range.to,
    schedulingType,
    exportMetrics,
    exportSplitBy,
    exportStepDetails,
    project,
    app,
    campaignType,
    exportAllCampaigns,
    activeFilters.commons,
    activeFilters.labels,
    closeAndDownloadExport,
    closeAndDisplayToast,
    closeAndDisplayError,
  ])

  const isAutomation = schedulingType === 'automations'
  const hasTriggerMetrics = isAutomation && campaignType === 'push' && !isEmail
  const hasActiveFilters =
    (activeFilters.commons.size > 0 || activeFilters.labels.size > 0) && !isEmail

  const onChannelChange = React.useCallback(
    channel => () => {
      setCampaignType(channel)
      if (channel === 'in-app') {
        setExportSplitBy(exportSplitBy.delete('versions'))
        setExportMetrics(exportMetrics.delete('triggerMetrics'))
      }
    },
    [exportMetrics, exportSplitBy]
  )

  const onByStepChange = React.useCallback(() => {
    setExportStepDetails(state => !state)
  }, [setExportStepDetails])

  const onExportAllChange = React.useCallback(
    (bool: boolean) => () => setExportAllCampaigns(bool),
    []
  )

  const onExportMetricToggle = React.useCallback(
    metric => () =>
      setExportMetrics(
        exportMetrics.has(metric) ? exportMetrics.delete(metric) : exportMetrics.add(metric)
      ),
    [exportMetrics, setExportMetrics]
  )

  const onExportSplitBy = React.useCallback(
    param => () => {
      setExportSplitBy(
        exportSplitBy.has(param) ? exportSplitBy.delete(param) : exportSplitBy.add(param)
      )
    },
    [exportSplitBy]
  )

  const isDayDisabled = React.useCallback(
    (day: Date) =>
      isEmail ? dayjs().isBefore(day, 'day') : dayjs().subtract(1, 'day').isBefore(day, 'day'),
    [isEmail]
  )

  const renderDateRangePicker = React.useCallback(
    (marginTop: number) => {
      return (
        <DateRangePicker
          style={{ marginTop }}
          range={range}
          setRange={setRange}
          shortcuts={ranges}
          disabledDays={isDayDisabled}
          singleDayRange
          icon="calendar"
          placeholder="Pick a range"
          changePlaceholderOnFocus
          hasInputs
          clearable={false}
        />
      )
    },
    [isDayDisabled, range]
  )

  const renderSplitDataBySection = React.useCallback(() => {
    return (
      <CustomInputWrapper>
        <div className="row-split">
          <p className="label-modal" id="label-modal" style={{ fontWeight: 500 }}>
            Split data by
          </p>
          <Hint minTooltipWidth={260} placement="right" size={13}>
            <div style={{ textAlign: 'left' }}>
              Select the level of granularity of your CSV export.
            </div>
          </Hint>
        </div>
        <div role="group" aria-labelledby="label-modal">
          <Grid template="auto auto auto 1fr" gap={20}>
            <Checkbox
              name="campaigns"
              disabled
              label={isAutomation ? 'Automation' : 'Campaign'}
              checked={exportSplitBy.has('campaigns')}
            />

            <Checkbox
              name="days"
              id="days"
              checked={exportSplitBy.has('days')}
              onChange={onExportSplitBy('days')}
              label="Day"
            />

            {(campaignType === 'push' || campaignType === 'in-app') && !isEmail && (
              <Checkbox
                name="versions"
                id="versions"
                tooltip={
                  !app.features.has('ab-testing')
                    ? 'Upgrade your plan to split by A/B versions.'
                    : undefined
                }
                label="A/B versions"
                disabled={!app.features.has('ab-testing') || exportMetrics.has('triggerMetrics')}
                checked={exportSplitBy.has('versions')}
                onChange={onExportSplitBy('versions')}
              />
            )}
          </Grid>
        </div>
      </CustomInputWrapper>
    )
  }, [app, campaignType, exportMetrics, exportSplitBy, onExportSplitBy, isEmail, isAutomation])

  return (
    <Popin
      opened={toggleOpen.value}
      close={toggleOpen.close}
      style={{ overflow: 'visible', width: '715px' }}
    >
      <ExportModalContainer>
        <Box>
          <BoxHeader>
            <h1 className="modal-title">New {schedulingType} export</h1>
            <Button style={{ marginRight: -10 }} onClick={toggleOpen.close}>
              <Icon icon="close" />
            </Button>
          </BoxHeader>

          {exportAsked ? (
            <LoaderText>
              <Loader loading />
              <h3 className="loader-title">We are preparing your CSV export.</h3>
              <p className="loader-text">
                It can take up to a minute, please don't close this window.
              </p>
            </LoaderText>
          ) : (
            <React.Fragment>
              <BoxBody $padding>
                <BoxSection style={{ border: 'none' }}>
                  <Grid
                    alignItems="start"
                    template={!isAutomation && !hasActiveFilters && !isEmail ? '1fr' : '1fr 1fr'}
                    gap={isEmail ? 64 : 12}
                  >
                    {hasActiveFilters && (
                      <DatesWrapper>
                        <p
                          className="dates-label"
                          id="radiogroup-label-export-for"
                          style={{ fontWeight: 500 }}
                        >
                          Export for
                        </p>
                        <div
                          style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}
                          role="radiogroup"
                          aria-labelledby="radiogroup-label-export-for"
                        >
                          <Radio
                            label="Applied filtering"
                            disabled={filters
                              .filter(f => f.category === 'status')
                              .filter(f => activeFilters.commons.has(f))
                              .equals(
                                filters
                                  .filter(f => f.category === 'status')
                                  .filter(f => f.name === 'draft')
                              )}
                            checked={
                              !filters
                                .filter(f => f.category === 'status')
                                .filter(f => activeFilters.commons.has(f))
                                .equals(
                                  filters
                                    .filter(f => f.category === 'status')
                                    .filter(f => f.name === 'draft')
                                ) && !exportAllCampaigns
                            }
                            onChange={onExportAllChange(false)}
                            style={{ marginRight: '12px' }}
                          />
                          <Radio
                            label={isAutomation ? 'All automations' : 'All campaigns'}
                            disabled={false}
                            checked={
                              filters
                                .filter(f => f.category === 'status')
                                .filter(f => activeFilters.commons.has(f))
                                .equals(
                                  filters
                                    .filter(f => f.category === 'status')
                                    .filter(f => f.name === 'draft')
                                ) || exportAllCampaigns
                            }
                            onChange={onExportAllChange(true)}
                          />
                        </div>
                      </DatesWrapper>
                    )}
                    {isAutomation && !isEmail && (
                      <DatesWrapper>
                        <div className="row-split">
                          <p
                            className="dates-label"
                            id="radiogroup-label-channel"
                            style={{ fontWeight: 500 }}
                          >
                            Which channel
                          </p>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }}
                          role="radiogroup"
                          aria-labelledby="radiogroup-label-channel"
                        >
                          <Radio
                            label="Push"
                            checked={campaignType === 'push'}
                            onChange={onChannelChange('push')}
                            style={{ marginRight: '12px' }}
                          />
                          {app.platform !== 'webpush' && (
                            <Radio
                              label="In-app"
                              disabled={false}
                              checked={campaignType === 'in-app'}
                              onChange={onChannelChange('in-app')}
                            />
                          )}
                        </div>
                      </DatesWrapper>
                    )}
                    <DatesWrapper
                      style={
                        hasActiveFilters && isAutomation
                          ? { gridColumn: '1 / -1', marginTop: 20 }
                          : {}
                      }
                    >
                      <div className="row-split">
                        <p className="dates-label" style={{ fontWeight: 500 }}>
                          Date range filter
                        </p>
                      </div>
                      {renderDateRangePicker(10)}
                    </DatesWrapper>
                    <DatesWrapper>{isEmail && renderSplitDataBySection()}</DatesWrapper>
                  </Grid>

                  <CustomInputWrapper>
                    <div className="row-split">
                      <label className="label-modal">
                        {capitalize(schedulingType)} metrics & data to include
                      </label>
                      <Hint minTooltipWidth={260} placement="right" size={13}>
                        <div style={{ textAlign: 'left' }}>
                          Select the type of metrics and data you will find in your CSV export
                        </div>
                      </Hint>
                    </div>
                    <Grid template={hasTriggerMetrics ? '1fr 1fr 1fr' : '1fr 1fr'} gap={12}>
                      {/* <Grid template={type === 'push' ? '1fr 1fr 1fr' : '1fr 1fr'} gap={12}> */}

                      {isEmail ? (
                        <BoxedCheckbox
                          title="Basic metrics"
                          style={{ maxWidth: 306 }}
                          description="Sent, Delivered, Unique Open, Unique Machine Open, Unique Click..."
                          isDisabled={true}
                          isSelected={true}
                        />
                      ) : (
                        <BoxedCheckbox
                          title="Essentials"
                          description={
                            campaignType === 'push'
                              ? 'Sent, Sent to opt-ins, Display, Direct opens, Labels, ...'
                              : 'Devices synced, Display, Clicks, Close, Main button, Secondary button, ...'
                          }
                          isDisabled={true}
                          isSelected={true}
                        />
                      )}

                      {hasTriggerMetrics && (
                        <BoxedCheckbox
                          title="Trigger advanced metrics"
                          description="Mismatch, cancellation event, uninstall, skipped, ..."
                          isDisabled={false}
                          onChange={onExportMetricToggle('triggerMetrics')}
                          isSelected={exportMetrics.has('triggerMetrics')}
                        />
                      )}

                      {!isEmail && (
                        <BoxedCheckbox
                          title="Extended"
                          description={
                            campaignType === 'push'
                              ? 'Smart segment, Country, Start date, Message, Deeplink, ...'
                              : 'Start date, Trigger event, Message, Deeplink, Format'
                          }
                          isDisabled={false}
                          onChange={onExportMetricToggle('additionalData')}
                          isSelected={exportMetrics.has('additionalData')}
                        />
                      )}
                    </Grid>
                  </CustomInputWrapper>
                  {!isEmail && renderSplitDataBySection()}

                  {isEmail && isAutomation && (
                    <div style={{ marginTop: 20 }}>
                      <Switch onChange={onByStepChange} isActive={exportStepDetails}>
                        Add automation trigger step details
                      </Switch>
                    </div>
                  )}
                </BoxSection>
              </BoxBody>

              <BoxFooter isEditable>
                <Button kind="inline" onClick={toggleOpen.close}>
                  Cancel
                </Button>
                <FooterBoxActions>
                  <Button
                    type="submit"
                    intent="action"
                    kind="primary"
                    disabled={!range}
                    onClick={onSubmitExportForm}
                  >
                    Download CSV export
                  </Button>
                </FooterBoxActions>
              </BoxFooter>
            </React.Fragment>
          )}
        </Box>
      </ExportModalContainer>
    </Popin>
  )
}
