import { type Dayjs } from 'dayjs'
import Immutable from 'immutable'

import { buildAgeFromSeconds } from 'com.batch.common/utils'

import { TriggerConfigFactory, type TriggerConfigRecord } from 'com.batch.redux/_records'

import { DELAY_MODE } from 'constants/common'

type rawTriggerConfig = {
  version: number
  resetTimerOnEvent: boolean
  pushTimer: number
  gracePeriod: number
  timerReferenceAttribute: string | null | undefined
  instanceIDSelector?: {
    type: 'LABEL' | 'ATTRIBUTE'
    value: string | null
  }
  entryTrigger: {
    eventName: string
    query?: string | null | undefined
  }
  exitTriggers: Array<{
    eventName: string
    query: string
  }>
  delayMode?: 'IMMEDIATE' | 'TIMER'
}

function attemptToParse(data?: string | null): Record<any, any> | null | undefined {
  let q: Record<any, any> | null | undefined = null
  try {
    q = typeof data === 'string' ? JSON.parse(data) : null
  } catch (_: any) {}
  return typeof q === 'object' ? q : null
}

export function parseTriggerConfig({
  config,
  start,
  end,
  capping,
}: {
  config: rawTriggerConfig
  start: Dayjs | null | undefined
  end: Dayjs | null | undefined
  grace: number
  capping: number
}): TriggerConfigRecord {
  return TriggerConfigFactory({
    enterEvent: config.entryTrigger.eventName,
    enterEventQuery: attemptToParse(config.entryTrigger.query),
    pushTimer: buildAgeFromSeconds(Math.abs(config.pushTimer)),
    pushTimerReference:
      typeof config.timerReferenceAttribute === 'string' ? config.timerReferenceAttribute : '',
    pushTimerMode:
      typeof config.timerReferenceAttribute === 'string' && config.timerReferenceAttribute !== ''
        ? config.pushTimer > 0
          ? 'after'
          : 'before'
        : 'event',
    instanceId:
      typeof config.instanceIDSelector === 'object' && config.instanceIDSelector !== null
        ? config.instanceIDSelector.type === 'LABEL'
          ? 'eventLabel()'
          : `eventAttr(attr: '${config.instanceIDSelector.value || ''}')`
        : '',
    hasInstanceId:
      typeof config.instanceIDSelector === 'object' && config.instanceIDSelector !== null,
    resetTimerOnEvent: config.resetTimerOnEvent,
    hasExitEvent: Array.isArray(config.exitTriggers) && config.exitTriggers.length > 0,
    exitEvents: Immutable.List(
      Array.isArray(config.exitTriggers) && config.exitTriggers.length > 0
        ? config.exitTriggers.map(raw => ({
            eventId: raw.eventName,
            query: attemptToParse(raw.query),
          }))
        : [{ eventId: '', query: null }]
    ),
    hasStart: !!start,
    start,
    hasEnd: !!end,
    end,
    hasGrace: typeof config.gracePeriod === 'number',
    grace:
      typeof config.gracePeriod === 'number'
        ? buildAgeFromSeconds(config.gracePeriod)
        : buildAgeFromSeconds(3600),
    hasCapping: !!capping && capping > 0,
    capping: !!capping && capping > 0 ? capping : 0,
    delayMode: config.delayMode || DELAY_MODE.TIMER,
  })
}
