import * as React from 'react'
import { Route, Routes } from 'react-router-dom'

type ProjectRouterWrapperProps = {
  children: React.ReactNode
}

export const ProjectRouterWrapper = ({
  children,
}: ProjectRouterWrapperProps): React.ReactElement => {
  return (
    <Routes>
      <Route path=":companyId/projects/:projectId">{children}</Route>
      <Route path=":companyId">{children}</Route>
    </Routes>
  )
}
