import styled from 'styled-components'

import * as colors from 'components/styled/tokens/colors'

export type TrendContainerProps = {
  $color: 'neutral' | 'danger' | 'success'
}
export const TrendContainer = styled.div<TrendContainerProps>`
  display: flex;
  justify-content: center;
  gap: 4px;
  font-weight: 600;
  font-size: 12px;
  cursor: default;

  color: ${({ $color }) =>
    $color === 'neutral'
      ? colors.textLight
      : $color === 'success'
        ? colors.textSuccess
        : colors.textDanger};
`
