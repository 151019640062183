import { type List } from 'immutable'
import * as React from 'react'
import styled from 'styled-components'

import { Avatar } from 'components/common/avatar'

import { generateUrl } from 'com.batch.common/router'
import { ucFirst } from 'com.batch.common/utils'

import { SidebarAccountDropdown, Container as DropdownContainer } from './sidebar-account-dropdown'
import { SidebarApps, Container as AppsDesktopContainer } from './sidebar-apps'
import { SidebarAppsMobile } from './sidebar-apps-mobile'
import { SidebarResponsiveButtonStyles } from './sidebar.styles'

import { type CompanyRecord, type AppRecord } from 'com.batch.redux/_records'
import { type openNew } from 'com.batch.redux/app.action'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { type UserRecord } from 'com.batch.redux/user.records'

type OwnProps = {
  apps: List<AppRecord>
  currentApp: AppRecord
  project: ProjectRecord | null | undefined
  user: UserRecord
  company: CompanyRecord
  isMobile?: boolean
}
type DispatchProps = {
  openNew: typeof openNew
}

export type SidebarDashboardProps = OwnProps & DispatchProps

export const SidebarDashboard = (props: SidebarDashboardProps): React.ReactElement => {
  const { apps, currentApp, company, user, isMobile, openNew, project } = props

  const SidebarAppsComponent = isMobile ? SidebarAppsMobile : SidebarApps

  return (
    <Container>
      <SidebarAppsComponent
        apps={apps}
        currentApp={currentApp}
        company={company}
        openNew={openNew}
        project={project}
      />
      {isMobile ? (
        <UserMobile href={generateUrl('account_settings')}>
          <Avatar
            url={user.avatarUrl || ''}
            placeholder={ucFirst(user.firstName) + ucFirst(user.lastName)}
            size={34}
            style={{ marginLeft: -3 }}
          />
        </UserMobile>
      ) : (
        <SidebarAccountDropdown user={user} company={company} project={project} />
      )}
    </Container>
  )
}

export const UserMobile = styled.a`
  ${SidebarResponsiveButtonStyles}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 0 138px 0;
  overflow: hidden;

  ${AppsDesktopContainer} {
    flex: 1 1 auto;
  }
  ${DropdownContainer} {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  @media (max-width: 980px) {
    flex-direction: row;
    padding: 0;

    ${DropdownContainer} {
      position: relative;
      z-index: 11;
      width: 84px;
    }
  }
`

export default SidebarDashboard
