import { type Dayjs } from 'dayjs'
import Immutable, { type List, type OrderedMap } from 'immutable'
import request from 'superagent-interface-promise'

import { config } from 'com.batch.common/config'
import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router/router'

import { type CompanyRecord, type DispatchBoundFn } from 'com.batch.redux/_records'
import {
  type PlanRecord,
  type InvoiceRecord,
  type BillingRecord,
  type CardRecord,
  PlanFactory,
  PriceFactory,
  InvoiceFactory,
  CardFactory,
} from 'com.batch.redux/billing.records'
import { normalizeCompany } from 'com.batch.redux/company.api'

const billingUrls = config.billing.urls

export const plans: OrderedMap<string, PlanRecord> = Immutable.OrderedMap<string, PlanRecord>([
  [
    'free',
    PlanFactory({
      code: 'free',
      name: 'Free',
      seats: 1,
      legacy: true,
      usd: PriceFactory(),
      eur: PriceFactory(),
    }),
  ],
  [
    'free2',
    PlanFactory({
      code: 'free2',
      name: 'Basic',
      seats: 1,
      legacy: false,
      usd: PriceFactory(),
      eur: PriceFactory(),
    }),
  ],
  [
    'premium',
    PlanFactory({
      code: 'premium',
      name: 'Premium',
      legacy: true,
      weight: 1,
      seats: 1,
      usd: PriceFactory({
        monthly: 4900,
        monthlyStripePlanId: 'b-premium-monthly',
        yearly: 5000,
        yearlyStripePlanId: 'b-premium-yearly',
      }),
      eur: null,
    }),
  ],
  [
    'pro',
    PlanFactory({
      code: 'pro',
      name: 'Pro (legacy)',
      legacy: true,
      weight: 2,
      seats: 1,
      usd: PriceFactory({
        monthly: 19900,
        monthlyStripePlanId: 'b-pro-monthly',
        yearly: 190000,
        yearlyStripePlanId: 'b-pro-yearly',
      }),
      eur: null,
    }),
  ],
  [
    'pro2',
    PlanFactory({
      code: 'pro2',
      name: 'Developer',
      descr: 'Solo account',
      seats: 1,
      weight: 3,
      legacy: false,
      usd: PriceFactory({
        monthly: 27500,
        monthlyStripePlanId: 'b-pro2-monthly-usd',
        yearly: 330000,
        yearlyStripePlanId: 'b-pro2-yearly-usd',
      }),
      eur: PriceFactory({
        monthly: 24500,
        monthlyStripePlanId: 'b-pro2-monthly-eur',
        yearly: 294000,
        yearlyStripePlanId: 'b-pro2-yearly-eur',
      }),
    }),
  ],
  [
    'startup',
    PlanFactory({
      code: 'startup',
      name: 'Startup',
      legacy: false,
      seats: 3,
      weight: 4,
      descr: 'Small teams, 3 seats',
      usd: PriceFactory({
        monthly: 57500,
        monthlyStripePlanId: 'b-startup-monthly-usd',
        yearly: 690000,
        yearlyStripePlanId: 'b-startup-yearly-usd',
      }),
      eur: PriceFactory({
        monthly: 49500,
        monthlyStripePlanId: 'b-startup-monthly-eur',
        yearly: 594000,
        yearlyStripePlanId: 'b-startup-yearly-eur',
      }),
    }),
  ],
  [
    'premier',
    PlanFactory({
      code: 'premier',
      name: 'Business',
      legacy: false,
      seats: 5,
      weight: 5,
      descr: 'Growing teams, 5 seats',
      usd: PriceFactory({
        monthly: 100000,
        monthlyStripePlanId: '',
        yearly: 1200000,
        yearlyStripePlanId: '',
      }),
      eur: PriceFactory({
        monthly: 100000,
        monthlyStripePlanId: '',
        yearly: 1200000,
        yearlyStripePlanId: '',
      }),
    }),
  ],
  [
    'enterprise',
    PlanFactory({
      code: 'enterprise',
      name: 'Enterprise',
      seats: 20,
      weight: 6,
      descr: 'Large teams, 20 seats',
      legacy: false,
      usd: null,
      eur: null,
    }),
  ],
])

export function getPlanFromCode(code: string): PlanRecord {
  return plans.get(code, PlanFactory())
}

export function getInvoices(company: CompanyRecord): Promise<List<InvoiceRecord>> {
  const url = billingUrls.invoices.replace('{companyId}', company.id?.toString() ?? '')
  return request.get(url).then(response => {
    return Immutable.List<InvoiceRecord>(
      response.body.map(rawInvoice =>
        InvoiceFactory({
          id: rawInvoice.id,
          paidDate: dayjs.utc(rawInvoice.date, 'YYYY-MM-DD HH:mm:ss'),
          periodFrom: dayjs.utc(rawInvoice.periodFrom, 'YYYY-MM-DD HH:mm:ss'),
          periodTo: dayjs.utc(rawInvoice.periodTo, 'YYYY-MM-DD HH:mm:ss'),
          varNumber: rawInvoice.varNumber,
          total: rawInvoice.total,
          currency: rawInvoice.currency,
          paid: rawInvoice.paid,
        })
      )
    ).filter(i => i.total !== 0)
  })
}

export function getVat(
  vatNumber: string,
  countryCode: string
): Promise<{
  rate: number
  vatNumberState: 'VALID' | 'INVALID' | 'UNKNOWN' | 'NA'
}> {
  const url = generateUrl('billing_api_get_vat')
  return request
    .post(url, {
      customer: {
        vat_number: vatNumber,
        country: countryCode,
      },
    })
    .then(response => {
      return response.body
    })
}

export function persistBillingInfo({
  company,
  billingInfo,
  paymentMethod,
}: {
  company: CompanyRecord
  billingInfo: BillingRecord | null | undefined
  paymentMethod: string | null | undefined
}): Promise<BillingRecord> {
  const url = generateUrl('billing_api_company_persist', {
    companyId: company.id,
  })
  return request
    .post(url, {
      billingInformation:
        billingInfo === null || typeof billingInfo === 'undefined'
          ? null
          : {
              billingCompanyName: billingInfo.companyName,
              billingFirstname: billingInfo.firstname,
              billingLastname: billingInfo.lastname,
              billingAddress: billingInfo.address,
              billingAddress2: billingInfo.address2,
              billingCity: billingInfo.city,
              billingZip: billingInfo.zip,
              billingVatNumber: billingInfo.vatNumber,
              billingVatRate: billingInfo.vatRate,
              billingCountry: billingInfo.country,
            },
      paymentMethod,
    })
    .then(
      response => normalizeCompany(response.body),
      response => {
        throw response.body
      }
    )
}

export function getCurrentCard({ company }: { company: CompanyRecord }): Promise<CardRecord> {
  const url = generateUrl('billing_api_subscription_getcurrentpaymentinformation', {
    companyId: company.id,
  })
  return request.get(url).then(response => {
    return CardFactory(response.body)
  })
}

/**
 * convert a card number to a stripe token
 * @param  {element} card a stripe element
 * @return {token|error}
 */
export function createToken(card: any): Promise<string> {
  return request
    .get('/api/billing/client-secret')
    .then(
      response => response.body.clientSecret,
      () => {
        throw 'Unable to create a Setup Intent.'
      }
    )
    .then(clientSecret => window.stripe.handleCardSetup(clientSecret, card))
    .then(response => {
      if (response.error) {
        throw response.error.message
      } else {
        return response.setupIntent.payment_method
      }
    })
}

type StripeCartLine = {
  label: string
  price: number
}

export type StripeCart = {
  currency: 'EUR' | 'USD'
  interval: 'year' | 'month'
  starts: Dayjs
  total: number
  lines: Array<StripeCartLine>
}

export function getCartSummary(company: CompanyRecord, stripePlanId: string): Promise<StripeCart> {
  return request
    .get(`/api/companies/${company.id}/subscription/summary/${stripePlanId}`)
    .then(response => {
      return { ...response.body, starts: dayjs.utc(response.body.starts) }
    })
}

export function createSub(company: CompanyRecord, stripePlanId: string): Promise<any> {
  return request
    .post(`/api/companies/${company.id}/subscription/create`, {
      stripePlanId: stripePlanId,
      coupon: null,
    })
    .then(response => {
      return response.body
    })
}

export function updateAutoRenew(company: CompanyRecord, autoRenew: boolean): Promise<any> {
  return request
    .post(`/api/companies/${company.id}/subscription/renew/${autoRenew ? '1' : '0'}`)
    .then(
      () => {},
      response => {
        throw response.body.errors[0].message
      }
    )
}

export function setNca(company: CompanyRecord, stripePlanId: string): Promise<any> {
  return request
    .post(`/api/companies/${company.id}/subscription/set-nca`, {
      plan: stripePlanId,
    })
    .then(
      () => {},
      response => {
        throw response.body.errors[0].message
      }
    )
}

export function cancelNca(company: CompanyRecord): DispatchBoundFn<any> {
  return request.post(`/api/companies/${company.id}/subscription/cancel-nca`).then(
    () => {},
    response => {
      throw response.body.errors[0].message
    }
  )
}

// used to get data we can't guess from what we got in db

type SubDetails = {
  cancelUnpaidAt: Dayjs | null | undefined
  cancelSubAt: Dayjs | null | undefined
  nextInvoice: Dayjs | null | undefined
}

export function getSubscriptionDetails(company: CompanyRecord): Promise<SubDetails> {
  return request.get(`/api/companies/${company.id}/subscription`).then(
    response => {
      const data = response.body
      return {
        nextInvoice:
          typeof data.current_period_end !== 'number'
            ? null
            : dayjs.unix(data.current_period_end).utc(),
        cancelUnpaidAt:
          typeof data.cancel_unpaid_at !== 'number'
            ? null
            : dayjs.unix(data.cancel_unpaid_at).utc(),
        cancelSubAt:
          typeof data.cancel_at_period_end === 'boolean' && data.cancel_at_period_end
            ? dayjs.unix(data.current_period_end).utc()
            : null,
      }
    },
    response => {
      throw response.body
    }
  )
}

export function updateSub(company: CompanyRecord, stripePlanId: string): Promise<any> {
  return request
    .post(`/api/companies/${company.id}/subscription/change-plan`, {
      stripePlanId,
    })
    .then(
      () => {},
      response => {
        throw response.body.errors[0].message
      }
    )
}
