import { textUsesTemplating } from 'com.batch.common/utils'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { EmailMessageFactory } from 'com.batch/message/models/message.records'

import { fetchTemplates } from 'com.batch/message/usecases/fetch-templates'
import {
  type ContentToTemplate,
  type TemplatedContent,
} from 'com.batch/shared/infra/oursql.service'

export type UpdateEmailTemplateAction = {
  type: 'UPDATE_EMAIL_TEMPLATE'
  payload: {
    messageId: string
    lang: string
    html?: string
    subject?: string
    replyTo?: string
  }
}
export const updateEmailTemplate = ({
  messageId,
  lang,
}: {
  messageId: string
  lang: string
}): DispatchExtraBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    const email = state.message.email
      .get(messageId, EmailMessageFactory())
      .get('localizedContent')
      .get(lang)

    const action: UpdateEmailTemplateAction = {
      type: 'UPDATE_EMAIL_TEMPLATE',
      payload: {
        messageId,
        lang,
      },
    }

    const contentsToTemplate: Array<ContentToTemplate> = []

    if (textUsesTemplating(email?.html)) {
      contentsToTemplate.push({
        messageId,
        lang,
        field: 'html',
        value: email?.html ?? '',
        type: 'HTML_TEMPLATE',
      })
    }

    if (textUsesTemplating(email?.subject)) {
      contentsToTemplate.push({
        messageId,
        lang,
        field: 'subject',
        value: email?.subject ?? '',
        type: 'TEMPLATE',
      })
    }

    if (textUsesTemplating(email?.replyTo)) {
      contentsToTemplate.push({
        messageId,
        lang,
        field: 'replyTo',
        value: email?.replyTo ?? '',
        type: 'TEMPLATE',
      })
    }

    if (contentsToTemplate.length === 0) {
      return dispatch(action)
    }

    return dispatch(fetchTemplates(contentsToTemplate))
      .then((res: Array<TemplatedContent>) => {
        res.forEach(({ field, template, value }) => {
          action.payload[field] = template ?? value
        })
        dispatch(action)
      })
      .catch(e => {
        console.error(
          'Error while fetching templates:\n',
          e.error?.errors?.[0]?.message ?? 'Unknown error'
        )
      })
  }
}
