import Immutable from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'
import { useParams } from 'react-router-dom'

import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Title } from 'components/styled/text'

import { StatsBlocksRow, StatsContainer } from './orchestration-analytics.styles'
import { PlatformFilter, type PlatformFilterOption } from './platform-filter'

import { PushKeyMetrics } from '../key-metrics/push-key-metrics'
import { orchestrationPlatformsSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { fetchOrchestrationDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import {
  getStatsConfig,
  type PlatformFilterDimensionValue,
} from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { STATUS } from 'constants/common'
import { OrchestrationAnalyticsFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

export const PushAnalytics = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { token } = useParams()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const stats = React.useMemo(
    () =>
      (token
        ? analyticsMap.get(token, OrchestrationAnalyticsFactory())
        : OrchestrationAnalyticsFactory()
      )
        .get('channels')
        .get('push')
        .get('stats'),
    [analyticsMap, token]
  )
  const loadingState: LoadingState = React.useMemo(
    () => (token ? analyticsMap.get(token)?.pushLoadingState ?? 'INIT' : 'ERROR'),
    [analyticsMap, token]
  )
  const isLoading = React.useMemo(
    () => loadingState === STATUS.LOADING || loadingState === STATUS.INIT,
    [loadingState]
  )
  const isOverlayShown = React.useMemo(() => loadingState === STATUS.ERROR, [loadingState])
  const { dateRange, filters } = useGetAnalyticsFilters(token)

  const orchestrationPlatforms = useSelector(orchestrationPlatformsSelector)
  const [selectedPlatformOption, setSelectedPlatformOption] = React.useState<PlatformFilterOption>(
    () => {
      if (orchestrationPlatforms.has('ios') && orchestrationPlatforms.has('android')) {
        return 'mobile'
      }
      return orchestrationPlatforms.first()
    }
  )
  const platformFilter = React.useMemo(() => {
    let platformFilterList = Immutable.List<PlatformFilterDimensionValue>()
    switch (selectedPlatformOption) {
      case 'mobile':
        platformFilterList = platformFilterList.push('IOS', 'ANDROID')
        break
      case 'ios':
        platformFilterList = platformFilterList.push('IOS')
        break
      case 'android':
        platformFilterList = platformFilterList.push('ANDROID')
        break
      case 'webpush':
        platformFilterList = platformFilterList.push('WEBPUSH')
        break
    }
    return platformFilterList
  }, [selectedPlatformOption])

  React.useEffect(() => {
    if (token) {
      const tokens = Immutable.List([token])
      dispatch(
        fetchOrchestrationDynamicStats({
          tokens,
          filters: filters.set('platform', platformFilter),
          dateRange,
          config: getStatsConfig({ name: 'orchestrationStatsForPush' }),
          includePreviousPeriod: true,
        })
      ).catch(() => {})
    }
  }, [dispatch, token, filters, dateRange, platformFilter])

  return (
    <StatsContainer>
      <ChannelSectionTitle style={{ margin: '0 0 24px' }}>
        <Grid template="auto auto" gap={8}>
          <Title>
            <Icon icon="push" size={22} />
            Push
          </Title>
          {orchestrationPlatforms.size > 1 && (
            <PlatformFilter
              selectedPlatformOption={selectedPlatformOption}
              setSelectedPlatformOption={setSelectedPlatformOption}
              platforms={orchestrationPlatforms}
              context={schedulingType}
            />
          )}
        </Grid>
      </ChannelSectionTitle>
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={GlobalErrorOverlayProps}
        boxed
      >
        <StatsBlocksRow>
          <PushKeyMetrics stats={stats} context={schedulingType} token={token} />
        </StatsBlocksRow>
      </Wrapper>
    </StatsContainer>
  )
}
