import styled, { css } from 'styled-components'

import { BoxContainer } from 'components/common/box/box.styles'

type PopinBackdropProps = {
  shown: boolean
}
export const PopinBackdrop = styled.div<PopinBackdropProps>`
  position: fixed;
  z-index: 40;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.2s ease-in;
  opacity: ${props => (props.shown ? 1 : 0)};
  pointer-events: ${props => (props.shown ? 'all' : 'none')};
  background: rgba(0, 0, 0, 0.64);
  display: flex;
  align-items: center;
  justify-content: center;
`
type PopinContainerProps = {
  fullscreen?: boolean
}
export const PopinContainer = styled.div<PopinContainerProps>`
  overflow-y: hidden;
  overflow-x: auto;
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }

  ${props =>
    !props.fullscreen
      ? css`
          max-height: calc(100% - 70px);
          margin: 0 35px;
        `
      : css`
          width: 100vw;
          height: 100vh;
        `};

  ${BoxContainer} {
    border: none;

    ${props =>
      props.fullscreen &&
      css`
        border-radius: 0;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
      `}
  }
`
