import Immutable, { type RecordOf } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

type DailyVolumeProps = {
  date: string
  sent: number
  cumulativeSent: number
}
export const DailyVolumeFactory = Immutable.Record<DailyVolumeProps>({
  date: '',
  sent: 0,
  cumulativeSent: 0,
} as DailyVolumeProps)
export type DailyVolumeRecord = RecordOf<DailyVolumeProps>

type VolumeDataProps = {
  total: number
  daily: Array<DailyVolumeRecord>
}
export const VolumeDataFactory = Immutable.Record<VolumeDataProps>({
  total: 0,
  daily: [],
} as VolumeDataProps)
export type VolumeDataRecord = RecordOf<VolumeDataProps>

type PlatformSentVolumeProps = {
  loadingState: fetchingState
  email: VolumeDataRecord
  sms: VolumeDataRecord
}
export const PlatformSentVolumeFactory = Immutable.Record<PlatformSentVolumeProps>({
  loadingState: STATUS.INIT,
  email: VolumeDataFactory(),
  sms: VolumeDataFactory(),
} as PlatformSentVolumeProps)
export type PlatformSentVolumeRecord = RecordOf<PlatformSentVolumeProps>

type ProfileVolumeProps = {
  loadingState: fetchingState
  active: VolumeDataRecord
}
export const ProfileVolumeFactory = Immutable.Record<ProfileVolumeProps>({
  loadingState: STATUS.INIT,
  active: VolumeDataFactory(),
} as ProfileVolumeProps)
export type ProfileVolumeRecord = RecordOf<ProfileVolumeProps>

type VolumesProps = {
  profile: ProfileVolumeRecord
  sent: PlatformSentVolumeRecord
}
export const VolumesFactory = Immutable.Record<VolumesProps>({
  profile: ProfileVolumeFactory(),
  sent: PlatformSentVolumeFactory(),
} as VolumesProps)
export type VolumesRecord = RecordOf<VolumesProps>
