import { useSpring, animated, config } from '@react-spring/web'
import * as React from 'react'

const springConfig = config.gentle

type WebSvgProps = {
  mode: 'normal' | 'active' | 'hover'
}

export const WebSvg = ({ mode }: WebSvgProps): React.ReactElement => {
  const active = mode === 'active'
  const hover = mode === 'hover'

  const brProps = useSpring({
    opacity: active ? 0 : hover ? 1 : 0.6,
    config: springConfig,
  })
  const overlayProps = useSpring({
    opacity: active ? 1 : 0,
    config: springConfig,
  })
  const animNotifProps = useSpring({
    transform: `translate(${active || hover ? 0 : 80}, 0)`,
    opacity: active || hover ? 1 : 0,
    config: springConfig,
  })
  return (
    <svg viewBox="0 30 254 147" fill="none" style={{ pointerEvents: 'none' }}>
      <g>
        <rect x="17" y="36.0037" width="220" height="152" rx="10" fill="transparent" />
        <animated.path
          {...overlayProps}
          fill="url(#gradientBrowser)"
          d="M50.7574 59.761C49 61.5184 49 64.3468 49 70.0037V154.004C49 159.661 49 162.489 50.7574 164.246C52.5147 166.004 55.3431 166.004 61 166.004H193C198.657 166.004 201.485 166.004 203.243 164.246C205 162.489 205 159.661 205 154.004V70.0037C205 64.3468 205 61.5184 203.243 59.761C201.485 58.0037 198.657 58.0037 193 58.0037H61C55.3431 58.0037 52.5147 58.0037 50.7574 59.761ZM57 61.0037C55.8954 61.0037 55 61.8991 55 63.0037C55 64.1082 55.8954 65.0037 57 65.0037C58.1046 65.0037 59 64.1082 59 63.0037C59 61.8991 58.1046 61.0037 57 61.0037ZM61 63.0037C61 61.8991 61.8954 61.0037 63 61.0037C64.1046 61.0037 65 61.8991 65 63.0037C65 64.1082 64.1046 65.0037 63 65.0037C61.8954 65.0037 61 64.1082 61 63.0037ZM69 61.0037C67.8954 61.0037 67 61.8991 67 63.0037C67 64.1082 67.8954 65.0037 69 65.0037C70.1046 65.0037 71 64.1082 71 63.0037C71 61.8991 70.1046 61.0037 69 61.0037ZM95 63.0037C95 62.0609 95 61.5894 95.2929 61.2966C95.5858 61.0037 96.0572 61.0037 97 61.0037H157C157.943 61.0037 158.414 61.0037 158.707 61.2966C159 61.5894 159 62.0609 159 63.0037C159 63.9465 159 64.4179 158.707 64.7108C158.414 65.0037 157.943 65.0037 157 65.0037H97C96.0572 65.0037 95.5858 65.0037 95.2929 64.7108C95 64.4179 95 63.9465 95 63.0037ZM185.293 61.2966C185 61.5894 185 62.0609 185 63.0037C185 63.9465 185 64.4179 185.293 64.7108C185.586 65.0037 186.057 65.0037 187 65.0037H197C197.943 65.0037 198.414 65.0037 198.707 64.7108C199 64.4179 199 63.9465 199 63.0037C199 62.0609 199 61.5894 198.707 61.2966C198.414 61.0037 197.943 61.0037 197 61.0037H187C186.057 61.0037 185.586 61.0037 185.293 61.2966Z"
        />
        <animated.path
          {...brProps}
          fill="#99A1AA"
          d="M50.7574 59.761C49 61.5184 49 64.3468 49 70.0037V154.004C49 159.661 49 162.489 50.7574 164.246C52.5147 166.004 55.3431 166.004 61 166.004H193C198.657 166.004 201.485 166.004 203.243 164.246C205 162.489 205 159.661 205 154.004V70.0037C205 64.3468 205 61.5184 203.243 59.761C201.485 58.0037 198.657 58.0037 193 58.0037H61C55.3431 58.0037 52.5147 58.0037 50.7574 59.761ZM57 61.0037C55.8954 61.0037 55 61.8991 55 63.0037C55 64.1082 55.8954 65.0037 57 65.0037C58.1046 65.0037 59 64.1082 59 63.0037C59 61.8991 58.1046 61.0037 57 61.0037ZM61 63.0037C61 61.8991 61.8954 61.0037 63 61.0037C64.1046 61.0037 65 61.8991 65 63.0037C65 64.1082 64.1046 65.0037 63 65.0037C61.8954 65.0037 61 64.1082 61 63.0037ZM69 61.0037C67.8954 61.0037 67 61.8991 67 63.0037C67 64.1082 67.8954 65.0037 69 65.0037C70.1046 65.0037 71 64.1082 71 63.0037C71 61.8991 70.1046 61.0037 69 61.0037ZM95 63.0037C95 62.0609 95 61.5894 95.2929 61.2966C95.5858 61.0037 96.0572 61.0037 97 61.0037H157C157.943 61.0037 158.414 61.0037 158.707 61.2966C159 61.5894 159 62.0609 159 63.0037C159 63.9465 159 64.4179 158.707 64.7108C158.414 65.0037 157.943 65.0037 157 65.0037H97C96.0572 65.0037 95.5858 65.0037 95.2929 64.7108C95 64.4179 95 63.9465 95 63.0037ZM185.293 61.2966C185 61.5894 185 62.0609 185 63.0037C185 63.9465 185 64.4179 185.293 64.7108C185.586 65.0037 186.057 65.0037 187 65.0037H197C197.943 65.0037 198.414 65.0037 198.707 64.7108C199 64.4179 199 63.9465 199 63.0037C199 62.0609 199 61.5894 198.707 61.2966C198.414 61.0037 197.943 61.0037 197 61.0037H187C186.057 61.0037 185.586 61.0037 185.293 61.2966Z"
        />
        <rect x="54" y="68.0037" width="146" height="93" rx="4" fill="white" />
        <animated.rect
          {...overlayProps}
          x="54"
          y="68.0037"
          width="146"
          height="93"
          rx="4"
          fill="url(#paint111_linear)"
          fillOpacity="0.02"
        />
        <animated.g {...animNotifProps}>
          <g filter="url(#shadowNotif)">
            <rect x="88" y="74.0037" width="106" height="32" rx="4" fill="white" />
            <path
              d="M128.293 81.2966C128 81.5894 128 82.0609 128 83.0037C128 83.9465 128 84.4179 128.293 84.7108C128.586 85.0037 129.057 85.0037 130 85.0037H178C178.943 85.0037 179.414 85.0037 179.707 84.7108C180 84.4179 180 83.9465 180 83.0037C180 82.0609 180 81.5894 179.707 81.2966C179.414 81.0037 178.943 81.0037 178 81.0037H130C129.057 81.0037 128.586 81.0037 128.293 81.2966ZM128 90.0037C128 89.0609 128 88.5894 128.293 88.2966C128.586 88.0037 129.057 88.0037 130 88.0037H184C184.943 88.0037 185.414 88.0037 185.707 88.2966C186 88.5894 186 89.0609 186 90.0037C186 90.9465 186 91.4179 185.707 91.7108C185.414 92.0037 184.943 92.0037 184 92.0037H130C129.057 92.0037 128.586 92.0037 128.293 91.7108C128 91.4179 128 90.9465 128 90.0037ZM128 98.5037C128 98.0377 128 97.8048 128.076 97.621C128.178 97.3759 128.372 97.1813 128.617 97.0798C128.801 97.0037 129.034 97.0037 129.5 97.0037H170.5C170.966 97.0037 171.199 97.0037 171.383 97.0798C171.628 97.1813 171.822 97.3759 171.924 97.621C172 97.8048 172 98.0377 172 98.5037C172 98.9696 172 99.2026 171.924 99.3863C171.822 99.6314 171.628 99.826 171.383 99.9275C171.199 100.004 170.966 100.004 170.5 100.004H129.5C129.034 100.004 128.801 100.004 128.617 99.9275C128.372 99.826 128.178 99.6314 128.076 99.3863C128 99.2026 128 98.9696 128 98.5037Z"
              fill="#212B37"
              fillOpacity="0.16"
            />
            <rect
              x="87.5"
              y="73.5037"
              width="33"
              height="33"
              fill="#212B37"
              fillOpacity="0.04"
              stroke="#EFF0F0"
            />
          </g>
          <rect x="87.5" y="73.5037" width="107" height="33" rx="4.5" stroke="#E3E3E3" />
        </animated.g>
      </g>
      <defs>
        <filter
          id="shadowNotif"
          x="59"
          y="49.0037"
          width="164"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="14" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
        <clipPath id="rect-clip">
          <rect x="88" y="74.0037" width="106" height="32" rx="4" fill="white" />
        </clipPath>
        <linearGradient
          id="gradientBrowser"
          x1="121.54"
          y1="58.0037"
          x2="125.659"
          y2="166.174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#027bd8" />
          <stop offset="1" stopColor="#0e68aa" />
        </linearGradient>
        <linearGradient
          id="paint111_linear"
          x1="121.89"
          y1="68.0037"
          x2="125.154"
          y2="161.171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#027bd8" />
          <stop offset="1" stopColor="#0e68aa" />
        </linearGradient>
      </defs>
    </svg>
  )
}
