import styled, { css } from 'styled-components'

type OverlayContainerProps = {
  align?: 'flex-start' | 'center' | 'flex-end'
  isPageWrapper?: boolean
}
export const OverlayContainer = styled.div<OverlayContainerProps>`
  position: absolute;
  width: 100%;
  min-height: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  background: ${props =>
    props.isPageWrapper ? 'rgba(248, 248, 248, 0.95)' : 'rgba(255, 255, 255, 0.94)'};
  ${props =>
    props.isPageWrapper &&
    css`
      padding-top: 80px;
    `}
  align-items: ${p => p.align ?? 'center'};
  z-index: 12;

  @media (max-width: 700px) {
    width: calc(100% + 64px);
    left: -32px;
  }
`
type WrapperContainerProps = {
  isOverlayShown: boolean
  isPageWrapper: boolean
}
export const WrapperContainer = styled.div<WrapperContainerProps>`
  position: relative;
  ${props =>
    props.isPageWrapper
      ? css`
          ${props.isOverlayShown &&
          css`
            min-height: 530px;
            width: 100%;
          `}
          > ${OverlayContainer} {
            align-items: flex-start;
          }
        `
      : css`
          ${props.isOverlayShown &&
          css`
            width: 100%;
            border-radius: 3px;
            overflow: hidden;
          `}
          > ${OverlayContainer} {
            border-radius: 4px;
            align-items: center;
          }
        `}
`
