import { generateTypedId } from 'com.batch.common/typed-id'

import { duplicateMessage } from './duplicate-message'
import { setActiveLanguage } from './multilanguage'

import { removeVariantIdWithoutGap } from '../models/message.helper'
import { type ExperimentRecord, type VariantId } from '../models/message.records'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { type ReduxAction, type DispatchBoundFn } from 'com.batch.redux/_records'

export type UpdateCampaignExperimentAction = ReduxAction<
  'UPDATE_CAMPAIN_EXPERIMENT',
  ExperimentRecord
>
const fireUpdateAction = (
  dispatch,
  stepMessageNodeId: string | null | undefined,
  exp: ExperimentRecord
) => {
  if (stepMessageNodeId) {
    throw new Error('Not implemented')
  } else {
    dispatch({
      type: 'UPDATE_CAMPAIN_EXPERIMENT',
      payload: exp,
    } as UpdateCampaignExperimentAction)
  }
}
export const selectVariant = ({
  stepMessageNodeId,
  variantId,
}: {
  stepMessageNodeId: string | null | undefined
  variantId: VariantId
}): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const getConfig = getMessageConfigSelector(getState())
    const exp = getConfig({ stepMessageNodeId }).experiment.set('selectedVariantId', variantId)
    // @TOTO : maybe check if activeLanguage is present on variant, and do reset when it is the case
    dispatch(setActiveLanguage('default'))
    fireUpdateAction(dispatch, stepMessageNodeId, exp)
  }
}

export const removeVariant = ({
  stepMessageNodeId,
  variantId,
}: {
  stepMessageNodeId: string | null | undefined
  variantId: VariantId
}): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const getConfig = getMessageConfigSelector(getState())
    const exp = getConfig({ stepMessageNodeId }).experiment
    if (exp.selectedVariantId === variantId) {
      // @TOTO : maybe check if activeLanguage is present on variant, and do reset when it is the case
      dispatch(setActiveLanguage('default'))
    }
    const updatedExperiment = exp.set(
      'variants',
      removeVariantIdWithoutGap(exp.variants, variantId)
    )
    const fixedActiveVariant = updatedExperiment.variants.has(updatedExperiment.selectedVariantId)
      ? updatedExperiment
      : updatedExperiment.set('selectedVariantId', 1)
    fireUpdateAction(dispatch, stepMessageNodeId, fixedActiveVariant)
  }
}

export const enableExperiment = ({
  stepMessageNodeId,
}: {
  stepMessageNodeId: string | null | undefined
}): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const getConfig = getMessageConfigSelector(getState())
    const config = getConfig({ stepMessageNodeId })
    const exp = config.experiment.set('enabled', true)

    fireUpdateAction(
      dispatch,
      stepMessageNodeId,
      exp.set(
        'variants',
        config.experiment.variants.size === 0
          ? config.experiment.variants.set(1, config.messageTypedId)
          : config.experiment.variants
      )
    )
  }
}

const getNextVariantId = (size: number): VariantId => {
  switch (size) {
    case 0:
      return 1
    case 1:
      return 2
    case 2:
      return 3
    case 3:
      return 4
    default:
      throw new Error('Too many variants')
  }
}

export const addVariant = ({
  stepMessageNodeId,
  sourceVariantId,
  copySourceVariant,
}: {
  stepMessageNodeId: string | null | undefined
  sourceVariantId: VariantId
  copySourceVariant: boolean
}): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const getConfig = getMessageConfigSelector(getState())
    const config = getConfig({ stepMessageNodeId })

    const sourceMessageId = config.experiment.variants.get(sourceVariantId)
    if (!sourceMessageId) throw new Error('Attempt to clone non existent id')

    const messageId = generateTypedId('message')
    if (copySourceVariant) {
      dispatch(
        duplicateMessage({
          sourceTypedMessageId: sourceMessageId,
          targetTypedMessageId: messageId,
        })
      )
    } else {
      dispatch(setActiveLanguage('default'))
    }
    const nextVariantId = getNextVariantId(config.experiment.variants.size)
    fireUpdateAction(
      dispatch,
      stepMessageNodeId,
      config.experiment
        .set('variants', config.experiment.variants.set(nextVariantId, messageId))
        .set('selectedVariantId', nextVariantId)
    )
  }
}

export const disableExperiment = ({
  stepMessageNodeId,
}: {
  stepMessageNodeId: string | null | undefined
}): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const getConfig = getMessageConfigSelector(getState())
    const exp = getConfig({ stepMessageNodeId }).experiment.set('enabled', false)
    fireUpdateAction(dispatch, stepMessageNodeId, exp)
    // if (!stepMessageNodeId) {
    //   dispatch(
    //     updateOrchestrationCampaign(
    //       getState().orchestration.campaign.set('messageTypedId', exp.variants.get(0, ''))
    //     )
    //   )
    // } else {
    //   throw new Error('Not implemented')
    // }
  }
}
