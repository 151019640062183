import { type EmailContent } from 'com.batch/email/infra/types/email.message.types'
import { type OrchestrationPush } from 'com.batch/push/infra/types/push.message.types'
import { type SmsContent } from 'com.batch/sms/infra/types/sms.message.types'

export type OrchestrationJourney = {
  settings: OrchestrationJourneySettings
  messages: {
    [key: string]:
      | {
          email: EmailContent
        }
      | {
          sms: SmsContent
        }
      | {
          push: OrchestrationPush
        }
  }
  nodes: {
    [key: string]: Blocks
  }
  rootId: string
}
export type OrchestrationJourneyInstanceId = {
  type: 'LABEL' | 'ATTRIBUTE'
  instanceIdFieldName: string
}
export type OrchestrationJourneySettings = {
  name: string
  startTime?: string
  endTime?: string
  runningState?: 'UNKNOWN_RUNNING_STATE' | 'RUNNING_STATE' | 'STOPPED_STATE'
  globalCapping?: number
  globalGracePeriod?: string
  targeting?: {
    regions?: Array<string>
    languages?: Array<string>
    jsonQuery?: string
  }
  entryEvents?: Array<TriggerInfo>
  instanceId?: OrchestrationJourneyInstanceId
  lastStoppedDate?: string
  labels?: Array<string>
}

// --- event ---------------------

type TriggerInfo = {
  event: string
  jsonQuery?: string
  protoQuery?: Record<any, any>
}

// --- messages ------------------
// --- blocks / nodes ------------
export type EventWithQuery = {
  event: TriggerInfo
  requireMatchingInstanceId?: boolean
}
type OrchestrationBaseBlock = {
  name: string
  label?: string
  decorators?: Array<Decorator>
}
export type MessageBlock = OrchestrationBaseBlock & {
  message: {
    nextNodeId: string
    messageReference: string
  }
}
export type FinalBlock = OrchestrationBaseBlock & {
  final: Record<any, any>
}
export type TimerBlock = OrchestrationBaseBlock & {
  timer: {
    waitUntilTime?: {
      hour: number
      min: number
      daysOfWeek: Array<number>
    }
    nextNodeId: string
    timer?: string
    timerReference?: {
      eventName: string
      timerReferenceAttribute: string
    }
    onEvents?: Array<{
      triggers: Array<EventWithQuery>
      nextNodeId: string
    }>
  }
}
export type YesNoBlock = OrchestrationBaseBlock & {
  yesNo: {
    yesNodeId: string
    noNodeId: string
    branchingCriteria: {
      regions?: Array<string>
      languages?: Array<string>
      jsonQuery?: string
    }
  }
}

export type RandomBlock = OrchestrationBaseBlock & {
  random: {
    splits: Array<{
      weight: number
      nextNodeId: string
    }>
  }
}

export type Blocks = TimerBlock | FinalBlock | MessageBlock | YesNoBlock | RandomBlock

export const isTimer = (block: Blocks): block is TimerBlock => {
  return (block as TimerBlock).timer !== undefined
}
export const isMessage = (block: Blocks): block is MessageBlock => {
  return (block as MessageBlock).message !== undefined
}
export const isYesNo = (block: Blocks): block is YesNoBlock => {
  return (block as YesNoBlock).yesNo !== undefined
}
export const isRandom = (block: Blocks): block is RandomBlock => {
  return (block as RandomBlock).random !== undefined
}
// --- decorators ----------------

type ResetOnReenterDecorator = {
  reset: Record<any, any>
}

type CheckTargetingDecorator = {
  check: Record<any, any>
}

type CappingDecorator = {
  capping: {
    delta?: number
  }
}

type QuietDecorator = {
  quiet: {
    behaviour?: 'WAIT' | 'SKIP' | 'UNKNOWN'
    startHour: number
    endHour: number
    startMin?: number
    endMin?: number
    quietHoursTimePeriodDisabled?: boolean
    quietDaysOfWeek: Array<number>
  }
}

export type Decorator =
  | ResetOnReenterDecorator
  | CheckTargetingDecorator
  | CappingDecorator
  | QuietDecorator

export const isQuietDecorator = (deco: Decorator): deco is QuietDecorator => {
  return (deco as QuietDecorator).quiet !== undefined
}
