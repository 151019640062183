import Immutable, { type Set, type Map } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useToggle } from 'components/_hooks'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  FooterBoxActions,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { BoxSectionContainer } from 'components/common/box/box.styles'
import { Button, ButtonLink } from 'components/common/button'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Checkbox } from 'components/form/fields/checkbox'
import { ProjectAvatar } from 'components/project/navigation/dropdown.styles'
import { colors } from 'components/styled/tokens'

import { generateOrchestrationUrl } from 'com.batch.common/router/url-generator-helper'

import { CheckableProject } from './replication-modal.styles'

import { type pushCampaignSendType, type fetchingState } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { currentProjectSelector, projectsSelector } from 'com.batch.redux/project.selector'

import { replicateOrchestration } from 'com.batch/orchestration/usecases/replicate-orchestration'

export type ReplicationOrchestrationMeta = {
  id: string
  name: string
  sendType: pushCampaignSendType
  channels: Set<ChannelUntilCleanup>
}
type ReplicationModalProps = {
  isOpened: boolean
  dismiss: () => void
  orchestrationMeta: ReplicationOrchestrationMeta
}
type RepRes = {
  state: fetchingState
  error?: string
  url?: string
}
const EmptyRes: RepRes = { state: 'LOADING' }
export const ReplicationModal = ({
  isOpened,
  dismiss,
  orchestrationMeta,
}: ReplicationModalProps): React.ReactElement => {
  const { id, name, sendType, channels } = orchestrationMeta
  const dispatch = useDispatch()
  const replicatingState = useToggle()
  const [results, setResults] = React.useState<Map<string, RepRes>>(Immutable.Map())

  const projects = useSelector(projectsSelector)
  const currentProject = useSelector(currentProjectSelector)
  const createOnProjectCheck = React.useCallback((project: ProjectRecord) => {
    return () =>
      setSelectedProjects(sel => (sel.has(project) ? sel.delete(project) : sel.add(project)))
  }, [])
  const [selectedProjects, setSelectedProjects] = React.useState<Set<ProjectRecord>>(
    Immutable.Set([currentProject])
  )
  const filterProject = React.useCallback(
    (project: ProjectRecord) => {
      return channels.reduce((allowed, channel) => {
        let currentChannelOk = true
        switch (channel) {
          case 'email':
            if (!project.emailConfigured) currentChannelOk = false
            break
          case 'sms':
            if (!project.smsConfigured) currentChannelOk = false
            break
          case 'push':
            if (!project.anyPushConfigured) currentChannelOk = false
            break
          default:
            currentChannelOk = true
        }
        return allowed && currentChannelOk
      }, true)
    },
    [channels]
  )
  const dismissAndClean = React.useCallback(() => {
    if (replicatingState.value) return
    dismiss()
    setResults(Immutable.Map())
    replicatingState.close()
    setSelectedProjects(Immutable.Set([currentProject]))
  }, [currentProject, dismiss, replicatingState])
  const onReplicate = React.useCallback(async () => {
    replicatingState.open()
    const promises = Promise.all(
      selectedProjects.toArray().map(async proj => {
        try {
          const token = await dispatch(replicateOrchestration({ id, targetProject: proj }))
          setResults(res =>
            res.set(proj.id, {
              state: 'LOADED',
              url: generateOrchestrationUrl({
                channel: channels.first(),
                page: 'form',
                sendType: sendType,
                companyId: currentProject.companyId,
                projectId: proj.id,
                token: token,
              }),
            })
          )
        } catch (error: any) {
          setResults(res =>
            res.set(proj.id, {
              state: 'ERROR',
              error: error?.error?.errors?.[0]?.message ?? 'Unknown error',
            })
          )
        }
      })
    )
    await promises
    replicatingState.close()
  }, [
    channels,
    currentProject.companyId,
    dispatch,
    id,
    replicatingState,
    selectedProjects,
    sendType,
  ])
  return (
    <Popin opened={isOpened} close={dismissAndClean}>
      <Box style={{ width: '640px' }}>
        <BoxHeader>
          <HeaderBoxTitle title={'Replicate this orchestration on the following projects'} />
          <HeaderBoxActions>
            <Button onClick={dismissAndClean}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          {(results.size === 0 || replicatingState.value) && (
            <BoxSectionContainer $padding style={{ marginLeft: 0, marginRight: 0 }}>
              <p>
                This will replicate orchestration <ConfirmHighlight>{name}</ConfirmHighlight> on
                selected projects. Newly created orchestrations will be in draft state.
              </p>
              <p>
                Please note that entry event, targetings, personnalization attributes and sender
                identities might need to be updated on a different project.
              </p>
            </BoxSectionContainer>
          )}
          <BoxSectionContainer
            style={{
              marginLeft: 0,
              marginRight: 0,
              padding: '20px',
              maxHeight: 256,
              overflowY: 'auto',
            }}
          >
            {results.size > 0 && !replicatingState.value
              ? selectedProjects.map(proj => {
                  const res = results.get(proj.id, EmptyRes)
                  return (
                    <Grid
                      template="32px 1fr 80px"
                      key={proj.id}
                      style={{ height: 44, margin: '4px 0px' }}
                    >
                      <ProjectAvatar project={proj} />
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {proj.name}
                      </span>
                      <span>
                        {res?.state === 'LOADED' ? (
                          <ButtonLink
                            kind="secondary"
                            intent="action"
                            addOn="suffix"
                            href={res.url}
                            target="_blank"
                          >
                            View
                            <Icon icon="external" />
                          </ButtonLink>
                        ) : res?.state === 'ERROR' ? (
                          <Tooltip tooltip={res.error}>
                            <Icon icon="error" color={colors.fillDanger} />
                          </Tooltip>
                        ) : null}
                      </span>
                    </Grid>
                  )
                })
              : projects
                  .filter(filterProject)
                  .sort(proj => (proj === currentProject ? -1 : 0))
                  .map(proj => {
                    return (
                      <CheckableProject key={proj.id}>
                        <Checkbox
                          onChange={createOnProjectCheck(proj)}
                          disabled={replicatingState.value}
                          checked={selectedProjects.has(proj)}
                          style={{ width: '100%' }}
                          label={
                            <Grid
                              template={proj === currentProject ? '32px 1fr auto' : '32px 1fr'}
                              style={{ minWidth: 90 }}
                            >
                              <ProjectAvatar project={proj} />
                              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {proj.name}
                              </span>
                              {proj === currentProject && (
                                <span style={{ color: colors.textLight, fontWeight: 400 }}>
                                  current project
                                </span>
                              )}
                            </Grid>
                          }
                        />
                      </CheckableProject>
                    )
                  })}
          </BoxSectionContainer>
        </BoxBody>
        <BoxFooter isEditable>
          {results.size > 0 && !replicatingState.value ? (
            <React.Fragment>&nbsp;</React.Fragment>
          ) : (
            <Button kind="inline" onClick={dismissAndClean}>
              Cancel
            </Button>
          )}
          <FooterBoxActions>
            {results.size > 0 && !replicatingState.value ? (
              <Button intent="action" kind="primary" onClick={dismissAndClean}>
                Done
              </Button>
            ) : (
              <Button
                intent="action"
                kind="primary"
                isLoading={replicatingState.value}
                onClick={onReplicate}
                disabled={selectedProjects.size === 0 || replicatingState.value}
              >
                {`Replicate on ${
                  selectedProjects.size > 1
                    ? `these projects (${selectedProjects.size})`
                    : 'this project'
                }`}
              </Button>
            )}
          </FooterBoxActions>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
