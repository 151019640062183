import * as React from 'react'

type ReviewItemProps = {
  kind?: React.ReactNode
  children: React.ReactNode
  style?: Record<any, any>
}

const ReviewItem = ({ kind, children, style }: ReviewItemProps): React.ReactElement => {
  return (
    <div className="prr__item__sub" style={style}>
      {kind && <div className="prr__item__sub__kind">{kind}</div>}
      <div className="prr__item__sub__content" style={{ width: '100%' }}>
        {children}
      </div>
    </div>
  )
}
export default ReviewItem
