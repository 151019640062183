import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import * as colorsLegacy from 'components/styled/colors'
import { colors, schemes } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { humanizeTimeRemainingDayjs } from 'com.batch.common/utils'

export const WebpushSafariExpired = ({
  expire,
  displayIcon = true,
}: {
  expire: string
  displayIcon?: boolean
}): React.ReactElement => {
  const now = new Date()
  const expired = dayjs(expire).isBefore(dayjs(now))
  const soonExpired = dayjs(expire).isBetween(dayjs(now), dayjs(now).add(2, 'month'))

  const iconType = expired ? 'error' : soonExpired ? 'warning-triangle' : 'success'
  const iconColor = expired
    ? colors.textDangerContrast
    : soonExpired
      ? schemes.orange['50']
      : colorsLegacy.good.shade.s2

  const iconComponent = displayIcon ? (
    <Icon icon={iconType} style={{ marginRight: 7, color: iconColor }} />
  ) : (
    ''
  )

  // certificate expired
  if (expired) {
    return (
      <React.Fragment>
        {iconComponent}
        expired{' '}
        <span style={{ color: '#707782' }}>(since {dayjs(expire).format('DD/MM/YYYY')})</span>
      </React.Fragment>
    )
  }
  // certificate soon expired (< 2 months)
  if (soonExpired) {
    return (
      <React.Fragment>
        {iconComponent}
        {humanizeTimeRemainingDayjs(dayjs(expire))}{' '}
        <span style={{ color: '#707782' }}>(until {dayjs(expire).format('DD/MM/YYYY')})</span>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      {iconComponent}
      {humanizeTimeRemainingDayjs(dayjs(expire))}{' '}
      <span style={{ color: '#707782' }}>(until {dayjs(expire).format('DD/MM/YYYY')})</span>
    </React.Fragment>
  )
}
