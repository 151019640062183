import Immutable, { type RecordOf } from 'immutable'

type InAppCappingProps = {
  enabled: boolean
  level: 'global' | 'session'
  capping: number
  periodUnit: 'h' | 'd'
  periodValue: number
}

export const InAppCappingFactory = Immutable.Record<InAppCappingProps>({
  enabled: false,
  level: 'session',
  capping: 1,
  periodUnit: 'h',
  periodValue: 1,
} as InAppCappingProps)

export type InAppCappingRecord = RecordOf<InAppCappingProps>
