import * as React from 'react'
import styled from 'styled-components'

import { schemes, Opacity } from 'components/styled/tokens'

//  In order to update flow hint for avaliableIcons enum, you can run & paste the result
//  ls public/medias/icons/ | sed 's/.svg//g'  | sed "s/^.*/ '&':'',/g" | pbcopy

export type availableIcons =
  | 'split'
  | 'notfound'
  | 'action'
  | 'add'
  | 'adjust'
  | 'air'
  | 'all-of'
  | 'analytics'
  | 'android'
  | 'android-smartphone'
  | 'arrow-double-horizontal'
  | 'arrow-long-down'
  | 'arrow-long-left'
  | 'arrow-long-right'
  | 'arrow-long-up'
  | 'ascending'
  | 'audiences'
  | 'audio'
  | 'automation'
  | 'fit'
  | 'exit'
  | 'experiment'
  | 'billing'
  | 'boolean'
  | 'box-valid'
  | 'browser'
  | 'calendar'
  | 'campaign-instant'
  | 'campaign-instant-icon'
  | 'campaign-recurring'
  | 'campaign-scheduled'
  | 'campaign-trigger'
  | 'change'
  | 'check'
  | 'check-dashed'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'children'
  | 'chrome-colored'
  | 'chrome'
  | 'click-false'
  | 'click'
  | 'close'
  | 'code'
  | 'collapse-expand'
  | 'collapse-node'
  | 'collapse-shrink'
  | 'collection'
  | 'computer'
  | 'connect'
  | 'cookie'
  | 'copy'
  | 'cordova'
  | 'cross'
  | 'crown'
  | 'custom-audience'
  | 'danger'
  | 'date'
  | 'default'
  | 'delete'
  | 'descending'
  | 'details'
  | 'device'
  | 'different'
  | 'documentation'
  | 'door'
  | 'dots'
  | 'download'
  | 'draft'
  | 'draft-fill'
  | 'edge'
  | 'edit'
  | 'ends-with'
  | 'enter'
  | 'equal'
  | 'error'
  | 'event'
  | 'exist'
  | 'expand'
  | 'expand-invert'
  | 'expand-node'
  | 'external'
  | 'favorize'
  | 'file-icon'
  | 'file'
  | 'filter'
  | 'firefox'
  | 'flutter'
  | 'forbidden'
  | 'fullscreen'
  | 'graphic'
  | 'greater-equal'
  | 'greater'
  | 'help'
  | 'hide'
  | 'histogram'
  | 'identifier'
  | 'image'
  | 'import'
  | 'integer'
  | 'in-app'
  | 'inbox'
  | 'infos'
  | 'interaction'
  | 'ios'
  | 'ios-smartphone'
  | 'label'
  | 'link'
  | 'location'
  | 'lock'
  | 'logout'
  | 'lower-equal'
  | 'lower'
  | 'mac'
  | 'macros'
  | 'mail'
  | 'mail-rounded'
  | 'mail-fill'
  | 'mail-fill-rounded'
  | 'message'
  | 'metric'
  | 'mobile'
  | 'mouse'
  | 'money'
  | 'news'
  | 'none'
  | 'no-result'
  | 'not-one-of'
  | 'notif'
  | 'number'
  | 'object'
  | 'one-of'
  | 'operator'
  | 'optin'
  | 'options'
  | 'os'
  | 'parent'
  | 'paused'
  | 'pc-windows'
  | 'pending'
  | 'place'
  | 'planned'
  | 'play'
  | 'play_circled'
  | 'privacy'
  | 'push-expanded'
  | 'push'
  | 'push-compact'
  | 'quiet-hours'
  | 'react-native'
  | 'reattributed'
  | 'reload-animated'
  | 'reload'
  | 'remove'
  | 'required'
  | 'return'
  | 'reveal-false'
  | 'reveal'
  | 'running-animated'
  | 'running'
  | 'running-fill'
  | 'safari'
  | 'save'
  | 'schedule'
  | 'search'
  | 'segments'
  | 'select'
  | 'send'
  | 'settings'
  | 'smart-segments'
  | 'sms'
  | 'show'
  | 'smart'
  | 'spinner'
  | 'star'
  | 'starts-with'
  | 'stopped'
  | 'success'
  | 'table'
  | 'target-false'
  | 'target'
  | 'targeting'
  | 'team'
  | 'text'
  | 'timer'
  | 'tiret'
  | 'trigger'
  | 'unavailable'
  | 'undelivered'
  | 'unify'
  | 'undo'
  | 'redo'
  | 'unity'
  | 'unlock'
  | 'upload'
  | 'user'
  | 'version'
  | 'video'
  | 'view'
  | 'waiting-fill'
  | 'waiting'
  | 'wait'
  | 'warning-triangle'
  | 'warning'
  | 'webpush'
  | 'webpush-fill'
  | 'windows'
  | 'world'
  | 'yesno'
  | 'translation'
  | 'trending-up'
  | 'trending-down'
  | 'cup-fill'
  | 'cup'
  | 'add-list'
  | 'nested'
  | 'inbox'

const EmptyComponent: React.ComponentType = () => null
const data: { [K in availableIcons]: React.ComponentType } = {
  split: EmptyComponent,
  notfound: EmptyComponent,
  action: EmptyComponent,
  add: EmptyComponent,
  adjust: EmptyComponent,
  air: EmptyComponent,
  'all-of': EmptyComponent,
  analytics: EmptyComponent,
  android: EmptyComponent,
  'android-smartphone': EmptyComponent,
  'arrow-double-horizontal': EmptyComponent,
  'arrow-long-down': EmptyComponent,
  'arrow-long-left': EmptyComponent,
  'arrow-long-right': EmptyComponent,
  'arrow-long-up': EmptyComponent,
  ascending: EmptyComponent,
  audiences: EmptyComponent,
  audio: EmptyComponent,
  automation: EmptyComponent,
  billing: EmptyComponent,
  experiment: EmptyComponent,
  boolean: EmptyComponent,
  'box-valid': EmptyComponent,
  browser: EmptyComponent,
  calendar: EmptyComponent,
  'campaign-instant': EmptyComponent,
  'campaign-instant-icon': EmptyComponent,
  'campaign-recurring': EmptyComponent,
  'campaign-scheduled': EmptyComponent,
  'campaign-trigger': EmptyComponent,
  change: EmptyComponent,
  check: EmptyComponent,
  'check-dashed': EmptyComponent,
  'chevron-down': EmptyComponent,
  'chevron-left': EmptyComponent,
  'chevron-right': EmptyComponent,
  'chevron-up': EmptyComponent,
  children: EmptyComponent,
  chrome: EmptyComponent,
  'chrome-colored': EmptyComponent,
  'click-false': EmptyComponent,
  fit: EmptyComponent,
  exit: EmptyComponent,
  click: EmptyComponent,
  close: EmptyComponent,
  code: EmptyComponent,
  'collapse-expand': EmptyComponent,
  'collapse-node': EmptyComponent,
  'collapse-shrink': EmptyComponent,
  collection: EmptyComponent,
  computer: EmptyComponent,
  connect: EmptyComponent,
  cookie: EmptyComponent,
  copy: EmptyComponent,
  cordova: EmptyComponent,
  cross: EmptyComponent,
  crown: EmptyComponent,
  'custom-audience': EmptyComponent,
  danger: EmptyComponent,
  date: EmptyComponent,
  default: EmptyComponent,
  delete: EmptyComponent,
  descending: EmptyComponent,
  details: EmptyComponent,
  device: EmptyComponent,
  different: EmptyComponent,
  documentation: EmptyComponent,
  door: EmptyComponent,
  dots: EmptyComponent,
  download: EmptyComponent,
  draft: EmptyComponent,
  'draft-fill': EmptyComponent,
  edge: EmptyComponent,
  edit: EmptyComponent,
  'ends-with': EmptyComponent,
  enter: EmptyComponent,
  equal: EmptyComponent,
  error: EmptyComponent,
  event: EmptyComponent,
  exist: EmptyComponent,
  expand: EmptyComponent,
  'expand-invert': EmptyComponent,
  'expand-node': EmptyComponent,
  external: EmptyComponent,
  favorize: EmptyComponent,
  'file-icon': EmptyComponent,
  file: EmptyComponent,
  filter: EmptyComponent,
  firefox: EmptyComponent,
  flutter: EmptyComponent,
  forbidden: EmptyComponent,
  fullscreen: EmptyComponent,
  graphic: EmptyComponent,
  'greater-equal': EmptyComponent,
  greater: EmptyComponent,
  help: EmptyComponent,
  hide: EmptyComponent,
  histogram: EmptyComponent,
  identifier: EmptyComponent,
  inbox: EmptyComponent,
  image: EmptyComponent,
  import: EmptyComponent,
  integer: EmptyComponent,
  'in-app': EmptyComponent,
  infos: EmptyComponent,
  interaction: EmptyComponent,
  ios: EmptyComponent,
  'ios-smartphone': EmptyComponent,
  label: EmptyComponent,
  link: EmptyComponent,
  location: EmptyComponent,
  lock: EmptyComponent,
  logout: EmptyComponent,
  'lower-equal': EmptyComponent,
  lower: EmptyComponent,
  mac: EmptyComponent,
  macros: EmptyComponent,
  mail: EmptyComponent,
  'mail-rounded': EmptyComponent,
  'mail-fill': EmptyComponent,
  'mail-fill-rounded': EmptyComponent,
  message: EmptyComponent,
  metric: EmptyComponent,
  mobile: EmptyComponent,
  mouse: EmptyComponent,
  money: EmptyComponent,
  news: EmptyComponent,
  none: EmptyComponent,
  'no-result': EmptyComponent,
  'not-one-of': EmptyComponent,
  notif: EmptyComponent,
  number: EmptyComponent,
  object: EmptyComponent,
  'one-of': EmptyComponent,
  operator: EmptyComponent,
  optin: EmptyComponent,
  options: EmptyComponent,
  os: EmptyComponent,
  parent: EmptyComponent,
  paused: EmptyComponent,
  'pc-windows': EmptyComponent,
  pending: EmptyComponent,
  place: EmptyComponent,
  planned: EmptyComponent,
  play: EmptyComponent,
  play_circled: EmptyComponent,
  privacy: EmptyComponent,
  'push-expanded': EmptyComponent,
  push: EmptyComponent,
  'push-compact': EmptyComponent,
  'quiet-hours': EmptyComponent,
  'react-native': EmptyComponent,
  reattributed: EmptyComponent,
  'reload-animated': EmptyComponent,
  reload: EmptyComponent,
  remove: EmptyComponent,
  required: EmptyComponent,
  return: EmptyComponent,
  'reveal-false': EmptyComponent,
  reveal: EmptyComponent,
  'running-animated': EmptyComponent,
  running: EmptyComponent,
  'running-fill': EmptyComponent,
  safari: EmptyComponent,
  save: EmptyComponent,
  schedule: EmptyComponent,
  search: EmptyComponent,
  segments: EmptyComponent,
  select: EmptyComponent,
  send: EmptyComponent,
  settings: EmptyComponent,
  'smart-segments': EmptyComponent,
  sms: EmptyComponent,
  show: EmptyComponent,
  smart: EmptyComponent,
  spinner: EmptyComponent,
  star: EmptyComponent,
  'starts-with': EmptyComponent,
  stopped: EmptyComponent,
  success: EmptyComponent,
  table: EmptyComponent,
  'target-false': EmptyComponent,
  target: EmptyComponent,
  targeting: EmptyComponent,
  team: EmptyComponent,
  text: EmptyComponent,
  timer: EmptyComponent,
  tiret: EmptyComponent,
  trigger: EmptyComponent,
  unavailable: EmptyComponent,
  undelivered: EmptyComponent,
  unify: EmptyComponent,
  unity: EmptyComponent,
  unlock: EmptyComponent,
  undo: EmptyComponent,
  redo: EmptyComponent,
  upload: EmptyComponent,
  user: EmptyComponent,
  version: EmptyComponent,
  video: EmptyComponent,
  view: EmptyComponent,
  'waiting-fill': EmptyComponent,
  waiting: EmptyComponent,
  wait: EmptyComponent,
  'warning-triangle': EmptyComponent,
  warning: EmptyComponent,
  webpush: EmptyComponent,
  'webpush-fill': EmptyComponent,
  windows: EmptyComponent,
  world: EmptyComponent,
  yesno: EmptyComponent,
  translation: EmptyComponent,
  'trending-up': EmptyComponent,
  'trending-down': EmptyComponent,
  'cup-fill': EmptyComponent,
  cup: EmptyComponent,
  'add-list': EmptyComponent,
  nested: EmptyComponent,
}

export const availableIconsArray: Array<availableIcons> = Object.keys(data) as Array<availableIcons>
availableIconsArray.forEach(k => {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const mod = require(`../../../public/medias/icons/${k}.svg`)
  data[k] = mod.default as React.ComponentType
})

export type IconProps = {
  icon: availableIcons
  size?: number
  color?: string
  thickness?: number
  wireframe?: boolean
} & React.ComponentPropsWithoutRef<'i'>

export const Icon = React.forwardRef<HTMLElement, IconProps>((props: IconProps, ref) => {
  const { icon, size, color, thickness, wireframe, className, style } = props
  const IconRoot = data[icon]
  if (!IconRoot) {
    console.error(`Icon "${icon}" doesn't exist.`)
    return null
  }

  let computedColor = 'currentColor'
  if (defaultColors[icon]) computedColor = defaultColors[icon]
  if (color) computedColor = color

  return (
    <IconContainer
      ref={ref}
      role="img"
      aria-label={props.icon}
      {...{ color: computedColor, size, thickness, wireframe, className, style }}
    >
      <IconRoot />
    </IconContainer>
  )
})
type IconContainerProps = {
  color: string
  size?: number
  thickness?: number
  wireframe?: boolean
}
export const IconContainer = styled.i<IconContainerProps>`
  display: inline-block;
  font-size: ${p => (p.size ? `${p.size}px` : '1em')};
  line-height: ${p => (p.size ? `${p.size}px` : '1em')};
  color: currentColor;

  svg,
  svg:root,
  svg:not(:root) {
    width: 1em;
    height: 1em;
    overflow: visible;
    stroke: ${p => p.color};
    color: ${p => p.color};
    stroke-width: ${p => p.thickness || 1.5}px;
    fill: ${p => p.color};
    strokelinecap: round;
    strokelinejoin: round;
    border: ${p => (p.wireframe ? `1px solid ${Opacity(schemes.red['70'], 0.58)}` : 'none')};
  }

  path,
  circle,
  rect {
    transform: translate(110, 110);
    vector-effect: non-scaling-stroke;
  }
`

const defaultColors: Partial<{ [key in availableIcons]: string }> = {
  android: '#8cc052',
  windows: '#0078d7',
  ios: '#cccfda',
}

Icon.displayName = 'Icon'

export const loadIconsForTest = async () => {
  for (const k of availableIconsArray) {
    try {
      const mod = await import(`../../../public/medias/icons/${k}.svg`)
      data[k] = mod.default
    } catch (error: any) {
      console.error(`Failed to load icon: ${k}`, error)
    }
  }
}
