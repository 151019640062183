import Immutable, { type List, type Set } from 'immutable'
import * as React from 'react'

import { ConditionLine, FlexLine, FlexLineItem } from 'components/common/flexline'
import { OptionAudience } from 'components/common/option-row-audience'
import { Icon } from 'components/common/svg-icon'
import { SelectMulti } from 'components/form'

import { type CustomAudienceRecord } from 'com.batch.redux/_records'
import * as api from 'com.batch.redux/audience.api'

const audienceToString = (audience?: CustomAudienceRecord | null): string =>
  (audience?.description ?? '') + (audience?.name ?? '')

type CustomAudienceSelectProps = {
  appId: number
  updateCustomAudiences: (arg1: Set<CustomAudienceRecord>) => void
  values: Set<CustomAudienceRecord>
}
type CustomAudiencePickerProps = CustomAudienceSelectProps & {
  selectOnly?: boolean
}
const CustomAudienceSelect = ({
  appId,
  values,
  updateCustomAudiences,
}: CustomAudienceSelectProps): React.ReactElement => {
  const onChange = React.useCallback(
    (audiencesList: List<CustomAudienceRecord>) =>
      updateCustomAudiences(Immutable.Set(audiencesList)),
    [updateCustomAudiences]
  )
  const loadOptions = React.useCallback(
    (query: string): Promise<List<CustomAudienceRecord>> => {
      return api
        .fetchCustomAudiences(appId, {
          sortBy: 'date',
          sortOrder: 'dsc',
          search: query,
        })
        .then(
          ({ customAudiences }): List<CustomAudienceRecord> => {
            return customAudiences
          },
          () => {
            return Immutable.List()
          }
        )
    },
    [appId]
  )
  return (
    <SelectMulti
      isClearable
      optionFormatter={OptionAudience}
      loadOptions={loadOptions}
      placeholder="Search and pick an audience"
      optionToString={audienceToString}
      value={values.toList()}
      onChange={onChange}
    />
  )
}

export const CustomAudiencePicker = ({
  selectOnly = false,
  ...props
}: CustomAudiencePickerProps): React.ReactElement => {
  if (selectOnly) return <CustomAudienceSelect {...props} />
  return (
    <ConditionLine>
      <FlexLineItem grow={1}>
        <div className="condi">
          <div className="condi__logical condi__logical--disabled">AND</div>
          <div className="condi__header">
            <Icon icon="custom-audience" size={14} className="condi__header__icon" />
            <div className="condi__header__label condi__header__label--noevent condi__header__label--main">
              Custom Audiences
            </div>
            <div className="condi__header__phrase">
              <FlexLine>
                <FlexLineItem grow={1}>
                  <CustomAudienceSelect {...props} />
                </FlexLineItem>
              </FlexLine>
            </div>
          </div>
          <div className="condi__logical condi__logical--bottom condi__logical--disabled">AND</div>
        </div>
      </FlexLineItem>
    </ConditionLine>
  )
}
