import * as React from 'react'
import request from 'superagent-interface-promise'

import { Button } from 'components/common/button'
import { Form, ConfirmPassword } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

import { PasswordRecoveryContainer } from './password-recovery'

import { AuthStep, AuthStepFooter, Title, Text } from '../auth.styles'

const resetPasswordApi = ({
  email,
  token,
  password,
}: {
  email: string
  token: string
  password: string
}) => {
  const url = generateUrl('api_password_reset_reset')
  return request.post(url, { email, token, password })
}

type NewPasswordProps = {
  email: string
  token: string
}

export const NewPassword = ({ email, token }: NewPasswordProps): React.ReactElement => {
  const [password, setPassword] = React.useState('')
  const [confirm, setConfirm] = React.useState('')
  const [error, setError] = React.useState('')
  const [invalid, setInvalid] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)

  const _onSubmit = React.useCallback(() => {
    if (password.trim().length < 8) {
      setError('Your password must be at least 8 characters long.')
      setInvalid(true)
    } else if (password !== confirm) {
      setError("The 2 passwords don't match.")
      setInvalid(true)
    } else {
      setInvalid(false)
      setError('')
      setLoading(true)
      resetPasswordApi({
        email,
        token,
        password,
      }).then(
        () => {
          setLoading(false)
          window.location.href = '/login'
        },
        ({ body }) => {
          setLoading(false)
          setError(body.errors[0].message)
          setInvalid(true)
        }
      )
    }
  }, [confirm, email, password, token])
  return (
    <PasswordRecoveryContainer>
      <AuthStep>
        <Title>New password</Title>
        <Text>
          Everything is working good now, let's choose a new password for your account ({email}).
        </Text>
        <Form onSubmit={_onSubmit}>
          <ConfirmPassword
            invalid={invalid}
            feedback={error}
            values={[password, confirm]}
            setValues={([p, c]: [any, any]) => {
              setPassword(p)
              setConfirm(c)
            }}
          />
          {/* This field is password managers, that way they can save the username in addition to the password */}
          <input style={{ display: 'none' }} type="email" name="email" defaultValue={email} />
          <AuthStepFooter>
            <Button kind="primary" intent="action" isLoading={loading} type="submit">
              Submit
            </Button>
          </AuthStepFooter>
        </Form>
      </AuthStep>
    </PasswordRecoveryContainer>
  )
}
