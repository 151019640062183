type MockupType = {
  name: string
  regExp: RegExp
  exceptions: Array<{
    nameIncludes: Array<string>
    mockup: string
  }>
  rule: Array<{
    mockup: string
    from: number
  }>
  default: string
}
export const MOCKUP: Array<MockupType> = [
  {
    name: 'WEB',
    regExp: /(WEB)/g,
    exceptions: [],
    rule: [{ mockup: '/medias/img/mockup/web/default.svg', from: 0 }],
    default: '/medias/img/mockup/web/default.svg',
  },
  {
    name: 'IPHONE',
    regExp: /(IPHONE ([0-9A-W]+|(X[A-W]{0,1})))/g,
    exceptions: [
      { nameIncludes: [' X'], mockup: '/medias/img/mockup/mobile/apple/notch.svg' },
      { nameIncludes: ['14', 'Pro'], mockup: '/medias/img/mockup/mobile/apple/modern.svg' },
      { nameIncludes: ['SE'], mockup: '/medias/img/mockup/mobile/apple/legacy.svg' },
    ],
    rule: [
      { mockup: '/medias/img/mockup/mobile/apple/modern.svg', from: 15 },
      { mockup: '/medias/img/mockup/mobile/apple/notch.svg', from: 11 },
      { mockup: '/medias/img/mockup/mobile/apple/legacy.svg', from: 0 },
    ],
    default: '/medias/img/mockup/mobile/default.svg',
  },
  {
    name: 'GALAXY S',
    regExp: /(GALAXY S[0-9]+)/g,
    exceptions: [],
    rule: [
      { mockup: '/medias/img/mockup/mobile/android/modern.svg', from: 10 },
      { mockup: '/medias/img/mockup/mobile/android/old-galaxy.svg', from: 8 },
      { mockup: '/medias/img/mockup/mobile/android/samsung-legacy.svg', from: 3 },
    ],
    default: '/medias/img/mockup/mobile/default.svg',
  },
  {
    name: 'GALAXY A',
    regExp: /(GALAXY A[0-9]+)/g,
    exceptions: [],
    rule: [
      { mockup: '/medias/img/mockup/mobile/android/modern-notch.svg', from: 10 },
      { mockup: '/medias/img/mockup/mobile/android/samsung-legacy.svg', from: 0 },
    ],
    default: '/medias/img/mockup/mobile/default.svg',
  },
  {
    name: 'GALAXY F',
    regExp: /(GALAXY F[0-9]+)/g,
    exceptions: [],
    rule: [{ mockup: '/medias/img/mockup/mobile/android/modern-notch.svg', from: 0 }],
    default: '/medias/img/mockup/mobile/default.svg',
  },
  {
    name: 'GALAXY M',
    regExp: /(GALAXY M[0-9]+)/g,
    exceptions: [],
    rule: [{ mockup: '/medias/img/mockup/mobile/android/modern-notch.svg', from: 0 }],
    default: '/medias/img/mockup/mobile/default.svg',
  },
  {
    name: 'REDMI',
    regExp: /(REDMI [0-9]+)/g,
    exceptions: [],
    rule: [
      { mockup: '/medias/img/mockup/mobile/android/modern.svg', from: 9 },
      { mockup: '/medias/img/mockup/mobile/android/modern-notch.svg', from: 0 },
    ],
    default: '/medias/img/mockup/mobile/default.svg',
  },
  {
    name: 'HUAWEI',
    regExp: /(HUAWEI P[0-9]+)/g,
    exceptions: [
      { nameIncludes: ['P30'], mockup: '/medias/img/mockup/mobile/android/modern-notch.svg' },
      { nameIncludes: ['P20'], mockup: '/medias/img/mockup/mobile/android/modern-notch.svg' },
    ],
    rule: [{ mockup: '/medias/img/mockup/mobile/default.svg', from: 0 }],
    default: '/medias/img/mockup/mobile/default.svg',
  },
]
