import { type OptionsGeneric, type StrictModifiers } from '@popperjs/core'
import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { type RegionRecord, type LanguageRecord } from 'com.batch.redux/_records'
import { languages, regions } from 'com.batch.redux/lang-region'

export type CommonSelectProps<T> = {
  autoFocus?: boolean
  forceOpen?: boolean
  id?: string
  isClearable?: boolean
  isDisabled?: boolean
  onFocus?: () => void
  onBlur?: () => void
  menuOffset?: number
  optionFormatter?: (
    arg1: T,
    arg2: {
      context: 'value' | 'menu'
    }
  ) => React.ReactNode
  optionMenuHeight?: number
  optionMenuShownCount?: number
  optionMenuStyle?: {
    [key: string]: string | number
  }
  menuStyle?: {
    [key: string]: string | number
  }
  optionToString: (arg1?: T | null | undefined) => string
  placeholder?: string
  noResultsNode?: React.ReactNode | string
  noOptionText?: string
  style?: {
    [key: string]: string | number
  }
  minWidth?: number
  $noShadow?: boolean
  $noHover?: boolean
  ['data-testid']?: string
  ariaLabel?: string
}

export const selectBaseCountryProps = {
  options: regions,
  optionToString: (region?: RegionRecord | null): string => region?.label ?? '',
}
export const selectBaseLanguageProps = {
  options: languages,
  optionToString: (language?: LanguageRecord | null): string => language?.label ?? '',
}
export const selectPopperConfig: Partial<OptionsGeneric<StrictModifiers>> = {
  modifiers: [
    { name: 'applyStyles' },
    { name: 'eventListeners', enabled: false },
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top-start'],
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 6],
      },
    },
  ],
  placement: 'bottom-start',
  strategy: 'fixed',
}

/*
  really fake / partial definition, so flow screems at us if we forgot one of OUR props
*/
export type SelectMenuPropsType = {
  innerRef: React.Ref<any>
  style: {
    [key: string]: string | number
  }
  ['aria-labelledby']: string
  id: string
}

export const RemovableValueButton = React.forwardRef<
  HTMLButtonElement,
  {
    children: React.ReactNode
  }
>(
  (
    {
      children,
      ...rest
    }: {
      children: React.ReactNode
    },
    ref
  ): React.ReactElement => {
    return (
      <Button {...rest} ref={ref} addOn="prefix" kind="discreet" addOnGap={4}>
        {children}
        <Icon icon="remove" size={14} />
      </Button>
    )
  }
)
