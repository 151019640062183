import { type Dayjs, dayjs } from 'com.batch.common/dayjs.custom'
import {
  humanizeDayjs,
  kformat,
  percentage,
  pluralizeAndKformat,
  randomSize,
} from 'com.batch.common/utils'
import { type StatsRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { type BarChartData } from 'com.batch/shared/infra/types/chart-data'
import { BarChart, BarChartProvider } from 'com.batch/shared/ui/component/charts/bar-chart'
import { type BarChartProps } from 'com.batch/shared/ui/component/charts/bar-chart/bar-chart'
import { Metric } from 'com.batch/shared/ui/component/metric/metric'
import { BoxHeader } from 'components/common/box'
import { BoxContainer } from 'components/common/box/box.styles'
import { Skeleton } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { colors, schemes, texts } from 'components/styled/tokens'
import * as React from 'react'
import { type OrchestrationAnalyticsDateRangeRecord } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { getDateRangeLabel } from 'com.batch/orchestration-analytics/ui/helpers/date-label-utils'
import {
  MetricContainer,
  KeyMetricsChartsContainer,
  MetricBadge,
  KeyMetricsTimeline,
} from './key-metrics-charts.styles'

const skeletonDateRange = Array(28)
  .fill(0)
  .map((_, index) => dayjs.utc().startOf('week').subtract(index, 'week').startOf('week'))
const skeletonData = Array(28)
  .fill(0)
  .map((_, index) => {
    const delivered = randomSize(1, 6)
    const sent = randomSize(1, 6)
    const open = randomSize(1, 6)
    const click = randomSize(1, 6)
    const bounce = randomSize(1, 6)
    const unsubscribe = randomSize(1, 6)
    return {
      date: skeletonDateRange[index],
      sent: {
        value: sent,
        rate: 1,
      },
      delivered: {
        value: delivered,
        rate: delivered / sent,
      },
      open: {
        value: open,
        rate: open / delivered,
      },
      click: {
        value: click,
        rate: click / delivered,
      },
      bounce: {
        value: bounce,
        rate: bounce / delivered,
      },
      unsubscribe: {
        value: unsubscribe,
        rate: unsubscribe / delivered,
      },
    }
  })

type KeyMetricsChartsProps = {
  data: Array<BarChartData>
  periodicity: 'day' | 'week' | 'month'
  stats: StatsRecord | null
  channel: ChannelUntilCleanup
  isLoading: boolean
  dateRangeFilter: OrchestrationAnalyticsDateRangeRecord | null | undefined
}
export const KeyMetricsCharts = React.memo(
  ({
    data,
    periodicity,
    stats,
    channel,
    isLoading,
    dateRangeFilter,
  }: KeyMetricsChartsProps): React.ReactNode => {
    const [dateOnView, setDateOnView] = React.useState<Dayjs | null>()
    const selectedDate: BarChartData | null = React.useMemo(
      () =>
        dateOnView
          ? data.find(
              ({ date }) => dateOnView && dateOnView.format('YYYYMMDD') === date.format('YYYYMMDD')
            ) ?? {
              date: dateOnView,
              sent: { value: 0, rate: 0 },
              delivered: { value: 0, rate: 0 },
              open: { value: 0, rate: 0 },
              click: { value: 0, rate: 0 },
              bounce: { value: 0, rate: 0 },
              unsubscribe: { value: 0, rate: 0 },
            }
          : null,
      [data, dateOnView]
    )
    const [from, to] = React.useMemo(
      () =>
        dateRangeFilter
          ? [dateRangeFilter.get('from'), dateRangeFilter.get('to')]
          : [dayjs.utc().subtract(364, 'day'), dayjs.utc()],
      [dateRangeFilter]
    )
    const nbBars = React.useMemo(() => {
      let nbBars = 0
      switch (periodicity) {
        case 'day':
          nbBars = to.diff(from, 'day') + 1
          break
        case 'week':
          nbBars = to.endOf('isoWeek').diff(from.startOf('isoWeek'), 'week') + 1
          break
        case 'month':
          nbBars = to.endOf('month').diff(from.startOf('month'), 'month') + 1
          break
      }
      return nbBars > 7 ? nbBars : 7
    }, [from, to, periodicity])
    const barPadding = React.useMemo(() => nbBars / 300, [nbBars])
    const dateRange = React.useMemo(
      () =>
        Array.from({ length: nbBars }, (_, index) => {
          if (index === 0) return from
          switch (periodicity) {
            case 'day':
              return from.add(index, 'day')
            case 'week':
              return from.add(index, 'week').startOf('isoWeek')
            case 'month':
              return from.add(index, 'month').startOf('month')
          }
        }),
      [from, nbBars, periodicity]
    )
    const isUntilToday = React.useMemo(() => to.isSame(dayjs.utc(), periodicity), [to, periodicity])
    const overwriteDateName = React.useMemo(
      () =>
        periodicity === 'month'
          ? dateRange.map(date => ({ date: date, name: date.format('MMM') }))
          : [
              ...(isUntilToday && periodicity === 'day'
                ? [
                    { date: dateRange[nbBars - 1], name: 'Today' },
                    { date: dateRange[0], name: humanizeDayjs({ date: dateRange[0] }) },
                  ]
                : []),
            ],
      [dateRange, periodicity, isUntilToday, nbBars]
    )

    const selectedDateLabel = React.useMemo(() => {
      if (!selectedDate) return null
      const { date } = selectedDate
      if (periodicity === 'day') return `On ${date.format('MMM Do, YYYY')}`
      if (periodicity === 'week') {
        const endOfWeek = date.endOf('isoWeek').startOf('day')
        return `From ${date.format('MMM Do, YYYY')} to ${
          endOfWeek.isAfter(to)
            ? to.format('MMM Do, YYYY')
            : date.endOf('isoWeek').format('MMM Do, YYYY')
        }`
      }
      return `In ${date.format('MMMM YYYY')}`
    }, [selectedDate, periodicity, to])

    const commonBarChartProps: Omit<BarChartProps, 'groups'> = React.useMemo(
      () => ({
        data: isLoading ? skeletonData : data,
        barPadding,
        barMinHeight: 2,
        disableAfterDay: to,
        dateFormat: 'DD MMM YYYY',
        disabledBarHeight: 0,
        roundRadius: 4,
        graduationPlacement: 'over',
        showZeroScaleline: false,
        showZero: true,
      }),
      [to, barPadding, data, isLoading]
    )
    const groups = React.useMemo(
      () => [
        ...(channel === 'push'
          ? [
              {
                name: 'sent',
                color: '#5AB195',
                title: 'Sent',
                titleColor: colors.textSuccessDark,
                value: kformat((selectedDate ?? stats)?.sent?.value ?? 0),
              },
            ]
          : [
              {
                name: 'delivered',
                color: '#5AB195',
                title: 'Delivered',
                titleColor: colors.textSuccessDark,
                value: kformat((selectedDate ?? stats)?.delivered?.value ?? 0),
                subtitle: `${kformat((selectedDate ?? stats)?.sent?.value ?? 0)} sent`,
              },
            ]),
        ...(['email', 'push'].includes(channel)
          ? [
              {
                name: 'open',
                color: schemes.blue['30'],
                title: 'Opened',
                titleColor: schemes.blue['90'],
                value: (
                  <span>
                    {percentage((selectedDate ?? stats)?.open?.rate ?? 0, 2, false, false)}
                    <span style={texts.metric}>%</span>
                  </span>
                ),
                subtitle: pluralizeAndKformat(
                  'unique',
                  selectedDate?.open?.value ?? stats?.open.unique ?? 0
                ),
              },
            ]
          : []),
        ...(channel === 'email'
          ? [
              {
                name: 'click',
                color: schemes.purple['30'],
                title: 'Clicked',
                titleColor: schemes.purple['90'],
                value: (
                  <span>
                    {percentage((selectedDate ?? stats)?.click?.rate ?? 0, 2, false, false)}
                    <span style={texts.metric}>%</span>
                  </span>
                ),
                subtitle: pluralizeAndKformat(
                  'unique',
                  selectedDate?.click?.value ?? stats?.click.unique ?? 0
                ),
              },
            ]
          : []),
        {
          name: 'bounce',
          color: schemes.red['30'],
          title: 'Bounced',
          titleColor: schemes.red['90'],
          value: (
            <span>
              {percentage((selectedDate ?? stats)?.bounce?.rate ?? 0, 2, false, false)}
              <span style={texts.metric}>%</span>
            </span>
          ),
          subtitle: pluralizeAndKformat('unique', (selectedDate ?? stats)?.bounce?.value ?? 0),
        },
        ...(['email', 'sms'].includes(channel)
          ? [
              {
                name: 'unsubscribe',
                color: schemes.grayscale['40'],
                title: 'Unsubscribed',
                titleColor: schemes.grayscale['90'],
                value: (
                  <span>
                    {percentage((selectedDate ?? stats)?.unsubscribe?.rate ?? 0, 2, false, false)}
                    <span style={texts.metric}>%</span>
                  </span>
                ),
                subtitle: pluralizeAndKformat(
                  'unique',
                  selectedDate?.unsubscribe?.value ?? stats?.unsubscribe.unique ?? 0
                ),
              },
            ]
          : []),
      ],
      [selectedDate, stats, channel]
    )

    return (
      <KeyMetricsChartsContainer>
        <BoxHeader>
          <p style={{ ...texts.h2 }}>History</p>
          <p style={{ color: colors.textLight }}>
            {!dateOnView ? <span>{getDateRangeLabel({ from, to })}</span> : selectedDateLabel}
            <span style={{ margin: '0 6px' }}>•</span>
            View {periodicity} by {periodicity}
          </p>
        </BoxHeader>
        <BoxContainer>
          <BarChartProvider
            dateRange={isLoading ? skeletonDateRange : dateRange}
            setDateOnView={setDateOnView}
            isLoading={isLoading}
          >
            {groups.map(({ title, titleColor, value, subtitle, ...group }, index) => {
              const isRate = group.name !== 'delivered' && group.name !== 'sent'
              return (
                <Grid
                  key={group.name}
                  template="146px 1fr"
                  style={{
                    borderBottom: index < groups.length - 1 ? `1px solid ${colors.stroke}` : 'none',
                    marginTop: 16,
                    paddingBottom: 1,
                    alignItems: 'start',
                  }}
                >
                  <MetricContainer>
                    <Skeleton h={20} w={68} display="block" style={{ marginBottom: 8 }}>
                      <MetricBadge $badgeColor={group.color} $titleColor={titleColor}>
                        {title}
                      </MetricBadge>
                    </Skeleton>
                    <Metric value={value} subtitle={subtitle} skeletonMaxWidth={146} />
                  </MetricContainer>
                  <div style={{ height: 110, display: 'flex', alignItems: 'flex-end' }}>
                    <BarChart
                      {...commonBarChartProps}
                      groups={[group]}
                      height={isRate ? 90 : 110}
                      unit={isRate ? 'rate' : 'value'}
                      scaleLineNumber={isRate ? 3 : 4}
                      scaleLineKind={isRate ? 'onTheMaxBar' : 'underTheMaxBar'}
                    />
                  </div>
                </Grid>
              )
            })}
            <KeyMetricsTimeline>
              <BarChart
                data={data}
                height={0}
                groups={[]}
                showTimeline
                timelineLabelNumber={7}
                timelineRenameDates={overwriteDateName}
                dateFormat={periodicity === 'month' ? 'DD MMM YYYY' : 'DD MMM'}
              />
            </KeyMetricsTimeline>
          </BarChartProvider>
        </BoxContainer>
      </KeyMetricsChartsContainer>
    )
  }
)
