import styled from 'styled-components'

import { colors, HEXtoRGB, shadows } from 'components/styled/tokens'

import { FeedbackContainer } from './../feedback.styles'

type OptionContainerProps = {
  disabled?: boolean
  size?: number
  activeColor?: string
}
export const OptionContainer = styled.label<OptionContainerProps>`
  display: inline-flex;
  align-items: baseline;
  width: auto !important;
  min-width: 24px;
  margin: 0;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};

  /* Checkbox Option - label */
  .styled-option-label {
    color: ${p => (p.disabled ? colors.textDisabled : colors.text)};
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* Checkbox Option - symbol */
  .styled-option-symbol,
  .styled-option-novalue {
    display: flex;
    flex: 0 0 ${p => p?.size ?? 20}px;
    justify-content: center;
    align-items: center;
    width: ${p => p?.size ?? 20}px;
    height: ${p => p?.size ?? 20}px;
    padding: 0 0 3px 0;
    font-size: calc(1em - 1px);
    color: ${colors.text};
    border-radius: 4px;
    border: 1px solid ${colors.stroke};
    background-color: ${colors.fill};
    box-shadow: ${shadows.raised};
    color: transparent;

    + .styled-option-label {
      margin: 0 0 0 8px;
    }
  }
  /* Checkbox Option - input */
  input {
    display: none;
  }

  /*  Checked and Hover state for - and ✓ symbols */

  input:checked + .styled-option-symbol {
    background: ${props => props?.activeColor ?? colors.fillAction};
    border: 1px solid ${props => props?.activeColor ?? colors.fillAction};
    color: ${colors.textContrast};
  }

  /* Hover state for Checkbox Option - symbol when input is checked */
  &:hover input:not([disabled]):checked + .styled-option-symbol {
    background: ${props => props?.activeColor ?? colors.fillActionHover};
    border: 1px solid ${props => props?.activeColor ?? colors.fillActionHover};
  }

  &:active input:checked + .styled-option-symbol {
    background: ${props => props?.activeColor ?? colors.fillActionHover};
    border: 1px solid ${props => props?.activeColor ?? colors.fillAction};
  }

  /*  Checked state for label text  */

  input:checked ~ .styled-option-label {
    color: ${colors.text};
  }

  /*  Disable state for ✓ symbol  */

  input:disabled + .styled-option-symbol {
    background: rgba(${HEXtoRGB(colors.fillDisabled).toString()}, 0.31);
    border: 1px solid ${colors.stroke};
    box-shadow: none;
  }

  /*  Disable and checked state for ✓ symbol  */

  input:disabled:checked + .styled-option-symbol {
    color: ${colors.textDisabled};
    border: 1px solid ${colors.stroke};
    background: rgba(${HEXtoRGB(colors.fillDisabled).toString()}, 0.31);
  }

  /*  Focus state for ✓ symbol  */

  & + ${FeedbackContainer} {
    margin: 10px 0 0 0;
  }
`
