/* eslint-disable react/jsx-no-bind */
import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { Button } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { ConfirmWarning } from 'components/common/confirm.styles'
import { Overlay } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { ConfirmPassword, Form, FormActions, InputWrapper, Password } from 'components/form'
import * as colorsLegacy from 'components/styled/colors'
import { LinkArrow } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { AccountSection, SectionForm, SectionInfo, SectionTitle, Title } from './account.styles'
import { Security2FA } from './security.2fa'

import { disable2FA, enable2FA, updateUserPassword } from 'com.batch.redux/user'
import { companyStateSelector, currentUserSelector } from 'com.batch.redux/user.selector'

const emptyFunc = () => {}
type section = '2fa' | 'password'
export const Security = (): React.ReactElement => {
  const user = useSelector(currentUserSelector)
  const dispatch = useDispatch()

  const company = useSelector(companyStateSelector)
  const [popinOpened, updatePopinOpened] = React.useState(false)
  const [lastSubmittedSection, setLastSubmittedSection] = React.useState<
    section | null | undefined
  >(null)
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())

  const [newPassword, setNewPassword] = React.useState('')
  const [currentPassword, setCurrentPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')

  const toggle2FA = () => {
    if (user.securedBy2FA) {
      confirm({
        title: 'Disable two-factor authentication?',
        message: (
          <React.Fragment>
            <p>Are you sure you want to disable two-factor authentication on your account ?</p>
            {user.mustBeSecuredBy2FA && (
              <ConfirmWarning>
                Your company requires 2FA, if you disable it you won't be able to use the dashboard
                until it's enabled again.
              </ConfirmWarning>
            )}
          </React.Fragment>
        ),
        confirm: 'Yes, disable it',
        sensitive: true,
      }).then(
        () => {
          setLastSubmittedSection('2fa')
          dispatch(disable2FA(user))
        },
        () => {}
      )
    } else {
      updatePopinOpened(true)
    }
  }

  const onSubmit = () => {
    const err: [string, string][] = []
    if (currentPassword.trim().length < 6)
      err.push(['current', 'Please enter your current password.'])
    if (newPassword.trim().length < 8)
      err.push(['password', 'Your password must be at least 8 characters long.'])
    else if (newPassword !== confirmPassword) err.push(['password', "The 2 passwords don't match."])
    const errors = Immutable.Map(err)
    setErrors(errors)
    if (errors.size === 0) {
      setLastSubmittedSection('password')
      dispatch(
        updateUserPassword({
          user,
          currentPassword,
          newPassword, // $FlowFixMe THUNKABLE
        })
      ).then(response => {
        if (response.errors) {
          setErrors(Immutable.Map([['current', response.errors[0].message]]))
        } else {
          setConfirmPassword('')
          setNewPassword('')
          setConfirmPassword('')
        }
      })
    }
  }

  if (company.saml?.isEnabled) {
    return (
      <React.Fragment>
        <Title>Security</Title>
        <AccountSection>
          <Overlay
            description=""
            status="restricted"
            title="Your security settings are managed by your organization"
          />
        </AccountSection>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Title>Security</Title>
      {popinOpened && (
        <Security2FA
          close={() => updatePopinOpened(false)}
          user={user}
          enable2FA={(...args) => {
            setLastSubmittedSection('2fa')
            return dispatch(enable2FA(...args))
          }}
        />
      )}
      <AccountSection>
        <SectionInfo>
          <SectionTitle>Password</SectionTitle>
          <p>Choose a strong & unique password to protect your account.</p>
        </SectionInfo>
        <SectionForm>
          <Form horizontal onSubmit={onSubmit}>
            <Password
              label="Current password"
              value={currentPassword}
              setValue={setCurrentPassword}
              invalid={errors.has('current')}
              feedback={errors.get('current')}
            />

            <ConfirmPassword
              label="New password"
              invalid={errors.has('password')}
              feedback={errors.get('password')}
              values={[newPassword, confirmPassword]}
              setValues={([p1, p2]: [any, any]) => {
                setNewPassword(p1)
                setConfirmPassword(p2)
              }}
            />
            <FormActions>
              <Button
                intent="action"
                kind="primary"
                disabled={!newPassword}
                isLoading={lastSubmittedSection === 'password' && user.loading}
              >
                Update
              </Button>
            </FormActions>
          </Form>
        </SectionForm>
      </AccountSection>
      <AccountSection>
        <SectionInfo>
          <SectionTitle>Two-factor authentication (2FA)</SectionTitle>
          <p>Your account security can be improved by enabling a second layer of authentication.</p>
          <LinkArrow
            style={{ marginTop: 12 }}
            intent="action"
            target="_blank"
            href="https://help.batch.com/en/articles/2721053-using-two-factor-authentication-2fa-to-secure-your-account"
          >
            Learn more about 2FA
          </LinkArrow>
        </SectionInfo>
        <SectionForm>
          <Form horizontal onSubmit={emptyFunc}>
            <InputWrapper label="Status" style={{ marginTop: 6 }}>
              <p>
                {user.securedBy2FA && (
                  <Icon
                    icon="check"
                    thickness={2}
                    style={{ marginRight: 7, color: colorsLegacy.good.shade.s4 }}
                  />
                )}
                Two-factor authentication is {user.securedBy2FA ? 'enabled' : 'disabled'}.
              </p>
              {!user.securedBy2FA && user.mustBeSecuredBy2FA && (
                <p style={{ color: colors.textDanger, marginTop: '12px' }}>
                  Your company requires 2FA, you won't be able to use the rest of the dashboard
                  until it's enabled again.
                </p>
              )}
            </InputWrapper>
            <FormActions>
              <Button
                intent={user.securedBy2FA ? 'danger' : 'action'}
                onClick={toggle2FA}
                kind="primary"
                isLoading={lastSubmittedSection === '2fa' && user.loading}
              >
                {user.securedBy2FA ? 'Disable 2FA' : 'Enable 2FA'}
              </Button>
            </FormActions>
          </Form>
        </SectionForm>
      </AccountSection>
    </React.Fragment>
  )
}
