import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { config } from 'com.batch.common/config'

let loadingMap = false
let googleMapCallbacks: any = []
let geocoder: any = null
const reverseCache = {}

type Position = {
  lat: number
  lng: number
}

type OptionalProps = {
  initialZoom?: number
  center?: Position
  radius?: number | string
}

type Props = {
  updatePosition: (arg?: any) => void
  updateRadius: (radius: number) => void
  position?: Position | null
} & OptionalProps

const canDrawPosition = (position?: Position | null): boolean => {
  return Boolean(position && position.lat !== undefined && position.lng !== undefined)
}

export const MapPicker = ({
  position,
  updatePosition,
  updateRadius,
  initialZoom = 9,
  radius = 500,
  center = { lat: 48.85341, lng: 2.3488 },
}: Props): React.ReactElement => {
  const mapId = React.useRef<string>(`map${Math.floor(Math.random() * 100000000) + Date.now()}`)
  const map = React.useRef<any>(null)
  const marker = React.useRef<any>(null)
  const circle = React.useRef<any>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [query, setQuery] = React.useState<string>('')
  const [text, setText] = React.useState<string>('')
  const onRadiusChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateRadius(parseInt(event.target.value))
    },
    [updateRadius]
  )
  const onQueryChanged = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value)
    },
    [setQuery]
  )
  const onPositionChanged = React.useCallback(
    (event: any) => {
      updatePosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      })
    },
    [updatePosition]
  )
  const onSearch = React.useCallback(() => {
    if (!query) {
      return
    }
    setLoading(true)
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ address: query }, (results, status) => {
      setLoading(false)
      if (status === window.google.maps.GeocoderStatus.OK) {
        const location = results[0].geometry.location
        updatePosition({ lat: location.lat(), lng: location.lng() })
        setQuery('')
      }
    })
  }, [query, updatePosition])
  const onReverse = React.useCallback(() => {
    const pos = position
    if (pos) {
      const cacheKey = `${pos.lat.toFixed(6)}-${pos.lat.toFixed(6)}`
      if (typeof reverseCache[cacheKey] !== 'undefined') {
        setText(reverseCache[cacheKey])
      }
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === 'OK') {
          if (results[1]) {
            reverseCache[cacheKey] = results[1].formatted_address
            setText(reverseCache[cacheKey])
          }
        }
      })
    }
  }, [position])
  const drawPosition = React.useCallback(
    ({ position, radius }: { position: Position | null | undefined; radius?: number | string }) => {
      if (canDrawPosition(position)) {
        const pos = position
        if (pos) {
          setText(`${pos.lat.toFixed(4)},${pos.lng.toFixed(4)}`)
        }
        if (marker.current === null) {
          marker.current = new window.google.maps.Marker({
            position: position,
            map: map.current,
          })
          onReverse()
          circle.current = new window.google.maps.Circle({
            strokeColor: '#00A1E0',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#00A1E0',
            fillOpacity: 0.25,
            map: map.current,
            center: position,
            radius: radius,
          })
          circle.current.addListener('click', onPositionChanged)
        } else {
          marker.current.setPosition(position)
          onReverse()
          map.current.panTo(position)
          circle.current.setCenter(position)
          circle.current.setRadius(radius)
        }
        map.current.fitBounds(circle.current.getBounds())
      }
    },
    [onPositionChanged, onReverse]
  )
  const drawMap = React.useCallback(() => {
    const mapElement = document.getElementById(mapId.current)
    if (!mapElement) {
      window.setTimeout(() => {
        drawMap()
      }, 60)
    } else {
      map.current = new window.google.maps.Map(document.getElementById(mapId.current), {
        zoom: initialZoom,
        center: center,
        clickableIcons: false,
        mapTypeControl: false,
        streetViewControl: false,
      })
      drawPosition({ position, radius })
      map.current.addListener('click', onPositionChanged)
    }
  }, [center, drawPosition, initialZoom, onPositionChanged, position, radius])
  // mount map
  React.useEffect(() => {
    if (!window._mapBatchInitialised) {
      if (!loadingMap) {
        loadingMap = true
        window._mapBatchOnInit = () => {
          window._mapBatchInitialised = true
          geocoder = new window.google.maps.Geocoder()
          for (let indice = 0; indice < googleMapCallbacks.length; indice = indice + 1) {
            googleMapCallbacks[indice]()
          }
          googleMapCallbacks = []
        }
        const tag = document.createElement('script')
        tag.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&callback=_mapBatchOnInit`
        document.body?.appendChild(tag)
      }
      googleMapCallbacks.push(() => drawMap())
    } else {
      drawMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useLayoutEffect(() => {
    if (map.current) {
      if (marker.current === null) {
        map.current.setCenter(center)
        drawPosition({ position, radius })
      } else {
        marker.current.setPosition(position)
        onReverse()
        map.current.panTo(position)
        circle.current.setCenter(position)
        if (radius) {
          circle.current.setRadius(radius)
        }

        map.current.fitBounds(circle.current.getBounds())
      }
    }
  }, [center, drawPosition, onReverse, position, radius])
  const onSubmit = React.useCallback(
    (event: React.ChangeEvent<HTMLFormElement>) => {
      event.preventDefault()
      onSearch()
    },
    [onSearch]
  )
  return (
    <div className="mapPicker">
      <form className="mapPicker__header" onSubmit={onSubmit}>
        <div className="input-group">
          <input
            type="search"
            onChange={onQueryChanged}
            placeholder="Search an address, a place..."
            className="form-control"
            disabled={loading}
          />
          <span className="input-group-btn">
            <Button type="submit" kind="primary" disabled={loading}>
              <Icon icon={loading ? 'running-animated' : 'search'} size={10} />
            </Button>
          </span>
        </div>
      </form>
      <div className="mapPicker__map">
        <div className="mapContent" id={mapId.current} />
      </div>
      <div className="mapPicker__controls">
        <div className="mpc__item mpc__item--label">
          <i className="fa fa-country fa-1-5x" />
          {!text && (
            <span className="review__undef review__undef--inline">Pick a point on the map</span>
          )}
          {text && <span>{text}&nbsp;+</span>}
        </div>
        <div className="mpc__item mpc__item--radius">
          <input
            type="number"
            min="50"
            max="1000000"
            value={radius}
            required
            onChange={onRadiusChanged}
            className="form-control form-control--sm v"
            placeholder="distance"
          />
        </div>
        <div className="mpc__item mpc__item--around">meters around, in the last 12 hours</div>
      </div>
    </div>
  )
}
