export type automationStateType = campaignStateType

export const STATE_AUTOMATION: { [key in automationStateType]: automationStateType } =
  Object.freeze({
    NEW: 'NEW',
    DRAFT: 'DRAFT',
    RUNNING: 'RUNNING',
    STOPPED: 'STOPPED',
    COMPLETED: 'COMPLETED',
  })
