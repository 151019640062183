import { type List } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { SelectNoSearch } from './select-no-search'
import { SelectSearch } from './select-search'
import { type CommonSelectProps } from './select.helper'

export type BaseSelectProps<T> = CommonSelectProps<T> & {
  value: T | null | undefined
  options?: List<T>
  loadOptions?: (arg1: string) => Promise<List<T>>
  optionToString: (arg1?: T | null | undefined) => string
  isOptionDisabled?: (arg1?: T | null | undefined) => boolean
  onChange: (arg1?: T | null | undefined) => void
  invalid?: boolean
  isLoading?: boolean
  optionCreator?: (arg1: string) => T
  localSearchEveryWhere?: boolean
  tooltip?: React.ReactNode
  header?: React.ReactNode
  noResultText?: string
}
export type SelectProps<T> = BaseSelectProps<T> & {
  isSearchable?: boolean
}

export function Select<T>({
  isSearchable = false,
  isDisabled = false,
  ...rest
}: SelectProps<T>): React.ReactElement {
  const theme = useTheme()
  const computedDisabled = React.useMemo(() => {
    return Boolean(isDisabled || theme?.disabledMode)
  }, [isDisabled, theme?.disabledMode])

  return isSearchable || rest.optionCreator ? (
    <SelectSearch {...rest} isDisabled={computedDisabled} />
  ) : (
    <SelectNoSearch {...rest} isDisabled={computedDisabled} />
  )
}
