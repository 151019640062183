import styled, { css } from 'styled-components'

import { colors } from 'components/styled/tokens'
import { grayscale } from 'components/styled/tokens/schemes'

import { BaseThumb, InactiveEditorContainer } from 'com.batch/message-builder/ui/components/common'

export const Notif = styled.div`
  ${p =>
    p.theme.isFocused
      ? css`
          background: ${grayscale['10']};
          transition: background-color 0.2s ease;
        `
      : css`
          background: ${grayscale['05']};
        `}

  border-radius: 14px;
  width: 386px;
  margin-top: ${p => (p.theme.abTestingEnabled ? '197' : '160')}px;
  margin-bottom: ${p => (p.theme.abTestingEnabled ? '83' : p.theme.isExpanded ? '90' : '0')}px;
  display: grid;

  ${p =>
    p.theme.isExpanded
      ? p.theme.hasMedia
        ? css`
            padding: 0 0 10px 0;
            grid-template-columns: 4px 36px 1fr 34px 4px; // 4 = 10(padding) - 6(col-gap)
            grid-template-rows: auto;
            grid-column-gap: 6px;
            grid-template-areas:
              'header header header header header'
              'thumb thumb thumb thumb thumb'
              '. browser title title .'
              '. browser sender sender .'
              '. browser message message .';
          `
        : css`
            padding: 10px;
            grid-template-columns: 36px 1fr 48px;
            grid-template-rows: auto;
            grid-column-gap: 6px;
            grid-template-areas:
              'browser title header'
              'browser sender sender'
              'browser message message';
          `
      : css`
          padding: 10px;
          grid-template-columns: 36px 1fr 34px;
          grid-template-rows: auto;
          grid-column-gap: 6px;
          ${p.theme.hasIcon || p.theme.hasMedia || p.theme.draggingState !== 'NONE'
            ? css`
                grid-template-areas:
                  'browser title date'
                  'browser sender thumb'
                  'browser message thumb';
              `
            : css`
                grid-template-areas:
                  'browser title date'
                  'browser sender sender'
                  'browser message message';
              `}
        `};
`
export const ReceivedDate = styled.div`
  color: ${colors.textLight};
  font-size: 12px;
  text-align: right;
  grid-area: date;
  align-self: center;
  ${p => p.theme.isExpanded && 'display: none'};
`
export const NotifSettings = styled.div`
  text-align: right;
  color: ${colors.textDisabled};
  ${p => p.theme.hasMedia && 'height: 30px'};
  padding-top: 5px;
  ${p => p.theme.isExpanded && p.theme.hasMedia && 'padding-right: 10px'};
  grid-area: header;
  ${p => !p.theme.isExpanded && 'display: none'};
`
export const Sender = styled.div`
  color: ${colors.text};
  grid-area: sender;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
type ThumbProps = {
  $url: string
}
export const Thumb = styled(BaseThumb)<ThumbProps>`
  display: ${p => (!!p.$url || p.theme.draggingState !== 'NONE' ? 'block' : 'none')};
  ${p =>
    p.theme.isExpanded
      ? css`
          background: #e6e6e6;
          position: relative;
          height: 160px;
          width: 100%;
          margin-bottom: 10px;
          &:before {
            content: '';
            display: block;
            ${(p: { $url: string }) =>
              p.$url &&
              css`
                background-image: url(${p.$url});
              `}
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        `
      : css`
          border-radius: 8px;
          overflow: hidden;
          &:before {
            content: '';
            display: block;
            ${(p: { $url: string }) =>
              p.$url &&
              css`
                background-image: url(${p.$url});
              `}
            background-size: cover;
            width: 34px;
            height: 34px;
          }
          position: relative;
          width: 34px;
          height: 34px;
        `}

  grid-area: thumb;
`

export const BrowserIcon = styled.div`
  width: 36px;
  height: 36px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 2px;
  grid-area: browser;
  place-self: center;
  align-self: center;
  border-radius: 8px;
  object-fit: cover;

  & img {
    width: 100%;
    height: 100%;
  }
`
export const Title = styled.div`
  grid-area: title;
  color: ${colors.text};
  font-weight: 500;
  ${p =>
    p.theme.isFocused &&
    css`
      margin: -5px 0 5px 0;
    `}
  transition: margin 0.2s ease;

  ${InactiveEditorContainer} {
    -webkit-line-clamp: 1;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
export const Message = styled.div`
  grid-area: message;
  color: ${colors.text};
  ${p =>
    p.theme.isFocused &&
    css`
      margin: 5px 0 -5px 0;
    `}
  transition: margin 0.2s ease;

  ${InactiveEditorContainer} {
    -webkit-line-clamp: ${p => (p.theme.isExpanded ? '10' : '4')};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
