/* eslint-disable react/jsx-no-bind */
import * as React from 'react'
import { useSelector, useDispatch } from 'components/console/react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { Button, useBlurOnClickCallback } from 'components/common/button'
import { Code } from 'components/common/code'
import { confirm } from 'components/common/confirm'
import { Grid } from 'components/common/grid'
import Loader from 'components/common/loader-legacy'
import { Pager } from 'components/common/pager'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableBody,
  TableCell,
  TableFooter,
  TableCellActions,
  TableRow,
} from 'components/common/table/index'
import { Tooltip } from 'components/common/tooltip'
import { FilterSearch } from 'components/filter'
import { Ellipsis } from 'components/styled/text'

import { loadDemoCodes, demoCodesSelector, removeDemoCode } from './../redux/codes'
import { DemoCodeApp } from './demo-code-app'
import { DemoCodesForm } from './demo-codes-form'

import { Title, Subtitle } from '../console.style'

export const DemoCodesListRaw = (): React.ReactElement => {
  // ====================== Dispatcher & History
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  // ====================== Redux state
  const codes = useSelector(demoCodesSelector)
  const loading = useSelector(state => state.code.loading)

  // ====================== Component state
  const [isOpened, setIsOpened] = React.useState<boolean>(false)

  // ====================== Component constants
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  const page = params.has('page') ? parseInt(params.get('page') ?? '1') : 1
  const search = params.has('search') ? params.get('search') : ''

  const NB_PER_PAGE = 10

  const filterCode = search
    ? codes
        .filter(
          f =>
            (!!f.ios && f.ios.apiKey.includes(search)) ||
            (!!f.android && f.android.apiKey.includes(search)) ||
            f.id.includes(search)
        )
        .toList()
    : codes

  const showCode = filterCode.slice(NB_PER_PAGE * (page - 1), NB_PER_PAGE * page)

  const codeListSize = filterCode.size

  // ====================== Use Effect
  React.useEffect(() => {
    dispatch(loadDemoCodes())
  }, [dispatch])

  // ====================== Callback
  const handleDelete = (code: string) => {
    confirm({
      title: 'Remove a demo code',
      sensitive: true,
      message: (
        <React.Fragment>
          Are you sure you want to remove the demo code "<strong>{code}</strong>" ?
          <br />
          <strong>Please be aware that code will be lost.</strong>
        </React.Fragment>
      ),
    }).then(
      () => dispatch(removeDemoCode(code)),
      () => console.log('you are disagree')
    )
  }

  const openOnBlur = useBlurOnClickCallback(() => {
    setIsOpened(true)
  }, [])

  // ====================== Render
  return (
    <React.Fragment>
      <Grid template="1fr 220px 190px">
        <Grid template="auto 1fr" gap={30} alignItems="end">
          <Title>All demo codes</Title>
          <Subtitle>
            {codeListSize} demo code{codeListSize > 1 && 's'}
          </Subtitle>
        </Grid>

        <FilterSearch
          value={search ?? ''}
          disabled={loading}
          identifier="code or any api key"
          onChange={value => navigate({ search: `?page=1&search=${value}` })}
          style={{ marginTop: 0 }}
          expandable={false}
        />

        <Button
          kind="primary"
          type="button"
          intent="action"
          disabled={loading}
          onClick={openOnBlur}
        >
          Generate a new code
        </Button>
      </Grid>

      <br />

      <Loader loading={loading} overlay>
        <Table style={{ tableLayout: 'fixed', width: '100%' }} template="repeat(4, 1fr) 99px">
          <TableHeader>
            <TableCellHeader>Code</TableCellHeader>
            <TableCellHeader>Description</TableCellHeader>
            <TableCellHeader>IOS app</TableCellHeader>
            <TableCellHeader>Android app</TableCellHeader>
            <div />
          </TableHeader>

          <TableBody>
            {showCode.map(code => {
              return (
                <TableRow key={code.id}>
                  <TableCell>
                    <Code>{code.id}</Code>
                  </TableCell>
                  <TableCell noOverflow>
                    <Tooltip tooltip={code.description}>
                      <Ellipsis>{code.description}</Ellipsis>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <DemoCodeApp kind="ios" code={code} />
                  </TableCell>
                  <TableCell>
                    <DemoCodeApp kind="android" code={code} />
                  </TableCell>
                  <TableCellActions>
                    <Button
                      type="button"
                      kind="discreet"
                      intent="danger"
                      onClick={() => handleDelete(code.id)}
                    >
                      <Icon icon="delete" />
                    </Button>
                  </TableCellActions>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            {codeListSize !== 0 && (
              <Pager
                page={page}
                total={codeListSize}
                nbPerPage={NB_PER_PAGE}
                selectPage={p => navigate({ search: `?page=${p}&search=${search ?? ''}` })}
              />
            )}
          </TableFooter>
        </Table>

        <div style={{ textAlign: 'center', color: '#8f959e', marginTop: 10 }}>
          <small>
            listing {codeListSize} demo code{codeListSize > 0 && 's'}
          </small>
        </div>
      </Loader>

      <DemoCodesForm isOpened={isOpened} close={() => setIsOpened(false)} />
    </React.Fragment>
  )
}

export const DemoCodesList: React.ComponentType<Record<any, any>> =
  React.memo<Record<any, any>>(DemoCodesListRaw)
