import { orchestrationIDSelector } from 'com.batch/orchestration/store/orchestration.selectors'
import { getSmsContentForActiveLanguageSelector } from 'com.batch/sms/store/sms.selector'
import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import { sendTestSmsRequest } from 'com.batch/sms/infra/send-test-sms.api'

export type SendTestSmsSuccessAction = {
  type: 'SEND_TEST_SMS_SUCCESS'
  payload: null
}

export type SendTestSmsFailureAction = {
  type: 'SEND_TEST_SMS_FAILURE'
  payload: {
    error: {
      errors: Array<{
        message: string
      }>
    }
  }
}
export const sendTestSms = ({
  messageId,
  recipient,
}: {
  messageId: string
  recipient: string
}): DispatchExtraBoundFn<Promise<any>> => {
  return async (dispatch, getState) => {
    const state = getState()
    const getContent = getSmsContentForActiveLanguageSelector(state)
    const content = getContent(messageId)
    const project = currentProjectSelector(state)
    const transmissionType = subscriptionStatusSelector(state)
    const orchestrationID = orchestrationIDSelector(state)

    return await promiseActionCreator({
      dispatch,
      promise: sendTestSmsRequest({
        projectKey: project.projectKey,
        content,
        recipient,
        transmissionType,
        orchestrationID,
      }),
      actionName: 'SEND_TEST_SMS',
    })
  }
}
