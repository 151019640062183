import styled, { css } from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

export const TokenCellsSize = [50, 60, 40, 60, 30, 50, 60, 70, 40, 50] as const
type UploadAudiencesContainerProps = {
  side: 'right' | 'left'
  hasFile?: boolean
}
export const UploadAudiencesContainer = styled.div<UploadAudiencesContainerProps>`
  height: 440px;
  ${props =>
    props.side === 'right'
      ? css`
          display: flex;
          justify-content: center;
          padding: ${(p: UploadAudiencesContainerProps) =>
            p.hasFile ? '53px 22px 40px 22px' : '22px'};
          background-color: ${schemes.grayscale['01']};
          border-left: 1px solid ${colors.stroke};
        `
      : css`
          padding: 29px 22px;
        `}
`

export const PreviewAudiences = styled.div`
  > div {
    width: 100%;
    height: 36px;
    border-top: 1px solid ${schemes.darklucent['05']};

    > div:first-of-type {
      text-align: center;
      color: ${colors.textLight};
      font-weight: 500;
    }
  }
`
type TokensIdsTooltipProps = {
  isTokens?: boolean
}
export const TokensIdsTooltip = styled.div<TokensIdsTooltipProps>`
  padding: 6px 7px;
  text-align: left;
  min-width: 128px;

  ${props =>
    props.isTokens &&
    css`
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    `}
  > div {
    &:first-of-type {
      font-weight: 300;
      font-size: 16px;
      color: #fff;
    }

    &:last-of-type {
      font-size: 12px;
      letter-spacing: -0.01em;
      color: rgba(255, 255, 255, 0.68);
    }
  }
`

export const EstimateContainer = styled.div`
  display: flex;
  align-items: baseline;
`
