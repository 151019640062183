import * as React from 'react'

type ClusterBtnProps = {
  code: string
  label: string
  active?: boolean
  disabled?: boolean
  ratio: number
  handler: (arg1: string, arg2: boolean) => void
}
export const ClusterBtn: React.ComponentType<ClusterBtnProps> = React.forwardRef<
  HTMLButtonElement,
  ClusterBtnProps
>(({ code, label, active, disabled, ratio, handler }: ClusterBtnProps, ref) => {
  const handleClick = React.useCallback(() => {
    handler(code, !active)
  }, [active, code, handler])
  const classes = ['clust', `clust--${code}`]
  if (active) classes.push('active')
  const st = {
    flexBasis: `calc(${Math.round(ratio)}% - 10px)`,
  }
  return (
    <button
      ref={ref}
      id={`cluster-${code}`}
      style={st}
      disabled={disabled}
      className={classes.join(' ')}
      onClick={handleClick}
      data-code={code}
    >
      <span className="clust__dot" />
      {` ${label}`}
    </button>
  )
})
