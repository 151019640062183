// Transform Hex to rgb
export const HEXtoRGB = (hex: string): number[] => {
  hex = hex.replace(/^#/, '')
  if (hex.length === 3) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  const num = parseInt(hex, 16)

  return [num >> 16, (num >> 8) & 255, num & 255]
}

// Transform rgb to hex
export const RGBtoHEX = (color: [number, number, number]): string => {
  return `#${color
    .map(c => {
      const tmp = c.toString(16)
      return tmp.length === 1 ? `0${tmp}` : tmp
    })
    .join('')}`
}

// Add opacity to hex color
export const Opacity = (hex: string, opacity: number = 1): string =>
  `rgba(${HEXtoRGB(hex).toString()}, ${opacity})`
