import Immutable, { type List } from 'immutable'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type FetchUnifiedCustomerDataValuesFailureAction = {
  type: 'FETCH_UNIFIED_CUSTOMER_DATA_VALUES_FAILURE'
}

export const fetchUnifiedCustomerDataValues = ({
  key,
  type,
  searchTerm,
}: {
  key: string
  type: 'ATTRIBUTES' | 'TAGS'
  searchTerm?: string
}): DispatchExtraBoundFn<Promise<List<string>>> => {
  return async (dispatch, getState, { ucpGateway }) => {
    const project = currentProjectSelector(getState())

    try {
      return await ucpGateway.fetchUnifiedCustomerDataValues({
        project,
        key,
        type,
        searchTerm,
      })
    } catch (err: any) {
      dispatch({
        type: 'FETCH_UNIFIED_CUSTOMER_DATA_VALUES_FAILURE',
        payload: err,
      })
    }

    return Immutable.List()
  }
}
