import * as React from 'react'
import { useTheme } from 'styled-components'

import { GlobalErrorIcon } from './empty-states.icons'
import { EmptyField } from './empty-states.styles'
import { type OverlayEmptyProps } from './overlay'

const reloadWindow = () => window.location.reload()
export const GlobalErrorOverlayProps: OverlayEmptyProps = {
  title: 'Couldn’t load data',
  status: 'empty-page',
  description:
    'There has been an error during the loading of this page, try to refresh or contact the support team if this continues to happen.',
  content: <GlobalErrorIcon />,
  refresh: reloadWindow,
}

type SkeletonProps = {
  w: number
  h: number
  display?: 'inline' | 'block' | 'inline-block'
  style?: any
  children?: React.ReactNode
}
export const Skeleton = ({ w, h, children, display, style }: SkeletonProps): React.ReactNode => {
  const theme = useTheme()

  if (!theme.isEmpty && !theme.isLoading) {
    return children
  }
  return <EmptyField _width={w} _height={h} _display={display} style={style} />
}
