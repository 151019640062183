import { type List, type Set } from 'immutable'
import * as React from 'react'

import { Box, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { FlexLine } from 'components/common/flexline'

import { ChannelCheckbox } from './channel-checkbox'

export const CHANNEL_WEIGHT = {
  ios: 2,
  android: 3,
  webpush: 4,
}

type ChannelSelectorProps = {
  channels: List<'ios' | 'webpush' | 'android'>
  selected: Set<Channel>
  toggleChannel: (arg1: Channel) => void
  isDisabled: boolean
}

export const ChannelSelector = ({
  channels,
  selected,
  toggleChannel,
  isDisabled,
}: ChannelSelectorProps): React.ReactElement => {
  const sortedChannels = React.useMemo(
    () => channels.sort((a, b) => CHANNEL_WEIGHT[a] - CHANNEL_WEIGHT[b]),
    [channels]
  )

  return (
    <Box>
      <BoxHeader style={{ borderBottom: 'none' }}>
        <HeaderBoxTitle title="Channels" />
      </BoxHeader>
      <FlexLine style={{ minHeight: 124, alignItems: 'stretch', padding: '12px 14px 12px 14px' }}>
        {sortedChannels.map(channel => {
          return (
            <ChannelCheckbox
              channel={channel}
              key={channel}
              onChange={toggleChannel}
              isDisabled={isDisabled}
              checked={selected.has(channel)}
            />
          )
        })}
      </FlexLine>
    </Box>
  )
}
