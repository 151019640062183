import Immutable, { type RecordOf } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

type OrchestrationListStatsStateProps = {
  loadingState: fetchingState
  delivered: number
  openRate: number
}

export const OrchestrationListStatsStateFactory =
  Immutable.Record<OrchestrationListStatsStateProps>({
    loadingState: 'INIT',
    delivered: 0,
    openRate: 0,
  } as OrchestrationListStatsStateProps)

export type OrchestrationListStatsStateRecord = RecordOf<OrchestrationListStatsStateProps>
