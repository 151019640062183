import * as React from 'react'

import { Text } from '../auth.styles'

export const Register2FAIntro = ({ enforce2FA }: { enforce2FA: boolean }): React.ReactElement => (
  <React.Fragment>
    {enforce2FA ? (
      <Text>
        You will need to use two-factor authentication to access the Batch dashboard. Add this extra
        layer of security to protect your account.
      </Text>
    ) : (
      <Text>
        You can add an extra layer of security requiring a mobile-device based verification, to
        protect your account.
      </Text>
    )}
    <img
      style={{
        width: 'auto',
        height: '240px',
        margin: '32px 0 0 -2px',
      }}
      src="/medias/img/illustrations/onboarding_2fa_illustration.svg"
    />
  </React.Fragment>
)
