import Immutable, { type List } from 'immutable'
import { get } from 'lodash-es'
import request from 'superagent-interface-promise'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type CustomAudienceRecord, CustomAudienceFactory } from 'com.batch.redux/_records'

export const fetchCustomAudiences = (
  appId: number,
  options: {
    page?: number
    sortBy?: string
    sortOrder?: 'dsc' | 'asc'
    search?: string
  }
): Promise<{
  customAudiences: List<CustomAudienceRecord>
  count: number
  page: number
}> => {
  return request
    .get(`/api/app/${appId}/custom-audiences`)
    .query({
      page: options.page,
      ['sort-by']: options.sortBy,
      ['sort-order']: options.sortOrder,
      query: options.search,
    })
    .then(response => {
      const audiences = response.body.custom_audiences.map(audience => {
        return CustomAudienceFactory({
          id: audience.name, // retirer l'id des audiences partout dans le dashboard
          name: audience.name,
          description: audience.description,
          idsCount: audience.nb_ids,
          deleted: false,
          type: audience.type,
          isIndexing: audience.indexing_state === 'IN_PROGRESS',
          updatedAt: dayjs.utc(audience.updated),
        })
      })

      return {
        customAudiences: Immutable.List(audiences),
        count: response.body.count,
        page: options.page,
      }
    })
}

export const fetchCustomAudienceEstimate = (
  appId: number,
  audience: CustomAudienceRecord
): Promise<number | 'error'> => {
  return request
    .post(`/api/app/${appId}/data/pushtokens`, {
      clusters: ['N', 'U', 'E', 'D', 'I'],
      doNotCache: audience.updatedAt.isAfter(dayjs().utc().subtract(15, 'minute')),
      regions: [],
      customAudiencesOperator: 'UNION',
      customAudiences: [[audience.name, audience.type]],
      languages: [],
      forInApp: false,
    })
    .then(resp => get(resp, 'body.results.matching', 'error'))
}

export const createCustomAudience = (
  appId: number,
  name: string,
  type: 'custom_ids' | 'advertising_ids' | 'install_ids',
  description: string,
  file: File,
  onProgress?: (p: number) => any
): Promise<CustomAudienceRecord> => {
  const data = new FormData()
  data.append('type', type)
  data.append('description', description)
  data.append('file', file)
  return request
    .post(`/api/app/${appId}/custom-audience/${name}`)
    .send(data)
    .on('progress', function (p) {
      if (onProgress) onProgress(p.percent)
    })
    .then(
      audienceRaw =>
        CustomAudienceFactory({ ...audienceRaw, type: matchAudienceType(audienceRaw.type) }),
      error => {
        throw { error: error.body?.message ?? '' }
      }
    )
}

export const saveCustomAudience = (
  appId: number,
  audience: CustomAudienceRecord
): Promise<CustomAudienceRecord> => {
  return request
    .post(`/api/app/${appId}/custom-audience/${audience.name}/update`, audience)
    .then(({ body }) => ({
      customAudience: audience.merge({
        description: body.description,
        estimate: audience.estimate,
      }),
    }))
}

export const deleteCustomAudience = (
  appId: number,
  audience: CustomAudienceRecord
): Promise<CustomAudienceRecord> => {
  return request
    .delete(`/api/app/${appId}/custom-audience/${audience.name}`)
    .then(() => ({ customAudience: audience }))
}

export const matchAudienceType = (
  text: string
): 'custom_ids' | 'advertising_ids' | 'install_ids' => {
  switch (text) {
    case 'ADVERTISING_UUID':
      return 'advertising_ids'
    case 'INSTALL_ID':
      return 'install_ids'
    default:
      return 'custom_ids'
  }
}
