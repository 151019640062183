import styled from 'styled-components'

import { Link } from 'components/styled/text'
import { colors, schemes } from 'components/styled/tokens'

const TITLE_STYLES_CONSTANTS = {
  noData: {
    margin: '0 0 16px 0',
    size: 26,
    weight: 300,
    lineHeight: 31,
    letterSpacing: -0.02,
  },
  restrictedAccess: {
    margin: '0 0 12px 0',
    size: 24,
    weight: 400,
    lineHeight: 26,
    letterSpacing: -0.01,
  },
  scoped: {
    margin: '0 0 10px 0',
    size: 14,
    weight: 500,
    lineHeight: 18,
    letterSpacing: 0,
  },
}

const DESC_STYLES_CONSTANTS = {
  noData: {
    margin: '0 0 38px 0',
    size: 15,
    lineHeight: 22,
  },
  restrictedAccess: {
    margin: '0 0 24px 0',
    size: 14,
    lineHeight: 20,
  },
  scoped: {
    margin: '0 0 30px 0',
    size: 14,
    lineHeight: 18,
  },
}

export const RestrictedAccessContainer = styled.div`
  display: flex;
  min-width: 520px;
  max-width: 710px;
  background-color: ${colors.fillBackground};
  border: 1px solid ${colors.stroke};
  border-radius: 8px;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.04),
    0px 2px 36px rgba(0, 0, 0, 0.06);

  .styled-restrict-access-links {
    min-width: 100px;
    margin-top: 24px;
    display: flex;
    align-items: center;

    button {
      margin-bottom: 0;
      margin-right: 30px;
    }
  }

  @media (max-width: 700px) {
    min-width: auto;
    ${'' /* max-width: 710px; */}
    max-width: calc(100vw - 64px);
    flex-direction: column;

    .styled-restrict-access-links {
      flex-direction: column;
      align-items: flex-start;

      button {
        margin-bottom: 18px;
        margin-right: 0;
      }
    }
  }
`

export const RestrictedAccessIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 190px;
  min-height: initial;
  border-right: 1px solid ${schemes.darklucent['05']};
  border-bottom: 0;

  @media (max-width: 700px) {
    min-width: calc(100% - 20px);
    min-height: 160px;
    border-bottom: 1px solid ${schemes.darklucent['05']};
    border-right: 0;
  }
`

export const RestrictedAccessContent = styled.div`
  padding: 32px 32px 28px 32px;

  @media (max-width: 700px) {
    min-width: 100%;
  }
`

const coerceKind = (kind?: string | null) =>
  kind === 'noData' || kind === 'restrictedAccess' ? kind : 'scoped'

export const EmptyStateSubtitle = styled.div`
  font-size: 28px;
  font-weight: 300;
  color: #4f4f4f; // textNeutral
`
type EmptyStateDescriptionProps = {
  kind?: 'noData' | 'restrictedAccess' | 'scoped'
  intent?: 'plan'
}
export const EmptyStateDescription = styled.div<EmptyStateDescriptionProps>`
  margin: ${props => DESC_STYLES_CONSTANTS[coerceKind(props.kind)].margin};
  font-size: ${props => DESC_STYLES_CONSTANTS[coerceKind(props.kind)].size}px;
  font-weight: 400;
  line-height: ${props => DESC_STYLES_CONSTANTS[coerceKind(props.kind)].lineHeight}px;
  color: ${colors.textLight};
  width: ${props => (props.intent === 'plan' ? '400px' : 'auto')};
  &:last-child {
    margin-bottom: 0;
  }
  &:empty {
    display: none;
  }
`
type EmptyStateTitleProps = {
  kind?: 'noData' | 'restrictedAccess' | 'scoped'
}
export const EmptyStateTitle = styled.h3<EmptyStateTitleProps>`
  padding: 0;
  margin: ${props => TITLE_STYLES_CONSTANTS[props.kind ?? 'noData'].margin};
  font-size: ${props => TITLE_STYLES_CONSTANTS[props.kind ?? 'noData'].size}px;
  font-weight: ${props => TITLE_STYLES_CONSTANTS[props.kind ?? 'noData'].weight};
  line-height: ${props => TITLE_STYLES_CONSTANTS[props.kind ?? 'noData'].lineHeight}px;
  letter-spacing: ${props => TITLE_STYLES_CONSTANTS[props.kind ?? 'noData'].letterSpacing}em;
  text-transform: initial;
  color: ${colors.text};
`

export const LinkDocContainer = styled(Link)`
  margin-left: -7px;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: red;
  ${'' /* color: ${schemes.grayscale['60']}; */}

  span {
    margin-right: 8px;
    &,
    &:visited {
      color: ${schemes.grayscale['60']};
    }
    &:hover {
      color: ${schemes.grayscale['70']};
    }
    &:active {
      color: ${schemes.grayscale['80']};
    }
  }
`
type ScopedIconContainerProps = {
  intent: 'upgrade' | 'permission' | 'failed'
}
export const ScopedIconContainer = styled.div<ScopedIconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 24px;
  margin-bottom: ${props => (props.intent === 'upgrade' ? 18 : 20)}px;
  padding-top: ${props =>
    props.intent === 'permission' ? 2 : props.intent === 'failed' ? 0 : 3}px;
  border-radius: 5px;
  color: white;
  background-color: ${props =>
    props.intent === 'upgrade'
      ? colors.fillTech
      : props.intent === 'permission'
        ? schemes.grayscale['60']
        : colors.fillDanger};
`
export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  height: fit-content;
  text-align: center;

  a {
    margin-right: 30px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`
