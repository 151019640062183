import { animated } from '@react-spring/web'
import type * as React from 'react'
import styled, { css } from 'styled-components'

import { Button } from 'components/common/button/button.styles'
import { OverlayContainer } from 'components/common/empty-states/wrapper.styles'
import { TableContainer } from 'components/common/table/table.styles'
import { LabelPlaceholder } from 'components/styled/placeholder'
import { Link } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { CopyableText } from 'com.batch/shared/ui/component/copy/copyable.styles'

const BORDER_RADIUS = '8px'
const SPACING = 8

type BoxHeaderProps = {
  large?: boolean
}
export const BoxHeader = styled.div<BoxHeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: ${props => (props.large ? '80px' : '56px')};
  flex-shrink: 0;
  padding: 0 20px;
  background-color: ${p => (p.theme.isDark ? colors.fillContrast : colors.fill)};
  border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
  border-bottom: 1px solid ${p => (p.theme.isDark ? colors.strokeContrast : colors.stroke)};
`
type HeaderBoxActionsProps = {
  large?: boolean
}
export const HeaderBoxActions = styled.div<HeaderBoxActionsProps>`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  margin-right: -10px;

  & > ${Link} {
    margin-right: 14px;
  }

  ${props =>
    props.large &&
    css`
      align-self: flex-start;
      margin-top: 8px;
    `}

  & > :not(${Link}):last-child {
    margin-right: 0;
  }
`
type BoxTitleProps = {
  withDetail?: React.ReactNode | string
  withSuffix?: React.ReactNode
  withPrefix?: React.ReactNode
  style?: any
}

export const BoxTitle = styled(LabelPlaceholder.withComponent('h3'))<BoxTitleProps>`
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  text-align: left;
  color: ${p => (p.theme.isDark ? colors.textContrast : colors.text)};
  display: flex;

  ${props =>
    Boolean(props.withDetail) &&
    css`
      flex-direction: column;

      span {
        color: #707782;
      }

      > div {
        align-self: flex-start;
      }
    `}

  ${props =>
    Boolean(props.withSuffix) &&
    css`
      align-items: center;

      i,
      button {
        margin-right: 8px;
        margin-left: 0;
      }
      & > span,
      & > div,
      & > a {
        margin-left: 8px;
      }
      em {
        margin-left: 4px;
        font-style: normal;
        color: #818792;
        font-size: 14px;
      }
    `}

  ${props =>
    Boolean(props.withPrefix) &&
    css`
      & > span,
      & > div,
      & > img,
      & > a {
        margin-right: 12px;
      }

      a {
        font-size: 13px;
      }
    `}
`

export const BoxSearchContainer = styled.div`
  display: flex;
  height: 48px;
  position: sticky;
  width: 100%;
  top: 0;
  align-items: center;
  z-index: 1;
  color: ${colors.text};
  border-bottom: 1px solid ${colors.stroke};
  background: transparent;

  i {
    position: absolute;
    top: 16px;
    left: 21px;
  }
  input {
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 44px;
    font-weight: 500;
    color: currentColor;
    border: none;
    outline: none;
    appearance: none;
    background: white;
  }

  &:focus-within {
    color: ${colors.text};
  }
  button {
    position: absolute;
    z-index: 1;
    right: 12px;
  }
`
type BoxSectionContainerProps = {
  $padding?: boolean
}

export const BoxSectionContainer = styled.div<BoxSectionContainerProps>`
  position: relative;
  padding: ${props => (props.$padding ? '20px' : false)};
  background: ${p => (p.theme.isDark ? colors.fillContrast : colors.fillDepth)};
  border: 1px solid ${props => (props.theme.isDark ? colors.strokeContrast : colors.stroke)};
  ${TableContainer} {
    border: none;
    margin: 0;
    box-shadow: none;
  }
`
type BoxBodyProps = {
  $padding?: boolean
}

export const BoxBody = styled(animated.div)<BoxBodyProps>`
  position: relative;
  background: ${p => (p.theme.isDark ? colors.fillContrast : colors.fillDepth)};
  border-radius: ${BORDER_RADIUS};
  padding: ${props => (props.$padding ? '20px' : 0)};

  scrollbar-color: rgb(50, 54, 57, 0.18) transparent;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent !important;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    width: 6px;
    background-color: transparent !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(50, 54, 57, 0.18);
  }
`

export const FooterBoxActions = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 8px;
`
export const FooterBoxLeft = styled.div`
  margin-right: auto;
  margin-left: 8px;
  ${CopyableText} {
    color: ${colors.textLight};
    margin-bottom: 5px;
  }
`
type BoxFooterProps = {
  small?: boolean
  isEditable?: boolean
}

export const BoxFooter = styled.div<BoxFooterProps>`
  width: 100%;
  position: relative;
  background-color: ${colors.fill};
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
  border-top: 1px solid ${p => (p.theme.isDark ? colors.fillContrast : colors.stroke)};
  display: flex;
  padding: 11px;
  height: ${props => (props.small ? '44px' : '56px')};
  align-items: center;
  justify-content: flex-end;

  ${props =>
    props.isEditable &&
    css`
      justify-content: space-between;
    `}

  > ${
    // $FlowFixMe
    Button
  }:first-child {
    margin-left: -1px;
  }
  > ${
      // $FlowFixMe
      Button
    }:last-child {
    margin-right: -1px;
  }
`

export const BoxContainer = styled.article`
  position: relative;
  margin-bottom: ${SPACING * 5}px;
  border-radius: ${BORDER_RADIUS};
  background-color: ${props => (props.theme.isDark ? colors.fillContrast : colors.fill)};
  border: 1px solid ${props => (props.theme.isDark ? colors.strokeContrast : colors.stroke)};
  overflow: ${props => (props.theme.isEmpty ? 'hidden' : 'initial')};
  z-index: 1;
  ${OverlayContainer} {
    border-radius: ${BORDER_RADIUS};
  }
  //TODO utiliser les tokens
  box-shadow: ${p =>
    !p.theme.isEmpty
      ? '0px 1px 3px rgba(15, 15, 15, 0.06), 0px 1px 8px rgba(15, 15, 15, 0.02)'
      : 'none'};

  ${BoxSectionContainer} {
    margin: -1px;
  }

  ${TableContainer} {
    border: none;
    margin: 0;
    box-shadow: none;
  }
`
