import {
  gsm7Charset,
  gsm7CharsetCounts,
  unicodeCharsReplacedWithGsm7Chars,
  STOP_MSG_LENGTH,
} from 'com.batch/sms/constants/charset-counts'

export const gsm7Count = ({
  message,
  countStop = false,
}: {
  message: string
  countStop?: boolean
}): number => {
  let count = 0
  for (const char of message) {
    // Si c'est un caractère GSM7 référencé on ajoute son count,
    // Si non on cherche si c'est éventuellement un caractère unicode remplacé par un caractère GSM7
    // Si non on ajoute 0 (impossible normalement)
    count +=
      gsm7CharsetCounts.find(c => c.value === char)?.count ??
      unicodeCharsReplacedWithGsm7Chars.find(c => c.unicode === getUnicode(char))?.count ??
      0
  }

  return count + (countStop ? STOP_MSG_LENGTH : 0)
}

export const gsm7Parts = (count: number): number => {
  // 1 part = 160
  // 1 multi-part = 153 (160 - header)
  return Math.ceil(count / (count > 160 ? 153 : 160))
}

export const unicodeCount = ({
  message,
  countStop = false,
}: {
  message: string
  countStop: boolean
}): number => {
  return message.length + (countStop ? STOP_MSG_LENGTH : 0)
}

export const unicodeParts = (count: number): number => {
  return Math.ceil(count / (count > 70 ? 67 : 70))
}

export const isGsm7Char = (char: string): boolean => {
  return gsm7Charset.indexOf(char) !== -1
}
export const isGsm7 = (message: string): boolean => {
  let isGsm7 = true

  // On check si chaque caractère est GSM7, si ce n'est pas le cas on cherche éventuellement si c'est un caractère unicode remplacé par un caractère GSM7
  for (const char of message) {
    if (!isGsm7Char(char)) {
      if (
        !unicodeCharsReplacedWithGsm7Chars.find(
          unicodeChar => unicodeChar.unicode === getUnicode(char)
        )
      ) {
        isGsm7 = false
      }
    }
  }

  return isGsm7
}
export const countSmsMessage = ({
  message,
  countStop = false,
}: {
  message: string
  countStop?: boolean
}): {
  count: number
  parts: number
  mode: 'UNI' | 'GSM7'
} => {
  if (isGsm7(message)) {
    const count = gsm7Count({ message, countStop })
    return {
      count,
      parts: gsm7Parts(count),
      mode: 'GSM7',
    }
  } else {
    const count = unicodeCount({ message, countStop })
    return { count, parts: unicodeParts(count), mode: 'UNI' }
  }
}

export const getUnicode = (char: string): string => {
  return '\\u' + char.charCodeAt(0).toString(16).padStart(4, '0')
}
