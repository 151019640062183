import * as colors from 'components/styled/tokens/colors'
import * as schemes from 'components/styled/tokens/schemes'

export type ButtonSchemeKind =
  | 'primary'
  | 'secondary'
  | 'inline'
  | 'inlineDark'
  | 'discreet'
  | 'discreetDark'
export type ButtonSchemeIntent = 'neutral' | 'action' | 'danger'

export const buttonScheme = {
  // kind
  primary: {
    // intent
    neutral: {
      normal: {
        color: colors.textNeutralContrast,
        background: colors.fillNeutral,
      },
      hover: {
        color: colors.textContrast,
        background: colors.fillNeutralHover,
      },
      active: {
        color: colors.textContrast,
        background: colors.fillNeutralActive,
      },
      disabled: {
        color: colors.textDisabled,
        background: colors.fillDisabled,
      },
    },

    action: {
      normal: {
        color: colors.textNeutralContrast,
        background: colors.fillAction,
      },
      hover: {
        color: colors.textContrast,
        background: colors.fillActionHover,
      },
      active: {
        color: colors.textContrast,
        background: colors.fillActionActive,
      },
      disabled: {
        color: colors.textDisabled,
        background: colors.fillDisabled,
      },
    },

    danger: {
      normal: {
        color: colors.textNeutralContrast,
        background: colors.fillDanger,
      },
      hover: {
        color: colors.textContrast,
        background: colors.fillDangerHover,
      },
      active: {
        color: colors.textContrast,
        background: colors.fillDangerActive,
      },
      disabled: {
        color: colors.textDisabled,
        background: colors.fillDisabled,
      },
    },
  },
  secondary: {
    neutral: {
      normal: {
        color: colors.textNeutral,
        background: colors.fill,
      },
      hover: {
        color: colors.text,
        background: schemes.grayscale['05'],
      },
      active: {
        color: colors.text,
        background: schemes.grayscale['10'],
      },
      disabled: {
        color: colors.textDisabled,
        background: colors.fillDisabled,
      },
    },

    action: {
      normal: {
        color: colors.textAction,
        background: colors.fill,
      },
      hover: {
        color: colors.textAction,
        background: schemes.grayscale['05'],
      },
      active: {
        color: colors.textAction,
        background: schemes.grayscale['10'],
      },
      disabled: {
        color: colors.textDisabled,
        background: colors.fillDisabled,
      },
    },

    danger: {
      normal: {
        color: colors.textDanger,
        background: colors.fill,
      },
      hover: {
        color: colors.textDanger,
        background: schemes.grayscale['05'],
      },
      active: {
        color: colors.textDanger,
        background: schemes.grayscale['10'],
      },
      disabled: {
        color: colors.textDisabled,
        background: colors.fillDisabled,
      },
    },
  },
  // inline-dark :)
  // -------------------------------------
  inlineDark: {
    // intent
    neutral: {
      normal: {
        color: colors.textNeutralContrast,
        background: 'transparent',
      },
      hover: {
        color: colors.textContrast,
        background: colors.opacifyContrastHover,
      },
      active: {
        color: colors.textContrast,
        background: colors.opacifyContrastActive,
      },
      disabled: {
        color: colors.textContrastDisabled,
        background: 'transparent',
      },
    },
    // intent
    action: {
      normal: {
        color: colors.textActionContrast,
        background: 'transparent',
      },
      hover: {
        color: colors.textActionContrast,
        background: colors.opacifyContrastHover,
      },
      active: {
        color: colors.textActionContrast,
        background: colors.opacifyContrastActive,
      },
      disabled: {
        color: colors.textContrastDisabled,
        background: 'transparent',
      },
    },
    // intent
    danger: {
      normal: {
        color: colors.textDangerContrast,
        background: 'transparent',
      },
      hover: {
        color: colors.textDangerContrast,
        background: colors.opacifyContrastHover,
      },
      active: {
        color: colors.textDangerContrast,
        background: colors.opacifyContrastActive,
      },
      disabled: {
        color: colors.textContrastDisabled,
        background: 'transparent',
      },
    },
  },

  // -----------------------------------------
  inline: {
    // intent
    neutral: {
      normal: {
        color: colors.textNeutral,
        background: 'transparent',
      },
      hover: {
        color: colors.text,
        background: colors.opacifyHover,
      },
      active: {
        color: colors.text,
        background: colors.opacifyActive,
      },
      disabled: {
        color: colors.textDisabled,
        background: 'transparent',
      },
    },
    // intent
    action: {
      normal: {
        color: colors.textAction,
        background: 'transparent',
      },
      hover: {
        color: colors.textAction,
        background: colors.opacifyHover,
      },
      active: {
        color: colors.textAction,
        background: colors.opacifyActive,
      },
      disabled: {
        color: colors.textDisabled,
        background: 'transparent',
      },
    },
    // intent
    danger: {
      normal: {
        color: colors.textDanger,
        background: 'transparent',
      },
      hover: {
        color: colors.textDanger,
        background: colors.opacifyHover,
      },
      active: {
        color: colors.textDanger,
        background: colors.opacifyActive,
      },
      disabled: {
        color: colors.textDisabled,
        background: 'transparent',
      },
    },
  },
  // -----------------------------------------
  discreet: {
    // intent
    neutral: {
      normal: {
        color: colors.textNeutral,
        background: 'transparent',
      },
      hover: {
        color: colors.text,
        background: 'transparent',
      },
      active: {
        color: colors.text,
        background: 'transparent',
      },
      disabled: {
        color: colors.textDisabled,
        background: 'transparent',
      },
    },
    // intent
    action: {
      normal: {
        color: colors.textNeutral,
        background: 'transparent',
      },
      hover: {
        color: colors.textAction,
        background: 'transparent',
      },
      active: {
        color: colors.textAction,
        background: 'transparent',
      },
      disabled: {
        color: colors.textDisabled,
        background: 'transparent',
      },
    },
    // intent
    danger: {
      normal: {
        color: colors.textNeutral,
        background: 'transparent',
      },
      hover: {
        color: colors.textDanger,
        background: 'transparent',
      },
      active: {
        color: colors.textDanger,
        background: 'transparent',
      },
      disabled: {
        color: colors.textDisabled,
        background: 'transparent',
      },
    },
  },
  // -----------------------------------------
  discreetDark: {
    // intent
    neutral: {
      normal: {
        color: colors.textNeutralContrast,
        background: 'transparent',
      },
      hover: {
        color: colors.textContrast,
        background: 'transparent',
      },
      active: {
        color: colors.textContrast,
        background: 'transparent',
      },
      disabled: {
        color: colors.strokeDark,
        background: 'transparent',
      },
    },
  },
}
