import styled, { css } from 'styled-components'

type LoaderContainerProps = {
  overlay?: boolean
  padding: number | null | undefined
}
export const LoaderContainer = styled.div<LoaderContainerProps>`
  position: relative;
  ${props =>
    !props.overlay &&
    css`
      min-height: 120px;
    `}

  .styled-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: ${props => props.padding ?? 0}px;

    ${props =>
      props.overlay &&
      css`
        z-index: 100;
        position: absolute;

        ${'' /* opacity: 0.7; */}
        ${'' /* background-color: #f5f5f5; */}
        background-color: white;
        background-blend-mode: lighten;
      `}
  }
`
