import React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'

export const useProjectChannels = (): {
  hasOneCepChannelConfigured: boolean
} => {
  const project = useSelector(optionalCurrentProjectSelector)

  const hasOneCepChannelConfigured = React.useMemo(() => {
    if (!project) {
      return false
    }
    return project.smsConfigured || project.anyPushConfigured || project.emailConfigured
  }, [project])

  return {
    hasOneCepChannelConfigured,
  }
}
