import { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { Pressure } from './pressure'

import { type AppRecord, type CappingCategoryRecord } from 'com.batch.redux/_records'
import { fetchAttributesAndValues } from 'com.batch.redux/attribute'
import { sdkMatchingTargetVersionSelector, lvlLoadingSelector } from 'com.batch.redux/sdk.selector'
import { showToast } from 'com.batch.redux/toaster'

type PressureProps = {
  hasCappingLabels: boolean
  hasCappingGlobal: boolean
  hasInAppCapping: boolean
  app: AppRecord
  categories: List<CappingCategoryRecord>
}

export const PressureContainer: React.ComponentType<PressureProps> = React.memo<PressureProps>(
  ({ hasCappingLabels, hasCappingGlobal, hasInAppCapping, app, categories }: PressureProps) => {
    const dispatch = useDispatch()

    const getIntallRateMatchingApiLvl = useSelector(sdkMatchingTargetVersionSelector)
    const sdkLevelLoading = useSelector(lvlLoadingSelector)
    const fetchAttributesAndValuesForApp = React.useCallback(
      () => dispatch(fetchAttributesAndValues({ app })),
      [dispatch, app]
    )

    const showToastSimplified = React.useCallback(
      (message: string, isSuccess) => {
        dispatch(showToast({ message, kind: isSuccess ? 'success' : 'error' }))
      },
      [dispatch]
    )

    // ====================== RENDER
    return (
      <Pressure
        showToast={showToastSimplified}
        sdkLevelLoading={sdkLevelLoading}
        fetchAttributesAndValues={fetchAttributesAndValuesForApp}
        getIntallRateMatchingApiLvl={getIntallRateMatchingApiLvl}
        hasCappingLabels={hasCappingLabels}
        hasCappingGlobal={hasCappingGlobal}
        hasInAppCapping={hasInAppCapping}
        categories={categories}
        app={app}
      />
    )
  }
)
