import * as React from 'react'
import styled from 'styled-components'

import { Avatar } from 'components/common/avatar'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router'

import { SidebarAccount } from './sidebar-account'
import { SidebarMobileMenu } from './sidebar-mobile-menu'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { type UserRecord } from 'com.batch.redux/user.records'

export type Menu = {
  avatar: string | typeof undefined | null
  namespace: string
  label: string
}
export type SidebarAccountMobileProps = {
  user: UserRecord
  company: CompanyRecord
  project: ProjectRecord | null | undefined
}

export const SidebarAccountMobile = (props: SidebarAccountMobileProps): React.ReactElement => {
  const { user, company, project } = props
  const menu = getMenu(window.location.pathname, user, company)

  const label = (
    <LabelContent>
      <Avatar
        url={menu.avatar || undefined}
        placeholder={menu.namespace}
        size={30}
        style={{ flex: '0 0 auto', marginRight: 12 }}
      />
      <strong>{menu.namespace}</strong>
      <Icon icon="chevron-right" color={colors.textLight} style={{ margin: '0 9px 0 6px' }} />
      <span>{menu.label}</span>
      <Icon icon="select" color={colors.textLight} style={{ marginLeft: 18 }} />
    </LabelContent>
  )
  return (
    <SidebarMobileMenu label={label} border="left">
      <SidebarAccount
        user={user}
        company={company}
        project={project}
        style={{ margin: '6px 0 28px 0' }}
      />
    </SidebarMobileMenu>
  )
}

const ROUTES: {
  [key: string]: {
    type: 'user' | 'company'
    label: string
  }
} = {
  account_settings: { type: 'user', label: 'Account settings' },
  account_security: { type: 'user', label: 'Security' },
  user_list_by_company: { type: 'company', label: 'Manage team' },
  company_settings: { type: 'company', label: 'Settings' },
  company_billing: { type: 'company', label: 'Plans & billing' },
  company_gdpr: { type: 'company', label: 'GDPR & privacy' },
}

const getMenu = (path: string, user: UserRecord, company: CompanyRecord): Menu => {
  for (const k in ROUTES) {
    const route = ROUTES[k]
    if (path !== generateUrl(k, route.type === 'user' ? {} : { companyId: company.id })) continue

    return {
      avatar: route.type === 'user' ? user.avatarUrl : company.avatarUrl,
      namespace: route.type === 'user' ? `${user.firstName} ${user.lastName}` : company.name,
      label: ROUTES[k].label,
    }
  }
  return { avatar: undefined, namespace: '', label: '' }
}

export const LabelContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  font-weight: 500;

  strong {
    flex: 0 1 auto;
    overflow: hidden;
    color: ${colors.text};
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  i + span {
    display: block;
    flex: 1 0 auto;
    color: ${colors.textLight};
    white-space: nowrap;
  }
`

export default SidebarAccountMobile
