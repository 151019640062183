import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { PreviewSwitchContainer } from 'com.batch/email/ui/components/preview-switch/preview-switch.styles'
import { Toolbar } from 'com.batch/shared/ui/component/toolbar/toolbar.styles'

type Props = {
  preview: 'MOBILE' | 'DESKTOP'
  setPreview: (state: 'MOBILE' | 'DESKTOP') => void
}
export const PreviewSwitch = ({ preview, setPreview }: Props): React.ReactElement => {
  const handleOnClick = React.useCallback(
    (previewType: 'MOBILE' | 'DESKTOP') => () => setPreview(previewType),
    [setPreview]
  )

  return (
    <PreviewSwitchContainer>
      <Toolbar vertical>
        <Button
          style={{ width: 35, height: 36 }}
          onClick={handleOnClick('DESKTOP')}
          isActive={preview === 'DESKTOP'}
        >
          <Icon icon="computer" />
        </Button>

        <Button
          style={{ width: 35, height: 36 }}
          onClick={handleOnClick('MOBILE')}
          isActive={preview === 'MOBILE'}
        >
          <Icon icon="mobile" />
        </Button>
      </Toolbar>
    </PreviewSwitchContainer>
  )
}
