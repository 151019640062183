import { type Map, type OrderedMap } from 'immutable'

import {
  type JourneySettingsRecord,
  type JourneyNodeRecord,
  type TimerNodeRecord,
} from './journey.records'

import { type AttributeRecord } from 'com.batch.redux/_records'

type props = {
  settings: JourneySettingsRecord
  nodes: Map<string, JourneyNodeRecord>
}
export const checkMissingEventsOnEntryAndTimer = (
  { settings, nodes }: props,
  attributes: OrderedMap<string, AttributeRecord>
): props => {
  let dropInstanceIdCauseWeChangedEntryEvents = false
  const updatedEntryEvents = settings.entryEvents.filter(event => {
    if (!attributes.has(event.name)) {
      dropInstanceIdCauseWeChangedEntryEvents = true
      return false
    }
    return true
  })

  const nodesIdToDrop: Array<string> = []
  const updatedNodes = nodes.map(node => {
    if (node.type === 'TIMER') {
      const timerNodeWithoutMissingTriggers: TimerNodeRecord = node.set(
        'onEvents',
        node.onEvents.map(eventWithNext => {
          const filteredTriggers = eventWithNext.triggers.filter(trigger => {
            if (!attributes.has(trigger.name)) {
              return false
            }
            return true
          })
          if (filteredTriggers.size === 0) {
            nodesIdToDrop.push(eventWithNext.nextNodeId)
          }
          return eventWithNext.set('triggers', filteredTriggers)
        })
      )
      return timerNodeWithoutMissingTriggers.set(
        'onEvents',
        timerNodeWithoutMissingTriggers.onEvents.filter(
          eventWithNext => eventWithNext.triggers.size > 0
        )
      )
    }
    return node
  })
  return {
    settings: settings
      .set('entryEvents', updatedEntryEvents)
      .set(
        'hasInstanceId',
        dropInstanceIdCauseWeChangedEntryEvents ? false : settings.hasInstanceId
      ),
    nodes: updatedNodes.filter((node, id) => !nodesIdToDrop.includes(id)),
  }
}
