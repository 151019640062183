import * as React from 'react'
import styled from 'styled-components'

type StickyContainerProps = {
  stuck: boolean
}
const StickyContainer = styled.div<StickyContainerProps>`
  @media (min-height: 700px) {
    position: sticky;
    top: 0;
    z-index: ${props => (props.stuck ? 200 : 40)};
    margin: ${props => (props.stuck ? -1 : 0)}px 0 0 1px;
  }
`
const ScrollTrackerContainer = styled.div`
  position: relative;
  padding-bottom: 0.01px; /* Avoid overflowing margins */
  &:last-child {
    padding-bottom: 400px;
  }
`
type ScrollTrackerAnchorProps = {
  anchorOffset: number
}
const ScrollTrackerAnchor = styled.div<ScrollTrackerAnchorProps>`
  position: absolute;
  top: ${props => `${props.anchorOffset || 0}px`};
`

type StickyProps = {
  onStickyChange?: (isStuck: boolean) => void
  children: React.ReactNode
}
export const Sticky = ({ children, onStickyChange }: StickyProps): React.ReactElement => {
  const [isStuck, setIsStuck] = React.useState(false)
  const io = React.useMemo(
    () =>
      new IntersectionObserver(
        entries => {
          const isStuck =
            entries[0].isIntersecting && window.matchMedia('(min-height: 700px)').matches
          if (onStickyChange) onStickyChange(isStuck)
          setIsStuck(isStuck)
        },
        { rootMargin: '0% 0% -100% 0%' }
      ),
    [onStickyChange]
  )

  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    const container = containerRef.current
    if (container) io.observe(container)

    return () => {
      if (container) io.unobserve(container)
    }
  }, [io])
  return (
    <StickyContainer ref={containerRef} stuck={isStuck}>
      {children}
    </StickyContainer>
  )
}
type ScrollTrackerProps = {
  children: React.ReactNode
  id: string
  anchorOffset: number
  onScrollEnter?: () => void
  onScrollLeave?: () => void
}
export const ScrollTracker = ({
  children,
  id,
  anchorOffset,
  onScrollEnter,
  onScrollLeave,
}: ScrollTrackerProps): React.ReactElement => {
  const io = React.useMemo(
    () =>
      new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            if (onScrollEnter) onScrollEnter()
          } else {
            if (onScrollLeave) onScrollLeave()
          }
        },
        { rootMargin: '-50% 0%' }
      ),
    [onScrollEnter, onScrollLeave]
  )
  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    const container = containerRef.current
    if (container) io.observe(container)

    return () => {
      if (container) io.unobserve(container)
    }
  }, [io])

  return (
    <ScrollTrackerContainer ref={containerRef}>
      <ScrollTrackerAnchor id={id} anchorOffset={anchorOffset} />
      {children}
    </ScrollTrackerContainer>
  )
}
