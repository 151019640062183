import { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { getEmailErrorsSelector } from 'com.batch/email/store/email-automation.selector'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { getPushErrorsSelector } from 'com.batch/push/store/push.selector'
import { getSmsErrorsSelector } from 'com.batch/sms/store/sms.selector'
import { type State } from 'com.batch.redux/_records'

export const getMessageErrorsSelector: (
  arg1: State
) => (arg1: { stepMessageNodeId: string | null | undefined }) => Map<string, Array<string>> =
  createSelector(
    getEmailErrorsSelector,
    getPushErrorsSelector,
    getSmsErrorsSelector,
    getMessageConfigSelector,
    (getEmailErr, getPushErr, getSmsErr, getConfig) =>
      ({ stepMessageNodeId }) => {
        const config = getConfig({ stepMessageNodeId })
        switch (config.channel) {
          case 'sms':
            return getSmsErr({ stepMessageNodeId })
          case 'email':
            return getEmailErr({ stepMessageNodeId })
          default:
            return getPushErr({ stepMessageNodeId })
        }
      }
  )

export const campaignMessageErrorsSelector: (arg1: State) => Map<string, Array<string>> =
  createSelector(getMessageErrorsSelector, getMessageError => {
    return getMessageError({ stepMessageNodeId: undefined })
  })
