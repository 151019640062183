// @ts-nocheck
const isProductionSentry = window.location.host.indexOf('batch.com') !== -1
const dsn = isProductionSentry ? process.env.sentryDSN : process.env.sentryDSNStaging
let SentryV = null
let middleWare = () => (next: unknown) => (action: unknown) => {
  next(action)
}
if (dsn) {
  const SentryStuff = require('@sentry/browser')
  SentryStuff.init({
    dsn,
    environment: isProductionSentry ? 'production' : 'staging',
  })
  SentryV = SentryStuff
  middleWare = (store: unknown) => {
    SentryV.configureScope(scope => {
      scope.addEventProcessor(event => {
        const state = store.getState()
        const user =
          typeof window.user !== 'undefined' && typeof window.user.debug !== 'undefined'
            ? window.user.debug
            : {}
        return {
          ...event,
          extra: {
            ...event.extra,
            'redux:state': state,
          },
          user: {
            ...event.user,
            ...user,
          },
        }
      })
    })

    return (next: unknown) => (action: unknown) => {
      SentryV.addBreadcrumb({
        category: 'redux-action',
        message: action.type,
      })

      return next(action)
    }
  }
}
export const Sentry = SentryV

export const SentryMiddleware = middleWare
