import request from 'superagent-interface-promise'

import { generateUrl } from 'com.batch.common/router'

export function apiCaller<T>(
  method: 'GET' | 'POST',
  route: string,
  data: Record<any, any> = {}
): Promise<T> {
  const url = generateUrl(route)
  const query = method === 'POST' ? request.post(url).send(data) : request.get(url).query(data)

  return query.then(
    ({ body }) => body,
    ({ body }) =>
      Promise.reject(
        typeof body.errors === 'object' && Array.isArray(body.errors)
          ? body.errors
          : [{ message: 'An unexpected error occurred', field: 'email' }]
      )
  )
}

export const registerInfos = ({
  email,
  firstname,
  lastname,
  confirmationToken,
  sso = false,
}: {
  email: string
  firstname: string
  lastname: string
  confirmationToken?: string
  sso?: boolean
}): Promise<Record<any, any>> =>
  apiCaller<Record<any, any>>('POST', sso ? 'api_register_sso' : 'api_register', {
    email,
    firstname,
    lastname,
    confirmationToken,
  })

export const resendEmail = (): Promise<Record<any, any>> =>
  apiCaller('POST', 'api_register_confirm_resend')

export const savePassword = (email: string, password: string): Promise<Record<any, any>> =>
  apiCaller('POST', 'api_register_password', { email, password })

export const generate2fa = (): Promise<{
  totpUri: string
  qrCodeSvg: string
}> => apiCaller('GET', 'api_register_2fa_generate')

export const validate2fa = ({
  totpUri,
  totpCode,
}: {
  totpUri: string
  totpCode: string
}): Promise<{
  codes: Array<string>
}> => apiCaller('POST', 'api_register_2fa_enable', { totpUri, totpCode })

export const skip2fa = (): Promise<Record<any, any>> =>
  apiCaller<Record<any, any>>('POST', 'api_register_2fa_skip')

export const saveCompany = (name: string): Promise<Record<any, any>> =>
  apiCaller<Record<any, any>>('POST', 'api_register_company', { name })
