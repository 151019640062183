import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { useIsCurrentUserAllowedTo, useAction } from 'components/_hooks'
import {
  Box,
  BoxHeader,
  BoxBody,
  HeaderBoxTitle,
  BoxFooter,
  FooterBoxActions,
  HeaderBoxActions,
} from 'components/common/box'
import { PermissionButton, Button } from 'components/common/button'
import { Code } from 'components/common/code'
import { HideableCode } from 'components/common/code/hideable-code'
import { Grid } from 'components/common/grid'
import { InputWrapper, Input, Radio } from 'components/form'
import { Title } from 'components/styled/text'

import { AppAvatar } from './app-avatar'
import { type CommonGlobalProps } from './global'
import { APIKeyContainer, KeyColumn, Labelkey } from './global.styles'
import {
  DragAndDropContainer,
  DragAndDropIconContainer,
  DangerZone,
} from './settings-global-styles'

import { ChannelSubNav } from '../project/navigation/channel-sub-nav'
import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { updateProject } from 'com.batch.redux/project'
import { type ProjectRecord } from 'com.batch.redux/project.records'

type GlobalProjectsProps = {
  project: ProjectRecord
} & CommonGlobalProps

// first release we disable much of the stuff
const disabled = true

const GlobalProjects = ({
  project,
  name,
  setName,
  icon,
  sdk,
  setSdk,
  errors,
  bundleId,
  setBundleId,
  buttonLoading,
  userNotAllowedToUpdade,
  remove,
  save,
  sdks,
  hasChanges,
  company,
  hasDevApiKeyFeature,
  app,
}: GlobalProjectsProps): React.ReactElement => {
  // ====================== Selectors

  const apps = useSelector(currentProjectAppsSelector)
  const updateProjectBound = useAction(updateProject)

  const [projectName, setProjectName] = React.useState(project.name)
  const saveProject = React.useCallback(() => {
    updateProjectBound({ company, project: project.set('name', projectName) })
  }, [company, project, projectName, updateProjectBound])

  const isAllowedToDeleteApp = useIsCurrentUserAllowedTo(['company', 'apps:create-delete'])
  const isAllowedToSaveSettings = useIsCurrentUserAllowedTo(['app', 'settings:infos:write'])

  const onProjectNameChange = React.useCallback(evt => setProjectName(evt.target.value), [])
  const onAppNameChange = React.useCallback(evt => setName(evt.target.value), [setName])
  const onUrlChange = React.useCallback(evt => setBundleId(evt.target.value), [setBundleId])
  const onSdkChange = React.useCallback(sdkKind => () => setSdk(sdkKind), [setSdk])

  return (
    <React.Fragment>
      <Title overEmptyState>General</Title>

      <Box>
        <BoxHeader>
          <HeaderBoxTitle title="Project information" />
        </BoxHeader>
        <BoxBody>
          <Grid template="1fr 1fr">
            <div style={{ padding: 20 }}>
              <InputWrapper label="Project name" htmlFor="project-name">
                <Input
                  type="text"
                  id="project-name"
                  value={projectName}
                  onChange={onProjectNameChange}
                  invalid={!projectName}
                  accessNotAllowed={userNotAllowedToUpdade}
                />
              </InputWrapper>

              <Button
                onClick={saveProject}
                style={{ marginTop: 46 }}
                isLoading={project.loadingState === 'LOADING'}
                intent="action"
                kind="primary"
                disabled={projectName === project.name || !projectName}
              >
                Save
              </Button>
            </div>

            <DragAndDropContainer>
              {/*  <Button
                disabled={disabled}
                style={{ position: 'absolute', height: 28, right: 20, margin: 5 }}
              >
                <Icon icon="delete" />
              </Button>
              <Grid template="2fr 1fr" alignItems="center">
                <DragAndDropText disabled={disabled}>
                  <p>Drag & drop your icon here or</p>
                  <Button
                    style={{ marginTop: 10, height: 28 }}
                    disabled
                    kind="inline"
                    addOn="suffix"
                    // onClick={() => console.log('Upload')}
                  >
                    Browse your files
                    <Icon icon="file" />
                  </Button>
                </DragAndDropText>

              </Grid>*/}
              <DragAndDropIconContainer>
                <AppAvatar
                  size={100}
                  style={{ marginBottom: 0 }}
                  icon={disabled ? icon : project?.iconUrl}
                  name={name ? project?.name : 'A'}
                />
              </DragAndDropIconContainer>
            </DragAndDropContainer>
          </Grid>
        </BoxBody>
      </Box>

      <Box as="form" onSubmit={save} style={{ borderRadius: 6 }}>
        <BoxHeader
          style={{ height: 'auto', alignItems: 'flex-start', paddingTop: 10, paddingBottom: 10 }}
        >
          <div style={{ flex: '1 1 auto', marginRight: 22 }}>
            <HeaderBoxTitle
              title={app.platform === 'webpush' ? 'Website information' : 'App settings'}
              style={{ padding: '8px 0 0 0', marginBottom: 8 }}
            />
            <ChannelSubNav
              apps={apps}
              isCollapsed
              project={project}
              isTransparent
              channel={{ appId: app.id }}
            />
          </div>
          <HeaderBoxActions>
            <Button kind="primary" intent="action" disabled={disabled} type="button">
              Create app
            </Button>
          </HeaderBoxActions>
        </BoxHeader>

        <BoxBody>
          <Grid template="1fr 1fr" alignItems="stretch">
            <div style={{ padding: 20 }}>
              <InputWrapper
                label={app.platform === 'webpush' ? 'Name' : 'Application name'}
                feedback={errors.get('name')}
                htmlFor="app-name"
              >
                <Input
                  type="text"
                  id="app-name"
                  value={name}
                  onChange={onAppNameChange}
                  invalid={errors.has('name')}
                  accessNotAllowed={userNotAllowedToUpdade}
                />
              </InputWrapper>

              {app.platform === 'webpush' && (
                <InputWrapper label="URL" feedback={errors.get('bundleId')} htmlFor="bundleId">
                  <Input
                    type="text"
                    id="bundleId"
                    value={bundleId || ''}
                    onChange={onUrlChange}
                    invalid={errors.has('bundleId')}
                    accessNotAllowed={userNotAllowedToUpdade}
                  />
                </InputWrapper>
              )}

              {(app.platform === 'ios' || app.platform === 'android') && (
                <InputWrapper label="SDK" id="radiogroup-label-sdk">
                  <div role="radiogroup" aria-labelledby="radiogroup-label-sdk">
                    {sdks
                      .filter(s => !s.legacy || s.kind === sdk)
                      .map((currentSdk, i) => (
                        <div key={i} style={{ marginRight: 24, display: 'inline-block' }}>
                          <Radio
                            onChange={onSdkChange(currentSdk.kind)}
                            label={currentSdk.kind}
                            key={currentSdk.kind}
                            checked={sdk === currentSdk.kind}
                            disabled={userNotAllowedToUpdade}
                          />
                        </div>
                      ))}
                  </div>
                </InputWrapper>
              )}
              <Grid template="1fr auto" style={{ marginTop: 20 }}>
                <span />
                <PermissionButton
                  type="submit"
                  intent="action"
                  kind="primary"
                  disabled={!hasChanges || buttonLoading === 'updating'}
                  isLoading={buttonLoading === 'updating'}
                  isAllowed={isAllowedToSaveSettings}
                >
                  Save
                </PermissionButton>
              </Grid>
            </div>

            <APIKeyContainer>
              <Grid template="1fr 1fr">
                {app.platform !== 'webpush' && hasDevApiKeyFeature && (
                  <KeyColumn>
                    <Labelkey>Dev API Key</Labelkey>
                    <div className="keyCode">
                      <Code>{app.devApiKey || '**** Insufficient privileges ****'}</Code>
                    </div>
                  </KeyColumn>
                )}
                <KeyColumn>
                  <Labelkey>
                    {app.platform === 'webpush' || !hasDevApiKeyFeature
                      ? 'SDK API Key'
                      : 'Live API Key'}
                  </Labelkey>
                  <div className="keyCode">
                    <Code>{app.apiKey || '**** Insufficient privileges ****'}</Code>
                  </div>
                </KeyColumn>
              </Grid>
              <Grid template="1fr 1fr" style={{ marginTop: 20 }}>
                <KeyColumn>
                  <Labelkey>REST API key</Labelkey>
                  <div style={{ maxWidth: 230 }}>
                    <HideableCode>
                      {company.restKey || '**** Insufficient privileges ****'}
                    </HideableCode>
                  </div>
                </KeyColumn>

                {app.features.includes('inbox') && app.platform !== 'webpush' && (
                  <KeyColumn>
                    <Labelkey>Inbox secret</Labelkey>
                    <div className="keyCode">
                      <Code>{app.inboxSecret || '**** Insufficient privileges ****'}</Code>
                    </div>
                  </KeyColumn>
                )}
              </Grid>
              <Grid template="auto" style={{ marginTop: 20 }}>
                <KeyColumn>
                  <Labelkey>Project key</Labelkey>
                  <div className="keyCode" style={{ maxWidth: '100%' }}>
                    <Code>{project.projectKey}</Code>
                  </div>
                </KeyColumn>
              </Grid>
            </APIKeyContainer>
          </Grid>
        </BoxBody>

        <BoxFooter>
          <FooterBoxActions>
            <PermissionButton
              type="button"
              kind="inline"
              intent="danger"
              disabled={disabled}
              isLoading={buttonLoading === 'removing'}
              onClick={remove}
              isAllowed={isAllowedToDeleteApp}
            >
              Delete app
            </PermissionButton>
          </FooterBoxActions>
        </BoxFooter>
      </Box>

      <DangerZone>
        <BoxHeader>
          <HeaderBoxTitle title="Danger zone" />
        </BoxHeader>
        <BoxBody style={{ padding: 20 }}>
          <p>
            If you delete the project, all the applications inside this project will be deleted.
          </p>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 20 }}>
            <Button kind="primary" intent="danger" disabled>
              Delete project
            </Button>
          </div>
        </BoxBody>
      </DangerZone>
    </React.Fragment>
  )
}
export { GlobalProjects }
