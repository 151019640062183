import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { showToast } from 'com.batch.redux/toaster'

import { type LabelRecord } from 'com.batch/labels/models/labels.records'

type UpdateProjectLabelAction = ReduxAction<'UPDATE_PROJECT_LABEL', LabelRecord>

export type UpdateProjectLabelFailureAction = ReduxAction<
  'UPDATE_PROJECT_LABEL_FAILURE',
  LabelRecord
>

export type UpdateProjectLabelSuccessAction = ReduxAction<
  'UPDATE_PROJECT_LABEL_SUCCESS',
  LabelRecord
>

export type UpdateProjectLabelActionType =
  | UpdateProjectLabelAction
  | UpdateProjectLabelFailureAction
  | UpdateProjectLabelSuccessAction

export const updateLabel = (label: LabelRecord): DispatchExtraBoundFn<Promise<LabelRecord>> => {
  return async (dispatch, getState, { metadataService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    const existingLabels = state.projectLabels.labels
    if (!existingLabels.some(existingLabel => label.code === existingLabel.code)) {
      dispatch(
        showToast({
          kind: 'error',
          title: 'Update error',
          message: 'Label does not exist',
        })
      )
      return Promise.reject('Label does not exist')
    }

    return promiseActionCreator({
      dispatch,
      promise: metadataService
        .setLabels({
          projectKey: project.projectKey,
          labels: [{ description: label.description, code: label.code }],
        })
        .then(() => {
          dispatch(showToast({ kind: 'success', title: 'Label updated', message: 'Label updated' }))
          return label
        })
        .catch(() => {
          dispatch(
            showToast({ kind: 'error', title: 'Save error', message: 'Unable to update label' })
          )
          return label
        }),
      payload: label,
      actionName: 'UPDATE_PROJECT_LABEL',
    })
  }
}
