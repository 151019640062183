import styled, { css } from 'styled-components'

import { Button } from 'components/common/button'
import { buttonScheme } from 'components/common/button/button.scheme'
import { Grid } from 'components/common/grid'
import { FilterCounterOptionContent, Counter } from 'components/filter/filter-counter-option.styles'
import { focusedStyle } from 'components/form/styles'
import * as colors from 'components/styled/tokens/colors'
import * as schemes from 'components/styled/tokens/schemes'
import * as shadows from 'components/styled/tokens/shadows'

// ====================== MAIN
type SelectInputProps = {
  value: string
  isOpen: boolean
  isSearchable: boolean
  isMulti?: boolean
  isEmpty?: boolean
  isClearable?: boolean
}
export const SelectInput = styled.input<SelectInputProps>`
  ${props =>
    props.isMulti
      ? css`
          padding: 0px 12px 0px 0px;
          margin-top: -2px;
          margin-bottom: -2px;
          flex-grow: 1;
          min-width: 10px;
          flex-shrink: 1;
          flex-basis: 40px;
          opacity: ${(props: SelectInputProps) => (props.isSearchable ? 1 : 0)};
          ${(props: SelectInputProps) =>
            !props.isOpen &&
            css`
              display: none;
            `}
        `
      : css`
          opacity: ${(props: SelectInputProps) => (props.isOpen ? 1 : 0)};
          position: absolute;
          z-index: 2;
          width: calc(100% - ${(props: SelectInputProps) => (props.isClearable ? 70 : 42)}px);
          height: 100%;
          left: 8px;
          right: 0;
          padding-bottom: 1px;
        `}
  background: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  text-overflow: ellipsis;
  cursor: ${props => (props.isSearchable ? 'text' : 'pointer')};
`
type SelectValueContainerProps = {
  isMulti?: boolean
  hasValue?: boolean
}
export const SelectValueContainer = styled.div<SelectValueContainerProps>`
  display: flex;
  ${props =>
    props.isMulti
      ? css`
          flex-wrap: wrap;
          padding: 2px 0;
          margin-left: ${(p: SelectValueContainerProps) => (p.hasValue ? -4 : 0)}px;

          ${Button} {
            padding: 1px 4px 1px 6px;
            height: auto;
            margin-top: 2px;
            margin-bottom: 2px;
            margin-right: 4px;
            font-weight: 400;
            background-color: ${colors.opacifyHover};
            box-shadow: none;
            outline: none;

            &:hover,
            &:focus {
              background-color: ${colors.opacifyActive};
            }
          }
        `
      : css``}
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  min-height: 34px;
  min-width: 1px;
  font-weight: 400;
  color: ${colors.text};
`

export const SelectClear = styled.span`
  display: flex;
  justify-content: center;
  cursor: pointer;

  i svg {
    margin-bottom: 2px;

    path {
      stroke-width: 1.5;
    }
  }
`

export const SelectIndicator = styled.button`
  cursor: pointer;
  display: inline-block;
  text-align: right;
  padding-right: 9px;
  border: none;
`
// differents parts of SelectValueContainer
export const SelectValue = styled.div`
  border: 0;
  border-radius: 4px 0 0 4px;
  display: flex;
  align-items: center;
`

export const SelectPlaceholder = styled.div<{
  isDisabled: boolean
}>`
  font-size: 14px;
  user-select: none;
  color: ${colors.textLight};
`

const stateHover = css`
  box-shadow:
    ${shadows.raised},
    0 0 0 1px ${colors.strokeHover};
  background-color: ${colors.fillBackground};
  color: ${colors.textLight};
`
const stateDefault = css`
  box-shadow:
    ${shadows.raised},
    0 0 0 1px ${colors.stroke};
  background-color: ${colors.fill};
  color: ${colors.textLight};

  &:hover {
    ${stateHover}
  }
`
const stateDefaultNoShadow = css`
  box-shadow: none;
  color: ${colors.textLight};

  &:hover {
    background-color: ${colors.opacifyHover};
  }
`
const stateFocus = css`
  box-shadow:
    ${shadows.depthAction},
    0 0 0 1px ${colors.strokeAction},
    inset 0 0 0 1px ${colors.strokeAction};
  background-color: ${colors.fillBackground};
`
const stateDisabled = css`
  box-shadow:
    ${shadows.raised},
    0 0 0 1px ${schemes.darklucent[20]};
  color: ${colors.textDisabled};
  background-color: ${colors.fillDisabled};
`
const stateDisabledNoShadow = css`
  box-shadow: none;
  color: ${colors.textDisabled};
  background-color: ${colors.fillDisabled};
`
const stateInvalid = css`
  box-shadow:
    ${shadows.depthDanger},
    0 0 0 1px ${colors.strokeDanger},
    inset 0 0 0 1px ${colors.strokeDanger};
  background-color: ${colors.fill};

  &:hover {
    background-color: ${colors.fillBackground};
    color: ${colors.textLight};
  }
`

export const SelectField = styled(Grid)`
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`

type SelectContainerProps = {
  isDisabled: boolean
  isSearchable?: boolean
  isFocused: boolean
  invalid?: boolean
  $noShadow?: boolean
}

export const SelectContainer = styled.div<SelectContainerProps>`
  min-height: 36px;
  display: flex;

  &:empty {
    display: none;
  }

  letter-spacing: normal;
  align-items: stretch;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  padding-left: 12px;

  ${props =>
    props.isDisabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
    `}
  ${p => {
    if (p.$noShadow) {
      return p.isDisabled
        ? stateDisabledNoShadow
        : p.invalid
          ? stateInvalid
          : p.isFocused
            ? focusedStyle
            : stateDefaultNoShadow
    }
    return p.isDisabled
      ? stateDisabled
      : p.isFocused
        ? stateFocus
        : p.invalid
          ? stateInvalid
          : stateDefault
  }}
    
  &:hover ${SelectIndicator} {
    color: ${buttonScheme.discreet.neutral.hover.color};
  }

  ${SelectIndicator} {
    color: ${p =>
      p.isFocused
        ? buttonScheme.discreet.neutral.active.color
        : buttonScheme.discreet.neutral.normal.color};
  }

  ${SelectClear} {
    color: ${p =>
      p.isFocused
        ? buttonScheme.discreet.neutral.active.color
        : buttonScheme.discreet.neutral.normal.color};

    &:hover {
      color: ${buttonScheme.discreet.neutral.hover.color};
    }
  }

  border-radius: 6px;
`
// ====================== MENU

export const SelectNoOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  height: 72px;
  border-radius: 5px;
  margin: 4px;
  color: ${colors.textLight};
`
type SelectOptionProps = {
  isActive: boolean
}
export const SelectOption = styled.div<SelectOptionProps>`
  ${props =>
    props.isActive &&
    css`
      ${FilterCounterOptionContent} {
        color: ${colors.textNeutral};
      }

      ${Counter} {
        color: ${colors.textLight};
      }

      ${Button}:disabled {
        background-color: ${schemes.grayscale['10']} !important;
        color: ${colors.textNeutral} !important;
      }
    `}
  &:hover ${Counter} {
    color: ${colors.textLight};
  }

  &:hover {
    color: ${colors.textNeutral};
  }

  &:hover input:checked:not([disabled]) + .styled-option-symbol {
    background: ${colors.fillActionHover};
    border: 1px solid ${colors.fillActionHover};
  }

  &:active input:checked + .styled-option-symbol {
    background: ${colors.fillActionHover};
    border: 1px solid ${colors.fillAction};
  }
`
