import styled from 'styled-components'

import { grayscale } from 'components/styled/tokens/schemes'

export const RatioTagContainer = styled.div`
  display: inline-flex;
  padding: 3px 4px 3px 5px;
  justify-content: center;
  align-items: center;
  gap: 1px;
  position: fixed;
  border-radius: 5px;
  background: #ffffff;
  box-shadow:
    0 0 0 1px rgba(15, 15, 15, 0.12),
    0 1px 4px 0 rgba(15, 15, 15, 0.06);
  color: ${grayscale[80]};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`
