import * as React from 'react'

import { Box, BoxHeader, BoxBody, HeaderBoxTitle } from 'components/common/box'
import { Code } from 'components/common/code'
import { HideableCode } from 'components/common/code/hideable-code'
import { Grid } from 'components/common/grid'
import { InputWrapper } from 'components/form'

import { type AppRecord } from 'com.batch.redux/_records'

type GlobalKeysProps = {
  app: AppRecord
  restKey: string | null | undefined
  hasDevApiKeyFeature: boolean
}

const GlobalKeys = ({ app, restKey, hasDevApiKeyFeature }: GlobalKeysProps) => {
  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle title="API Keys" />
      </BoxHeader>

      <BoxBody $padding>
        <Grid template="1fr 1fr">
          {app.platform !== 'webpush' && hasDevApiKeyFeature && (
            <InputWrapper
              label="Dev API Key"
              hint="Dev API Key for development use only. Will not trigger stats computation."
            >
              <div className="keyCode">
                <Code>{app.devApiKey || '**** Insufficient privileges ****'}</Code>
              </div>
            </InputWrapper>
          )}
          <InputWrapper
            style={{ marginTop: 0 }}
            label={
              app.platform === 'webpush'
                ? 'SDK API Key'
                : `${hasDevApiKeyFeature ? 'Live ' : ''}API Key`
            }
            hint={`${
              hasDevApiKeyFeature ? 'Live ' : ''
            }API Key, for use in a production environment. Stats will be computed.`}
          >
            <div className="keyCode">
              <Code>{app.apiKey || '**** Insufficient privileges ****'}</Code>
            </div>
          </InputWrapper>
        </Grid>
        <Grid template="1fr 1fr">
          <InputWrapper
            style={{ marginTop: 20 }}
            label="REST API Key"
            hint="REST API KEY if you want to send push using our APIs. Refer to the
                documentation to learn more."
          >
            <div style={{ width: 313 }}>
              <HideableCode>{restKey || '**** Insufficient privileges ****'}</HideableCode>
            </div>
          </InputWrapper>
          {app.features.includes('inbox') && app.platform !== 'webpush' && (
            <InputWrapper
              label="Inbox secret"
              hint="Inbox Secret, used in the SDK to hash custom user id when retrieving inbox
                        messages"
            >
              <div className="keyCode">
                <Code>{app.inboxSecret || '**** Insufficient privileges ****'}</Code>
              </div>
            </InputWrapper>
          )}
        </Grid>
      </BoxBody>
    </Box>
  )
}

export default React.memo<GlobalKeysProps>(GlobalKeys) as React.ComponentType<GlobalKeysProps>
