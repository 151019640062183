import * as React from 'react'

import { Ellipsis } from 'components/styled/text'

import { AttributeRow } from 'com.batch/profile/ui/components/attributes-block/attribute-row'

type UrlAttributeProps = {
  name: string
  value?: string
  isCopyable?: boolean
}

export const UrlAttributeRow = ({
  name,
  value,
  isCopyable = true,
}: UrlAttributeProps): React.ReactElement => {
  const shortUrl = React.useMemo(() => {
    return value?.replace('https://', '')
  }, [value])
  return (
    <AttributeRow icon="link" name={name} copyableValue={value} isCopyable={isCopyable}>
      <Ellipsis title={shortUrl}>{shortUrl}</Ellipsis>
    </AttributeRow>
  )
}
