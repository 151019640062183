import styled from 'styled-components'

export const IFramePreviewSwitchContainer = styled.div`
  flex-grow: 20;
  background: #fdfdfd;
  position: relative;
  height: 100%;
`

export const IFrameContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
