import Immutable, { type Map } from 'immutable'
import * as React from 'react'

import { schemes } from 'components/styled/tokens'

import {
  AvatarContainer,
  AppAvatarContainer,
  AvatarContent,
  AvatarLoader,
  AvatarPlatform,
} from './avatar.styles'

function generatePlaceholder(placeholder?: React.ReactNode) {
  if (typeof placeholder === 'string') {
    return placeholder
      .replace(/[^A-Za-z ]/g, '')
      .split(' ')
      .filter(str => str !== '')
      .slice(0, 2)
      .map(str => str[0])
      .join('')
  }
  return placeholder
}

export type AvatarProps = {
  children?: React.ReactElement
  className?: string
  color?: string
  placeholder?: React.ReactNode
  platform?: Platforms
  size?: number
  style?: any
  url?: string | null | undefined
}

let localCache: Map<string, boolean> = Immutable.Map()

/*
on veut 2 composants
Avatar avec des children qu'on resize
& AppAvatar avec une platform

*/
const BaseAvatarComponent = ({
  size = 30,
  placeholder,
  platform,
  children,
  className,
  style,
  url,
  color = schemes.grayscale['40'],
  ...rest
}: AvatarProps): React.ReactElement => {
  const sizedChildren = React.Children.map(children, c => {
    if (c) {
      return React.cloneElement(c, { ...c.props, size: Math.floor(size * 0.6) })
    }
    return null
  })
  const [image, setImage] = React.useState<any>(undefined)
  const [loading, setLoading] = React.useState(false)
  const genPlaceholder = generatePlaceholder(placeholder)
  const isAppAvatar = !!platform
  React.useEffect(() => {
    const img = new Image()
    if (!url) {
      if (image !== null) setImage(null)
      return
    }
    if (localCache.has(url)) {
      if (localCache.get(url) === true) {
        setImage(url)
      }
    } else {
      setLoading(true)
      img.onload = () => {
        setImage(url)
        localCache = localCache.set(url, true)
        setLoading(false)
      }
      img.onerror = () => {
        setLoading(false)
        localCache = localCache.set(url, false)
      }
      img.src = url
    }
    return () => {
      img.onload = null
      img.onerror = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])
  const containerProps = { size, className, style }
  const content = (
    <React.Fragment>
      <AvatarContent url={image ? image : ''} loader={!!loading} color={color} {...rest}>
        {loading && <AvatarLoader />}
        {!loading && !image && genPlaceholder}
      </AvatarContent>
      {platform && <AvatarPlatform icon={platform} />}
      {sizedChildren}
    </React.Fragment>
  )
  return isAppAvatar ? (
    <AppAvatarContainer {...containerProps}>{content}</AppAvatarContainer>
  ) : (
    <AvatarContainer {...containerProps}>{content}</AvatarContainer>
  )
}

export { AvatarContainer, AppAvatarContainer }
export const Avatar = BaseAvatarComponent
export const AppAvatar = BaseAvatarComponent
