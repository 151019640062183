import { type ReduxAction } from 'com.batch.redux/_records'

type DuplicateMessageActionPayload = {
  sourceTypedMessageId: string
  targetTypedMessageId: string
}

export type DuplicateMessageAction = ReduxAction<'DUPLICATE_MESSAGE', DuplicateMessageActionPayload>

export const duplicateMessage = ({
  sourceTypedMessageId,
  targetTypedMessageId,
}: DuplicateMessageActionPayload): DuplicateMessageAction => ({
  type: 'DUPLICATE_MESSAGE',
  payload: {
    sourceTypedMessageId,
    targetTypedMessageId,
  },
})
