import { debounce } from 'lodash-es'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { Editor } from './editor'

import { configSelector, personalisationAttrSelector } from 'com.batch.redux/attribute'
import { triggerEventSelector } from 'com.batch.redux/campaign.selector'
import { setTemplateForField } from 'com.batch.redux/template'

export type EditorConnectedProps = {
  id: string
  onChange: (newValue: string) => any
  onFocus?: () => any
  onBlur?: () => any
  placeholder: string | null | undefined
  value: string
  style?: {
    [key: string]: number | string
  }
  forcedFocus?: boolean
  disabled?: boolean
}

export const EditorConnected = ({
  id,
  onChange,
  ...props
}: EditorConnectedProps): React.ReactElement => {
  const contextEventId = useSelector(triggerEventSelector)
  const attributes = useSelector(personalisationAttrSelector)
  const config = useSelector(configSelector)
  const dispatch = useDispatch()

  const setTemplateForFieldBound = React.useCallback(
    (text: string) => {
      if (config.profileDataMode) return
      dispatch(setTemplateForField(id, text))
    },
    [config.profileDataMode, dispatch, id]
  )

  const setTemplateForFieldDebounced = React.useMemo(
    () =>
      debounce(setTemplateForFieldBound, 1200, {
        maxWait: 5000,
        trailing: true,
      }),
    [setTemplateForFieldBound]
  )
  const onChangeBound = React.useCallback(
    (text: string) => {
      setTemplateForFieldDebounced(text)
      onChange(text)
    },
    [setTemplateForFieldDebounced, onChange]
  )
  return (
    <Editor
      {...props}
      id={id}
      onChange={onChangeBound}
      contextEventId={contextEventId}
      attributes={attributes}
    />
  )
}
