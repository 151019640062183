import { InputButton } from 'components/form/fields/date-picker/date-picker.styles'
import styled from 'styled-components'

export const DateRangeFilterContainer = styled.div`
  max-width: 650px;
  width: 100%;

  ${InputButton} {
    &::placeholder {
      letter-spacing: -0.5px;
    }
  }
`
