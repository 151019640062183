export const bounceReasonsLabels = {
  UNDETERMINED: 'Unknown',
  INVALID_RECIPIENT: 'Invalid Recipient',
  SOFT_BOUNCE: 'Soft Bounce',
  DNS_FAILURE: 'DNS Failure',
  MAILBOX_FULL: 'Mailbox Full',
  TOO_LARGE: 'Too Large',
  TIMEOUT: 'Timeout',
  ADMIN_FAILURE: 'Admin Failure',
  NO_RECIPIENT: 'No recipient',
  GENERIC_BOUNCE: 'Generic Bounce',
  MAIL_BLOCK: 'Mail Block',
  SPAM_BLOCK: 'Spam Block',
  SPAM_CONTENT: 'Spam Content',
  PROHIBITED_ATTACHMENT: 'Prohibited Attachment',
  RELAYING_DENIED: 'Relaying Denied',
  AUTO_REPLY: 'Auto-Reply',
  TRANSIENT_FAILURE: 'Transient Failure',
  SUBSCRIBE: 'Subscribe',
  UNSUBSCRIBE: 'Unsubscribe',
  CHALLENGE_RESPONSE: 'Challenge-Response',
}
