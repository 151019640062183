import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { AppSettingsMenu } from 'components/app/app-settings-menu'
import { ProjectSettingsMenu } from 'components/project/project-settings-menu'

import { optionalCurrentProjectSelector } from 'com.batch.redux/project.selector'

export const SettingsMenu = (): React.ReactElement => {
  const project = useSelector(optionalCurrentProjectSelector)
  return project ? <ProjectSettingsMenu /> : <AppSettingsMenu />
}
