import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

import { type SubscriptionRecord } from 'com.batch/profile/models/profile.records'
import {
  ChannelBlockSubscriptions,
  ProfileBodyTitle,
  ChannelBlock,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { SubscriptionBlock } from 'com.batch/profile/ui/components/profile-hero/subscription-block/subscription-block'
import { Copyable } from 'com.batch/shared/ui/component/copy/copyable'

type Props = {
  isLoading?: boolean
  subscription: SubscriptionRecord
  phoneNumber: string | null | undefined
}

export const SmsChannelBlock = ({
  isLoading,
  phoneNumber,
  subscription,
}: Props): React.ReactElement => (
  <ChannelBlock>
    <ProfileBodyTitle>
      <h2>
        <Skeleton w={22} h={22}>
          <Icon icon="sms" />
        </Skeleton>
        <Skeleton w={80} h={22} style={{ marginLeft: 8 }}>
          <span>SMS</span>
        </Skeleton>
      </h2>
    </ProfileBodyTitle>
    {!phoneNumber && !isLoading ? (
      <div className="no-contact">No phone number attached to this profile</div>
    ) : (
      <React.Fragment>
        <ChannelBlockSubscriptions>
          <SubscriptionBlock
            channel="sms"
            subscriptionName="MARKETING"
            subscriptionStatus={subscription.smsMarketing.status}
            contact={phoneNumber}
          />
          <SubscriptionBlock
            channel="sms"
            subscriptionName="TRANSACTIONAL"
            subscriptionStatus={subscription.smsService.status}
            contact={phoneNumber}
          />
        </ChannelBlockSubscriptions>
        <Skeleton w={150} h={13}>
          <span className="fs-exclude">
            <Copyable notificationText="Phone number copied" value={phoneNumber ?? ''} />
          </span>
        </Skeleton>
      </React.Fragment>
    )}
  </ChannelBlock>
)
