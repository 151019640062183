import styled, { css } from 'styled-components'

import { textLight, textAction, strokeAction, opacifyAction } from 'components/styled/tokens/colors'
import { blue } from 'components/styled/tokens/schemes'

import { type DropzoneState } from 'com.batch/email/models/dropzone-state'

type EmailDropzoneContainerProps = {
  mode?: DropzoneState
}
export const EmailDropzoneContainer = styled.div<EmailDropzoneContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${strokeAction};
  border-radius: 12px;
  background-color: ${opacifyAction};
  text-align: center;

  & h2 {
    margin-bottom: 8px;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: ${textAction};
  }

  & p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${textLight};
  }

  & i {
    color: ${textAction};
  }

  ${props =>
    (props.mode === 'active' || props.mode === 'loading') &&
    css`
      border: 2px solid ${strokeAction};
      background-color: ${blue['00']};
    `}
`
