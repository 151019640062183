import { type Map } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { colors } from 'components/styled/tokens'

import { InlineEditorEditor } from './inline-editor.helper'

import { InactiveEditorContainer, InactiveEditorLabelContainer } from '../common.styles'
import {
  TemplateFactory,
  TemplateResultFactory,
  type TemplateRecord,
} from 'com.batch.redux/_records'

import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'

type InlineEditorProps = {
  messageId: string
  isActive: boolean
  onBlur: (arg1: [number, number]) => void
  placeholder?: string
  field: MessageBuilderEditableField
  variant: 'a' | 'b'
  canUseTemplating: boolean
  preventAllCariageReturn?: boolean
  inlinePersonalizationButton?: boolean
  selection: [number, number]
  onFocus: () => void
  onActivateAtPos: (arg1: number) => void
  onChange: (arg1: string) => void
  value: string
  templateCache: Map<string, TemplateRecord>
  label?: string
  hasCharCount?: boolean
}

export const InlineEditor: React.ComponentType<InlineEditorProps> = React.memo(
  ({
    messageId,
    value,
    onBlur,
    selection,
    templateCache,
    onActivateAtPos,
    variant,
    onFocus,
    field,
    canUseTemplating,
    preventAllCariageReturn = false,
    inlinePersonalizationButton = false,
    isActive,
    onChange,
    placeholder,
    label,
    hasCharCount = false,
  }: InlineEditorProps): React.ReactElement => {
    const parsing = React.useMemo(
      () => templateCache.get(value, TemplateFactory()).parsing,
      [templateCache, value]
    )
    const text = React.useMemo(() => {
      const tempResult = templateCache
        .get(value, TemplateFactory())
        .results.get(0, TemplateResultFactory())
      return tempResult.result ? tempResult.result : value
    }, [templateCache, value])

    return isActive || parsing ? (
      <InlineEditorEditor
        messageId={messageId}
        isLoading={parsing}
        initValue={value}
        variant={variant}
        field={field}
        canUseTemplating={canUseTemplating}
        preventAllCariageReturn={preventAllCariageReturn}
        inlinePersonalizationButton={inlinePersonalizationButton}
        onBlur={onBlur}
        selection={selection}
        onChange={onChange}
        placeholder={placeholder}
        hasCharCount={hasCharCount}
      />
    ) : (
      <InactiveInlineEditor
        inlinePersonalizationButton={inlinePersonalizationButton}
        value={text}
        placeholder={placeholder}
        onFocus={onFocus}
        onActivateAtPos={onActivateAtPos}
        label={label}
        field={field}
      />
    )
  }
)

type InactiveInlineEditorProps = {
  inlinePersonalizationButton?: boolean
  value: string
  placeholder: string | null | undefined
  onFocus: () => void
  onActivateAtPos: (arg1: number) => void
  label?: string
  field: MessageBuilderEditableField
}
const InactiveInlineEditor = ({
  inlinePersonalizationButton = false,
  value,
  placeholder,
  onFocus,
  onActivateAtPos,
  label,
  field,
}: InactiveInlineEditorProps): React.ReactElement => {
  const theme = useTheme()
  const id = React.useId()

  const onMouseUp = React.useCallback(() => {
    if (!theme?.disabledMode) {
      const selection = document.getSelection()
      onActivateAtPos(selection?.anchorOffset ?? 0)
    }
  }, [onActivateAtPos, theme?.disabledMode])

  const renderField = (
    <InactiveEditorContainer
      $fullyInline={inlinePersonalizationButton}
      $isPlaceholder={!value}
      onMouseUp={onMouseUp}
      data-testid={`${field}-placeholder`}
    >
      {value || placeholder}
      {!theme.disabledMode && (
        <input
          id={id}
          type="text"
          style={{
            textIndent: '-9999em',
            width: 0,
            height: 0,
            opacity: 0.01,
            position: 'fixed',
            top: 0,
          }}
          onFocus={onFocus}
        />
      )}
    </InactiveEditorContainer>
  )

  return label ? (
    <InactiveEditorLabelContainer data-testid={`${field}-placeholder`}>
      <label htmlFor={id} style={{ color: colors.textLight, fontWeight: 400, flexShrink: 0 }}>
        {label}
      </label>
      {renderField}
    </InactiveEditorLabelContainer>
  ) : (
    renderField
  )
}
