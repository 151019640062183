import { useLocation } from 'react-router-dom'

export const useGetReportTypeFromUrl = (): string => {
  const { pathname } = useLocation()

  if (pathname.includes('/reasons')) {
    return 'reasons'
  } else if (pathname.includes('/mailbox')) {
    return 'mailbox'
  }
  return 'root'
}
