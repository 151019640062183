import styled, { css, keyframes, type Keyframes } from 'styled-components'

import { Button } from 'components/common/button/button.styles'
import { schemes, colors } from 'components/styled/tokens'

type DropdownContainerProps = {
  $isOpen: boolean
  $forcedWidth?: number
  $forcedHeight?: number
  $isScrollable?: boolean
  $willScroll?: boolean
  $alwaysInDom: boolean
}

const ShowOnMountAnim: Keyframes = keyframes`
  0%{ opacity: 0;  }
  100%{ opacity: 1; }
`

export const DropdownContainer = styled.div<DropdownContainerProps>`
  border: 1px solid ${schemes.darklucent['20']};
  background: ${schemes.grayscale['00']};
  border-radius: 6px;
  outline: none;
  ${p =>
    p.$alwaysInDom
      ? css`
          transition: opacity 0.16s ease-in-out;
          pointer-events: ${(p: DropdownContainerProps) => (p.$isOpen ? 'auto' : 'none')};
          opacity: ${(p: DropdownContainerProps) => (p.$isOpen ? 1 : 0)};
        `
      : css`
          animation: ${ShowOnMountAnim} 0.16s ease-in-out;
        `}
  .styled-windowed-list {
    scrollbar-width: thin;
    scrollbar-color: #dadbdb transparent;

    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
    }

    ::-webkit-scrollbar {
      width: 16px;
      background-color: #fff;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 30px;
      width: 16px;
      height: 36px;
      background-color: #dadbdb;
      border: 4px solid #fff;
    }

    & > div > div {
      padding: 4px 4px 0 4px;
    }
    & > div {
      margin-bottom: ${p => (p.$willScroll ? 4 : 0)}px;
    }
  }

  padding-bottom: 4px;

  ${p =>
    p.$forcedWidth
      ? css`
          width: ${p.$forcedWidth}px;
        `
      : ''}
  ${p =>
    p.$forcedHeight
      ? css`
          max-height: ${p.$forcedHeight}px;
          overflow-y: auto;
        `
      : ''}
    ${p =>
    p.$isScrollable
      ? css`
          overflow-y: auto;
          overflow-x: hidden;
          padding-right: 20px;
          scrollbar-width: thin;
          scrollbar-color: #dadbdb #fff;
          &::-webkit-scrollbar {
            width: 10px;
            height: 0px;
          }
          &::-webkit-scrollbar-thumb {
            width: 6px;
            height: 16px;
            border-radius: 50%;
            border: 3px solid #fff;
            background: #dadbdb;
          }
        `
      : ''}
   padding: 4px;
  box-shadow: 0px 1px 18px ${schemes.darklucent['20']};
  ${Button} {
    display: grid;
    justify-content: initial;
    text-align: left;
    padding-left: 8px;
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
    flex-shrink: 0;
    width: 100%;
  }
`

export const DropdownSeparator = styled.hr`
  background-color: ${colors.stroke};
  width: calc(100% - 8px);
  margin: 5px 4px;
  height: 1px;
`
