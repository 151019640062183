import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { ButtonShowMore, TagsListContainer } from 'components/common/tags/tags-list.styles'

// ====================== TYPES
type TagsListProps = {
  children: React.ReactNode
  nbItemsCollapsable?: number
}

const TagsList = ({ children, nbItemsCollapsable = 0 }: TagsListProps): React.ReactElement => {
  const nbChildren = React.Children.count(children)
  const isCollapsable =
    nbItemsCollapsable !== null && nbItemsCollapsable > 0 && nbChildren > nbItemsCollapsable
  const [isCollapsed, setIsCollapsed] = React.useState(false)
  const handleOnClickShowMore = React.useCallback(
    e => {
      e.stopPropagation()
      setIsCollapsed(!isCollapsed)
    },
    [isCollapsed]
  )

  return (
    <TagsListContainer nbItemsCollapsable={nbItemsCollapsable} isCollapsed={isCollapsed}>
      {children}
      {isCollapsable && (
        <ButtonShowMore type="button" kind="discreet" onClick={handleOnClickShowMore}>
          {isCollapsed ? 'Less' : `${nbChildren - nbItemsCollapsable} more`}
          <Icon icon={isCollapsed ? 'chevron-up' : 'chevron-down'} />
        </ButtonShowMore>
      )}
    </TagsListContainer>
  )
}

export { TagsList }
