import { type NodeType } from 'com.batch/orchestration-journey/models/journey.records'

const buildId = (length: number): string => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

// maybe we could do better, having node type in the mix to help debugging
export const getNodeId = (nodeType: NodeType): string => {
  return `${nodeType}__${buildId(7)}`
}
