import * as React from 'react'

import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { InputWrapper, Input } from 'components/form'

import { Icon } from '../common/svg-icon'
import { type AppRecord } from 'com.batch.redux/_records'

type GlobalPopinIconProps = {
  app: AppRecord
  opened: boolean
  close: () => any
  fetchIcon: (app: AppRecord) => any
  error: string | null
  loading: boolean
}

const FetchIconData = {
  ios: {
    name: 'App Store',
    url: 'https://apps.apple.com/app/id',
    example: '46788790',
  },
  webpush: { name: 'website', url: 'https://www.google.com', example: '' },
  android: {
    name: 'Play Store',
    url: 'https://play.google.com/store/apps/details?id=',
    example: 'com.twitter.android',
  },
  windows: {
    name: 'Window Store',
    url: 'https://www.microsoft.com/fr-fr/p/app/',
    example: '9wzdncrfhx3w',
  },
  mobile: { name: '', url: '', example: '' },
  '': { name: '', url: '', example: '' },
}

export const GlobalPopinIcon = ({
  app,
  opened,
  close,
  fetchIcon,
  error,
  loading,
}: GlobalPopinIconProps): React.ReactElement => {
  const [bundleId, setBundleId] = React.useState<string>(
    app.bundleId
      ? app.platform !== 'webpush'
        ? FetchIconData[app.platform].url + app.bundleId
        : app.bundleId
      : ''
  )

  const inputRef = React.useRef<HTMLInputElement & HTMLTextAreaElement>(null)

  React.useEffect(() => {
    inputRef?.current?.focus()
  }, [opened])
  const onInputChange = React.useCallback(evt => setBundleId(evt.target.value), [])
  const onFetch = React.useCallback(
    () => fetchIcon(app.set('bundleId', bundleId)),
    [app, bundleId, fetchIcon]
  )
  return (
    <Popin opened={opened} close={close}>
      <Box>
        <BoxHeader>
          <HeaderBoxTitle title="Fetch icon from application store" />
          <HeaderBoxActions>
            <Button onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>

        <BoxBody $padding>
          <p style={{ marginBottom: 12 }} id="app-url">
            Enter your app URL on the {FetchIconData[app.platform].name} to fetch your icon.
          </p>
          <InputWrapper feedback={error}>
            <Input
              aria-labelledby="app-url"
              ref={inputRef}
              type="url"
              placeholder={FetchIconData[app.platform].url + FetchIconData[app.platform].example}
              value={bundleId}
              onChange={onInputChange}
              invalid={!!error}
            />
          </InputWrapper>
        </BoxBody>

        <BoxFooter isEditable>
          <Button kind="inline" intent="neutral" onClick={close}>
            Cancel
          </Button>
          <Button kind="primary" intent="action" isLoading={loading} onClick={onFetch}>
            Fetch icon
          </Button>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
