import * as React from 'react'

import { FilterCounterOptionContent, Counter } from './filter-counter-option.styles'

type FilterCounterOptionProps = {
  label: string | number
  counter: number | string
}
export const FilterCounterOption = ({
  label,
  counter,
}: FilterCounterOptionProps): React.ReactElement => {
  return (
    <FilterCounterOptionContent>
      {label}
      <Counter>{counter}</Counter>
    </FilterCounterOptionContent>
  )
}
