import * as React from 'react'
import styled from 'styled-components'

import { Icon } from 'components/common/svg-icon'

import { generateUrl } from 'com.batch.common/router'

import { SidebarAccount, Container as SidebarAccountContainer } from './sidebar-account'
import { SidebarAccountMobile } from './sidebar-account-mobile'
import {
  SidebarButtonStyles,
  SidebarResponsiveButtonStyles,
  PADDING,
  INNER_PADDING,
} from './sidebar.styles'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { type UserRecord } from 'com.batch.redux/user.records'

export type SidebarSettingsProps = {
  user: UserRecord
  company: CompanyRecord
  project: ProjectRecord | null | undefined
  isMobile: boolean
}

export const SidebarSettings = (props: SidebarSettingsProps): React.ReactElement => {
  const { user, company, isMobile, project } = props
  const permissions = user.permissionsForCurrentCompany.filterNot(perm => perm.startsWith('group:'))
  const editorialDashboardOnly = permissions.count() === 1 && permissions.has('editorial')
  const dashboardUrl = editorialDashboardOnly
    ? 'https://editorial.batch.com'
    : generateUrl('company_show', { companyId: company.id })

  return (
    <Container>
      {isMobile ? (
        <React.Fragment>
          <DashboardMobileButton href={generateUrl('dashboard')}>
            <Icon icon="return" />
          </DashboardMobileButton>
          <SidebarAccountMobile user={user} company={company} project={project} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SidebarAccount user={user} company={company} project={project} />
          <DashboardButton href={dashboardUrl}>
            <Icon icon="return" style={{ margin: '0 12px 0 -2px' }} />
            <span>Back to dashboard</span>
          </DashboardButton>
        </React.Fragment>
      )}
    </Container>
  )
}

export const DashboardMobileButton = styled.a`
  ${SidebarResponsiveButtonStyles}
`

export const DashboardButton = styled.a`
  ${SidebarButtonStyles};
  padding: 20px ${PADDING + INNER_PADDING}px 19px ${PADDING + INNER_PADDING}px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${SidebarAccountContainer} {
    flex: 1 1 auto;
  }

  @media (max-width: 980px) {
    flex-direction: row;
  }
`

export default SidebarSettings
