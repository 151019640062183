import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type ProfileAudienceRecord } from 'com.batch/audience/model/audience-profile.records'

type UpdateNameAudienceAction = ReduxAction<'UPDATE_AUDIENCE', string>

export type UpdateNameAudienceSuccessAction = ReduxAction<
  'UPDATE_AUDIENCE_SUCCESS',
  ProfileAudienceRecord
>
export type UpdateNameAudienceFailureAction = ReduxAction<'UPDATE_AUDIENCE_FAILURE', string>

export type UpdateNameAudienceActions =
  | UpdateNameAudienceAction
  | UpdateNameAudienceSuccessAction
  | UpdateNameAudienceFailureAction

export const updateAudience = ({
  audience,
}: {
  audience: ProfileAudienceRecord
}): DispatchExtraBoundFn<Promise<any>> => {
  return async (dispatch, getState, { audienceServiceApi }): Promise<any> => {
    const state = getState()
    const project = currentProjectSelector(state)
    const { name, displayName } = audience

    return promiseActionCreator({
      dispatch,
      payload: audience.name,
      promise: audienceServiceApi
        .updateAudience({
          projectKey: project.projectKey,
          name,
          displayName,
        })
        .then(
          () => audience,
          error => {
            throw { audience, error }
          }
        ),
      actionName: 'UPDATE_AUDIENCE',
    })
  }
}
