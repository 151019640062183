import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { FeedbackContainer } from './feedback.styles'

type FeedbackProps = {
  type?: 'success' | 'error' | 'loading' | 'insight' | 'warning'
  message?: string | React.ReactNode
  $fontWeight?: number
} & React.ComponentPropsWithoutRef<'div'>

const FeedbackRaw = ({ type = 'insight', message, ...rest }: FeedbackProps): React.ReactElement => {
  return (
    <FeedbackContainer _type={type} {...rest}>
      {type === 'loading' && <Icon icon="spinner" className="styled-feedback-icon" />}
      {message}
    </FeedbackContainer>
  )
}

const Feedback: React.ComponentType<FeedbackProps> = React.memo<FeedbackProps>(FeedbackRaw)

export { Feedback }
