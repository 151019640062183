import * as React from 'react'

import { PermissionButton } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { LinkDocumentation } from 'components/styled/text'

import { LegacyBannerContainer } from 'com.batch/settings/ui/components/settings-fcm/legacy-banner/legacy-banner.styles'

type Props = {
  onClick: () => void
  isAllowed: boolean
}

export const LegacyBanner = ({ onClick, isAllowed }: Props): React.ReactElement => {
  return (
    <LegacyBannerContainer>
      <Grid template="1fr auto" gap={16}>
        <p>
          Due to Firebase Cloud Messaging (FCM) APIs deprecation, effective in June 2024, updates on
          your push configuration is required to migrate from Legacy FCM Protocol to FCM v1 API
          settings.
        </p>
        <div>
          <LinkDocumentation
            intent="dark"
            href="https://doc.batch.com/android/prerequisites.html"
            target="_blank"
          >
            How-to guide
          </LinkDocumentation>
          <PermissionButton
            type="button"
            kind="secondary"
            intent="danger"
            onClick={onClick}
            isAllowed={isAllowed}
          >
            Update FCM credentials
          </PermissionButton>
        </div>
      </Grid>
    </LegacyBannerContainer>
  )
}
