import * as React from 'react'

type BlurValidationProps<T> = {
  value: T
  validator: (arg1: T) => boolean
  children: (arg1: { onBlur: () => void; invalid: boolean; value: T }) => React.ReactElement
}

export function BlurValidation<T>({
  children,
  validator,
  value,
  ...rest
}: BlurValidationProps<T>): React.ReactElement {
  // const [touched, setTouched] = React.useState(false)
  const [invalid, setInvalid] = React.useState(false)
  const onBlur = React.useCallback(() => setInvalid(!validator(value)), [validator, value])

  return children({ ...rest, onBlur, invalid, value })
}
