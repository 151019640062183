import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'

import { type FetchMonthlySentResult } from 'com.batch/account/infra/parses/parse-monthly-sent'

type FetchMonthlySentVolumesAction = ReduxAction<'FETCH_MONTHLY_SENT_VOLUMES', null>
type FetchMonthlySentVolumesSuccessAction = ReduxAction<
  'FETCH_MONTHLY_SENT_VOLUMES_SUCCESS',
  FetchMonthlySentResult
>
type FetchMonthlySentVolumesFailureAction = ReduxAction<
  'FETCH_MONTHLY_SENT_VOLUMES_FAILURE',
  string
>

export type FetchMonthlySentVolumesActions =
  | FetchMonthlySentVolumesAction
  | FetchMonthlySentVolumesSuccessAction
  | FetchMonthlySentVolumesFailureAction

export const fetchMonthlySentVolumes =
  ({
    projectKeys,
    month,
  }: {
    projectKeys: Array<string>
    month: string
  }): DispatchExtraBoundFn<Promise<any>> =>
  async (dispatch, getState, { dataService }) =>
    promiseActionCreator({
      dispatch,
      promise: dataService.getMonthlySentForBilling({
        projectKeys,
        month,
      }),
      actionName: 'FETCH_MONTHLY_SENT_VOLUMES',
    })
