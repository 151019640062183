import * as React from 'react'

import { Box, BoxBody, BoxFooter, FooterBoxActions } from 'components/common/box'
import { Button, ButtonLink } from 'components/common/button'
import { Form, InputWrapper, Input } from 'components/form'
import { Title } from 'components/styled/text'

import { type PushConfigRecord, type AppRecord } from 'com.batch.redux/_records'

type SettingsWindowsProps = {
  config: PushConfigRecord
  app: AppRecord
  checkWns: (packageId: string, secret: string) => any
  savePushConfig: (
    config: PushConfigRecord,
    file?: File | null | undefined,
    password?: string | null | undefined
  ) => Promise<void>
}

const SettingsWindows = ({ config, checkWns, savePushConfig }: SettingsWindowsProps) => {
  const [packageSID, setPackageSID] = React.useState<string>(
    config.wns ? config.wns.packageSID : ''
  )
  const [clientSecret, setClientSecret] = React.useState<string>(
    config.wns ? config.wns.clientSecret : ''
  )

  const canSave =
    clientSecret &&
    packageSID &&
    config.wns &&
    (packageSID !== config.wns.packageSID || clientSecret !== config.wns.clientSecret)

  const save = React.useCallback(() => {
    if (!canSave || !config.wns) return

    checkWns(packageSID, clientSecret).then(() => {
      if (!config.wns) return
      savePushConfig(
        config.set(
          'wns',
          config.wns.set('packageSID', packageSID).set('clientSecret', clientSecret)
        )
      )
    })
  }, [canSave, checkWns, clientSecret, config, packageSID, savePushConfig])
  const onPackageSIDChange = React.useCallback(evt => setPackageSID(evt.target.value), [])
  const onClientSecretChange = React.useCallback(evt => setClientSecret(evt.target.value), [])
  return (
    <Form onSubmit={save}>
      <Box>
        <BoxBody $padding>
          <Title>Setup your WNS configuration</Title>

          <p style={{ marginBottom: 24 }}>
            The Batch dashboard needs your package SID & client secret to obtain an OAuth token from
            WNS.
          </p>

          <InputWrapper label="Package SID" htmlFor="package-sid">
            <Input
              id="package-sid"
              type="text"
              name="public"
              value={packageSID}
              onChange={onPackageSIDChange}
            />
          </InputWrapper>

          <InputWrapper label="Client secret" htmlFor="client-secret">
            <Input
              id="client-secret"
              type="text"
              name="private"
              className="fs-exclude"
              value={clientSecret}
              onChange={onClientSecretChange}
            />
          </InputWrapper>
        </BoxBody>

        <BoxFooter>
          <FooterBoxActions>
            <ButtonLink
              kind="discreet"
              intent="action"
              href="https://doc.batch.com/windows/prerequisites.html"
            >
              Documentation
            </ButtonLink>
            <Button type="submit" kind="primary" intent="action" disabled={!canSave}>
              Save push settings
            </Button>
          </FooterBoxActions>
        </BoxFooter>
      </Box>
    </Form>
  )
}

export default React.memo<SettingsWindowsProps>(
  SettingsWindows
) as React.ComponentType<SettingsWindowsProps>
