import React from 'react'

/**
 * Permet de remonter le bouton intercom. Il sera reset à 20px lors de la destruction du component.
 * @param padding le nombre en pixel duquel il faut remonter le bouton
 * @param restorePadding le nombre en pixel auquel il faut replacer le bouton intercom qunand le composant est détruit
 */
export const useIntercomVerticalPadding = (padding: number, restorePadding: number = 20): void => {
  React.useEffect(() => {
    // Si intercom n'est pas dispo (e.g: adblocker)
    if (!window?.Intercom) return

    window?.Intercom('update', {
      // 20px est la valeur by default dans notre intercom
      vertical_padding: 20 + padding,
    })

    return () => {
      window?.Intercom('update', {
        vertical_padding: restorePadding + 20,
      })
    }
  }, [padding, restorePadding])
}
