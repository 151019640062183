import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { Button } from 'components/common/button'
import { PermissionButton } from 'components/common/button/permission-button'
import { Code } from 'components/common/code'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight, ConfirmWarning } from 'components/common/confirm.styles'
import { Icon } from 'components/common/svg-icon'
import { TableCell, TableCellActions, TableRow } from 'components/common/table/table.styles'
import { Input } from 'components/form'
import { Ellipsis } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { pluralize } from 'com.batch.common/utils'

import { updateLabel } from '../../usecases/update-label'

import { LabelFactory, type LabelRecord } from 'com.batch/labels/models/labels.records'
import { deleteLabel } from 'com.batch/labels/usecases/delete-label'

type RowProps = {
  label: LabelRecord
  index: number
}

export const Row = ({ label }: RowProps): React.ReactElement => {
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = React.useState(false)
  const [description, setDescription] = React.useState(label.description)

  const onDelete = React.useCallback(() => {
    confirm({
      sensitive: true,
      message: (
        <article>
          {label.orchestrationCount > 0 && (
            <ConfirmWarning>
              This label is currently used in {label.orchestrationCount} orchestration(s).
            </ConfirmWarning>
          )}
          <p>
            Are you sure you want to delete the label with code&nbsp;
            <ConfirmHighlight>{label.code}</ConfirmHighlight>?
          </p>
        </article>
      ),
      title: 'Delete this label?',
    }).then(
      () =>
        dispatch(deleteLabel(label)).catch(e => {
          // temporary error handling
          console.log('err', e)
        }),
      () => {}
    )
  }, [dispatch, label])

  const stopEditing = React.useCallback(() => {
    setIsEditing(false)
    setDescription(label.description)
  }, [label.description])

  const onSubmit = React.useCallback(() => {
    dispatch(
      updateLabel(
        LabelFactory({
          description,
          code: label.code,
          orchestrationCount: label.orchestrationCount,
        })
      )
    ).then(
      () => setIsEditing(false),
      () => {}
    )
  }, [description, dispatch, label])

  // This ensures that the description state always reflects the current description of the label
  React.useEffect(() => {
    setDescription(label.description)
  }, [label.description])

  const onInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.currentTarget.value)
  }, [])

  const onEdit = React.useCallback(() => {
    setIsEditing(true)
  }, [])

  return (
    <TableRow as={isEditing ? 'form' : ''} isEditing={isEditing} onSubmit={onSubmit}>
      {isEditing ? (
        <TableCell kind="input" style={{ overflowX: 'unset', paddingRight: '20px' }}>
          <Input
            autoFocus
            value={description}
            placeholder={label.description}
            onChange={onInputChange}
          />
        </TableCell>
      ) : (
        <TableCell style={{ overflow: 'visible', cursor: 'default' }}>
          <Ellipsis style={{ whiteSpace: 'pre' }} title={label.description}>
            {label.description}
          </Ellipsis>
        </TableCell>
      )}

      <TableCell>
        {label.orchestrationCount > 0 ? (
          pluralize('orchestration', label.orchestrationCount)
        ) : (
          <div style={{ color: colors.textDisabled }}>—</div>
        )}
      </TableCell>
      <TableCell>
        <Code size="small">{label.code}</Code>
      </TableCell>

      <TableCellActions>
        {isEditing ? (
          <React.Fragment>
            <Button
              style={{ width: 50 }}
              type="submit"
              isLoading={label.isMutating}
              disabled={label.isMutating || description === label.description}
              intent="action"
            >
              Save
            </Button>
            <Button onClick={stopEditing} type="button">
              <Icon icon="close" />
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PermissionButton kind="inline" intent="neutral" onClick={onEdit} isAllowed={true}>
              <Icon icon="edit" />
            </PermissionButton>
            <PermissionButton
              kind="inline"
              onClick={onDelete}
              isAllowed={true}
              isLoading={label.isMutating}
            >
              <Icon icon="delete" />
            </PermissionButton>
          </React.Fragment>
        )}
      </TableCellActions>
    </TableRow>
  )
}
