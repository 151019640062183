// ====================== LIBS
import * as React from 'react'

// ====================== COMPONENTS
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { Ellipse, NavigatorContainer } from './navigator.styles'
// ====================== DATA

// ====================== TYPES
type NavigatorProps = {
  nbResults: number
  currentResult: number
  prev: () => void
  next: () => void
}

type ResultProps = {
  id: number
  active: boolean
}

export const Navigator = ({
  nbResults,
  currentResult,
  prev,
  next,
}: NavigatorProps): React.ReactElement => {
  const results: ResultProps[] = React.useMemo(
    () =>
      Array(nbResults)
        .fill(0)
        .map((_, i) => ({
          id: i + 1,
          active: i + 1 === currentResult,
        })),
    [nbResults, currentResult]
  )

  const onKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft' && currentResult > 1) {
        prev()
      }
      if (event.key === 'ArrowRight' && currentResult < nbResults) {
        next()
      }
    },
    [currentResult, nbResults, next, prev]
  )

  React.useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return (
    <NavigatorContainer>
      <Button
        kind="discreetDark"
        intent="neutral"
        onClick={prev}
        addOn="prefix"
        disabled={currentResult === 1}
        style={{ paddingRight: 2 }}
      >
        <Icon icon="arrow-long-left" />
      </Button>

      {results.map(result => (
        <Ellipse key={result.id} style={{ marginRight: 2, marginLeft: 2 }} active={result.active} />
      ))}

      <Button
        kind="discreetDark"
        intent="neutral"
        onClick={next}
        addOn="prefix"
        style={{ paddingLeft: 10, paddingRight: 4 }}
        disabled={currentResult >= nbResults}
      >
        <Icon icon="arrow-long-right" />
      </Button>
    </NavigatorContainer>
  )
}
