import { useMultipleSelection, useCombobox } from 'downshift'
import Immutable, { type List } from 'immutable'
import { debounce } from 'lodash-es'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { useWidthObserver, usePopper, useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { SelectDropdown } from './select-dropdown'
import {
  RemovableValueButton,
  selectPopperConfig,
  type SelectMenuPropsType,
  type CommonSelectProps,
} from './select.helper'
import {
  SelectContainer,
  SelectField,
  SelectValueContainer,
  SelectInput,
  SelectClear,
  SelectPlaceholder,
} from './select.styles'

export type AutoCompleteMultiProps<T> = CommonSelectProps<T> & {
  value: List<T>
  loadOptions: (arg1: string) => Promise<List<T>>
  optionCreator?: (arg1: string) => T
  isClearable?: boolean
  onChange: (arg1: List<T>) => void
  invalid?: boolean
}

export function AutoCompleteMulti<T>({
  placeholder,
  forceOpen,
  isClearable,
  loadOptions,
  optionCreator,
  optionMenuStyle,
  optionMenuHeight,
  optionToString,
  onBlur,
  onFocus,
  menuOffset = 20,
  optionMenuShownCount,
  isDisabled,
  value,
  optionFormatter,
  onChange,
  ...rest
}: AutoCompleteMultiProps<T>): React.ReactElement {
  // popper config for menu placement
  const [triggerRef, popperRef, popperInstance] = usePopper(selectPopperConfig)
  const [query, setQuery] = React.useState('')
  // slection management -> we should only update picked options with this selectedItems array
  const {
    getSelectedItemProps,
    addSelectedItem,
    getDropdownProps,
    removeSelectedItem,
    selectedItems,
    reset,
  } = useMultipleSelection({
    initialSelectedItems: value.toArray(),
    onSelectedItemsChange: ({ selectedItems }) => {
      onChange(Immutable.List(selectedItems))
      popperInstance?.forceUpdate()
    },
  })

  const [suggestions, setSuggestions] = React.useState<List<T>>(Immutable.List())
  const focusedState = useToggle()
  const loadingState = useToggle()

  const removeDuplicates = React.useCallback(
    (suggestions: List<T>, values: List<T>) => {
      return suggestions.filter((item: T) => {
        const suggestionString = optionToString(item)
        const matchingValues = values.filter(opt => {
          const selectedValueString = optionToString(opt)
          return selectedValueString === suggestionString
        })
        return matchingValues.size === 0 && suggestionString !== ''
      })
    },
    [optionToString]
  )

  const loadOptionsDebounced = React.useRef(
    debounce(
      (q: string) => {
        loadingState.open()
        loadOptions(q).then(options => {
          loadingState.close()
          setSuggestions(options)
          popperInstance?.update()
        })
      },
      200,
      { trailing: true }
    )
  )
  const inputRef = React.useRef<HTMLInputElement>(null)
  const uniqueSuggestionsWithNew = React.useMemo(
    () =>
      removeDuplicates(
        optionCreator && query ? suggestions.push(optionCreator(query)) : suggestions,
        value
      ),
    [optionCreator, query, removeDuplicates, suggestions, value]
  )
  const {
    getMenuProps,
    isOpen,
    closeMenu,
    setHighlightedIndex,
    highlightedIndex,
    getItemProps,
    openMenu,
    getInputProps,
  } = useCombobox({
    items: uniqueSuggestionsWithNew.toArray(),
    itemToString: optionToString,
    inputValue: query,
    defaultInputValue: '',
    selectedItem: null,
    stateReducer(state: any, actionAndChanges: any) {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputFocus:
          return { ...changes, isOpen: false, inputValue: '' }
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return { ...changes, inputValue: '', isOpen: Boolean(changes.inputValue) }
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          return { ...changes, inputValue: '', isOpen: state.isOpen }
      }
      return changes
    },
    onIsOpenChange: changes => {
      if (changes.isOpen) {
        inputRef?.current?.focus()
        loadOptionsDebounced.current(inputRef.current?.value ?? '')
      } else {
        focusedState.close()
      }
      if (onBlur && !changes.isOpen) {
        onBlur()
      }
      if (onFocus && changes.isOpen) {
        onFocus()
      }
    },
    onStateChange({ inputValue, type, selectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            addSelectedItem(selectedItem)
          }
          setSuggestions(Immutable.List())
          setQuery('')
          break
        case useCombobox.stateChangeTypes.InputChange:
          setQuery(inputValue ?? '')
          loadOptionsDebounced.current(inputValue ?? '')
          setHighlightedIndex(0)
          break
        default:
          break
      }
    },
  })

  const theme = useTheme()
  const computedDisabled = React.useMemo(() => {
    return Boolean(isDisabled || theme?.disabledMode)
  }, [isDisabled, theme?.disabledMode])
  const showClearButton = React.useMemo(
    () => isClearable && value.size > 0 && !computedDisabled,
    [isClearable, value, computedDisabled]
  )
  const { innerRef, ...menuProps }: SelectMenuPropsType = getMenuProps({
    ref: popperRef,
    refKey: 'innerRef',
  })

  const onPaste = React.useCallback(
    evt => {
      const clipboardData = evt.clipboardData || window.clipboardData
      const pastedData = clipboardData.getData('Text')
      if (pastedData.includes(',') && pastedData.length > 4) {
        const newValues = pastedData
          .split(',')
          .map((str: string) => str.trim())
          .filter(Boolean)
        if (newValues.length > 2 && newValues.length < 64) {
          evt.preventDefault()
          const newItems = removeDuplicates(Immutable.List(newValues.map(optionCreator)), value)
          const valueList = value.concat(newItems)
          onChange(valueList)
          newItems.forEach(item => addSelectedItem(item))
        }
      }
    },
    [addSelectedItem, onChange, optionCreator, value, removeDuplicates]
  )
  const inputProps = getDropdownProps(
    getInputProps({
      ref: inputRef,
      type: 'search',
      disabled: isDisabled,
      autoFocus: rest?.autoFocus ?? false,
      onPaste,
      onFocus: () => {
        inputRef.current?.select()
        focusedState.open()
        if (!isOpen) {
          popperInstance?.update()
          openMenu()
        }
      },
    })
  )

  const width = useWidthObserver(triggerRef, 200)

  const handleSelection = React.useCallback(
    selectedItem => (evt: any) => {
      evt.stopPropagation()
      removeSelectedItem(selectedItem)
      onChange(value.filter(option => option !== selectedItem))
    },
    [onChange, removeSelectedItem, value]
  )

  const isOptionSelected = React.useCallback((option: T) => value.includes(option), [value])

  const onClearButton = React.useCallback(
    evt => {
      evt?.stopPropagation()
      reset()
      closeMenu()
    },
    [closeMenu, reset]
  )
  React.useLayoutEffect(() => {
    if (isOpen) {
      popperInstance?.setOptions(options => ({
        ...options,
        modifiers: [...(options.modifiers ?? []), { name: 'eventListeners', enabled: true }],
      }))
    } else {
      popperInstance?.setOptions(options => ({
        ...options,
        modifiers: [...(options.modifiers ?? []), { name: 'eventListeners', enabled: false }],
      }))
    }
  }, [isOpen, popperInstance])
  return (
    <SelectContainer
      {...rest}
      ref={triggerRef}
      isFocused={focusedState.value || isOpen}
      isDisabled={Boolean(isDisabled)}
    >
      <SelectField template={`minmax(100px, 1fr) ${showClearButton ? '30px' : ''}`} gap={0}>
        <SelectValueContainer isMulti onClick={openMenu} hasValue={value.size > 0}>
          {value.size > 0
            ? selectedItems.map((selectedItem, index) => (
                <RemovableValueButton
                  key={index}
                  {...getSelectedItemProps({ selectedItem, index })}
                  onClick={handleSelection(selectedItem)}
                >
                  {!optionFormatter
                    ? optionToString(selectedItem)
                    : optionFormatter(selectedItem, { context: 'value' })}
                </RemovableValueButton>
              ))
            : !query && (
                <SelectPlaceholder isDisabled={Boolean(isDisabled)}>
                  {placeholder}
                </SelectPlaceholder>
              )}

          <SelectInput
            {...inputProps}
            isOpen={isOpen || focusedState.value || forceOpen}
            isSearchable={focusedState.value}
            isMulti={value.size > 0}
            style={{ left: 0 }}
            isEmpty={value.size === 0}
          />
        </SelectValueContainer>
        {showClearButton && (
          <SelectClear onClick={onClearButton}>
            <Button kind="discreet" style={{ height: 34 }}>
              <Icon icon="close" />
            </Button>
          </SelectClear>
        )}
      </SelectField>
      <SelectDropdown
        {...menuProps}
        innerRef={innerRef}
        width={width + menuOffset}
        optionMenuShownCount={optionMenuShownCount}
        getItemProps={getItemProps}
        optionMenuStyle={optionMenuStyle}
        optionMenuHeight={optionMenuHeight}
        optionToString={optionToString}
        optionFormatter={optionFormatter}
        options={uniqueSuggestionsWithNew}
        isOpen={isOpen || !!forceOpen}
        highlightedIndex={highlightedIndex}
        setHighlightedIndex={setHighlightedIndex}
        isOptionSelected={isOptionSelected}
      />
    </SelectContainer>
  )
}
