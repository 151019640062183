import { type Dayjs } from 'dayjs'
import * as React from 'react'

import { TextEmpty } from 'components/styled/text'

import { verboseDateTime } from 'com.batch.common/date-formats'

import { AttributeRow } from 'com.batch/profile/ui/components/attributes-block/attribute-row'

type DateAttributeProps = {
  name: string
  value: Dayjs
  isCopyable?: boolean
}

export const DateAttributeRow = ({
  name,
  value,
  isCopyable = true,
}: DateAttributeProps): React.ReactElement => {
  const isValidDate = React.useMemo(() => value.isValid(), [value])
  return (
    <AttributeRow
      name={name}
      icon="date"
      copyableValue={isValidDate ? value.utc().format('YYYY-MM-DDTHH:mm:ss[Z]') : 'Invalid date'}
      copyableNotification={`${name} copied`}
      isCopyable={isCopyable}
    >
      {isValidDate ? value.format(verboseDateTime) : <TextEmpty>Invalid date</TextEmpty>}
    </AttributeRow>
  )
}
