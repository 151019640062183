import { css } from 'styled-components'

import { colors } from 'components/styled/tokens'
import * as schemes from 'components/styled/tokens/schemes'
import { type BorderType, CustomBorder } from 'components/styled/utils'

export const SIDEBAR_WIDTH = 300

export const PADDING = 12
export const MOBILE_PADDING = 20
export const INNER_PADDING = 12

export const CURRENT_HEIGHT = 58
export const USER_HEIGHT = 80

export const RESPONSIVE_HEIGHT = 64

export const SidebarButtonStyles = css`
  display: flex;
  align-items: center;
  color: ${colors.textLight};
  cursor: pointer;
  border-top: 1px solid ${colors.stroke};

  &:hover,
  &:active {
    color: ${colors.text};
    background: rgba(50, 54, 57, 0.05);
  }
  &:active {
    background: rgba(50, 54, 57, 0.02);
  }
`

export const SidebarResponsiveButtonStyles = css`
  @media (max-width: 980px) {
    display: flex;
    align-items: center;
    position: relative;
    height: ${RESPONSIVE_HEIGHT}px;
    padding: 0 ${MOBILE_PADDING}px;
    color: ${colors.textLight};
    box-shadow: ${(p: { border?: BorderType; opened?: boolean }) =>
      p.opened ? `0 0 8px ${colors.opacifyDefault}` : 'none'};
    cursor: pointer;

    ${(p: { border?: BorderType; opened?: boolean }) =>
      p.border &&
      CustomBorder(p.border, {
        color: p.opened ? schemes.darklucent['05'] : colors.stroke,
        offset: p.opened ? 0 : 16,
      })}

    &:hover {
      color: ${colors.text};
    }
  }
`
