import * as React from 'react'

import { EmptyField } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { getOffsetForTimezone } from 'com.batch.common/get-offset-for-timezone'

import {
  ProfileHeroUtcContainer,
  ProfileTooltip,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

type Props = {
  timezone: string | null | undefined
  isLoading: boolean
}

export const ProfileUtc = ({ timezone, isLoading }: Props): React.ReactElement | null => {
  if (isLoading) return <EmptyField _width={60} />
  if (timezone)
    return (
      <Tooltip
        placement="bottom"
        tooltip={
          <ProfileTooltip>
            <div className="profile-tooltip-title">Timezone</div> <div>{timezone}</div>
          </ProfileTooltip>
        }
      >
        <ProfileHeroUtcContainer>
          <Icon icon="timer" />
          <span>{getOffsetForTimezone(timezone)}</span>
        </ProfileHeroUtcContainer>
      </Tooltip>
    )
  return null
}
