import Immutable from 'immutable'
import * as React from 'react'

import DateTimePicker from 'components/common/date-time-picker'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { OptionPcent } from 'components/common/option-row-pcent'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { SelectMulti, Select } from 'components/form'
import AgePicker, { type allowedAgePickerUnits } from 'components/targeting/age-picker'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  type AttributeValueProps,
  type AttributeRecord,
  AttributeValueFactory,
} from 'com.batch.redux/_records'

const MAX_OPTIONS = 64
const ALLOWED_OSES = ['Android', 'Windows', 'macOS', 'Linux', 'Chrome OS']
const ALLOWED_BROWSERS = [
  'Chrome',
  'Firefox',
  'Edge',
  'Opera',
  'SamsungBrowser',
  'Vivaldi',
  'Safari',
]
const FLStyle = { minHeight: '32px' }
const unitsDayOnly: Array<allowedAgePickerUnits> = ['d']
const unitsDayHours: Array<allowedAgePickerUnits> = ['h', 'd']

type ConditionValue = any

type ConditionInputProps = {
  inputType: string
  value: ConditionValue
  loading: boolean
  attribute: AttributeRecord
  attributeValues: Array<AttributeValueProps>
  onValueChanged: (value?: any) => void
  onNumberValueChanged: (arg1: React.ChangeEvent<HTMLInputElement>) => void
  onInputValueChanged: (arg1: React.ChangeEvent<HTMLInputElement>) => void
  onPeriodChanged: (value: string) => void
  functionName: string | null | undefined
  period: string | null | undefined
  valid: boolean
}

interface AttributeOption {
  value: string
  pretty?: string
  pcent: string
  installs: number
  tokens: number
  opacity: number | null | undefined
}

export const ConditionInput = ({
  inputType,
  value,
  onNumberValueChanged,
  onInputValueChanged,
  onValueChanged,
  onPeriodChanged,
  attribute,
  loading,
  functionName,
  attributeValues,
  period,
  valid,
}: ConditionInputProps): React.ReactElement => {
  const classes = `condi__builder__value condi__builder__value--${inputType}`
  const hasReachedMaxOptions = React.useMemo(
    () => Array.isArray(value) && value.length >= MAX_OPTIONS,
    [value]
  )
  const optionCreator = React.useCallback(
    (txt: string) =>
      attribute.native || hasReachedMaxOptions
        ? null
        : AttributeValueFactory({
            value: txt,
            pretty: txt,
          }),
    [attribute.native, hasReachedMaxOptions]
  )
  const optionsList = React.useMemo(
    () =>
      Immutable.List(
        attributeValues
          .filter(v => {
            if (typeof v.value === 'string') {
              if (attribute.id === 'b.browser_model') return ALLOWED_BROWSERS.includes(v.value)
              if (attribute.id === 'b.os_model') return ALLOWED_OSES.includes(v.value)
            }
            return true
          })
          .map(opt => ({ ...opt, value: String(opt.value) }))
      ),
    [attribute.id, attributeValues]
  )

  switch (inputType) {
    case 'datepicker':
      return (
        <div className={classes}>
          {/* $FlowExpectedError can't type this */}
          <DateTimePicker value={dayjs.isDayjs(value) ? value : null} update={onValueChanged} />
        </div>
      )

    case 'agepicker':
      return (
        <div className={classes}>
          <FlexLine style={FLStyle}>
            <FlexLineItem>
              <AgePicker
                key={functionName}
                age={typeof value === 'string' ? value : ''}
                autofocus={!valid}
                defaultUnit="d"
                allowedUnits={
                  functionName === 'nextBirthday' || functionName === 'lastBirthday'
                    ? unitsDayOnly
                    : unitsDayHours
                }
                negative={
                  functionName === 'nextBirthday' ||
                  functionName === 'lastBirthday' ||
                  functionName === 'age'
                }
                onChange={onValueChanged}
              />
            </FlexLineItem>
            {(functionName === 'ageLastEvent' ||
              functionName === 'lastBirthday' ||
              functionName === 'age') && <FlexLineItem>ago</FlexLineItem>}
          </FlexLine>
        </div>
      )
    case 'multi': {
      /* we need a List of AttributeRecord, using the one from optionsList when available,
        but keeping the missing ones from value
      */
      const cleanValue = Immutable.List().push(
        ...(!Array.isArray(value)
          ? []
          : value.map(hopefullyAttributeValueRecord => {
              const valueString = String(hopefullyAttributeValueRecord.value)
              const foundInOptionsList = optionsList.find(option => option.value === valueString)
              return foundInOptionsList ? foundInOptionsList : hopefullyAttributeValueRecord
            }))
      )
      return (
        <div className={classes}>
          <SelectMulti
            isLoading={loading}
            value={cleanValue}
            optionCreator={optionCreator}
            placeholder="pick some options"
            optionFormatter={OptionPcent}
            onChange={opt => {
              onValueChanged(opt.toArray())
            }}
            optionToString={(opt: AttributeOption) => {
              return String(opt?.pretty ?? '')
            }}
            options={optionsList}
          />
        </div>
      )
    }
    case 'boolean':
      return (
        <div className={classes}>
          <TableToggle width={160}>
            <TableToggleItem active={value === true} onClick={() => onValueChanged(true)}>
              YES
            </TableToggleItem>
            <TableToggleItem active={value === false} onClick={() => onValueChanged(false)}>
              NO
            </TableToggleItem>
          </TableToggle>
        </div>
      )
    case 'text': {
      const options = attributeValues
      if (Array.isArray(options) && !Array.isArray(value) && !!value) {
        let found = false
        options.forEach(opt => {
          if (
            typeof value === 'object' &&
            typeof value.value !== 'undefined' &&
            opt.value === value.value
          ) {
            found = true
          }
        })

        if (
          typeof value === 'object' &&
          typeof value.value !== 'undefined' &&
          !Array.isArray(value) &&
          !found
        ) {
          options.push(value)
        }
      }
      const lookForValue =
        value && typeof value === 'object' && !Array.isArray(value) && value.value
          ? value.value
          : null

      return (
        <div className={classes}>
          <Select
            optionCreator={optionCreator}
            placeholder="Pick a value"
            options={Immutable.List(attributeValues)}
            optionToString={opt => String(opt?.value ?? '')}
            optionFormatter={opt => opt?.pretty ?? ''}
            onChange={onValueChanged}
            value={attributeValues.find(av => av.value === lookForValue)}
          />
        </div>
      )
    }
    default:
      return (
        <div className={classes}>
          <FlexLine style={FLStyle}>
            <FlexLineItem
              grow={inputType === 'number' ? 0 : 1}
              width={functionName === 'countSince' ? 80 : 90}
            >
              <input
                type={inputType === 'number' ? 'number' : 'text'}
                autoFocus={!valid}
                style={{
                  maxWidth: inputType === 'number' ? '100px' : '220px',
                }}
                onChange={inputType === 'number' ? onNumberValueChanged : onInputValueChanged}
                className="form-control"
                placeholder={inputType === 'float' ? 'Decimal number' : inputType}
                min={functionName ? 0 : -9999999999}
                value={value !== null ? value : ''}
              />
            </FlexLineItem>
            {(functionName === 'count' || functionName === 'countSince') && (
              <FlexLineItem width={40}>
                time
                {parseInt(value) && parseInt(value) > 1 ? 's' : ''}
              </FlexLineItem>
            )}
            {functionName === 'countSince' && <FlexLineItem width={75}>in the last</FlexLineItem>}
            {functionName === 'countSince' && (
              <FlexLineItem width={190}>
                <AgePicker
                  autofocus={!valid}
                  age={period ? period : ''}
                  defaultUnit="d"
                  allowedUnits={unitsDayHours}
                  negative={false}
                  onChange={onPeriodChanged}
                />
              </FlexLineItem>
            )}
          </FlexLine>
        </div>
      )
  }
}
