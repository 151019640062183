import styled from 'styled-components'

type GridProps = {
  template: string
  gap?: number
  margin?: Array<number>
  alignItems?: 'start' | 'end' | 'center' | 'stretch'
}

export const Grid = styled.div<GridProps>`
  display: grid;
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  grid-column-gap: ${props => (typeof props.gap === 'number' ? props.gap : 10)}px;
  grid-template-columns: ${props => props.template};
  margin: ${props => (props.margin ? props.margin.map(m => `${m}px`).join(' ') : 0)};
`

export const FormGrid = styled(Grid).attrs<GridProps>({
  as: 'form',
})``
