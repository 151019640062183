import { useParams } from 'react-router-dom'

const coerceSchedulingType = (t?: string | null): schedulingType => {
  switch (t) {
    case 'campaigns':
      return 'campaigns'
    case 'automations':
      return 'automations'
    default:
      return 'automations'
  }
}

const coerceCampaignType = (t?: string | null): campaignType => {
  switch (t) {
    case 'push':
      return 'push'
    case 'in-app':
      return 'in-app'
    default:
      return 'push'
  }
}

export const useCampaignInfoFromRouter = (): {
  campaignType: campaignType
  schedulingType: schedulingType
  token: string
} => {
  const params = useParams()
  const campaignType = coerceCampaignType(params.campaignType)
  const schedulingType = coerceSchedulingType(params.schedulingType)
  const token = typeof params.token === 'string' ? params.token : 'new'
  return { campaignType, schedulingType, token }
}
