import { type Set, type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { Filter } from 'components/campaign/filters/filter'
import { Icon } from 'components/common/svg-icon'
import { FilterSelectMulti } from 'components/filter'
import * as schemes from 'components/styled/tokens/schemes'

import { pluralize } from 'com.batch.common/utils'

import { labelsSelector } from 'com.batch/labels/store/labels.selector'

import { type LabelRecord } from 'com.batch/labels/models/labels.records'

type LabelsFilterProps = {
  value: Set<string>
  onChange: (arg1: Set<string>) => void
}

export const LabelsFilter = ({ value, onChange }: LabelsFilterProps): React.ReactElement => {
  const { labels, loadingState } = useSelector(labelsSelector)

  const onSelect = React.useCallback(
    (values: List<LabelRecord>) => {
      onChange(values.map(label => label.code).toSet())
    },
    [onChange]
  )

  const valueAsLabelRecord = React.useMemo(
    () => value.map(code => labels.find(label => label.code === code)),
    [value, labels]
  )

  const formatTerm = React.useCallback((count: number) => pluralize('label', count), [])

  const optToString = React.useCallback((opt?: LabelRecord | null) => opt?.description ?? '', [])

  const isLoading = React.useMemo(
    () => loadingState === 'LOADING' || loadingState === 'INIT',
    [loadingState]
  )

  const searchMatch = React.useCallback(
    (term: string, label: LabelRecord) => {
      const words = term.toLowerCase().split(' ')
      return (
        words.reduce(
          (nbMatch, word) => nbMatch + (label.description.toLowerCase().includes(word) ? 1 : 0),
          0
        ) === words.length
      )
    },

    []
  )

  return (
    <Filter title="Labels">
      <FilterSelectMulti
        autoFocus={false}
        isDisabled={isLoading}
        options={labels}
        value={valueAsLabelRecord.toList()}
        onChange={onSelect}
        optionToString={optToString}
        placeholder="All labels"
        noResultsNode={<NoResultsView />}
        isSearchable
        searchMatchFunction={searchMatch}
        term={formatTerm}
        menuOffset={120}
      />
    </Filter>
  )
}

const NoResultsView = (): React.ReactElement => (
  <div>
    <Icon icon="no-result" style={{ marginRight: '6px' }} />
    No labels to display
  </div>
)
