import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { generateUrl } from 'com.batch.common/router'

import {
  profileDataStateSelector,
  overviewPlatformsSelector,
  mobilePlatformsSelector,
  webpushPlatformsSelector,
} from 'com.batch/profile/store/profile.selector'
import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type Platform } from 'com.batch/profile/infra/debug/models/shared.model'
import { type IdentityRecord } from 'com.batch/profile/models/profile.records'
import { ProfileHeroBody } from 'com.batch/profile/ui/components/profile-hero/profile-hero-body'
import { ProfileHeroFooter } from 'com.batch/profile/ui/components/profile-hero/profile-hero-footer'
import { ProfileHeroHeader } from 'com.batch/profile/ui/components/profile-hero/profile-hero-header'
import { ProfileHeroContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { CollapsePanel } from 'com.batch/shared/ui/component/collapse/collapse'

type Props = {
  identity: IdentityRecord
  isLoading: boolean
  profileId: string
  isRefresh: boolean
  dataLoaded: boolean
  hasError: boolean
}

export const ProfileHero = ({
  isLoading,
  identity,
  profileId,
  isRefresh,
  dataLoaded,
  hasError,
}: Props): React.ReactElement => {
  const profileData = useSelector(profileDataStateSelector)
  const apps = useSelector(currentProjectAppsSelector)
  const project = useSelector(currentProjectSelector)
  const overviewPlatforms = useSelector(overviewPlatformsSelector)
  const mobilePlatforms = useSelector(mobilePlatformsSelector)
  const webpushPlatforms = useSelector(webpushPlatformsSelector)

  const [isBodyShown, setIsBodyShown] = React.useState<boolean>(false)
  const [profilePhoneNumber, setProfilePhoneNumber] = React.useState('')

  const findDebugUrl = React.useMemo(
    () => (installId: string, platform: Platform) =>
      generateUrl('app_settings', {
        appId: apps.find(app => app.platform === platform.toLowerCase())?.id,
        companyId: project.companyId,
        activeTab: 'debug',
        installation_id: installId,
      }),
    [apps, project.companyId]
  )

  React.useEffect(() => {
    if (profileData.phoneNumber)
      import(
        /* webpackChunkName: "libphonenumber" */
        /* webpackMode: "lazy" */
        'libphonenumber-js'
      )
        .then(({ parsePhoneNumber }) => {
          setProfilePhoneNumber(parsePhoneNumber(profileData.phoneNumber).formatInternational())
        })
        .catch(error => {
          console.error(error)
          setProfilePhoneNumber('')
        })
    setProfilePhoneNumber('')
  }, [profileData.phoneNumber])

  const handleOnToggle = React.useCallback((isToggled: boolean) => {
    setIsBodyShown(isToggled)
  }, [])

  React.useEffect(() => {
    if ((dataLoaded && !isRefresh) || hasError) {
      setIsBodyShown(false)
    }
  }, [dataLoaded, isRefresh, hasError])

  return (
    <ProfileHeroContainer>
      <ProfileHeroHeader
        isLoading={isLoading}
        identity={identity}
        email={profileData.email}
        profileId={profileId}
        timezone={profileData.timezone}
        lastVisitDate={profileData.lastVisit.date}
      />
      <CollapsePanel isOpened={isBodyShown} id="profile-hero">
        <ProfileHeroBody
          mobilePlatforms={mobilePlatforms}
          webpushPlatforms={webpushPlatforms}
          platformsCount={overviewPlatforms.length}
          email={profileData.email}
          phoneNumber={profilePhoneNumber}
          subscription={profileData.subscription}
          isLoading={isLoading}
          findDebugUrl={findDebugUrl}
        />
      </CollapsePanel>
      <ProfileHeroFooter
        isBodyShown={isBodyShown}
        onToggle={handleOnToggle}
        email={profileData.email}
        phoneNumber={profilePhoneNumber}
        platforms={overviewPlatforms}
      />
    </ProfileHeroContainer>
  )
}
