import * as React from 'react'

import { confirm } from 'components/common/confirm'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Copy } from 'components/form'

import { type UserRecord } from 'com.batch.redux/user.records'

export const deleteConfirm = (user: UserRecord): Promise<boolean> => {
  return confirm({
    title: `Are you sure you want to delete ${user.email}?`,
    sensitive: true,
    confirm: `Yes, I want to delete ${user.firstName}`,
    message: (
      <React.Fragment>
        <p>
          You are about to delete{' '}
          <strong style={{ textTransform: 'capitalize' }}>
            {user.firstName} {user.lastName}
          </strong>
          :
        </p>
        <FlexLine style={{ margin: '10px 0' }}>
          <FlexLineItem grow={1}>
            <Copy value={user.email} />
          </FlexLineItem>
          <FlexLineItem width={130}>
            <Copy value={user.id ? user.id.toString() : 'na'} />
          </FlexLineItem>
        </FlexLine>
        <p>
          In most cases, you'll also want to delete the user from{' '}
          <a href={`https://app.intercom.io/apps/ekaza78m/users/show?email=${user.email}`}>
            Intercom
          </a>{' '}
          and{' '}
          <a href="https://us8.admin.mailchimp.com/lists/members/unsubscribe-form?id=413081">
            MailChimp
          </a>
          .
        </p>
      </React.Fragment>
    ),
  })
}
