import * as React from 'react'

import { Button, DropdownMenu, useDropdown } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { ucFirst } from 'com.batch.common/utils'

type LogicalToggleProps = {
  value: 'and' | 'or'
  onChange: (arg1: 'and' | 'or') => void
  style: any
}

const activeStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.02)',
  color: colors.textNeutral as string,
}

const LogicalToggleRaw = ({ value, onChange, style }: LogicalToggleProps): React.ReactElement => {
  const { triggerProps, dropdownProps, closeDropdown } = useDropdown({ placement: 'bottom' })

  const onChangeAnd = React.useCallback(() => {
    closeDropdown()
    onChange('and')
  }, [closeDropdown, onChange])

  const onChangeOr = React.useCallback(() => {
    closeDropdown()
    onChange('or')
  }, [closeDropdown, onChange])

  return (
    <React.Fragment>
      <Button
        {...triggerProps}
        kind="primary"
        style={{
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          height: 20,
          paddingLeft: 4,
          paddingRight: 3,
          marginTop: -10,
          fontSize: 12,
          justifyContent: 'flex-start',
          position: 'absolute',
          ...style,
        }}
      >
        <Grid template="14px 10px">
          {ucFirst(value)}
          <Icon icon="select" size={9} />
        </Grid>
      </Button>
      <DropdownMenu {...dropdownProps}>
        <Button addOn="suffix" onClick={onChangeAnd} style={value === 'and' ? activeStyle : {}}>
          And
          {value === 'and' && <Icon icon="check" />}
        </Button>
        <Button addOn="suffix" onClick={onChangeOr} style={value === 'or' ? activeStyle : {}}>
          Or
          {value === 'or' && <Icon icon="check" />}
        </Button>
      </DropdownMenu>
    </React.Fragment>
  )
}
export const LogicalToggle = React.memo(LogicalToggleRaw) as React.ComponentType<LogicalToggleProps>
