import { List } from 'immutable'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentAppSelector } from 'com.batch.redux/app'

import { parseFcmConfig } from 'com.batch/settings/infra/parses/parse-fcm-config'
import { type ServiceAccountKeyRaw } from 'com.batch/settings/infra/types/fcm-config-raw'
import { validateFcmConfig } from 'com.batch/settings/infra/validate/validate-fcm-config'
import { type FCMConfigRecord } from 'com.batch/settings/models/fcm-config.records'

export const getFCMConfigFromFile = async (
  file: File,
  configs: List<FCMConfigRecord>
): Promise<FCMConfigRecord> => {
  return new Promise((resolve, reject: (error?: any) => void) => {
    if (file.type !== 'application/json') {
      reject({ error: 'Not a valid file type' })
    }
    file
      .text()
      .then((rawJSON: string) => {
        try {
          const jsonContent: ServiceAccountKeyRaw = JSON.parse(rawJSON)
          const validationError = validateFcmConfig(jsonContent)
          if (validationError) {
            reject(validationError)
          }
          const fcmConfig = parseFcmConfig(jsonContent)
          configs.forEach(config => {
            if (
              config.serviceAccountKey.privateKeyId === fcmConfig.serviceAccountKey.privateKeyId
            ) {
              reject({ error: 'This Service Account Key already exist' })
            }
          })
          resolve(fcmConfig)
        } catch (e: any) {
          console.error(e)
          reject({ error: 'Invalid JSON file content' })
        }
      })
      .catch(e => {
        console.error(e)
        reject({ error: 'Invalid JSON file content' })
      })
  })
}

export const getFCMConfig = (
  file: File,
  configs: List<FCMConfigRecord>
): DispatchExtraBoundFn<Promise<FCMConfigRecord>> => {
  return async (dispatch, getState, { settingsServiceApi }): Promise<any> => {
    const state = getState()
    const app = currentAppSelector(state)
    const fcmConfig = await getFCMConfigFromFile(file, configs)
    return promiseActionCreator({
      dispatch,
      promise: settingsServiceApi
        .checkFcm({
          appId: app.id,
          fcm: fcmConfig,
        })
        .then(
          () => fcmConfig,
          error => {
            throw { error }
          }
        ),
      actionName: 'CHECK_FCM',
    })
  }
}
