import Immutable from 'immutable'
import req from 'superagent-interface-promise'

import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import {
  type AudienceServiceApiInterface,
  type fetchAudienceListResponse,
} from './audience-service.interface'
import { parseProjectAudienceList } from './parses/parse-audience-profile'
import { type AudiencesModel, type grpcAudienceListResponse } from './types/grpc-audience-service'

import { fetchPaginatedEntities } from 'com.batch/shared/infra/fetch-paginated-entities'
import { generateProjectKeyBlockRequest } from 'com.batch/shared/infra/generate-block-request'
import { ProfileAudienceRecord } from '../model/audience-profile.records'

type AudienceListRequest = {
  sortDirection: 'ASC' | 'DESC'
  filter: string
  sortField: string
}

export const audienceServiceApi: AudienceServiceApiInterface = {
  fetchAudiencesList: async ({
    projectKey,
    page,
    pageSize,
    orderDirection,
    orderBy,
    search,
    abortSignal,
    currentCount,
  }): Promise<fetchAudienceListResponse> => {
    const fieldSearch =
      orderBy === 'name'
        ? 'NAME'
        : orderBy === 'display_name'
          ? 'DISPLAY_NAME'
          : orderBy === 'nb_ids'
            ? 'NB_IDS'
            : orderBy === 'type'
              ? 'TYPE'
              : 'UPDATED'

    const fetcher = async ({ page, pageSize }: { page: number; pageSize: number }) => {
      const audienceListRequest: AudienceListRequest = {
        filter: search,
        sortField: fieldSearch,
        sortDirection: orderDirection === 'asc' ? 'ASC' : 'DESC',
      }
      const response = await request.post<grpcAudienceListResponse>(
        generateUrl('api_grpc_audience_service', {
          methodName: 'List',
        }),
        {
          ...generateProjectKeyBlockRequest(projectKey),
          ...audienceListRequest,
          page,
          pageSize,
        },
        abortSignal
      )
      return { entities: response.audiences, total: response.count, totalMatching: response.count }
    }

    const { entities, total: totalResult } = await fetchPaginatedEntities<AudiencesModel>({
      total: currentCount,
      totalMatching: currentCount,
      page,
      pageSize,
      fetcher,
    })

    return {
      entities: Immutable.List<ProfileAudienceRecord>().push(
        ...(entities?.map(parseProjectAudienceList) ?? [])
      ),
      count: totalResult ?? 0,
    }
  },
  saveAudience: ({ projectKey, name, identifierType, displayName, file, onProgress }) => {
    try {
      const formData = new FormData()
      formData.append('projectKey', projectKey)
      formData.append('name', name)
      formData.append('displayName', displayName)
      formData.append('identifierType', identifierType)
      if (file) formData.append('file', file)

      return req
        .post(
          generateUrl('api_project_audience_create_update', {
            projectKey,
          })
        )
        .send(formData)
        .on('progress', p => {
          if (onProgress) onProgress(p.percent)
        })
    } catch (e: any) {
      throw e.error.body.errors[0].message ?? 'Unknown error'
    }
  },

  updateAudience: async ({ projectKey, name, displayName }): Promise<any> => {
    try {
      await request.post<undefined>(
        generateUrl('api_grpc_audience_service', {
          methodName: 'Update',
        }),
        {
          ...generateProjectKeyBlockRequest(projectKey),
          name,
          displayName,
        }
      )
    } catch (e: any) {
      throw e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },

  deleteAudience: async ({ projectKey, name }) => {
    try {
      await request.post<undefined>(
        generateUrl('api_grpc_audience_service', {
          methodName: 'Remove',
        }),
        {
          ...generateProjectKeyBlockRequest(projectKey),
          name: name,
        }
      )
    } catch (e: any) {
      throw e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  findAudiencesByName: async ({ projectKey, namesAndIdx }): Promise<any> => {
    const response = await request.post<{
      audience: Array<AudiencesModel>
    }>(
      generateUrl('api_grpc_audience_service', {
        methodName: 'View',
      }),
      {
        ...generateProjectKeyBlockRequest(projectKey),
        namesAndIdx,
      }
    )

    return Immutable.List((response.audience?.map(parseProjectAudienceList) ?? []))
  },
}
