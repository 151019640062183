import * as React from 'react'
import styled from 'styled-components'

import { Box } from 'components/common/box'
import { Wrapper } from 'components/common/empty-states'
import { colors } from 'components/styled/tokens'

import Metric from './metric'

import { type summaryData } from '../review.data.selectors'
import { type CampaignRecord } from 'com.batch.redux/_records'

const Summary = ({
  data,
  campaign,
}: {
  data: summaryData
  campaign: CampaignRecord
}): React.ReactElement => {
  const [hidden, toggleHidden] = React.useState(true)
  const toggle = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'h') toggleHidden(!hidden)
    },
    [hidden, toggleHidden]
  )
  React.useEffect(() => {
    document.addEventListener('keydown', toggle)
    return () => document.removeEventListener('keydown', toggle)
  })

  return (
    <Box>
      <Wrapper
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
        isEmpty={data.empty}
        isLoading={data.loading}
        isOverlayShown={!data.loading && data.empty}
        overlayProps={{
          status: 'empty',
          title: 'No data for this campaign.',
          description:
            campaign.state === 'COMPLETED'
              ? 'It might not have targeted anyone.'
              : campaign.state === 'RUNNING'
                ? 'It is not completed yet.'
                : campaign.state === 'STOPPED'
                  ? 'It is paused.'
                  : campaign.state === 'DRAFT'
                    ? 'It is still a draft.'
                    : '',
        }}
      >
        {data.items
          .filter(item => item.label !== 'Accepted' || !hidden)
          .map((item, index) => (
            <SummaryMetric
              color={item.color}
              first={index === 0}
              empty={data.empty}
              key={item.label}
            >
              <Metric value={item.value} isRatio={item.ratio} tooltip={item.tooltip} align="center">
                {item.label}
              </Metric>
            </SummaryMetric>
          ))}
      </Wrapper>
    </Box>
  )
}
type SummaryMetricProps = {
  color: string
  first: boolean
  empty: boolean
}
const SummaryMetric = styled.div<SummaryMetricProps>`
  display: block;
  flex: 1 1 100%;
  margin-right: -1px;
  padding: 28px 0 26px 0;
  background: ${colors.fill};
  box-shadow:
    inset 0 3px 0 ${p => (p.empty ? colors.stroke : p.color)},
    inset 0 0 0 1px ${p => (!p.empty ? colors.stroke : 'transparent')};

  border-radius: ${p => (p.first ? '5px 0 0 5px' : 0)};
  &:last-of-type {
    margin-right: 0;
    border-radius: 0 5px 5px 0;
  }
`

export default Summary
