import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { DropdownSeparator, Button, useDropdown, DropdownMenu } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { PushFilterButton, PushFilterOption } from './push-platform-filter.styles'

import { PUSH_PLATFORM_FILTER } from 'com.batch/shared/constants/push-platform-filter.constants'
import { type PushPlatformFilterType } from 'com.batch/shared/infra/types/push-platform-filter.type'

export const PushPlatformFilter = (): React.ReactElement => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const { dropdownProps, triggerProps, closeDropdown } = useDropdown({ forcedWidth: 232 })

  const options = React.useMemo(
    () =>
      PUSH_PLATFORM_FILTER.reduce<List<PushPlatformFilterType>>(
        (acc, current) => acc.push(current),
        Immutable.List()
      ),
    []
  )

  const defaultValue = React.useMemo(() => PUSH_PLATFORM_FILTER[0], [])

  const selectedValue = React.useMemo(
    () => options.find(opt => opt.filter === (search.get('push') ?? defaultValue.filter)),
    [search, options, defaultValue]
  )

  const filterIsActive = React.useMemo(
    () => selectedValue?.filter !== defaultValue.filter,
    [selectedValue?.filter, defaultValue.filter]
  )

  const handleButtonClick = React.useCallback(
    (opt?: PushPlatformFilterType) => event => {
      event.stopPropagation()
      closeDropdown()
      navigate({
        search: opt ? encodeURI(`push=${opt.filter}`) : '',
      })
    },
    [navigate, closeDropdown]
  )

  return (
    <React.Fragment>
      <PushFilterButton
        kind="inline"
        intent="neutral"
        addOn="prefix"
        addOnGap={8}
        {...triggerProps}
        aria-label="Toggle dropdown menu"
        isActive={filterIsActive}
      >
        <Icon icon="filter" />
        <span>{selectedValue?.label}</span>
        {filterIsActive && (
          <div className="clear-button" onClick={handleButtonClick()}>
            <Icon icon="close" />
          </div>
        )}
      </PushFilterButton>
      <DropdownMenu {...dropdownProps}>
        {PUSH_PLATFORM_FILTER.map((option, index) => (
          <React.Fragment>
            {index !== 0 && option.kind === 'categorie' && <DropdownSeparator />}
            <PushFilterOption key={index} kind={option.kind}>
              <Button
                kind="inline"
                intent="neutral"
                onClick={handleButtonClick(option)}
                isActive={selectedValue?.filter === option.filter}
              >
                <Icon icon={option.icon} />
                {option.label}
              </Button>
            </PushFilterOption>
          </React.Fragment>
        ))}
      </DropdownMenu>
    </React.Fragment>
  )
}
