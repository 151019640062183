import * as React from 'react'
import styled, { css } from 'styled-components'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { InputContainer } from 'components/form/styles' // we do not import from root to avoid circular dependency
import * as colorsLegacy from 'components/styled/colors'
import { colors, schemes, shadows, rules } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'

// ====================== STYLED COMPONENTS
type DayPickerContainerProps = {
  isRange?: boolean
  position: 'left' | 'right'
  $hasValue?: boolean
  opened?: boolean
  $hasIcon?: boolean
}
// type ThemeProps = {
//   disabledMode?: boolean
//   kind?: 'filter' | 'default'
// }
export const DayPickerContainer = styled.div<DayPickerContainerProps>`
  /** DAYPICKER ***/
  .DayPicker {
    width: fit-content;
    padding: 22px;
  }

  /*** RESET  ***/
  .DayPicker-Weekday,
  .DayPicker-Day,
  .DayPicker-wrapper,
  .DayPicker-Month,
  .DayPicker-NavBar,
  .DayPicker-Day--selected {
    padding: 0;
    margin: 0;
    outline: none;
  }

  .DayPicker-wrapper {
    position: relative;
  }

  ${'' /* .DayPicker-Day--selected:not(.DayPicker-Day--before):not(.DayPicker-Day--outside), */}
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: transparent;
  }

  /*** styled-day-picker-popup ****/
  .styled-day-picker-popup {
    position: absolute;
    top: 40px;
    display: flex;
    width: fit-content;
    min-height: 301px;
    border-radius: 3px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid ${colors.stroke};
    background-color: ${colors.fill};
    z-index: 11;
    overflow: hidden;

    ${props =>
      props.position === 'right'
        ? css`
            right: 0;
          `
        : css`
            left: 0;
          `}
  }

  /*** CAPTION ***/
  .DayPicker-Caption {
    height: fit-content;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    color: ${colors.text};
    color: red;
    display: table-caption;
    height: 1.5rem;
    text-align: center;
    > span {
      font-weight: 300;
      color: ${colors.textLight};
    }
  }

  /***  NAV BAR  ****/
  .DayPicker-NavBar {
    top: 2px;
  }
  .DayPicker-NavButton {
    background-size: 5px;
    outline: none;
    color: ${colors.textLight};

    &.DayPicker-NavButton--prev {
      left: 0;
    }

    &.DayPicker-NavButton--next {
      right: 0;
    }
  }

  /***   MONTH   ***/
  ${props =>
    props.isRange &&
    css`
      .DayPicker-Months {
        display: flex;
        padding: 0;
        margin: 0;
      }

      .DayPicker-Month {
        display: block;
        &:first-of-type {
          margin-right: 19px;
        }

        &:last-of-type {
          padding-left: 19px;
          border-left: 1px solid ${schemes.darklucent['05']};
        }
      }
    `}

  /***  WEEKS  ***/
  .DayPicker-Weekday {
    padding-top: 22px;
    padding-bottom: 12px;
    font-size: 13px;
    font-weight: 400;
    color: ${colors.textLight};
  }

  /***  DAYS  ***/

  .DayPicker-Day {
    & > div {
      padding: 0 3px;
      margin: 4px 0;
    }

    .styled-day-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.04rem;
    }

    &:hover,
    &:focus {
      outline: none;
      background-color: transparent;
    }

    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--highlighted):not(
        .DayPicker-Day--selected
      ):hover {
      .styled-day-container {
        border-radius: 3px;
        background-color: ${schemes.darklucent['20']};
      }
    }
  }

  .DayPicker-Day--selected .styled-day-container {
    font-weight: 500;
    color: white;
    background-color: ${schemes.blue['30']};
    border-radius: 3px;
  }

  .DayPicker-Day--highlighted > div {
    background-color: ${schemes.darklucent['05']};
    cursor: pointer;
  }

  .DayPicker-Day--overview .styled-day-container {
    background-color: ${schemes.darklucent['20']};
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .DayPicker-Day--from {
    & > div {
      margin-right: 0;
      margin-left: 3px;
      padding-left: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    .styled-day-container {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .DayPicker-Day--to {
    & > div {
      margin-left: 0;
      margin-right: 3px;
      padding-right: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    .styled-day-container {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .DayPicker-Day--today {
    color: ${colors.textAction};

    .styled-day-container {
      font-weight: 700;
    }
  }

  .DayPicker-Day--disabled {
    font-weight: 400;
    color: ${colors.textDisabled};
    pointer-events: none;
  }

  .DayPicker-Day--lastWeekDay > div {
    padding-right: 0;
    margin-right: 0;
  }

  .DayPicker-Day--firstWeekDay > div {
    padding-left: 0;
    margin-left: 0;
  }

  /*** Input  ***/
  ${InputContainer} {
    cursor: pointer;
    font-weight: 500;

    ${props => (props.$hasValue ? rules.FilterWithValue : rules.FilterWithoutValue)}
    ${props =>
      props.theme?.disabledMode &&
      css`
        color: ${colors.text};
        -webkit-text-fill-color: ${colors.text};
        background: ${p =>
          p.theme.kind === 'filter' ? schemes.darklucent['10'] : colors.fillDisabled};
      `}
  }
  input {
    cursor: pointer;
    width: calc(50% - 59px);
  }
  .styled-dp-dropdown-icon {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    min-width: 33px;
    min-height: 36px;
    color: ${colors.textLight};
    cursor: pointer;
    border: none;
    background-color: transparent;
  }

  /*** Specific to DateRangePicker ***/

  ${InputContainer} {
    transition: all ease-out 0.2s;
  }

  ${props =>
    (props.$hasValue || props.opened) &&
    css`
      ${InputContainer} {
        padding-left: ${props.$hasIcon ? '0' : '12px'};
        padding-right: 35px;
      }
    `}

  input {
    font-variant-numeric: tabular-nums;
    ${props =>
      (props.$hasValue || props.opened) &&
      css`
        letter-spacing: -0.42px;
      `}
    width: 92px;

    color: ${colors.textNeutral};
  }

  hr {
    width: 2px;
    height: 13px;
    rotate: 90deg;
    margin: 0 10px 0 8px;
    border-right: 1px solid
      ${props =>
        props.theme.kind === 'filter' ? 'rgba(112, 112, 112, 1)' : colorsLegacy.stroke.lighter};
  }
`

// SHORTCUTS
export const ShortcutsContainer = styled.div`
  width: 160px;
  padding: 22px 8px 22px 8px;
  border-left: 1px solid ${colors.stroke};

  background-color: ${schemes.grayscale['02']};

  button {
    padding: 0 8px;
    margin-bottom: 4px;
    justify-content: flex-start;
  }
`
type ShortcutsButtonProps = {
  isActive: boolean
}
export const ShortcutsButton = styled(Button)<ShortcutsButtonProps>`
  ${props => props.isActive && css``}
`

// = Styled Date piker element
export const DayElements = (day: Date): React.ReactElement => (
  <div>
    <div className="styled-day-container">{dayjs(day).format('D')}</div>
  </div>
)

export const Caption = (props: { date: Date }): React.ReactElement => {
  const day = dayjs(props.date)
  return (
    <div className="DayPicker-Caption">
      {day.format('MMMM')} <span>{day.format('YYYY')}</span>
    </div>
  )
}

/** * NAVBAR  ***/
const DatePickerNavbarContainer = styled.div`
  position: absolute;
  top: -5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 10;

  button {
    width: 28px;
  }
`
export const DatePickerNavbar = ({
  onPreviousClick,
  onNextClick,
}: {
  onPreviousClick: (internal?: any) => void
  onNextClick: (internal?: any) => void
}): React.ReactElement => {
  /*
    please keep the callbacks, they are usefull even if they don't seem to do anything
    internaly react-day-picker does something with the param passed, and we managed to
    break prev/next by passing the click event  
  */

  const onNext = React.useCallback(() => {
    onNextClick()
  }, [onNextClick])
  const onPrevious = React.useCallback(() => {
    onPreviousClick()
  }, [onPreviousClick])
  return (
    <DatePickerNavbarContainer>
      <Button style={{ height: 28 }} onClick={onPrevious}>
        <Icon icon="chevron-left" />
      </Button>
      <Button style={{ height: 28 }} onClick={onNext}>
        <Icon icon="chevron-right" />
      </Button>
    </DatePickerNavbarContainer>
  )
}

/** *  WEEKDAYS ELEMENT  ***/
const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const WeekdayElement = ({
  weekday,
  className,
}: {
  weekday: number
  className: string
}): React.ReactElement => {
  return (
    <div className={className} role="columnheader">
      {WEEKDAYS_SHORT[weekday]}
    </div>
  )
}
type DatePickerPopoverContainerProps = {
  position?: 'right' | 'left'
  isRange?: boolean
  width: number
}
export const DatePickerPopoverContainer = styled.div<DatePickerPopoverContainerProps>`
  display: flex;
  width: ${p => p.width}px;
  height: fit-content;
  border-radius: 6px;
  box-shadow: ${shadows.overlay};
  /* box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1); */
  border: 1px solid ${colors.stroke};
  background-color: ${colors.fill};
  z-index: 9999;
  overflow: hidden;

  ${props =>
    props.position === 'right'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}

  /** DAYPICKER ***/
  .DayPicker {
    width: fit-content;
    padding: 22px;
  }

  /*** RESET  ***/
  .DayPicker-Weekday,
  .DayPicker-Day,
  .DayPicker-wrapper,
  .DayPicker-Month,
  .DayPicker-NavBar,
  .DayPicker-Day--selected {
    padding: 0;
    margin: 0;
    outline: none;
  }

  .DayPicker-wrapper {
    position: relative;
  }

  ${'' /* .DayPicker-Day--selected:not(.DayPicker-Day--before):not(.DayPicker-Day--outside), */}
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: transparent;
  }

  /*** CAPTION ***/
  .DayPicker-Caption {
    height: fit-content;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    color: ${colors.text};

    > span {
      font-weight: 300;
      color: ${colors.textLight};
    }
  }

  /***  NAV BAR  ****/
  .DayPicker-NavBar {
    top: 2px;
  }
  .DayPicker-NavButton {
    background-size: 5px;
    outline: none;
    color: ${colors.textLight};

    &.DayPicker-NavButton--prev {
      left: 0;
    }

    &.DayPicker-NavButton--next {
      right: 0;
    }
  }
  .DayPicker-Months {
    padding: 0;
  }
  /***   MONTH   ***/
  ${props =>
    props.isRange &&
    css`
      .DayPicker-Months {
        display: flex;
        margin: 0;
      }

      .DayPicker-Month {
        display: block;
        &:first-of-type {
          margin-right: 19px;
        }

        &:last-of-type {
          padding-left: 19px;
          border-left: 1px solid ${colors.stroke};
        }
      }
    `}

  /***  WEEKS  ***/
  .DayPicker-Weekday {
    padding-top: 22px;
    padding-bottom: 12px;
    font-size: 13px;
    font-weight: 400;
    color: ${colors.textLight};
  }

  /***  DAYS  ***/

  .DayPicker-Day {
    & > div {
      padding: 0 3px;
      margin: 4px 0;
    }

    .styled-day-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.04rem;
    }

    &:hover,
    &:focus {
      outline: none;
      background-color: transparent;
    }

    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--highlighted):not(
        .DayPicker-Day--selected
      ):hover {
      .styled-day-container {
        border-radius: 3px;
        background-color: ${colors.opacifyHover};
      }
    }
  }

  .DayPicker-Day--selected .styled-day-container {
    font-weight: 500;
    color: white;
    background-color: ${colors.fillAction};
    border-radius: 3px;
  }

  .DayPicker-Day--highlighted > div {
    background-color: ${colors.opacifyHover};
    cursor: pointer;
  }

  .DayPicker-Day--overview .styled-day-container {
    background-color: ${colors.opacifyHover};
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .DayPicker-Day--from {
    & > div {
      margin-right: 0;
      margin-left: 3px;
      padding-left: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    .styled-day-container {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .DayPicker-Day--to {
    & > div {
      margin-left: 0;
      margin-right: 3px;
      padding-right: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    .styled-day-container {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .DayPicker-Day--today {
    color: ${colors.textAction};
    .styled-day-container {
      font-weight: 600;
    }
  }

  .DayPicker-Day--disabled {
    font-weight: 400;
    color: ${colors.textDisabled};
    pointer-events: none;
  }

  .DayPicker-Day--lastWeekDay > div {
    padding-right: 0;
    margin-right: 0;
  }

  .DayPicker-Day--firstWeekDay > div {
    padding-left: 0;
    margin-left: 0;
  }
`

export const InputButton = styled.input`
  &:hover::placeholder {
    color: ${schemes.grayscale['80']} !important;
    -webkit-text-fill-color: ${schemes.grayscale['80']} !important;
  }
`
