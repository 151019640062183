import { type Dayjs } from 'dayjs'
import * as React from 'react'
import styled from 'styled-components'

import { Tooltip } from 'components/common/tooltip'
import { Timezone } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'

export const UtcFlag = styled.span`
  display: inline-block;
  padding: 3px 2px;
  border-radius: 0.3em;
  font-size: 0.7em;
  line-height: 0.7em;
  border: 1px solid #a0a7af7a;
  cursor: default;
  color: #a0a7af;
  margin-left: 0.5em;
`

export const Utc = ({ m }: { m: Dayjs }): React.ReactElement => {
  const offset = dayjs().utcOffset()
  const h = Math.floor(Math.abs(offset) / 60)
  if (offset === 0) {
    return <UtcFlag>UTC</UtcFlag>
  }
  return (
    <Tooltip
      minWidth={140}
      tooltip={
        <div style={{ textAlign: 'left' }}>
          <p>
            Your local time is {h} hour{h > 1 ? 's' : ''} {offset < 0 ? 'behind' : 'ahead'} UTC:
          </p>
          <ul
            style={{
              borderTop: '1px solid rgba(255,255,255,0.1)',
              padding: '5px 0 0 14px',
              marginTop: 4,
              fontSize: 11,
              lineHeight: '20px',
              fontFamily: 'monospace',
              textTransform: 'uppercase',
            }}
          >
            <li>UTC:&nbsp;&nbsp; {m.format('HH:mm')}</li>
            <li>Local: {m.add(offset, 'minute').format('HH:mm')}</li>
          </ul>
        </div>
      }
    >
      <Timezone style={{ marginLeft: 5 }}>UTC</Timezone>
    </Tooltip>
  )
}
