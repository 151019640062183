import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { HorizontalDivider } from 'components/styled/utils'

import { capitalize, formatNumberWithCommas } from 'com.batch.common/utils'

import { BounceNoData } from '../bounce-no-data'
import { currentCampaign } from 'com.batch.redux/campaign.selector'

import { bounceReasonsLabels } from 'com.batch/orchestration-analytics/constants/bounce-reasons-labels'
import { bouncesColors } from 'com.batch/orchestration-analytics/constants/stat-type-colors'
import {
  BounceReasonCategory,
  BounceReasonContainer,
  BounceReasonRow,
} from 'com.batch/orchestration-analytics/ui/components/bounce-reasons/bounce-reasons.styles'
import { BounceBadge } from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-report-block.styles'

type Props = {
  reasons: Array<{
    name: string
    category: 'HARD' | 'SOFT' | 'BLOCK' | 'UNDETERMINED'
    count: number
  }>
}
export const BounceReasons = ({ reasons }: Props): React.ReactElement => {
  const campaign = useSelector(currentCampaign)

  if (reasons.length === 0) return <BounceNoData schedulingType={campaign.schedulingType} />

  return (
    <div style={{ overflow: 'auto', paddingLeft: 20, paddingRight: 20 }}>
      {reasons.map((reason, index) => (
        <BounceReasonRow key={reason.name}>
          <BounceReasonContainer>
            <div style={{ fontWeight: 500 }}>{bounceReasonsLabels[reason.name]}</div>
            <BounceReasonCategory>
              {capitalize(
                (reason.category === 'UNDETERMINED' ? 'UNKNOWN' : reason.category).toLowerCase()
              )}
              <BounceBadge color={bouncesColors[reason.category.toLowerCase()]} />
            </BounceReasonCategory>
            <div style={{ textAlign: 'end', fontWeight: 400, color: '#4F4F4F' }}>
              {formatNumberWithCommas(reason.count)} occurrences
            </div>
          </BounceReasonContainer>

          {index !== reasons.length - 1 && <HorizontalDivider />}
        </BounceReasonRow>
      ))}
    </div>
  )
}
