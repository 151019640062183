import * as React from 'react'

import { schemes } from 'components/styled/tokens'

import { Graduation, GraduationSkeletton } from './scale-line.styles'

type Props = {
  max: number
  height: number
  width: number
  nbLines: number
  nbParts: number
  lineMax: number
  graduationPlacement?: 'over' | 'under'
  showZero?: boolean
  showZeroScaleLine?: boolean
  isLoading?: boolean
  isEmpty?: boolean
  isRate?: boolean
}

const prefixes = [
  {
    symbol: 'P',
    min: 1000000000000,
    div: 1000000000000,
  },
  {
    symbol: 'T',
    min: 1000000000,
    div: 1000000000,
  },
  {
    symbol: 'M',
    min: 1000000,
    div: 1000000,
  },
  {
    symbol: 'k',
    min: 10000,
    div: 1000,
  },
]

export const ScaleLine = ({
  isLoading,
  isEmpty,
  max,
  width,
  height,
  nbLines,
  nbParts,
  lineMax,
  graduationPlacement = 'under',
  showZero = false,
  showZeroScaleLine = true,
  isRate = false,
}: Props): React.ReactElement => {
  const scaleLineRefs = React.useRef<(SVGTextElement | null)[]>([])
  const onRefSet = React.useCallback(
    (index: number) => (el: SVGTextElement | null) => {
      scaleLineRefs.current[index] = el
    },
    []
  )

  const convertToKformat = React.useCallback(number => {
    const found = prefixes.find(f => number > f.min)
    return found ? Math.round(number / found.div) + found.symbol : number.toLocaleString('en-US')
  }, [])

  const scaleLine = React.useMemo(() => {
    const valueInterval = max / lineMax
    const yInterval = height / nbParts

    return Array.from({ length: nbLines }, (_, i) => ({
      value: i * valueInterval,
      y: height - i * yInterval,
    }))
  }, [max, height, nbParts, lineMax, nbLines])

  return (
    <g>
      {scaleLine.map((line, index) => {
        const y = line.value === 0 ? line.y - 2 : line.y
        return (
          <g key={`scaleLine-${index}`}>
            {line.value === 0 && !showZeroScaleLine ? null : (
              <line
                strokeDasharray="5, 2"
                x1="0"
                y1={y}
                x2={width}
                y2={y}
                style={{ strokeWidth: 1 }}
                stroke={schemes.darklucent['10']}
              />
            )}
            {!isLoading && !isEmpty && ((showZero && index === 0) || line.value) && (
              <Graduation
                ref={onRefSet(index)}
                x={width}
                y={graduationPlacement === 'over' ? y - 5 : y + 20}
              >
                {isRate ? `${line.value}%` : convertToKformat(line.value)}
              </Graduation>
            )}

            {(isLoading || isEmpty) && (
              <GraduationSkeletton
                x={width - 30}
                y={graduationPlacement === 'over' ? line.y - 20 : line.y + 5}
                width="30"
                height="15"
                rx="5"
                ry="5"
                isLoading={isLoading}
                isEmpty={isEmpty}
              />
            )}
          </g>
        )
      })}
    </g>
  )
}
