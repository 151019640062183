import { type Dayjs } from 'dayjs'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { humanizeDayjsShortFormat } from 'com.batch.common/utils'

import { type TransmissionType } from 'com.batch/shared/models/transmission-type'

const formatMessageResultTooltip = (dateDayJs: Dayjs): string =>
  `${dateDayJs.format('DD/MM/YYYY')} at ${dateDayJs.format('h:ma')}`

type MessageResultType = {
  message: string
  tooltip: string
}

export const getSubscriptionTimeDetailLegacy = (
  subscriptionName: TransmissionType,
  lastEmailClicked?: string | null,
  lastEmailOpened?: string | null
): {
  opened: MessageResultType
  clicked: MessageResultType
} => {
  const emailKind = subscriptionName === 'MARKETING' ? 'marketing email' : 'transactional email'

  const messageResult = {
    opened: {
      message: 'No open',
      tooltip: `This profile has not opened any ${emailKind}`,
    },
    clicked: {
      message: 'No click',
      tooltip: `This profile has not clicked on any ${emailKind}`,
    },
  }

  if (lastEmailOpened) {
    const openedDayJs = dayjs(lastEmailOpened)
    messageResult.opened.message = `Opened ${humanizeDayjsShortFormat({ date: openedDayJs })}`
    messageResult.opened.tooltip = formatMessageResultTooltip(openedDayJs)
  }

  if (lastEmailClicked) {
    const clickedDayJs = dayjs(lastEmailClicked)
    messageResult.clicked.message = `Clicked ${humanizeDayjsShortFormat({
      date: clickedDayJs,
    })}`
    messageResult.clicked.tooltip = formatMessageResultTooltip(clickedDayJs)
  }

  return messageResult
}

export const getSubscriptionTimeDetails = (
  subscriptionName: TransmissionType,
  lastEmailClicked?: string | null,
  lastEmailOpened?: string | null
): {
  opened: string
  clicked: string
} => {
  const messageResult = {
    opened: 'Never opened',
    clicked: 'Never clicked',
  }

  if (lastEmailOpened) {
    const openedDayJs = dayjs(lastEmailOpened)
    messageResult.opened = `Opened ${humanizeDayjsShortFormat({ date: openedDayJs })}`
  }

  if (lastEmailClicked) {
    const clickedDayJs = dayjs(lastEmailClicked)
    messageResult.clicked = `Clicked ${humanizeDayjsShortFormat({
      date: clickedDayJs,
    })}`
  }

  return messageResult
}
