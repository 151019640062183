import Immutable, { type List } from 'immutable'

import { type DispatchExtraBoundFn, type ReduxAction } from '../../../redux/_records'
import { currentProjectSelector } from '../../../redux/project.selector'
import { type GetSegmentResponse } from '../infra/segments-service'
import { SegmentFactory, type SegmentRecord } from '../models/segment.records'

type GetSegmentsByNameAction = ReduxAction<
  'GET_SEGMENTS_BY_NAME',
  {
    names: Array<string>
  }
>
type GetSegmentsByNameFailureAction = ReduxAction<'GET_SEGMENTS_BY_NAME_FAILURE', string>
type GetSegmentsByNameSuccessAction = ReduxAction<
  'GET_SEGMENTS_BY_NAME_SUCCESS',
  List<SegmentRecord>
>

export type GetSegmentsByNameActionType =
  | GetSegmentsByNameAction
  | GetSegmentsByNameFailureAction
  | GetSegmentsByNameSuccessAction

export const getSegmentsByName = (
  names: Array<string>
): DispatchExtraBoundFn<Promise<List<SegmentRecord>>> => {
  return async (dispatch, getState, { segmentsService }): Promise<List<SegmentRecord>> => {
    const state = getState()
    const project = currentProjectSelector(state)
    dispatch({
      type: 'GET_SEGMENTS_BY_NAME',
      payload: { names },
    } as GetSegmentsByNameAction)
    try {
      const { segments }: GetSegmentResponse = await segmentsService.getSegmentsByName({
        names,
        projectKey: project.projectKey,
      })
      const segmentList: List<SegmentRecord> = Immutable.List(
        segments.map(segment =>
          SegmentFactory({
            name: segment.name,
            displayName: segment.displayName,
            query: segment.query,
            deletedAt: segment.deletedAt,
          })
        )
      )
      dispatch({
        type: 'GET_SEGMENTS_BY_NAME_SUCCESS',
        payload: segmentList,
      } as GetSegmentsByNameSuccessAction)
      return segmentList
    } catch (error: any) {
      dispatch({
        type: 'GET_SEGMENTS_BY_NAME_FAILURE',
        payload: error.message,
      } as GetSegmentsByNameFailureAction)
      return Immutable.List()
    }
  }
}
