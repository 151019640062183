import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { useToggle } from 'components/_hooks/use-toggle'
import { Button, ButtonLink } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { colors } from 'components/styled/tokens'

import { SelectSearch } from './select-search'
import { type CommonSelectProps } from './select.helper'

export type SelectMultiLineProps<T> = CommonSelectProps<T> & {
  maxItems?: number
  value: List<T>
  options?: List<T>
  isLoading?: boolean
  loadOptions?: (arg1: string) => Promise<List<T>>
  onChange: (arg1: List<T>) => void
  buildViewOptionLink?: (arg1: T) => string
  invalid?: boolean
  itemName: string
  optionFormatter?: (
    arg1: T,
    arg2: {
      context: 'value' | 'menu'
    }
  ) => React.ReactElement
}

export function SelectMultiLine<T>({
  maxItems,
  options,
  buildViewOptionLink,
  optionToString,
  loadOptions,
  isDisabled,
  itemName,
  value,
  optionFormatter,
  onChange,
}: SelectMultiLineProps<T>): React.ReactElement {
  const insertModeState = useToggle(value.size === 0)

  const createOnChangeForIndex = React.useCallback(
    (index: number) => (v?: T | null) => {
      insertModeState.close()
      if (v) onChange(value.set(index, v))
    },
    [onChange, value, insertModeState]
  )
  const createOnRemove = React.useCallback(
    (index: number) => {
      return () => {
        if (value.size < 2) {
          onChange(Immutable.List())
        } else {
          onChange(value.splice(index, 1))
        }
      }
    },
    [onChange, value]
  )
  const optionsExculdingAlreadyPicked = React.useMemo(
    () => options?.filter((opt: T) => !value.includes(opt)) || Immutable.List<T>(),
    [options, value]
  )

  const loadOptionsExcludingPicked = React.useMemo(() => {
    if (loadOptions) {
      return (query: string) => {
        return loadOptions(query).then(options => {
          return options.filter(opt => !value.some(v => optionToString(v) === optionToString(opt)))
        })
      }
    }
  }, [loadOptions, value, optionToString])

  const cancelInsert = React.useCallback(() => {
    onChange(value)
    insertModeState.close()
  }, [insertModeState, onChange, value])
  return (
    <React.Fragment>
      {value.map((v, index) => {
        const isLastItem = index === value.size - 1
        const viewUrl = buildViewOptionLink ? buildViewOptionLink(v) : null
        const showInsertButton =
          isLastItem &&
          !insertModeState.value &&
          !isDisabled &&
          (!maxItems || value.size < maxItems)
        return (
          <Grid
            key={index}
            template={`1fr ${viewUrl ? '28px' : ''} ${showInsertButton ? '28px' : ''} ${
              !isDisabled ? '46px' : '8px'
            }`}
            margin={[0, 0, showInsertButton ? 0 : 10, 0]}
          >
            <SelectSearch
              style={{ minWidth: 150 }}
              placeholder={`Select a ${itemName}`}
              options={loadOptions ? undefined : optionsExculdingAlreadyPicked}
              isDisabled={isDisabled}
              value={v}
              optionFormatter={optionFormatter}
              loadOptions={loadOptions}
              optionToString={optionToString}
              onChange={createOnChangeForIndex(index)}
              isClearable={false}
              localSearchEveryWhere
            />
            {viewUrl && (
              <Tooltip placement="bottom-start" tooltip={`View ${itemName}`}>
                <ButtonLink href={viewUrl} target="_blank" kind="inline">
                  <Icon icon="view" />
                </ButtonLink>
              </Tooltip>
            )}
            {showInsertButton && (
              <Tooltip placement="bottom-start" tooltip={`Add another ${itemName}`} minWidth={170}>
                <Button onClick={insertModeState.open} kind="inline">
                  <Icon icon="add" />
                </Button>
              </Tooltip>
            )}
            {isDisabled ? (
              <div />
            ) : (
              <Tooltip placement="bottom-start" tooltip={`Remove ${itemName}`} minWidth={140}>
                <Button onClick={createOnRemove(index)} style={{ width: 36 }}>
                  <Icon icon="remove" color={colors.textLight} size={16} />
                </Button>
              </Tooltip>
            )}
          </Grid>
        )
      })}
      {insertModeState.value && (
        <Grid template="1fr 46px">
          <SelectSearch
            style={{ minWidth: 150 }}
            autoFocus={value.size !== 0}
            placeholder={`${loadOptions ? 'Search' : 'Select'} a${
              itemName.substr(0, 1) === 'a' ? 'n' : ''
            } ${itemName}`}
            isDisabled={isDisabled}
            options={loadOptions ? undefined : optionsExculdingAlreadyPicked}
            loadOptions={loadOptionsExcludingPicked}
            value={null}
            optionFormatter={optionFormatter}
            optionToString={optionToString}
            onChange={createOnChangeForIndex(value.size)}
            isClearable={false}
            localSearchEveryWhere
          />
          {isDisabled ? (
            <div />
          ) : (
            <Tooltip placement="bottom-start" tooltip={`Remove ${itemName}`} minWidth={140}>
              <Button onClick={cancelInsert} style={{ width: 36 }}>
                <Icon icon="remove" color={colors.textLight} size={16} />
              </Button>
            </Tooltip>
          )}
        </Grid>
      )}
    </React.Fragment>
  )
}
