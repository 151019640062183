import { type Map } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button'
import { Code } from 'components/common/code'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Input, InputWrapper } from 'components/form'

import { type GCMConfigRecord } from 'com.batch.redux/_records'

type Props = {
  conf: GCMConfigRecord
  index: number
  edit?: boolean
  errors?: Map<string, string>
  onServerApiKeyChange?: (index: number) => (evt: any) => void
  onRemoveConfig?: (index: number) => () => void
  onSenderIdChange?: (index: number) => (evt: any) => void
}

export const LegacyConfigGrid = ({
  conf,
  index,
  edit = false,
  errors,
  onServerApiKeyChange,
  onRemoveConfig,
  onSenderIdChange,
}: Props): React.ReactElement => {
  return (
    <React.Fragment>
      <InputWrapper
        style={{ marginTop: 0, marginBottom: index === 0 ? 20 : 14 }}
        feedback={errors?.get(`senderId_${index}`)}
        label={index === 0 ? 'FCM sender ID' : index === 1 ? 'Additional(s) FCM sender ID' : null}
        htmlFor="sender"
      >
        {edit && onSenderIdChange ? (
          <Input
            id="sender"
            className="fs-exclude"
            value={conf.senderId}
            invalid={errors?.has(`senderId_${index}`)}
            placeholder={index > 0 ? 'Additional Sender ID' : 'Sender ID'}
            allowedCharsRegex={/[0-9]/}
            onChange={onSenderIdChange(index)}
          />
        ) : (
          <Code className="fs-exclude">{conf.senderId}</Code>
        )}
      </InputWrapper>
      <div />
      <InputWrapper
        style={{ marginTop: 0, marginBottom: index === 0 ? 20 : 10 }}
        feedback={errors?.get(`serverApiKey_${index}`)}
        label={
          index === 0
            ? 'FCM server API key'
            : index === 1
              ? 'Additional(s) FCM server API key'
              : null
        }
        htmlFor="server"
      >
        {edit && onServerApiKeyChange && onRemoveConfig ? (
          <Grid template={index === 0 ? '1fr' : '1fr 30px'}>
            <Input
              id="server"
              name="server"
              className="fs-exclude"
              invalid={errors?.has(`serverApiKey_${index}`)}
              placeholder={index > 0 ? 'Additional Server API Key' : 'Server API Key'}
              onChange={onServerApiKeyChange(index)}
              value={conf.serverApiKey}
            />
            {index > 0 && (
              <div style={{ paddingLeft: 10 }}>
                <Button kind="discreet" intent="danger" onClick={onRemoveConfig(index)}>
                  <Icon icon="delete" />
                </Button>
              </div>
            )}
          </Grid>
        ) : (
          <Code className="fs-exclude">{conf.serverApiKey}</Code>
        )}
      </InputWrapper>
    </React.Fragment>
  )
}
