import { rgb, hsl } from 'd3-color'
import { geoMercator, geoPath } from 'd3-geo'
import { scalePow } from 'd3-scale'
import { filter as _filter, last as _last } from 'lodash-es'
import * as React from 'react'
import styled from 'styled-components'
import { feature } from 'topojson'

import { useResizeObserver } from 'components/_hooks'
import { builtEmptyStateAnimation } from 'components/common/empty-states'
import { TooltipContainer, TooltipInner, TooltipArrow } from 'components/common/tt'
import { color as placeholder_color } from 'components/styled/placeholder'

import { kformat } from 'com.batch.common/utils'

type serie = {
  color: string
  title: string
  values: Array<{
    value: number
    regionCode: string
    regionLabel: string
    color: string
  }>
}
type MapProps = {
  topologyUrl: string
  serie: serie
  style?: any
}

/*
  builds a color scale for an array of values.
*/
const getColorScale = (serie: serie) => {
  const clean = _filter(
    serie.values.map(v => v.value),
    v => v !== 0
  ).sort((a, b) => (a > b ? 1 : -1))
  let max = _last(clean)
  if (!max) {
    max = 2
  } else {
    max = Math.floor(max)
  }
  const color = hsl(rgb(serie.color))
  const scaleSat = scalePow().exponent(0.3).range([0.1, color.s]).domain([1, max])

  const scaleLum = scalePow().exponent(0.3).range([0.9, color.l]).domain([1, max])

  return (value: number) => {
    if (value === 0) {
      value = 1
    }
    color.s = scaleSat(value)
    color.l = scaleLum(value)
    color.l = Math.min(scaleLum(value), 0.97)
    return color.toString()
  }
}

// look for label & value in a serie values array for a given regionCode
const findCountryInSerie = (
  scale: (value: number) => string,
  rs: serie,
  regionCode: string
): {
  color: string
  label: string
  value: number
} => {
  const match = rs.values.find(value => value.regionCode === regionCode)
  if (typeof match !== 'undefined') {
    return { color: scale(Math.floor(match.value)), label: match.regionLabel, value: match.value }
  } else {
    return { color: scale(0), label: '', value: 0 }
  }
}

// cache for features sets, so we only fetch it once
type Feature = {
  type: 'Feature'
  id: string
  properties: {
    name: string
  }
  geometry: {
    type: 'Polygon'
    coordinates: []
  }
}
let featuresCache: Array<Feature> = []
let featuresLoading = false

// this component has to be in a fixed height parent, overwise it will grow px by px until it fills it....
export const Map = ({ topologyUrl, serie, style }: MapProps): React.ReactElement => {
  const [tooltipX, updateTooltipX] = React.useState(0)
  const [tooltipY, updateTooltipY] = React.useState(0)
  const [tooltip, updateTooltip] = React.useState('')
  const [features, updateFeatures] = React.useState(featuresCache)
  const [ref, width, height] = useResizeObserver()
  React.useEffect(() => {
    if (features.length === 0 && !featuresLoading) {
      featuresLoading = true
      fetch(topologyUrl).then(response => {
        featuresLoading = false
        response.json().then(data => {
          featuresCache = feature(data, data.objects.countries).features
          updateFeatures(featuresCache)
        })
      })
    }
  }, [features.length, topologyUrl])

  const ratio = Math.min(height / width, 0.75)
  const fixedHeight = width * ratio
  const scale = getColorScale(serie)
  const projection = geoMercator()
    .scale(width / 2.2 / Math.PI)
    .translate([width * 0.5, fixedHeight * 0.7])
    .precision(0.1)
  const onEmptyTooltip = React.useCallback(() => {
    updateTooltip('')
  }, [])

  const createOnCountryMouseMove = React.useCallback(
    (label: string, value: number) => (evt: any) => {
      updateTooltip(label ? `${label}: ${kformat(value)}` : '')
      updateTooltipX(evt.nativeEvent.layerX - 60)
      updateTooltipY(evt.nativeEvent.layerY - 40)
    },
    []
  )

  const countryDAttr = React.useCallback(
    (f: Feature) => geoPath().projection(projection)(f) ?? undefined,
    [projection]
  )

  return (
    <div ref={ref} style={style} onMouseOut={onEmptyTooltip} onBlur={onEmptyTooltip}>
      <TooltipContainer
        placement="top"
        style={{
          position: 'absolute',
          top: tooltipY,
          left: tooltipX,
          opacity: tooltip ? 1 : 0,
        }}
      >
        <TooltipArrow placement="top" />
        <TooltipInner>{tooltip}</TooltipInner>
      </TooltipContainer>
      <svg
        width={width}
        height={fixedHeight}
        style={{ marginTop: Math.floor((height - fixedHeight) / 2) }}
      >
        {features.map((f, index) => {
          const { color, value, label } = findCountryInSerie(scale, serie, f.id)
          return (
            <Country
              onMouseMove={createOnCountryMouseMove(label, value)}
              key={index}
              id={`country-${f.id}`}
              d={countryDAttr(f)}
              style={{
                fill: color,
                transition: 'all .1s ease',
              }}
            />
          )
        })}
      </svg>
    </div>
  )
}

const Country = styled.path`
  fill: ${props => (props.theme.isLoading ? placeholder_color : false)};
  ${props => props.theme.isLoading && builtEmptyStateAnimation()}
`
