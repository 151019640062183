import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { Box, BoxBody, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import Loader from 'components/common/loader-legacy'

import { generateUrl } from 'com.batch.common/router'

import { InappActionReview } from './inapp-action-review'
import { FadedLabel, Item, ItemDescription, LinkedContent, TextContent } from './rsr.styles'

import { type State, type AppRecord, type CampaignRecord } from 'com.batch.redux/_records'
import { pushShowVariant } from 'com.batch.redux/campaign.action'
import { type AbTestedInAppRecord } from 'com.batch.redux/content.records'
import { type AbTestedThemeRecord } from 'com.batch.redux/theme.records'

type RSRMobileLandingProps = {
  app: AppRecord
  campaign: CampaignRecord
  languagePicker: React.ReactNode
  variantsThemes: AbTestedThemeRecord
  abTestedInAppContent: AbTestedInAppRecord
}

const RSRMobileLanding = ({
  app,
  campaign,
  languagePicker,
  variantsThemes,
  abTestedInAppContent,
}: RSRMobileLandingProps): React.ReactElement => {
  const dispatch = useDispatch()

  const setActiveVariant = React.useCallback(
    variant => () => {
      dispatch(pushShowVariant(variant))
    },
    [dispatch]
  )

  const variant = useSelector((state: State) => state.campaign.config.abtesting)
  // Pas de a/b sur push landing mais sur la review de push l'user peut choisir d'afficher la version B du push ... ce qui change l'inapp aussi
  const displayedVariant = React.useMemo(
    () => (campaign.type !== 'push' ? variant : 'a'),
    [campaign.type, variant]
  )

  const variantsWeights = React.useMemo(() => {
    const isVariantAActive = campaign?.abtesting.activeVariants.has('a')
    const isVariantBActive = campaign?.abtesting.activeVariants.has('b')
    const variantSize = campaign?.abtesting.activeVariants.size === 2 ? 50 : 100

    return {
      a: isVariantAActive ? variantSize : 0,
      b: isVariantBActive ? variantSize : 0,
    }
  }, [campaign.abtesting])

  const ext = React.useMemo(() => {
    const img = abTestedInAppContent.get(displayedVariant).imageUrl
    const tmp = img.split('.')
    return tmp.length ? tmp[tmp.length - 1] : ''
  }, [abTestedInAppContent, displayedVariant])
  const theme = React.useMemo(
    () => variantsThemes.get(displayedVariant),
    [displayedVariant, variantsThemes]
  )

  return (
    <Box>
      <BoxHeader style={{ padding: '0 10px 0 20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <HeaderBoxTitle title={campaign.type === 'push' ? 'Mobile Landing' : 'Content'} />
          {campaign.abtesting.enabled && campaign.type !== 'push' && (
            <div style={{ display: 'flex', gap: 8 }}>
              <Button isActive={variant === 'a'} onClick={setActiveVariant('a')}>
                Version A ({variantsWeights.a}%)
              </Button>
              <Button isActive={variant === 'b'} onClick={setActiveVariant('b')}>
                Version B ({variantsWeights.b}%)
              </Button>
            </div>
          )}
        </div>
      </BoxHeader>
      <BoxBody>
        <Item name="Language">
          <div style={{ position: 'relative', zIndex: 10, margin: '-4px 0 -4px -4px' }}>
            {languagePicker}
          </div>
        </Item>
        <Item name="Theme" borderTop>
          <Loader loading={!theme} size={'tiny'} height={19} left>
            <LinkedContent
              linkLabel="View theme"
              linkUrl={generateUrl('theme_editor', {
                companyId: app.companyId,
                appId: app.id,
                id: theme ? theme.id : '',
              })}
            >
              {theme ? theme.name : ''}
            </LinkedContent>
          </Loader>
        </Item>

        <Item name="header" borderTop word>
          {abTestedInAppContent.get(displayedVariant).header ? (
            <TextContent>{abTestedInAppContent.get(displayedVariant).header}</TextContent>
          ) : (
            <FadedLabel>—</FadedLabel>
          )}
        </Item>
        <Item name="Title" borderTop word>
          {abTestedInAppContent.get(displayedVariant).title ? (
            <TextContent>{abTestedInAppContent.get(displayedVariant).title}</TextContent>
          ) : (
            <FadedLabel>—</FadedLabel>
          )}
        </Item>
        <Item name="Message" borderTop word>
          {abTestedInAppContent.get(displayedVariant).text ? (
            <TextContent>{abTestedInAppContent.get(displayedVariant).text}</TextContent>
          ) : (
            <FadedLabel>—</FadedLabel>
          )}
        </Item>
        <Item name="Image" borderTop>
          {abTestedInAppContent.get(displayedVariant).imageUrl ? (
            <React.Fragment>
              <LinkedContent
                linkLabel="View image"
                linkUrl={abTestedInAppContent.get(displayedVariant).imageUrl}
              >
                Image <FadedLabel>({ext.toUpperCase()})</FadedLabel>
              </LinkedContent>
              <ItemDescription>
                {abTestedInAppContent.get(displayedVariant).imageAlt
                  ? abTestedInAppContent.get(displayedVariant).imageAlt
                  : '—'}
              </ItemDescription>
            </React.Fragment>
          ) : (
            <FadedLabel>—</FadedLabel>
          )}
        </Item>
        {(theme && theme.fields.has('global')
          ? (['mainButton', 'secondaryButton', 'globalTap'] as const)
          : (['mainButton', 'secondaryButton'] as const)
        ).map(key => {
          return (
            <InappActionReview
              key={key}
              elem={key}
              inappContent={abTestedInAppContent.get(displayedVariant)}
            />
          )
        })}
        <Item name="Tracking ID" borderTop>
          {abTestedInAppContent.get(displayedVariant).trackingId || <FadedLabel>—</FadedLabel>}
        </Item>
      </BoxBody>
    </Box>
  )
}

export default RSRMobileLanding
