export const AGE = 'AGE'
export const AUDIENCE = 'AUDIENCE'
export const BOOLEAN = 'BOOLEAN'
export const DATE = 'DATE'
export const EVENT = 'EVENT'
export const FLOAT = 'FLOAT'
export const INTEGER = 'INTEGER'
export const POSITION = 'POSITION'
export const STRING = 'STRING'
export const TAG = 'TAG'
export const VERSION = 'VERSION'
export const URL = 'URL'

export type OursQLType =
  | 'AGE'
  | 'BOOLEAN'
  | 'DATE'
  | 'EVENT'
  | 'FLOAT'
  | 'INTEGER'
  | 'POSITION'
  | 'STRING'
  | 'TAG'
  | 'VERSION'
  | 'URL'
  | 'AUDIENCE'
