import { pluralize } from 'com.batch.common/utils'
import dayjs, { type Dayjs } from 'dayjs'

export const computeFromTodayLabel = (from: Dayjs): string => {
  const diff = dayjs.utc().diff(from, 'day') + 1
  return diff > 1 ? `Last ${pluralize('day', diff)}` : 'Last day'
}

export const getDateRangeLabel = ({ from, to }: { from: Dayjs; to: Dayjs }): string =>
  to.isSame(dayjs.utc(), 'day')
    ? computeFromTodayLabel(from)
    : `From ${from?.format('MMM Do, YYYY')} to ${to?.format('MMM Do, YYYY')}`
