import { type List, type Map } from 'immutable'

import { parseBounceByDays } from '../infra/parses/parse-bounce-by-days'
import { parseBounceCategories } from '../infra/parses/parse-bounce-categories'
import { parseStatsByChannel } from '../infra/parses/parse-stats-by-channel'
import { parseStatsByPeriod } from '../infra/parses/parse-stats-by-period'
import { parseStatsByProvider } from '../infra/parses/parse-stats-by-provider'
import { parseStatsByStep } from '../infra/parses/parse-stats-by-step'
import { type BounceDetailRecord } from '../models/bounce-detail-record'
import { type EmailProviderRecord } from '../models/orchestration-stats-by-provider.record'
import { type OrchestrationStatsByStepRecord } from '../models/orchestration-stats-by-step.record'
import {
  type OrchestrationStatsRecord,
  type BounceCategoryRecord,
} from '../models/orchestration-stats.record'
import { type PeriodicAnalyticsRecord } from '../store/orchestration-analytics.state'

import { parseStatsByVariant } from 'com.batch/orchestration-analytics/infra/parses/parse-stats-by-variant'
import { type OrchestrationStatsByVariantRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-variant.record'
import { parseOrchestrationStatsToListStatsState } from 'com.batch/orchestration-list/infra/parses/parse-orchestration-stats-to-list-stats-state'
import { type OrchestrationListStatsStateRecord } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'

export type DynamicDimension =
  | 'channel'
  | 'bounce_classification'
  | 'day'
  | 'week'
  | 'month'
  | 'mailbox_provider'
  | 'step'
  | 'content_language'
  | 'orchestration'
  | 'ab_testing_variant'
  | 'ab_testing_status'
  | 'platform'
  | 'label'

export type FilterDimensions = Array<{
  dimensionName: DynamicDimension
  dimensionValues: Array<string>
}>
type GroupByConfig = {
  groupDimensionNames: Array<DynamicDimension>
  includeFilterDimensions?: FilterDimensions
}

export type PlatformFilterDimensionValue = 'IOS' | 'ANDROID' | 'WEBPUSH'

type GroupBy = 'classification' | 'day' | 'mailboxProvider' | 'variant'
const groupBy: {
  [key in GroupBy]: GroupByConfig
} = {
  classification: {
    groupDimensionNames: ['bounce_classification'],
    includeFilterDimensions: [
      {
        dimensionName: 'channel',
        dimensionValues: ['email'],
      },
    ],
  },
  day: {
    groupDimensionNames: ['day'],
    includeFilterDimensions: [
      {
        dimensionName: 'channel',
        dimensionValues: ['email'],
      },
    ],
  },
  mailboxProvider: {
    groupDimensionNames: ['mailbox_provider'],
    includeFilterDimensions: [
      {
        dimensionName: 'channel',
        dimensionValues: ['email'],
      },
    ],
  },
  variant: {
    groupDimensionNames: ['ab_testing_variant'],
    includeFilterDimensions: [
      {
        dimensionName: 'ab_testing_status',
        dimensionValues: ['ONGOING'],
      },
    ],
  },
}

export type FetchDynamicStatsConfig = {
  actionName: string
  groupBy: GroupByConfig
  parser: (stats?: Array<OrchestrationStatsDynamicDimension>) => unknown
}
export type ConfigName =
  | 'orchestrationStatsForEmail'
  | 'orchestrationStatsForSms'
  | 'orchestrationStatsForPush'
  | 'orchestrationStatsByClassification'
  | 'orchestrationStatsByDay'
  | 'orchestrationStatsByProvider'
  | 'orchestrationStatsByStep'
  | 'orchestrationListStats'
  | 'orchestrationStatsByVariant'
  | 'orchestrationStatsByChannelAndPeriod'
export const getStatsConfig = ({
  name,
  periodicity = 'day',
}: {
  name: ConfigName
  periodicity?: 'day' | 'week' | 'month'
}): FetchDynamicStatsConfig => {
  switch (name) {
    case 'orchestrationStatsForEmail':
      return {
        actionName: 'FETCH_ORCHESTRATION_STATS_EMAIL',
        groupBy: {
          groupDimensionNames: ['channel'],
        },
        parser: parseStatsByChannel,
      }
    case 'orchestrationStatsForSms':
      return {
        actionName: 'FETCH_ORCHESTRATION_STATS_SMS',
        groupBy: {
          groupDimensionNames: ['channel'],
        },
        parser: parseStatsByChannel,
      }
    case 'orchestrationStatsForPush':
      return {
        actionName: 'FETCH_ORCHESTRATION_STATS_PUSH',
        groupBy: {
          groupDimensionNames: ['channel'],
        },
        parser: parseStatsByChannel,
      }
    case 'orchestrationStatsByClassification':
      return {
        actionName: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION',
        groupBy: groupBy.classification,
        parser: parseBounceCategories,
      }
    case 'orchestrationStatsByDay':
      return {
        actionName: 'FETCH_ORCHESTRATION_STATS_BY_DAY',
        groupBy: groupBy.day,
        parser: parseBounceByDays,
      }
    case 'orchestrationStatsByProvider':
      return {
        actionName: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER',
        groupBy: groupBy.mailboxProvider,
        parser: parseStatsByProvider,
      }
    case 'orchestrationStatsByStep':
      return {
        actionName: 'FETCH_ORCHESTRATION_STATS_BY_STEP',
        groupBy: {
          groupDimensionNames: ['step', 'channel'],
        },
        parser: parseStatsByStep,
      }
    case 'orchestrationListStats':
      return {
        actionName: 'FETCH_ORCHESTRATIONS_LIST_DATA',
        groupBy: {
          groupDimensionNames: ['orchestration'],
        },
        parser: parseOrchestrationStatsToListStatsState,
      }
    case 'orchestrationStatsByVariant':
      return {
        actionName: 'FETCH_ORCHESTRATION_STATS_BY_VARIANT',
        groupBy: groupBy.variant,
        parser: parseStatsByVariant,
      }
    case 'orchestrationStatsByChannelAndPeriod':
      return {
        actionName: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD',
        groupBy: {
          groupDimensionNames: ['channel', periodicity],
        },
        parser: parseStatsByPeriod(periodicity),
      }
  }
}

// ACTIONS
export type FetchDynamicStatsActionCommon = {
  payload: {
    tokens?: List<string>
    projectKey?: string
  }
}
type FetchDynamicStatsFailureActionCommon = {
  payload: {
    error: {
      message: string
    }
    aborted: boolean
    tokens?: List<string>
    projectKey?: string
  }
}

// FetchOrchestrationStatsEmail
export type FetchOrchestrationStatsEmailAction = {
  type: 'FETCH_ORCHESTRATION_STATS_EMAIL'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsEmailSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_EMAIL_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: OrchestrationStatsRecord
  }
}
export type FetchOrchestrationStatsEmailFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_EMAIL_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsEmailActions =
  | FetchOrchestrationStatsEmailAction
  | FetchOrchestrationStatsEmailSuccessAction
  | FetchOrchestrationStatsEmailFailureAction

// FetchOrchestrationStatsSms
export type FetchOrchestrationStatsSmsAction = {
  type: 'FETCH_ORCHESTRATION_STATS_SMS'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsSmsSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_SMS_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: OrchestrationStatsRecord
  }
}
export type FetchOrchestrationStatsSmsFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_SMS_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsSmsActions =
  | FetchOrchestrationStatsSmsAction
  | FetchOrchestrationStatsSmsSuccessAction
  | FetchOrchestrationStatsSmsFailureAction

// FetchOrchestrationStatsPush
export type FetchOrchestrationStatsPushAction = {
  type: 'FETCH_ORCHESTRATION_STATS_PUSH'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsPushSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_PUSH_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: OrchestrationStatsRecord
  }
}
export type FetchOrchestrationStatsPushFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_PUSH_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsPushActions =
  | FetchOrchestrationStatsPushAction
  | FetchOrchestrationStatsPushSuccessAction
  | FetchOrchestrationStatsPushFailureAction

// FetchOrchestrationStatsByClassification
export type FetchOrchestrationStatsByClassificationAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByClassificationSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: List<BounceCategoryRecord>
  }
}
export type FetchOrchestrationStatsByClassificationFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CLASSIFICATION_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsByClassificationActions =
  | FetchOrchestrationStatsByClassificationAction
  | FetchOrchestrationStatsByClassificationSuccessAction
  | FetchOrchestrationStatsByClassificationFailureAction

// FetchOrchestrationStatsByDay
export type FetchOrchestrationStatsByDayAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_DAY'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByDaySuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_DAY_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: Map<string, BounceDetailRecord>
  }
}
export type FetchOrchestrationStatsByDayFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_DAY_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsByDayActions =
  | FetchOrchestrationStatsByDayAction
  | FetchOrchestrationStatsByDaySuccessAction
  | FetchOrchestrationStatsByDayFailureAction

// FetchOrchestrationStatsByProvider
export type FetchOrchestrationStatsByProviderAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByProviderSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: List<EmailProviderRecord>
  }
}
export type FetchOrchestrationStatsByProviderFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_PROVIDER_FAILURE'
} & FetchDynamicStatsFailureActionCommon
export type FetchOrchestrationStatsByProviderActions =
  | FetchOrchestrationStatsByProviderAction
  | FetchOrchestrationStatsByProviderSuccessAction
  | FetchOrchestrationStatsByProviderFailureAction

// FetchOrchestrationStatsByStep
export type FetchOrchestrationStatsByStepAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_STEP'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByStepSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_STEP_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: OrchestrationStatsByStepRecord
  }
}
export type FetchOrchestrationStatsByStepFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_STEP_FAILURE'
} & FetchDynamicStatsFailureActionCommon

export type FetchOrchestrationStatsByStepActions =
  | FetchOrchestrationStatsByStepAction
  | FetchOrchestrationStatsByStepSuccessAction
  | FetchOrchestrationStatsByStepFailureAction

// FetchOrchestrationStatsByVariant
export type FetchOrchestrationStatsByVariantAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_VARIANT'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByVariantSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_VARIANT_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: OrchestrationStatsByVariantRecord
  }
}
export type FetchOrchestrationStatsByVariantFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_VARIANT_FAILURE'
} & FetchDynamicStatsFailureActionCommon

export type FetchOrchestrationStatsByVariantActions =
  | FetchOrchestrationStatsByVariantAction
  | FetchOrchestrationStatsByVariantSuccessAction
  | FetchOrchestrationStatsByVariantFailureAction

// FetchOrchestrationListStats
export type FetchOrchestrationsDataAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationsDataSuccessAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: Map<string, OrchestrationListStatsStateRecord>
  }
}
export type FetchOrchestrationsDataFailureAction = {
  type: 'FETCH_ORCHESTRATIONS_LIST_DATA_FAILURE'
} & FetchDynamicStatsFailureActionCommon

export type FetchOrchestrationsDataActions =
  | FetchOrchestrationsDataAction
  | FetchOrchestrationsDataSuccessAction
  | FetchOrchestrationsDataFailureAction

// FetchOrchestrationStatsByChannelAndPeriod
export type FetchOrchestrationStatsByChannelAndPeriodAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD'
} & FetchDynamicStatsActionCommon
export type FetchOrchestrationStatsByChannelAndPeriodSuccessAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD_SUCCESS'
  payload: {
    tokens?: List<string>
    projectKey?: string
    parsed: Map<ChannelUntilCleanup, PeriodicAnalyticsRecord>
  }
}
export type FetchOrchestrationStatsByChannelAndPeriodFailureAction = {
  type: 'FETCH_ORCHESTRATION_STATS_BY_CHANNEL_AND_PERIOD_FAILURE'
} & FetchDynamicStatsFailureActionCommon

export type FetchOrchestrationStatsByChannelAndPeriodActions =
  | FetchOrchestrationStatsByChannelAndPeriodAction
  | FetchOrchestrationStatsByChannelAndPeriodSuccessAction
  | FetchOrchestrationStatsByChannelAndPeriodFailureAction
