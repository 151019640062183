import styled from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

export const SeatsInfos = styled.div`
  margin: 14px 0 0 0;
  font-size: 1em;
  font-weight: 500;
  color: ${colors.textLight};

  strong {
    display: inline-block;
    margin: -3px 1px;
    padding: 3px 5px;
    font-family: 'SF Mono', monospace;
    font-weight: 500;
    color: ${colors.text};
    background: ${schemes.darklucent['10']};
    border-radius: 3px;
  }
`

export const UserName = styled.div`
  color: ${colors.text};
  line-height: 20px;
  flex: 0 0 20px;
  margin-top: -3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
`
export const UserNameInline = styled.div`
  color: ${colors.text};
`

export const InvitationTag = styled.span`
  height: 18px;
  font-size: 12px;
  line-height: 12px;
  padding: 3px 3px;
  display: inline-block;
  color: ${colors.textNeutral};
  background: ${schemes.darklucent['30']};
  border-radius: 3px;
  align-self: flex-start;
  margin-bottom: -2px;
`

export const UserIdentifierInline = styled.div`
  display: flex;
  align-items: baseline;
`
export const UserIdentifier = styled.div`
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  height: 34px;
  min-width: 0;
  margin: -3px 0;
`

export const UserEmail = styled.div`
  line-height: 20px;
  flex: 0 0 20px;
  color: ${colors.textLight};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const UserEmailInline = styled.div`
  color: ${colors.textLight};
  margin-left: 10px;
`
