import {
  VolumeDataFactory,
  DailyVolumeFactory,
  type VolumeDataRecord,
  type DailyVolumeRecord,
} from 'com.batch/account/model/volumes.records'
import {
  type ChannelSent,
  type FetchMonthlySentResponse,
} from 'com.batch/shared/infra/types/grpc-stats-service'

export type FetchMonthlySentResult = {
  email: VolumeDataRecord
  sms: VolumeDataRecord
}

const parseDailyVolume = (data: ChannelSent): Array<DailyVolumeRecord> =>
  data.dailyCumulativeSent.map(d =>
    DailyVolumeFactory({
      date: d.date,
      sent: d.sent ?? 0,
      cumulativeSent: d.cumulativeSent ?? 0,
    })
  )

export const parseMonthlySentResponse = (
  response: FetchMonthlySentResponse
): FetchMonthlySentResult => {
  return {
    email: VolumeDataFactory({
      total: response.emails.total || 0,
      daily: parseDailyVolume(response.emails),
    }),
    sms: VolumeDataFactory({
      total: response.sms.total || 0,
      daily: parseDailyVolume(response.sms),
    }),
  }
}
