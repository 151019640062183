import * as React from 'react'
import styled from 'styled-components'

import { Box, BoxBody, BoxSection, BoxFooter } from 'components/common/box'
import { Code } from 'components/common/code'
import {
  Wrapper,
  SettingsTranascDebugIcon,
  EmptyField,
  Skeleton,
  GlobalErrorOverlayProps,
} from 'components/common/empty-states'
import { FlexLine } from 'components/common/flexline'
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableRow,
  TableCell,
  TableBody,
  TableTemplateCell,
} from 'components/common/table'
import Tag from 'components/common/tag'
import { Tooltip } from 'components/common/tooltip'
import * as colorsLegacy from 'components/styled/colors'
import { Ellipsis, CapsLabel, Value, Timezone } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { randomSize, kformat } from 'com.batch.common/utils'

import { type AppRecord } from 'com.batch.redux/_records'
import { nomalizeDebugTransac } from 'com.batch.redux/stat.api.debug'
import { type TransacResponseProps, type DebugTransacRecord } from 'com.batch.redux/stat.records'

type DebugTransacProps = {
  app: AppRecord
  rec: DebugTransacRecord
}

const fakeData = {
  success: [
    {
      date: 1554454968000,
      token: 'dv3fe633094cba060896072fda9a96fa916aee592348168468401e00e398640a',
      sent_date: 1554454968453,
    },
    {
      date: 1554454968000,
      token: 'qqqfe633094cba060896072fda9a96fa916aee592348168468401e00e398640a',
      sent_date: 1554454968953,
    },
    {
      date: 1554454968000,
      token: 'de3cx4cba060896072fda9a96fa916aee592348168468401e00456e398640a',
      sent_date: 1554454969833,
    },
    {
      date: 1554454968000,
      token: '489fe633094cba060896072fda9a96dsaafffe8168468401e00e39778640a',
      sent_date: 1554454969833,
    },
    {
      date: 1554454968000,
      token: 'de3fe633094cba060896072fda9a96fa916aee7fds8468401e00e398640a',
      sent_date: 1554454969833,
    },
    {
      date: 1554454968000,
      token: 'bv3fe633094cba060fds9a9a96fa916aee592348168468401e00e398640a',
      sent_date: 1554454970833,
    },
    {
      date: 1554454968000,
      token: 'be89e6m3094cba060dqsdsq6da9a96fa916aee592348168468401e00e398640b',
      sent_date: 1554454970833,
    },
    {
      date: 1554454968000,
      token: 'de3fe633094cba06089dddzfzaa96fa916aee592348168468401e00e39864cs4',
      sent_date: 1554454968833,
    },
    {
      date: 1554454968000,
      token: 'de3fe633094cba060896072fda9a96fa916aee592348168468401e00e398678ez',
      sent_date: 1661780480106,
    },
  ],
  failure: [],
  feedback: [],
  summary: {
    nb_success: 10,
    nb_failure: 0,
    not_found: 10,
    group_id: '11',
    unregistered: 0,
    failure: {},
  },
  input_nb_recipient: 21,
  error: false,
  input: {
    apiKey: { key: '5353545454545455545EDFF666' },
    transacId: 'df9155fd-9b05-442a-b690-3e9192f60d2e',
    groupId: 'new_follower',
    pushDate: 1554454968000,
    requestId: '35579ee3-aa83-4427-bf15-96c958fd5cc6',
  },
  input_recipient_type: ['CUSTOM_ID'],
  input_recipients: [
    'jcoulthart0@google.ca',
    'drenachowski1@webmd.com',
    'mzeal2@g.co',
    'kilyinski3@weebly.com',
    'ksalla4@sitemeter.com',
    'ssouthern5@craigslist.org',
    'eeardley6@techcrunch.com',
    'ascrancher7@ft.com',
    'apilpovic8@google.com.au',
    'kalthropez9@pagesperso-orange.fr',
  ],
  requestId: '99579ee3-aa83-4427-bf15-96c958fd5cc6',
}

const fakeDataRecord = nomalizeDebugTransac(fakeData)

const DebugTransac = ({ app, rec }: DebugTransacProps): React.ReactElement => {
  // ====================== Component constants
  const loading = rec.loading
  const error = rec.results ? rec.results.error : false
  const errorSummary: string = rec.results ? rec.results.failureMessage : ''
  const active = rec.query

  const isEmpty = React.useMemo(
    () => !active || error || !rec.results,
    [rec.results, active, error]
  )

  const data: TransacResponseProps = React.useMemo(() => {
    return rec.results && !isEmpty ? rec.results : fakeDataRecord
  }, [isEmpty, rec.results])

  const labelsList = app.cappingCategories

  // ====================== Callbacks
  const fmt = (datejs: number) => {
    return datejs ? dayjs.utc(datejs).format('DD/MM/YYYY HH:mm:ss') : '-'
  }

  const emptyContent = React.useMemo(() => loading || isEmpty || error, [isEmpty, error, loading])
  return (
    <Wrapper
      isEmpty={isEmpty}
      isLoading={loading}
      isOverlayShown={(isEmpty || error) && !loading}
      overlayProps={
        active && rec.results
          ? noResultOverlayProps(active)
          : error
            ? GlobalErrorOverlayProps
            : emptyTransacProps
      }
    >
      <Box>
        <BoxBody>
          <DebugMetricsSection>
            <TransacMetric style={{ flex: '1 0 auto' }}>
              <CapsLabel>
                <Skeleton w={59} h={12}>
                  Group ID
                </Skeleton>
              </CapsLabel>
              <Value>
                <Skeleton w={136} h={23}>
                  {data.groupId}
                </Skeleton>
              </Value>
            </TransacMetric>
            <TransacMetric>
              <CapsLabel>
                <Skeleton w={84} h={12}>
                  Request date
                </Skeleton>
              </CapsLabel>
              <Value>
                <Skeleton w={260} h={23}>
                  <React.Fragment>
                    {fmt(data.pushDate)} <Timezone>UTC</Timezone>{' '}
                    {data.apiKey && data.apiKey.substring(0, 3) === 'DEV' && (
                      <Timezone>DEV</Timezone>
                    )}
                  </React.Fragment>
                </Skeleton>
              </Value>
            </TransacMetric>
            <FlexLine>
              <TransacMetric>
                <CapsLabel>
                  <Skeleton w={32} h={12}>
                    Sent
                  </Skeleton>
                </CapsLabel>
                <Value style={{ color: colorsLegacy.good.shade.s4 }}>
                  <Skeleton w={32} h={23}>
                    {data.successCount}
                  </Skeleton>
                </Value>
              </TransacMetric>
              <TransacMetric>
                <CapsLabel>
                  <Skeleton w={67} h={12}>
                    Not found
                  </Skeleton>
                </CapsLabel>
                <Value>
                  <Skeleton w={23} h={23}>
                    {data.notFoundCount}
                  </Skeleton>
                </Value>
              </TransacMetric>
              <TransacMetric>
                <CapsLabel>
                  <Skeleton w={92} h={12}>
                    Uninstalls
                  </Skeleton>
                </CapsLabel>
                <Value style={{ color: colors.textAction }}>
                  <Skeleton w={23} h={23}>
                    {data.unregistredCount}
                  </Skeleton>
                </Value>
              </TransacMetric>
              <TransacMetric>
                <CapsLabel>
                  <Skeleton w={45} h={12}>
                    Errors
                  </Skeleton>
                </CapsLabel>
                <Tooltip tooltip={errorSummary} isTooltipEmpty={!errorSummary.length}>
                  <Value style={{ color: colors.textDanger }}>
                    <Skeleton w={23} h={23}>
                      {data.failureCount}
                    </Skeleton>
                  </Value>
                </Tooltip>
              </TransacMetric>
            </FlexLine>
          </DebugMetricsSection>
          <Table template="1fr 200px 180px">
            <TableHeader>
              <TableCellHeader>
                <Skeleton w={247} h={13}>
                  Push tokens (first ten per sent and/or error)
                </Skeleton>
              </TableCellHeader>
              <TableCellHeader>
                <Skeleton w={115} h={14}>
                  <React.Fragment>
                    Request date <Timezone style={{ margin: '0 0 0 6px' }}>UTC</Timezone>
                  </React.Fragment>
                </Skeleton>
              </TableCellHeader>
              <TableCellHeader>
                <Skeleton w={115} h={14}>
                  <React.Fragment>
                    Send date<Timezone style={{ margin: '0 0 0 6px' }}>UTC</Timezone>
                  </React.Fragment>
                </Skeleton>
              </TableCellHeader>
            </TableHeader>

            <TableBody emptyTemplate={<EmptyTableTemplate />} templateSize={9}>
              {data.success &&
                data.success.map((s, i) => (
                  <TableRow key={`success-${i}`}>
                    <TableCell noOverflow>
                      <Ellipsis>{s.token}</Ellipsis>
                    </TableCell>
                    <TableCell>{fmt(s.date)}</TableCell>
                    <TableCell>{fmt(s.sent_date)}</TableCell>
                  </TableRow>
                ))}
              {data.failure &&
                data.failure.map((s, i) => (
                  <TableRow key={`failure-${i}`}>
                    <TableCell noOverflow>
                      <Ellipsis>{s.token}</Ellipsis>
                    </TableCell>
                    <TableCell>{fmt(s.date)}</TableCell>
                    <TableCell>{fmt(s.sent_date)}</TableCell>
                  </TableRow>
                ))}
              {data.feedback &&
                data.feedback.map((s, i) => (
                  <TableRow key={`feedback-${i}`}>
                    <TableCell noOverflow>
                      <Ellipsis>{s.token}</Ellipsis>
                    </TableCell>
                    <TableCell>{fmt(s.date)}</TableCell>
                    <TableCell>{fmt(s.sent_date)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </BoxBody>
        <BoxFooter style={{ height: 'auto' }}>
          <CapsLabel>
            <Skeleton w={147} h={14}>
              <div style={{ minWidth: 300 }}>
                Targeting {kformat(data.recipientsCount)} recipient
                {data.recipientsCount > 1 && 's'} by{' '}
                {data.recipientsType.map(kind => (
                  <Tag key={kind} style={{ margin: '0 2px', fontSize: 11, fontWeight: 600 }}>
                    {kind}
                  </Tag>
                ))}
              </div>
            </Skeleton>
          </CapsLabel>
          <span>
            {emptyContent ? (
              <React.Fragment>
                <EmptyField _width="59%" _height={14} />
                <EmptyField _width="54%" _height={14} />
              </React.Fragment>
            ) : (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data.recipientsSample.map(id => (
                  <Code style={{ marginRight: 10, minWidth: 0, maxWidth: 300 }}>
                    <Ellipsis>{id}</Ellipsis>
                  </Code>
                ))}
                {data.recipientsSample.length < data.recipientsCount && (
                  <strong>
                    {' '}
                    and {data.recipientsCount - data.recipientsSample.length} other
                    {data.recipientsCount - data.recipientsSample.length > 1 ? 's' : ''}.
                  </strong>
                )}
              </div>
            )}
          </span>
          {data.apiKey.substring(0, 3) === 'DEV' && (
            <Tag type="label" style={{ margin: '0 2px', fontSize: 11, fontWeight: 600 }}>
              DEV
            </Tag>
          )}
          {data.labels.length > 0 && (
            <React.Fragment>
              <CapsLabel style={{ marginTop: 25 }}>Marketing labels </CapsLabel>
              <p>
                {data.labels.map(id => labelsList.find(l => l.code === id)?.name ?? id).join(', ')}
              </p>
            </React.Fragment>
          )}
        </BoxFooter>
      </Box>
    </Wrapper>
  )
}

const emptyTransacProps = {
  status: 'empty-page' as 'error' | 'empty-page' | 'empty' | 'error-page',
  title: 'Look up a transactional token',
  description: 'Enter a transactional token in the search field to get the transactional responses',
  content: <SettingsTranascDebugIcon />,
  links: [
    {
      name: 'Learn more about transactional',
      href: 'https://doc.batch.com/api/transactional.html#_debug',
    },
    {
      name: 'Learn more about debug',
      href: 'https://help.batch.com/en/articles/3595647-how-can-i-use-the-transactional-response-token-to-debug-a-transactional-api-call',
    },
  ],
}
const noResultOverlayProps = (token?: string) => ({
  status: 'empty-page' as 'error' | 'empty-page' | 'empty' | 'error-page',
  title: 'No results found',
  description: (
    <React.Fragment>
      We haven't been able to find any transactional response matching this token{' '}
      <code>{token}</code>
    </React.Fragment>
  ),
  content: <SettingsTranascDebugIcon />,
  links: [
    {
      name: 'Learn more about transactional',
      href: 'https://doc.batch.com/api/transactional.html#_debug',
    },
    {
      name: 'Learn more about debug',
      href: 'https://help.batch.com/en/articles/3595647-how-can-i-use-the-transactional-response-token-to-debug-a-transactional-api-call',
    },
  ],
})

const EmptyTableTemplateBase = () => {
  return (
    <React.Fragment>
      <TableTemplateCell template={`${randomSize(50, 90)}%`} />
      <TableTemplateCell template={`${randomSize(80, 90)}%`} />
      <TableTemplateCell template={`${randomSize(80, 90)}%`} />
    </React.Fragment>
  )
}
const EmptyTableTemplate = React.memo(EmptyTableTemplateBase)

const TransacMetric = styled.div`
  flex: 0 0 auto;
  padding: 16px 36px 15px 24px;
  overflow: hidden;

  &:last-child {
    padding: 16px 24px 15px 24px;
  }

  ${CapsLabel} {
    display: block;
    margin: 0 0 8px 0;
    white-space: nowrap;
  }

  ${Value} {
    display: block;
  }
`

const DebugMetricsSection = styled(BoxSection)`
  display: flex;
  flex-wrap: wrap;
`

export default DebugTransac
