import styled, { css } from 'styled-components'

import { LoadingSvgAnim } from 'components/common/empty-states'
import { schemes, texts } from 'components/styled/tokens'

export const Graduation = styled.text`
  ${texts.legend}
  fill: ${schemes.darklucent['30']};
  text-anchor: end;
`

type GraduationSkelettonProps = {
  isLoading?: boolean
  isEmpty?: boolean
}
export const GraduationSkeletton = styled.rect<GraduationSkelettonProps>`
  ${p =>
    p.isLoading &&
    css`
      animation-name: ${LoadingSvgAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}

  ${p =>
    p.isEmpty &&
    css`
      fill: ${schemes.grayscale['10']};
    `}
`
