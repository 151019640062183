import { type ScaleLinear } from 'd3-scale'
import { type List } from 'immutable'
import * as React from 'react'

import { kformat } from 'com.batch.common/utils'

import { ChartScale, ChartScaleSkeleton } from './styles'

type YAxisProps = {
  values: List<number>
  yScale: ScaleLinear<number, number>
  marginRight: number
  width: number
  loading?: boolean
}

const YAxisBase = ({
  values,
  yScale,
  marginRight,
  width,
  loading,
}: YAxisProps): React.ReactElement => {
  return (
    <g>
      {values.map((value, key) => {
        return (
          <g key={key}>
            {key === 1 && values.size === 3 && (
              <line
                x1={0}
                stroke="#C6D3DF"
                strokeOpacity="0.25"
                x2={width - marginRight}
                y1={yScale(value)}
                y2={yScale(value)}
              />
            )}
            {loading ? (
              <ChartScaleSkeleton
                x={key === 1 ? width - 50 : width - 45}
                y={yScale(value) + (key === 0 ? -15 : key === 1 ? 0 : 15)}
                rx={2}
                fill="#b8b8b8"
                width={key === 1 ? 40 : 35}
                height={13}
              />
            ) : (
              <ChartScale
                x={width - 10}
                textAnchor="end"
                y={yScale(value) + (key === 0 ? -5 : key === 1 ? 4 : 12)}
                fill="#949AA3"
                stroke="none"
              >
                {kformat(value)}
              </ChartScale>
            )}
          </g>
        )
      })}
    </g>
  )
}

export const YAxis: React.ComponentType<YAxisProps> = React.memo<YAxisProps>(YAxisBase)
