import * as React from 'react'

import { AttributeRow } from 'com.batch/profile/ui/components/attributes-block/attribute-row'

type NumberAttributeProps = {
  name: string
  value?: number
  isCopyable?: boolean
}

export const NumberAttributeRow = ({
  name,
  value,
  isCopyable = true,
}: NumberAttributeProps): React.ReactElement => {
  return (
    <AttributeRow
      name={name}
      icon="number"
      copyableValue={`${value ?? ''}`}
      isCopyable={isCopyable}
    >
      {value}
    </AttributeRow>
  )
}
