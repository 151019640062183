import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useTheme } from 'styled-components'

import { useAction, useToggle } from 'components/_hooks'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { SwitchButton } from 'components/common/button'
import { Input, InputWrapper } from 'components/form'
import { LegacyLangPicker } from 'components/project/campaign/common/legacy-lang-picker/legacy-lang-picker'
import { LegacyPushBuilder } from 'components/project/campaign/push/preview/legacy-push-builder'

import * as Styles from './message.styles'

import {
  addTranslation,
  removeTranslation,
  setActiveTranslation,
  togglePushAbTesting,
} from 'com.batch.redux/campaign.action'
import {
  activeLanguageSelector,
  campaignHasAbTesting,
  pickableLanguagesSelector,
  pickedLanguagesSelector,
} from 'com.batch.redux/campaign.selector'
import { updatePushSettings } from 'com.batch.redux/content'
import { pushSettingsSelector } from 'com.batch.redux/content.selector'
import { validTranslationsIdSelector } from 'com.batch.redux/project-error.selector'

import {
  type ActionType,
  PushActionPicker,
} from 'com.batch/push/ui/push-action-picker/push-action-picker'

type MessageProps = Record<any, any>

const actions = Immutable.List<ActionType>([
  {
    label: 'Redirect to app',
    description: 'Head to the app home screen',
    value: 'nothing',
  },
  {
    label: 'Open deeplink',
    description: 'Open a specific link/screen in the app',
    value: 'deeplink',
  },
  // { label: 'Open landing', value: 'landing' }
])

export const Message: React.ComponentType<MessageProps> = (): React.ReactElement => {
  // ----------- redux ---
  const dispatch = useDispatch()
  const language = useSelector(activeLanguageSelector)
  const abTestingEnabled = useSelector(campaignHasAbTesting)
  const pickableLanguages = useSelector(pickableLanguagesSelector)
  const pickedLanguages = useSelector(pickedLanguagesSelector)
  const validLanguagesId = useSelector(validTranslationsIdSelector)
  const pushSettings = useSelector(pushSettingsSelector)

  // --- ------- local state
  const languagePickerState = useToggle()
  const [onOpen, setOnOpen] = React.useState<'nothing' | 'deeplink' | 'landing'>('nothing')

  // ----------- callbacks ---
  const updatePushSettingsBound = React.useCallback(
    (field, value) => dispatch(updatePushSettings(field, value)),
    [dispatch]
  )

  const addTranslationBound = useAction(addTranslation)

  const removeTranslationBound = useAction(removeTranslation)
  const setActiveTranslationBound = useAction(setActiveTranslation)
  const togglePushAbTestingBound = useAction(togglePushAbTesting)
  const onTogglePushAbTesting = React.useCallback(() => {
    togglePushAbTestingBound(!abTestingEnabled)
  }, [abTestingEnabled, togglePushAbTestingBound])
  const onDeeplinkChanged = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      updatePushSettingsBound('deeplink', evt.target.value)
    },
    [updatePushSettingsBound]
  )
  const onActionChanged = React.useCallback(o => o && setOnOpen(o.value), [])

  const toggleMultiLanguageAndRemoveTranslation = React.useCallback(() => {
    if (languagePickerState.value) {
      pickedLanguages
        .filter(lang => lang.value !== 'default')
        .forEach(lang => {
          removeTranslationBound({ lang })
        })
    }
    languagePickerState.toggle()
  }, [languagePickerState, pickedLanguages, removeTranslationBound])

  // ----------- Upload junk ---

  // ====================== Component constants

  const theme = useTheme()
  return (
    <Box id="push" style={{ marginTop: 35 }}>
      <BoxHeader>
        <HeaderBoxTitle title="Message" />
        <HeaderBoxActions>
          <Styles.MessageActions>
            <SwitchButton
              disabled={theme?.disabledMode}
              isActive={languagePickerState.value}
              onChange={toggleMultiLanguageAndRemoveTranslation}
            >
              Multi-language
            </SwitchButton>
            <hr />
            <SwitchButton
              disabled={theme?.disabledMode}
              isActive={abTestingEnabled}
              onChange={onTogglePushAbTesting}
            >
              A/B testing
            </SwitchButton>
          </Styles.MessageActions>
        </HeaderBoxActions>
      </BoxHeader>
      <BoxBody>
        {languagePickerState.value && (
          <Styles.MessageLanguages>
            <LegacyLangPicker
              activeTranslation={language}
              addTranslation={addTranslationBound}
              pickableLanguages={pickableLanguages}
              pickedLanguages={pickedLanguages}
              removeTranslation={removeTranslationBound}
              setActiveTranslation={setActiveTranslationBound}
              validLanguagesId={validLanguagesId}
              defaultLanguageId={null}
            />
          </Styles.MessageLanguages>
        )}

        <LegacyPushBuilder />
      </BoxBody>
      <BoxFooter style={{ padding: '19px 24px 18px', height: 'auto' }}>
        <InputWrapper>
          <PushActionPicker
            value={actions.find(o => o.value === onOpen) as ActionType}
            onChange={onActionChanged}
            options={actions}
          >
            {onOpen === 'deeplink' && (
              <Input
                value={pushSettings.deeplink}
                onChange={onDeeplinkChanged}
                placeholder="app://my-custom-deeplink"
                style={{ flex: '1 1 auto', marginTop: 25 }}
                autoFocus
                aria-label="deeplink"
              />
            )}
          </PushActionPicker>
        </InputWrapper>
      </BoxFooter>
    </Box>
  )
}
