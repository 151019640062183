import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type CountOrchestrationsUsingSIAction = {
  type: 'COUNT_ORCHESTRATIONS_USING_SI'
}

type CountOrchestrationsUsingSISuccessAction = {
  type: 'COUNT_ORCHESTRATIONS_USING_SI_SUCCESS'
  payload: number
}
type CountOrchestrationsUsingSIFailureAction = {
  type: 'COUNT_ORCHESTRATIONS_USING_SI_FAILURE'
}

export type CountBySenderIdentityActions =
  | CountOrchestrationsUsingSIAction
  | CountOrchestrationsUsingSISuccessAction
  | CountOrchestrationsUsingSIFailureAction

export const countBySenderIdentity = (id: number): DispatchExtraBoundFn<Promise<number>> => {
  return (dispatch, getState, { senderIdentityGateway }) => {
    const project = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: senderIdentityGateway.countBySenderIdentity(project.projectKey, id),
      actionName: 'COUNT_ORCHESTRATIONS_USING_SI',
    })
  }
}
