import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'
import { ThemeProvider } from 'styled-components'

import { Button } from 'components/common/button'
import { Sticky } from 'components/common/scroll'

import { FiltersContainer } from './filters.styles'

import {
  updateAnalyticsFilters,
  updateAnalyticsDateRange,
} from 'com.batch/orchestration-analytics/usecases/analytics-filters'

type FilterBarProps = {
  children: React.ReactNode
  filterCount: number
  token?: string
  isClearButtonDisabled?: boolean
}

export const FilterBar = ({
  children,
  filterCount,
  token,
  isClearButtonDisabled,
}: FilterBarProps): React.ReactNode => {
  const dispatch = useDispatch()

  const clearFilters = React.useCallback(() => {
    if (token) {
      dispatch(updateAnalyticsFilters({ token, filters: Immutable.Map() }))
      dispatch(updateAnalyticsDateRange({ token, dateRange: null }))
    }
  }, [dispatch, token])

  const [isStuck, setIsStuck] = React.useState(false)

  return (
    <ThemeProvider theme={{ kind: 'filter' }}>
      <Sticky onStickyChange={setIsStuck}>
        <FiltersContainer $isStuck={isStuck}>
          {children}
          {filterCount > 1 && (
            <Button
              onClick={clearFilters}
              disabled={isClearButtonDisabled}
              style={{ flexShrink: 0 }}
              data-track="analytics-filter-clear"
            >
              Clear filters
            </Button>
          )}
        </FiltersContainer>
      </Sticky>
    </ThemeProvider>
  )
}
