import styled from 'styled-components'

import { type ButtonProps, Button } from 'components/common/button/button.styles'
import { schemes } from 'components/styled/tokens'

export const PlatformsIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${schemes.blue['00']};
  padding: 0 8px;
  height: 28px;
`

export const SelectPlatformToggle = styled(Button)<ButtonProps>`
  background-color: ${schemes.blue['00']};
  height: 28px;
  padding: 0 8px !important;

  &:hover,
  &:focus,
  &:active {
    background-color: #d6dfe9;
  }

  ${p => p.isActive && 'background-color: #d6dfe9 !important;'}
`
