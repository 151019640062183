import { get } from 'lodash-es'
import styled from 'styled-components'

import * as colorsLegacy from 'components/styled/colors'
import { colors } from 'components/styled/tokens'

// ====================== Some constants
const COLORS = {
  success: colorsLegacy.good.shade.s4,
  error: colors.textDanger,
  loading: colors.textAction,
  insight: colors.textLight,
  warning: '#ee8749',
}

// ====================== STYLED COMPONENTS
type FeedbackContainerProps = {
  _type: keyof typeof COLORS
  $fontWeight?: number
} & React.ComponentProps<'div'>
const FeedbackContainer = styled.div<FeedbackContainerProps>`
  display: inline-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1em;
  font-weight: ${p => p.$fontWeight || 400};
  color: ${p => get(COLORS, p._type, COLORS.insight)};
  line-height: 1.4em;
  .styled-feedback-icon {
    margin: 0 10px 0 2px;
    font-size: calc(1em - 1px);
  }
`

export { FeedbackContainer }
