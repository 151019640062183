import { type Set } from 'immutable'
import * as React from 'react'

import { Button } from 'components/common/button/button.styles'
import { useDropdown, DropdownMenu } from 'components/common/button/dropdown'
import { DropdownSeparator } from 'components/common/button/dropdown.styles'
import { Icon } from 'components/common/svg-icon'

import {
  PushFilterOption,
  PushFilterButton,
} from 'com.batch/shared/ui/component/filter/push-platform-filter/push-platform-filter.styles'

const labels = {
  mobile: 'Mobile app',
  ios: 'iOS',
  android: 'Android',
  webpush: 'Website',
}
const platformOrder = ['ios', 'android', 'webpush']

export type PlatformFilterOption = 'mobile' | 'ios' | 'android' | 'webpush'

type PlatformFilterProps = {
  selectedPlatformOption: PlatformFilterOption
  setSelectedPlatformOption: (arg1: PlatformFilterOption) => void
  platforms: Set<ProjectPlatforms>
  context: 'campaigns' | 'automations' | 'project'
}
export const PlatformFilter = ({
  selectedPlatformOption,
  setSelectedPlatformOption,
  platforms,
  context,
}: PlatformFilterProps): React.ReactElement => {
  const hasBothMobilePlatforms = React.useMemo(
    () => platforms.has('ios') && platforms.has('android'),
    [platforms]
  )

  const createOnPlatformChange = React.useCallback(
    (opt: PlatformFilterOption) => () => {
      setSelectedPlatformOption(opt)
    },
    [setSelectedPlatformOption]
  )

  const sortedPlatforms = React.useMemo(
    () => platforms.sort((a, b) => platformOrder.indexOf(a) - platformOrder.indexOf(b)),
    [platforms]
  )

  const { dropdownProps, triggerProps } = useDropdown(
    context !== 'project' ? { forcedWidth: 232 } : {}
  )

  const optionToString = React.useCallback(
    opt =>
      `${labels[opt]}${hasBothMobilePlatforms && ['ios', 'android'].includes(opt) ? ' only' : ''}`,
    [hasBothMobilePlatforms]
  )

  return (
    <React.Fragment>
      <PushFilterButton
        kind="inline"
        addOn="prefix"
        addOnGap={8}
        {...triggerProps}
        aria-label="Toggle platform filter selection dropdown"
        isActive={dropdownProps.isOpen}
        style={{ flexShrink: 0, ...(context === 'project' ? { width: '100%' } : {}) }}
      >
        <Icon icon="filter" />
        <span>{optionToString(selectedPlatformOption)}</span>
      </PushFilterButton>
      <DropdownMenu {...dropdownProps}>
        {hasBothMobilePlatforms && (
          <PushFilterOption kind="categorie">
            <Button
              kind="inline"
              onClick={createOnPlatformChange('mobile')}
              isActive={selectedPlatformOption === 'mobile'}
            >
              <Icon icon="mobile" />
              {optionToString('mobile')}
            </Button>
          </PushFilterOption>
        )}
        {sortedPlatforms.map(platform => {
          const isMobile = ['ios', 'android'].includes(platform)
          return (
            <React.Fragment key={`platform-filter-option-${platform}`}>
              {!isMobile && hasBothMobilePlatforms && <DropdownSeparator />}
              <PushFilterOption
                kind={isMobile && hasBothMobilePlatforms ? 'subCategorie' : 'categorie'}
              >
                <Button
                  kind="inline"
                  onClick={createOnPlatformChange(platform)}
                  isActive={selectedPlatformOption === platform}
                >
                  <Icon icon={platform} />
                  {optionToString(platform)}
                </Button>
              </PushFilterOption>
            </React.Fragment>
          )
        })}
      </DropdownMenu>
    </React.Fragment>
  )
}
