import Immutable from 'immutable'
import * as React from 'react'
import { useCallback } from 'react'

import { DateRangePicker } from 'components/form'

import { type DateRange, dayjs } from 'com.batch.common/dayjs.custom'
import * as predefinedRanges from 'com.batch.common/predefined-ranges'

type DateFilterProps = {
  value: DateRange | null | undefined
  onChange: (arg1?: DateRange | null | undefined) => void
}

const TOMORROW = dayjs.utc().add(1, 'day').startOf('day')
const disabledDays = (date: Date) => TOMORROW.isBefore(date)

export const DataRangeFilter = ({ onChange, value }: DateFilterProps): React.ReactElement => {
  const ranges = Immutable.List([
    predefinedRanges.last7Days,
    predefinedRanges.lastMonth,
    predefinedRanges.last3Months,
    predefinedRanges.last6Months,
    predefinedRanges.lastYear,
  ])

  const onClose = useCallback(dateRange => onChange(dateRange), [onChange])

  return (
    <DateRangePicker
      range={value}
      setRange={onChange}
      shortcuts={ranges}
      singleDayRange
      icon="calendar"
      placeholder="All dates"
      hasInputs
      changePlaceholderOnFocus
      disabledDays={disabledDays}
      onClose={onClose}
    />
  )
}
