import * as React from 'react'
import styled from 'styled-components'

import { colors } from 'components/styled/tokens'

const themes = {
  action: {
    bg: colors.opacifyAction,
    fc: colors.textAction,
  },
  feature: {
    bg: colors.opacifyFeature,
    fc: colors.fillFeature,
  },
}
type TagContainerProps = {
  $kind: keyof typeof themes
}
const TagContainer = styled.span<TagContainerProps>`
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: ${p => themes[p.$kind].fc};
  padding: 4px 7px;
  border-radius: 100px;
  background: ${p => themes[p.$kind].bg};

  & + & {
    margin: 0 0 0 5px;
  }
`

export type TagProps = {
  intent?: keyof typeof themes
  children: React.ReactNode
}

export const Tag = ({ children, intent, ...rest }: TagProps): React.ReactElement => (
  <TagContainer {...rest} $kind={intent || 'action'}>
    {children}
  </TagContainer>
)
