import styled from 'styled-components'

export const IntegrateDocContainer = styled.div`
  h3 {
    position: relative;
    margin: 2em 0 1em 0;
    color: #2d3c46;
    line-height: 1.1;
    font-weight: 500;
    font-size: 1.333em;
  }
  h4 {
    position: relative;
    margin: 2em 0 1em 0;
    color: #2d3c46;
    line-height: 1.1;
    font-weight: 500;
    font-size: 1.1em;
  }
  h5 {
    position: relative;
    margin: 2em 0 1em 0;
    line-height: 1.1;
    font-weight: 600;
    color: white;
    background-color: grey;
    font-size: 0.9em;
    border-radius: 2px;
    width: fit-content;
    padding: 0.35em 0.6em;
  }
  p {
    color: #505051;
    margin-bottom: 1.5em;
    line-height: 1.5;
  }
  ul {
    margin-bottom: 1.5em;
    li {
      margin: 0 0 0.5em 1em;
    }
  }
  pre {
    border-radius: 0.3rem;
    white-space: pre-wrap;
    word-break: break-all;
    margin-bottom: 1rem;
  }
  .nav-pills {
    list-style-type: none;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    display: flex;
    margin: 1em 0 -1px 0;
    color: #fff;
    li {
      margin: 0 0.125em 0 0;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      background-color: #48555d;
      font-weight: 500;
      cursor: pointer;
      a {
        display: block;
        padding: 0.6em 1.4em;
        color: #929a9f;
      }
      &.active {
        background-color: #2d3c46;
        border-top-left-radius: 3px;
        a {
          color: #fff;
        }
      }
    }
  }
  blockquote {
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 2em;
    padding-left: 4em;
    background-color: #f5fbfe;
    border-radius: 8px;
    border: 0;
    font-size: 1em;
    position: relative;
  }
  p + ul,
  p + ol {
    margin-top: -1em;
  }
`
