import styled, { css } from 'styled-components'

import { LoadingSvgStrokeAnim } from 'components/common/empty-states'
import { color } from 'components/styled/placeholder'

type LineChartPathProps = {
  color?: string
}
export const LineChartPath = styled.path<LineChartPathProps>`
  stroke: ${props => props.color || color};
  stroke-width: 1.6px;
  fill: transparent;
  ${props =>
    props.theme.isLoading &&
    css`
      animation-name: ${LoadingSvgStrokeAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`
export const LineChartArea = styled.path<{
  color?: string
}>`
  fill: ${props => props.color || color};
  stroke: transparent;
  opacity: 0.13;
`

export const LineChartPointer = styled.circle`
  r: 6px;
  stroke: #fefefe;
  stroke-width: 3px;
`

export const LineChartGuide = styled.line`
  stroke: #e8eaed;
  stroke-dasharray: 5, 5;
`
