import * as React from 'react'

import { validateUrl } from 'com.batch.common/utils'

type ImageLoaderProps = {
  imgSrc: string
  isBackground?: boolean
  isUploading?: boolean
  isDynamic?: boolean
  style?: any
} & React.ComponentProps<'img'>
export type ImgState = 'loading' | 'error' | 'valid' | 'dynamic'

const ImageCache: {
  [key: string]: ImgState
} = {}

export const ImageLoader = ({
  isBackground,
  isDynamic,
  isUploading,
  imgSrc,
  style,
  ...rest
}: ImageLoaderProps): React.ReactElement => {
  const [state, setState] = React.useState<ImgState>('loading')

  const successCb = React.useCallback(() => {
    setState('valid')
    ImageCache[imgSrc] = 'valid'
  }, [imgSrc])

  const failureCb = React.useCallback(() => {
    setState('error')
    ImageCache[imgSrc] = 'error'
  }, [imgSrc])

  React.useEffect(() => {
    const image = new Image()

    if (!validateUrl(imgSrc)) {
      setState('error')
    } else if (Object.hasOwnProperty.call(ImageCache, imgSrc)) {
      setState(ImageCache[imgSrc])
    } else {
      setState('loading')
      image.src = imgSrc
      image.addEventListener('load', successCb)
      image.addEventListener('error', failureCb)
    }
    return () => {
      image.removeEventListener('load', successCb)
      image.removeEventListener('error', failureCb)
    }
  }, [failureCb, imgSrc, successCb])

  const correctStyle =
    state === 'loading' || isUploading
      ? {
          ...style,
          backgroundColor: '#EEE',
          backgroundImage: 'url(/medias/img/notif-preview/legacy/loading-spin.svg)',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '30px 30px',
        }
      : state === 'error'
        ? isDynamic
          ? {
              ...style,
              backgroundImage: 'url(/medias/img/notif-preview/legacy/placeholder_dynamic.png',
              backgroundColor: '#d8d8d8',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }
          : {
              ...style,
              backgroundImage: 'url(/medias/img/notif-preview/legacy/placeholder_img.png',
              backgroundColor: '#f1f1f1',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '38px 30px',
              backgroundPosition: 'center center',
            }
        : {
            ...style,
            backgroundImage: `url(${imgSrc})`,
          }
  if (isBackground) {
    return <div {...rest} style={correctStyle} />
  } else {
    if (isUploading || state === 'loading' || state === 'error') {
      return <div {...rest} style={{ width: '100%', minHeight: '140px', ...correctStyle }} />
    }
    return <img {...rest} src={imgSrc} alt="Uploaded media" />
  }
}

type ImageLoaderRendererProps = {
  imgSrc: string
  isDynamic?: boolean
  isUploading?: boolean
  render: (props: ImgState) => any
}

export const ImageLoaderRenderer = ({
  imgSrc,
  render,
  isUploading,
  isDynamic,
}: ImageLoaderRendererProps): React.ReactElement => {
  const [state, setState] = React.useState<ImgState>('loading')

  const successCb = React.useCallback(() => {
    setState('valid')
    ImageCache[imgSrc] = 'valid'
  }, [imgSrc])

  const failureCb = React.useCallback(() => {
    setState('error')
    ImageCache[imgSrc] = 'error'
  }, [imgSrc])

  React.useEffect(() => {
    const image = new Image()

    if (!validateUrl(imgSrc)) {
      setState('error')
    } else if (Object.hasOwnProperty.call(ImageCache, imgSrc)) {
      setState(ImageCache[imgSrc])
    } else {
      setState('loading')
      image.src = imgSrc
      image.addEventListener('load', successCb)
      image.addEventListener('error', failureCb)
    }
    return () => {
      image.removeEventListener('load', successCb)
      image.removeEventListener('error', failureCb)
    }
  }, [failureCb, imgSrc, successCb])

  return render(isUploading ? 'loading' : state === 'error' && isDynamic ? 'dynamic' : state)
}
