import * as React from 'react'

import { Alert } from 'components/common/alert'
import Highlight from 'components/common/highlight'

import { type SdkRecord } from 'com.batch.redux/_records'

type SetupCordovaProps = {
  senderId: string | null | undefined
  platform: Platforms
  sdk: SdkRecord
  hasDevApiKeyFeature: boolean
}
const SetupCordova = ({ platform, hasDevApiKeyFeature }: SetupCordovaProps): React.ReactElement => {
  return (
    <div>
      <p>
        Batch Cordova is compatible with Cordova 9.0.0+, Cordova-iOS 6.0.0+ and Cordova-android
        9.0.0+. Please see the native SDK documentation for minimum OS version per platform.
      </p>
      Ionic Cordova and Ionic Capacitor are also supported with Ionic 6.0+ and Ionic Capacitor 2.4+.
      Other platforms supported by Cordova are <strong>not supported</strong> by Batch.{' '}
      <h3>Setting up the SDK</h3>
      <Highlight language="bash">
        {`
# Cordova
cordova plugin add @batch.com/cordova-plugin

# Ionic Cordova 
ionic cordova plugin add @batch.com/cordova-plugin 

# Ionic Capacitor
npm i @batch.com/cordova-plugin npx
cap sync
        `}
      </Highlight>
      <Alert icon="help" kind="info">
        <strong>Note:</strong> The cordova plugin internal name is 'com.batch.cordova'. If you need
        to manipulate the plugin (for example, with <code>cordova plugin remove</code>), you might
        need to use this name.
      </Alert>
      <h3>Your first start</h3>
      <p>
        Batch exposes a global <code>batch</code> object, which is your main entry point to any API
        that Batch provides. The <i>start</i> function requires you to provide a configuration
        object. For example, a typical implementation in the <code>www/js/init.js</code> will be:
      </p>
      <Highlight language="javascript">
        {`
function onDeviceReady() {
    batch.setConfig({"androidAPIKey":"<YOUR_ANDROID_APIKEY>",
        "iOSAPIKey":"<YOUR_IOS_APIKEY>"});
    batch.start();
}
        `}
      </Highlight>
      <br />
      {hasDevApiKeyFeature ? (
        <React.Fragment>
          <p>
            <code>YOUR_API_KEY</code> is your Batch Dev or Live API key. You'll find the API keys
            needed to set up the SDK in ⚙ Settings → General:
          </p>
          <ul>
            <li>
              <strong>Dev API key</strong>: Use it for development or testing purposes. This API key
              won't trigger stats computation. Please take care not to ship an app on a Store with
              this API key.
            </li>
            <li>
              <strong>Live API key</strong>: Should be used in a production environment only and in
              the final version of your app on the Store.
            </li>
          </ul>
        </React.Fragment>
      ) : (
        <p>You'll find the API keys needed to set up the SDK in ⚙ Settings → General.</p>
      )}
      <p>
        Note that you should not use the same API key for iOS and Android. If you do not want to
        support one of these platforms, you are not required to provide an API Key for it.
      </p>
      <p>
        Batch iOS API key must not be mistaken for the APNS environment Batch will output to the
        Xcode logs when starting. The environment depends on the app's provision, not on the Batch
        API Key used.
      </p>
      <Alert icon="help" kind="info">
        Do not try to use batch in <code>initialize</code> or <code>bindEvents</code>, batch will
        not be loaded yet. It is guaranteed to be loaded by cordova only in{' '}
        <code>onDeviceReady</code>.
      </Alert>
      <h3>Setting up push notifications</h3>
      {platform === 'android' && (
        <React.Fragment>
          <h4>Project Setup</h4>
          <p>
            Batch requires androidx to be enabled in your project. <br />
            How to enable it depends on your environment:
          </p>
          <ul>
            <li>
              Cordova Android 9.0 has native support for androidx: add
              <code>{'<preference name="AndroidXEnabled" value="true" />'}</code> to your
              config.xml.
            </li>
          </ul>
          <p>
            If you're using a lower Cordova Android version, please update if possible. Using
            Cordova Android 8 with an androidx plugin might work but is unsupported.
          </p>
          <ul>
            <li>
              Depending on your installed plugins, you might need to add an extra plugin:{' '}
              <code>ionic cordova plugin add cordova-plugin-androidx-adapter</code>. Ionic Cordova
              users are required to add this plugin.
            </li>
            <li>Ionic Capacitor users have nothing to do.</li>
          </ul>
          <h4>Firebase Cloud Messaging</h4>
          <p>On Android, Batch makes full use of Firebase Cloud Messaging (FCM).</p>
          <h5>Cordova / Ionic Cordova</h5>
          <p>
            Due to the variety of Firebase solutions in the Cordova ecosystem, Batch requires that
            FCM is added in your application using a separate plugin or manually. <br />
            We recommend using <code>cordova-plugin-firebase-messaging</code>.
          </p>
          <Alert kind="info" icon="help">
            Note: Please make sure that the plugin you're using doesn't use the native{' '}
            <code>firebase-messaging</code> library version 22.0 or greater. If so, please downgrade
            or add <code>com.google.firebase:firebase-iid:21.1.0</code> in your native dependencies.
            A future SDK release will support FCM tokens.
          </Alert>
          <p>
            One caveat is that most Cordova Firebase plugins add FCM to both Android and iOS. This
            might not be desired: Batch does not require Firebase on iOS, so you might not want to
            ship Firebase with your iOS application.
          </p>
          <p>
            It is possible to add FCM to your project manually. Open up your <code>config.xml</code>{' '}
            and add these lines:
          </p>
          <Highlight language="xml">
            {`
<platform name="android">
  <preference name="GradlePluginGoogleServicesEnabled" value="true" />
  <preference name="GradlePluginGoogleServicesVersion" value="4.3.3" />
  <resource-file src="build-extras.gradle" target="app/build-extras.gradle" />
</platform>
            `}
          </Highlight>
          <Alert kind="info" icon="help">
            Note: Make sure that your <code>platforms/android</code> folder do not already have a{' '}
            <code>build-extras.gradle</code> file in it, as this will override it. If you do, either
            merge the files or remove the <code>resource-file</code> config tag.
          </Alert>
          <p>
            If you're using an older Cordova version, you might need extra plugins such as{' '}
            <code>cordova-plugin-androidx</code>, <code>cordova-support-google-services</code> and{' '}
            <code>cordova-support-android-plugin</code>.
          </p>
          <p>
            Then, at the root of your application project, create a new file called{' '}
            <code>build-extras.gradle</code> and add the following in it:
          </p>
          <Highlight language="gradle">
            {`
repositories {
    mavenCentral()
    maven {
        url "https://maven.google.com"
    }
}

dependencies {
    implementation "androidx.appcompat:appcompat:1.2.+"
    implementation "com.google.firebase:firebase-messaging:21.0.+"
    // Uncomment this line if you want Advertising ID support
    //implementation "com.google.android.gms:play-services-ads-identifier:+"
    // Uncomment this line if you use firebase-messaging 22 or greater
    //implementation "com.google.firebase:firebase-iid:21.1.0"
}
          `}
          </Highlight>
          <Alert kind="info" icon="help">
            Note: This documentation isn't updated with androidx.core and firebase-messaging's
            latest versions. Please use up to date versions of these dependencies when possible.
          </Alert>
          <p>
            Once you've set up FCM, you will need to configure <code>google-services.json</code>.
            How to add it into your app depends on your plugin, but common steps are:
          </p>
          <ul>
            <li>
              Download <code>google-services.json</code> and save it in your app's root directory.
            </li>
          </ul>
          <p>
            If you don't have this file yet, please follow the "Manually add Firebase" part of{' '}
            <a
              href="https://firebase.google.com/docs/android/setup#manually_add_firebase"
              target="_blank"
            >
              this
            </a>{' '}
            documentation until you get it.
          </p>
          <ul>
            <li>
              Tell Cordova to copy it. Your firebase plugin should come with instructions on how to
              do so.
            </li>
          </ul>
          <p>
            If not, the following <code>config.xml</code> items might work:
          </p>
          <Highlight language="xml">
            {`
<platform name="android">
    <resource-file src="google-services.json" target="app/google-services.json" />
</platform>
          `}
          </Highlight>
          <h4>Ionic Capacitor</h4>
          <p>
            Open <code>android/variables.gradle</code> and add{' '}
            <code>firebaseMessagingVersion = "21.0.1"</code> if missing. You may want to use the
            latest{' '}
            <a href="https://firebase.google.com/support/release-notes/android" target="_blank">
              firebase-messaging
            </a>{' '}
            version.
          </p>
          <Highlight language="gradle">
            {`
ext {
  minSdkVersion = 21
  ...
  cordovaAndroidVersion = '7.0.0'
  firebaseMessagingVersion = '21.0.1'
}
          `}
          </Highlight>
          <p>
            Then, please follow{' '}
            <a
              href="https://capacitorjs.com/docs/guides/push-notifications-firebase#android"
              target="_blank"
            >
              Capacitor's Android Push Notifications documentation
            </a>{' '}
            to configure Firebase.
          </p>
          <Alert kind="info" icon="help">
            Note: If you're using <code>firebase-messaging</code> {'>'}= 22.0, please add the
            Firebase Installations library to your dependencies in your build.gradle or you will not
            be able to receive push notifications:{' '}
            <code>implementation "com.google.firebase:firebase-iid:21.1.0"</code>. A future SDK
            release will bring support for FCM Tokens.
          </Alert>
          <h5>Setting Your Small Icon</h5>
          <p>
            Follow the{' '}
            <a
              href="https://doc.batch.com/cordova/advanced/customizing-notifications#setting-up-custom-push-icons"
              target="_blank"
            >
              Customizing Batch notifications
            </a>{' '}
            guide to display your notification icon correctly on Android.
          </p>{' '}
        </React.Fragment>
      )}
      {platform === 'ios' && (
        <React.Fragment>
          <p>
            On iOS, you need to call <code>batch.push.registerForRemoteNotifications()</code> every
            time your application is started. You can, however, delay the very first call to another
            place in your app, so that the notification request popup is shown at a later date.
          </p>
          <Highlight language="swift">
            {`
function onDeviceReady() {
    batch.setConfig({"androidAPIKey":"<YOUR ANDROID APIKEY>",
        "iOSAPIKey":"<YOUR IOS APIKEY>"});
    batch.start();
    batch.push.registerForRemoteNotifications();
}
                `}
          </Highlight>
          <h5>Rich Push</h5>
          <p>
            In order to enable rich content in notification (such as images), a Notification Service
            extension must be added in the Xcode project. First, locate your Xcode project: it
            should be located <code>platforms/ios/</code> and be named{' '}
            <code>{'<yourapp>.xcworkspace'}</code>.
          </p>
          <p>
            Then, follow the{' '}
            <a
              href="https://doc.batch.com/ios/sdk-integration/rich-notifications-setup"
              target="_blank"
            >
              native rich push setup documentation
            </a>
            .
          </p>
          <Alert kind="info" icon="help">
            Note: This needs to be performed each time the <code>platforms/ios</code> directory is
            regenerated.
          </Alert>
        </React.Fragment>
      )}
    </div>
  )
}

export default SetupCordova
