import * as React from 'react'

import { useQuery } from 'components/_hooks'
import { TableCellOrder, TableHeader } from 'components/common/table'

import { useSortParams } from 'com.batch/shared/hooks/use-sort-params'

type HeaderProps = {
  onSortChange: (field: LabelTableHeaders) => () => void
}

export type LabelTableHeaders = 'description' | 'code' | 'orchestrationCount'

export const Header = ({ onSortChange }: HeaderProps): React.ReactElement => {
  const query = useQuery()
  const qSortBy = query.get('orderBy') || 'description'

  const { sortDirectionParam } = useSortParams()

  const qSortOrder = sortDirectionParam

  return (
    <TableHeader style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
      <TableCellOrder
        sort={qSortBy === 'description' ? qSortOrder ?? 'asc' : undefined}
        onClick={onSortChange('description')}
      >
        Label name
      </TableCellOrder>

      <TableCellOrder
        sort={qSortBy === 'orchestrationCount' ? qSortOrder ?? 'asc' : undefined}
        onClick={onSortChange('orchestrationCount')}
      >
        Used in
      </TableCellOrder>

      <TableCellOrder
        sort={qSortBy === 'code' ? qSortOrder ?? 'asc' : undefined}
        onClick={onSortChange('code')}
      >
        Code
      </TableCellOrder>
      <div />
    </TableHeader>
  )
}
