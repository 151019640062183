import { type Map, type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'
import { ThemeProvider, useTheme } from 'styled-components'

import { Button } from 'components/common/button'
import { Wrapper } from 'components/common/empty-states'
import { FlexLine } from 'components/common/flexline'
import { FilterSearch } from 'components/filter'
import { HeaderActions, ActionsGroup } from 'components/styled/blocs'
import { Title } from 'components/styled/text'

import { AttributeWithValues } from './attribute-with-values'
import { UserbaseAttributeContainer, Datas } from './userbase.styles'

import {
  AttributeValuesListFactory,
  AttributeFactory,
  type AttributeRecord,
  type AppRecord,
  type AttributeValuesListRecord,
  type fetchingState,
} from 'com.batch.redux/_records'
import { fetchAttributesAndValues, updateAttributeFilterTerm } from 'com.batch.redux/attribute'

import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'

type UserbaseAttributeProps = {
  app: AppRecord
  attributes: List<AttributeRecord>
  values: Map<string, AttributeValuesListRecord>
  failure: boolean
  loading: boolean
  hasDevApiKeyFeature: boolean
  attributeLoadingState: fetchingState
}

const UserbaseAttributeRaw = ({
  app,
  attributes,
  values,
  failure,
  loading,
  hasDevApiKeyFeature,
  attributeLoadingState,
}: UserbaseAttributeProps) => {
  const dispatch = useDispatch()

  // ====================== REDUX STATES
  const dev: boolean = useSelector(state => state.attribute.config.devMode)
  const term: string = useSelector(state => state.attribute.config.filterTerm)

  // ====================== USE EFFECT
  React.useEffect(() => {
    if (attributeLoadingState === 'INIT')
      dispatch(
        fetchAttributesAndValues({
          app: app,
          devMode: dev,
          withEventDays: true,
        })
      )
  }, [app, dev, dispatch, attributeLoadingState])

  const changeMode = React.useCallback(
    (devMode: boolean) => {
      dispatch(fetchAttributesAndValues({ app, devMode, withEventDays: true }))
    },
    [dispatch, app]
  )

  const tease: boolean = app.platform !== 'webpush' && !app.features.has('query-attributes')

  const theme = useTheme()
  const isEmpty: boolean = theme.isEmpty ? theme.isEmpty : failure
  const isLoading: boolean = loading && !theme.isEmpty

  const emptyData = ['empty1', 'empty2', 'empty3']

  const onFilterChange = React.useCallback(
    e => {
      dispatch(updateAttributeFilterTerm(e))
    },
    [dispatch]
  )
  const onModeChange = React.useCallback(b => () => changeMode(b), [changeMode])

  // ====================== RENDER
  return (
    <UserbaseAttributeContainer>
      <FlexLine
        style={{ justifyContent: 'space-between', alignItems: 'center', margin: '32px 0 38px 0' }}
      >
        <ThemeProvider theme={{ kind: 'filter' }}>
          <Title mb={0}>Native &amp; custom data</Title>
          {!loading && !failure && !theme.isEmpty ? (
            <HeaderActions>
              <ActionsGroup>
                <FilterSearch
                  value={term}
                  onChange={onFilterChange}
                  identifier="attributes"
                  disabled={loading}
                  expandedMaxWidth={240}
                />
              </ActionsGroup>
              {app.platform !== 'webpush' && hasDevApiKeyFeature && (
                <ActionsGroup style={{ paddingLeft: 1 }}>
                  <Button
                    kind="inline"
                    onClick={onModeChange(true)}
                    isActive={dev}
                    isLoading={loading}
                    disabled={loading}
                    style={{ marginRight: 10 }}
                  >
                    Dev
                  </Button>
                  <Button
                    kind="inline"
                    onClick={onModeChange(false)}
                    isActive={!dev}
                    isLoading={loading}
                    disabled={loading}
                  >
                    Live
                  </Button>
                </ActionsGroup>
              )}
            </HeaderActions>
          ) : (
            <div style={{ height: 40 }} />
          )}
        </ThemeProvider>
      </FlexLine>

      <ThemeProvider
        theme={{
          kind: 'attributes',
          isEmpty: isEmpty,
          isLoading: loading && !theme.isEmpty,
        }}
      >
        <Wrapper
          isEmpty={isEmpty}
          isLoading={isLoading && !theme.isEmpty}
          isOverlayShown={!isLoading && !theme.isEmpty && !isEmpty && failure}
          overlayProps={{
            status: 'error',
            title: 'Something went wrong',
            refresh: () => {
              location.reload()
            },
          }}
        >
          <NoResultWrapper
            isEmpty={!isEmpty && !isLoading && attributes.size === 0}
            entityName="attributes"
          >
            <Datas template="1fr">
              {isLoading
                ? emptyData.map(attr => (
                    <AttributeWithValues
                      key={attr}
                      attribute={AttributeFactory()}
                      datas={AttributeValuesListFactory()}
                      loading={loading}
                      failure={failure}
                      tease={tease}
                    />
                  ))
                : attributes.map(attr => (
                    <AttributeWithValues
                      key={attr.id}
                      attribute={attr}
                      datas={values.get(attr.id, AttributeValuesListFactory())}
                      loading={loading}
                      failure={failure}
                      tease={tease}
                    />
                  ))}
            </Datas>
          </NoResultWrapper>
        </Wrapper>
      </ThemeProvider>
    </UserbaseAttributeContainer>
  )
}

export const UserbaseAttributes: React.ComponentType<UserbaseAttributeProps> =
  React.memo<UserbaseAttributeProps>(UserbaseAttributeRaw)
