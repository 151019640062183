import * as React from 'react'

import { colors } from 'components/styled/tokens'

type WhenSvgProps = {
  isSelected: boolean
}

const Now = ({ isSelected }: WhenSvgProps): React.ReactElement => {
  return (
    <svg width="45" height="55" viewBox="0 0 45 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5665 37.824C24.8582 37.6235 25.0221 37.2838 24.9976 36.9307C24.9731 36.5777 24.7638 36.2639 24.4472 36.1056L20.4472 34.1056C20.049 33.9065 19.5672 33.9946 19.2652 34.3217L16.9541 36.8254L16.9541 36.8254L16.9515 36.8282L16.9329 36.8484C15.6337 38.2558 14.6344 39.3385 13.9691 40.1269C13.6365 40.521 13.361 40.8723 13.1686 41.1726C13.0726 41.3224 12.9792 41.4873 12.9111 41.6587C12.8492 41.8147 12.7676 42.0746 12.8073 42.3776C12.9112 43.172 13.4792 43.8277 14.2506 44.0439C14.5449 44.1264 14.8137 44.0827 14.977 44.0437C15.1564 44.0007 15.3329 43.9318 15.4949 43.8581C15.8195 43.7105 16.2066 43.4879 16.6441 43.215C17.5193 42.669 18.7334 41.8343 20.3117 40.7492L20.3375 40.7315L24.5665 37.824Z"
        fill="#EAEAEA"
        stroke="#C8C8C8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.86152 27.241L4.51827 23.6788C3.70158 23.1344 3.29324 22.8622 3.18326 22.5433C3.08746 22.2655 3.11867 21.9597 3.2686 21.707C3.44073 21.4168 3.89565 21.2327 4.80548 20.8644L40.8191 6.28752C41.8331 5.87706 42.3402 5.67183 42.6789 5.77517C42.9733 5.86499 43.21 6.08542 43.3205 6.37275C43.4476 6.70329 43.2788 7.22359 42.9413 8.26419L42.9413 8.26424L32.6517 39.9905C32.4304 40.673 32.3197 41.0143 32.1031 41.1981C31.9133 41.3591 31.6704 41.4435 31.4217 41.435C31.1378 41.4252 30.8393 41.2262 30.2423 40.8282L22.9097 35.9398C21.9003 35.2669 21.3956 34.9304 20.8893 34.8846C20.4445 34.8444 19.999 34.9542 19.6238 35.1966C19.1968 35.4725 18.9064 36.005 18.3254 37.0701L16.1867 40.9911C15.5161 42.2204 15.1809 42.8351 14.8139 42.9612C14.4963 43.0704 14.1451 43.0129 13.879 42.8081C13.5714 42.5715 13.4498 41.882 13.2064 40.503L11.2378 29.3475C11.1501 28.8506 11.1063 28.6022 11.0076 28.3795C10.9201 28.182 10.8012 28.0001 10.6555 27.8406C10.4912 27.6608 10.2813 27.5209 9.86152 27.241Z"
        fill="white"
      />
      <path
        d="M10 29L37.4576 8.96336C40.0908 7.04188 41.4073 6.08115 41.8547 6.18652C42.2381 6.27681 42.5328 6.58374 42.6074 6.97048C42.6944 7.42181 41.6809 8.69814 39.6538 11.2508L39.6538 11.2508L20 36L16.0063 41.9906C15.3713 42.9431 15.0538 43.4193 14.708 43.5226C14.4077 43.6123 14.0828 43.556 13.8301 43.3705C13.5393 43.157 13.4004 42.6017 13.1228 41.4911L10 29Z"
        fill="url(#nowGrad)"
      />
      <path
        d="M13.2691 43.6006L13.7446 42.9827L13.2691 43.6006C13.8015 44.0103 14.5039 44.1253 15.1391 43.9069C15.6724 43.7235 16.0078 43.2635 16.2208 42.9411C16.4578 42.5824 16.7261 42.0906 17.0425 41.5105L17.0646 41.4699L19.2033 37.5489C19.502 37.0013 19.6969 36.6457 19.8668 36.3885C20.0308 36.1401 20.1192 36.0671 20.1665 36.0365C20.3541 35.9154 20.5768 35.8604 20.7992 35.8806C20.8553 35.8856 20.9675 35.9092 21.2282 36.0528C21.4982 36.2016 21.836 36.4258 22.355 36.7719L29.656 41.6392L29.6875 41.6602C29.9635 41.8442 30.2231 42.0173 30.4504 42.1412C30.6916 42.2726 31.0033 42.4106 31.3778 42.4216C31.88 42.4363 32.3693 42.2615 32.7485 41.9319C33.0312 41.6861 33.1849 41.3819 33.2883 41.1273C33.3856 40.8875 33.4768 40.5891 33.5737 40.2718L33.5848 40.2356L42.9911 9.45109L43.0041 9.40856C43.1477 8.93888 43.2749 8.5225 43.3457 8.18383C43.4149 7.85246 43.4781 7.39797 43.3012 6.94902C43.0786 6.38392 42.6112 5.9506 42.031 5.77126L41.7358 6.72613L42.031 5.77126C41.5699 5.62877 41.1215 5.72609 40.7963 5.8201C40.4639 5.91618 40.0583 6.07449 39.6008 6.25306L39.5594 6.26922L4.49924 19.9512L4.45863 19.9671C4.03027 20.1342 3.64659 20.2839 3.3516 20.4313C3.05815 20.5779 2.68212 20.8006 2.44177 21.1977C2.13568 21.7035 2.069 22.3187 2.25962 22.8783C2.40931 23.3177 2.72887 23.6158 2.98409 23.8219C3.24067 24.029 3.58336 24.2575 3.96596 24.5125L4.00223 24.5367L9.30682 28.0731C9.76752 28.3802 9.85425 28.4462 9.91728 28.5152L10.6555 27.8406L9.91728 28.5152C9.99012 28.5949 10.0496 28.6859 10.0933 28.7846L11.0076 28.3795L10.0933 28.7846C10.1312 28.87 10.1568 28.976 10.253 29.5212L12.2216 40.6767L12.2296 40.7221C12.3445 41.3729 12.4418 41.9246 12.552 42.3403C12.6511 42.7137 12.8222 43.2567 13.2691 43.6006Z"
        stroke="#C0C0C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 47L4 50"
        stroke={isSelected ? colors.strokeAction : '#E0E0E0'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16 51L13 54"
        stroke={isSelected ? colors.strokeAction : '#E0E0E0'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4 38L1 41"
        stroke={isSelected ? colors.strokeAction : '#E0E0E0'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="nowGrad"
          x1="22"
          y1="16"
          x2="30.5"
          y2="29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7F7F7" />
          <stop offset="1" stopColor="#DEDEDE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

const Recurring = ({ isSelected }: WhenSvgProps): React.ReactElement => {
  return (
    <svg width="62" height="47" viewBox="0 0 62 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="31" cy="26" r="20" fill="white" />
      <mask
        id="mask0_recu"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="11"
        y="6"
        width="40"
        height="40"
      >
        <circle cx="31" cy="26" r="20" fill="white" />
      </mask>
      <g mask="url(#mask0_recu)">
        <path
          d="M31 40C15 40 9.5 25 14 17H8V49H54V17H48C52.5 26 47 40 31 40Z"
          fill="url(#paint_radial_recu)"
        />
      </g>
      <circle cx="31" cy="26" r="20" stroke="#C0C0C0" strokeWidth="2" />
      <path
        d="M58 26L55 23M58 26L61 23M58 26C58 19.5701 55.7524 13.6652 52 9.02798"
        stroke={isSelected ? colors.strokeAction : '#E0E0E0'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 26.028L7 29.028M4 26.028L1 29.028M4 26.028C4 32.4579 6.2476 38.3628 10 43"
        stroke={isSelected ? colors.strokeAction : '#E0E0E0'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 11C32 11.5523 31.5523 12 31 12C30.4477 12 30 11.5523 30 11C30 10.4477 30.4477 10 31 10C31.5523 10 32 10.4477 32 11Z"
        fill="#E0E0E0"
      />
      <path
        d="M32 41C32 41.5523 31.5523 42 31 42C30.4477 42 30 41.5523 30 41C30 40.4477 30.4477 40 31 40C31.5523 40 32 40.4477 32 41Z"
        fill="#E0E0E0"
      />
      <path
        d="M47 27C47 27.5523 46.5523 28 46 28C45.4477 28 45 27.5523 45 27C45 26.4477 45.4477 26 46 26C46.5523 26 47 26.4477 47 27Z"
        fill="#E0E0E0"
      />
      <path
        d="M17 27C17 27.5523 16.5523 28 16 28C15.4477 28 15 27.5523 15 27C15 26.4477 15.4477 26 16 26C16.5523 26 17 26.4477 17 27Z"
        fill="#E0E0E0"
      />
      <path d="M31 27L39 19" stroke="#C0C0C0" strokeWidth="2" strokeLinecap="round" />
      <path d="M31 27L21 17" stroke="#E0E0E0" strokeWidth="2" strokeLinecap="round" />
      <circle cx="31" cy="27" r="1" fill="#C0C0C0" stroke="#C0C0C0" strokeWidth="3" />
      <path
        d="M31 1C28.5748 1 26.2271 1.37888 24 2M31 1C33.4252 1 35.7729 1.37888 38 2M31 1V6"
        stroke="#C0C0C0"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id="paint_radial_recu"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31 30.5) rotate(90) scale(18.5 23)"
        >
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="#E3E3E3" />
        </radialGradient>
      </defs>
    </svg>
  )
}
const Trigger = ({ isSelected }: WhenSvgProps): React.ReactElement => {
  return (
    <svg width="42" height="53" viewBox="0 0 42 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 23V14.5C17.5 12.567 15.933 11 14 11C12.067 11 10.5 12.5663 10.5 14.4993V28C1.49995 28 3.99994 37.5 6.49996 43C7.99196 46.2824 9.99994 48.5 13 50C16 51.5 18.5 52 23.5 52C31.5 52 38.5 47 38.4999 40L38.4999 26.9963C38.4999 25.3395 37.1568 24 35.4999 24H34.5C32.8431 24 31.5 25.3431 31.5 27V25C31.5 23.3431 30.1568 22 28.5 22H27.5C25.8431 22 24.5 23.3431 24.5 25V23C24.5 21.3431 23.1568 20 21.5 20H20.5C18.8431 20 17.5 21.3431 17.5 23Z"
        fill="white"
      />
      <mask id="mask0" maskUnits="userSpaceOnUse" x="4" y="11" width="35" height="41">
        <path
          d="M17.5 23V14.5C17.5 12.567 15.933 11 14 11C12.067 11 10.5 12.5663 10.5 14.4993V28C1.49995 28 3.99994 37.5 6.49996 43C7.99196 46.2824 9.99994 48.5 13 50C16 51.5 18.5 52 23.5 52C31.5 52 38.5 47 38.4999 40L38.4999 26.9963C38.4999 25.3395 37.1568 24 35.4999 24H34.5C32.8431 24 31.5 25.3431 31.5 27V25C31.5 23.3431 30.1568 22 28.5 22H27.5C25.8431 22 24.5 23.3431 24.5 25V23C24.5 21.3431 23.1568 20 21.5 20H20.5C18.8431 20 17.5 21.3431 17.5 23Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M22.5 47C11.5 46.1538 3 41 5.5 30.5L1 45L-0.5 57L23 61.5L45 50.5C43.1667 43.6667 39.3 31.4 38.5 37C37.5 44 35.5 48 22.5 47Z"
          fill="url(#paint0_linear)"
        />
        <path d="M38.5 31L17.5 28V18L39 21L44.5 28L38.5 31Z" fill="url(#paint1_linear)" />
      </g>
      <path
        d="M10.5001 37C10.5001 37 10.5001 31.1242 10.5001 28M17.5001 29V25M17.5001 25V14.5C17.5001 12.567 15.9331 11 14.0001 11V11C12.0671 11 10.5001 12.5663 10.5001 14.4993C10.5001 18.037 10.5001 23.5958 10.5001 28M17.5001 25V24C17.5001 22.3431 18.8432 21 20.5001 21H21.5001C23.157 21 24.5001 22.3431 24.5001 24V30V26C24.5001 24.3431 25.8433 23 27.5001 23H28.5001C30.157 23 31.5001 24.3431 31.5001 26V31V28C31.5001 26.3431 32.8433 25 34.5001 25H35.5001C37.1569 25 38.5001 26.3409 38.5001 27.9978C38.5001 30.9632 38.5001 35.6727 38.5001 40C38.5001 47 31.5001 52 23.5001 52C18.5001 52 16.0001 51.5 13.0001 50C10.0001 48.5 7.99209 46.2824 6.5001 43C4.00008 37.5 3.50006 28 10.5001 28"
        stroke="#C0C0C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 36V41"
        stroke={isSelected ? colors.strokeAction : '#E0E0E0'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M24.5 37V41"
        stroke={isSelected ? colors.strokeAction : '#E0E0E0'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M31.5 38V41"
        stroke={isSelected ? colors.strokeAction : '#E0E0E0'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M13.5 22.5H14.5" stroke="#E0E0E0" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.5 20.5H14.5" stroke="#E0E0E0" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2C15 1.17157 14.3284 0.5 13.5 0.5C12.6716 0.5 12 1.17157 12 2V6C12 6.82843 12.6716 7.5 13.5 7.5C14.3284 7.5 15 6.82843 15 6V2ZM5.14648 3.52512C4.56069 2.93934 3.61094 2.93934 3.02516 3.52512C2.43937 4.11091 2.43937 5.06066 3.02516 5.64645L5.85358 8.47487C6.43937 9.06066 7.38912 9.06066 7.9749 8.47487C8.56069 7.88909 8.56069 6.93934 7.9749 6.35355L5.14648 3.52512ZM0 14C0 13.1716 0.671573 12.5 1.5 12.5H5.5C6.32843 12.5 7 13.1716 7 14C7 14.8284 6.32843 15.5 5.5 15.5H1.5C0.671573 15.5 0 14.8284 0 14ZM21 14C21 13.1716 21.6716 12.5 22.5 12.5H26.5C27.3284 12.5 28 13.1716 28 14C28 14.8284 27.3284 15.5 26.5 15.5H22.5C21.6716 15.5 21 14.8284 21 14ZM19.0252 8.47488C18.4394 7.88909 18.4394 6.93934 19.0252 6.35355L21.8536 3.52513C22.4394 2.93934 23.3891 2.93934 23.9749 3.52513C24.5607 4.11091 24.5607 5.06066 23.9749 5.64645L21.1465 8.47488C20.5607 9.06066 19.6109 9.06066 19.0252 8.47488Z"
        fill={isSelected ? colors.strokeAction : '#E0E0E0'}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="22"
          y1="38"
          x2="19.5"
          y2="55.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F0F0" />
          <stop offset="1" stopColor="#E6E6E6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="31"
          y1="20.5"
          x2="31"
          y2="31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E5E5" />
          <stop offset="1" stopColor="#FEFDFD" />
        </linearGradient>
      </defs>
    </svg>
  )
}
const Scheduled = ({ isSelected }: WhenSvgProps): React.ReactElement => {
  return isSelected ? (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 15.2C1 11.2796 1 9.31945 1.76295 7.82207C2.43407 6.50493 3.50493 5.43407 4.82207 4.76295C6.31945 4 8.27963 4 12.2 4H33.8C37.7204 4 39.6805 4 41.1779 4.76295C42.4951 5.43407 43.5659 6.50493 44.237 7.82207C45 9.31945 45 11.2796 45 15.2V32.8C45 36.7204 45 38.6805 44.237 40.1779C43.5659 41.4951 42.4951 42.5659 41.1779 43.237C39.6805 44 37.7204 44 33.8 44H12.2C8.27963 44 6.31945 44 4.82207 43.237C3.50493 42.5659 2.43407 41.4951 1.76295 40.1779C1 38.6805 1 36.7204 1 32.8V15.2Z"
        fill="#BEBEBE"
        fillOpacity="0.08"
        stroke="#E0E0E0"
        strokeWidth="2"
      />
      <path
        d="M1 15.2C1 11.2796 1 9.31945 1.76295 7.82207C2.43407 6.50493 3.50493 5.43407 4.82207 4.76295C6.31945 4 8.27963 4 12.2 4H35C37.7929 4 39.1893 4 40.312 4.39282C42.3227 5.0964 43.9036 6.67732 44.6072 8.68805C45 9.81065 45 11.2071 45 14C45 22.3787 45 26.568 43.8216 29.9359C41.7108 35.968 36.968 40.7108 30.9359 42.8216C27.568 44 23.3787 44 15 44H12C8.2682 44 6.4023 44 4.96281 43.3068C3.53406 42.6187 2.38127 41.4659 1.69322 40.0372C1 38.5977 1 36.7318 1 33V15.2Z"
        fill="white"
        stroke="#C0C0C0"
        strokeWidth="2"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="5"
        y="20"
        width="36"
        height="20"
      >
        <rect x="5" y="20" width="36" height="20" fill="url(#paint0_radial)" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M11 20V40M5 26H41M5 33H41M19 20V40M27 20V40M35 20V40"
          stroke={colors.strokeAction}
          strokeWidth="1.5"
        />
      </g>
      <path
        d="M32 31C37 26 45 28 45 21V23.0641C45 34.7102 35.5228 44.132 23.877 44.0637L13 44H22C29 44 27 36 32 31Z"
        fill="url(#paint1_radial)"
        stroke="#C0C0C0"
        strokeWidth="2"
      />
      <g filter="url(#filter0_d)">
        <path
          d="M2 11C2 7.68629 4.68629 5 8 5H38C41.3137 5 44 7.68629 44 11V16H2V11Z"
          fill="url(#paint2_linear)"
        />
      </g>
      <path
        d="M15 1V7M31 1V7"
        stroke="#C0C0C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="2"
          y="5"
          width="42"
          height="12"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23 30) rotate(180) scale(18 10)"
        >
          <stop />
          <stop offset="0.291667" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.5 29.5) rotate(45) scale(14.1421 14.0598)"
        >
          <stop stopColor="#F6F6F6" />
          <stop offset="1" stopColor="#F1F1F1" />
        </radialGradient>
        <linearGradient
          id="paint2_linear"
          x1="23"
          y1="4"
          x2="23"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F1F1" />
          <stop offset="1" stopColor="#F0F0F0" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 15.2C1 11.2796 1 9.31945 1.76295 7.82207C2.43407 6.50493 3.50493 5.43407 4.82207 4.76295C6.31945 4 8.27963 4 12.2 4H33.8C37.7204 4 39.6805 4 41.1779 4.76295C42.4951 5.43407 43.5659 6.50493 44.237 7.82207C45 9.31945 45 11.2796 45 15.2V32.8C45 36.7204 45 38.6805 44.237 40.1779C43.5659 41.4951 42.4951 42.5659 41.1779 43.237C39.6805 44 37.7204 44 33.8 44H12.2C8.27963 44 6.31945 44 4.82207 43.237C3.50493 42.5659 2.43407 41.4951 1.76295 40.1779C1 38.6805 1 36.7204 1 32.8V15.2Z"
        fill="#BEBEBE"
        fillOpacity="0.08"
        stroke="#E0E0E0"
        strokeWidth="2"
      />
      <path
        d="M1 15.2C1 11.2796 1 9.31945 1.76295 7.82207C2.43407 6.50493 3.50493 5.43407 4.82207 4.76295C6.31945 4 8.27963 4 12.2 4H35C37.7929 4 39.1893 4 40.312 4.39282C42.3227 5.0964 43.9036 6.67732 44.6072 8.68805C45 9.81065 45 11.2071 45 14C45 22.3787 45 26.568 43.8216 29.9359C41.7108 35.968 36.968 40.7108 30.9359 42.8216C27.568 44 23.3787 44 15 44H12C8.2682 44 6.4023 44 4.96281 43.3068C3.53406 42.6187 2.38127 41.4659 1.69322 40.0372C1 38.5977 1 36.7318 1 33V15.2Z"
        fill="white"
        stroke="#C0C0C0"
        strokeWidth="2"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="5"
        y="20"
        width="36"
        height="20"
      >
        <rect x="5" y="20" width="36" height="20" fill="url(#paint0_radial)" />
      </mask>
      <g mask="url(#mask0)">
        <path d="M11 20V40M5 26H41M5 33H41M19 20V40M27 20V40M35 20V40" stroke="#E0E0E0" />
      </g>
      <path
        d="M32 31C37 26 45 28 45 21V23.0641C45 34.7102 35.5228 44.132 23.877 44.0637L13 44H22C29 44 27 36 32 31Z"
        fill="url(#paint1_radial)"
        stroke="#C0C0C0"
        strokeWidth="2"
      />
      <g filter="url(#filter0_d)">
        <path
          d="M2 11C2 7.68629 4.68629 5 8 5H38C41.3137 5 44 7.68629 44 11V16H2V11Z"
          fill="url(#paint2_linear)"
        />
      </g>
      <path
        d="M15 1V7M31 1V7"
        stroke="#C0C0C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="2"
          y="5"
          width="42"
          height="12"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 0 0.878431 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23 30) rotate(180) scale(18 10)"
        >
          <stop />
          <stop offset="0.482639" />
          <stop offset="1" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.5 29.5) rotate(45) scale(14.1421 14.0598)"
        >
          <stop stopColor="#F6F6F6" />
          <stop offset="1" stopColor="#F1F1F1" />
        </radialGradient>
        <linearGradient
          id="paint2_linear"
          x1="23"
          y1="4"
          x2="23"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F1F1" />
          <stop offset="1" stopColor="#F0F0F0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const whenSvg = {
  now: Now,
  scheduled: Scheduled,
  recurring: Recurring,
  trigger: Trigger,
}
