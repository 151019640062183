import Immutable, { type Map } from 'immutable'
import * as React from 'react'

import { useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Hint } from 'components/common/hint'
import { MapPicker } from 'components/common/map-picker'
import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'
import { CampaignPicker } from 'components/targeting/campaign-picker'
import ConditionOperator from 'components/targeting/condition-operator'
import { CustomAudiencePicker } from 'components/targeting/custom-audience-picker'

import Types from 'com.batch.common/legacy-query-types'

import { ConditionInput } from './condition/condition-input'
import { ConditionFunction } from './condition/function'
import ConditionEventParam from './condition-event-param'

import { type AppRecord, type AttributeRecord } from 'com.batch.redux/_records'

type LatLong = {
  lat: number
  lng: number
}
type ConditionValue = any

type Change = {
  what: string
  value: ConditionValue
}
type ConditionChange = {
  changes: Change
  id: string
}

type ConditionProps = {
  id: string
  app: AppRecord
  loading: boolean
  canUseEventCountPeriod: boolean
  canQueryEventData: boolean
  previousWasLogical: boolean
  ttlRetargeting: number
  attribute: AttributeRecord
  platform: string
  attributeValues: Array<any>
  createAttributeValue: (attributeId: string, label: string) => void
  operator: QBOperator | null | undefined
  operators: Array<QBOperator>
  func: QBFunction | null | undefined
  args: Map<string, number | string>
  valid: boolean
  functions: Array<QBFunction>
  value: ConditionValue
  negate: boolean
  inputType: string
  toggle: (id: string) => void
  remove: (id: string) => void
  update: (arg: ConditionChange) => void
}

export const Condition: React.ComponentType<ConditionProps> = React.memo<ConditionProps>(
  ({
    id,
    app,
    loading,
    canUseEventCountPeriod,
    canQueryEventData,
    previousWasLogical,
    ttlRetargeting,
    attribute,
    platform,
    attributeValues,
    operator,
    operators,
    func,
    args,
    valid,
    functions,
    value,
    negate,
    inputType,
    toggle,
    remove,
    update,
  }: ConditionProps): React.ReactElement | null => {
    // --- local state ---

    const eventPickerState = useToggle()

    // --- derived state ---
    const hasEventLabel = React.useMemo(() => attribute.type === Types.EVENT, [attribute.type])

    const position: LatLong | null | undefined = React.useMemo(() => {
      const tmp = args.get('__POSITION__')
      const pos = {
        // @ts-expect-error legacy
        lat: tmp?.lat ?? args?.getIn(['__POSITION__', 'lat'], 0) ?? 0,
        // @ts-expect-error legacy
        lng: tmp?.lng ?? args?.getIn(['__POSITION__', 'lng'], 0) ?? 0,
      }
      if (pos.lat === 0 && pos.lng === 0) {
        return null
      }
      return pos
    }, [args])

    const period: string | null | undefined = React.useMemo(() => {
      const maybe = args.get('__PERIOD__', null)
      return typeof maybe === 'string' ? maybe : null
    }, [args])

    const labelClass = React.useMemo(
      () =>
        hasEventLabel
          ? 'condi__header__label'
          : 'condi__header__label condi__header__label--noevent',
      [hasEventLabel]
    )
    const condClass = React.useMemo(
      () => `condi ${valid ? '' : 'condi--err'} ${previousWasLogical ? 'condi--border' : ''}`,
      [previousWasLogical, valid]
    )

    const isCustom = attribute.id === 'b.position' || attribute.id.substr(0, 3) === 'be.'

    // --- callbacks ---

    const onInputValueChanged = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        update({
          changes: {
            what: 'value',
            value:
              event.target.type === 'number'
                ? event.target.value
                  ? Number(event.target.value)
                  : ''
                : event.target.value,
          },
          id: id,
        })
      },
      [id, update]
    )
    const onValueChanged = React.useCallback(
      (value: ConditionValue) => {
        update({
          changes: { what: 'value', value },
          id: id,
        })
      },
      [id, update]
    )

    const helper = (helper: string) => {
      const values: Array<{
        value: string
      }> = Array.isArray(value) ? value : []
      const empty: Array<{
        value: string
      }> = []
      if (helper === '') {
        return onValueChanged(empty)
      } else {
        const reg = new RegExp(helper + '[S,,]*')
        const filtered = attributeValues.filter(v => {
          return v.value.match(reg)
        })
        const all: Array<{
          value: string
        }> = []
        values.forEach(v => {
          if (all.filter(c => c.value === v.value).length === 0) {
            all.push(v)
          }
        })
        filtered.forEach(v => {
          if (all.filter(c => c.value === v.value).length === 0) {
            all.push(v)
          }
        })

        return onValueChanged(all)
      }
    }

    const onNumberValueChanged = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        update({
          changes: {
            what: 'value',
            value: Number(event.target.value),
          },
          id: id,
        })
      },
      [id, update]
    )

    const onDelete = React.useCallback(() => {
      remove(id)
    }, [id, remove])
    const onConditionToggled = React.useCallback(() => {
      toggle(id)
    }, [id, toggle])
    const onEventParamKeyChanged = React.useCallback(
      (key: string) => {
        update({
          changes: { what: '__KEY__', value: key },
          id,
        })
        eventPickerState.close()
      },
      [eventPickerState, id, update]
    )
    const onEventParamValueChanged = React.useCallback(
      (value: ConditionValue) => {
        update({
          changes: { what: '__VALUE__', value },
          id,
        })
        eventPickerState.close()
      },
      [eventPickerState, id, update]
    )
    const onCampaignTokenChanged = React.useCallback(
      (value: string) => {
        update({
          changes: { what: '__TOKEN__', value },
          id,
        })
        eventPickerState.close()
      },
      [eventPickerState, id, update]
    )

    const onOperatorChanged = React.useCallback(
      (operator: QBOperator) => {
        update({
          changes: { what: 'operator', value: operator.value },
          id: id,
        })
        if (operator.value === 'exists') {
          update({
            changes: { what: 'value', value: true },
            id: id,
          })
        }
      },
      [id, update]
    )

    const onRadiusChanged = React.useCallback(
      (value: number) => {
        update({
          changes: { what: '__RADIUS__', value: value },
          id: id,
        })
      },
      [id, update]
    )
    const onPeriodChanged = React.useCallback(
      (value: string) => {
        update({
          changes: { what: '__PERIOD__', value: value },
          id: id,
        })
      },
      [id, update]
    )
    const onPositionChanged = React.useCallback(
      (value: LatLong) => {
        update({
          changes: { what: '__POSITION__', value: value },
          id: id,
        })
      },
      [id, update]
    )
    const onFunctionChanged = React.useCallback(
      (localFunc: QBFunction): void => {
        if (localFunc.$api === '$exists') {
          // hackish way to allow $exists operator for date field to be selected from the functions selector
          update({
            id: id,
            changes: { what: 'func', value: null },
          })
          update({
            id: id,
            changes: { what: 'operator', value: 'exists' },
          })
          update({
            id: id,
            changes: { what: 'value', value: true },
          })
        } else if (!localFunc || !func || localFunc.value !== func.value) {
          // this is the real deal
          update({
            id: id,
            changes: { what: 'func', value: localFunc.value },
          })
          // when we changes the DATE function to something other than exists we need to reset the operator
          if (
            attribute.type === 'DATE' &&
            operator?.api === '$exists' &&
            localFunc.$api !== '$exists'
          ) {
            update({
              id: id,
              changes: { what: 'operator', value: operators[0].value },
            })
          }
        }
      },
      [attribute.type, id, operator?.api, operators, update, func]
    )

    return (
      <div className={condClass}>
        <div className="condi__header">
          <div className="condi__header__icon">
            <Icon icon={attribute.icon} size={14} />
          </div>
          <div
            className={labelClass}
            title={attribute.label}
            style={attribute.id.substr(0, 3) === 'be.' ? { flex: '0 0 220px' } : {}}
          >
            {attribute.label}
            {attribute.id.substr(0, 3) === 'be.' && (
              <Hint placement="left" minTooltipWidth={250}>
                <div style={{ textAlign: 'left' }}>
                  Your account has been configured to store campaigns retargeting data for{' '}
                  <strong>{ttlRetargeting} days</strong> which means you won’t be able to retarget
                  campaigns sent before.You can also retarget campaigns scheduled in the future, but
                  be aware that 1/ the retargeted campaign should obviously be sent before this one
                  and 2/ potential reach won’t work properly on the current campaign as it depends
                  on a campaign that hasn't been sent yet.
                </div>
              </Hint>
            )}
          </div>

          {hasEventLabel ? (
            <div className="condi__header__event">
              {attribute.allowedKeys.size > 0 && (
                <ConditionEventParam
                  app={app}
                  eventName={attribute.label}
                  eventId={attribute.id}
                  onEventParamKeyChanged={onEventParamKeyChanged}
                  onEventParamValueChanged={onEventParamValueChanged}
                  allowedKeys={attribute.allowedKeys}
                  canUseData={canQueryEventData}
                  pickedKey={String(args.get('__KEY__'))}
                  value={args.get('__VALUE__')}
                />
              )}
            </div>
          ) : (
            <div className="condi__header__phrase" />
          )}
          <div className="condi__header__action">
            <Button kind="discreet" onClick={onConditionToggled}>
              Invert
            </Button>
            <Button kind="discreet" onClick={onDelete}>
              <Icon icon="delete" size={11} />
            </Button>
          </div>
        </div>
        <div className="condi__builder">
          <div
            className={`condi__builder__negate ${negate ? 'condi__builder__negate--active' : ''}`}
          >
            {negate ? 'NOT' : ''}
          </div>
          {attribute.id === 'b.position' && (
            <MapPicker
              position={position}
              radius={args.get('__RADIUS__')}
              updatePosition={onPositionChanged}
              updateRadius={onRadiusChanged}
            />
          )}
          {!isCustom && (
            <ConditionFunction
              attribute={attribute}
              functions={functions}
              active={func}
              onFunctionChanged={onFunctionChanged}
              canUseEventCountPeriod={canUseEventCountPeriod}
            />
          )}
          {!isCustom && (func || attribute.type !== 'DATE' || operator?.api !== '$exists') && (
            <ConditionOperator
              active={operator}
              operators={operators}
              onChange={onOperatorChanged}
              func={func}
            />
          )}
          {attribute.id.substr(0, 3) === 'be.' && (
            <CampaignPicker
              token={String(args.get('__TOKEN__', ''))}
              pickCampaign={onCampaignTokenChanged}
              optionsKind={attribute.id === 'be.displayed' ? 'LOCAL' : 'PUSH'}
            />
          )}
          {attribute.id.substr(0, 3) === 'bt.' && (
            <div className="condi__builder__value condi__builder__value--multi">
              <CustomAudiencePicker
                selectOnly
                appId={app.id}
                // @ts-expect-error legacy
                values={Immutable.isImmutable(value) ? value : Immutable.Set()}
                updateCustomAudiences={onValueChanged}
              />
            </div>
          )}
          {!isCustom && attribute.id.substr(0, 3) !== 'bt.' && (
            <ConditionInput
              attribute={attribute}
              attributeValues={attributeValues}
              functionName={func ? func.value : null}
              inputType={inputType}
              value={value}
              loading={loading}
              onInputValueChanged={onInputValueChanged}
              onPeriodChanged={onPeriodChanged}
              onValueChanged={onValueChanged}
              onNumberValueChanged={onNumberValueChanged}
              period={period}
              valid={valid}
            />
          )}
          {attribute.id === 'b.device_type' && platform === 'ios' && (
            <div
              style={{
                fontSize: '12px',
                textAlign: 'left',
                padding: '4px 0 0 5px',
              }}
            >
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  padding: 0,
                  color: colors.textAction,
                }}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => helper('iPhone')}
              >
                all iPhones
              </button>{' '}
              -{' '}
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  padding: 0,
                  color: colors.textAction,
                }}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => helper('iPad')}
              >
                all iPads
              </button>{' '}
              -{' '}
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  padding: 0,
                  color: colors.textAction,
                }}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => helper('iPod')}
              >
                all iPods
              </button>{' '}
              -{' '}
              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  padding: 0,
                  color: colors.textAction,
                }}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => helper('')}
              >
                empty
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
)
