import styled, { css } from 'styled-components'

import { colors } from 'components/styled/tokens'
import { grayscale } from 'components/styled/tokens/schemes'

import { BaseThumb, InactiveEditorContainer } from 'com.batch/message-builder/ui/components/common'

export const IosContainer = styled.div`
  ${p =>
    p.theme.isFocused
      ? css`
          background: ${grayscale['10']};
          transition: background-color 0.2s ease;
        `
      : css`
          background: ${grayscale['05']};
        `}

  padding: ${p => (p.theme.isExpanded && p.theme.hasMedia ? '0px' : '10px')};
  border-radius: 14px;
  width: 386px;
  margin-top: ${p => (p.theme.abTestingEnabled ? 225 : 188)}px;
  margin-bottom: ${p => (p.theme.isExpanded ? 80 : 0)}px;
  display: grid;
  color: ${colors.text};
  ${p =>
    p.theme.isExpanded
      ? css`
          overflow: hidden;
          grid-template-columns: auto 1fr auto;
          grid-template-rows: auto;
          grid-column-gap: 6px;
          grid-template-areas:
            'ios title date'
            'ios message message'
            'thumb thumb thumb';
        `
      : css`
          overflow: visible;
          grid-template-columns: auto 1fr auto;
          grid-template-rows: auto;
          grid-column-gap: 6px;
          grid-template-areas:
            'ios title date'
            'ios message ${p.theme.hasMedia || p.theme.draggingState !== 'NONE'
              ? 'thumb'
              : 'message'}'
            'ios message ${p.theme.hasMedia || p.theme.draggingState !== 'NONE'
              ? 'thumb'
              : 'message'}';
        `};
`

export const IosAppIcon = styled.div<{
  $url?: string
  $noBackground?: boolean
}>`
  width: 34px;
  grid-area: ios;
  place-self: center;
  height: 34px;
  margin-left: ${p => (p.theme.isExpanded && p.theme.hasMedia ? '10px' : '0px')};
  border-radius: 8px;
  background: ${p => (!p?.theme?.app?.icon ? '#ACB1B9' : 'unset')};
  background-size: cover;
  ${p =>
    p?.theme.app?.icon &&
    css`
      background-image: url(${p?.theme.app?.icon});
    `}
`
export const Thumb = styled(BaseThumb)<{
  $url: string
  $noBackground?: boolean
}>`
  position: relative;
  display: ${p => (!!p.$url || p.theme.draggingState !== 'NONE' ? 'block' : 'none')};
  ${p =>
    p.theme.isExpanded
      ? css`
          height: ${p.$noBackground ? 'auto' : '160px'};
          width: 100%;
          video,
          audio {
            width: 386px;
            object-fit: cover;
          }
          audio {
            margin: 6px 6px 0 6px;
          }
          ${!p.$noBackground &&
          css`
            &:before {
              content: '';
              display: block;
              background-image: url(${p.$url ? p.$url : 'none'});
              background-size: 100%;
              background-repeat: no-repeat;
              background-position: center center;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }
          `}
        `
      : css`
          border-radius: 8px;
          overflow: hidden;
          ${!p.$noBackground
            ? css`
                &:before {
                  content: '';
                  display: block;
                  background-image: url(${p.$url ? p.$url : 'none'});
                  background-size: cover;
                  width: 32px;
                  height: 32px;
                }
                position: relative;
                width: 32px;
                height: 32px;
              `
            : css`
                display: flex;
                align-items: center;
                justify-content: center;
              `};
        `}
  grid-area: thumb;
  margin-top: -7px; // YDA: Pas très propre mais je ne vois pas comment faire autrement pour faire remonter thumb sur la 1st row de la grid vu que c'est le principe meme de grid ?
`

export const Title = styled.div`
  font-weight: 500;
  margin-top: ${p => (p.theme.isExpanded && p.theme.hasMedia ? '10px' : 'unset')};
  grid-area: title;
  margin-bottom: ${p => (p.theme.isFocused ? '10px' : 'unset')};
  transition: margin-bottom 0.2s ease;
  ${InactiveEditorContainer} {
    margin-bottom: -2px;
    word-break: break-word;
    -webkit-line-clamp: ${p => (p.theme.isExpanded ? '2' : '1')};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
export const Message = styled.div`
  --margin: ${p => (p.theme.isExpanded && p.theme.hasMedia ? '10px' : 'unset')};
  font-weight: 400;
  grid-area: message;
  margin-right: var(--margin);
  margin-bottom: var(--margin);
  transition:
    margin-right 0.2s ease,
    margin-bottom 0.2s ease;
  ${InactiveEditorContainer} {
    margin-top: -2px;
    word-break: break-word;
    -webkit-line-clamp: ${p => (p.theme.isExpanded ? '6' : '4')};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
export const DateLabel = styled.div`
  color: ${colors.textLight};
  text-align: right;
  font-size: 11px;
  margin-right: ${p => (p.theme.isExpanded && p.theme.hasMedia ? '10px' : 'unset')};
  grid-area: date;
  margin-top: ${p => (p.theme.isExpanded && p.theme.hasMedia ? '10px' : 'unset')};
`
