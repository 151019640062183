import { type Map } from 'immutable'

import {
  type AttributesJSON,
  type AttributesJSONItem,
  type QueryValue,
  type TypeValue,
} from 'com.batch/profile/infra/debug/models/shared.model'

export const formatAttributeProperty = (prop: string, type: TypeValue): string => {
  if (type === 'DATE' || type === 'URL') {
    return `${type.toLowerCase()}(${prop})`
  }
  return prop
}

export const getAttributeJsonItem = (
  attribute: QueryValue
): AttributesJSONItem | null | undefined => {
  switch (attribute.type) {
    case 'BOOL':
      return attribute.boolValue
    case 'LONG':
      return attribute.longValue
    case 'DOUBLE':
      return attribute.doubleValue
    case 'URL':
      return attribute.urlValue
    case 'DATE':
      return attribute.dateValue?.isValid() ? attribute.dateValue?.toISOString() : 'Invalid date'
    case 'OBJECT': {
      const objectValue = attribute.objectValue ?? {}
      const obj: {
        [key: string]: AttributesJSONItem | null | undefined
      } = {}
      Object.keys(objectValue).map(key => {
        const attr = objectValue[key]
        obj[key] = getAttributeJsonItem(attr)
      })
      return obj
    }
    case 'ARRAY':
      return attribute.arrayValue?.map(getAttributeJsonItem) ?? []
    default:
      return attribute.stringValue
  }
}

export const convertAttributesToJson = (attributes: Map<string, QueryValue>): string => {
  const attributesJSON: AttributesJSON = {}
  attributes
    .keySeq()
    .toArray()
    .sort((a, b) => a.localeCompare(b))
    .map(key => {
      const attribute = attributes.get(key)
      if (attribute) {
        const item = getAttributeJsonItem(attribute)
        if (item) {
          attributesJSON[formatAttributeProperty(key, attribute.type)] = item
        }
      }
    })
  return JSON.stringify(attributesJSON, null, 4)
}
