import styled from 'styled-components'

import { InputContainer } from 'components/form/styles'
import { colors } from 'components/styled/tokens'

export const EditorContainer = styled(InputContainer)`
  position: relative;
  display: block;
  .batch_templating__tag {
    padding: 1px 3px;
    font-size: 13px;
    background-color: #f1f6fc;
    word-break: break-all;
    color: #434d5f;
    border-radius: 10px;
  }
  .batch_templating__logical {
    padding: 1px 3px;
    font-size: 13px;
    background-color: #fef1c1;
    color: #39424e;
    border-radius: 2px;
  }
  .editor-placeholder {
    color: ${colors.textDisabled};
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    padding: 8px 16px;
    left: 0;
    top: 0;
    font-size: 15px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
  }
  .editor-input {
    height: auto;
    padding: 8px 30px 8px 16px;
    min-height: inherit;
    outline: none;
  }
  .editor-input.disabled {
    background: ${colors.fillDisabled};
    cursor: not-allowed;
  }
`
