import { List } from 'immutable'

import { computeRate } from './compute'

import {
  type ClickLinkRecord,
  ClickLinkFactory,
} from 'com.batch/orchestration-analytics/models/click-links-detail.record'
import {
  type ClickDetail,
  type ClickLinkName,
  type ClickUrlCleaned,
} from 'com.batch/shared/infra/types/grpc-stats-service'

const isClickLinkName = (item: ClickLinkName | ClickUrlCleaned): item is ClickLinkName =>
  (item as ClickLinkName).linkName !== undefined

export const parseClickLinks = ({
  clickDetails,
}: {
  clickDetails: ClickDetail | null | undefined
}): List<ClickLinkRecord> => {
  if (!clickDetails) return List()

  const mapClicksDetails = (data: ClickLinkName[] | ClickUrlCleaned[]): ClickLinkRecord[] =>
    data.map((item: ClickLinkName | ClickUrlCleaned) => {
      const name = isClickLinkName(item) ? item.linkName : item.urlCleaned
      return ClickLinkFactory({
        name: name,
        count: item.count,
        unique: item.uniqueCount,
        rate: computeRate(item.uniqueCount, clickDetails?.uniqueCount ?? 0),
      })
    })

  return List([
    ...mapClicksDetails(clickDetails.byLinkName ?? []),
    ...mapClicksDetails(clickDetails.byUrlCleaned ?? []),
  ])
}
