
import Immutable, { type List, type Map } from 'immutable'
import { createSelector } from 'reselect'

import { type SegmentStateRecord } from 'com.batch/segments/store/segments.state'
import { type State, type Extract, type fetchingState } from 'com.batch.redux/_records'

import { SegmentFactory, type SegmentRecord } from 'com.batch/segments/models/segment.records'

export const segmentsSelector = (state: State): SegmentStateRecord => state.segments

export const allSegmentsSelector: Extract<Map<string, SegmentRecord>> = createSelector(
  segmentsSelector,
  segments => segments.segments
)
export const allSegmentAsListSelector: Extract<List<SegmentRecord>> = createSelector(
  segmentsSelector,
  segments => segments.segments.toList()
)

export const currentPageSegmentsSelector: Extract<List<SegmentRecord>> = createSelector(
  segmentsSelector,
  segments => {
    const segmentsNames = segments.segmentsPerPage.get(segments.page, Immutable.List<string>())
    return segmentsNames.map(name => segments.segments.get(name, SegmentFactory()))
  }
)

export const segmentLoadingStateSelector: Extract<{
  fetchingLoadingState: fetchingState
  mutationLoadingState: fetchingState
}> = createSelector(segmentsSelector, segments => {
  return {
    fetchingLoadingState: segments.loadingState,
    mutationLoadingState: segments.mutationLoadingState,
  }
})
