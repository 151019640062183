import * as React from 'react'
import { useTheme } from 'styled-components'

import { LoadingRect } from './legend.styles'

import { type Pattern } from 'com.batch/shared/infra/types/chart-data'

type Props = {
  pattern: Pattern | null | undefined
  color: string | undefined
  isClicked: boolean
}

export const LegendIcon = ({ pattern, color, isClicked }: Props): React.ReactElement => {
  const { isEmpty, isLoading } = useTheme()
  if (isEmpty || isLoading)
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none">
        <LoadingRect x={4} y={4} width={14} height={14} rx={4} isEmpty={Boolean(isEmpty)} />
      </svg>
    )
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none">
      <rect x={4} y={4} width={14} height={14} rx={4} fill={color} />
      {pattern && (
        <React.Fragment>
          {pattern.content}
          <rect
            x={4}
            y={4}
            width={14}
            height={14}
            rx={4}
            fill={`url(#${pattern.id})`}
            style={{ opacity: 0.12 }}
          />
        </React.Fragment>
      )}
      {isClicked && (
        <rect x={1.5} y={1.5} width={19} height={19} rx={5} stroke={color} strokeWidth={2} />
      )}
    </svg>
  )
}
