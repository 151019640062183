import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { type AppRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import {
  optionalCurrentProjectSelector,
  projectMatchingCurrentAppSelector,
} from 'com.batch.redux/project.selector'

const redirectToProjectOrApp = (event: KeyboardEvent, project?: ProjectRecord | null) => {
  // C'est temporaire donc je me suis pas embété a utiliser les routeurs et tout
  // Si vous avez la foi...
  const currentLocation = document.location.href
  const isInProject = currentLocation.indexOf('projects') !== -1

  let nextLocation
  if (isInProject) {
    let appID = window.initialData.appId
    if (appID === 0) {
      appID = window.initialData.apps.filter(a => a.projectKey === project?.projectKey)[0]?.id ?? 0
    }

    if (appID !== 0) {
      nextLocation = `/${window.company.id}/apps/${appID}/settings/global`
    } else {
      alert('Could not find App ID to navigate to.')
      return
    }
  } else {
    if (!project) {
      alert("This app doesn't seem to be part of a project.")
      return
    }
    nextLocation = `/${window.company.id}/projects/${project?.id ?? 0}/apps/${
      window.initialData.appId
    }/settings/global`
  }

  if (event.shiftKey) {
    window.open(nextLocation, '_blank')
  } else {
    window.location = nextLocation
  }
}

const openConsole = (event: KeyboardEvent, app: AppRecord, project?: ProjectRecord | null) => {
  const currentLocation = document.location.href
  const regex = /projects\/(.{7})/
  const match = currentLocation.match(regex)
  const isInProject = Boolean(match)

  let nextLocation
  if (isInProject) {
    nextLocation = `/console/projects/${project?.projectKey ?? match?.groups?.projectId ?? ''}`
  } else {
    nextLocation = `/console/apps/${app.id}`
  }

  if (event.shiftKey) {
    window.open(nextLocation, '_blank')
  } else {
    window.location = nextLocation
  }
}

// Provides admin only hotkeys
// Alt-P: Toggle between App and Project
// Alt-C: Opens console
export const useAdminHotkeys = () => {
  // We need two way to get the current project in order to
  // properly detect it when there is no current app (pure CEP)
  // screens and when we don't have a current project and need to
  // search for it (MEP)
  const projectFromCEP = useSelector(optionalCurrentProjectSelector)
  const projectFromMEP = useSelector(projectMatchingCurrentAppSelector)
  const app = useSelector(currentAppSelector)

  const onKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (!event.ctrlKey && !event.metaKey && event.altKey) {
        switch (event.keyCode) {
          case 80:
            redirectToProjectOrApp(event, projectFromMEP)
            break
          case 67:
            openConsole(event, app, projectFromCEP)
            break
        }
      }
    },
    [app, projectFromCEP, projectFromMEP]
  )

  React.useEffect(() => {
    if (!window.isAdmin) {
      return
    }

    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  })
}
