/* eslint-disable react/jsx-no-bind */
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { ContentPushSettings } from 'components/campaign/form/content-push-settings'
import { ContentPushVariant } from 'components/campaign/form/content-push-variant'
import {
  PreviewScroll,
  PreviewScrollContent,
  PushPreviewToolbarContainer,
} from 'components/campaign/form/form.styles'
import PreviewToolbar from 'components/campaign/preview/preview-toolbar'
import { StandalonePreviewConnected } from 'components/campaign/preview/standalone-preview'
import { Banner } from 'components/common/banner'
import { BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { Icon } from 'components/common/svg-icon'

import {
  type AppRecord,
  type CampaignABRecord,
  type LanguageRecord,
  type State,
} from 'com.batch.redux/_records'
import {
  pushShowVariant,
  setAdvancedConfig,
  togglePushVariant,
  uploadError,
} from 'com.batch.redux/campaign.action'
import { advancedPaneOpenedSelector } from 'com.batch.redux/campaign.selector'
import { updatePushContentMEP, updatePushSettings } from 'com.batch.redux/content'
import { sdkMatchingTargetVersionSelector } from 'com.batch.redux/sdk.selector'
import { pushContentABForActiveLanguageSelector } from 'com.batch.redux/targeting.selector.composed'
import { changePreferedInstall } from 'com.batch.redux/template'

type ContentPushProps = {
  lang: LanguageRecord
  previewedVariant: 'a' | 'b'
  abtesting: CampaignABRecord
  app: AppRecord
  campaignHasLanding: boolean
}

// ====================== LOCAL SELECTOR
const templateLoadingSelector = (state: State) => state.template.loading
const installIdSelector = (state: State) => state.template.preferedInstallId
const installIdsSelector = (state: State) => state.template.pinnedInstallIds
const pushSettingsSelector = (state: State) => state.content.pushSettings

const variants = ['a', 'b'] as const

export const ContentPush = ({
  lang,
  previewedVariant,
  abtesting,
  app,
  campaignHasLanding,
}: ContentPushProps): React.ReactElement => {
  // local state --------------------------------
  const [expanded, setExpanded] = React.useState(false)
  const [webPreview, setWebPreview] = React.useState('chrome-win')

  const dispatch = useDispatch()

  // redux state --------------------------------
  const advancedPaneOpened = useSelector(advancedPaneOpenedSelector)
  const content = useSelector(pushContentABForActiveLanguageSelector)
  const sdkMatchingTargetVersion = useSelector(sdkMatchingTargetVersionSelector)
  const installIds = useSelector(installIdsSelector)
  const installId = useSelector(installIdSelector)
  const templateLoading = useSelector(templateLoadingSelector)
  const pushSettings = useSelector(pushSettingsSelector)

  // callbacks ----------------------------------
  const toggleAdvanced = React.useCallback(() => {
    dispatch(setAdvancedConfig(!advancedPaneOpened))
  }, [advancedPaneOpened, dispatch])

  const togglePushVariantBound = React.useCallback(
    variant => dispatch(togglePushVariant(variant)),
    [dispatch]
  )
  const uploadErrorBound = React.useCallback(message => dispatch(uploadError(message)), [dispatch])
  const changePreferedInstallBound = React.useCallback(
    installId => {
      dispatch(changePreferedInstall(installId))
    },
    [dispatch]
  )
  const updatePushContentBound = React.useCallback(
    payload => dispatch(updatePushContentMEP(payload)),
    [dispatch]
  )
  const updatePushSettingsBound = React.useCallback(
    (setting, value: string) => dispatch(updatePushSettings(setting, value)),
    [dispatch]
  )
  // derived state ------------------------------
  const platform = React.useMemo(() => app.platform, [app.platform])
  const previewOptionsCount = React.useMemo(
    () =>
      [
        platform !== 'webpush' || webPreview === 'android',
        platform === 'webpush',
        installIds.size > 0,
      ].filter(i => i === true).length,
    [platform, installIds.size, webPreview]
  )
  const warnPreviewInconsistent = React.useMemo(
    () =>
      expanded &&
      sdkMatchingTargetVersion(33) === 0 &&
      platform === 'android' &&
      pushSettings.attachmentKind === 'image' &&
      pushSettings.attachmentUrl,
    [
      expanded,
      platform,
      pushSettings.attachmentKind,
      pushSettings.attachmentUrl,
      sdkMatchingTargetVersion,
    ]
  )

  return (
    <div>
      <FlexLine sameHeight>
        <FlexLineItem
          grow={1}
          style={{
            maxWidth: '440px',
            minWidth: '280px',
            paddingRight: 0,
            minHeight: '300px',
          }}
        >
          {variants
            .filter(variant => variant === 'a' || abtesting.enabled)
            .map(variant => (
              <ContentPushVariant
                key={`${variant}-${lang.value}`}
                lang={lang.value}
                show={() => dispatch(pushShowVariant(variant))}
                content={content.get(variant)}
                variant={variant}
                hasTemplate={app.features.has('macro')}
                weight={
                  abtesting.activeVariants.has(variant)
                    ? abtesting.activeVariants.size === 2
                      ? 50
                      : 100
                    : 0
                }
                updatePushContent={updatePushContentBound}
                shown={previewedVariant === variant}
                toggle={() => togglePushVariantBound(variant)}
                showVariantInfo={abtesting.enabled}
                isTitleRequired={platform === 'windows' || platform === 'webpush'}
                app={app}
                campaignHasLanding={campaignHasLanding}
              />
            ))}
        </FlexLineItem>
        <FlexLineItem
          grow={1}
          bl
          style={{
            paddingBottom: 0,
            paddingRight: 0,
            minWidth: 300,
            position: 'relative',
            minHeight: '520px',
            background: '#fafafc',
          }}
        >
          {warnPreviewInconsistent && (
            <Banner
              kind="bloc"
              intent="danger"
              title="The preview might not be accurate since your current SDK version doesn't support the most recent features."
              style={{
                position: 'absolute',
                zIndex: 3,
              }}
            />
          )}
          <PushPreviewToolbarContainer
            style={{ top: 0, bottom: 'inherit', borderBottom: '1px solid HSL(228, 19%, 95%)' }}
          >
            <PreviewToolbar
              disableFullscreen
              hasLanding={false}
              installLoading={templateLoading}
              isPush
              dropUp
              toggleFullScreen={() => {}}
              previewMode={expanded ? 'expanded' : 'normal'}
              isWeb={app.platform === 'webpush'}
              previewWeb={webPreview}
              updatePreviewMode={mode => setExpanded(mode === 'expanded')}
              updatePreviewWeb={mode => setWebPreview(mode)}
              changePreferedInstall={changePreferedInstallBound}
              installIds={installIds}
              installId={installId}
              style={{ justifyContent: previewOptionsCount > 1 ? 'space-between' : 'center' }}
            />
          </PushPreviewToolbarContainer>
          <PreviewScroll>
            <PreviewScrollContent
              isWeb={app.platform === 'webpush' && webPreview !== 'android'}
              style={{ paddingTop: 60 }}
            >
              <StandalonePreviewConnected
                variant={previewedVariant}
                previewMode={expanded ? 'expanded' : 'normal'}
                previewWeb={webPreview}
              />
            </PreviewScrollContent>
          </PreviewScroll>
        </FlexLineItem>
      </FlexLine>
      <BoxHeader
        style={{
          borderTop: '1px solid #ececec',
          height: 48,
          marginBottom: advancedPaneOpened ? -1 : 0,
          borderRadius: advancedPaneOpened ? 0 : '0 0 8px 8px',
          borderBottom: advancedPaneOpened
            ? '1px solid rgba(233,233,233,1)'
            : '1px solid transparent',
          cursor: 'pointer',
        }}
        onClick={toggleAdvanced}
      >
        <HeaderBoxTitle title="Advanced settings" />
        <Button style={{ height: 28, width: 28 }} kind="inline">
          <Icon icon={advancedPaneOpened ? 'chevron-up' : 'chevron-down'} />
        </Button>
      </BoxHeader>
      {advancedPaneOpened && (
        <ContentPushSettings
          app={app}
          uploadError={uploadErrorBound}
          updatePushSettings={updatePushSettingsBound}
          settings={pushSettings}
        />
      )}
    </div>
  )
}
