import React from 'react'

/**
 * Custom hook to managing modular bar charts
 */

type Result = {
  clickedIndex: number
  setClickedIndex: (index: number) => () => void
  hoveredIndex: number | null
  setHoveredIndex: (index: number | null) => () => void
}
export const useModularDonutChart = (): Result => {
  const [clickedIndex, setClickedIndex] = React.useState<number>(0)
  const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null)

  const handleClickedIndexChange = React.useCallback(
    (index: number) => () => setClickedIndex(index),
    []
  )
  const handleHoveredIndexChange = React.useCallback(
    (index: number | null) => () => setHoveredIndex(index),
    []
  )

  return {
    clickedIndex,
    setClickedIndex: handleClickedIndexChange,
    hoveredIndex,
    setHoveredIndex: handleHoveredIndexChange,
  }
}
