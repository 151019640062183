import * as React from 'react'

import { useToggle } from 'components/_hooks'

export const useForceEnabledForm = (): boolean => {
  const forceEnabledState = useToggle()
  const shortcutListner = React.useCallback(
    e => {
      if (e.key === 'e' && e.ctrlKey) {
        forceEnabledState.toggle()
      }
    },
    [forceEnabledState]
  )
  React.useEffect(() => {
    window.addEventListener('keydown', shortcutListner)
    return () => window.removeEventListener('keydown', shortcutListner)
  }, [shortcutListner])
  return !window.isAdmin ? false : forceEnabledState.value
}
