import { type OrderedSet } from 'immutable'
import * as React from 'react'

import { Tooltip } from 'components/common/tooltip'

import { type onboardingStepType } from 'com.batch.redux/user.records'

type OnboardingCircleProps = {
  step: OrderedSet<onboardingStepType>
}

export const OnboardingCircle = ({ step }: OnboardingCircleProps): React.ReactElement => {
  const complete = step.has('company') && step.has('password')
  const needsEmailConfirm = !step.has('password')
  const color = needsEmailConfirm ? '#ee8749' : complete ? '#4ba34b' : '#da364e'
  const message = complete
    ? 'Onboarding completed'
    : needsEmailConfirm
    ? 'Awaiting email confirmation'
    : 'Email confirmed but onboarding still in progress'
  return (
    <Tooltip tooltip={message}>
      <svg viewBox="0 0 8 8" width="8" height="8">
        <circle cx={4} cy={4} r={4} fill={color} />
      </svg>
    </Tooltip>
  )
}
