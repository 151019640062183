import styled from 'styled-components'

import { colors, schemes, shadows } from 'components/styled/tokens'

type TimingLabelProps = {
  isSelected: boolean
}
export const TimingLabel = styled.label<TimingLabelProps>`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 68px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  pointer-events: ${p => (p.theme.disabledMode ? 'none' : 'unset')};
  background-color: ${p => (p.isSelected ? `${colors.fill} !important` : schemes.grayscale['01'])};
  box-shadow: ${p =>
    p.isSelected
      ? `0 0 0 2px ${colors.strokeAction} !important`
      : `0 0 0 1px ${colors.stroke}, ${shadows.raised}`};

  label {
    flex: 1 1 auto;
    height: 78px;
    padding: 14px 18px;
  }

  &:hover {
    box-shadow:
      0 0 0 1px ${colors.strokeHover},
      ${shadows.raised};
  }

  svg {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
`
