import Immutable, { type Map, type RecordOf, type Set } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

import {
  OrchestrationCampaignFactory,
  type OrchestrationCampaignRecord,
} from 'com.batch/orchestration-campaign/models/campaign.records'
import {
  getNodeId,
  type JourneyNodeRecord,
  JourneyNodes,
  JourneySettingsFactory,
  type JourneySettingsRecord,
} from 'com.batch/orchestration-journey/models/journey.records'

type OrchestrationStateProps = {
  version: number
  savedVersion: number
  triggerSettings: JourneySettingsRecord
  triggerNodes: Map<string, JourneyNodeRecord>
  triggerRootId: string
  campaign: OrchestrationCampaignRecord
  loadingState: fetchingState
  savingState: fetchingState
  state: campaignStateType
  channels: Set<ChannelUntilCleanup>
  id: string
  name: string
  incomplete: boolean
  editingNodeId: string
  labelCodes: Immutable.Set<string>
  copiedNodeId: string
  createdByApi: boolean
}
export type OrchestrationStateRecord = RecordOf<OrchestrationStateProps>

const fid1 = getNodeId('FINAL')
export const OrchestrationStateFactory = Immutable.Record<OrchestrationStateProps>({
  version: 0,
  savedVersion: 0,
  state: 'DRAFT',
  channels: Immutable.Set(),
  id: '',
  name: '',
  loadingState: 'INIT',
  savingState: 'INIT',
  campaign: OrchestrationCampaignFactory(),
  triggerSettings: JourneySettingsFactory(),
  triggerRootId: fid1,
  incomplete: false,
  triggerNodes: Immutable.Map([[fid1, JourneyNodes.Final({ id: fid1 })]]),
  editingNodeId: '',
  labelCodes: Immutable.Set(),
  copiedNodeId: '',
  createdByApi: false,
} as OrchestrationStateProps)
