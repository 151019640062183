import styled, { css } from 'styled-components'

import { colors } from 'components/styled/tokens'

type EstimateContainerProps = {
  $withSeparator: boolean
}
export const EstimateContainer = styled.div<EstimateContainerProps>`
  ${p =>
    p.$withSeparator
      ? css`
          border-top: 1px solid ${colors.stroke};
        `
      : ''}

  padding: 20px;
`
export const EstimateTitle = styled.h3`
  font-size: 1em;
  font-weight: 500;
  padding-bottom: 4px;
`
type EstimateCountProps = {
  $warn: boolean
}
export const EstimateCount = styled.div<EstimateCountProps>`
  color: ${({ $warn }: EstimateCountProps) => ($warn ? colors.textWarning : 'currentColor')};
  display: flex;
  gap: 36px;
  .styled-matching {
    font-size: 1.4em;
  }
  .styled-total {
    font-size: 1em;
    color: ${({ $warn }: EstimateCountProps) => ($warn ? colors.textWarning : colors.textLight)};
  }
  .styled-main {
    padding-bottom: 4px;
    i {
      margin-bottom: -2px;
    }
  }
  .styled-sub {
    display: flex;
    gap: 20px;
  }
  .styled-channel {
    display: grid;
    grid-template-columns: 18px 1fr;
    align-items: center;
    margin-right: 8px;
  }
`
