import styled from 'styled-components'

import { BoxBody } from 'components/common/box'

import { ProfilebaseBox } from 'com.batch/profilebase/ui/pages/profilebase.syles'

export const ImportedTokensBox = styled(ProfilebaseBox)`
  position: relative;
  margin-bottom: 16px;

  ${BoxBody} {
    padding: 0 20px 16px 20px;
  }
`

export const ImportedTokensIllustration = styled.div`
  display: none;
  position: absolute;
  width: 267px;
  height: 100%;
  top: 0;
  right: 0px;
  background-image: url('/medias/img/illustrations/analytics/illustration_imported_tokens@2x.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;

  @media (min-width: 992px) {
    display: ${p => (p.theme.isEmpty ? 'none' : 'block')};
  }
`
