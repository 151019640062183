import { type Dayjs } from 'dayjs'
import * as React from 'react'
import styled from 'styled-components'

import { EventFormatter } from 'components/campaign/event-formatter'
import { Icon } from 'components/common/svg-icon'
import { Timezone } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { computeRepeatWord } from 'com.batch.common/utils'

import { type CampaignRecord } from 'com.batch.redux/_records'

type ReviewCampaignDateProps = {
  campaign: CampaignRecord
  lastSend: Dayjs | null | undefined
  style?: any
  className?: string
}
const nowUTC = dayjs.utc()
const ReviewCampaignDate = ({
  campaign,
  lastSend,
  style,
  className,
}: ReviewCampaignDateProps): React.ReactElement => {
  const completed = campaign.state === 'COMPLETED'
  const running = campaign.state === 'RUNNING'

  if (campaign.type === 'push') {
    if (campaign.sendType === 'trigger') {
      return (
        <Container style={style} className={className}>
          <Icon icon="campaign-trigger" size={13} style={{ marginRight: 8 }} /> On{' '}
          <EventFormatter eventCode={campaign.triggerConfig.enterEvent} />
        </Container>
      )
    } else if (campaign.sendType === 'recurring') {
      return (
        <Container style={style} className={className}>
          <Icon icon="campaign-recurring" size={13} style={{ marginRight: 8 }} /> Every{' '}
          {computeRepeatWord(campaign.repeatUnit, campaign.repeatFrequency, campaign.start)} at{' '}
          {campaign.start && campaign.start.format('HH[h]mm')}
          {!campaign.tzAware && <Timezone style={{ margin: '0 0 0 5px' }}>UTC</Timezone>}
          {!completed && campaign.end && running && `, until ${campaign.end.format('DD/MM/YYYY')}`}
          {running && !campaign.end}
          {!running && lastSend && `, until ${lastSend.format('DD/MM/YYYY')}`}
        </Container>
      )
    } else {
      return (
        <Container style={style} className={className}>
          <Icon
            icon={
              campaign.start && campaign.start.isBefore(nowUTC)
                ? 'campaign-instant'
                : 'campaign-scheduled'
            }
            size={13}
            style={{ marginRight: 8 }}
          />
          {campaign.start && campaign.start.format('DD/MM/YYYY [at] HH[h]mm ')}
          {!campaign.tzAware && <Timezone style={{ margin: '0 0 0 5px' }}>UTC</Timezone>}
        </Container>
      )
    }
  } else {
    return (
      <Container style={style} className={className}>
        <Icon icon="campaign-trigger" size={13} style={{ marginRight: 8 }} /> On{' '}
        <EventFormatter eventCode={campaign.trigger} eventLabel={campaign.triggerLabel} />
      </Container>
    )
  }
}

const Container = styled.span`
  color: #323639;
  ${Timezone} {
    margin: 0 3px 0 0;
    vertical-align: 1px;
  }
`

export default ReviewCampaignDate
