import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Select } from 'components/form'
import { colors } from 'components/styled/tokens'

import * as Inputs from './input'
import { LeftBase } from './left/left-base'
import {
  ConditionPart,
  ConditionPartAttribute,
  ConditionPartAction,
  ConditionEventFilterContainer,
} from './query.styles'

import { api } from 'com.batch.redux/query/query'
import {
  type QueryAttributeRecord,
  type ConditionRecord,
  ConditionFactory,
} from 'com.batch.redux/query/query.records'
import { LeftArray } from './left/left-array'

type ConditionEventFilterProps = {
  condition: ConditionRecord
  updateCondition: (condition: ConditionRecord) => void
  disabledMode: boolean
  eventFilterIndex: number
}

const optionToString = (option?: QueryAttributeRecord | null) => option?.label ?? ''

const formatOption = (option?: QueryAttributeRecord | null) => {
  if (!option) {
    return null
  }

  return (
    <Grid template="auto 1fr">
      <Icon
        icon={option.icon}
        thickness={1.2}
        style={{ color: colors.textLight, marginTop: -1, marginLeft: 3 }}
      />
      {option?.label}
    </Grid>
  )
}
const emptyFunc = () => {}
export const ConditionEventFilter = ({
  condition,
  updateCondition,
  disabledMode,
  eventFilterIndex,
}: ConditionEventFilterProps): React.ReactElement => {
  const filter = React.useMemo(
    () => condition.eventFilters.get(eventFilterIndex, ConditionFactory()),
    [condition, eventFilterIndex]
  )
  const updateFilter = React.useCallback(
    (evf: ConditionRecord) => {
      updateCondition(
        condition.set('eventFilters', condition.eventFilters.set(eventFilterIndex, evf))
      )
    },
    [condition, eventFilterIndex, updateCondition]
  )
  const updateAttribute = useCallback(
    (value?: QueryAttributeRecord | null) => {
      if (!value) return
      updateFilter(api.buildDefaultCondition(value))
    },
    [updateFilter]
  )

  const onRemoveFilter = useCallback(() => {
    updateCondition(condition.set('eventFilters', condition.eventFilters.delete(eventFilterIndex)))
  }, [condition, eventFilterIndex, updateCondition])

  const onAddFilter = useCallback(() => {
    updateCondition(condition.set('eventFilters', condition.eventFilters.push(ConditionFactory())))
  }, [condition, updateCondition])

  const isFirstEventFilter = useMemo(() => eventFilterIndex === 0, [eventFilterIndex])

  const ConditionOperandInput = Inputs[filter.value.mode]

  const isLastEventFilter = useMemo(
    () => eventFilterIndex === condition.eventFilters.size - 1,
    [condition, eventFilterIndex]
  )

  const baseProps = React.useMemo(
    () => ({
      condition: filter,
      updateCondition: updateFilter,
      removeSelf: onRemoveFilter,
      isDisabled: disabledMode,
      negate: emptyFunc,
      unNegate: emptyFunc,
      negated: filter.isEventFilterNegated ?? false,
      isInvalid: false,
    }),
    [filter, updateFilter, onRemoveFilter, disabledMode]
  )

  const hasReachedMaximumEventFilters = useMemo(
    () => condition.eventFilters.size > 9,
    [condition.eventFilters.size]
  )

  return (
    <ConditionEventFilterContainer isSub withSeparator={isLastEventFilter}>
      <Icon
        icon="nested"
        color="none"
        className="styled-icon"
        style={{ marginLeft: '12px', marginBottom: '6px' }}
      />
      <div style={{ marginRight: '8px', color: colors.textLight, fontWeight: 500, width: '48px' }}>
        {isFirstEventFilter ? 'Where' : 'And'}
      </div>
      <ConditionPartAttribute>
        <Select
          isSearchable
          options={condition.attribute?.eventAttributes}
          value={filter.attribute}
          placeholder="Pick an event attribute"
          optionFormatter={formatOption}
          menuOffset={40}
          optionToString={optionToString}
          onChange={updateAttribute}
        />
      </ConditionPartAttribute>
      {filter.attribute && (
        <ConditionPart>
          {filter.attribute.type === 'TAG' ? (
            <LeftArray {...baseProps} />
          ) : (
            <LeftBase {...baseProps} />
          )}
        </ConditionPart>
      )}
      <ConditionPart style={{ flexGrow: 1 }}>
        {filter.attribute && <ConditionOperandInput {...baseProps} />}
      </ConditionPart>
      {isLastEventFilter && (
        <Tooltip
          tooltip={
            hasReachedMaximumEventFilters ? (
              <div style={{ fontSize: 14 }}>
                Event filters on a condition are limited to 10 maximum
              </div>
            ) : (
              <div style={{ fontSize: 14 }}>Add filter</div>
            )
          }
          minWidth={hasReachedMaximumEventFilters ? 220 : 100}
        >
          <ConditionPartAction style={{ margin: 0 }}>
            <Button
              kind="inline"
              intent="neutral"
              onClick={onAddFilter}
              disabled={hasReachedMaximumEventFilters}
              style={{ width: 36, marginRight: 8 }}
            >
              <Icon
                icon="add-list"
                color={hasReachedMaximumEventFilters ? colors.textDisabled : colors.textLight}
              />
            </Button>
          </ConditionPartAction>
        </Tooltip>
      )}
      <ConditionPartAction>
        {!disabledMode && (
          <Button onClick={onRemoveFilter} style={{ width: 36 }}>
            <Icon icon="remove" color={colors.textLight} size={16} />
          </Button>
        )}
      </ConditionPartAction>
    </ConditionEventFilterContainer>
  )
}
