import { type Map } from 'immutable'

import { type State } from 'com.batch.redux/_records'
import {
  type AbTestedInAppRecord,
  type AbTestedPushRecord,
  type PushSettingsRecord,
} from 'com.batch.redux/content.records'

export const pushSelector = (state: State): Map<string, AbTestedPushRecord> => state.content.push
export const inappSelector = (state: State): Map<string, AbTestedInAppRecord> => state.content.inapp
export const pushSettingsSelector = (state: State): PushSettingsRecord => state.content.pushSettings
export const campaignNameSelector = (state: State): string => state.content.campaignName
