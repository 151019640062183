import Immutable from 'immutable'
import { v4 as uuidv4 } from 'uuid'

import {
  QuietTimesFactory,
  type QuietTimesRecord,
  EventWithOptionalQueryFactory,
  type JourneySettingsRecord,
} from './journey.records'

import { TriggerConfigFactory, type TriggerConfigRecord } from 'com.batch.redux/_records'

/*
  kinda legacy helper : for now the entry sheet works with trigger record
  to stay compatible with old form

  this could be rewritten later on to drop this file

*/

export const parseTriggerQuery = (query?: Record<any, any> | null): string => {
  return query ? JSON.stringify(query) : ''
}
export const formatJourneyQuery = (query: string): Record<any, any> | null | undefined => {
  return query ? JSON.parse(query) : undefined
}

export const generateUniqueEventId = (prefix: string): string => {
  return `${prefix}-${uuidv4()}`
}

export const settingsToTrigger = (settings: JourneySettingsRecord): TriggerConfigRecord => {
  return TriggerConfigFactory({
    enterEvent: settings.entryEvents.get(0)?.name ?? '',
    enterEventQuery: formatJourneyQuery(settings.entryEvents.get(0)?.query ?? ''),
    hasStart: settings.hasStart,
    start: settings.start,
    hasEnd: settings.hasEnd,
    end: settings.end,
    hasCapping: settings.hasCapping,
    capping: settings.capping,
    hasInstanceId: settings.hasInstanceId,
    instanceId: settings.instanceId,
    grace: settings.gracePeriod,
    hasGrace: settings.hasGrace,
  })
}

export const updateSettingsWithTrigger = (
  settings: JourneySettingsRecord,
  trigger: TriggerConfigRecord
): JourneySettingsRecord => {
  return settings
    .set(
      'entryEvents',
      Immutable.List(
        trigger.enterEvent
          ? [
              EventWithOptionalQueryFactory({
                name: trigger.enterEvent,
                query: parseTriggerQuery(trigger.enterEventQuery),
              }),
            ]
          : []
      )
    )
    .set('hasStart', trigger.hasStart)
    .set('start', trigger.start)
    .set('hasEnd', trigger.hasEnd)
    .set('end', trigger.end)
    .set('hasCapping', trigger.hasCapping)
    .set('capping', trigger.capping)
    .set('hasInstanceId', trigger.hasInstanceId)
    .set('instanceId', trigger.instanceId)
    .set('hasGrace', trigger.hasGrace)
    .set('gracePeriod', trigger.grace)
}

export const updateSettingsWithTiming = (
  settings: JourneySettingsRecord,
  trigger: TriggerConfigRecord
): JourneySettingsRecord => {
  return settings
    .set('hasStart', trigger.hasStart)
    .set('start', trigger.start)
    .set('hasEnd', trigger.hasEnd)
    .set('end', trigger.end)
}

export const updateSettingsQuietTimes = (
  settings: JourneySettingsRecord,
  quietTimes?: QuietTimesRecord | null
): JourneySettingsRecord => {
  return settings
    .set('hasQuietTimes', Boolean(quietTimes))
    .set('quietTimes', quietTimes ? quietTimes : QuietTimesFactory({}))
}
