import styled from 'styled-components'

import { BoxTitle, BoxBody, BoxHeader } from 'components/common/box'
import { EmptyField } from 'components/common/empty-states'
import { Empty } from 'components/styled/empty'
import { colors, schemes } from 'components/styled/tokens'

import { ProfilebaseBox } from 'com.batch/profilebase/ui/pages/profilebase.syles'
import { MetricContainer } from 'com.batch/shared/ui/component/metric/metric.styles'

type SubscriptionGrowthBlockBoxProps = {
  $color: string
  $hasData: boolean
}
export const SubscriptionGrowthBlockBox = styled(ProfilebaseBox)<SubscriptionGrowthBlockBoxProps>`
  ${BoxHeader} {
    padding: 0 16px;

    ${BoxTitle} {
      margin-left: 4px;
    }
  }

  ${BoxBody} {
    padding: 0 12px;
  }

  ${MetricContainer} {
    position: absolute;
    margin-left: 8px;
    margin-bottom: ${p => (p.$hasData ? 0 : '5px')};
    background-color: white;

    ${EmptyField}:first-child {
      margin-bottom: 0;
    }
  }

  ${Empty} {
    padding: 64px 26px 26px 26px;
    background-color: ${schemes.lightlucent['70']};
    margin-bottom: 7px;
    inset: 0;

    a {
      color: ${colors.textFeature};
    }
  }
`
