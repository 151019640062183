import * as React from 'react'

import { NoMoreEventsContent, NoMoreEventsContentTitle } from './no-more-events.styles'

export const NoMoreEvents = (): React.ReactElement => {
  return (
    <NoMoreEventsContent>
      <NoMoreEventsContentTitle>No more events</NoMoreEventsContentTitle>
      <div>
        We provide events history within a limit of <br />1 month & 60 events
      </div>
    </NoMoreEventsContent>
  )
}
