import * as React from 'react'

import { useQuery } from 'components/_hooks'
import { TableCellOrder, TableHeader } from 'components/common/table'
import { TableCell } from 'components/common/table/table.styles'

import { useSortParams } from 'com.batch/shared/hooks/use-sort-params'

export type SegmentTableHeaders = 'displayName'

export const Header = (): React.ReactElement => {
  const query = useQuery()
  const qSortBy = query.get('orderBy')

  const { sortDirectionParam, setSortParams } = useSortParams()

  const qSortOrder = sortDirectionParam
  // we only sort on display name (or no sort), need to tweak if this changes
  const onSortChange = React.useCallback((): void => {
    switch (sortDirectionParam) {
      case 'asc':
        return setSortParams('displayName', 'dsc')
      case 'dsc':
        return setSortParams(null, null)
      default:
        return setSortParams('displayName', 'asc')
    }
  }, [sortDirectionParam, setSortParams])

  return (
    <TableHeader style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
      <TableCellOrder
        sort={qSortBy === 'displayName' ? qSortOrder ?? 'asc' : undefined}
        onClick={onSortChange}
      >
        Name
      </TableCellOrder>

      <TableCell>Estimated profiles</TableCell>

      <TableCell>Orchestrations</TableCell>
      <div />
    </TableHeader>
  )
}
