export type PaginationProps = {
  total: number | null | undefined
  totalMatching: number | null | undefined
  page: number
  pageSize: number
}

export type PaginatedResponse<R> = {
  total: number | null | undefined
  totalMatching: number | null | undefined
  entities: Array<R>
}

export type PageFetcher<R> = (arg1: {
  page: number
  pageSize: number
}) => Promise<PaginatedResponse<R>>

export type PropsAndFetcher<R> = {
  fetcher: PageFetcher<R>
} & PaginationProps

export const fetchPaginatedEntities = async <R>({
  fetcher,
  total,
  totalMatching,
  page,
  pageSize,
}: PropsAndFetcher<R>): Promise<PaginatedResponse<R>> => {
  if (
    (total === null || totalMatching === null || total === -1 || totalMatching === -1) &&
    page !== 0
  ) {
    const response = await fetcher({
      page: 0,
      pageSize: 1,
    }).catch(error => {
      // ignore abort errors
      if (error.error.name === 'AbortError') {
        return { entities: [], total: 0, totalMatching: 0 }
      }

      throw new Error(`Error while loading entities: ${error.message}`)
    })
    total = response.total ?? 0
    totalMatching = response.totalMatching ?? response.total ?? 0
  }

  const response = await fetcher({
    page,
    pageSize,
  }).catch(error => {
    // ignore abort errors
    if (error.error.name === 'AbortError') {
      return { entities: [], total: 0, totalMatching: 0 }
    }

    throw new Error(`Error while loading entities: ${error.message}`)
  })

  if (page === 0) {
    total = response.total ?? 0
    totalMatching = response.totalMatching ?? 0
  }

  const entities = response.entities ?? []

  return { entities, total, totalMatching }
}
