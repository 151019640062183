import styled from 'styled-components'

import { Box, BoxBody } from 'components/common/box'
import { IconContainer } from 'components/common/svg-icon'
import { FilterButton } from 'components/filter/filter-select/filter-select.styles'
import { texts, colors } from 'components/styled/tokens'
import { textDisabled } from 'components/styled/tokens/colors'

import { SectionInfos } from 'com.batch/account/ui/components/account.styles'

export const VolumeSection = styled(SectionInfos)`
  & > div,
  & > article {
    margin-bottom: 12px;
  }

  ${FilterButton} > span {
    display: inline-block;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const VolumeBox = styled(Box)`
  ${BoxBody} {
    position: relative;
    overflow: hidden;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }

  .tootlip-icon {
    margin-left: 8px;
    color: ${textDisabled};

    &:hover {
      cursor: pointer;
      color: ${colors.textNeutral};
    }
  }
`

export const VolumeTitle = styled.div`
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  gap: 8px;

  > span {
    ${texts.h2};
  }
`
export const VolumeDesc = styled.div`
  color: ${colors.textLight};
`

export const VolumeNumber = styled.div`
  ${texts.metricXl}
`

export const VolumeError = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: rgb(255, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px 0 150px;
  color: ${colors.textDisabled};

  ${IconContainer} {
    margin-right: 8px;
  }
`

export const VolumeFilters = styled.div`
  display: flex;
  gap: 8px;
`
