import styled from 'styled-components'

import { BoxHeader, BoxBody } from 'components/common/box/box.styles'
import { IconContainer } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { ProfilebaseBox } from 'com.batch/profilebase/ui/pages/profilebase.syles'
import { MetricBlockContainer } from 'com.batch/shared/ui/component/metric-block//metric-block.styles'

export const ChannelMetricsBox = styled(ProfilebaseBox)`
  ${BoxHeader} {
    padding-top: 16px;
  }
  ${BoxBody} {
    padding: 0 10px 16px 20px !important;
  }
`

export const ChannelMetricsBlock = styled(MetricBlockContainer)`
  ${BoxBody} {
    display: flex;
    align-items: baseline;
    gap: 20px;
  }

  ${IconContainer} {
    margin-right: 6px;
  }
`

export const ImportedTooltipContainer = styled.div`
  width: 274px;
  font-size: 14px;
  text-align: left;

  & > div {
    color: ${colors.textContrastLight};

    &:first-of-type {
      margin-bottom: 4px;
      font-weight: 500;
      color: ${colors.textContrast};
    }
  }
`
