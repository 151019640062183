import * as React from 'react'
import { useTheme } from 'styled-components'

import { Box, BoxBody, BoxHeader, BoxTitle } from 'components/common/box'
import { LinkDocumentation } from 'components/styled/text'

import { DeliveryReportBlockWrapper } from './delivery-report-block.styles'

import { DeliveryChartMiddleText } from 'com.batch/orchestration-analytics/ui/components/delivery-chart-middle-text'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { type ChartData } from 'com.batch/shared/infra/types/chart-data'
import { DonutChart } from 'com.batch/shared/ui/component/charts/donut-chart/donut-chart'

type DeliveredReportProps = {
  stats: ChartData[]
}

export const DeliveryReportBlock = ({ stats }: DeliveredReportProps): React.ReactElement => {
  const schedulingType = useGetSchedulingTypeFromUrl()

  const [clickedIndex, setClickedIndex] = React.useState<number>(0)

  const { isEmpty, isLoading } = useTheme()

  const middleText = React.useMemo(
    () => <DeliveryChartMiddleText activeIndex={clickedIndex} data={stats} />,
    [clickedIndex, stats]
  )

  return (
    <Box style={{ width: 412, display: 'flex', flexDirection: 'column', marginBottom: 0 }}>
      <BoxHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <BoxTitle>Delivery report</BoxTitle>
          <LinkDocumentation
            target="_blank"
            href="https://help.batch.com/en/articles/8058036-email-analytics-glossary#h_f3c36f90f0"
            intent="action"
            style={{ marginLeft: 'auto' }}
          >
            Learn more
          </LinkDocumentation>
        </div>
      </BoxHeader>

      <DeliveryReportBlockWrapper
        noDataMsg={`No data for this ${schedulingType === 'campaigns' ? 'campaign' : 'automation'}`}
        overlay={!!isEmpty && !isLoading}
      >
        <BoxBody
          style={{
            display: 'grid',
            placeItems: 'center',
            padding: '30px 0 20px 0',
            flexGrow: 2,
          }}
        >
          <DonutChart
            data={stats}
            clickedIndex={clickedIndex}
            setClickedIndex={setClickedIndex}
            middleText={clickedIndex !== null && !isLoading && middleText}
            width={295}
            height={290}
          />
        </BoxBody>
      </DeliveryReportBlockWrapper>
    </Box>
  )
}
