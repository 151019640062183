import * as React from 'react'
import { ThemeContext } from 'styled-components'

import { FlexLineItem } from 'components/common/flexline'
import { schemes } from 'components/styled/tokens'

type AnalyticsMetricProps = {
  emptyTemplate: React.ReactNode
  children: React.ReactNode
}

export const AnalyticsMetric = ({
  emptyTemplate,
  children,
}: AnalyticsMetricProps): React.ReactNode => {
  const themeContext = React.useContext(ThemeContext)
  const columnList: Array<React.ReactNode> = []
  for (let i = 0; i < 4; i++) columnList.push(emptyTemplate)

  return themeContext.isEmpty || themeContext.isLoading
    ? columnList.map((element, index) => (
        <FlexLineItem
          grow={1}
          key={index}
          style={{
            borderLeft: index !== 0 ? `1px solid ${schemes.darklucent['05']}` : 'none',
          }}
        >
          {element}
        </FlexLineItem>
      ))
    : children
}
