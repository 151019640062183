import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'
import styled from 'styled-components'

import { Highlight } from 'components/common/highlight'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { type State, AttributeFactory } from 'com.batch.redux/_records'

type EventFormatterProps = {
  eventCode: string | null | undefined
  asText?: boolean
  eventLabel?: string | null | undefined
  isTooltipDisabled?: boolean
  eventQuery?: any | null | undefined
}

const FilterHint = (
  <span className="EventFormatter--Filters">
    <Icon icon="filter" />
  </span>
)

export const EventFormatterContainer = styled.span`
  display: inline-flex;
  align-items: baseline;
  color: currentColor;

  .EventFormatter--Filters {
    padding: 0 3px;
    margin-left: 2px;
    font-size: 13px;
    color: currentColor;
    cursor: pointer;
  }
  .EventFormatter--Filter i {
    color: currentColor;
  }
`

export const EventFormatter = ({
  eventCode,
  eventLabel,
  eventQuery,
  asText,
  isTooltipDisabled,
}: EventFormatterProps) => {
  const attributes = useSelector(allAttributesSelector)
  if (eventCode === null || eventCode === '' || typeof eventCode === 'undefined') {
    return ''
  } else {
    const event = attributes.get(
      eventCode,
      AttributeFactory({
        label:
          eventCode === 'be.install'
            ? 'INSTALLATION'
            : eventCode === 'be.install_optin'
              ? 'INSTALLED THEN OPTED-IN'
              : eventCode === 'be.first_subscription_optin'
                ? 'SUBSCRIPTION'
                : eventCode.toLowerCase() === 'now'
                  ? 'AS SOON AS POSSIBLE'
                  : eventCode.toLowerCase() === 'next_session'
                    ? 'NEW SESSION'
                    : eventCode.substring(eventCode.indexOf('.') + 1),
      })
    )
    if (asText) return `${event.label}${eventLabel ? ` (${eventLabel})` : ''}`
    return (
      <EventFormatterContainer>
        {event.label}
        {eventLabel ? (
          <React.Fragment>({eventLabel})</React.Fragment>
        ) : eventQuery ? (
          isTooltipDisabled ? (
            FilterHint
          ) : (
            <Tooltip
              offset={[0, 4]}
              tooltip={
                <Highlight
                  language="js"
                  backgroundColor="none"
                  style={{ minWidth: 300, textAlign: 'left', margin: '-4px -6px -7px -8px' }}
                >
                  {JSON.stringify(eventQuery, null, 2)}
                </Highlight>
              }
            >
              {FilterHint}
            </Tooltip>
          )
        ) : (
          ''
        )}
      </EventFormatterContainer>
    )
  }
}

const allAttributesSelector = (state: State) => state.attribute.entities
