import * as React from 'react'

import {
  FilterContainer,
  FilterBox,
  FilterBoxTitle,
} from 'components/campaign/campaign-list.styles'
import { FilterContent } from 'components/campaign/filters/filter-content'
import { type OverlayProps } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'

type FilterProps = {
  children: React.ReactNode
  clearButton?: React.ReactNode
  title: React.ReactNode
  spanCol?: boolean
  needUpgrade?: boolean
  companyId?: number
  emptyState?: OverlayProps
  isEmbedded?: boolean
}

export const Filter = ({
  clearButton,
  title,
  children,
  spanCol,
  needUpgrade,
  companyId,
  emptyState,
  isEmbedded,
}: FilterProps): React.ReactElement | null => {
  if (isEmbedded) {
    return (
      <FilterContainer spanCol={spanCol}>
        <FilterBox>
          <FilterBoxTitle>
            <Grid
              template={clearButton ? '1fr auto' : '1fr'}
              style={{ height: 28, marginBottom: -8 }}
            >
              {title}
              {clearButton}
            </Grid>
          </FilterBoxTitle>
          <FilterContent needUpgrade={needUpgrade} companyId={companyId} emptyState={emptyState}>
            {children}
          </FilterContent>
        </FilterBox>
      </FilterContainer>
    )
  }
  if (!isEmbedded && needUpgrade) {
    return null
  }
  return (
    <FilterContent needUpgrade={needUpgrade} companyId={companyId} emptyState={emptyState}>
      {children}
    </FilterContent>
  )
}
