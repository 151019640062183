import Immutable, { type List, type Map } from 'immutable'
import { createSelector } from 'reselect'

import {
  type ProfileAudienceStateRecord,
  type AudienceListSortTable,
} from './audience-profile.state'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import {
  ProfileAudienceFactory,
  type ProfileAudienceRecord,
} from 'com.batch/audience/model/audience-profile.records'
import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'

type Extract<T> = (arg1: State) => T
export const profileAudienceStateSelector: Extract<ProfileAudienceStateRecord> = state =>
  state.audienceProfile

export const profileAudienceMetaSelector: Extract<{
  page: number
  loadingState: fetchingState
  sortBy: AudienceListSortTable
  count: number
  search: string
  nbPerPage: number
  sortDirection: 'asc' | 'dsc'
  estimatesForAudiences: Map<string, EstimateProfileRecord>
}> = createSelector(profileAudienceStateSelector, aud => ({
  page: aud.page,
  search: aud.search,
  loadingState: aud.loadingState,
  sortBy: aud.sortBy,
  nbPerPage: aud.nbPerPage,
  count: aud.count,
  sortDirection: aud.sortDirection,
  estimatesForAudiences: aud.estimatesForAudiences,
}))

export const pagedPartialAudiencessSelector: Extract<List<ProfileAudienceRecord>> = createSelector(
  profileAudienceStateSelector,
  aud =>
    aud.idsPerPage
      .get(aud.page, Immutable.List())
      .map(id => aud.entities.get(id, ProfileAudienceFactory()))
)

export const matchingEmailsSelector: Extract<Map<string, EstimateProfileRecord>> = createSelector(
  profileAudienceStateSelector,
  aud => aud.estimatesForAudiences
)
export const audiencesMapSelector: Extract<Map<string, ProfileAudienceRecord>> = createSelector(
  profileAudienceStateSelector,
  aud => aud.entities
)
export const allProfileAudiencesSelector: (arg1: State) => List<ProfileAudienceRecord> =
  createSelector(profileAudienceStateSelector, aud => {
    return Immutable.List<ProfileAudienceRecord>().push(...(aud.entities.valueSeq() ?? []))
  })
