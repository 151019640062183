import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { type SenderIdentityRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type UpdateSenderIdentityAction = {
  type: 'UPDATE_SENDER_IDENTITY'
}
export type UpdateSenderIdentityActionSuccessAction = {
  type: 'UPDATE_SENDER_IDENTITY_SUCCESS'
  payload: SenderIdentityRecord
}
type UpdateSenderIdentityActionFailureAction = {
  type: 'UPDATE_SENDER_IDENTITY_FAILURE'
  payload: {
    error: string
  }
}

export type UpdateSenderIdentityActions =
  | UpdateSenderIdentityAction
  | UpdateSenderIdentityActionSuccessAction
  | UpdateSenderIdentityActionFailureAction

export const updateSenderIdentity = (
  sender: SenderIdentityRecord
): DispatchExtraBoundFn<Promise<SenderIdentityRecord>> => {
  return (dispatch, getState, { senderIdentityGateway }) => {
    const project = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: senderIdentityGateway.updateSenderIdentity(project.projectKey, sender),
      actionName: 'UPDATE_SENDER_IDENTITY',
    })
  }
}
