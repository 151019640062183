import { type Map } from 'immutable'
import * as React from 'react'

import { permissionNameFormatter } from 'components/account/team/utils'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { TableCell, TableCellActions, TableRow } from 'components/common/table/index'
import { Tag } from 'components/common/tag'
import { Tooltip } from 'components/common/tooltip'

import { CompanyPermissionPopin } from './company-permission-popin'

import { type CompanyRecord, CompanyFactory } from 'com.batch.redux/_records'
import {
  type CompanyUserPermissionsRecord,
  type UserRecord,
  CompanyUserPermissionsFactory,
} from 'com.batch.redux/user.records'

type CompanyPermissionProps = {
  companyId: number
  companies: Map<number, CompanyRecord>
  user: UserRecord
  updateUser: (user: UserRecord) => void
}

export const CompanyPermission = ({
  companies,
  user,
  companyId,
  updateUser,
}: CompanyPermissionProps): React.ReactElement => {
  const company = companies.get(companyId, CompanyFactory({ name: 'loading...' }))
  const [opened, setOpened] = React.useState<boolean>(false)
  const permissions: CompanyUserPermissionsRecord =
    user.companiesPermissions.get(companyId) || CompanyUserPermissionsFactory()
  return (
    <React.Fragment>
      <CompanyPermissionPopin
        company={company}
        permissions={permissions}
        updatePermissions={(company: CompanyRecord, permissions: CompanyUserPermissionsRecord) => {
          updateUser(
            user.set('companiesPermissions', user.companiesPermissions.set(company.id, permissions))
          )
        }}
        user={user}
        opened={opened}
        close={() => setOpened(false)}
      />
      <TableRow
        style={{
          borderBottom: '1px solid #e9e9e9',
        }}
      >
        <TableCell>
          <Tooltip tooltip={`Seats: ${company.usedSeats} / ${company.seats} `}>
            <a href={`/console/company/${company.id}`} title={company.name}>
              <span style={{ maxWidth: '120px', overflowY: 'hidden', textOverflow: 'ellipsis' }}>
                {company.name}
              </span>{' '}
            </a>
          </Tooltip>
        </TableCell>
        <TableCell
          style={{
            color: '#777e88',
            textTransform: 'capitalize',
            width: 60,
            padding: 0,
          }}
        >
          {company.plan}
        </TableCell>
        <TableCell>
          {permissions.permissions.map(p => (
            <Tag type="label" key={p}>
              {permissionNameFormatter(p)}
            </Tag>
          ))}
        </TableCell>
        <TableCellActions>
          <Button type="button" onClick={() => setOpened(true)} kind="inline" intent="neutral">
            <Icon icon="edit" />
          </Button>
          <Button
            type="button"
            kind="discreet"
            intent="neutral"
            onClick={() =>
              updateUser(
                user.set('companiesPermissions', user.companiesPermissions.remove(companyId))
              )
            }
          >
            <Icon icon="delete" />
          </Button>
        </TableCellActions>
      </TableRow>
    </React.Fragment>
  )
}
