import { type ServiceAccountKeyRaw } from 'com.batch/settings/infra/types/fcm-config-raw'
import {
  FCMConfigFactory,
  ServiceAccountKeyFactory,
  type FCMConfigRecord,
} from 'com.batch/settings/models/fcm-config.records'

export const parseFcmConfig = (raw: ServiceAccountKeyRaw): FCMConfigRecord => {
  return FCMConfigFactory({
    projectId: raw.project_id,
    serviceAccountKey: ServiceAccountKeyFactory({
      type: 'service_account',
      projectId: raw.project_id,
      privateKeyId: raw.private_key_id,
      privateKey: raw.private_key,
      clientEmail: raw.client_email,
      clientId: raw.client_id,
      authUri: raw.auth_uri,
      tokenUri: raw.token_uri,
      authProviderX509CertUrl: raw.auth_provider_x509_cert_url,
      clientX509CertUrl: raw.client_x509_cert_url,
      universeDomain: raw.universe_domain,
    }),
  })
}
