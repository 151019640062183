import * as React from 'react'

import { Item, CTAReviewContainer, FadedLabel, CTAReview } from './rsr.styles'

import { type InAppContentRecord } from 'com.batch.redux/content.records'

type ActionReviewProps = {
  elem: 'mainButton' | 'secondaryButton' | 'globalTap'
  inappContent: InAppContentRecord
}
export const InappActionReview = ({
  elem,
  inappContent,
}: ActionReviewProps): React.ReactElement | null => {
  const naming = {
    mainButton: 'CTA 1',
    secondaryButton: 'CTA 2',
    globalTap: 'Global Action',
  }
  const id = `${elem}Action`
  const title = inappContent[`${elem}Label`]
  const action = inappContent[id].action
  const tooltipWording =
    action.value === 'batch.deeplink'
      ? inappContent[id].deeplinkUrl
      : action.value === 'batch.clipboard'
        ? inappContent[id].copyText
        : ''
  const additionals = inappContent[id].additional
  const label = title ? `${title} (${action.label.toLowerCase()})` : action.label
  // const maybeTitle = tooltipWording === '' ? {} : { title: tooltipWording }

  if (!title && elem !== 'globalTap') return null
  return (
    <Item name={naming[elem]} borderTop noFlex>
      {!!title || elem === 'globalTap' ? (
        <CTAReviewContainer>
          <CTAReview tooltip={tooltipWording} label={label} icon={action.icon} />
          {additionals.map((additional, key) => {
            return (
              <CTAReview
                key={key}
                isAdditional
                tooltip={
                  additional.mode === 'EVENT' ? (
                    <p>Track event: {additional.label || additional.name.replace('e.', '')}</p>
                  ) : (
                    <React.Fragment>
                      <p>Collection: {additional.name.replace('t.', '')}</p>
                      <p>
                        Tag{additional.tags.length > 1 ? 's: ' : ': '}
                        {additional.tags.map(
                          (tag, e) => `${tag}${e + 1 < additional.tags.length ? ', ' : ''}`
                        )}
                      </p>
                    </React.Fragment>
                  )
                }
                label={
                  additional.mode === 'EVENT'
                    ? 'Event added'
                    : `Tag${additional.tags.size > 1 ? 's' : ''} ${
                        additional.mode === 'REMOVE_TAG' ? 'detached' : 'attached'
                      }`
                }
                icon={additional.mode === 'EVENT' ? 'action' : 'label'}
              />
            )
          })}
        </CTAReviewContainer>
      ) : (
        <FadedLabel>—</FadedLabel>
      )}
    </Item>
  )
}
