import { List } from 'immutable'

import { computeRate } from './compute'

import {
  type EmailProviderRecord,
  BounceDataFactory,
  DataFactory,
  EmailProviderFactory,
  EmailProviderStatsFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats-by-provider.record'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'

export const parseStatsByProvider = (
  stats?: Array<OrchestrationStatsDynamicDimension> | null
): List<EmailProviderRecord> => {
  const providerIndex = stats ? stats[0].dimensionNames.indexOf('mailbox_provider') : -1
  return List(
    stats && providerIndex !== -1
      ? stats.map(({ dimensionValues, currentPeriod }) => {
          const sent = currentPeriod.sent ?? 0
          const delivered = currentPeriod.delivered ?? 0
          const uniqueClick = currentPeriod.uniqueClick ?? 0
          const bounce = currentPeriod.bounce ?? 0
          const uniqueOpen = currentPeriod.uniqueOpen ?? 0
          const uniqueMachineOpen = currentPeriod.uniqueMpp ?? 0
          const unsubscribe = currentPeriod.unsubscribe ?? 0
          const hardBounce = currentPeriod.hardBounce ?? 0
          const softBounce = currentPeriod.softBounce ?? 0
          const blockBounce = currentPeriod.blockBounce ?? 0

          // computed values
          const bounceRate = computeRate(bounce, sent)
          const clickRate = computeRate(uniqueClick, delivered)
          const unsubscribeRate = computeRate(unsubscribe, delivered)
          const openRate = computeRate(uniqueOpen, delivered)
          const uniqueMachineOpenRate = computeRate(uniqueMachineOpen, delivered)

          return EmailProviderFactory({
            name: dimensionValues[providerIndex],
            stats: EmailProviderStatsFactory({
              delivered: DataFactory({ value: delivered }),
              open: DataFactory({ unique: uniqueOpen, rate: openRate }),
              click: DataFactory({ rate: clickRate }),
              bounce: BounceDataFactory({
                value: bounce,
                rate: bounceRate,
                hardBounce,
                softBounce,
                blockBounce,
              }),
              unsubscribe: DataFactory({ rate: unsubscribeRate }),
              mpp: DataFactory({ unique: uniqueMachineOpen, rate: uniqueMachineOpenRate }),
              sent,
            }),
          })
        })
      : []
  )
}
