/*  basic wrapper around fetch. 
    ---------------------------
    mimics our current superagent API, but :
      - drop the useless .body in response
      - returns a typed promise
*/
type acceptedMethod = 'POST' | 'GET' | 'DELETE' | 'PATCH' | 'UPDATE' | 'PUT'
function send<T>(
  url: string,
  method: acceptedMethod,
  data: any | null = null,
  signal?: AbortSignal
): Promise<T> {
  let params: RequestInit = {
    method: 'GET',
    signal,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  params.method = method
  if (!!data && method !== 'GET') params.body = JSON.stringify(data)
  const start = Date.now()
  return fetch(url, params).then(
    response => {
      if (response.ok) {
        // 200
        return response.json().then(
          (body: T) => {
            return body
          },
          error => {
            throw {
              error,
              status: response.status,
              seconds: Math.floor((Date.now() - start) / 1000),
              parseError: true,
              aborted: error.name === 'AbortError',
            }
          }
        )
      } else
        return response.json().then(
          (error: any) => {
            throw {
              error,
              status: response.status,
              seconds: Math.floor((Date.now() - start) / 1000),
              parseError: false,
              aborted: error.name === 'AbortError',
            }
          },
          error => {
            throw {
              error,
              status: response.status,
              seconds: Math.floor((Date.now() - start) / 1000),
              parseError: true,
              aborted: error.name === 'AbortError',
            }
          }
        )
    },
    error => {
      throw {
        error,
        status: error.status,
        seconds: Math.floor((Date.now() - start) / 1000),
        parseError: false,
        aborted: error.name === 'AbortError',
      }
    }
  )
}

export const request = {
  get: function <T>(url: string, signal?: AbortSignal): Promise<T> {
    return send<T>(url, 'GET', null, signal)
  },
  delete: function <T>(url: string): Promise<T> {
    return send<T>(url, 'DELETE')
  },
  post: function <T>(url: string, payload: any, signal?: AbortSignal): Promise<T> {
    return send<T>(url, 'POST', payload, signal)
  },
  put: function <T>(url: string, payload: any): Promise<T> {
    return send<T>(url, 'PUT', payload)
  },
  update: function <T>(url: string, payload: any): Promise<T> {
    return send<T>(url, 'UPDATE', payload)
  },
}
