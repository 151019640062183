import styled from 'styled-components'

import { schemes } from 'components/styled/tokens'

export const ValidatedInputContainer = styled.div`
  .styled-button-template-editor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 28px;
    color: ${schemes.grayscale['60']};
    background-color: ${schemes.darklucent['10']};
    border-radius: 4px;

    &:hover {
      color: ${schemes.grayscale['70']};
      background-color: ${schemes.darklucent['20']};
    }

    &:focus {
      color: ${schemes.grayscale['80']};
      background-color: ${schemes.darklucent['30']};
    }

    & > i {
      font-size: 12px;
    }
  }
`
