import * as React from 'react'
import { Routes, Route } from 'react-router-dom'

import { SegmentsList } from '../components/segment-list'
import { SegmentPopin } from '../components/segment-popin'

export const SegmentRouter = (): React.ReactElement => {
  return (
    <Routes>
      <Route element={<SegmentsList />}>
        <Route path="/" element={<div />} />
        <Route path=":segmentName" element={<SegmentPopin />} />
      </Route>
    </Routes>
  )
}
