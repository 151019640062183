import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { BoxBody, BoxFooter } from 'components/common/box'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import Loader from 'components/common/loader-legacy'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { formatPrice } from 'com.batch.common/utils'

import { CartLine, CartLinePrice } from './plan.styles'
import { Steps } from './steps'

import { type CompanyRecord } from 'com.batch.redux/_records'
import {
  getCartSummary,
  createSub,
  updateSub,
  setNca,
  plans,
  type StripeCart,
} from 'com.batch.redux/billing.api'
import { PriceFactory, type PlanRecord } from 'com.batch.redux/billing.records'
import { showToast } from 'com.batch.redux/toaster'

type currencyKind = 'eur' | 'usd'
type cycleKind = 'monthly' | 'yearly'

type CheckoutProps = {
  company: CompanyRecord
  plan: PlanRecord
  cycle: cycleKind
  currency: currencyKind
  prev: () => void
}

const waitForWebhook = () => {
  window.setTimeout(() => {
    window.location.reload()
  }, 3000)
}

export const Checkout = ({
  prev,
  company,
  plan,
  cycle,
  currency,
}: CheckoutProps): React.ReactNode => {
  const billing = company.billing
  const dispatch = useDispatch()

  // check if it's a downgrade
  const currentPlanCode = billing.plan.code
  const nextPlanCode = plan.code
  let isDowngrade = false
  let found = false
  plans.forEach(p => {
    if (p.code === nextPlanCode && !found) {
      isDowngrade = true
    }
    if (p.code === currentPlanCode) {
      found = true
    }
  })
  const subState = company.billing.subscriptionStatus
  const upgradeCartTrilaing =
    subState === 'trialing' && company.billing.plan.code === 'pro2' && plan.code === 'startup'

  const currencyPrices = (currency === 'eur' ? plan.eur : plan.usd) || PriceFactory()
  const nextPrice = cycle === 'monthly' ? currencyPrices.monthly : currencyPrices.yearly
  const nextPlanId =
    cycle === 'monthly' ? currencyPrices.monthlyStripePlanId : currencyPrices.yearlyStripePlanId
  const [loading, setLoading] = React.useState(false)
  const [cart, setCart] = React.useState<StripeCart | null | undefined>(null)
  React.useEffect(() => {
    if (!isDowngrade) {
      setLoading(true)
      getCartSummary(company, nextPlanId).then(c => {
        const isUpgradeCart =
          upgradeCartTrilaing ||
          c.lines.reduce(
            (acc, current) => acc || current.label.toLowerCase().indexOf('unused') !== -1,
            false
          )
        if (isUpgradeCart) {
          const fixedLines = c.lines.filter(
            (line, index) => index < 2 && line.label.toLowerCase().indexOf('vat') === -1
          )
          const fixedLinesWithVat = billing.vatRate
            ? [
                ...fixedLines,
                {
                  label: `VAT (${billing.vatRate}%)`,
                  price:
                    (fixedLines.reduce((acc, current) => acc + current.price, 0) *
                      billing.vatRate) /
                    100,
                },
              ]
            : fixedLines

          setCart({
            ...c,
            lines: fixedLinesWithVat,
            total: fixedLinesWithVat.reduce((acc, current) => acc + current.price, 0),
          })
        } else {
          setCart(c)
        }
        setLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, cycle, currency, isDowngrade])
  const now = dayjs.utc()
  const updatePlan = React.useCallback(() => {
    dispatch(showToast({ kind: 'success', message: 'Plan updated' }))
    waitForWebhook()
  }, [dispatch])
  const onPurchase = React.useCallback(() => {
    setLoading(true)
    if (isDowngrade) {
      setNca(company, nextPlanId).then(updatePlan)
    } else {
      if (billing.subscriptionStatus === 'active' || billing.subscriptionStatus === 'trialing') {
        updateSub(company, nextPlanId).then(updatePlan)
      } else {
        createSub(company, nextPlanId).then(updatePlan)
      }
    }
  }, [billing.subscriptionStatus, company, isDowngrade, nextPlanId, updatePlan])
  const willBillNow = cart && now.isSame(cart.starts, 'hour')

  return (
    <Loader overlay loading={loading}>
      <BoxBody>
        {isDowngrade ? (
          <React.Fragment>
            <CartLine>
              <label>
                You will keep your current <strong>{billing.plan.name}</strong> plan until{' '}
                {!!billing.nextInvoice && billing.nextInvoice.format('DD/MM/YYYY')}; then it will
                downgrade to <strong>{plan.name}</strong>.
              </label>
            </CartLine>
            <CartLine total>
              <label>
                Total (Billed now)
                <p>
                  Then {formatPrice(nextPrice, currency, true)}{' '}
                  {billing.vatRate &&
                    `(+ VAT ${formatPrice((nextPrice * billing.vatRate) / 100, currency, true)}) `}
                  each {cycle === 'monthly' ? 'month' : 'year'} starting on{' '}
                  {!!billing.nextInvoice && billing.nextInvoice.format('DD/MM/YYYY')}
                </p>
              </label>
              <CartLinePrice>{formatPrice(0, currency)}</CartLinePrice>
            </CartLine>
          </React.Fragment>
        ) : cart ? (
          <React.Fragment>
            {cart.lines.map((line, i) => (
              <CartLine key={i}>
                <label>{line.label}</label>
                <CartLinePrice>{formatPrice(line.price, currency)}</CartLinePrice>
              </CartLine>
            ))}
            <CartLine total>
              <label>
                Total {willBillNow && '(Billed now)'}
                <p>
                  Then {formatPrice(nextPrice, currency, true)}{' '}
                  {billing.vatRate
                    ? `(+ VAT ${formatPrice((nextPrice * billing.vatRate) / 100, currency, true)}) `
                    : ''}
                  each
                  {cycle === 'monthly' ? ' month' : ' year'} starting on{' '}
                  {willBillNow
                    ? cart.starts
                        .add(1, cycle === 'monthly' ? 'month' : 'year')
                        .format('DD/MM/YYYY')
                    : cart.starts.format('DD/MM/YYYY')}{' '}
                </p>
              </label>
              <CartLinePrice>
                {willBillNow ? formatPrice(cart.total, currency) : formatPrice(0, currency)}
              </CartLinePrice>
            </CartLine>
          </React.Fragment>
        ) : null}
      </BoxBody>
      <BoxFooter>
        <Grid template="8fr 6fr 1fr">
          <Button kind="secondary" intent="neutral" onClick={prev}>
            Previous
          </Button>
          <Steps active={3} />
          <Button kind="primary" intent="action" onClick={onPurchase}>
            Purchase
          </Button>
        </Grid>
      </BoxFooter>
    </Loader>
  )
}
