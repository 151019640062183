/* eslint-disable react/jsx-no-bind */
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useClickOutside, useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { Select } from 'components/form'
import { Device } from 'components/tester/device'

import { AdvertiserId } from 'com.batch.common/utils'

import { type Variant, TestDeviceFactory, type TestDeviceRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { devicesSelector, sendTestForCampaign, sendTestForTheme } from 'com.batch.redux/testDevice'

type TesterProps = {
  isThemeTester: boolean
  btnText: string
  valid: boolean
  isEmbeddedInToolbar?: boolean
  variant?: Variant
}

let firstRenderWithDevices = true

export const Tester = ({
  isThemeTester,
  btnText,
  valid,
  isEmbeddedInToolbar = false,
  variant,
}: TesterProps): React.ReactElement => {
  // ---- redux
  const app = useSelector(currentAppSelector)
  const loading = useSelector(state => state.campaign.loadingTest)
  const devices = useSelector(devicesSelector)
  const dispatch = useDispatch()
  const openedState = useToggle()
  const [preventClose, setPreventClose] = React.useState(false)
  const [tab, setTab] = React.useState('existing')
  const [existingDevice, setExistingDevice] = React.useState<TestDeviceRecord | null | undefined>(
    null
  )
  const [newDevice, setNewDevice] = React.useState<TestDeviceRecord>(TestDeviceFactory())

  React.useEffect(() => {
    if (!existingDevice && devices.size > 0 && firstRenderWithDevices) {
      firstRenderWithDevices = false
      if (window.localStorage) {
        const deviceId = window.localStorage.getItem(`device_${app.id}`) || null
        if (deviceId) {
          const existingDevice: TestDeviceRecord | undefined = devices.findLast(
            d => d.id === parseInt(deviceId)
          )
          if (existingDevice) setExistingDevice(existingDevice)
        }
      }
    }
  }, [app.id, devices, existingDevice])

  const sendTest = React.useCallback(() => {
    if (isThemeTester) {
      dispatch(sendTestForTheme(existingDevice ? existingDevice : newDevice))
    } else {
      dispatch(
        sendTestForCampaign({ device: existingDevice ? existingDevice : newDevice, variant })
      )
    }
  }, [isThemeTester, dispatch, existingDevice, newDevice, variant])

  const onSelectExisting = React.useCallback(
    (existingDevice?: TestDeviceRecord | null) => {
      if (window.localStorage && !!existingDevice) {
        window.localStorage.setItem(`device_${app.id}`, existingDevice.id.toString())
      }
      setExistingDevice(existingDevice)
      setNewDevice(TestDeviceFactory())
    },
    [app.id]
  )

  const renderDeviceOption = React.useCallback(
    (device, { context }) =>
      context === 'value' ? device.name : <Device device={device} platform={app.platform} />,
    [app.platform]
  )

  const setDevice = React.useCallback((evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewDevice(d => d.set('value', evt.target.value ? evt.target.value.trim() : ''))
    setExistingDevice(null)
  }, [])

  const allDeviceKindsForPlatformList = React.useMemo(
    () =>
      Immutable.List<{
        label: string
        value: 'custom_id' | 'advertising_id' | 'installation_id' | 'token'
      }>([
        { label: 'Custom User ID', value: 'custom_id' },
        { label: 'Installation ID', value: 'installation_id' },
        { label: AdvertiserId[app.platform], value: 'advertising_id' },
        { label: 'Push token', value: 'token' },
      ]).filter(k => k.value !== 'advertising_id' || app.platform !== 'webpush'),
    [app.platform]
  )

  const canSendTest = valid && (existingDevice || newDevice.value.length > 2)
  const closeUnlessMenuIsOpened = React.useCallback(() => {
    if (!preventClose) {
      openedState.close()
    }
  }, [openedState, preventClose])
  const [ref] = useClickOutside(closeUnlessMenuIsOpened)
  return (
    <div className="settings" ref={ref}>
      <div style={{ display: 'flex' }}>
        <Button
          style={{
            ...(isEmbeddedInToolbar && { marginRight: 1 }),
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
          }}
          kind={isEmbeddedInToolbar ? 'secondary' : 'primary'}
          onClick={sendTest}
          disabled={!canSendTest || loading}
        >
          {btnText}
        </Button>
        <Button
          kind={isEmbeddedInToolbar ? 'secondary' : 'primary'}
          style={{
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
          }}
          onClick={openedState.open}
        >
          <Icon icon={loading ? 'running-animated' : 'settings'} size={10} />
        </Button>
      </div>
      {openedState.value && (
        <div
          className="settings__popin"
          style={{
            width: '400px',
            zIndex: isThemeTester ? 1 : 99,
          }}
        >
          <div className="settings__popin__content">
            <TableToggle>
              <TableToggleItem onClick={() => setTab('new')} active={tab === 'new'}>
                Temporary device
              </TableToggleItem>
              <TableToggleItem onClick={() => setTab('existing')} active={tab === 'existing'}>
                Saved devices
              </TableToggleItem>
            </TableToggle>
            {tab === 'existing' && (
              <div style={{ paddingTop: '5px' }}>
                <Select
                  optionToString={opt => opt?.name ?? ''}
                  options={Immutable.List(devices)}
                  onFocus={() => setPreventClose(true)}
                  onBlur={() => setPreventClose(false)}
                  isSearchable
                  optionMenuStyle={{ height: 60 }}
                  optionMenuHeight={60}
                  placeholder="Pick an existing device"
                  optionFormatter={renderDeviceOption}
                  value={existingDevice}
                  onChange={onSelectExisting}
                />
              </div>
            )}
            {tab === 'new' && (
              <div>
                <div style={{ paddingTop: '5px' }}>
                  <Select
                    optionToString={opt => opt?.label ?? ''}
                    onFocus={() => setPreventClose(true)}
                    onBlur={() => setPreventClose(false)}
                    value={allDeviceKindsForPlatformList.find(opt => opt.value === newDevice.kind)}
                    options={allDeviceKindsForPlatformList}
                    onChange={opt => {
                      setNewDevice(d => d.set('kind', opt?.value ?? 'custom_id'))
                    }}
                  />
                </div>
                <textarea
                  rows={3}
                  className="form-control fs-exclude"
                  value={newDevice.value}
                  onChange={setDevice}
                />
                {app.platform === 'ios' && newDevice.kind === 'token' && (
                  <TableToggle>
                    <TableToggleItem
                      onClick={() => setNewDevice(d => d.set('distribution', true))}
                      active={newDevice.distribution}
                    >
                      Production
                    </TableToggleItem>
                    <TableToggleItem
                      onClick={() => setNewDevice(d => d.set('distribution', false))}
                      active={!newDevice.distribution}
                    >
                      Sandbox
                    </TableToggleItem>
                  </TableToggle>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
