import { type List } from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { getTransmissionType } from 'components/app/push/sender-identity/get-transmission-type'
import { Label } from 'components/common/label/label.styles'

import { SenderIdentityListContainer, SenderIdentityListItem } from './sender-identity.styles'

import { type SenderIdentityRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'
import {
  creatingSenderIdentitySelector,
  selectedSenderIdentitySelector,
} from 'com.batch.redux/corelogic/selectors/sender-identity.selector'
import { countBySenderIdentity } from 'com.batch.redux/corelogic/usecases/sender-identity/count-orchestrations-using-sender-identity'
import { cancelCreatingAndSetNew } from 'com.batch.redux/corelogic/usecases/sender-identity/select-sender-identities'

type SenderIdentityListProps = {
  items: List<SenderIdentityRecord>
}

export const SenderIdentityList: React.ComponentType<SenderIdentityListProps> = React.memo(
  ({ items }: SenderIdentityListProps) => {
    const dispatch = useDispatch()
    const creating = useSelector(creatingSenderIdentitySelector)
    const selected = useSelector(selectedSenderIdentitySelector)

    const select = React.useCallback(
      (senderIdentity: SenderIdentityRecord) => () => {
        dispatch(cancelCreatingAndSetNew(senderIdentity))
        dispatch(countBySenderIdentity(senderIdentity.id)).catch(e => console.error(e))
      },
      [dispatch]
    )

    return (
      <SenderIdentityListContainer>
        {creating && (
          <SenderIdentityListItem isActive>{selected.sendingName || 'New'}</SenderIdentityListItem>
        )}
        {items.map(senderIdentity => {
          return (
            <SenderIdentityListItem
              key={senderIdentity.id}
              isActive={senderIdentity.id === selected.id}
              onClick={select(senderIdentity)}
              disabled={creating}
            >
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {senderIdentity.sendingName}
              </span>

              <Label variant="neutral" style={{ height: 20, marginLeft: 8, padding: '0 4px' }}>
                {getTransmissionType(senderIdentity.transmissionTypes)}
              </Label>
            </SenderIdentityListItem>
          )
        })}
      </SenderIdentityListContainer>
    )
  }
)
