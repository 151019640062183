import * as React from 'react'
import { useDropzone } from 'react-dropzone'

import { Button } from 'components/common/button'
import { Ellipsis } from 'components/styled/text'

import { formatBytes } from 'com.batch.common/utils'

import FileIcon from './file-icon'
import { DragAudiences, UploadContainer } from './uploadFiles.styles'

import { Icon } from '../svg-icon'

type UploadFilesProps = {
  progress: number | null
  parseFile: (arg1: File) => any
  rejectFile?: (arg1: File) => any
  removeFile: () => any
  dragDropMessage?: React.ReactNode
  extensionsForMimeType: {
    [key: string]: Array<string>
  }
  maxSizeFiles: number
  preview: React.ReactNode
  previewPosition?: 'in' | 'out'
  fileSize?: number
  isSaving?: boolean
  noFileErrorMsg?: React.ReactNode
}

export const UploadFiles = ({
  progress,
  dragDropMessage,
  extensionsForMimeType,
  maxSizeFiles,
  parseFile,
  removeFile,
  isSaving,
  preview,
  previewPosition,
  noFileErrorMsg,
}: UploadFilesProps): React.ReactElement => {
  const [file, setFile] = React.useState<File | null | undefined>(null)
  const [fileExt, setFileExt] = React.useState<string>('')
  const [error, setError] = React.useState<string | null>(null)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: files => {
      setFileExt(files[0].name.split('.').reverse()[0])
      setFile(files[0])
      parseFile(files[0])
    },
    onDropRejected: files => {
      const errType = files[0].errors[0].code

      setFileExt(files[0].file.name.split('.').reverse()[0])
      setFile(files[0].file)

      if (errType === 'file-invalid-type') setError('Invalid file (requires csv format)')
      else if (errType === 'file-too-large')
        setError(`File size must be under ${formatBytes(maxSizeFiles)}`)
    },
    multiple: false,
    accept: extensionsForMimeType,
    // maxSize: 52428800
    maxSize: maxSizeFiles,
  })
  const onRemoveFile = React.useCallback(() => {
    removeFile()
    setFile(null)
    setError(null)
  }, [removeFile])
  if (file) {
    return (
      <UploadContainer isInvalid={!!error} progress={progress || undefined}>
        <div className="styled-upload-file">
          <div className="styled-upload-container">
            {!!progress && !error && (
              <svg
                className="styled-progress-loader-file"
                xmlns="http://www.w3.org/2000/svg"
                width="304"
                height="64"
              >
                <rect x="2" y="2" fill="none" stroke="#000" strokeWidth="2" rx="6" ry="6" />
              </svg>
            )}
            <div className="styled-upload-infos">
              <div>
                <FileIcon extension={fileExt} isInvalid={!!error} />
              </div>

              <div>
                <Ellipsis>{file.name}</Ellipsis>
                <span>{progress !== null && !error ? 'Uploading...' : formatBytes(file.size)}</span>
              </div>
            </div>
            {previewPosition === 'in' && !error && progress === null && (
              <div className="styled-upload-preview">{preview}</div>
            )}
          </div>
        </div>

        {previewPosition === 'out' && !error && (
          <div className="styled-upload-preview">{preview}</div>
        )}

        <div className="styled-upload-file-footer">
          {!!error && <div>{error}</div>}

          {progress === null && (
            <Button
              type="button"
              kind="inline"
              intent="danger"
              addOn="prefix"
              disabled={isSaving}
              onClick={onRemoveFile}
            >
              <Icon icon="close" />
              {error ? 'Change file' : 'Replace file'}
            </Button>
          )}
        </div>
      </UploadContainer>
    )
  } else {
    return (
      <DragAudiences
        {...getRootProps()}
        isDragActive={isDragActive}
        isInvalid={!!noFileErrorMsg && !isDragActive}
      >
        {isDragActive ? (
          <p>Release file to start the upload</p>
        ) : dragDropMessage ? (
          dragDropMessage
        ) : (
          <React.Fragment>
            <p style={{ marginBottom: 10 }}>Drag and drop your file here or</p>
            <Button type="button" kind="inline" intent="action" addOn="suffix">
              Browse your files
              <Icon icon="file" />
            </Button>
          </React.Fragment>
        )}
        {!!noFileErrorMsg && !isDragActive ? (
          <p>{noFileErrorMsg}</p>
        ) : (
          <p>Requires .txt or .csv format with identifiers contained in the first column</p>
        )}

        <input {...getInputProps()} />
      </DragAudiences>
    )
  }
}
