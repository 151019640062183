import styled from 'styled-components'

import * as colorsLegacy from 'components/styled/colors'
import { colors } from 'components/styled/tokens'

// ====================== STYLED COMPONENTS
export const ReviewContainer = styled.div``

export const ReviewContent = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${colorsLegacy.stroke.lighter};

  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
  }
`

export const ReviewTitle = styled.h4<any>`
  margin-bottom: 8px;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  line-height: 13px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${colors.textLight};
`

export const ReviewMessage = styled.div``

export const ReviewHeader = styled.div`
  margin-bottom: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
`

export const ReviewImage = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: ${colors.text};
  cursor: default;

  & > span {
    padding-left: 4px;
    color: ${colors.textLight};
  }
`

export const ReviewSubtitle = styled.div`
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: ${colors.text};
`

export const ReviewValue = styled.div`
  margin-bottom: 4px;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: ${colors.textLight};
`

export const ReviewLinks = styled.div`
  display: flex;
  & > div {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: ${colors.text};
    cursor: default;

    & > span {
      margin-left: 4px;
    }

    &:first-of-type {
      margin-right: 16px;
    }
  }
`
