import Immutable from 'immutable'
import * as React from 'react'
import styled from 'styled-components'

import { Select } from 'components/form'
import { colors } from 'components/styled/tokens'

type ConditionOperatorProps = {
  active: QBOperator | null | undefined
  operators: Array<QBOperator>
  func: QBFunction | null | undefined
  onChange: (operator: QBOperator) => void
}

const getLabelForAge = (apiOp: string) => {
  switch (apiOp) {
    case '$gt':
      return 'occurred more than'
    case '$gte':
      return 'occurred more than (or =)'
    case '$lte':
      return 'occurred less than (or =)'
    case '$lt':
      return 'occurred less than'
    default:
      return 'occurred'
  }
}

const getLabelForDate = (apiOp: string) => {
  switch (apiOp) {
    case '$gt':
      return 'is after'
    case '$gte':
      return 'is after (or =)'
    case '$lte':
      return 'is before (or =)'
    case '$lt':
      return 'is before'
    default:
      return 'is'
  }
}
const getLabelForNextAnniversary = (apiOp: string) => {
  switch (apiOp) {
    case '$gt':
      return 'is in more than'
    case '$gte':
      return 'is in more than (or =)'
    case '$lte':
      return 'is in less than (or =)'
    case '$lt':
      return 'is in less than'
    default:
      return 'is exactly in'
  }
}
const getLabelForPrevAnniversary = (apiOp: string) => {
  switch (apiOp) {
    case '$gt':
      return 'was more than'
    case '$gte':
      return 'was more than (or =)'
    case '$lte':
      return 'was less than (or =)'
    case '$lt':
      return 'was less than'
    default:
      return 'was exactly'
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const OperatorSympbol = styled.div`
  padding: 2px 2px;
  border-radius: 2px;
  background: ${colors.fillNeutral};
  line-height: 12px;
  text-align: center;
  height: 18px;
  color: #fff;
  font-weight: 500;
  margin-right: 5px;
  flex: 0 0 18px;
`

const gs = (option: QBOperator) => {
  switch (option.value) {
    case 'exists':
      return '?'
    case 'startsWith':
      return '⇤'
    case 'endsWith':
      return '⇥'
    case 'IN':
    case false:
      return '⊙'
    case 'NOT IN':
      return '⃠'
    case 'contains any of':
      return '⊙'
    case 'contains all of':
      return '⊙'
    case true:
    case 'does not contain some of':
      return '⃠'
    case 'does not contain all':
      return '⃠'
    default:
      return option.value
  }
}

export const ConditionOperator = ({
  active,
  operators,
  onChange,
  func,
}: ConditionOperatorProps): React.ReactElement => {
  const getLabel = React.useMemo(() => {
    if (!func) {
      return (op: QBOperator) => op.label
    } else {
      return (op: QBOperator) => {
        switch (func.value) {
          case 'age':
          case 'ageLastEvent':
            return getLabelForAge(op.api)
          case 'date':
            return getLabelForDate(op.api)
          case 'nextBirthday':
            return getLabelForNextAnniversary(op.api)
          case 'lastBirthday':
            return getLabelForPrevAnniversary(op.api)
        }
        return op.label
      }
    }
  }, [func])
  const contextAwareOperators = React.useMemo(
    () =>
      // not sur about this, it works this way so...
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Immutable.List(
        operators.map(op => {
          op.label = getLabel(op)
          return op
        })
      ),
    [getLabel, operators]
  )

  const FormatOperatorOption = React.useMemo(
    () => (option: QBOperator) => {
      const boolOrString = gs(option)
      return (
        <Wrapper>
          <OperatorSympbol>{boolOrString}</OperatorSympbol>
          {typeof boolOrString !== 'boolean' && boolOrString.length < 3 && (
            <div className="opLabel">{getLabel(option)}</div>
          )}
        </Wrapper>
      )
    },
    [getLabel]
  )

  return (
    <div className="condi__builder__operator">
      <Select
        isSearchable={false}
        isClearable={false}
        // eslint-disable-next-line react/jsx-no-bind
        optionToString={op => op?.label ?? ''}
        tooltip={active?.label}
        optionFormatter={FormatOperatorOption}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={opt => {
          if (opt) onChange(opt)
        }}
        value={active}
        options={contextAwareOperators}
      />
    </div>
  )
}

export default ConditionOperator
