import styled from 'styled-components'

import * as colors from 'components/styled/tokens/colors'
import * as shadows from 'components/styled/tokens/shadows'

type props = {
  grow?: boolean
  size: 'normal' | 'small'
  invalid?: boolean
  disabled?: boolean
}

export const SegmentedControlContainer = styled.div<props>`
  display: ${p => (p.grow ? 'flex' : 'inline-flex')};
  align-items: stretch;
  border-radius: 3px;
  min-height: ${p => (p.size === 'small' ? 28 : 36)}px;
  transform-style: preserve-3d;

  button {
    flex-grow: ${props => (props.grow ? 1 : 0)};
    flex-basis: ${props => (props.grow ? 0 : 'auto')};
    min-height: ${props => (props.size === 'small' ? 30 : 38)}px;
    margin-right: -1px;
    padding: 0 ${p => (p.size === 'small' ? 9 : 13)}px;
    font-size: 1em;
    color: ${p => (p.disabled ? colors.textDisabled : colors.textLight)};
    border: none;
    background-color: ${colors.fillBackground};
    box-shadow: inset 0 -1px 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
      inset 0 1px 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
      inset 1px 0 0 ${colors.stroke}, inset -1px 0 0 ${colors.stroke};
    cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};

    &:hover {
      color: ${p => (p.disabled ? colors.textDisabled : colors.text)};
    }
    &:focus {
      outline: none;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
      box-shadow: inset 0 -1px 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
        inset 0 1px 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
        inset 1px 0 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
        inset -1px 0 0 ${colors.stroke};
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
      box-shadow: inset 0 -1px 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
        inset 0 1px 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
        inset 1px 0 0 ${colors.stroke},
        inset -1px 0 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)};
    }

    &.active {
      color: ${p => (p.disabled ? colors.textDisabled : colors.text)};
      background-color: ${p => (p.disabled ? colors.fillDisabled : colors.fill)};
      box-shadow: inset 0 -2px 0 ${p => (p.disabled ? colors.textDisabled : colors.strokeAction)},
        inset 0 1px 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
        inset 1px 0 0 ${colors.stroke}, inset -1px 0 0 ${colors.stroke}, ${shadows.raised};

      &:first-of-type {
        box-shadow: inset 0 -2px 0 ${colors.strokeAction},
          inset 0 1px 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
          inset 1px 0 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
          inset -1px 0 0 ${colors.stroke}, ${shadows.raised};
      }
      &:last-of-type {
        box-shadow: inset 0 -2px 0 ${colors.strokeAction},
          inset 0 1px 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
          inset 1px 0 0 ${colors.stroke},
          inset -1px 0 0 ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
          ${shadows.raised};
      }
    }
  }
`
