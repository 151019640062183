import * as React from 'react'

import { FilterSearch } from 'components/filter'

type SearchInputProps = {
  items: string[]
  onChange: (filteredItems: string[]) => void
}

export const FilteredAttributesProvider = ({
  items,
  onChange,
}: SearchInputProps): React.ReactElement => {
  const [terms, setTerms] = React.useState('')

  React.useEffect(() => {
    onChange(items.filter(item => item.toLowerCase().includes(terms.toLowerCase())))
  }, [terms, items, onChange])

  return (
    <FilterSearch
      style={{ marginLeft: 'auto', width: 'auto' }}
      width={97}
      expandedMaxWidth={220}
      onChange={setTerms}
      placeholder={'Search attributes...'}
      value={terms}
      isSensitive
    />
  )
}
