import styled, { css } from 'styled-components'

import { LoadingSvgAnim } from 'components/common/empty-states/'
import * as colors from 'components/styled/colors'
import { ShapePlaceholder, HiddenPlaceholder } from 'components/styled/placeholder'
import { schemes } from 'components/styled/tokens'

export const LegendTimeLabel = styled(HiddenPlaceholder.withComponent('text'))`
  font-weight: 500;
  fill: #c1c1c1;
  text-anchor: middle;
`

export const LegendTimeLabelSkeleton = styled(LegendTimeLabel.withComponent('rect'))`
  ${props =>
    props.theme.isLoading &&
    css`
      animation-name: ${LoadingSvgAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`
type LegendTimeCurrentProps = {
  height: number
  x: number
}
export const LegendTimeCurrent = styled(
  HiddenPlaceholder.withComponent('g')
)<LegendTimeCurrentProps>`
  width: 140px;
  height: ${props => props.height - 1}px;
  x: ${props => props.x - 70}px;
  transform: translate3d(${props => props.x}px, 0, 0);
  transition: all ease-out 0.2s;

  rect {
    width: 140px;
    height: ${props => props.height - 1}px;
    x: -70px;
  }
  text {
    font-weight: 500;
    fill: #3e5067;
    text-anchor: middle;
  }
`

export const LegendTimeRect = styled.rect`
  fill: #fcfcfd;
  pointer-events: none;
  cursor: pointer;
`

export const LegendTimeContainer = styled.g`
  pointer-events: none;
`

export const LegendTimeSeparator = styled.line`
  stroke: ${props => (props.theme.isLoading ? colors.stroke.lighter : '#fafafb')};
`

export const ChartTtip = styled(HiddenPlaceholder.withComponent('div'))`
  background: ${schemes.grayscale['80']};
  text-align: left;
  width: 140px;
  border-radius: 4px;
  pointer-events: none;
  font-weight: bold;
  position: absolute;
  top: 20px;
  font-size: 11px;
  color: #fff;
  z-index: 3;
  transition: all ease-out 0.2s;
`

export const ChartTtipElement = styled.div`
  margin: 0 10px;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  &:last-child {
    border-bottom: none;
  }
`

type ChartTtipTitleProps = {
  color: string
}
export const ChartTtipTitle = styled.div<ChartTtipTitleProps>`
  display: flex;
  align-items: start;
  span {
    color: #b1b0b0;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 16px;
    line-height: 10px;
    flex-grow: 1;
  }
  svg {
    fill: ${props => props.color};
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
`

export const ChartTtipValue = styled.div`
  display: block;
  line-height: 29px;
  font-weight: 300;
  font-size: 24px;
`

type ChartNoDataProps = {
  height: number
}
export const ChartNoData = styled.div<ChartNoDataProps>`
  height: ${props => props.height}px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
`

export const ChartBar = styled(ShapePlaceholder)``

export const ChartScale = styled(HiddenPlaceholder.withComponent('text'))``

export const ChartScaleSkeleton = styled(ChartScale.withComponent('rect'))`
  ${props =>
    props.theme.isLoading &&
    css`
      animation-name: ${LoadingSvgAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`

export const ChartBarSkeleton = styled(ChartBar)`
  fill: '#c1c1c1';
  ${props =>
    props.theme.isLoading &&
    css`
      animation-name: ${LoadingSvgAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}
`
