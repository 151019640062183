import styled from 'styled-components'

import { Separator } from 'components/project/navigation/navbar.styles'
import { colors } from 'components/styled/tokens'

type ChannelSubNavContainerProps = {
  isTransparent: boolean
  gotBorder: boolean
  isCollapsed: boolean
}
export const ChannelSubNavContainer = styled.div<ChannelSubNavContainerProps>`
  height: ${p => (p.isCollapsed ? 'unset' : '56px')};
  background-color: ${p => (p.isTransparent ? 'transparent' : colors.fill)};
  border-bottom: ${p => (p.gotBorder ? `1px solid ${colors.stroke}` : 'none')};
  box-shadow: ${p => (p.isTransparent ? 'none' : '0px 1px 4px rgba(0, 0, 0, 0.02)')};
  display: flex;
  padding-left: ${p => (p.isCollapsed ? '0px' : '5px')};
  margin: ${p => (p.isCollapsed ? '0px' : '0px 10px 0px 10px')};
  align-items: center;

  a {
    font-size: 14px;
    &.ios:hover svg,
    &.ios.isActive svg {
      fill: #5c5c5c !important;
    }
  }
  a + a {
    margin-left: 8px;
  }
  a:after {
    border-radius: 8px;
  }
  .styled-button-suffix {
    margin-left: 12px;
    margin-right: 4px;
  }

  ${Separator} {
    margin: 0 12px;
    height: 24px;
    background-color: ${colors.stroke};
  }
`
