import * as React from 'react'

type Props = {
  children: React.ReactNode
  append?: React.ReactNode
  prepend?: React.ReactNode
}

// Simple component to prepend / append if the value is there
class Text extends React.PureComponent<Props> {
  render(): React.ReactElement | null {
    const { children, append, prepend } = this.props
    if (typeof children !== 'string' || children === '') {
      return null
    } else {
      return (
        <span>
          {prepend ? prepend : null}
          {children}
          {append ? append : null}
        </span>
      )
    }
  }
}

export default Text
