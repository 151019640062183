import * as React from 'react'
import styled, { css } from 'styled-components'

import { LoadingCellAnim } from 'components/common/empty-states'
import { TableTemplateCell } from 'components/common/table'

const randomSize = (minSize: number, maxSize: number) =>
  Math.floor(Math.random() * (maxSize - minSize)) + minSize
// empty template for metrics items
export const AnalyticsMetricsEmptyTemplate = (): React.ReactElement => {
  return (
    <div style={{ margin: '37px 32px' }}>
      <MetricLabelEmpty size={React.useMemo(() => randomSize(44, 68), [])}>0</MetricLabelEmpty>
      <MetricValueEmpty size={React.useMemo(() => randomSize(58, 78), [])}>0</MetricValueEmpty>
      <MetricIndicatorEmpty size={React.useMemo(() => randomSize(44, 68), [])}>
        –
      </MetricIndicatorEmpty>
    </div>
  )
}

type MetricItemEmptyProps = {
  size?: number
  loading?: boolean
}
const MetricItemEmpty = styled.label<MetricItemEmptyProps>`
  display: flex;
  justify-content: center;
  font-size: 11px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    background-color: #b8b8b8;
    border-radius: 2px;
    min-width: ${props => props.size}px;
    height: 13px;

    ${props =>
      props.theme.isLoading &&
      css`
        animation-name: ${LoadingCellAnim};
        animation-duration: 2s;
        animation-iteration-count: infinite;
      `}
  }
`

const MetricValueEmpty = styled(MetricItemEmpty)<MetricItemEmptyProps>`
  margin-bottom: 20px;

  &::before {
    height: 21px;
  }
`

type MetricLabelEmptyProps = {
  size?: number
}
const MetricLabelEmpty = styled(MetricItemEmpty)<MetricLabelEmptyProps>`
  margin-bottom: 12px;
`

type MetricIndicatorEmptyProps = {
  size?: number
}
const MetricIndicatorEmpty = styled(MetricItemEmpty)<MetricIndicatorEmptyProps>`
  margin-bottom: O;
`

// Empty Template
export const AnalyticsListEmptyTemplate = (): React.ReactElement => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" minSize={80} maxSize={100} />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
}

// Empty Template
export const ReachListEmptyTemplate = (): React.ReactElement => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" minSize={80} maxSize={100} />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
}
