import Immutable, { type Map, type Set, type RecordOf } from 'immutable'

import { type EstimateRecord } from 'com.batch.redux/_records'

import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'

type TargetStateProps = {
  languages: Set<string>
  languagesInverted: boolean
  regions: Set<string>
  regionsInverted: boolean
  segments: Set<string>
  currentEstimateHash: number
  attributesLoaded: boolean
  estimates: Map<number, EstimateRecord>
  profileEstimates: Map<number, EstimateProfileRecord>
  subscriptionStatus: 'marketing' | 'fullbase'
}
export const TargetStateFactory = Immutable.Record<TargetStateProps>({
  languages: Immutable.Set(),
  languagesInverted: false,
  regions: Immutable.Set(),
  regionsInverted: false,
  segments: Immutable.Set(['N', 'E', 'D', 'Du']),
  currentEstimateHash: 0,
  attributesLoaded: false,
  estimates: Immutable.Map(),
  profileEstimates: Immutable.Map(),
  subscriptionStatus: 'marketing',
} as TargetStateProps)

export type TargetStateRecord = RecordOf<TargetStateProps>
