
import Immutable, { type List } from 'immutable'
import { createSelector } from 'reselect'

import { type AppRecord, type State } from 'com.batch.redux/_records'
import { type EspDomainsRecord } from 'com.batch.redux/corelogic/records/esp-configuration.records'
import { ProjectFactory, type ProjectRecord } from 'com.batch.redux/project.records'

type extract<T> = (arg1: State) => T

const projectStateSelector = (state: State) => state.project
// duplicated code to avoid circular dependency
const currentAppSelector: (state: State) => AppRecord = state => {
  return state.app.current
}
export const currentProjectSelector: extract<ProjectRecord> = createSelector(
  projectStateSelector,
  projectState => {
    return projectState.entities.get(projectState?.currentProjectId ?? '', ProjectFactory())
  }
)

export const optionalCurrentProjectSelector: extract<ProjectRecord | null | undefined> =
  createSelector(projectStateSelector, projectState => {
    return projectState.currentProjectId
      ? projectState.entities.get(projectState.currentProjectId)
      : null
  })

export const sendingDomainsSelector: extract<List<EspDomainsRecord>> = createSelector(
  projectStateSelector,
  projectState => {
    const currentProject = projectState.currentProjectId
      ? projectState.entities.get(projectState.currentProjectId)
      : null

    return currentProject?.espConfiguration?.domains ?? Immutable.List()
  }
)

export const projectsSelector: extract<List<ProjectRecord>> = createSelector(
  projectStateSelector,
  projectState => {
    return projectState.entities.toList()
  }
)
export const projectMatchingCurrentAppSelector: extract<ProjectRecord | null | undefined> =
  createSelector(currentAppSelector, projectsSelector, (app, projects) => {
    return projects.find(project => project.appIds.has(app.id))
  })
