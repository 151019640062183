// ====================== LIBS
import { type List } from 'immutable'
import * as React from 'react'

import { TableBody } from 'components/common/table'

import { type LabelRecord } from 'com.batch/labels/models/labels.records'
import { Row } from 'com.batch/labels/ui/components/row'
import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'

type RowProps = {
  labels: List<LabelRecord>
  templateSize: number
  emptyTemplate: React.ReactNode
}

export const Body = ({ labels, templateSize, emptyTemplate }: RowProps): React.ReactElement => {
  return (
    <TableBody templateSize={templateSize} emptyTemplate={emptyTemplate}>
      <NoResultWrapper isEmpty={labels.size === 0} entityName="labels" height={400}>
        {labels.map((label: LabelRecord, index: number) => {
          return <Row key={label.code} label={label} index={index} />
        })}
      </NoResultWrapper>
    </TableBody>
  )
}
