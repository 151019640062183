import * as React from 'react'

import { formatNumberWithCommas, percentage } from 'com.batch.common/utils'

import {
  DeliveryChartLegendTitle,
  DeliveryChartMiddleTextContainer,
} from 'com.batch/orchestration-analytics/ui/components/delivery-chart-middle-text.styles'
import { type ChartData } from 'com.batch/shared/infra/types/chart-data'

type Props = {
  activeIndex: number | null
  data: ChartData[]
}

export const DeliveryChartMiddleText = ({ activeIndex, data }: Props): React.ReactElement => {
  const value = data.find((d, i) => i === activeIndex)?.value ?? 0
  return (
    <DeliveryChartMiddleTextContainer>
      <DeliveryChartLegendTitle data-testid="legend_title">
        {formatNumberWithCommas(value)}
      </DeliveryChartLegendTitle>

      <span style={{ fontWeight: 400 }} data-testid="legend_rate">
        {`${percentage(data.find((d, i) => i === activeIndex)?.rate, 2)} of sents`}
      </span>
    </DeliveryChartMiddleTextContainer>
  )
}
