import { computeEvolution, computeRate } from './compute'

import { type BaseOrchestrationStats } from 'com.batch/shared/infra/types/grpc-stats-service'

type TrendByValue = {
  trend: number | null | undefined
  previousPeriodValue: number | null | undefined
}
type TrendByRate = {
  trend: number | null | undefined
  previousPeriodRate: number | null | undefined
}

export const parseTrends = (
  current: {
    sent: number
    delivered: number
    openRate: number
    clickRate: number
    bounceRate: number
    unsubscribeRate: number
  },
  previousPeriod?: BaseOrchestrationStats | null
): {
  sent: TrendByValue
  delivered: TrendByValue
  open: TrendByRate
  click: TrendByRate
  bounce: TrendByRate
  unsubscribe: TrendByRate
} => {
  if (!previousPeriod)
    return {
      sent: { trend: null, previousPeriodValue: null },
      delivered: { trend: null, previousPeriodValue: null },
      open: { trend: null, previousPeriodRate: null },
      click: { trend: null, previousPeriodRate: null },
      bounce: { trend: null, previousPeriodRate: null },
      unsubscribe: { trend: null, previousPeriodRate: null },
    }

  // Compute rates for previous period
  const prevSent = previousPeriod.sent ?? 0
  const prevDelivered = previousPeriod.delivered ?? 0
  const prevBounce = previousPeriod.bounce ?? 0
  const prevUniqueOpen = previousPeriod.uniqueOpen ?? 0
  const prevUniqueClick = previousPeriod.uniqueClick ?? 0
  const prevUniqueUnsubscribe = previousPeriod.uniqueUnsubscribe ?? 0
  const prevBounceRate = computeRate(prevBounce, prevSent)
  const prevOpenRate = computeRate(prevUniqueOpen, prevDelivered)
  const prevClickRate = computeRate(prevUniqueClick, prevDelivered)
  const prevUnsubscribeRate = computeRate(prevUniqueUnsubscribe, prevDelivered)

  // Compute trends
  const sent = computeEvolution(current.sent, prevSent)
  const delivered = computeEvolution(current.delivered, prevDelivered)
  const bounce = computeEvolution(current.bounceRate, prevBounceRate)
  const open = computeEvolution(current.openRate, prevOpenRate)
  const click = computeEvolution(current.clickRate, prevClickRate)
  const unsubscribe = computeEvolution(current.unsubscribeRate, prevUnsubscribeRate)

  return {
    sent: { trend: sent, previousPeriodValue: prevSent },
    delivered: { trend: delivered, previousPeriodValue: prevDelivered },
    bounce: { trend: bounce, previousPeriodRate: prevBounceRate },
    open: { trend: open, previousPeriodRate: prevOpenRate },
    click: { trend: click, previousPeriodRate: prevClickRate },
    unsubscribe: { trend: unsubscribe, previousPeriodRate: prevUnsubscribeRate },
  }
}
