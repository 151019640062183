import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { type SettingsServiceApiInterface } from 'com.batch/settings/infra/settings-service.api.interface'

export const settingsServiceApi: SettingsServiceApiInterface = {
  checkFcm: async ({ fcm, appId }): Promise<any> => {
    try {
      await request.post<any>(generateUrl('api_app_push_settings_fcm_check', { appId }), fcm)
    } catch (e: any) {
      throw e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
}
