import { type Dayjs } from 'dayjs'
import Immutable, { type List } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

interface MonthList {
  name: string
  date: Dayjs
}

export const getMonthList = (startedDate: Dayjs): List<MonthList> => {
  const optList: Array<MonthList> = []
  let checkDate = dayjs().startOf('month')
  while (checkDate.isSameOrAfter(startedDate.startOf('month'))) {
    optList.push({
      name: checkDate.isSame(dayjs(), 'month') ? 'Current month' : checkDate.format('MMMM YYYY'),
      date: checkDate,
    })
    checkDate = checkDate.startOf('month').subtract(1, 'month')
  }

  return Immutable.List<MonthList>().push(...optList)
}
