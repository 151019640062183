/* eslint-disable react/jsx-no-bind */
import * as React from 'react'

import { useAction } from 'components/_hooks'
import {
  StripeCardContainer,
  CardContainer,
  StripeStyle,
  CardButtonContainer,
  CardButton,
} from 'components/account/plans/plan.styles'
import { BoxBody, BoxFooter } from 'components/common/box'
import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import Loader from 'components/common/loader-legacy'
import { Icon } from 'components/common/svg-icon'
import { Form, InputWrapper, FormActions } from 'components/form'

import { Steps } from './steps'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { getCurrentCard, createToken } from 'com.batch.redux/billing.api'
import { CardFactory, type CardRecord } from 'com.batch.redux/billing.records'
import { persistBilling } from 'com.batch.redux/company'

type CardProps = {
  company: CompanyRecord
  elementId: string
  prev?: () => void
  next?: () => void
}

const expiryRegex = /(\d{2})(\d{2})(\d{2})/
const formatExpiry = (exp: string) => {
  const tmp = expiryRegex.exec(exp)
  if (tmp && tmp !== null && tmp.length !== 4) {
    return exp
  } else {
    return tmp !== null ? tmp[1] + '/' + tmp[3] : exp
  }
}
export const Card = ({ company, elementId, next, prev }: CardProps): React.ReactElement => {
  const [stripeCardElement, setStripeCardElement]: [any | null | undefined, (n?: any) => void] =
    React.useState<any>(null)
  const [saving, setSaving] = React.useState<boolean>(false)
  const [cardError, setCardError] = React.useState<string>('')
  const [forceRefresh, setForceRefresh] = React.useState<number>(1)
  const [newCardForm, setNewCardForm] = React.useState<boolean>(false)
  const [newCardValid, setNewCardValid] = React.useState<boolean>(false)
  const [existingCard, setExistingCard] = React.useState<CardRecord>(CardFactory({ loading: true }))
  const persistBillingBound = useAction(persistBilling)
  // load existing card for company
  React.useEffect(() => {
    getCurrentCard({ company }).then(
      card => {
        setExistingCard(card)
        if (!card.found) {
          setNewCardForm(true)
        }
      },
      () => {
        setExistingCard(existingCard.set('loading', false))
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.id, forceRefresh])

  // init form stripe
  React.useEffect(() => {
    if (newCardForm) {
      const elem = window.stripe
        .elements()
        .create('card', { hidePostalCode: true, style: StripeStyle })
      setStripeCardElement(elem)
      elem.mount('#' + elementId)
      elem.addEventListener('change', event => {
        setNewCardValid(!event.error && event.complete)
      })
      return () => {
        if (stripeCardElement) {
          stripeCardElement.unmount()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCardForm])

  const save = () => {
    setSaving(true)
    setCardError('')
    createToken(stripeCardElement).then(
      paymentMethod => {
        persistBillingBound({ company, billingInfo: null, paymentMethod }).then(
          () => {
            setSaving(false)
            setForceRefresh(forceRefresh + 1)
            if (!!stripeCardElement && typeof stripeCardElement.unmount === 'function') {
              stripeCardElement.unmount()
            }
            setStripeCardElement(null)
            setNewCardForm(false)
            if (typeof next === 'function') {
              next()
            }
          },
          () => {
            setSaving(false)
          }
        )
      },
      error => {
        console.log(error)
        setSaving(false)
        setCardError(error)
      }
    )
  }

  const cancel = () => {
    if (!!stripeCardElement && typeof stripeCardElement.unmount === 'function') {
      stripeCardElement.unmount()
    }
    setStripeCardElement(null)
    setNewCardForm(false)
  }
  return (
    <Form horizontal={!prev}>
      <Loader loading={existingCard.loading || saving} overlay>
        {!prev ? (
          <CardBase
            showLabel={true}
            newCardForm={newCardForm}
            existingCard={existingCard}
            error={cardError}
            elementId={elementId}
          />
        ) : (
          <BoxBody>
            <div style={{ minHeight: 120, padding: '20px' }} className="fs-exclude">
              <CardBase
                showLabel={false}
                newCardForm={newCardForm}
                existingCard={existingCard}
                error={cardError}
                elementId={elementId}
              />
              <CardButtonContainer>
                {newCardForm && existingCard.found && (
                  <CardButton
                    onClick={() => {
                      if (!!stripeCardElement && typeof stripeCardElement.unmount === 'function') {
                        stripeCardElement.unmount()
                      }
                      setStripeCardElement(null)
                      setNewCardForm(false)
                    }}
                  >
                    Cancel &nbsp;
                    <Icon icon="close" />
                  </CardButton>
                )}
                {!newCardForm && (
                  <CardButton onClick={() => setNewCardForm(true)}>
                    Use another credit card &nbsp;
                    <Icon icon="edit" />
                  </CardButton>
                )}
              </CardButtonContainer>
            </div>
          </BoxBody>
        )}
        {!prev ? (
          <FormActions>
            {!!stripeCardElement && (
              <Button kind="primary" disabled={!newCardValid} intent="action" onClick={save}>
                Save
              </Button>
            )}
            {existingCard.found && !newCardForm && (
              <Button kind="primary" intent="action" onClick={() => setNewCardForm(true)}>
                Change credit card
              </Button>
            )}
            {existingCard.found && newCardForm && (
              <Button kind="inline" onClick={cancel} style={{ marginLeft: 10 }}>
                Cancel
              </Button>
            )}
          </FormActions>
        ) : (
          <BoxFooter>
            <FlexLine grow>
              <FlexLineItem width={220}>
                <Button intent="neutral" kind="secondary" onClick={() => prev()}>
                  Previous
                </Button>
              </FlexLineItem>
              <FlexLineItem grow={1}>
                <Steps active={2} />
              </FlexLineItem>
              <FlexLineItem>
                <Button
                  kind="primary"
                  intent="action"
                  type="submit"
                  disabled={!newCardValid && newCardForm}
                  onClick={() => (!newCardForm && typeof next === 'function' ? next() : save())}
                >
                  Continue
                </Button>
              </FlexLineItem>
            </FlexLine>
          </BoxFooter>
        )}
      </Loader>
    </Form>
  )
}

const CardBase = ({
  newCardForm,
  elementId,
  existingCard,
  showLabel,
  error,
}: {
  newCardForm: boolean
  elementId: string
  error: string
  existingCard: CardRecord
  showLabel: boolean
}) => {
  return newCardForm ? (
    <InputWrapper label={showLabel ? 'Card' : null} feedback={!!error && error}>
      <StripeCardContainer id={elementId}></StripeCardContainer>
    </InputWrapper>
  ) : (
    <InputWrapper
      label={showLabel ? 'Card' : null}
      feedback={
        !existingCard.card && !existingCard.loading && 'Unable to access your Stripe information'
      }
    >
      <CardContainer brand={existingCard.brand}>
        <code>**** **** **** {existingCard.card}</code>
        <span>{formatExpiry(existingCard.expiration)}</span>
      </CardContainer>
    </InputWrapper>
  )
}
