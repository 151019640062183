import Immutable from 'immutable'
import * as React from 'react'

import { Button, ButtonLink } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Form, FormActions, InputWrapper, Input, Copy, Checkbox, Select } from 'components/form'
import { schemes, colors } from 'components/styled/tokens'

import { config } from 'com.batch.common/config'
import { dayjs } from 'com.batch.common/dayjs.custom'
import { kformat } from 'com.batch.common/utils'

import { Section, SectionTitle, DataBoxContainer, DataBox } from '../console.style'
import { EntityLog } from '../entity-log'
import { type AppRecord, type CompanyRecord, type openRateAlgEnum } from 'com.batch.redux/_records'
import attributeApi from 'com.batch.redux/attribute.api'
import { estimateRaw } from 'com.batch.redux/stat.api'
import { fetchAnalyticsByDay } from 'com.batch.redux/stat.api.analytics'
import { AnalyticByPeriodFactory, type AnalyticByPeriodRecord } from 'com.batch.redux/stat.records'

type AppDataProps = {
  app: AppRecord
  company: CompanyRecord
  updatePushConfig: (arg1: {
    app: AppRecord
    maxRate: number | null | undefined
    pushImported: boolean
    ttlJourney: number | null | undefined
    ttlRetargeting: number | null | undefined
    openRateAlg: openRateAlgEnum
    safariOpenTracking: boolean
  }) => Promise<AppRecord>
}

type OpenRate = {
  value: openRateAlgEnum
  label: string
}

const optionsOpenRate = Immutable.List<OpenRate>([
  { value: 'LEGACY', label: '(direct + influenced) / sent' },
  { value: 'LEGACY_DIRECT', label: 'direct / sent' },
  { value: 'ACCURATE', label: '(direct + influenced) / sentNotifOn' },
  { value: 'ACCURATE_DIRECT', label: 'direct / sentNotifOn' },
])

const optToString = (e?: OpenRate | null) => e?.label ?? ''

const AppData = ({ app, company, updatePushConfig }: AppDataProps): React.ReactElement => {
  const [openRate, setOpenRate] = React.useState<openRateAlgEnum>(app.openRateAlg)
  const [maxRate, setMaxRate] = React.useState(app.pushConfig.maxRate)
  const [safariOpenTracking, setSafariOpenTracking] = React.useState(
    app.pushConfig.safariOpenTracking
  )
  const [ttlRetargeting, setTtlRetargeting] = React.useState<number | null | undefined>(
    app.ttlRetargeting
  )
  const [ttlJourney, setTtlJourney] = React.useState<number | null | undefined>(app.ttlJourney)
  const [btnLoading, setBtnLoading] = React.useState(false)
  const [imported, setImported] = React.useState(app.pushImported)
  const [analytics, setAnalytics] =
    React.useState<AnalyticByPeriodRecord>(AnalyticByPeriodFactory())
  const [notifOn, setNotifOn] = React.useState(0)
  const [sdkLvls, setSdkLvls] = React.useState<
    Array<{
      nb: number
      value: string
      pcent: string
    }>
  >([])

  React.useEffect(() => {
    attributeApi.fetchAttributesValues({ app, attributesIds: ['lvl'] }).then(data => {
      setSdkLvls(typeof data.lvl !== 'undefined' ? data.lvl : [])
    })
    estimateRaw(app).then(d => setNotifOn(d.results.matching_notif_on))
    fetchAnalyticsByDay({
      app,
      devMode: false,
      dimension: 'none',
      granularity: 'day',
      start: dayjs.utc().subtract(1, 'day').startOf('day'),
      end: dayjs().utc().subtract(1, 'day'),
    }).then(list => setAnalytics(list.get(0, AnalyticByPeriodFactory())))
  }, [app])

  const handleOnSubmit = React.useCallback(() => {
    setBtnLoading(true)
    updatePushConfig({
      app,
      maxRate: maxRate === null || typeof maxRate === 'undefined' ? null : maxRate,
      ttlRetargeting,
      ttlJourney,
      pushImported: imported,
      openRateAlg: openRate,
      safariOpenTracking,
    }).then(
      () => {
        setBtnLoading(false)
      },
      error => {
        console.warn(error)
        setBtnLoading(false)
        alert('We failed. Probably sync related.')
      }
    )
  }, [
    app,
    imported,
    maxRate,
    openRate,
    safariOpenTracking,
    ttlJourney,
    ttlRetargeting,
    updatePushConfig,
  ])

  const handleOnOpenRateChange = React.useCallback(opt => {
    if (opt) setOpenRate(opt.value)
  }, [])

  const handleOnMaxRateChange = React.useCallback(evt => {
    setMaxRate(evt.target.value)
  }, [])

  const handleOnTtlJourneyChange = React.useCallback(evt => {
    setTtlJourney(evt.target.value)
  }, [])

  const handleOnTtlRetargetingChange = React.useCallback(evt => {
    setTtlRetargeting(evt.target.value)
  }, [])

  const handleOnShowImportedSegmentChange = React.useCallback(() => {
    setImported(!imported)
  }, [imported])

  const handleOnSafariOpenTrackingChange = React.useCallback(() => {
    setSafariOpenTracking(!safariOpenTracking)
  }, [safariOpenTracking])

  return (
    <Grid
      template="390px 1fr"
      alignItems="stretch"
      style={{ marginTop: 12, borderTop: `1px solid ${colors.stroke}` }}
    >
      <div>
        <Section>
          <SectionTitle>Push config</SectionTitle>
          <Form onSubmit={handleOnSubmit}>
            <Grid template="240px 1fr">
              <InputWrapper label="Open rate">
                <Select
                  id="openRate"
                  isClearable={false}
                  value={optionsOpenRate.find(e => e.value === openRate)}
                  options={optionsOpenRate}
                  optionToString={optToString}
                  onChange={handleOnOpenRateChange}
                />
              </InputWrapper>
              <div>
                <InputWrapper label="Max Rate" hint="Pushes per minute" htmlFor="max-rate">
                  <Input
                    id="max-rate"
                    type="number"
                    name="maxRate"
                    placeholder={config.pushRate[company.plan ?? 'free']}
                    value={maxRate ? maxRate : ''}
                    onChange={handleOnMaxRateChange}
                  />
                </InputWrapper>
              </div>
            </Grid>
            <InputWrapper>
              <Grid template="1fr 1fr">
                <div>
                  <InputWrapper
                    label="Journey max days"
                    hint="Defines how many days a user can stay in the waiting state of a trigger campaign / journey. Mostly revelant when push timer does not relates to event date but to another attibute, like departure date for SNCF. Has a cost (CO2 & $$$)."
                    htmlFor="max-days"
                  >
                    <Input
                      id="max-days"
                      type="number"
                      name="retargeting"
                      placeholder="64"
                      value={ttlJourney ?? ''}
                      onChange={handleOnTtlJourneyChange}
                    />
                  </InputWrapper>
                </div>
                <div>
                  <InputWrapper
                    label="Keep retargeting for"
                    hint="Defines how many days we keep targeting & open information per user per campaign, for retargeting, purposes. Has a cost (CO2 & $$$)."
                    htmlFor="retargeting"
                  >
                    <Input
                      id="retargeting"
                      type="number"
                      name="retargeting"
                      placeholder="14"
                      value={ttlRetargeting ?? ''}
                      onChange={handleOnTtlRetargetingChange}
                    />
                  </InputWrapper>
                </div>
              </Grid>
            </InputWrapper>
            <InputWrapper>
              <Checkbox
                checked={imported}
                onChange={handleOnShowImportedSegmentChange}
                label={
                  <React.Fragment>
                    Show &laquo; <strong>Imported</strong> &raquo; segment
                  </React.Fragment>
                }
              />
            </InputWrapper>
            {app.platform === 'webpush' && (
              <InputWrapper>
                <Checkbox
                  checked={safariOpenTracking}
                  onChange={handleOnSafariOpenTrackingChange}
                  label={<React.Fragment>Enable Safari web push open tracking</React.Fragment>}
                />
              </InputWrapper>
            )}
            <FormActions>
              <Button
                style={{ marginTop: 10 }}
                kind="primary"
                intent="action"
                isLoading={btnLoading}
              >
                Save settings & resync push config
              </Button>
            </FormActions>
          </Form>
        </Section>

        <Section>
          <SectionTitle>Keys</SectionTitle>
          <Copy
            label={
              app.platform === 'webpush' ? (
                <Grid template="1fr 120px">
                  <span>Site URL</span>
                  <ButtonLink
                    href={app.bundleId}
                    kind="secondary"
                    intent="neutral"
                    style={{ height: 28 }}
                  >
                    Open website
                  </ButtonLink>
                </Grid>
              ) : (
                <Grid template="1fr 100px">
                  <span>Bundle ID</span>
                  {app.platform === 'ios' && (
                    <ButtonLink
                      href={`https://itunes.apple.com/app/id${app.bundleId}`}
                      kind="secondary"
                      style={{ height: 28 }}
                      intent="neutral"
                    >
                      App Store
                    </ButtonLink>
                  )}
                  {app.platform === 'android' && (
                    <ButtonLink
                      kind="secondary"
                      style={{ height: 28 }}
                      href={`https://play.google.com/store/apps/details?id=${app.bundleId}`}
                    >
                      Play Store
                    </ButtonLink>
                  )}
                </Grid>
              )
            }
            value={app.bundleId || ''}
          />
          <Copy label="API Key" value={app.apiKey} />
          {app.platform !== 'webpush' && <Copy label="Dev key" value={app.devApiKey || ''} />}
          <Copy label="REST key" value={company.restKey || ''} />
        </Section>
      </div>
      <div
        style={{
          backgroundColor: schemes.grayscale['01'],
          borderLeft: `1px solid ${colors.stroke}`,
          borderRight: `1px solid ${colors.stroke}`,
        }}
      >
        <DataBoxContainer>
          <DataBox>
            <label>Created</label>
            {app.created ? app.created.format('DD/MM/YY') : 'NA'}
          </DataBox>
          <DataBox>
            <label>Updated</label>
            {app.updated ? app.updated.format('DD/MM/YY') : 'NA'}
          </DataBox>
          <DataBox>
            <label>Last access</label>
            {app.lastAccess ? app.lastAccess.format('DD/MM/YY') : 'NA'}
          </DataBox>
        </DataBoxContainer>
        <DataBoxContainer>
          <DataBox>
            <Tooltip tooltip="Total opt-in users">
              <label>
                <Icon style={{ marginRight: 5 }} size={11} icon="push-compact" />
                Opt-ins
              </label>
            </Tooltip>

            {kformat(notifOn)}
          </DataBox>
          <DataBox>
            <Tooltip tooltip="Yesterday unique active users">
              <label>
                <Icon style={{ marginRight: 5 }} size={11} icon="calendar" />
                DAUs
              </label>
            </Tooltip>
            {kformat(analytics.data.daus)}
          </DataBox>
          <DataBox>
            <Tooltip tooltip="Yesterday starts">
              <label>
                <Icon style={{ marginRight: 5 }} size={11} icon="calendar" />
                Starts
              </label>
            </Tooltip>
            {kformat(analytics.data.starts)}
          </DataBox>
          <DataBox>
            <Tooltip tooltip="Yesterday opt out">
              <label>
                <Icon style={{ marginRight: 5 }} size={11} icon="calendar" />
                Opt outs
              </label>
            </Tooltip>
            {kformat(analytics.data.optOut)}
          </DataBox>
          <DataBox>
            <Tooltip
              tooltip={
                <React.Fragment>
                  {sdkLvls.map(sdk => (
                    <p key={sdk.value}>
                      <strong>{sdk.value}:</strong> {sdk.pcent}
                    </p>
                  ))}
                </React.Fragment>
              }
            >
              <label>
                <Icon style={{ marginRight: 5 }} size={11} icon="version" />
                SDK Level
              </label>
            </Tooltip>
            {sdkLvls.length === 0 ? 'NA' : `${sdkLvls[0].value}: ${sdkLvls[0].pcent}`}
          </DataBox>
        </DataBoxContainer>
        <EntityLog logs={app.logs} />
      </div>
    </Grid>
  )
}

export { AppData }
