import { type Dayjs } from 'dayjs'
import * as Immutable from 'immutable'
import { type Map, type RecordOf, type Set } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type fetchingState } from './_records'

import {
  EspConfigurationFactory,
  type EspConfigurationRecord,
} from 'com.batch.redux/corelogic/records/esp-configuration.records'

import {
  type SspConfigurationRecord,
  SspConfigurationFactory,
} from 'com.batch/sms/infra/models/ssp-configuration.record'

// ====================== PROJECTS RECORD
type ProjectProps = {
  id: string
  name: string
  iconUrl: string
  loadingState: fetchingState
  appCount: number
  appIds: Set<number>
  emailConfigured: boolean
  smsConfigured: boolean
  pushConfigured: boolean
  webPushConfigured: boolean
  anyPushConfigured: boolean
  companyId: number
  projectKey: string
  espConfiguration?: EspConfigurationRecord
  createdAt: Dayjs
  sspConfiguration?: SspConfigurationRecord
}

export const ProjectFactory = Immutable.Record<ProjectProps>({
  id: '',
  name: '',
  loadingState: 'INIT',
  iconUrl: '',
  appCount: 0,
  emailConfigured: false,
  smsConfigured: false,
  pushConfigured: false,
  webPushConfigured: false,
  anyPushConfigured: false,
  companyId: 0,
  appIds: Immutable.Set(),
  projectKey: '',
  espConfiguration: EspConfigurationFactory(),
  createdAt: dayjs(),
  sspConfiguration: SspConfigurationFactory(),
} as ProjectProps)
export type ProjectRecord = RecordOf<ProjectProps>

type ProjectStateProps = {
  entities: Map<string, ProjectRecord>
  currentProjectId: string | null | undefined
}
export const ProjectStateFactory = Immutable.Record<ProjectStateProps>({
  entities: Immutable.Map(),
  currentProjectId: null,
} as ProjectStateProps)

export type ProjectStateRecord = RecordOf<ProjectStateProps>
