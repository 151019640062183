import styled, { css } from 'styled-components'

import { Box, BoxBody } from 'components/common/box'
import { Grid } from 'components/common/grid'
import { colors, schemes } from 'components/styled/tokens'
import { LoadingAnimationStyle } from 'components/styled/utils'

// type ThemeProps = {
//   themeThumbMode: 'main' | 'modal'
//   isLoading: boolean
// }
// ====================== theme list
export const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;

  h4 {
    margin-bottom: 0;
  }

  span {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    color: ${colors.textLight};
  }
`

export const Separator = styled.div`
  width: 1px;
  height: 20px;
  margin-left: 4px;
  background-color: ${colors.stroke};
`

export const ThemeListContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 200px;
  gap: 32px;
  justify-content: space-around;
  min-height: 200px;

  @media (min-width: 730px) {
    grid-template-columns: 200px 200px 200px;
    justify-content: space-between;
  }

  @media (min-width: 1260px) {
    grid-template-columns: 200px 200px 200px 200px;
  }

  @media (min-width: 1500px) {
    grid-template-columns: 200px 200px 200px 200px 200px;
  }
`
type ThemeFilterEmptyContainerProps = {
  kind: 'basic' | 'modal'
}
export const ThemeFilterEmptyContainer = styled.div<ThemeFilterEmptyContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  background: ${schemes.grayscale['10']};
  border-radius: 4px;

  ${p =>
    p.kind === 'modal'
      ? css`
          height: calc(100% - 40px);
          margin: 20px;
        `
      : css`
          height: 220px;
        `}

  h3 {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: ${colors.text};
  }

  p {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: ${colors.textNeutral};
  }
`

// ====================== theme thumb
type ThemeThumbContainerProps = {
  isChecked: boolean
}
export const ThemeThumbContainer = styled.div<ThemeThumbContainerProps>`
  position: relative;
  width: 200px;
  height: 382px;
  background-color: ${colors.fill};
  border-radius: 8px;
  border: 1px solid ${colors.stroke};
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  cursor: ${p => (p.theme.themeThumbMode === 'modal' ? 'pointer' : 'default')};

  ${p =>
    p.isChecked &&
    css`
      &:after {
        content: '';
        top: -1px;
        right: -1px;
        height: calc(100% + 2px);
        width: calc(100% + 2px);
        position: absolute;
        border: 2px solid ${colors.fillAction};
        border-radius: 8px;
      }
    `}
`
type ThemeThumbHeaderProps = {
  noCampaignsRunning: boolean
}
export const ThemeThumbHeader = styled(Grid)<ThemeThumbHeaderProps>`
  display: grid;
  ${'' /* grid-template-columns: minmax(50px, 1fr) 28px; */}
  ${'' /* grid-gap: 6px; */}
  height: 58px;
  padding: 10px 6px 10px 10px;
  background-color: ${colors.fill};
  border-bottom: 1px solid ${colors.stroke};
  border-radius: 8px 8px 0 0;

  .styled_theme-thunk-options {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3 {
    margin-bottom: 3px;
    font-size: 1em;
    line-height: 16px;
    font-weight: 600;
    text-overflow: ellipsis;
    color: ${colors.textNeutral};
    overflow: hidden;
    white-space: nowrap;
  }

  span {
    display: block;
    margin-top: 4px;
    font-size: 1em;
    line-height: 15px;
    text-overflow: ellipsis;
    color: ${p => (p.noCampaignsRunning ? colors.textDisabled : colors.textLight)};
    overflow: hidden;
    white-space: nowrap;
  }
`
export const ThemeThumbOverlay = styled.div`
  position: absolute;
  top: -322px;
  left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 50px;
  height: 100%;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.64);

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-of-type {
      margin-top: 25px;
    }

    &:last-of-type {
      font-size: 13px;
      line-height: 16px;
      color: ${schemes.grayscale['20']};
    }
  }
`

export const ThemeThumbPreview = styled.div`
  transform: scale(0.66);
  transform-origin: left top 0px;

  & > div {
    position: relative;
    width: 300px;
    height: 488px;
  }

  /* fix preview */
  .landingPreview.landingPreview--modal.landingPreview,
  .landingPreview.landingPreview--image.landingPreview {
    height: 488px;
  }

  ${
    '' /* .landingPreview--imageFullScreen .lpcc__imgcont__img {
    background-position: -115px 0px;
  } */
  }
`
export const ThemeTumbContainer = styled.div`
  position: relative;
  height: 322px;
  width: 100%;
  overflow: hidden;
  border-radius: 0 0 7px 7px;

  &:hover {
    ${ThemeThumbOverlay} {
      top: 0;
    }

    ${p =>
      p.theme?.themeThumbMode === 'main' &&
      css`
        ${ThemeThumbPreview} {
          opacity: 0.2;
        }
      `};
  }
`

export const ThemeThumbPlaceholder = styled.div`
  width: 200px;
  height: 380px;

  border-radius: 4px;

  ${p =>
    p.theme.isLoading
      ? LoadingAnimationStyle()
      : css`
          background: ${schemes.grayscale['30']};
        `}
`

// ====================== theme modal
export const ThemeModalBox = styled(Box)`
  width: 900px;
  border: none;

  ${BoxBody} {
    height: 438px;
    overflow-y: scroll;
    overflow-x: hidden;

    & > ${Grid} {
      justify-content: space-between;
      grid-gap: 20px;
    }
  }
`

export const ThemeModalHeader = styled(Grid)`
  width: 100%;

  & > * {
    margin: 0;
  }
`
