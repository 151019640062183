import * as React from 'react'

import { Box, BoxHeader, HeaderBoxTitle, BoxBody, HeaderBoxActions } from 'components/common/box'
import { Button } from 'components/common/button'
import { Hint } from 'components/common/hint'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableRow,
  TableCell,
  TableBody,
} from 'components/common/table'

import { numberFormat, percentage } from 'com.batch.common/utils'

type ClickDetailPopinProps = {
  data: Array<{
    label: string
    value: number
  }>
  display: number
}

export const ClickDetailPopin = ({ data, display }: ClickDetailPopinProps): React.ReactElement => {
  const [open, setOpen] = React.useState(false)
  return (
    <React.Fragment>
      <Button kind="inline" intent="neutral" style={{ height: 28 }} onClick={() => setOpen(true)}>
        See clicks details
      </Button>
      <Popin opened={open} close={() => setOpen(false)}>
        <Box style={{ width: 522 }}>
          <BoxHeader>
            <HeaderBoxTitle title="Buttons" />
            <HeaderBoxActions>
              <Button onClick={() => setOpen(false)}>
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody>
            <Table template="1fr 120px 120px">
              <TableHeader>
                <TableCellHeader>ID</TableCellHeader>
                <TableCellHeader>Clicked</TableCellHeader>
                <TableCellHeader>Click rate</TableCellHeader>
              </TableHeader>
              <TableBody style={{ maxHeight: 320, overflowY: 'auto' }}>
                {data.map((d, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {d.label === 'unknown' ? (
                        <React.Fragment>
                          Unknown
                          <Hint>
                            Number of clicks tracked without defined analytics ID. For example,
                            clicks on a deeplink action using &laquo;_blank&raquo; target will fall
                            under this category.
                          </Hint>
                        </React.Fragment>
                      ) : (
                        d.label?.toUpperCase()
                      )}
                    </TableCell>
                    <TableCell>{numberFormat(d.value)}</TableCell>
                    <TableCell>{percentage(display ? d.value / display : 0)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </BoxBody>
        </Box>
      </Popin>
    </React.Fragment>
  )
}
