import Immutable, { type RecordOf } from 'immutable'

import { type TransmissionType } from 'com.batch/shared/models/transmission-type'

type EspDomainsProps = {
  bounceDomain: string
  sendingDomain: string
  transmissionTypes: Immutable.Set<TransmissionType>
}

export const EspDomainFactory = Immutable.Record<EspDomainsProps>({
  bounceDomain: '',
  sendingDomain: '',
  transmissionTypes: Immutable.Set(),
})
export type EspDomainsRecord = RecordOf<EspDomainsProps>

type EspConfigurationProps = {
  subAccountId: number
  domains: Immutable.List<EspDomainsRecord>
}

export const EspConfigurationFactory = Immutable.Record<EspConfigurationProps>({
  subAccountId: -1,
  domains: Immutable.List(),
})
export type EspConfigurationRecord = RecordOf<EspConfigurationProps>
