import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

import { type SubscriptionRecord } from 'com.batch/profile/models/profile.records'
import {
  ProfileBodyTitle,
  ChannelBlock,
  ChannelBlockSubscriptions,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { SubscriptionBlock } from 'com.batch/profile/ui/components/profile-hero/subscription-block/subscription-block'
import { Copyable } from 'com.batch/shared/ui/component/copy/copyable'

type Props = {
  isLoading?: boolean
  subscription: SubscriptionRecord
  email: string | null | undefined
}

export const EmailChannelBlock = ({
  isLoading = false,
  email,
  subscription,
}: Props): React.ReactElement => (
  <ChannelBlock>
    <ProfileBodyTitle>
      <h2>
        <Skeleton w={22} h={22}>
          <Icon icon="mail-rounded" />
        </Skeleton>
        <Skeleton w={80} h={22} style={{ marginLeft: 8 }}>
          <span>Email</span>
        </Skeleton>
      </h2>
    </ProfileBodyTitle>
    {!email && !isLoading ? (
      <div className="no-contact">No email address attached to this profile</div>
    ) : (
      <React.Fragment>
        <ChannelBlockSubscriptions>
          <SubscriptionBlock
            channel="email"
            subscriptionName="MARKETING"
            subscriptionStatus={subscription.emailMarketing.status}
            lastEmailOpened={subscription.emailMarketing.lastEmailOpened}
            lastEmailClicked={subscription.emailMarketing.lastEmailClicked}
            contact={email}
            isLoading={isLoading}
          />
          <SubscriptionBlock
            channel="email"
            subscriptionName="TRANSACTIONAL"
            subscriptionStatus={subscription.emailService.status}
            lastEmailOpened={subscription.emailService.lastEmailOpened}
            lastEmailClicked={subscription.emailService.lastEmailClicked}
            contact={email}
            isLoading={isLoading}
          />
        </ChannelBlockSubscriptions>
        <Skeleton w={150} h={13}>
          <span className="fs-exclude">
            <Copyable fullWidth={true} notificationText="Email copied" value={email ?? ''} />
          </span>
        </Skeleton>
      </React.Fragment>
    )}
  </ChannelBlock>
)
