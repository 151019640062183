import Immutable, { type OrderedSet } from 'immutable'
import * as React from 'react'
import { useSelector } from 'components/console/react-redux'
import { useParams } from 'react-router-dom'

import { useToggle } from 'components/_hooks/use-toggle'
import { Box, BoxHeader, BoxTitle, BoxFooter, BoxBody } from 'components/common/box'
import { Button } from 'components/common/button'
import { Loader } from 'components/common/loader/loader'
import { Icon } from 'components/common/svg-icon'
import {
  Table,
  TableBody,
  TableHeader,
  TableCellHeader,
  TableRow,
  TableCell,
} from 'components/common/table'
import { Checkbox } from 'components/form'

import { createProjectAppsSelector } from '../redux/app'
import {
  PlatformFluxFactory,
  type Flux,
  coerceStringToFlux,
  type PlatformFluxRecord,
} from '../redux/console.records'
import { getAdditionnalsData, saveAdditionalData } from '../redux/metadata'

const LabelUcpGdprSdk = () => {
  return (
    <React.Fragment>
      <Icon icon="warning-triangle" size={20} style={{ marginRight: 6 }} />
      SDK optOutAndWipeData
    </React.Fragment>
  )
}

const label: { [K in Flux]: React.ReactNode } = {
  ucp_native_events_sdk: 'SDK Data',
  ucp_custom_events_sdk: 'SDK Events',
  ucp_custom_events_api: 'Trigger Events API',
  ucp_custom_data_api: 'Custom Data API',
  ucp_gdpr_api: 'GDPR API',
  ucp_gdpr_sdk: LabelUcpGdprSdk(),
  profile_api_data_compatibility: 'Profile API Data',
  profile_api_event_compatibility: 'Profile API Events',
  ucp_push_subscriptions: 'Push Subscriptions',
}

export const listSource: OrderedSet<Flux> = Immutable.OrderedSet([
  'ucp_native_events_sdk',
  'ucp_custom_events_sdk',
  'ucp_custom_events_api',
  'ucp_custom_data_api',
  'ucp_gdpr_api',
  'ucp_gdpr_sdk',
  'profile_api_data_compatibility',
  'profile_api_event_compatibility',
  'ucp_push_subscriptions',
])

const coercePlatform = (p: string): ProjectPlatforms =>
  p === 'ios' ? 'ios' : p === 'android' ? 'android' : 'webpush'

export const ProjectDataManagement = (): React.ReactElement => {
  const { projectKey } = useParams()
  const [loading, setLoading] = React.useState(false)
  const savingState = useToggle()
  const [currentBackendValues, setCurrentBackendValues] =
    React.useState<PlatformFluxRecord>(PlatformFluxFactory())
  const [values, setValues] = React.useState<PlatformFluxRecord>(PlatformFluxFactory())

  const getAppsForProjectKey = useSelector(createProjectAppsSelector)

  const apps = React.useMemo(() => {
    return getAppsForProjectKey(projectKey ?? '')
  }, [getAppsForProjectKey, projectKey])

  const onProjectDataManagementSave = React.useCallback(async () => {
    savingState.open()

    const valuesToSave: Array<{
      apiKey: string
      platform: Platforms
      source: string
      value: boolean
    }> = []

    apps.forEach(app => {
      const toDisabled = currentBackendValues
        .get(coercePlatform(app.platform))
        .subtract(values.get(coercePlatform(app.platform)))
      const toEnabled = values
        .get(coercePlatform(app.platform))
        .subtract(currentBackendValues.get(coercePlatform(app.platform)))

      if (toDisabled.size !== 0) {
        toDisabled.toArray().forEach(source => {
          valuesToSave.push({
            value: false,
            platform: app.platform,
            source: source,
            apiKey: app.apiKey,
          })
        })
      }

      if (toEnabled.size !== 0) {
        toEnabled.toArray().forEach(source => {
          valuesToSave.push({
            value: true,
            platform: app.platform,
            source: source,
            apiKey: app.apiKey,
          })
        })
      }
    })

    try {
      await saveAdditionalData(valuesToSave)
      window.location.href = `/console/projects/${projectKey ?? ''}`
    } catch (error: any) {
      savingState.close()
      console.log({ error })
      alert(error)
    }
  }, [currentBackendValues, values, apps, savingState, projectKey])

  React.useEffect(() => {
    const fetchAdditionnalData = async () => {
      setLoading(true)
      const apiKeys = apps.map(app => ({
        key: app.apiKey,
        platform: coercePlatform(app.platform),
      }))

      try {
        const response = await getAdditionnalsData(apiKeys)
        const transformData: PlatformFluxRecord = response.flat().reduce((acc, curr) => {
          const { values } = curr.additionalData

          let fluxSet = Immutable.OrderedSet<Flux>(['ucp_gdpr_api']) // default value

          for (const property in values) {
            const fluxName = coerceStringToFlux(property)
            if (fluxName) {
              if (values[property].boolValue) fluxSet = fluxSet.add(fluxName)
            }
            // we remove the default value set above when explicitely set to false
            if (fluxName === 'ucp_gdpr_api' && !values[property].boolValue) {
              fluxSet = fluxSet.remove(fluxName)
            }
          }

          const platform = curr.apiKey.platform.toLowerCase()
          return acc.set(coercePlatform(platform), fluxSet)
        }, PlatformFluxFactory())

        setCurrentBackendValues(transformData)
        setValues(transformData)
      } catch (e: any) {
        console.log({ e })
      }
      setLoading(false)
    }

    fetchAdditionnalData()
  }, [apps])

  const togglePlatform = React.useCallback(
    (platform, source) => () => {
      setValues(values =>
        values.set(
          platform,
          values.get(platform).has(source)
            ? values.get(platform).remove(source)
            : values.get(platform).add(source)
        )
      )
    },
    []
  )

  const projectHasAppAndroid = React.useMemo(() => {
    return apps.find(app => app.platform === 'android') !== undefined
  }, [apps])
  const projectHasAppIOS = React.useMemo(() => {
    return apps.find(app => app.platform === 'ios') !== undefined
  }, [apps])
  const projectHasAppWebpush = React.useMemo(() => {
    return apps.find(app => app.platform === 'webpush') !== undefined
  }, [apps])

  return (
    <Loader loading={loading}>
      <Box>
        <BoxHeader>
          <BoxTitle>Data management</BoxTitle>
        </BoxHeader>
        <BoxBody $padding>
          <Table template="1fr 1fr 1fr 1fr">
            <TableHeader>
              <TableCellHeader>Flux</TableCellHeader>
              {projectHasAppAndroid && <TableCellHeader>Android</TableCellHeader>}
              {projectHasAppIOS && <TableCellHeader>iOS</TableCellHeader>}
              {projectHasAppWebpush && <TableCellHeader>WEBPUSH</TableCellHeader>}
            </TableHeader>
            <TableBody>
              {listSource.map((source, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{label[source]}</TableCell>
                    {projectHasAppAndroid && (
                      <TableCell>
                        <Checkbox
                          checked={values.android.has(source)}
                          onChange={togglePlatform('android', source)}
                          ariaLabel="Enable for Android"
                        />
                      </TableCell>
                    )}

                    {projectHasAppIOS && (
                      <TableCell>
                        <Checkbox
                          checked={values.ios.has(source)}
                          onChange={togglePlatform('ios', source)}
                          ariaLabel="Enable for iOS"
                        />
                      </TableCell>
                    )}

                    {projectHasAppWebpush && (
                      <TableCell>
                        <Checkbox
                          checked={values.webpush.has(source)}
                          onChange={togglePlatform('webpush', source)}
                          ariaLabel="Enable for Webpush"
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </BoxBody>
        <BoxFooter>
          <Button
            kind="primary"
            intent="action"
            onClick={onProjectDataManagementSave}
            disabled={currentBackendValues === values}
            isLoading={savingState.value}
          >
            Save and sync project
          </Button>
        </BoxFooter>
      </Box>
    </Loader>
  )
}
