import Immutable from 'immutable'
import * as React from 'react'

import { useToggle } from 'components/_hooks'
import { Box, HeaderBoxActions, BoxHeader, HeaderBoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { Title } from 'components/styled/text'

import { SettingsAndroidFcmLegacy } from './settings-android-fcm-legacy'
import { SettingsAndroidHMS } from './settings-android-hms'
import { SettingsCommon } from './settings-common'

import { type PushConfigRecord, type AppRecord } from 'com.batch.redux/_records'
import { type MessageType } from 'com.batch.redux/toaster'

import { SettingsAndroidFcm } from 'com.batch/settings/ui/components/settings-fcm/settings-android-fcm'

type SettingsAndroidProps = {
  config: PushConfigRecord
  advanced: boolean
  app: AppRecord
  savePushConfig: (
    config: PushConfigRecord,
    file?: File | null | undefined,
    password?: string | null | undefined
  ) => Promise<void>
  showToast: (arg1: MessageType) => any
}

const SettingsAndroid = ({
  config,
  advanced,
  savePushConfig,
  app,
  showToast,
}: SettingsAndroidProps) => {
  const googleTabState = useToggle(true)
  const commonOptions = Immutable.Set<'priority' | 'ttl' | 'collapseKey'>([
    'ttl',
    'priority',
    'collapseKey',
  ])

  return (
    <React.Fragment>
      <Title overEmptyState>Android Push Settings</Title>
      <Box>
        <BoxHeader>
          <HeaderBoxTitle title="Push configuration" />
          {advanced && (
            <HeaderBoxActions>
              <Button
                style={{ margin: 0 }}
                kind="inline"
                isActive={googleTabState.value}
                onClick={googleTabState.open}
              >
                Google (FCM)
              </Button>
              <Button
                style={{ margin: 0 }}
                kind="inline"
                isActive={!googleTabState.value}
                onClick={googleTabState.close}
              >
                Huawei (HMS)
              </Button>
            </HeaderBoxActions>
          )}
        </BoxHeader>
        {googleTabState.value ? (
          app.features.includes('fcm-v1-api-migration') ? (
            <SettingsAndroidFcm />
          ) : (
            <SettingsAndroidFcmLegacy
              config={config}
              savePushConfig={savePushConfig}
              showToast={showToast}
            />
          )
        ) : (
          <SettingsAndroidHMS
            config={config}
            savePushConfig={savePushConfig}
            appId={app.id}
            showToast={showToast}
          />
        )}
      </Box>

      {advanced && (
        <SettingsCommon
          config={config}
          updateConfig={savePushConfig}
          show={commonOptions}
          app={app}
          showToast={showToast}
        />
      )}
    </React.Fragment>
  )
}

export default React.memo<SettingsAndroidProps>(
  SettingsAndroid
) as React.ComponentType<SettingsAndroidProps>
