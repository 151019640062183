import Immutable, { type RecordOf, type List } from 'immutable'

import { type LabelRecord } from '../models/labels.records'

type LabelStateProps = {
  labels: List<LabelRecord>
  loadingState: LoadingState
}

export const LabelStateFactory = Immutable.Record<LabelStateProps>({
  labels: Immutable.List(),
  loadingState: 'INIT',
} as LabelStateProps)

export type LabelStateRecord = RecordOf<LabelStateProps>
