import parseInt from 'core-js/es/number/parse-int'
import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Input } from 'components/form'

import { type InputProps } from './helper'
import { useNumberInputValidation } from './use-validation'

export const InputInteger = ({
  condition,
  updateCondition,
  isInvalid,
}: InputProps): React.ReactElement => {
  const { localValue, onChange, onBlur, isLocalInvalid } = useNumberInputValidation(
    condition,
    updateCondition,
    false
  )

  const showTimes = React.useMemo(
    () =>
      condition.attribute?.type === 'EVENT' &&
      condition.functions.filter(f => f.value === 'count' || f.value === 'countSince').size >= 1,
    [condition]
  )
  return (
    <Grid template={showTimes ? '80px 20px' : 'auto'}>
      <Input
        invalid={isInvalid || isLocalInvalid}
        type="number"
        onBlur={onBlur}
        value={localValue}
        onChange={onChange}
      />
      <span>
        {showTimes && (parseInt(localValue) === 0 || parseInt(localValue) > 1 ? 'times' : 'time')}
      </span>
    </Grid>
  )
}
