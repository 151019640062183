import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'
import { useParams } from 'react-router-dom'

import { FilterSelectMulti } from 'components/filter/filter-select-multi/filter-select-multi'

import { contents } from '../steps-analytics/steps-analytics-row'

import { updateAnalyticsFilters } from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { journeyTreeSelector } from 'com.batch/orchestration-journey/models/journey.selectors'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'

type StepOption = {
  step: string
  label: string
  channel: ChannelUntilCleanup
}

export const StepFilter = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { token } = useParams()
  const { nodesMap } = useSelector(journeyTreeSelector)
  const { filters } = useGetAnalyticsFilters(token)
  const steps: List<StepOption> = React.useMemo(
    () =>
      nodesMap.reduce((acc: List<StepOption>, node) => {
        if (node.type !== 'MESSAGE') return acc
        return acc.push({
          step: node.id,
          label: node.label,
          channel: node.messageConfig.channel,
        })
      }, Immutable.List()),
    [nodesMap]
  )
  const selectedSteps = React.useMemo(
    () => steps.filter(step => filters.get('step', Immutable.Map()).includes(step.step)),
    [steps, filters]
  )

  const onStepChange = React.useCallback(
    (steps: List<StepOption>) => {
      if (token)
        dispatch(
          updateAnalyticsFilters({
            token,
            filters:
              steps.size > 0
                ? filters.set(
                    'step',
                    steps.map(step => step.step)
                  )
                : filters.delete('step'),
          })
        )
    },
    [dispatch, token, filters]
  )
  const optionToString = React.useCallback((option?: StepOption | null) => {
    if (option) return option.label || contents[option.channel]?.label
    return ''
  }, [])
  const formatTerm = React.useCallback(
    (count: number) => {
      if (count === 1) {
        const firstStepChannel: ChannelUntilCleanup | undefined = selectedSteps.first()?.channel
        return (
          selectedSteps.first()?.label ||
          (firstStepChannel ? contents[firstStepChannel].label : `${count} steps`)
        )
      } else {
        return `${count} steps`
      }
    },
    [selectedSteps]
  )

  return (
    <FilterSelectMulti
      id="analytics-filter-step"
      options={steps}
      value={selectedSteps}
      onChange={onStepChange}
      optionToString={optionToString}
      placeholder={'All steps'}
      term={formatTerm}
      toggleButtonWidth={321}
      menuOffset={100}
      isSearchable={false}
    />
  )
}
