export default {
  default: 'Default',
  ar: 'Arabic',
  be: 'Belarus',
  bg: 'Bulgarian',
  ca: 'Catalan',
  zh: 'Chinese (Simplified)',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  nl: 'Dutch',
  en: 'English',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  de: 'German',
  el: 'Greek',
  he: 'Hebrew',
  hi: 'Hindi',
  hu: 'Hungarian',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  ms: 'Malay',
  no: 'Norwegian',
  pl: 'Polish',
  pt: 'Portuguese (All)',
  'pt-PT': 'Portuguese (Portugal)',
  'pt-BR': 'Portuguese (Brazil)',
  ro: 'Romanian',
  ru: 'Russian',
  sr: 'Serbian',
  sk: 'Slovak',
  sl: 'Slovenian',
  es: 'Spanish',
  sv: 'Swedish',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  vi: 'Vietnamese',
  fa: 'Persian',
  nb: 'Norwegian Bokmål',
  lt: 'Lithuanian',
  az: 'Azerbaijani',
  lv: 'Latvian',
  my: 'Burmese',
  km: 'Khmer',
  mk: 'Macedonian',
  ka: 'Georgian',
  bs: 'Bosnian',
  sq: 'Albanian',
}
