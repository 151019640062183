import Immutable, { type List, type OrderedMap, type OrderedSet, type Set } from 'immutable'
import { get as _get, map as _map } from 'lodash-es'
import request from 'superagent-interface-promise'

import { type availableIcons } from 'components/common/svg-icon'

import { stringToUtcDayJs } from 'com.batch.common/dayjs.custom'
import Types from 'com.batch.common/legacy-query-types'
import { percentage } from 'com.batch.common/utils'

import {
  AttributeFactory,
  EventDataAttributeFactory,
  type AppRecord,
  type AttributeRecord,
  type AttributeProp,
  type EventDataAttributeRecord,
} from 'com.batch.redux/_records'

export const RegionAttribute: AttributeRecord = AttributeFactory({
  id: 'b.region',
  label: 'region (native)',
  icon: 'text',
  native: true,
  type: Types.STRING,
  category: 'attribute',
})
export const CustomIDAttribute: AttributeRecord = AttributeFactory({
  id: 'b.custom_id',
  label: 'custom user ID (native)',
  icon: 'text',
  native: true,
  type: Types.STRING,
  category: 'attribute',
})
export const CampaignTokenAttribute: AttributeRecord = AttributeFactory({
  id: 'b.campaign_token',
  label: 'campaign token (native)',
  icon: 'text',
  native: true,
  type: Types.STRING,
  category: 'attribute',
})
export const EmailMarketingAttribute: AttributeRecord = AttributeFactory({
  id: 'b.email_marketing',
  label: 'email marketing (native)',
  icon: 'text',
  native: true,
  type: Types.BOOLEAN,
  category: 'attribute',
})
export const TimeZoneAttribute: AttributeRecord = AttributeFactory({
  id: 'b.timezone',
  label: 'timezone (native)',
  icon: 'text',
  native: true,
  type: Types.STRING,
  category: 'attribute',
})
export const EmailAttribute: AttributeRecord = AttributeFactory({
  id: 'b.email_address',
  label: 'email address (native)',
  icon: 'text',
  native: true,
  type: Types.STRING,
  category: 'attribute',
})

export const EmailDomainAttribute: AttributeRecord = AttributeFactory({
  id: 'b.email_domain',
  label: 'Email domain',
  icon: 'mail',
  native: true,
  type: Types.STRING,
  category: 'attribute',
})

export const AudienceAttribute: AttributeRecord = AttributeFactory({
  id: 'bt.custom_audiences',
  label: 'Audiences',
  icon: 'targeting',
  native: true,
  type: Types.AUDIENCE,
  category: 'attribute',
})

export const SegmentsCollectionAttribute: AttributeRecord = AttributeFactory({
  id: 'bt.segments',
  label: 'Segments',
  icon: 'segments',
  native: true,
  type: Types.TAG,
  category: 'attribute',
})

// Click
export const LastEmailClickMarketing: AttributeRecord = AttributeFactory({
  id: 'b.last_email_click_marketing',
  label: 'Last email click',
  icon: 'calendar',
  native: true,
  type: Types.DATE,
  category: 'attribute',
})

export const LastEmailClick: AttributeRecord = AttributeFactory({
  id: 'b.last_email_click',
  label: 'Last email click',
  icon: 'calendar',
  native: true,
  type: Types.DATE,
  category: 'attribute',
})

// Open
export const LastEmailMarketingOpen: AttributeRecord = AttributeFactory({
  id: 'b.last_email_marketing_open',
  label: 'Last email open',
  icon: 'calendar',
  native: true,
  type: Types.DATE,
  category: 'attribute',
})

export const LastEmailOpen: AttributeRecord = AttributeFactory({
  id: 'b.last_email_open',
  label: 'Last email open',
  icon: 'calendar',
  native: true,
  type: Types.DATE,
  category: 'attribute',
})
export const LastVisitDate: AttributeRecord = AttributeFactory({
  id: 'b.last_visit_date',
  label: 'Last visit',
  icon: 'calendar',
  native: true,
  type: Types.DATE,
  category: 'attribute',
})

export const HasCustomId: AttributeRecord = AttributeFactory({
  id: 'b.has_custom_id',
  label: 'Has custom ID',
  icon: 'boolean',
  native: true,
  type: Types.BOOLEAN,
  category: 'attribute',
})
// optins
export const IsEmailOptin: AttributeRecord = AttributeFactory({
  id: 'b.is_email_optin',
  label: 'Email opt-in',
  icon: 'boolean',
  native: true,
  type: Types.BOOLEAN,
  category: 'attribute',
})
export const IsSmsOptin: AttributeRecord = AttributeFactory({
  id: 'b.is_sms_optin',
  label: 'SMS opt-in',
  icon: 'boolean',
  native: true,
  type: Types.BOOLEAN,
  category: 'attribute',
})
export const IsPushOptin: AttributeRecord = AttributeFactory({
  id: 'b.is_push_optin',
  label: 'App Push opt-in',
  icon: 'boolean',
  native: true,
  type: Types.BOOLEAN,
  category: 'attribute',
})
export function searchOptions<T>({
  app,
  attributeId,
  search,
}: {
  app: AppRecord
  attributeId: string
  search: string
}): Promise<List<T>> {
  return request
    .get(`/api/app/${app.id}/custom-data/values/prod/${attributeId}?startsWith=${search}`)
    .then(response => {
      const data = _get(response.body.results, attributeId, [])
      return Immutable.List(data.map(v => v.value))
    })
}

export const getCategoryFromId = (id: string): AttributeProp['category'] => {
  if (id.substring(0, 2) === 'c.') {
    return 'attribute'
  } else if (id.substring(0, 2) === 'e.') {
    return 'event'
  } else if (id.substring(0, 2) === 'u.') {
    return 'user_attribute'
  } else if (id.substring(0, 3) === 'ue.') {
    return 'user_event'
  } else if (id.substring(0, 3) === 'ut.') {
    return 'user_tag'
  } else if (id.substring(0, 2) === 'b.') {
    return 'batch_attribute'
  } else if (id.substring(0, 3) === 'be.') {
    return 'batch_event'
  } else {
    return 'tag'
  }
}

export const listNativeAttributesForPlatforms = (
  platforms: Set<Platforms>
): List<AttributeRecord> => {
  const firstPlatform = platforms.first()
  let list: List<AttributeRecord> = Immutable.List()
  list = list
    .push(
      AttributeFactory({
        native: true,
        id: 'b.region',
        label: 'Country',
        type: Types.STRING,
        icon: 'place',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.language',
        label: 'Language',
        type: Types.STRING,
        icon: 'message',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.push_service',
        label: 'Push service',
        type: Types.STRING,
        icon: 'connect',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.os_version',
        label: 'OS version',
        type: Types.VERSION,
        icon: platforms.size === 1 ? (firstPlatform !== '' ? firstPlatform : 'add') : 'add',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.app_version',
        label: 'App version',
        type: Types.VERSION,
        icon: 'version',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.device_type',
        label: 'Device type',
        type: Types.SET,
        icon: 'mobile',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.install_date',
        label: 'Installation date',
        type: Types.DATE,
        icon: 'download',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.last_push_date',
        label: 'Last push date',
        type: Types.DATE,
        icon: 'waiting',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.last_visit_date',
        label: 'Last visit date',
        type: Types.DATE,
        icon: 'calendar',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.carrier_code',
        label: 'Mobile carrier',
        icon: 'operator',
        type: Types.SET,
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.has_custom_id',
        label: 'Has custom user ID',
        icon: 'user',
        type: Types.BOOLEAN,
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.transaction_tracked',
        label: 'Transactions tracked',
        type: Types.BOOLEAN,
        icon: 'money',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.city_code',
        label: 'City',
        icon: 'place',
        type: Types.SET,
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.position',
        label: 'Last location',
        icon: 'location',
        type: Types.POSITION,
      })
    )

  // .push(
  //   AttributeFactory({
  //     native: true,
  //     id: 'bt.custom_audiences',
  //     label: 'Custom audiences',
  //     icon: 'targeting',
  //     type: Types.AUDIENCE
  //   })
  // )
  // .push(
  //   AttributeFactory({
  //     native: true,
  //     id: 'be.displayed',
  //     label: 'Retarget in-app viewers',
  //     name: 'Retarget in-app viewers',
  //     icon: 'targeting',
  //     type: Types.RETARGETING
  //   })
  // )
  // .push(
  //   AttributeFactory({
  //     native: true,
  //     id: 'be.sent',
  //     label: 'Retarget push audiences',
  //     name: 'Retarget push audiences',
  //     icon: 'targeting',
  //     type: Types.RETARGETING
  //   })
  // )
  // .push(
  //   AttributeFactory({
  //     native: true,
  //     id: 'be.opened',
  //     label: 'Retarget push openers',
  //     name: 'Retarget push openers',
  //     icon: 'click',
  //     type: Types.RETARGETING
  //   })
  // )
  if (platforms.size > 1) {
    list = list.filter(attr => !['b.os_version', 'b.app_version'].includes(attr.id))
  }

  list = list.filter(
    attr =>
      ((platforms.has('android') && platforms.size === 1) || attr.id !== 'b.push_service') &&
      (!platforms.has('webpush') ||
        ![
          'b.os_version',
          'b.app_version',
          'b.device_type',
          'b.position',
          'b.carrier_code',
          'b.transaction_tracked',
        ].includes(attr.id))
  )
  if (platforms.has('webpush') && platforms.size === 1) {
    list = list

      .push(
        AttributeFactory({
          native: true,
          id: 'b.device_category',
          label: 'Device category',
          name: 'Device category',
          icon: 'device',
          type: Types.SET,
        })
      )
      .push(
        AttributeFactory({
          native: true,
          id: 'b.browser_model',
          label: 'Browser Model',
          name: 'Browser Model',
          icon: 'browser',
          type: Types.SET,
        })
      )
      .push(
        AttributeFactory({
          native: true,
          id: 'b.os_model',
          label: 'OS model',
          name: 'OS model',
          icon: 'os',
          type: Types.SET,
        })
      )
  }
  if (platforms.has('ios') || platforms.has('android')) {
    list = list.push(
      AttributeFactory({
        native: true,
        id: 'b.is_push_optin',
        label: 'Push opt-in',
        name: 'Push opt-in',
        icon: 'boolean',
        type: Types.BOOLEAN,
      })
    )
  }
  return list
}

export const listNativeAttributesForApp: (app: AppRecord) => List<AttributeRecord> = app => {
  let list: List<AttributeRecord> = Immutable.List()
  list = list
    .push(
      AttributeFactory({
        native: true,
        id: 'b.region',
        label: 'Country',
        type: Types.STRING,
        icon: 'place',
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.language',
        label: 'Language',
        type: Types.STRING,
        icon: 'message',
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.push_service',
        label: 'Push service',
        type: Types.STRING,
        icon: 'connect',
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.os_version',
        label: 'OS version',
        type: Types.VERSION,
        icon: !app.platform ? 'add' : app.platform,
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.app_version',
        label: 'App version',
        type: Types.VERSION,
        icon: 'version',
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.device_type',
        label: 'Device type',
        type: Types.SET,
        icon: 'mobile',
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.install_date',
        label: 'Installation date',
        type: Types.DATE,
        icon: 'download',
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.last_push_date',
        label: 'Last push date',
        type: Types.DATE,
        icon: 'waiting',
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.last_visit_date',
        label: 'Last visit date',
        type: Types.DATE,
        icon: 'calendar',
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.carrier_code',
        label: 'Mobile carrier',
        icon: 'operator',
        type: Types.SET,
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.has_custom_id',
        label: 'Has custom user ID',
        icon: 'user',
        type: Types.BOOLEAN,
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.transaction_tracked',
        label: 'Transactions tracked',
        type: Types.BOOLEAN,
        icon: 'money',
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.city_code',
        label: 'City',
        icon: 'place',
        type: Types.SET,
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'b.position',
        label: 'Last location',
        icon: 'location',
        type: Types.POSITION,
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'bt.custom_audiences',
        label: 'Custom audiences',
        icon: 'targeting',
        type: Types.AUDIENCE,
        category: 'batch_attribute',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'be.displayed',
        label: 'Retarget in-app viewers',
        name: 'Retarget in-app viewers',
        icon: 'targeting',
        type: Types.RETARGETING,
        category: 'batch_event',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'be.sent',
        label: 'Retarget push audiences',
        name: 'Retarget push audiences',
        icon: 'targeting',
        type: Types.RETARGETING,
        category: 'batch_event',
      })
    )
    .push(
      AttributeFactory({
        native: true,
        id: 'be.opened',
        label: 'Retarget push openers',
        name: 'Retarget push openers',
        icon: 'click',
        type: Types.RETARGETING,
        category: 'batch_event',
      })
    )

  list = list.filter(
    attr =>
      (app.platform === 'android' || attr.id !== 'b.push_service') &&
      (app.platform !== 'webpush' ||
        [
          'b.os_version',
          'b.app_version',
          'b.device_type',
          'b.position',
          'b.carrier_code',
          'b.transaction_tracked',
        ].indexOf(attr.id) === -1)
  )
  if (app.platform === 'webpush') {
    list = list
      .push(
        AttributeFactory({
          native: true,
          id: 'b.browser',
          label: 'Browser Model',
          name: 'Browser',
          icon: 'browser',
          type: Types.RETARGETING,
          category: 'batch_attribute',
        })
      )
      .push(
        AttributeFactory({
          native: true,
          id: 'b.device_category',
          label: 'Device category',
          name: 'Device category',
          icon: 'device',
          type: Types.SET,
          category: 'batch_attribute',
        })
      )
      .push(
        AttributeFactory({
          native: true,
          id: 'b.browser_model',
          label: 'Browser model',
          name: 'Browser model',
          icon: 'browser',
          type: Types.SET,
          category: 'batch_attribute',
        })
      )
      .push(
        AttributeFactory({
          native: true,
          id: 'b.os_model',
          label: 'OS model',
          name: 'OS model',
          icon: 'os',
          type: Types.SET,
          category: 'batch_attribute',
        })
      )
  }
  if (app.platform === 'ios' || app.platform === 'android') {
    list = list.push(
      AttributeFactory({
        native: true,
        id: 'b.is_push_optin',
        label: 'Push opt-in',
        name: 'Push opt-in',
        icon: 'boolean',
        type: Types.BOOLEAN,
        category: 'batch_attribute',
      })
    )
  }
  return list
}

type rawAttributeProps = {
  hidden: boolean
  lastCount?: string
  lastUpdate?: string
  name: string | null | undefined
  id: string
  overridenType: string | null | undefined
  lastReceivedType: string
  hasTag?: boolean
  hasLabel?: boolean
  attributes?: string
}
export const LabelEventParam: EventDataAttributeRecord = EventDataAttributeFactory({
  name: 'Label',
  type: '__LABEL__',
  kind: 'label',
})
export const TagEventParam: EventDataAttributeRecord = EventDataAttributeFactory({
  name: 'tag',
  kind: 'tag',
  type: '__TAG__',
})

export const TypesIcons: {
  [key: string]: availableIcons
} = {
  STRING: 'text',
  BOOLEAN: 'boolean',
  BOOL: 'boolean',
  DATE: 'calendar',
  EVENT: 'action',
  TAG: 'one-of',
  INTEGER: 'integer',
  LONG: 'integer',
  DOUBLE: 'number',
  FLOAT: 'number',
  POSITION: 'location',
  VERSION: 'version',
  URL: 'link',
}

// MEP ONLY
export const rawToRecord: (raw: rawAttributeProps) => AttributeRecord = raw => {
  const usedType = raw.overridenType ? raw.overridenType : raw.lastReceivedType
  const category = getCategoryFromId(raw.id)
  let allowedKeys: OrderedSet<EventDataAttributeRecord> = Immutable.OrderedSet()
  if (category === 'event' || category === 'user_event') {
    if (raw.hasLabel) {
      allowedKeys = allowedKeys.add(LabelEventParam)
    }
    if (raw.hasTag) {
      allowedKeys = allowedKeys.add(TagEventParam)
    }
    if (raw.attributes && JSON.parse(raw.attributes)) {
      const attributes = JSON.parse(raw?.attributes ?? '')
      if (Array.isArray(attributes)) {
        attributes.forEach(attr => {
          allowedKeys = allowedKeys.add(EventDataAttributeFactory({ ...attr, kind: 'attribute' }))
        })
      }
    }
  }
  return AttributeFactory({
    hidden: raw.hidden,
    lastCount: stringToUtcDayJs(raw.lastCount),
    lastUpdate: stringToUtcDayJs(raw.lastUpdate),
    name: raw.name,
    id: raw.id,
    icon: TypesIcons[usedType],
    cleanId: raw.id.substring(raw.id.indexOf('.') + 1),
    allowedKeys,
    label: raw.name ? raw.name : raw.id.substring(raw.id.indexOf('.') + 1),
    overridenType: raw.overridenType ? Types[raw.overridenType.toUpperCase()] : null,
    lastReceivedType: Types[raw.lastReceivedType],
    type: Types[usedType],
    native: false,
    category,
  })
}
type fetchCustomAttributesResponse = {
  profileData: boolean
  data: OrderedMap<string, AttributeRecord>
}
const fetchCustomAttributes = ({
  app,
  devMode = false,
  refresh = true,
}: {
  app: AppRecord
  devMode?: boolean
  refresh?: boolean
}): Promise<fetchCustomAttributesResponse> => {
  return request
    .get(
      `/api/app/${app.id}/custom-data/summary/${refresh ? 'data' : 'local'}/${
        devMode ? 'dev' : 'prod'
      }`
    )
    .then(
      response => {
        if (Array.isArray(response.body)) {
          return {
            profileData: false,
            data: Immutable.OrderedMap(
              response.body.map(raw => {
                return [raw.id, rawToRecord(raw)]
              })
            ),
          }
        } else {
          throw 'Unable to load your app custom data'
        }
      },
      error => {
        console.log(error)
        throw error.body
      }
    )
}

export default {
  listNativeAttributesForApp,
  fetchCustomAttributes,
  fetchAttributesValues: ({
    app,
    attributesIds,
    devMode = false,
  }: {
    app: AppRecord
    attributesIds: Array<string>
    devMode?: boolean
  }): Promise<any> => {
    return request
      .post(`/api/app/${app.id}/custom-data/values/${devMode ? 'dev' : 'prod'}`, { attributesIds })
      .then(response => {
        const prettified: {
          [key: string]: Array<{
            pretty: string
            pcent: string
            value: string
            nb: number
          }>
        } = {}
        _map(
          response.body.results,
          (
            values: Maybe<
              Array<{
                value: string | number | boolean
                nb: number
                pcent?: number
                pretty?: string
              }>
            >,
            attrId: string
          ) => {
            if (!Array.isArray(values)) {
              values = []
            }
            prettified[attrId] = values.map(v => ({
              pretty: v.pretty ?? v.value.toString(),
              pcent: percentage(v.pcent),
              value: v.value.toString(),
              nb: v.nb,
            }))
          }
        )
        return prettified
      })
  },
  saveCustomAttribute: ({
    app,
    attribute,
  }: {
    app: AppRecord
    attribute: AttributeRecord
  }): Promise<{
    attribute: AttributeRecord
  }> => {
    return request.post(`/api/app/${app.id}/override-custom-data`, attribute).then(
      (response: { body: rawAttributeProps }) => {
        return { attribute: rawToRecord(response.body) }
      },
      error => {
        throw {
          error: error.body,
          attribute,
        }
      }
    )
  },
}

export const filterCategory = (
  attrMap: OrderedMap<string, AttributeRecord>,
  categoryArray: Array<
    | 'attribute'
    | 'event'
    | 'tag'
    | 'user_attribute'
    | 'user_tag'
    | 'user_event'
    | 'batch_attribute'
    | 'batch_event'
    | string
  >
): OrderedMap<string, AttributeRecord> => {
  return attrMap.filter(attribute => categoryArray.includes(attribute.category))
}

export const attributeIsEvent = (attribute: AttributeRecord): boolean => {
  return ['event', 'user_event'].includes(attribute.category)
}

export const isAttributeMatchingTerm = (attribute: AttributeRecord, term: string): boolean => {
  return (
    attribute.label.toLowerCase().includes(term.toLowerCase()) ||
    attribute.id.toLowerCase().includes(term.toLowerCase())
  )
}
