import styled from 'styled-components'

export const ActionPickerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
`

export const ActionFieldLabel = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 500;
`
