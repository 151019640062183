import styled, { css } from 'styled-components'

type FlexLineProps = {
  mb?: boolean
  height?: number
  separator?: boolean
  shouldWrap?: boolean
  condensed?: boolean
  center?: boolean
  container?: boolean
  notRounded?: boolean
  sameHeight?: boolean
  top?: boolean
  vertical?: boolean
  grow?: boolean
}
/**
 * @deprecated do not use this legacy stuff
 */
export const FlexLine = styled.div<FlexLineProps>`
  display: flex;
  min-height: ${props => (props.condensed ? '0px' : '34px')};
  flex-wrap: ${props => (props.shouldWrap ? 'wrap' : 'nowrap')};
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  margin-bottom: ${props => (props.mb ? '15px' : '0')};
  ${props => (props.grow ? 'flex-grow:1;' : '')};
  ${props => (props.separator ? 'border-top: 1px solid #F2F3F8;' : '')};
  ${props =>
    props.container
      ? `border-radius: ${props.notRounded ? '0' : '0 0 5px 5px'}; padding:32px;background: #fff;`
      : ''};
  align-items: ${props => (props.top ? 'flex-start' : props.sameHeight ? 'stretch' : 'center')};
  @media (max-width: 650px) {
    flex-direction: column;
  }
`

export const ConditionLine = styled(FlexLine)<FlexLineItemProps>`
  border-bottom: 1px solid #f2f3f8;
  padding-bottom: 8px;
  margin-bottom: 7px;
  padding-right: 30px;
`
type FlexLineItemTitleProps = {
  sm?: boolean
  mb?: number
}
export const FlexLineItemTitle = styled.div<FlexLineItemTitleProps>`
  color: #9ca4b5;
  font-weight: 500;
  font-size: ${props => (props.sm ? '10px' : '12px')};
  ${props =>
    props.mb
      ? css`
          margin-bottom: 10px;
        `
      : ''} text-transform: uppercase;
`
type FlexLineItemProps = {
  width?: number
  bl?: boolean
  right?: boolean
  fit?: boolean
  switch?: boolean
  scrollable?: boolean
  ellipsis?: boolean
  container?: boolean
  hideUnder?: number
  tinyContainer?: boolean
  contentVA?: boolean
  center?: boolean
  grow?: number
}

export const FlexLineItem = styled.div<FlexLineItemProps>`
  ${props =>
    props.ellipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
  ${props =>
    props.hideUnder &&
    css`
      @media screen and (max-width: ${props.hideUnder}px) {
        display: none;
      }
    `}
  ${props =>
    props.fit
      ? css`
          overflow: auto;
        `
      : ''}
  flex-shrink: ${props => (props.width ? 0 : 1)};
  min-width: 0px;
  text-align: ${props => (props.right ? 'right' : 'inherit')};
  flex-basis: ${props => (props.width ? props.width + 'px' : 'auto')};
  overflow-y: ${props => (props.scrollable ? 'auto' : 'inherit')};
  flex-grow: ${props => (props.grow ? `${props.grow}` : 0)};
  align-items: ${props => (props.center ? 'center' : 'start')};
  ${props =>
    props.bl
      ? css`
          border-left: 1px solid #f2f3f8;
        `
      : css`
          &:last-child {
            padding-right: 0;
          }
        `}
  ${props =>
    props.container
      ? css`
          border-radius: 0 0 5px 5px;
          background: #fff;
        `
      : ''}
  padding: ${props =>
    props.container ? '32px' : props.tinyContainer ? '16px 20px' : '0 10px 0 0'};
  ${props =>
    props.switch
      ? css`
          padding-bottom: 4px;
        `
      : '0'};
  ${props =>
    props.contentVA
      ? css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `
      : ''};
  @media (max-width: 650px) {
    flex-basis: auto;
    border-left: none;
    flex-shrink: 0;
    padding: ${props => (props.container ? '8px' : '0 0 8px 0')};
  }
`

export const FlexLineItemOption = styled(FlexLineItem)<FlexLineItemProps>`
  font-size: 13px;
  font-weight: normal;
`

export const FlexLineItemSwitch = styled(FlexLineItem)<FlexLineItemProps>`
  padding-bottom: 4px;
`
