import * as React from 'react'

type StepsProps = {
  active: number
  style?: Record<any, any>
}
const steps = [0, 1, 2, 3]
export const Steps = ({ active, style }: StepsProps): React.ReactElement => {
  return (
    <svg width={40} height={10} viewBox="0 0 40 10" style={style}>
      {steps.map(i => (
        <circle key={i} cx={i * 10 + 5} cy={5} r={3} fill={active === i ? '#03ADF3' : '#E7E7E7'} />
      ))}
    </svg>
  )
}
