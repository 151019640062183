import { type Map } from 'immutable'
import * as React from 'react'

import { Box, BoxBody, HeaderBoxTitle } from 'components/common/box'
import { Skeleton, Wrapper } from 'components/common/empty-states'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import {
  AttributesBoxHeader,
  AttributesContainer,
} from 'com.batch/profile/ui/components/attributes-block/attributes-block.styles'
import { AttributesTable } from 'com.batch/profile/ui/components/attributes-block/attributes-table'
import { FilteredAttributesProvider } from 'com.batch/profile/ui/components/attributes-block/filtered-attributes-provider'

type AttributesBlockProps = {
  attributes: Map<string, QueryValue>
  isLoading: boolean
  isEmpty: boolean
}

const maxItemsScrollable = 4

export const AttributesBlock = ({
  attributes,
  isLoading,
  isEmpty,
}: AttributesBlockProps): React.ReactElement => {
  const [attributeNames, setAttributeNames] = React.useState<string[]>([])
  const [filteredAttributeNames, setFilteredAttributeNames] = React.useState<string[]>([])
  const isOverlayShown = React.useMemo(
    () =>
      (attributeNames.length === 0 || filteredAttributeNames.length === 0) &&
      !isLoading &&
      !isEmpty,
    [attributeNames.length, filteredAttributeNames.length, isLoading, isEmpty]
  )

  React.useEffect(() => {
    let names: Array<string> = []
    if (attributes.size > 0) {
      names = names.concat(attributes.keySeq().toArray())
    }
    setAttributeNames(names.sort((a, b) => a.localeCompare(b)))
  }, [attributes])

  React.useEffect(() => {
    setFilteredAttributeNames(attributeNames)
  }, [attributeNames])

  return (
    <Box>
      <AttributesBoxHeader>
        <Skeleton w={80} h={22}>
          <HeaderBoxTitle title="Attributes" />
        </Skeleton>
        {attributeNames.length > maxItemsScrollable && (
          <Skeleton w={96} h={14}>
            <FilteredAttributesProvider
              items={attributeNames}
              onChange={setFilteredAttributeNames}
            />
          </Skeleton>
        )}
      </AttributesBoxHeader>

      <BoxBody>
        <Wrapper
          isEmpty={isOverlayShown || isEmpty}
          isLoading={isLoading}
          isOverlayShown={isOverlayShown}
          overlayProps={{
            status: 'empty',
            title: attributes.size > 0 ? 'No matching results' : undefined,
            description:
              attributes.size === 0
                ? 'This Profile doesn’t have any attribute'
                : 'We found no attributes or tag collections matching your search.',
          }}
        >
          <AttributesContainer
            style={{
              minHeight:
                filteredAttributeNames.length > maxItemsScrollable && !isOverlayShown
                  ? 224
                  : 48 * filteredAttributeNames.length,
            }}
          >
            <AttributesTable
              isLoading={isLoading || isEmpty}
              attributes={attributes}
              names={filteredAttributeNames}
            />
          </AttributesContainer>
        </Wrapper>
      </BoxBody>
    </Box>
  )
}
