/**
 * app reducer
 */
import Immutable, { type List, type Set } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'
import languages from 'com.batch.common/languages'

import {
  AgeFactory,
  AppFactory,
  type AppRecord,
  AppStateFactory,
  type AppStateRecord,
  CampaignFactory,
  type CampaignRecord,
  CappingCategoryFactory,
  type CappingCategoryRecord,
  CappingFactory,
  type fetchingState,
  type SafariWebsitesRecord,
  SdkFactory,
  type SdkRecord,
  type State,
} from './_records'
import { currentProjectSelector, optionalCurrentProjectSelector } from './project.selector'

import { buildAgeFromSeconds, get } from '../common/utils'
import {
  PushSettingsFactory,
  type PushSettingsRecord,
} from '../modules/message/models/message.records'
import { FETCH_PROJECT_APPS, FETCH_PROJECT_APPS_SUCCESS } from 'com.batch.redux/action-name'
import { type AppActions, type AppPressureActions } from 'com.batch.redux/app.action'
import { normalizeApp } from 'com.batch.redux/app.api'
import { normalizeUser } from 'com.batch.redux/user.api'

import { FCM_ALERT_DATE } from 'com.batch/settings/constants/fcm-dates'
import { type WindowUser } from '../../types/window'

// ========================================================
// INITIAL STATE
// ========================================================

let defaultLanguage: string = 'en'

if (typeof navigator !== 'undefined') {
  defaultLanguage =
    (typeof navigator.language === 'string' && navigator.language) || navigator.language || 'en'
}
defaultLanguage = defaultLanguage.substring(0, 2)
// eslint-disable-next-line no-prototype-builtins
if (!languages.hasOwnProperty(defaultLanguage)) {
  defaultLanguage = 'en'
}

const initialState: AppStateRecord = AppStateFactory({
  entities: Immutable.List(get(window, 'initialData.apps', []).map(normalizeApp)),
  current: window?.initialData?.app ? normalizeApp(window.initialData.app) : AppFactory(),
  user: normalizeUser(get(window, 'user', {} as WindowUser)),
})

// ========================================================
// SELECTORS
// ========================================================
type extract<T> = (arg1: State) => T
export const currentAppSelector: (state: State) => AppRecord = state => {
  return state.app.current
}

export const currentAppLoadingState: extract<fetchingState> = createSelector(
  currentAppSelector,
  app => app.loadingState
)

export const cappingsSelector: extract<List<CappingCategoryRecord>> = createSelector(
  currentAppSelector,
  app => {
    return app.cappingCategories
  }
)
export const unsortedLabelsSelector: extract<List<CappingCategoryRecord>> = createSelector(
  cappingsSelector,
  categories => {
    return categories.filter(cat => !cat.internal)
  }
)
export const labelsSelector: extract<List<CappingCategoryRecord>> = createSelector(
  unsortedLabelsSelector,
  categories => {
    return categories.sort((a, b) => (a.name > b.name ? 1 : -1))
  }
)
export const appCanUseCluster: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('clusters')
)
export const appCanUseCustomAudiences: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('custom-audience')
)
export const appCanUseEventCountPeriod: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('query-event-count-period')
)

export const appCanUseLanding: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('inapp-messaging')
)

export const appCanUseCustomAudience: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('custom-audience')
)

export const appCanQueryAttribute: extract<boolean> = createSelector(
  currentAppSelector,
  app =>
    (app.platform === 'webpush' && app.features.includes('query-attributes-webpush')) ||
    (app.platform !== 'webpush' && app.features.includes('query-attributes'))
)
export const appCanQueryEventData: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('query-event-data')
)
export const appCanQueryEvent: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('query-events')
)
export const appCanRetarget: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('query-retargeting')
)
export const appCanRetargetInApp: extract<boolean> = createSelector(
  currentAppSelector,
  app => app.features.includes('query-retargeting') && app.features.includes('inapp-campaigns')
)

export const appsSelector: extract<List<AppRecord>> = state => state.app.entities

const userSelector = (state: State) => state.app.user

export const currentProjectAppsSelector: extract<List<AppRecord>> = createSelector(
  optionalCurrentProjectSelector,
  appsSelector,
  (project, apps) => {
    return apps.filter(app => project && app.projectKey === project.projectKey)
  }
)

export const firstAppOfProjectSelector: extract<AppRecord> = createSelector(
  currentProjectAppsSelector,
  apps => {
    return apps.first()
  }
)

export const currentProjectPlatformsSelector: extract<Set<ProjectPlatforms>> = createSelector(
  currentProjectAppsSelector,
  currentProjectSelector,
  (apps, project) => {
    const { pushConfigured, webPushConfigured } = project
    return Immutable.Set(
      apps
        .map(({ platform }) => {
          switch (platform) {
            case 'ios':
            case 'android':
              return pushConfigured ? platform : null
            default:
              return webPushConfigured ? 'webpush' : null
          }
        })
        .filter((platform): platform is ProjectPlatforms => Boolean(platform))
    )
  }
)

export const currentProjectPushDefaultSettingsSelector: extract<PushSettingsRecord> =
  createSelector(currentProjectAppsSelector, apps => {
    const iosApp = apps.find(app => app.platform === 'ios', null, AppFactory())
    const androidApp = apps.find(app => app.platform === 'android', null, AppFactory())

    const defaultTtlInHours: number =
      iosApp?.pushConfig.defaultTtl ?? androidApp?.pushConfig.defaultTtl ?? 0

    const priority =
      (iosApp ?? androidApp)?.getIn(['pushConfig', 'defaultPriority']) === 'NORMAL'
        ? 'PUSH_PRIORITY_NORMAL'
        : 'PUSH_PRIORITY_HIGH'
    let settings = PushSettingsFactory()
    settings = settings
      .set('ttlEnabled', Boolean(defaultTtlInHours))
      .set(
        'ttl',
        defaultTtlInHours
          ? buildAgeFromSeconds(defaultTtlInHours * 60 * 60, ['m', 'h', 'd'], 60, 28 * 24 * 3600)
          : AgeFactory({ unit: 'h', valid: true, seconds: 3600, inputValue: '1' })
      )
      .set('priority', priority)
    if (androidApp) {
      const collapseKey = androidApp.getIn(['pushConfig', 'defaultCollapseKey'])
      if (collapseKey) {
        settings = settings.setIn(['androidOverride', 'collapseKey'], collapseKey)
      }
    }
    return settings
  })

export const restrictedLanguagesSelector: extract<Set<string>> = createSelector(
  userSelector,
  user => user.restrictedLanguages
)
export const restrictedRegionsSelector: extract<Set<string>> = createSelector(
  userSelector,
  user => user.restrictedRegions
)

// circular dep prevents direct import
const campaignTypeSelector = (state: State) => state.campaign.config.type
const hasLandingSelector = (state: State) =>
  state.campaign
    .get('entities', Immutable.Map<string, CampaignRecord>())
    .get(state.campaign.config.editing, CampaignFactory())
    .get('hasLanding')

// returns all app with a valid config for push if push, and excluding win / web if landing / inapp
export const replicableAppsSelector: (state: State) => List<AppRecord> = createSelector(
  appsSelector,
  currentAppSelector,
  hasLandingSelector,
  campaignTypeSelector,
  (apps, app, hasLanding: boolean, type: 'in-app' | 'push' | 'email') => {
    apps = apps.filter(app => {
      if (type === 'push') {
        return (
          app.get('validPushConfig', false) &&
          (['android', 'ios'].indexOf(app.get('platform')) !== -1 || !hasLanding)
        )
      } else {
        return ['android', 'ios'].indexOf(app.get('platform')) !== -1
      }
    })

    return apps.sort((a, b) => (a.id === app.id ? -1 : a.id > b.id ? 1 : -1))
  }
)

export const appCanUseGeoloc: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('geoloc')
)
export const appCanQueryNative: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('query-natives')
)
export const appCanQueryUserData: extract<boolean> = createSelector(currentAppSelector, app =>
  app.features.includes('ingestion')
)
export const sdks: (arg1: State) => List<SdkRecord> = state => state.app.sdks

export const currentAppSdk: extract<SdkRecord> = createSelector(
  currentAppSelector,
  sdks,
  (app, sdks) => {
    const key = app.platform === 'webpush' ? 'Web' : app.sdk
    return sdks.find(s => s.kind === key, SdkFactory()) ?? SdkFactory()
  }
)

export const pickableSdksSelector: extract<List<SdkRecord>> = createSelector(
  currentAppSelector,
  sdks,
  (app, sdks) => sdks.filter(sdk => sdk.allowedPlatforms.includes(app.platform))
)

export const installLabel: extract<string> = createSelector(currentAppSelector, app =>
  app.platform === 'webpush' ? 'Users' : 'Installs'
)
export const currentAppCanPush: extract<boolean> = createSelector(
  currentAppSelector,
  (app: AppRecord) => {
    if (app.validPushConfig) {
      return true
    }
    const cfg = app.pushConfig
    if (cfg.p12) {
      return !!cfg.p12.expireAt && cfg.p12.expireAt.isAfter(dayjs())
    }
    if (cfg.p8) {
      return !!cfg.p8.teamId && !!cfg.p8.keyId && !!cfg.p8.privateKey && !!cfg.p8.topic
    }
    if (cfg.vapid) {
      return !!cfg.vapid.publicKey && !!cfg.vapid.privateKey
    }
    if (cfg.wns) {
      return !!cfg.wns.packageSID && !!cfg.wns.clientSecret
    }
    if (app.platform === 'android') {
      const now = dayjs.utc()
      const fcmDeadline = dayjs.utc(FCM_ALERT_DATE, 'YYYY-MM-DD')
      if (now.isSameOrAfter(fcmDeadline)) {
        return cfg.fcm && cfg.fcm.size > 0
      }
      return (cfg.gcm && cfg.gcm.size > 0) || !!cfg.hms || (cfg.fcm && cfg.fcm.size > 0)
    }
    return false
  }
)

const certificatesExpired = (websites: List<SafariWebsitesRecord>): boolean => {
  const websiteExpired = websites.find(website =>
    dayjs(website.certificateExpirationDate).isBefore(dayjs())
  )
  return Boolean(websiteExpired)
}

export const currentSafariSetupValidSelector: extract<boolean> = createSelector(
  currentAppSelector,
  (app: AppRecord) => {
    const cfg = app.pushConfig
    if (app.safariSetupValid) {
      return true
    }
    if (cfg.safariWebsiteName && cfg.safariWebsiteIcon && cfg.safariWebsites.size > 0) {
      return !certificatesExpired(cfg.safariWebsites)
    }
    return false
  }
)

export const safariIconSelector: extract<string> = createSelector(
  currentAppSelector,
  (app: AppRecord) => {
    return app.pushConfig.safariWebsiteIcon
  }
)

// ========================================================
// ACTIONS
// ========================================================

function pressureReducer(
  state: CappingCategoryRecord = CappingCategoryFactory(),
  action: AppPressureActions
) {
  switch (action.type) {
    case 'REMOVE_PRESSURE': {
      const payload = action.payload
      const index = state.cappings.findIndex(cap => cap === payload.cappingRecord)
      return index === -1
        ? state
        : state.set('dirty', true).set('cappings', state.cappings.delete(index))
    }
    case 'UPDATE_PRESSURE': {
      const payload = action.payload
      const index = state.cappings.findIndex(cap => cap === payload.cappingRecord)
      if (index !== -1) {
        return state.set('dirty', true).set(
          'cappings',
          state.cappings.update(
            index,
            c => c?.set('period', payload.period).set('capping', payload.capping)
          )
        )
      } else {
        return state.set('dirty', true).set(
          'cappings',
          state.cappings.push(
            CappingFactory({
              period: payload.period,
              capping: payload.capping,
            })
          )
        )
      }
    }
    case 'UPDATE_PRESSURE_CATEGORIE':
      return action.payload.updated
    default:
      return state
  }
}

// ========================================================
// REDUCER
// ========================================================
function appReducer(
  state: AppStateRecord = initialState,
  a: AppActions | AppPressureActions
): AppStateRecord {
  const action = a
  switch (action.type) {
    case 'POPIN_INTEGRATE_OPEN':
      return state.set('integratePopin', true)
    case 'POPIN_INTEGRATE_CLOSE':
      return state.set('integratePopin', false)
    case 'POPIN_NEW_OPEN':
      return state.set('newAppBackUrl', action.payload)
    case 'POPIN_NEW_CLOSE':
      return state.set('newAppBackUrl', '')
    case 'CHECK_WNS':
      return state
        .setIn(['current', 'pushConfig', 'loading'], true)
        .setIn(['current', 'pushConfig', 'wns', 'checked'], false)
    case 'SAVE_PUSH_CONFIG':
      return state.setIn(['current', 'pushConfig', 'loading'], true)
    case 'CHECK_WNS_FAILURE':
    case 'SAVE_PUSH_CONFIG_FAILURE':
      return state.setIn(['current', 'pushConfig', 'loading'], false)
    case 'ARCHIVE_APP_FAILURE':
      return state.setIn(['current', 'loadingState'], 'ERROR')
    case 'CHECK_WNS_SUCCESS':
      return state
        .setIn(['current', 'pushConfig', 'loading'], false)
        .setIn(['current', 'pushConfig', 'wns', 'checked'], true)
    case 'DELETE_PRESSURE':
    case 'SAVE_PRESSURE': {
      return state.setIn(['current', 'loadingState'], 'LOADING')
    }
    case 'DELETE_PRESSURE_FAILURE':
    case 'SAVE_PRESSURE_FAILURE': {
      return state.setIn(['current', 'loadingState'], 'ERROR')
    }
    case 'DELETE_PRESSURE_SUCCESS': {
      try {
        const cappings = state.current.cappingCategories
        const ind = cappings.findIndex(cat => cat.id === action.payload.categorieRecord.id)
        return state
          .setIn(['current', 'loadingState'], 'LOADED')
          .deleteIn(['current', 'cappingCategories', ind])
      } catch (err: any) {
        console.log(err)
        return state
      }
    }
    case 'SAVE_PRESSURE_SUCCESS': {
      try {
        const cappings = state.current.cappingCategories
        const ind = cappings.findIndex(cat => cat.id === action.payload.categorieRecord.id)
        if (ind === -1) {
          return state
            .setIn(['current', 'loadingState'], 'LOADED')
            .setIn(
              ['current', 'cappingCategories'],
              state.current.cappingCategories.push(action.payload.categorie)
            )
        }
        return state
          .setIn(['current', 'loadingState'], 'LOADED')
          .setIn(['current', 'cappingCategories', ind], action.payload.categorie)
      } catch (err: any) {
        console.log(err)
        return state
      }
    }
    case 'FETCH_CAMPAIGN_LABELS_COUNTS':
      return state.setIn(['current', 'labelsCountLoadingState'], 'LOADING')
    case 'FETCH_CAMPAIGN_LABELS_COUNTS_FAILURE':
      return state.setIn(['current', 'labelsCountLoadingState'], 'ERROR')
    case 'FETCH_CAMPAIGN_LABELS_COUNTS_FINAL':
      return state
        .setIn(['current', 'cappingCategories'], action.payload)
        .setIn(['current', 'labelsCountLoadingState'], 'LOADED')
    case 'UPDATE_PRESSURE':
    case 'REMOVE_PRESSURE':
    case 'UPDATE_PRESSURE_CATEGORIE': {
      const cappingsPath = ['current', 'cappingCategories']
      const cappings = state.current.cappingCategories
      const index = cappings.findIndex(cat => cat.id === action.payload.categorieRecord.id)
      if (index === -1) {
        return state.setIn(
          cappingsPath,
          cappings.push(pressureReducer(CappingCategoryFactory(), action))
        )
      } else {
        return state.setIn(
          cappingsPath,
          cappings.update(index, capRec => pressureReducer(capRec, action))
        )
      }
    }

    case 'SAVE_PUSH_SETTINGS_SUCCESS':
    case 'SAVE_PUSH_CONFIG_SUCCESS':
      return state.setIn(['current', 'pushConfig'], action.payload)

    case 'SAVE_DEV_ORIGINS':
    case 'DELETE_DEV_ORIGINS':
      return state.setIn(['current', 'pushConfig', 'loadingDevOrigins'], true)

    case 'SAVE_DEV_ORIGINS_SUCCESS':
    case 'DELETE_DEV_ORIGINS_SUCCESS':
      return state.setIn(['current', 'pushConfig'], action.payload)

    case 'SAVE_DEV_ORIGINS_FAILURE':
    case 'DELETE_DEV_ORIGINS_FAILURE':
      return state.setIn(['current', 'pushConfig', 'loadingDevOrigins'], false)

    case 'SAVE_SAFARI_WEBSITE_NAME_SUCCESS':
      return state.set('current', action.payload)

    case 'UPDATE_SAFARI_WEBSITE_SUCCESS': {
      const websites = state.current.pushConfig.safariWebsites
      const index = websites.findIndex(website => website.id === action.payload.id)

      return state.setIn(
        ['current', 'pushConfig', 'safariWebsites'],
        websites.update(index, () => action.payload)
      )
    }

    case 'DELETE_SAFARI_WEBSITE_SUCCESS': {
      const websites = state.current.pushConfig.safariWebsites
      const index = websites.findIndex(website => parseInt(website.id) === action.payload)
      return state.deleteIn(['current', 'pushConfig', 'safariWebsites', index])
    }

    case 'ADD_SAFARI_WEBSITE_SUCCESS': {
      return state.setIn(
        ['current', 'pushConfig', 'safariWebsites'],
        state.current.pushConfig.safariWebsites.push(action.payload)
      )
    }

    case 'SAVE_APP_FAILURE':
      return state.set('current', state.current.set('loadingState', 'LOADED'))

    case 'SAVE_APP':
    case 'ARCHIVE_APP':
    case 'FETCH_APP_BY_ID':
      return state.setIn(['current', 'loadingState'], 'LOADING')

    case 'FETCH_APP_BY_ID_SUCCESS':
      return state.set('current', action.payload.app.set('loadingState', 'LOADED'))

    case 'FETCH_APP_BY_ID_FAILURE':
      return state.setIn(['current', 'loadingState'], 'ERROR')

    case 'FETCH_SDKS_SUCCESS':
      return state.set('sdks', action.payload)

    case 'FETCH_APPS':
    case FETCH_PROJECT_APPS:
      return state.set('loading', true)
    case FETCH_PROJECT_APPS_SUCCESS:
      action.payload.forEach(app => {
        const index = state.entities.findIndex(a => a.id === app.id)
        if (index !== -1) state = state.set('entities', state.entities.set(index, app))
      })
      return state.set('loaded', true).set('loading', false)
    case 'FETCH_APPS_SUCCESS':
      return state.set('entities', action.payload).set('loaded', true).set('loading', false)

    case 'SAVE_APP_SUCCESS':
      return state.set('current', action.payload.app)

    case 'SET_WEBPUSH_ICONS_SUCCESS':
      return state
        .setIn(['current', 'pushConfig', 'safariWebsiteIcon'], action.payload.safariWebsiteIconUrl)
        .setIn(['current', 'pushConfig', 'androidSmallIcon'], action.payload.androidSmallIconUrl)

    default:
      return state
  }
}

export default appReducer
