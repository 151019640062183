import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router/router'

import { generateProjectKeyBlockRequest } from './generate-block-request'

import { parseEstimateProfile } from 'com.batch/orchestration/infra/parses/estimate-profile.parse'
import { type ProfileEstimateResponse } from 'com.batch/orchestration/infra/types/estimate.types'
import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'

export type ContentToTemplate = {
  messageId: string
  lang: string
  parent?: string
  field: string
  value: string
  type: 'HTML_TEMPLATE' | 'TEMPLATE'
}
export type TemplatedContent = {
  messageId: string
  lang: string
  parent?: string
  field: string
  template: string
  value: string
}

export type OurSqlService = {
  fetchProfileEstimateFor(arg1: {
    projectKey: string
    query: string
    languages?: Array<string>
    regions?: Array<string>
  }): Promise<EstimateProfileRecord>
  fetchRenderedContent(arg1: {
    contents: Array<ContentToTemplate>
    abortSignal?: AbortSignal
  }): Promise<Array<TemplatedContent>>
  fetchTemplatedContent(arg1: {
    projectKey: string
    profileId: string
    contents: Array<ContentToTemplate>
    abortSignal?: AbortSignal
  }): Promise<Array<TemplatedContent>>
}

export const ourSqlService: OurSqlService = {
  fetchProfileEstimateFor: async ({
    projectKey,
    query,
    languages = [] as Array<string>,
    regions = [] as Array<string>,
  }) => {
    const response = await request.post<{
      response: ProfileEstimateResponse
    }>(
      generateUrl('api_grpc_oursql_service', {
        methodName: 'EstimateOC',
      }),
      {
        ...generateProjectKeyBlockRequest(projectKey),
        query,
        languages,
        regions,
      }
    )
    return parseEstimateProfile(response.response)
  },
  fetchRenderedContent: async ({ contents, abortSignal }) => {
    const response = await request.post<{
      contentSetResults: Array<{
        index: number
        contents: Array<{ content: string; dynamic: boolean }>
      }>
    }>(
      generateUrl('api_grpc_oursql_service', { methodName: 'RenderContent' }),
      {
        contents: [
          {
            elements: [
              {
                contents: contents.map(({ type, value }) => ({ type, value })),
              },
            ],
          },
        ],
      },
      abortSignal
    )
    const responseContents = response?.contentSetResults[0].contents ?? []
    return responseContents.map(({ content: template }, index) => {
      const { messageId, lang, parent, field, value } = contents[index]
      return {
        messageId,
        lang,
        parent,
        field,
        value,
        template,
      }
    })
  },
  fetchTemplatedContent: async ({ projectKey, profileId, contents, abortSignal }) => {
    // J'any ici car le type de la réponse dépend de la requete sur la condition profileId !== ''
    const response = await request.post<any>(
      generateUrl('api_grpc_oursql_service', {
        methodName: profileId !== '' ? 'FindProfile' : 'RenderContent',
      }),
      {
        base: {
          projectKey: {
            textual: {
              text: projectKey,
            },
          },
        },
        profileID: {
          data: profileId,
        },
        dataRequest: {
          contents: [
            {
              elements: [
                {
                  contents: contents.map(({ type, value }) => ({ type, value })),
                },
              ],
            },
          ],
        },
      },
      abortSignal
    )
    const responseContents = response?.dataResponse?.contentSetResults[0].contents ?? []
    return responseContents.map(({ content: template }, index) => {
      const { messageId, lang, parent, field, value } = contents[index]
      return {
        messageId,
        lang,
        parent,
        field,
        value,
        template,
      }
    })
  },
}
