// ====================== CONFIG
import { scaleBand, scaleLinear } from 'd3-scale'
import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { useResizeObserver } from 'components/_hooks'
import { Wrapper } from 'components/common/empty-states'
import * as colors from 'components/styled/colors'

import { numberFormat, percentage } from 'com.batch.common/utils'

import { Bar } from './bar'
import { BarChartDefs } from './defs'
import { ChartTtip, ChartTtipElement, ChartTtipTitle, ChartTtipValue } from './styles'
import { Timeline } from './timeline'
import { YAxis } from './yaxis'

import { DataSetFactory, DataPointFactory, type DataSetRecord } from '../chart-helper'

const MARGIN_RIGHT = 60
const MARGIN_LEFT = 10
const MARGIN_TOP = 20
const NB_TICKS = 10
const TL_HEIGHT = 34
const DOMAINFORMAT = 'YYYYMMDDHH'

const docLinks: {
  [key: string]: [string, string]
} = {
  dau: ['https://doc.batch.com/dashboard/analytics/overview.html#_daus', 'Learn more about DAUs'],
  installs: [
    'https://doc.batch.com/dashboard/analytics/overview.html#_installs',
    'Learn more about Installs',
  ],
  uninstalls: [
    'https://doc.batch.com/dashboard/analytics/overview.html#_uninstalls',
    'Learn more about Uninstalls',
  ],
  starts: [
    'https://doc.batch.com/dashboard/analytics/overview.html#_starts',
    'Learn more about Starts',
  ],
  push: ['https://doc.batch.com/dashboard/analytics/overview.html#_push', 'Learn more about Pushs'],
  revenue: [
    'https://doc.batch.com/dashboard/analytics/overview.html#_revenue',
    'Learn more about Revenue',
  ],
  rollingmaus: [
    'https://doc.batch.com/dashboard/analytics/overview.html#_mau',
    'Learn more about Rolling MAUs',
  ],
}

type BarChartProps = {
  clamp?: boolean
  fadeOnOver?: boolean
  hasSeparator?: boolean
  height: number
  label?: string
  loading?: boolean
  overIndex: number | null
  setOverIndex: (arg1: null | number) => void
  sets: List<DataSetRecord>
  timelineMode: 'none' | 'top' | 'bottom'
}

const BarChartBase = ({
  clamp,
  fadeOnOver,
  height,
  label,
  loading,
  overIndex,
  setOverIndex,
  sets,
  timelineMode,
  hasSeparator,
}: BarChartProps): React.ReactElement => {
  const [ref, width] = useResizeObserver()
  const [fixWidth, setFixWidth] = React.useState(0)

  const callback = React.useCallback(() => {
    setFixWidth(fixWidth => fixWidth + 1)
  }, [])

  // hack because useResizeObserver does not always trigger a render, dunno why
  React.useEffect(() => {
    if (width === 1) {
      const t = window.setTimeout(callback, 1)
      return () => window.clearTimeout(t)
    }
  }, [fixWidth, width, callback])

  // ================= DERIVED FROM LOCAL STATE
  const fullHeight = React.useMemo(
    () => height + MARGIN_TOP + (timelineMode !== 'none' ? TL_HEIGHT : 0),
    [height, timelineMode]
  )

  const xScale = React.useMemo(() => {
    return scaleBand()
      .domain(
        sets
          .get(0, DataSetFactory())
          .data.map(v => v.date.format(DOMAINFORMAT))
          .toArray()
          .sort((a, b) => (a < b ? -1 : 1))
      )
      .rangeRound([MARGIN_LEFT, width - MARGIN_RIGHT])
      .paddingInner(0.35)
      .paddingOuter(0)
  }, [sets, width])

  const pad = React.useMemo(() => xScale.bandwidth() * 0.35, [xScale])

  const absoluteMin = React.useMemo(() => {
    let absoluteMin = Infinity
    sets.get(0, DataSetFactory()).data.map((point, k) => {
      const pointTotal = sets.reduce((acc, current) => {
        return acc + current.data.get(k, DataPointFactory()).value
      }, 0)
      if (pointTotal < absoluteMin) absoluteMin = pointTotal
    })
    return clamp ? absoluteMin * 0.9 : 0
  }, [clamp, sets])

  const absoluteMax = React.useMemo(() => {
    let absoluteMax = -Infinity
    sets.get(0, DataSetFactory()).data.map((point, k) => {
      const pointTotal = sets.reduce((acc, current) => {
        return acc + current.data.get(k, DataPointFactory()).value
      }, 0)
      if (pointTotal > absoluteMax) absoluteMax = pointTotal
    })
    return absoluteMax
  }, [sets])

  const granularity = React.useMemo(() => {
    const firstElem = sets.get(0, DataSetFactory()).data.get(0, DataPointFactory())
    const secondElem = sets.get(0, DataSetFactory()).data.get(1, DataPointFactory())
    const diff = secondElem.date.unix() - firstElem.date.unix()
    return diff < 6000 ? 'hour' : 'day'
  }, [sets])

  const yScale = React.useMemo(
    () =>
      scaleLinear()
        .range([
          height + (timelineMode === 'top' ? TL_HEIGHT + MARGIN_TOP : MARGIN_TOP),
          0 + (timelineMode === 'top' ? TL_HEIGHT + MARGIN_TOP : MARGIN_TOP),
        ])
        .domain([absoluteMin, absoluteMax]),
    [absoluteMax, absoluteMin, height, timelineMode]
  )
  const yTicksValues = React.useMemo(() => {
    const tv: Array<number> = []
    if (absoluteMin !== 0 || absoluteMax !== 0) {
      tv.push(absoluteMin)
      if (height > 55) {
        tv.push(Math.floor((absoluteMax - absoluteMin) / 2 + absoluteMin))
      }
      tv.push(absoluteMax)
    }
    return Immutable.Set(tv).toList()
  }, [absoluteMax, absoluteMin, height])

  const noSets = React.useMemo(() => sets.size === 0, [sets.size])
  const notEnoughData = React.useMemo(() => sets.get(0, DataSetFactory()).data.size < 3, [sets])
  const tooMuchData = React.useMemo(() => sets.get(0, DataSetFactory()).data.size > 600, [sets])
  const total = React.useMemo(() => sets.reduce((sum, set) => sum + set.total, 0), [sets])
  const noData = React.useMemo(
    () => noSets || notEnoughData || tooMuchData || total === 0,
    [noSets, notEnoughData, tooMuchData, total]
  )
  const hasSkeletonScreen = React.useMemo(() => loading || noData, [loading, noData])

  const handleOnMouseMove = React.useCallback(
    (evt: React.MouseEvent<SVGPathElement, MouseEvent>) => {
      const o = parseInt((evt.target as SVGPathElement).getAttribute('data-index') || '0', 10)
      if (overIndex !== o) setOverIndex(o)
    },
    [overIndex, setOverIndex]
  )
  return (
    <div
      ref={ref}
      style={{
        height: hasSkeletonScreen ? fullHeight + 1 : fullHeight,
        position: 'relative',
        lineHeight: 0,
        borderBottom: hasSeparator ? `1px solid ${colors.stroke.lighter}` : '1px solid transparent',
      }}
    >
      <Wrapper
        isLoading={!!loading}
        isEmpty={noData}
        isOverlayShown={noData && !loading}
        overlayProps={{
          status: 'empty',
          title: notEnoughData
            ? 'Not enough data to display'
            : tooMuchData
              ? 'Too much data to display'
              : 'No data for the selected period',
          links: [
            {
              name: docLinks[label ?? '']?.[1] ?? '',
              href: docLinks[label ?? '']?.[0] ?? '',
              isDocLink: true,
            },
          ],
        }}
      >
        {!hasSkeletonScreen && overIndex !== null && (
          <ChartTtip
            style={{
              top: timelineMode !== 'none' ? (timelineMode === 'top' ? 55 : 10) : 20,
              left:
                (xScale(
                  sets
                    .get(0, DataSetFactory())
                    .data.get(overIndex, DataPointFactory())
                    .date.format(DOMAINFORMAT)
                ) ?? 0) +
                xScale.bandwidth() / 2 +
                (overIndex > sets.get(0, DataSetFactory()).data.size / 2 ? -160 : 20),
            }}
          >
            {sets.map((set, k) => {
              const point = set.data.get(overIndex, DataPointFactory())
              return (
                <ChartTtipElement key={k}>
                  <ChartTtipTitle color={set.color}>
                    <span>{set.label}</span>
                    <svg>
                      <circle cx="5" cy="5" r="5" />
                    </svg>
                  </ChartTtipTitle>
                  <ChartTtipValue>
                    {point.value > 0 && point.value < 1
                      ? percentage(point.value)
                      : numberFormat(point.value)}
                  </ChartTtipValue>
                </ChartTtipElement>
              )
            })}
          </ChartTtip>
        )}
        <svg width={width} height={fullHeight}>
          <BarChartDefs sets={sets} />
          <YAxis
            values={yTicksValues}
            yScale={yScale}
            width={width}
            marginRight={MARGIN_RIGHT}
            loading={hasSkeletonScreen}
          />
          <Bar
            fadeAllBut={!fadeOnOver ? null : overIndex}
            sets={sets}
            xScale={xScale}
            yScale={yScale}
            loading={hasSkeletonScreen}
          />
          {!hasSkeletonScreen && overIndex !== null && !fadeOnOver && (
            <line
              strokeDasharray="10,10"
              stroke="#000"
              style={{ opacity: 0.3, pointerEvents: 'none', transition: 'all 0.2s ease-out' }}
              x1={
                (xScale(
                  sets
                    .get(0, DataSetFactory())
                    .data.get(overIndex ?? 0, DataPointFactory())
                    .date.format(DOMAINFORMAT)
                ) ?? 0) +
                xScale.bandwidth() / 2
              }
              x2={
                (xScale(
                  sets
                    .get(0, DataSetFactory())
                    .data.get(overIndex ?? 0, DataPointFactory())
                    .date.format(DOMAINFORMAT)
                ) ?? 0) +
                xScale.bandwidth() / 2
              }
              y1={0}
              y2={fullHeight}
            />
          )}
          {timelineMode !== 'none' && (
            <Timeline
              nbTicks={NB_TICKS}
              dateFormat={granularity === 'hour' ? 'DD/MM HH[H]' : 'DD/MM'}
              height={TL_HEIGHT}
              width={width}
              overIndex={overIndex}
              sets={sets}
              translateY={timelineMode === 'bottom' ? height + 21 : 0}
              xScale={xScale}
              loading={hasSkeletonScreen}
              position={timelineMode}
            />
          )}
          <g style={{ fill: 'transparent' }} onMouseMove={handleOnMouseMove}>
            {sets.map(set =>
              set.data.map((point, k) => (
                <rect
                  key={k}
                  x={(xScale(point.date.format(DOMAINFORMAT)) ?? 0) - pad}
                  y={0}
                  data-index={k}
                  width={xScale.bandwidth() + 2 * pad}
                  height={fullHeight}
                />
              ))
            )}
          </g>
        </svg>
      </Wrapper>
    </div>
  )
}
export const BarChart: React.ComponentType<BarChartProps> = React.memo<BarChartProps>(BarChartBase)
BarChart.displayName = 'BarChart'
