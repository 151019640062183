import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import {
  Box,
  BoxHeader,
  HeaderBoxTitle,
  HeaderBoxActions,
  BoxBody,
  BoxFooter,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper, Input } from 'components/form'

import { type CappingCategoryRecord } from 'com.batch.redux/_records'
import { saveCappingCategory } from 'com.batch.redux/app.action'

type LabelPopinProps = {
  close: () => void
  category: CappingCategoryRecord
}

const formattedCode = (code: string): string => code.toUpperCase().replace(/[^A-Z0-9]/g, '')

export const LabelPopin = ({ close, category }: LabelPopinProps): React.ReactElement => {
  const dispatch = useDispatch()
  const [localCategory, updateLocalCategory] = React.useState<CappingCategoryRecord>(category)
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())
  const onNameChange = React.useCallback(
    evt => updateLocalCategory(localCategory.set('name', evt.target.value)),
    [localCategory]
  )
  const onCodeChange = React.useCallback(
    evt => updateLocalCategory(localCategory.set('code', formattedCode(evt.target.value))),
    [localCategory]
  )
  const createLabel = React.useCallback(
    (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault()
      const form = e.target as HTMLFormElement
      form.reset()

      const err: [string, string][] = []

      if (localCategory.name.length < 2)
        err.push(['createName', 'Label name must be at least 2 chars.'])

      if (localCategory.code.length > 0 && localCategory.code.length < 2)
        err.push(['createCode', 'Code name must be at least 2 chars.'])

      if (Immutable.Map(err).size === 0) {
        setErrors(Immutable.Map())
        dispatch(saveCappingCategory(localCategory, 'label'))
        close()
      } else {
        setErrors(Immutable.Map(err))
      }
    },
    [close, dispatch, localCategory]
  )

  return (
    <Box as="form" onSubmit={createLabel}>
      <BoxHeader>
        <HeaderBoxTitle title="New label" />
        <HeaderBoxActions>
          <Button kind="inline" onClick={close} type="button">
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>

      <BoxBody $padding>
        <InputWrapper label="Label name" htmlFor="label-name">
          <Input
            id="label-name"
            value={localCategory.name}
            invalid={errors.has('createName')}
            invalidMessage={errors.get('createName', '')}
            placeholder="Add label name..."
            onChange={onNameChange}
          />
        </InputWrapper>

        <InputWrapper label="Label code" optional htmlFor="label-code">
          <Input
            id="label-code"
            value={localCategory.code}
            invalid={errors.has('createCode')}
            invalidMessage={errors.get('createCode', '')}
            placeholder="Add label code..."
            onChange={onCodeChange}
          />
        </InputWrapper>
      </BoxBody>

      <BoxFooter isEditable>
        <Button kind="inline" intent="neutral" onClick={close} type="button">
          Cancel
        </Button>
        <Button type="submit" kind="primary" intent="action" disabled={!localCategory.name}>
          Add label
        </Button>
      </BoxFooter>
    </Box>
  )
}
