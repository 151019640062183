import { type List } from 'immutable'
import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'com.batch.common/react-redux'

import { PermissionButton } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { showToast } from 'com.batch.redux/toaster'

import { type FCMConfigRecord } from 'com.batch/settings/models/fcm-config.records'
import {
  Dropzone,
  DropzoneContainer,
  DropzoneTitle,
} from 'com.batch/settings/ui/components/settings-fcm/service-account-key-dropzone/service-account-key-dropzone.styles'
import { getFCMConfig } from 'com.batch/settings/usecases/get-fcm-config'

type Props = {
  addNewConfig: (config: FCMConfigRecord) => void
  isDisabled: boolean
  onLoading: (isLoading: boolean) => void
  configs: List<FCMConfigRecord>
}

export const ServiceAccountKeyDropzone = ({
  addNewConfig,
  isDisabled,
  onLoading,
  configs,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const [error, setError] = React.useState<string | null | undefined>(null)
  const [isLoading, setIsLoading] = React.useState<any>()

  const onDrop = React.useCallback(
    (acceptedFiles: Array<File>) => {
      setIsLoading(true)
      const file = acceptedFiles[0]
      if (!file) {
        setIsLoading(false)
        onLoading(false)
        setError('No file selected')
        return
      }
      dispatch(getFCMConfig(file, configs))
        .then(fcmConfig => {
          addNewConfig(fcmConfig)
          setError(null)
        })
        .catch((err: { error: string }) => {
          setError(err.error)
          dispatch(showToast({ kind: 'error', message: err.error }))
        })
        .finally(() => {
          setIsLoading(false)
          onLoading(false)
        })
    },
    [addNewConfig, configs, dispatch, onLoading]
  )

  const onDragEnter = React.useCallback(() => {
    setError(null)
  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDropAccepted: onDrop,
    onDragEnter,
    multiple: false,
    disabled: isLoading || isDisabled,
    accept: {
      'application/json': ['.json'],
    },
  })

  React.useEffect(() => {
    if (isDragReject) {
      setError('Not a valid file type')
    }
  }, [isDragReject])

  return (
    <DropzoneContainer>
      <DropzoneTitle>Service Account Key</DropzoneTitle>
      <Dropzone {...getRootProps()} hasError={error} isActive={isDragActive} isLoading={isLoading}>
        <input {...getInputProps()} />
        {isLoading && !isDisabled ? (
          <Icon icon="spinner" />
        ) : isDragActive && !isDisabled ? (
          <p>Release file to start the upload</p>
        ) : (
          <React.Fragment>
            <p>Drag & drop your .json here or</p>
            <PermissionButton
              intent={error ? 'neutral' : 'action'}
              kind="inline"
              addOn="prefix"
              isAllowed={true}
            >
              <Icon icon="upload" />
              Upload .json file
            </PermissionButton>
          </React.Fragment>
        )}
      </Dropzone>
      {error && <p className="error">{error}</p>}
    </DropzoneContainer>
  )
}
