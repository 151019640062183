import Immutable, { type RecordOf } from 'immutable'

export type ClickLinkProps = {
  name: string
  count: number
  unique: number
  rate: number
}

export type ClickLinkRecord = RecordOf<ClickLinkProps>
export const ClickLinkFactory = Immutable.Record<ClickLinkProps>({
  name: '',
  count: 0,
  rate: 0,
  unique: 0,
})
