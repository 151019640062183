import * as React from 'react'

type ArianeProps = {
  steps: Array<string>
  activeStep?: string | null | undefined
}

export const Ariane = ({ steps, activeStep }: ArianeProps): React.ReactElement => (
  <ul className="pb">
    {steps.map(step => (
      <li className={`pb__step ${step === activeStep ? 'pb__step--active' : ''}`} key={step}>
        <a href={`#${step}`} className="pb__step__label">
          {step}
        </a>
      </li>
    ))}
  </ul>
)
