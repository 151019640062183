import * as React from 'react'
import styled from 'styled-components'

import { useClickOutside, useToggle } from 'components/_hooks'
import { Avatar } from 'components/common/avatar'
import { Icon } from 'components/common/svg-icon'
import { schemes, colors } from 'components/styled/tokens'

import { SidebarAccount } from './sidebar-account'
import {
  PADDING,
  INNER_PADDING,
  USER_HEIGHT,
  SIDEBAR_WIDTH,
  SidebarButtonStyles,
} from './sidebar.styles'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { type UserRecord } from 'com.batch.redux/user.records'

type SidebarAccountDropdownProps = {
  user: UserRecord
  project: ProjectRecord | null | undefined
  company: CompanyRecord
}

export const SidebarAccountDropdown = (props: SidebarAccountDropdownProps): React.ReactElement => {
  const { user, company, project } = props
  const openState = useToggle()
  const [$dropdown] = useClickOutside(openState.close)

  return (
    <Container opened={openState.value}>
      <Label
        onClick={openState.open}
        title={`${user.firstName} ${user.lastName} (${company.name})`}
      >
        <Avatar url={user.avatarUrl || ''} placeholder={user.firstName} size={36}>
          <Avatar url={company.avatarUrl} placeholder={company.name} />
        </Avatar>
        <Infos>
          <strong>
            {user.firstName} {user.lastName}
          </strong>
          <span>{company.name}</span>
        </Infos>
        <Icon icon="select" color={colors.textLight} />
      </Label>
      <Dropdown ref={$dropdown}>
        <DropdownClose onClick={openState.close}>
          <Icon icon="close" />
        </DropdownClose>
        <SidebarAccount user={user} company={company} project={project} />
      </Dropdown>
    </Container>
  )
}

export const DropdownClose = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 34px;
  padding: 0 0 0 12px;
  mask: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='34' viewBox='0 0 44 34'%3E%3Cpath fillRule='evenodd' d='M24.825 0H44v34H0v-1h.123c1.912 0 3.48-.371 4.784-1.069a7.27 7.27 0 0 0 3.024-3.024C8.63 27.604 9 26.035 9 24.123v-8.298c0-3.694.65-6.381 1.87-8.663a12.721 12.721 0 0 1 5.292-5.292C18.444.65 21.131 0 24.825 0z'/%3E%3C/svg%3E%0A");
  color: ${colors.textLight};
  background: #dadbdb;
  transition:
    color 0.2s ease,
    background 0.2s ease;
  cursor: pointer;

  &:hover {
    color: ${colors.text};
    background: #cecfcf;
  }
`

export const Dropdown = styled.div`
  position: relative;
  padding: 4px 0 24px 0;
  background: ${colors.fill};
  border-top: 1px solid ${schemes.darklucent['20']};
  box-shadow: 0 -1px 8px ${schemes.darklucent['02']};

  ${DropdownClose} {
    position: absolute;
    top: -34px;
    right: 0;
  }
`

export const Infos = styled.div`
  strong,
  span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  strong {
    margin-bottom: 1px;
    font-weight: 500;
    color: ${colors.text};
  }
  span {
    color: ${colors.textLight};
  }
`

export const Label = styled.a`
  height: ${USER_HEIGHT}px;
  padding: ${PADDING + 2}px ${PADDING + INNER_PADDING - 2}px ${PADDING - 2}px
    ${PADDING + INNER_PADDING - 2}px;
  background: ${schemes.grayscale['10']};
  ${SidebarButtonStyles}

  ${Infos} {
    flex: 1 1 auto;
    overflow: hidden;
    margin: 0 18px 0 14px;
  }
`
type ContainerProps = {
  opened: boolean
}
export const Container = styled.div<ContainerProps>`
  position: relative;

  ${Label} {
    position: relative;
    z-index: 21;
    opacity: ${p => (p.opened ? 0 : 1)};
    pointer-events: ${p => (p.opened ? 'none' : 'all')};
    transition: opacity 0.1s ease;
  }
  ${Dropdown} {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    transform: translate3d(0, ${p => (p.opened ? `calc(-100% + ${USER_HEIGHT}px)` : 0)}, 0);
    opacity: ${p => (p.opened ? 1 : 0)};
    transition:
      transform 0.2s ease,
      opacity 0.1s ease;
  }

  &:after {
    display: block;
    position: fixed;
    z-index: 19;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${SIDEBAR_WIDTH}px;
    content: '';
    background: ${schemes.darklucent['02']};
    opacity: ${p => (p.opened ? 1 : 0)};
    pointer-events: ${p => (p.opened ? 'all' : 'none')};
  }
`

export default SidebarAccountDropdown
