import dayjs from 'dayjs'
import * as React from 'react'
import { useTheme } from 'styled-components'

import {
  PreviewMockupContainer,
  DateContainer,
  DateFull,
  DateMain,
  DateSub,
  PreviewMockupBackground,
  StatusIcons,
} from './mockup.styles'

export const PreviewMockup = (): React.ReactElement => {
  // Date...
  const dateDay = dayjs(new Date()).format('dddd, MMMM DD')
  const dateHour = dayjs(new Date()).format('HH:mm')
  const dateFull = `${dateDay} at ${dateHour}`

  const { previewPlatform } = useTheme()

  return (
    <PreviewMockupBackground>
      <PreviewMockupContainer>
        <DateContainer>
          <DateMain>{dateDay}</DateMain>
          <DateSub>{dateHour}</DateSub>
          <DateFull>{dateFull}</DateFull>
        </DateContainer>

        <StatusIcons>
          {previewPlatform === 'android' ? (
            <img
              src="/medias/img/builders/icons/status-android.svg"
              width="46"
              height="46"
              alt="mockup-icon-android"
            />
          ) : (
            <React.Fragment>
              <img
                src="/medias/img/builders/icons/status-gsm.svg"
                width="19.2"
                height="12.3"
                alt="mockup-icon-gsm"
              />
              <img
                src="/medias/img/builders/icons/status-wifi.svg"
                width="17.14"
                height="12.3"
                alt="mockup-icon-wifi"
              />
              <img
                src="/medias/img/builders/icons/status-battery.svg"
                width="27.33"
                height="13"
                alt="mockup-icon-battery"
              />
            </React.Fragment>
          )}
        </StatusIcons>
      </PreviewMockupContainer>
    </PreviewMockupBackground>
  )
}
