import Immutable, { type RecordOf, type Set } from 'immutable'

import * as Types from './query.types'
import { type OursQLType } from './query.types'

type FunctionProps = {
  label: string
  value: string
  accept: Set<OursQLType>
  allowedContext: Set<'targeting' | 'event_filter'>
  produce: OursQLType
}
const FunctionFactory = Immutable.Record<FunctionProps>({
  label: '',
  value: '',
  accept: Immutable.Set(),
  allowedContext: Immutable.Set(['targeting', 'event_filter']),
  produce: Types.STRING,
} as FunctionProps)

export type FunctionRecord = RecordOf<FunctionProps>

export const AgeFunction: FunctionRecord = FunctionFactory({
  label: 'Time',
  value: 'age',
  accept: Immutable.Set([Types.DATE, Types.EVENT]),
  allowedContext: Immutable.Set(['targeting', 'event_filter']),
  produce: Types.AGE,
})

export const DateFunction: FunctionRecord = FunctionFactory({
  label: 'Date',
  value: 'date',
  accept: Immutable.Set([Types.DATE]),
  allowedContext: Immutable.Set(['targeting', 'event_filter']),
  produce: Types.DATE,
})

export const NextBirthdayFunction: FunctionRecord = FunctionFactory({
  label: 'Next anniversary',
  value: 'nextBirthday',
  accept: Immutable.Set([Types.DATE]),
  allowedContext: Immutable.Set(['targeting']),
  produce: Types.AGE,
})

export const LastBirthdayFunction: FunctionRecord = FunctionFactory({
  label: 'Last anniversary',
  value: 'lastBirthday',
  accept: Immutable.Set([Types.DATE]),
  allowedContext: Immutable.Set(['targeting']),
  produce: Types.AGE,
})

export const IsNearFunction: FunctionRecord = FunctionFactory({
  label: 'Is near',
  value: 'isNear',
  accept: Immutable.Set([Types.POSITION]),
  allowedContext: Immutable.Set(['targeting', 'event_filter']),
  produce: Types.BOOLEAN,
})

export const CountFunction: FunctionRecord = FunctionFactory({
  label: 'count',
  value: 'count',
  accept: Immutable.Set([Types.TAG, Types.EVENT]),
  allowedContext: Immutable.Set(['targeting', 'event_filter']),
  produce: Types.INTEGER,
})

export const CountSinceFunction: FunctionRecord = FunctionFactory({
  label: 'count since',
  value: 'countSince',
  accept: Immutable.Set([Types.EVENT]),
  allowedContext: Immutable.Set(['targeting', 'event_filter']),
  produce: Types.INTEGER,
})
