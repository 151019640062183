import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useTheme } from 'styled-components'

import { Button } from 'components/common/button'
import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Feedback, Select } from 'components/form'
import { colors } from 'components/styled/tokens'
import { HorizontalDivider } from 'components/styled/utils'

import {
  getContentHasDeletedSenderIdentitySelector,
  getContentHasUpdatedSenderIdentitySelector,
  getEmailContentForActiveLanguageSelector,
} from 'com.batch/email/store/email-automation.selector'
import { previewLanguageSelector } from 'com.batch/message/store/message.selector'
import { inlineEditorConfigSelector } from 'com.batch/message-builder/store/inline-editor.selector'
import { type SenderIdentityRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'
import {
  senderIdentitiesSelector,
  senderIdentityLoadingStateSelector,
} from 'com.batch.redux/corelogic/selectors/sender-identity.selector'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import { MessageTemplateWizard } from 'com.batch/email/message-template-wizard'
import { updateEmailSender } from 'com.batch/email/usecases/update-content'
import { EditableField } from 'com.batch/message-builder/ui/components/editable-field'
import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'
import { SenderIdentityOptionFormatter } from 'com.batch/orchestration-journey/ui/components/canvas/canvas.helper'
import { type TransmissionType } from 'com.batch/shared/models/transmission-type'
import { useLeaveConfirmation } from 'com.batch/shared/ui/hooks/use-leave-confirmation'
import { STATUS } from 'constants/common'

const identityToString = (opt?: SenderIdentityRecord | null) => {
  return opt ? `${opt.sendingName}${opt.sendingPrefix}@${opt.sendingDomain}` : ''
}

type Props = {
  messageId: string
}
export const EmailBuilderHeader = ({ messageId = 'default' }: Props): React.ReactElement => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const getContent = useSelector(getEmailContentForActiveLanguageSelector)
  const content = React.useMemo(() => getContent(messageId), [getContent, messageId])
  const lang = useSelector(previewLanguageSelector)
  const editorConfig = useSelector(inlineEditorConfigSelector)
  const isReplyToVisible = React.useMemo(
    () => editorConfig.field === 'replyTo' || !!content.replyTo,
    [editorConfig, content]
  )
  const senderIdentities = useSelector(senderIdentitiesSelector)
  const targetedUserbase = useSelector(subscriptionStatusSelector)
  const requiredTransmissionType: TransmissionType = React.useMemo(
    () => (targetedUserbase === 'marketing' ? 'MARKETING' : 'TRANSACTIONAL'),
    [targetedUserbase]
  )
  const filteredSenderIdentities = React.useMemo(
    () =>
      senderIdentities.filter(
        si =>
          si.transmissionTypes.includes(requiredTransmissionType) || si.transmissionTypes.size === 0
      ),
    [requiredTransmissionType, senderIdentities]
  )
  const getCurrentSenderIdentityHasBeenUpdated = useSelector(
    getContentHasUpdatedSenderIdentitySelector
  )

  const currentSenderIdentityHasBeenUpdated = React.useMemo(
    () => getCurrentSenderIdentityHasBeenUpdated(messageId),
    [getCurrentSenderIdentityHasBeenUpdated, messageId]
  )
  const getCurrentSenderIdentityHasBeenDeleted = useSelector(
    getContentHasDeletedSenderIdentitySelector
  )
  const currentSenderIdentityHasBeenDeleted = React.useMemo(
    () => getCurrentSenderIdentityHasBeenDeleted(messageId),
    [getCurrentSenderIdentityHasBeenDeleted, messageId]
  )
  const senderIdentityLoadingState = useSelector(senderIdentityLoadingStateSelector)
  const isPristine = React.useMemo(() => content?.isPristine ?? true, [content?.isPristine])
  useLeaveConfirmation({ isPristine })

  const onSenderIdentityChange = React.useCallback(
    (identity?: SenderIdentityRecord | null) => {
      if (identity) {
        dispatch(
          updateEmailSender({
            messageId,
            lang,
            name: identity.sendingName,
            senderIdentityId: identity.id,
            fromEmail: `${identity.sendingPrefix}@${identity.sendingDomain}`,
          })
        )
      }
    },
    [dispatch, messageId, lang]
  )

  const formattedSenderIdentity = React.useMemo(
    () =>
      content?.fromEmail && content?.name ? (
        <p>
          <span style={{ fontWeight: 500 }}>{content?.name}</span> (
          <span style={{ whiteSpace: 'nowrap' }}>{content.fromEmail}</span>)
        </p>
      ) : null,
    [content]
  )

  const senderInputFeedback: {
    label?: string
    type?: 'success' | 'error' | 'loading' | 'insight' | 'warning'
  } = React.useMemo(() => {
    const current = `${content?.name ?? ''}<${content?.fromEmail ?? ''}>`
    if (senderIdentityLoadingState === STATUS.LOADING) {
      return { label: 'Loading sender identities...', type: 'insight' }
    }
    if (senderIdentityLoadingState === STATUS.ERROR) {
      return { label: 'Unable to load sender identities', type: 'error' }
    }
    if (currentSenderIdentityHasBeenUpdated) {
      return {
        label: `Selected sender has been modified (${current}). Choose again.`,
        type: 'warning',
      }
    }
    if (currentSenderIdentityHasBeenDeleted) {
      return {
        label: `Selected sender has been deleted (${current}). Choose another one.`,
        type: 'error',
      }
    }
    return { label: undefined, type: undefined }
  }, [
    content?.name,
    content?.fromEmail,
    senderIdentityLoadingState,
    currentSenderIdentityHasBeenUpdated,
    currentSenderIdentityHasBeenDeleted,
  ])

  const isLoading = React.useMemo(
    () => senderIdentityLoadingState === STATUS.LOADING,
    [senderIdentityLoadingState]
  )

  const isError = React.useMemo(
    () => senderIdentityLoadingState === STATUS.ERROR,
    [senderIdentityLoadingState]
  )

  const showReplyToField = React.useCallback(() => {
    dispatch(setInlineEditor(editorConfig.set('field', 'replyTo')))
  }, [dispatch, editorConfig])

  const senderIdentityValue = React.useMemo(
    () =>
      currentSenderIdentityHasBeenUpdated || currentSenderIdentityHasBeenDeleted
        ? undefined
        : filteredSenderIdentities.find(option => option.id === content?.senderIdentityId),
    [
      content,
      filteredSenderIdentities,
      currentSenderIdentityHasBeenUpdated,
      currentSenderIdentityHasBeenDeleted,
    ]
  )

  return (
    <React.Fragment>
      <MessageTemplateWizard content={content} messageId={messageId} lang={lang} />
      <Wrapper
        isLoading={isLoading}
        isEmpty={isError}
        style={{
          paddingInline: 16,
          paddingBlock: 4,
          borderBottom: `1px solid ${colors.stroke}`,
        }}
        isOverlayShown={isError}
        overlayProps={GlobalErrorOverlayProps}
      >
        <div
          style={
            isReplyToVisible
              ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 5 }
              : { display: 'flex', gap: 5 }
          }
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              {isReplyToVisible && (
                <label
                  htmlFor="sender-select"
                  style={{ fontSize: 14, color: colors.textLight, marginLeft: 10, fontWeight: 400 }}
                >
                  Sender
                </label>
              )}

              <Select
                isSearchable
                isDisabled={senderIdentityLoadingState === 'LOADING'}
                options={filteredSenderIdentities}
                optionFormatter={SenderIdentityOptionFormatter}
                optionToString={identityToString}
                onChange={onSenderIdentityChange}
                localSearchEveryWhere
                optionMenuHeight={58}
                optionMenuShownCount={5}
                noResultsNode="No sender found"
                noOptionText="No sender settings"
                placeholder="Company (name@yourcompany.com)"
                data-testid="select_sender_identity"
                value={senderIdentityValue}
                tooltip={formattedSenderIdentity}
                style={{ minWidth: 288 }}
                menuOffset={120}
                id="sender-select"
                header={
                  <div
                    style={{
                      borderBottom: `1px solid ${colors.stroke}`,
                      paddingBlock: 8,
                      marginInline: 12,
                      color: colors.textLight,
                    }}
                  >{`Only ${requiredTransmissionType.toLowerCase()} and uncategorized senders are listed.`}</div>
                }
                $noShadow
              />
            </div>
            <Feedback message={senderInputFeedback.label} type={senderInputFeedback.type} />
          </div>

          {isReplyToVisible ? (
            <EditableField
              messageId={messageId}
              field="replyTo"
              label="Reply-to"
              preventAllCariageReturn
              inlinePersonalizationButton
            />
          ) : (
            <Tooltip
              tooltip="Use a custom reply to address instead of your sender address"
              minWidth={170}
            >
              <Button
                addOn="prefix"
                style={{ marginLeft: 'auto', color: `${colors.textLight}` }}
                onClick={showReplyToField}
                disabled={theme?.disabledMode ?? false}
              >
                <Icon icon="add" />
                Reply to
              </Button>
            </Tooltip>
          )}
        </div>
        <HorizontalDivider margin="4px 0 4px 0" />

        <EditableField
          field="subject"
          messageId={messageId}
          preventAllCariageReturn
          inlinePersonalizationButton
        />
      </Wrapper>
    </React.Fragment>
  )
}
