import { type Dayjs } from 'com.batch.common/dayjs.custom'
import * as React from 'react'

import { roundedRect } from 'components/charts/chart-helper'
import { schemes } from 'components/styled/tokens'

import { BarPath } from './bar.styles'

import { STRIPE_PATTERN_REF } from 'com.batch/shared/ui/component/charts/donut-chart/patterns/stripe-pattern'

type Props = {
  color: string
  currentDate: Dayjs
  dateFormat: string
  hasBorderRadius?: {
    top?: boolean
    bottom?: boolean
  }
  hoveredDate?: Dayjs | null | undefined
  isEmpty: boolean
  isLoading: boolean
  isStriped?: boolean
  width: number
  height: number
  x: number
  y: number
  r?: number
}
export const Bar = ({
  width,
  height,
  x,
  y,
  r = 5,
  hasBorderRadius,
  isStriped = false,
  color,
  currentDate,
  hoveredDate,
  isLoading,
  isEmpty,
  dateFormat,
}: Props): React.ReactElement | null => {
  const plot = React.useMemo(
    () =>
      roundedRect({
        x,
        y,
        w: width,
        h: height,
        r: height / 2 < r ? height / 2 : r,
        tl: hasBorderRadius?.top ?? false,
        tr: hasBorderRadius?.top ?? false,
        bl: hasBorderRadius?.bottom ?? false,
        br: hasBorderRadius?.bottom ?? false,
      }),
    [width, height, x, y, r, hasBorderRadius]
  )

  if (height !== 0)
    return (
      <React.Fragment>
        <BarPath fill={color} d={plot} opacity={1} isLoading={isLoading} isEmpty={isEmpty} />
        {isStriped && !isLoading && !isEmpty && (
          <BarPath fill={`url(#${STRIPE_PATTERN_REF})`} d={plot} opacity={0.2} />
        )}
        {!isLoading && !isEmpty && (
          <BarPath
            fill={
              hoveredDate && hoveredDate.format(dateFormat) === currentDate.format(dateFormat)
                ? schemes.grayscale['100']
                : schemes.grayscale['00']
            }
            opacity={hoveredDate ? (hoveredDate === currentDate ? 0.1 : 0.65) : 0}
            d={plot}
            className={hoveredDate === currentDate ? 'hovered' : ''}
          />
        )}
      </React.Fragment>
    )

  return null
}
