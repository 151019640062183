import * as React from 'react'

import { Switch } from 'components/common/button'

import { config } from 'com.batch.common/config'
import { generateUrl } from 'com.batch.common/router'

import { type AppRecord } from 'com.batch.redux/_records'

type LandingSwitchType = {
  hasLandingFeature: boolean
  hasThemes: boolean
  app: AppRecord
  isLandingVisible: boolean
  isLandingActive: boolean
  toggleMode: (mode: 'landing' | 'push') => void
  toggleLandingActive: () => void
}

export const LandingSwitch = (props: LandingSwitchType): React.ReactElement => {
  const {
    hasLandingFeature,
    isLandingVisible,
    isLandingActive,
    toggleMode,
    hasThemes,
    toggleLandingActive,
    app,
  } = props
  const pl15 = { paddingLeft: '15px' }
  const onToggleMode = React.useCallback(
    (mode: 'push' | 'landing') => () => toggleMode(mode),
    [toggleMode]
  )
  const createOnToggle = React.useCallback(
    (mode: 'push' | 'landing') => (e: React.KeyboardEvent<HTMLSpanElement>) => {
      if (e.code === 'Enter') {
        toggleMode(mode)
      }
    },
    [toggleMode]
  )

  return (
    <div style={{ padding: '15px 25px', backgroundColor: '#FAFAFC' }}>
      <ul className="funky">
        <li
          className={`btn funky__tab no-animate ${isLandingVisible ? '' : 'active'}`}
          style={{ borderRight: 0 }}
        >
          <span
            onClick={onToggleMode('push')}
            onKeyDown={createOnToggle('push')}
            style={{ ...pl15, ...(isLandingActive ? {} : { pointerEvents: 'none' }) }}
            role="button"
            tabIndex={isLandingActive ? 0 : -1}
          >
            1. Push notification
          </span>
        </li>
        <li
          className={`btn funky__tab no-animate ${isLandingVisible ? 'active' : ''} ${
            isLandingActive ? '' : 'disabled'
          }`}
        >
          <span
            onClick={onToggleMode('landing')}
            onKeyDown={createOnToggle('landing')}
            style={{ ...pl15, ...(isLandingActive ? {} : { pointerEvents: 'none' }) }}
            role="button"
            tabIndex={isLandingActive ? 0 : -1}
          >
            2. Mobile landing (optional)
          </span>
        </li>

        {hasLandingFeature && hasThemes && (
          <span
            style={{ width: 48, borderRadius: 0 }}
            className={`funky-switch ${isLandingVisible ? 'active' : ''} ${
              isLandingActive && hasThemes ? '' : 'disabled'
            }`}
          >
            <Switch isActive={isLandingActive} onChange={toggleLandingActive} />
          </span>
        )}

        {!hasLandingFeature && (
          <span className="funky-upgrade btn btn--action">
            <i className="fa fa-lock" style={{ color: '#fff' }} />{' '}
            <a
              style={{ color: '#fff' }}
              href={generateUrl('company_billing', { companyId: app.companyId?.toString() ?? '' })}
            >
              Upgrade
            </a>
          </span>
        )}

        {!hasThemes && hasLandingFeature && (
          <span className="funky-upgrade btn btn--action">
            <a
              style={{ color: '#fff' }}
              href={config.common.urls.appSettingsThemeEditor
                .replace('{companyId}', app.companyId?.toString() ?? '')
                .replace('{appId}', app.id?.toString() ?? '')
                .replace('{id}', 'new')}
            >
              Create a theme
            </a>
          </span>
        )}
      </ul>
    </div>
  )
}
