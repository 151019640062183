import * as Immutable from 'immutable'
import { type RecordOf } from 'immutable'

type ServiceAccountKeyProps = {
  type: 'service_account'
  projectId: string
  privateKeyId: string
  privateKey: string
  clientEmail: string
  clientId: string
  authUri: string
  tokenUri: string
  authProviderX509CertUrl: string
  clientX509CertUrl: string
  universeDomain: string
}
export const ServiceAccountKeyFactory = Immutable.Record<ServiceAccountKeyProps>({
  type: 'service_account',
  projectId: '',
  privateKeyId: '',
  privateKey: '',
  clientEmail: '',
  clientId: '',
  authUri: '',
  tokenUri: '',
  authProviderX509CertUrl: '',
  clientX509CertUrl: '',
  universeDomain: '',
} as ServiceAccountKeyProps)

export type ServiceAccountKeyRecord = RecordOf<ServiceAccountKeyProps>

type FCMConfigProps = {
  projectId: string
  serviceAccountKey: ServiceAccountKeyRecord
}
export const FCMConfigFactory = Immutable.Record<FCMConfigProps>({
  projectId: '',
  serviceAccountKey: ServiceAccountKeyFactory(),
} as FCMConfigProps)

export type FCMConfigRecord = RecordOf<FCMConfigProps>
