export const getOffsetForTimezone = (timeZone: string): string => {
  const timeZoneName = Intl.DateTimeFormat('fr', {
    timeZoneName: 'short',
    timeZone,
  })
    .formatToParts()
    .find(i => i.type === 'timeZoneName')

  return timeZoneName?.value ?? ''
}
