import * as React from 'react'
import styled from 'styled-components'

import { useClickOutside } from 'components/_hooks'
import * as colorsLegacy from 'components/styled/colors'
import { colors, schemes, Opacity } from 'components/styled/tokens'

import { SidebarResponsiveButtonStyles, RESPONSIVE_HEIGHT } from './sidebar.styles'

export type SidebarMobileMenuProps = {
  label: React.ReactNode
  border?: 'left' | 'right'
  children: React.ReactNode
}

export const SidebarMobileMenu = (props: SidebarMobileMenuProps): React.ReactElement => {
  const { label, border, children } = props
  const [opened, setOpened] = React.useState<boolean>(false)
  const toggle = React.useCallback(() => updateState(!opened), [opened])
  const [$dropdown] = useClickOutside(() => {
    if (opened) updateState(false)
  })

  const updateState = (state: boolean): void => {
    if (state && document.body) document.body.classList.add('modal-open')
    else if (document.body) document.body.classList.remove('modal-open')

    setOpened(state)
  }

  return (
    <Container opened={opened} ref={$dropdown}>
      <Label opened={opened} onClick={toggle} border={border}>
        {label}
      </Label>
      {opened && <Overlay>{children}</Overlay>}
    </Container>
  )
}

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.fill};
  border-top: 1px solid ${colorsLegacy.stroke.lighter};
  border-bottom: 1px solid ${colors.stroke};
`

export const Label = styled.div`
  display: block;
  width: 100%;

  ${SidebarResponsiveButtonStyles}
`
type ContainerProps = {
  opened: boolean
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;

  ${Overlay} {
    position: absolute;
    z-index: 11;
    top: ${RESPONSIVE_HEIGHT}px;
    left: 0;
    right: 0;
  }

  &:after {
    display: ${p => (p.opened ? 'block' : 'none')};
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background: ${Opacity(schemes.grayscale['10'], 0.88)};
    pointer-events: none;
  }
`

export default SidebarMobileMenu
