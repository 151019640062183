import Immutable, { type Map } from 'immutable'

import {
  PeriodicAnalyticsFactory,
  type PeriodicAnalyticsRecord,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'
import { computeRate } from './compute'
import { type BarChartData } from 'com.batch/shared/infra/types/chart-data'
import { dayjs } from 'com.batch.common/dayjs.custom'

export const parseStatsByPeriod =
  (periodicity: 'day' | 'week' | 'month') =>
  (stats?: Array<OrchestrationStatsDynamicDimension> | null) => {
    const periodicityIndex = stats ? stats[0].dimensionNames.indexOf(periodicity) : -1
    const channelIndex = stats ? stats[0].dimensionNames.indexOf('channel') : -1
    const periodicAnalytics = PeriodicAnalyticsFactory({
      periodicity,
    })
    const periodicAnalyticsMap = Immutable.Map<ChannelUntilCleanup, PeriodicAnalyticsRecord>({
      email: periodicAnalytics,
      push: periodicAnalytics,
      sms: periodicAnalytics,
    })
    return stats && periodicityIndex !== -1 && channelIndex !== -1
      ? stats.reduce(
          (
            acc: Map<ChannelUntilCleanup, PeriodicAnalyticsRecord>,
            { currentPeriod, dimensionValues }
          ) => {
            const periodKey = dimensionValues[periodicityIndex]
            const channelKey = dimensionValues[channelIndex]

            const delivered = currentPeriod.delivered ?? 0
            const sent = currentPeriod.sent ?? 0
            const bounce = currentPeriod.bounce ?? 0
            const click = currentPeriod.uniqueClick ?? 0
            const open = currentPeriod.uniqueOpen ?? 0
            const unsubscribe = currentPeriod.uniqueUnsubscribe ?? 0

            const bounceRate = computeRate(bounce, sent)
            const clickRate = computeRate(click, delivered)
            const openRate = computeRate(open, delivered)
            const unsubscribeRate = computeRate(unsubscribe, delivered)

            const year = parseInt(periodKey.slice(0, 4))
            let date = dayjs.utc().year(year).startOf('year')
            switch (periodicity) {
              case 'day':
                date = dayjs.utc(periodKey)
                break
              case 'week': {
                const week = parseInt(periodKey.slice(4))
                date = date.isoWeek(week).weekday(1)
                break
              }
              case 'month': {
                const month = parseInt(periodKey.slice(4)) - 1
                date = date.month(month).startOf('month')
              }
            }

            const barChartData: BarChartData = {
              date,
              delivered: {
                value: delivered,
                rate: 0,
              },
              sent: {
                value: sent,
                rate: 0,
              },
              bounce: {
                value: bounce,
                rate: bounceRate,
              },
              click: {
                value: click,
                rate: clickRate,
              },
              open: {
                value: open,
                rate: openRate,
              },
              unsubscribe: {
                value: unsubscribe,
                rate: unsubscribeRate,
              },
            }

            switch (channelKey) {
              case 'email':
              case 'push':
              case 'sms': {
                return acc.set(
                  channelKey,
                  acc
                    .get(channelKey, periodicAnalytics)
                    .set('data', acc.get(channelKey, periodicAnalytics).data.push(barChartData))
                )
              }
              default:
                return acc
            }
          },
          periodicAnalyticsMap
        )
      : periodicAnalyticsMap
  }
