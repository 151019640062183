import styled from 'styled-components'

export const InteChoice = styled.div`
  border-radius: 4px;
  background-color: #f2f3f8;
  &:hover {
    background-color: #eff0f6;
  }
  display: flex;
  border: 2px solid #f2f3f8;
  flex-grow: 1;
  margin: 10px;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
