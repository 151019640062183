import { css } from 'styled-components'

import * as colors from 'components/styled/tokens/colors'

export const FilterWithoutValue = css`
  background: transparent;
  &,
  &:placeholder,
  .styled-input-addons.styled-addon-pre {
    color: ${colors.textLight};
  }
  &:hover {
    background: ${colors.opacifyHover};
    .styled-input-addons.styled-addon-pre {
      color: ${colors.text};
    }
  }
  &:focus,
  &:focus-within {
    background: ${colors.opacifyActive};
    .styled-input-addons.styled-addon-pre {
      color: ${colors.text};
    }
  }
`
export const FilterWithValue = css`
  background: ${colors.opacifyActive};
  color: ${colors.text};
  &,
  &:placeholder,
  .styled-input-addons.styled-addon-pre {
    color: ${colors.textLight};
  }
  &:hover {
    background: ${colors.opacifyActive};
    .styled-input-addons.styled-addon-pre {
      color: ${colors.text};
    }
  }
  &:focus,
  &:focus-within {
    background: ${colors.opacifyActive};
    .styled-input-addons.styled-addon-pre {
      color: ${colors.text};
    }
  }
`
