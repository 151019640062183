export const formatFilterPushTokens = (
  filterPushTokens?: 'collected' | 'all' | 'imported'
): 'PUSH_TOKEN_COLLECTED' | 'PUSH_TOKEN_IMPORTED' | 'PUSH_TOKEN_ALL' => {
  switch (filterPushTokens) {
    case 'collected':
      return 'PUSH_TOKEN_COLLECTED'
    case 'imported':
      return 'PUSH_TOKEN_IMPORTED'
    case 'all':
      return 'PUSH_TOKEN_ALL'
    default:
      return 'PUSH_TOKEN_COLLECTED'
  }
}
