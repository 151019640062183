import dayjs from 'dayjs'
import * as React from 'react'

import { type availableIcons, Icon } from 'components/common/svg-icon'
import { Ellipsis } from 'components/styled/text'
import { Tooltip } from 'components/common/tooltip'
import {
  EventRowContent,
  EventRowDetails,
  EventRowContentInformation,
  EventRowDetailsSource,
} from '../event-row/event-row.styles'
import { APIIcon } from '../icons'
import { Timeline } from '../timeline/timeline'

import { convertAttributesToJson } from 'com.batch/profile/infra/formats/convert-attributes-to-json'
import { type CustomEventRecord } from 'com.batch/profile/models/event.records'
import { EventRowAttributes } from 'com.batch/profile/ui/components/events-block/event-row/event-row-attributes'
import { Time } from 'com.batch/profile/ui/components/events-block/timeline/time'
import { Skeleton } from 'components/common/empty-states'
import { STATUS } from 'constants/common'
import { useSelector } from 'react-redux'
import { profileLoadingStateSelector } from 'com.batch/profile/store/profile.selector'

type Props = {
  event: CustomEventRecord
}

const platform: {
  [key: string]: {
    icon: availableIcons
    label: string
  }
} = {
  IOS: {
    icon: 'ios',
    label: 'iOS',
  },
  ANDROID: {
    icon: 'android',
    label: 'Android',
  },
  WEBPUSH: {
    icon: 'webpush',
    label: 'Web',
  },
}

export const CustomEventRow = ({ event }: Props): React.ReactElement => {
  const { attributes } = event
  const [isAttributesShown, setIsAttributesShown] = React.useState<boolean>(false)
  const profileLoadingState = useSelector(profileLoadingStateSelector)
  const isLoading = React.useMemo(
    () => profileLoadingState === STATUS.LOADING,
    [profileLoadingState]
  )

  convertAttributesToJson(attributes)

  const isSdkEvent = React.useMemo(() => {
    return (
      event.source === 'ANDROID' ||
      event.source === 'IOS' ||
      event.source === 'WEBPUSH' ||
      event.source === 'SDK'
    )
  }, [event.source])

  const sendDateUtc = React.useMemo(() => {
    return dayjs.utc(event.sendDate)
  }, [event.sendDate])

  const sendDate = React.useMemo(() => {
    return sendDateUtc.local()
  }, [sendDateUtc])

  const hasAttributes = React.useMemo(() => event.attributes.size > 0, [event.attributes.size])

  const handleClickExpandBtn = React.useCallback(
    () => setIsAttributesShown(!isAttributesShown),
    [isAttributesShown]
  )

  return (
    <EventRowContent>
      <Time date={sendDate} />
      <Timeline />
      <EventRowContentInformation>
        <EventRowDetails $isToggable={hasAttributes}>
          <div onClick={handleClickExpandBtn}>
            <Skeleton style={{ marginTop: '3px' }} w={100} h={14}>
              <Ellipsis>{event.name}</Ellipsis>
            </Skeleton>
            {hasAttributes && (
              <span>
                &nbsp;with {attributes.size} {attributes.size > 1 ? 'attributes' : 'attribute'}
              </span>
            )}

            {!isLoading && (
              <EventRowDetailsSource>
                {event.isReattribution && (
                  <Tooltip
                    placement="bottom-start"
                    tooltip={
                      <div style={{ width: 246, fontSize: 14, textAlign: 'left' }}>
                        This event has been performed anonymously then reattributed to this profile
                      </div>
                    }
                  >
                    <Icon
                      icon="reattributed"
                      className="reattributed-icon"
                      style={{ marginLeft: 8 }}
                    />
                  </Tooltip>
                )}
                {isSdkEvent ? (
                  event.platform && (
                    <Icon icon={platform[event.platform].icon} style={{ marginLeft: 8 }} />
                  )
                ) : (
                  <APIIcon />
                )}
              </EventRowDetailsSource>
            )}
            {hasAttributes && (
              <Icon
                className="toggle-icon"
                style={{ marginLeft: '7px' }}
                icon={isAttributesShown ? 'chevron-up' : 'chevron-down'}
              />
            )}
          </div>
        </EventRowDetails>
        {hasAttributes && isAttributesShown && (
          <div>
            <EventRowAttributes
              attributes={event.attributes}
              eventName={event.name}
              isShown={isAttributesShown}
            />
          </div>
        )}
      </EventRowContentInformation>
    </EventRowContent>
  )
}
