import { type Dayjs } from 'dayjs'
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  DebugDevice,
  PreviewDevice,
  PreviewDevicePadding,
  PreviewContent,
  StatusBar,
  AndroidSvg,
  StatusBarOperator,
  AndroidTime,
  AndroidBattery,
  CommandCenter,
  CommandCenterTitle,
  CommandCenterBar,
  CommandCenterButton,
  CommandCenterHandle,
  StatusBarMain,
  StatusBarBattery,
  OpBar,
} from './preview.styles'
import * as themes from './preview.theme'

import { type AppRecord } from 'com.batch.redux/_records'
import { type StyledPreviewTheme } from './preview.theme'

type DefaultProps = {
  mode?: 'light' | 'dark'
  webPreviewMode?: string
  webviewBackground?: boolean
  hideStatus?: boolean
  time: Dayjs
}

type PreviewContainerProps = {
  app: AppRecord
  previewMode: 'normal' | 'expanded' | 'landing'
  lightStatusBar: boolean
  children: React.ReactNode
} & DefaultProps

type PreviewContainerState = {
  level: number
  charging: boolean
}

export const PreviewContainer = ({
  app,
  previewMode,
  mode = 'light',
  webPreviewMode = 'chrome-win',
  webviewBackground = false,
  hideStatus = false,
  lightStatusBar,
  time = dayjs(),
  children,
}: PreviewContainerProps): React.ReactElement => {
  const [state, setState] = React.useState<PreviewContainerState>({
    level: 0.7,
    charging: false,
  })

  const mountedRef = React.useRef<boolean>(false)

  React.useEffect(() => {
    mountedRef.current = true

    if (typeof navigator.getBattery === 'function') {
      navigator.getBattery().then(battery => {
        if (mountedRef.current) {
          setState({ charging: battery.charging, level: battery.level })
        }

        battery.onchargingchange = () => {
          if (mountedRef.current) setState(prev => ({ ...prev, charging: battery.charging }))
        }

        battery.onlevelchange = () => {
          if (mountedRef.current) setState(prev => ({ ...prev, level: battery.level }))
        }
      })
    }

    return () => {
      mountedRef.current = false
      if (typeof navigator.getBattery === 'function') {
        navigator.getBattery().then(battery => {
          battery.onchargingchange = null
          battery.onlevelchange = null
        })
      }
    }
  }, [])

  const expanded = previewMode === 'expanded'
  let theme: StyledPreviewTheme =
    app.platform === 'ios' ||
    app.platform === 'android' ||
    app.platform === 'windows' ||
    app.platform === 'webpush' // @ts-expect-error legacy code we don't want to break
      ? { ...themes[app.platform], expanded, mode }
      : { expanded }

  if (webPreviewMode === 'android') {
    // @ts-expect-error legacy code we don't want to break
    theme = { ...themes.android, expanded, mode }
  }

  const contentStyle = webviewBackground
    ? {
        backgroundImage: 'url(/medias/img/notif-preview/legacy/sample_app_background.png)',
        backgroundSize: 'cover',
      }
    : {}

  return (
    <ThemeProvider theme={theme}>
      <DebugDevice>
        <PreviewDevice />
        {app.platform === 'ios' && !hideStatus && (
          <StatusBar light={lightStatusBar}>
            <StatusBarOperator>
              <OpBar num={1} />
              <OpBar num={2} />
              <OpBar num={3} />
              <OpBar num={4} off />
            </StatusBarOperator>
            <StatusBarMain>{expanded ? time?.format('HH:mm') ?? '' : ''}</StatusBarMain>
            <StatusBarBattery level={state.level} charging={state.charging} light={lightStatusBar}>
              <div />
            </StatusBarBattery>
          </StatusBar>
        )}
        {(app.platform === 'android' || webPreviewMode === 'android') && !hideStatus && (
          <StatusBar light={lightStatusBar}>
            {expanded && <AndroidTime>{time?.format('HH:mm')}</AndroidTime>}
            <StatusBarMain />
            {!expanded && (
              <div>
                <AndroidSvg
                  width="12"
                  height="12"
                  style={{ marginRight: '8px', paddingTop: '1px' }}
                >
                  <path d="M0 3 L6 11 L12 3 C9 0, 3 0, 0 3" />
                </AndroidSvg>
              </div>
            )}
            <div>
              <AndroidBattery />
            </div>
            <div>{Math.floor(state.level * 100)}&nbsp;%</div>
          </StatusBar>
        )}
        {(app.platform === 'android' || webPreviewMode === 'android') && expanded && (
          <CommandCenter>
            <CommandCenterTitle>
              <div>{time?.format('ddd. DD MMM.')}</div>
              <span>
                <AndroidSvg width="12" height="12">
                  <path d="M0 3 L6 11 L12 3 C9 0, 3 0, 0 3" />
                </AndroidSvg>
              </span>
            </CommandCenterTitle>
            <CommandCenterBar>
              <CommandCenterButton active>
                <AndroidSvg width="18" height="16">
                  <path d="M0 5 L9 16 L18 5 C14 0, 4 0, 0 5" />
                </AndroidSvg>
              </CommandCenterButton>
              <CommandCenterButton />
              <CommandCenterButton />
              <CommandCenterButton />
              <CommandCenterButton />
              <CommandCenterButton />
            </CommandCenterBar>
            <CommandCenterHandle />
          </CommandCenter>
        )}
        <PreviewDevicePadding>
          <PreviewContent style={contentStyle}>{children}</PreviewContent>
        </PreviewDevicePadding>
      </DebugDevice>
    </ThemeProvider>
  )
}
