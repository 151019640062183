import { type Platform } from 'com.batch/profile/infra/debug/models/shared.model'
import { availableIcons } from 'components/common/svg-icon'

export const platformsList: Array<Platform> = ['ANDROID', 'IOS', 'WINDOWS', 'WEBPUSH']
export const platforms: {
  [key in Platform]: {
    icon: availableIcons
    label: string
  }
} = {
  IOS: {
    icon: 'ios',
    label: 'iOS',
  },
  ANDROID: {
    icon: 'android',
    label: 'Android',
  },
  WEBPUSH: {
    icon: 'webpush',
    label: 'Web',
  },
  WINDOWS: {
    icon: 'windows',
    label: 'Windows',
  },
}
