import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

import { parseEmailSubDistributionResponse } from 'com.batch/profilebase/infra/parses/parse-email-sub-distribution'
import { parseProfilesDistributionResponse } from 'com.batch/profilebase/infra/parses/parse-profiles-distribution'
import { parsePushOptinDistributionResponse } from 'com.batch/profilebase/infra/parses/parse-push-optin-distribution'
import { parseReachabilityResponse } from 'com.batch/profilebase/infra/parses/parse-reachability'
import {
  type FetchSubDistributionResponse,
  type FetchPushOptinDistributionResponse,
  type FetchProfilesDistributionResponse,
  type FetchReachabilityTimeserieResponse,
  type UCPDataService,
  type FetchMonthlyActiveProfile,
} from 'com.batch/profilebase/infra/ucp-data-service.interface'

export const ucpDataService: UCPDataService = {
  fetchProfilesDistribution: async ({ projectKey }) => {
    try {
      const response = await request.post<FetchProfilesDistributionResponse>(
        generateUrl('api_grpc_ucp_data_service', {
          methodName: 'GetProfileDistribution',
        }),
        {
          projectKey: {
            textual: {
              text: projectKey,
            },
          },
        }
      )
      return parseProfilesDistributionResponse(response)
    } catch (e: any) {
      throw e.aborted ? e : e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  fetchEmailSubDistribution: async ({ projectKey, subscriptionList }) => {
    try {
      const response = await request.post<FetchSubDistributionResponse>(
        generateUrl('api_grpc_ucp_data_service', {
          methodName: 'GetEmailSubscriptionDistribution',
        }),
        {
          projectKey: {
            textual: {
              text: projectKey,
            },
          },
          subscriptionList: subscriptionList === 'marketing' ? 0 : 1, // améliorer this 💩, voir comment passer un enum
        }
      )
      return parseEmailSubDistributionResponse(response)
    } catch (e: any) {
      throw e.aborted ? e : e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  fetchSmsSubDistribution: async ({ projectKey, subscriptionList }) => {
    try {
      const response = await request.post<FetchSubDistributionResponse>(
        generateUrl('api_grpc_ucp_data_service', {
          methodName: 'GetSMSSubscriptionDistribution',
        }),
        {
          projectKey: {
            textual: {
              text: projectKey,
            },
          },
          subscriptionList: subscriptionList === 'marketing' ? 0 : 1, // améliorer this 💩, voir comment passer un enum
        }
      )
      return parseEmailSubDistributionResponse(response)
    } catch (e: any) {
      throw e.aborted ? e : e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  fetchPushOptinDistribution: async ({ projectKey, platform }) => {
    try {
      const response = await request.post<FetchPushOptinDistributionResponse>(
        generateUrl('api_grpc_ucp_data_service', {
          methodName: 'GetPushOptinDistribution',
        }),
        {
          projectKey: {
            textual: {
              text: projectKey,
            },
          },
          platform,
        }
      )

      return parsePushOptinDistributionResponse('push', response)
    } catch (e: any) {
      throw e.aborted ? e : e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  fetchWebpushOptinDistribution: async ({ projectKey }) => {
    try {
      const response = await request.post<FetchPushOptinDistributionResponse>(
        generateUrl('api_grpc_ucp_data_service', {
          methodName: 'GetWebpushOptinDistribution',
        }),
        {
          projectKey: {
            textual: {
              text: projectKey,
            },
          },
        }
      )

      return parsePushOptinDistributionResponse('webpush', response)
    } catch (e: any) {
      throw e.aborted ? e : e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  fetchReachabilityTimeserie: async ({ projectKey, channel, platform }) => {
    try {
      const response = await request.post<FetchReachabilityTimeserieResponse>(
        generateUrl('api_grpc_ucp_data_service', {
          methodName: 'GetReachabilityTimeserie',
        }),
        {
          projectKey: {
            textual: {
              text: projectKey,
            },
          },
          channel: `REACHABILITY_CHANNEL_${channel.toUpperCase()}`,
          platform,
        }
      )
      return parseReachabilityResponse(response)
    } catch (e: any) {
      throw e.aborted ? e : e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
  getMonthlyActiveProfiles: async ({ projectKeys, month }) => {
    try {
      const response = await request.post<FetchMonthlyActiveProfile>(
        generateUrl('api_grpc_ucp_data_service', {
          methodName: 'GetMonthlyActiveProfiles',
        }),
        {
          projectKeys: projectKeys.map(p => ({
            textual: {
              text: p,
            },
          })),
          month,
        }
      )
      return parseInt(response.total, 10) || 0
    } catch (e: any) {
      throw e.aborted ? e : e.error?.errors?.[0]?.message ?? 'Unknown error'
    }
  },
}
