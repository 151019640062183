import Immutable from 'immutable'
import { get as _get } from 'lodash-es'
import * as React from 'react'
import { createRoot } from 'react-dom/client'

import { StandalonePreview } from 'components/campaign/preview/standalone-preview'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { AppFactory, type Variant } from 'com.batch.redux/_records'
import { AbTestedThemeFactory, type FieldKind } from 'com.batch.redux/theme.records'

const fakeSets = Immutable.Set<string>()

const parseHash = () => {
  const hash = window.location.hash.substr(2)
  const results = hash.split('&').reduce<Record<string, any>>((acc, item) => {
    if (!item) return acc
    const parts = item.split('=')
    acc[parts[0]] = decodeURIComponent(parts[1])
    return acc
  }, {})
  return results
}

const fakeSelectThemeField: (arg1: {
  field: FieldKind | null | undefined
  variant: Variant | null | undefined
}) => void = () => undefined
const noFunction = () => undefined
export const HashChangeListener = (): React.ReactElement => {
  // ====================== COMPONENT CONSTANTS
  const params = parseHash()

  // ====================== COMPONENT STATE
  const [bundleId, setBundleId] = React.useState(_get(params, 'bundleId', ''))
  const [appIcon, setAppIcon] = React.useState(_get(params, 'appIcon', ''))
  const [app, setApp] = React.useState(_get(params, 'app', ''))
  const [title, setTitle] = React.useState(_get(params, 'title', ''))
  const [message, setMessage] = React.useState(_get(params, 'message', ''))
  const [expanded, setExpanded] = React.useState(_get(params, 'expanded', ''))
  const [image, setImage] = React.useState(_get(params, 'image', ''))
  const [audio, setAudio] = React.useState(_get(params, 'audio', ''))
  const [icon, setIcon] = React.useState(_get(params, 'icon', ''))
  const [video, setVideo] = React.useState(_get(params, 'video', ''))

  // ====================== CALLBACKS
  const onHashChange = () => {
    const params = parseHash()
    setBundleId(_get(params, 'bundleId', ''))
    setAppIcon(_get(params, 'appIcon', ''))
    setApp(_get(params, 'app', ''))
    setTitle(_get(params, 'title', ''))
    setMessage(_get(params, 'message', ''))
    setExpanded(_get(params, 'expanded', ''))
    setImage(_get(params, 'image', ''))
    setAudio(_get(params, 'audio', ''))
    setIcon(_get(params, 'icon', ''))
    setVideo(_get(params, 'video', ''))
  }

  const getVariantData = React.useCallback(
    () => ({
      title,
      message,
      image,
      audio,
      video,
    }),
    [title, message, image, audio, video]
  )

  // ====================== USE EFFECTS
  React.useEffect(() => {
    onHashChange()
    window.addEventListener('hashchange', onHashChange)
    return () => window.removeEventListener('hashchange', onHashChange)
  }, [])

  return (
    <StandalonePreview
      variant="a"
      templatedFields={fakeSets}
      date={dayjs()}
      getVariantData={getVariantData}
      icon={icon}
      safariIcon=""
      previewMode={expanded === '1' ? 'expanded' : 'normal'}
      app={AppFactory({
        platform: window.platform,
        name: app,
        icon: appIcon,
        bundleId: bundleId,
      })}
      variantsThemes={AbTestedThemeFactory()}
      selectedField=""
      previewWeb="chrome-win"
      loadingThemes={false}
      mode={Immutable.Map<Variant, 'dark' | 'light'>({ a: 'light', b: 'light' })}
      hasPush={false}
      hasLanding={false}
      focusField={fakeSelectThemeField}
      // @ts-expect-error pas utilisé
      getLandingVariantData={noFunction}
      audio=""
      image=""
      video=""
    />
  )
}

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)
  root.render(<HashChangeListener />)
}
