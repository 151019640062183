import * as React from 'react'

import { Input, AutoComplete } from 'components/form'

import { requiredString } from 'com.batch.common/validators'

import { useSearchOptions, type InputProps } from './helper'

import { StartsWithOperator, EndsWithOperator } from 'com.batch.redux/query/query.records.operators'
import { type ChangeEvent } from 'react'

type LabelValue = {
  label: string
  value: string
}
const stringToLabelValue = (value: string) => ({
  label: value,
  value,
})

const OptFormater = (
  opt:
    | LabelValue
    | (LabelValue & {
        __isNew__: boolean
      })
) =>
  '__isNew__' in opt && opt.__isNew__ ? (
    <React.Fragment>
      <em>Create </em>
      {opt.value}
    </React.Fragment>
  ) : (
    opt.value
  )

export const InputString = ({
  condition,
  updateCondition,
  isInvalid,
}: InputProps): React.ReactElement => {
  const attributeId = React.useMemo(
    () => condition?.attribute?.api ?? '',
    [condition?.attribute?.api]
  )
  const isTag = condition?.attribute?.type === 'TAG'
  const loadOptions = useSearchOptions(attributeId, isTag)
  const loadOptionsAsLabelValue = React.useCallback(
    (q: string) => loadOptions(q).then(opts => opts.map(stringToLabelValue)),
    [loadOptions]
  )

  const showSelect = React.useMemo(
    () => condition.operator !== StartsWithOperator && condition.operator !== EndsWithOperator,
    [condition.operator]
  )

  const [invalid, setInvalid] = React.useState(false)
  const [isTouched, setIsTouched] = React.useState(false)

  const onChangeLocalAutoComplete = React.useCallback(
    (option: { value: string; label: string }) => {
      setInvalid(!requiredString(option?.value))
      updateCondition(condition.set('value', condition.value.set('string', option?.value ?? '')))
    },
    [condition, updateCondition]
  )

  const onChangeLocalInput = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInvalid(!requiredString(event.target.value))
      updateCondition(
        condition.set('value', condition.value.set('string', event.target.value ?? ''))
      )
    },
    [condition, updateCondition]
  )

  const onBlur = React.useCallback(() => {
    setIsTouched(true)
  }, [])

  const optionToString = React.useCallback((opt: LabelValue) => opt?.label ?? '', [])

  const optionCreator = React.useCallback((text: string) => ({ label: text, value: text }), [])

  return showSelect ? (
    <AutoComplete
      key={attributeId}
      optionToString={optionToString}
      loadOptions={loadOptionsAsLabelValue}
      invalid={(invalid && isTouched) || isInvalid}
      optionCreator={optionCreator}
      onBlur={onBlur}
      optionFormatter={OptFormater}
      value={stringToLabelValue(condition.get('value').string)}
      onChange={onChangeLocalAutoComplete}
    />
  ) : (
    <Input
      type="text"
      value={condition.get('value').string}
      onBlur={onBlur}
      invalid={(invalid && isTouched) || isInvalid}
      onChange={onChangeLocalInput}
    />
  )
}
