import { type ReduxAction } from 'com.batch.redux/_records'

import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import { type InlineEditorConfigRecord } from 'com.batch/message-builder/models/message-builder.records'

export type SetInlineEditorAction = ReduxAction<
  'SET_INLINE_EDITOR_CONFIG',
  InlineEditorConfigRecord
>
export const setInlineEditor = (config: InlineEditorConfigRecord): SetInlineEditorAction => {
  return {
    type: 'SET_INLINE_EDITOR_CONFIG',
    payload: config,
  }
}

type InlineEditorBlurPayload = {
  field: MessageBuilderEditableField
  variant: 'a' | 'b'
  selection: [number, number]
}
export type BlurInlineEditorAction = ReduxAction<'BLUR_INLINE_EDITOR', InlineEditorBlurPayload>
export const blurInlineEditorAction = ({
  field,
  variant,
  selection,
}: InlineEditorBlurPayload): BlurInlineEditorAction => {
  return {
    type: 'BLUR_INLINE_EDITOR',
    payload: { field, variant, selection: selection },
  }
}
