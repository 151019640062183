import { type Browser } from 'com.batch/profile/infra/debug/models/shared.model'
import { availableIcons } from 'components/common/svg-icon'

export const browsersList: Array<Browser> = ['CHROME', 'SAFARI', 'EDGE', 'FIREFOX', 'UNKNOWN']
export const browsers: {
  [key in Browser]: {
    icon: availableIcons
    label: string
  }
} = {
  CHROME: {
    icon: 'chrome-colored',
    label: 'Chrome',
  },
  SAFARI: {
    icon: 'safari',
    label: 'Safari',
  },
  EDGE: {
    icon: 'edge',
    label: 'Edge',
  },
  FIREFOX: {
    icon: 'firefox',
    label: 'Firefox',
  },
  UNKNOWN: {
    icon: 'webpush',
    label: 'Web browser',
  },
}
