import { type Instance } from '@popperjs/core'
import { type Placement } from '@popperjs/core/lib/enums'
import * as React from 'react'
import { createPortal } from 'react-dom'

import { minWidth, usePopper, useClickOutsideOnExistingRefs, useToggle } from 'components/_hooks'
import { Icon, type availableIcons } from 'components/common/svg-icon'

import { Button, type ButtonProps } from './button.styles'
import { DropdownContainer } from './dropdown.styles'

// eslint-disable-next-line no-unused-vars

type ReactRef<T> = {
  current: T | null
}

type DropdownMenuProps = React.ComponentProps<'div'> & {
  children: React.ReactNode
  forcedWidth?: number
  forcedHeight?: number
  willScroll?: boolean
  isScrollable?: boolean
  isOpen: boolean
  alwaysInDom: boolean
}

export const DropdownMenu: React.ComponentType<DropdownMenuProps> = React.forwardRef(
  (
    {
      children,
      isOpen,
      forcedWidth,
      forcedHeight,
      willScroll,
      isScrollable,
      alwaysInDom,
      ...rest
    }: DropdownMenuProps,
    ref
  ): React.ReactElement | null => {
    if (!isOpen && !alwaysInDom) return null
    let targetNode = document.getElementById('btn-dropdown-root')
    if (!targetNode) {
      targetNode = document.createElement('div')
      targetNode.setAttribute('id', 'btn-dropdown-root')
      targetNode.style.zIndex = '40'
      targetNode.style.position = 'relative'
      document.body?.appendChild(targetNode)
    }
    return createPortal(
      <DropdownContainer
        {...rest}
        ref={ref}
        $isOpen={isOpen}
        $willScroll={willScroll}
        $forcedWidth={forcedWidth}
        $forcedHeight={forcedHeight}
        $isScrollable={isScrollable}
        $alwaysInDom={alwaysInDom}
      >
        {children}
      </DropdownContainer>,
      targetNode
    )
  }
)
type DropdownConfig = {
  placement?: Placement
  offset?: [number | null | undefined, number | null | undefined]
  forcedWidth?: number
  forcedHeight?: number
  isScrollable?: boolean
  alwaysInDom?: boolean
}

export const useDropdown = ({
  placement = 'bottom-start',
  forcedWidth = 0,
  forcedHeight = 0,
  isScrollable = false,
  alwaysInDom = false,
  offset = [0, 6],
}: DropdownConfig): {
  triggerProps: {
    ref: ReactRef<any>
    onClick: () => void
    isActive: boolean
  }
  dropdownProps: {
    ref: ReactRef<any>
    isOpen: boolean
    forcedWidth?: number
    forcedHeight?: number
    alwaysInDom: boolean
    isScrollable?: boolean
  }
  closeDropdown: () => void
  instance: Instance | null
} => {
  const dropdownState = useToggle(false)
  const [triggerRef, dropdownRef, instance] = usePopper({
    modifiers: [
      minWidth,
      { name: 'applyStyles' },
      {
        name: 'flip',
        enabled: false,
      },
      { name: 'eventListeners', enabled: false },
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
    placement,
  })
  React.useLayoutEffect(() => {
    if (dropdownState.value) {
      instance?.setOptions(options => ({
        ...options,
        modifiers: [...(options.modifiers ?? []), { name: 'eventListeners', enabled: true }],
      }))
    } else {
      instance?.setOptions(options => ({
        ...options,
        modifiers: [...(options.modifiers ?? []), { name: 'eventListeners', enabled: false }],
      }))
    }
  }, [dropdownState.value, instance])

  useClickOutsideOnExistingRefs(dropdownState.close, [triggerRef, dropdownRef])
  return {
    triggerProps: {
      ref: triggerRef,
      onClick: dropdownState.toggle,
      isActive: dropdownState.value,
    },
    dropdownProps: {
      ref: dropdownRef,
      isOpen: dropdownState.value,
      forcedWidth,
      forcedHeight,
      isScrollable,
      alwaysInDom,
    },
    closeDropdown: dropdownState.close,
    instance,
  }
}

export const Dropdown = ({
  children,
  label,
  buttonProps,
  style,
  icon = { as: 'suffix', icon: 'select' },
  ...config
}: {
  children: React.ReactNode
  label: string | React.ReactNode
  buttonProps?: ButtonProps
  style?: {
    [key: string]: string | number
  }
  icon?: {
    as: 'prefix' | 'suffix'
    icon: availableIcons
  }
} & DropdownConfig): React.ReactElement => {
  const { dropdownProps, triggerProps } = useDropdown(config)
  return (
    <React.Fragment>
      <Button
        {...buttonProps}
        addOn="suffix"
        addOnGap={12}
        {...triggerProps}
        aria-label="Toggle dropdown menu"
      >
        {icon.as === 'prefix' && <Icon icon={icon.icon} />}
        {label}
        {icon.as === 'suffix' && <Icon icon={icon.icon} />}
      </Button>
      <DropdownMenu style={style} {...dropdownProps}>
        {children}
      </DropdownMenu>
    </React.Fragment>
  )
}

export const IconDropdown = ({
  children,
  icon,
  buttonProps,
  ...config
}: {
  children: React.ReactNode
  icon?: availableIcons
  buttonProps?: ButtonProps
} & DropdownConfig): React.ReactElement => {
  const { dropdownProps, triggerProps } = useDropdown(config)

  return (
    <React.Fragment>
      <Button {...buttonProps} {...triggerProps} aria-label="Toggle dropdown menu">
        <Icon icon={icon ?? 'options'} />
      </Button>
      <DropdownMenu {...dropdownProps}>{children}</DropdownMenu>
    </React.Fragment>
  )
}
