import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { Box, BoxBody, BoxHeader, BoxTitle } from 'components/common/box'
import { EmptyField, SettingsEmptyIcon, type OverlayProps } from 'components/common/empty-states'
import { Pager } from 'components/common/pager'
import {
  Table,
  TableHeader,
  TableCellHeader,
  TableBody,
  TableTemplateCell,
} from 'components/common/table'
import { TableFooter } from 'components/common/table/table.styles'

import { getPlatformDoc } from 'com.batch.common/utils'

// ====================== CUSTOM DATA EMPTY LINE
export const CustomDataEmptyTable = (): React.ReactElement => (
  <ThemeProvider theme={{ isLoading: true }}>
    <Table template="1fr 250px 138px 160px 99px">
      <TableHeader>
        <TableCellHeader style={{ marginLeft: 20 }}>Campaign name</TableCellHeader>
        <TableCellHeader>Name</TableCellHeader>
        <TableCellHeader>Type</TableCellHeader>
        <TableCellHeader>Last receipt</TableCellHeader>
        <div />
      </TableHeader>
      <TableBody emptyTemplate={<TableEmptyTemplate />}></TableBody>
    </Table>
  </ThemeProvider>
)

const TableEmptyTemplate = () => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
}
const emptyFunc = () => {}

// ====================== CUSTOM DATA EMPTY LIST
export const CustomDataEmptyBox: React.ComponentType<Record<any, any>> = React.memo<
  Record<any, any>
>((): React.ReactElement => {
  return (
    <Box>
      <BoxHeader>
        <BoxTitle>
          <EmptyField _width={118} />
        </BoxTitle>
      </BoxHeader>
      <BoxBody>
        <CustomDataEmptyTable />
      </BoxBody>
      <TableFooter>
        <Pager page={1} total={0} nbPerPage={10} selectPage={emptyFunc} />
      </TableFooter>
    </Box>
  )
})

// ====================== NO CUSTOM DATAS

export const getNoCustomDataProps = (platform: Platforms): OverlayProps => {
  const linkCustomData =
    platform === 'webpush'
      ? `https://doc.batch.com/${getPlatformDoc(platform)}/custom-data/customid`
      : `https://doc.batch.com/${getPlatformDoc(platform)}/custom-data/custom-attributes`
  return {
    status: 'empty-page',
    title: 'No custom data yet',
    description:
      'Our custom audience feature lets you send static lists of user or device IDs to Batch and then target those with push or in-app campaigns.',
    content: <SettingsEmptyIcon />,
    links: [
      {
        name: 'Tracking Custom Data',
        href: linkCustomData,
      },
      {
        name: 'Install the SDK',
        href: `https://doc.batch.com/${getPlatformDoc(platform)}/getting-started/prerequisites`,
      },
    ],
  }
}
