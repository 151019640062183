import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { LoaderContainer } from './loader.styles'

type LoaderProps = {
  loading: boolean
  overlay?: boolean
  size?: 'normal' | 'small'
  children?: React.ReactNode
  padding?: number
  style?: {
    [key: string]: string | number
  }
}

export const Loader: React.ComponentType<LoaderProps> = React.memo(
  ({
    loading,
    style,
    overlay,
    size = 'normal',
    children,
    padding,
  }: LoaderProps): React.ReactElement => {
    return (
      <LoaderContainer overlay={overlay} padding={padding} style={style}>
        {loading && (
          <div className="styled-loader">
            <Icon
              icon="spinner"
              size={size === 'small' ? 14 : 28}
              style={{ color: colors.textAction }}
            />
          </div>
        )}

        {(!loading || overlay) && children}
      </LoaderContainer>
    )
  }
)
