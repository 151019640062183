import styled from 'styled-components'

export const PushDate = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  font-size: 12px;
  color: #858ea1;
`

export const PushDateTitle = styled.h3<any>`
  margin: 0 0 7px 0;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #858ea1;
`

export const PushDateAbsolute = styled.strong`
  margin-right: 7px;
  line-height: 1;
  font-size: 20px;
  font-weight: 400;
  color: #383838;
`
