import { type Dayjs } from 'dayjs'
import Immutable, { type List, type Set, type RecordOf } from 'immutable'

import {
  MessageConfigFactory,
  type MessageConfigRecord,
} from '../../message/models/message.records'
import { AgeFactory, type AgeRecord } from 'com.batch.redux/_records'

type QuietTimesProps = {
  behavior: 'wait' | 'skip'
  startHour: number
  endHour: number
  startMin: number
  endMin: number
  quietDaysOfWeek: Set<number>
  quietHoursTimePeriodDisabled: boolean
}
export type QuietTimesRecord = RecordOf<QuietTimesProps>
export const QuietTimesFactory = Immutable.Record<QuietTimesProps>({
  behavior: 'wait',
  startHour: 22,
  endHour: 8,
  startMin: 0,
  endMin: 0,
  quietDaysOfWeek: Immutable.Set<number>([]),
  quietHoursTimePeriodDisabled: true,
})

export { getNodeId } from './id-generator'

type EventWithOptionalQueryProps = {
  name: string
  query: string
  eventId: string
  requireMatchingInstanceId: boolean
}
export type EventWithOptionalQueryRecord = RecordOf<EventWithOptionalQueryProps>
export const EventWithOptionalQueryFactory = Immutable.Record<EventWithOptionalQueryProps>({
  name: '',
  query: '',
  eventId: '',
  requireMatchingInstanceId: true,
})

export type JourneySettingsProps = {
  instanceId: string
  hasInstanceId: boolean
  start: Dayjs | null | undefined
  hasStart: boolean
  end: Dayjs | null | undefined
  hasEnd: boolean
  capping: number
  hasCapping: boolean
  hasGrace: boolean
  gracePeriod: AgeRecord
  entryEvents: List<EventWithOptionalQueryRecord>
  quietTimes: QuietTimesRecord
  hasQuietTimes: boolean
}
export type JourneySettingsRecord = RecordOf<JourneySettingsProps>
export const JourneySettingsFactory = Immutable.Record<JourneySettingsProps>({
  instanceId: '',
  hasInstanceId: false,
  start: null,
  hasStart: false,
  end: null,
  hasEnd: false,
  capping: 1,
  hasCapping: false,
  hasGrace: false,
  gracePeriod: AgeFactory({
    inputValue: '1',
    seconds: 3600,
    valid: true,
    unit: 'h',
    label: 'hour',
    fullText: '1 hour',
  }),
  entryEvents: Immutable.List(),
  quietTimes: QuietTimesFactory(),
  hasQuietTimes: false,
})
export type NodeType = 'FINAL' | 'MESSAGE' | 'TIMER' | 'YESNO' | 'RANDOM'
export type NodeError =
  | 'INVALID_TIMER'
  | 'INCOMPLETE_MESSAGE'
  | 'SPLIT_EMPTY_BRANCHES'
  | 'SPLIT_RANDOM_DISTRIBUTION'
  | 'MISSING_TARGETING'
  | 'INVALID_END'

export type FinalNodeProps = {
  id: string
  type: 'FINAL'
  errors: Set<NodeError>
}
export type FinalNodeRecord = RecordOf<FinalNodeProps>
export const FinalNodeFactory = Immutable.Record<FinalNodeProps>({
  id: '',
  type: 'FINAL',
  errors: Immutable.Set(),
})

export type MessageNodeProps = {
  id: string
  type: 'MESSAGE'
  label: string
  messageConfig: MessageConfigRecord
  nextNodeId: string
  errors: Set<NodeError>
  hasQuietTimes?: boolean
}
export type MessageNodeRecord = RecordOf<MessageNodeProps>
export const MessageNodeFactory = Immutable.Record<MessageNodeProps>({
  id: '',
  type: 'MESSAGE',
  messageConfig: MessageConfigFactory(),
  label: '',
  nextNodeId: '',
  errors: Immutable.Set(),
  hasQuietTimes: true,
})

type EventNextProps = {
  triggers: List<EventWithOptionalQueryRecord>
  nextNodeId: string
}
export type EventNextRecord = RecordOf<EventNextProps>
export const EventNextFactory = Immutable.Record<EventNextProps>({
  triggers: Immutable.List<EventWithOptionalQueryRecord>(),
  nextNodeId: '',
})

export type TimerMode = 'for' | 'before' | 'after' | 'until'

export type TimerNodeProps = {
  id: string
  type: 'TIMER'
  mode: TimerMode
  timerReference: string
  timer: AgeRecord
  waitUntilTime: WaitUntilTimeRecord
  nextNodeId: string
  onEvents: List<EventNextRecord>
  errors: Set<NodeError>
}

export type WaitUntilTimeProps = {
  hour: number
  minute: number
  daysOfWeek: Set<number>
}

export type WaitUntilTimeRecord = RecordOf<WaitUntilTimeProps>
export const WaitUntilTimeFactory = Immutable.Record<WaitUntilTimeProps>({
  hour: 11,
  minute: 0,
  daysOfWeek: Immutable.Set<number>([0, 1, 2, 3, 4, 5, 6]),
})

export type TimerNodeRecord = RecordOf<TimerNodeProps>
export const TimerNodeFactory = Immutable.Record<TimerNodeProps>({
  id: '',
  type: 'TIMER',
  mode: 'for',
  timerReference: '',
  waitUntilTime: WaitUntilTimeFactory(),
  timer: AgeFactory({
    inputValue: '1',
    seconds: 86400,
    valid: true,
    unit: 'd',
    label: 'day',
    fullText: '1 day',
  }),
  errors: Immutable.Set(),
  nextNodeId: '',
  onEvents: Immutable.List(),
})

export type YesNoNodeProps = {
  id: string
  type: 'YESNO'
  label: string
  yesNodeId: string
  noNodeId: string
  errors: Set<NodeError>
}
export type YesNoNodeRecord = RecordOf<YesNoNodeProps>
export const YesNoNodeFactory = Immutable.Record<YesNoNodeProps>({
  id: '',
  type: 'YESNO',
  label: '',
  yesNodeId: '',
  noNodeId: '',
  errors: Immutable.Set(),
})

export type SplitBranchProps = {
  weight: number
  nextNodeId: string
}
export type SplitBranchRecord = RecordOf<SplitBranchProps>
export const SplitBranchFactory = Immutable.Record<SplitBranchProps>({
  weight: 0,
  nextNodeId: '',
})
export type RandomNodeProps = {
  id: string
  type: 'RANDOM'
  label: string
  splits: List<SplitBranchRecord>
  errors: Set<NodeError>
}
export type RandomNodeRecord = RecordOf<RandomNodeProps>
export const RandomNodeFactory = Immutable.Record<RandomNodeProps>({
  id: '',
  label: '',
  type: 'RANDOM',
  errors: Immutable.Set(),
  splits: Immutable.List(),
})

export type JourneyNodeRecord =
  | FinalNodeRecord
  | MessageNodeRecord
  | TimerNodeRecord
  | YesNoNodeRecord
  | RandomNodeRecord

export const JourneyNodes = {
  Final: FinalNodeFactory,
  Message: MessageNodeFactory,
  Timer: TimerNodeFactory,
  YesNo: YesNoNodeFactory,
  Random: RandomNodeFactory,
}
export type NodesMap = Immutable.Map<string, JourneyNodeRecord>

// targetNode information
export type BranchId =
  | {
      type: 'ROOT'
    }
  | {
      type: 'MESSAGE'
      stepMessageNodeId: string
    }
  | {
      type: 'TIMER-NEXT'
      timerNodeId: string
    }
  | {
      type: 'TIMER-EVENT'
      timerNodeId: string
      triggerIndex: number
    }
  | {
      type: 'YESNO'
      yesNoNodeId: string
      branch: 'yes' | 'no'
    }
  | {
      type: 'RANDOM'
      randomNodeId: string
      splitIndex: number
    }
  | {
      type: 'REJOIN'
      nodeId: string
    }
