import * as React from 'react'

import { textFeature } from 'components/styled/tokens/colors'

import { dayjs } from 'com.batch.common/dayjs.custom'

export const RemainingIcon = (): React.ReactElement => {
  const radius = 2
  const circumference = 2 * Math.PI * radius
  const now = dayjs().utc()
  const currentDay = now.date()
  const totalDaysInMonth = now.daysInMonth()
  const completionPercent = currentDay / totalDaysInMonth

  return (
    <svg width={16} height={16} viewBox="0 0 16 16" style={{ marginTop: '2px' }}>
      <circle cx={8} cy={8} r={6.5} fill="transparent" stroke={textFeature} strokeWidth={2} />
      <circle
        cx={8}
        cy={8}
        r={radius}
        fill="transparent"
        stroke={textFeature}
        strokeWidth={4}
        strokeDashoffset={circumference - completionPercent * circumference}
        strokeDasharray={circumference}
        transform="rotate(-90 8 8)"
      />
    </svg>
  )
}
