import styled from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

export const ScrollContainer = styled.div`
  max-height: calc(100vh - 420px);
  min-height: 80px;
  border-top: 1px solid ${colors.stroke};
  border-bottom: 1px solid ${colors.stroke};
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: overlay;
`

export const ColumnHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: ${colors.textNeutral};
`

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  margin: 12px 0;
  padding: 4px 0 0 0;
  background: ${schemes.darklucent['05']};
  border-radius: 4px;

  h4 {
    margin: 0 0 6px 0;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.textNeutral};
  }
  p {
    color: ${colors.textLight};
  }
`
