import styled from 'styled-components'

import { colors } from 'components/styled/tokens'

export const BounceReasonRow = styled.div`
  height: 52px;
  display: grid;
  grid-template-rows: 1fr auto;
`
export const BounceReasonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 130px 180px;
  gap: 8px;
  align-content: center;
`

export const BounceReasonCategory = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
  color: ${colors.textNeutral};
`
