import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useParams } from 'react-router-dom'

import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { LinkDocumentation, Title } from 'components/styled/text'

import { capitalize } from 'com.batch.common/utils'

import { EmailKeyMetrics } from '../key-metrics/email-key-metrics'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { statTypeColors } from 'com.batch/orchestration-analytics/constants/stat-type-colors'
import { STAT_TYPE } from 'com.batch/orchestration-analytics/infra/types/stat-type'
import { BounceReportBlock } from 'com.batch/orchestration-analytics/ui/components/bounce-report-block/bounce-report-block'
import { ClicksPerUrlBlock } from 'com.batch/orchestration-analytics/ui/components/clicks-per-url-block/clicks-per-url-block'
import { DeliveryReportBlock } from 'com.batch/orchestration-analytics/ui/components/delivery-report-block'
import {
  StatsBlocksRow,
  StatsContainer,
} from 'com.batch/orchestration-analytics/ui/components/orchestration-analytics/orchestration-analytics.styles'
import { StatsPerMailboxProvider } from 'com.batch/orchestration-analytics/ui/components/stats-per-mailbox-provider/stats-per-mailbox-provider'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { fetchOrchestrationDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { type ChartData } from 'com.batch/shared/infra/types/chart-data'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { STATUS } from 'constants/common'
import { OrchestrationAnalyticsFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { getStatsConfig } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'

export const EmailAnalytics = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { token } = useParams()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const { stats, providers, bounces } = React.useMemo(
    () =>
      (token
        ? analyticsMap.get(token, OrchestrationAnalyticsFactory())
        : OrchestrationAnalyticsFactory()
      )
        .get('channels')
        .get('email'),
    [analyticsMap, token]
  )
  const { filters, dateRange } = useGetAnalyticsFilters(token)
  const categories = React.useMemo(() => bounces.get('categories'), [bounces])

  const loadingState: LoadingState = React.useMemo(
    () => (token ? analyticsMap.get(token)?.emailLoadingState ?? 'INIT' : 'ERROR'),
    [analyticsMap, token]
  )
  const isLoading = React.useMemo(
    () => loadingState === STATUS.LOADING || loadingState === STATUS.INIT,
    [loadingState]
  )
  const isEmpty = React.useMemo(
    () => !stats && loadingState === STATUS.LOADED,
    [stats, loadingState]
  )
  const isOverlayShown = React.useMemo(() => loadingState === STATUS.ERROR, [loadingState])

  const data: ChartData[] = React.useMemo(
    () => [
      {
        value: stats?.delivered.value ?? 0,
        rate: stats?.delivered.rate ?? 0,
        label: capitalize(STAT_TYPE.DELIVERED),
        color: (statTypeColors[STAT_TYPE.DELIVERED] ?? statTypeColors[0]).chart,
      },
      {
        value: stats?.sending.value ?? 0,
        rate: stats?.sending.rate ?? 0,
        label: capitalize(STAT_TYPE.SENDING),
        color: (statTypeColors[STAT_TYPE.SENDING] ?? statTypeColors[0]).chart,
      },
      {
        value: stats?.bounce.value ?? 0,
        rate: stats?.bounce.rate ?? 0,
        label: capitalize(STAT_TYPE.BOUNCED),
        color: (statTypeColors[STAT_TYPE.BOUNCED] ?? statTypeColors[0]).chart,
      },
      {
        value: stats?.skippedSuppressionList.value ?? 0,
        rate: stats?.skippedSuppressionList.rate ?? 0,
        label: capitalize(STAT_TYPE.SKIPPED),
        color: (statTypeColors[STAT_TYPE.SKIPPED] ?? statTypeColors[0]).chart,
      },
    ],
    [stats]
  )

  React.useEffect(() => {
    if (token) {
      const tokens = Immutable.List([token])
      dispatch(
        fetchOrchestrationDynamicStats({
          tokens,
          filters,
          dateRange,
          config: getStatsConfig({ name: 'orchestrationStatsForEmail' }),
          includePreviousPeriod: true,
        })
      ).catch(() => {})
      dispatch(
        fetchOrchestrationDynamicStats({
          tokens,
          filters,
          dateRange,
          config: getStatsConfig({ name: 'orchestrationStatsByClassification' }),
        })
      ).catch(() => {})
    }
  }, [dispatch, token, filters, dateRange])

  return (
    <StatsContainer>
      <ChannelSectionTitle style={{ margin: '0 0 24px', alignItems: 'flex-end' }}>
        <Title>
          <Icon icon="mail" />
          Email
        </Title>
        <LinkDocumentation
          target="_blank"
          href="https://help.batch.com/en/articles/8058036-email-analytics-glossary#h_94c527c82b"
          intent="action"
          style={{ marginLeft: 'auto' }}
        >
          Understand email metrics
        </LinkDocumentation>
      </ChannelSectionTitle>
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={
          loadingState === STATUS.ERROR
            ? GlobalErrorOverlayProps
            : {
                status: 'empty',
                title: `No data for this ${
                  schedulingType === 'campaigns' ? 'campaign' : 'automation'
                }`,
                description: '',
              }
        }
        style={isOverlayShown ? { overflow: 'hidden' } : {}}
        boxed
      >
        <StatsBlocksRow $marginBottom>
          <EmailKeyMetrics stats={stats} context={schedulingType} token={token} />
        </StatsBlocksRow>
      </Wrapper>

      <StatsPerMailboxProvider />

      <StatsBlocksRow style={isOverlayShown ? { flexWrap: 'nowrap' } : {}}>
        <Wrapper
          isLoading={isLoading}
          isEmpty={isEmpty}
          isOverlayShown={isOverlayShown}
          overlayProps={GlobalErrorOverlayProps}
          boxed
          style={isOverlayShown ? { width: 412 } : {}}
        >
          <DeliveryReportBlock stats={data} />
        </Wrapper>
        <Wrapper
          isLoading={isLoading}
          isEmpty={false}
          isOverlayShown={isOverlayShown}
          overlayProps={GlobalErrorOverlayProps}
          boxed
        >
          <BounceReportBlock bounceCategories={categories} providers={providers} token={token} />
        </Wrapper>
      </StatsBlocksRow>
      <ClicksPerUrlBlock schedulingType={schedulingType} />
    </StatsContainer>
  )
}
