import { type Map } from 'immutable'
import { type Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, delay, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators'

import { checkFeatureForCompany } from 'components/_hooks/use-company-feature'

import { generateUrl } from 'com.batch.common/router'

import { type AttributeRecord, type ReduxAction, type State } from 'com.batch.redux/_records'
import { recursiveParseOursql } from 'com.batch.redux/query/query.api.parsing'
import { QueryFactory, type QueryRecord } from 'com.batch.redux/query/query.records'
import {
  availableEventAttributesSelector,
  profileTargetingAttributesSelector,
} from 'com.batch.redux/query/query.selector'

type parseEpicActions =
  | ReduxAction<
      'T_NEW_QUERY',
      {
        query: string
        eventId: string
        queryId: string
        retries: number
      }
    >
  | ReduxAction<
      'T_OURSQL_RESPONSE',
      {
        eventId: string
        queryId: string
        oursql: any
      }
    >
  | ReduxAction<
      'T_OURSQL_RESPONSE_ATTRIBUTE_LOADED',
      {
        eventId: string
        queryId: string
        oursql: any
      }
    >
  | ReduxAction<
      'T_QUERY_PARSED',
      {
        queryId: string
        query: QueryRecord
      }
    >
  | ReduxAction<
      'T_QUERY_FAILED',
      {
        queryId: string
        error: string
      }
    >
  | ReduxAction<'FETCH_ATTRIBUTES_SUCCESS', Map<string, AttributeRecord>>
  | ReduxAction<'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY_SUCCESS', Map<string, AttributeRecord>>

const MAX_RETRIES = 3

// takes a T_NEW_QUERY action, call the API, & returns a T_OURSQL_RESPONSE with the detailed json
export const fetchOursqlEpic = (
  action$: Observable<parseEpicActions>,
  state$: Observable<State>
): Observable<{
  type: string
  payload: any
}> => {
  return action$.pipe(
    filter(action => action.type === 'T_NEW_QUERY'),
    withLatestFrom(state$),
    mergeMap(([action, state]: [any, any]) => {
      const appId = state.app.current.id
        ? state.app.current.id
        : state.app.entities.first()?.id ?? 0

      const callOrchestrationService = checkFeatureForCompany(
        state.company,
        'cep-event-based-targeting-feature'
      )

      const headers = callOrchestrationService
        ? { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' }
        : { 'X-Requested-With': 'XMLHttpRequest' }
      const body = callOrchestrationService ? { query: action.payload.query } : action.payload.query

      const url = callOrchestrationService
        ? generateUrl('api_grpc_orchestration_service', { methodName: 'GetDetailedQuery' })
        : generateUrl('campaign_parse_query', { appId })

      return ajax({
        url,
        method: 'POST',
        headers,
        body,
      }).pipe(
        map(xhr => {
          return {
            type: 'T_OURSQL_RESPONSE',
            payload: {
              eventId: action.payload.eventId,
              queryId: action.payload.queryId,
              oursql: callOrchestrationService ? JSON.parse(xhr.response.query) : xhr.response,
            },
          }
        }),
        catchError(e => {
          console.log(' error', e)
          // @todo we probably should not retry on all errors, some won't recover
          return action.payload.retries < MAX_RETRIES
            ? of({
                type: 'T_NEW_QUERY',
                payload: { ...action.payload, retries: action.payload.retries + 1 },
              }).pipe(delay(action.payload.retries * 900 + 1200))
            : of({
                type: 'T_OURSQL_ERROR',
                payload: { ...action.payload, exception: e },
              })
        })
      )
    })
  )
}

export const replayQueryEpic = (
  action$: Observable<parseEpicActions>,
  state$: Observable<State>
): Observable<{
  type: string
  payload: any
}> => {
  return action$.pipe(filter(action => action.type === 'T_OURSQL_RESPONSE')).pipe(
    withLatestFrom(state$),
    mergeMap(([action, state]: [any, any]) => {
      if (state.target.getIn(['default', 'attributesLoaded'], false)) {
        return of({
          type: 'T_OURSQL_RESPONSE_ATTRIBUTE_LOADED',
          payload: action.payload,
        })
      } else {
        return of(action).pipe(delay(600))
      }
    })
  )
}
export const parseQueryEpic = (
  action$: Observable<parseEpicActions>,
  state$: Observable<State>
): Observable<{
  type: string
  payload: any
}> => {
  return action$.pipe(filter(action => action.type === 'T_OURSQL_RESPONSE_ATTRIBUTE_LOADED')).pipe(
    withLatestFrom(state$),
    map(([action, latestStateValue]: [any, any]) => {
      const attributes =
        action.payload.eventId !== ''
          ? availableEventAttributesSelector(latestStateValue)(action.payload.eventId)
          : profileTargetingAttributesSelector(latestStateValue)
      try {
        const query = recursiveParseOursql({
          profileDataEnabled: latestStateValue.attribute.config.profileDataMode,
          attributes,
          data: action.payload.oursql,
          parsed: QueryFactory({ eventId: action.payload.eventId }),
          isRootNode: true,
          position: 'root',
        })
        return {
          type: 'T_QUERY_PARSED',
          payload: { queryId: action.payload.queryId, query },
        }
      } catch (error: any) {
        console.log(error.message)
        return {
          type: 'T_QUERY_FAILED',
          payload: { queryId: action.payload.queryId, error: error.message },
        }
      }
    })
  )
}
