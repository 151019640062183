import * as React from 'react'
import styled from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import { Tooltip, type placement as TooltipPlacement } from 'components/common/tooltip'
import { colors } from 'components/styled/tokens'

export function Hint({
  children,
  size,
  maxTooltipWidth,
  minTooltipWidth,
  placement = 'top',
  noMargin,
  icon = 'help',
  intent = 'default',
  style,
  tooltipStyle,
  arrow = true,
}: {
  children: React.ReactNode
  size?: number
  maxTooltipWidth?: number
  minTooltipWidth?: number
  placement?: TooltipPlacement
  icon?: 'help' | 'details'
  intent?: 'default' | 'disabled'
  noMargin?: boolean
  style?: any
  tooltipStyle?: any
  arrow?: boolean
}): React.ReactElement {
  return noMargin ? (
    <Tooltip
      tooltip={children}
      placement={placement}
      maxWidth={maxTooltipWidth}
      minWidth={minTooltipWidth}
      arrow={arrow}
      style={tooltipStyle}
    >
      <HintIcon size={size} noMargin={noMargin} style={style} icon={icon} intent={intent} />
    </Tooltip>
  ) : (
    <span style={{ marginLeft: 4 }}>
      <Tooltip
        tooltip={children}
        placement={placement}
        maxWidth={maxTooltipWidth}
        minWidth={minTooltipWidth}
        arrow={arrow}
      >
        <HintIcon size={size} noMargin={noMargin} style={style} icon={icon} intent={intent} />
      </Tooltip>
    </span>
  )
}

type HintIconProps = {
  noMargin?: boolean
  intent: 'default' | 'disabled'
}

export const HintIcon = styled(Icon)<HintIconProps>`
  vertical-align: baseline;
  padding: 6px;
  margin: ${p => (p.noMargin ? '0' : '-6px 0 -6px 0')};
  color: ${p => (p.intent === 'disabled' ? colors.textDisabled : colors.textLight)};
  cursor: pointer;
  &:hover {
    color: ${colors.textNeutral};
  }
`

export default Hint
