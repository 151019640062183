import { type availableIcons } from 'components/common/svg-icon'

import {
  type PlatformKindType,
  type SubscriptionStatus,
} from 'com.batch/profile/models/profile.records'

type getOptinStatusMessagesType = (arg1: {
  kind: PlatformKindType
  size: 'short' | 'expanded'
  subscriptionStatus: SubscriptionStatus
  notifType: string[]
}) => {
  title: string
  icon: availableIcons
  message: string
}

export const getOptinStatusMessages: getOptinStatusMessagesType = ({
  kind,
  subscriptionStatus,
  size,
  notifType,
}) => {
  switch (kind) {
    case 'imported':
      return {
        title: size === 'expanded' ? 'Considered opt-in' : 'Opt-in',
        icon: size === 'expanded' ? 'check-dashed' : 'check',
        message: 'Will be confirmed when the device will be detected',
      }

    default:
      return {
        title:
          subscriptionStatus === 'SUBSCRIBED'
            ? `${size === 'expanded' ? 'Push o' : 'O'}pt-in`
            : `${size === 'expanded' ? 'Push o' : 'O'}pt-out`,
        icon: subscriptionStatus === 'SUBSCRIBED' ? 'check' : 'close',
        message:
          subscriptionStatus === 'SUBSCRIBED' ? notifType.join(', ') : 'No push notifications',
      }
  }
}
