import { type List } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { Box, BoxHeader, HeaderBoxTitle, HeaderBoxActions } from 'components/common/box'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Ellipsis } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { PressureCategoryList } from './pressure.styles'

import { type CappingCategoryRecord } from 'com.batch.redux/_records'
import { updatePressureCategory } from 'com.batch.redux/app.action'

import { createOnKeyDownHandler } from 'com.batch/shared/ui/create-handler-onkeydown'

type AddPressureCategoryProps = {
  categories: List<CappingCategoryRecord>
  close: () => any
  editCategory: (arg1: string | null) => any
}

export const AddPressureCategory = ({
  categories,
  close,
  editCategory,
}: AddPressureCategoryProps): React.ReactElement => {
  const dispatch = useDispatch()

  const createOnAddCategory = React.useCallback(
    (cat: CappingCategoryRecord) => () => {
      const updatedCat = cat.set('dirty', true)
      editCategory(cat.code)
      dispatch(
        updatePressureCategory({
          categorieRecord: cat,
          updated: updatedCat,
        })
      )

      close()
    },
    [dispatch, editCategory, close]
  )

  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle
          title="Add capping"
          suffix={<span style={{ color: '#707782', fontSize: '13px' }}>— Choose a label</span>}
        />
        <HeaderBoxActions>
          <Button kind="inline" intent="neutral" onClick={close}>
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>

      <PressureCategoryList>
        {categories.map(cat => {
          return (
            <div
              key={cat.id}
              onClick={createOnAddCategory(cat)}
              onKeyDown={createOnKeyDownHandler(createOnAddCategory(cat))}
              role="button"
              tabIndex={0}
            >
              <Ellipsis>{cat.name}</Ellipsis>
              <span
                style={cat.count > 0 ? { color: colors.textLight } : { color: colors.textDisabled }}
              >
                {cat.count === 0
                  ? 'No campaign'
                  : `${cat.count} campaign${cat.count > 1 ? 's' : ''}`}
              </span>
            </div>
          )
        })}
      </PressureCategoryList>
    </Box>
  )
}
