import { type Dayjs } from 'dayjs'
import * as React from 'react'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { AlertLabel } from './remaining-day-alert.styles'

import { RemainingIcon } from 'com.batch/account/ui/components/volumes/remaining-icon'
import { Alert } from 'com.batch/shared/ui/component/alert/alert'

type Props = {
  searchingMonth: Dayjs
}

export const RemainingDayAlert = ({ searchingMonth }: Props): React.ReactElement | null => {
  const lastDayCurrentMonth = dayjs().endOf('month')

  const daysToEndMonth = React.useMemo(
    () =>
      searchingMonth.isSame(dayjs().endOf('month'), 'month')
        ? lastDayCurrentMonth.diff(dayjs(), 'day')
        : 0,
    [searchingMonth, lastDayCurrentMonth]
  )

  if (daysToEndMonth)
    return (
      <Alert kind="feature" prefix={<RemainingIcon />}>
        <AlertLabel>The period is still ongoing</AlertLabel>
        <div>
          These metrics are subject to change between now and{' '}
          {lastDayCurrentMonth.format('MMMM DD')}
        </div>
      </Alert>
    )
  return null
}
