import styled from 'styled-components'

import { HeaderBoxTitle } from 'components/common/box'
import { Grid } from 'components/common/grid'
import * as colorsLegacy from 'components/styled/colors'
import { colors, schemes } from 'components/styled/tokens'

export const AppInfosGrid = styled(Grid)`
  grid-template-columns: 1fr;
  grid-column-gap: 0;

  > div:first-of-type {
    border-right: 0;
    border-bottom: 1px solid ${colorsLegacy.stroke.lighter};
  }

  @media (min-width: 766px) {
    grid-template-columns: minmax(350px, 1fr) minmax(220px, 340px);

    > div:first-of-type {
      border-right: 1px solid ${colorsLegacy.stroke.lighter};
      border-bottom: 0;
    }
  }
`

export const AppInfosIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  & > div {
    margin-bottom: 30px;
  }

  & > .styled-button {
    margin-left: 0;

    &:first-of-type {
      margin-bottom: 7px;
    }
  }
`
export const APIKeyContainer = styled.div`
  background: ${schemes.grayscale['01']};
  border-left: 1px solid ${colors.stroke};
  padding: 22px 34px;
  .keyCode {
    border-radius: 6px;
    max-width: 230px;
    width: fit-content;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -2px;
  }
  .key {
    font-family:
      SF Mono,
      monospace,
      serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 15px;
    color: ${colors.textTechDark};
  }
`
export const Labelkey = styled.label`
  color: ${colors.text};
  font-weight: 500;
  font-size: 1em;
  line-height: 16px;
  margin-bottom: 12px;
`

export const KeyColumn = styled.div``

export const AppSettingTitle = styled(HeaderBoxTitle)`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`
