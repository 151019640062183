import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useIsCurrentUserAllowedTo } from 'components/_hooks/use-allowed'
import { SwitchButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { Icon } from 'components/common/svg-icon'

import { getMessageId } from '../../models/message.helper'
import { pickedLanguagesSelector, previewLanguageSelector } from '../../store/message.selector'
import {
  setActiveLanguage,
  activateMultiLanguage,
  deactivateMultiLanguage,
} from '../../usecases/multilanguage'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { orchestrationMetaSelector } from 'com.batch/orchestration/store/orchestration.selectors'

import { saveDragDropHtml } from 'com.batch/email/usecases/update-content'

type Props = {
  stepMessageNodeId: string | null | undefined
  style?: any
  templateId?: string
}
export const MultilanguageToggle = ({
  stepMessageNodeId,
  style,
  templateId,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const getMessageConfig = useSelector(getMessageConfigSelector)
  const config = React.useMemo(() => {
    return getMessageConfig({ stepMessageNodeId })
  }, [getMessageConfig, stepMessageNodeId])
  const pickedLanguages = useSelector(
    pickedLanguagesSelector({
      messageId: getMessageId(config),
      channel: config.channel,
    })
  )
  const { state } = useSelector(orchestrationMetaSelector)
  const activeLanguage = useSelector(previewLanguageSelector)
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])

  const onEnableEmailMultilanguage = React.useCallback(() => {
    confirm({
      title: 'Enable multi-language?',
      message: (
        <p>
          Each template is independent and will be duplicated from the default one when a new
          language is added. Changes made to one template will not affect the others.
        </p>
      ),
      confirm: 'Yes, enable it',
    }).then(
      () => {
        dispatch(activateMultiLanguage({ stepMessageNodeId }))
      },
      () => {}
    )
  }, [dispatch, stepMessageNodeId])

  const onDisableMultilanguage = React.useCallback(() => {
    confirm({
      title: 'Disable multi-language?',
      message: <p>All languages will be deleted except the default one.</p>,
      sensitive: true,
      confirm: 'Yes, disable it',
    }).then(
      () => {
        dispatch(setActiveLanguage('default'))
        dispatch(deactivateMultiLanguage({ stepMessageNodeId }))
      },
      () => {}
    )
  }, [dispatch, stepMessageNodeId])

  const handleMultilanguageOnChange = React.useCallback(
    async (enable: boolean) => {
      if (templateId) {
        try {
          dispatch(
            saveDragDropHtml({ lang: activeLanguage, templateId, messageId: getMessageId(config) })
          )
        } catch (e: any) {
          console.error(e)
        }
      }

      if (enable && config.channel === 'email') {
        onEnableEmailMultilanguage()
      } else if (!enable && pickedLanguages.size > 1) {
        onDisableMultilanguage()
      } else {
        dispatch(
          enable
            ? activateMultiLanguage({ stepMessageNodeId })
            : deactivateMultiLanguage({ stepMessageNodeId })
        )
      }
    },
    [
      templateId,
      config,
      pickedLanguages.size,
      dispatch,
      activeLanguage,
      onEnableEmailMultilanguage,
      onDisableMultilanguage,
      stepMessageNodeId,
    ]
  )

  return (
    <SwitchButton
      addOn="prefix"
      style={{ display: 'flex', justifyContent: 'flex-start', ...style }}
      onChange={handleMultilanguageOnChange}
      isActive={config.multilanguageEnabled}
      disabled={state === 'COMPLETED' || !userHasWritePermission}
      data-track="multilang-toggle"
      data-testid="multilanguage-toggle"
    >
      Multi-language
      <Icon icon="translation" style={{ marginLeft: 8 }} />
    </SwitchButton>
  )
}
