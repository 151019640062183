import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router/router'

import { generateProjectKeyBlockRequest } from '../generate-block-request'

import {
  type OrchestrationChannels,
  type OrchestrationType,
} from 'com.batch/orchestration/infra/types/orchestration.types'

const TIMEOUT_MS = 45000
const CHECK_INTERVAL_MS = 1000

export type ProfileAttributeDefinition = {
  // List of native attributes to be included in the export file.
  attributes: Array<string>
  // List of profile identifiers to be included in the export file.
  identifiers: Array<string>
  // Query to filter the profiles to be included in the export file.
  jsonQuery: string
  // The maximum number of profiles to be included in the export file.
  limit: number
}
// Definition of the export request used for export Profile Events.
// Used by Export API /2.2/profiles/export, type EVENTS.
type ProfileEventDefinition = {
  // The inclusive start date for the export request. Cannot be after the endDate.
  startDate: number
  // The inclusive end date for the export request. Cannot be before the startDate.
  endDate: number
  // List of profile identifiers to be included in the export file.
  identifiers: Array<string>
  // List of events to be included in the export file.
  events: Array<string>
}
type ProfileReachabilityDefinition = {
  // The inclusive start date for the export request. Cannot be after the endDate.
  startDate: number
  // The inclusive end date for the export request. Cannot be before the startDate.
  endDate: number
  // List of profile identifiers to be included in the export file.
  identifiers: Array<string>
  // List of channels to be included in the export file.
  channels: Array<string>
}
export type ExportStatus = 'UNKNOWN' | 'CREATED' | 'PENDING' | 'RUNNING' | 'ERROR' | 'SUCCESS'

type ExportRequest = {
  status: ExportStatus
  files: Array<{
    id: FileID
  }>
}

type Scope =
  | 'SCOPE_UNKNOWN'
  | 'PROFILE_ATTRIBUTES'
  | 'PROFILE_EVENTS'
  | 'PROFILE_REACHABILITY'
  | 'ORCHESTRATION_LIST'

type RequestSource = 'DASHBOARD' | 'SOURCE_UNKNOWN' | 'BOB' | 'API'

export type OrchestrationListDefinition = {
  startDate: string
  endDate: string
  orchestrationTypes: Array<OrchestrationType>
  orchestrationChannels: OrchestrationChannels
  orchestrationStates: Array<campaignStateType>
  orchestrationSources: Array<'DASHBOARD' | 'API'>
  orchestrationLabels: Array<string>
}

type ExportID = {
  // 18 to 27 bytes of a serialized export id (maybe string will work ?)
  data: string
}

type FileID = {
  // 18 to 27 bytes of a serialized export id (maybe string will work ?)
  data: string
}

export const exportService = {
  create: async ({
    projectKey,
    definition,
    source = 'DASHBOARD',
  }: {
    projectKey: string
    source: RequestSource
    definition: {
      scope: Scope
      profileAttribute?: ProfileAttributeDefinition
      orchestrationsList?:
        | ProfileAttributeDefinition
        | ProfileEventDefinition
        | ProfileReachabilityDefinition
        | OrchestrationListDefinition
    }
  }): Promise<string> => {
    const { id } = await request.post<{
      id: ExportID
    }>(generateUrl('api_grpc_export_service', { methodName: 'Create' }), {
      ...generateProjectKeyBlockRequest(projectKey),
      definition,
      source,
    })
    return id.data
  },
  view: async ({
    id,
    projectKey,
  }: {
    id: ExportID
    projectKey: string
  }): Promise<ExportRequest> => {
    const req = await request.post<ExportRequest>(
      generateUrl('api_grpc_export_service', { methodName: 'View' }),
      {
        ...generateProjectKeyBlockRequest(projectKey),
        id,
      }
    )
    return req
  },
  fetchRequestUntilReady: async (
    id: string,
    projectKey: string,
    retryCount: number = 0
  ): Promise<ExportRequest> => {
    if (retryCount > TIMEOUT_MS / CHECK_INTERVAL_MS) {
      throw new Error('timeout')
    }
    const exportRequest = await exportService.view({
      id: { data: id },
      projectKey,
    })
    if (exportRequest.status === 'SUCCESS') {
      return exportRequest
    }
    await new Promise(resolve => setTimeout(resolve, CHECK_INTERVAL_MS))
    return exportService.fetchRequestUntilReady(id, projectKey, retryCount + 1)
  },
}
