import { useMultipleSelection, useCombobox } from 'downshift'
import Immutable, { type List } from 'immutable'
import { debounce, isEqual } from 'lodash-es'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { useWidthObserver, usePopper, useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { SelectDropdown } from './select-dropdown'
import {
  RemovableValueButton,
  selectPopperConfig,
  type SelectMenuPropsType,
  type CommonSelectProps,
} from './select.helper'
import {
  SelectContainer,
  SelectField,
  SelectValueContainer,
  SelectInput,
  SelectClear,
  SelectIndicator,
  SelectPlaceholder,
} from './select.styles'

export type SelectMultiProps<T> = CommonSelectProps<T> & {
  value: List<T>
  loadOptions?: (arg1: string) => Promise<List<T>>
  options?: List<T>
  optionCreator?: (arg1: string) => T
  isLoading?: boolean
  onChange: (arg1: List<T>) => void
  invalid?: boolean
  autoFocus?: boolean
  optionFormatter?: (
    arg1: T,
    arg2: {
      context: 'value' | 'menu'
    }
  ) => React.ReactNode
  noResultText?: string
}

export function SelectMulti<T>({
  placeholder,
  isClearable,
  options,
  optionMenuStyle,
  menuStyle,
  loadOptions,
  isLoading,
  noResultText = 'No options',
  optionCreator,
  optionMenuHeight,
  optionToString,
  menuOffset = 20,
  optionMenuShownCount,
  isDisabled,
  value,
  optionFormatter,
  onChange,
  ariaLabel,
  ...rest
}: SelectMultiProps<T>): React.ReactElement {
  // popper config for menu placement
  const [triggerRef, popperRef, popperInstance] = usePopper(selectPopperConfig)

  const [localOptions, setLocalOptions] = React.useState<List<T>>(options ?? Immutable.List())

  // ensure we populate the dropdown when options is not filled on first render
  React.useEffect(() => {
    setLocalOptions(options ?? Immutable.List())
  }, [options])

  const [inputValue, setInputValue] = React.useState('')

  const loadOptionsDebounced = React.useRef(
    debounce(
      (q: string) => {
        if (loadOptions) {
          loadOptions(q).then(options => {
            setLocalOptions(options)
            popperInstance?.update()
          })
        }
      },
      200,
      { trailing: true }
    )
  )

  // new option we'll be able to create if no match
  const newOption = React.useMemo(() => {
    return localOptions.size === 0 && inputValue && optionCreator ? optionCreator(inputValue) : null
  }, [inputValue, optionCreator, localOptions])
  const items = React.useMemo(
    () => (newOption ? [newOption] : localOptions.toArray()),
    [localOptions, newOption]
  )
  // selection management -> we should only update picked options with this selectedItems array
  const {
    getSelectedItemProps,
    addSelectedItem,
    getDropdownProps,
    removeSelectedItem,
    selectedItems,
    reset,
    setSelectedItems,
  } = useMultipleSelection({
    initialSelectedItems: value.toArray(),
    onSelectedItemsChange: ({ selectedItems }) => {
      onChange(Immutable.List(selectedItems))
      popperInstance?.forceUpdate()
    },
  })

  // refresh selecteditems when they change from outside (redux, async loading...)
  React.useEffect(() => {
    const downshiftSelectedStrings = selectedItems.map(optionToString).sort()
    const valueStrings = value.map(optionToString).toArray().sort()

    if (!isEqual(downshiftSelectedStrings, valueStrings)) {
      setSelectedItems(value.toArray())
    }
    // we skip the deps check cause we only want to trigger this when value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, optionToString, setSelectedItems])

  const focusedState = useToggle()
  const updateLocalOptions = React.useCallback(
    (inputValue: string) => {
      setInputValue(inputValue)
      if (loadOptions) {
        loadOptionsDebounced.current(inputValue)
      } else {
        setLocalOptions(
          (options ? options : Immutable.List<T>()).filter(option =>
            optionToString(option).toLowerCase().startsWith(inputValue.toLowerCase())
          )
        )
      }
    },
    [loadOptions, optionToString, options]
  )
  const inputRef = React.useRef<HTMLInputElement>(null)

  /* 
    normaly we would just check if selectedItem is in our immutable list
    but legacy query builder is not immutable, so we need this hack
  */
  const isOptionSelected = React.useCallback(
    (option: T) => {
      return value.filter(opt => optionToString(opt) === optionToString(option)).size > 0
    },
    [optionToString, value]
  )
  const stateReducer = React.useCallback(
    (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          updateLocalOptions(changes.inputValue)
          return { ...changes, inputValue: changes.inputValue }
        case useCombobox.stateChangeTypes.InputFocus:
          return { ...changes, isOpen: Boolean(changes.inputValue) }
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (changes.selectedItem && !isOptionSelected(changes.selectedItem)) {
            addSelectedItem(changes.selectedItem)
            updateLocalOptions('')
          } else {
            const foundItem = selectedItems.find(
              item => optionToString(item) === optionToString(changes.selectedItem)
            )
            if (foundItem) removeSelectedItem(foundItem)
            // on first remove from dropdown menu, input loses focus, dunno why. ugly hack to please design team.
            setTimeout(() => inputRef.current?.focus(), 0)
          }

          return { ...changes, inputValue: '', isOpen: Boolean(changes.inputValue) }
        case useCombobox.stateChangeTypes.InputBlur:
          updateLocalOptions('')
          return {
            ...changes,
            inputValue: '',
            highlightedIndex: localOptions.findIndex(value => value === changes.selectedItem) ?? 0,
            isOpen:
              !!changes.selectedItem ||
              (type !== useCombobox.stateChangeTypes.InputBlur && state.isOpen), // keep the menu open after selection.
          }
      }
      return changes
    },
    [
      addSelectedItem,
      isOptionSelected,
      localOptions,
      optionToString,
      removeSelectedItem,
      selectedItems,
      updateLocalOptions,
    ]
  )

  const onIsOpenChange = React.useCallback(
    changes => {
      if (changes.isOpen) {
        inputRef?.current?.focus()
        popperInstance?.update()
        updateLocalOptions(inputValue)
      } else {
        focusedState.close()
      }
    },
    [focusedState, inputValue, popperInstance, updateLocalOptions]
  )

  const {
    getToggleButtonProps,
    getMenuProps,
    isOpen,
    closeMenu,
    setHighlightedIndex,
    highlightedIndex,
    getItemProps,
    openMenu,
    getInputProps,
  } = useCombobox({
    items,
    itemToString: optionToString,
    defaultInputValue: '',
    selectedItem: null, // useMultipleSelection handle this
    stateReducer,
    onIsOpenChange,
  })

  const createRemoveButtonClickHandler = React.useCallback(
    selectedItem => (evt: any) => {
      evt.stopPropagation()
      evt.preventDefault()
      removeSelectedItem(selectedItem)
      onChange(
        value.filter(
          // this is needed because code not always immutable
          // ex: bug on legacy language picker, language get updated for userbase pcent after initial load
          option => optionToString(option) !== optionToString(selectedItem)
        )
      )
    },
    [removeSelectedItem, value, onChange, optionToString]
  )

  const onClear = React.useCallback(
    evt => {
      evt?.stopPropagation()
      reset()
      closeMenu()
    },
    [closeMenu, reset]
  )

  const theme = useTheme()
  const computedDisabled = React.useMemo(() => {
    return Boolean(isDisabled || theme?.disabledMode)
  }, [isDisabled, theme?.disabledMode])

  const showClearButton = React.useMemo(
    () => isClearable && value.size > 0 && !computedDisabled,
    [computedDisabled, isClearable, value.size]
  )
  const { innerRef, ...menuProps }: SelectMenuPropsType = getMenuProps({
    ref: popperRef,
    refKey: 'innerRef',
  })
  const buttonProps = getToggleButtonProps({})

  const inputProps = getDropdownProps(
    getInputProps({
      ref: inputRef,
      disabled: computedDisabled,
      autoFocus: rest?.autoFocus ?? false,
      onBlur: () => {
        focusedState.close()
      },
      'aria-label': ariaLabel,
      onFocus: () => {
        inputRef.current?.select()
        focusedState.open()
        if (!isOpen) {
          popperInstance?.update()
          openMenu()
        }
      },
    })
  )
  const width = useWidthObserver(triggerRef, 200)
  return (
    <SelectContainer
      {...rest}
      ref={triggerRef}
      isFocused={focusedState.value || isOpen}
      isDisabled={computedDisabled}
    >
      <SelectField template={`minmax(100px, 1fr) ${showClearButton ? '30px' : ''} 30px`} gap={0}>
        <SelectValueContainer isMulti onClick={openMenu} hasValue={value.size > 0}>
          {value.size > 0
            ? selectedItems.map((selectedItem, index) => (
                <RemovableValueButton
                  key={index}
                  {...getSelectedItemProps({ selectedItem, index })}
                  onClick={createRemoveButtonClickHandler(selectedItem)}
                >
                  {!optionFormatter
                    ? optionToString(selectedItem)
                    : optionFormatter(selectedItem, { context: 'value' })}
                </RemovableValueButton>
              ))
            : !inputValue && (
                <SelectPlaceholder isDisabled={Boolean(isDisabled)}>
                  {placeholder}
                </SelectPlaceholder>
              )}

          <SelectInput
            {...inputProps}
            value={inputValue}
            isOpen={isOpen || focusedState.value}
            isSearchable={focusedState.value}
            isMulti={value.size > 0}
            style={{ left: 0 }}
            isEmpty={value.size === 0}
          />
        </SelectValueContainer>
        {showClearButton && (
          <SelectClear onClick={onClear}>
            <Button kind="discreet" style={{ height: 34 }} aria-label="Clear select">
              <Icon icon="close" />
            </Button>
          </SelectClear>
        )}
        <SelectIndicator
          {...buttonProps}
          kind="discreet"
          disabled={Boolean(isDisabled)}
          aria-label="Toggle select"
        >
          <Icon icon="select" />
        </SelectIndicator>
      </SelectField>
      <SelectDropdown
        {...menuProps}
        innerRef={innerRef}
        getItemProps={getItemProps}
        optionMenuStyle={optionMenuStyle}
        menuStyle={menuStyle}
        optionMenuHeight={optionMenuHeight}
        optionToString={optionToString}
        width={width + menuOffset}
        noResultNode={
          isLoading ? undefined : inputValue ? (
            <React.Fragment>
              <Icon size={16} style={{ marginRight: 8 }} thickness={0.3} icon="no-result" />{' '}
              <p style={{ fontSize: 14 }}>{noResultText}</p>
            </React.Fragment>
          ) : (
            placeholder
          )
        }
        optionFormatter={optionFormatter}
        showCheckbox
        creatableOption={isLoading ? null : newOption}
        optionMenuShownCount={optionMenuShownCount}
        options={isLoading ? Immutable.List() : localOptions}
        isOpen={isOpen}
        highlightedIndex={highlightedIndex}
        setHighlightedIndex={setHighlightedIndex}
        // isOptionSelected={(option: T) => value.includes(option)}
        // @TODO : ce comportement est là pour le vieux query builder, qui met le binz dans les pointeurs
        isOptionSelected={isOptionSelected}
      />
    </SelectContainer>
  )
}
