import * as React from 'react'
import { createPortal } from 'react-dom'

type DumbPortalProps = {
  children: React.ReactNode
}

export const DumbPortal: React.ComponentType<DumbPortalProps> = React.memo(({ children }) => {
  {
    const dest = document.getElementById('modal-root')
    if (dest !== null) {
      return createPortal(children, dest)
    } else {
      return null
    }
  }
})
