import Immutable, { type RecordOf, type Map } from 'immutable'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { type PlatformType, type SourceType } from 'com.batch/profile/models/profile.records'

export type OrchestrationMetadataProps = {
  orchestrationID: string
  orchestrationName: string
  orchestrationType?:
    | 'ORCHESTRATION_TYPE_ONETIME'
    | 'ORCHESTRATION_TYPE_TRIGGER'
    | 'ORCHESTRATION_TYPE_RECURRENT'
    | 'ORCHESTRATION_TYPE_SEND_TEST'
    | 'ORCHESTRATION_TYPE_TRANSACTIONAL'
  stepID: string
  stepName: string
  contentLanguage: string
  variantID: string
}

export const OrchestrationMetadataFactory = Immutable.Record<OrchestrationMetadataProps>({
  orchestrationID: '',
  orchestrationName: '',
  orchestrationType: undefined,
  stepID: '',
  stepName: '',
  contentLanguage: '',
  variantID: '',
})

export type OrchestrationMetadataRecord = RecordOf<OrchestrationMetadataProps>

export type ProfileMetadataProps = {
  profileID: string
  customID: string
  emailAddress: string
  phoneNumber: string
}

export const ProfileMetadataFactory = Immutable.Record<ProfileMetadataProps>({
  profileID: '',
  customID: '',
  emailAddress: '',
  phoneNumber: '',
} as ProfileMetadataProps)

export type ProfileMetadataRecord = RecordOf<ProfileMetadataProps>

export type AdditionalDataProps = {
  bounceType: string
  bounceCode: string
  bounceCodeLabel: string
}

export const AdditionalDataFactory = Immutable.Record<AdditionalDataProps>({
  bounceType: '',
  bounceCode: '',
  bounceCodeLabel: '',
} as AdditionalDataProps)

export type AdditionalDataRecord = RecordOf<AdditionalDataProps>

export type EmailAdditionalDataProps = {
  mailID: string
  mailboxProvider: string
  clickURL: string
  clickURLName: string
}

export const EmailAdditionalDataFactory = Immutable.Record<EmailAdditionalDataProps>({
  mailID: '',
  mailboxProvider: '',
  clickURL: '',
  clickURLName: '',
} as EmailAdditionalDataProps)

export type EmailAdditionalDataRecord = RecordOf<EmailAdditionalDataProps>

export type SMSAdditionalDataProps = {
  smsID: string
  countryCode: string
  nbParts: number
}

export const SMSAdditionalDataFactory = Immutable.Record<SMSAdditionalDataProps>({
  smsID: '',
  countryCode: '',
  nbParts: 0,
} as SMSAdditionalDataProps)

export type SMSAdditionalDataRecord = RecordOf<SMSAdditionalDataProps>

export type PushAdditionalDataProps = {
  transmissionID: string
  transmissionUnitID: string
  apiKey: string
  platform: string
  installID: string
}

export const PushAdditionalDataFactory = Immutable.Record<PushAdditionalDataProps>({
  transmissionID: '',
  transmissionUnitID: '',
  apiKey: '',
  platform: '',
  installID: '',
} as PushAdditionalDataProps)

export type PushAdditionalDataRecord = RecordOf<PushAdditionalDataProps>

export type Metric = 'SENT' | 'DELIVERED' | 'OPEN' | 'CLICK'

type CustomEventProps = {
  id: string
  name: string
  label: string
  platform?: PlatformType
  attributes: Map<string, QueryValue>
  tags: Map<string, Array<string>>
  sendDate: string
  source: SourceType
  isReattribution: boolean
}
export const CustomEventFactory = Immutable.Record<CustomEventProps>({
  id: '',
  name: '',
  label: '',
  platform: undefined,
  attributes: Immutable.Map(),
  tags: Immutable.Map(),
  sendDate: '',
  source: 'PROFILE_API',
  isReattribution: false,
} as CustomEventProps)

export type CustomEventRecord = RecordOf<CustomEventProps>

type MessagingEventProps = {
  eventID: string
  metric: Metric
  eventDate: number
  channel: 'sms' | 'email' | 'push'
  orchestrationMetadata: OrchestrationMetadataRecord
  profileMetadata: ProfileMetadataRecord
  additionalData?: AdditionalDataRecord
  emailAdditionalData?: EmailAdditionalDataRecord
  smsAdditionalData?: SMSAdditionalDataRecord
  pushAdditionalData?: PushAdditionalDataRecord
}
export const MessagingEventFactory = Immutable.Record<MessagingEventProps>({
  eventID: '',
  metric: 'SENT',
  eventDate: 0,
  channel: 'email',
  orchestrationMetadata: OrchestrationMetadataFactory(),
  profileMetadata: ProfileMetadataFactory(),
  additionalData: undefined,
  emailAdditionalData: undefined,
  smsAdditionalData: undefined,
  pushAdditionalData: undefined,
} as MessagingEventProps)

export type MessagingEventRecord = RecordOf<MessagingEventProps>

type EventProps = {
  customEvent?: CustomEventRecord
  messagingEvent?: MessagingEventRecord
}
export const EventFactory = Immutable.Record<EventProps>({
  customEvent: undefined,
  messagingEvent: undefined,
} as EventProps)

export type EventRecord = RecordOf<EventProps>
