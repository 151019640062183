import * as React from 'react'
import styled from 'styled-components'

import { TableTemplateCell } from 'components/common/table'

export const EmptyLabelRow: React.ComponentType<Record<any, any>> = React.memo(
  (): React.ReactElement => {
    return (
      <React.Fragment>
        <TableTemplateCell template="1fr" />
        <TableTemplateCell template="1fr" />
        <TableTemplateCell template="1fr" />
      </React.Fragment>
    )
  }
)

export const PerviewFormContainer = styled.div`
  padding: 10px 14px;
  background: #fafafa;
  border: 1px solid #e3e3e3;
  box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
`
