import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Checkbox, Form, Input, InputWrapper } from 'components/form'
import { Link, LinkArrow } from 'components/styled/text'

import { generateUrl, trimUnsecureDomain } from 'com.batch.common/router'
import { isEmail } from 'com.batch.common/utils'

import { getLoginMethod, getSSOUrl, login } from './api'
import { AuthError, LoginPage } from './login.styles'

import { AuthStep, AuthStepFooter, Text, Title } from '../auth.styles'

import { Copyable } from 'com.batch/shared/ui/component/copy/copyable'

export type LoginMethod = 'classic' | 'sso'

type LoginProps = {
  isFullForm?: boolean
}

export type ErrorSSO = {
  code: number
  message: string
  isTechnical: boolean
}

export const Login = ({ isFullForm = false }: LoginProps): React.ReactElement | null => {
  const [ssoError, setSsoError] = React.useState<ErrorSSO | null>(window.ssoError ?? null)
  const [form, setForm] = React.useState<{
    email: string
    password: string
  }>({
    email: '',
    password: '',
  })
  const [rememberMe, setRememberMe] = React.useState<boolean>(true)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [formErrors, setFormErrors] = React.useState<Map<string, string>>(Immutable.Map())
  const [errorExpanded, setErrorExpanded] = React.useState<boolean>(false)
  const navigate = useNavigate()

  const handleChange = React.useCallback(
    ({ target }) => {
      setForm({ ...form, [target.name]: target.value })
    },
    [form]
  )

  const handleChangeRememberMe = React.useCallback(() => {
    setRememberMe(!rememberMe)
  }, [rememberMe])

  const getRedirectParam = () => {
    const params = new URLSearchParams(window.location.search)
    return params.has('redirect-to') ? { 'redirect-to': params.get('redirect-to') } : {}
  }

  const handleClickNotYou = React.useCallback(() => {
    navigate(generateUrl('login', getRedirectParam()))
    setForm({ ...form, email: '' })
  }, [navigate, form])

  const redirectToSSOURL = React.useCallback(() => {
    const params = new URLSearchParams(window.location.search)
    const redirectTo = params.get('redirect-to')
      ? trimUnsecureDomain(params.get('redirect-to') ?? '')
      : generateUrl('dashboard')

    getSSOUrl(form.email, redirectTo, false).then(
      (url: string) => {
        window.location.href = url
      },
      err => {
        setSsoError(err)
        setLoading(false)
      }
    )
  }, [form.email])

  const redirectToLoginURL = React.useCallback(() => {
    setLoading(true)
    getLoginMethod(form.email).then(
      (method: LoginMethod) => {
        if (method === 'sso') {
          redirectToSSOURL()
        } else {
          setLoading(false)
          navigate(generateUrl('login_password', getRedirectParam()))
        }
      },
      err => {
        setFormErrors(Immutable.Map(err.map(e => [e.field, e.message])))
        setLoading(false)
      }
    )
  }, [form.email, navigate, redirectToSSOURL])

  const submitLogin = React.useCallback(() => {
    const err: [string, string][] = []
    if (form.password.trim() === '') err.push(['password', 'You must enter a password.'])
    const formErrors = Immutable.Map(err)
    setFormErrors(formErrors)
    if (formErrors.size === 0) {
      setLoading(true)
      login({ ...form, rememberMe }).then(
        (mustConfirmTwoFactorAuth: boolean) => {
          const params = new URLSearchParams(window.location.search)
          if (mustConfirmTwoFactorAuth) {
            window.location.href = generateUrl('login_2fa', getRedirectParam())
          } else {
            const redirectTo = params.get('redirect-to')
            window.location.href = redirectTo
              ? trimUnsecureDomain(redirectTo)
              : generateUrl('dashboard')
          }
        },
        err => {
          setFormErrors(Immutable.Map(err.map(e => [e.field, e.message])))
          setLoading(false)
        }
      )
    }
  }, [form, rememberMe])

  const submitLoginMethod = React.useCallback(() => {
    const err: [string, string][] = []
    if (!isEmail(form.email)) err.push(['email', 'You must enter a valid email address.'])
    const formErrors = Immutable.Map(err)
    setFormErrors(formErrors)
    if (formErrors.size === 0) {
      redirectToLoginURL()
    }
  }, [form.email, redirectToLoginURL])

  const onSubmit = React.useCallback(() => {
    setSsoError(null)
    if (isFullForm) {
      submitLogin()
    } else {
      submitLoginMethod()
    }
  }, [isFullForm, submitLogin, submitLoginMethod])

  const onClickExpandError = React.useCallback(() => {
    setErrorExpanded(!errorExpanded)
  }, [setErrorExpanded, errorExpanded])

  const computedError: React.ReactNode = React.useMemo(() => {
    if (formErrors.get('email')) return formErrors.get('email')
    if (ssoError) {
      return (
        <AuthError>
          An error occurred during your login, please try again or contact our team.
          <Button
            aria-label="Expand error"
            type="button"
            kind="discreet"
            onClick={onClickExpandError}
          >
            <Icon icon={errorExpanded ? 'chevron-up' : 'chevron-down'} />
          </Button>
          {errorExpanded && (
            <Copyable
              notificationText="Error copied"
              value={`${ssoError.message} (${ssoError.code})`}
            />
          )}
        </AuthError>
      )
    }
    return null
  }, [errorExpanded, formErrors, onClickExpandError, ssoError])

  React.useEffect(() => {
    if (isFullForm && !isEmail(form.email)) {
      setForm({ ...form, email: '' })
      navigate(generateUrl('login', getRedirectParam()))
    }
  }, [isFullForm, form.email, form, navigate])

  return (
    <LoginPage>
      <AuthStep>
        <Title>Login</Title>
        <Text>
          Welcome back! Please use your credentials to log into your account. <br />
          <LinkArrow as={RouterLink} to={generateUrl('register')} intent="action">
            Don't have an account? Create one here
          </LinkArrow>
        </Text>

        <Form onSubmit={onSubmit}>
          <InputWrapper feedback={computedError}>
            <Input
              placeholder="Email"
              invalid={formErrors.has('email')}
              type="email"
              value={form.email}
              disabled={isFullForm}
              name="email"
              onChange={handleChange}
              suffix={
                isFullForm
                  ? { kind: 'text', value: 'Not you?', handler: handleClickNotYou }
                  : undefined
              }
              autoComplete
              aria-label="Email address"
            />
          </InputWrapper>
          {isFullForm && (
            <React.Fragment>
              <InputWrapper feedback={formErrors.get('password')} style={{ marginTop: 8 }}>
                <Input
                  placeholder="Password"
                  invalid={formErrors.has('password')}
                  type="password"
                  value={form.password}
                  name="password"
                  onChange={handleChange}
                  autoComplete
                  autoFocus
                  aria-label="Password"
                />
              </InputWrapper>
              <div style={{ paddingTop: 8, textAlign: 'right' }}>
                <Link href={generateUrl('password_reset_request')}>Forgot your password?</Link>
              </div>
            </React.Fragment>
          )}
          <AuthStepFooter
            style={{
              display: 'flex',
              justifyContent: isFullForm ? 'space-between' : 'flex-end',
              alignItems: 'center',
            }}
          >
            {isFullForm && (
              <Checkbox
                label="Keep me logged in"
                checked={rememberMe}
                onChange={handleChangeRememberMe}
              />
            )}
            <Button
              kind="primary"
              intent="action"
              isLoading={loading}
              style={{ minWidth: 100 }}
              type="submit"
            >
              {isFullForm ? 'Log in' : 'Continue'}
            </Button>
          </AuthStepFooter>
        </Form>
      </AuthStep>
    </LoginPage>
  )
}
