import { addClassNamesToElement } from '@lexical/utils'
import {
  TextNode,
  type NodeKey,
  type EditorConfig,
  type LexicalNode,
  type SerializedTextNode,
} from 'lexical'

export class TagNode extends TextNode {
  constructor(text: string, key?: NodeKey) {
    super(text, key)
  }

  static getType(): string {
    return 'batch-tag'
  }

  static clone(node: TagNode): TagNode {
    return new TagNode(node.__text, node.__key)
  }

  exportJSON(): SerializedTextNode {
    const json = super.exportJSON()
    json.type = TagNode.getType()
    return json
  }

  static importJSON(node: SerializedTextNode): TagNode {
    return new TagNode(node.text)
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config)
    addClassNamesToElement(element, 'batch_templating__tag')
    return element
  }

  updateDOM(prevNode: TextNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config)
    return isUpdated || !$isTagNode(prevNode)
  }
}

export function $createTagNode(text: string): TagNode {
  return new TagNode(text)
}

export function $isTagNode(node?: LexicalNode | null): boolean {
  return node instanceof TagNode
}
