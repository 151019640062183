import Immutable, { type RecordOf, type List, type Map, type OrderedMap } from 'immutable'

import {
  type fetchingState,
  type RegionRecord,
  type LanguageRecord,
  RegionFactory,
  LanguageFactory,
} from 'com.batch.redux/_records'

import {
  type Platform,
  type QueryValue,
  type Browser,
} from 'com.batch/profile/infra/debug/models/shared.model'
import { type EventRecord } from 'com.batch/profile/models/event.records'
import { STATUS } from 'constants/common'

export type PlatformType = 'ANDROID' | 'IOS' | 'WEBPUSH'
/** SDK source type is deprecated **/
export type SourceType = 'ANDROID' | 'IOS' | 'WEBPUSH' | 'API' | 'PROFILE_API' | 'SDK'

export type SubscriptionStatus = 'SUBSCRIBED' | 'UNSUBSCRIBED' | 'UNKNOWN'

export type SubscriptionEmailProps = {
  status: SubscriptionStatus
  lastEmailOpened: string | null | undefined
  lastEmailClicked: string | null | undefined
}
export const SubscriptionEmailFactory = Immutable.Record<SubscriptionEmailProps>({
  status: 'UNKNOWN',
  lastEmailOpened: null,
  lastEmailClicked: null,
} as SubscriptionEmailProps)

export type SubscriptionEmailRecord = RecordOf<SubscriptionEmailProps>

export type SubscriptionSmsProps = {
  status: SubscriptionStatus
}

export const SubscriptionSmsFactory = Immutable.Record<SubscriptionSmsProps>({
  status: 'UNKNOWN',
} as SubscriptionSmsProps)

export type SubscriptionSmsRecord = RecordOf<SubscriptionSmsProps>

export type SubscriptionPushProps = {
  status: SubscriptionStatus
}

export const SubscriptionPushFactory = Immutable.Record<SubscriptionPushProps>({
  status: 'UNSUBSCRIBED',
})
export type SubscriptionPushRecord = RecordOf<SubscriptionPushProps>

export type SubscriptionProps = {
  emailMarketing: SubscriptionEmailRecord
  emailService: SubscriptionEmailRecord
  smsMarketing: SubscriptionSmsRecord
  smsService: SubscriptionSmsRecord
  push: SubscriptionPushRecord
}
export const SubscriptionFactory = Immutable.Record<SubscriptionProps>({
  emailMarketing: SubscriptionEmailFactory(),
  emailService: SubscriptionEmailFactory(),
  smsMarketing: SubscriptionSmsFactory(),
  smsService: SubscriptionSmsFactory(),
  push: SubscriptionPushFactory(),
} as SubscriptionProps)
export type SubscriptionRecord = RecordOf<SubscriptionProps>

type IdentityProps = {
  customUserId: string
  region: RegionRecord
  language: LanguageRecord
}
export const IdentityFactory = Immutable.Record<IdentityProps>({
  customUserId: '',
  region: RegionFactory(),
  language: LanguageFactory(),
} as IdentityProps)

export type IdentityRecord = RecordOf<IdentityProps>

export type InstallProps = {
  type: string
  isImported: boolean
  marketingName: string | null | undefined
  installID: string
  platform: Platform
  pushToken: string
  subscriptionStatus: SubscriptionStatus
  notifType: string[]
  lastActivity: string
}
export const InstallFactory = Immutable.Record<InstallProps>({
  type: '',
  isImported: false,
  marketingName: '',
  installID: '',
  platform: 'IOS',
  pushToken: '',
  subscriptionStatus: 'UNKNOWN',
  notifType: [],
  lastActivity: '',
})
export type InstallRecord = RecordOf<InstallProps>

export type LastVisitProps = {
  platform: Platform
  date: string
}
export const LastVisitFactory = Immutable.Record<LastVisitProps>({
  platform: 'IOS',
  date: '',
})
export type LastVisitRecord = RecordOf<LastVisitProps>

// ======
export type ProfileWebpushPlatormProps = {
  browser: Browser
  browserType: string
  browserVersion: string
  installId: string
  lastActivity: string
  platform: Platform
  pushToken: string
  subscriptionStatus: SubscriptionStatus
}
export const ProfileWebpushPlatformFactory = Immutable.Record<ProfileWebpushPlatormProps>({
  browser: 'UNKNOWN',
  browserType: '',
  browserVersion: '',
  installId: '',
  lastActivity: '',
  platform: 'WEBPUSH',
  pushToken: '',
  subscriptionStatus: 'UNKNOWN',
})
export type ProfileWebpushPlatformsRecord = RecordOf<ProfileWebpushPlatormProps>

// =====================
export type ProfilePlatformsProps = {
  mobile: Map<string, InstallRecord>
  webpush: Map<string, ProfileWebpushPlatformsRecord>
}
export const ProfilePlatformsFactory = Immutable.Record<ProfilePlatformsProps>({
  mobile: Immutable.Map(),
  webpush: Immutable.Map(),
} as ProfilePlatformsProps)
export type ProfilePlatformsRecord = RecordOf<ProfilePlatformsProps>

// ======================
type ProfileDataProps = {
  identity: IdentityRecord
  email: string
  phoneNumber: string
  lastVisit: LastVisitRecord
  lastActivity: string
  subscription: SubscriptionRecord
  attributes: Map<string, QueryValue>
  platforms: ProfilePlatformsRecord
  id: string
  events: OrderedMap<string, List<EventRecord>>
  eventsLoadingState: fetchingState
  timezone: string | null | undefined
}
export const ProfileDataFactory = Immutable.Record<ProfileDataProps>({
  identity: IdentityFactory(),
  email: '',
  phoneNumber: '',
  lastVisit: LastVisitFactory(),
  lastActivity: '',
  subscription: SubscriptionFactory(),
  attributes: Immutable.Map(),
  platforms: ProfilePlatformsFactory(),
  id: '',
  events: Immutable.OrderedMap(),
  eventsLoadingState: STATUS.INIT,
  timezone: null,
} as ProfileDataProps)
export type ProfileDataRecord = RecordOf<ProfileDataProps>

// ====================== REDUCER ====================== //
type ProfileStateProps = {
  loadingState: fetchingState
  data: OrderedMap<string, ProfileDataRecord>
  profileId: string
}

export const ProfileStateFactory = Immutable.Record<ProfileStateProps>({
  loadingState: STATUS.INIT,
  data: Immutable.OrderedMap().set('', ProfileDataFactory()),
  profileId: '',
} as ProfileStateProps)
export type ProfileStateRecord = RecordOf<ProfileStateProps>

// ====================== OTHER RECORD ====================== //
export type PlatformKindType = 'native' | 'imported'
