import styled, { css } from 'styled-components'

import { LoadingSvgAnim } from 'components/common/empty-states'
import { schemes } from 'components/styled/tokens'

type BarPathProps = {
  isLoading?: boolean
  isEmpty?: boolean
}
export const BarPath = styled.path<BarPathProps>`
  transition: opacity 0.2s ease;

  &.hovered {
    transition: opacity 0.2s ease;
  }

  ${props =>
    props.isLoading &&
    css`
      animation-name: ${LoadingSvgAnim};
      animation-duration: 2s;
      animation-iteration-count: infinite;
    `}

  ${props =>
    props.isEmpty &&
    css`
      fill: ${schemes.grayscale['10']};
    `}
`
