import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { Input, Select } from 'components/form'

import { singular } from 'com.batch.common/utils'

import { type CappingRuleRecord, type DurationUnit } from 'com.batch/capping/model/capping-rule'
import {
  ConditionRow as ConditionRowStyle,
  ValueContainer,
} from 'com.batch/capping/ui/components/capping.styles'

export type DurationUnitOption = {
  label: string
  value: DurationUnit
}

export type RuleProps = {
  cappingRule: CappingRuleRecord
  onChangeInput: (event: React.SyntheticEvent<HTMLInputElement>) => void
  onChangeDropdown: (option?: DurationUnitOption | null | undefined) => void
  isEditing: boolean
  testId?: string
}

const durationUnitOptions: List<DurationUnitOption> = Immutable.List([
  { label: 'hours', value: 'HOURS' },
  { label: 'days', value: 'DAYS' },
])

export const MAXIMUM_HOURS = 720
export const MAXIMUM_DAYS = 30

export const Rule = ({
  cappingRule,
  onChangeInput,
  onChangeDropdown,
  isEditing,
  testId,
}: RuleProps): React.ReactElement => {
  const handleInputChange = React.useCallback(
    (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChangeInput(event as React.SyntheticEvent<HTMLInputElement>)
    },
    [onChangeInput]
  )

  const optionToString = React.useCallback(
    (option?: DurationUnitOption | null): string => {
      if (!option) return ''

      return cappingRule.durationValue === 1 ? singular(option.label) : option.label
    },
    [cappingRule.durationValue]
  )

  return (
    <ThemeProvider theme={{ isEmpty: false }}>
      <ConditionRowStyle data-testid={testId}>
        Send no more than&nbsp;
        {isEditing ? (
          <Input
            value={cappingRule.capping}
            onChange={handleInputChange}
            type="number"
            style={{ width: '48px' }}
            inputStyle={{ textAlign: 'left', padding: '8px' }}
            disabled={!isEditing}
            name="capping"
            min={1}
            data-testid={`input_capping_${testId ?? ''}`}
            invalid={cappingRule.capping < 1 || !cappingRule.capping}
          />
        ) : (
          <ValueContainer>{cappingRule.capping}</ValueContainer>
        )}
        message(s) every&nbsp;
        {isEditing ? (
          <Input
            value={cappingRule.durationValue}
            onChange={handleInputChange}
            type="number"
            style={{ width: '48px' }}
            inputStyle={{ textAlign: 'left', padding: '8px' }}
            disabled={!isEditing}
            name="durationValue"
            min={1}
            max={cappingRule.durationUnit === 'HOURS' ? MAXIMUM_HOURS : MAXIMUM_DAYS}
            invalid={
              cappingRule.durationValue >
                (cappingRule.durationUnit === 'HOURS' ? MAXIMUM_HOURS : MAXIMUM_DAYS) ||
              !cappingRule.durationValue
            }
            data-testid={`input_duration_value_${testId ?? ''}`}
          />
        ) : (
          <ValueContainer>{cappingRule.durationValue}</ValueContainer>
        )}
        {isEditing ? (
          <Select
            value={durationUnitOptions.find(option => option.value === cappingRule.durationUnit)}
            options={durationUnitOptions}
            onChange={onChangeDropdown}
            optionToString={optionToString}
            style={{ width: '100px' }}
            data-testid={`select_duration_unit_${testId ?? ''}`}
          />
        ) : (
          <ValueContainer>
            {cappingRule.durationValue === 1
              ? singular(
                  durationUnitOptions.find(option => option.value === cappingRule.durationUnit)
                    ?.label ?? ''
                )
              : durationUnitOptions.find(option => option.value === cappingRule.durationUnit)
                  ?.label}
          </ValueContainer>
        )}
      </ConditionRowStyle>
    </ThemeProvider>
  )
}
