import styled, { css } from 'styled-components'

import { Button } from 'components/common/button'
import { IconContainer } from 'components/common/svg-icon'
import { colors, schemes } from 'components/styled/tokens'

export const LEVEL_COLORS = [colors.fillNeutral, colors.fillAction, schemes.orange['70']] as const
// type ThemeProps = {
//   kind: 'capture' | 'filter'
//   isEventFilter: boolean
// }
export const LogicalToggleContainer = styled.div`
  width: 45px;
  height: 18px;
  position: absolute;
  left: -2px;
  margin-top: -9px;
  .styled-value {
    color: #fff;
    font-weight: 600;
    font-size: 11px;
    line-height: 10px;
    flex-grow: 1;
  }
  ${IconContainer} {
    display: block;
  }
  .styled-btn {
    cursor: pointer;
    overflow: hidden;
    border: none;
    background: ${colors.fillNeutral};
    text-transform: uppercase;
    border-radius: 0 5px 5px 0;
    width: 45px;
    height: 18px;
    letter-spacing: -0.01em;
    display: flex;
    padding: 0 2px 0 5px;
    align-items: center;
    &:hover {
      background: ${colors.fillNeutralHover};
    }
  }
`
type LogicalContainerProps = {
  level: number
  isNot?: boolean
  isEmpty?: boolean
  disabledMode?: boolean
  withSeparator?: boolean
}
export const LogicalContainer = styled.section<LogicalContainerProps>`
  ${props =>
    props.theme.isEventFilter &&
    css`
      .styled-scrollable {
        max-height: min(80vh, 600px);
        overflow-y: auto;
      }
    `}
  .styled-descendants {
    position: relative;
    &.styled-scroll {
      max-height: calc(100vh - 400px);
      overflow-y: auto;
    }
  }
  .styled-empty {
    height: 68px;
    ${props =>
      props.level === 1 &&
      css`
        padding: 0 18px;
      `}
    text-align: center;
    font-weight: 500;
    color: ${p => (p.disabledMode ? colors.textDisabled : colors.textNeutral)};
    display: flex;
    align-items: center;
    justify-content: start;
  }

  margin-left: ${props => (props.level === 1 ? '0' : props.isNot ? 0 : '45px')};
  ${props =>
    props.withSeparator &&
    css`
      border-bottom: 1px dashed ${colors.stroke};
      border-bottom: 1px dashed crimson;
    `}
  ${props =>
    !props.theme.isEventFilter &&
    !props.isNot &&
    css`
      border-left: 2px solid ${props.isEmpty ? 'transparent' : LEVEL_COLORS[(props.level - 1) % 3]};
    `}
`

type StyledToolbarProps = {
  withSeparator?: boolean
}
export const StyledToolbar = styled.div<StyledToolbarProps>`
  padding: 8px 16px 8px 16px;
  background: ${schemes.grayscale['05']};
  border-bottom: none;

  ${props =>
    props.withSeparator &&
    css`
      border-bottom: 1px dashed ${colors.stroke};
    `}
`

export const OperatorIcon = styled.div`
  position: relative;
  width: 19px;
  height: 20px;
  margin-right: 7px;
  background-color: ${colors.fillDisabled};
  border-radius: 3px;

  .OperatorIcon--icon {
    position: absolute;
    z-index: 2;
    top: 2px;
    left: 3px;
    color: ${colors.textLight};
  }
  .OperatorIcon--strikethrough {
    position: absolute;
    z-index: 3;
  }
`
type CCProps = {
  isSub?: boolean
  withSeparator?: boolean
  hasEventFilter?: boolean
}
export const ConditionContainer = styled.div<CCProps>`
  padding: 16px 16px 16px 18px;
  width: 100%;
  display: flex;

  ${props =>
    props.withSeparator &&
    css`
      border-bottom: 1px dashed ${colors.stroke};
    `}

  ${props =>
    props.isSub &&
    css`
      .styled-icon {
        color: ${colors.stroke};
        flex-basis: 22px;
      }
      border-radius: 6px;
      align-items: center;
      border-top: none;
      height: 56px;
    `}
    &:first-child {
    border-top: none;
  }

  ${props =>
    props.hasEventFilter &&
    css`
      padding: 16px 16px 4px 18px;
    `}
`

export const ConditionEventFilterContainer = styled.div<CCProps>`
  padding: 8px 16px 4px 20px;
  width: 100%;
  display: flex;

  .styled-icon {
    color: ${colors.stroke};
    flex-basis: 22px;
  }
  border-radius: 6px;
  align-items: center;
  border-top: none;

  &:first-child {
    border-top: none;
  }

  ${props =>
    props.withSeparator &&
    css`
      border-bottom: 1px dashed ${colors.stroke};
      padding: 8px 16px 12px 20px;
    `}
`

export const ConditionPart = styled.div`
  margin-right: 10px;
`
export const ConditionPartAttribute = styled(ConditionPart)`
  flex: 0 1 200px;
`
export const ConditionPartAction = styled(ConditionPart)`
  margin: 0;
`
export const ConditionPartSpacer = styled.div`
  flex: 1 0 0px;
`

export const LogicalToggleItem = styled(Button)`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
`
export const CategoryTitle = styled.h2`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0 0 20px 0;
`
export const CategoryContainer = styled.div`
  border-bottom: 1px solid ${colors.stroke};
  padding: 20px 0 12px 0;
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
`
export const SubCategoryTitle = styled.h2`
  color: ${colors.textLight};
  font-size: 14px;
  font-weight: 500;
  i {
    margin-right: 10px;
  }
  margin-bottom: 12px;
`
export const SubCategoryContainer = styled.div`
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const AttributePicker = styled.button<{
  isSelected: boolean
}>`
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  height: 36px;
  margin: 0 8px 8px 0px;
  padding: 8px 12px;
  font-weight: 500;
  color: ${p => (p.isSelected ? `${colors.text} !important` : `${colors.textLight}`)};
  letter-spacing: -0.01em;
  border-radius: 6px;
  transition: all 0.2s ease;
  background-color: ${p =>
    p.isSelected ? `${colors.opacifyAction} !important` : `${colors.fillBackground}`};
  box-shadow: ${p =>
    p.isSelected
      ? `0 0 0 2px ${colors.strokeAction}`
      : `0 0 0 1px ${colors.stroke}, 0 1px 4px rgba(0, 0, 0, 0.02)`};

  cursor: pointer;
  overflow: hidden;
  user-select: none;

  > i {
    margin-right: 8px;
    padding-bottom: 2px;
  }
  > span {
    max-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover,
  &:active,
  &:focus {
    color: ${colors.textNeutral};
    background-color: ${colors.opacifyHover};
  }
`
