import Immutable, { type RecordOf, type List } from 'immutable'

export type BounceClassificationProps = {
  name: string
  count: number
}
export type BounceClassificationRecord = RecordOf<BounceClassificationProps>

export const BounceClassificationFactory = Immutable.Record<BounceClassificationProps>({
  name: '',
  count: 0,
})

export type BounceCategoryName = 'HARD' | 'SOFT' | 'BLOCK' | 'UNDETERMINED'
export type BounceCategoryProps = {
  name: BounceCategoryName
  count: number
  rate: number
  classifications: List<BounceClassificationRecord>
}

export type BounceCategoryRecord = RecordOf<BounceCategoryProps>
export const BounceCategoryFactory = Immutable.Record<BounceCategoryProps>({
  name: 'UNDETERMINED',
  count: 0,
  rate: 0,
  classifications: Immutable.List(),
})

type DataProps = {
  value: number
  rate?: number
  unique?: number | null | undefined
  trend?: number | null | undefined
  previousPeriodRate?: number | null | undefined
  previousPeriodValue?: number | null | undefined
}
type DataRecord = RecordOf<DataProps>
export const DataFactory = Immutable.Record<DataProps>({
  value: 0,
  rate: 0,
  unique: null,
  trend: null,
  previousPeriodRate: null,
  previousPeriodValue: null,
})

export type StatsProps = {
  sent: DataRecord
  bounce: DataRecord
  click: DataRecord
  mpp: DataRecord
  delivered: DataRecord
  open: DataRecord
  unsubscribe: DataRecord
  skippedSuppressionList: DataRecord
  sending: DataRecord
  injection: number
  spam: number
  deliveredSMSNbParts: number
}
export const StatsFactory = Immutable.Record<StatsProps>({
  sent: DataFactory(),
  bounce: DataFactory(),
  click: DataFactory(),
  mpp: DataFactory(),
  delivered: DataFactory(),
  open: DataFactory(),
  unsubscribe: DataFactory(),
  skippedSuppressionList: DataFactory(),
  sending: DataFactory(),
  injection: 0,
  spam: 0,
  deliveredSMSNbParts: 0,
})
export type StatsRecord = RecordOf<StatsProps>

export type OrchestrationStatsProps = {
  email: StatsRecord | null | undefined
  sms: StatsRecord | null | undefined
  push: StatsRecord | null | undefined
}
export const OrchestrationStatsFactory = Immutable.Record<OrchestrationStatsProps>({
  email: null,
  sms: null,
  push: null,
})
export type OrchestrationStatsRecord = RecordOf<OrchestrationStatsProps>
