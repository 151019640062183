import * as React from 'react'
import { StatsBlocksRow } from '../orchestration-analytics/orchestration-analytics.styles'
import { EmailKeyMetrics } from '../key-metrics/email-key-metrics'
import { PushKeyMetrics } from '../key-metrics/push-key-metrics'
import { SmsKeyMetrics } from '../key-metrics/sms-key-metrics'
import { type StatsRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { useSelector } from 'react-redux'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import { OrchestrationAnalyticsFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { channelConfig } from './cross-orchestrations-analytics'
import { STATUS } from 'constants/common'

type CrossOrchestrationsKeyMetricsProps = {
  channel: ChannelUntilCleanup
  stats: StatsRecord | null
  projectKey: string
}
export const CrossOrchestrationsKeyMetrics = React.memo(
  ({ channel, stats, projectKey }: CrossOrchestrationsKeyMetricsProps): React.ReactNode => {
    const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
    const loadingState: LoadingState = React.useMemo(
      () =>
        analyticsMap
          .get(projectKey, OrchestrationAnalyticsFactory())
          .get(channelConfig[channel].loadingState) ?? 'INIT',

      [analyticsMap, projectKey, channel]
    )
    const isLoading = React.useMemo(
      () => loadingState === STATUS.INIT || loadingState === STATUS.LOADING,
      [loadingState]
    )
    const isOverlayShown = React.useMemo(() => loadingState === STATUS.ERROR, [loadingState])

    return (
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={GlobalErrorOverlayProps}
        style={isOverlayShown ? { overflow: 'hidden' } : {}}
        boxed
      >
        <StatsBlocksRow $marginBottom>
          {channel === 'email' && (
            <EmailKeyMetrics stats={stats} context="project" token={projectKey} />
          )}
          {channel === 'sms' && (
            <SmsKeyMetrics stats={stats} context="project" token={projectKey} />
          )}
          {channel === 'push' && (
            <PushKeyMetrics stats={stats} context="project" token={projectKey} />
          )}
        </StatsBlocksRow>
      </Wrapper>
    )
  }
)
