import { Box, BoxHeader } from 'components/common/box'
import { BoxContainer } from 'components/common/box/box.styles'
import { colors, texts } from 'components/styled/tokens'
import styled from 'styled-components'

export const KeyMetricsChartsContainer = styled(Box)`
  width: 100%;

  ${BoxHeader} {
    justify-content: flex-start;
    gap: 8px;
  }

  ${BoxContainer} {
    padding: 0 16px;
    border: none;
  }
`

export const MetricContainer = styled.div`
  margin-top: 4px;
`

type MetricBadgeProps = {
  $titleColor: string
  $badgeColor: string
}
export const MetricBadge = styled.p<MetricBadgeProps>`
  ${texts.h2}
  color: ${({ $titleColor }) => $titleColor};
  margin-bottom: 8px;

  &::after {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: ${({ $badgeColor }) => $badgeColor};
    border-radius: 4px;
    margin-left: 6px;
    position: relative;
    top: 1px;
  }
`

export const KeyMetricsTimeline = styled.div`
  height: 36px;
  padding-left: 156px;
  position: sticky;
  bottom: 0;
  z-index: 200;
  background-color: white;
  border-top: 1px solid ${colors.stroke};
  overflow: hidden;

  & svg:nth-child(2) {
    position: relative;
    top: -20px;
  }
`
