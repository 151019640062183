import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'
import { useLocation } from 'react-router-dom'

import { useCurrentCompanyHasFeature, useIsCurrentUserAllowedTo } from 'components/_hooks'
import { Banner } from 'components/common/banner'
import { currentProjectAppsSelector } from 'com.batch.redux/app'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'

import { currentAppSelector } from 'com.batch.redux/app'

import { FCM_ALERT_DATE, FCM_WARNING_DATE } from 'com.batch/settings/constants/fcm-dates'

export const FcmMigrationBanner = (): React.ReactElement | null => {
  const apps = useSelector(currentProjectAppsSelector)
  const hasFeature = useCurrentCompanyHasFeature('fcm-v1-api-migration')
  const isAllowedToChangeFCMConfig = useIsCurrentUserAllowedTo(['app', 'push:config:write'])
  const app = useSelector(currentAppSelector)
  const location = useLocation()
  const isSettingsRoute = location.pathname.includes('/settings/push')
  const now = dayjs.utc()
  const firstDeadline = dayjs.utc(FCM_WARNING_DATE, 'YYYY-MM-DD')
  const lastDeadline = dayjs.utc(FCM_ALERT_DATE, 'YYYY-MM-DD')

  const isBeforeFirstDeadline = React.useMemo(
    () => now.isBefore(firstDeadline),
    [firstDeadline, now]
  )
  const isAfterLastDeadLine = React.useMemo(
    () => now.isAfter(lastDeadline) || now.isSame(lastDeadline),
    [lastDeadline, now]
  )

  const isFcmConfigRequired = React.useMemo(() => {
    const isRequiredFromCurrentApp =
      app.platform === 'android' && app.pushConfig.fcm.size === 0 && app.pushConfig.gcm.size > 0
    if (isRequiredFromCurrentApp) return true
    // if false, its means it's a new app or an app without GCM config filled in the project's Dashboard,
    // so we have to check in every project's app if we match the condition
    return (
      apps.find(
        app =>
          app.platform === 'android' && app.pushConfig.fcm.size === 0 && app.pushConfig.gcm.size > 0
      ) !== undefined
    )
  }, [app.platform, app.pushConfig.fcm.size, app.pushConfig.gcm.size, apps])

  if (hasFeature && isFcmConfigRequired && !isSettingsRoute) {
    return (
      <Banner
        intent={isBeforeFirstDeadline ? 'danger' : 'blocked'}
        title={
          isAfterLastDeadLine
            ? 'Channel settings to update to send out Android push '
            : isBeforeFirstDeadline
              ? 'Android push settings to update'
              : 'Android push settings to update before June 2024'
        }
        content={
          isBeforeFirstDeadline
            ? 'Upload new credentials to migrate from legacy FCM APIs to HTTP v1'
            : 'To migrate from legacy FCM APIs to HTTP v1 without push delivery interruption, upload new credentials as soon as possible'
        }
        hasCloseButton={!isAfterLastDeadLine}
        link={
          isAllowedToChangeFCMConfig
            ? {
                kind: 'basic',
                name: 'Push settings',
                href: generateUrl('app_settings', {
                  companyId: app.companyId,
                  appId: app.id,
                  activeTab: 'push',
                }),
              }
            : undefined
        }
      />
    )
  }
  return null
}
