import Immutable, { type List, type Map, type Set } from 'immutable'
import { createSelector } from 'reselect'

import languagesRaw from 'com.batch.common/languages'

import { type langId, type MessageStateRecord } from './message.state'

import {
  AttributeValuesListFactory,
  type AttributeValueRecord,
  type Extract,
  LanguageFactory,
  type LanguageRecord,
  type RegionRecord,
  type State,
} from 'com.batch.redux/_records'
import { valueSelector } from 'com.batch.redux/attribute.selector'
import {
  EmailMessageFactory,
  PushMessageFactory,
  SmsMessageFactory,
} from '../models/message.records'

// all the languages we allow for targeting / translation
export const languages: List<LanguageRecord> = Immutable.List(
  Object.keys(languagesRaw).map(k =>
    LanguageFactory({
      value: k,
      label: languagesRaw[k],
      nb: 0,
      tokens: 0,
      pcent: '0.0%',
    })
  )
)

export const getLangFromContent: (
  content: Map<string, unknown>,
  multilanguageEnabled: boolean
) => List<LanguageRecord> = (content, multilanguageEnabled) =>
  multilanguageEnabled
    ? content
        .map((_, k) => languages.find(rl => rl.get('value') === k) ?? LanguageFactory())
        .toSet()
        .toList()
    : languages.filter(lang => lang.get('value') === 'default')

const langOrRegionSorter: (
  a: LanguageRecord | RegionRecord,
  b: LanguageRecord | RegionRecord
) => 1 | -1 = (a, b) => {
  if (a.nb < b.nb) {
    return 1
  }
  if (a.nb > b.nb) {
    return -1
  }
  return a.label < b.label ? -1 : 1
}

export type MessageIdGetterSelector<T> = (state: State) => (messageId: string) => T

export const messageStateSelector: Extract<MessageStateRecord> = state => state.message
export const previewLanguageSelector: Extract<string> = createSelector(
  messageStateSelector,
  messageState => messageState.previewLanguage
)

export const enrichedLanguagesSelector: (state: State) => List<LanguageRecord> = createSelector(
  valueSelector,
  values => {
    const languagesValues = values
      .get('b.language', AttributeValuesListFactory())
      .values.get('__default', Immutable.List<AttributeValueRecord>())
    return languages
      .map(lang => {
        const match = languagesValues.find(lv => lv.value.toString() === lang.value, false)
        return match
          ? lang.set('nb', match.installs).set('tokens', match.tokens).set('pcent', match.pcent)
          : lang
      })
      .sort(langOrRegionSorter)
      .map(lang => lang.set('pretty', lang.label))
  }
)

export const getLangKeys: (args: {
  messageId: string
  channel: ChannelUntilCleanup
  messageState: MessageStateRecord
}) => IterableIterator<langId> = ({ messageId, channel, messageState }) => {
  switch (channel) {
    case 'email':
      return messageState.email.get(messageId, EmailMessageFactory()).localizedContent.keys()
    case 'push':
      return messageState.push.get(messageId, PushMessageFactory()).localizedContent.keys()
    case 'sms':
      return messageState.sms.get(messageId, SmsMessageFactory()).localizedContent.keys()
    default:
      return Immutable.Map<langId, unknown>().keys()
  }
}

export const globallyConfiguredLanguagesSelector: Extract<Set<langId>> = createSelector(
  messageStateSelector,
  messageState => {
    const emailLangKeys: Set<langId> = messageState
      .get('email', Immutable.Map())
      .reduce(
        (acc, messageId) => acc.union(messageId.get('localizedContent', Immutable.Map()).keys()),
        Immutable.Set()
      )
    const pushLangKeys: Set<langId> = messageState
      .get('push', Immutable.Map())
      .reduce(
        (acc, messageId) => acc.union(messageId.get('localizedContent', Immutable.Map()).keys()),
        Immutable.Set()
      )
    const smsLangKeys: Set<langId> = messageState
      .get('sms', Immutable.Map())
      .reduce(
        (acc, messageId) => acc.union(messageId.get('localizedContent', Immutable.Map()).keys()),
        Immutable.Set()
      )
    return Immutable.Set<langId>().union(emailLangKeys, pushLangKeys, smsLangKeys)
  }
)

export const pickedLanguagesSelector: (arg1: {
  messageId: string
  channel: ChannelUntilCleanup
}) => (state: State) => List<LanguageRecord> = ({ messageId, channel }) =>
  createSelector(
    messageStateSelector,
    enrichedLanguagesSelector,
    (messageState: MessageStateRecord, enrichedLanguages) => {
      const alreadyPickedLanguagesIds = getLangKeys({ messageId, channel, messageState })

      const pickedLanguagesList = Immutable.List(alreadyPickedLanguagesIds)
      return pickedLanguagesList
        .map(v => {
          return enrichedLanguages.find(rl => rl.get('value') === v) ?? LanguageFactory()
        })
        .sort((a, b) =>
          pickedLanguagesList.indexOf(a.value) > pickedLanguagesList.indexOf(b.value) ? 1 : -1
        )
    }
  )

export const pickableLanguagesSelector: (arg1: {
  messageId: string
  channel: ChannelUntilCleanup
}) => (state: State) => List<LanguageRecord> = ({ messageId, channel }) =>
  createSelector(messageStateSelector, enrichedLanguagesSelector, (messageState, langs) => {
    const langKeys = getLangKeys({ messageId, channel, messageState })
    const alreadyPickedLanguagesIds = langKeys ? [...langKeys] : []

    return langs.filter(
      lang => lang.value !== 'default' && !alreadyPickedLanguagesIds.includes(lang.value)
    )
  })
