import * as React from 'react'

import { TableTemplateCell } from 'components/common/table'

export const AudienceEmptyList = (): React.ReactElement => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" align="end" />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
}
