type ZipEntry = {
  filename: string
  directory: boolean
  getData: (arg1?: any) => Promise<any>
}

export const filterUselessEntries = (entries: Array<ZipEntry>): Array<ZipEntry> =>
  entries.filter(entry => !entry.directory && !entry.filename.includes('__MACOSX'))

export const extractHtmlFromEntries = (
  entries: Array<ZipEntry>
): Promise<string> => // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve: (result: Promise<never>) => void, reject: (error?: any) => void) => {
    const htmlEntry = entries.find(
      entry => entry.filename.includes('index.htm') || entry.filename.includes('mail.html')
    )
    if (!htmlEntry) {
      reject(new Error('No index.htm(l) or mail.html file found'))
      return
    }
    const html = await htmlEntry.getData(new window.zip.TextWriter())
    resolve(html)
  })

export const isImage = (filename: string): boolean => {
  const ext = filename.split('.').pop()
  return ext ? ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(ext) : false
}

export const getMimeTypeFromFilename = (filename: string): string => {
  const ext = filename.split('.').pop()
  switch (ext) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    case 'gif':
      return 'image/gif'
    case 'webp':
      return 'image/webp'
    default:
      return 'application/octet-stream'
  }
}

export const loadZipScript = async (): Promise<void> =>
  new Promise((resolve: () => void, reject: (error?: any) => void) => {
    if (window.zip) {
      resolve()
    } else {
      const script = document.createElement('script')
      // @todo ajouter un nonce pour la CSP ?
      script.src = 'https://cdn.jsdelivr.net/npm/@zip.js/zip.js@2.6.67/dist/zip.min.js'
      script.async = false
      script.defer = false
      script.onload = () => resolve()
      script.onerror = () => reject()
      document.body?.appendChild(script)
    }
  })
