import { type List } from 'immutable'
import * as React from 'react'

import { Box, BoxBody, BoxHeader, HeaderBoxTitle, HeaderBoxActions } from 'components/common/box'
import { Button } from 'components/common/button'
import { Overlay } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { FilterSearch } from 'components/filter'

import { pluralize } from 'com.batch.common/utils'

import {
  LabelCount,
  LabelDescription,
  LabelRow,
  Scrollable,
  SearchContainer,
} from 'com.batch/capping/ui/components/capping.styles'
import { type LabelRecord } from 'com.batch/labels/models/labels.records'
import { useSort } from 'com.batch/shared/hooks/use-sort'

export interface NewCappingPopinProps {
  close: () => void
  labels: List<LabelRecord>
  onClickLabel: (labelCode: string) => void
}

export const NewCappingPopin = ({
  close,
  labels,
  onClickLabel,
}: NewCappingPopinProps): React.ReactElement => {
  const [search, setSearch] = React.useState('')
  const { sortEntitiesByKey } = useSort()

  const filteredAndSortedLabels = React.useMemo(
    () =>
      sortEntitiesByKey(
        sortEntitiesByKey(labels, 'description', 'asc'),
        'orchestrationCount',
        'dsc'
      ).filter(label => label.description.toLowerCase().includes(search.toLowerCase())),
    [labels, search, sortEntitiesByKey]
  )

  const onSearch = React.useCallback((searchQuery: string) => {
    setSearch(searchQuery)
  }, [])

  const createOnClickLabel = React.useCallback(
    (labelCode: string) => () => {
      onClickLabel(labelCode)
      close()
    },
    [close, onClickLabel]
  )

  return (
    <Box>
      <BoxHeader>
        <HeaderBoxTitle title="New label frequency capping" />
        <HeaderBoxActions>
          <Button kind="inline" onClick={close}>
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>
      <BoxBody>
        <SearchContainer>
          <FilterSearch
            value={search}
            onChange={onSearch}
            expandedMaxWidth={580}
            placeholder="Search labels..."
            fullPlaceholder
            width={580}
          />
        </SearchContainer>
        <Scrollable>
          {filteredAndSortedLabels.size === 0 && (
            <Overlay
              status="empty"
              title="No labels to display"
              description="There’s nothing matching your criteria, try something else."
              style={{ marginTop: '64px' }}
            />
          )}
          {filteredAndSortedLabels.map(label => (
            <LabelRow onClick={createOnClickLabel(label.code)} key={label.code}>
              <LabelDescription>{label.description || <s>{label.code}</s>}</LabelDescription>
              <LabelCount>
                {label.orchestrationCount > 0 &&
                  pluralize('orchestration', label.orchestrationCount)}
              </LabelCount>
            </LabelRow>
          ))}
        </Scrollable>
      </BoxBody>
    </Box>
  )
}
