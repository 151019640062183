import * as React from 'react'

import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { type availableIcons, Icon } from 'components/common/svg-icon'

type AlertProps = {
  kind?: 'warning' | 'error' | 'info' | 'success'
  icon: availableIcons
  children: React.ReactNode
  size?: number
}

export function Alert({
  kind = 'warning',
  icon,
  children,
  size,
  ...props
}: AlertProps): React.ReactElement {
  const className = `alert alert--shadow alert-${kind}`
  const st = size ? { maxWidth: `${size}px` } : {}
  return (
    <div {...props} className={className} style={st}>
      <FlexLine grow>
        {icon && (
          <FlexLineItem width={30}>
            <Icon icon={icon} size={16} />
          </FlexLineItem>
        )}
        <FlexLineItem grow={1}>
          <div className="alert__content">{children}</div>
        </FlexLineItem>
      </FlexLine>
    </div>
  )
}
