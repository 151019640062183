import { type Set } from 'immutable'

import { type AppRecord } from 'com.batch.redux/_records'
import { removeInvalidConditions } from 'com.batch.redux/query/query.api'
import { type QueryRecord } from 'com.batch.redux/query/query.records'
import { type TargetStateRecord } from 'com.batch.redux/target/target.records'

import { type FilterPushTokensType } from 'com.batch/message/models/message.records'
/*
  @todo: rewrite using a string hash, collisions seems too likely with our current implementation
*/
export function buildEstimateHashKey(
  target: TargetStateRecord,
  query: QueryRecord,
  apps: Set<AppRecord>,
  filterTokenMode: FilterPushTokensType
): number {
  const queryHash = removeInvalidConditions(query).hashCode()
  const targetHash =
    target.languages.hashCode() +
    target.regions.hashCode() +
    target.segments.hashCode() +
    (target.regionsInverted ? 666 : 0) +
    (target.languagesInverted ? 333 : 0)
  return apps.hashCode() + targetHash + generateValueFromFilterMode(filterTokenMode) + queryHash
}

const generateValueFromFilterMode = (filterTokenMode: FilterPushTokensType) => {
  switch (filterTokenMode) {
    case 'all':
      return 0
    case 'collected':
      return 1
    case 'imported':
      return 2
  }
}
