import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { ThemeProvider, useTheme } from 'styled-components'

import { useToggle } from 'components/_hooks'
import { Tooltip } from 'components/common/tooltip'

import { PopinSendTestPush } from './popin-send-test-push'
import { PushLayerBarSendTest } from './push-layer-bar-send-test'

import { previewLanguageSelector } from 'com.batch/message/store/message.selector'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'

import { getMessageId } from 'com.batch/message/models/message.helper'
import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import { LayerBarDropdown } from 'com.batch/message-builder/ui/components/layerbar-button'
import {
  DoubleLayerBarButton,
  LayerBarDivider,
  LayerBarWrapper,
} from 'com.batch/message-builder/ui/components/layerbar.styles'
import { updatePushTemplate } from 'com.batch/push/usecases/update-push-template'
import { PreviewProfilePopin } from 'com.batch/shared/ui/component/popins/preview-profile-popin'
import { PreviewAs } from 'com.batch/shared/ui/component/preview-as'

type PushLayerBarProps = {
  stepMessageNodeId: string | null | undefined
  hasMedia: boolean
  hasIcon: boolean
  showIcon: boolean
  setEditing: (field: MessageBuilderEditableField, caret: number) => void
  openFileUploader: () => void
  openMediaPopin: () => void
}
const PushLayerBarRaw = ({
  stepMessageNodeId,
  hasMedia,
  hasIcon,
  showIcon,
  setEditing,
  openFileUploader,
  openMediaPopin,
}: PushLayerBarProps): React.ReactElement => {
  const dispatch = useDispatch()
  const previewProfilePopinState = useToggle()
  const sendTestPopinState = useToggle()
  const lang = useSelector(previewLanguageSelector)
  const getMessageConfig = useSelector(getMessageConfigSelector)
  const config = React.useMemo(
    () => getMessageConfig({ stepMessageNodeId }),
    [getMessageConfig, stepMessageNodeId]
  )
  const messageId = React.useMemo(() => getMessageId(config), [config])
  const theme = useTheme()

  const handlePreviewAsClick = React.useCallback(() => {
    previewProfilePopinState.open()
  }, [previewProfilePopinState])
  const handleSendTestClick = React.useCallback(() => {
    sendTestPopinState.open()
  }, [sendTestPopinState])

  const updateTemplate = React.useCallback(() => {
    dispatch(
      updatePushTemplate({
        lang,
        messageId,
      })
    )
  }, [dispatch, lang, messageId])

  const createHandleSetEditing = React.useCallback(
    (type: MessageBuilderEditableField) => () => {
      setEditing(type, -1)
    },
    [setEditing]
  )

  const isMacOrIOS = React.useMemo(
    () => (theme.previewPlatform ? ['ios', 'webMac'].includes(theme.previewPlatform) : false),
    [theme]
  )

  return (
    <LayerBarWrapper $itemsCount={!theme?.disabledMode ? (showIcon ? 4 : 3) : 2}>
      <ThemeProvider theme={{ disabledMode: false }}>
        <PreviewProfilePopin
          togglePopin={previewProfilePopinState}
          updateTemplate={updateTemplate}
        />
        <PopinSendTestPush togglePopin={sendTestPopinState} stepMessageNodeId={stepMessageNodeId} />
      </ThemeProvider>

      {!theme?.disabledMode && (
        <DoubleLayerBarButton>
          <LayerBarDropdown
            embedded
            label={!hasMedia ? 'Add media' : 'Media'}
            icon={<img src="/medias/img/builders/icons/media.svg" alt="Adding a media" />}
            addable={!hasMedia}
            onClick={createHandleSetEditing('image')}
            options={[
              { label: 'From computer', prefix: 'upload', action: openFileUploader },
              { label: 'From link', prefix: 'link', action: openMediaPopin },
            ]}
          />

          {!theme?.disabledMode && showIcon && (
            <React.Fragment>
              <LayerBarDivider />
              <Tooltip
                tooltip={
                  <span style={{ whiteSpace: 'nowrap' }}>
                    Icons are not available on{' '}
                    {theme?.previewPlatform === 'ios' ? 'iOS' : 'Web Mac'}
                  </span>
                }
                isTooltipEmpty={!isMacOrIOS}
              >
                <div>
                  <LayerBarDropdown
                    embedded
                    label={!hasIcon ? 'Add icon' : 'Icon'}
                    icon={<img src="/medias/img/builders/icons/icon.svg" alt="Adding an icon" />}
                    addable={!hasIcon}
                    onClick={createHandleSetEditing('icon')}
                    options={[
                      { label: 'From computer', prefix: 'upload', action: openFileUploader },
                      { label: 'From link', prefix: 'link', action: openMediaPopin },
                    ]}
                  />
                </div>
              </Tooltip>
            </React.Fragment>
          )}
        </DoubleLayerBarButton>
      )}

      <PushLayerBarSendTest
        handleSendTestClick={handleSendTestClick}
        stepMessageNodeId={stepMessageNodeId}
      />
      <PreviewAs handlePreviewAsClick={handlePreviewAsClick} updateTemplate={updateTemplate} />
    </LayerBarWrapper>
  )
}

export const PushLayerBar: React.ComponentType<PushLayerBarProps> = React.memo(PushLayerBarRaw)
