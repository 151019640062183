import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { BoxContainer } from 'components/common/box/box.styles'

import { Overlay, type OverlayProps } from './overlay'
import { OverlayContainer, WrapperContainer } from './wrapper.styles'

type WrapperProps = {
  isEmpty: boolean
  isLoading: boolean
  isOverlayShown: boolean
  overlayProps: OverlayProps
  children: React.ReactNode
  overlayContainerStyle?: {
    [key: string]: string | number
  }
  style?: {
    [key: string]: string | number
  }
  boxed?: boolean
}

export const Wrapper = ({
  isEmpty,
  isLoading,
  isOverlayShown,
  children,
  overlayProps,
  overlayContainerStyle,
  style,
  boxed,
}: WrapperProps): React.ReactElement => {
  const isPageWrapper = overlayProps.status.includes('-page')
  const theme = React.useCallback(
    (theme: { [key: string]: any }) => {
      return theme ? { ...theme, isEmpty, isLoading } : { isLoading, isEmpty }
    },
    [isEmpty, isLoading]
  )

  const wrapper = React.useMemo(
    () => (
      <ThemeProvider theme={theme}>
        <WrapperContainer
          isOverlayShown={isOverlayShown}
          isPageWrapper={isPageWrapper}
          style={style}
        >
          {isOverlayShown && (
            <OverlayContainer isPageWrapper={isPageWrapper} style={overlayContainerStyle}>
              <Overlay {...overlayProps} />
            </OverlayContainer>
          )}
          {children}
        </WrapperContainer>
      </ThemeProvider>
    ),
    [children, isOverlayShown, isPageWrapper, overlayContainerStyle, overlayProps, style, theme]
  )

  return boxed && !isLoading && isOverlayShown ? <BoxContainer>{wrapper}</BoxContainer> : wrapper
}
