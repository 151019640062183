import styled, { css, keyframes, type Keyframes } from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

export const DonutChartContainer = styled.div`
  position: relative;
  overflow: visible;
`

export const LoadingSvgStrokeAnim: Keyframes = keyframes`
    0% {
        fill: ${schemes.darklucent['05']}
    }
    50% {
        fill: ${colors.stroke}
    }
    100% {
        fill: ${schemes.darklucent['05']}
    }
`

export const LoadingPath = styled.path`
  animation-name: ${LoadingSvgStrokeAnim};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`

export const PlaceholderPath = styled.path`
  fill: ${schemes.grayscale['30']};
`

export const LoadingRect = styled.rect<{
  isEmpty: boolean
}>`
  ${props =>
    props.isEmpty
      ? css`
          fill: ${schemes.grayscale['30']};
        `
      : css`
          animation-name: ${LoadingSvgStrokeAnim};
          animation-duration: 2s;
          animation-iteration-count: infinite;
        `}
`

export const Legend = styled.button<{
  disabled?: boolean
  active?: boolean
}>`
  display: inline-flex;
  text-align: center;
  align-items: center;
  border: 0;
  font-size: 14px;
  color: ${props => {
    if (props.disabled) return colors.textDisabled
    return props.active ? colors.textNeutral : colors.textLight
  }};
  font-weight: 500;
  padding: 0;
  gap: 4px;
`

export const LegendContainer = styled.div`
  display: block;
  text-align: center;
  margin-top: 20px;

  ${Legend}:not(:last-child) {
    margin-right: 8px;
  }
`
