import styled, { css } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'
import { schemes } from 'components/styled/tokens'

const TOOLBAR_MARGINS = 12

export const PreviewToolbarItem = styled.div`
  display: block;
  position: relative;
  margin: 0 ${TOOLBAR_MARGINS}px 0 0;

  &:after {
    display: block;
    position: absolute;
    top: 50%;
    right: ${-TOOLBAR_MARGINS / 2}px;
    width: 1px;
    height: 26px;
    transform: translate3d(0, -50%, 0);
    content: '';
    background-color: #e5e9f1;
  }
  &:last-child {
    margin: 0;

    &:after {
      display: none;
    }
  }
`
type PreviewToolbarContainerProps = {
  vertical?: boolean
}
export const PreviewToolbarContainer = styled.div<PreviewToolbarContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
      background-color: white;
      padding: ${TOOLBAR_MARGINS};
      border-radius: 5px;
      ${PreviewToolbarItem} {
        margin: 0 0 ${TOOLBAR_MARGINS}px 0;
        &:last-child {
          margin: 0;
        }
        &:after {
          display: none;
        }
      }
    `}
`

export const BrowserLabel = styled.div`
  span {
    display: inline-block;
    margin: 0 0 0 2px;
    font-weight: 600;
    color: #c5c8cd;
  }
`
export const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: ${schemes.grayscale['60']};
  border-radius: 4px;
  width: 39px;
  height: 40px;
  ${IconContainer} {
    line-height: unset;
    padding: 12px;
  }
  &:hover {
    color: ${schemes.grayscale['70']};
    background: ${schemes.darklucent['05']};
  }
  &:focus {
    color: ${schemes.grayscale['70']};
    background: ${schemes.darklucent['30']};
  }
`
