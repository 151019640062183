import * as React from 'react'

type WrapOverlayProps = {
  overlay: React.ReactNode | null | undefined
  children: React.ReactNode
}

export const WrapOverlay = ({ overlay, children }: WrapOverlayProps): React.ReactElement => {
  return (
    <div style={{ position: 'relative' }}>
      {Boolean(overlay) && (
        <div
          style={{
            position: 'absolute',
            top: -1,
            left: 0,
            right: 0,
            bottom: -1,
            zIndex: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255,255,255, 0.7)',
          }}
        >
          {overlay}
        </div>
      )}
      {children}
    </div>
  )
}
