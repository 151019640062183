import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { useIsCurrentUserAllowedTo } from 'components/_hooks/use-allowed'
import { SwitchButton } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { disableExperiment, enableExperiment } from '../../usecases/experiment'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { orchestrationMetaSelector } from 'com.batch/orchestration/store/orchestration.selectors'

type Props = {
  stepMessageNodeId: string | null | undefined
}
export const ExperimentToggle = ({ stepMessageNodeId }: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const getMessageConfig = useSelector(getMessageConfigSelector)
  const config = React.useMemo(
    () => getMessageConfig({ stepMessageNodeId }),
    [getMessageConfig, stepMessageNodeId]
  )

  const { state } = useSelector(orchestrationMetaSelector)
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])

  const handleExperimentOnChange = React.useCallback(
    (enabled: boolean) => {
      dispatch(
        enabled ? enableExperiment({ stepMessageNodeId }) : disableExperiment({ stepMessageNodeId })
      )
    },
    [dispatch, stepMessageNodeId]
  )

  return (
    <Tooltip
      tooltip="A/B Testing settings can't be changed once the orchestration has started"
      minWidth={220}
      isTooltipEmpty={state === 'DRAFT'}
    >
      <span>
        <SwitchButton
          addOn="prefix"
          style={{ display: 'flex', justifyContent: 'flex-start' }}
          onChange={handleExperimentOnChange}
          isActive={config.experiment.enabled}
          disabled={state !== 'DRAFT' || !userHasWritePermission}
        >
          A/B Testing
          <Icon icon="experiment" style={{ marginLeft: 8 }} />
        </SwitchButton>
      </span>
    </Tooltip>
  )
}
