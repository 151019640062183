export type StyledPreviewTheme = {
  expanded?: boolean
  platform: Platforms
  height: string
  borderRadius: string
  landingScale: number
  landingWidth: string
  landingHeight: string
  containerPadding: string
  containerRadius: string
  ls: {
    padding: string
    fontWeight: number | 'auto' | 'normal'
    time: {
      letterSpacing: string
      fontSize: string
      lineHeight: string
    }
    date: {
      fontSize: string
      fontWeight: number | 'auto' | 'normal'
      lineHeight: string
    }
  }
  notif: {
    borderRadius: string
    backgroundColor: string
    margin: string
    expanded: {
      margin: string
      backgroundColor: string
    }
    color: string
    head: {
      padding: string
      expanded: {
        padding: string
      }
      icon: {
        size: string
        borderRadius: string
        margin: string
      }
      appName: {
        color: string
        fontSize: string
        textTransform: string
        grow: number
      }
      date: {
        color: string
        fontSize: string
        fontWeight: string | number
        letterSpacing: string
        expanded: {
          color: string
          letterSpacing: string
          fontSize: string
        }
      }
    }
    content: {
      fontSize: number | 'auto'
      lineHeight: number | 'auto'
      padding: string
      color: string
      expanded: {
        padding: string
        color: string
        fontSize?: string
      }
      title: {
        fontWeight: number | 'auto' | 'normal'
        color: string
      }
    }
    media: {
      expanded: {
        padding: string
      }
      thumb: {
        size: string
        borderRadius: string
      }
    }
  }
}

export const ios: StyledPreviewTheme = {
  platform: 'ios',
  height: '851px',
  borderRadius: '48px',
  landingScale: 1.22433333,
  landingWidth: '306px',
  landingHeight: '542px',
  containerPadding: '87px 13px 101px 13px',
  containerRadius: '0px',
  ls: {
    padding: '66px 20px 10px 20px',
    fontWeight: 200,
    time: {
      letterSpacing: '2px',
      fontSize: '74px',
      lineHeight: '68px',
    },
    date: {
      fontSize: '21px',
      fontWeight: 300,
      lineHeight: '41px',
    },
  },
  notif: {
    borderRadius: '12px',
    backgroundColor: 'HSL(0, 0%, 94%)',
    margin: '10px 8px 0 8px',
    expanded: {
      margin: '100px 8px 0 8px',
      backgroundColor: 'HSL(0, 0%, 100%)',
    },
    color: 'HSL(0, 0%, 3%)',
    head: {
      padding: '10px 10px 10px 10px',
      expanded: {
        padding: '14px 14px 14px 14px',
      },
      icon: {
        size: '20px',
        borderRadius: '4px',
        margin: '0 8px 0 0',
      },
      appName: {
        color: 'HSL(0, 0%, 33%)',
        fontSize: '14px',
        textTransform: 'uppercase',
        grow: 1,
      },
      date: {
        color: 'HSL(0, 0%, 33%)',
        fontSize: '13px',
        fontWeight: 'auto',
        letterSpacing: '0',
        expanded: {
          color: 'HSL(109, 0%, 50%)',
          letterSpacing: '2px',
          fontSize: '14px',
        },
      },
    },
    content: {
      fontSize: 15,
      lineHeight: 20,
      padding: '0 11px 12px 11px',
      color: 'inherit',
      expanded: {
        padding: '11px 6px 12px 6px',
        color: 'inherit',
      },
      title: {
        fontWeight: 600,
        color: 'inherit',
      },
    },
    media: {
      expanded: {
        padding: '0 0px 0px 0px',
      },
      thumb: {
        size: '34px',
        borderRadius: '3px',
      },
    },
  },
}

export const android: StyledPreviewTheme = {
  platform: 'android',
  height: '815px',
  borderRadius: '60px',
  landingWidth: '315px',
  landingHeight: '558px',
  landingScale: 1.206349206,
  containerPadding: '72px 10px 70px 10px',
  containerRadius: '9px',
  ls: {
    padding: '72px 20px 10px 20px',
    fontWeight: 200,
    time: {
      letterSpacing: '0px',
      fontSize: '74px',
      lineHeight: '73px',
    },
    date: {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '30px',
    },
  },
  notif: {
    borderRadius: '8px',
    backgroundColor: 'HSL(120, 2%, 91%)',
    margin: '10px 5px 0 5px',
    expanded: {
      margin: '148px 10px 0 10px',
      backgroundColor: 'HSL(0, 0%, 100%)',
    },
    color: 'HSL(0, 0%, 3%)',
    head: {
      padding: '12px 12px 7px 12px',
      expanded: {
        padding: '14px 12px 8px 12px',
      },
      icon: {
        size: '20px',
        borderRadius: '4px',
        margin: '0 8px 0 0',
      },
      appName: {
        color: 'HSL(0, 0%, 13%)',
        fontSize: '14px',
        textTransform: 'inherit',
        grow: 0,
      },
      date: {
        color: 'HSL(0, 0%, 44%)',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0',
        expanded: {
          color: 'HSL(0, 0%, 44%)',
          letterSpacing: '0',
          fontSize: '12px',
        },
      },
    },
    content: {
      fontSize: 15,
      lineHeight: 'auto',
      padding: '0 14px 9px 14px',
      color: 'inherit',
      expanded: {
        padding: '0 14px 9px 14px',
        color: 'inherit',
      },
      title: {
        fontWeight: 600,
        color: 'inherit',
      },
    },
    media: {
      expanded: {
        padding: '14px',
      },
      thumb: {
        size: '34px',
        borderRadius: '1px',
      },
    },
  },
}

export const windows: StyledPreviewTheme = {
  platform: 'windows',
  height: '673px',
  borderRadius: '40px',
  landingWidth: '315px',
  landingHeight: '561px',
  landingScale: 1.206349206,
  containerPadding: '52px 10px 68px 10px',
  containerRadius: '0px',
  ls: {
    padding: '72px 20px 10px 20px',
    fontWeight: 200,
    time: {
      letterSpacing: '0px',
      fontSize: '74px',
      lineHeight: '73px',
    },
    date: {
      fontSize: '16px',
      fontWeight: 'auto',
      lineHeight: '20px',
    },
  },
  notif: {
    borderRadius: '0px',
    backgroundColor: 'HSL(210, 77%, 46%)',
    margin: '0px 8px 0 8px',
    expanded: {
      backgroundColor: 'HSL(210, 77%, 46%)',
      margin: '30px 2px 0 2px',
    },
    color: '#FFF',
    head: {
      padding: '9px 9px 7px 9px',
      expanded: {
        padding: '14px 10px 14px 10px',
      },
      icon: {
        size: '20px',
        borderRadius: '4px',
        margin: '0 8px 0 0',
      },
      appName: {
        color: '#FFF',
        fontSize: '12px',
        textTransform: 'inherit',
        grow: 0,
      },
      date: {
        color: 'HSL(210, 77%, 46%)',
        fontSize: '11px',
        fontWeight: 'auto',
        letterSpacing: '0',
        expanded: {
          color: 'HSL(210, 77%, 46%)',
          letterSpacing: '0',
          fontSize: '12px',
        },
      },
    },
    content: {
      fontSize: 'auto',
      color: 'inherit',
      padding: '0 9px 9px 9px',
      lineHeight: 'auto',
      expanded: {
        padding: '0 9px 9px 9px',
        color: 'inherit',
      },
      title: {
        fontWeight: 'normal',
        color: 'inherit',
      },
    },
    media: {
      expanded: {
        padding: '0',
      },
      thumb: {
        size: '34px',
        borderRadius: '3px',
      },
    },
  },
}

export const webpush = {
  platform: 'webpush' as Platforms,
  height: '442px',
  landingWidth: '315px',
  landingHeight: '561px',
  borderRadius: '0',
  landingScale: 1.206349206,
  containerPadding: '70px 20px 0 15px',
  containerRadius: '0px',
  ls: {
    padding: '72px 20px 10px 20px',
    fontWeight: 200,
    time: {
      letterSpacing: '0px',
      fontSize: '74px',
      lineHeight: '73px',
    },
    date: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  notif: {
    borderRadius: '0px',
    backgroundColor: '#FFF',
    margin: '0',
    expanded: {
      backgroundColor: '#FFF',
      margin: '18px 2px 0 2px',
    },
    color: '#000',
    head: {
      padding: '9px 9px 7px 9px',
      expanded: {
        padding: '14px 10px 14px 10px',
      },
      icon: {
        size: '70px',
        borderRadius: '4px',
        margin: '0 8px 0 0',
      },
      appName: {
        color: '#000',
        fontSize: '12px',
        textTransform: 'inherit',
        grow: 0,
      },
      date: {
        color: 'HSL(210, 77%, 46%)',
        fontSize: '12px',
        letterSpacing: '0',
        expanded: {
          color: 'HSL(210, 77%, 46%)',
          letterSpacing: '0',
          fontSize: '12px',
        },
      },
    },
    content: {
      padding: '0 9px 9px 9px',
      expanded: {
        padding: '0 9px 9px 9px',
      },
      title: {
        fontWeight: 'bold',
      },
    },
    media: {
      expanded: {
        padding: '0',
      },
      thumb: {
        size: '35px',
        borderRadius: '0',
      },
    },
  },
}
