import * as React from 'react'

import { BoxBody, BoxHeader, BoxTitle } from 'components/common/box/box.styles'
import { Skeleton } from 'components/common/empty-states'
import Hint from 'components/common/hint'
import { Label } from 'components/common/label/label.styles'
import { Icon } from 'components/common/svg-icon'

import { ChannelMetricsBlock } from './channels-metrics.styles'

import { CHANNEL_THEMES } from 'com.batch/profilebase/constants/channel-themes'
import { type AnalyticsChannel } from 'com.batch/profilebase/infra/types/channel-type'
import { Metric } from 'com.batch/shared/ui/component/metric/metric'

type Props = {
  channel: AnalyticsChannel
  metrics: Array<{
    value: string
    label: React.ReactNode
    size?: MetricsSizeProps
    hint?: React.ReactNode
  }>
}

export const ChannelMetric = ({ channel, metrics }: Props): React.ReactElement => {
  return (
    <ChannelMetricsBlock>
      <BoxHeader>
        <BoxTitle>
          <Skeleton h={25} w={68}>
            <Label variant={CHANNEL_THEMES[channel].labelVariant}>
              <Icon icon={CHANNEL_THEMES[channel].icon} />
              {CHANNEL_THEMES[channel].title}
            </Label>
          </Skeleton>
        </BoxTitle>
      </BoxHeader>
      <BoxBody>
        {metrics.map((metric, index) => (
          <Metric
            key={index}
            value={metric.value}
            subtitle={
              <React.Fragment>
                {metric.label}
                {metric.hint && (
                  <Hint intent="disabled" placement="bottom-start">
                    {metric.hint}
                  </Hint>
                )}
              </React.Fragment>
            }
            size={metric.size ?? 'XL'}
          />
        ))}
      </BoxBody>
    </ChannelMetricsBlock>
  )
}
