import { useEffect } from 'react'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createParagraphNode, RootNode } from 'lexical'

const newlinesRegex = /[\n\r]/g

export function SingleLinePlugin(): null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const nodes = rootNode.getAllTextNodes()
      const textContent = rootNode.getTextContent()

      if (newlinesRegex.test(textContent)) {
        const paragraph = $createParagraphNode()
        paragraph.append(...nodes)
        rootNode.clear().append(paragraph)
        rootNode.selectEnd()
      }
    })
  }, [editor])

  return null
}
