import * as React from 'react'

import { TableTemplateCell } from 'components/common/table'

import { randomSize } from 'com.batch.common/utils'

export const AttributesEmptyTemplate = (): React.ReactElement => {
  const template = React.useMemo(() => {
    const templates = [
      '1fr',
      `${randomSize(14, 104)}px ${randomSize(14, 104)}px ${randomSize(14, 104)}px ${randomSize(
        14,
        104
      )}px ${randomSize(14, 104)}px`,
    ]
    return templates[Math.round(Math.random())]
  }, [])

  return (
    <React.Fragment>
      <TableTemplateCell gap={8} template="14px 1fr" />
      <TableTemplateCell gap={4} template={template} />
    </React.Fragment>
  )
}
