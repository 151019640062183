import { createSelector } from 'reselect'

import { type State } from 'com.batch.redux/_records'

import {
  type ProfilesDistributionStateRecord,
  type ProfilesDistributionRecord,
} from 'com.batch/profilebase/models/profiles-distribution.records'

type extract<T> = (arg1: State) => T

const profilesDistributionSelector = (state: State): ProfilesDistributionStateRecord => {
  return state.profilebase.profilesDistribution
}

export const distributionSelector: extract<ProfilesDistributionRecord> = createSelector(
  profilesDistributionSelector,
  profilesDistribution => profilesDistribution.distribution
)

export const profileDistributionSelector: extract<ProfilesDistributionStateRecord> = createSelector(
  profilesDistributionSelector,
  profileDistributionState => profileDistributionState
)
