import {
  EstimateByChannelFactory,
  EstimateCountFactory,
  EstimateProfileFactory,
  type EstimateProfileRecord,
} from '../../models/profile-estimate.records'
import { type ProfileEstimateResponse } from '../types/estimate.types'

/*
  PUSH has no notion of unique / non unique or optin
  to ease usage in components, we fill this value 
    - unique = nonUnique
    - matchingOptin = matching
*/

export const parseEstimateProfile = (data: ProfileEstimateResponse): EstimateProfileRecord => {
  return EstimateProfileFactory({
    loading: 'LOADED',
    total: EstimateByChannelFactory({
      profileCount: data.total?.profileCount ?? 0,
      reachableProfileCount: data.total?.reachableProfileCount ?? 0,
      email: EstimateCountFactory({
        uniqueCount: data.total.email?.uniqueCount ?? 0,
        nonUniqueCount: data.total.email?.nonUniqueCount ?? 0,
      }),
      sms: EstimateCountFactory({
        uniqueCount: data.total.sms?.uniqueCount ?? 0,
        nonUniqueCount: data.total.sms?.nonUniqueCount ?? 0,
      }),
      push: EstimateCountFactory({
        uniqueCount: data.total.push?.nonUniqueCount ?? 0,
        nonUniqueCount: data.total.push?.nonUniqueCount ?? 0,
        ios: data.total.push?.ios ?? 0,
        android: data.total.push?.android ?? 0,
        webpush: data.total.push?.webpush ?? 0,
      }),
    }),
    totalOptin: EstimateByChannelFactory({
      profileCount: data.totalOptin?.profileCount ?? 0,
      reachableProfileCount: data.totalOptin?.reachableProfileCount ?? 0,
      email: EstimateCountFactory({
        uniqueCount: data.totalOptin.email?.uniqueCount ?? 0,
        nonUniqueCount: data.totalOptin.email?.nonUniqueCount ?? 0,
      }),
      sms: EstimateCountFactory({
        uniqueCount: data.totalOptin.sms?.uniqueCount ?? 0,
        nonUniqueCount: data.totalOptin.sms?.nonUniqueCount ?? 0,
      }),
    }),
    matching: EstimateByChannelFactory({
      profileCount: data.matching?.profileCount ?? 0,
      reachableProfileCount: data.matching?.reachableProfileCount ?? 0,
      email: EstimateCountFactory({
        uniqueCount: data.matching.email?.uniqueCount ?? 0,
        nonUniqueCount: data.matching.email?.nonUniqueCount ?? 0,
      }),
      sms: EstimateCountFactory({
        uniqueCount: data.matching.sms?.uniqueCount ?? 0,
        nonUniqueCount: data.matching.sms?.nonUniqueCount ?? 0,
      }),
      push: EstimateCountFactory({
        uniqueCount: data.matching.push?.nonUniqueCount ?? 0,
        nonUniqueCount: data.matching.push?.nonUniqueCount ?? 0,
        ios: data.matching.push?.ios ?? 0,
        android: data.matching.push?.android ?? 0,
        webpush: data.matching.push?.webpush ?? 0,
      }),
    }),
    matchingOptin: EstimateByChannelFactory({
      profileCount: data.matchingOptin?.profileCount ?? 0,
      reachableProfileCount: data.matchingOptin?.reachableProfileCount ?? 0,
      email: EstimateCountFactory({
        uniqueCount: data.matchingOptin.email?.uniqueCount ?? 0,
        nonUniqueCount: data.matchingOptin.email?.nonUniqueCount ?? 0,
      }),
      sms: EstimateCountFactory({
        uniqueCount: data.matchingOptin.sms?.uniqueCount ?? 0,
        nonUniqueCount: data.matchingOptin.sms?.nonUniqueCount ?? 0,
      }),
      push: EstimateCountFactory({
        uniqueCount: data.matching.push?.nonUniqueCount ?? 0,
        nonUniqueCount: data.matching.push?.nonUniqueCount ?? 0,
        ios: data.matching.push?.ios ?? 0,
        android: data.matching.push?.android ?? 0,
        webpush: data.matching.push?.webpush ?? 0,
      }),
    }),
  })
}
