import * as React from 'react'
import { useTheme } from 'styled-components'

import { Icon } from 'components/common/svg-icon'

import { DateLabel, IosAppIcon, IosContainer, Message, Thumb, Title } from './ios-preview.styles'

import { EditableField } from 'com.batch/message-builder/ui/components/editable-field'
import { MediaField } from 'com.batch/push/ui/media-field'

type Props = {
  messageId: string
}
export const IosPreview = ({ messageId }: Props): React.ReactElement => {
  const { isExpanded } = useTheme()

  return (
    <IosContainer>
      <IosAppIcon />

      <Title>
        <EditableField field="pushTitle" messageId={messageId} />
      </Title>
      <Message>
        <EditableField field="pushBody" messageId={messageId} />
      </Message>

      <DateLabel>Now</DateLabel>
      <MediaField messageId={messageId} field="media">
        {({ url, overlay, kind }) => {
          if (kind === 'image' || url.split('.').pop() === 'gif') {
            return <Thumb $url={url}>{overlay}</Thumb>
          } else if (kind === 'audio') {
            return (
              <Thumb $url={url} $noBackground>
                {overlay}
                {isExpanded ? (
                  <audio autoPlay loop controls>
                    <source src={url} type="audio/mp3" />
                  </audio>
                ) : (
                  <Icon icon="play" />
                )}
              </Thumb>
            )
          } else {
            return (
              <Thumb $url={url} $noBackground>
                {overlay}
                {isExpanded ? (
                  <video autoPlay loop>
                    <source src={url} type="video/mp4" />
                  </video>
                ) : (
                  <Icon icon="play" />
                )}
              </Thumb>
            )
          }
        }}
      </MediaField>
    </IosContainer>
  )
}
