import styled from 'styled-components'

import { colors } from 'components/styled/tokens'

type StepMessageNameProps = {
  $isDeleted: boolean
}
export const StepMessageName = styled.div<StepMessageNameProps>`
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-weight: 500;
  color: ${props => (props.$isDeleted ? colors.textDisabled : colors.textNeutral)};

  i {
    font-size: 1.1em;
    position: relative;
    top: -1px;
  }
`
