import { type SegmentRecord } from '../models/segment.records'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { fetchSegmentList } from 'com.batch/segments/usecases/fetch-segment-list'

type DeleteSegmentAction = ReduxAction<'DELETE_SEGMENT', undefined>

export type DeleteSegmentFailureAction = ReduxAction<'DELETE_SEGMENT_FAILURE', undefined>

export type DeleteSegmentSuccessAction = ReduxAction<'DELETE_SEGMENT_SUCCESS', undefined>

export type DeleteSegmentActionType =
  | DeleteSegmentAction
  | DeleteSegmentSuccessAction
  | DeleteSegmentFailureAction

export const deleteSegment = (segment: SegmentRecord): DispatchExtraBoundFn<Promise<void>> => {
  return async (dispatch, getState, { segmentsService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    return promiseActionCreator({
      dispatch,
      promise: segmentsService
        .deleteSegment({
          projectKey: project.projectKey,
          segmentName: segment.name,
        })
        .then(() => {
          dispatch(
            fetchSegmentList({
              page: state.segments.page,
              pageSize: 10,
              search: state.segments.search,
              sortDirection: state.segments.sortDirection,
              sortField: state.segments.sortBy,
              forceTrashCache: true,
            })
          )
        }),
      payload: segment.name,
      actionName: 'DELETE_SEGMENT',
    })
  }
}
