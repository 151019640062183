import { NavLink } from 'react-router-dom'
import styled, { css, type DefaultTheme } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'

interface TableToggleTheme extends DefaultTheme {
  collapsed?: boolean
  fs?: string
  fw?: string
  padding?: string
  letterSpacing?: string
  textTransform?: string
  active?: {
    fs?: string
    fw?: string
    bg?: string
    fg?: string
  }
  hover?: {
    bg?: string
    fg?: string
  }
  default?: {
    bg?: string
    fg?: string
  }
  br?: number
}

const ItemStyle = css<{
  active?: boolean
  disabled?: boolean
  column?: boolean
  tiny?: boolean
  theme: TableToggleTheme
  to?: string
}>`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1 0 auto;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  margin: ${props => (props.theme.collapsed ? '0' : '0 2px')};
  padding: ${props => (props.tiny ? '3px' : props.theme.padding)};
  justify-content: center;
  font-size: ${props => props.theme.fs}px;
  font-weight: ${props => props.theme.fw};
  color: ${props => (props.active ? props.theme.active?.fg : props.theme.default?.fg)};
  background-color: ${props => (props.active ? props.theme.active?.bg : props.theme.default?.bg)};
  letter-spacing: ${props => props.theme.letterSpacing};
  text-transform: ${props => props.theme.textTransform};
  text-align: center;
  line-height: ${props => parseInt(props.theme?.fs ?? '0') + 4}px;
  border: none;
  border-radius: ${props => (props.theme.collapsed ? 0 : props.theme.br)}px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  outline: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled && !!props.to ? 'none' : 'auto')};
  ${props =>
    !props.active &&
    !props.disabled &&
    css`
      &:hover,
      &:focus {
        color: ${props.theme.hover?.fg};
        background-color: ${props.theme.hover?.bg};
      }
    `}

  i {
    display: ${props => (props.column ? 'block' : 'inline-block')};
    margin: ${props => (props.column ? '4px 8px 4px 8px' : '0 8px 0 0')};
  }
  ${IconContainer} {
    margin: 0 -2px;
  }

  &:first-child {
    margin-left: 0;
    border-radius: ${props =>
      props.theme.collapsed
        ? `${props.theme.br}px 0 0 ${props.theme.br}px`
        : `${props.theme.br}px`};
  }
  &:last-child {
    margin-right: 0;
    border-radius: ${props =>
      props.theme.collapsed
        ? `0 ${props.theme.br}px ${props.theme.br}px 0`
        : `${props.theme.br}px`};
  }
`

export const ToggleButton = styled.button`
  ${ItemStyle}
`
export const ToggleLink = styled(NavLink)`
  ${ItemStyle}
  &.active {
    color: ${props => props.theme.active?.fg};
    background-color: ${props => props.theme.active?.bg};
  }
`
type ToggleContainerProps = {
  width?: number
  inline?: boolean
}
export const ToggleContainer = styled.div<ToggleContainerProps>`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  min-height: 36px;
  justify-content: space-evenly;
  width: ${props => (props.width ? `${props.width}px` : props.inline ? 'auto' : '100%')};
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.02);
`
