import * as React from 'react'

import { ConfirmModalFooter } from 'components/common/confirm-modal-footer'
import { Password } from 'components/form'

import { type UserRecord } from 'com.batch.redux/user.records'

import { securityService } from 'com.batch/shared/infra/security-service.api'
import {
  ConfirmMFA,
  ConfirmMFAFooter,
} from 'com.batch/shared/ui/component/confirm-mfa/confirm-mfa.styles'

type Props = {
  onSuccess: () => void
  onCancel: () => void
  confirm?: string
  cancel: string
  sensitive?: boolean
  user: UserRecord
  authRequired: boolean
}

export const PasswordMfa = ({
  onSuccess,
  onCancel,
  confirm,
  cancel,
  sensitive,
  user,
  authRequired,
}: Props): React.ReactElement => {
  const [password, setPassword] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>('')

  const isConfirmBtnDisabled = React.useMemo<boolean>(
    () => authRequired && password.length < 8,
    [authRequired, password.length]
  )

  const handleOnClickConfirm = React.useCallback(() => {
    if (!authRequired) {
      return onSuccess()
    }
    if (isLoading) return
    setIsLoading(true)
    securityService
      .validatePassword({ password, email: user.email })
      .then(onSuccess)
      .catch(error => {
        setError(error.message)
        setIsLoading(false)
      })
  }, [authRequired, isLoading, password, user.email, onSuccess])

  return (
    <ConfirmMFAFooter>
      {authRequired && (
        <ConfirmMFA style={{ marginBottom: '4px', maxWidth: '90%' }}>
          <p>Please confirm by entering your password</p>
          <Password invalid={!!error} feedback={error} value={password} setValue={setPassword} />
        </ConfirmMFA>
      )}
      <ConfirmModalFooter
        sensitive={sensitive}
        cancel={cancel}
        confirm={confirm}
        onClickCancel={onCancel}
        onClickConfirm={handleOnClickConfirm}
        confirmDisabled={isConfirmBtnDisabled}
        isLoading={isLoading}
      />
    </ConfirmMFAFooter>
  )
}
