import { type List } from 'immutable'
import * as React from 'react'

import { Separator } from 'components/styled/utils'

import { type Platform } from 'com.batch/profile/infra/debug/models/shared.model'
import {
  type SubscriptionRecord,
  type InstallRecord,
  type ProfileWebpushPlatformsRecord,
} from 'com.batch/profile/models/profile.records'
import { EmailChannelBlock } from 'com.batch/profile/ui/components/profile-hero/channel-block/email-channel-block'
import { PushChannelBlock } from 'com.batch/profile/ui/components/profile-hero/channel-block/push-channel-block'
import { SmsChannelBlock } from 'com.batch/profile/ui/components/profile-hero/channel-block/sms-channel-block'
import {
  ProfileBodyContainer,
  ProfileBodyChannels,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'
import { ProfilePlatforms } from 'com.batch/profile/ui/components/profile-hero/profile-platforms/profile-platforms'

type Props = {
  email: string | null | undefined
  isLoading?: boolean
  mobilePlatforms: List<InstallRecord>
  phoneNumber: string | null | undefined
  subscription: SubscriptionRecord
  webpushPlatforms: List<ProfileWebpushPlatformsRecord>
  findDebugUrl: (installId: string, platform: Platform) => string
  platformsCount: number
}

export const ProfileHeroBody = ({
  email,
  isLoading = false,
  mobilePlatforms,
  phoneNumber,
  subscription,
  webpushPlatforms,
  findDebugUrl,
  platformsCount,
}: Props): React.ReactElement => (
  <ProfileBodyContainer>
    <ProfileBodyChannels>
      <PushChannelBlock
        isLoading={isLoading}
        webpushPlatforms={webpushPlatforms}
        mobilePlatforms={mobilePlatforms}
      />
      <Separator fullHeight />
      <EmailChannelBlock isLoading={isLoading} email={email} subscription={subscription} />
      <Separator fullHeight />
      <SmsChannelBlock
        isLoading={isLoading}
        phoneNumber={phoneNumber}
        subscription={subscription}
      />
    </ProfileBodyChannels>
    <ProfilePlatforms
      isLoading={isLoading}
      mobilePlatforms={mobilePlatforms}
      webpushPlatforms={webpushPlatforms}
      findDebugUrl={findDebugUrl}
      platformsCount={platformsCount}
    />
  </ProfileBodyContainer>
)
