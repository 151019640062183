import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { Box, BoxHeader, HeaderBoxActions, HeaderBoxTitle } from 'components/common/box'
import { BoxBody } from 'components/common/box/box.styles'
import { Grid } from 'components/common/grid'
import { Targeting } from 'components/project/campaign/common/targeting'
import { Content } from 'components/styled/blocs'

import {
  orchestrationCampaignSelector,
  orchestrationMetaSelector,
} from 'com.batch/orchestration/store/orchestration.selectors'

import { EmailBuilder } from 'com.batch/email/ui/pages/email-builder'
import { getMessageId } from 'com.batch/message/models/message.helper'
import { ExperimentToggle } from 'com.batch/message/ui/components/experiment-toggle'
import { MultilanguageToggle } from 'com.batch/message/ui/components/multilanguage-toggle'
import { LangPicker } from 'com.batch/message-builder/ui/components/lang-picker/lang-picker'
import { VariantPicker } from 'com.batch/message-builder/ui/components/variant-picker/variant-picker'
import { FooterOrchestration } from 'com.batch/orchestration/ui/components/footer-orchestration'
import { PlatformPicker } from 'com.batch/orchestration/ui/components/platform-picker'
import { useForceEnabledForm } from 'com.batch/orchestration/ui/components/use-force-enabled-form'
import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { WhenOrchestration } from 'com.batch/orchestration-campaign/ui/components/when-orchestration'
import { updateOrchestrationCampaign } from 'com.batch/orchestration-campaign/usecases/update-orchestration-campaign'
import { TargetingWrapperCampaign } from 'com.batch/orchestration-journey/ui/components/sheet-settings.styles'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { PushAdvancedSettings } from 'com.batch/push/ui/push-advanced-settings'
import { PushBuilder } from 'com.batch/push/ui/push-builder'
import { AdvancedSettingsPanel } from 'com.batch/shared/ui/component/advanced-settings-panel'
import { SmsBuilder } from 'com.batch/sms/ui/components/sms-builder'

export const CampaignForm = (): React.ReactElement => {
  const schedulingTypeFromUrl = useGetSchedulingTypeFromUrl()
  const orchestration = useSelector(orchestrationCampaignSelector)
  const forceEnabled = useForceEnabledForm()

  const params = useParams()
  const channel: ChannelUntilCleanup = React.useMemo(() => {
    switch (params?.channel) {
      case 'email':
        return 'email'
      case 'sms':
        return 'sms'
      case 'push':
        return 'push'
      default:
        throw new Error('Unhandled channel parameter in URL')
    }
  }, [params?.channel])

  const { state } = useSelector(orchestrationMetaSelector)
  const dispatch = useDispatch()
  const userHasWritePermission = useIsCurrentUserAllowedTo(['app', 'push:write'])
  const formTheme = React.useMemo(
    () => ({
      kind: 'capture',
      disabledMode: (state === 'COMPLETED' || !userHasWritePermission) && !forceEnabled,
    }),
    [state, userHasWritePermission, forceEnabled]
  )
  const onOrchestrationCampaignChange = React.useCallback(
    (campaign: OrchestrationCampaignRecord) => {
      dispatch(updateOrchestrationCampaign(campaign))
    },
    [dispatch]
  )

  const shownMessageId = React.useMemo(
    () => getMessageId(orchestration.messageConfig),
    [orchestration.messageConfig]
  )

  return (
    <Content>
      <ThemeProvider theme={formTheme}>
        <TargetingWrapperCampaign>
          {channel && <Targeting canvasMode={false} isProfile id="default" channel={channel} />}
        </TargetingWrapperCampaign>
        <WhenOrchestration
          schedulingType={schedulingTypeFromUrl}
          value={orchestration}
          onChange={onOrchestrationCampaignChange}
          state={state}
        />

        <Box>
          <BoxHeader>
            <Grid template="auto auto" gap={8}>
              <HeaderBoxTitle title="Message" />
              {channel === 'push' && <PlatformPicker campaignState={state} />}
            </Grid>
            <HeaderBoxActions>
              {channel !== 'sms' && <ExperimentToggle stepMessageNodeId={null} />}
              <MultilanguageToggle stepMessageNodeId={null} />
            </HeaderBoxActions>
          </BoxHeader>
          <VariantPicker stepMessageNodeId={null} />

          {orchestration.messageConfig.multilanguageEnabled && (
            <LangPicker messageId={shownMessageId} channel={channel} />
          )}
          <BoxBody
            style={{
              minHeight: 480,
              height: channel === 'email' ? '700px' : 'auto',
              overflow: 'hidden',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            {channel === 'email' && <EmailBuilder stepMessageNodeId={undefined} />}
            {channel === 'sms' && <SmsBuilder messageId={shownMessageId} />}
            {channel === 'push' && (
              <React.Fragment>
                <PushBuilder stepMessageNodeId={undefined} />
                <AdvancedSettingsPanel campaignFormMode>
                  <PushAdvancedSettings stepMessageNodeId={undefined} />
                </AdvancedSettingsPanel>
              </React.Fragment>
            )}
          </BoxBody>
        </Box>
      </ThemeProvider>
      <FooterOrchestration
        isFixed={false}
        channel={channel}
        multilanguageEnabledOnCampaign={orchestration.messageConfig.multilanguageEnabled}
      />
    </Content>
  )
}
