import { textUsesTemplating } from 'com.batch.common/utils'

import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { SmsContentFactory, SmsMessageFactory } from 'com.batch/message/models/message.records'

import { fetchTemplates } from 'com.batch/message/usecases/fetch-templates'
import {
  type ContentToTemplate,
  type TemplatedContent,
} from 'com.batch/shared/infra/oursql.service'

export type UpdateSmsTemplateAction = {
  type: 'UPDATE_SMS_TEMPLATE'
  payload: {
    messageId: string
    lang: string
    template: string
  }
}

export const updateSmsTemplate = ({
  messageId,
  lang,
}: {
  messageId: string
  lang: string
}): DispatchExtraBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    const smsMessage = state.message.sms
      .get(messageId, SmsMessageFactory())
      .localizedContent.get(lang, SmsContentFactory()).smsMessage

    if (!textUsesTemplating(smsMessage)) {
      return dispatch({
        type: 'UPDATE_SMS_TEMPLATE',
        payload: {
          messageId,
          lang,
          template: smsMessage,
        },
      })
    }

    const contents: Array<ContentToTemplate> = [
      { messageId, lang, field: 'smsMessage', value: smsMessage, type: 'TEMPLATE' },
    ]
    return dispatch(fetchTemplates(contents))
      .then((res: Array<TemplatedContent>) => {
        const { template, value } = res[0]
        dispatch({
          type: 'UPDATE_SMS_TEMPLATE',
          payload: {
            messageId,
            lang,
            template: template ?? value,
          },
        })
      })
      .catch(e => {
        console.error(
          'Error while fetching templates:\n',
          e.error?.errors?.[0]?.message ?? 'Unknown error'
        )
      })
  }
}
