import Immutable, { type List } from 'immutable'
import { map as _map } from 'lodash-es'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  type AttributeValuesListRecord,
  type AttributeValueState,
  type AttributeValueRecord,
  AttributeValuesListFactory,
  AttributeValueFactory,
} from 'com.batch.redux/_records'
import { type AnalyticByPeriodRecord, AnalyticByPeriodFactory } from 'com.batch.redux/stat.records'

const initialAVState: AttributeValuesListRecord = AttributeValuesListFactory()
const initialState: AttributeValueState = Immutable.Map()

// ========================================================
// ACTIONS
// ========================================================
type setAttributeValuesPageAction = {
  type: 'SET_ATTR_VALUE_PAGE'
  payload: {
    attributeId: string
    page: number
  }
}
export const setAttributeValuesPage = (
  attributeId: string,
  page: number
): setAttributeValuesPageAction => {
  return {
    type: 'SET_ATTR_VALUE_PAGE',
    payload: { attributeId, page },
  }
}
type setAttributeValuesModeAction = {
  type: 'SET_ATTR_VALUE_MODE'
  payload: {
    attributeId: string
    mode: string
  }
}
export const setAttributeValuesMode = (
  attributeId: string,
  mode: string
): setAttributeValuesModeAction => {
  return {
    type: 'SET_ATTR_VALUE_MODE',
    payload: { attributeId, mode },
  }
}
type createAttributeValueAction = {
  type: 'CREATE_ATTRIBUTE_VALUE'
  payload: {
    attributeId: string
    label: string
    mode: string
  }
}
export const createAttributeValue = (
  attributeId: string,
  label: string,
  mode: string = '__default'
): createAttributeValueAction => {
  return {
    type: 'CREATE_ATTRIBUTE_VALUE',
    payload: { attributeId, label, mode },
  }
}
type receiveEventDataAction = {
  type: 'RECEIVE_EVENT_DATA'
  payload: {
    events: List<AttributeValueRecord>
  }
}
type fetchAnalyticsByDateSuccessAction = {
  type: 'FETCH_ANALYTIC_BY_DATE_SUCCESS'
  payload: List<AnalyticByPeriodRecord>
}
type fetchAttributeValueAction = {
  type: 'FETCH_ATTRIBUTE_VALUE'
  payload: {
    attributeId: string
  }
}
type fetchAttributesValuesAction = {
  type: 'FETCH_ATTRIBUTES_VALUES'
  payload: any
}
export type fetchAttributesValuesfailure = {
  type: 'FETCH_ATTRIBUTES_VALUES_FAILURE'
  payload: any
}

type fetchAttributeValueFailureAction = {
  type: 'FETCH_ATTRIBUTE_VALUE_FAILURE'
  payload: {
    attributeId: string
  }
}
type fetchAttributeValueSuccessAction = {
  type: 'FETCH_ATTRIBUTE_VALUE_SUCCESS'
  payload: {
    mode: string
    attributeId: string
    values: Array<{
      nb: number
      pretty: string
      value: string | number
      pcent: string
    }>
  }
}
type fetchAttributesValuesSuccessAction = {
  type: 'FETCH_ATTRIBUTES_VALUES_SUCCESS'
  payload: {
    attributeId: Array<{
      nb: number
      pretty: string
      value: string | number
      pcent: string
    }>
  }
}
// ========================================================
// REDUCERS
// ========================================================
type oneAttributeActions =
  | createAttributeValueAction
  | setAttributeValuesPageAction
  | setAttributeValuesModeAction
  | fetchAttributesValuesfailure
  | receiveEventDataAction
  | fetchAnalyticsByDateSuccessAction
  | fetchAttributeValueAction
  | fetchAttributeValueFailureAction
  | fetchAttributesValuesAction
  | fetchAttributeValueSuccessAction
  | fetchAttributesValuesSuccessAction
function oneAttributeValuesReducer(
  state: AttributeValuesListRecord = initialAVState,
  action: oneAttributeActions
) {
  switch (action.type) {
    case 'RECEIVE_EVENT_DATA': {
      const events = action.payload.events.sort((a, b) => {
        const aM = dayjs(a.value, 'DD/MM/YYYY')
        const bM = dayjs(b.value, 'DD/MM/YYYY')
        return dayjs.isDayjs(aM) && dayjs.isDayjs(bM)
          ? aM.isBefore(bM)
            ? 1
            : -1 // $FlowFixMe typing is not ideal for this
          : a.value > b.value
            ? -1
            : 1
      })
      return state.setIn(['values', 'events'], events).set('mode', 'events')
    }
    case 'SET_ATTR_VALUE_MODE':
      return state.set('mode', action.payload.mode).set('page', 1)
    case 'FETCH_ATTRIBUTE_VALUE':
    case 'FETCH_ATTRIBUTE_VALUE_FAILURE':
      return state.set('loading', action.type !== 'FETCH_ATTRIBUTE_VALUE_FAILURE')
    case 'FETCH_ATTRIBUTES_VALUES':
      return initialAVState.set('loading', true)
    case 'CREATE_ATTRIBUTE_VALUE':
      return state.setIn(
        ['values', action.payload.mode],
        state.values.get(action.payload.mode, Immutable.List()).push(
          AttributeValueFactory({
            value: action.payload.label,
            pretty: action.payload.label,
          })
        )
      )

    case 'FETCH_ATTRIBUTE_VALUE_SUCCESS': {
      return state
        .set('count', action.payload.values.length)
        .set('loading', false)
        .setIn(
          ['values', action.payload.mode],
          Immutable.List().push(
            ...action.payload.values.map(raw =>
              AttributeValueFactory({
                installs: raw.nb,
                pretty: raw.pretty,
                value: raw.value,
                pcent: raw.pcent,
              })
            )
          )
        )
    }
    case 'FETCH_ATTRIBUTES_VALUES_SUCCESS':
    default:
      return state
  }
}

type targetingParseAction = {
  type: 'TARGETING_PARSE'
  payload: {
    missingValues?: Array<{
      attrId: string
      value: {
        value: string
      }
    }>
  }
}

type allAttrActions = oneAttributeActions | targetingParseAction

export default function allAttributesValuesReducer(
  state: AttributeValueState = initialState,
  action: allAttrActions
): AttributeValueState {
  switch (action.type) {
    case 'TARGETING_PARSE':
      if (
        typeof action.payload.missingValues === 'undefined' ||
        action.payload.missingValues.length === 0
      ) {
        return state
      } else {
        action.payload.missingValues.forEach(row => {
          state = state.set(
            row.attrId,
            oneAttributeValuesReducer(state.get(row.attrId, initialAVState), {
              type: 'CREATE_ATTRIBUTE_VALUE',
              payload: {
                label: row.value.value,
                attributeId: row.attrId,
                mode: 'default',
              },
            })
          )
        })
        return state
      }
    case 'FETCH_ANALYTIC_BY_DATE_SUCCESS': {
      const payload = action.payload
      // we build an array with attributeId & data for each event the XHR returned
      const events: Array<{
        attributeId: string
        data: List<AttributeValueRecord>
      }> = payload
        .get(0, AnalyticByPeriodFactory())
        .events.keySeq()
        .map(attributeId => {
          return {
            attributeId,
            data: payload.map(byDay =>
              AttributeValueFactory({
                installs: byDay.events.get(attributeId, 0),
                pretty: byDay.period.format('DD/MM/YYYY'),
                value: byDay.period.format('DD/MM/YYYY'),
              })
            ),
          }
        })
        .toArray()
      // map this object, and fire an action on the single reducer to update
      // the revelant slice
      events.forEach(event => {
        const currentValue = state.get(event.attributeId, initialAVState)
        const slice = oneAttributeValuesReducer(currentValue, {
          type: 'RECEIVE_EVENT_DATA',
          payload: {
            events: event.data,
          },
        })
        state = state.set(event.attributeId, slice)
      })
      return state
    }

    case 'SET_ATTR_VALUE_PAGE':
      return state.set(
        action.payload.attributeId,
        state
          .get(action.payload.attributeId, AttributeValuesListFactory())
          .set('page', action.payload.page)
      )

    case 'FETCH_ATTRIBUTE_VALUE_SUCCESS':
    case 'SET_ATTR_VALUE_MODE':
    case 'FETCH_ATTRIBUTE_VALUE':
    case 'FETCH_ATTRIBUTE_VALUE_FAILURE':
    case 'CREATE_ATTRIBUTE_VALUE': {
      const attributeId = action.payload.attributeId
      return state.set(
        attributeId,
        oneAttributeValuesReducer(state.get(attributeId, initialAVState), action)
      )
    }
    case 'FETCH_ATTRIBUTES_VALUES': {
      const fixedPayload = action.payload // so flow does not fear it has mutate

      return action.payload.ids.reduce(
        (acc, attrValue) =>
          acc.set(
            attrValue,
            oneAttributeValuesReducer(initialAVState, {
              payload: fixedPayload,
              type: 'FETCH_ATTRIBUTES_VALUES',
            })
          ),
        Immutable.Map()
      )
    }

    case 'FETCH_ATTRIBUTES_VALUES_SUCCESS': {
      state = state.map(attr => attr.set('loading', false))
      _map(action.payload, (values, id) => {
        state = state.set(
          id,
          oneAttributeValuesReducer(state.get(id, initialAVState), {
            type: 'FETCH_ATTRIBUTE_VALUE_SUCCESS',
            payload: { values, mode: '__default', attributeId: id },
          })
        )
      })
      return state
    }

    case 'FETCH_ATTRIBUTES_VALUES_FAILURE': // $FlowFixMe
      return state.map(attr => attr.set('loading', false))
    default:
      return state
  }
}
