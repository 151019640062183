/**
 *
 * Ce composant va remplacer nos 3 overlay d'empty state actuels :
 *    - NoData / NoDataScoped -> empty ou error, avec un refresh btn optionnel et des liens optionnels
 *    - RestrictedAccess ->  resticted ou upgrade, la version avec les gros icons, et un lien vers les permissions si c'est restricted ou un lien custom optionnel
 *    - PlanSection -> resticted ou upgrade, avec des liens optioonnels
 *
 * On a donc :
 *  - 3 modes page (gros icons quand il y en a, gros texte) pour les soucis de permissions ou d'upgrade,
 *  - 4 modes blocs (petits icons) pour les cas no data, error, permission ou upgrade
 */

import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { LinkArrow, LinkDoc } from 'components/styled/text'

import { generateUrl } from 'com.batch.common/router'

import { LockIcon, CrownIcon } from './overlay.icons'
import {
  RestrictedAccessContainer,
  RestrictedAccessContent,
  RestrictedAccessIcon,
  ScopedIconContainer,
  EmptyStateDescription,
  NoDataContainer,
  EmptyStateSubtitle,
  EmptyStateTitle,
} from './overlay.styles'

export type OverlayLink = {
  name: string
  href: string
  isDocLink?: boolean
  intent?: 'action' | 'neutral'
}

type OverlayUpgradePageProps = {
  status: 'upgrade-page'
  title?: string
  style?: {
    [key: string]: string | number
  }
  companyId: number
  description?: React.ReactNode
  link?: OverlayLink
}
type OverlayRestrictedPageProps = {
  status: 'restricted-page'
  title: string
  style?: {
    [key: string]: string | number
  }
  description?: React.ReactNode
}
type OverlayUpgradeOrRestricedProps = {
  status: 'upgrade' | 'restricted'
  title: React.ReactNode
  style?: {
    [key: string]: string | number
  }
  description?: React.ReactNode
  links?: Array<OverlayLink>
}
export type OverlayEmptyProps = {
  status: 'error' | 'empty' | 'error-page' | 'empty-page'
  title?: React.ReactNode
  subtitle?: React.ReactNode
  style?: {
    [key: string]: string | number
  }
  description?: React.ReactNode
  content?: React.ReactNode
  refresh?: () => void
  links?: Array<OverlayLink>
}
export type OverlayProps =
  | OverlayUpgradePageProps
  | OverlayRestrictedPageProps
  | OverlayUpgradeOrRestricedProps
  | OverlayEmptyProps
const isOverlayUpgradePage = (props: OverlayProps): props is OverlayUpgradePageProps =>
  props.status === 'upgrade-page'
const isOverlayRestrictedPage = (props: OverlayProps): props is OverlayRestrictedPageProps =>
  props.status === 'restricted-page'
const isOverlayEmpty = (props: OverlayProps): props is OverlayEmptyProps =>
  props.status === 'error' ||
  props.status === 'empty' ||
  props.status === 'error-page' ||
  props.status === 'empty-page'

export const Overlay = (props: OverlayProps): React.ReactElement => {
  const handleOnClickUpgradeNow = React.useCallback(() => {
    if (isOverlayUpgradePage(props))
      window.location.href = generateUrl('company_billing', {
        companyId: props.companyId,
      })
  }, [props])
  if (isOverlayUpgradePage(props) || isOverlayRestrictedPage(props)) {
    return (
      <RestrictedAccessContainer style={props.style}>
        <RestrictedAccessIcon>
          {isOverlayUpgradePage(props) ? <CrownIcon /> : <LockIcon />}
        </RestrictedAccessIcon>
        <RestrictedAccessContent>
          <EmptyStateTitle kind="restrictedAccess">{props.title}</EmptyStateTitle>

          {(!!props.description ||
            (props as OverlayUpgradePageProps).companyId ||
            (props as OverlayUpgradePageProps).link ||
            status === 'restricted-page') && (
            <EmptyStateDescription>{props.description}</EmptyStateDescription>
          )}

          <div className="styled-restrict-access-links">
            {isOverlayUpgradePage(props) && !!props.companyId && (
              <React.Fragment>
                <Button kind="primary" intent="action" onClick={handleOnClickUpgradeNow}>
                  Upgrade now
                </Button>
                {!!props.link && <LinkDoc href={props.link.href}>{props.link.name}</LinkDoc>}
              </React.Fragment>
            )}

            {isOverlayRestrictedPage(props) && (
              <LinkDoc href="https://doc.batch.com/dashboard/settings/company-settings#managing-permissions">
                Learn about permissions
              </LinkDoc>
            )}
          </div>
        </RestrictedAccessContent>
      </RestrictedAccessContainer>
    )
  } else {
    return (
      <NoDataContainer style={props.style}>
        {props.status !== 'empty' && props.status !== 'empty-page' && (
          <ScopedIconContainer
            intent={
              props.status === 'error' || props.status === 'error-page'
                ? 'failed'
                : props.status === 'upgrade'
                  ? 'upgrade'
                  : 'permission'
            }
          >
            <Icon
              icon={
                props.status === 'error' || props.status === 'error-page'
                  ? 'cross'
                  : props.status === 'upgrade'
                    ? 'crown'
                    : 'lock'
              }
              size={props.status === 'error' || props.status === 'error-page' ? 10 : 13}
            />
          </ScopedIconContainer>
        )}

        <EmptyStateTitle kind={props.status.indexOf('page') !== -1 ? 'noData' : 'scoped'}>
          {props.title}
        </EmptyStateTitle>

        {(!!props.description ||
          (props as OverlayEmptyProps).content ||
          props.links ||
          (props as OverlayEmptyProps).refresh) && (
          <EmptyStateDescription kind={props.status.indexOf('page') !== -1 ? 'noData' : 'scoped'}>
            {props.description}
          </EmptyStateDescription>
        )}
        {isOverlayEmpty(props) && (
          <React.Fragment>
            {Boolean(props.content) && <div style={{ marginBottom: 20 }}>{props.content}</div>}
            {Boolean(props.subtitle) && <EmptyStateSubtitle>{props.subtitle}</EmptyStateSubtitle>}
          </React.Fragment>
        )}
        {!!props.links && (
          <div className="styled-empty-state-links">
            {props.links.map((link, index) =>
              link.isDocLink ? (
                <LinkDoc href={link.href} key={index}>
                  {link.name}
                </LinkDoc>
              ) : (
                <LinkArrow key={index} href={link.href} intent={link.intent}>
                  {link.name}
                </LinkArrow>
              )
            )}
          </div>
        )}

        {isOverlayEmpty(props) && Boolean(props.refresh) && (
          <div className="styled-empty-state-links">
            <Button kind="inline" addOn="prefix" onClick={props.refresh}>
              <Icon icon="reload" />
              Refresh page
            </Button>
          </div>
        )}
      </NoDataContainer>
    )
  }
}
