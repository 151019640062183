import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { Skeleton } from 'components/common/empty-states'
import { Tooltip } from 'components/common/tooltip'
import { colors } from 'components/styled/tokens'
import { HorizontalDivider } from 'components/styled/utils'

import { type Dayjs } from 'com.batch.common/dayjs.custom'
import { getOffsetForTimezone } from 'com.batch.common/get-offset-for-timezone'

import {
  TimeContent,
  TimeLabel,
  TimeLegend,
  TimeTooltip,
  TimeTooltipContent,
  TimeValue,
} from '../timeline/timeline.styles'
import { profileDataStateSelector } from 'com.batch/profile/store/profile.selector'

type Props = {
  date: Dayjs
}

export const Time = ({ date }: Props): React.ReactElement => {
  const { timezone } = useSelector(profileDataStateSelector)
  const browserTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
  const localTime = React.useMemo(() => date.format('HH:mm'), [date])
  const profileTime = React.useMemo(
    () => (timezone ? date.tz(timezone).format('HH:mm') : null),
    [date, timezone]
  )
  return (
    <Tooltip
      tooltip={
        <TimeTooltip>
          {browserTZ && (
            <TimeTooltipContent>
              <div>
                <TimeLabel>Your time</TimeLabel>
                <TimeLegend>{getOffsetForTimezone(browserTZ)}</TimeLegend>
              </div>
              <TimeValue>{localTime}</TimeValue>
            </TimeTooltipContent>
          )}
          {timezone && browserTZ && <HorizontalDivider color={colors.strokeContrast} />}
          {timezone && (
            <TimeTooltipContent>
              <div>
                <TimeLabel>Profile's time</TimeLabel>
                <TimeLegend>{getOffsetForTimezone(timezone)}</TimeLegend>
              </div>
              <TimeValue>{profileTime}</TimeValue>
            </TimeTooltipContent>
          )}
        </TimeTooltip>
      }
      placement="left"
      maxWidth={204}
      minWidth={204}
    >
      <TimeContent>
        <Skeleton w={30} h={14}>
          {localTime}
        </Skeleton>
      </TimeContent>
    </Tooltip>
  )
}
