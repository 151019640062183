import styled, { css } from 'styled-components'

import { colors } from 'components/styled/tokens'
import { grayscale } from 'components/styled/tokens/schemes'

import { BaseThumb, InactiveEditorContainer } from 'com.batch/message-builder/ui/components/common'

export const Notif = styled.div`
  background: ${p => (p.theme.isFocused ? grayscale[10] : grayscale['05'])};
  transition: background-color 0.2s ease;
  border-radius: 6px;
  overflow: hidden;
  pointer-events: ${p => (p.theme.disabledMode ? 'none' : 'unset')};
  margin-top: ${p => (p.theme.abTestingEnabled ? '197' : '160')}px;
  margin-bottom: ${p => (p.theme.abTestingEnabled ? '83' : '90')}px;
  width: 386px;
`
export const NotifContent = styled.div`
  padding: 10px 10px 10px 10px;
`
export const Sender = styled.div`
  color: ${colors.textLight};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 142%;
  padding: 0 6px;
  grid-area: sender;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

type ThumbProps = {
  $url: string
}

export const Thumb = styled(BaseThumb)<ThumbProps>`
  display: ${p => (!!p.$url || p.theme.draggingState !== 'NONE' ? 'block' : 'none')};
  position: relative;
  width: 100%;
  max-height: 320px;
  ${p =>
    !!p.$url || p.theme.draggingState !== 'NONE'
      ? css`
          min-height: 160px;
        `
      : ''};
  overflow: hidden;

  ${p =>
    p.$url &&
    css`
      background-image: url(${p.$url});
    `}
  background-size: cover;
  background-position: center center;
  img {
    width: 100%;
    display: block;
    visibility: hidden;
  }
`
type SmallIconProps = {
  $url: string
}
export const SmallIcon = styled(BaseThumb)<SmallIconProps>`
  display: ${p => (p.$url ? 'block' : 'none')};
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;

  ${p =>
    p.$url &&
    css`
      background-image: url(${p.$url});
    `}
  background-size: cover;
  background-position: center center;
  margin-top: 8px;
  grid-area: small;
`
export const Header = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr 16px;
  grid-column-gap: 8px;
  margin-bottom: 4px;
  align-items: center;
  i {
    align-self: start;
    justify-self: start;
    color: ${colors.textDisabled};
  }
  svg {
    display: block;
    width: 16px;
    height: 16px;
  }
`
export const ContentContainer = styled.div`
  display: grid;
  ${p =>
    p.theme.hasIcon
      ? css`
          grid-template-columns: 60px 1fr;
          grid-template-rows: auto;
          grid-template-areas:
            'small title'
            'small message'
            'small sender';
        `
      : css`
          grid-template-rows: auto;
          grid-template-areas:
            'title'
            'message'
            'sender';
        `}
`
export const BrowserName = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.text};
`
export const Title = styled.div`
  color: ${colors.text};
  grid-area: title;
  font-style: normal;
  font-weight: 500;
  margin-bottom: ${p => (p.theme.isFocused ? '6px' : 'unset')};
  transition: margin-bottom 0.2s ease;
  ${InactiveEditorContainer} {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`
export const Message = styled.div`
  color: ${colors.text};
  grid-area: message;
  margin-top: -2px;
  ${InactiveEditorContainer} {
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }
`
