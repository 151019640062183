import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'
import { Ellipsis } from 'components/styled/text'

import { formatBytes, textUsesTemplating, validateUrl } from 'com.batch.common/utils'

import { type MediaUrlValueProps, type MediaUrlMetaProps } from './media-url'
import { replaceTemplateTag } from './media-url-utils'
import { MediaUrlPreviewContainer } from './media-url.styles'

type MediaUrlPreviewProps = {
  value: MediaUrlValueProps
  meta: MediaUrlMetaProps
  error?: string
  deleteMedia: () => any
}

function checkImage(imageSrc: string, good: () => void, bad: () => void) {
  var img = new Image()
  img.onload = good
  img.onerror = bad
  img.src = imageSrc
}

export const MediaUrlPreview = ({
  value,
  meta,
  error,
  deleteMedia,
}: MediaUrlPreviewProps): React.ReactElement => {
  // ====================== Component state
  const [errorImg, setErrorImg] = React.useState<boolean>(true)

  // ====================== Use effect
  // @todo : est ce que truc est pertinent ? il manque aussi le cleanup
  React.useEffect(() => {
    if (
      value.mediaKind === 'image' &&
      validateUrl(value.mediaUrl) &&
      !textUsesTemplating(value.mediaUrl)
    ) {
      checkImage(
        value.mediaUrl,
        () => setErrorImg(false),
        () => setErrorImg(true)
      )
    }
  }, [value])

  // ====================== Component constant
  const hasSize: boolean = meta.mediaSize !== 0
  const hasWidth: boolean = meta.mediaWidth !== 0
  const hasHeight: boolean = meta.mediaHeight !== 0
  const hasMeta: boolean = hasSize || hasWidth || hasHeight

  const urlIsTemplate = textUsesTemplating(value.mediaUrl)

  const url = urlIsTemplate ? replaceTemplateTag(value.mediaUrl) : value.mediaUrl.split('/').pop()

  // ====================== Render
  return (
    <MediaUrlPreviewContainer template="85px minmax(100px, 1fr) 50px" hasMeta={hasMeta}>
      <div className="styled-media-url-img">
        {!errorImg &&
        value.mediaKind === 'image' &&
        !urlIsTemplate &&
        validateUrl(value.mediaUrl) ? (
          <img src={value.mediaUrl} width="85" alt="Uploaded media preview" />
        ) : (
          <Icon icon={urlIsTemplate ? 'macros' : value.mediaKind} />
        )}
      </div>
      <div>
        <Ellipsis className="styled-med-url-title" title={value.mediaUrl}>
          {url}
        </Ellipsis>

        {error ? (
          <div className="styled-med-url-error">{error}</div>
        ) : (
          hasMeta &&
          !urlIsTemplate && (
            <div className="styled-med-url-infos">
              {hasWidth && hasHeight && <span>{`${meta.mediaWidth} x ${meta.mediaHeight}px`}</span>}
              {hasWidth && hasHeight && hasSize && ' — '}
              {hasSize && formatBytes(meta.mediaSize, 2)}
            </div>
          )
        )}
      </div>
      <div>
        <Button style={{ height: 28, width: 28 }} onClick={deleteMedia}>
          <Icon icon="close" />
        </Button>
      </div>
    </MediaUrlPreviewContainer>
  )
}
