import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'
import { useParams } from 'react-router-dom'
import { type DynamicDimension } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import { fetchDimensionValues } from 'com.batch/orchestration-analytics/usecases/fetch-dimension-values'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { fetchLabelsAndCappingRules } from 'com.batch/labels/usecases/fetch-labels-and-capping-rules'
import { restoreOrchestrationAnalyticsFilters } from '../../helpers/restore-orchestration-analytics-filters'
import { restoreAnalyticsFiltersAndDateRange } from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import {
  OrchestrationAnalyticsDateRangeFactory,
  OrchestrationAnalyticsFactory,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { dayjs } from 'com.batch.common/dayjs.custom'
import { CrossOrchestrationsAnalytics } from './cross-orchestrations-analytics'
import { Loader } from 'components/common/loader/loader'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'
import { filterDiscovery } from 'com.batch/orchestration-analytics/usecases/filter-discovery'

const coerceChannel = (channel?: string): ChannelUntilCleanup => {
  switch (channel) {
    case 'email':
      return 'email'
    case 'sms':
      return 'sms'
    default:
      return 'push'
  }
}

export const CrossOrchestrationsAnalyticsLoader = React.memo((): React.ReactNode => {
  const dispatch = useDispatch()
  const { channel: channelParam } = useParams()
  const channel = React.useMemo(() => coerceChannel(channelParam), [channelParam])
  const { projectKey } = useSelector(currentProjectSelector)
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const { filtersRestored, channelAndPeriodLoadingState, filterDiscoveryState } = React.useMemo(
    () => analyticsMap.get(projectKey, OrchestrationAnalyticsFactory()),
    [analyticsMap, projectKey]
  )
  const channelLoadingState = React.useMemo(() => {
    const analytics = analyticsMap.get(projectKey, OrchestrationAnalyticsFactory())
    switch (channel) {
      case 'email':
        return analytics.emailLoadingState
      case 'push':
        return analytics.pushLoadingState
      case 'sms':
        return analytics.smsLoadingState
      default:
        return 'INIT'
    }
  }, [analyticsMap, projectKey, channel])
  const readyToFetchFilterValues = React.useMemo(
    () =>
      channelLoadingState === 'LOADED' &&
      channelAndPeriodLoadingState === 'LOADED' &&
      filterDiscoveryState === 'INIT',
    [channelLoadingState, channelAndPeriodLoadingState, filterDiscoveryState]
  )

  React.useEffect(() => {
    if (readyToFetchFilterValues) {
      const labelFetch = dispatch(fetchLabelsAndCappingRules()).catch(() => {})
      const dimensions: Array<DynamicDimension> = ['content_language', 'label']
      if (channel === 'email') dimensions.push('mailbox_provider')
      const filtersValuesFetches = dimensions.map(dimension =>
        dispatch(fetchDimensionValues({ dimension, channel })).catch(() => {})
      )
      dispatch(filterDiscovery([labelFetch, ...filtersValuesFetches]))
    }
  }, [dispatch, channel, projectKey, readyToFetchFilterValues])

  React.useEffect(() => {
    const { filters, dateRange } = restoreOrchestrationAnalyticsFilters()
    dispatch(
      restoreAnalyticsFiltersAndDateRange({
        token: projectKey,
        filters,
        dateRange:
          dateRange ??
          OrchestrationAnalyticsDateRangeFactory({
            from: dayjs().subtract(29, 'day').startOf('day'),
            to: dayjs().endOf('day'),
          }),
      })
    )
  }, [dispatch, projectKey])

  return (
    <Loader loading={!filtersRestored} padding={100}>
      <CrossOrchestrationsAnalytics channel={channel} />
    </Loader>
  )
})
