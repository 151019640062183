import Immutable, { type List, type Set } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  ConditionFactory,
  QueryFactory,
  type FunctionRecord,
  type QueryAttributeRecord,
  type QueryRecord,
  type ConditionRecord,
  type OperatorRecord,
  type LogicalNodeRecord,
  type InputType,
} from './query.records'
import * as Functions from './query.records.functions'
import * as Operators from './query.records.operators'
import * as Types from './query.types'
import { type OursQLType } from './query.types'

let conditionId = 97
let conditionCycle = 0
export const generateConditionId = (): string => {
  const id = `${String.fromCharCode(conditionId)}${conditionCycle}`
  conditionId++
  if (conditionId > 122) {
    conditionId = 97
    conditionCycle++
  }
  return id
}

export function isFunctionMandatory(attributeType: OursQLType): boolean {
  switch (attributeType) {
    case Types.EVENT:
    case Types.POSITION:
      return true
    default:
      return false
  }
}

export function getDefaultFunctions(attributeType: OursQLType): List<FunctionRecord> {
  switch (attributeType) {
    case Types.POSITION:
      return Immutable.List([Functions.IsNearFunction])
    case Types.DATE:
      return Immutable.List([Functions.AgeFunction])
    case Types.EVENT:
      return Immutable.List([Functions.AgeFunction])
    default:
      return Immutable.List()
  }
}
export function getDefaultOperator(attributeType: OursQLType): OperatorRecord {
  switch (attributeType) {
    case Types.TAG:
      return Operators.ContainsOperator
    case Types.AGE:
      return Operators.AgeEqualOperator
    case Types.DATE:
      return Operators.LowerOperator
    case Types.URL:
      return Operators.ExistsOperator
    default:
      return Operators.EqualOperator
  }
}

export function buildDefaultCondition(attribute: QueryAttributeRecord): ConditionRecord {
  const functions = getDefaultFunctions(attribute.type)
  const transformedType = functions.reverse().reduce((_, func) => func.produce, attribute.type)
  const operator = ['b.city_code', 'b.device_type', 'b.carrier_code', 'b.email_domain'].includes(
    attribute.api
  )
    ? Operators.InOperator
    : getDefaultOperator(transformedType)

  return setInputType(ConditionFactory({ attribute, functions, operator }))
}

export function coerceType(
  oldType: string,
  attributeKind?: 'ARRAY' | 'PRIMITIVE' | 'OBJECT'
): OursQLType {
  switch (oldType) {
    case 'AGE':
    case 'BOOLEAN':
    case 'DATE':
    case 'EVENT':
    case 'FLOAT':
    case 'INTEGER':
    case 'POSITION':
    case 'STRING':
    case 'TAG':
    case 'URL':
    case 'VERSION':
      return attributeKind === 'ARRAY' ? 'TAG' : oldType
    case 'RETARGETING':
      return 'EVENT'
    case 'AUDIENCE':
      return 'TAG'
    default:
      return 'STRING'
  }
}

export function deleteFromTree(tree: LogicalNodeRecord, id: string): LogicalNodeRecord {
  return removeEmptyNodeFromTree(
    tree.set(
      'descendants',
      tree.descendants
        .filter(conditionOrSubtree =>
          typeof conditionOrSubtree === 'string'
            ? conditionOrSubtree !== id
            : conditionOrSubtree.id !== id
        )
        .map(conditionOrSubtree =>
          typeof conditionOrSubtree === 'string'
            ? conditionOrSubtree
            : deleteFromTree(conditionOrSubtree, id)
        )
    )
  )
}

export function addToTree(
  tree: LogicalNodeRecord,
  parentId: string,
  valueToAdd: LogicalNodeRecord | string
): LogicalNodeRecord {
  return tree.set(
    'descendants',
    tree.id === parentId
      ? tree.descendants.push(valueToAdd)
      : tree.descendants.map(conditionOrSubtree =>
          typeof conditionOrSubtree === 'string'
            ? conditionOrSubtree
            : addToTree(conditionOrSubtree, parentId, valueToAdd)
        )
  )
}

export function updateTreeValue(
  tree: LogicalNodeRecord,
  position: string,
  value: 'and' | 'or'
): LogicalNodeRecord {
  return tree.set('value', tree.id === position ? value : tree.value).set(
    'descendants',
    tree.descendants.map(conditionOrSubtree =>
      typeof conditionOrSubtree === 'string'
        ? conditionOrSubtree
        : updateTreeValue(conditionOrSubtree, position, value)
    )
  )
}

export function getFromTree(
  tree: LogicalNodeRecord | string,
  position: string
): LogicalNodeRecord | string {
  if (typeof tree === 'string') {
    return tree === position ? tree : 'NOT FOUND'
  }
  if (tree.id === position) {
    return tree
  }
  return tree.descendants.reduce(
    (acc, nodeOrId) =>
      typeof nodeOrId === 'string'
        ? nodeOrId === position
          ? nodeOrId
          : acc
        : getFromTree(nodeOrId, position) === 'NOT FOUND'
          ? acc
          : getFromTree(nodeOrId, position),
    'NOT FOUND'
  )
}
export function updateTree(
  tree: LogicalNodeRecord | string,
  position: string,
  value: LogicalNodeRecord | string
): LogicalNodeRecord | string {
  if (typeof tree === 'string') {
    return tree === position ? value : tree
  }
  if (tree.id === position) {
    return value
  }
  return tree.set(
    'descendants',
    tree.descendants.map(nodeOrId =>
      typeof nodeOrId === 'string'
        ? nodeOrId === position
          ? value
          : nodeOrId
        : updateTree(nodeOrId, position, value)
    )
  )
}

export function getParentId(tree: LogicalNodeRecord, position: string, parent: string): string {
  if (tree.id === position) {
    return parent
  }
  return tree.descendants.reduce(
    (acc, nodeOrId) =>
      typeof nodeOrId === 'string'
        ? nodeOrId === position
          ? tree.id
          : acc
        : getParentId(nodeOrId, position, parent),
    'NOT FOUND'
  )
}

export function getParentLogical(
  tree: LogicalNodeRecord,
  conditionId: string
): LogicalNodeRecord | null {
  if (tree.descendants.includes(conditionId)) {
    return tree
  }
  const parentOrNull: LogicalNodeRecord | null = tree.descendants.reduce(
    (acc, nodeOrId) =>
      typeof nodeOrId === 'string'
        ? nodeOrId === conditionId
          ? tree
          : acc
        : getParentLogical(nodeOrId, conditionId),
    null
  )
  return parentOrNull
}

export function getInputType(condition: ConditionRecord): InputType {
  if (!condition.attribute) throw 'invalid condition - missing attribute'
  if (
    ['b.city_code', 'b.carrier_code'].includes(condition.attribute?.api) &&
    condition.operator !== Operators.ExistsOperator
  ) {
    return 'InputPrettyList'
  }
  // add audience input
  if (['bt.custom_audiences'].includes(condition.attribute?.api)) {
    return 'InputAudience'
  }
  if (['bt.segments'].includes(condition.attribute?.api)) {
    return 'InputSegment'
  }
  if (
    ['b.device_type'].includes(condition.attribute?.api) &&
    condition.operator !== Operators.ExistsOperator
  ) {
    return 'InputDeviceList'
  }

  if (condition.attribute?.api === 'b.position') {
    return 'InputGoogleMap'
  }
  const preOperatorType = condition.functions.reverse().reduce(
    (acc, func) => {
      return func.produce
    },
    condition.attribute?.type ?? 'STRING'
  )
  const resultingType =
    condition.operator.input === 'identity' ? preOperatorType : condition.operator.input

  if (resultingType === 'INTEGER') return 'InputInteger'
  if (resultingType === 'FLOAT') return 'InputFloat'
  if (resultingType === 'BOOLEAN' || resultingType === 'URL') return 'InputBoolean'
  if (resultingType === 'AGE') return 'InputAge'
  if (resultingType === 'DATE') return 'InputDate'
  if (resultingType === 'STRING') {
    return condition.operator === Operators.StartsWithOperator ||
      condition.operator === Operators.EndsWithOperator ||
      condition.operator === Operators.EqualOperator
      ? 'InputString'
      : 'InputStringList'
  }
  return 'InputString'
}

export function setInputType(condition: ConditionRecord): ConditionRecord {
  return condition.set('value', condition.value.set('mode', getInputType(condition)))
}

export function validateCondition(condition: ConditionRecord): boolean {
  if (!condition.attribute) return false
  // @todo probably some test about event function parameters
  const v = condition.value
  switch (v.mode) {
    case 'InputGoogleMap':
      return (
        !isNaN(condition.functionParams?.lat ?? 0) &&
        !isNaN(condition.functionParams?.lng ?? 0) &&
        !isNaN(condition.functionParams?.radius ?? 0)
      )
    case 'InputDate':
      return dayjs(v.date.inputValue, 'DD/MM/YYYY', 'fr', true).isValid()
    case 'InputAge':
      return v.age.valid && v.age.inputValue !== ''
    case 'InputFloat':
      return !isNaN(v.number)
    case 'InputInteger':
      return !isNaN(v.number) && v.number - v.number === 0
    case 'InputAudience':
    case 'InputSegment':
      return v.stringList.size > 0
    case 'InputPrettyList':
      return v.numberList.size > 0
    case 'InputDeviceList':
    case 'InputStringList':
      return v.stringList.size > 0
    case 'InputString':
      return v.string !== ''
    case 'InputBoolean':
    default:
      return true
  }
}
function validateNode(node: LogicalNodeRecord): boolean {
  if (node.value === 'not' && node.descendants.size > 1) {
    throw 'Invalid state - multiple child for a not node'
  }
  return (
    (node.value === 'not' && node.descendants.size === 1) ||
    (node.value !== 'not' && node.descendants.size > 1)
  )
}
function removeEmptyNodeFromTree(node: LogicalNodeRecord): LogicalNodeRecord {
  return node.set(
    'descendants',
    node.descendants
      .map(nodeOrId => {
        if (typeof nodeOrId === 'string') return nodeOrId
        if (validateNode(nodeOrId)) return nodeOrId
        const singleChildInThisCase = nodeOrId.descendants.first()
        if (typeof singleChildInThisCase !== 'undefined') return singleChildInThisCase
        return nodeOrId // we should always return before but flow does not seem to get it
      })
      .filter(nodeOrId => typeof nodeOrId === 'string' || validateNode(nodeOrId))
  )
}

export function removeInvalidConditions(query: QueryRecord): QueryRecord {
  const conditionToRemove = query.conditions
    .filter(condition => !validateCondition(condition))
    .keySeq()

  const validConditions = query.conditions.filter(condition => validateCondition(condition))

  const validConditionsWithValidEventFilters = validConditions.map(condition =>
    condition.set('eventFilters', condition.eventFilters.filter(validateCondition))
  )

  return QueryFactory({
    tree: removeEmptyNodeFromTree(
      conditionToRemove.reduce((acc, key) => deleteFromTree(acc, key), query.tree)
    ),
    conditions: validConditionsWithValidEventFilters,
    eventId: query.eventId,
  })
}

function getUsedConditionsIds(node: LogicalNodeRecord, ids: Set<string>): Set<string> {
  return ids.merge(
    node.descendants.flatMap(nodeOrId =>
      typeof nodeOrId === 'string' ? Immutable.Set([nodeOrId]) : getUsedConditionsIds(nodeOrId, ids)
    )
  )
}

export function getInvalidConditionIds(query: QueryRecord): Set<string> {
  const ids = getUsedConditionsIds(query.tree, Immutable.Set())
  return Immutable.Set(
    query.conditions
      .filter((c, id) => ids.includes(id) && !validateCondition(c))
      .map((c, id) => id)
      .keySeq()
  )
}

export function getDepth(node: LogicalNodeRecord): number {
  return node.descendants.reduce((acc, nodeOrId) => {
    if (typeof nodeOrId === 'string') {
      return acc + 1
    }
    return acc + 1 + getDepth(nodeOrId)
  }, 0)
}
