import Immutable, { type List } from 'immutable'

import {
  SdkActionFactory,
  sdkNativeActionsMap,
  AdditionalActionFactory,
  type SdkActionRecord,
  type AdditionalActionRecord,
} from './content.records'

const VALID_ADDITIONAL_ACTIONS = ['batch.user.tag', 'batch.user.event']
const VALID_COMBO_MAIN_ACTIONS = ['batch.clipboard', 'batch.deeplink']
const NATIVE_MAIN_ACTIONS = Object.keys(sdkNativeActionsMap).filter(
  name => name !== 'custom' && !VALID_ADDITIONAL_ACTIONS.includes(name)
)

function canWeHandleAsAdditional(action: string, args: any): boolean {
  if (action === 'batch.user.event') {
    return typeof args === 'object' && !args.t && !args.a
  }
  return VALID_ADDITIONAL_ACTIONS.includes(action)
}
function buildAdditionals(
  arr: Array<['batch.user.tag' | 'batch.user.event', any]>
): List<AdditionalActionRecord> {
  return Immutable.List(
    arr.map(([actionName, actionArgs]: [any, any]) => {
      if (actionName === 'batch.user.event') {
        return AdditionalActionFactory({
          mode: 'EVENT',
          name: `e.${actionArgs.e}`,
          label: actionArgs?.l ?? '',
        })
      }
      return AdditionalActionFactory({
        mode: actionArgs?.a === 'add' ? 'ADD_TAG' : 'REMOVE_TAG',
        name: `t.${actionArgs.c}`,
        tags: [actionArgs?.t ?? ''],
      })
    })
  )
}
export function normalizeCta(action: string, args: string): SdkActionRecord {
  if (!action) {
    return SdkActionFactory({
      action: sdkNativeActionsMap['batch.dismiss'],
    })
  }
  let data: any = {}
  try {
    data = JSON.parse(args)
  } catch {
    console.warn('args', args)
  }

  /* root action is a group : we can handle it if : 
        - we got exactly 1 non event/tag action
        - we got exactly 2 non event/tag action, and it is clipboard & deeplink
        - AND the event does not use data or tags
  */
  if (action === 'batch.group') {
    const groupActions = data?.actions
    if (Array.isArray(groupActions)) {
      const mainActions: Array<[string, any]> = groupActions.filter(
        actionTuple =>
          Array.isArray(actionTuple) &&
          typeof actionTuple[0] === 'string' &&
          !VALID_ADDITIONAL_ACTIONS.includes(actionTuple[0])
      )
      const additionalActions: Array<['batch.user.tag' | 'batch.user.event', any]> =
        groupActions.filter(
          actionTuple =>
            Array.isArray(actionTuple) &&
            typeof actionTuple[0] === 'string' &&
            VALID_ADDITIONAL_ACTIONS.includes(actionTuple[0])
        )
      const hasUnhandledAdditionals =
        additionalActions.filter(tuple => !canWeHandleAsAdditional(tuple[0], tuple[1])).length > 0
      if (!hasUnhandledAdditionals) {
        if (
          mainActions.length === 2 &&
          mainActions.filter(tuple => VALID_COMBO_MAIN_ACTIONS.includes(tuple[0])).length === 2
        ) {
          const deeplink = mainActions.find(tuple => tuple[0] === 'batch.deeplink')
          const clipboard = mainActions.find(tuple => tuple[0] === 'batch.clipboard')
          if (deeplink && clipboard)
            return SdkActionFactory({
              action: sdkNativeActionsMap['batch.clipboard'],
              copyText: clipboard[1]?.t ?? '',
              deeplinkUrl: deeplink[1]?.l ?? '',
              deeplinkInternal: Boolean(deeplink[1]?.li ?? 0),
              additional: buildAdditionals(additionalActions),
            })
        }
        if (mainActions.length === 1) {
          const name = mainActions[0][0]
          const args = mainActions[0][1]
          return SdkActionFactory({
            action: NATIVE_MAIN_ACTIONS.includes(name) // $FlowExpectedError
              ? sdkNativeActionsMap[name]
              : sdkNativeActionsMap.custom,
            customActionName: NATIVE_MAIN_ACTIONS.includes(name) ? '' : name,
            customActionArgs: NATIVE_MAIN_ACTIONS.includes(name) ? '' : JSON.stringify(args),
            copyText: args?.t ?? '',
            deeplinkUrl: args?.l ?? '',
            deeplinkInternal: Boolean(args?.li ?? 0),
            additional: buildAdditionals(additionalActions),
          })
        }
      }
    }
  }

  if (NATIVE_MAIN_ACTIONS.includes(action)) {
    return SdkActionFactory({
      // $FlowExpectedError
      action: sdkNativeActionsMap[action],
      copyText: data?.t ?? '',
      deeplinkUrl: data?.l ?? '',
      deeplinkInternal: Boolean(data?.li ?? 0),
    })
  } else {
    return SdkActionFactory({
      action: sdkNativeActionsMap.custom,
      customActionName: action,
      customActionArgs: args,
    })
  }
}
