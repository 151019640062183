import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export const filterDiscovery = (
  fetches: Array<Promise<unknown>>
): DispatchExtraBoundFn<Promise<unknown>> => {
  return async (dispatch, getState) => {
    const state = getState()
    const { projectKey } = currentProjectSelector(state)

    dispatch({
      type: 'FILTER_DISCOVERY',
      payload: { projectKey },
    })
    return Promise.all(fetches).then(() => {
      dispatch({
        type: 'FILTER_DISCOVERY_SUCCESS',
        payload: { projectKey },
      })
    })
  }
}

type FilterDiscovery = {
  type: 'FILTER_DISCOVERY'
  payload: {
    projectKey: string
  }
}
type FilterDiscoverySuccess = {
  type: 'FILTER_DISCOVERY_SUCCESS'
  payload: {
    projectKey: string
  }
}
export type FilterDiscoveryActions = FilterDiscovery | FilterDiscoverySuccess
