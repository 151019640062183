import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

/**
 * Hook to manage pagination search params
 */
export const usePaginationParams = (): {
  currentPage: number
  setPaginationParam: (page: number) => void
} => {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentPage = useMemo(() => {
    const page = searchParams.get('page')
    return page ? parseInt(page, 10) : 1
  }, [searchParams])

  const setPaginationParam = useCallback(
    (page: number) => {
      setSearchParams(params => {
        params.set('page', page.toString())
        return params
      })
    },
    [setSearchParams]
  )

  return {
    currentPage,
    setPaginationParam,
  }
}
