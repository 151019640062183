import * as React from 'react'
import styled, { css } from 'styled-components'

import { LoadingCellAnim } from 'components/common/empty-states/'
import { FlexLineItem } from 'components/common/flexline'
import { TableTemplateCell } from 'components/common/table'

const randomSize = (minSize: number, maxSize: number) =>
  Math.floor(Math.random() * (maxSize - minSize)) + minSize

// Empty Template
export const NotificationListEmptyTemplate = (): React.ReactElement => {
  return (
    <React.Fragment>
      <TableTemplateCell template="1fr" minSize={80} maxSize={100} />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
      <TableTemplateCell template="1fr" />
    </React.Fragment>
  )
}

const PushOverviewMetricEmpty = (): React.ReactElement => {
  const valueProps = React.useMemo(
    () => ({ width: randomSize(64, 100), height: randomSize(26, 40) }),
    []
  )
  const width = React.useMemo(() => randomSize(42, 120), [])
  return (
    <FlexLineItem grow={1}>
      <div
        style={{
          margin: '24px 24px 46px 24px',
        }}
      >
        <MetricLabelEmpty width={width}>0</MetricLabelEmpty>
        <MetricValueEmpty {...valueProps}>0</MetricValueEmpty>
      </div>
    </FlexLineItem>
  )
}

// empty template for push overview metrics
export const PushOverviewMetricsEmptyTemplate = (): React.ReactElement => {
  return (
    <React.Fragment>
      <PushOverviewMetricEmpty />
      <FlexLineItem grow={3} tinyContainer />
      <PushOverviewMetricEmpty />
      <PushOverviewMetricEmpty />
      <PushOverviewMetricEmpty />
    </React.Fragment>
  )
}

// empty template for push overview metrics
export const PushOverviewMetricsRateEmptyTemplate = (): React.ReactElement => {
  return (
    <React.Fragment>
      <PushOverviewMetricEmpty />
      <PushOverviewMetricEmpty />
      <PushOverviewMetricEmpty />
      <FlexLineItem grow={1} tinyContainer />
      <PushOverviewMetricEmpty />
      <PushOverviewMetricEmpty />
      <FlexLineItem grow={1} tinyContainer />
    </React.Fragment>
  )
}
type MetricEmptyProps = {
  width?: number
  height?: number
  loading?: boolean
}
const MetricItemEmpty = styled.label<MetricEmptyProps>`
  display: flex;
  justify-content: left;
  font-size: 11px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    background-color: #b8b8b8;
    border-radius: 2px;
    min-width: ${props => props.width}px;
    height: 13px;

    ${props =>
      props.theme.isLoading &&
      css`
        animation-name: ${LoadingCellAnim};
        animation-duration: 2s;
        animation-iteration-count: infinite;
      `}
  }
`
type MetricValueEmptyProps = {
  width: number
  height: number
  loading?: boolean
}
const MetricValueEmpty = styled(MetricItemEmpty)<MetricValueEmptyProps>`
  margin-bottom: 19px;

  &::before {
    height: ${props => (props.height ? `${props.height}px ` : '21px')};
  }
`

type MetricLabelEmptyProps = MetricEmptyProps & {
  size?: number
}

const MetricLabelEmpty = styled(MetricItemEmpty)<MetricLabelEmptyProps>`
  margin-bottom: 11px;
`
