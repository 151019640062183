/* eslint-disable react/jsx-no-bind */
import Immutable, { type Set } from 'immutable'
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { Button, ButtonNavLink, ButtonLink } from 'components/common/button'
import { Grid } from 'components/common/grid'
import Loader from 'components/common/loader-legacy'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { EntityLog } from 'components/console/entity-log'
import { Form, InputWrapper, Input, Copy } from 'components/form'
import { schemes } from 'components/styled/tokens'

import { kformat } from 'com.batch.common/utils'

import { type AnalyticByCompanyRecord } from './../redux/console.records'
import { SalesforceSelect } from './salesforce-select'

import { Section, SectionTitle, DataBoxContainer, DataBox } from '../console.style'
import { type CompanyRecord } from 'com.batch.redux/_records'

type CompanyDataProps = {
  company: CompanyRecord
  analytics: AnalyticByCompanyRecord
  analyticsLoading: boolean
  setSalesForceId: (company: CompanyRecord) => Promise<CompanyRecord>
  saveSeats: (c: CompanyRecord, seats?: number | null | undefined) => Promise<CompanyRecord>
  toggleGdprExternalFlag: (arg1: CompanyRecord) => Promise<CompanyRecord>
  toggleEdito: (arg1: CompanyRecord) => Promise<CompanyRecord>
}

export const CompanyData = ({
  company,
  analytics,
  analyticsLoading,
  toggleGdprExternalFlag,
  setSalesForceId,
  saveSeats,
  toggleEdito,
}: CompanyDataProps): React.ReactElement => {
  const [seats, setSeats] = React.useState<string>(company.seats.toString())
  const [loading, setLoading] = React.useState<Set<'gdpr' | 'seats' | 'edito' | '2fa'>>(
    Immutable.Set()
  )

  const handleGdpr = () => {
    setLoading(loading.add('gdpr'))
    toggleGdprExternalFlag(company).then(() => {
      setLoading(loading.remove('gdpr'))
    })
  }
  const handleSeats = () => {
    setLoading(loading.add('seats'))
    saveSeats(company, !seats ? null : parseInt(seats)).then(() => {
      setLoading(loading.remove('seats'))
    })
  }
  const handleEdito = () => {
    setLoading(loading.add('edito'))
    toggleEdito(company).then(() => {
      setLoading(loading.remove('edito'))
    })
  }

  return (
    <Grid template="1fr 1fr" alignItems="stretch" margin={[12, 0, 0, 0]}>
      <div>
        <ThemeProvider theme={{ horizontal: true }}>
          <Section style={{ borderTop: '1px solid #e9e9e9' }}>
            <SectionTitle>General informations</SectionTitle>
            <Copy label="Rest KEY" value={company.restKey || ''} />
            <Copy label="External ID" value={company.externalId} />
            <Loader overlay size="tiny" loading={company.loading}>
              <InputWrapper label="Salesforce" style={{ marginBottom: 18, paddingTop: 18 }}>
                <SalesforceSelect
                  accountId={company.sfid}
                  onChange={id => setSalesForceId(company.set('sfid', id))}
                />
              </InputWrapper>
            </Loader>
            <Form horizontal onSubmit={handleSeats}>
              <InputWrapper label="Seats" htmlFor="seats">
                <Grid template="1fr 140px">
                  <Input
                    id="seats"
                    placeholder={company.seats.toString()}
                    value={seats}
                    type="number"
                    onChange={evt => setSeats(evt.target.value)}
                  />
                  <div>
                    <Tooltip tooltip={`Plan has ${company.billing.plan.seats} seats included`}>
                      <Button
                        style={{ width: '100%' }}
                        intent="action"
                        kind="primary"
                        type="submit"
                        isLoading={loading.has('seats')}
                        disabled={company.seats.toString() === seats}
                      >
                        Save seats
                      </Button>
                    </Tooltip>
                  </div>
                </Grid>
              </InputWrapper>
            </Form>
            <InputWrapper label="Edito">
              <Grid template="1fr 140px">
                <span>
                  {company.hasEditorialDashboard ? 'Managers can grant editorial access' : 'NA'}
                </span>
                <Button
                  type="button"
                  style={{ width: '100%' }}
                  intent="action"
                  kind="primary"
                  onClick={handleEdito}
                  isLoading={loading.has('edito')}
                >
                  {!company.hasEditorialDashboard ? 'Grant' : 'Remove'}
                </Button>
              </Grid>
            </InputWrapper>
            <InputWrapper label="GDPR">
              <Grid template="1fr 140px">
                {company.gdpr.updated ? (
                  <a href={`/${company.id}/gdpr`}>
                    {company.gdpr.disabled ? 'Opt-out' : 'Filled'}{' '}
                    {company.gdpr.updated ? company.gdpr.updated.format('DD/MM/YYYY') : ''}
                    {company.gdpr.external && <span> (external)</span>}
                  </a>
                ) : (
                  <span>NA{company.gdpr.external && <span> (external)</span>}</span>
                )}
                <div>
                  <Tooltip
                    tooltip={
                      company.gdpr.external
                        ? 'Remove the external flag - this will allow the company to edit & agree to our GDPR terms'
                        : "Add the external flag - this mean we have a legal agreement already signed, and the company won't have to configure its GDPR settings"
                    }
                  >
                    <Button
                      type="button"
                      style={{ width: '100%' }}
                      intent="action"
                      kind="primary"
                      onClick={handleGdpr}
                      isLoading={loading.has('gdpr')}
                    >
                      {company.gdpr.external ? 'Set internal' : 'Set external'}
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
            </InputWrapper>
          </Section>
          <Section>
            <SectionTitle>Links</SectionTitle>
            <Grid template="auto auto auto auto">
              <ButtonNavLink
                kind="secondary"
                to={`/console/apps?companyId=${company.id}`}
                style={{ width: '100%' }}
              >
                Apps
              </ButtonNavLink>
              <ButtonNavLink
                kind="secondary"
                to={`/console/projects?companyId=${company.id}`}
                style={{ width: '100%' }}
              >
                Projects
              </ButtonNavLink>
              <ButtonNavLink
                kind="secondary"
                to={`/console/users?companyId=${company.id}`}
                style={{ width: '100%' }}
              >
                Users
              </ButtonNavLink>
              <ButtonLink kind="secondary" href={`/${company.id}/users`} style={{ width: '100%' }}>
                Team
              </ButtonLink>
            </Grid>
          </Section>
        </ThemeProvider>
      </div>

      <div style={{ backgroundColor: schemes.grayscale['10'] }}>
        <DataBoxContainer>
          <DataBox>
            <label>DAUs (J-1)</label>
            {analyticsLoading ? <Icon icon="spinner" /> : kformat(analytics.data.daus)}
          </DataBox>
          <DataBox>
            <label>MAUs (J-1)</label>
            {analyticsLoading ? <Icon icon="spinner" /> : kformat(analytics.data.maus)}
          </DataBox>
          <DataBox>
            <label>Starts (J-1)</label>
            {analyticsLoading ? <Icon icon="spinner" /> : kformat(analytics.data.starts)}
          </DataBox>
        </DataBoxContainer>
        <div>
          <EntityLog logs={company.logs} />
        </div>
      </div>
    </Grid>
  )
}
