import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'
import { ThemeContext, ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo } from 'components/_hooks/use-allowed'
import { Box, BoxHeader, HeaderBoxTitle, HeaderBoxActions, BoxBody } from 'components/common/box'
import { PermissionButton } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Table, TableBody, FooterEditable } from 'components/common/table'
import { Input } from 'components/form'
import { Empty, EmptyTitle, EmptyText } from 'components/styled/empty'
import { LinkDoc } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { AllowedDevOriginsEditor } from './allowed-dev-origins-editor'

import { EmptyDevOriginsRow } from '../settings-push-empty-states'
import { type PushConfigRecord, type AppRecord } from 'com.batch.redux/_records'
import { saveDevOrigins, deleteDevOrigins } from 'com.batch.redux/app.action'

type AllowedDevOriginsProps = {
  app: AppRecord
  config: PushConfigRecord
}

export const AllowedDevOrigins = ({ app, config }: AllowedDevOriginsProps): React.ReactElement => {
  const dispatch = useDispatch()
  const theme = React.useContext(ThemeContext)

  const [editLine, setEditLine] = React.useState<number | null>(null)
  const [newAllowedDevOrigins, setNewAllowedDevOrigins] = React.useState('')

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()
      dispatch(
        saveDevOrigins(
          app,
          config.set('allowedDevOrigins', [...config.allowedDevOrigins, newAllowedDevOrigins])
        )
      )
      setNewAllowedDevOrigins('')
    },
    [app, config, dispatch, newAllowedDevOrigins]
  )

  const deleteLine = React.useCallback(
    (index: number) => () => {
      dispatch(
        deleteDevOrigins(
          app,
          config.set(
            'allowedDevOrigins',
            config.allowedDevOrigins.filter((v, i) => i !== index)
          )
        )
      )
    },
    [app, config, dispatch]
  )

  const save = React.useCallback(
    (index: number) => (devOrigins: string) => {
      dispatch(
        saveDevOrigins(
          app,
          config.set(
            'allowedDevOrigins',
            config.allowedDevOrigins.map((a, i) => (i === index ? devOrigins.trim() : a))
          )
        )
      )
    },
    [app, config, dispatch]
  )

  const isAllowed = useIsCurrentUserAllowedTo(['app', 'push:config:write'])
  const onNewAllowedChange = React.useCallback(
    evt => setNewAllowedDevOrigins(evt.target.value.trim()),
    []
  )
  const onEditingChange = React.useCallback((index: null | number) => () => setEditLine(index), [])
  return (
    <Box style={{ overflow: 'hidden' }}>
      <BoxHeader>
        <HeaderBoxTitle title="Allowed dev origins" />
        <HeaderBoxActions>
          <LinkDoc
            href="https://doc.batch.com/web/testing-integration"
            intent="action"
            target="_blank"
          >
            Help
          </LinkDoc>
        </HeaderBoxActions>
      </BoxHeader>
      <BoxBody>
        <Table template="1fr 103px">
          <TableBody emptyTemplate={<EmptyDevOriginsRow />} templateSize={2}>
            {config.allowedDevOrigins.length > 0 ? (
              config.allowedDevOrigins.map((devOrigins, index) => (
                <AllowedDevOriginsEditor
                  key={devOrigins}
                  value={devOrigins}
                  isEditing={editLine === index + 1}
                  edit={onEditingChange(index + 1)}
                  editClose={onEditingChange(null)}
                  save={save(index)}
                  deleteLine={deleteLine(index)}
                />
              ))
            ) : (
              <AllowedDevOriginsEmpty />
            )}
          </TableBody>
        </Table>

        <ThemeProvider theme={{ isEmpty: false, isLoading: false, size: 'small' }}>
          <FooterEditable onSubmit={handleSubmit} as={'form'}>
            <Grid template="1fr 120px" margin={[10, 0, 10, 0]}>
              <Input
                placeholder="https://www.batch.com"
                value={newAllowedDevOrigins}
                onChange={onNewAllowedChange}
                style={{
                  WebkitTextFillColor: theme.isLoading ? colors.textDisabled : 'initial',
                }}
                disabled={theme.isLoading}
              />

              <PermissionButton
                type="submit"
                intent="action"
                isAllowed={isAllowed}
                disabled={!newAllowedDevOrigins}
              >
                Add dev origins
              </PermissionButton>
            </Grid>
          </FooterEditable>
        </ThemeProvider>
      </BoxBody>
    </Box>
  )
}

const AllowedDevOriginsEmpty = () => (
  <Empty style={{ position: 'relative', height: 172 }}>
    <EmptyTitle>No dev origins set</EmptyTitle>
    <EmptyText>Add a new dev origin to implement Batch on your testing environment</EmptyText>
  </Empty>
)
