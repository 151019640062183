import styled, { css } from 'styled-components'

import { colors } from 'components/styled/tokens'

type DeliveryReportBlockWrapperProps = {
  overlay: boolean
  noDataMsg: string
}
export const DeliveryReportBlockWrapper = styled.div<DeliveryReportBlockWrapperProps>`
  position: relative;

  ${props =>
    props.overlay &&
    css`
      &:before {
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${colors.textLight};
        content: '${(props: DeliveryReportBlockWrapperProps) => props.noDataMsg}';
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
      }
    `}
`
