import * as React from 'react'
import styled, { css } from 'styled-components'

import { type availableIcons, Icon } from 'components/common/svg-icon'
import * as colors from 'components/styled/tokens/colors'

type ReviewLineProps = {
  children?: React.ReactNode | null | undefined
  icon?: availableIcons
}

const ReviewLineContainer = styled.div`
  margin-top: 10px;
  line-height: 22px;
  > i {
    color: ${colors.textAction};
  }
  ${(props: { hasIcon: boolean }) =>
    props.hasIcon &&
    css`
      display: grid;
      grid-template-columns: 28px 1fr;
      align-items: baseline;
    `}
`

const ReviewLine = ({ children, icon }: ReviewLineProps): React.ReactElement => {
  return (
    <ReviewLineContainer hasIcon={Boolean(icon)}>
      {icon && <Icon icon={icon} size={13} thickness={1.2} />}
      <span>{children}</span>
    </ReviewLineContainer>
  )
}

export default ReviewLine
