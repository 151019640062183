export const restoreValuesFromLocalStorage = <T>(key: string): Array<T> => {
  const itemAsString = localStorage.getItem(key)
  if (!itemAsString) return []
  const parsedItem = JSON.parse(itemAsString)
  return Array.isArray(parsedItem) ? parsedItem : []
}

export const saveStringValueInLocalStorage = ({
  key,
  value,
  max = 3,
}: {
  key: string
  value: string | null | undefined
  max?: number
}) => {
  if (value) {
    const currentValues = restoreValuesFromLocalStorage(key)

    if (!currentValues.includes(value)) {
      if (currentValues.length >= max) currentValues.shift()
      currentValues.push(value)
      localStorage.setItem(key, JSON.stringify(currentValues))
    }
  }
}
