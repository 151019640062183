import dayjs from 'dayjs'
import Immutable from 'immutable'

import {
  OrchestrationAnalyticsDateRangeFactory,
  type OrchestrationAnalyticsDateRangeRecord,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { type DynamicDimension } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'

const getDateRange = (
  urlParams: URLSearchParams
): OrchestrationAnalyticsDateRangeRecord | null | undefined => {
  const dateFromString = urlParams.get('from')
  const dateToString = urlParams.get('to')

  return dateFromString && dateToString
    ? OrchestrationAnalyticsDateRangeFactory({
        from: dayjs(dateFromString),
        to: dayjs(dateToString),
      })
    : null
}

const getFilters = (
  urlParams: URLSearchParams
): Immutable.Map<DynamicDimension, Immutable.List<string>> | undefined => {
  let restoredFilters = Immutable.Map<DynamicDimension, Immutable.List<string>>()

  const steps = urlParams.get('step')
  if (steps) {
    const splittedSteps = steps.split(',')
    restoredFilters = restoredFilters.set('step', Immutable.List<string>().push(...splittedSteps))
  }

  ;['content_language', 'mailbox_provider', 'label'].forEach((key: DynamicDimension) => {
    const values = urlParams.get(key)
    if (values) {
      const splittedValues = values.split(',')
      restoredFilters = restoredFilters.set(key, Immutable.List<string>().push(...splittedValues))
    }
  })

  return restoredFilters.size > 0 ? restoredFilters : undefined
}

export const restoreOrchestrationAnalyticsFilters = (): {
  dateRange: OrchestrationAnalyticsDateRangeRecord | null | undefined
  filters: Immutable.Map<DynamicDimension, Immutable.List<string>> | undefined
} => {
  const urlParams = new URLSearchParams(window.location.search)
  const restoredDateRange = getDateRange(urlParams)
  const restoredFilters = getFilters(urlParams)

  return {
    dateRange: restoredDateRange,
    filters: restoredFilters,
  }
}
