import Immutable, { type List } from 'immutable'
import { get as _get } from 'lodash-es'
import { createSelector, defaultMemoize, createSelectorCreator } from 'reselect'

import {
  type DataSetRecord,
  DataPointFactory,
  DataSetFactory,
} from 'components/charts/chart-helper'
import { colors } from 'components/styled/tokens'

import { type DateRange, type Dayjs } from 'com.batch.common/dayjs.custom'
import regions from 'com.batch.common/regions'

import { type AppRecord, type State } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import {
  AnalyticDataFactory,
  type AnalyticByPeriodRecord,
  type AnalyticDataRecord,
  type ReachByDayRecord,
  type loadingState,
  type AnalyticDataProp,
} from 'com.batch.redux/stat.records'

type extract<T> = (arg1: State) => T

const rootSelector = (state: State) => state.stat

export const loadingOverviewSelector = (state: State): loadingState =>
  state.stat.config.loadingOverview
export const loadingReachSelector = (state: State): loadingState => state.stat.config.loadingReach
export const loadingRegionSelector = (state: State): loadingState => state.stat.config.loadingRegion
export const reachSelector = (state: State): List<ReachByDayRecord> => state.stat.reach

const analyticsByDaySelector = (state: State) => state.stat.analyticsByDate
const regionDataSelector = (state: State) => state.stat.analyticsByRegion
const secretSelector = (state: State) => state.stat.config.secret
const configSelector = (state: State) => state.stat.config

const rangeSelectorCreator = createSelectorCreator(defaultMemoize, (m1, m2) =>
  m1.isSame(m2, 'hour')
)
const fromSelector = createSelector(configSelector, c => c.from)
const toSelector = createSelector(configSelector, c => c.to)
export const rangeSelector: extract<DateRange> = rangeSelectorCreator(
  fromSelector,
  toSelector,
  (from, to) => {
    return {
      from,
      to,
    }
  }
)

export const analyticsIsEmptySelector: extract<boolean> = createSelector(
  rootSelector,
  stateState => {
    return stateState.analyticsByDate.reduce((acc, day) => acc + day.data.daus, 0) === 0
  }
)

export type AnalyticOverviewData = {
  color: string
  label: string
  value: number
  previousValue: number
  buildTooltip?: (formatedValue: string) => React.ReactNode
}

const currentPeriodAnalytics = createSelector(
  rangeSelector,
  analyticsByDaySelector,
  (range: DateRange, all: List<AnalyticByPeriodRecord>) => {
    return all.filter(value => {
      return (
        value.period.isSameOrAfter(range.from, 'hour') &&
        value.period.isSameOrBefore(range.to, 'hour')
      )
    })
  }
)
const previousPeriodAnalytics = createSelector(
  rangeSelector,
  analyticsByDaySelector,
  (range: DateRange, all: List<AnalyticByPeriodRecord>) => {
    const diff = range.to.unix() - range.from.unix()
    const prev = range.from.subtract(diff, 'second').hour(0).minute(0)
    return all.filter(value => {
      return value.period.isBefore(range.from, 'hour') && value.period.isSameOrAfter(prev, 'hour')
    })
  }
)
export const analyticsOverviewSelector: extract<Array<AnalyticOverviewData>> = createSelector(
  currentPeriodAnalytics,
  previousPeriodAnalytics,
  (current: List<AnalyticByPeriodRecord>, previous: List<AnalyticByPeriodRecord>) => {
    const data: Array<AnalyticOverviewData> = []
    const sumRecord = (prev: AnalyticDataRecord, curr: AnalyticByPeriodRecord) => {
      ;['daus', 'maus', 'starts', 'installs', 'pushes', 'transactions'].forEach(
        (key: keyof AnalyticDataProp) => {
          prev = prev.set(key, prev.get(key) + curr.data.get(key))
        }
      )

      return prev
    }
    const computeBase = (
      current: AnalyticDataRecord,
      previous: AnalyticDataRecord,
      key: 'daus' | 'installs' | 'starts' | 'pushes'
    ) => {
      const value = current.get(key)
      const previousValue = previous.get(key)
      const pogression = value - previousValue
      return {
        color: colors.analytics[key],
        value,
        previousValue,
        pogression,
      }
    }
    let currentSum = current.reduce(sumRecord, AnalyticDataFactory())
    if (current.size > 1) currentSum = currentSum.set('daus', currentSum.daus / current.size)
    let previousSum = previous.reduce(sumRecord, AnalyticDataFactory())
    if (previous.size > 1) previousSum = previousSum.set('daus', previousSum.daus / previous.size)
    data.push({
      label: 'DAUS',
      buildTooltip: formatedValue =>
        `${formatedValue} unique users opened your app on a daily basis`,
      ...computeBase(currentSum, previousSum, 'daus'),
    })
    data.push({
      label: 'Installs',
      buildTooltip: formatedValue => `${formatedValue} installations of your app`,
      ...computeBase(currentSum, previousSum, 'installs'),
    })
    data.push({
      label: 'Starts',
      buildTooltip: formatedValue => `${formatedValue} sessions initiated by your users`,
      ...computeBase(currentSum, previousSum, 'starts'),
    })
    if (currentSum.pushes) {
      data.push({
        label: 'Push',
        buildTooltip: formatedValue =>
          `${formatedValue} campaigns or transactional notifications sent to your users`,
        ...computeBase(currentSum, previousSum, 'pushes'),
      })
    }
    return data
  }
)
type serieKey =
  | 'daus'
  | 'maus'
  | 'starts'
  | 'installs'
  | 'pushes'
  | 'transactions'
  | 'tokens'
  | 'deletedTokens'
  | 'optOut'
export type serie = {
  label: string
  color: string
  data: Array<{
    value: number
    period: Dayjs
  }>
  key: serieKey
}

type serieType = {
  key: serieKey
  title: string
  region: boolean
  headerTitle?: string
  chart: boolean
  color: string
}
const seriesBasis: Array<serieType> = [
  {
    key: 'daus',
    title: 'DAU',
    region: true,
    chart: true,
    color: '#87BB60',
  },
  {
    key: 'installs',
    title: 'Installs',
    region: true,
    chart: true,
    color: '#FECB42',
  },
  {
    key: 'starts',
    title: 'Starts',
    headerTitle: 'Starts',
    region: true,
    chart: true,
    color: '#56B6E3',
  },
  {
    key: 'pushes',
    title: 'Push',
    region: false,
    chart: false,
    color: '#345DA8',
  },
  {
    key: 'transactions',
    title: 'Revenue',
    region: false,
    chart: false,
    color: '#1BA28A',
  },
  {
    key: 'deletedTokens',
    title: 'Deleted tokens',
    region: false,
    chart: true,
    color: '#FF6347',
  },
  {
    key: 'maus',
    title: 'Rolling MAUs',
    region: false,
    chart: true,
    color: '#82CCBC',
  },
]

const hasPushSelector = createSelector(analyticsOverviewSelector, overview => {
  return overview.length === 4
})

export const analyticsSeriesSelector: extract<Array<List<DataSetRecord>>> = createSelector(
  currentPeriodAnalytics,
  currentAppSelector,
  hasPushSelector,
  secretSelector,
  (current: List<AnalyticByPeriodRecord>, app: AppRecord, hasPush: boolean, secret: boolean) => {
    const isWeb = app.platform === 'webpush'
    const sumTransactions = current.reduce((sum, rec) => sum + rec.data.transactions, 0)
    const res = seriesBasis
      .filter(
        serie =>
          serie.chart ||
          secret ||
          (serie.key === 'pushes' && hasPush) ||
          (serie.key === 'transactions' && sumTransactions > 0)
      )
      .map(serie => {
        return Immutable.List([
          DataSetFactory({
            color: serie.color,
            label:
              !isWeb && serie.title.toLowerCase() === 'deleted tokens'
                ? 'Uninstalls'
                : serie.title.toLowerCase(),
            total: current.reduce((sum, rec) => sum + rec.data[serie.key], 0),
            data: current.map(rec =>
              DataPointFactory({
                value: rec.data.get(serie.key),
                date: rec.period,
              })
            ),
          }),
        ])
      })

    return res
  }
)

export const analyticsRegionSelector: extract<
  Array<{
    color: string
    title: string
    values: Array<{
      regionCode: string
      color: string
      regionLabel: string
      value: number
    }>
  }>
> = createSelector(regionDataSelector, hasPushSelector, (data, hasPush) => {
  return seriesBasis
    .filter(serie => serie.region || (serie.key === 'pushes' && hasPush))
    .map(serie => {
      return {
        color: serie.color,
        title: serie.title,
        values: data
          .map(regionRec => {
            const tempArrayNumByPeriodForRegion: Array<number> = regionRec.data.get(serie.key, [])
            return {
              regionCode: regionRec.region,
              color: '',
              regionLabel: _get(regions, regionRec.region, regionRec.region),
              value:
                tempArrayNumByPeriodForRegion.reduce((prev, curr) => prev + curr, 0) /
                (serie.key === 'daus' ? regionRec.data.get(serie.key, []).length : 1),
            }
          })
          .toArray(),
      }
    })
})

export const reachIsEmptySelector: extract<boolean> = createSelector(
  reachSelector,
  (reachByDays: List<ReachByDayRecord>) => {
    return reachByDays.reduce((acc, current) => acc + current.data.count.tokens, 0) === 0
  }
)

export const reachSeriesSelector: extract<List<List<DataSetRecord>>> = createSelector(
  reachSelector,
  currentAppSelector,
  (reachByDays: List<ReachByDayRecord>, app: AppRecord) => {
    const sets = [
      Immutable.List([
        DataSetFactory({
          color: '#87bb60',
          label: app.platform !== 'webpush' ? 'total tokens' : 'total opt-ins',
          total: reachByDays.reduce((sum, rec) => sum + rec.data.count.tokens, 0),
          data: reachByDays.map(rd => {
            return DataPointFactory({
              value: rd.data.count.tokens,
              date: rd.date,
            })
          }),
        }),
      ]),
    ]
    if (app.platform !== 'webpush') {
      sets.push(
        Immutable.List([
          DataSetFactory({
            color: '#02b2fc',
            label: 'Total opt-ins',
            total: reachByDays.reduce((sum, rec) => sum + rec.data.count.tokensNotifOn, 0),
            data: reachByDays.map(rd => {
              return DataPointFactory({
                value: rd.data.count.tokensNotifOn,
                date: rd.date,
              })
            }),
          }),
        ]),
        Immutable.List([
          DataSetFactory({
            color: '#72D7A5',
            label: 'New opt-ins',
            total: reachByDays.reduce(
              (sum, rec) => sum + rec.data.changes.newTokensNotifOn + rec.data.changes.toNotifOn,
              0
            ),
            data: reachByDays.map(rd => {
              return DataPointFactory({
                value: rd.data.changes.newTokensNotifOn + rd.data.changes.toNotifOn,
                date: rd.date,
              })
            }),
          }),
        ]),
        Immutable.List([
          DataSetFactory({
            color: '#DC4142',
            label: 'Opt-outs',
            total: reachByDays.reduce(
              (sum, rec) =>
                sum + rec.data.changes.deletedTokensNotifOn + rec.data.changes.toNotifOff,
              0
            ),
            data: reachByDays.map(rd => {
              return DataPointFactory({
                value: rd.data.changes.deletedTokensNotifOn + rd.data.changes.toNotifOff,
                date: rd.date,
              })
            }),
          }),
        ])
      )
    } else {
      sets.push(
        Immutable.List([
          DataSetFactory({
            color: '#72D7A5',
            label: 'Opt-ins',
            total: reachByDays.reduce((sum, rec) => sum + rec.data.changes.newTokens, 0),
            data: reachByDays.map(rd => {
              return DataPointFactory({
                value: rd.data.changes.newTokens,
                date: rd.date,
              })
            }),
          }),
        ]),
        Immutable.List([
          DataSetFactory({
            color: '#DC4142',
            label: 'Opt-outs',
            total: reachByDays.reduce((sum, rec) => sum + rec.data.changes.deletedTokens, 0),
            data: reachByDays.map(rd => {
              return DataPointFactory({
                value: rd.data.changes.deletedTokens,
                date: rd.date,
              })
            }),
          }),
        ])
      )
    }
    return Immutable.List(sets)
  }
)
export const reachCsvSelector: extract<string> = createSelector(
  reachSelector,
  currentAppSelector,
  (reachByDays: List<ReachByDayRecord>, app: AppRecord) => {
    const isWeb = app.platform === 'webpush'
    const cols: Array<string | number> = isWeb
      ? ['date', 'total_opt-ins', 'new_opt-ins', 'opt-outs']
      : ['date', 'total_tokens', 'total_opt-ins', 'new_opt-ins', 'opt-outs', 'uninstalls_opt-outs']

    const rows: Array<Array<string | number>> = [cols]
    reachByDays.forEach(d => {
      rows.push(
        isWeb
          ? [
              d.date.format('DD/MM/YYYY'),
              d.data.count.tokens,
              d.data.changes.newTokens,
              d.data.changes.deletedTokens,
            ]
          : [
              d.date.format('DD/MM/YYYY'),
              d.data.count.tokens,
              d.data.count.tokensNotifOn,
              d.data.changes.newTokensNotifOn + d.data.changes.toNotifOn,
              d.data.changes.toNotifOff,
              d.data.changes.deletedTokensNotifOn,
            ]
      )
    })
    return rows.map(row => row.join(',')).join('\n')
  }
)
