import { textUsesTemplating } from 'com.batch.common/utils'

import { type HtmlEditorConfig } from '../models/html-editor-config'
import { type DispatchBoundFn } from 'com.batch.redux/_records'

import { type EmailMessageRecord } from 'com.batch/message/models/message.records'
import { fetchTemplate } from 'com.batch/message/usecases/fetch-templates'

export type UpdateEmailContentAction = {
  type: 'UPDATE_EMAIL_CONTENT'
  payload: {
    messageId: string
    lang: string
    field: 'subject' | 'html'
    value: string
    htmlEditorConfig?: HtmlEditorConfig
  }
}

const debounces: {
  [key: string]: NodeJS.Timeout
} = {}
export const updateEmailContent = ({
  messageId,
  lang,
  field,
  value,
  htmlEditorConfig,
  isInstant = false,
  timeout = 500,
}: {
  messageId: string
  lang: string
  field: 'subject' | 'html' | 'replyTo'
  value: string | null | undefined
  htmlEditorConfig?: HtmlEditorConfig | null | undefined
  isInstant?: boolean
  timeout?: number
}): DispatchBoundFn<void> => {
  const contentIdentifier = `${messageId}_${lang}_${field}`
  clearTimeout(debounces[contentIdentifier])

  return dispatch => {
    dispatch({
      type: 'UPDATE_EMAIL_CONTENT',
      payload: {
        messageId,
        lang,
        field,
        value,
        htmlEditorConfig,
      },
    })

    debounces[contentIdentifier] = setTimeout(
      () => {
        if (value && textUsesTemplating(value)) {
          dispatch(
            fetchTemplate({
              messageId,
              field,
              lang,
              value,
              type: field === 'html' ? 'HTML_TEMPLATE' : 'TEMPLATE',
            })
          )
            .then(res => {
              const { template, value } = res[0]
              dispatch({
                type: 'UPDATE_EMAIL_TEMPLATE',
                payload: {
                  messageId,
                  lang,
                  [field as string]: template ?? value,
                },
              })
            })
            .catch(e => {
              if (e.aborted) console.log('Aborted preview update')
              else
                console.error(
                  'Error while fetching templates:\n',
                  e.error?.errors?.[0]?.message ?? 'Unknown error'
                )
            })
        } else {
          dispatch({
            type: 'UPDATE_EMAIL_TEMPLATE',
            payload: {
              messageId,
              lang,
              [field as string]: value,
            },
          })
        }
      },
      isInstant ? 0 : timeout
    )
  }
}

export type UpdateEmailRecordAction = {
  type: 'UPDATE_EMAIL_RECORD'
  payload: {
    messageId: string
    email: EmailMessageRecord
  }
}

export const updateEmailRecord = ({
  messageId,
  emailRecord,
}: {
  messageId: string
  emailRecord: EmailMessageRecord
}): UpdateEmailRecordAction => ({
  type: 'UPDATE_EMAIL_RECORD',
  payload: { messageId, email: emailRecord },
})
