import * as React from 'react'

import { Button } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

type MediaUrlFieldProps = {
  openPopin: () => any
  open: () => any
}

export const MediaUrlField = ({ openPopin, open }: MediaUrlFieldProps): React.ReactElement => {
  return (
    <React.Fragment>
      <Button kind="secondary" addOn="prefix" intent="action" onClick={open}>
        <Icon icon="image" />
        Upload image
      </Button>
      <Button type="button" kind="inline" onClick={openPopin}>
        or use an URL
      </Button>
    </React.Fragment>
  )
}
