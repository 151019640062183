import * as React from 'react'

import { Grid } from 'components/common/grid'

import { ProjectAvatar, ProjectListItemContainer } from './dropdown.styles'

import { type ProjectRecord } from 'com.batch.redux/project.records'

type ProjectListItemProps = {
  project: ProjectRecord
  selected: boolean
  href: string
}

export const ProjectListItem = ({
  project,
  selected,
  href,
}: ProjectListItemProps): React.ReactElement => {
  return (
    <ProjectListItemContainer $selected={selected} key={project.id} href={href}>
      <Grid template="32px 1fr auto">
        <ProjectAvatar project={project} />
        <div className="project-info">
          <p className="project-name">{project.name}</p>
        </div>
      </Grid>
    </ProjectListItemContainer>
  )
}
