import * as React from 'react'

import { ButtonLink, ButtonNavLink, type ButtonProps } from 'components/common/button'
import { type availableIcons, Icon } from 'components/common/svg-icon'

type Props = React.HtmlHTMLAttributes<HTMLButtonElement> &
  ButtonProps & {
    hardReload: boolean
    url: string
    isActive?: boolean
    icon?: availableIcons
    $withIconSuffix?: boolean
    as?: any
  }

export const HardLinkOrNavLink = ({
  hardReload,
  url,
  isActive,
  children,
  icon,
  ...rest
}: Props): React.ReactElement => {
  const className = rest.className
    ? `${rest.className} ${isActive ? 'active' : ''}`
    : `${isActive ? 'active' : ''}`
  return hardReload ? (
    <ButtonLink
      {...rest}
      style={{ marginRight: 12 }}
      isActive={isActive}
      href={url}
      className={className}
    >
      {icon && <Icon icon={icon} />}
      <span className="styled-button-text">{children}</span>
    </ButtonLink>
  ) : (
    <ButtonNavLink
      {...rest}
      style={{ marginRight: 12 }}
      isActive={isActive}
      to={url}
      className={className}
    >
      {icon && <Icon icon={icon} />}
      <span className="styled-button-text">{children}</span>
    </ButtonNavLink>
  )
}
