import * as React from 'react'

export function useCombinedRefs<T>(
  outerRef: React.ForwardedRef<T>,
  innerRef: React.ForwardedRef<T>
): React.RefObject<T> {
  const targetRef = React.useRef<T>(null)
  React.useEffect(() => {
    if (typeof outerRef === 'function') {
      outerRef(targetRef.current)
    } else if (outerRef !== null) {
      outerRef.current = targetRef.current
    }
    if (typeof innerRef === 'function') {
      innerRef(targetRef.current)
    } else if (innerRef?.current) {
      innerRef.current = targetRef.current
    }
  }, [outerRef, innerRef])

  return targetRef
}
