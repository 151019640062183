import * as React from 'react'

export const TimelineIcon = (): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
      <rect width="15" height="15" transform="translate(0.5)" fill="#FDFDFD" />
      <circle cx="8" cy="7.5" r="3.5" fill="#E0E0E0" />
    </svg>
  )
}

export const APIIcon = (): React.ReactElement => {
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: 4 }}
    >
      <rect width="25" height="18" rx="5" fill="#C9C9C9" />
      <path
        d="M3.38135 13L6.13135 5.24951H7.69434L10.4443 13H8.99414L8.34424 10.9966H5.47607L4.83154 13H3.38135ZM6.86719 6.68896L5.8252 9.92773H8.00049L6.9585 6.68896H6.86719ZM11.7764 13V5.24951H14.8701C16.4438 5.24951 17.5073 6.28613 17.5073 7.83838V7.84912C17.5073 9.396 16.4438 10.438 14.8701 10.438H13.1621V13H11.7764ZM14.5317 6.38281H13.1621V9.3208H14.5317C15.5254 9.3208 16.1055 8.78369 16.1055 7.85449V7.84375C16.1055 6.91455 15.5254 6.38281 14.5317 6.38281ZM19.0381 13V5.24951H20.4238V13H19.0381Z"
        fill="white"
      />
    </svg>
  )
}
