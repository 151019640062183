import styled, { css, keyframes } from 'styled-components'

import { colors } from 'components/styled/tokens'

const LoadingAnimation = keyframes`
0% { background-color: rgba(240, 240, 240, 0.1)}
50% { background-color: rgba(240, 240, 240, 0.7) }
100% { background-color: rgba(240, 240, 240, 0.1) }
`

const LoadingAnimationStyle = css`
  animation-name: ${LoadingAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`

type InlineEditorContainerProps = {
  $isLoading: boolean
  $fullyInline: boolean
}
export const InlineEditorContainer = styled.div<InlineEditorContainerProps>`
  position: relative;
  z-index: 5;
  ${p =>
    p.$isLoading &&
    css`
      &:before {
        content: '';
        position: absolute;
        background: #f0f0f0;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        ${LoadingAnimationStyle}
      }
    `}
  .editor-input {
    min-height: ${props => (props.$fullyInline ? '36px' : '20px')};
    background: #fff;
    color: ${colors.text};
    outline: none;
    box-shadow: 0 0 0 1px ${p => (p.theme.kind === 'filter' ? 'transparent' : colors.strokeAction)},
      inset 0 0 0 1px ${p => (p.theme.kind === 'filter' ? 'transparent' : colors.strokeAction)};
    border-radius: 8px;
    padding: ${props => (props.$fullyInline ? '8px 58px 4px 12px' : '4px 6px')};
  }
  .batch_templating__tag {
    padding: 1px 3px;
    font-size: 13px;
    background-color: #f1f6fc;
    word-break: break-all;
    color: #434d5f;
    border-radius: 10px;
  }
  .batch_templating__logical {
    padding: 1px 3px;
    font-size: 13px;
    background-color: #fef1c1;
    color: #39424e;
    border-radius: 2px;
  }
  .editor-placeholder {
    position: absolute;
    pointer-events: none;
    color: ${colors.textDisabled};
    top: ${props => (props.$fullyInline ? '8px' : '4px')};
    left: ${props => (props.$fullyInline ? '12px' : '6px')};
  }
`
