import styled, { keyframes, css, type Keyframes } from 'styled-components'

import { ButtonLink } from 'components/common/button'
import { InputWrapperContainer } from 'components/form/input-wrapper.styles'
import { InputContainer } from 'components/form/styles'
import { colors } from 'components/styled/tokens'
import { textLight, textNeutral } from 'components/styled/tokens/colors'
import { darklucent } from 'components/styled/tokens/schemes'
import { label, base } from 'components/styled/tokens/texts'

import { type ProjectRecord } from 'com.batch.redux/project.records'

export const Appear: Keyframes = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
`
export const Hide: Keyframes = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
`

export const DropDownContainer = styled.div<{
  isOpen: boolean
}>`
  width: 280px;
  position: relative;
  z-index: 100;
  font-size: 14px;
  background-color: ${colors.fill};
  outline: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  overflow: hidden;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.04),
    0 1px 18px rgba(0, 0, 0, 0.18);
  animation: ${p => (p.isOpen ? Appear : Hide)} 0.1s ease-in-out;
  animation-fill-mode: both;

  .dropdown-section {
    border-bottom: 1px solid ${colors.stroke};

    &:last-child {
      border-bottom: none;
    }
  }
`

export const ProjectsDropdownContainer = styled.div`
  max-width: 330px;
  padding: 0 4px 8px 4px;

  ${InputWrapperContainer} {
    height: 52px;
    margin: 8px 4px 0 4px;
    display: flex;
    justify-content: center;
    width: calc(100% - 8px);
    border-bottom: 1px solid rgba(15, 15, 15, 0.12);
  }

  ${InputContainer} {
    height: 36px;
    padding-right: 4px;
    border-radius: 5px;
    box-shadow: none;
    padding-left: 2px;

    input {
      padding-left: 10px;
    }
  }

  .projects-list {
    display: flex;
    flex-flow: column;
    gap: 6px;
    max-height: 300px;
    overflow-y: auto;
    padding: 0;
    margin-top: 8px;
    overscroll-behavior: contain;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(50, 54, 57, 0.18);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ${ButtonLink} {
      margin: 0 4px;
      width: calc(100% - 8px);
    }
  }

  .projects-empty {
    padding: 28px 22px;
    text-align: center;
  }
`

export const ProfileSectionHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 14px 0 4px;
  color: ${colors.text};

  .profile-section-infos {
    flex: 1 1 auto;
    margin-left: 12px;
    font-weight: 500;
  }

  .profile-section-infos span {
    color: ${colors.textLight};
  }
`

export const ProfileSection = styled.div.attrs({
  className: 'dropdown-section',
})`
  display: flex;
  flex-direction: column;
  padding: 8px;

  ${ProfileSectionHeader} {
    margin-bottom: 10px;
  }

  button,
  a {
    justify-content: flex-start;
    font-size: 14px;

    .styled-button-prefix {
      margin-right: 12px;
    }
  }

  button + button,
  a + a {
    margin-top: 2px;
  }
`

export const ProjectAvatar = styled.div<{
  project: ProjectRecord
}>`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid rgba(15, 15, 15, 0.06);
  overflow: hidden;
  ${props =>
    !props.project.iconUrl &&
    css`
      display: flex;
      color: #4b5155;
      align-items: center;
      justify-content: center;
      font-size: 14px;

      &:before {
        content: '${props.project.name.substring(0, 1)}';
      }
    `}
  background: ${props =>
    props.project.iconUrl ? `no-repeat center/cover url(${props.project.iconUrl})` : '#DDDDDD'};
`

export const ProjectListItemContainer = styled(ButtonLink)<{
  $selected: boolean
}>`
  height: 48px;
  padding: 4px 6px 4px 10px;

  &:hover {
    .project-name {
      color: ${textNeutral};
    }
  }

  .project-info {
    overflow: hidden;
  }

  .project-name {
    font-weight: ${label.weight};
    color: ${textLight};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .app-size {
    color: ${textLight};
    font-weight: ${base.weight};
  }

  ${props =>
    props.$selected &&
    css`
      background: ${darklucent[20]};

      .project-name {
        color: ${textNeutral};
      }
    `}
`
