import { MessageConfigFactory } from '../../message/models/message.records'
import {
  type UpdatePushMessageRecordTokenModeAction,
  type UpdatePushMessageContentAction,
  type UpdatePushMessageRecordAction,
} from '../../push/usecases/update-push-content'
import {
  OrchestrationStateFactory,
  type OrchestrationStateRecord,
} from 'com.batch/orchestration/store/orchestration.state'
import { type FetchUnifiedCustomerDataSummarySuccessAction } from 'com.batch.redux/corelogic/usecases/unified-customer-data/fetch-unified-customer-data-summary'
import { type QueryReducerActions } from 'com.batch.redux/query/query'
import { type TargetReducerActions } from 'com.batch.redux/target/target'

import {
  type UpdateEmailInfoAction,
  type UpdateEmailSenderAction,
} from 'com.batch/email/usecases/update-content'
import { type UpdateEmailContentAction } from 'com.batch/email/usecases/update-email-content'
import { type UpdateCampaignExperimentAction } from 'com.batch/message/usecases/experiment'
import {
  type AddLanguageAction,
  type RemoveLanguageAction,
  type ActivateMultiLanguageAction,
  type DeactivateMultiLanguageAction,
} from 'com.batch/message/usecases/multilanguage'
import { type RemoveEmailContentAction } from 'com.batch/message/usecases/remove-email-content'
import {
  type BlurInlineEditorAction,
  type SetInlineEditorAction,
} from 'com.batch/message-builder/usecases/manage-inline-editor'
import {
  type fetchOrchestrationAction,
  type fetchOrchestrationFailureAction,
  type fetchOrchestrationSuccessAction,
} from 'com.batch/orchestration/usecases/fetch-orchestration'
import { type InitFormAction } from 'com.batch/orchestration/usecases/init-form'
import {
  type SaveOrchestrationAction,
  type SaveOrchestrationFailureAction,
  type SaveOrchestrationSuccessAction,
} from 'com.batch/orchestration/usecases/persist-orchestration'
import { type SetLabelsAction } from 'com.batch/orchestration/usecases/set-orchestration-label-codes'
import { type UpdateOrchestrationNameAction } from 'com.batch/orchestration/usecases/update-name'
import { OrchestrationCampaignFactory } from 'com.batch/orchestration-campaign/models/campaign.records'
import { type InitOrchestrationCampaignAction } from 'com.batch/orchestration-campaign/usecases/init-orchestration-campaign'
import { type UpdateOrchestrationCampaignAction } from 'com.batch/orchestration-campaign/usecases/update-orchestration-campaign'
import { checkMissingEventsOnEntryAndTimer } from 'com.batch/orchestration-journey/models/events.helper'
import {
  insertNodeAndUpdateTree,
  removeNodeAndUpdateTree,
  removeOrphanNodes,
  validateTree,
} from 'com.batch/orchestration-journey/models/tree.helpers'
import { type FlagMissingTargetingAction } from 'com.batch/orchestration-journey/usecases/check-missing-targeting-yesno-node'
import { type CopyMessageNodeAction } from 'com.batch/orchestration-journey/usecases/copy-message-node'
import { type FlagIncompleteAction } from 'com.batch/orchestration-journey/usecases/flag-incomplete-message-id'
import { type InitSettingsAction } from 'com.batch/orchestration-journey/usecases/init-settings'
import { type InsertNodeAfterAction } from 'com.batch/orchestration-journey/usecases/insert-node-after'
import { type PasteMessageNodeAction } from 'com.batch/orchestration-journey/usecases/paste-message-node'
import { type RemoveNodeAction } from 'com.batch/orchestration-journey/usecases/remove-node'
import { type SetEditingNodeIdAction } from 'com.batch/orchestration-journey/usecases/set-editing-node-id'
import { type SetNodesAction } from 'com.batch/orchestration-journey/usecases/set-nodes'
import { type UpdateJourneyNodeAction } from 'com.batch/orchestration-journey/usecases/update-node'
import { type UpdateSettingsAction } from 'com.batch/orchestration-journey/usecases/update-settings'
import { type UpdatePushPlatformsAction } from 'com.batch/push/usecases/update-push-platforms'
import { type UpdatePushSettingsAction } from 'com.batch/push/usecases/update-push-settings'
import { type UpdateSmsContentAction } from 'com.batch/sms/usecases/update-sms-content'

type Action =
  | InitFormAction
  | UpdateOrchestrationNameAction
  | UpdateOrchestrationCampaignAction
  | InitOrchestrationCampaignAction
  | SaveOrchestrationAction
  | FlagIncompleteAction
  | UpdateJourneyNodeAction
  | UpdateSmsContentAction
  | SaveOrchestrationSuccessAction
  | SaveOrchestrationFailureAction
  | UpdateSettingsAction
  | InsertNodeAfterAction
  | FlagMissingTargetingAction
  | RemoveNodeAction
  | SetNodesAction
  | SetEditingNodeIdAction
  | fetchOrchestrationAction
  | fetchOrchestrationSuccessAction
  | FetchUnifiedCustomerDataSummarySuccessAction
  | fetchOrchestrationFailureAction
  | SetInlineEditorAction
  | BlurInlineEditorAction
  | InitSettingsAction
  | SetLabelsAction
  | QueryReducerActions
  | TargetReducerActions
  | UpdateEmailInfoAction
  | UpdateEmailContentAction
  | UpdateEmailSenderAction
  | RemoveEmailContentAction
  | CopyMessageNodeAction
  | PasteMessageNodeAction
  | UpdatePushMessageRecordAction
  | UpdatePushMessageContentAction
  | AddLanguageAction
  | RemoveLanguageAction
  | ActivateMultiLanguageAction
  | DeactivateMultiLanguageAction
  | UpdateCampaignExperimentAction
  | UpdatePushSettingsAction
  | UpdatePushPlatformsAction
  | UpdatePushMessageRecordTokenModeAction

const bumpVersion = (state: OrchestrationStateRecord): OrchestrationStateRecord =>
  state.set('version', state.version + 1)

export const orchestrationReducer = (
  state = OrchestrationStateFactory(),
  action: Action
): OrchestrationStateRecord => {
  switch (action.type) {
    case 'ACTIVATE_MULTILANGUAGE':
    case 'DEACTIVATE_MULTILANGUAGE': {
      const stepMessageNodeId = action.payload.stepMessageNodeId
      if (stepMessageNodeId) {
        const messageNode = state.triggerNodes.get(stepMessageNodeId)
        if (!messageNode || messageNode.type !== 'MESSAGE') {
          throw new Error('Inconsistent state, message node not found')
        }
        return bumpVersion(
          state.setIn(
            ['triggerNodes', stepMessageNodeId],
            messageNode.set(
              'messageConfig',
              messageNode.messageConfig.set(
                'multilanguageEnabled',
                action.type === 'ACTIVATE_MULTILANGUAGE'
              )
            )
          )
        )
      } else {
        return bumpVersion(
          state.set(
            'campaign',
            state.campaign.set(
              'messageConfig',
              state.campaign.messageConfig.set(
                'multilanguageEnabled',
                action.type === 'ACTIVATE_MULTILANGUAGE'
              )
            )
          )
        )
      }
    }
    case 'UPDATE_PUSH_SETTINGS': {
      const stepMessageNodeId = action.payload.stepMessageNodeId
      if (stepMessageNodeId) {
        const messageNode = state.triggerNodes.get(stepMessageNodeId)
        if (!messageNode || messageNode.type !== 'MESSAGE') {
          throw new Error('Inconsistent state, message node not found')
        }
        return bumpVersion(
          state.setIn(
            ['triggerNodes', stepMessageNodeId],
            messageNode.set(
              'messageConfig',
              messageNode.messageConfig.set('pushSettings', action.payload.settings)
            )
          )
        )
      } else {
        return bumpVersion(
          state.set(
            'campaign',
            state.campaign.set(
              'messageConfig',
              state.campaign.messageConfig.set('pushSettings', action.payload.settings)
            )
          )
        )
      }
    }
    case 'UPDATE_PUSH_PLATFORMS': {
      const stepMessageNodeId = action.payload.stepMessageNodeId
      if (stepMessageNodeId) {
        const messageNode = state.triggerNodes.get(stepMessageNodeId)
        if (!messageNode || messageNode.type !== 'MESSAGE') {
          throw new Error('Inconsistent state, message node not found')
        }
        return bumpVersion(
          state.setIn(
            ['triggerNodes', stepMessageNodeId],
            messageNode.set(
              'messageConfig',
              messageNode.messageConfig.set('platforms', action.payload.platforms)
            )
          )
        )
      } else {
        return bumpVersion(
          state.set(
            'campaign',
            state.campaign.set(
              'messageConfig',
              state.campaign.messageConfig.set('platforms', action.payload.platforms)
            )
          )
        )
      }
    }
    case 'UPDATE_PUSH_MESSAGE_TOKEN_MODE_RECORD': {
      let platforms = state.campaign.messageConfig.platforms
      if (platforms.has('webpush') && action.payload.tokenMode === 'imported') {
        platforms = platforms.delete('webpush')
      }
      // update all push messages
      return state.set(
        'campaign',
        state.campaign.set(
          'messageConfig',
          state.campaign.messageConfig
            .set(
              'pushSettings',
              state.campaign.messageConfig.pushSettings.set(
                'filterPushTokens',
                action.payload.tokenMode
              )
            )
            .set('platforms', platforms)
        )
      )
    }
    case 'UPDATE_CAMPAIN_EXPERIMENT':
      return bumpVersion(state.setIn(['campaign', 'messageConfig', 'experiment'], action.payload))
    case 'COPY_MESSAGE_NODE':
      return state.set('copiedNodeId', action.payload)
    case 'PASTE_MESSAGE_NODE':
      return state.set('copiedNodeId', '')
    case 'INIT_FORM':
      return OrchestrationStateFactory({
        campaign: OrchestrationCampaignFactory({
          messageConfig: MessageConfigFactory({
            messageTypedId: action.payload.messageTypedId,
            channel: action.payload.channel,
          }),
          sendType: action.payload.sendType,
        }),
      })
    case 'FLAG_MISSING_TARGETING': {
      const node = state.triggerNodes.get(action.payload.nodeId)
      if (!node || node.type !== 'YESNO') return state
      return state.setIn(
        ['triggerNodes', action.payload.nodeId],
        node.set(
          'errors',
          action.payload.targetingMissing
            ? node.errors.add('MISSING_TARGETING')
            : node.errors.remove('MISSING_TARGETING')
        )
      )
    }
    case 'FLAG_INCOMPLETE': {
      const nodeId = state.triggerNodes.findKey(
        node =>
          node.type === 'MESSAGE' && node.messageConfig.messageTypedId === action.payload.messageId
      )
      if (!nodeId) {
        return state
      }
      const updatedMessage = state.triggerNodes.get(nodeId)
      if (!updatedMessage || updatedMessage.type !== 'MESSAGE') {
        return state
      }
      return state.setIn(
        ['triggerNodes', nodeId],
        updatedMessage.set(
          'errors',
          action.payload.incomplete
            ? updatedMessage.errors.add('INCOMPLETE_MESSAGE')
            : updatedMessage.errors.remove('INCOMPLETE_MESSAGE')
        )
      )
    }
    case 'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY_SUCCESS': {
      if (state.campaign.sendType !== 'trigger') return state
      const fixed = checkMissingEventsOnEntryAndTimer(
        {
          settings: state.triggerSettings,
          nodes: state.triggerNodes,
        },
        action.payload
      )
      return state.set('triggerSettings', fixed.settings).set('triggerNodes', fixed.nodes)
    }
    case 'INIT_ORCHESTRATION_CAMPAIGN':
      return state.set('campaign', action.payload)
    case 'UPDATE_ORCHESTRATION_CAMPAIGN':
      return bumpVersion(state.set('campaign', action.payload))
    case 'UPDATE_ORCHESTRATION_NAME':
      return bumpVersion(state.set('name', action.payload))
    case 'FETCH_ORCHESTRATION':
      return state.set('loadingState', 'LOADING').set('copiedNodeId', '')
    case 'FETCH_ORCHESTRATION_FAILURE':
      return state.set('loadingState', 'ERROR')
    case 'FETCH_ORCHESTRATION_SUCCESS':
      return state
        .set('loadingState', 'LOADED')
        .set('name', action.payload.name)
        .set('state', action.payload.state)
        .set('channels', action.payload.channels)
        .set('id', action.payload.id)
        .set('incomplete', action.payload.incomplete)
        .setIn(['campaign', 'sendType'], action.payload.sendType)
        .set('labelCodes', action.payload.labelCodes)
        .set('createdByApi', action.payload.createdByApi)
    case 'SAVE_ORCHESTRATION':
      return state.set('savingState', 'LOADING')
    case 'SAVE_ORCHESTRATION_FAILURE':
      return state.set('savingState', 'ERROR')
    case 'SAVE_ORCHESTRATION_SUCCESS':
      return state.set('savingState', 'LOADED').set('savedVersion', state.version)
    case 'SET_JOURNEY_NODES': {
      const nodesMap = validateTree({
        nodesMap: action.payload.nodes,
      })
      return state.set('triggerNodes', nodesMap).set('triggerRootId', action.payload.rootId)
    }
    case 'UPDATE_JOURNEY_NODE': {
      const updatedNodes = state.triggerNodes.set(action.payload.node.id, action.payload.node)
      if (action.payload.dangerousSkipChecks) {
        return state.set('triggerNodes', updatedNodes)
      }
      return bumpVersion(
        state.set(
          'triggerNodes',
          validateTree({
            nodesMap: removeOrphanNodes({
              nodesMap: updatedNodes,
              rootId: state.triggerRootId,
            }),
          })
        )
      )
    }
    case 'REMOVE_NODE': {
      const updatedTree = removeNodeAndUpdateTree({
        nodes: state.triggerNodes,
        rootId: state.triggerRootId,
        nodeToRemove: action.payload.nodeToRemove,
        branchToKeep: action.payload.branchToKeep,
      })
      return bumpVersion(
        state
          .set(
            'triggerNodes',
            validateTree({
              nodesMap: removeOrphanNodes({
                nodesMap: updatedTree.nodes,
                rootId: updatedTree.rootId,
              }),
            })
          )
          .set('triggerRootId', updatedTree.rootId)
          .set('copiedNodeId', '')
      )
    }
    case 'INSERT_NODE_AFTER': {
      const updated = insertNodeAndUpdateTree({
        ...action.payload,
        nodes: state.triggerNodes,
        rootId: state.triggerRootId,
      })
      return bumpVersion(
        state
          .set(
            'triggerNodes',
            validateTree({
              nodesMap: removeOrphanNodes({
                nodesMap: updated.nodes,
                rootId: updated.rootId,
              }),
            })
          )
          .set('triggerRootId', updated.rootId)
          .set('editingNodeId', state.editingNodeId ? action.payload.id : '')
      )
    }
    case 'INIT_JOURNEY_SETTINGS':
      return state.set('triggerSettings', action.payload)
    case 'UPDATE_JOURNEY_SETTINGS': {
      const currentEntryEvents = state.triggerSettings.entryEvents.reduce(
        (acc, event) => acc + event.name,
        ''
      )
      const updatedEntryEvents = action.payload.entryEvents.reduce(
        (acc, event) => acc + event.name,
        ''
      )
      const needToTrashTimerNodeEventDateAttribute = currentEntryEvents !== updatedEntryEvents
      const stateWithUpdatedSettings = bumpVersion(state.set('triggerSettings', action.payload))
      return needToTrashTimerNodeEventDateAttribute
        ? stateWithUpdatedSettings.set(
            'triggerNodes',
            validateTree({
              nodesMap: stateWithUpdatedSettings.triggerNodes.map(node =>
                node.type === 'TIMER' ? node.set('timerReference', '') : node
              ),
            })
          )
        : stateWithUpdatedSettings
    }
    case 'SET_EDITING_NODE_ID':
      return state.set('editingNodeId', action.payload).set('copiedNodeId', '')
    case 'SET_ORCHESTRATION_LABELS':
      return bumpVersion(state.set('labelCodes', action.payload))
    case 'T_ADD_CONDITION':
    case 'T_REMOVE_CONDITION':
    case 'T_UPDATE_CONDITION':
    case 'T_NEGATE':
    case 'T_UN_NEGATE':
    case 'UPDATE_EMAIL_INFO':
    case 'UPDATE_PUSH_MESSAGE_CONTENT':
    case 'UPDATE_PUSH_MESSAGE_RECORD':
    case 'UPDATE_EMAIL_CONTENT':
    case 'REMOVE_EMAIL_CONTENT':
    case 'UPDATE_EMAIL_SENDER':
    case 'UPDATE_SMS_CONTENT':
    case 'TARGET_KIND':
    case 'TARGET_LANG_REGION':
    case 'ADD_LANGUAGE':
    case 'REMOVE_LANGUAGE':
      return bumpVersion(state)
    default:
      return state
  }
}
