import styled, { css } from 'styled-components'

type props = {
  $isFixed: boolean
}
export const FooterOrchestrationContainer = styled.div<props>`
  ${p =>
    p.$isFixed
      ? css`
          position: fixed;
          bottom: 0;
          right: 0;
          background: white;
          padding: 8px;
          border-radius: 8px;
          display: flex;
          gap: 8px;
          margin: 20px;
          border: 1px solid #0f0f0f1f;
        `
      : css`
          text-align: right;
          span + span {
            margin-left: 8px;
          }
        `}
`
