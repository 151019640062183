import Immutable, { type Map, type List, type RecordOf } from 'immutable'

import { type ClickLinkRecord } from '../models/click-links-detail.record'
import { type OrchestrationStatsByStepRecord } from '../models/orchestration-stats-by-step.record'
import { type StatsRecord, type BounceCategoryRecord } from '../models/orchestration-stats.record'
import { type DynamicDimension } from '../usecases/fetch-dynamic-stats.helper'

import { type BounceDetailRecord } from 'com.batch/orchestration-analytics/models/bounce-detail-record'
import { type EmailProviderRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-provider.record'
import { type OrchestrationStatsByVariantRecord } from 'com.batch/orchestration-analytics/models/orchestration-stats-by-variant.record'
import { STATUS } from 'constants/common'
import { type BarChartData } from 'com.batch/shared/infra/types/chart-data'
import { dayjs, type Dayjs } from 'com.batch.common/dayjs.custom'

type BounceAnalytics = {
  periods: Map<string, BounceDetailRecord>
  categories: List<BounceCategoryRecord> | null
}
export const BounceAnalyticsFactory = Immutable.Record<BounceAnalytics>({
  periods: Immutable.Map(),
  categories: null,
})
export type BounceAnalyticsRecord = RecordOf<BounceAnalytics>

export type PeriodicAnalyticsProps = {
  periodicity: 'day' | 'week' | 'month'
  data: List<BarChartData>
}
export const PeriodicAnalyticsFactory = Immutable.Record<PeriodicAnalyticsProps>({
  periodicity: 'day',
  data: Immutable.List(),
})
export type PeriodicAnalyticsRecord = RecordOf<PeriodicAnalyticsProps>

export type EmailAnalyticsProps = {
  stats: StatsRecord | null
  bounces: BounceAnalyticsRecord
  providers: List<EmailProviderRecord>
  links: List<ClickLinkRecord>
  periodic: PeriodicAnalyticsRecord
}
export const EmailAnalyticsFactory = Immutable.Record<EmailAnalyticsProps>({
  stats: null,
  bounces: BounceAnalyticsFactory(),
  providers: Immutable.List(),
  links: Immutable.List(),
  periodic: PeriodicAnalyticsFactory(),
})
export type EmailAnalyticsRecord = RecordOf<EmailAnalyticsProps>

export type SmsAnalyticsProps = {
  stats: StatsRecord | null
  periodic: PeriodicAnalyticsRecord
}
export const SmsAnalyticsFactory = Immutable.Record<SmsAnalyticsProps>({
  stats: null,
  periodic: PeriodicAnalyticsFactory(),
})
export type SmsAnalyticsRecord = RecordOf<SmsAnalyticsProps>

export type PushAnalyticsProps = {
  stats: StatsRecord | null
  periodic: PeriodicAnalyticsRecord
}
export const PushAnalyticsFactory = Immutable.Record<PushAnalyticsProps>({
  stats: null,
  periodic: PeriodicAnalyticsFactory(),
})
export type PushAnalyticsRecord = RecordOf<PushAnalyticsProps>

export type OrchestrationChannelsStatsProps = {
  email: EmailAnalyticsRecord
  sms: SmsAnalyticsRecord
  push: PushAnalyticsRecord
}
export const OrchestrationChannelsStatsFactory = Immutable.Record<OrchestrationChannelsStatsProps>({
  email: EmailAnalyticsFactory({
    stats: null,
    bounces: BounceAnalyticsFactory(),
    providers: Immutable.List(),
    links: Immutable.List(),
    periodic: PeriodicAnalyticsFactory(),
  }),
  sms: SmsAnalyticsFactory({
    stats: null,
    periodic: PeriodicAnalyticsFactory(),
  }),
  push: PushAnalyticsFactory({
    stats: null,
    periodic: PeriodicAnalyticsFactory(),
  }),
})
export type OrchestrationChannelsStatsRecord = RecordOf<OrchestrationChannelsStatsProps>

type OrchestrationAnalyticsDateRangeProps = {
  from: Dayjs
  to: Dayjs
}
export const OrchestrationAnalyticsDateRangeFactory =
  Immutable.Record<OrchestrationAnalyticsDateRangeProps>({
    from: dayjs.utc().subtract(6, 'day').startOf('day'),
    to: dayjs.utc().endOf('day'),
  })
export type OrchestrationAnalyticsDateRangeRecord = RecordOf<OrchestrationAnalyticsDateRangeProps>
export type OrchestrationAnalyticsFilters = Map<DynamicDimension, List<string>>

type DiscoveryFilterProps = {
  name: string
  sent: number
}
export const DiscoveryFilterFactory = Immutable.Record<DiscoveryFilterProps>({
  name: '',
  sent: 0,
})
export type DiscoveryFilterRecord = RecordOf<DiscoveryFilterProps>

export type OrchestrationAnalyticsProps = {
  expire: number
  steps: OrchestrationStatsByStepRecord | null
  variants: OrchestrationStatsByVariantRecord | null
  channels: OrchestrationChannelsStatsRecord
  discovery: Map<DynamicDimension, List<DiscoveryFilterRecord>>
  filters: OrchestrationAnalyticsFilters
  dateRange: OrchestrationAnalyticsDateRangeRecord | null
  filtersRestored: boolean
  emailLoadingState: LoadingState
  pushLoadingState: LoadingState
  smsLoadingState: LoadingState
  daysLoadingState: LoadingState
  stepsLoadingState: LoadingState
  variantsLoadingState: LoadingState
  providersLoadingState: LoadingState
  classificationsLoadingState: LoadingState
  linksLoadingState: LoadingState
  channelAndPeriodLoadingState: LoadingState
  filterDiscoveryState: LoadingState
}

export const OrchestrationAnalyticsFactory = Immutable.Record<OrchestrationAnalyticsProps>({
  expire: Date.now() + 1000 * 60 * 2, // TTL 2 min
  steps: null,
  variants: null,
  channels: OrchestrationChannelsStatsFactory(),
  discovery: Immutable.Map(),
  filters: Immutable.Map(),
  dateRange: null,
  filtersRestored: false,
  emailLoadingState: STATUS.INIT,
  pushLoadingState: STATUS.INIT,
  smsLoadingState: STATUS.INIT,
  daysLoadingState: STATUS.INIT,
  stepsLoadingState: STATUS.INIT,
  variantsLoadingState: STATUS.INIT,
  providersLoadingState: STATUS.INIT,
  classificationsLoadingState: STATUS.INIT,
  linksLoadingState: STATUS.INIT,
  channelAndPeriodLoadingState: STATUS.INIT,
  filterDiscoveryState: STATUS.INIT,
})

export type OrchestrationAnalyticsRecord = RecordOf<OrchestrationAnalyticsProps>

export type OrchestrationAnalyticsState = Map<string, OrchestrationAnalyticsRecord>
