import { textUsesTemplating } from 'com.batch.common/utils'

import { type DispatchBoundFn } from 'com.batch.redux/_records'

import {
  type FilterPushTokensType,
  type PushContentRecord,
  type PushSettingsRecord,
} from 'com.batch/message/models/message.records'
import { fetchTemplate } from 'com.batch/message/usecases/fetch-templates'

export type UpdatePushMessageRecordAction = {
  type: 'UPDATE_PUSH_MESSAGE_RECORD'
  payload: {
    messageId: string
    lang?: string
    content?: PushContentRecord
  }
}
export const updatePushMessageRecord = ({
  messageId,
  lang,
  content,
}: {
  messageId: string
  lang?: string
  content?: PushContentRecord
  settings?: PushSettingsRecord
}): DispatchBoundFn<UpdatePushMessageRecordAction> => {
  return dispatch => {
    return dispatch({
      type: 'UPDATE_PUSH_MESSAGE_RECORD',
      payload: {
        messageId,
        lang,
        content,
      },
    })
  }
}

export type UpdatePushMessageRecordTokenModeAction = {
  type: 'UPDATE_PUSH_MESSAGE_TOKEN_MODE_RECORD'
  payload: {
    tokenMode: FilterPushTokensType
  }
}

export const updatePushMessageRecordTokenModeAction = ({
  tokenMode,
}: {
  tokenMode: FilterPushTokensType
}): DispatchBoundFn<UpdatePushMessageRecordTokenModeAction> => {
  return dispatch => {
    return dispatch({
      type: 'UPDATE_PUSH_MESSAGE_TOKEN_MODE_RECORD',
      payload: {
        tokenMode,
      },
    })
  }
}

export type UpdatePushMessageContentAction = {
  type: 'UPDATE_PUSH_MESSAGE_CONTENT'
  payload: {
    messageId: string
    lang: string
    field: 'pushBody' | 'pushTitle' | 'pushPicture' | 'pushIcon'
    value: string
  }
}

const debounces: {
  [key: string]: NodeJS.Timeout
} = {}
export const updatePushMessageContent = ({
  messageId,
  lang,
  field,
  parent,
  value,
  isInstant = false,
}: {
  messageId: string
  lang: string
  field: 'pushBody' | 'pushTitle' | 'pushPicture' | 'pushIcon'
  parent: 'content' // | 'androidContent' | 'iosContent' | 'webContent' => quand la perso pour le deeplink sera dispo,,
  value: string
  isInstant?: boolean
}): DispatchBoundFn<void> => {
  const contentIdentifier = `${messageId}_${lang}_${field}`
  clearTimeout(debounces[contentIdentifier])

  return dispatch => {
    dispatch({
      type: 'UPDATE_PUSH_MESSAGE_CONTENT',
      payload: {
        messageId,
        lang,
        field,
        value,
      },
    })

    debounces[contentIdentifier] = setTimeout(
      () => {
        if (textUsesTemplating(value)) {
          dispatch(fetchTemplate({ messageId, field, parent, lang, value, type: 'TEMPLATE' }))
            .then(res => {
              const { template } = res[0]
              dispatch({
                type: 'UPDATE_PUSH_TEMPLATE',
                payload: {
                  messageId,
                  lang,
                  [parent as string]: {
                    [field as string]: template ?? value,
                  },
                },
              })
            })
            .catch(e => {
              console.error(
                'Error while fetching templates:\n',
                e.error?.errors?.[0]?.message ?? 'Unknown error'
              )
            })
        } else {
          dispatch({
            type: 'UPDATE_PUSH_TEMPLATE',
            payload: {
              messageId,
              lang,
              [parent as string]: {
                [field as string]: value,
              },
            },
          })
        }
      },
      isInstant ? 0 : 500
    )
  }
}
