import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { ThemeProvider } from 'styled-components'

import { useAction, useImageUploader, useToggle } from 'components/_hooks'
import { BoxBody, BoxFooter } from 'components/common/box'
import { Popin } from 'components/common/popin/popin'
import { MediaUrlPopin } from 'components/form/fields/media-url-builder/media-url-popin'
import { TemplateWizard } from 'components/template/template-wizard'

import { convertArrayPlatformToPreviewPlatform, textUsesTemplating } from 'com.batch.common/utils'

import { updatePushTemplate } from '../usecases/update-push-template'
import { previewLanguageSelector } from 'com.batch/message/store/message.selector'
import { inlineEditorConfigSelector } from 'com.batch/message-builder/store/inline-editor.selector'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { getPushContentForActiveLanguageSelector } from 'com.batch/push/store/push.selector'
import { currentProjectAppsSelector } from 'com.batch.redux/app'
import { personalisationAttrSelector } from 'com.batch.redux/attribute.selector'
import { triggerEventSelector } from 'com.batch.redux/campaign.selector'
import { showToast } from 'com.batch.redux/toaster'

import { getMessageId } from 'com.batch/message/models/message.helper'
import {
  PushMessageRawFactory,
  type PushContentRecord,
} from 'com.batch/message/models/message.records'
import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import {
  InlineContainer,
  Preview,
  PreviewContainer,
  PreviewMockup,
  PushBuilderToolbar,
  StickyInfoPanelContainer,
  type ThemeProps,
  VariantContainer,
} from 'com.batch/message-builder/ui/components'
import { setInlineEditor } from 'com.batch/message-builder/usecases/manage-inline-editor'
import { PushDeeplinkField } from 'com.batch/push/ui/push-deeplink-field'
import { PushLayerBar } from 'com.batch/push/ui/push-layer-bar'
import {
  updatePushMessageRecord,
  updatePushMessageContent,
} from 'com.batch/push/usecases/update-push-content'

const previewPlatformMatcher: Omit<{ [key in PreviewPlatform]: Channel }, 'sms'> = {
  android: 'android',
  ios: 'ios',
  webMac: 'webpush',
  webWin: 'webpush',
  webIos: 'webpush',
  webAndroid: 'webpush',
}

type PushBuilderProps = {
  stepMessageNodeId: string | null | undefined
}
const PushBuilderRaw = ({ stepMessageNodeId }: PushBuilderProps): React.ReactElement => {
  const dispatch = useDispatch()
  const getConfig = useSelector(getMessageConfigSelector)
  const config = React.useMemo(() => {
    return getConfig({ stepMessageNodeId })
  }, [getConfig, stepMessageNodeId])
  const messageId = React.useMemo(() => getMessageId(config), [config])
  const inlineEditorConfig = useSelector(inlineEditorConfigSelector)
  const editorConfig = useSelector(inlineEditorConfigSelector)
  const editing = React.useMemo(() => editorConfig.field, [editorConfig.field])
  const attributes = useSelector(personalisationAttrSelector)
  const contextEventId = useSelector(triggerEventSelector)
  const lang = useSelector(previewLanguageSelector)
  const getContent = useSelector(getPushContentForActiveLanguageSelector)
  const apps = useSelector(currentProjectAppsSelector)
  const campaignFormMode = React.useMemo(() => !stepMessageNodeId, [stepMessageNodeId])

  const showToastBound = useAction(showToast)

  const expandedToggle = useToggle()
  const mediaPopinState = useToggle()

  const content: PushContentRecord = React.useMemo(
    () => getContent(messageId),
    [getContent, messageId]
  )

  const selectedPlatforms = React.useMemo(() => config.platforms.toArray(), [config])
  const availablePreviewPlatforms = React.useMemo(() => {
    return convertArrayPlatformToPreviewPlatform(selectedPlatforms)
  }, [selectedPlatforms])
  const [previewPlatform, setPreviewPlatform] = React.useState<PreviewPlatform>(
    availablePreviewPlatforms.length > 0 ? availablePreviewPlatforms[0] : 'ios'
  )

  React.useEffect(() => {
    if (!availablePreviewPlatforms.includes(previewPlatform)) {
      setPreviewPlatform(availablePreviewPlatforms[0] ?? 'ios')
    }
  }, [availablePreviewPlatforms, previewPlatform])

  const previewedApp = React.useMemo(() => {
    return apps.find(app => app.platform === previewPlatformMatcher[previewPlatform])
  }, [apps, previewPlatform])

  const hasIcon = React.useMemo(() => !!content.content.templates.pushIcon, [content])
  const hasMedia = React.useMemo(() => !!content.content.templates.pushPicture, [content])

  const s3signingUrl = React.useMemo(() => `/api/app/${apps.first()?.id ?? 1}/s3config`, [apps])
  const onImageUploadSuccess = React.useCallback(
    url => {
      if (inlineEditorConfig.field === 'icon') {
        dispatch(
          updatePushMessageContent({
            messageId,
            lang,
            parent: 'content',
            field: 'pushIcon',
            value: url,
          })
        )
      } else {
        dispatch(
          updatePushMessageRecord({
            messageId,
            lang,
            content: content.setIn(['content', 'attachmentKind'], 'image'),
          })
        )
        dispatch(
          updatePushMessageContent({
            messageId,
            lang,
            parent: 'content',
            field: 'pushPicture',
            value: url,
          })
        )
      }
    },
    [content, dispatch, inlineEditorConfig.field, lang, messageId]
  )

  const onImageUploadError = React.useCallback(
    txt => showToastBound({ message: txt }),
    [showToastBound]
  )

  const { getUploaderRootProps, uploaderInputField, openUploader, draggingState } =
    useImageUploader({
      onImageUploadSuccess,
      onImageUploadError,
      imageMinWidth: inlineEditorConfig.field === 'icon' ? 192 : 300,
      imageMinHeight: inlineEditorConfig.field === 'icon' ? 192 : 200,
      imageRequiredRatio: inlineEditorConfig.field === 'icon' ? 1 : 0,
      s3signingUrl,
    })

  const openReplaceMedia = React.useCallback(() => {
    if (
      textUsesTemplating(content.get('content').pushPicture) ||
      content.get('content').attachmentKind !== 'image'
    ) {
      mediaPopinState.open()
    } else {
      openUploader()
    }
  }, [content, mediaPopinState, openUploader])

  const previewTheme = React.useMemo<ThemeProps>(
    () => ({
      previewPlatform,
      isFocused: editing === 'pushBody' || editing === 'pushTitle',
      app: previewedApp,
      openReplaceMedia,
      isExpanded: expandedToggle.value,
      hasIcon,
      hasMedia,
      abTestingEnabled: false,
      draggingState,
    }),
    [
      previewPlatform,
      editing,
      previewedApp,
      openReplaceMedia,
      expandedToggle.value,
      hasIcon,
      hasMedia,
      draggingState,
    ]
  )

  const onMediaUrlChanged = React.useCallback(
    media => {
      mediaPopinState.close()
      if (inlineEditorConfig.field === 'icon') {
        dispatch(
          updatePushMessageContent({
            messageId,
            lang,
            field: 'pushIcon',
            parent: 'content',
            value: media.mediaUrl,
          })
        )
      } else {
        dispatch(
          updatePushMessageRecord({
            messageId,
            lang,
            content: content.setIn(['content', 'attachmentKind'], media.mediaKind),
          })
        )
        dispatch(
          updatePushMessageContent({
            messageId,
            lang,
            field: 'pushPicture',
            parent: 'content',
            value: media.mediaUrl,
          })
        )
      }
    },
    [content, dispatch, inlineEditorConfig.field, lang, mediaPopinState, messageId]
  )

  const updateTemplate = React.useCallback(
    (toAppend: string) => {
      const { selection } = editorConfig
      const field = editorConfig.field === 'pushTitle' ? 'pushTitle' : 'pushBody'
      const text = content.get('content', PushMessageRawFactory()).get(field)
      const computedText = text.substring(0, selection[0]) + toAppend + text.substring(selection[1])

      dispatch(
        updatePushMessageContent({
          messageId,
          lang,
          field,
          parent: 'content',
          value: computedText,
        })
      )
    },
    [dispatch, messageId, lang, content, editorConfig]
  )

  const closeTemplateWizard = React.useCallback(() => {
    dispatch(setInlineEditor(editorConfig.set('personalizationModalIsOpen', false)))
  }, [dispatch, editorConfig])

  const setEditing = React.useCallback(
    (editing: MessageBuilderEditableField, caret: number) => {
      const newConfig = inlineEditorConfig
        .set('field', editing)
        .set('variant', 'a')
        .set('selection', [caret, caret])

      dispatch(setInlineEditor(newConfig))
    },
    [inlineEditorConfig, dispatch]
  )

  // La notif est scrollée pour être affichée en entier (e.g: si image ou long texte)
  // Trigger: Upload d'image, changement de plateforme, appui bouton expand
  const previewContainer = React.useRef<HTMLDivElement>(null)
  React.useLayoutEffect(() => {
    if (previewContainer.current) {
      previewContainer.current.scrollTop = previewContainer.current.scrollHeight
    }
  }, [previewPlatform, expandedToggle.value])

  const imageRecommandation = React.useMemo(
    () =>
      inlineEditorConfig.field === 'icon'
        ? {
            message: 'Image must be square and at least 192px wide',
            docLinkText: 'Learn more',
            docLinkUrl:
              'https://doc.batch.com/android/advanced/customizing-notifications#setting-up-custom-push-icons',
          }
        : {
            message:
              previewPlatform === 'android'
                ? 'Landscape image. Width must be at least 300px, height at least 200px.'
                : 'Width must be at least 300px, height at least 200px.',
            docLinkText: 'Learn more',
            docLinkUrl:
              'https://help.batch.com/en/articles/3993058-what-is-the-best-image-size-for-my-push-notification',
          },
    [inlineEditorConfig.field, previewPlatform]
  )

  React.useEffect(() => {
    dispatch(updatePushTemplate({ messageId, lang }))
  }, [dispatch, messageId, lang])

  return (
    <React.Fragment>
      <BoxBody style={{ height: '100%', borderRadius: 0, boxSizing: 'content-box' }}>
        <VariantContainer
          {...getUploaderRootProps()}
          $channel="push"
          $form={campaignFormMode ? 'campaign' : 'orchestration'}
        >
          {uploaderInputField}
          <TemplateWizard
            appendText={updateTemplate}
            attributes={attributes}
            close={closeTemplateWizard}
            contextEventId={contextEventId}
            opened={editorConfig.personalizationModalIsOpen}
          />
          <Popin close={mediaPopinState.close} opened={mediaPopinState.value}>
            <MediaUrlPopin
              allowedMedia={['image']}
              close={mediaPopinState.close}
              onChange={onMediaUrlChanged}
              platforms={apps.map(app => app.platform).toArray()}
              showTemplateWizard={apps.first()?.features?.has('macro') ?? false}
              imageRecommandation={imageRecommandation}
            />
          </Popin>
          <React.StrictMode>
            <ThemeProvider theme={previewTheme}>
              <InlineContainer style={{ display: 'flex', justifyContent: 'center' }}>
                <StickyInfoPanelContainer id={'js-variant-a'} />

                <PushLayerBar
                  stepMessageNodeId={stepMessageNodeId}
                  hasIcon={hasIcon}
                  showIcon={config.platforms.has('webpush') || config.platforms.has('android')}
                  hasMedia={hasMedia}
                  setEditing={setEditing}
                  openFileUploader={openUploader}
                  openMediaPopin={mediaPopinState.open}
                />

                <PreviewMockup />
                <PreviewContainer ref={previewContainer}>
                  <Preview messageId={messageId} previewPlatform={previewPlatform} variant="a" />
                </PreviewContainer>
              </InlineContainer>
              <PushBuilderToolbar
                availablePreviewPlatforms={availablePreviewPlatforms}
                previewPlatform={previewPlatform}
                isExpanded={expandedToggle.value}
                setPreviewPlatform={setPreviewPlatform}
                toggleIsExpanded={expandedToggle.toggle}
              />
            </ThemeProvider>
          </React.StrictMode>
        </VariantContainer>
      </BoxBody>

      <BoxFooter style={{ padding: '19px 24px 18px', height: 'auto', position: 'relative' }}>
        <PushDeeplinkField stepMessageNodeId={stepMessageNodeId} content={content} lang={lang} />
      </BoxFooter>
    </React.Fragment>
  )
}
export const PushBuilder: React.ComponentType<PushBuilderProps> = React.memo(PushBuilderRaw)
PushBuilder.displayName = 'PushBuilder'
