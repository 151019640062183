import styled from 'styled-components'

import { colors } from 'components/styled/tokens'
import * as schemes from 'components/styled/tokens/schemes'

export const TimelineContent = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 12px;
    background: rgb(225, 225, 225);
    width: 1.5px;
    top: 0;
    right: calc(50% - 0.5px);
  }

  &::after {
    content: '';
    position: absolute;
    background: rgb(225, 225, 225);
    width: 1.5px;
    top: 24px;
    bottom: 0;
    right: calc(50% - 0.5px);
  }

  svg {
    margin-top: 10.5px;
  }
`

export const TimeContent = styled.div`
  color: ${colors.textLight};
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  min-width: 48px;

  &:hover {
    color: ${colors.textNeutral};
  }
`

export const TimeTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const TimeTooltipContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    text-align: left;
  }
`

export const TimeLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${colors.textContrast};
`
export const TimeLegend = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${schemes.lightlucent['60']};
`
export const TimeValue = styled.div`
  font-weight: 300;
  color: ${colors.textContrast};
  font-size: 20px;
  letter-spacing: -0.2px;
  text-align: right;
`
