import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'
import styled from 'styled-components'

import { Tooltip } from 'components/common/tooltip'
import { colors, schemes } from 'components/styled/tokens'
import { HorizontalDivider } from 'components/styled/utils'

import { kformat } from 'com.batch.common/utils'

import { useEstimateProfileChannelTooltipHelper } from './estimate-profile-channel-tooltip.helper'

import { currentProjectPlatformsSelector } from 'com.batch.redux/app'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import { type FilterPushTokensType } from 'com.batch/message/models/message.records'
import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'

type Props = {
  estimate: EstimateProfileRecord
  channel: ChannelUntilCleanup | null | undefined
  children: React.ReactElement
  forceNonOptin?: boolean
  filterPushTokens?: FilterPushTokensType
}

export const EstimateProfileChannelToolip = ({
  estimate,
  channel,
  children,
  forceNonOptin,
  filterPushTokens,
}: Props): React.ReactElement => {
  const subscriptionStatus = useSelector(subscriptionStatusSelector)
  const pushPlatforms = useSelector(currentProjectPlatformsSelector)
  const { steps, pushPlatformSteps } = useEstimateProfileChannelTooltipHelper({
    estimate,
    subscriptionStatus,
    filterPushTokens: filterPushTokens ?? 'all',
    forceNonOptin: Boolean(forceNonOptin),
    pushPlatforms,
    channel,
  })

  return (
    <Tooltip
      placement={!channel ? 'top' : 'right'}
      minWidth={300}
      offset={[0, 10]}
      tooltip={
        <React.Fragment>
          <StyledSteps>
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <span className="legend">{step.label}</span>
                <span className="count">{kformat(step.count)}</span>
                <span className="bar" style={{ width: `${step.ratio * 100}%` }} />
              </React.Fragment>
            ))}
          </StyledSteps>

          {channel === 'push' && (
            <React.Fragment>
              <HorizontalDivider
                color={colors.strokeContrast}
                style={{ width: '100%', marginTop: '8px', marginBottom: '8px' }}
              />
              <StyledSteps>
                {pushPlatformSteps.map((step, index) => (
                  <React.Fragment key={index}>
                    <span className="legend">{step.label}</span>
                    <span className="count">{kformat(step.count)}</span>
                    <span className="bar" style={{ width: `${step.ratio * 100}%` }} />
                  </React.Fragment>
                ))}
              </StyledSteps>
            </React.Fragment>
          )}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  )
}

const StyledSteps = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 170px 40px 1fr;
  grid-auto-rows: 18px;
  align-items: center;
  grid-gap: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  .legend {
    color: ${colors.textContrast};
    font-weight: 400;
    text-align: left;
  }
  .count {
    color: ${colors.textContrast};
    font-weight: 500;
    text-align: left;
  }
  .bar {
    text-align: left;
    display: block;
    border-radius: 9px;
    height: 12px;
    background: ${schemes.lightlucent['30']};
  }
`
