import { createSelector } from 'reselect'
import { type Map } from 'immutable'
import { type OrchestrationStateRecord } from './orchestration.state'

import {
  MessageConfigFactory,
  type MessageConfigRecord,
} from '../../message/models/message.records'
import { type State } from 'com.batch.redux/_records'

import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'
import { type MessageNodeRecord } from 'com.batch/orchestration-journey/models/journey.records'

type Extract<T> = (arg1: State) => T

const orchestrationCampaignSelector: Extract<OrchestrationCampaignRecord> = state =>
  state.orchestration.campaign
const orchestrationStateSelector: Extract<OrchestrationStateRecord> = state => state.orchestration
export const campaignMessageConfigSelector: Extract<MessageConfigRecord> = createSelector(
  orchestrationCampaignSelector,
  campaign => campaign.messageConfig
)
export const messageNodesSelector: Extract<Map<string, MessageNodeRecord>> = createSelector(
  orchestrationStateSelector,
  (orchestration: OrchestrationStateRecord) =>
    // $FlowIgnore Notre version de flow ne supporte pas les TypeGuards `(node: JourneyNodeRecord): node is MessageNodeRecord =>`
    orchestration.triggerNodes.filter(node => node.type === 'MESSAGE')
)

export const getMessageConfigSelector: Extract<
  (arg1: { stepMessageNodeId: string | null | undefined }) => MessageConfigRecord
> = createSelector(campaignMessageConfigSelector, messageNodesSelector, (messaageConfig, nodes) => {
  return ({ stepMessageNodeId }) => {
    if (stepMessageNodeId) {
      const node = nodes.get(stepMessageNodeId)
      if (!node) {
        /* @TODO : c'est probablement mieux de throw ici, car si ça se produit en théorie notre 
            journey est corrompue. Sauf qu'actuellement, on conserve la ref du dernier message
            ouvert pour gérer l'animation de fermeture des sidesheets, ce qui fait qu'on balançait
            des exceptions à la moindre suppression du dernier message node ouvert

            il faudrait clean journey-canvas-form pour ne stocker que le minimum utile à rendre l'anim ok

        */
        console.log('Node not found in getMessageConfigSelector', stepMessageNodeId)
        return MessageConfigFactory()
      }
      return node.messageConfig
    }
    return messaageConfig
  }
})
