import styled from 'styled-components'

type Placement = 'top' | 'bottom' | 'left' | 'right'
type TooltipContainerProps = {
  toggle?: (arg1?: any) => any
  placement: Placement
}
export const TooltipContainer = styled.div<TooltipContainerProps>`
  z-index: 9999;
  opacity: 0;
  ${props => (!props.toggle ? 'pointer-events: none;' : '')}
  transition: opacity ease-in 0.1s;
  ${props => {
    switch (props.placement) {
      case 'top':
        return 'margin-top:-3px; padding:5px 0;'
      case 'right':
        return 'margin-left:3px; padding:0 5px;'
      case 'bottom':
        return 'margin-top:3px; padding:5px 0;'
      case 'left':
        return 'margin-left:-3px; padding:0 5px;'
    }
  }};
`
type TooltipInnerProps = {
  inline?: boolean
  noPadding?: boolean
}
export const TooltipInner = styled.div<TooltipInnerProps>`
  font-size: 12px;
  min-width: 120px;
  min-width: ${props => (props.inline ? 0 : 120)}px;
  max-width: 250px;
  padding: ${props => (props.noPadding ? '0' : '8px 12px 9px 12px')};
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: RGBA(34, 44, 59, 0.9);
  border-radius: 3px;
  hr {
    margin: 5px 0;
    opacity: 0.4;
  }
`
type TooltipArrowProps = {
  placement?: Placement
}
export const TooltipArrow = styled.div<TooltipArrowProps>`
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  ${props => {
    switch (props.placement) {
      case 'top':
        return `bottom: 1px;
          left: 50%;
          margin-left: -5px;
          border-width: 5px 5px 0;
          border-top-color: RGBA(34, 44, 59, 0.9)`
      case 'right':
        return `top: 50%;
          left: 0;
          margin-top: -5px;
          border-width: 5px 5px 5px 0;
          border-right-color: RGBA(34, 44, 59, 0.9);`
      case 'bottom':
        return `top: 0;
          left: 50%;
          margin-left: -5px;
          border-width: 0 5px 5px;
          border-bottom-color: RGBA(34, 44, 59, 0.9);`
      case 'left':
        return `top: 50%;
          right: 0;
          margin-top: -5px;
          border-width: 5px 0 5px 5px;
          border-left-color: RGBA(34, 44, 59, 0.9);
          `
    }
  }};
`
