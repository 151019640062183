import { type List } from 'immutable'
import * as React from 'react'

import { Button, ButtonNavLink } from 'components/common/button'
import { Icon } from 'components/common/svg-icon'

import { generateUrl } from 'com.batch.common/router'

import { type AppRecord, type AttributeCategory } from 'com.batch.redux/_records'

type CategoryFilterProps = {
  app: AppRecord
  categories: List<AttributeCategory>
  updateCat: (arg1: AttributeCategory['id']) => void
}

export const CategoryFilter = ({
  app,
  categories,
  updateCat,
}: CategoryFilterProps): React.ReactElement => {
  return (
    <ul className="nav nav-pills nav-stacked">
      {categories.map((cat, indice) => {
        const classes = ['uib-tab', 'nav-item']
        if (cat.active) classes.push('active')
        return (
          <li className={classes.join(' ')} key={indice}>
            {cat.locked ? (
              <div className="nav-link nav-link__header">
                <span>
                  <Icon icon={cat.icon} size={12} style={{ margin: '0 14px 0 0' }} />
                  {cat.label} {cat.count > 0 && `(${cat.count})`}
                </span>
                {cat.id && (
                  <ButtonNavLink
                    kind="primary"
                    intent="action"
                    to={generateUrl('company_billing', { companyId: app.companyId })}
                    style={{ width: '100%' }}
                  >
                    Upgrade
                  </ButtonNavLink>
                )}
              </div>
            ) : (
              <Button
                kind="discreet"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => updateCat(cat.id)}
                style={{ justifyContent: 'flex-start', height: 48, width: '100%', fontSize: 14 }}
              >
                <Icon icon={cat.icon} size={12} style={{ margin: '0 14px 0 0' }} />
                {cat.label} {cat.count > 0 && `(${cat.count})`}
              </Button>
            )}
          </li>
        )
      })}
    </ul>
  )
}
