import styled, { css } from 'styled-components'

import { Button } from 'components/common/button'
import { colors } from 'components/styled/tokens'

type props = {
  $isVariant?: boolean
}
export const TopToolbarContainer = styled.div<props>`
  --margin-inline: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  line-height: 36px;
  background: ${colors.fillDepth};
  gap: 8px;
  margin: 0 0px;
  padding: 8px 20px;
  ${Button} {
    color: ${colors.textNeutral};
    padding-block: 0;
    &.styled-winner,
    &.styled-winner:active,
    &.active.styled-winner {
      color: ${colors.textSuccess}!important;
    }
  }
  ${p =>
    p.$isVariant
      ? css`
          ${Button}:has(i):not(.styled-tool-button, .styled-winner) {
            padding-right: 0;
          }
        `
      : css`
          /* Les boutons avec icon de suppression pas de padding right, le last button = add lang button */
          > ${Button}:has(i):not(:last-child) {
            padding-right: 0;
          }
        `}
`

export const DeleteLangButton = styled.span`
  width: 23px;
  height: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  color: ${colors.textLight};
  justify-content: flex-start;

  &:hover {
    i {
      color: ${colors.text};
    }
  }
`
