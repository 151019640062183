import { dayjs } from 'com.batch.common/dayjs.custom'

import { generateUrl } from '../../../common/router/router'
import { type SegmentRecord } from '../models/segment.records'
import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { showToast } from 'com.batch.redux/toaster'

import { type ProfileAttributeDefinition } from 'com.batch/shared/infra/export-service/export-service'

const PROFILE_LIMIT = 1000000

export const getSegmentsExportFileUrl = ({
  segment,
}: {
  segment: SegmentRecord
}): DispatchExtraBoundFn<Promise<string>> => {
  return async (dispatch, getState, { exportService }) => {
    const state = getState()
    const project = currentProjectSelector(state)
    try {
      const definition: ProfileAttributeDefinition = {
        attributes: ['$phone_number', '$email_address'],
        identifiers: ['profile_id', 'custom_id'],
        jsonQuery: segment.query,
        limit: PROFILE_LIMIT,
      }
      const id = await exportService.create({
        projectKey: project.projectKey,
        source: 'DASHBOARD',
        definition: {
          scope: 'PROFILE_ATTRIBUTES',
          profileAttribute: definition,
        },
      })

      const request = await exportService.fetchRequestUntilReady(id, project.projectKey)

      const fileId = request?.files?.[0]?.id
      if (!fileId) {
        throw new Error('No file found in export request')
      }

      const currentDate = dayjs.utc().format('YYYY-MM-DD')

      return generateUrl('api_export_download', {
        projectKey: project.projectKey,
        fileId: encodeURIComponent(fileId.data),
        filename: `${currentDate}-${segment.name}-segment-export.csv`,
      })
    } catch (error: any) {
      dispatch(
        showToast({
          kind: 'error',
          message:
            error.message === 'timeout'
              ? 'Export request timed out'
              : 'Unknown error during export request',
        })
      )
      throw error
    }
  }
}
