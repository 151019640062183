import { request } from 'com.batch.common/request'
import { generateUrl } from 'com.batch.common/router'

export const getStripoAuthToken = (callback: (data?: any) => void): Promise<void> => {
  return request.get(generateUrl('api_stripo_token')).then(
    response => {
      callback(response)
    },
    error => {
      console.error(error)
    }
  )
}
