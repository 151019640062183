import Immutable, { type List, type OrderedSet, type Set } from 'immutable'
import * as React from 'react'

import { Avatar } from 'components/common/avatar'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  BoxSection,
  HeaderBoxActions,
  HeaderBoxTitle,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import Hint from 'components/common/hint'
import { trackEvent, TrackingContext } from 'components/common/page-tracker'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Checkbox, Form, Input, InputWrapper } from 'components/form'

import { isEmail } from 'com.batch.common/utils'

import { Permissions } from './permissions'
import {
  RestrictionAppName,
  RestrictionsApp,
  RestrictionsEmpty,
  RestrictionsHeader,
  RestrictionsLabel,
  RestrictionsSelect,
} from './user-form.styles'
import { getAllowedAppsOnCompanyForUser, getPermissionsForUserAndCompany } from './utils'

import { type AppRecord, type CompanyRecord } from 'com.batch.redux/_records'
import {
  CompanyUserPermissionsFactory,
  type groupOnlyPermissionType,
  type UserRecord,
} from 'com.batch.redux/user.records'

import { useConfirmWithMfa } from 'com.batch/shared/ui/hooks/use-confirm-with-mfa'

export type UserFormProps = {
  user: UserRecord
  apps: List<AppRecord>
  appsLoading: boolean
  company: CompanyRecord
  canUseAppRestriction: boolean
  onSave: (user: UserRecord) => Promise<void>
  close: () => void
}

export const UserForm = ({
  user,
  apps,
  company,
  onSave,
  close,
  canUseAppRestriction,
}: UserFormProps): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true)
  const confirm = useConfirmWithMfa()
  const [email, setEmail] = React.useState(user.email)
  const [emailError, setEmailError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const [permissions, setPermissions] = React.useState<OrderedSet<groupOnlyPermissionType>>(
    getPermissionsForUserAndCompany(user, company)
  )

  const [allowedApps, setAllowedApps] = React.useState<Set<number>>(
    getAllowedAppsOnCompanyForUser(company, user)
  )

  const [searchMode, setSearchMode] = React.useState(false)
  const [search, setSearch] = React.useState('')

  const { eventLocation } = React.useContext(TrackingContext)

  const isAdmin = permissions.has('group:administrate')

  const userHasAdminPermission = React.useMemo<boolean | null | undefined>(
    () => user.companiesPermissions.get(company.id)?.permissions.has('group:administrate'),
    [company.id, user.companiesPermissions]
  )

  const allowedCount = isAdmin ? apps.size : allowedApps.size
  const toggleMode: 'all' | 'none' | 'some' =
    allowedCount === apps.size ? 'all' : allowedApps.size === 0 ? 'none' : 'some'

  const appsFiltered = React.useMemo<List<AppRecord>>(
    () =>
      apps.filter(
        app => search === '' || app.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ),
    [apps, search]
  )

  const onBlurAppSearch = React.useCallback(() => {
    if (eventLocation !== 'unset' && search)
      trackEvent('SEARCH_APPS', { location: eventLocation, keywords: search })
  }, [eventLocation, search])

  const addOrUpdateUser = React.useCallback(() => {
    if (!isEmail(email)) {
      setEmailError('Please provide a valid email address.')
      setLoading(false)
    } else {
      setEmailError('')
      setLoading(true)

      // tracking -----------
      const initSet = getAllowedAppsOnCompanyForUser(company, user)
      const allowedSet = isAdmin ? Immutable.Set(apps.map(app => app.id)) : allowedApps
      const added = allowedSet.subtract(initSet)
      const removed = initSet.subtract(allowedSet)
      const location = 'team'
      if (user.id) {
        added.forEach(app_id => {
          trackEvent('APP_ADD_USER', { location, user_id: user.id, app_id })
        })
        removed.forEach(app_id => {
          trackEvent('APP_REMOVE_USER', { location, user_id: user.id, app_id })
        })
      }

      // end tracking -------

      onSave(
        user.set('email', email).set(
          'companiesPermissions',
          user.companiesPermissions.set(
            company.id,
            CompanyUserPermissionsFactory({
              permissions,
              apps: allowedSet,
            })
          )
        )
      ).then(close, ({ errors }) => {
        errors.forEach(err => {
          if (err.field === 'email') {
            setEmailError(err.message)
          }
        })
        setLoading(false)
      })
    }
  }, [allowedApps, apps, close, company, email, isAdmin, onSave, permissions, user])

  const handleOnAllowedAppsChange = React.useCallback(() => {
    setAllowedApps(toggleMode !== 'none' ? Immutable.Set() : Immutable.Set(apps.map(app => app.id)))
  }, [apps, toggleMode])

  const handleOnEmailChange = React.useCallback(e => setEmail(e.target.value), [])

  const handleOnKeyPressSearchApp = React.useCallback(evt => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
    }
  }, [])

  const handleOnChangeSearchApp = React.useCallback(evt => setSearch(evt.target.value), [])

  const handleOnClickSearch = React.useCallback(() => setSearchMode(true), [])

  const handleOnChangeAllowedApps = React.useCallback(
    app => () => {
      setAllowedApps(allowedApps.has(app.id) ? allowedApps.remove(app.id) : allowedApps.add(app.id))
    },
    [allowedApps]
  )

  const handleOnSubmit = React.useCallback(() => {
    if (loading) return
    setLoading(true)
    if (isAdmin || userHasAdminPermission) {
      confirm({
        title: user.id ? 'Update an admin user' : 'Invite a new admin user',
        message: (
          <React.Fragment>
            <p>
              You’re about to invite <ConfirmHighlight>{email}</ConfirmHighlight> with admin
              permissions
            </p>
            <p>
              This means they will be able to invite new users, create and delete apps, manage
              billing or security settings.
            </p>
          </React.Fragment>
        ),
        confirm: user.id ? 'Yes, update' : 'Yes, invite',
        sensitive: true,
        onModalShown: () => {
          setIsOpen(false)
        },
      })
        .then(addOrUpdateUser, () => {
          setLoading(false)
        })
        .finally(() => {
          setIsOpen(true)
        })
    } else {
      addOrUpdateUser()
    }
  }, [confirm, email, isAdmin, loading, addOrUpdateUser, user.id, userHasAdminPermission])

  // update effects on user change
  React.useEffect(() => {
    setPermissions(getPermissionsForUserAndCompany(user, company))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.companiesPermissions])

  return (
    <Popin opened={isOpen} close={close} style={{ overflow: 'hidden' }}>
      <Form onSubmit={handleOnSubmit}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: 550,
          }}
        >
          <BoxHeader>
            <HeaderBoxTitle title={user.id ? 'Edit a user' : 'Invite a new user'} />
            <HeaderBoxActions>
              <Button kind="inline" intent="neutral" onClick={close} type="button">
                <Icon icon="close" />
              </Button>
            </HeaderBoxActions>
          </BoxHeader>
          <BoxBody
            style={{
              display: 'flex',
              flex: '1 1 auto',
              overflow: 'hidden',
              height: 'calc(100vh - 240px)',
              maxHeight: 520,
            }}
          >
            <BoxSection style={{ flex: '1 1 auto', padding: '20px', overflow: 'auto' }}>
              <InputWrapper
                label="This user ..."
                htmlFor="email"
                feedback={emailError}
                style={{ marginBottom: 28 }}
              >
                <Input
                  invalid={!!emailError}
                  value={email}
                  disabled={!!user.id}
                  placeholder="Email address"
                  onChange={handleOnEmailChange}
                />
              </InputWrapper>
              <Permissions
                errorAbove={!!emailError}
                permissions={permissions}
                setPermissions={setPermissions}
                hasEditorialDashboard={company.hasEditorialDashboard}
              />
            </BoxSection>
            <BoxSection
              style={{ flex: '0 1 380px', width: 380, background: '#fdfdfd', overflowY: 'auto' }}
            >
              <RestrictionsHeader>
                {searchMode ? (
                  <Input
                    autoFocus
                    value={search}
                    onKeyPress={handleOnKeyPressSearchApp}
                    onChange={handleOnChangeSearchApp}
                    onBlur={onBlurAppSearch}
                    placeholder="Search for an app..."
                    suffix={{
                      kind: 'icon',
                      value: 'close',
                      handler: () => {
                        setSearchMode(false)
                        setSearch('')
                      },
                    }}
                    style={{
                      paddingLeft: 8,
                      paddingRight: 14,
                      background: 'transparent',
                      border: 'none',
                      boxShadow: 'none',
                    }}
                  />
                ) : (
                  <RestrictionsSelect>
                    <Checkbox
                      checked={
                        canUseAppRestriction
                          ? toggleMode === 'some'
                            ? undefined
                            : toggleMode === 'all'
                          : true
                      }
                      handleIndeterminate
                      disabled={isAdmin || !canUseAppRestriction}
                      onChange={handleOnAllowedAppsChange}
                      label={
                        canUseAppRestriction ? (
                          <RestrictionsLabel>
                            Has access to :&nbsp;
                            {toggleMode === 'some' ? (
                              <span>
                                {allowedCount} app{allowedApps.size > 1 && 's'} selected
                                <Hint>Grant or revoke access to specific apps</Hint>
                              </span>
                            ) : toggleMode === 'none' ? (
                              <span>
                                No app selected <Hint>Grant or revoke access to specific apps</Hint>
                              </span>
                            ) : (
                              <span>
                                All apps selected ({allowedCount})
                                <Hint>
                                  {isAdmin
                                    ? 'Users with administrate permissions can access all apps'
                                    : 'Grant or revoke access to specific apps'}
                                </Hint>
                              </span>
                            )}
                          </RestrictionsLabel>
                        ) : (
                          <Tooltip
                            placement="bottom"
                            tooltip="You cannot limit access to specific apps with your current plan. Please contact your sales representative."
                          >
                            <RestrictionsLabel>
                              Has access to :&nbsp;{' '}
                              <span>
                                All apps selected ({apps.size})&nbsp;&nbsp;&nbsp;
                                <Icon icon="lock" size={10} />
                              </span>
                            </RestrictionsLabel>
                          </Tooltip>
                        )
                      }
                      style={{ flex: '1 1 auto' }}
                    />
                    <Button
                      style={{
                        height: 28,
                        width: 27,
                      }}
                      kind="inline"
                      type="button"
                      onClick={handleOnClickSearch}
                    >
                      <Icon size={10} icon="search" />
                    </Button>
                  </RestrictionsSelect>
                )}
              </RestrictionsHeader>
              {apps.size > 0 && appsFiltered.size <= 0 ? (
                <RestrictionsEmpty>No apps matching your search</RestrictionsEmpty>
              ) : (
                appsFiltered.map(app => (
                  <RestrictionsApp key={app.id}>
                    <Checkbox
                      checked={isAdmin || allowedApps.has(app.id) || !canUseAppRestriction}
                      disabled={isAdmin || !canUseAppRestriction}
                      onChange={handleOnChangeAllowedApps(app)}
                      label={
                        <React.Fragment>
                          <Avatar
                            size={24}
                            url={app.icon}
                            placeholder={app.name.split(' ')[0]}
                            platform={app.platform}
                            style={{ flex: '0 0 auto', margin: '0 10px 0 2px' }}
                          />
                          <RestrictionAppName>{app.name}</RestrictionAppName>
                        </React.Fragment>
                      }
                    />
                  </RestrictionsApp>
                ))
              )}
            </BoxSection>
          </BoxBody>
          <BoxFooter>
            <Button
              type="submit"
              kind="primary"
              intent="action"
              isLoading={loading}
              disabled={!email}
            >
              {!user.id ? 'Invite user' : 'Update user'}
            </Button>
          </BoxFooter>
        </Box>
      </Form>
    </Popin>
  )
}
