import { type Dayjs } from 'dayjs'
import Immutable, { type RecordOf, type Set } from 'immutable'

import { dayjs } from 'com.batch.common/dayjs.custom'

import {
  type pushCampaignSendType,
  type TriggerConfigRecord,
  type fetchingState,
} from 'com.batch.redux/_records'

type oneTypeProps = {
  sendDate: Dayjs
  localTimezoneDependant: boolean
}
export const OneTimeFactory = Immutable.Record<oneTypeProps>({
  sendDate: dayjs(),
  localTimezoneDependant: false,
} as oneTypeProps)

export type OneTimeRecord = RecordOf<oneTypeProps>

// recurrent

type recurrentProps = {
  start: Dayjs | null | undefined
  end: Dayjs | null | undefined
  localTimezoneDependant: boolean
  repeatUnit: repeatUnitType
  repeatFrequency: number
}

export const RecurrentFactory = Immutable.Record<recurrentProps>({
  start: null,
  end: null,
  localTimezoneDependant: false,
  repeatFrequency: 0,
  repeatUnit: 'DAILY',
} as recurrentProps)

export type RecurrentRecord = RecordOf<recurrentProps>

type PartialOrchestrationProps = {
  token: string
  incomplete: boolean
  createdByApi: boolean
  loadingState: fetchingState
  triggerConfig: TriggerConfigRecord | null | undefined
  oneTime: OneTimeRecord | null | undefined
  recurring: RecurrentRecord | null | undefined
  channels: Set<ChannelUntilCleanup>
  routingMode: 'campaigns' | 'automations'
  name: string
  state: campaignStateType
  sendType: pushCampaignSendType
}

export const PartialOrchestrationFactory = Immutable.Record<PartialOrchestrationProps>({
  token: '',
  incomplete: false,
  createdByApi: false,
  channels: Immutable.Set([]),
  loadingState: 'LOADED',
  triggerConfig: null,
  oneTime: null,
  recurring: null,
  routingMode: 'campaigns',
  name: '',
  state: 'NEW',
  sendType: 'now',
} as PartialOrchestrationProps)
export type PartialOrchestrationRecord = RecordOf<PartialOrchestrationProps>
