import styled from 'styled-components'

import { colors } from 'components/styled/tokens'

export type AlertKind = 'warning' | 'error' | 'info' | 'success' | 'feature'

const ALERT_THEMES: { [key in AlertKind]: { background: string; color: string } } = {
  warning: {
    background: colors.opacifyFeature,
    color: colors.textFeature,
  },
  error: {
    background: colors.opacifyFeature,
    color: colors.textFeature,
  },
  info: {
    background: colors.opacifyFeature,
    color: colors.textFeature,
  },
  success: {
    background: colors.opacifyFeature,
    color: colors.textFeature,
  },
  feature: {
    background: colors.opacifyFeature,
    color: colors.textFeature,
  },
}

type AlertWrapperProps = {
  $kind: AlertKind
}
export const AlertWrapper = styled.div<AlertWrapperProps>`
  display: flex;
  padding: 8px 24px 8px 18px;
  border-radius: 7px;
  color: ${p => ALERT_THEMES[p.$kind].color};
  background-color: ${p => ALERT_THEMES[p.$kind].background};
  gap: 10px;

  > div {
    text-wrap: pretty;
  }
`
