import styled from 'styled-components'

import { FlexLine } from 'components/common/flexline'

export const MetricsFlexLine = styled(FlexLine)`
  position: relative;
  padding: 30px 0 0 0;

  &:before {
    top: -2px;
    display: block;
    position: absolute;
    left: 0;
    right: -4px;
    height: 34px;
    content: '';
    background-color: #fcfcfd;
    border-bottom: 1px solid #f6f6fa;
    box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.01);
  }
`

export const MetricsColumn = styled(FlexLine)`
  flex-direction: column;
  align-items: flex-start;
  padding: 17px 14px 25px 28px;
  width: 100%;
  border-bottom: 1px solid #f2f3f8;
  border-radius: 0;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.04);

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 0 5px;
  }
`

export const ChartTitle = styled.h4`
  width: calc(100% + 14px);
  margin: 0 0 10px -14px;
  font-size: 12px;
  color: #707782;
  text-transform: uppercase;
`

export const ChartMetric = styled.div`
  margin: 0 0 0 -1px;
  font-size: 26px;
  font-weight: 300;
  color: #393b41;
  line-height: 30px;
`

export const ChartAverage = styled.div`
  margin: 5px 0 6px 0;
  font-size: 14px;
  color: #acacac;

  strong {
    font-weight: 500;
    color: #5d606a;
  }
`
