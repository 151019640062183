import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import {
  Box,
  BoxBody,
  BoxHeader,
  HeaderBoxActions,
  BoxFooter,
  FooterBoxActions,
} from 'components/common/box'
import { HeaderBoxTitle } from 'components/common/box/box'
import { Button } from 'components/common/button/button.styles'
import { Loader } from 'components/common/loader/loader'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Input, InputWrapper } from 'components/form'
import { QueryBuilder } from 'components/query/query-builder-targeting'
import { colors } from 'components/styled/tokens'

import { generateUrl } from 'com.batch.common/router/router'

import { QueryBuilderWrapper } from './segment.style'

import { SegmentFactory, type SegmentRecord } from '../../models/segment.records'
import { allSegmentsSelector, segmentLoadingStateSelector } from '../../store/segments.selector'
import { getSegmentsByName } from '../../usecases/get-segments-by-name'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { resetQuery } from 'com.batch.redux/query/query'
import {
  getAPIQueryForIdSelector,
  getIsQueryPresentSelector,
} from 'com.batch.redux/query/query.selector'
import { estimateProfileGetterSelector } from 'com.batch.redux/target/target.selector'
import { showToast } from 'com.batch.redux/toaster'

import { EstimateProfile } from 'com.batch/orchestration/ui/components/estimate-profile'
import { saveSegment } from 'com.batch/segments/usecases/save-segment'
import { updateSegment } from 'com.batch/segments/usecases/update-segment'

const fakeErrors = Immutable.Set<string>()

// format string to replace space by dash and capitalize and block special characters except - and _
const formatAndCleanCode = (str: string): string => {
  return str
    .replace(/ /g, '-')
    .replace(/[^a-zA-Z0-9-_ ]/g, '')
    .toUpperCase()
    .substring(0, 240)
}

export const SegmentPopin = (): React.ReactElement => {
  const { segmentName } = useParams()

  const dispatch = useDispatch()
  const project = useSelector(currentProjectSelector)
  const segments = useSelector(allSegmentsSelector)
  const { mutationLoadingState } = useSelector(segmentLoadingStateSelector)
  const editedSegment: SegmentRecord = React.useMemo(() => {
    return segments.get(segmentName ?? '', SegmentFactory())
  }, [segmentName, segments])

  // load the segment if we don't have it in redux
  React.useEffect(() => {
    if (!editedSegment.name && segmentName) dispatch(getSegmentsByName([segmentName]))
  }, [dispatch, editedSegment.name, segmentName])

  // local state for edition
  const [name, setName] = React.useState(editedSegment.name)
  const [displayName, setDisplayName] = React.useState(editedSegment.displayName)

  const getAPIQueryForId = useSelector(getAPIQueryForIdSelector)
  const getIsQueryPresent = useSelector(getIsQueryPresentSelector)
  const queryId = React.useMemo(
    () => (segmentName ? 'segment-' + segmentName : 'segment-new'),
    [segmentName]
  )
  const estimateGetter = useSelector(estimateProfileGetterSelector)
  const estimate = React.useMemo(() => estimateGetter(queryId), [estimateGetter, queryId])

  const isEditing = React.useMemo(() => segmentName !== 'new', [segmentName])
  const isDeleted = React.useMemo(() => Boolean(editedSegment.deletedAt), [editedSegment])

  const query = React.useMemo(() => getAPIQueryForId(queryId), [getAPIQueryForId, queryId])
  const onDisplayNameChange = React.useCallback(evt => setDisplayName(evt.target.value), [])
  const onCodeChange = React.useCallback(evt => setName(formatAndCleanCode(evt.target.value)), [])
  const needSave = React.useMemo(() => {
    return (
      editedSegment.name !== name ||
      editedSegment.displayName !== displayName ||
      editedSegment.query !== (query ? JSON.stringify(query, null, 2) : '')
    )
  }, [editedSegment, name, displayName, query])
  React.useEffect(() => {
    if (editedSegment.query)
      dispatch({
        type: 'T_NEW_QUERY',
        payload: {
          query: editedSegment.query,
          eventId: '',
          queryId: 'segment-' + (segmentName ?? ''),
          retries: 0,
        },
      })
  }, [dispatch, editedSegment.query, segmentName])

  const baseUrl = React.useMemo(
    () =>
      generateUrl('project_profiles', {
        activeTab: 'segments',
        companyId: project.companyId,
        projectId: project.id,
      }),
    [project]
  )

  const navigate = useNavigate()
  const navigateToRootUrl = React.useCallback(() => {
    navigate(baseUrl)
  }, [navigate, baseUrl])
  const onPopinClose = React.useCallback(() => {
    navigateToRootUrl()
    dispatch(resetQuery({ queryId: 'segment-new' }))
  }, [dispatch, navigateToRootUrl])

  const isFetching = React.useMemo(
    () =>
      segmentName !== 'new' &&
      (!editedSegment.name || !getIsQueryPresent('segment-' + segmentName)),

    [editedSegment.name, getIsQueryPresent, segmentName]
  )
  const onSave = React.useCallback(async () => {
    try {
      const generateCodeBasedOnDisplayName = !name
      const updated = editedSegment
        .set('query', JSON.stringify(query, null, 2) ?? '')
        .set('displayName', displayName)
      if (segmentName === 'new') {
        await dispatch(
          saveSegment(
            updated.set(
              'name',
              formatAndCleanCode(generateCodeBasedOnDisplayName ? displayName : name)
            ),
            generateCodeBasedOnDisplayName
          )
        )
      } else {
        await dispatch(updateSegment(updated))
      }
      onPopinClose()
    } catch (err: any) {
      console.log(err)
    }
  }, [editedSegment, query, name, displayName, segmentName, dispatch, onPopinClose])
  const onCopySegmentName = React.useCallback(() => {
    navigator.clipboard.writeText(editedSegment.name)
    dispatch(showToast({ kind: 'success', message: 'Segment code copied to clipboard' }))
  }, [editedSegment.name, dispatch])
  return (
    <Popin
      opened={true}
      close={onPopinClose}
      style={{
        minWidth: '1080px',
        maxWidth: '1400px',
        height: 'auto',
        overflowX: 'hidden',
        margin: 0,
        padding: 0,
      }}
    >
      <Loader loading={isFetching} overlay>
        <Box>
          <ThemeProvider theme={{ isEmpty: false, isLoading: isFetching, disabledMode: isDeleted }}>
            <BoxHeader style={{ height: 56, gap: '4px' }} large>
              {isDeleted ? (
                <div style={{ display: 'flex' }}>
                  <Icon icon="delete" style={{ marginRight: '8px' }} />
                  <HeaderBoxTitle title={editedSegment.displayName} />
                </div>
              ) : (
                <Input
                  value={displayName || editedSegment.displayName}
                  onChange={onDisplayNameChange}
                  placeholder="Segment name"
                  style={{ width: '100%' }}
                  forcedFocus={true}
                  maxLength={255}
                />
              )}
              <HeaderBoxActions large>
                {isEditing && (
                  <Tooltip
                    tooltip={
                      <React.Fragment>
                        Code: {editedSegment.name} <br />
                        Click to copy
                      </React.Fragment>
                    }
                    minWidth={300}
                  >
                    <Button onClick={onCopySegmentName} style={{ marginLeft: 4 }}>
                      <Icon icon="copy" />
                    </Button>
                  </Tooltip>
                )}
                <Button onClick={onPopinClose}>
                  <Icon icon="close" />
                </Button>
              </HeaderBoxActions>
            </BoxHeader>

            <BoxBody>
              {!isEditing && (
                <div style={{ padding: '20px 20px 20px 20px' }}>
                  <InputWrapper
                    label="Segment code"
                    hintMinSize={350}
                    hint={
                      <div style={{ textAlign: 'left' }}>
                        <p style={{ marginBottom: 6 }}>
                          Used to identify your segment, for <code>API usage</code>.<br />
                          Can't be changed afterwards.
                        </p>
                        <p>If you don't provide one, we will generate it for you.</p>
                      </div>
                    }
                  >
                    <Input
                      value={name || editedSegment.name}
                      onChange={onCodeChange}
                      placeholder="Add segment code (optional) ..."
                      maxLength={245}
                      disabled={isEditing}
                    />
                  </InputWrapper>
                </div>
              )}
              <div style={{ padding: '10px 20px 0 20px' }}>
                <InputWrapper label="Segmentation" />
              </div>
              <QueryBuilderWrapper>
                <QueryBuilder isProfileMode queryId={queryId} errors={fakeErrors} />
              </QueryBuilderWrapper>
              <EstimateProfile
                estimate={estimate}
                forceNonOptin
                style={{
                  borderTop: `1px solid ${colors.stroke}`,
                  height: 110,
                  marginTop: 0,
                  padding: '20px',
                }}
              />
            </BoxBody>
            {!isDeleted && (
              <BoxFooter isEditable>
                <Button onClick={onPopinClose} kind="inline">
                  Cancel
                </Button>

                <FooterBoxActions>
                  <Button
                    onClick={onSave}
                    kind="primary"
                    intent="action"
                    isLoading={mutationLoadingState === 'LOADING'}
                    disabled={!query || !displayName || !needSave}
                  >
                    Save segment
                  </Button>
                </FooterBoxActions>
              </BoxFooter>
            )}
          </ThemeProvider>
        </Box>
      </Loader>
    </Popin>
  )
}
