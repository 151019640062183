import * as React from 'react'

import { TimelineIcon } from '../icons'
import { TimelineContent } from '../timeline/timeline.styles'

export const Timeline = (): React.ReactElement => {
  return (
    <TimelineContent>
      <TimelineIcon />
    </TimelineContent>
  )
}
