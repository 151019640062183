import promiseDebounce from 'es6-promise-debounce'
import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'components/console/react-redux'
import request from 'superagent-interface-promise'

import { Avatar } from 'components/common/avatar'
import { Grid } from 'components/common/grid'
import { Tag } from 'components/common/tag'
import { FilterSelect } from 'components/filter'
import { Select } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

import { CompanyName, CompanyPlan, CompanyId } from '../console.style'
import { loadCompaniesSuccess, createCompany } from '../redux/company'
import { type State } from '../redux/console.records'
import { CompanyFactory, type CompanyRecord } from 'com.batch.redux/_records'
import { normalizeCompany } from 'com.batch.redux/company.api'

type CompanyPickerProps = {
  creatable?: boolean
  isClearable?: boolean
  placeholder?: string
  companyId: number | null | undefined
  setCompany: (c?: CompanyRecord | null | undefined) => void
}
const companiesSelector = (state: State) => state.company.entities
const optionCreator = (name: string) => CompanyFactory({ name })
export const CompanyPicker = ({
  companyId,
  setCompany,
  creatable = false,
  placeholder = 'Select a company',
  isClearable,
}: CompanyPickerProps): React.ReactElement => {
  const dispatch = useDispatch()
  const companies = useSelector(companiesSelector)
  const fetchCompanies = (inputValue: string) => {
    const inputIsNumber = `${parseInt(inputValue)}` === inputValue
    const queryParam: string = inputIsNumber ? 'companyIds' : 'search'
    const query = {
      [queryParam]: inputValue,
    }
    return request.get(generateUrl('console_api_companies', query)).then(({ body }) => {
      const companies = Immutable.List<CompanyRecord>(body.entities.map(normalizeCompany))
      dispatch(
        loadCompaniesSuccess({
          entities: Immutable.List(companies),
        })
      )
      return companies
    })
  }

  const onCompanyChanged = React.useCallback(
    (comp?: CompanyRecord) => {
      if (comp && !comp.id) {
        dispatch(createCompany(comp.name)).then(c => {
          setCompany(c)
        })
      } else {
        setCompany(comp)
      }
    },
    [dispatch, setCompany]
  )

  const company = companyId && companies.has(companyId) ? companies.get(companyId) : null

  const props = {
    loadOptions: promiseDebounce(fetchCompanies, 400),
    value: company,
    onChange: onCompanyChanged,
    style: { width: 400 },
    optionMenuHeight: 46,
    optionMenuShownCount: 7,
    optionMenuStyle: { height: 46 },
    menuOffset: 40,
    optionToString: (company?: CompanyRecord | null) => company?.name ?? '',
    isClearable,
    placeholder,
    optionFormatter: CompanyOption,
  }

  return creatable ? (
    <Select {...props} optionCreator={optionCreator} />
  ) : (
    <FilterSelect {...props} />
  )
}

const CompanyOption = (
  company: CompanyRecord,
  {
    context,
  }: {
    context: 'value' | 'menu'
  }
) => {
  return (
    <Grid
      template={context === 'menu' ? ' 32px 1fr 70px 50px 45px' : '1fr 70px 50px 45px'}
      style={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}
    >
      {context === 'menu' && (
        <Avatar
          size={28}
          url={company.avatarUrl}
          placeholder={company.name || ''}
          color={'#1c2e43'}
        />
      )}
      <CompanyName style={{ overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {company.name}
      </CompanyName>
      <CompanyPlan>{company.billing.plan.name}</CompanyPlan>
      <Tag>
        {company.usedSeats} / {company.seats}
      </Tag>
      <CompanyId>{company.id}&nbsp;</CompanyId>
    </Grid>
  )
}
