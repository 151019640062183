import { type List, type Set } from 'immutable'

import { legacyPromiseActionCreator, promiseActionCreator } from './actionCreator'

import {
  type AppRecord,
  type CappingCategoryRecord,
  type CappingRecord,
  type DispatchBoundFn,
  type DispatchOnlyBoundFn,
  type PushConfigRecord,
  type ReduxAction,
  type SafariWebsitesRecord,
  type SdkRecord,
} from 'com.batch.redux/_records'
import { FETCH_PROJECT_APPS } from 'com.batch.redux/action-name'
import * as appApi from 'com.batch.redux/app.api'
import { type ProjectRecord } from 'com.batch.redux/project.records'
import { type UserRecord } from 'com.batch.redux/user.records'

import { type SavePushSettingsSuccessAction } from 'com.batch/settings/usecases/save-push-settings'

type openIntegrateAction = {
  readonly type: 'POPIN_INTEGRATE_OPEN'
  readonly payload: null
}
type closeIntegrateAction = {
  readonly type: 'POPIN_INTEGRATE_CLOSE'
  readonly payload: null
}
type openNewAction = {
  readonly type: 'POPIN_NEW_OPEN'
  readonly payload: string
}
type closeNewAction = {
  readonly type: 'POPIN_NEW_CLOSE'
  readonly payload: null
}

type fetchAppAction = {
  readonly type: 'FETCH_APP_BY_ID'
  readonly payload: null
}
export type fetchAppSuccessAction = {
  readonly type: 'FETCH_APP_BY_ID_SUCCESS'
  readonly payload: {
    app: AppRecord
  }
}
export type fetchAppFailureAction = {
  readonly type: 'FETCH_APP_BY_ID_FAILURE'
  readonly payload: null
}

type fetchAppsAction = {
  readonly type: 'FETCH_APPS'
  readonly payload: null
}

type fetchAppsSuccessAction = {
  readonly type: 'FETCH_APPS_SUCCESS'
  readonly payload: List<AppRecord>
}
type fetchAppsFailureAction = {
  readonly type: 'FETCH_APPS_FAILURE'
  readonly payload: null
}

export type fetchProjectAppsAction = ReduxAction<'FETCH_PROJECT_APPS', null>
export type fetchProjectAppsSuccessAction = ReduxAction<
  'FETCH_PROJECT_APPS_SUCCESS',
  List<AppRecord>
>
export type fetchProjectAppsFailureAction = ReduxAction<'FETCH_PROJECT_APPS_FAILURE', null>

type updateCappingAction = {
  readonly type: 'PRESSURE_UPDATE'
  readonly payload: null
}
type updateCappingSuccessAction = {
  readonly type: 'PRESSURE_UPDATE_SUCCESS'
  readonly payload: null
}
type updateCappingFailureAction = {
  readonly type: 'PRESSURE_UPDATE_FAILURE'
  readonly payload: null
}

type fetchSdksAction = {
  readonly type: 'FETCH_SDKS'
  readonly payload: null
}
type fetchSdksSuccessAction = {
  readonly type: 'FETCH_SDKS_SUCCESS'
  readonly payload: List<SdkRecord>
}
type fetchSdksFailureAction = {
  readonly type: 'FETCH_SDKS_FAILURE'
  readonly payload: null
}

type saveAppAction = {
  readonly type: 'SAVE_APP'
  readonly payload: null
}
export type saveAppSuccessAction = {
  readonly type: 'SAVE_APP_SUCCESS'
  readonly payload: {
    app: AppRecord
  }
}
export type saveAppFailureAction = {
  readonly type: 'SAVE_APP_FAILURE'
  readonly payload: null
}

type archiveAppAction = {
  readonly type: 'ARCHIVE_APP'
  readonly payload: null
}
type archiveAppSuccessAction = {
  readonly type: 'ARCHIVE_APP_SUCCESS'
  readonly payload: null
}
export type archiveAppFailureAction = {
  readonly type: 'ARCHIVE_APP_FAILURE'
  readonly payload: null
}

type savePushConfigAction = {
  readonly type: 'SAVE_PUSH_CONFIG'
  readonly payload: null
}
type savePushConfigSuccessAction = {
  readonly type: 'SAVE_PUSH_CONFIG_SUCCESS'
  readonly payload: PushConfigRecord
}
export type savePushConfigFailureAction = {
  readonly type: 'SAVE_PUSH_CONFIG_FAILURE'
  readonly payload: null
}

type saveCappingCategorieAction = {
  readonly type: 'SAVE_PRESSURE'
  readonly payload: null
}
export type saveCappingCategorieSuccessAction = {
  readonly type: 'SAVE_PRESSURE_SUCCESS'
  readonly payload: {
    categorieRecord: CappingCategoryRecord
    categorie: CappingCategoryRecord
  }
}
export type saveCappingCategorieFailureAction = {
  readonly type: 'SAVE_PRESSURE_FAILURE'
  readonly payload: null
}

type updatePressureCategorieAction = {
  readonly type: 'UPDATE_PRESSURE_CATEGORIE'
  readonly payload: {
    categorieRecord: CappingCategoryRecord
    updated: CappingCategoryRecord
  }
}

type updatePressureCappingAction = {
  readonly type: 'UPDATE_PRESSURE'
  readonly payload: {
    cappingRecord: CappingRecord
    categorieRecord: CappingCategoryRecord
    period: string
    capping: number
  }
}

type removePressureCappingAction = {
  readonly type: 'REMOVE_PRESSURE'
  readonly payload: {
    categorieRecord: CappingCategoryRecord
    cappingRecord: CappingRecord
  }
}

type deleteCappingCategorieAction = {
  readonly type: 'DELETE_PRESSURE'
  readonly payload: null
}
export type deleteCappingCategorieSuccessAction = {
  readonly type: 'DELETE_PRESSURE_SUCCESS'
  readonly payload: {
    categorieRecord: CappingCategoryRecord
  }
}
export type deleteCappingCategorieFailureAction = {
  readonly type: 'DELETE_PRESSURE_FAILURE'
  readonly payload: null
}

export type checkWnsAction = {
  readonly type: 'CHECK_WNS'
  readonly payload: null
}
export type checkWnsSuccessAction = {
  readonly type: 'CHECK_WNS_SUCCESS'
  readonly payload: null
}
export type checkWnsFailureAction = {
  readonly type: 'CHECK_WNS_FAILURE'
  readonly payload: null
}

export type fetchCampaignLabelsCountsAction = {
  type: 'FETCH_CAMPAIGN_LABELS_COUNTS'
  payload: null
}
export type fetchCampaignLabelsCountsFInalAction = {
  type: 'FETCH_CAMPAIGN_LABELS_COUNTS_FINAL'
  payload: List<CappingCategoryRecord>
}
export type fetchCampaignLabelsCountsFailureAction = {
  type: 'FETCH_CAMPAIGN_LABELS_COUNTS_FAILURE'
  payload: {
    error: string
  }
}

type SetWebpushIconsAction = {
  type: 'SET_WEBPUSH_ICONS'
  payload: null
}
export type SetWebpushIconsSuccessAction = {
  type: 'SET_WEBPUSH_ICONS_SUCCESS'
  payload: {
    safariWebsiteIconUrl: string
    androidSmallIconUrl: string
  }
}
export type SetWebpushIconsFailureAction = {
  type: 'SET_WEBPUSH_ICONS_FAILURE'
  payload: {
    error: string
  }
}

type updateAllowedUsersAction = {
  type: 'UPDATE_ALLOWED_USERS'
  payload: null
}
export type updateAllowedUsersSuccessAction = {
  type: 'UPDATE_ALLOWED_USERS_SUCCESS'
  payload: Map<number, UserRecord>
}
export type updateAllowedUsersFailureAction = {
  type: 'UPDATE_ALLOWED_USERS_FAILURE'
  payload: null
}
export const updateAllowedUsers =
  (app: AppRecord, allowedUsers: Set<number>): DispatchOnlyBoundFn<Promise<any>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'UPDATE_ALLOWED_USERS',
      dispatch,
      promise: appApi.updateAllowedUsers(app, { allowedUsers: allowedUsers.toArray() }),
      payload: null,
    })

type saveDevOriginsAction = {
  type: 'SAVE_DEV_ORIGINS'
  payload: null
}
export type saveDevOriginsSuccessAction = {
  type: 'SAVE_DEV_ORIGINS_SUCCESS'
  payload: PushConfigRecord
}
export type saveDevOriginsFailureAction = {
  type: 'SAVE_DEV_ORIGINS_FAILURE'
  payload: null
}

type deleteDevOriginsAction = {
  type: 'DELETE_DEV_ORIGINS'
  payload: null
}
export type deleteDevOriginsSuccessAction = {
  type: 'DELETE_DEV_ORIGINS_SUCCESS'
  payload: PushConfigRecord
}
export type deleteDevOriginsFailureAction = {
  type: 'DELETE_DEV_ORIGINS_FAILURE'
  payload: null
}

type addSafariWebsiteAction = {
  type: 'ADD_SAFARI_WEBSITE'
  payload: null
}
export type addSafariWebsiteSuccessAction = {
  type: 'ADD_SAFARI_WEBSITE_SUCCESS'
  payload: SafariWebsitesRecord
}
export type addSafariWebsiteFailureAction = {
  type: 'ADD_SAFARI_WEBSITE_FAILURE'
  payload: {
    error: string
  }
}

type deleteSafariWebsiteAction = {
  type: 'DELETE_SAFARI_WEBSITE'
  payload: null
}
export type deleteSafariWebsiteSuccessAction = {
  type: 'DELETE_SAFARI_WEBSITE_SUCCESS'
  payload: number
}
export type deleteSafariWebsiteFailureAction = {
  type: 'DELETE_SAFARI_WEBSITE_FAILURE'
  payload: {
    error: string
  }
}

type updateSafariWebsiteAction = {
  type: 'UPDATE_SAFARI_WEBSITE'
  payload: null
}
export type updateSafariWebsiteSuccessAction = {
  type: 'UPDATE_SAFARI_WEBSITE_SUCCESS'
  payload: SafariWebsitesRecord
}
export type updateSafariWebsiteFailureAction = {
  type: 'UPDATE_SAFARI_WEBSITE_FAILURE'
  payload: {
    error: string
  }
}

type saveSafariWebsiteNameAction = {
  type: 'SAVE_SAFARI_WEBSITE_NAME'
  payload: null
}
export type saveSafariWebsiteNameSuccessAction = {
  type: 'SAVE_SAFARI_WEBSITE_NAME_SUCCESS'
  payload: AppRecord
}

export type saveSafariWebsiteNameFailureAction = {
  type: 'SAVE_SAFARI_WEBSITE_NAME_FAILURE'
  payload: {
    error: string
  }
}

export type AppPressureActions =
  | saveCappingCategorieAction
  | saveCappingCategorieSuccessAction
  | saveCappingCategorieFailureAction
  | updatePressureCategorieAction
  | updatePressureCappingAction
  | removePressureCappingAction
  | deleteCappingCategorieAction
  | deleteCappingCategorieSuccessAction
  | deleteCappingCategorieFailureAction

export type AppActions =
  | openIntegrateAction
  | closeIntegrateAction
  | openNewAction
  | closeNewAction
  | fetchAppAction
  | fetchAppSuccessAction
  | fetchAppFailureAction
  | fetchAppsAction
  | fetchAppsSuccessAction
  | fetchAppsFailureAction
  | fetchProjectAppsAction
  | fetchProjectAppsSuccessAction
  | fetchProjectAppsFailureAction
  | updateCappingAction
  | updateCappingSuccessAction
  | updateCappingFailureAction
  | fetchSdksAction
  | fetchSdksSuccessAction
  | fetchSdksFailureAction
  | saveAppAction
  | saveAppSuccessAction
  | saveAppFailureAction
  | archiveAppAction
  | archiveAppSuccessAction
  | archiveAppFailureAction
  | savePushConfigAction
  | savePushConfigSuccessAction
  | savePushConfigFailureAction
  | fetchCampaignLabelsCountsFInalAction
  | fetchCampaignLabelsCountsFailureAction
  | fetchCampaignLabelsCountsAction
  | checkWnsAction
  | checkWnsSuccessAction
  | checkWnsFailureAction
  | updateAllowedUsersAction
  | updateAllowedUsersSuccessAction
  | updateAllowedUsersFailureAction
  | addSafariWebsiteAction
  | deleteSafariWebsiteAction
  | updateSafariWebsiteAction
  | saveSafariWebsiteNameAction
  | saveSafariWebsiteNameSuccessAction
  | addSafariWebsiteSuccessAction
  | updateSafariWebsiteSuccessAction
  | deleteSafariWebsiteSuccessAction
  | SetWebpushIconsAction
  | SetWebpushIconsSuccessAction
  | SetWebpushIconsFailureAction
  | saveDevOriginsAction
  | saveDevOriginsSuccessAction
  | saveDevOriginsFailureAction
  | deleteDevOriginsAction
  | deleteDevOriginsSuccessAction
  | deleteDevOriginsFailureAction
  | SavePushSettingsSuccessAction

export const openIntegrate = (): openIntegrateAction => {
  return {
    type: 'POPIN_INTEGRATE_OPEN',
    payload: null,
  }
}

export const closeIntegrate = (): closeIntegrateAction => {
  return {
    type: 'POPIN_INTEGRATE_CLOSE',
    payload: null,
  }
}
export const openNew = (backUrl: string): openNewAction => {
  return {
    type: 'POPIN_NEW_OPEN',
    payload: backUrl,
  }
}
export const closeNew = (): closeNewAction => {
  return {
    type: 'POPIN_NEW_CLOSE',
    payload: null,
  }
}
export const fetchApp = (appId: number): DispatchOnlyBoundFn<Promise<any>> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: appId,
      promise: appApi.fetchById(appId),
      actionName: 'FETCH_APP_BY_ID',
      successCallback: function (normalized) {
        dispatch({
          type: 'FETCH_COMPANY_SUCCESS',
          payload: normalized.company,
        })
        dispatch({
          type: 'PT_FETCH_DEVICES_SUCCESS',
          payload: normalized.devices,
        })
      },
    })
}

export const fetchApps = (companyId: number): DispatchOnlyBoundFn<Promise<List<AppRecord>>> => {
  return dispatch =>
    promiseActionCreator({
      dispatch,
      payload: {},
      promise: appApi.fetchAll(companyId),
      actionName: 'FETCH_APPS',
    })
}
export const fetchProjectApps = (
  project: ProjectRecord
): DispatchOnlyBoundFn<Promise<List<AppRecord>>> => {
  return dispatch =>
    promiseActionCreator({
      dispatch,
      payload: {},
      promise: appApi.fetchProjectApps(project.companyId, project.id),
      actionName: FETCH_PROJECT_APPS,
    })
}
export const updateCapping: (arg1: { id: string; what: string; value: string }) => ReduxAction<
  'PRESSURE_UPDATE',
  {
    id: string
    what: string
    value: string
  }
> = ({ id, what, value }) => {
  return {
    type: 'PRESSURE_UPDATE',
    payload: {
      id,
      what,
      value,
    },
  }
}

export const fetchSdks = (): DispatchBoundFn<Promise<any>> => {
  return (dispatch, getState) => {
    const state = getState()
    return promiseActionCreator({
      dispatch,
      payload: {},
      promise: appApi.sdksVersions().then(versions => {
        return state.app.sdks.map(sdk => {
          const version = versions.find(v => v.sdk.toLowerCase() === sdk.kind.toLowerCase())
          return version
            ? sdk
                .set('version', version.latest)
                .set(
                  'packageManagerMinVersion',
                  typeof version.packager_str === 'string' ? version.packager_str : ''
                )
            : sdk
        })
      }),
      actionName: 'FETCH_SDKS',
    })
  }
}

export const saveApp: (
  app: AppRecord,
  allowedUsers?: Set<number> | null | undefined
) => DispatchOnlyBoundFn<Promise<any>> = app => {
  return dispatch =>
    promiseActionCreator({
      dispatch,
      payload: app,
      promise: appApi.save(app),
      actionName: 'SAVE_APP',
    })
}

export const archiveApp = (app: AppRecord): DispatchOnlyBoundFn<Promise<any>> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: app,
      promise: appApi.archive(app),
      actionName: 'ARCHIVE_APP',
    })
}

export const savePushConfig = (
  app: AppRecord,
  pushConfig: PushConfigRecord,
  file: File | null = null,
  password: string | null = null
): DispatchBoundFn<any> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: { app, pushConfig },
      promise: appApi.savePushConfig(app, pushConfig, file, password),
      actionName: 'SAVE_PUSH_CONFIG',
    })
}

export const saveCappingCategory = (
  categorie: CappingCategoryRecord,
  context?: string
): DispatchBoundFn<any> => {
  return (dispatch, getState) => {
    const state = getState()
    const appId = state.app.current.id
    try {
      const promise = appApi.saveCappingCategory({ appId, categorie, context })
      return legacyPromiseActionCreator({
        dispatch,
        payload: { appId, categorie },
        promise,
        actionName: 'SAVE_PRESSURE',
      })
    } catch {
      dispatch({
        payload: { categorie },
        type: 'SAVE_PRESSURE_FAILURE',
      })
    }
  }
}

export const deleteCappingCategorie = (
  categorie: CappingCategoryRecord
): DispatchBoundFn<Promise<any>> => {
  return (dispatch, getState) => {
    const state = getState()
    const appId = state.app.current.id
    const promise = appApi.deleteCappingCategorie({ appId, categorie })
    return legacyPromiseActionCreator({
      dispatch,
      payload: { appId, categorie },
      promise,
      actionName: 'DELETE_PRESSURE',
    })
  }
}

export const checkWns = ({
  app,
  packageId,
  secret,
}: {
  app: AppRecord
  packageId: string
  secret: string
}): DispatchOnlyBoundFn<Promise<any>> => {
  return dispatch =>
    legacyPromiseActionCreator({
      dispatch,
      payload: { app, packageId, secret },
      promise: appApi.checkWns(app, packageId, secret),
      actionName: 'CHECK_WNS',
    })
}

export const updatePressureCategory = ({
  categorieRecord,
  updated,
}: {
  categorieRecord: CappingCategoryRecord
  updated: CappingCategoryRecord
}): updatePressureCategorieAction => {
  return {
    type: 'UPDATE_PRESSURE_CATEGORIE',
    payload: {
      categorieRecord,
      updated,
    },
  }
}

export const updatePressureCapping = ({
  categorieRecord,
  cappingRecord,
  capping,
  period,
}: {
  categorieRecord: CappingCategoryRecord
  cappingRecord: CappingRecord
  capping: number
  period: string
}): updatePressureCappingAction => {
  return {
    type: 'UPDATE_PRESSURE',
    payload: { categorieRecord, cappingRecord, capping, period },
  }
}

export const removePressureCapping = ({
  categorieRecord,
  cappingRecord,
}: {
  categorieRecord: CappingCategoryRecord
  cappingRecord: CappingRecord
}): removePressureCappingAction => {
  return {
    type: 'REMOVE_PRESSURE',
    payload: { categorieRecord, cappingRecord },
  }
}

export const saveDevOrigins = (
  app: AppRecord,
  pushConfig: PushConfigRecord,
  file: File | null = null,
  password: string | null = null
): DispatchBoundFn<any> => {
  return dispatch =>
    promiseActionCreator({
      dispatch,
      payload: { app, pushConfig },
      promise: appApi.savePushConfig(app, pushConfig, file, password),
      actionName: 'SAVE_DEV_ORIGINS',
    })
}

export const deleteDevOrigins = (
  app: AppRecord,
  pushConfig: PushConfigRecord,
  file: File | null = null,
  password: string | null = null
): DispatchBoundFn<any> => {
  return dispatch =>
    promiseActionCreator({
      dispatch,
      payload: { app, pushConfig },
      promise: appApi.savePushConfig(app, pushConfig, file, password),
      actionName: 'DELETE_DEV_ORIGINS',
    })
}

export const addSafariWebsite =
  ({
    app,
    domain,
    file,
    password,
  }: {
    app: AppRecord
    domain: string
    file: File
    password: string
  }): DispatchBoundFn<Promise<any>> =>
  dispatch => {
    return promiseActionCreator({
      actionName: 'ADD_SAFARI_WEBSITE',
      dispatch,
      promise: appApi.addSafariWebsite({ app, domain, file, password }),
      payload: null,
    })
  }

export const deleteSafariWebsite =
  ({ app, websiteId }: { app: AppRecord; websiteId: string }): DispatchBoundFn<Promise<any>> =>
  dispatch => {
    return legacyPromiseActionCreator({
      dispatch,
      payload: null,
      promise: appApi.deleteSafariWebsite({ app, websiteId }),
      actionName: 'DELETE_SAFARI_WEBSITE',
    })
  }

export const saveSafariWebsiteName =
  ({
    app,
    safariWebsiteName,
  }: {
    app: AppRecord
    safariWebsiteName: string
  }): DispatchBoundFn<Promise<any>> =>
  dispatch => {
    return promiseActionCreator({
      actionName: 'SAVE_SAFARI_WEBSITE_NAME',
      dispatch,
      promise: appApi.saveSafariWebsiteName({ app, safariWebsiteName }),
      payload: null,
    })
  }

export const updateSafariWebsite =
  ({
    app,
    websiteId,
    domain,
    file,
    password,
  }: {
    app: AppRecord
    websiteId: string
    domain: string
    file: File | null | undefined
    password: string
  }): DispatchBoundFn<Promise<any>> =>
  dispatch => {
    return promiseActionCreator({
      actionName: 'UPDATE_SAFARI_WEBSITE',
      dispatch,
      promise: appApi.updateSafariWebsite({ app, websiteId, domain, file, password }),
      payload: null,
    })
  }

export const setWebpushIcons =
  ({
    app,
    safariWebsiteIconUrl,
    androidSmallIconUrl,
  }: {
    app: AppRecord
    safariWebsiteIconUrl: string | null
    androidSmallIconUrl: string | null
  }): DispatchOnlyBoundFn<
    Promise<{
      androidSmallIconUrl: string
      safariWebsiteIconUrl: string
    }>
  > =>
  dispatch =>
    promiseActionCreator({
      dispatch,
      actionName: 'SET_WEBPUSH_ICONS',
      payload: null,
      promise: appApi.saveWebpushIcons({ app, safariWebsiteIconUrl, androidSmallIconUrl }),
    })

export const appActions = {
  fetchApp,
  savePushConfig,
  removePressureCapping,
  saveCappingCategory,
  checkWns,
  updatePressureCategory,
  updatePressureCapping,
  fetchSdks,
  saveApp,
  archiveApp,
  addSafariWebsite,
  deleteSafariWebsite,
  saveSafariWebsiteName,
  updateSafariWebsite,
}
