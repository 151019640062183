import * as React from 'react'

import { InputContainer } from 'components/form/styles'

import { numberOnly } from 'com.batch.common/preventKeyPress'

type TimePickerProps = {
  value: [number, number]
  onHoursChange: (arg1: number) => void
  onMinutesChange: (arg1: number) => void
}

export const TimePicker = ({
  value,
  onHoursChange,
  onMinutesChange,
}: TimePickerProps): React.ReactElement => {
  const [typedHours, setTypedHours] = React.useState(value[0].toString().padStart(2, '0'))
  const [typedMinutes, setTypedMinutes] = React.useState(value[1].toString().padStart(2, '0'))

  const onHoursChangeHandler = React.useCallback(
    evt => {
      const stringEnd = evt.target.value.slice(-2)
      const newHours: number = parseInt(stringEnd)
      setTypedHours(stringEnd)
      if (!isNaN(newHours) && newHours >= 0 && newHours <= 23) {
        onHoursChange(newHours)
      }
    },
    [onHoursChange]
  )

  const onMinutesChangeHandler = React.useCallback(
    evt => {
      const stringEnd = evt.target.value.slice(-2)
      const newMinutes: number = parseInt(stringEnd)
      setTypedMinutes(stringEnd)
      if (!isNaN(newMinutes) && newMinutes >= 0 && newMinutes <= 59) {
        onMinutesChange(newMinutes)
      }
    },
    [onMinutesChange]
  )

  const fixTypedHourOnBlur = React.useCallback(() => {
    if (parseInt(typedHours) !== value[0]) {
      setTypedHours(value[0].toString().padStart(2, '0'))
    } else {
      setTypedHours(typedHours.padStart(2, '0'))
    }
  }, [typedHours, value])

  const fixTypedMinuteOnBlur = React.useCallback(() => {
    if (parseInt(typedMinutes) !== value[1]) {
      setTypedMinutes(value[1].toString().padStart(2, '0'))
    } else {
      setTypedMinutes(typedMinutes.padStart(2, '0'))
    }
  }, [typedMinutes, value])

  const stopPropagation = React.useCallback(evt => evt.stopPropagation(), [])

  return (
    <InputContainer>
      <input
        value={typedHours}
        type="number"
        min={0}
        max={23}
        style={{ textAlign: 'center', padding: 4, maxWidth: 70 }}
        placeholder="hh"
        onKeyDown={numberOnly}
        onChange={onHoursChangeHandler}
        onBlur={fixTypedHourOnBlur}
        onScroll={stopPropagation}
      />
      <input
        value={typedMinutes}
        type="number"
        min={0}
        max={59}
        style={{
          borderLeft: '1px solid #E9E9E9',
          textAlign: 'center',
          height: 34,
          minHeight: 34,
          margin: '1px 0',
          maxWidth: 70,
          padding: 4,
        }}
        placeholder="mm"
        onKeyDown={numberOnly}
        onChange={onMinutesChangeHandler}
        onBlur={fixTypedMinuteOnBlur}
        onScroll={stopPropagation}
      />
    </InputContainer>
  )
}
