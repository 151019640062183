import styled, { css } from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

const BorderBottom = css`
  &:after {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 0;
    content: '';
    border-bottom: 1px solid ${schemes.darklucent['05']};
  }
`

export const RestrictionsSelect = styled.div`
  display: flex;
  align-items: center;
  padding: 0 23px 0 20px;
`

export const RestrictionsHeader = styled.div`
  width: 100%;
  height: 46px;
  position: sticky;
  z-index: 100;
  top: 0;
  background: #fdfdfd98;
  background: rgba(253, 253, 253, 0.96);
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.01);

  ${BorderBottom}
  > div {
    height: 100%;
  }
`

export const RestrictionsLabel = styled.span`
  font-weight: 500;
  color: ${colors.textNeutral};
  user-select: none;

  span {
    color: ${colors.textLight};
  }
`

export const RestrictionsApp = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 22px 0 20px;
  position: relative;
  z-index: 90;
  white-space: nowrap;

  ${BorderBottom}
  &:last-child:after {
    display: none;
  }

  > label {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }

  > label > span {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    overflow: hidden;
  }
`

export const RestrictionAppName = styled.span`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const RestrictionsEmpty = styled.div`
  color: ${colors.textDisabled};
  padding: 16px 20px;
`
