import * as React from 'react'
import styled from 'styled-components'

import { ButtonLink } from 'components/common/button'

import { generateUrl } from 'com.batch.common/router'

import { RegWizard } from './register-containers'
import { RegisterStep, RegisterBlock, RegisterStepFooter } from './register.styles'

import { Title, Text } from '../auth.styles'

export const RegisterNext = ({ isInvite }: { isInvite: boolean }): React.ReactElement => {
  return (
    <RegWizard step={0} total={0} noProgress>
      <RegisterStep>
        <Title style={{ margin: '0 0 0 -3px' }}>Congratulations</Title>
        <Text>
          You've been successfully registered!{' '}
          {!isInvite && 'Here are some insights on what your next steps should be.'}
        </Text>
        {!isInvite && (
          <React.Fragment>
            <RegisterNextBlock>
              <RegisterNextText>
                <h3>Create your first app</h3>
                <p>Create your first app on Batch to start using the Dashboard.</p>
              </RegisterNextText>
              <img src="/medias/img/illustrations/onboarding_next_createApp.svg" />
            </RegisterNextBlock>
            <RegisterNextBlock>
              <RegisterNextText>
                <h3>Install the SDK</h3>
                <p>Install the SDK to collect data and start user segmentation.</p>
              </RegisterNextText>
              <img src="/medias/img/illustrations/onboarding_next_sdk.svg" />
            </RegisterNextBlock>
            <RegisterNextBlock>
              <RegisterNextText>
                <h3>Send your first push</h3>
                <p>
                  Create your first campaign and send your first push notifications, in-app or web
                  push.
                </p>
              </RegisterNextText>
              <img src="/medias/img/illustrations/onboarding_next_campaigns.svg" />
            </RegisterNextBlock>
          </React.Fragment>
        )}
        <RegisterStepFooter>
          <ButtonLink kind="primary" intent="action" href={generateUrl('dashboard')}>
            Continue to dashboard
          </ButtonLink>
        </RegisterStepFooter>
      </RegisterStep>
    </RegWizard>
  )
}

export const RegisterNextText = styled.div``

export const RegisterNextBlock = styled(RegisterBlock)`
  display: flex;
  align-items: center;

  ${RegisterNextText} {
    margin: 0 18px 0 0;
  }
  svg {
    height: 108px;
    margin: -24px 0;
  }
`
