import { type Set } from 'immutable'

import { type DispatchBoundFn } from 'com.batch.redux/_records'
import { currentProjectPlatformsSelector } from 'com.batch.redux/app'

type UpdatePushPlatformsPayload = {
  stepMessageNodeId: string | null | undefined
  platforms: Set<ProjectPlatforms>
}
export type UpdatePushPlatformsAction = {
  type: 'UPDATE_PUSH_PLATFORMS'
  payload: UpdatePushPlatformsPayload
}

export const updatePushPlatforms = (
  payload: UpdatePushPlatformsPayload
): UpdatePushPlatformsAction => ({
  type: 'UPDATE_PUSH_PLATFORMS',
  payload,
})

export const initDefaultPushPlatformsForCampaign = (): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    if (
      !state.orchestration.id &&
      state.orchestration.campaign.sendType !== 'trigger' &&
      state.orchestration.campaign.messageConfig.channel === 'push' &&
      state.orchestration.campaign.messageConfig.messageTypedId &&
      ['NEW', 'DRAFT'].includes(state.orchestration.state)
    ) {
      let platforms = currentProjectPlatformsSelector(state)
      if (platforms.size > 1 && platforms.has('webpush')) {
        platforms = platforms.delete('webpush')
      }
      dispatch(updatePushPlatforms({ stepMessageNodeId: undefined, platforms }))
    }
  }
}
