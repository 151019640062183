import * as React from 'react'
import styled from 'styled-components'

import { Icon, IconContainer } from 'components/common/svg-icon'

import { ucFirst } from 'com.batch.common/utils'

type AppThumbProps = {
  platform: string
  name: string
  icon: string
  showMeta: boolean
  centered: boolean
  size?: number
}
type AppIconProps = {
  size: number
  windows?: boolean
  url?: string
  centered: boolean
  platform: string
}
export const AppIcon = styled.div<AppIconProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  margin: ${props => (props.centered ? '0 auto' : '0')};
  background-image: ${props => (props.url ? `url(${props.url})` : 'none')};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: ${props => (props.platform === 'ios' || !props.url ? '10px' : '0')};
  background-color: ${props =>
    props.windows && props.url ? '#1A7BBE' : props.url ? 'transparent' : '#EFF0F4'};
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cccfda;

  ${IconContainer} {
    display: ${props => (props.url ? 'none' : 'block')};
    text-align: center;
  }
`

const AppName = styled.div`
  font-size: 20px;
  text-align: ${(props: { centered?: boolean }) => (props.centered ? 'center' : 'left')};
  padding-top: 10px;
`
const AppPlatform = styled.div`
  text-align: ${(props: { centered?: boolean }) => (props.centered ? 'center' : 'left')};
  padding-top: 8px;
`

const AppThumb = ({
  platform,
  icon,
  name,
  size = 100,
  showMeta = false,
  centered = false,
}: AppThumbProps): React.ReactElement => {
  return (
    <div style={{ position: 'relative' }}>
      <AppIcon url={icon} platform={platform} size={size} centered={centered}>
        <Icon icon={platform !== 'webpush' ? 'mobile' : 'computer'} size={size / 4} />
      </AppIcon>
      {showMeta && (
        <AppName centered={centered}>
          {name || (platform === 'webpush' ? 'New website' : 'New app')}
        </AppName>
      )}
      {showMeta && platform !== 'webpush' && (
        <AppPlatform centered={centered}>
          {platform === 'ios' ? 'iOS' : ucFirst(platform)}
        </AppPlatform>
      )}
    </div>
  )
}

export default AppThumb
