import Immutable, { type List, type RecordOf } from 'immutable'

import { type CappingRuleRecord } from 'com.batch/capping/model/capping-rule'

type LabelProps = {
  description: string
  code: string
  orchestrationCount: number
  isMutating: boolean
  isDeleted?: boolean
  cappingRules: List<CappingRuleRecord>
}

export type LabelRecord = RecordOf<LabelProps>

export const LabelFactory = Immutable.Record<LabelProps>({
  description: '',
  code: '',
  orchestrationCount: 0,
  isMutating: false,
  isDeleted: false,
  cappingRules: Immutable.List(),
} as LabelProps)
