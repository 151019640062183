import * as React from 'react'
import { useState } from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import {
  BoxHeader,
  HeaderBoxTitle,
  BoxBody,
  BoxFooter,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Input } from 'components/form'
import { Radio } from 'components/form/fields/radio'

import { PushTesterModal } from './push-tester.styles'

import { TestDeviceFactory } from 'com.batch.redux/_records'
import { sendTestCampaignForProject } from 'com.batch.redux/testDevice'

type PushTesterPopinProps = {
  close: () => void
}

const allDeviceKinds: {
  value: 'custom_id' | 'installation_id' | 'advertising_id' | 'token'
  label: string
}[] = [
  { value: 'custom_id', label: 'Custom user ID' },
  { value: 'installation_id', label: 'Installation ID' },
  { value: 'advertising_id', label: 'IDFA' },
  { value: 'token', label: 'Push token' },
]

export const PushTesterTemporaryPopin = ({ close }: PushTesterPopinProps): React.ReactElement => {
  const dispatch = useDispatch()
  const [newDevice, setNewDevice] = useState(TestDeviceFactory({ kind: allDeviceKinds[0].value }))

  const canSendTest = newDevice.value.length > 2

  const sendTest = React.useCallback(() => {
    dispatch(sendTestCampaignForProject(newDevice))
  }, [newDevice, dispatch])

  return (
    <Popin opened close={close} style={{ width: 700 }}>
      <PushTesterModal>
        <BoxHeader large>
          <HeaderBoxTitle
            title="Send a push test"
            detail="Preview your push to a temporary device"
          />
          <HeaderBoxActions large>
            <Button onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody
          style={{
            padding: 20,
          }}
        >
          {allDeviceKinds.map(device => {
            return (
              <Radio
                key={device.value}
                onChange={() => setNewDevice(() => newDevice.set('kind', device.value))}
                label={device.label}
                checked={device.value === newDevice.kind}
                style={{ marginRight: '12px' }}
              />
            )
          })}

          <Input
            id="code"
            style={{ margin: '12px 0px 0px' }}
            placeholder="Enter code..."
            value={newDevice.value}
            onChange={evt =>
              setNewDevice(() =>
                newDevice.set(
                  'value',
                  evt.target.value ? evt.target.value.trim() : evt.target.value
                )
              )
            }
          />
          {newDevice.kind === 'token' && (
            <div>
              <Radio
                onChange={() => setNewDevice(() => newDevice.set('distribution', true))}
                label={'Production'}
                checked={newDevice.distribution}
                style={{ marginRight: '12px', marginTop: 10 }}
              />
              <Radio
                style={{ marginTop: 10 }}
                onChange={() => setNewDevice(() => newDevice.set('distribution', false))}
                label="Sandbox"
                checked={!newDevice.distribution}
              />
            </div>
          )}
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" onClick={close}>
            Cancel
          </Button>
          <Button kind="primary" intent="action" onClick={sendTest} disabled={!canSendTest}>
            Send to device
          </Button>
        </BoxFooter>
      </PushTesterModal>
    </Popin>
  )
}
