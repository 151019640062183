// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable react/jsx-no-bind */
import { color } from 'd3-color'
import { select as D3Select } from 'd3-selection'
import { linkHorizontal } from 'd3-shape'
import uniqueId from 'lodash/uniqueId'
import * as React from 'react'

import { Box, BoxHeader, HeaderBoxTitle, BoxBody } from 'components/common/box'
import { Wrapper } from 'components/common/empty-states'

import { kformat, percentage } from 'com.batch.common/utils'

import { TooltipDetail } from './delivery.styles'
import Metric, { MetricLabel, MetricRatio, MetricContainer } from './metric'
import {
  PerformanceContainer,
  PerformanceBlock,
  PerformanceNumbers,
  Foreground,
  Background,
} from './performance.styles'

import { type performanceItem } from '../review.data.selectors'

type Elements = Element[]
type Gradient = Array<string>
type Point = {
  x: number
  y: number
}

type Connectors = Array<{
  from: Point
  to: Point
  step: number
}>
type Points = Array<
  Point & {
    rotate: boolean
    step: number
  }
>

type PerformanceProps = {
  loading: boolean
  journey: boolean
  data: Array<{
    title: string
    ratio?: number
    items: Array<performanceItem>
  }>
}

class Performance extends React.PureComponent<PerformanceProps> {
  static defaultProps: {
    journey: boolean
  } = {
    journey: false,
  }
  $foreground: Element | null | undefined
  $background: Element | null | undefined
  $gradient: Element | null | undefined
  $clipTracks: Element | null | undefined
  $clipShadows: Element | null | undefined
  blocks: {
    $containers: Elements
    $sources: Elements[]
    $targets: Elements[]
  } = {
    $containers: [],
    $sources: [],
    $targets: [],
  }

  componentDidMount() {
    this.drawConnectors()
    window.addEventListener('resize', this.drawConnectors)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.drawConnectors)
  }
  componentDidUpdate() {
    this.drawConnectors()
  }

  drawConnectors: () => void = () => {
    const links:
      | {
          connectors: Connectors
          points: Points
        }
      | null
      | undefined = this.getLinks()
    if (!links) {
      return
    }

    const colors = getGradients(this.props.data.length)
    const boxWidth = this.$foreground ? this.$foreground.getBoundingClientRect().width : 0
    const stops: Array<
      | any
      | {
          color: string
          offset: string
        }
    > = []
    links.connectors.forEach((l, i) => {
      if (links.connectors[i - 1] && links.connectors[i - 1].step === l.step) return
      stops.push({ offset: `${(l.from.x / boxWidth) * 100}%`, color: colors[l.step][0] })
      stops.push({ offset: `${(l.to.x / boxWidth) * 100}%`, color: colors[l.step][1] })
    })
    D3Select(this.$gradient)
      .selectAll('stop')
      .data(stops)
      .enter()
      .append('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color)

    D3Select(this.$clipTracks).selectAll('path').data(links.connectors).enter().append('path')

    D3Select(this.$clipTracks)
      .selectAll('path')
      .attr('d', d => linkGenerator(d))
      .attr('fill', 'none')
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 2)
      .attr('strokeLinecap', 'round')

    D3Select(this.$foreground).selectAll('circle').data(links.points).enter().append('circle')

    D3Select(this.$foreground)
      .selectAll('circle')
      .attr('cx', d => d.x)
      .attr('cy', d => d.y)
      .attr('r', (d, i) => (this.props.journey && i === 2 ? 8 : 4))
      .attr('fill', d => {
        const length = this.props.data.length
        const gradient = getGradients(this.props.data.length)
        return d.step >= length - 1 ? gradient[length - 2][1] : gradient[d.step][0]
      })

    D3Select(this.$clipShadows).selectAll('path').data(links.connectors).enter().append('path')

    D3Select(this.$clipShadows)
      .selectAll('path')
      .attr('d', d => linkGenerator(d))
      .attr('fill', 'none')
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 32)
      .attr('strokeLinecap', 'round')
  }

  getLinks: () =>
    | {
        connectors: Connectors
        points: Points
      }
    | null
    | undefined = () => {
    let parentX: number = 0
    let parentY: number = 0
    let min: number = 9999
    let max: number = 0

    const sources: Point &
      {
        step: number
      }[][] = []
    const targets: Point &
      {
        step: number
      }[][] = []
    const connectors: Connectors = []
    const points: Points = []

    if (this.blocks.$containers.length === 0 || this.blocks.$targets === 0) {
      return
    }

    this.blocks.$containers.forEach(($container, i) => {
      if (parentX === 0) {
        const { left, top } = $container.parentElement
          ? $container.parentElement.getBoundingClientRect()
          : { top: 0, left: 0 }
        parentX = left
        parentY = top
      }
      let tmp: Array<
        Point & {
          step: number
        }
      > = []
      let boxWidth = 0
      for (const $source of this.blocks.$sources[i] || []) {
        const b = $source.getBoundingClientRect()
        boxWidth = b.width
        tmp.push({
          x: b.left - parentX + (i === 1 && this.props.journey ? boxWidth / 2 : boxWidth),
          y: b.top - parentY + b.height / 2,
          step: i,
        })
      }
      if (tmp.length > 0) {
        sources.push(tmp)
      }
      tmp = []
      for (const $target of this.blocks.$targets[i] || []) {
        const b = $target.getBoundingClientRect()
        tmp.push({
          x: b.left - parentX + (i === 1 && this.props.journey ? boxWidth / 2 : 0),
          y: b.top - parentY + b.height / 2,
          step: i,
        })
      }
      if (tmp.length > 0) {
        targets.push(tmp)
      }
    })
    sources.forEach((source, i) => {
      const target = targets[i]
      if (!target) {
        return
      }
      for (const sourcePoint of source) {
        for (const targetPoint of target) {
          connectors.push({ from: sourcePoint, to: targetPoint, step: i })
          points.push({ ...sourcePoint, rotate: false, step: i })
          points.push({ ...targetPoint, rotate: true, step: i })
          min = sourcePoint.x < min ? sourcePoint.x : min
          max = sourcePoint.x > max ? sourcePoint.x : max
        }
      }
    })
    return { connectors, points }
  }

  _refItem: (
    $el: Element | null | undefined,
    i: number,
    length: number,
    isSource: boolean
  ) => void = ($el: Element | null | undefined, i: number, length: number, isSource: boolean) => {
    if (!$el) {
      return
    }

    if (isSource && i < length) {
      if (Array.isArray(this.blocks.$sources[i])) this.blocks.$sources[i].push($el)
      else this.blocks.$sources[i] = [$el]
    }
    if (i > 0) {
      if (Array.isArray(this.blocks.$targets[i])) this.blocks.$targets[i].push($el)
      else this.blocks.$targets[i] = [$el]
    }
  }

  render(): React.ReactElement {
    this.blocks = { $containers: [], $sources: [], $targets: [] }

    const { data, loading, journey } = this.props
    const Gradient = getGradients(data.length)
    const gID = uniqueId('performance-gradient__')
    const clipTID = uniqueId('performance-clipTracks__')
    const clipSID = uniqueId('performance-clipShadows__')
    const noClick = data.length === 2 && data[1].title === 'Clicked' && data[1].items.length === 0

    const isEmpty = data.length === 0 || data[0].items[0].count === 0 || noClick

    return (
      <Box>
        <Wrapper
          isLoading={loading}
          isEmpty={isEmpty}
          isOverlayShown={!loading && isEmpty}
          overlayProps={{
            status: 'empty',
            title: 'No data for this campaign.',
            description: noClick
              ? 'The theme probably has no button'
              : 'It might not have targeted anyone known to Batch.',
          }}
        >
          <BoxHeader>
            {journey ? (
              <HeaderBoxTitle title="User journey" />
            ) : (
              <HeaderBoxTitle title="Performance report" />
            )}
          </BoxHeader>
          <BoxBody style={{ position: 'relative' }}>
            <Foreground ref={ref => (this.$foreground = ref)}>
              <defs>
                <linearGradient id={gID} ref={ref => (this.$gradient = ref)} />
                <mask id={clipTID} ref={ref => (this.$clipTracks = ref)} />
                <mask id={clipSID} ref={ref => (this.$clipShadows = ref)} />
              </defs>
              <rect width="100%" height="100%" fill={`url(#${gID})`} mask={`url(#${clipTID})`} />
            </Foreground>
            <PerformanceContainer nb={data.length}>
              {data.map((d, i) => (
                <PerformanceBlock
                  key={i}
                  journey={journey}
                  multiple={d.items.length > 1 && !journey && !!d.title}
                  transition={journey && i !== 0 && i !== data.length - 1}
                  ref={$el => {
                    if ($el) this.blocks.$containers[i] = $el
                  }}
                >
                  {d.items.length > 1 && d.title && (
                    <MetricContainer as="span" style={{ display: 'flex' }}>
                      <MetricLabel style={{ flexGrow: 1 }}>{d.title}</MetricLabel>
                      {d.ratio && <MetricRatio small>{percentage(d.ratio)}</MetricRatio>}
                    </MetricContainer>
                  )}

                  {d.items.map((sub, j) => {
                    return (
                      <PerformanceNumbers
                        key={j}
                        className={
                          sub.label === 'Close' && !journey ? 'styled-fix-border-hack' : ''
                        }
                        style={{
                          marginBottom:
                            journey && d.items.length > 1 && j === 0
                              ? 80
                              : j === 0 && d.title === ''
                                ? 24
                                : 0,
                        }}
                        ref={$el => this._refItem($el, i, data.length - 1, !sub.notASource)}
                        input={i !== 0}
                        output={i !== data.length - 1 && !sub.notASource}
                        transition={journey && i !== 0 && i !== data.length - 1 && !sub.notASource}
                      >
                        <Metric
                          value={sub.count}
                          appendNode={<div>{sub.appendNode}</div>}
                          small
                          smallNoArrow
                          ratio={sub.ratio}
                          color={color(
                            i > Gradient.length - 1
                              ? Gradient[Gradient.length - 1][1]
                              : Gradient[i][0]
                          ).darker(0.5)}
                          alternateTooltipIcon={
                            journey && sub.tooltip && sub.tooltip.details.length > 1
                          }
                          tooltip={
                            !sub.tooltip ? (
                              false
                            ) : (
                              <React.Fragment>
                                {!!sub.tooltip.descr && sub.tooltip.descr}
                                {sub.tooltip.details.map((d, j) => (
                                  <TooltipDetail key={j}>
                                    <strong>{kformat(d.value)}</strong>
                                    <label>{d.label}</label>
                                  </TooltipDetail>
                                ))}
                              </React.Fragment>
                            )
                          }
                        >
                          {sub.label || d.title}
                        </Metric>
                      </PerformanceNumbers>
                    )
                  })}
                </PerformanceBlock>
              ))}
            </PerformanceContainer>
            <Background ref={$el => (this.$background = $el)} style={{ backroundColor: 'red' }}>
              <rect width="100%" height="100%" fill={`url(#${gID})`} mask={`url(#${clipSID})`} />
            </Background>
          </BoxBody>
        </Wrapper>
      </Box>
    )
  }
}

function getGradients(steps: number = 3): Array<Gradient> {
  switch (steps) {
    case 4:
      return [
        ['#79b34e', '#2a9ba0'],
        ['#2a9ba0', '#1d7bb5'],
        ['#1d7bb5', '#52578c'],
      ]
    case 3:
    default:
      return [
        ['#79b34e', '#238aab'],
        ['#238aab', '#52578c'],
      ]
  }
}

const linkGenerator = linkHorizontal()
  .x(d => Math.floor(d.x))
  .y(d => Math.floor(d.y))
  .source(d => d.from)
  .target(d => d.to)

export default Performance
