import * as React from 'react'

import { type availableIcons, Icon } from 'components/common/svg-icon'

import { ProfileHeroFooterItemContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

export const ProfileHeroFooterItem = ({
  icon,
  item,
  emptyMessage,
}: {
  icon?: availableIcons | null | undefined
  item: string | null | undefined | React.ReactNode
  emptyMessage: string
}): React.ReactElement => (
  <ProfileHeroFooterItemContainer noContact={!item}>
    {icon && <Icon icon={icon} style={{ marginRight: 6 }} />}
    <span> {item ? item : emptyMessage}</span>
  </ProfileHeroFooterItemContainer>
)
