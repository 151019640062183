import { type List } from 'immutable'
import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

import { getPlatformName } from './get-platform-name'

import {
  type InstallRecord,
  type ProfileWebpushPlatformsRecord,
  type SubscriptionStatus,
} from 'com.batch/profile/models/profile.records'

export const getPlatformList = (platforms: List<InstallRecord>): string =>
  platforms
    .reduce<Array<any>>((acc, current) => {
      if (current.platform && !acc.includes(getPlatformName(current.platform)))
        acc.push(getPlatformName(current.platform))
      return acc
    }, [])
    .join(' & ')

export const getBrowserList = (browsers: List<ProfileWebpushPlatformsRecord>): string => {
  if (browsers.size > 2) return `${browsers.size} platforms opted-in`
  return browsers
    .reduce<Array<any>>((acc, browser) => {
      if (browser.browser === 'UNKNOWN' && !acc.includes('Web browser'))
        acc.push(browser.browserType.length > 0 ? browser.browserType : 'Web browser')
      else acc.push(getPlatformName(browser.browser))
      return acc
    }, [])
    .join(', ')
}

type Result = {
  subscriptionStatus: SubscriptionStatus
  message: string
  subscriptionIcon: React.ReactNode
  tooltipTitle?: string
  tooltipMessage?: string
}
export const getPushSubscriptionMessage = (mobilePlatforms: List<InstallRecord>): Result => {
  const isOnlyImportedPlatforms =
    mobilePlatforms.size > 0 && mobilePlatforms.every(install => install.isImported)
  if (isOnlyImportedPlatforms)
    return {
      subscriptionStatus: 'SUBSCRIBED',
      message: getPlatformList(mobilePlatforms),
      subscriptionIcon: <Icon icon="check-dashed" color={colors.textLight} />,
      tooltipTitle: 'Considered opted-in',
      tooltipMessage:
        'All mobile app tokens have been imported but not yet detected by our systems',
    }

  const isSubscribed = mobilePlatforms.some(install => install.subscriptionStatus === 'SUBSCRIBED')
  if (isSubscribed)
    return {
      subscriptionStatus: 'SUBSCRIBED',
      message: getPlatformList(mobilePlatforms.filter(f => f.subscriptionStatus === 'SUBSCRIBED')),
      subscriptionIcon: <Icon icon="check" color={colors.textSuccess} />,
      tooltipMessage: 'At least one mobile app platform is opt-in to push notifications',
    }

  return {
    subscriptionStatus: 'UNSUBSCRIBED',
    message: `No install ${mobilePlatforms.size > 0 ? 'subscribed' : 'available'}`,
    subscriptionIcon: <Icon icon="close" color={colors.textDanger} />,
  }
}

export const getWebpushSubscriptionMessage = (
  webpushPlatforms: List<ProfileWebpushPlatformsRecord>
): Result => {
  const isSubscribed = webpushPlatforms.some(install => install.pushToken.length > 0)

  if (isSubscribed)
    return {
      subscriptionStatus: 'SUBSCRIBED',
      message: getBrowserList(webpushPlatforms.filter(f => f.subscriptionStatus === 'SUBSCRIBED')),
      subscriptionIcon: <Icon icon="check" color={colors.textSuccess} />,
      tooltipMessage: 'At least one web platform is opt-in to push notifications',
    }
  return {
    subscriptionStatus: 'UNSUBSCRIBED',
    message: `No browser ${webpushPlatforms.size > 0 ? 'subscribed' : 'available'}`,
    subscriptionIcon: <Icon icon="close" color={colors.textDanger} />,
  }
}
