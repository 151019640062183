import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export type sortDirection = 'asc' | 'dsc'

/**
 * Custom hook to updating/fetching of the search params associated with the sorting
 */
export const useSortParams = (): {
  sortDirectionParam: sortDirection | null | undefined
  sortOrderByParam: string | null | undefined
  setSortParams: (column?: string | null | undefined, direction?: string | null | undefined) => void
} => {
  const [searchParams, setSearchParams] = useSearchParams()

  const sortDirectionParam: null | sortDirection = useMemo(() => {
    const direction = searchParams.get('orderDirection')
    return direction === 'asc' || direction === 'dsc' ? direction : null
  }, [searchParams])

  const sortOrderByParam = useMemo(() => searchParams.get('orderBy'), [searchParams])

  const setSortParams = useCallback(
    (column: string | null | undefined, direction: string | null | undefined) => {
      setSearchParams(params => {
        if (!column || !direction) {
          params.delete('orderDirection')
          params.delete('orderBy')
          return params
        }
        params.set('orderDirection', direction)
        params.set('orderBy', column)
        return params
      })
    },
    [setSearchParams]
  )

  return {
    sortDirectionParam,
    sortOrderByParam,
    setSortParams,
  }
}
