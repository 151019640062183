import { type ReduxAction } from 'com.batch.redux/_records'

export type AddLanguageAction = ReduxAction<
  'ADD_LANGUAGE',
  {
    messageId: string
    lang: string
    channel: ChannelUntilCleanup
  }
>
export const addLanguage = (payload: {
  messageId: string
  lang: string
  channel: ChannelUntilCleanup
}): AddLanguageAction => {
  return { type: 'ADD_LANGUAGE', payload }
}

export type RemoveLanguageAction = ReduxAction<
  'REMOVE_LANGUAGE',
  {
    messageId: string
    lang: string
    channel: ChannelUntilCleanup
  }
>
export const removeLanguage = (payload: {
  messageId: string
  lang: string
  channel: ChannelUntilCleanup
}): RemoveLanguageAction => {
  return { type: 'REMOVE_LANGUAGE', payload }
}

export type ClearLanguagesAction = ReduxAction<
  'CLEAR_LANGUAGES',
  {
    stepMessageNodeId: string | null | undefined
  }
>
export const clearLanguages = (payload: {
  stepMessageNodeId: string | null | undefined
}): ClearLanguagesAction => {
  return { type: 'CLEAR_LANGUAGES', payload }
}

export type SetActiveLanguageAction = ReduxAction<'SET_ACTIVE_LANGUAGE', string>
export const setActiveLanguage = (lang: string): SetActiveLanguageAction => {
  return { type: 'SET_ACTIVE_LANGUAGE', payload: lang }
}

type ActivateMultiLanguagePayload = {
  stepMessageNodeId: string | null | undefined
}
export type ActivateMultiLanguageAction = ReduxAction<
  'ACTIVATE_MULTILANGUAGE',
  ActivateMultiLanguagePayload
>
export const activateMultiLanguage = ({
  stepMessageNodeId,
}: {
  stepMessageNodeId: string | null | undefined
}): ActivateMultiLanguageAction => {
  return {
    type: 'ACTIVATE_MULTILANGUAGE',
    payload: { stepMessageNodeId },
  }
}

type DeactivateMultiLanguagePayload = {
  stepMessageNodeId: string | null | undefined
}
export type DeactivateMultiLanguageAction = ReduxAction<
  'DEACTIVATE_MULTILANGUAGE',
  DeactivateMultiLanguagePayload
>
export const deactivateMultiLanguage = ({
  stepMessageNodeId,
}: {
  stepMessageNodeId: string | null | undefined
}): DeactivateMultiLanguageAction => {
  return {
    type: 'DEACTIVATE_MULTILANGUAGE',
    payload: { stepMessageNodeId },
  }
}
