import * as React from 'react'

import { ImageLoader, ImageLoaderRenderer } from 'components/common/image-loader'

import {
  NotifArea,
  NotifAppName,
  NotifMedia,
  NotifContent,
  NotifContentText,
  NotifContentTextMessage,
  NotifContentTextTitle,
  NotifContentBrowserThumb,
  NotifContentMediaThumb,
} from './preview.styles'

type NotifWebProps = {
  os: 'mac' | 'win'
  browser: 'firefox' | 'chrome' | 'safari'
  title: string
  message: string
  iconSafari: string | null | undefined
  website: string
  icon: string
  image: string
  style?: {
    [key: string]: string | number
  }
}
export const NotifWeb = ({
  os,
  browser,
  title,
  iconSafari,
  message,
  website,
  icon,
  style,
  image,
}: NotifWebProps): React.ReactElement => {
  if (os === 'win' && browser === 'firefox' && message.length > 200) {
    message = message.substring(0, 200) + '...'
  }

  const onImageLoad = React.useCallback(
    imgState => (
      <NotifContentMediaThumb
        style={{ marginLeft: icon && os === 'mac' ? 10 : 'unset' }}
        os={os}
        src={
          imgState === 'loading'
            ? '/medias/img/notif-preview/legacy/loading-spin.svg'
            : imgState === 'error'
              ? '/medias/img/notif-preview/legacy/placeholder_img.png'
              : icon
        }
      >
        &nbsp;
      </NotifContentMediaThumb>
    ),
    [icon, os]
  )

  return (
    <NotifArea browser={browser} os={os} style={style}>
      <NotifContent browser={browser}>
        {(os === 'mac' || !icon) && (
          <NotifContentBrowserThumb
            browser={browser}
            os={os}
            icon={browser === 'safari' && iconSafari !== null ? iconSafari : ''}
          />
        )}
        <NotifContentText style={{ marginLeft: icon && os === 'win' ? 10 : 'unset' }}>
          {browser !== 'safari' && (
            <NotifContentTextTitle browser={browser}>{title}</NotifContentTextTitle>
          )}
          {os === 'mac' && (
            <NotifAppName browser={browser}>
              {website} {browser === 'safari' && <span>Now</span>}
            </NotifAppName>
          )}
          {browser === 'safari' && (
            <NotifContentTextTitle browser={browser}>{title}</NotifContentTextTitle>
          )}
          <NotifContentTextMessage browser={browser} os={os}>
            {message}
          </NotifContentTextMessage>
          {os === 'win' && <NotifAppName>{website}</NotifAppName>}
        </NotifContentText>
        {!!icon && browser !== 'safari' && (
          <ImageLoaderRenderer imgSrc={icon} render={onImageLoad} />
        )}
      </NotifContent>
      {!!image && os === 'win' && browser === 'chrome' && (
        <NotifMedia>
          {image && <ImageLoader imgSrc={image} alt="Notification large image" />}
        </NotifMedia>
      )}
    </NotifArea>
  )
}
