import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { pluralize } from 'com.batch.common/utils'

import { type Platform } from 'com.batch/profile//infra/debug/models/shared.model'
import { platforms as listPlatforms } from 'com.batch/profile/constants/platforms'
import { ProfilePlatformsCounterContainer } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

type Props = {
  platforms: Array<Platform>
}

export const ProfilePlatformsCounter = ({ platforms }: Props): React.ReactElement => (
  <ProfilePlatformsCounterContainer>
    <span>{pluralize('platform', platforms.length)}</span>
    {platforms.map(p => (
      <Icon key={listPlatforms[p].icon} icon={listPlatforms[p].icon} />
    ))}
  </ProfilePlatformsCounterContainer>
)
