import styled from 'styled-components'

import { colors } from 'components/styled/tokens'

type LabelProps = {
  $isSelected: boolean
  $isDisabled: boolean | null | undefined
}
export const BoxedRadioLabel = styled.label<LabelProps>`
  cursor: pointer;
  width: 100%;
  height: 68px;
  margin-right: 12px;
  padding: 12px;

  border-radius: 8px;
  overflow: hidden;
  border: none;
  outline: ${p =>
    p.$isSelected && !p.$isDisabled
      ? `2px solid ${colors.fillAction};`
      : p.$isDisabled
        ? `1px solid ${colors.stroke};`
        : `1px solid ${colors.stroke};`};
  background: ${p => (p.$isSelected ? colors.fill : colors.fill)};
  .styled-option-symbol {
    flex: 0 0 16px;
  }

  .styled-boxedradio-title {
    font-size: 14px;
    padding-left: 4px;
    font-weight: 500;
    color: ${p =>
      p.$isDisabled ? colors.textDisabled : !p.$isSelected ? colors.textLight : colors.text};
  }
  .styled-boxedradio-text {
    font-weight: normal;
    font-size: 14px;
    color: ${p =>
      p.$isDisabled ? colors.textDisabled : !p.$isSelected ? colors.textLight : colors.text};
    line-height: 16px;
    margin-top: 4px;
  }
`
