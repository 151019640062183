import { animated } from '@react-spring/web'
import styled, { css } from 'styled-components'

import { Box, BoxFooter, BoxHeader, BoxBody } from 'components/common/box'

const thinScrollbar = css`
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 12px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    width: 8px;
    height: 36px;
    background-color: #dadbdb;
    border: 4px solid #fff;
  }
`

type SideSheetContainerProps = {
  $width: number
  $scrollable: boolean
  $template: string | null | undefined
}
export const SideSheetContainer = styled(animated.div)<SideSheetContainerProps>`
  position: fixed;
  z-index: 10;
  width: ${props => props.$width}px;
  transition: width 0.17s;
  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
  bottom: 0;
  top: 0;
  box-shadow: 0 1px 12px rgba(15, 15, 15, 0.08);
  ${props =>
    props.$scrollable
      ? css`
          overflow-y: auto;
          scroll-behavior: smooth;
          ${thinScrollbar}
        `
      : ''};

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  ${props =>
    props.$scrollable
      ? css`
          ${BoxHeader} {
            position: sticky;
            top: 0;
            z-index: 10;
          }

          ${BoxFooter} {
            position: sticky;
            bottom: 0;
            z-index: 10;
          }
        `
      : ''}

  ${Box} {
    display: grid;
    grid-template-rows: ${props => props.$template ?? 'auto 1fr auto'};
    margin-bottom: 0;
    border-radius: 0;
    border-top: 0;
    border-bottom: 0;
    ${props => (props.$scrollable ? 'min-height: 100vh;' : 'height: 100%;')}
  }

  ${BoxBody} {
    border-radius: 0;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #dadbdb transparent;
    ${thinScrollbar}
  }

  ${BoxFooter}, ${BoxHeader} {
    border-radius: 0 !important;
  }
`
