import * as React from 'react'

export const useDebounce = (
  callback: (...args: Array<any>) => any,
  wait: number = 100,
  leading: boolean = false
): ((...args: Array<any>) => any) => {
  const storedCallback = React.useRef(callback)

  React.useEffect(() => {
    storedCallback.current = callback
  })

  const timeout = React.useRef<ReturnType<typeof setTimeout> | null | undefined>()

  // Cleans up pending timeouts when the deps change
  React.useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current)
      timeout.current = undefined
    },
    [wait, leading, storedCallback]
  )

  return React.useCallback(
    function () {
      // eslint-disable-next-line prefer-rest-params
      const args = arguments
      const { current } = timeout
      // Calls on leading edge
      if (current === undefined && leading) {
        timeout.current = setTimeout(() => {
          timeout.current = undefined
        }, wait)
        // eslint-disable-next-line prefer-spread
        return storedCallback.current.apply(null, args)
      }
      // Clear the timeout every call and start waiting again
      current && clearTimeout(current)
      // Waits for `wait` before invoking the callback
      timeout.current = setTimeout(() => {
        timeout.current = undefined
        storedCallback.current.apply(null, args)
      }, wait)
    },
    [wait, leading, storedCallback]
  )
}
