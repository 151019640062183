/* eslint-disable react/jsx-no-bind */
import Immutable, { type List } from 'immutable'
import { get as _get } from 'lodash-es'
import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { FlexLineItem } from 'components/common/flexline'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Select } from 'components/form'
import { colors } from 'components/styled/tokens'

import { TriggerBadge } from './trigger-picker.styles'

import { AttributeFactory, type AttributeRecord, type AppRecord } from 'com.batch.redux/_records'
import {
  sdkCountMatchingTargetVersionSelector,
  lvlLoadingSelector,
} from 'com.batch.redux/sdk.selector'
import { fetchEventData } from 'com.batch.redux/stat.api'

type TriggerPickerProps = {
  app: AppRecord
  update: (trigger: string, triggerLabel?: string | null | undefined) => any
  trigger: string | null | undefined
  disabled?: boolean
  id?: string
  triggerLabel: string
  events: List<AttributeRecord>
}

export const TriggerPicker = ({
  app,
  id,
  events,
  disabled,
  trigger,
  triggerLabel,
  update,
}: TriggerPickerProps): React.ReactElement => {
  const getInstallMatchingSDKLevel = useSelector(sdkCountMatchingTargetVersionSelector)
  const loading = useSelector(lvlLoadingSelector)
  // SDK version 1.19 = API Level 50
  // we will hide now / asap trigger if we have more than 10 installs on SDK 1.19
  const nbInstalls119 = React.useMemo(() => {
    return getInstallMatchingSDKLevel(50)
  }, [getInstallMatchingSDKLevel])

  const TriggerFormater = React.useMemo(
    () =>
      (attribute: AttributeRecord, { context }) => {
        if (!attribute) return <div />
        const isDeprecated = !loading && attribute.id === 'now'
        const isUpdated = !loading && attribute.id === 'next_session' && nbInstalls119 > 10
        return (
          <Tooltip
            isTooltipEmpty={!isDeprecated && !isUpdated}
            placement="left"
            minWidth={240}
            offset={[0, 20]}
            tooltip={
              isUpdated
                ? 'You will display to your targeting your campaigns with more efficiency.'
                : 'New session trigger was improved in SDK 1.19 and is meant to replace this legacy trigger.'
            }
          >
            <Grid template={context === 'menu' && (isDeprecated || isUpdated) ? '1fr auto' : '1fr'}>
              {attribute.label}
              {context === 'menu' && (
                <React.Fragment>
                  {isDeprecated && <TriggerBadge kind="deprecated">Deprecated</TriggerBadge>}
                  {isUpdated && <TriggerBadge kind="updated">Updated</TriggerBadge>}
                </React.Fragment>
              )}
            </Grid>
          </Tooltip>
        )
      },
    [nbInstalls119, loading]
  )

  const triggers: List<AttributeRecord> = React.useMemo(() => {
    const foundTriggers = events
      .filter(ev => !ev.id.startsWith('be.')) // filter native events
      .unshift(
        AttributeFactory({
          id: 'now',
          label: 'AS SOON AS POSSIBLE',
        })
      )
      .unshift(
        AttributeFactory({
          id: 'next_session',
          label: 'NEW SESSION',
        })
      )
      .filter(ev => ev.id !== 'now' || nbInstalls119 < 10 || trigger === 'now')
    // si on a pas l'attribut courant, on l'ajoute à la liste
    return foundTriggers.filter(attr => attr.id === trigger).size === 1 || !trigger
      ? foundTriggers
      : foundTriggers.push(
          AttributeFactory({
            id: trigger,
            label: trigger,
          })
        )
  }, [events, nbInstalls119, trigger])

  const warnNotFound = React.useMemo(
    () =>
      events.filter(ev => !ev.id.startsWith('be.')).size > 0 &&
      trigger !== 'now' &&
      trigger !== 'next_session' &&
      events.filter(ev => ev.id === trigger).size === 0,
    [events, trigger]
  )
  const attribute = triggers.find(t => t.id === trigger) || AttributeFactory()
  const loadOptions = React.useCallback(
    (
      query: string
    ): Promise<
      List<{
        label: string
        value: string
      }>
    > => {
      return fetchEventData({
        appId: app.id,
        eventId: attribute.id,
        eventDataType: 'label',
        eventDataAttributeKey: 'Label',
        query,
      }).then(res => {
        const opt = Immutable.List<{
          label: string
          value: string
        }>(
          _get(res.body.results, attribute.id, [])
            .filter(l => l.value !== '')
            .map(l => ({ value: l.value, label: l.value }))
        )
        return opt
      })
    },
    [attribute.id, app.id]
  )
  return (
    <React.Fragment>
      <FlexLineItem grow={2}>
        <Select
          placeholder="Pick an event"
          id={id}
          isSearchable
          menuOffset={0}
          noResultsNode="No event found"
          isDisabled={!!disabled}
          optionToString={opt => opt?.label ?? ''}
          optionFormatter={TriggerFormater}
          value={attribute.id ? attribute : null}
          onChange={attribute => update(attribute ? attribute.id : '', null)}
          options={triggers}
        />
      </FlexLineItem>
      {!!(attribute && attribute.allowedKeys.filter(ed => ed.type === '__LABEL__').size === 1) && (
        <FlexLineItem grow={2}>
          <Select
            isSearchable
            isClearable
            noResultsNode="No label found"
            isDisabled={!!disabled}
            key={attribute.id}
            placeholder="Event label (optional)"
            loadOptions={loadOptions}
            optionToString={opt => opt?.label ?? ''}
            optionCreator={option => ({ label: option, value: option })}
            onChange={l => update(attribute ? attribute.id : '', l ? l.value : null)}
            value={triggerLabel ? { label: triggerLabel, value: triggerLabel } : null}
          />
        </FlexLineItem>
      )}
      {warnNotFound && (
        <FlexLineItem>
          <Tooltip tooltip="This event is unknown to Batch.">
            <Icon icon="danger" color={colors.fillDanger} thickness={1.5} />
          </Tooltip>
        </FlexLineItem>
      )}
    </React.Fragment>
  )
}
