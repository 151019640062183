import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ToolbarContainer } from 'components/campaign/review/review/rsr.styles'
import { LightHeaderMain, LightHeaderSub } from 'components/styled/blocs'
import { SubNavLink, SubNav } from 'components/styled/subnav'
import { TagLabel } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import ReviewCampaignDate from './review-campaign-date'

export const Link = styled(SubNavLink.withComponent(NavLink))`
  padding: 0.8em 0 0.8em 0;
  padding: 12px 0 11px 0;
  padding: 12px 0;
`
type SubLinkProps = {
  active?: boolean
}
export const SubLink = styled.a<SubLinkProps>`
  padding: 5px 4px 7px 4px;
  color: ${props => (props.active ? colors.text : colors.textLight)};
  cursor: pointer;

  &:hover {
    color: ${colors.text};
  }
`

export const ReviewHeader = styled(LightHeaderMain)`
  display: flex;
  align-items: flex-start;
  padding-bottom: 22px;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`

export const ReviewDate = styled(ReviewCampaignDate)<{
  separator?: boolean
}>`
  margin: 0 13px 0 0;

  ${(props: { separator?: boolean }) =>
    props.separator
      ? css`
          &:after {
            display: inline-block;
            vertical-align: -4px;
            width: 1px;
            height: 16px;
            margin: 0 0 0 12px;
            content: '';
            background-color: #e6e8ee;
          }
        `
      : ''}
`

export const ReviewInfos = styled.div`
  display: block;
  flex: 1 0 0;
  min-width: 0;
`

export const ReviewStatus = styled.span`
  display: inline-block;
`

export const ReviewTitle = styled.div`
  display: flex;
  align-items: center;
  line-height: 19px;
  margin: -4px 0 6px 0;

  h1 {
    display: block;
    font-size: 24px;
    line-height: 34px;
    overflow: hidden;

    @media (max-width: 1300px) {
      font-size: 20px;
    }

    > span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  ${ReviewStatus}, ${TagLabel} {
    display: block;
    flex: 0 0 auto;
    min-width: 0;
  }
  ${ReviewStatus} {
    align-self: middle;
    margin-top: 4px;
    margin: 4px 10px 0 0;
  }
  ${TagLabel} {
    margin: 0 0 0 12px;
  }
`

export const ReviewActions = styled.div`
  display: flex;
  flex: 0 1 auto;
  min-width: 0;
  align-items: center;
  margin: -2px 0 0 46px;

  @media (max-width: 650px) {
    width: 100%;
    margin: 26px 0 -10px 0;
  }
`

export const ReviewNav = styled(LightHeaderSub)`
  background-color: ${props => (props.theme.fixed ? '#FFF' : '#F6F7FA')};
  background-color: #f8f8f8;
  background-color: #fafafa;
  background-color: #fbfbfb;
  background-color: ${props => (props.theme.fixed ? '#fff' : '#fbfbfb')};
`

const ARROW_ANGLE = 24
export const ReviewNavMain = styled(SubNav)`
  display: block;
  position: relative;
  overflow: hidden;
  padding: 0 ${ARROW_ANGLE + 22}px 0 0;
  margin: 0 8px 0 0;
  font-size: 15px;

  ${Link} {
    position: relative;
    margin: 0 24px 0 0;
    z-index: 20;
    background-color: ${props => (props.theme.fixed ? 'transparent' : '#F6F7FA')};
    background-color: transparent;

    &:last-child {
      margin: 0;
    }
  }

  &:before,
  :after {
    display: block;
    position: absolute;
    z-index: 10;
    top: 0;
    right: ${ARROW_ANGLE / 2}px;
    width: 20px;
    height: 100%;
    content: '';
    background-color: ${props => (props.theme.fixed ? 'transparent' : '#F6F7FA')};
    background-color: transparent;
    border-right: 1px solid #e6e9f1;

    @media screen and (max-width: 1150px) {
      display: none;
    }
  }
  &:before {
    transform-origin: 100% 100%;
    transform: translate3d(0, -50%, 0) rotate(-${ARROW_ANGLE}deg);
  }
  &:after {
    transform-origin: 100% 0;
    transform: translate3d(0, 50%, 0) rotate(${ARROW_ANGLE}deg);
  }
`

export const ReviewNavSecond = styled.div``

export const ReviewNavSecondLinks = styled(SubNav)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  font-size: 13px;
  text-align: left;

  ${SubLink} {
    display: inline-flex;
    margin: 0 12px 0 0;
  }

  @media (max-width: 650px) {
    overflow-x: scroll;
    padding: 0 0 8px 0;
    scrollbar-width: 0;

    &::-webkit-scrollbar {
      height: 0 !important;
    }
  }
`

export const ReviewNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${ReviewNavSecond} {
    flex: 1;
  }

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    align-items: flex-start;

    ${Link} {
      padding: 12px 0 6px 0;
    }

    ${ReviewNavMain} {
      margin: 2px 0 0 0;
    }
    ${ReviewNavSecond} {
      width: 100%;
      margin: 3px 0 3px 0;

      > div {
        align-items: flex-start;
      }
    }

    ${ToolbarContainer} {
      transform: translate3d(0, -50%, 0);
    }
  }

  @media screen and (max-width: 650px) {
    ${ReviewNavSecond} {
      margin: 4px 0 -4px 0;
    }
  }
`
