import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'

import { getStableNumberFromTextEnd } from 'com.batch.common/utils'

type Props = {
  profileId: string
}

export const ProfileAvatar = ({ profileId }: Props): React.ReactElement => {
  return (
    <Skeleton w={38} h={40} style={{ borderRadius: '100%' }}>
      <img
        src={`/medias/img/avatars/Avatar_${getStableNumberFromTextEnd(profileId)}.png`}
        alt="avatar"
        width="38"
        height="40"
      />
    </Skeleton>
  )
}
