import { type List } from 'immutable'
import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'

import { PlatformModal } from './platform-modal'
import { ProfilePlatformsList } from './profile-platform-list'

import { type Platform } from 'com.batch/profile/infra/debug/models/shared.model'
import {
  type InstallRecord,
  type ProfileWebpushPlatformsRecord,
} from 'com.batch/profile/models/profile.records'
import {
  ProfileBodyTitle,
  ProfilePlatformContainer,
  PlatformsContainer,
} from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

type Props = {
  isLoading: boolean
  mobilePlatforms: List<InstallRecord>
  webpushPlatforms: List<ProfileWebpushPlatformsRecord>
  findDebugUrl: (installId: string, platform: Platform) => string
  platformsCount: number
}

export const ProfilePlatforms = ({
  isLoading,
  mobilePlatforms,
  webpushPlatforms,
  findDebugUrl,
  platformsCount,
}: Props): React.ReactElement => {
  const [selectedMobilePlatformId, setSelectedMobilePlatformId] = React.useState<string | null>(
    null
  )

  const onModalClosed = React.useCallback(() => setSelectedMobilePlatformId(null), [])

  const mobilePlatformOnView = React.useMemo(
    () => mobilePlatforms.find(f => f.installID === selectedMobilePlatformId) ?? null,
    [mobilePlatforms, selectedMobilePlatformId]
  )

  return (
    <React.Fragment>
      <ProfilePlatformContainer>
        <ProfileBodyTitle>
          <Skeleton w={22} h={22} style={{ marginRight: 8 }}>
            <Icon icon="device" />
          </Skeleton>
          <Skeleton w={84} h={22} style={{ marginRight: 8 }}>
            <h2>Platforms</h2>
          </Skeleton>
        </ProfileBodyTitle>
        <PlatformsContainer>
          <ProfilePlatformsList
            isLoading={isLoading}
            setSelectedPlatform={setSelectedMobilePlatformId}
            mobilePlatforms={mobilePlatforms}
            webpushPlatforms={webpushPlatforms}
            findDebugUrl={findDebugUrl}
            platformsCount={platformsCount}
          />
        </PlatformsContainer>
      </ProfilePlatformContainer>
      {mobilePlatformOnView && (
        <PlatformModal
          opened={!!mobilePlatformOnView}
          close={onModalClosed}
          platform={mobilePlatformOnView}
          findDebugUrl={findDebugUrl}
        />
      )}
    </React.Fragment>
  )
}
