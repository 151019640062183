import type Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useNavigate } from 'react-router-dom'

import { useQuery } from 'components/_hooks'

import { generateUrl } from 'com.batch.common/router'

import {
  DateRangeFilterFactory,
  type CampaignActiveFiltersRecord,
  type CampaignFilterRecord,
} from 'com.batch.redux/_records'
import { UPDATE_CAMPAIGN_CONFIG } from 'com.batch.redux/action-name'
import { currentAppSelector } from 'com.batch.redux/app'
import { buildActiveFilters } from 'com.batch.redux/campaign.api.filters'
import { campaignConfigSelector } from 'com.batch.redux/campaign.selector'

type FiltersProviderProps = {
  children: React.ReactNode
}

type SortValue = {
  field: string
  direction: string
}

export type ParamsName =
  | 'sort'
  | 'page'
  | 'query'
  | 'status'
  | 'sources'
  | 'when'
  | 'channels'
  | 'dateRange'
  | 'labels'

type StateFilters = {
  filters: CampaignActiveFiltersRecord
  updateLocalDateRange: (...args: Array<any>) => any
  localActiveFilters: CampaignActiveFiltersRecord
  setLocalActiveFilters: (arg1: CampaignActiveFiltersRecord) => any
  updateLocalFilters: (arg1: CampaignFilterRecord) => any
  goToFilteredURL: (arg1: ParamsName, arg2: string | number | SortValue) => void
  goToURLWithNewFilters: () => void
  gotToURLWithoutFilters: () => void
}

const getQueryParam = (queryString: URLSearchParams, key: string, defaultValue: string): string => {
  const data = queryString.get(key)
  return data !== null ? data : defaultValue
}

const convertFilterToStr = (
  filters: Immutable.Set<CampaignFilterRecord>,
  filterName: ParamsName
): string => {
  return filters
    .filter(f => f.category === filterName)
    .map(f => f.name)
    .join('|')
}

const FiltersContext = React.createContext<StateFilters | null | undefined>(null)

export const FiltersProvider = ({ children }: FiltersProviderProps): React.ReactElement => {
  const queryString = useQuery()
  const queryStr = queryString.toString()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const config = useSelector(campaignConfigSelector)
  const schedulingType = config.get('schedulingType')
  const isAutomation = schedulingType === 'automations'
  const { id: appId, companyId } = useSelector(currentAppSelector)
  const [localActiveFilters, setLocalActiveFilters] = React.useState<CampaignActiveFiltersRecord>(
    config.filters
  )

  const updateLocalFilters = React.useCallback(
    (filter: CampaignFilterRecord) => {
      setLocalActiveFilters(
        localActiveFilters.set(
          'commons',
          localActiveFilters.commons.has(filter)
            ? localActiveFilters.commons.delete(filter)
            : localActiveFilters.commons.add(filter)
        )
      )
    },
    [localActiveFilters, setLocalActiveFilters]
  )

  const goToURLWithNewFilters = React.useCallback(() => {
    const { dateRange, commons, query, labels } = localActiveFilters
    const url = generateUrl('campaign_list', {
      companyId,
      appId,
      schedulingType,
      filters: {
        name: query,
        from: dateRange?.from?.format('YYYY-MM-DD') ?? null,
        to: dateRange?.to?.format('YYYY-MM-DD') ?? null,
        status: convertFilterToStr(commons, 'status'),
        sources: convertFilterToStr(commons, 'sources'),
        when: convertFilterToStr(commons, 'when'),
        channels: isAutomation ? convertFilterToStr(commons, 'channels') : null,
        labels: labels.join('|'),
      },
      sortBy: config.get('sortBy'),
      sortOrder: config.get('sortOrder'),
    })

    navigate(url)
  }, [config, appId, companyId, localActiveFilters, isAutomation, schedulingType, navigate])

  const goToFilteredURL = React.useCallback(
    (paramsName: ParamsName, value: any) => {
      const url = generateUrl('campaign_list', {
        companyId,
        appId,
        schedulingType,
        filters: config.filters && {
          from:
            paramsName === 'dateRange'
              ? value
                ? value.from.format('YYYY-MM-DD')
                : null
              : config.filters.dateRange
                ? config.filters.dateRange.from.format('YYYY-MM-DD')
                : null,
          to:
            paramsName === 'dateRange'
              ? value
                ? value.to.format('YYYY-MM-DD')
                : null
              : config.filters.dateRange
                ? config.filters.dateRange.to.format('YYYY-MM-DD')
                : null,
          status: convertFilterToStr(
            paramsName === 'status' ? value : config.filters.commons,
            'status'
          ),
          sources: convertFilterToStr(
            paramsName === 'sources' ? value : config.filters.commons,
            'sources'
          ),
          when: convertFilterToStr(paramsName === 'when' ? value : config.filters.commons, 'when'),
          channels: isAutomation
            ? convertFilterToStr(
                paramsName === 'channels' ? value : config.filters.commons,
                'channels'
              )
            : null,
          labels: (paramsName === 'labels' ? value : config.filters.labels).join('|'),
          name: paramsName === 'query' ? value : config.filters.query,
        },
        sortBy: paramsName === 'sort' ? value.field : config.get('sortBy'),
        sortOrder: paramsName === 'sort' ? value.direction : config.get('sortOrder'),
        page:
          paramsName === 'page' && (typeof value === 'number' || typeof value === 'string')
            ? value
            : '1',
      })

      navigate(url)
    },

    [appId, companyId, config, navigate, isAutomation, schedulingType]
  )

  const gotToURLWithoutFilters = React.useCallback(() => {
    window.localStorage.removeItem(`${schedulingType}-filters`)
    const url = generateUrl('campaign_list', {
      companyId,
      appId,
      schedulingType,
      sortBy: config.get('sortBy'),
      sortOrder: config.get('sortOrder'),
    })
    navigate(url)
  }, [companyId, appId, schedulingType, navigate, config])

  const updateLocalDateRange = React.useCallback(
    (dateRange, refresh = false) => {
      const newFilters = localActiveFilters.set(
        'dateRange',
        dateRange !== null
          ? DateRangeFilterFactory({ from: dateRange.from, to: dateRange.to })
          : null
      )
      setLocalActiveFilters(newFilters)
      if (refresh) {
        goToFilteredURL('dateRange', dateRange)
      }
    },
    [localActiveFilters, goToFilteredURL]
  )

  React.useEffect(() => {
    const newConfig = config
      .set('sortBy', getQueryParam(queryString, 'sortBy', 'id'))
      .set('sortOrder', getQueryParam(queryString, 'sortOrder', 'dsc') === 'dsc' ? 'dsc' : 'asc')
      .set('page', parseInt(getQueryParam(queryString, 'page', '1')))
      .set(
        'filters',
        buildActiveFilters({
          queryString,
          platform: (window?.initialData?.app?.platform as Platforms) ?? 'ios',
          schedulingType,
          persistedCommaSeparatedFilters: !config.filtersFilled
            ? String(window.localStorage.getItem(`${schedulingType}-filters`))
            : '',
        })
      )
      .set('filtersFilled', true)

    if (!newConfig.equals(config)) {
      dispatch({
        type: UPDATE_CAMPAIGN_CONFIG,
        payload: {
          previousConfig: config,
          config: newConfig,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStr, schedulingType])

  return (
    <FiltersContext.Provider
      value={{
        filters: config.filters,
        updateLocalDateRange,
        gotToURLWithoutFilters,
        goToFilteredURL,
        goToURLWithNewFilters,
        updateLocalFilters,
        localActiveFilters,
        setLocalActiveFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export const useFilters = (): StateFilters => {
  const ctx = React.useContext(FiltersContext)
  if (!ctx) throw 'Context must be not empty'
  return { ...ctx }
}
