import * as React from 'react'

import Highlight from 'components/common/highlight'
import { Tabs, Tab } from 'components/common/tabbed'

import { type SdkRecord } from 'com.batch.redux/_records'

type SetupIosProps = {
  apiKey: string
  devApiKey: string
  sdk: SdkRecord | null | undefined
  hasDevApiKeyFeature: boolean
}
const SetupIos = ({
  apiKey,
  devApiKey,
  sdk,
  hasDevApiKeyFeature,
}: SetupIosProps): React.ReactElement => {
  return (
    <div>
      <h3>Get the SDK with CocoaPods</h3>
      <p>First, simply add this line to your Podfile to integrate Batch in your project:</p>
      <Highlight language="bash">
        {`pod 'Batch', '~> ${
          !!sdk && sdk.packageManagerMinVersion ? sdk.packageManagerMinVersion : ''
        }'`}
      </Highlight>
      <p>
        Then, run pod install in your Podfile folder, and open the .xcworkspace that was created.
        You're ready to go! In order to update Batch SDK, simply run pod update in that directory.
      </p>
      <p>
        If you don't have a Podfile or are unsure on how to proceed,{' '}
        <a href="http://guides.cocoapods.org/using/using-cocoapods.html" target="_blank">
          see the CocoaPods usage guide
        </a>
        .
      </p>
      <h3>Get the SDK with Carthage</h3>
      <p>
        <i>Batch only supports Carthage 0.30 and higher</i>
        <br />
        Simply add this line to your Cartfile to integrate Batch in your project:
      </p>
      <Highlight language="bash">{'github "BatchLabs/ios-sdk"'}</Highlight>
      <p>
        <i>Do not add Batch to the "carthage copy-frameworks" script input/output.</i>
      </p>
      <p>
        If you don't want to use <strong>CocoaPods</strong> or <strong>Carthage</strong>, you can{' '}
        <a
          href={`https://download.batch.com/sdk/ios/BatchSDK-ios-${
            sdk && sdk.version ? sdk.version : ''
          }.zip`}
        >
          download the SDK here
        </a>{' '}
        and follow{' '}
        <a href="https://doc.batch.com/ios/advanced/general.html#_manual-sdk-integration">
          our <strong>manual setup</strong> guide
        </a>
        .
      </p>
      <h3>Then, integrate Batch in your application delegate</h3>

      <p>
        To ask for the permission to display notifications and register the current device for push,
        you can either call the method [BatchPush&nbsp;requestNotificationAuthorization] or use the
        standard Apple methods.
      </p>

      <Tabs>
        <Tab title="Objective C">
          <Highlight language="objc">
            {`@import Batch;

    - (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions
     {
        // Start Batch.
        ${
          hasDevApiKeyFeature
            ? `// TODO : switch to live api key before store release
        [Batch startWithAPIKey:@"${devApiKey}"]; // dev
        // [Batch startWithAPIKey:@"${apiKey}"]; // live`
            : `[Batch startWithAPIKey:@"${apiKey}"];`
        }

        // Ask for the permission to display notifications
        // The push token will automatically be fetched by the SDK
        [BatchPush requestNotificationAuthorization];

        // Alternatively, you can call requestNotificationAuthorization later
        // But, you should always refresh your token on each application start
        // This will make sure that even if your user's token changes, you still get notifications
        // [BatchPush refreshToken];

        // Sets Batch as your UNUserNotificationCenterDelegate.
        // This will disable the legacy callbacks on your app delegate (didReceiveRemoteNotification, ...).
        // If you rely on those, do not add this line and please consider migrating to the UserNotification framework.
        //
        // If you already have a UNUserNotificationCenterDelegate implementation, do not add this line.
        // Instead, add Batch's callbacks in your implementation. See 'Advanced > Intercepting notifications', 
        [BatchUNUserNotificationCenterDelegate registerAsDelegate];
    
        return YES;
    }`}
          </Highlight>
        </Tab>
        <Tab title="Swift">
          <Highlight language="swift">
            {`func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplicationLaunchOptionsKey: Any]?) -> Bool {
        // Start Batch.
        ${
          hasDevApiKeyFeature
            ? `// TODO : switch to live api key before store release
        Batch.start(withAPIKey: "${devApiKey}") // dev
        // Batch.start(withAPIKey: "${apiKey}") // live`
            : `Batch.start(withAPIKey: "${apiKey}")`
        }
        
        // Ask for the permission to display notifications
        // The push token will automatically be fetched by the SDK
        BatchPush.requestNotificationAuthorization()

        // Alternatively, you can call requestNotificationAuthorization later
        // But, you should always refresh your token on each application start
        // This will make sure that even if your user's token changes, you still get notifications
        // BatchPush.refreshToken();

        // Sets Batch as your UNUserNotificationCenterDelegate.
        // This will disable the legacy callbacks on your app delegate (didReceiveRemoteNotification, ...).
        // If you rely on those, do not add this line and please consider migrating to the UserNotification framework.
        //
        // If you already have a UNUserNotificationCenterDelegate implementation, do not add this line.
        // Instead, add Batch's callbacks in your implementation. See 'Advanced > Intercepting notifications', 
        BatchUNUserNotificationCenterDelegate.registerAsDelegate()

        return true
    }
    `}
          </Highlight>
        </Tab>
      </Tabs>

      <p>
        <strong>Important note:</strong> If your application already implements
        UNUserNotificationCenterDelegate, you will also have to integrate Batch in it.{' '}
        <a href="https://doc.batch.com/ios/advanced/intercepting-notifications.html#_ios-10">
          More info here
        </a>
        .
      </p>

      <blockquote>
        <p>
          This assumes that your provision has correctly been configured for push. If you need more
          information, please check the full documentation.
        </p>
      </blockquote>

      <h3>What's next</h3>
      <p>Here are a couple of extra steps you need to take before releasing your app:</p>
      <ul>
        <li>
          <strong>Rich notifications:</strong> Add support for iOS 10{' '}
          <a href="https://doc.batch.com/ios/sdk-integration/rich-notifications-setup.html">
            rich push notifications
          </a>
          .
        </li>
        <li>
          <strong>Mobile Landings:</strong> Make sure{' '}
          <a href="https://doc.batch.com/ios/mobile-landings.html">Mobile Landings</a> are set up
          correctly.
        </li>
        <li>
          <strong>Custom user identifier:</strong> Add support for{' '}
          <a href="https://doc.batch.com/ios/custom-data/customid.html">custom user identifiers</a>{' '}
          if you are planning to use the{' '}
          <a href="https://doc.batch.com/api/transactional.html">Transactional</a> and{' '}
          <a href="https://doc.batch.com/api/custom-data-api/set-update.html">Custom Data</a> APIs.
        </li>
        <li>
          <strong> Analytics: </strong>
          <a href="https://doc.batch.com/ios/advanced/event-dispatchers">
            Add an event dispatcher
          </a>{' '}
          to automatically track your campaigns in your third-party analytics tool.
        </li>
      </ul>
    </div>
  )
}

export default SetupIos
