import styled from 'styled-components'

import { colors } from 'components/styled/tokens'

export const DeliveryChartMiddleTextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;

  & > span {
    color: ${colors.textLight};
  }
`

export const DeliveryChartTitle = styled.h1<any>`
  font-weight: 300;
  font-size: 48px;

  & > span {
    font-weight: 400;
    font-size: 20px;
  }
`

export const DeliveryChartLegendTitle = styled.h1<any>`
  font-size: 32px;
  font-weight: 300;
  width: max-content;
  line-height: normal;
`
