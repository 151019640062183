import styled, { css } from 'styled-components'

import { Grid } from 'components/common/grid'
import { schemes } from 'components/styled/tokens'
import { fillDanger, fillWarning, textContrast } from 'components/styled/tokens/colors'

// ====================== STYLED COMPONENTS
const intentBannerColors = {
  danger: fillWarning,
  blocked: fillDanger,
  limited: schemes.grayscale['40'],
}
type BannerContainerProps = {
  intent?: 'danger' | 'blocked' | 'limited'
  kind?: 'global' | 'bloc'
  style: Record<any, any>
}
export const BannerContainer = styled.div<BannerContainerProps>`
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 9px ${p => (p.kind === 'bloc' ? 24 : 32)}px;
  background-color: ${p => intentBannerColors[p.intent ?? 'blocked']};

  ${p =>
    p.kind === 'global' &&
    css`
      & {
        border-bottom: 1px solid ${schemes.lightlucent['70']};

        &:last-of-type {
          border-bottom: 0;
        }
      }
    `}
  ${Grid} {
    width: 100%;
  }
`

export const BannerIconContainer = styled.div`
  position: relative;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  & > div {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    background-color: ${schemes.darklucent['20']};
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
`
type BannerTextContainerProps = {
  hasContent: boolean
}
export const BannerTextContainer = styled.div<BannerTextContainerProps>`
  h4,
  p {
    font-size: 13px;
    line-height: 18px;
  }

  h4 {
    font-weight: 500;
    color: ${schemes.lightlucent['100']};
    margin-bottom: ${p => (p.hasContent ? 2 : 0)}px;
  }

  p {
    font-weight: normal;
    color: ${textContrast};
  }
`

export const BannerLinkContainer = styled.div`
  padding-left: 24px;
`

export const BannerCloseContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 20px;
  width: 28px;

  button {
    margin-top: -6px;
    margin-bottom: -6px;

    &:after {
      background-color: transparent;
    }

    &:hover:after {
      background-color: ${schemes.darklucent['40']};
    }

    &:focus:after {
      background-color: ${schemes.darklucent['50']};
    }
  }
`
