import Immutable from 'immutable'
import * as React from 'react'

import { filters } from 'components/campaign/campaign-list-utils'
import { Filter } from 'components/campaign/filters/filter'
import { FilterSelectMulti } from 'components/filter'
import { Checkbox } from 'components/form'
import { CampaignStateIcon } from 'components/project/campaign/common/campaign-state-icon'

import {
  type CampaignActiveFiltersRecord,
  type CampaignFilterRecord,
} from 'com.batch.redux/_records'

type StatusFilterProps = {
  localActiveFilters: CampaignActiveFiltersRecord
  onChange: (arg1?: any) => void
  isEmbedded: boolean
  isAutomation: boolean
}

const campaignStates: Array<campaignStateType> = ['NEW', 'DRAFT', 'RUNNING', 'STOPPED', 'COMPLETED']

const optionToString = (opt?: CampaignFilterRecord | null) => opt?.label ?? ''

export const StatusFilter = ({
  localActiveFilters,
  onChange,
  isEmbedded,
  isAutomation,
}: StatusFilterProps): React.ReactElement => {
  const status = React.useMemo(() => {
    return filters.filter(f => f.category === 'status')
  }, [])

  const selectedStatus = React.useMemo(() => {
    let selected = Immutable.List<CampaignFilterRecord>()
    status.map(st => {
      if (localActiveFilters.commons.has(st)) {
        selected = selected.push(st)
      }
    })
    return selected
  }, [localActiveFilters, status])

  const formatOptionEmbedded = React.useCallback(
    (option: CampaignFilterRecord) =>
      option.name === 'running' && !isAutomation ? 'Planned' : option.label,
    [isAutomation]
  )

  const formatOption = React.useCallback(
    (option: CampaignFilterRecord) => {
      const state: campaignStateType =
        campaignStates.find(campaignState => campaignState === option.name.toUpperCase()) ||
        'STOPPED'

      return (
        <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {option.name === 'running' && !isAutomation ? 'Planned' : option.label}{' '}
          <CampaignStateIcon
            state={state}
            schedulingType={isAutomation ? 'automations' : 'campaigns'}
          />
        </span>
      )
    },
    [isAutomation]
  )

  const term = React.useCallback(
    count => (count === 1 ? formatOptionEmbedded(selectedStatus.first()) : `${count} status`),
    [formatOptionEmbedded, selectedStatus]
  )

  const handleStatus = React.useCallback(st => () => onChange(st), [onChange])

  return (
    <Filter isEmbedded={isEmbedded} title="Status">
      {isEmbedded ? (
        filters
          .filter(f => f.category === 'status')
          .map(status => (
            <Checkbox
              key={status.name}
              name={status.name}
              label={formatOptionEmbedded(status)}
              checked={localActiveFilters.commons.has(status)}
              onChange={handleStatus(status)}
              className={`status_${status.name}`}
            />
          ))
      ) : (
        <FilterSelectMulti
          options={status.toList()}
          value={selectedStatus}
          placeholder="Any status"
          term={term}
          onChange={onChange}
          isSearchable={false}
          optionToString={optionToString}
          optionFormatter={formatOption}
        />
      )}
    </Filter>
  )
}
