import styled, { css } from 'styled-components'

import { Button } from 'components/common/button'
import { IconContainer } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'

export const PushFilterButton = styled(Button)`
  position: relative;
  text-align: left;

  &,
  &:hover,
  &:focus {
    color: ${colors.textLight};
  }

  & > span {
    margin-right: 35px;
  }

  .clear-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 100%;
    top: 0;
    right: 0;
  }
`

type PushFilterOptionProps = {
  kind: 'categorie' | 'subCategorie'
}
export const PushFilterOption = styled.div<PushFilterOptionProps>`
  margin-bottom: 4px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${Button} {
    display: inline;
    padding: 0 10px;

    ${IconContainer} {
      margin-right: 8px;
    }
  }

  ${p =>
    p.kind === 'subCategorie' &&
    css`
      margin-left: 16px;
    `}
`
