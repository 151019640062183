import styled from 'styled-components'

import { Box, BoxBody, BoxFooter, BoxHeader } from 'components/common/box'
import { schemes } from 'components/styled/tokens'
import { textLight, textNeutral } from 'components/styled/tokens/colors'

export const ResetApiKeyModalContainer = styled(Box)`
  width: 380px;

  ${BoxHeader} {
    border-bottom: 0;
  }

  ${BoxFooter} {
    border-top: 0;
  }

  ${BoxBody} {
    padding: 0 12px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 12px;

      &:not(:first-child) {
        border-top: 1px solid ${schemes.darklucent['20']};
      }

      > h4,
      > p {
        line-height: 20px;
      }

      > h4 {
        font-weight: 500;
        color: ${textNeutral};
      }

      > p {
        color: ${textLight};
      }
    }
  }
`
