import * as React from 'react'
import { useDispatch } from 'components/console/react-redux'

import { deleteCompany } from 'components/console/redux/company'
import { AuthCode } from 'components/form'

type Props = {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  companyId: number
  companyIdValue: number
  code: string
  setCode: (code: string) => void
  error: string
  setError: (error: string) => void
  step: number
  setStep: (step: number) => void
}

export const TwoFactorAuthStep = ({
  code,
  setCode,
  isLoading,
  setIsLoading,
  error,
  setError,
  setStep,
  companyIdValue,
  companyId,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()

  const handleOnFocus2FA = React.useCallback(() => {
    if (!isLoading) {
      setCode('')
    }
  }, [setCode, isLoading])

  React.useEffect(() => {
    if (code.length === 6 && !isLoading) {
      setIsLoading(true)
      dispatch(deleteCompany({ companyId, code, companyIdValue, email: window.user.email }))
        .then(() => {
          setStep(3)
        })
        .catch(e => {
          setError(e.error)
          setStep(2)
        })
        .finally(() => {
          setCode('')
          setIsLoading(false)
        })
    }
  }, [
    code,
    companyIdValue,
    companyId,
    dispatch,
    isLoading,
    setIsLoading,
    setStep,
    setError,
    setCode,
  ])

  return (
    <React.Fragment>
      <p>Validate this action with 2-factor authentication:</p>
      <AuthCode
        loading={isLoading}
        inputMode="numeric"
        pattern="[0-9]*"
        autoComplete="one-time-code"
        onChange={setCode}
        onFocus={handleOnFocus2FA}
        invalid={!!error}
        feedback={error}
        value={code}
      />
    </React.Fragment>
  )
}
