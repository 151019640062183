import { type Dayjs } from 'dayjs'
import Immutable, { type RecordOf } from 'immutable'

import {
  MessageConfigFactory,
  type MessageConfigRecord,
} from '../../message/models/message.records'
import { type pushCampaignSendType } from 'com.batch.redux/_records'

export type OneTimeProps = {
  sendDate: Dayjs | null | undefined
}
export type OneTimeRecord = RecordOf<OneTimeProps>
export const OneTimeFactory = Immutable.Record<OneTimeProps>({
  sendDate: undefined,
} as OneTimeProps)
export type RecurrentProps = {
  startDate: Dayjs | null | undefined
  endDate: Dayjs | null | undefined
  recurrence: 'DAILY' | 'WEEKLY' | 'MONTHLY'
  frequency: number
  capping: number
  hasCapping: boolean
}
export type RecurrentRecord = RecordOf<RecurrentProps>
export const RecurrentFactory = Immutable.Record<RecurrentProps>({
  frequency: 1,
  recurrence: 'WEEKLY',
  startDate: undefined,
  endDate: undefined,
  hasCapping: false,
  capping: 1,
} as RecurrentProps)

type OrchestrationCampaignProps = {
  sendType: pushCampaignSendType
  oneTime: OneTimeRecord
  recurrent: RecurrentRecord
  tzAware: boolean
  messageConfig: MessageConfigRecord
}

export type OrchestrationCampaignRecord = RecordOf<OrchestrationCampaignProps>
export const OrchestrationCampaignFactory = Immutable.Record<OrchestrationCampaignProps>({
  sendType: 'trigger',
  tzAware: false,
  oneTime: OneTimeFactory(),
  recurrent: RecurrentFactory(),
  messageConfig: MessageConfigFactory(),
} as OrchestrationCampaignProps)
