import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { LabelPlaceholder } from 'components/styled/placeholder'
import { colors } from 'components/styled/tokens'

export const SubNavItem = styled.li`
  list-style: none;
  display: inline-block;
`
type SubNavLinkProps = {
  active?: boolean
  disabled?: boolean
}
export const SubNavLink = styled(LabelPlaceholder.withComponent('a'))<SubNavLinkProps>`
  display: inline-flex;
  padding: 0.3em 0;
  color: ${props =>
    props.disabled ? colors.textDisabled : props.active ? colors.text : colors.textLight};
  border-bottom: 2px solid ${props => (props.active ? colors.strokeAction : 'transparent')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  ${props =>
    props.disabled
      ? css`
          &:hover {
            color: ${colors.textDisabled};
          }
        `
      : css`
          &:hover {
            color: ${colors.text};
          }
          &:disabled {
            color: ${colors.textDisabled};
          }
          &.active {
            color: ${colors.text};
            border-bottom-color: ${colors.strokeAction};
          }
        `}
`

export const SubNavRouterLink = styled(SubNavLink.withComponent(NavLink))``

export const SubNav = styled.ul`
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  font-size: 16px;
  text-align: left;

  ${SubNavLink}, ${SubNavRouterLink} {
    margin: 0 24px 0 0;
  }
`
