/* eslint-disable react/jsx-no-bind */
import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { useIsCurrentUserAllowedTo } from 'components/_hooks'
import { Separator } from 'components/app/custom-data/custom-data.styles'
import {
  BoxHeader,
  BoxBody,
  BoxFooter,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button, Switch, PermissionButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Wrapper, EmptyField } from 'components/common/empty-states'
import { FlexLineItem } from 'components/common/flexline'
import { Icon } from 'components/common/svg-icon'
import { Form } from 'components/form'

import { randomSize } from 'com.batch.common/utils'

import { PressureRow } from './pressure-row'
import { CappingBox, CappingRow } from './pressure.styles'

import { type CappingCategoryRecord } from 'com.batch.redux/_records'
import * as appActions from 'com.batch.redux/app.action'

type PressureCategoryProps = {
  category: CappingCategoryRecord
  hideToggle?: boolean
  isEditing: boolean
  editCategory: (arg1: string | null) => any
}

const initialErrors = Immutable.Map<string, string>()

export const PressureCategory = ({
  category,
  isEditing,
  editCategory,
  hideToggle,
}: PressureCategoryProps): React.ReactElement => {
  const dispatch = useDispatch()

  // ====================== COMPONENT STATES
  const [original, setOriginal] = React.useState<CappingCategoryRecord>(category)
  const [errors, setErrors] = React.useState<Map<string, string>>(initialErrors)

  // ====================== COMPONENT CONSTANTS
  const disabledSaveBtn =
    (category.cappings.size === 0 && original.cappings.size === 0) || !category.dirty
  const hasErrors = errors.delete('capping_new_new').delete('period_new_new').size > 0
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  // ====================== USE EFFECT
  React.useEffect(() => {
    if (!isEditing && category.dirty) {
      setErrors(initialErrors)
      dispatch(
        appActions.updatePressureCategory({
          categorieRecord: category,
          updated: original.set('dirty', false),
        })
      )
    }
  }, [dispatch, isEditing, category, original])

  // refocus
  React.useEffect(() => {
    if (containerRef.current && isEditing) {
      const inputs = containerRef.current.getElementsByTagName('input')
      if (inputs.length > 1) {
        inputs[inputs.length - 2].focus()
      }
    }
  }, [category.cappings.size, isEditing])

  // ====================== COMPONENT CALLBACKS
  const toggleCategory = React.useCallback(
    (enabled: boolean) => {
      editCategory(category.code)
      dispatch(
        appActions.updatePressureCategory({
          categorieRecord: category,
          updated: category.set('enabled', enabled).set('dirty', true),
        })
      )
    },
    [category, dispatch, editCategory]
  )

  const handleSubmit = React.useCallback(() => {
    if (!hasErrors) {
      dispatch(appActions.saveCappingCategory(category, 'cappingSaved'))
      setOriginal(category.set('dirty', false))
      editCategory(null)
    }
  }, [category, dispatch, editCategory, hasErrors])

  const handleDeleteCategory = React.useCallback(() => {
    confirm({
      title: 'Delete this label capping?',
      message: (
        <article>
          <p>
            Your’re about to delete the <ConfirmHighlight>{category.name}</ConfirmHighlight> capping
            rules for this app.
          </p>
          <p>This action is final and can’t be undone.</p>
        </article>
      ),
      sensitive: true,
    }).then(
      () =>
        dispatch(
          appActions.saveCappingCategory(
            category.set('cappings', Immutable.List()),
            'cappingDeleted'
          )
        ),
      () => {}
    )
  }, [dispatch, category])

  const isEmpty = category.cappings.size === 0 && !isEditing
  const isAllowedToChangeCappings = useIsCurrentUserAllowedTo(['app', 'cappings:write'])
  // ====================== RENDER
  return (
    <div ref={containerRef}>
      <CappingBox isEditing={isEditing} style={{ overflow: 'hidden' }}>
        <Form size="small" onSubmit={handleSubmit}>
          <BoxHeader>
            <HeaderBoxTitle
              title={
                hideToggle ? (
                  category.code === '_campaign' ? (
                    'Push campaigns'
                  ) : category.code === '_transac' ? (
                    'Transactional push'
                  ) : (
                    category.name
                  )
                ) : (
                  <Switch
                    isActive={(category.cappings.size !== 0 || isEditing) && category.enabled}
                    onChange={boolValue => {
                      toggleCategory(
                        isEditing ? boolValue : category.cappings.size > 0 ? boolValue : true
                      )
                    }}
                  >
                    {category.code === '_campaign'
                      ? 'Push orchestrations'
                      : category.code === '_transac'
                        ? 'Transactional push'
                        : category.name}
                  </Switch>
                )
              }
            />
            <HeaderBoxActions>
              {isEditing ? (
                <React.Fragment>
                  <Button
                    style={{ margin: 0 }}
                    kind="inline"
                    intent="neutral"
                    onClick={() => editCategory(null)}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ margin: 0 }}
                    type="submit"
                    intent="action"
                    kind="inline"
                    disabled={disabledSaveBtn}
                  >
                    Save
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <PermissionButton
                    type="button"
                    kind="inline"
                    onClick={() => editCategory(category.code)}
                    isAllowed={isAllowedToChangeCappings}
                  >
                    Edit
                  </PermissionButton>
                  {!category.internal && (
                    <React.Fragment>
                      <Separator style={{ marginLeft: 0 }} />
                      <PermissionButton
                        type="button"
                        intent="danger"
                        kind="discreet"
                        onClick={handleDeleteCategory}
                        isAllowed={isAllowedToChangeCappings}
                      >
                        <Icon icon="delete" />
                      </PermissionButton>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </HeaderBoxActions>
          </BoxHeader>

          <BoxBody>
            <Wrapper
              isEmpty={isEmpty}
              overlayProps={{ status: 'empty', title: 'No rules added yet.' }}
              isOverlayShown={isEmpty}
              isLoading={false}
            >
              {isEmpty
                ? Array(2)
                    .fill(undefined)
                    .map((a, i) => <EmptyCategoryRow key={i} />)
                : category.cappings.map((cap, index) => {
                    const line = (index + 1).toString()

                    return (
                      <PressureRow
                        key={index}
                        line={line}
                        isEditing={isEditing}
                        cap={cap}
                        category={category}
                        errors={errors}
                        setErrors={(newErrors: Map<string, string>) => setErrors(newErrors)}
                      />
                    )
                  })}
            </Wrapper>
          </BoxBody>

          {isEditing && (
            <BoxFooter
              small
              style={
                isEditing && category.cappings.size === 0
                  ? { borderTop: 0, backgroundColor: '#fafafb', padding: 0 }
                  : isEditing
                    ? { backgroundColor: '#fafafb', padding: 0 }
                    : { padding: 0 }
              }
            >
              <PressureRow
                isNew
                isEmpty={category.cappings.size === 0}
                line={'new'}
                category={category}
                errors={errors}
                setErrors={(newErrors: Map<string, string>) => setErrors(newErrors)}
              />
            </BoxFooter>
          )}
        </Form>
      </CappingBox>
    </div>
  )
}

export const EmptyCategoryRow: React.ComponentType<Record<any, any>> = React.memo<Record<any, any>>(
  (): React.ReactElement => {
    return (
      <CappingRow>
        <FlexLineItem>
          <EmptyField _width={React.useMemo(() => randomSize(200, 500), [])} _height={12} />
        </FlexLineItem>
      </CappingRow>
    )
  }
)
