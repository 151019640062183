import * as React from 'react'

import { TableTemplateCell } from 'components/common/table'

export const EmptyTeamRow: React.ComponentType<Record<any, any>> = React.memo<Record<any, any>>(
  (): React.ReactElement => {
    return (
      <React.Fragment>
        <TableTemplateCell template="1fr" />
        <TableTemplateCell template="1fr" />
      </React.Fragment>
    )
  }
)
