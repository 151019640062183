import setTimeout from 'core-js/features/set-timeout'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { BoxTitle } from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { LinkArrow } from 'components/styled/text'
import { Separator } from 'components/styled/utils'

import { platforms } from 'com.batch/profile/constants/platforms'
import { type Platform } from 'com.batch/profile/infra/debug/models/shared.model'
import { type InstallRecord } from 'com.batch/profile/models/profile.records'
import { ProfileCopyableId } from 'com.batch/profile/ui/components/profile-hero/profile-copyable-id/profile-copyable-id'
import {
  PlatformModalBox,
  PlatformModalBoxHeader,
  PlatformModalBody,
  ButtonReload,
} from 'com.batch/profile/ui/components/profile-hero/profile-platforms/platform-modal.styles'
import { ProfileMockupOptin } from 'com.batch/profile/ui/components/profile-hero/profile-platforms/profile-mockup-optin'
import { refreshPlatform } from 'com.batch/profile/usecases/refresh-platform'

type Props = {
  opened: boolean
  close: () => void
  platform: InstallRecord
  findDebugUrl: (installId: string, platform: Platform) => string
}
export const PlatformModal = ({
  opened,
  close,
  platform,
  findDebugUrl,
}: Props): React.ReactElement => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const platformKind = React.useMemo(
    () => (platform.isImported ? 'imported' : 'native'),
    [platform.isImported]
  )

  const debugUrl = React.useMemo(
    () => findDebugUrl(platform.installID, platform.platform),
    [findDebugUrl, platform.installID, platform.platform]
  )

  const platformName = React.useMemo(
    () => platform.marketingName || platform.type,
    [platform.marketingName, platform.type]
  )

  const platformData = React.useMemo(() => platforms[platform.platform], [platform.platform])

  const handleOnClickReload = React.useCallback(() => {
    if (platformKind === 'native') {
      setIsLoading(true)
      dispatch(refreshPlatform({ installId: platform.installID })).finally(() => {
        const loadingTimeout = setTimeout(() => setIsLoading(false), 500)
        return () => clearTimeout(loadingTimeout)
      })
    }
  }, [dispatch, platformKind, platform.installID])

  return (
    <Popin opened={opened} close={close}>
      <PlatformModalBox>
        <PlatformModalBoxHeader>
          <BoxTitle>
            <Icon icon={platformData.icon} style={{ marginRight: 8 }} />
            {platformKind === 'imported' ? `Unknow ${platform.platform} device` : platformName}
            {platformKind === 'imported' && (
              <span>
                <Icon icon="import" />
                Imported token
              </span>
            )}
          </BoxTitle>
          {platformKind === 'native' && (
            <React.Fragment>
              <ButtonReload onClick={handleOnClickReload} disabled={isLoading}>
                <Icon icon="reload" />
              </ButtonReload>
              <Separator />
            </React.Fragment>
          )}
          <Button onClick={close}>
            <Icon icon="close" />
          </Button>
        </PlatformModalBoxHeader>
        <PlatformModalBody>
          <div>
            <ProfileCopyableId
              label="Installation ID"
              id={platform.installID}
              noIdMessage="Not available"
              isLoading={false}
            />
            <ProfileCopyableId
              label="Push token"
              id={platform.pushToken}
              noIdMessage="No push token"
              isLoading={false}
            />
            {debugUrl && (
              <LinkArrow intent="action" target="_blank" href={debugUrl} className="fs-exclude">
                Inspect in app debug
              </LinkArrow>
            )}
          </div>
          <ProfileMockupOptin
            kind={platformKind}
            size="expanded"
            device={platformName}
            subscriptionStatus={platform.subscriptionStatus}
            notifType={platform.notifType}
            isLoading={isLoading}
          />
        </PlatformModalBody>
      </PlatformModalBox>
    </Popin>
  )
}
