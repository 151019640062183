import { type Map } from 'immutable'
import * as React from 'react'

import Highlight from 'components/common/highlight'
import { SideSheet } from 'components/common/sidesheet'
import { Ellipsis } from 'components/styled/text'
import { fillContrast } from 'components/styled/tokens/colors'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { convertAttributesToJson } from 'com.batch/profile/infra/formats/convert-attributes-to-json'
import {
  AttributesSheetContainer,
  AttributesSheetTitle,
} from 'com.batch/profile/ui/components/events-block/attributes-sheet/attributes-sheet-provider.styles'

type Props = {
  children: React.ReactNode
}

type State = {
  open: (attributes: Map<string, QueryValue>, name: string) => void
}

const AttributesSheetContext = React.createContext<State | null>(null)

export const AttributesSheetProvider = ({ children }: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [eventName, setEventName] = React.useState<string>('')
  const [jsonAttributes, setJsonAttributes] = React.useState<string>('')

  const open = React.useCallback(
    (attributes: Map<string, QueryValue>, name: string) => {
      setJsonAttributes(convertAttributesToJson(attributes))
      setEventName(name)
      setIsOpen(true)
    },
    [setJsonAttributes, setIsOpen, setEventName]
  )

  const close = React.useCallback(() => {
    setIsOpen(false)
    setJsonAttributes('')
    setEventName('')
  }, [setJsonAttributes, setIsOpen, setEventName])

  const ctxValue = React.useMemo(() => {
    return { open }
  }, [open])

  return (
    <AttributesSheetContext.Provider value={ctxValue}>
      <SideSheet
        direction="right"
        isOpened={isOpen}
        close={close}
        width={620}
        headerTitle={
          <AttributesSheetTitle>
            <Ellipsis>{eventName}</Ellipsis>
            <span>Event data</span>
          </AttributesSheetTitle>
        }
      >
        <AttributesSheetContainer>
          <Highlight
            isSensitive
            language="json"
            style={{
              backgroundColor: fillContrast,
              borderRadius: 0,
            }}
          >
            {jsonAttributes}
          </Highlight>
        </AttributesSheetContainer>
      </SideSheet>
      {children}
    </AttributesSheetContext.Provider>
  )
}

export const useAttributesSheet = (): State => {
  const ctx = React.useContext(AttributesSheetContext)
  if (!ctx) throw 'Context must be not empty'
  return { ...ctx }
}
