import * as React from 'react'

import { getSSOUrl } from 'components/authentication/login/api'
import { Button, ButtonLink } from 'components/common/button'
import { ConfirmModalFooter } from 'components/common/confirm-modal-footer'
import { ConfirmFooter } from 'components/common/confirm.styles'

import { generateUrl } from 'com.batch.common/router'

import { type UserRecord } from 'com.batch.redux/user.records'

import {
  ConfirmMFA,
  ConfirmMFAFooter,
} from 'com.batch/shared/ui/component/confirm-mfa/confirm-mfa.styles'

type Props = {
  onSuccess: () => void
  onCancel: () => void
  confirm?: string
  cancel: string
  sensitive?: boolean
  user: UserRecord
  authRequired: boolean
}

export const SsoMfa = ({
  onSuccess,
  onCancel,
  confirm,
  cancel,
  sensitive,
  user,
  authRequired,
}: Props): React.ReactElement => {
  const [ssoUrl, setSsoUrl] = React.useState<string>('')

  React.useEffect(() => {
    const callbackUrl = `${window.location.protocol}//${window.location.host}${generateUrl(
      'login_mfa_sso_callback'
    )}`
    getSSOUrl(user.email, callbackUrl, true).then((url: string) => {
      setSsoUrl(url)
    })
  }, [user.email])

  // Listen to the message sent by the tab
  React.useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) return
      if (event.data === 'success') {
        onSuccess()
      }
    }
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [onSuccess])

  return (
    <ConfirmMFAFooter>
      {authRequired ? (
        <React.Fragment>
          <ConfirmMFA>
            <p style={{ marginBottom: '4px' }}>
              Please confirm by authenticating through your SSO provider
            </p>
          </ConfirmMFA>
          <ConfirmFooter>
            <Button kind="inline" onClick={onCancel}>
              {cancel}
            </Button>
            <ButtonLink
              kind="primary"
              intent={sensitive ? 'danger' : 'action'}
              href={ssoUrl}
              target="_blank"
              rel="opener"
              disabled={ssoUrl === ''}
            >
              {confirm}
            </ButtonLink>
          </ConfirmFooter>
        </React.Fragment>
      ) : (
        <ConfirmModalFooter
          sensitive={sensitive}
          cancel={cancel}
          confirm={confirm}
          onClickCancel={onCancel}
          onClickConfirm={onSuccess}
        />
      )}
    </ConfirmMFAFooter>
  )
}
