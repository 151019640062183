import { type GetState, type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { dynamicPreviewSourceProfileIdSelector } from 'com.batch.redux/campaign.selector'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import {
  type ContentToTemplate,
  type TemplatedContent,
} from 'com.batch/shared/infra/oursql.service'

const getStateData = (getState: GetState) => {
  const state = getState()
  const project = currentProjectSelector(state)
  const profileId = dynamicPreviewSourceProfileIdSelector(state)

  return { project, profileId }
}

export type FetchTemplateFailureAction = {
  type: 'FETCH_TEMPLATE_FAILURE'
  payload: {
    error: {
      message: string
    }
    aborted: boolean
  }
}
const abortFetchTemplatesControllers: {
  [key: string]: AbortController
} = {}
export const fetchTemplate = (
  content: ContentToTemplate
): DispatchExtraBoundFn<Promise<Array<TemplatedContent>>> => {
  const contentIdentifier = `${content.messageId}_${content.lang}_${content.field}`
  if (!abortFetchTemplatesControllers[contentIdentifier])
    abortFetchTemplatesControllers[contentIdentifier] = new AbortController()

  return (dispatch, getState, { ourSqlService }) => {
    abortFetchTemplatesControllers[contentIdentifier].abort()
    abortFetchTemplatesControllers[contentIdentifier] = new AbortController()

    const { project, profileId } = getStateData(getState)

    return promiseActionCreator<Array<TemplatedContent>>({
      dispatch,
      promise:
        profileId !== ''
          ? ourSqlService.fetchTemplatedContent({
              projectKey: project.projectKey ?? '',
              profileId,
              contents: [content],
              abortSignal: abortFetchTemplatesControllers[contentIdentifier].signal,
            })
          : ourSqlService.fetchRenderedContent({
              contents: [content],
              abortSignal: abortFetchTemplatesControllers[contentIdentifier].signal,
            }),
      actionName: 'FETCH_TEMPLATE',
    })
  }
}

export type FetchTemplatesFailureAction = {
  type: 'FETCH_TEMPLATES_FAILURE'
  payload: {
    error: {
      message: string
    }
    aborted: boolean
  }
}
export const fetchTemplates = (
  contents: Array<ContentToTemplate>
): DispatchExtraBoundFn<Promise<Array<TemplatedContent>>> => {
  return (dispatch, getState, { ourSqlService }) => {
    const { project, profileId } = getStateData(getState)

    return promiseActionCreator<Array<TemplatedContent>>({
      dispatch,
      promise:
        profileId !== ''
          ? ourSqlService.fetchTemplatedContent({
              projectKey: project.projectKey ?? '',
              profileId,
              contents,
            })
          : ourSqlService.fetchRenderedContent({
              contents,
            }),
      actionName: 'FETCH_TEMPLATES',
    })
  }
}
