import * as React from 'react'
// import React, { useEffect, useRef } from 'react'

import { CodeContainer } from './authcode.styles'

import { Feedback } from '../feedback'
import { InputWrapper } from '../input-wrapper'

export type AuthCodeTypes = {
  invalid?: boolean
  disabled?: boolean
  loading?: boolean
  value: string
  feedback?: string
  style?: Record<any, any>
  onChange: (value: string) => void
  onBlur?: (value: string) => void
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'>

const AuthCode = ({
  // invalid = false,
  loading = false,
  value,
  feedback,
  style,
  onChange,
  onBlur,
  ...props
}: AuthCodeTypes): React.ReactElement => {
  const ref = React.useRef<null | HTMLInputElement>(null)

  React.useEffect(() => {
    if (ref.current) ref.current.focus()
  }, [])

  const restrain = (v: string) => {
    v = v.replace(/\D/g, '')
    if (v.length > 6) v = v.substring(0, 6)
    return v
  }

  const handleOnBlur = React.useCallback(
    evt => {
      const v = restrain(evt.target.value)
      onChange(restrain(evt.target.value))
      if (typeof onBlur === 'function') onBlur(v)
    },
    [onBlur, onChange]
  )

  const handleOnChange = React.useCallback(
    evt => {
      const v = restrain(evt.target.value)
      onChange(v)
      if (v.length >= 6 && ref.current) ref.current.blur()
    },
    [onChange]
  )
  return (
    <InputWrapper style={style} htmlFor="authcode">
      <CodeContainer
      // invalid={invalid}
      >
        <input
          {...props}
          id="authcode"
          ref={ref}
          className="styled-code-input"
          value={value}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          aria-label="Authentication code"
        />
        <svg className="styled-code-frame" viewBox="0 0 100 20">
          {[1, 2, 3, 4, 5].map(l => {
            const x = l * (100 / 6)
            return <line x1={x} x2={x} y1={5} y2={15} key={l} />
          })}
        </svg>
      </CodeContainer>

      {loading ? (
        <Feedback type="loading" message="Validating" style={{ marginTop: '14px' }} />
      ) : (
        feedback && <Feedback type="error" message={feedback} style={{ marginTop: '14px' }} />
      )}
    </InputWrapper>
  )
}

export { AuthCode }
