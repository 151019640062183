import styled from 'styled-components'

import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import * as colors from 'components/styled/colors'

export const SenderIdentityGrid = styled(Grid)`
  grid-template-columns: 1fr;
  grid-column-gap: 0;
  height: 380px;

  > div:first-of-type {
    border-right: 0;
    border-bottom: 1px solid ${colors.stroke.lighter};
  }

  @media (min-width: 766px) {
    grid-template-columns: minmax(350px, 1fr) minmax(220px, 840px);

    > div:first-of-type {
      border-right: 1px solid ${colors.stroke.lighter};
      border-bottom: 0;
      padding-right: 12px;
    }
  }
`
export const SenderIdentityListContainer = styled.div`
  flex: 1 1 auto;
  flex-direction: column;
  align-items: baseline;
  margin: 12px 0 12px 12px;
  overflow-y: auto;
  height: 348px;
`
export const SenderIdentityListItem = styled(Button)`
  margin-bottom: 5px;
  width: 100%;
  justify-content: start;
`

export const SenderIdentityContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

export const SenderIdentityForm = styled.div`
  height: 100%;
`

export const SenderIdentityAddress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  > div:first-of-type {
    margin-right: 10px;
  }
  > div:last-of-type {
    margin-left: 10px;
  }
`

export const SenderIdentityButtonZone = styled.div`
  display: flex;
  flex-direction: row;

  > div:first-of-type {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  > div:last-of-type {
    display: flex;
    justify-content: flex-end;
  }
`

export const SenderIdentityButton = styled(Button)`
  margin-left: 4px;
  margin-right: 4px;
`
