import styled from 'styled-components'

import { textLight } from 'components/styled/tokens/colors'
import { darklucent, grayscale } from 'components/styled/tokens/schemes'

export const ConfirmMFAFooter = styled.div`
  border-top: 1px solid ${darklucent[20]};
  background: ${grayscale['01']};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export const ConfirmMFA = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 20px 0 20px;

  > p {
    color: ${textLight};
  }
`
