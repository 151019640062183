import styled from 'styled-components'

import { Box } from 'components/common/box'
import * as colorsLegacy from 'components/styled/colors'
import { colors, schemes } from 'components/styled/tokens'

export const DragAndDropContainer = styled.div`
  width: 552px;
  height: 176px;
  background: ${schemes.grayscale['01']};
  border: 1px dashed ${schemes.darklucent['20']};
  border-radius: 6px;
`
type DragAndDropTextProps = {
  disabled: boolean
}
export const DragAndDropText = styled.div<DragAndDropTextProps>`
  text-align: center;
  padding: 15px 82px 0px 71px;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  letter-spacing: -0.02em;
  height: 76px;
  border-right: 1px solid ${colorsLegacy.stroke.lighter};
  color: ${p => (p.disabled ? colors.textDisabled : colors.textLight)};
`
export const DragAndDropIconContainer = styled.div`
  padding: 40px;

  .icon-container {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    overflow: hidden;
  }
`

export const DangerZone = styled(Box)`
  border: 1px solid ${schemes.red['30']};
`
