import Immutable, { type Map, type Set } from 'immutable'
import * as React from 'react'

import {
  InvitationTag,
  UserEmailInline,
  UserIdentifierInline,
  UserNameInline,
} from 'components/account/team/team.styles'
import { UserAvatar } from 'components/account/team/user-avatar'
import {
  getPermissionsForUserAndCompany,
  permissionNameFormatter,
  userCanAdministrate,
  isUserAllowedOnApp,
  ROLES,
} from 'components/account/team/utils'
import { Separator } from 'components/app/custom-data/custom-data.styles'
import {
  Box,
  BoxBody,
  BoxFooter,
  BoxHeader,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Grid } from 'components/common/grid'
import { TrackingContext, trackEvent } from 'components/common/page-tracker'
import { Popin } from 'components/common/popin/popin'
import {
  Table,
  TableCellOrder,
  TableHeader,
  TableCellHeader,
  TableRow,
  TableCell,
  TableBody,
} from 'components/common/table'
import { Tag } from 'components/common/tag'
import { Tooltip } from 'components/common/tooltip'
import { FilterSelect, FilterSearch } from 'components/filter'
import { Checkbox } from 'components/form'

import { ucFirst } from 'com.batch.common/utils'

//
import { Icon } from '../common/svg-icon'
import { type CompanyRecord, type AppRecord } from 'com.batch.redux/_records'
import { type UserRecord } from 'com.batch.redux/user.records'

import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'
import { useConfirmWithMfa } from 'com.batch/shared/ui/hooks/use-confirm-with-mfa'

type AppTeamPopinProps = {
  opened: boolean
  company: CompanyRecord
  app: AppRecord
  users: Map<number, UserRecord>
  close: () => void
  updateAllowedUsers: (arg1: Set<number>) => Promise<Map<number, UserRecord>>
}

type userKind = 'all' | 'invite' | 'active' | 'none'
type userKindOption = {
  filter: userKind
  label: string
}

function userKindToString(opt?: userKindOption | null): string {
  return opt?.label ?? ''
}

const GridTemplate = '64px 1fr 320px'
const userKindOptions = Immutable.List<userKindOption>([
  { filter: 'all', label: 'All users' },
  { filter: 'active', label: 'Active users' },
  { filter: 'invite', label: 'Invited users' },
])

export const AppTeamPopin = ({
  opened,
  close,
  users,
  company,
  app,
  updateAllowedUsers,
}: AppTeamPopinProps): React.ReactElement => {
  const initSet = React.useMemo(
    () =>
      Immutable.Set(
        users
          .toList()
          .filter(user => isUserAllowedOnApp({ app, company, user }))
          .map(user => Number(user.id))
      ),
    [users, app, company]
  )
  const [allowed, setAllowed] = React.useState<Set<number>>(initSet)
  const [sortAsc, setSortAsc] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [filter, setFilter] = React.useState<userKind>('none')
  const [query, setQuery] = React.useState('')
  const [confirmOpened, setConfirmOpened] = React.useState<boolean>(false)
  const confirm = useConfirmWithMfa()

  React.useEffect(() => {
    setAllowed(initSet)
  }, [users, setAllowed, initSet])

  const cleanAndClose = React.useCallback(() => {
    setAllowed(initSet)
    close()
  }, [close, initSet])

  const added = React.useMemo<Set<number>>(
    () => allowed.subtract(initSet).filter(Boolean),
    [allowed, initSet]
  )
  const removed = React.useMemo<Set<number>>(
    () => initSet.subtract(allowed).filter(Boolean),
    [allowed, initSet]
  )
  const nbChanged = React.useMemo<number>(() => added.size + removed.size, [added, removed])

  const save = React.useCallback(() => {
    // tracking
    const location = 'app team'
    added.forEach(user_id => {
      trackEvent('APP_ADD_USER', { location, user_id, app_id: app.id })
    })
    removed.forEach(user_id => {
      trackEvent('APP_REMOVE_USER', { location, user_id, app_id: app.id })
    })

    updateAllowedUsers(allowed).then(
      () => {
        setLoading(false)
        close()
      },
      () => {
        setLoading(false)
      }
    )
  }, [added, allowed, app.id, close, removed, updateAllowedUsers])

  const handleOnClick = React.useCallback(() => {
    setLoading(true)
    confirm({
      title: 'Edit users access to an app',
      message: (
        <p>
          You’re about to change the access of {nbChanged} user{nbChanged > 1 ? 's' : ''} to the app{' '}
          <ConfirmHighlight>{app.name}</ConfirmHighlight>
        </p>
      ),
      onModalShown: () => setConfirmOpened(true),
      confirm: 'Yes, edit',
    })
      .then(save, () => {
        setConfirmOpened(false)
        setLoading(false)
      })
      .then(() => {
        setConfirmOpened(false)
      })
  }, [app.name, confirm, nbChanged, save])

  // filters users

  const filteredUsers = users
    .filter(
      user =>
        (query === '' ||
          user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          user.lastName.toLowerCase().indexOf(query.toLowerCase()) !== -1) &&
        (filter === 'all' ||
          filter === 'none' ||
          (user.isInvite && filter === 'invite') ||
          (!user.isInvite && filter === 'active'))
    )
    .sort((a, b) => (a.email < b.email ? (sortAsc ? -1 : 1) : sortAsc ? 1 : -1))
    .toList()
  const minAllowedUsers = users.filter(u => userCanAdministrate(u, company)).size
  const selectedValue = userKindOptions.find(opt => opt.filter === filter)
  const updateCount = allowed.subtract(initSet).size + initSet.subtract(allowed).size

  const userKindFormatter = React.useCallback(
    (opt, { context }) => {
      if (context === 'menu') return opt.label
      switch (opt.filter) {
        case 'all':
          return `${opt.label} (${users.size})`
        case 'invite':
          return `${opt.label} (${users.filter(u => u.isInvite).size})`
        default:
          return `${opt.label} (${users.filter(u => !u.isInvite).size})`
      }
    },
    [users]
  )
  const onUserPermissionChanged = React.useCallback(
    (user: UserRecord) => () =>
      setAllowed(
        allowed.has(Number(user.id))
          ? allowed.remove(Number(user.id))
          : allowed.add(Number(user.id))
      ),
    [allowed]
  )
  const { eventLocation } = React.useContext(TrackingContext)
  const onToggleSort = React.useCallback(() => setSortAsc(!sortAsc), [sortAsc])
  const onUserKindChange = React.useCallback(
    opt => {
      const filter = opt?.filter ?? 'none'
      trackEvent('FILTER_USERS', { location: eventLocation, filter_type: filter })
      setFilter(filter)
    },
    [eventLocation]
  )
  const onSelectAllChange = React.useCallback(
    () =>
      setAllowed(
        allowed.size === minAllowedUsers
          ? Immutable.Set(users.toList().map(u => Number(u.id)))
          : Immutable.Set(
              users
                .filter(u => userCanAdministrate(u, company))
                .toList()
                .map(u => Number(u.id))
            )
      ),
    [allowed.size, company, minAllowedUsers, users]
  )
  return (
    <Popin
      opened={opened && !confirmOpened}
      close={cleanAndClose}
      style={{ overflowY: 'hidden', padding: 0 }}
    >
      <Box style={{ width: 890 }}>
        <BoxHeader>
          <HeaderBoxTitle title="Edit user access" />
          <HeaderBoxActions>
            <FilterSearch value={query} onChange={setQuery} placeholder="Search users" />

            <FilterSelect
              isSearchable={false}
              options={userKindOptions}
              value={selectedValue ? selectedValue : null}
              optionToString={userKindToString}
              optionFormatter={userKindFormatter}
              onChange={onUserKindChange}
              expandable={true}
              placeholder="All users"
            />
            <Separator style={{ marginLeft: 0 }} />
            <Button style={{ margin: 0 }} onClick={close}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          <Table template={GridTemplate}>
            <TableHeader>
              <TableCell kind="checkbox">
                <Checkbox
                  handleIndeterminate
                  onChange={onSelectAllChange}
                  checked={
                    allowed.size === minAllowedUsers
                      ? false
                      : allowed.size === users.size
                        ? true
                        : undefined
                  }
                  ariaLabel="Select all users"
                />
              </TableCell>
              <TableCellOrder sort={sortAsc ? 'asc' : 'dsc'} onClick={onToggleSort}>
                Users
              </TableCellOrder>
              <TableCellHeader>Permissions</TableCellHeader>
            </TableHeader>

            <TableBody style={{ overflowY: 'auto', height: 300 }}>
              <NoResultWrapper isEmpty={filteredUsers.size === 0} entityName="users" height={230}>
                {filteredUsers.map(user => {
                  const userName = `${ucFirst(user.firstName)} ${ucFirst(user.lastName)}`
                  const userPerm = getPermissionsForUserAndCompany(user, company)
                  return (
                    <TableRow key={user.id}>
                      <TableCell kind="checkbox">
                        <Checkbox
                          disabled={userCanAdministrate(user, company)}
                          checked={allowed.has(Number(user.id))}
                          onChange={onUserPermissionChanged(user)}
                          ariaLabel={`Select ${userName}`}
                        />
                      </TableCell>
                      <TableCell>
                        <Grid template="24px 1fr">
                          <UserAvatar user={user} size={24} />
                          <UserIdentifierInline>
                            {user.isInvite ? (
                              <Tooltip
                                placement="right"
                                tooltip="The account is awaiting confirmation."
                              >
                                <InvitationTag>Invite</InvitationTag>
                              </Tooltip>
                            ) : (
                              <UserNameInline>{userName}</UserNameInline>
                            )}
                            <UserEmailInline>{user.email}</UserEmailInline>
                          </UserIdentifierInline>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <div>
                          {ROLES.filter(
                            r =>
                              userPerm.has(r.id) &&
                              !r.autoGrantedWith.reduce(
                                (acc, current) => acc || userPerm.has(current),
                                false
                              )
                          ).map((p, k) => (
                            <Tag type="label" key={k}>
                              {permissionNameFormatter(p.id)}
                            </Tag>
                          ))}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </NoResultWrapper>
            </TableBody>
          </Table>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" intent="neutral" onClick={cleanAndClose}>
            Cancel
          </Button>

          <Button
            kind="primary"
            intent="action"
            onClick={handleOnClick}
            isLoading={loading}
            disabled={updateCount === 0}
          >
            Update users {updateCount > 0 && <React.Fragment>({updateCount})</React.Fragment>}
          </Button>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
