import styled from 'styled-components'

import { Box } from 'components/common/box'
import * as colorsLegacy from 'components/styled/colors'
import { colors, schemes } from 'components/styled/tokens'

type PushTesterRowProps = {
  active?: boolean
}
export const PushTesterRow = styled.div<PushTesterRowProps>`
  display: grid;
  grid-template-columns: 28px 1fr 24px;
  align-items: center;
  height: 48px;
  padding: 0 18px 0 18px;
  border-bottom: 1px solid ${colorsLegacy.stroke.lighter};
  cursor: pointer;

  .styled-name {
    padding-right: 22px;
    color: ${p => (p.active ? colors.text : colors.textLight)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  &:active {
    background-color: ${schemes.darklucent['05']};
  }
`

export const PushTesterModal = styled(Box)`
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: calc(100vh - 100px);
  border: none;
`
