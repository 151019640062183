import * as React from 'react'

import { Android } from 'components/project/campaign/push/preview/platform/android'
import { Ios } from 'components/project/campaign/push/preview/platform/ios'
import { WebMac } from 'components/project/campaign/push/preview/platform/web-mac'
import { WebWin } from 'components/project/campaign/push/preview/platform/web-win'

import { AndroidPreview } from 'com.batch/push/ui/android-preview'
import { IosPreview } from 'com.batch/push/ui/ios-preview'
import { WebMacPreview } from 'com.batch/push/ui/web-mac-preview'
import { WebWinPreview } from 'com.batch/push/ui/web-win-preview'
import { SmsPreview } from 'com.batch/sms/ui/components/sms-preview/sms-preview'

type PreviewProps = {
  messageId?: string
  previewPlatform: PreviewPlatform
  variant: 'a' | 'b'
}

export const Preview = ({
  messageId,
  previewPlatform,
  variant,
}: PreviewProps): React.ReactElement | null => {
  // Quand on fera les campagnes SMS/Push au profile alors un messageId: 'default' sera à gérer
  if (!messageId) {
    switch (previewPlatform) {
      case 'webMac':
        return <WebMac variant={variant} />
      case 'webWin':
        return <WebWin variant={variant} />
      case 'ios':
      case 'webIos':
        return <Ios variant={variant} />
      case 'android':
      case 'webAndroid':
        return <Android variant={variant} />
      default:
        return null
    }
  } else {
    switch (previewPlatform) {
      case 'ios':
      case 'webIos':
        return <IosPreview messageId={messageId} />
      case 'android':
      case 'webAndroid':
        return <AndroidPreview messageId={messageId} />
      case 'sms':
        return <SmsPreview messageId={messageId} />
      case 'webMac':
        return <WebMacPreview messageId={messageId} />
      case 'webWin':
        return <WebWinPreview messageId={messageId} />
      default:
        return null
    }
  }
}
