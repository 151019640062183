import { incrementStringCode } from 'com.batch.common/utils'

import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { showToast } from 'com.batch.redux/toaster'

import { type SegmentRecord } from 'com.batch/segments/models/segment.records'
import { fetchSegmentList } from 'com.batch/segments/usecases/fetch-segment-list'

type SaveSegmentAction = ReduxAction<'SAVE_SEGMENT', SegmentRecord>

export type SaveSegmentFailureAction = ReduxAction<'SAVE_SEGMENT_FAILURE', SegmentRecord>

export type SaveSegmentSuccessAction = ReduxAction<'SAVE_SEGMENT_SUCCESS', undefined>

export type SaveSegmentActionType =
  | SaveSegmentAction
  | SaveSegmentSuccessAction
  | SaveSegmentFailureAction

export const saveSegment = (
  segment: SegmentRecord,
  autoGeneratedCode: boolean = true
): DispatchExtraBoundFn<Promise<void>> => {
  return async (dispatch, getState, { segmentsService }) => {
    const state = getState()
    const project = currentProjectSelector(state)
    const page = state.segments.page
    dispatch({ type: 'SAVE_SEGMENT', payload: segment })

    const getNextAvailableCode = async (code: string): Promise<string> => {
      try {
        await segmentsService.getSegmentsByName({
          projectKey: project.projectKey,
          names: [code],
        })
        const newCode = incrementStringCode(code, '-')
        return getNextAvailableCode(newCode)
      } catch {
        return code
      }
    }

    let segmentToSave = segment

    if (autoGeneratedCode) {
      // max à length à 255, avec le tiret et l'auto-increment ça laisse le temps de faire quelques TEST...TEST-2212
      const code = await getNextAvailableCode(segment.name.substring(0, 250))
      segmentToSave = segmentToSave.set('name', code)
    }

    try {
      await segmentsService.createSegment({
        projectKey: project.projectKey,
        segmentRequest: {
          displayName: segmentToSave.displayName,
          name: segmentToSave.name,
          query: segmentToSave.query,
        },
      })
      dispatch({ type: 'SAVE_SEGMENT_SUCCESS', payload: segment })
    } catch (error: any) {
      dispatch({ type: 'SAVE_SEGMENT_FAILURE', payload: error })
      if (error.message.includes('already exists')) {
        dispatch(
          showToast({
            message: 'Segment code already exists',
            kind: 'error',
          })
        )
      } else {
        dispatch(
          showToast({
            message: 'An error occurred while creating the segment',
            kind: 'error',
          })
        )
      }
      throw error
    }
    dispatch(
      fetchSegmentList({
        page,
        pageSize: 10,
        search: state.segments.search,
        sortDirection: state.segments.sortDirection,
        sortField: state.segments.sortBy,
        forceTrashCache: true,
      })
    )
  }
}
