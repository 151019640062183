import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { EmailDropzoneContainer } from './email-dropzone.styles'

import { type DropzoneState } from 'com.batch/email/models/dropzone-state'
import { type DropzoneInputProps } from 'react-dropzone'

type EmailDropzoneProps = {
  getRootProps: <T extends DropzoneInputProps>(props?: T) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T
  mode: DropzoneState
}
export const EmailDropzone = ({
  getRootProps,
  getInputProps,
  mode,
}: EmailDropzoneProps): React.ReactElement => {
  const title = React.useMemo(() => {
    switch (mode) {
      case 'active':
        return '...and drop it!'
      case 'loading':
        return 'Loading'
      default:
        return 'Just drag here...'
    }
  }, [mode])

  return (
    <EmailDropzoneContainer {...getRootProps()} mode={mode}>
      <input {...getInputProps()} />
      <div>
        <h2>{title}</h2>
        {mode === 'loading' ? (
          <Icon icon="spinner" />
        ) : (
          <p>Release your .zip file here and we'll start processing your template</p>
        )}
      </div>
    </EmailDropzoneContainer>
  )
}
