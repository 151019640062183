import { type Dayjs } from 'dayjs'
import Immutable, { type Map } from 'immutable'
import { get } from 'lodash-es'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type fetchNotificationResolve } from './dataCampaign.api'

import {
  type State,
  type AppRecord,
  type DispatchBoundFn,
  type ReduxAction,
} from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentAppSelector } from 'com.batch.redux/app'
import * as api from 'com.batch.redux/dataCampaign.api'
import {
  type DataCampaignStateRecord,
  type CampaignDataRecord,
  DataCampaignStateFactory,
  CampaignDataFactory,
  type DayDataRecord,
} from 'com.batch.redux/dataCampaign.records'

import { STATUS } from 'constants/common'

export const intentToFetchNotifications =
  ({
    from,
    to,
    category,
  }: {
    category: 'summary' | 'marketing' | 'transactional'
    from: Dayjs
    to: Dayjs
  }): DispatchBoundFn<any> =>
  (dispatch, getState) => {
    const state = getState()
    const needed = api.buildArrayOfCacheIds(from, to, category)
    dispatch({
      type: 'INTENT_TO_FETCH',
      payload: { from: from.format('DD/MM/YYYY'), to: to.format('DD/MM/YYYY'), category },
    })
    const missing = Immutable.OrderedSet(needed)
      .subtract(state.dataCampaign.loaded.merge(state.dataCampaign.loading))
      .sort((a, b) => (a < b ? -1 : 1))
      .map(st => dayjs(st.substring(0, 8), 'YYYYMMDD'))
    if (missing.size > 0) {
      dispatch(
        fetchNotifications({ from: missing.first() ?? from, to: missing.last() ?? to, category })
      ).catch(() => null)
    }
    // si on est pas sur le tab summary & que la range a changé, il faut refetch summary également
    if (category !== 'summary') {
      dispatch(
        intentToFetchNotifications({
          from: missing.first() ?? from,
          to: missing.last() ?? to,
          category: 'summary',
        })
      )
    }
  }

type FetchCampaignsDataAction = {
  type: 'FETCH_CAMPAIGNS_DATA'
  payload: Array<string>
}
type FetchCampaignsDataSuccessAction = {
  type: 'FETCH_CAMPAIGNS_DATA_SUCCESS'
  payload: {
    campaignData: Map<string, CampaignDataRecord>
    tokens: Array<string>
  }
}
export type FetchCampaignsDataFailureAction = {
  type: 'FETCH_CAMPAIGNS_DATA_FAILURE'
  payload: {
    tokens: Array<string>
    error: string
  }
}
export const fetchCampaignsData =
  ({ app, tokens }: { app: AppRecord; tokens: Array<string> }): DispatchBoundFn<Promise<any>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'FETCH_CAMPAIGNS_DATA',
      dispatch,
      promise: api.fetchCampaignsAnalytics({ app, tokens }).then(
        cdMap => {
          return { campaignData: cdMap, tokens }
        },
        ({ error, status }) => {
          if (status)
            throw {
              tokens,
              error: get(
                error,
                'errors[0].message',
                status === 200 ? 'Unexpected server response' : 'Server error'
              ),
            }
        }
      ),
      payload: tokens,
    }).catch(() => {})

export const fetchNotifications =
  ({
    from,
    to,
    category,
  }: {
    category: 'summary' | 'marketing' | 'transactional'
    from: Dayjs
    to: Dayjs
  }): DispatchBoundFn<Promise<fetchNotificationResolve>> =>
  (dispatch, getState) => {
    const state: State = getState()
    const app = currentAppSelector(state)
    return promiseActionCreator({
      dispatch,
      payload: { from: dayjs(from.toDate()), to: dayjs(to.toDate()), category },
      promise: api
        .fetchNotifications({
          app,
          from: dayjs(from.toDate()),
          to: dayjs(to.toDate()),
          category,
        })
        .then(
          r => r,
          ({ error, status }) => {
            throw {
              from: dayjs(from.toDate()),
              to: dayjs(to.toDate()),
              category,
              error: get(
                error,
                'errors[0].message',
                status === 200 ? 'Unexpected server response' : 'Server error'
              ),
            }
          }
        ),
      actionName: 'FETCH_NOTIFICATIONS',
    })
  }

type fetchNotificationsAction = ReduxAction<
  'FETCH_NOTIFICATIONS',
  {
    category: 'summary' | 'marketing' | 'transactional'
    from: Dayjs
    to: Dayjs
  }
>
type fetchNotificationsSuccessAction = ReduxAction<
  'FETCH_NOTIFICATIONS_SUCCESS',
  fetchNotificationResolve
>
export type fetchNotificationsFailureAction = ReduxAction<
  'FETCH_NOTIFICATIONS_FAILURE',
  {
    category: 'summary' | 'marketing' | 'transactional'
    from: Dayjs
    to: Dayjs
    error: string
  }
>

type allowedActions =
  | fetchNotificationsAction
  | fetchNotificationsSuccessAction
  | fetchNotificationsFailureAction
  | FetchCampaignsDataAction
  | FetchCampaignsDataSuccessAction
  | FetchCampaignsDataFailureAction

// on utilise un Set<string> pour indiquer ce qui a déjà été load, & on ne l'écrase jamais par la suite
// à terme on pourrait optimiser pour ne trigger que le load des élémments manquant
const buildCacheKey = (pd: DayDataRecord, category: 'marketing' | 'summary' | 'transactional') =>
  pd.period.format('YYYYMMDD') + '_' + category

export const dataCampaignReducer = (
  state: DataCampaignStateRecord = DataCampaignStateFactory(),
  action: allowedActions
): DataCampaignStateRecord => {
  switch (action.type) {
    case 'FETCH_CAMPAIGNS_DATA':
      return state.set(
        'campaign',
        state.campaign.merge(
          Immutable.Map(
            action.payload.map(token => [token, CampaignDataFactory({ loading: true })])
          )
        )
      )
    case 'FETCH_CAMPAIGNS_DATA_SUCCESS':
      return state.set(
        'campaign',
        state.campaign
          .merge(action.payload.campaignData)
          .map((d, token) => (action.payload.tokens.includes(token) ? d.set('loading', false) : d))
      )
    case 'FETCH_CAMPAIGNS_DATA_FAILURE':
      return state.set(
        'campaign',
        state.campaign.map((d, token) =>
          action.payload.tokens.includes(token) ? d.set('loading', false) : d
        )
      )
    case 'FETCH_NOTIFICATIONS':
      return state
        .set(
          'loading',
          state.loading.merge(
            Immutable.Set(
              api.buildArrayOfCacheIds(
                action.payload.from,
                action.payload.to,
                action.payload.category
              )
            )
          )
        )
        .set(
          'status',
          dayjs(action.payload.from).isSame(action.payload.to) ? state.status : STATUS.LOADING
        )
    case 'FETCH_NOTIFICATIONS_FAILURE':
      return state
        .set(
          'loading',
          state.loading.subtract(
            Immutable.Set(
              api.buildArrayOfCacheIds(
                action.payload.from,
                action.payload.to,
                action.payload.category
              )
            )
          )
        )
        .set('status', STATUS.ERROR)

    case 'FETCH_NOTIFICATIONS_SUCCESS': {
      const cacheIds = Immutable.Set(
        api.buildArrayOfCacheIds(action.payload.from, action.payload.to, action.payload.category)
      )
      return state
        .set(
          'data',
          state.data.merge(
            action.payload.data.filter(pd => {
              // we might load more than needed if we have partial data
              // if we receive data already in our state, we filter it out
              const weDontAlreadyHaveIt = !state.loaded.has(
                buildCacheKey(pd, action.payload.category)
              )
              return weDontAlreadyHaveIt
            })
          )
        )
        .set('loaded', state.loaded.merge(cacheIds))
        .set('loading', state.loading.subtract(cacheIds))
        .set('status', STATUS.LOADED)
    }
    default:
      return state
  }
}
