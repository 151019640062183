import { type Dayjs } from 'dayjs'
import * as React from 'react'

export const BarChartContext = React.createContext<{
  dateRange: Array<Dayjs>
  hoveredDate: Dayjs | null | undefined
  isLoading: boolean
  isEmpty: boolean
  setHoveredDate: (date?: Dayjs | null | undefined) => void
}>({
  dateRange: [],
  hoveredDate: null,
  isLoading: false,
  isEmpty: false,
  setHoveredDate: () => {},
})

type BarChartProviderProps = {
  children: React.ReactNode
  dateRange: Array<Dayjs>
  isLoading?: boolean
  isEmpty?: boolean
  setDateOnView?: (date?: Dayjs | null | undefined) => void
}
export const BarChartProvider = ({
  children,
  dateRange = [],
  isLoading = false,
  isEmpty = false,
  setDateOnView,
}: BarChartProviderProps): React.ReactElement => {
  const [hoveredDate, setHoveredDate] = React.useState<any>(null)

  const onHoveredDateChange = React.useCallback(
    (date?: Dayjs | null) => {
      setHoveredDate(date)
      if (setDateOnView) setDateOnView(date)
    },
    [setDateOnView]
  )

  return (
    <BarChartContext.Provider
      value={{
        dateRange,
        hoveredDate,
        isLoading,
        isEmpty,
        setHoveredDate: onHoveredDateChange,
      }}
    >
      {children}
    </BarChartContext.Provider>
  )
}
