import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import {
  SspConfigurationFactory,
  type SspConfigurationRecord,
} from 'com.batch/sms/infra/models/ssp-configuration.record'
import { parseSspConfiguration } from 'com.batch/sms/infra/parses/ssp-configuration.parse'

type FetchSspConfigurationeAction = {
  type: 'FETCH_SSP_CONFIGURATION'
}

type FetchSspConfigurationeSuccessAction = {
  type: 'FETCH_SSP_CONFIGURATION_SUCCESS'
  payload: SspConfigurationRecord
}
type FetchSspConfigurationeFailureAction = {
  type: 'FETCH_SSP_CONFIGURATION_FAILURE'
}

export type FetchSspConfigurationActions =
  | FetchSspConfigurationeAction
  | FetchSspConfigurationeSuccessAction
  | FetchSspConfigurationeFailureAction

export const fetchSspConfiguration = (): DispatchExtraBoundFn<Promise<SspConfigurationRecord>> => {
  return async (dispatch, getState, { metadataService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    return promiseActionCreator({
      dispatch,
      promise: metadataService
        .fetchProject({
          projectKey: project.projectKey,
        })
        .then(projectResult =>
          projectResult.smsProviders
            ? parseSspConfiguration(projectResult.smsProviders)
            : SspConfigurationFactory()
        ),
      actionName: 'FETCH_SSP_CONFIGURATION',
    })
  }
}
