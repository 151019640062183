import { type List, type OrderedMap } from 'immutable'
import * as React from 'react'

import { EventListContainer } from './event-list.styles'

import { NoMoreEvents } from '../no-more-events/no-more-events'
import { StickyDay } from '../sticky-day/sticky-day'

import { type EventRecord } from 'com.batch/profile/models/event.records'
import { EventRow } from 'com.batch/profile/ui/components/events-block/event-row/event-row'

type EventListProps = {
  events: OrderedMap<string, List<EventRecord>>
  hasEvents: boolean
}

export const EventList = ({ events, hasEvents }: EventListProps): React.ReactElement | null => {
  const datesEvent = events.keySeq().toArray()

  return (
    <EventListContainer>
      {datesEvent.map((date, index) => {
        const eventList = events.get(date)
        if (eventList === undefined) {
          return null
        }
        const eventsArray = eventList.toArray()
        return (
          eventsArray.length > 0 && (
            <React.Fragment key={index}>
              <StickyDay date={date} firstElement={index === 0} />
              <div className="events">
                {eventList?.map((event: EventRecord, indexEvent: number) => {
                  return (
                    <EventRow
                      event={event}
                      nextEvent={eventsArray[indexEvent - 1]}
                      previousEvent={eventsArray[indexEvent + 1]}
                      key={event.hashCode()}
                    />
                  )
                })}
              </div>
            </React.Fragment>
          )
        )
      })}
      {hasEvents && <NoMoreEvents />}
    </EventListContainer>
  )
}
