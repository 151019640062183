import { type DispatchBoundFn } from 'com.batch.redux/_records'
import { currentProjectPushDefaultSettingsSelector } from 'com.batch.redux/app'
import { getMessageIds } from 'com.batch/message/models/message.helper'

import { type PushSettingsRecord } from 'com.batch/message/models/message.records'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'

type UpdatePushSettingsPayload = {
  stepMessageNodeId: string | null | undefined
  settings: PushSettingsRecord
}
export type UpdatePushSettingsAction = {
  type: 'UPDATE_PUSH_SETTINGS'
  payload: UpdatePushSettingsPayload
}

export type PushSettingsUpdatedForMessageIdAction = {
  type: 'PUSH_SETTING_UPDATED_FOR_MESSAGE_ID'
  payload: string
}

export const updatePushSettings = (payload: UpdatePushSettingsPayload): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    const getConfig = getMessageConfigSelector(state)
    const config = getConfig({ stepMessageNodeId: payload.stepMessageNodeId })
    dispatch<UpdatePushSettingsAction>({
      type: 'UPDATE_PUSH_SETTINGS',
      payload,
    })
    getMessageIds(config).forEach(messageTypedId => {
      if (!state.message.updatedMessageIds.has(messageTypedId))
        dispatch<PushSettingsUpdatedForMessageIdAction>({
          type: 'PUSH_SETTING_UPDATED_FOR_MESSAGE_ID',
          payload: messageTypedId,
        })
    })
  }
}

export const initDefaultAdvancedSettingsForCampaign = (): DispatchBoundFn<void> => {
  return (dispatch, getState) => {
    const state = getState()
    if (
      !state.orchestration.id &&
      state.orchestration.campaign.sendType !== 'trigger' &&
      state.orchestration.campaign.messageConfig.channel === 'push' &&
      state.orchestration.campaign.messageConfig.messageTypedId &&
      ['NEW', 'DRAFT'].includes(state.orchestration.state)
    ) {
      const settings = currentProjectPushDefaultSettingsSelector(state)
      dispatch(updatePushSettings({ stepMessageNodeId: undefined, settings }))
    }
  }
}
