import styled, { keyframes, css, type Keyframes } from 'styled-components'

import { schemes } from 'components/styled/tokens'

// ====================== EMPTY STATE BUILD ANIMATION
export const builtEmptyStateAnimation = () => css`
  animation-name: ${LoadingCellAnim};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`

// ====================== EMPTY STATE ANIMATIONS
export const LoadingCellAnim: Keyframes = keyframes`
0% { background-color: ${schemes.grayscale['10']}}
50% { background-color: ${schemes.grayscale['20']} }
100% { background-color: ${schemes.grayscale['10']} }
`

export const LoadingSvgAnim: Keyframes = keyframes`
0% { fill: ${schemes.grayscale['10']}}
50% { fill: ${schemes.grayscale['20']} }
100% { fill: ${schemes.grayscale['10']} }
`

export const LoadingSvgStrokeAnim: Keyframes = keyframes`
0% { stroke: ${schemes.grayscale['10']}}
50% { stroke: ${schemes.grayscale['20']} }
100% { stroke: ${schemes.grayscale['10']} }
`

export const LoadingSectionAnim: Keyframes = keyframes`
0% { background: ${schemes.grayscale['10']}}
50% { background: ${schemes.grayscale['20']} }
100% { background: ${schemes.grayscale['10']} }
`

type EmptyFieldProps = {
  _width?: number | string
  _height?: number
  _display?: 'inline' | 'block' | 'inline-block'
  forceLoading?: boolean
}
export const EmptyField = styled.div<EmptyFieldProps>`
  width: ${p => (p._width ? `${p._width}${typeof p._width === 'number' ? 'px' : ''}` : '48px')};
  height: ${p => p._height ?? 13}px;
  display: ${p => p._display ?? 'inline-block'};
  border-radius: 2px;

  ${p =>
    p.theme.isLoading || p.forceLoading
      ? builtEmptyStateAnimation()
      : css`
          background-color: ${schemes.grayscale['30']};
        `}
`

type EmptyCircleProps = {
  _size?: number
}
export const EmptyCircle = styled.div<EmptyCircleProps>`
  width: ${p => (p._size ? p._size : '10')}px;
  height: ${p => (p._size ? p._size : '10')}px;
  display: inline-block;
  border-radius: ${p => (p._size ? p._size : '10')}px;
  padding: 0;

  ${p =>
    p.theme.isLoading
      ? builtEmptyStateAnimation()
      : css`
          background-color: ${schemes.grayscale['30']};
        `}
`
