import * as React from 'react'
import { useTheme } from 'styled-components'

import { Icon, type availableIcons } from 'components/common/svg-icon'

import { type AppRecord } from 'com.batch.redux/_records'

// ====================== RE-EXPORT
export {
  MediaOverlay,
  BaseThumb,
  InactiveEditorContainer,
  InlineContainer,
  VariantToggler,
  PreviewContainer,
  VariantContainer,
  StickyInfoPanelContainer,
} from './common.styles'
// c'est le type qui est circular
// eslint-disable-next-line import/no-cycle
export { LegacyMediaField } from 'components/project/campaign/push/preview/legacy-media-field'
export { LegacyEditableField } from './legacy-editable-field'

export type ThemeProps = {
  previewPlatform: PreviewPlatform
  isExpanded?: boolean
  abTestingEnabled?: boolean
  hasMedia?: boolean
  hasIcon?: boolean
  draggingState?: draggingState
  isFocused: boolean
  openReplaceMedia?: () => void
  app?: AppRecord
  disabledMode?: boolean
}

export const OverlayIcon = (): React.ReactElement => {
  const { draggingState } = useTheme()
  const icon = React.useMemo<availableIcons>(
    () =>
      draggingState === 'UPLOADING'
        ? 'spinner'
        : draggingState === 'ACCEPTED'
          ? 'upload'
          : draggingState === 'REJECTED'
            ? 'warning-triangle'
            : 'edit',
    [draggingState]
  )
  return <Icon icon={icon} color={draggingState === 'REJECTED' ? 'crimson' : '#FFF'} />
}
