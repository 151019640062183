import styled from 'styled-components'

import { Grid } from 'components/common/grid'
import { InputWrapperContainer } from 'components/form/input-wrapper.styles'
import { darklucent } from 'components/styled/tokens/schemes'

type ConfigGridContainerProps = {
  gap: number
}

export const ConfigGridContainer = styled(Grid)<ConfigGridContainerProps>`
  row-gap: ${props => `${props.gap / 2}px`};

  ${InputWrapperContainer} {
    margin: 0;
  }

  .separator:not(:last-child) {
    grid-column: 1 / -1;
    border-bottom: 1px solid ${darklucent[20]};
  }
`
