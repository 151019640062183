import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { getVariantLabel } from 'com.batch.common/utils'

import {
  getLangFromContent,
  type MessageIdGetterSelector,
  previewLanguageSelector,
} from 'com.batch/message/store/message.selector'
import { getMessageConfigSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { type State } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import { getMessageIds, getMessageKey } from 'com.batch/message/models/message.helper'
import {
  SmsMessageFactory,
  SmsContentFactory,
  type SmsMessageRecord,
  type SmsContentRecord,
} from 'com.batch/message/models/message.records'
import { countSmsMessage } from 'com.batch/sms/usecases/count-sms-message'
import { validateSmsFields } from 'com.batch/sms/usecases/validate-sms-fields'

export const getSmsMessageSelector =
  (state: State): ((arg1: string) => SmsMessageRecord) =>
  messageId =>
    state.message.sms.get(messageId, SmsMessageFactory())

export const getSmsContentSelector: MessageIdGetterSelector<Map<string, SmsContentRecord>> =
  createSelector(getSmsMessageSelector, getPushMessage => (messageId: string) => {
    return getPushMessage(messageId).get('localizedContent', Immutable.Map())
  })

export const getSmsContentForActiveLanguageSelector: MessageIdGetterSelector<SmsContentRecord> =
  createSelector(
    getSmsContentSelector,
    previewLanguageSelector,
    (getData, langId) => (messageId: string) => {
      const data = getData(messageId)
      return data.get(langId ?? 'default', SmsContentFactory())
    }
  )

type getErrorsForMessage = (arg1: {
  stepMessageNodeId: string | null | undefined
}) => Map<string, Array<string>>
export const getSmsErrorsSelector: (arg1: State) => getErrorsForMessage = createSelector(
  getSmsMessageSelector,
  getMessageConfigSelector,
  subscriptionStatusSelector,
  (getMessage, getConfig, targetedUserbase) =>
    ({ stepMessageNodeId }) => {
      const config = getConfig({ stepMessageNodeId })
      const abEnabled = config.experiment.enabled
      const messageTypedIdList = getMessageIds(config)
      const errors: Array<[string, Array<string>]> = []
      messageTypedIdList.forEach((messageId, index) => {
        const message = getMessage(messageId)
        const localizedContent = message.get('localizedContent', Immutable.Map())
        const languages = getLangFromContent(localizedContent, config.multilanguageEnabled)
        languages.forEach(lang => {
          const contentForLang = localizedContent.get(lang.value, SmsContentFactory())
          const localErrors: Array<string> = []

          const smsMessage = contentForLang.smsMessage

          if (!smsMessage) localErrors.push('SMS message is missing')

          const { parts } = countSmsMessage({
            message: smsMessage,
            countStop: targetedUserbase === 'marketing',
          })

          const smsErrors = validateSmsFields({
            message: smsMessage,
            parts,
          })

          if (smsErrors.has('MSG_TOO_LONG')) localErrors.push('SMS message is too long')
          if (localErrors.length > 0)
            errors.push([
              getMessageKey(lang) + (abEnabled ? ` ${getVariantLabel(index)}` : ''),
              localErrors,
            ])
        })
      })
      return Immutable.Map(errors)
    }
)

export const smsSenderSelector: (arg1: State) => string | null | undefined = createSelector(
  currentProjectSelector,
  project => {
    return project.sspConfiguration?.senders.first()
  }
)
