export const config = {
  googleApiKey: 'AIzaSyAgQlH-bMnjXQiJzwpYtONY2jASRa5oAs0',
  pushRate: {
    free: 500,
    free2: 500,
    premium: 2000,
    pro: 20000,
    pro2: 50000,
    startup: 100000,
    premier: 1000000,
    enterprise: 1000000,
  },
  common: {
    urls: {
      appSettings: '/{companyId}/apps/{appId}/settings/{activeTab}',
      appSettingsThemeEditor: '/{companyId}/apps/{appId}/settings/themes/{id}',
      appSettingsDebug: '/{companyId}/apps/{appId}/settings/debug',
      appSettingsDebugTransactional: '/{companyId}/apps/{appId}/settings/debug/transactional',
      applications: '/api/companies/{companyId}/apps/all',
      findApp: '/api/app/{appId}',
      saveAppSettings: '/api/app/{appId}/save/settings',
      gettingStarted: '/api/app/{appId}/getting-started',
      saveAppAdsSettings: '/api/app/{appId}/save/ads',
      savePushSettings: '/api/app/{appId}/save/push-settings',
      overrideCustomData: '/api/app/{appId}/override-custom-data',
      savePushCertificate: '/api/app/{appId}/save/push-certificate/{type}',
      archiveApp: '/api/app/{appId}/archive',
      listCompanyThemes: '/api/companies/{companyId}/themes',
      createApp: '/api/companies/{companyId}/apps',
      infos: '/api/app/{appId}/data/infos',
      getPushSettings: '/api/app/{appId}/push-settings',
      testPushToken: {
        save: '/api/app/{appId}/test-push-token',
        delete: '/api/app/{appId}/test-push-token/{tokenId}',
      },
      fetchCompany: '/api/company/{companyId}',
      saveCompanyGdpr: '/api/companies/{companyId}/gdpr',
    },
  },
  regions: require('./regions'),
  billing: {
    urls: {
      createSubscription: '/api/companies/{companyId}/subscription/create',
      subscriptionSummary: '/api/companies/{companyId}/subscription/summary/{stripePlanId}?',
      existingPaymentInformation: '/api/companies/{companyId}/subscription/cur-payment-infos',
      saveSettings: '/api/companies/{companyId}/billing/settings',
      subscription: '/api/companies/{companyId}/subscription',
      upgradeCost: '/api/companies/{companyId}/subscription/upgrade-cost/{stripePlanId}',
      setNCA: '/api/companies/{companyId}/subscription/set-nca',
      cancelNCA: '/api/companies/{companyId}/subscription/cancel-nca',
      changePlan: '/api/companies/{companyId}/subscription/change-plan',
      toggleRenew: '/api/companies/{companyId}/subscription/renew/{renewIsActive}',
      invoices: '/api/companies/{companyId}/subscription/invoices',
      invoice: '/{companyId}/invoice/{invoiceId}',
      overviewPage: '/{companyId}/plans',
      billingInfoForm: '/plans/billing-information',
    },
  },
  campaign: {
    urls: {
      push: {
        data: '/api/app/{appId}/data/push/{id}',
        list: '/api/app/{appId}/campaigns',
        count: '/api/app/{appId}/push-campaigns/count-total',
        get: '/api/app/{appId}/push-campaign/{id}',
        play: '/api/app/{appId}/push-campaign/{id}/play',
        pause: '/api/app/{appId}/push-campaign/{id}/pause',
        test: '/api/app/{appId}/push-campaign/test',
        save: '/api/app/{appId}/push-campaign/persist',
        archive: '/api/app/{appId}/push-campaign/{id}/archive',
      },
      unlock: {
        list: '/api/app/{appId}/unlocks',
        get: '/api/app/{appId}/unlock/{id}',
        play: '/api/app/{appId}/unlock/{id}/{env}/play',
        pause: '/api/app/{appId}/unlock/{id}/{env}/pause',
      },
    },
  },
  clusters: [
    {
      code: 'N',
      color: '#F8DF7E',
      name: 'New',
      match: ['N', 'Np'],
      desc: "Newcomers to your app that haven't yet established themselves as engaged users.",
      apiSegmentId: 'NEW',
    },
    {
      code: 'E',
      color: '#9ED58A',
      name: 'Engaged',
      match: ['E', 'Er'],
      desc: 'Regular users of your app that are actively using it.',
      apiSegmentId: 'ENGAGED',
    },
    {
      code: 'D',
      color: '#F08B50',
      name: 'Dormant',
      match: ['D', 'Dp'],
      desc: 'Users who were engaged at one point, but have not launched your app for a long time.',
      apiSegmentId: 'DORMANT',
    },
    {
      code: 'U',
      color: '#CC3C4F',
      name: 'One time',
      match: ['Du'],
      desc: 'Users who did not come back after they first launched your app.',
      apiSegmentId: 'ONE_TIME',
    },
    {
      code: 'Np',
      color: '#F8DF7E',
      name: 'New Promising',
      match: [] as Array<string>,
      desc: 'Users who are about to become engaged.',
      apiSegmentId: 'NEW',
    },
    {
      code: 'Er',
      color: '#F8DF7E',
      name: 'Engaged risky',
      match: [] as Array<string>,
      desc: 'Users with a high risk of becoming dormant.',
      apiSegmentId: 'Engaged',
    },
    {
      code: 'Dp',
      color: '#F8DF7E',
      name: 'Dormant Promising',
      match: [] as Array<string>,
      desc: 'Users who are about to become engaged.',
      apiSegmentId: 'Dormant promising',
    },
    {
      code: 'I',
      name: 'Imported',
      match: ['I'],
      desc: 'Imported push tokens. These users are progressively analysed and transferred to other segments.',
      apiSegmentId: 'IMPORTED',
    },
  ],
  application: {
    urls: {
      analytics_fails: '/api/app/{appId}/data/analytics/fails/{from}/{to}',
      analytics: '/api/app/{appId}/data/analytics/{from}/{to}/{dim}/{mode}',
      engagement: '/api/app/{appId}/data/engagement',
      userbase: '/api/app/{appId}/data/userbase',
      look4user: '/api/app/{appId}/data/user/search',
      pushtokens: '/api/app/{appId}/data/pushtokens',
      customDataValues: '/api/app/{appId}/custom-data/values/{mode}/{attribute}',
      customDataList: '/api/app/{appId}/custom-data/summary/{mode}',
      pushoverview: '/api/app/{appId}/data/push-overview/{kind}/{from}/{to}',
    },
    item: {
      urls: {
        findByApp: '/api/app/{appId}/item',
        save: '/api/app/{appId}/item/save',
        archive: '/api/app/{appId}/item/archive',
        unarchive: '/api/app/{appId}/item/unarchive',
      },
    },
  },
}
