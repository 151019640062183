import styled from 'styled-components'

import { colors } from 'components/styled/tokens'

type SegmentLabelProps = {
  color: string
  checked: boolean
}
// type ThemeProps = {
//   disabledMode: boolean
// }
export const SegmentLabel = styled.label<SegmentLabelProps>`
  border: ${props => (props.checked ? 2 : 1)}px solid
    ${props => (props.checked ? props.color : colors.stroke)};
  border-radius: 6px;
  display: inline-flex;
  flex-grow: 1;
  padding: 10px 13px;
  pointer-events: ${p => (p.theme.disabledMode ? 'none' : 'unset')};
  margin: ${props => (props.checked ? '0 7px 0 0' : '1px 8px 1px 1px')};
  cursor: pointer;
  font-weight: 500;
  color: ${colors.text};
  span {
    margin: 0 10px 0 10px;
  }
  &:last-child {
    margin-right: 0;
  }
`
export const QueryReviewContainer = styled.div`
  pre {
    border-radius: 0px;
  }
  .hljs {
    padding: 16px;
  }
`
