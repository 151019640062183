import Immutable, { type List } from 'immutable'
import * as React from 'react'

import { useConfirmEditTrigger } from 'components/_hooks'
import { BoxHeader, HeaderBoxTitle, HeaderBoxActions } from 'components/common/box'
import { Button, Switch } from 'components/common/button'
import DateTimePicker from 'components/common/date-time-picker'
import { WrapLabel } from 'components/common/form/wrap-label'
import { Grid } from 'components/common/grid'
import Hint from 'components/common/hint'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { TimeIntervalInput } from 'components/common/time-interval-input'
import { Feedback, Select, Radio, InputWrapper, Input } from 'components/form'
import { schemes } from 'components/styled/tokens'

import { buildAgeFromInputValue, preventKeyPressNonInteger } from 'com.batch.common/utils'

import { PushWhenTriggerEvent } from './push-when-trigger-event'

import { Icon } from '../common/svg-icon'
import {
  type TriggerConfigRecord,
  type AttributeRecord,
  type EventDataAttributeRecord,
} from 'com.batch.redux/_records'

import { DELAY_MODE } from 'constants/common'
type TimerOption = { label: string; short: string; value: 'event' | 'before' | 'after' }
const TIMER_OPTIONS = Immutable.List<TimerOption>([
  {
    value: 'event',
    label: 'after trigger event occurred',
    short: 'after trigger event occurred',
  },
  { value: 'after', label: 'after a custom time...', short: 'after' },
  { value: 'before', label: 'before a custom time...', short: 'before' },
])

type PushWhenTriggerProps = {
  config: TriggerConfigRecord
  warnOnEdit: boolean
  isEmailAutomation: boolean
  update: (d: TriggerConfigRecord) => void
  events: List<AttributeRecord>
}

const triggerOptionFormater = (
  {
    label,
    short,
  }: {
    value: 'event' | 'before' | 'after'
    label: string
    short: string
  },
  {
    context,
  }: {
    context: 'value' | 'menu'
  }
) => (context === 'value' ? short : label)

const optToStringLabel = (
  opt?: {
    label: string
  } | null
) => opt?.label ?? ''

const optToStringName = (opt?: EventDataAttributeRecord | null) => opt?.name ?? ''

export const PushWhenTrigger = ({
  config,
  update,
  isEmailAutomation,
  warnOnEdit,
  events,
}: PushWhenTriggerProps): React.ReactElement => {
  const [showAdvanced, setShowAdvanced] = React.useState(config.hasInstanceId)
  const updateWhenConfirmed = useConfirmEditTrigger(update, warnOnEdit)
  const triggerEvent = React.useMemo(
    () => events.find(evt => evt.id === config.enterEvent),
    [config.enterEvent, events]
  )

  const createUpdateConfigHandler = React.useCallback(
    (property, bool) => () => {
      updateWhenConfirmed(config.set(property, bool))
    },
    [config, updateWhenConfirmed]
  )

  const triggerEventOptions = React.useMemo(
    () =>
      triggerEvent
        ? triggerEvent.allowedKeys.toList().filter(data => data.type === 'DATE')
        : Immutable.List<EventDataAttributeRecord>(),
    [triggerEvent]
  )
  const triggerInstanceIdOptions = React.useMemo(
    () =>
      triggerEvent
        ? triggerEvent.allowedKeys
            .toList()
            .filter(data => data.type === 'STRING' || data.type === '__LABEL__')
        : Immutable.List<EventDataAttributeRecord>(),
    [triggerEvent]
  )

  const handleQueryChanged = React.useCallback(
    query => {
      updateWhenConfirmed(config.set('enterEventQuery', query))
    },
    [config, updateWhenConfirmed]
  )

  const createDelayModeChangeHandler = React.useCallback(
    delayMode => () => {
      updateWhenConfirmed(config.set('delayMode', delayMode))
    },
    [config, updateWhenConfirmed]
  )

  const handlePushTimerChange = React.useCallback(
    age => {
      updateWhenConfirmed(config.set('pushTimer', age))
    },
    [config, updateWhenConfirmed]
  )

  const handlePushTimerModeChange = React.useCallback(
    (opt?: TimerOption | null) => {
      if (opt) updateWhenConfirmed(config.set('pushTimerMode', opt.value))
    },
    [config, updateWhenConfirmed]
  )

  const handlePushTimerReferenceChange = React.useCallback(
    opt => {
      if (opt) updateWhenConfirmed(config.set('pushTimerReference', opt.name))
    },
    [config, updateWhenConfirmed]
  )

  const handleExitEventEnabledChange = React.useCallback(
    exitEventEnabled => {
      updateWhenConfirmed(config.set('hasExitEvent', exitEventEnabled))
    },
    [config, updateWhenConfirmed]
  )

  const onExitEventQueryChange = React.useCallback(
    (eventId: string, key: number) => (evtQuery: any) => {
      updateWhenConfirmed(config.setIn(['exitEvents', key], { eventId, query: evtQuery }))
    },
    [config, updateWhenConfirmed]
  )

  const createOnExitEventChange = React.useCallback(
    (key: number) => (evt: string) => {
      updateWhenConfirmed(config.setIn(['exitEvents', key], { eventId: evt, query: null }))
    },
    [config, updateWhenConfirmed]
  )

  const handleExitEventRemove = React.useCallback(
    key => {
      updateWhenConfirmed(config.set('exitEvents', config.exitEvents.splice(key, 1)))
    },
    [config, updateWhenConfirmed]
  )

  const handleExitEventAdd = React.useCallback(() => {
    updateWhenConfirmed(
      config.set('exitEvents', config.exitEvents.push({ eventId: '', query: null }))
    )
  }, [config, updateWhenConfirmed])

  const handleCappingEnabledChange = React.useCallback(
    cappingEnabled => {
      update(config.set('hasCapping', cappingEnabled).set('capping', cappingEnabled ? 0 : 1))
    },
    [config, update]
  )

  const handleCappingChange = React.useCallback(
    evt => {
      const value = parseInt(evt.target.value)
      if (!isNaN(value)) {
        updateWhenConfirmed(config.set('capping', value))
      }
    },
    [config, updateWhenConfirmed]
  )

  const handleGraceEnabledChange = React.useCallback(
    graceEnabled => {
      updateWhenConfirmed(
        config
          .set('hasGrace', graceEnabled)
          .set('grace', buildAgeFromInputValue(graceEnabled ? 1 : 0, 'h'))
      )
    },
    [config, updateWhenConfirmed]
  )

  const handleGraceChange = React.useCallback(
    age => {
      updateWhenConfirmed(config.set('grace', age))
    },
    [config, updateWhenConfirmed]
  )

  const handleStartChange = React.useCallback(
    start => {
      updateWhenConfirmed(config.set('start', start))
    },
    [config, updateWhenConfirmed]
  )

  const handleEndChange = React.useCallback(
    end => {
      updateWhenConfirmed(config.set('end', end))
    },
    [config, updateWhenConfirmed]
  )

  const handleInstanceIdChange = React.useCallback(
    opt => {
      if (opt) {
        updateWhenConfirmed(
          config.set(
            'instanceId',
            opt.type === '__LABEL__' ? 'eventLabel()' : `eventAttr(attr: '${opt.name}')`
          )
        )
      }
    },
    [config, updateWhenConfirmed]
  )

  const handleEventChange = React.useCallback(
    evt => {
      if (evt)
        updateWhenConfirmed(
          config
            .set('enterEvent', evt)
            .set('instanceId', '')
            .set('enterEventQuery', null)
            .set('pushTimerMode', 'event')
            .set('pushTimerReference', '')
        )
    },
    [config, updateWhenConfirmed]
  )

  const onToggleAdvancedSettings = React.useCallback(() => {
    setShowAdvanced(!showAdvanced)
  }, [showAdvanced])

  return (
    <div style={{ minWidth: 430, margin: '0 -32px' }}>
      <WrapLabel
        label={
          <React.Fragment>
            Trigger event
            <Hint minTooltipWidth={150}>
              The user enters a user journey when this event occurs.
            </Hint>
          </React.Fragment>
        }
        id="enterEvent"
        style={{ position: 'relative', padding: '0 32px' }}
      >
        <PushWhenTriggerEvent
          queryId="enterEvent"
          isEmailAutomation={isEmailAutomation}
          eventId={config.enterEvent}
          events={events}
          onQueryChange={handleQueryChanged}
          onEventChange={handleEventChange}
        />
      </WrapLabel>
      <WrapLabel
        label={<React.Fragment>Send push notification</React.Fragment>}
        style={{
          padding: '0 32px',
          position: 'relative',
          marginBottom: config.delayMode === DELAY_MODE.IMMEDIATE ? '25px' : '32px',
        }}
      >
        <div style={{ display: 'block' }}>
          <div
            style={{ display: 'inline-flex', margin: '10px 0 16px 0' }}
            role="radiogroup"
            aria-label="Send push notification"
          >
            <InputWrapper style={{ width: '194px' }}>
              <Radio
                checked={config.delayMode === DELAY_MODE.TIMER}
                label="After a timer"
                onChange={createDelayModeChangeHandler(DELAY_MODE.TIMER)}
              />
            </InputWrapper>
            <InputWrapper style={{ margin: 0 }}>
              <Radio
                checked={config.delayMode === DELAY_MODE.IMMEDIATE}
                label="Immediately"
                onChange={createDelayModeChangeHandler(DELAY_MODE.IMMEDIATE)}
              />
            </InputWrapper>
          </div>
        </div>
        {config.delayMode !== DELAY_MODE.IMMEDIATE && (
          <Grid
            template={config.pushTimerMode === 'event' ? '160px 1fr' : '160px 210px 1fr'}
            gap={10}
          >
            <TimeIntervalInput
              age={config.pushTimer}
              min={config.pushTimerReference ? 0 : 60}
              max={3600 * 24 * 30}
              onChange={handlePushTimerChange}
            />
            <Select
              options={TIMER_OPTIONS}
              menuOffset={98}
              optionToString={optToStringLabel}
              optionFormatter={triggerOptionFormater}
              value={TIMER_OPTIONS.find(opt => opt.value === config.pushTimerMode)}
              onChange={handlePushTimerModeChange}
            />
            {config.pushTimerMode !== 'event' && (
              <Select
                optionToString={optToStringName}
                options={triggerEventOptions}
                placeholder={
                  triggerEventOptions.size === 0 ? 'No date attribute available' : 'Pick a date'
                }
                isDisabled={triggerEventOptions.size === 0}
                optionFormatter={optToStringName}
                value={triggerEventOptions.find(data => data.name === config.pushTimerReference)}
                onChange={handlePushTimerReferenceChange}
              />
            )}
          </Grid>
        )}
      </WrapLabel>
      {config.delayMode !== 'IMMEDIATE' && (
        <div style={{ padding: '0 30px' }}>
          <Switch
            isActive={config.hasExitEvent}
            onChange={handleExitEventEnabledChange}
            disabled={events.size === 0}
          >
            Cancellation event(s)
            <Hint maxTooltipWidth={260} minTooltipWidth={260}>
              If triggered, the user exits the user journey and the sending of the notification is
              cancelled.{' '}
              {events.size === 0 && (
                <strong>
                  Option is disabled beacuse we did not receive any event from your app.
                </strong>
              )}
            </Hint>
          </Switch>
          <div style={{ marginBottom: 6 }} />
          {config.hasExitEvent && (
            <React.Fragment>
              <Grid
                template={config.exitEvents.size <= 1 ? '1fr' : '1fr 23px'}
                style={{ marginBottom: -2 }}
              >
                {config.exitEvents.map(({ eventId }, key) => (
                  <React.Fragment key={key}>
                    <PushWhenTriggerEvent
                      queryId={`exitEvent-${key}`}
                      eventId={eventId}
                      isEmailAutomation={false}
                      events={events.filter(ev => !ev.id.startsWith('be.'))} // filter native events
                      onQueryChange={onExitEventQueryChange(eventId, key)}
                      onEventChange={createOnExitEventChange(key)}
                    />
                    {config.exitEvents.size > 1 && (
                      <Button
                        onClick={handleExitEventRemove}
                        style={{ marginTop: -15, height: 28, width: 28 }}
                      >
                        <Icon icon="close" />
                      </Button>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
              <Button
                intent="neutral"
                kind="secondary"
                addOn="prefix"
                style={{ height: 28, marginBottom: 16, display: 'inline-flex' }}
                onClick={handleExitEventAdd}
              >
                <Icon icon="add" />
                Add item
              </Button>
            </React.Fragment>
          )}
        </div>
      )}

      <WrapLabel
        label="Pressure"
        style={{ padding: '24px 32px 0 32px', borderTop: `1px solid ${schemes.darklucent['05']}` }}
      >
        <Grid
          template={config.hasCapping ? 'auto 50px 1fr' : 'auto 1fr'}
          gap={3}
          style={{ height: '40px', marginBottom: 12 }}
        >
          <Switch onChange={handleCappingEnabledChange} isActive={config.hasCapping}>
            {config.hasCapping ? 'Send only' : 'No capping'}
          </Switch>
          {config.hasCapping && (
            <React.Fragment>
              <Input
                type="number"
                min="1"
                style={{ marginRight: 10 }}
                value={config.capping}
                onKeyPress={preventKeyPressNonInteger}
                onChange={handleCappingChange}
              />

              <span>notification{!!config.capping && config.capping > 1 && 's'} per user</span>
            </React.Fragment>
          )}
        </Grid>
        {!config.hasCapping && (
          <Feedback
            message="The user might receive the notification each time the trigger event occurs."
            style={{ margin: '-10px 0 12px 0' }}
          />
        )}

        <Grid
          template={config.hasGrace ? 'auto 114px 1fr' : 'auto 1fr'}
          gap={3}
          style={{ height: '40px', marginBottom: 12 }}
        >
          <Switch onChange={handleGraceEnabledChange} isActive={config.hasGrace}>
            {config.hasGrace ? 'Grace period' : 'No grace period'}
            <Hint maxTooltipWidth={260} minTooltipWidth={260}>
              Users won't enter in a new automation if a message has already been sent to them
              during the grace period.
            </Hint>
          </Switch>
          {config.hasGrace && (
            <span>
              <TimeIntervalInput
                age={config.grace}
                min={3600}
                max={7 * 24 * 3600}
                allowedUnits={['h', 'd']}
                onChange={handleGraceChange}
                style={{ display: 'inline-flex' }}
              />
            </span>
          )}
        </Grid>
        {!config.hasGrace ? (
          <Feedback
            message="The message might be send multiple times in a very short time."
            style={{ margin: '-10px 0 12px 0' }}
          />
        ) : (
          <Feedback message="Grace period is up to 7 days." style={{ margin: '0px 0 12px 0' }} />
        )}
      </WrapLabel>
      <WrapLabel label="Starting" style={{ padding: '0 32px' }}>
        <TableToggle>
          <TableToggleItem
            active={!config.hasStart}
            onClick={createUpdateConfigHandler('hasStart', false)}
            style={{ flexBasis: '40px' }}
          >
            Now
          </TableToggleItem>
          <TableToggleItem
            active={config.hasStart}
            onClick={createUpdateConfigHandler('hasStart', true)}
          >
            On&nbsp;a&nbsp;specific&nbsp;date
          </TableToggleItem>
        </TableToggle>
        {config.hasStart && (
          <div style={{ marginTop: 16 }}>
            <DateTimePicker value={config.start} update={handleStartChange} />
          </div>
        )}
      </WrapLabel>
      <WrapLabel label="Ending" style={{ padding: '0 32px' }}>
        <TableToggle>
          <TableToggleItem
            active={!config.hasEnd}
            onClick={createUpdateConfigHandler('hasEnd', false)}
            style={{ flexBasis: '40px' }}
          >
            Never
          </TableToggleItem>
          <TableToggleItem
            active={config.hasEnd}
            onClick={createUpdateConfigHandler('hasEnd', true)}
          >
            On&nbsp;a&nbsp;specific&nbsp;date
          </TableToggleItem>
        </TableToggle>
        {config.hasEnd && (
          <div style={{ marginTop: 16 }}>
            <DateTimePicker value={config.end} update={handleEndChange} />
          </div>
        )}
      </WrapLabel>
      <BoxHeader
        style={{
          borderTop: '1px solid #ececec',
          height: 48,
          marginBottom: showAdvanced ? 16 : -26,
          marginLeft: 12,
          marginRight: 12,
          borderRadius: showAdvanced ? 0 : '0 0 8px 8px',
          borderBottom: '1px solid rgba(233,233,233,1)',
          cursor: 'pointer',
        }}
        onClick={onToggleAdvancedSettings}
      >
        <HeaderBoxTitle title="Advanced settings" />
        <HeaderBoxActions>
          <Button style={{ height: 28, width: 28 }}>
            <Icon icon={showAdvanced ? 'chevron-up' : 'chevron-down'} />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>
      {showAdvanced && (
        <div>
          <WrapLabel
            label={
              <React.Fragment>
                Multi triggers
                <Hint maxTooltipWidth={260}>
                  If the same user triggers again the event while a notification is already
                  scheduled, how should we handle it?
                </Hint>
              </React.Fragment>
            }
            id="instanceId"
            style={{ position: 'relative', padding: '0 32px' }}
          >
            <div role="radiogroup" aria-label="Multi triggers">
              <div style={{ marginBottom: 10, marginTop: 10 }}>
                <Radio
                  checked={!config.hasInstanceId}
                  label="Schedule a new push and reset the timer at each event received"
                  onChange={createUpdateConfigHandler('hasInstanceId', false)}
                />
              </div>
              <div>
                <Radio
                  checked={config.hasInstanceId}
                  label="Schedule a new push based on an event attribute"
                  onChange={createUpdateConfigHandler('hasInstanceId', true)}
                />
              </div>
            </div>
            {config.hasInstanceId && (
              <InputWrapper style={{ paddingLeft: 25, marginTop: 12 }}>
                <Select
                  isSearchable
                  optionToString={optToStringName}
                  options={triggerInstanceIdOptions}
                  placeholder={
                    config.enterEvent && config.enterEvent !== ''
                      ? triggerInstanceIdOptions.size === 0
                        ? 'No string attribute available for this trigger event'
                        : 'Pick an event attribute'
                      : 'Pick a trigger event first'
                  }
                  isDisabled={triggerInstanceIdOptions.size === 0}
                  optionFormatter={optToStringName}
                  value={triggerInstanceIdOptions.find(data => {
                    const formated =
                      data.type === '__LABEL__' ? 'eventLabel()' : `eventAttr(attr: '${data.name}')`
                    return formated === config.instanceId
                  })}
                  onChange={handleInstanceIdChange}
                />{' '}
              </InputWrapper>
            )}
          </WrapLabel>
        </div>
      )}
    </div>
  )
}
