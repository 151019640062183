import styled, { css } from 'styled-components'

import { Box } from 'components/common/box'
import { Label } from 'components/common/label/label.styles'
import { IconContainer } from 'components/common/svg-icon'
import { TableContainer } from 'components/common/table/table.styles'
import { Ellipsis } from 'components/styled/text'
import { colors, schemes } from 'components/styled/tokens'
import { text, textSuccess } from 'components/styled/tokens/colors'
import { darklucent, grayscale } from 'components/styled/tokens/schemes'

import { AttributeRowContainer } from 'com.batch/profile/ui/components/attributes-block/attributes-block.styles'
import {
  TimeContent,
  TimelineContent,
} from 'com.batch/profile/ui/components/events-block/timeline/timeline.styles'
import { EmptyField } from 'components/common/empty-states'

export const EventRowContent = styled.div`
  padding-left: 12px;
  padding-right: 20px;
  display: flex;

  ${TimeContent} {
    margin-top: 11px;
    margin-right: 24px;
  }
`

type EventRowDetailsProps = {
  $isToggable: boolean
}

export const EventRowDetails = styled.div<EventRowDetailsProps>`
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${colors.textLight};
  > div {
    display: flex;
    align-items: center;
    padding: 8px 0;
    > :first-child {
      font-weight: 500;
      color: ${colors.textNeutral};
    }
  }

  .toggle-icon {
    display: none;
  }

  ${props =>
    props.$isToggable &&
    css`
      &:hover {
        > div:hover {
          cursor: pointer;
          color: ${colors.textNeutral};
          
          ${EventRowDetailsSource} {
            svg, ${EventRowDetailsDay}, rect {
              fill: ${grayscale[50]};
              color: ${grayscale[50]};
            }

            .reattributed-icon > svg {
              fill: ${colors.textNeutral};
              color: ${colors.textNeutral};
            }
        }

        .toggle-icon {
          display: block;
          color: ${colors.textLight};
        }
      }
    `}
`

export const EventTooltipContainer = styled.div`
  text-align: left;
  font-size: 14px;

  .tz {
    margin-left: 6px;
    font-weight: 600;
  }
`

export const EventRowDetailsDay = styled.div`
  color: ${colors.textLight};
  position: relative;
  margin-left: 8px;

  &::before {
    content: '.';
    position: absolute;
    left: -6px;
    bottom: 3px;
  }
`

export const EventRowContentInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 4px;
  width: 100%;

  ${Label} {
    margin-right: 4px;
  }

  ${TableContainer} {
    margin-top: 8px;
    margin-bottom: 8px;
    background: ${schemes.grayscale['02']};
    border: 1px solid ${schemes.darklucent['20']};
    border-radius: 8px;

    ${AttributeRowContainer} {
      min-height: 40px;
    }
  }
`

export const EventRowDetailsSource = styled.span`
  display: flex;
  cursor: pointer;
  color: ${schemes.grayscale['40']};

  &:hover svg,
  &:hover ${EventRowDetailsDay}, &:hover rect {
    fill: ${colors.textNeutral};
    color: ${colors.textNeutral};
  }
`

export const AttributesContainer = styled.span`
  & button {
    height: 24px;
    padding: 6px;
  }
`

export const ChannelBadgeContainer = styled(Label)`
  display: inline-flex;
  align-items: baseline;
  font-weight: 500;
  padding: 2px 6px 2px 7px;
  gap: 6px;
`

type MessagingEventNameProps = {
  $twoLines?: boolean
}

export const MessagingEventName = styled.div<MessagingEventNameProps>`
  display: inline-flex;
  max-width: 100%;
  align-items: flex-end;

  ${props =>
    props.$twoLines &&
    css`
      ${Ellipsis} {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        align-self: stretch;
        white-space: normal;
      }
    `}
  > ${IconContainer} {
    margin-left: 8px;
    color: ${colors.textLight};
  }
`

export const MessagingEventText = styled.div`
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-flow: column;
  gap: 2px;

  a {
    margin-left: 4px;
  }

  ${MessagingEventName} {
    > ${IconContainer} {
      display: none;
      position: relative;
      top: -3px;
    }

    &:hover {
      > ${IconContainer} {
        display: block;
      }
    }
  }

  ${MessagingEventName}:first-child a {
    font-weight: 500;
    text-overflow: ellipsis;
    color: ${colors.textNeutral};
  }

  ${MessagingEventName}:nth-child(2) a {
    color: ${colors.textLight};
    font-weight: 400;
    text-overflow: ellipsis;
  }
`

export const MessagingEventRowBox = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 166px;
  width: 100%;
  gap: 8px;
  min-height: 92px;
  z-index: 0;
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;
    padding: 8px 8px 12px 8px;
    overflow: hidden;

    ${EmptyField} {
      margin-top: 6px;
    }

    ${ChannelBadgeContainer} {
      margin-bottom: 6px;
    }
  }

  > img {
    width: 166px;
    height: 100%;
    border-left: 1px solid ${darklucent[20]};
    background: ${grayscale['02']};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

type MessagingEventRowContainerProps = {
  $variant?: string
}

export const MessagingEventRowContainer = styled.div<MessagingEventRowContainerProps>`
  display: flex;
  align-items: flex-start;
  padding-left: 12px;
  padding-right: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: rgb(225, 225, 225);
    width: 1.5px;
    top: 0;
    left: 91px;
    height: 12px;

    ${props => {
      if (!props.$variant) {
        return null
      }
      switch (props.$variant) {
        case 'action':
          return css`
            background: ${colors.textAction};
          `
        case 'feature':
          return css`
            background: ${colors.textFeature};
          `
        case 'neutral':
          return css`
            background: ${text};
          `
        case 'success':
          return css`
            background: ${textSuccess};
          `
        default:
          return css`
            background: ${text};
          `
      }
    }}
  }

  &::after {
    content: '';
    position: absolute;
    background: rgb(225, 225, 225);
    width: 1.5px;
    bottom: 0;
    left: 91px;
    height: 12px;
  }

  ${TimeContent} {
    margin-top: 23px;
    margin-right: 10px;
  }
`

type LightMessagingEventContainerProps = {
  $variant: string
  $nextVariant?: string
}

export const LightMessagingEventContainer = styled.div<LightMessagingEventContainerProps>`
  display: flex;
  position: relative;
  padding: 12px 20px 4px 12px;

  &::before {
    content: '';
    position: absolute;
    background: rgb(225, 225, 225);
    ${props => {
      if (!props.$nextVariant) {
        return null
      }
      switch (props.$nextVariant) {
        case 'action':
          return css`
            background: ${colors.textAction};
          `
        case 'feature':
          return css`
            background: ${colors.textFeature};
          `
        case 'neutral':
          return css`
            background: ${text};
          `
        case 'success':
          return css`
            background: ${textSuccess};
          `
        default:
          return css`
            background: ${text};
          `
      }
    }}
    width: 1.5px;
    margin-top: -12px;
    left: 91px;
    height: 12px;
  }

  &::after {
    content: '';
    position: absolute;
    background: rgb(225, 225, 225);
    ${props => {
      switch (props.$variant) {
        case 'action':
          return css`
            background: ${colors.textAction};
          `
        case 'feature':
          return css`
            background: ${colors.textFeature};
          `
        case 'neutral':
          return css`
            background: ${text};
          `
        case 'success':
          return css`
            background: ${textSuccess};
          `
        default:
          return css`
            background: ${text};
          `
      }
    }}
    width: 1.5px;
    bottom: 0;
    left: 91px;
    height: 4px;
  }

  ${TimeContent} {
    margin-top: 3px;
    margin-right: 18px;
  }
`

export const MessagingEventTooltip = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  text-align: left;

  > :first-child {
    font-weight: 500;
  }

  > :last-child {
    color: ${colors.textContrastLight};
    font-size: 14px;
  }
`

export const EventRowContentSkeleton = styled(EventRowContent)`
  padding-left: 30px;

  ${EventRowContentInformation} {
    justify-content: center;
  }

  ${TimelineContent} {
    &::before {
      top: -7px;
      right: calc(50% - 1px);
    }

    &::after {
      bottom: -10px;
      top: 0;
      right: calc(50% - 1px);
    }
  }
`
