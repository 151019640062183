import styled, { css } from 'styled-components'

import { BoxBody, BoxFooter } from 'components/common/box'
import { Grid } from 'components/common/grid'
import * as colorsLegacy from 'components/styled/colors'
import { schemes, colors } from 'components/styled/tokens'

// ====================== STYLED COMPONENTS
export const MediaUrlWarning = styled.div`
  color: ${colors.textWarning};
  padding-top: 20px;
`
type MediaUrlContainerProps = {
  kind: 'field' | 'content'
  isActive: boolean
  isInvalid: boolean
  isDragReject: boolean
}
export const MediaUrlContainer = styled.div<MediaUrlContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px;
  width: 100%;
  min-height: 72px;
  border-radius: 5px;

  /* fields */
  ${p =>
    p.isDragReject || p.isActive
      ? css`
          background-color: ${colors.fillBackground};
          border: 2px dashed ${schemes.blue['30']};
        `
      : p.isInvalid
        ? css`
            background-color: ${schemes.grayscale['01']};
            border: 1px dashed ${colors.textDanger};
          `
        : css`
            background-color: ${schemes.grayscale['01']};
            border: 1px dashed ${schemes.darklucent['20']};
          `}

  button:last-of-type {
    margin-left: 8px;
  }
`
type MediaUrlTextProps = {
  isInvalid?: boolean
}
export const MediaUrlText = styled.p<MediaUrlTextProps>`
  font-size: 13px;
  font-weight: 500;
  line-height: 12px;
  color: ${p => (p.isInvalid ? colors.textDanger : colors.textLight)};
  min-width: 332px;
  text-align: center;
`

export const MediaUrlLoading = styled.div`
  & > i {
    font-size: 11px;
    color: ${colors.textActionContrast};
  }

  & > span {
    margin-left: 8px;
    color: ${colors.textLight};
  }
`

type MediaUrlPreviewContainerProps = {
  hasMeta: boolean
}
export const MediaUrlPreviewContainer = styled(Grid)<MediaUrlPreviewContainerProps>`
  height: 100%;
  width: 100%;

  .styled-media-url-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    ${'' /* height: 100%; */}
    height: 54px;
    color: ${colors.textDisabled};
    background-color: ${schemes.grayscale['80']};
    border-radius: 3px;
    overflow: hidden;

    & > i {
      padding-bottom: 2px;
    }
  }

  .styled-med-url-title {
    ${p =>
      !p.hasMeta
        ? css`
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          `
        : css`
            display: block;
          `}

    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01rem;
    max-width: 360px;
    color: ${colors.textNeutral};
  }

  .styled-med-url-infos {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01rem;
    color: ${colors.textLight};
  }

  .styled-med-url-error {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01rem;
    color: ${colors.textDanger};
  }

  .styled-url-template-tag {
    display: inline;
    padding: 0 2px;
    color: ${colors.textAction};
    background-color: #edf9ff;
    border-radius: 20px;
  }

  button {
    margin: 0 !important;
  }
`

// ====================== STYLED COMPONENTS
export const MediaUrlPopupBody = styled(BoxBody)`
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 410px;

  & > div {
    &:first-of-type {
      padding: 24px 22px;
    }

    &:last-of-type {
      background-color: #fdfdfd;
      border-left: 1px solid ${colorsLegacy.stroke.lighter};
    }
  }
`

export const MediaUrlPopupFooter = styled(BoxFooter)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 12px;

  & > button:first-of-type {
    margin-right: 8px;
  }
`

export const MediaUrlInfoSection = styled.div`
  margin: 0 22px;
  padding: 24px 0;
  border-bottom: 1px solid ${colorsLegacy.stroke.lighter};

  &:last-of-type {
    border-bottom: 0;
  }
`

export const MediaUrlSectionHeader = styled(Grid)`
  margin-bottom: 12px;

  svg {
    color: ${colors.textLight};
  }

  h4 {
    font-size: 1em;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01rem;
    color: ${colors.textNeutral};
  }
`
type MediaUrlTagProps = {
  dark?: boolean
}
export const MediaUrlTag = styled.div<MediaUrlTagProps>`
  display: inline-block;
  margin: 0 2px;
  padding: 2px 4px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -0.01rem;
  color: ${schemes.lightlucent['100']};
  background-color: ${p => (p.dark ? 'rgba(0, 0, 0, 0.28)' : schemes.grayscale['60'])};
  border-radius: 4px;
`
type MediaUrlParagraphProps = {
  warning?: boolean
  hasLinkBelow?: boolean
}
export const MediaUrlParagraph = styled.p<MediaUrlParagraphProps>`
  margin-top: 8px;
  margin-bottom: ${p => (p.hasLinkBelow ? 8 : 0)}px;
  font-size: 1em;
  font-weight: ${p => (p.warning ? 500 : 400)};
  letter-spacing: -0.01rem;
  color: ${p => (p.warning ? colors.textWarning : colors.textNeutral)};
`

// Media URL hint
export const MediaUrlHintContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: left;

  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.01rem;

  & > div {
    &:first-of-type {
      padding-right: 12px;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    &:last-of-type {
      padding-left: 12px;
    }
  }

  .styled-med-url-hint-title {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    color: ${schemes.lightlucent['100']};
  }

  p {
    margin-top: 8px;
    font-weight: 400;
    color: ${schemes.grayscale['20']};
  }
`
