import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useSelector } from 'com.batch.common/react-redux'

import { type availableIcons, Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { VariantTitle } from 'components/styled/text'

import {
  ReviewHeader,
  ReviewImage,
  ReviewLinks,
  ReviewSubtitle,
  ReviewValue,
} from './text-review.styles'

import { type CampaignABRecord, type Variant } from 'com.batch.redux/_records'
import { currentCampaign } from 'com.batch.redux/campaign.selector'
import { type AbTestedInAppRecord } from 'com.batch.redux/content.records'
import type { FieldRecord } from 'com.batch.redux/theme.records'
import { type AbTestedThemeRecord } from 'com.batch.redux/theme.records'
import { InAppVariantFieldsSelector } from 'com.batch.redux/theme.selector'

type ReviewFieldsProps = {
  lang: string
  abTestedInAppContent: Map<string, AbTestedInAppRecord>
  abtesting: CampaignABRecord
  variantsThemes: AbTestedThemeRecord
}
const variants: Variant[] = ['a', 'b']

export const ReviewFields = ({
  lang,
  abTestedInAppContent,
  abtesting,
  variantsThemes,
}: ReviewFieldsProps): React.ReactElement => {
  // selector
  const usedInAppField = useSelector(InAppVariantFieldsSelector)
  const campaign = useSelector(currentCampaign)

  const displayTooltip = (
    buttonAction: 'mainButtonAction' | 'secondaryButtonAction' | 'globalTapAction',
    variant: Variant
  ): string | null | undefined => {
    // @ts-expect-error ts can't refine type with getIn
    return abTestedInAppContent.getIn([lang, variant, buttonAction, 'action', 'value']) ===
      'batch.deeplink'
      ? abTestedInAppContent.getIn([lang, variant, buttonAction, 'deeplinkUrl'])
      : abTestedInAppContent.getIn([lang, variant, buttonAction, 'action', 'value']) ===
          'batch.clipboard'
        ? abTestedInAppContent.getIn([lang, variant, buttonAction, 'copyText'])
        : ''
  }

  return (
    <div style={{ display: 'flex' }}>
      {variants
        .filter(variant => variant === 'a' || (abtesting.enabled && campaign.type !== 'push'))
        .map((variant: Variant) => {
          // Flow est en erreur avec {variant} car il ne comprend pas que c'est égal à 'a' ou 'b' donc il faut re-déclarer une var
          const variantForFlow = variant === 'a' ? 'a' : 'b'
          const usedInAppFieldsId =
            usedInAppField.get(variant)?.map((k: FieldRecord) => k.id) ?? Immutable.List()
          return (
            <div style={{ background: '#fbfbfc', padding: 8 }} key={variant}>
              {abtesting.enabled && campaign.type !== 'push' && (
                <VariantTitle variant={variant}>
                  Version {variant.toUpperCase()} (
                  {abtesting.activeVariants.has(variant)
                    ? abtesting.activeVariants.size === 2
                      ? '50'
                      : '100'
                    : '0'}
                  %)
                </VariantTitle>
              )}
              {variantsThemes[variant]?.payloadVars.kind === 'webview' ? (
                <div>HTML content in WebView</div>
              ) : (
                <React.Fragment>
                  <ReviewHeader>
                    {usedInAppFieldsId.includes('header') &&
                      (abTestedInAppContent.getIn([lang, variant, 'header']) as string)}
                  </ReviewHeader>
                  <ReviewSubtitle>
                    {usedInAppFieldsId.includes('title') &&
                      (abTestedInAppContent.getIn([lang, variant, 'title']) as string)}
                  </ReviewSubtitle>
                  <ReviewValue>
                    {usedInAppFieldsId.includes('text') &&
                      (abTestedInAppContent.getIn([lang, variant, 'text']) as string)}
                  </ReviewValue>
                  {usedInAppFieldsId.includes('image') ? (
                    abTestedInAppContent.getIn([lang, variant, 'imageUrl']) ? (
                      <ReviewImage>
                        {displayTooltip('globalTapAction', variant) ? (
                          <Tooltip
                            tooltip={displayTooltip('globalTapAction', variant) ?? ''}
                            placement="right"
                          >
                            <span>
                              <Icon
                                style={{ marginRight: 4 }}
                                icon={
                                  (abTestedInAppContent.getIn([
                                    lang,
                                    variantForFlow,
                                    'globalTapAction',
                                    'action',
                                    'icon',
                                  ]) as Maybe<availableIcons>) ?? 'add'
                                }
                              />
                              <span>[image]</span>
                            </span>
                          </Tooltip>
                        ) : (
                          <React.Fragment>
                            <Icon
                              style={{ marginRight: 4 }}
                              icon={
                                (abTestedInAppContent.getIn([
                                  lang,
                                  variantForFlow,
                                  'globalTapAction',
                                  'action',
                                  'icon',
                                ]) as Maybe<availableIcons>) ?? 'add'
                              }
                            />
                            <span>[image]</span>
                          </React.Fragment>
                        )}
                      </ReviewImage>
                    ) : null
                  ) : null}
                  <ReviewLinks>
                    {usedInAppFieldsId.includes('cta1') ? (
                      abTestedInAppContent.getIn([lang, variant, 'mainButtonLabel']) ? (
                        displayTooltip('mainButtonAction', variant) ? (
                          <Tooltip
                            placement="right"
                            tooltip={displayTooltip('mainButtonAction', variant) ?? ''}
                          >
                            <span>
                              <Icon
                                icon={
                                  (abTestedInAppContent.getIn([
                                    lang,
                                    variantForFlow,
                                    'mainButtonAction',
                                    'action',
                                    'icon',
                                  ]) as Maybe<availableIcons>) ?? 'add'
                                }
                                style={{ marginRight: 4 }}
                              />
                              {
                                abTestedInAppContent.getIn([
                                  lang,
                                  variantForFlow,
                                  'mainButtonLabel',
                                ]) as string
                              }
                            </span>
                          </Tooltip>
                        ) : (
                          <span>
                            <Icon
                              icon={
                                (abTestedInAppContent.getIn([
                                  lang,
                                  variantForFlow,
                                  'mainButtonAction',
                                  'action',
                                  'icon',
                                ]) as Maybe<availableIcons>) ?? 'add'
                              }
                              style={{ marginRight: 4 }}
                            />
                            {
                              abTestedInAppContent.getIn([
                                lang,
                                variantForFlow,
                                'mainButtonLabel',
                              ]) as string
                            }
                          </span>
                        )
                      ) : null
                    ) : null}

                    {usedInAppFieldsId.includes('cta2') &&
                    abTestedInAppContent.getIn([lang, variant, 'secondaryButtonLabel']) ? (
                      displayTooltip('secondaryButtonAction', variant) ? (
                        <Tooltip
                          placement="right"
                          tooltip={displayTooltip('secondaryButtonAction', variant) ?? ''}
                        >
                          <span style={{ marginLeft: 10 }}>
                            <Icon
                              style={{ marginRight: 4 }}
                              icon={
                                (abTestedInAppContent.getIn([
                                  lang,
                                  variantForFlow,
                                  'secondaryButtonAction',
                                  'action',
                                  'icon',
                                ]) as Maybe<availableIcons>) ?? 'add'
                              }
                            />
                            {
                              abTestedInAppContent.getIn([
                                lang,
                                variantForFlow,
                                'secondaryButtonLabel',
                              ]) as string
                            }
                          </span>
                        </Tooltip>
                      ) : (
                        <span style={{ marginLeft: 10 }}>
                          <Icon
                            style={{ marginRight: 4 }}
                            icon={
                              (abTestedInAppContent.getIn([
                                lang,
                                variantForFlow,
                                'secondaryButtonAction',
                                'action',
                                'icon',
                              ]) as Maybe<availableIcons>) ?? 'add'
                            }
                          />
                          {
                            abTestedInAppContent.getIn([
                              lang,
                              variantForFlow,
                              'secondaryButtonLabel',
                            ]) as string
                          }
                        </span>
                      )
                    ) : null}
                  </ReviewLinks>
                </React.Fragment>
              )}
            </div>
          )
        })}
    </div>
  )
}
