import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { ThemeProvider } from 'styled-components'

import { useToggle, type StateToggler } from 'components/_hooks'
import {
  Box,
  BoxHeader,
  BoxBody,
  BoxFooter,
  HeaderBoxTitle,
  HeaderBoxActions,
  FooterBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Input } from 'components/form'
import { TextFeedback } from 'components/styled/text'

import { campaignConfigSelector } from 'com.batch.redux/campaign'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { updatePreviewProfile } from 'com.batch/email/usecases/update-preview-profile'
import { saveStringValueInLocalStorage } from 'com.batch/shared/usecases/save-restore-local'

export const savedProfilesKey = 'previewAsProfiles'

type PreviewProfilePopinProps = {
  togglePopin: StateToggler
  updateTemplate: () => void
}

export const PreviewProfilePopin = ({
  togglePopin,
  updateTemplate,
}: PreviewProfilePopinProps): React.ReactElement => {
  const [value, setValue] = React.useState('')
  const lookingUserState = useToggle()
  const [feedback, setFeedback] = React.useState<'NOT_FOUND' | 'ERROR' | 'NONE'>('NONE')
  const config = useSelector(campaignConfigSelector)
  const dispatch = useDispatch()
  const { id: projectId } = useSelector(currentProjectSelector)
  const scopedSavedProfilesKey = React.useMemo(
    () => `${projectId}-${savedProfilesKey}`,
    [projectId]
  )
  const onUserIdChange = React.useCallback(event => setValue(event.target.value), [])
  const onSubmit = React.useCallback(
    evt => {
      evt.preventDefault()
      lookingUserState.open()
      setFeedback('NONE')

      dispatch(updatePreviewProfile('user_id', value)).then(state => {
        lookingUserState.close()
        if (state === 'FOUND') {
          saveStringValueInLocalStorage({
            key: scopedSavedProfilesKey,
            value,
          })
          setValue('')
          updateTemplate()
          togglePopin.close()
        } else {
          setFeedback(state === 'NOT_FOUND' ? 'NOT_FOUND' : 'ERROR')
        }
      })
    },
    [dispatch, lookingUserState, togglePopin, value, scopedSavedProfilesKey, updateTemplate]
  )

  return (
    <ThemeProvider theme={{ isDark: false }}>
      <Popin
        opened={togglePopin.value}
        close={togglePopin.close}
        style={{ width: 480, overflowY: 'hidden' }}
      >
        <form onSubmit={onSubmit}>
          <Box>
            <BoxHeader>
              <HeaderBoxTitle title="Preview with user data" />
              <HeaderBoxActions>
                <Button kind="inline" intent="neutral" onClick={togglePopin.close} type="button">
                  <Icon icon="close" />
                </Button>
              </HeaderBoxActions>
            </BoxHeader>
            <BoxBody $padding>
              <Input
                autoFocus
                type="search"
                placeholder="Enter a custom ID"
                value={value}
                onChange={onUserIdChange}
              />
              {feedback === 'ERROR' ? (
                <TextFeedback style={{ marginTop: 4, color: 'crimson' }}>
                  Unable to contact our services.
                </TextFeedback>
              ) : feedback === 'NOT_FOUND' ? (
                <TextFeedback style={{ marginTop: 4, color: 'crimson' }}>
                  Requested profile was not found
                </TextFeedback>
              ) : config.previewSourceValue ? (
                <TextFeedback style={{ marginTop: 4 }}>
                  Clean the input to see the default data.
                </TextFeedback>
              ) : null}
            </BoxBody>
            <BoxFooter isEditable>
              <Button kind="inline" onClick={togglePopin.close} type="button">
                Cancel
              </Button>
              <FooterBoxActions>
                <Button
                  kind="primary"
                  intent="action"
                  isLoading={lookingUserState.value}
                  type="submit"
                >
                  Update preview
                </Button>
              </FooterBoxActions>
            </BoxFooter>
          </Box>
        </form>
      </Popin>
    </ThemeProvider>
  )
}
