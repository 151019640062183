import { type Dayjs } from 'dayjs'
import * as React from 'react'

import Loader from 'components/common/loader-legacy'
import { Icon, type availableIcons } from 'components/common/svg-icon'
import { CapsLabel } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { CertifFileReview, CertifFileStatus, CertifFileFeature } from './settings.styles'

type IOSCertReviewProps = {
  isParsing: boolean
  isValid: boolean
  expire: Dayjs | null | undefined
  filename: string | null | undefined
  cn: string | null | undefined
  mode: string | null | undefined
}
type StateStyle = {
  color: 'success' | 'warning' | 'error'
  icon: availableIcons
}

const IOSCertReview = ({
  isParsing,
  isValid,
  expire,
  filename,
  cn,
  mode,
}: IOSCertReviewProps): React.ReactElement => {
  let stateStyles: StateStyle = { color: 'error', icon: 'error' }
  if (!isValid || mode === 'P8') {
    stateStyles = { color: 'warning', icon: 'help' }
  }
  if (mode === 'P12' && isValid && expire && expire.isAfter(dayjs())) {
    stateStyles = { color: 'success', icon: 'success' }
  }

  return (
    <CertifFileReview>
      <CertifFileStatus intent={stateStyles.color}>
        <Loader loading={isParsing}>
          <Icon icon={stateStyles.icon} size={16} />
        </Loader>
      </CertifFileStatus>

      <CertifFileFeature>
        <CapsLabel>Name</CapsLabel>
        <span className="ellipsis">{mode === 'P12' && isValid ? cn : filename}</span>
      </CertifFileFeature>

      <CertifFileFeature>
        <CapsLabel>Format</CapsLabel>
        <span>
          {mode === 'P8'
            ? 'JWT APN .p8 Private Key'
            : isValid
              ? 'Universal .p12 APN Certificate'
              : 'Unkown'}
        </span>
      </CertifFileFeature>

      <CertifFileFeature>
        <CapsLabel>Expires</CapsLabel>
        <span>
          {mode === 'P8' ? '—' : isValid && expire ? expire.format('DD/MM/YYYY') : 'Unkown'}
        </span>
      </CertifFileFeature>
    </CertifFileReview>
  )
}

export default IOSCertReview
