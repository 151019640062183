import Immutable, { type RecordOf } from 'immutable'

type DataProps = {
  value: number
  rate: number
}
export type DataRecord = RecordOf<DataProps>
export const DataFactory = Immutable.Record<DataProps>({
  value: 0,
  rate: 0,
} as DataProps)

type BounceDetailProps = {
  total: number
  block: DataRecord
  hard: DataRecord
  soft: DataRecord
}
export type BounceDetailRecord = RecordOf<BounceDetailProps>
export const BounceDetailFactory = Immutable.Record<BounceDetailProps>({
  total: 0,
  block: DataFactory(),
  hard: DataFactory(),
  soft: DataFactory(),
} as BounceDetailProps)
