import Immutable, { type List } from 'immutable'
import { createSelector } from 'reselect'
import request from 'superagent-interface-promise'

import { generateUrl } from 'com.batch.common/router'

import {
  AppStateFactory,
  type AppStateRecord,
  type AppFilterRecord,
  type State,
} from './console.records'
import { type LoadProjectsSuccessAction } from './project'

import { AppFactory, type AppRecord, type CompanyRecord } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { normalizeApp } from 'com.batch.redux/app.api'
import { type DispatchOnlyBoundFn } from 'components/console/react-redux'

type loadAppsAction = {
  type: 'LOAD_APPS'
  payload: null
}

type loadAppsSuccessAction = {
  type: 'LOAD_APPS_SUCCESS'
  payload: {
    entities: List<AppRecord>
    // entities: Map<number, AppRecord>,
    count: number
    nbPerPage: number
    page: number
  }
}

type loadAppsFailureAction = {
  type: 'LOAD_APPS_FAILURE'
  payload: null
}

type updateAppsPageAction = {
  type: 'UPDATE_APPS_PAGE'
  payload: number
}

type updateAppFiltersAction = {
  type: 'UPDATE_APPS_FILTER'
  payload: AppFilterRecord
}

type fetchAppAction = {
  type: 'FETCH_APP'
  payload: null
}

type fetchAppSuccessAction = {
  type: 'FETCH_APP_SUCCESS'
  payload: AppRecord
}

type fetchAppFailureAction = {
  type: 'FETCH_APP_FAILURE'
  payload: null
}

type updatePushConfigAction = {
  type: 'UPDATE_PUSH_CONFIG'
  payload: null
}
type updatePushConfigSuccessAction = {
  type: 'UPDATE_PUSH_CONFIG_SUCCESS'
  payload: AppRecord
}
type updatePushConfigFailureAction = {
  type: 'UPDATE_PUSH_CONFIG_FAILURE'
  payload: any
}

type resycnPlanAction = {
  type: 'RESYNC_PLAN'
  payload: null
}
type resyncPlanActionSuccess = {
  type: 'RESYNC_PLAN_SUCCESS'
  payload: AppRecord
}
type resyncPlanActionFailure = {
  type: 'RESYNC_PLAN_FAILURE'
  payload: any
}

type CompanyChangeAction = {
  type: 'APP_CHANGE_COMPANY'
  payload: null
}
type CompanyChangeSuccessAction = {
  type: 'APP_CHANGE_COMPANY_SUCCESS'
  payload: AppRecord
}
type CompanyChangeFailureAction = {
  type: 'APP_CHANGE_COMPANY_FAILURE'
  payload: null
}

// ====================== ACTIONS
export const loadApps = (): loadAppsAction => ({
  type: 'LOAD_APPS',
  payload: null,
})

export const changeCompany =
  ({
    app,
    company,
  }: {
    app: AppRecord
    company: CompanyRecord
  }): DispatchOnlyBoundFn<Promise<AppRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'APP_CHANGE_COMPANY',
      dispatch,
      promise: request
        .post(generateUrl('console_api_app_change_company', { appId: app.id }), {
          companyId: company.id,
        })
        .then(({ body }) => normalizeApp(body)),
      payload: null,
    })

export const loadAppsSuccess = ({
  count,
  entities,
  nbPerPage,
  page,
}: {
  loading: boolean
  count: number
  entities: List<AppRecord>
  nbPerPage: number
  page: number
}): loadAppsSuccessAction => ({
  type: 'LOAD_APPS_SUCCESS',
  payload: { count, entities, nbPerPage, page },
})

export const loadAppsFailure = (): loadAppsFailureAction => ({
  type: 'LOAD_APPS_FAILURE',
  payload: null,
})

export const setPage = (page: number): updateAppsPageAction => {
  return { type: 'UPDATE_APPS_PAGE', payload: page }
}

export const updateFilters = (filter: AppFilterRecord): updateAppFiltersAction => {
  return { type: 'UPDATE_APPS_FILTER', payload: filter }
}

export const fetchApp =
  (appId: number): DispatchOnlyBoundFn<Promise<AppRecord>> =>
  dispatch =>
    promiseActionCreator({
      actionName: 'FETCH_APP',
      dispatch,
      promise: request
        .get(generateUrl('console_api_app', { appId }))
        .then(({ body }) => normalizeApp(body)),
      payload: null,
    })

export const updatePushConfig =
  ({
    app,
    maxRate,
    ttlRetargeting,
    ttlJourney,
    pushImported,
    openRateAlg,
    safariOpenTracking,
  }: {
    app: AppRecord
    maxRate: number | null | undefined
    ttlRetargeting: number | null | undefined
    ttlJourney: number | null | undefined
    pushImported: boolean
    openRateAlg: 'LEGACY' | 'LEGACY_DIRECT' | 'ACCURATE' | 'ACCURATE_DIRECT'
    safariOpenTracking: boolean
  }): DispatchOnlyBoundFn<Promise<AppRecord>> =>
  dispatch =>
    promiseActionCreator({
      dispatch,
      actionName: 'UPDATE_PUSH_CONFIG',
      payload: null,
      promise: request
        .post(generateUrl('console_api_app_sync_push', { appId: app.id }), {
          maxRate,
          ttlRetargeting,
          ttlJourney,
          openRateAlg,
          pushImported,
          safariOpenTracking,
        })
        .then(({ body }) => normalizeApp(body)),
    })

export const resyncPlan =
  (app: AppRecord): DispatchOnlyBoundFn<Promise<AppRecord>> =>
  dispatch =>
    promiseActionCreator({
      dispatch,
      actionName: 'RESYNC_PLAN',
      payload: null,
      promise: request
        .post(generateUrl('console_api_app_sync_plan', { appId: app.id }), {})
        .then(({ body }) => normalizeApp(body)),
    })

export const clearCache = (app: AppRecord): any => {
  return request.post(generateUrl('console_api_clear_cache', { appId: app.id }))
}

// ====================== ACTIONS TYPE
type supportedActions =
  | loadAppsAction
  | loadAppsSuccessAction
  | loadAppsFailureAction
  | updateAppsPageAction
  | updateAppFiltersAction
  | fetchAppAction
  | fetchAppSuccessAction
  | fetchAppFailureAction
  | updatePushConfigAction
  | updatePushConfigSuccessAction
  | updatePushConfigFailureAction
  | resycnPlanAction
  | resyncPlanActionSuccess
  | resyncPlanActionFailure
  | CompanyChangeAction
  | CompanyChangeSuccessAction
  | CompanyChangeFailureAction
  | LoadProjectsSuccessAction

// ====================== SELECTOR
const appStateSelector = (state: State) => state.app

export const appsPageSelector: (arg1: State) => List<AppRecord> = createSelector(
  appStateSelector,
  (appState: AppStateRecord) => {
    const idsList: List<number> =
      appState.idsPerPage.get(appState.page, Immutable.List()) || Immutable.List()
    return idsList.map(id => appState.entities.get(id, AppFactory()))
  }
)
export const createProjectAppsSelector: (arg1: State) => (arg1: string) => List<AppRecord> =
  createSelector(
    appStateSelector,
    appState => (projectKey: string) =>
      appState.entities
        .filter(app => app.projectKey === projectKey)
        .toList()
        .sort((a, b) => (a.platform > b.platform ? 1 : -1))
  )
// ====================== REDUCER
export const appReducer = (
  state: AppStateRecord = AppStateFactory(),
  action: supportedActions
): AppStateRecord => {
  switch (action.type) {
    case 'FETCH_PROJECTS_SUCCESS':
      return state.set('entities', state.entities.merge(action.payload.apps))
    case 'LOAD_APPS':
    case 'FETCH_APP':
    case 'RESYNC_PLAN':
    case 'UPDATE_PUSH_CONFIG':
    case 'APP_CHANGE_COMPANY':
      return state.set('loading', true)

    case 'RESYNC_PLAN_FAILURE':
    case 'UPDATE_PUSH_CONFIG_FAILURE':
    case 'LOAD_APPS_FAILURE':
    case 'FETCH_APP_FAILURE':
    case 'APP_CHANGE_COMPANY_FAILURE':
      return state.set('loading', false)

    case 'LOAD_APPS_SUCCESS': {
      let entitiesMap = state.entities
      const ids: Array<number> = []

      action.payload.entities.forEach(app => {
        if (app.id) {
          ids.push(app.id)
          entitiesMap = entitiesMap.set(app.id, app)
        }
      })

      return state
        .set('loading', false)
        .set('entities', entitiesMap)
        .set('count', action.payload.count)
        .set('idsPerPage', state.idsPerPage.set(action.payload.page, Immutable.List(ids)))
        .set('page', action.payload.page)
        .set('nbPerPage', action.payload.nbPerPage)
    }
    case 'UPDATE_APPS_PAGE':
      return state.set('page', action.payload)

    case 'UPDATE_APPS_FILTER':
      return state.set('filters', action.payload).set('idsPerPage', Immutable.Map()).set('count', 0)

    case 'FETCH_APP_SUCCESS':
    case 'RESYNC_PLAN_SUCCESS':
    case 'UPDATE_PUSH_CONFIG_SUCCESS':
    case 'APP_CHANGE_COMPANY_SUCCESS':
      return state
        .set('loading', false)
        .set('entities', state.entities.set(action.payload.id || 0, action.payload))

    default:
      return state
  }
}
