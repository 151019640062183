import Immutable, { type Map } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { UploadAudiencesContainer, PreviewAudiences } from 'components/app/audience/audience.styles'
import {
  Box,
  BoxHeader,
  HeaderBoxTitle,
  HeaderBoxActions,
  BoxBody,
  BoxFooter,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Code } from 'components/common/code'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { UploadFiles } from 'components/common/upload-files'
import { InputWrapper, Input } from 'components/form'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { slugify } from 'com.batch.common/utils'

import { type fetchingState } from 'com.batch.redux/_records'

import { saveAudience } from 'com.batch/audience/usecases/save-audience'

type AudienceUploadProps = {
  closeModal: () => void
  creatingState: fetchingState
}

export const AudienceUpload = ({
  closeModal,
  creatingState,
}: AudienceUploadProps): React.ReactElement => {
  const dispatch = useDispatch()
  // ---------- local states --------
  const [progress, setProgress] = React.useState<null | number>(null)
  const [name, setName] = React.useState<string>('')
  const [displayName, setDisplayName] = React.useState<string>('')
  const [file, setFile] = React.useState<File | null | undefined>(null)
  const [preview, setPreview] = React.useState<Array<string[]>>([[]])
  const [error, setError] = React.useState<Map<string, string>>(Immutable.Map([]))
  // ---------- handlers --------

  const onDisplayNameChange = React.useCallback(evt => setDisplayName(evt.target.value), [])
  const onNameChange = React.useCallback(
    evt => setName(evt.target.value.toUpperCase().replace(/[^0-9a-zA-Z-_]/, '')),
    []
  )

  // parse current file, set preview error & file on local state
  const parseFile = React.useCallback((file: File) => {
    if (file) {
      const reader = new FileReader()

      reader.onloadend = (evt: ProgressEvent<FileReader>) => {
        if (evt.target && evt.target.readyState === 2) {
          const data = evt.target.result as string
          const lines = data.split('\n').slice(0, 10)
          const attributeColumns = lines.map(line => {
            const columns = line.split(',')
            return columns.map(column => column.trim())
          })
          setPreview(attributeColumns)
        }
      }
      reader.readAsBinaryString(file.slice(0, 3000))
    }
  }, [])

  const onParseFile = React.useCallback(
    file => {
      setFile(file)
      parseFile(file)
    },
    [parseFile]
  )
  const onRemoveFile = React.useCallback(() => {
    setPreview([])
    setFile(null)
  }, [])

  const save = React.useCallback(
    evt => {
      evt.preventDefault()

      const err: Array<Array<string>> = []

      if (!displayName) err.push(['displayName', 'You must enter a display name'])
      if (!file) err.push(['files', 'You must upload an audience file'])

      if (name && !name.match(/^[0-9A-Z_-]*$/)) {
        err.push(['name', 'This field can only contain alphanumeric characters'])
      }

      const n = name || `${slugify(file ? file.name : '')}_${dayjs().utc().format('DDMMYYYY-HHmm')}`

      if (err.length === 0 && n && displayName && file) {
        setError(Immutable.Map())
        dispatch(saveAudience(n, displayName, 'CUSTOM_ID', file, setProgress)).then(() => {
          console.log('yo save ok')
          window.location.reload()
        }, console.warn)
      } else {
        setError(Immutable.Map(err as [string, string][]))
      }
    },
    [displayName, file, name, dispatch]
  )

  const previewContainer = (
    <PreviewAudiences>
      {preview
        .filter(p => !p.includes(''))
        .flatMap((columns, i) => (
          <Grid key={i} template="35px minmax(100px, 1fr)" gap={0}>
            <div>{i + 1}</div>
            <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1, paddingRight: 20 }}>
                <Code disabledCopy size="small">
                  {columns[0]}
                </Code>
              </div>
              {columns[1] && (
                <div style={{ flex: 1, marginLeft: 10, paddingRight: 20 }}>
                  <Code disabledCopy size="small">
                    {columns[1]}
                  </Code>
                </div>
              )}
            </div>
          </Grid>
        ))}
    </PreviewAudiences>
  )

  return (
    <Box as="form" onSubmit={save} style={{ marginBottom: 0 }}>
      <BoxHeader>
        <HeaderBoxTitle title="Upload an audience" />
        <HeaderBoxActions>
          <Button kind="inline" onClick={closeModal} type="button">
            <Icon icon="close" />
          </Button>
        </HeaderBoxActions>
      </BoxHeader>

      <BoxBody style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
        <Grid template="1fr 390px" gap={0} alignItems="stretch">
          <UploadAudiencesContainer side="left">
            <InputWrapper
              label="Display name"
              hintMinSize={300}
              hint={
                <div style={{ textAlign: 'left', fontWeight: 300 }}>
                  Define a label that will be used for Dashboard operational usage and won’t impact
                  SDK tagging, API calls, etc.
                </div>
              }
              hintPlacement="right"
              feedback={error.get('displayName')}
              htmlFor="display-name"
            >
              <Input
                id="display-name"
                disabled={creatingState === 'LOADING'}
                value={displayName}
                placeholder="My audience"
                onChange={onDisplayNameChange}
                invalid={error.has('displayName')}
              />
            </InputWrapper>

            <InputWrapper
              label="Name"
              hintMinSize={300}
              hint={
                <div style={{ textAlign: 'left', fontWeight: 300 }}>
                  Define the name that will be used to target this audience via the Batch SDKs or
                  APIs.
                  <br />
                  It must be alphanumeric with no special characters allowed except dash (-) and
                  underscore (_). If you don’t specify a name, it will be generated at saving time.
                </div>
              }
              hintPlacement="right"
              feedback={error.get('name')}
              htmlFor="audience-name"
            >
              <Input
                id="audience-name"
                value={name}
                placeholder="MY-AUDIENCE_010120 (optional)"
                onChange={onNameChange}
                disabled={creatingState === 'LOADING'}
                invalid={error.has('name')}
              />
            </InputWrapper>
          </UploadAudiencesContainer>

          <UploadAudiencesContainer side="right" hasFile={!!file}>
            <UploadFiles
              progress={progress}
              isSaving={creatingState === 'LOADING'}
              maxSizeFiles={150 * 1024 * 1024}
              parseFile={onParseFile}
              removeFile={onRemoveFile}
              extensionsForMimeType={{ 'text/plain': ['.txt', '.csv'], 'text/csv': ['.csv'] }}
              preview={previewContainer}
              previewPosition="in"
              noFileErrorMsg={error.get('files')}
            />
          </UploadAudiencesContainer>
        </Grid>
      </BoxBody>

      <BoxFooter isEditable>
        <Button
          kind="inline"
          disabled={creatingState === 'LOADING'}
          intent="neutral"
          type="button"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          isLoading={creatingState === 'LOADING'}
          disabled={!displayName || file === null}
          type="submit"
          kind="primary"
          intent="action"
        >
          Add audience
        </Button>
      </BoxFooter>
    </Box>
  )
}
