import Immutable from 'immutable'

import { type fetchAudienceListResponse } from '../infra/audience-service.interface'
import { type AudienceListSortTable } from 'com.batch/audience/store/audience-profile.state'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type ProfileAudienceRecord } from 'com.batch/audience/model/audience-profile.records'

export type SetPageAudienceAction = ReduxAction<'SET_PAGE_AUDIENCE_LIST', number>
export const setPageAudience = (page: number): SetPageAudienceAction => ({
  type: 'SET_PAGE_AUDIENCE_LIST',
  payload: page,
})

// update

type sortDirections = 'asc' | 'dsc'

type FetchAudienceListAction = ReduxAction<'FETCH_AUDIENCE_LIST', null>

type AdditionalActionData = {
  trashCache: boolean
  page: number
  search: string
  sortBy: AudienceListSortTable
  sortDirection: sortDirections
  totalCount: number
}

export type FetchAudienceListSuccessAction = ReduxAction<
  'FETCH_AUDIENCE_LIST_SUCCESS',
  fetchAudienceListResponse & AdditionalActionData
>

export type FetchAudienceListFailureAction = ReduxAction<'FETCH_AUDIENCE_LIST_FAILURE', string>

export type FetchAudiencesActions =
  | FetchAudienceListAction
  | FetchAudienceListSuccessAction
  | FetchAudienceListFailureAction
  | SetPageAudienceAction

let abortFetchAudiencesController: AbortController = new AbortController()
export const fetchAudiencesList = ({
  page,
  sortDirection,
  search,
  sortBy,
  forceTrashCache,
}: {
  page: number
  sortDirection: 'asc' | 'dsc'
  search: string
  sortBy: AudienceListSortTable
  forceTrashCache?: boolean
}): DispatchExtraBoundFn<
  Promise<{
    count: number
    entities: Immutable.List<ProfileAudienceRecord>
  }>
> => {
  return async (dispatch, getState, { audienceServiceApi }): Promise<fetchAudienceListResponse> => {
    abortFetchAudiencesController.abort()
    abortFetchAudiencesController = new AbortController()
    const state = getState()
    const project = currentProjectSelector(state)
    const audienceProfile = state.audienceProfile

    const targetPage = page
    const trashCache =
      search !== audienceProfile?.search ||
      sortBy !== audienceProfile.sortBy ||
      sortDirection !== audienceProfile.sortDirection ||
      forceTrashCache

    if (!trashCache && audienceProfile.idsPerPage.has(targetPage)) {
      dispatch(setPageAudience(page))
      return Promise.resolve({
        entities: Immutable.List<ProfileAudienceRecord>(state.audienceProfile.entities.valueSeq()),
        count: audienceProfile.count,
      })
    }

    return promiseActionCreator({
      dispatch,
      promise: audienceServiceApi
        .fetchAudiencesList({
          projectKey: project.projectKey,
          pageSize: audienceProfile?.nbPerPage,
          page: Math.max(0, targetPage - 1),
          orderDirection: sortDirection,
          orderBy: sortBy,
          search,
          currentCount: trashCache ? null : audienceProfile.count,
          abortSignal: abortFetchAudiencesController.signal,
        })
        .then(response => {
          return {
            ...response,
            page,
            search,
            sortBy,
            sortDirection,
            trashCache,
          }
        }),
      actionName: 'FETCH_AUDIENCE_LIST',
    })
  }
}
