import * as React from 'react'

import { Button } from 'components/common/button'
import { ConfirmModalFooter } from 'components/common/confirm-modal-footer'
import {
  ConfirmContainer,
  ConfirmContent,
  ConfirmHeader,
  ConfirmTitle,
} from 'components/common/confirm.styles'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'

export type ConfirmModalProps = {
  message: React.ReactNode
  sensitive?: boolean
  title?: string
  confirm?: string
  cancel?: string
  width?: number
  mfa?: React.ReactNode
}

export const ConfirmModal = ({
  title = 'Are you sure?',
  cancel = 'Cancel',
  message = '',
  sensitive = false,
  confirm = sensitive ? 'Yes, delete it' : 'Yes, change it',
  width,
  resolver,
  rejecter,
  mfa,
}: ConfirmModalProps & {
  resolver: () => any
  rejecter: () => any
}): React.ReactElement => {
  return (
    <Popin close={rejecter} opened style={{ maxWidth: '620px', width: width ? width : 'auto' }}>
      <ConfirmContainer className={mfa ? 'mfa' : ''}>
        <ConfirmHeader>
          <ConfirmTitle>
            {mfa && <Icon icon="warning-triangle" />} {title}
          </ConfirmTitle>
          <Button autoFocus kind="inline" onClick={rejecter} style={{ height: 35, width: 35 }}>
            <Icon icon="close" />
          </Button>
        </ConfirmHeader>

        <ConfirmContent>{message}</ConfirmContent>

        {mfa ? (
          mfa
        ) : (
          <ConfirmModalFooter
            sensitive={sensitive}
            cancel={cancel}
            confirm={confirm}
            onClickCancel={rejecter}
            onClickConfirm={resolver}
          />
        )}
      </ConfirmContainer>
    </Popin>
  )
}
