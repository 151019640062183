import styled, { css } from 'styled-components'

import { colors } from 'components/styled/tokens'
import { fillBackground, text, textDisabled, textLight } from 'components/styled/tokens/colors'
import * as schemes from 'components/styled/tokens/schemes'
import { legend } from 'components/styled/tokens/texts'

export const MediaOverlay = styled.div<{
  $hover: boolean
}>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  position: absolute;
  color: ${colors.textLight};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${p => (p.theme.draggingState === 'NONE' && !p.$hover ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  ${p =>
    p.theme.draggingState === 'NONE' &&
    css`
      cursor: pointer;
    `}
`

export const BaseThumb = styled.div`
  overflow: hidden;
  &:hover {
    ${MediaOverlay} {
      display: flex;
    }
  }
`
type InactiveEditorContainerProps = {
  $isPlaceholder: boolean
  $fullyInline: boolean
}
export const InactiveEditorContainer = styled.div<InactiveEditorContainerProps>`
  color: ${text};

  ${props =>
    props.$isPlaceholder &&
    css`
      color: ${colors.textLight};
    `}
  ${props =>
    props.$fullyInline
      ? css`
          min-height: 36px;
          display: flex;
          align-items: center;
        `
      : css`
          min-height: 20px;
        `}
  padding: ${props => (props.$fullyInline ? '4px 6px 4px 12px' : '4px 6px')};
  white-space: pre-line;
  overflow-wrap: anywhere;
  flex-grow: 1;
  ${p =>
    !p.theme?.disabledMode &&
    css`
      &:hover {
        background: ${colors.opacifyHover};
        border-radius: 8px;
        cursor: pointer;
      }
    `}
`
export const InactiveEditorLabelContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

export const PreviewContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-left: calc(50% - 198px);
  position: fixed;
  top: 0;
  padding-bottom: 10px;

  z-index: 2;

  overflow-y: scroll;
  scrollbar-color: ${colors.opacifyActive} transparent;
  scrollbar-width: thin;

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    width: 6px;
    height: 36px;
    background-color: ${colors.opacifyContrastActive};
    border: 4px solid transparent;
  }
  ${p =>
    p.theme.previewPlatform === 'sms' &&
    css`
      :after {
        content: 'Rendering depends on user device and settings';
        position: absolute;
        bottom: 10px;
        color: ${textDisabled};
        font: ${legend.font};
        text-align: center;
        width: 386px;
      }
    `}
`

type VariantTogglerProps = {
  active: boolean
  $variant: 'a' | 'b'
}
export const VariantToggler = styled.div<VariantTogglerProps>`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  width: 100%;
  height: 46px;
  margin-bottom: -46px;
  padding: 0 22px 0 22px;
  z-index: 3;
  font-weight: 500;
  background-color: ${fillBackground};
  border-top: ${p => (p.$variant === 'b' ? `1px solid ${colors.stroke}` : 'none')};
  strong {
    margin-left: 8px;
    color: ${text};
    font-weight: 500;
  }
  span {
    margin-left: 8px;
    font-weight: 400;
    color: ${textLight};
  }

  &:after {
    display: block;
    position: absolute;
    left: 14px;
    right: 14px;
    bottom: 0;
    content: '';
    border-bottom: 1px solid ${schemes.lightlucent['05']};
  }
`

export const InlineContainer = styled.div<{
  enabled?: boolean
}>`
  position: relative;
  padding: 0;
  width: 100%;
  height: 480px;
  will-change: transform;
  & + & {
    border-top: 1px solid ${schemes.lightlucent['20']};
  }
`
export const StickyInfoPanelContainer = styled.div`
  position: fixed;
  overflow: visible;
  margin-left: calc(50% + 232px);
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
`

type VariantContainerProps = {
  $channel: 'sms' | 'push'
  $form: 'campaign' | 'orchestration'
}
export const VariantContainer = styled.div<VariantContainerProps>`
  color: ${text};
  background-color: ${colors.fillBackground};
  background-image: url('/medias/img/builders/${p => p.$channel}-background.webp');
  background-repeat: repeat;
  background-size: 400px 400px;
  background-attachment: local;
  height: 100%;
  ${p =>
    p.$form === 'orchestration' &&
    p.$channel === 'push' &&
    css`
      min-height: 480px;
    `}
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;
  position: relative;
`
