// @ts-nocheck
import Immutable from 'immutable'
import { createSelector } from 'reselect'

import { config as conf } from 'com.batch.common/config'

import { KIND_CONDITION } from './targeting'
import api from './targeting.api'
import {
  QbFuncFactory,
  QbOperatorFactory,
  QbConditionFactory,
  QbLogicalFactory,
} from './targeting.records'

import { EstimateFactory } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'

const conditionSelector = (state: unknown) => state.targeting.get('conditions')
const treeSelector = (state: unknown) => state.targeting.get('tree')

const recursiveMap = (tree: unknown, conditions: unknown) => {
  if (tree.get('kind') === KIND_CONDITION) {
    return tree.set('value', api.formatCondition(conditions.get(tree.get('id'))))
  }
  return tree.set(
    'child',
    tree.get('child').map(val => recursiveMap(val, conditions))
  )
}
const targetingSelector = (state: unknown) => state.targeting

export const activeClustersIds = createSelector(targetingSelector, targeting => {
  return targeting.get('clusters')
})

export const fullTreeSelector = createSelector(
  conditionSelector,
  treeSelector,
  (conditions, tree) => {
    const res = recursiveMap(tree, conditions)
    return res
  }
)

export const rawEstimateSelector = (state: unknown) =>
  state.targeting.get('estimate', EstimateFactory())

export const activeLanguagesIds = (state: unknown) => state.targeting.get('languages')
export const activeRegionsIds = (state: unknown) => state.targeting.get('regions')
export const estimateSelector = createSelector(
  currentAppSelector,
  rawEstimateSelector,
  (app, est) => {
    const sendRate = app.pushConfig.maxRate
      ? app.pushConfig.maxRate
      : conf.pushRate[app.get('status', 'free')]
    const matchingBiggestTz = est.matching.biggestTimezone
    const matching = est.matching.count
    const timeToSendAll = matching ? matching / sendRate : 0
    const timeToSendBiggestTz = matchingBiggestTz ? matchingBiggestTz / sendRate : 0
    return Immutable.fromJS({
      tokens: {
        total: est.all.count,
        sendRate: conf.pushRate[app.get('status', 'free')],
        matching,
        matchingBiggestTz,
        timeToSendAll,
        timeToSendBiggestTz,
        matchingNotifOn: est.matching.optIns,
      },
      installs: {
        total: est.installs,
        matching: est.matchingInstalls,
      },

      loading: est.loading,
      error: est.error,
    })
  }
)
// temporary solution to build typed record tree from our crappy map ~~~~~~~
const recursiveMapRecord = (tree: unknown, conditions: unknown) => {
  if (tree.get('kind') === KIND_CONDITION) {
    const cond = conditions.get(tree.get('id'))
    return QbConditionFactory({
      attribute: cond.get('attribute'),
      operator: QbOperatorFactory({
        value: cond.getIn(['operator', 'value']),
        api: cond.getIn(['operator', 'api']),
      }),
      func: cond.get('func')
        ? QbFuncFactory({
            label: cond.getIn(['func', 'label']),
            value: cond.getIn(['func', 'value']),
          })
        : null,
      args: cond.get('args'),
      valid: true,
      value: cond.get('value'),
      negate: cond.get('negate', false),
    })
  }
  return QbLogicalFactory({
    kind: tree.get('value'),
    children: Immutable.List(
      tree.get('child').map(subTree => recursiveMapRecord(subTree, conditions))
    ),
  })
}
export const fullTreeAsRecordSelector = createSelector(
  conditionSelector,
  treeSelector,
  (conditions, tree) => {
    return recursiveMapRecord(tree, conditions)
  }
)
// end temporary solution ~~~~
