import Immutable, { type RecordOf, type List, type Map } from 'immutable'

import { type SegmentRecord } from 'com.batch/segments/models/segment.records'

type SegmentStateProps = {
  segments: Map<string, SegmentRecord>
  segmentsPerPage: Map<number, List<string>>
  loadingState: LoadingState
  mutationLoadingState: LoadingState
  search: string
  sortBy: 'displayName' | null | undefined | 'updatedAt'
  sortDirection: 'asc' | null | undefined | 'dsc'
  page: number
  nbPerPage: number
  total: number
  totalMatching: number
}

export const SegmentStateFactory = Immutable.Record<SegmentStateProps>({
  segments: Immutable.Map(),
  segmentsPerPage: Immutable.Map(),
  loadingState: 'INIT',
  mutationLoadingState: 'INIT',
  search: '',
  sortBy: undefined,
  sortDirection: undefined,
  page: 1,
  nbPerPage: 10,
  total: 0,
  totalMatching: 0,
} as SegmentStateProps)

export type SegmentStateRecord = RecordOf<SegmentStateProps>
