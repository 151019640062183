import Immutable, { type Map, type List } from 'immutable'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'com.batch.common/react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from 'components/common/button'
import {
  FullPageContent,
  FullPageTitle,
  FullPageSubtitle,
  FullPageFooter,
} from 'components/common/full-page'
import { Icon } from 'components/common/svg-icon'
import { Form, InputWrapper, TabButton, TabButtonItem, InputContainer } from 'components/form'

import { generateUrl } from 'com.batch.common/router'

import { IconAndTextContainer } from './create-app.styles'

import { type CompanyRecord, type AppRecord, type SdkRecord } from 'com.batch.redux/_records'
import { showToast } from 'com.batch.redux/toaster'

type StepFormProps = {
  company: CompanyRecord
  app: AppRecord
  sdks: List<SdkRecord>
  setApp: (arg1: AppRecord) => any
  forwardToTeamScreen: boolean
  persistApp: () => Promise<{
    app: AppRecord
  }>
}

const StepFormRaw = ({
  app,
  setApp,
  company,
  forwardToTeamScreen,
  persistApp,
  sdks,
}: StepFormProps) => {
  const isMobile = app.platform !== 'webpush'

  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())
  const navigate = useNavigate()
  const onSubmit = () => {
    const err: [string, string][] = []
    if (app.platform === '' || app.platform === 'mobile')
      err.push(['platform', 'Please select a platform'])
    if (app.name.length < 3) err.push(['name', 'Please enter a name (3 characters minimum)'])

    setErrors(Immutable.Map(err))
    if (err.length > 0) return

    setLoading(true)
    persistApp().then(
      ({ app }) => {
        dispatch(showToast({ message: 'App created', kind: 'success' }))
        setLoading(false)
        if (forwardToTeamScreen) {
          navigate(
            generateUrl('app_new_team', {
              companyId: company.id,
              appId: app.id,
              kind: isMobile ? 'mobile' : 'web',
            })
          )
        } else {
          window.location.href = generateUrl('app_settings', {
            companyId: company.id,
            appId: app.id,
            activeTab: 'push',
            popin: 'integrate',
          })
        }
      },
      () => setLoading(false)
    )
  }
  const backUrl = generateUrl('app_new', {
    companyId: company.id,
  })

  return (
    <Form onSubmit={onSubmit}>
      <FullPageContent>
        <Helmet>
          <title>Batch - Set up your new {isMobile ? 'app' : 'website'}</title>
        </Helmet>

        <FullPageTitle>Set up your {isMobile ? 'app' : 'website'}</FullPageTitle>
        <FullPageSubtitle>
          Please provide additionnal information about your {isMobile ? 'mobile app' : 'website'} to
          finish its setup.
        </FullPageSubtitle>
        <div>
          <InputWrapper
            label={isMobile ? 'Application name' : 'Website name'}
            feedback={errors.get('name')}
            htmlFor="name"
          >
            <InputContainer invalid={errors.has('name')}>
              <input
                id="name"
                value={app.name}
                onChange={evt => setApp(app.set('name', evt.target.value))}
                placeholder={`Enter ${isMobile ? 'an app name' : 'a website name'}`}
              />
              {app.icon && (
                <div
                  className="styled-input-addons styled-addon-suf"
                  style={{
                    backgroundImage: `url(${app.icon}`,
                    backgroundSize: 'cover',
                    borderRadius: 4,
                    marginRight: 6,
                    width: 28,
                    height: 28,
                  }}
                >
                  &nbsp;
                </div>
              )}
            </InputContainer>
          </InputWrapper>
          {isMobile && (
            <React.Fragment>
              <InputWrapper label="Platform" feedback={errors.get('platform')}>
                <TabButton grow invalid={errors.has('platform')}>
                  <TabButtonItem
                    onClick={() => setApp(app.set('platform', 'ios').set('sdk', 'iOS'))}
                    isActive={app.platform === 'ios'}
                  >
                    <IconAndTextContainer>
                      <div>
                        <Icon icon="ios" size={22} />
                      </div>
                      <span>iOS</span>
                    </IconAndTextContainer>
                  </TabButtonItem>
                  <TabButtonItem
                    onClick={() => setApp(app.set('platform', 'android').set('sdk', 'Android'))}
                    isActive={app.platform === 'android'}
                  >
                    <IconAndTextContainer>
                      <div>
                        <Icon icon="android" size={22} />
                      </div>
                      Android
                    </IconAndTextContainer>
                  </TabButtonItem>
                  <TabButtonItem
                    onClick={() => setApp(app.set('platform', 'windows').set('sdk', 'Windows'))}
                    isActive={app.platform === 'windows'}
                  >
                    <IconAndTextContainer>
                      <div>
                        <Icon icon="windows" size={22} />
                      </div>
                      Windows
                    </IconAndTextContainer>
                  </TabButtonItem>
                </TabButton>
              </InputWrapper>
              {(app.platform === 'ios' || app.platform === 'android') && (
                <InputWrapper label="SDK">
                  <TabButton grow>
                    {sdks
                      .filter(sdk => !sdk.legacy && sdk.allowedPlatforms.has(app.platform))
                      .map(sdk => (
                        <TabButtonItem
                          key={sdk.kind}
                          onClick={() => setApp(app.set('sdk', sdk.kind))}
                          isActive={app.sdk === sdk.kind}
                        >
                          <IconAndTextContainer>
                            <div>
                              <Icon icon={sdk.icon} size={22} />
                            </div>
                            <span>{sdk.kind}</span>
                          </IconAndTextContainer>
                        </TabButtonItem>
                      ))}
                  </TabButton>
                </InputWrapper>
              )}
            </React.Fragment>
          )}
        </div>

        <FullPageFooter>
          <Button
            style={{ marginRight: 46 }}
            kind="inline"
            type="button"
            onClick={() => {
              setApp(
                app
                  .set('bundleId', '')
                  .set('icon', '')
                  .set('name', '')
                  .set('sdk', '')
                  .set('platform', isMobile ? 'mobile' : 'webpush')
              )
              navigate(backUrl)
            }}
            addOn="prefix"
          >
            <Icon icon="arrow-long-left" />
            Back
          </Button>
          <Button type="submit" kind="primary" intent="action" isLoading={loading}>
            Add your {isMobile ? 'app' : 'website'}
          </Button>
        </FullPageFooter>
      </FullPageContent>
    </Form>
  )
}
export const StepForm: React.ComponentType<StepFormProps> = React.memo<StepFormProps>(StepFormRaw)
