import { type Dayjs } from 'dayjs'
import Immutable, { type RecordOf, type OrderedMap, type Set, type Map } from 'immutable'

import { generateTypedId } from 'com.batch.common/typed-id'

import { type langId } from '../store/message.state'
import { AgeFactory, type AgeRecord } from 'com.batch.redux/_records'
import { type attachmentKind } from 'com.batch.redux/content.records'

import { type HtmlEditorConfig } from 'com.batch/email/models/html-editor-config'

export type SmsTemplatesProps = {
  smsMessage: string
}
export const SmsTemplatesFactory = Immutable.Record<SmsTemplatesProps>({
  smsMessage: '',
})
export type SmsTemplatesRecord = RecordOf<SmsTemplatesProps>

export type SmsContentProps = {
  smsMessage: string
  templates: SmsTemplatesRecord
}
export const SmsContentFactory = Immutable.Record<SmsContentProps>({
  smsMessage: '',
  templates: SmsTemplatesFactory(),
})
export type SmsContentRecord = RecordOf<SmsContentProps>

type SmsMessage = {
  localizedContent: OrderedMap<langId, SmsContentRecord>
}
export const SmsMessageFactory = Immutable.Record<SmsMessage>({
  localizedContent: Immutable.OrderedMap(),
})
export type SmsMessageRecord = RecordOf<SmsMessage>

export type EmailTemplatesProps = {
  html: string | null | undefined
  subject: string | null | undefined
  replyTo: string | null | undefined
}
export const EmailTemplatesFactory = Immutable.Record<EmailTemplatesProps>({
  html: null,
  subject: null,
  replyTo: null,
})
export type EmailTemplatesRecord = RecordOf<EmailTemplatesProps>

export type EmailContentProps = {
  replyTo: string | null | undefined
  fromEmail: string | null | undefined
  name: string | null | undefined
  subject: string | null | undefined
  html: string | null | undefined
  templates: EmailTemplatesRecord
  senderIdentityId?: number
  isEmailUploading: boolean
  loadingState: LoadingState
  htmlEditorConfig: HtmlEditorConfig | null | undefined
  isPristine: boolean
}
export const EmailContentFactory = Immutable.Record<EmailContentProps>({
  fromEmail: null,
  replyTo: null,
  name: null,
  subject: null,
  html: null,
  templates: EmailTemplatesFactory(),
  senderIdentityId: undefined,
  isEmailUploading: false,
  loadingState: 'INIT',
  htmlEditorConfig: null,
  isPristine: true,
})
export type EmailContentRecord = RecordOf<EmailContentProps>

type EmailMessage = {
  localizedContent: OrderedMap<langId, EmailContentRecord>
}
export const EmailMessageFactory = Immutable.Record<EmailMessage>({
  localizedContent: Immutable.OrderedMap(),
})
export type EmailMessageRecord = RecordOf<EmailMessage>

export const PUSH_PRIORITY = {
  PUSH_PRIORITY_UNSPECIFIED: '',
  PUSH_PRIORITY_NORMAL: 'Normal',
  PUSH_PRIORITY_HIGH: 'High',
}
export type PushPriority = keyof typeof PUSH_PRIORITY

export const PUSH_NOTIF_TYPE = {
  PUSH_TYPE_UNSPECIFIED: '',
  PUSH_TYPE_ALERT: 'Alert',
  PUSH_TYPE_BACKGROUND: 'Background',
}
export type PushNotifType = keyof typeof PUSH_NOTIF_TYPE

type PushContentTemplatesProps = {
  pushTitle: string
  pushBody: string
  pushPicture: string
  pushIcon: string
}
export const PushContentTemplatesFactory = Immutable.Record<PushContentTemplatesProps>({
  pushTitle: '',
  pushBody: '',
  pushPicture: '',
  pushIcon: '',
})
export type PushContentTemplatesRecord = RecordOf<PushContentTemplatesProps>

type PushMessageRaw = {
  pushTitle: string
  pushBody: string
  pushPicture: string
  pushIcon: string
  templates: PushContentTemplatesRecord
  attachmentKind: attachmentKind
  pushType: PushNotifType
  deeplink: string
}
export type PushMessageRawRecord = RecordOf<PushMessageRaw>
export const PushMessageRawFactory = Immutable.Record<PushMessageRaw>({
  pushTitle: '',
  pushBody: '',
  pushPicture: '',
  pushIcon: '',
  attachmentKind: 'image',
  templates: PushContentTemplatesFactory(),
  pushType: 'PUSH_TYPE_ALERT',
  deeplink: '',
})
type AndroidPushMessageRaw = {
  deeplink: string // no deeplink = redirect to app
}
export type AndroidPushMessageRawRecord = RecordOf<AndroidPushMessageRaw>
export const AndroidPushMessageRawFactory = Immutable.Record<AndroidPushMessageRaw>({
  deeplink: '',
})
type IosPushMessageRaw = {
  deeplink: string
  video: string
  audio: string
}
export type IosPushMessageRawRecord = RecordOf<IosPushMessageRaw>
export const IosPushMessageRawFactory = Immutable.Record<IosPushMessageRaw>({
  deeplink: '',
  video: '',
  audio: '',
})
type WebPushMessageRaw = {
  deeplink?: string
}
export type WebPushMessageRawRecord = RecordOf<WebPushMessageRaw>
export const WebPushMessageRawFactory = Immutable.Record<WebPushMessageRaw>({
  deeplink: undefined,
})
type PushContent = {
  content: PushMessageRawRecord
  androidContent: AndroidPushMessageRawRecord
  iosContent: IosPushMessageRawRecord
  webContent: WebPushMessageRawRecord
}
export type PushContentRecord = RecordOf<PushContent>
export const PushContentFactory = Immutable.Record<PushContent>({
  content: PushMessageRawFactory(),
  androidContent: AndroidPushMessageRawFactory(),
  iosContent: IosPushMessageRawFactory(),
  webContent: WebPushMessageRawFactory(),
})

type PushSettingsAndroidOverride = {
  collapseKey: string
  customPayload: string
}
export const PushSettingsAndroidOverrideFactory = Immutable.Record<PushSettingsAndroidOverride>({
  collapseKey: '',
  customPayload: '',
})
export type PushSettingsAndroidOverrideRecord = RecordOf<PushSettingsAndroidOverride>

type PushSettingsIosOverride = {
  customPayload: string
}
export const PushSettingsIosOverrideFactory = Immutable.Record<PushSettingsIosOverride>({
  customPayload: '',
})
export type PushSettingsIosOverrideRecord = RecordOf<PushSettingsIosOverride>

export type FilterPushTokensType = 'all' | 'collected' | 'imported'

type PushSettings = {
  priority: PushPriority
  ttl: AgeRecord
  ttlEnabled: boolean
  customPayload: string
  androidOverride: PushSettingsAndroidOverrideRecord
  iosOverride: PushSettingsIosOverrideRecord
  filterPushTokens: FilterPushTokensType
}
export const PushSettingsFactory = Immutable.Record<PushSettings>({
  priority: 'PUSH_PRIORITY_HIGH',
  ttl: AgeFactory(),
  ttlEnabled: false,
  customPayload: '',
  androidOverride: PushSettingsAndroidOverrideFactory(),
  iosOverride: PushSettingsIosOverrideFactory(),
  filterPushTokens: 'collected',
})
export type PushSettingsRecord = RecordOf<PushSettings>

export type PushMessage = {
  localizedContent: OrderedMap<langId, PushContentRecord>
}
export const PushMessageFactory = Immutable.Record<PushMessage>({
  localizedContent: Immutable.OrderedMap(),
})
export type PushMessageRecord = RecordOf<PushMessage>

type MessageId = string
export type VariantId = 1 | 2 | 3 | 4
type ExperimentProps = {
  id: string
  variants: Map<VariantId, MessageId>
  selectedVariantId: VariantId
  winningVariantSelectionDate: Dayjs | null | undefined
  winnerVariantId: VariantId | null | undefined
  enabled: boolean
  locked: boolean // au parsing, on va lock si running / stopped / completed
}
export const ExperimentFactory = Immutable.Record<ExperimentProps>({
  id: generateTypedId('experiment'),
  variants: Immutable.Map(),
  winningVariantSelectionDate: undefined,
  selectedVariantId: 1,
  winnerVariantId: null,
  enabled: false,
  locked: false,
})
export type ExperimentRecord = RecordOf<ExperimentProps>

type MessageConfig = {
  messageTypedId: MessageId
  channel: ChannelUntilCleanup
  pushSettings: PushSettingsRecord
  platforms: Set<ProjectPlatforms>
  experiment: ExperimentRecord
  multilanguageEnabled: boolean
}
export type MessageConfigRecord = RecordOf<MessageConfig>
export const MessageConfigFactory = Immutable.Record<MessageConfig>({
  messageTypedId: generateTypedId('message'),
  channel: 'email',
  pushSettings: PushSettingsFactory(),
  platforms: Immutable.Set(),
  experiment: ExperimentFactory(),
  multilanguageEnabled: false,
})
