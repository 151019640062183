import * as React from 'react'
import { useSelector, useDispatch } from 'components/console/react-redux'
import { useParams } from 'react-router-dom'

import Loader from 'components/common/loader-legacy'

import { fetchUser } from '../redux/user'
import { type UserRecord, UserFactory } from 'com.batch.redux/user.records'
import { type State } from 'components/console/redux/console.records'

type RequireUserProps = {
  forcedUserId?: number
  render: (User: UserRecord) => any
}

const usersSelector = (state: State) => state.user.entities

export const ConsoleRequireUserFromRouter = ({
  forcedUserId,
  render,
}: RequireUserProps): React.ReactElement => {
  const dispatch = useDispatch()
  const fakeComponentDidMount = React.useRef(false)
  const { userId } = useParams()
  const users = useSelector(usersSelector)
  const usedUserId = React.useMemo(
    () => forcedUserId ?? parseInt(userId ?? '', 10),
    [forcedUserId, userId]
  )

  React.useEffect(() => {
    if (!users.has(usedUserId) && !fakeComponentDidMount.current) {
      fakeComponentDidMount.current = true
      dispatch(fetchUser(usedUserId))
    }
  }, [dispatch, usedUserId, users])

  return users.has(usedUserId) ? (
    render(users.get(usedUserId, UserFactory()))
  ) : (
    <Loader loading={true} height={400} size="fat" />
  )
}
