import styled from 'styled-components'

import { Button } from 'components/common/button/button.styles'
import { FilterSearchContainer } from 'components/filter/filter-search.styles'
import { colors } from 'components/styled/tokens'

type ProfileSearchContainerProps = {
  isEmpty: boolean
}

export const ProfileSearchContainer = styled.div<ProfileSearchContainerProps>`
  display: flex;
  background: ${props => (props.isEmpty ? 'transparent' : colors.opacifyActive)};
  border-radius: 6px;

  ${FilterSearchContainer} {
    background: ${props => (props.isEmpty ? 'auto' : 'none')};
  }

  ${Button} {
    background: none;
  }
`
