import Immutable from 'immutable'
import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { useParams } from 'react-router-dom'

import { GlobalErrorOverlayProps, Wrapper } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { Title } from 'components/styled/text'

import { StatsBlocksRow, StatsContainer } from './orchestration-analytics.styles'

import { SmsKeyMetrics } from '../key-metrics/sms-key-metrics'
import { orchestrationAnalyticsMapSelector } from 'com.batch/orchestration-analytics/store/orchestration-analytics.selector'

import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { fetchOrchestrationDynamicStats } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats'
import { useGetSchedulingTypeFromUrl } from 'com.batch/orchestration-list/ui/hooks/use-scheduling-type-from-url'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { STATUS } from 'constants/common'
import { OrchestrationAnalyticsFactory } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { getStatsConfig } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'

export const SmsAnalytics = (): React.ReactElement => {
  const dispatch = useDispatch()
  const { token } = useParams()
  const schedulingType = useGetSchedulingTypeFromUrl()
  const analyticsMap = useSelector(orchestrationAnalyticsMapSelector)
  const stats = React.useMemo(
    () =>
      (token
        ? analyticsMap.get(token, OrchestrationAnalyticsFactory())
        : OrchestrationAnalyticsFactory()
      )
        .get('channels')
        .get('sms')
        .get('stats'),
    [analyticsMap, token]
  )
  const loadingState: LoadingState = React.useMemo(
    () => (token ? analyticsMap.get(token)?.smsLoadingState ?? 'INIT' : 'ERROR'),
    [analyticsMap, token]
  )
  const isLoading = React.useMemo(
    () => loadingState === STATUS.LOADING || loadingState === STATUS.INIT,
    [loadingState]
  )
  const isOverlayShown = React.useMemo(() => loadingState === STATUS.ERROR, [loadingState])
  const { dateRange, filters } = useGetAnalyticsFilters(token)

  React.useEffect(() => {
    if (token) {
      const tokens = Immutable.List([token])
      dispatch(
        fetchOrchestrationDynamicStats({
          tokens,
          filters,
          dateRange,
          config: getStatsConfig({ name: 'orchestrationStatsForSms' }),
          includePreviousPeriod: true,
        })
      ).catch(() => {})
    }
  }, [dispatch, token, filters, dateRange])

  return (
    <StatsContainer>
      <ChannelSectionTitle style={{ margin: '0 0 24px' }}>
        <Title>
          <Icon icon="sms" />
          SMS
        </Title>
      </ChannelSectionTitle>
      <Wrapper
        isLoading={isLoading}
        isEmpty={false}
        isOverlayShown={isOverlayShown}
        overlayProps={GlobalErrorOverlayProps}
        boxed
      >
        <StatsBlocksRow>
          <SmsKeyMetrics stats={stats} context={schedulingType} token={token} />
        </StatsBlocksRow>
      </Wrapper>
    </StatsContainer>
  )
}
