import { type RecordOf, Record } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

import { STATUS } from 'constants/common'

type DataProps = {
  value: number
  rate: number
}
export const DataFactory = Record<DataProps>({
  value: 0,
  rate: 0,
})
export type DataRecord = RecordOf<DataProps>

type SubDistributionProps = {
  subscribed: DataRecord
  unsubscribed: DataRecord
  unknown: DataRecord
}
export const SubDistributionFactory = Record<SubDistributionProps>({
  subscribed: DataFactory(),
  unsubscribed: DataFactory(),
  unknown: DataFactory(),
})
export type SubDistributionRecord = RecordOf<SubDistributionProps>

type SubDistributionStateProps = {
  loadingState: fetchingState
  distribution: SubDistributionRecord
}

export const SubDistributionStateFactory = Record<SubDistributionStateProps>({
  loadingState: STATUS.INIT,
  distribution: SubDistributionFactory(),
} as SubDistributionStateProps)
export type SubDistributionStateRecord = RecordOf<SubDistributionStateProps>

type ChannelsDistributionStateProps = {
  email: SubDistributionStateRecord
  sms: SubDistributionStateRecord
  push: SubDistributionStateRecord
  webpush: SubDistributionStateRecord
}

export const ChannelsDistributionStateFactory = Record<ChannelsDistributionStateProps>({
  email: SubDistributionStateFactory(),
  push: SubDistributionStateFactory(),
  sms: SubDistributionStateFactory(),
  webpush: SubDistributionStateFactory(),
} as ChannelsDistributionStateProps)
export type ChannelsDistributionStateRecord = RecordOf<ChannelsDistributionStateProps>
