import * as React from 'react'

import { Tag } from 'components/common/tags/tag.styles'
import { TagsList } from 'components/common/tags/tags-list'

import { type QueryValue } from 'com.batch/profile/infra/debug/models/shared.model'
import { AttributeRow } from 'com.batch/profile/ui/components/attributes-block/attribute-row'

type TagsAttributeProps = {
  name: string
  attributes: Array<QueryValue>
  isCopyable?: boolean
}

const nbTagsShown = 5

export const TagsAttributeRow = ({
  name,
  attributes,
  isCopyable = true,
}: TagsAttributeProps): React.ReactElement => {
  const tags = React.useMemo(() => {
    return attributes.map(val => val?.stringValue || '').filter(Boolean)
  }, [attributes])
  const tagsStr = React.useMemo(() => tags.join(',') ?? '', [tags])
  return (
    <AttributeRow name={name} icon="collection" copyableValue={tagsStr} isCopyable={isCopyable}>
      <TagsList nbItemsCollapsable={nbTagsShown}>
        {tags.map(tag => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </TagsList>
    </AttributeRow>
  )
}
