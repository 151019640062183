import { type DispatchBoundFn, type State } from 'com.batch.redux/_records'
import { currentCampaignAppsSelector } from 'com.batch.redux/campaign.selector'
import { QueryFactory } from 'com.batch.redux/query//query.records'
import { formatQueryForAPI } from 'com.batch.redux/query/query.api.formating'
import {
  type SetHashAction,
  type FetchHashAction,
  type FetchProfileHashAction,
} from 'com.batch.redux/target/target'
import { getLangAndRegionArray } from 'com.batch.redux/target/target.helper'
import { TargetStateFactory } from 'com.batch.redux/target/target.records'
import { buildEstimateHashKey } from 'com.batch.redux/target/target.utils'
import { currentUserSelector } from 'com.batch.redux/user.selector'

export const triggerEstimate =
  (
    id: string = 'default'
  ): DispatchBoundFn<SetHashAction | FetchHashAction | FetchProfileHashAction> =>
  (dispatch, getState) =>
    dispatch(buildTriggerEstimateAction(getState(), id))

export const buildTriggerEstimateAction = (
  state: State,
  id: string = 'default'
): SetHashAction | FetchHashAction | FetchProfileHashAction => {
  const query = state.query.get(id === 'default' ? 'targeting' : id, QueryFactory())
  const apps = currentCampaignAppsSelector(state)
  const target = state.target.get(id, TargetStateFactory())
  const { languages, regions } = getLangAndRegionArray({
    targetingState: target,
    user: currentUserSelector(state),
  })

  const filterPushTokens = state.orchestration.campaign.messageConfig.pushSettings.filterPushTokens

  const hash = buildEstimateHashKey(target, query, apps, filterPushTokens)
  const payload = {
    hash,
    id,
    retries: 0,
    apps: apps.map(app => app.id).toArray(),
    languages,
    regions,
    segments: target.segments.toArray(),
    query: JSON.stringify(formatQueryForAPI(query) ?? ''),
    filterPushTokens,
  }
  if (apps.size > 0) {
    return target.estimates.has(hash) &&
      !target.estimates.get(hash)?.loading &&
      !target.estimates.get(hash)?.error
      ? {
          type: 'T_ESTIMATE_SET_HASH',
          payload: { hash, id },
        }
      : {
          type: 'T_ESTIMATE_FETCH_HASH',
          payload,
        }
  } else {
    return target.profileEstimates.has(hash) &&
      target.profileEstimates.get(hash)?.loading === 'LOADED'
      ? {
          type: 'T_ESTIMATE_SET_HASH',
          payload: { hash, id },
        }
      : {
          type: 'T_ESTIMATE_FETCH_PROFILE_HASH',
          payload,
        }
  }
}
