import { type List } from 'immutable'
import * as React from 'react'

import { type CommonSelectProps } from 'components/form/fields/select/select.helper'

import { FilterSelectNoSearch } from './filter-select-no-search'
import { FilterSelectSearch } from './filter-select-search'

export type FilterVariant = 'default' | 'filled'

export type BaseFilterSelectProps<T> = CommonSelectProps<T> & {
  isSearchable?: boolean
  loadOptions?: (arg1: string) => Promise<List<T>>
  onChange: (arg1?: T | null | undefined, arg2?: boolean | null | undefined) => void
  options?: List<T>
  placeholder?: string
  expandable?: boolean
  value: T | null | undefined
  variant?: FilterVariant
  _width?: number
}
export type FilterSelectProps<T> = BaseFilterSelectProps<T> & {
  isSearchable?: boolean
}

export function FilterSelect<T>({
  isSearchable = true,
  ...rest
}: FilterSelectProps<T>): React.ReactElement {
  return isSearchable ? <FilterSelectSearch {...rest} /> : <FilterSelectNoSearch {...rest} />
}
