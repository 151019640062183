import Immutable, { type List, type Map } from 'immutable'
import { createSelector } from 'reselect'

import { checkFeatureForCompany } from 'components/_hooks/use-company-feature'

import { coerceType } from './query.api'
import {
  QueryAttributeFactory,
  QueryFactory,
  type QueryAttributeRecord,
  type QueryRecord,
} from './query.records'
import * as Types from './query.types'

import { TypesIcons, attributeIsEvent } from '../attribute.api'
import {
  AttributeFactory,
  EventDataAttributeFactory,
  type State,
  type EventDataAttributeRecord,
} from 'com.batch.redux/_records'
import { allAttrSelector } from 'com.batch.redux/attribute.selector'
import { currentCompanySelector } from 'com.batch.redux/company'
import { projectCanQueryEvents } from 'com.batch.redux/project'
import { formatQueryForAPI } from 'com.batch.redux/query/query.api.formating'

type extract<T> = (arg1: State) => T

export const profileTargetingAttributesSelector: extract<List<QueryAttributeRecord>> =
  createSelector(allAttrSelector, currentCompanySelector, (attributes, company) => {
    const hasEventAttributes = checkFeatureForCompany(company, 'cep-event-based-targeting-feature')

    return attributes
      .filter(
        attribute =>
          !attribute.hidden &&
          attribute.id !== 'b.language' &&
          attribute.id !== 'b.region' &&
          attribute.id.substr(0, 3) !== 'be.' &&
          // temporarily hide event attributes
          (attribute.type !== 'EVENT' || hasEventAttributes)
      )
      .toList()
      .map(attr =>
        QueryAttributeFactory({
          label: attr.label,
          api: attr.id,
          icon: attr.icon,
          type: coerceType(attr.type),
          eventAttributes: attr.allowedKeys
            .filter(eventData => eventData.attributeKind !== 'OBJECT')
            .map(eventData => {
              if (eventData.kind === 'label')
                return QueryAttributeFactory({
                  label: 'Label',
                  api: 'b.label',
                  type: Types.STRING,
                  icon: TypesIcons.STRING,
                })
              if (eventData.kind === 'tag')
                return QueryAttributeFactory({
                  label: 'Tag',
                  api: 'b.tags',
                  type: Types.TAG,
                  icon: TypesIcons.TAG,
                })
              return QueryAttributeFactory({
                label: eventData.name,
                api: generateQueryAttributeId(eventData),
                type: coerceType(eventData.type, eventData.attributeKind),
                icon:
                  eventData.type === '__TAG__' || eventData.attributeKind === 'ARRAY'
                    ? TypesIcons.TAG
                    : TypesIcons[eventData.type],
              })
            })
            .toList(),
        })
      )
  })

const generateQueryAttributeId = (eventData: EventDataAttributeRecord): string => {
  if (eventData.type === '__LABEL__') {
    return '$label'
  } else if (eventData.type === '__TAG__') {
    return '$tag'
  } else {
    return eventData.name
  }
}

export const availableAttributesSelector: extract<List<QueryAttributeRecord>> = createSelector(
  allAttrSelector,
  projectCanQueryEvents,
  attributes => {
    return attributes
      .filter(
        a =>
          !a.hidden &&
          a.id !== 'b.language' &&
          a.id !== 'b.region' &&
          a.id.substr(0, 3) !== 'be.' &&
          !attributeIsEvent(a)
      )
      .toList()
      .map(attr =>
        QueryAttributeFactory({
          label: attr.label,
          api: attr.id,
          icon: attr.icon,
          type: coerceType(attr.type),
        })
      )
  }
)
export const queryMapSelector: extract<Map<string, QueryRecord>> = (state: State) => state.query

export const getQueryRecordForIdSelector: extract<(arg1: string) => QueryRecord> = createSelector(
  queryMapSelector,
  queryMap => {
    return (queryId: string) => queryMap.get(queryId, QueryFactory())
  }
)
export const getAPIQueryForIdSelector: extract<
  (arg1: string) => Record<any, any> | null | undefined
> = createSelector(queryMapSelector, queryMap => {
  return (queryId: string) => formatQueryForAPI(queryMap.get(queryId, QueryFactory()))
})
export const getIsQueryPresentSelector: extract<(arg1: string) => boolean> = createSelector(
  queryMapSelector,
  queryMap => {
    return (queryId: string) => queryMap.has(queryId)
  }
)

export const availableEventAttributesSelector: extract<
  (arg1: string) => List<QueryAttributeRecord>
> = createSelector(allAttrSelector, attributes => {
  return (eventId: string) => {
    const event =
      eventId === 'be.install_optin'
        ? AttributeFactory({
            id: 'be.install_optin',
            name: 'INSTALLED THEN OPTED-IN',
            label: 'INSTALLED THEN OPTED-IN',
            native: true,
            allowedKeys: Immutable.OrderedSet.of(
              EventDataAttributeFactory({
                type: 'DATE',
                kind: 'attribute',
                name: 'install_date',
              })
            ),
          })
        : attributes.get(eventId, AttributeFactory())
    const options: Array<QueryAttributeRecord> = []

    event.allowedKeys
      .filter(
        eventData => eventData.attributeKind !== 'OBJECT' && eventData.attributeKind !== 'ARRAY'
      )
      .forEach(eventData => {
        options.push(
          QueryAttributeFactory(
            eventData.type === '__LABEL__'
              ? {
                  label: 'Event label',
                  api: 'eventLabel()',
                  type: 'STRING',
                  icon: 'label',
                }
              : eventData.type === '__TAG__'
                ? {
                    label: 'Tags',
                    api: 'eventTags()',
                    type: 'TAG',
                    icon: 'collection',
                  }
                : {
                    label: eventData.name,
                    api: `eventAttr(attr: '${eventData.name}')`,
                    type: eventData.type === 'UNKNOWN' ? 'STRING' : eventData.type,
                    icon:
                      eventData.type === 'DATE'
                        ? 'calendar'
                        : eventData.type === 'BOOLEAN'
                          ? 'boolean'
                          : eventData.type === 'STRING'
                            ? 'text'
                            : eventData.type === 'URL'
                              ? 'link'
                              : eventData.type === 'INTEGER'
                                ? 'integer'
                                : 'number',
                  }
          )
        )
      })
    return Immutable.List(options)
  }
})
