import * as React from 'react'

import { colors } from 'components/styled/tokens'

type BounceNoDataProps = {
  schedulingType: schedulingType
}

export const BounceNoData = ({ schedulingType }: BounceNoDataProps): React.ReactElement => (
  <div
    style={{
      display: 'grid',
      placeContent: 'center',
      height: '100%',
      color: colors.textLight,
    }}
  >
    No bounce for this {schedulingType === 'campaigns' ? 'campaign' : 'automation'}
  </div>
)
