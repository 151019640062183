import { type OrderedMap } from 'immutable'

import { type DispatchExtraBoundFn, type AttributeRecord } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type FetchUnifiedCustomerDataSummaryAction = {
  type: 'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY'
}
export type FetchUnifiedCustomerDataSummarySuccessAction = {
  type: 'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY_SUCCESS'
  payload: OrderedMap<string, AttributeRecord>
}
type FetchUnifiedCustomerDataSummaryFailureAction = {
  type: 'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY_FAILURE'
}

export type FetchUnifiedCustomerDataSummary =
  | FetchUnifiedCustomerDataSummaryAction
  | FetchUnifiedCustomerDataSummarySuccessAction
  | FetchUnifiedCustomerDataSummaryFailureAction

export const fetchUnifiedCustomerDataSummary = (): DispatchExtraBoundFn<
  Promise<OrderedMap<string, AttributeRecord>>
> => {
  return (dispatch, getState, { ucpGateway }) => {
    const project = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: ucpGateway.fetchUnifiedCustomerData({ project }),
      actionName: 'FETCH_UNIFIED_CUSTOMER_DATA_SUMMARY',
    })
  }
}
