import * as React from 'react'

import { LayerBarDropdown } from 'com.batch/message-builder/ui/components/layerbar-button'

type LayerBarProps = {
  openPopinDevices: () => void
  openPopinTemporaryDevice: () => void
}

export const LayerBarPushTest = ({
  openPopinDevices,
  openPopinTemporaryDevice,
}: LayerBarProps): React.ReactElement => {
  return (
    <LayerBarDropdown
      label="Push Test"
      icon={<img src="/medias/img/builders/icons/send-big.svg" alt="icon-send-test" />}
      active={false}
      options={[
        { label: 'Saved devices', action: openPopinDevices },
        { label: 'Temporary device', action: openPopinTemporaryDevice },
      ]}
    />
  )
}
