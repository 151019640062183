import * as React from 'react'

import { type UserRecord } from 'com.batch.redux/user.records'

import { PasswordMfa } from 'com.batch/shared/ui/component/confirm-mfa/password-mfa'
import { SsoMfa } from 'com.batch/shared/ui/component/confirm-mfa/sso-mfa'
import { TotpMfa } from 'com.batch/shared/ui/component/confirm-mfa/totp-mfa'

type Props = {
  user: UserRecord
  onSuccess: () => void
  onCancel: () => void
  confirm?: string
  cancel: string
  sensitive?: boolean
  authRequired: boolean
}

export const ConfirmMfa = (props: Props): React.ReactElement => {
  const { mfaMethod } = props.user
  if (mfaMethod === 'totp') {
    return <TotpMfa {...props} />
  } else if (mfaMethod === 'sso') {
    return <SsoMfa {...props} />
  }
  return <PasswordMfa {...props} />
}
