import { type List } from 'immutable'
import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo, type StateToggler } from 'components/_hooks'
import {
  BoxHeader,
  HeaderBoxTitle,
  BoxBody,
  BoxFooter,
  HeaderBoxActions,
} from 'components/common/box'
import { Button, Switch, PermissionButton } from 'components/common/button'
import { Tooltip } from 'components/common/tooltip'

import { percentage } from 'com.batch.common/utils'

import { PressureInAppRow } from './pressure-inapp-row'
import { CappingBox, SdkBadge } from './pressure.styles'

import { type fetchingState } from 'com.batch.redux/_records'
import { type InAppCappingRecord, InAppCappingFactory } from 'com.batch.redux/capping.records'

type PressureInAppProps = {
  clearOtherEdit: () => void
  editingState: StateToggler
  sdkLevelLoading: boolean
  loadingState: fetchingState
  hideToggle?: boolean
  wrappedEmptyState?: React.ReactNode
  installRate: number
  otherEdit: string | null
  data: List<InAppCappingRecord>
  save: (arg1: List<InAppCappingRecord>) => void
}

export const PressureInApp = ({
  data,
  save,
  hideToggle,
  editingState,
  sdkLevelLoading,
  clearOtherEdit,
  loadingState,
  installRate,
  wrappedEmptyState,
  otherEdit,
}: PressureInAppProps): React.ReactElement => {
  const [localData, setLocalData] = React.useState<List<InAppCappingRecord>>(data)

  const edit = React.useCallback(() => {
    clearOtherEdit()
    editingState.open()
    setLocalData(data)
  }, [clearOtherEdit, data, editingState])

  // remove editing state when editing other
  React.useEffect(() => {
    if (otherEdit !== null) {
      editingState.close()
    }
  }, [editingState, otherEdit])

  const enabled = React.useMemo(
    () => (editingState.value ? localData : data).reduce((acc, item) => acc || item.enabled, false),
    [data, editingState.value, localData]
  )
  const [newRule, setNewRule] = React.useState<InAppCappingRecord>(
    InAppCappingFactory({ enabled: localData.size === 0 || enabled })
  )
  const setEnabled = React.useCallback(
    enabled => {
      setLocalData(localData =>
        (editingState.value ? localData : data).map(capping => capping.set('enabled', enabled))
      )
      editingState.open()
      clearOtherEdit()
    },
    [clearOtherEdit, data, editingState]
  )

  const isAllowedToUpdateCappings = useIsCurrentUserAllowedTo(['app', 'cappings:write'])

  return (
    <ThemeProvider
      theme={{
        isEmpty: false,
        size: 'small',
        kind: 'capture',
        horizontal: true,
        isLoading: loadingState === 'LOADING',
      }}
    >
      <CappingBox isEditing={editingState.value} style={{ marginBottom: 28, overflow: 'hidden' }}>
        <BoxHeader>
          <HeaderBoxTitle
            title={
              hideToggle ? (
                'In-app campaigns'
              ) : (
                <Switch isActive={enabled} onChange={setEnabled}>
                  In-app campaigns
                </Switch>
              )
            }
            suffix={
              (editingState.value || enabled) && (
                <Tooltip
                  minWidth={340}
                  placement="right"
                  tooltip={`Available for SDK version 1.19 and above (around ${percentage(
                    installRate
                  )} of the userbase).`}
                >
                  <SdkBadge
                    isLoading={sdkLevelLoading}
                    ratio={installRate}
                    style={{ marginTop: 1 }}
                  >
                    SDK&nbsp;1.19
                  </SdkBadge>
                </Tooltip>
              )
            }
          />
          <HeaderBoxActions>
            {editingState.value ? (
              <React.Fragment>
                <Button
                  style={{ marginRight: 0 }}
                  kind="inline"
                  onClick={() => {
                    setLocalData(data)
                    editingState.close()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  intent="action"
                  kind="inline"
                  style={{ margin: 0 }}
                  onClick={() => save(localData)}
                  disabled={(data.size === 0 && localData.size === 0) || localData === data}
                >
                  Save
                </Button>
              </React.Fragment>
            ) : hideToggle ? null : (
              <PermissionButton
                type="button"
                kind="inline"
                onClick={edit}
                isAllowed={isAllowedToUpdateCappings}
              >
                Edit
              </PermissionButton>
            )}
          </HeaderBoxActions>
        </BoxHeader>
        {Boolean(wrappedEmptyState) && !editingState.value ? (
          <BoxBody>{wrappedEmptyState}</BoxBody>
        ) : (
          <React.Fragment>
            <BoxBody
              style={
                editingState.value && data.size === 0
                  ? { padding: 0, borderTop: 0 }
                  : { padding: 0 }
              }
            >
              {(editingState.value ? localData : data).map((capping, key) => (
                <PressureInAppRow
                  key={key}
                  capping={capping}
                  isEditing={editingState.value}
                  remove={() => setLocalData(localData.delete(key))}
                  onChange={capping => setLocalData(localData.set(key, capping))}
                />
              ))}
            </BoxBody>
            {editingState.value && (
              <BoxFooter
                small
                style={localData.size === 0 ? { padding: 0, borderTop: 0 } : { padding: 0 }}
              >
                <PressureInAppRow
                  capping={newRule}
                  isEditing
                  onChange={setNewRule}
                  add={() => {
                    setLocalData(localData.push(newRule))
                    setNewRule(InAppCappingFactory())
                  }}
                />
              </BoxFooter>
            )}
          </React.Fragment>
        )}
      </CappingBox>
    </ThemeProvider>
  )
}
