import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type EstimateProfileRecord } from 'com.batch/orchestration/models/profile-estimate.records'

type FetchAudienceEstimateAction = ReduxAction<
  'UPDATE_AUDIENCE_ESTIMATE',
  {
    audienceName: string
  }
>

export type FetchAudienceEstimateSuccessAction = ReduxAction<
  'UPDATE_AUDIENCE_ESTIMATE_SUCCESS',
  {
    estimate: EstimateProfileRecord
    audienceName: string
  }
>
export type FetchAudienceEstimateFailureAction = ReduxAction<
  'UPDATE_AUDIENCE_ESTIMATE_FAILURE',
  {
    audienceName: string
    error: string
  }
>

export type FetchEstimateActions =
  | FetchAudienceEstimateSuccessAction
  | FetchAudienceEstimateFailureAction
  | FetchAudienceEstimateAction

export const fetchEstimateForAudienceName = ({
  audienceName,
}: {
  audienceName: string
}): DispatchExtraBoundFn<Promise<any>> => {
  return async (dispatch, getState, { ourSqlService }): Promise<any> => {
    const state = getState()
    const project = currentProjectSelector(state)

    return promiseActionCreator({
      dispatch,
      promise: ourSqlService
        .fetchProfileEstimateFor({
          projectKey: project.projectKey,
          languages: [],
          regions: [],
          query: `{
            "bt.custom_audiences": {
              "$containsAny": [
                "${audienceName}"
              ]
            }
          }`,
        })
        .then(response => {
          return {
            estimate: response,
            audienceName,
          }
        })
        .catch(error => {
          throw { audienceName, error }
        }),
      payload: {
        audienceName,
      },
      actionName: 'UPDATE_AUDIENCE_ESTIMATE',
    })
  }
}
