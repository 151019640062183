import styled from 'styled-components'

import { FormActions } from 'components/form'
import { colors } from 'components/styled/tokens'

const MARGIN = 28

export const AccountSection = styled.div`
  border-top: 1px solid ${colors.stroke};
  margin: ${MARGIN}px;
  margin-top: ${3 * MARGIN}px;
  padding-top: ${MARGIN}px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    display: block;
  }

  &:first-of-type {
    margin-top: ${MARGIN}px;
  }
`

export const Title = styled.h2<any>`
  color: #232d3b;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0.7px;
  margin: ${MARGIN}px ${MARGIN}px ${2 * MARGIN}px;
  margin: 26px ${MARGIN}px ${2 * MARGIN}px ${MARGIN}px;
`

export const Header = styled.section`
  color: #505050;
  margin: ${-MARGIN}px ${MARGIN}px 0;

  p {
    font-size: 15px;
    margin-bottom: 1em;
    max-width: 600px;
  }

  div {
    margin-top: 2em;
  }

  label {
    background: #f0f0f0;
    padding: 1em;
    font-size: 16px;
    border-radius: 3px;
  }
`

export const SectionTitle = styled.h3<any>`
  font-size: 24px;
  margin-bottom: 0.5em;
  color: #232d3b;
`

export const SectionInfo = styled.div`
  flex: 0 1 380px;
  line-height: 21px;

  p {
    color: #999999;
    font-size: 14px;
    line-height: 21px;
  }
`

export const SectionForm = styled.div`
  flex: 0 1 580px;
  margin: 0 0 0 102px;
  @media screen and (max-width: 800px) {
    display: block;
    margin: ${MARGIN}px 0 0 0;
  }
`

export const Note = styled.div`
  color: #e78c52;
  font-weight: 500;
  font-size: 14px;
  margin: 1em 0;
`

export const PopinSection = styled.div`
  padding: 1em;
  color: #5c5c5c;

  h3 {
    color: #5c5c5c;
    font-weight: 600;
    font-size: 15px;
    margin: 0 0 1em 0;
  }

  hr {
    border-top: 2px solid #f6f6f6;
    width: 150px;
    text-align: left;
    margin: 2em 0 0 0;
  }
`
export const Enabled = styled.span`
  background-image: linear-gradient(#8fc169, #7eb552);
  box-shadow: 1px 1px 1px 1px #e9e9e9;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  padding: 0.6em 0.7em;
  color: #ffffff;
  display: inline-block;
  border-radius: 6px;
  margin-left: 0.7em;
`
export const RecoveryContainer = styled.div`
  border-radius: 5px;
  margin: 1em 0;
  border: 1px solid #e7e7e7;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;

  code {
    border-radius: 0;
    flex: 0 0 calc(50% - 24px);
    padding: 3px 8px;
    background-color: #fff;
    margin: 3px;
    font-size: 12px;
    color: #353535;
    border-bottom: 1px solid #f8f8f8;

    &:nth-child(even) {
      border-left: 1px solid #f8f8f8;
      padding: 3px 8px 3px 18px;
      flex: 0 0 50%;
    }

    &:nth-child(5),
    &:nth-child(6) {
      border-bottom: none;
    }
  }
`

export const LightButton = styled.button`
  display: flex;
  color: #9d9d9d;
  outline: none;
  border: none;
  padding: 5px;
  font-size: 11px;
  margin-right: 1em;

  span {
    padding-right: 1em;
  }

  &:hover {
    color: #0dbafa;
  }
`

export const AccountGrid = styled.div`
  display: grid;
  gap: 36px;

  ${Title} {
    margin-bottom: 0;
  }

  ${AccountSection} {
    display: grid;
    grid-template-columns: 1fr 160px 364px;
    gap: 16px;
    margin: 0 28px 0;

    > p {
      font-weight: 500;
    }

    &:first-child p {
      margin-top: 5px;
    }

    > :first-child {
      margin-right: 80px;

      > p {
        margin-top: 5px;
      }
    }

    .styled-option-label {
      white-space: normal;
    }

    ${FormActions} {
      padding: 0;
    }

    ${SectionInfo} {
      p {
        color: ${colors.textLight};
      }
    }
  }
`
