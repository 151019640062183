import request from 'superagent-interface-promise'

import { generateUrl } from 'com.batch.common/router'

export const securityService = {
  validateTotp: async ({ code, email }: { code: string; email: string }): Promise<void> => {
    try {
      await request
        .get(generateUrl('api_login_mfa_totp'))
        .set('X-2FA-code', code)
        .set('X-RateLimit-Id', `${email}-2fa`)
    } catch {
      throw new Error('Invalid code')
    }
  },
  validatePassword: async ({
    password,
    email,
  }: {
    password: string
    email: string
  }): Promise<void> => {
    try {
      await request
        .post(generateUrl('api_login_mfa_password'), { password })
        .set('X-RateLimit-Id', `${email}-mfa`)
    } catch {
      throw new Error('Invalid password')
    }
  },
}
