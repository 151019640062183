import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'
import { ThemeProvider } from 'styled-components'

import { useIsCurrentUserAllowedTo, useToggle } from 'components/_hooks'
import { Separator } from 'components/app/custom-data/custom-data.styles'
import { Box } from 'components/common/box'
import { PermissionButton } from 'components/common/button'
import { confirm } from 'components/common/confirm'
import { ConfirmHighlight } from 'components/common/confirm.styles'
import { Wrapper, GlobalErrorOverlayProps } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { TrackingContext, type TrackingEventProps } from 'components/common/page-tracker'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'
import { Table, TableCellOrder, TableHeader, TableBody } from 'components/common/table'
import { FilterSearch } from 'components/filter'
import { Title } from 'components/styled/text'

import { LabelEditor } from './label-editor'
import { LabelPopin } from './label-popin'
import { EmptyLabelRow } from './label.style'

import {
  type CappingCategoryRecord,
  type AppRecord,
  CappingCategoryFactory,
} from 'com.batch.redux/_records'
import { labelsSelector } from 'com.batch.redux/app'
import { deleteCappingCategorie } from 'com.batch.redux/app.action'
import { fetchCampaignsLabelsCount } from 'com.batch.redux/campaign.action'

import { NoResultWrapper } from 'com.batch/shared/ui/component/no-result-wrapper'
import { STATUS } from 'constants/common'

const TRACKING_CONTEXT: TrackingEventProps = {
  eventLocation: 'labels',
  searchEventCode: 'SEARCH_LABELS',
  pagerEventCode: 'unset',
}

type LabelsProps = {
  app: AppRecord
}

export const Labels: React.ComponentType<LabelsProps> = React.memo<LabelsProps>(
  ({ app }: LabelsProps) => {
    // ====================== Components states
    const [query, setQuery] = React.useState<string>('')
    const [allowCodeEdition, setAllowCodeEdition] = React.useState<boolean>(false)
    const [popinOpened, setPopinOpened] = React.useState<boolean>(false)
    const [editLine, setEditLine] = React.useState<number | null>(null)

    const dispatch = useDispatch()
    const categories = useSelector(labelsSelector)

    const [fieldOrder, setFieldOrder] = React.useState<'name' | 'count' | 'code'>('name')
    const [sortOrder, setSortOrder] = React.useState<'asc' | 'dsc'>('asc')

    const popinCreateLabel = useToggle()

    // ====================== Component constants
    const newCat = categories.find(cat => cat.id === 'new', null, CappingCategoryFactory())
    const labels = categories.filter(cat => cat.id !== 'new')

    const filtersData = React.useMemo(
      () =>
        query
          ? labels.filter(cat => cat.name.toLowerCase().indexOf(query.toLocaleLowerCase()) !== -1)
          : labels,
      [labels, query]
    )

    const matchingFilters = React.useMemo(
      () =>
        sortOrder === 'asc'
          ? filtersData.sortBy(e => e[fieldOrder])
          : filtersData.sortBy(e => e[fieldOrder]).reverse(),
      [fieldOrder, filtersData, sortOrder]
    )

    // check if features is activated for this app
    const featureActivated = React.useMemo(() => app.features.has('label'), [app.features])
    const isEmpty = React.useMemo(
      () =>
        (categories.size === 0 && !query) ||
        !featureActivated ||
        app.labelsCountLoadingState === STATUS.ERROR,
      [categories.size, featureActivated, query, app.labelsCountLoadingState]
    )
    const isLoading = React.useMemo(
      () =>
        app.labelsCountLoadingState === STATUS.INIT ||
        app.labelsCountLoadingState === STATUS.LOADING,
      [app.labelsCountLoadingState]
    )

    // ====================== Callbacks

    const createEditLineUpdater = React.useCallback((i: number | null) => () => setEditLine(i), [])

    const createSorterFor = React.useCallback(
      (field: 'name' | 'count' | 'code') => () => {
        setEditLine(null)
        if (field === fieldOrder) {
          setSortOrder(sortOrder === 'asc' ? 'dsc' : 'asc')
        } else {
          setFieldOrder(field)
          setSortOrder('asc')
        }
      },
      [fieldOrder, sortOrder]
    )

    const editCode = React.useCallback(
      (callback: () => any) => {
        if (!popinOpened) {
          setPopinOpened(true)
          confirm({
            message: (
              <article>
                <p>Code are used for all APIs calls, and are by our backends to track cappings</p>
                <p>By changing a label's code, you might : </p>
                <ul style={{ paddingLeft: '20px' }}>
                  <li>
                    Trigger errors on your API calls if you don't update them with the new code.
                  </li>
                  <li>Reset the cappings on running campaigns already using this label.</li>
                </ul>
              </article>
            ),
            title: 'Please confirm you are aware of the risk of changing code',
            confirm: 'I understand',
          }).then(
            () => {
              setPopinOpened(false)
              setAllowCodeEdition(true)
              if (callback) callback()
            },
            () => {
              setPopinOpened(false)
            }
          )
        }
      },
      [popinOpened]
    )

    const isAllowedToChangeCustomAudiences = useIsCurrentUserAllowedTo([
      'app',
      'custom-audiences:write',
    ])
    const createOnRemoveLabel = React.useCallback(
      (cat: CappingCategoryRecord) => () => {
        confirm({
          message: (
            <article>
              <p>
                This will delete the label <ConfirmHighlight>{cat.name}</ConfirmHighlight>.
              </p>
              <p>
                If you use our APIs to send notifications, any attempt to use the label with code{' '}
                <code>{cat.code}</code> will be rejected.
              </p>
              {cat.count > 0 ? (
                <p>
                  This label is currently used in {cat.count} campaign{cat.count > 1 ? 's' : ''}.
                </p>
              ) : (
                <p>This label is not currently used in any campaign.</p>
              )}
            </article>
          ),
          title: 'Delete this label?',
          sensitive: true,
        }).then(
          () => {
            dispatch(deleteCappingCategorie(cat))
          },
          () => {}
        )
      },
      [dispatch]
    )

    // ====================== Use effect

    React.useEffect(() => {
      dispatch(fetchCampaignsLabelsCount(app.id))
      setEditLine(null)
    }, [app.id, dispatch])

    // ====================== Render
    return (
      <TrackingContext.Provider value={TRACKING_CONTEXT}>
        <Wrapper
          isLoading={isLoading}
          isEmpty={isEmpty}
          isOverlayShown={isEmpty || app.labelsCountLoadingState === STATUS.ERROR}
          overlayProps={
            app.labelsCountLoadingState === STATUS.ERROR
              ? GlobalErrorOverlayProps
              : !featureActivated
                ? {
                    status: 'upgrade-page',
                    companyId: app.companyId ?? 0,
                    title: 'Activate this feature',
                    description:
                      'Labels allow you to categorize campaigns to filter the list of your campaigns and apply custom marketing pressure.',
                  }
                : {
                    status: 'empty-page',
                    title: 'No label to display',
                    description: (
                      <div>
                        <p style={{ marginBottom: 10 }}>
                          You haven’t created any label for this app for now. You can create your
                          first one right now by filling out the following form
                        </p>
                        <PermissionButton
                          intent="action"
                          kind="primary"
                          onClick={popinCreateLabel.open}
                          isAllowed={isAllowedToChangeCustomAudiences}
                        >
                          New label
                        </PermissionButton>
                      </div>
                    ),

                    links: [
                      {
                        name: 'Learn about  labels',
                        href: 'https://doc.batch.com/dashboard/settings/app-settings#labels-management',
                      },
                    ],
                  }
          }
        >
          <Grid template="1fr auto 9px auto" style={{ marginBottom: 38 }} gap={8}>
            <Title overEmptyState style={{ marginBottom: 0 }}>
              Labels
            </Title>
            <ThemeProvider theme={{ kind: 'filter' }}>
              <FilterSearch
                value={query}
                onChange={setQuery}
                disabled={isEmpty}
                expandedMaxWidth={220}
                identifier={'labels'}
              />

              <Separator />

              <PermissionButton
                intent="action"
                kind="primary"
                addOn="prefix"
                notAllowedMessage={
                  categories.size > 500
                    ? "You can't add more than 500 labels"
                    : 'You are not allowed to create new labels'
                }
                onClick={popinCreateLabel.open}
                isAllowed={isAllowedToChangeCustomAudiences && categories.size < 500}
              >
                <Icon icon="add" />
                New label
              </PermissionButton>
            </ThemeProvider>
          </Grid>

          <ThemeProvider theme={{ size: 'small' }}>
            <Box
              style={{
                overflow: 'hidden',
              }}
            >
              <Table template="1fr 200px 236px 110px" rowHeight={52}>
                <TableHeader>
                  <TableCellOrder
                    sort={fieldOrder === 'name' ? sortOrder : undefined}
                    onClick={createSorterFor('name')}
                  >
                    Label name
                  </TableCellOrder>
                  <TableCellOrder
                    sort={fieldOrder === 'count' ? sortOrder : undefined}
                    onClick={createSorterFor('count')}
                  >
                    Used in
                  </TableCellOrder>

                  <TableCellOrder
                    sort={fieldOrder === 'code' ? sortOrder : undefined}
                    onClick={createSorterFor('code')}
                  >
                    Code
                  </TableCellOrder>
                  <div />
                </TableHeader>
                <TableBody emptyTemplate={<EmptyLabelRow />}>
                  <NoResultWrapper isEmpty={matchingFilters.size === 0} entityName="label">
                    {matchingFilters.map((category, i) => (
                      <LabelEditor
                        key={category.id}
                        isEditing={editLine === i + 1}
                        editLine={createEditLineUpdater(i + 1)}
                        editClose={createEditLineUpdater(null)}
                        category={category}
                        editCode={editCode}
                        allowCodeEdition={allowCodeEdition}
                        warnAndDelete={createOnRemoveLabel(category)}
                        loadingLabels={isLoading}
                      />
                    ))}
                  </NoResultWrapper>
                </TableBody>
              </Table>
            </Box>
          </ThemeProvider>
        </Wrapper>
        {newCat && (
          <Popin
            opened={popinCreateLabel.value}
            close={popinCreateLabel.close}
            style={{ width: 480, margin: 0, padding: 0 }}
          >
            <LabelPopin close={popinCreateLabel.close} category={newCat} />
          </Popin>
        )}
      </TrackingContext.Provider>
    )
  }
)
