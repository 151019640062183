import * as React from 'react'

type TabItemProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isActive?: boolean
  isDisabled?: boolean
  children: React.ReactNode
  onClick?: () => any
  style?: {
    [key: string]: string | number
  }
}

export function TabButtonItem({
  isActive = false,
  isDisabled = false,
  children,
  onClick,
  style,
  ...rest
}: TabItemProps): React.ReactElement {
  const onClickHandler = React.useCallback(
    evt => {
      evt.preventDefault()
      if (onClick && !isDisabled) onClick()
    },
    [isDisabled, onClick]
  )
  return (
    <button
      {...rest}
      type="button"
      className={`${isActive ? 'active ' : ''}${isDisabled ? 'disabled ' : ''}`}
      style={style}
      onClick={onClickHandler}
    >
      {children}
    </button>
  )
}
