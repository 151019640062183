import { createSelector } from 'reselect'

import { type State } from 'com.batch.redux/_records'

import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import { type InlineEditorConfigRecord } from 'com.batch/message-builder/models/message-builder.records'
import { InlineEditorStateFactory } from './inline-editor.state'

export const inlineEditorConfigSelector: (arg1: State) => InlineEditorConfigRecord = (
  state: State
) => state.inlineEditor ?? InlineEditorStateFactory()

export const isFieldActiveSelector: (
  field: MessageBuilderEditableField
) => (arg1: State) => boolean = field =>
  createSelector(
    inlineEditorConfigSelector,
    (config: InlineEditorConfigRecord) =>
      config.field === field && !config.personalizationModalIsOpen && config.variant === 'a'
  )
