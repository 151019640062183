import type React from 'react'

export const createOnKeyDownHandler = (
  func: () => void,
  keys: Array<string> = ['Enter', 'Space']
): ((arg1: React.KeyboardEvent<HTMLInputElement>) => void) => {
  return (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (keys.includes(event.code)) func()
  }
}
