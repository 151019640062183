// @ts-nocheck l'enfer à fixer
// ====================== CONFIG
import * as D3Array from 'd3-array'
import {
  scaleBand,
  scaleUtc,
  scaleLinear,
  type ScaleTime,
  type ScaleBand,
  type ScaleLinear,
} from 'd3-scale'
import { line, curveCatmullRom, area } from 'd3-shape'
import { type Dayjs } from 'dayjs'
import { type List } from 'immutable'
import * as React from 'react'

import { useResizeObserver } from 'components/_hooks'
import { Wrapper } from 'components/common/empty-states'
import { schemes } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { kformat, percentage } from 'com.batch.common/utils'

import { LineChartPath, LineChartPointer, LineChartArea } from './styles'

import {
  LegendTimeLabel,
  LegendTimeCurrent,
  LegendTimeContainer,
  LegendTimeLabelSkeleton,
  LegendTimeRect,
  ChartTtip,
  ChartTtipElement,
  ChartTtipTitle,
  ChartTtipValue,
  ChartScale,
  ChartScaleSkeleton,
} from '../bar-chart/styles'
import { DataPointFactory, type DataSetRecord, type DataPointRecord } from '../chart-helper'

const MARGIN_RIGHT = 60
const MARGIN_LEFT = 10
const MARGIN_TOP = 20
const NB_TICKS = 10
const STROKE_COLOR = '#C6D3DF'
const TL_HEIGHT = 34
const DOMAINFORMAT = 'YYYYMMDD'

function curvedLine(
  data: List<
    | DataPointRecord
    | {
        date: Dayjs
        value: number
      }
  >,
  scaleX: any,
  scaleY: any,
  height: number
) {
  return line<
    | DataPointRecord
    | {
        date: Dayjs
        value: number
      }
  >()
    .x(d => scaleX(d.date.toDate()))
    .y(d => height - scaleY(d.value))
    .curve(curveCatmullRom.alpha(0.5))(data.toArray())
}

function curvedArea(
  data: List<
    | DataPointRecord
    | {
        date: Dayjs
        value: number
      }
  >,
  scaleX: any,
  scaleY: any,
  height: number
) {
  return area<
    | DataPointRecord
    | {
        date: Dayjs
        value: number
      }
  >()
    .x(d => scaleX(d.date.toDate()))
    .y1(d => height - scaleY(d.value))
    .y0(height)
    .curve(curveCatmullRom.alpha(0.5))(data.toArray())
}

type LineChartProps = {
  height: number
  loading?: boolean
  overIndex: number | null | undefined
  setOverIndex: (index: null | number) => void
  separator?: boolean
  sets: List<DataSetRecord>
  timeline: boolean
}

export const LineChart = ({
  height = 150,
  loading,
  overIndex,
  setOverIndex,
  separator,
  sets,
  timeline = false,
}: LineChartProps): React.ReactElement | null => {
  const [ref, width] = useResizeObserver()

  let xLine: ScaleTime<number, number>
  let granularity: 'hour' | 'day'
  let x: ScaleBand<string>
  let y: ScaleLinear<number, number>
  let ticksValues: Array<number> = []
  let previousSets: List<DataSetRecord>
  let previousWidth: number
  let previousHeight: number

  const computeXScale = (sets: List<DataSetRecord>, width: number) => {
    let domain: Array<any> = []
    const first = sets.first()
    if (first) {
      domain = first.data
        .map(v => v.date.format(DOMAINFORMAT))
        .toArray()
        .sort((a, b) => (a < b ? -1 : 1))
    }
    x = scaleBand()
      .domain(domain)
      .rangeRound([MARGIN_LEFT, width - MARGIN_RIGHT])
      .paddingInner(0.35)
      .paddingOuter(0)

    if (first) {
      domain = D3Array.extent(first.data.toArray(), d => d.date.toDate())
    }
    xLine = scaleUtc()
      .domain(domain)
      .range([MARGIN_LEFT + x.bandwidth() / 2, width - MARGIN_RIGHT - x.bandwidth() / 2])
  }

  const computeYScale = (sets: List<DataSetRecord>, height: number) => {
    let absoluteMin = Infinity
    let absoluteMax = -Infinity
    sets.forEach((set: DataSetRecord) => {
      const localMin = set.data.min((pointA, pointB) => {
        return pointA.value < pointB.value ? -1 : 1
      })
      if (localMin && localMin.value < absoluteMin) {
        absoluteMin = localMin.value
      }
      const localMax = set.data.max((pointA, pointB) => (pointA.value > pointB.value ? 1 : -1))
      if (localMax && localMax.value > absoluteMax) {
        absoluteMax = localMax.value
      }
    })
    if (absoluteMin !== Infinity) {
      absoluteMin = absoluteMin * 0.9
    }
    y = scaleLinear().range([0, height]).domain([absoluteMin, absoluteMax])
    ticksValues =
      absoluteMin === 0 && absoluteMax === 0
        ? []
        : [absoluteMin, Math.floor((absoluteMax - absoluteMin) / 2 + absoluteMin), absoluteMax]
  }

  const getOverData = () => {
    const s = sets.first()
    if (s && s.data.size && typeof overIndex === 'number' && s.data.get(overIndex)) {
      const data = s.data.get(overIndex, { date: dayjs(), value: 0 })
      return {
        domainDate: data.date.format(DOMAINFORMAT),
        date: data.date,
        formatedDate: data.date.format(granularity === 'hour' ? 'DD/MM HH[H]' : 'DD/MM'),
        value: data.value,
      }
    }
    return null
  }

  const getFullHeight = () => {
    return height + MARGIN_TOP + (timeline ? TL_HEIGHT : 0)
  }

  const getY = (value: number) => {
    return getFullHeight() - y(value)
  }

  const renderTimeline = () => {
    const overData = getOverData()
    let overTl = null
    const firstSet = sets.first()
    if (overData) {
      const overX = Math.min(width - 55, Math.max(35, xLine(overData.date)))
      overTl = (
        <LegendTimeCurrent height={TL_HEIGHT - 1} x={overX}>
          <rect
            fill="url(#barchart-currentLegendGradient)"
            x={-70}
            height={TL_HEIGHT - 2}
            width={140}
          />
          <text y={TL_HEIGHT / 2 + 4.5} width="140">
            {overData.formatedDate}
          </text>
        </LegendTimeCurrent>
      )
    }

    if (firstSet) {
      const every = Math.max(1, Math.floor(firstSet.data.size / NB_TICKS))
      const tv: Array<Dayjs | any> = []
      let ind = 0
      let safeg = 20

      while (ind < firstSet.data.size && safeg > 0) {
        tv.push(firstSet.data.get(ind).date)
        ind += every
        safeg--
      }
      return (
        <LegendTimeContainer>
          <LegendTimeRect x={-1} y={-1} width={width + 1} height={TL_HEIGHT + 0.5} />
          <line x1={-1} y1={TL_HEIGHT - 1.5} x2={width + 1} y2={TL_HEIGHT - 1.5} stroke="#FAFAFB" />
          <line x1={-1} y1={TL_HEIGHT - 0.5} x2={width + 1} y2={TL_HEIGHT - 0.5} stroke="#F6F6FA" />
          <line x1={-1} y1={TL_HEIGHT + 0.5} x2={width + 1} y2={TL_HEIGHT + 0.5} stroke="#FEFEFE" />
          <line x1={-1} y1={TL_HEIGHT + 1.5} x2={width + 1} y2={TL_HEIGHT + 1.5} stroke="#FEFEFE" />
          {tv.map(date => {
            const x = xLine(date.toDate())
            return !hasSkeletonScreen ? (
              <LegendTimeLabel
                key={date.format(DOMAINFORMAT)}
                y={TL_HEIGHT / 2 + 4}
                x={Math.max(x, 40)}
              >
                {date.format(granularity === 'hour' ? 'DD HH[H]' : 'DD/MM')}
              </LegendTimeLabel>
            ) : (
              <LegendTimeLabelSkeleton
                y={height / 6}
                x={Math.max(x)}
                key={date.format(DOMAINFORMAT)}
                fill={schemes.grayscale['30']}
                width={40}
                height={13}
                rx={2}
              />
            )
          })}
          {overTl}
        </LegendTimeContainer>
      )
    }
    return null
  }

  const onMouseMove = React.useCallback(
    (evt: React.MouseEvent<SVGPathElement>) => {
      const overIndex = parseInt(evt.currentTarget.getAttribute('data-index'))
      if (overIndex !== overIndex) {
        setOverIndex(overIndex)
      }
    },
    [setOverIndex]
  )

  const onLeave = React.useCallback(() => setOverIndex(null), [setOverIndex])

  const firstSet = sets.first()
  const isEmpty = !firstSet || firstSet.data.size < 3 || firstSet.data.size > 600
  const hasSkeletonScreen = isEmpty || loading

  if (previousHeight !== height || sets !== previousSets) {
    computeYScale(sets, height)
  }
  if (previousWidth !== width || sets !== previousSets) {
    computeXScale(sets, width)
  }
  previousSets = sets
  previousWidth = width
  previousHeight = height
  const overData = getOverData()
  const st: {
    [key: string]: string | number
  } = {}
  if (overData) {
    const xWidth = x(overData.domainDate) + x.bandwidth() / 2
    if (xWidth > width / 2) {
      st.left = xWidth - 150
    } else {
      st.left = xWidth + 10
    }
  }
  const pad = x.bandwidth() * 0.35
  return (
    <div
      ref={ref}
      onMouseLeave={onLeave}
      style={{
        position: 'relative',
        lineHeight: 0,
        borderBottom: separator ? '1px solid #F1F2F5' : 0,
        height: getFullHeight(),
      }}
    >
      <Wrapper
        overlayProps={{
          status: 'empty',
          title:
            firstSet && firstSet.data.size > 600
              ? 'Too much data for chart. You can select a shorter range or use the table view.'
              : 'No data for this period',
        }}
        isEmpty={!loading && isEmpty}
        isOverlayShown={!loading && isEmpty}
        isLoading={Boolean(loading)}
      >
        {overData && firstSet && (
          <ChartTtip style={st}>
            <ChartTtipElement>
              <ChartTtipTitle color={firstSet.color}>
                <span>{firstSet.label}</span>
                <svg>
                  <circle cx="5" cy="5" r="5" />
                </svg>
              </ChartTtipTitle>
              <ChartTtipValue>
                {overData.value > 0 && overData.value < 1
                  ? percentage(overData.value)
                  : kformat(overData.value)}
              </ChartTtipValue>
            </ChartTtipElement>
          </ChartTtip>
        )}
        <svg height={getFullHeight()} width={width}>
          <defs>
            <linearGradient id="barchart-currentLegendGradient">
              <stop offset="0%" stopColor="rgba(252, 252, 253, 0)" />
              <stop offset="30%" stopColor="rgba(252, 252, 253, 1)" />
              <stop offset="70%" stopColor="rgba(252, 252, 253, 1)" />
              <stop offset="100%" stopColor="rgba(252, 252, 253, 0)" />
            </linearGradient>
          </defs>
          <g>
            {ticksValues.map((value, key) => {
              return (
                <g key={`yaxis-${key}`}>
                  {key === 1 && (
                    <line
                      x1={0}
                      stroke={STROKE_COLOR}
                      strokeOpacity="0.25"
                      x2={width - MARGIN_RIGHT}
                      y1={getY(value)}
                      y2={getY(value)}
                    />
                  )}
                  {hasSkeletonScreen ? (
                    <ChartScaleSkeleton
                      x={key === 1 ? width - 47 : width - 42}
                      y={getY(value) + (key === 0 ? -20 : key === 1 ? -8 : 4)}
                      rx={2}
                      fill="#b8b8b8"
                      width={key === 1 ? 40 : 35}
                      height={13}
                    />
                  ) : (
                    <ChartScale
                      x={width - 10}
                      textAnchor="end"
                      y={getY(value) + (key === 0 ? -10 : key === 1 ? 4 : 20)}
                      fill="#949AA3"
                      stroke="none"
                    >
                      {kformat(value)}
                    </ChartScale>
                  )}
                </g>
              )
            })}
          </g>
          {sets.map((set, key) => {
            // we add a fake value at the start so we don't get a weird padding
            const dataForLine = set.data.unshift({
              date: dayjs(xLine.invert(0)),
              value: set.data.get(0, DataPointFactory()).value,
            } as DataPointRecord)

            return (
              <g key={key}>
                <LineChartArea
                  d={curvedArea(dataForLine, xLine, y, getFullHeight())}
                  color={set.color}
                />
                <LineChartPath
                  d={curvedLine(dataForLine, xLine, y, getFullHeight())}
                  color={set.color}
                />
              </g>
            )
          })}
          {overData !== null && (
            <g>
              <line
                strokeDasharray="10,10"
                stroke="#000"
                style={{ opacity: 0.3, pointerEvents: 'none', transition: 'all 0.2s ease-out' }}
                x1={xLine(overData.date.toDate())}
                x2={xLine(overData.date.toDate())}
                y1={0}
                y2={getFullHeight()}
              />
              <LineChartPointer
                cx={xLine(overData.date.toDate())}
                cy={getFullHeight() - y(overData.value)}
                style={{ fill: firstSet?.color }}
              />
            </g>
          )}
          {timeline && renderTimeline()}
          <g style={{ fill: 'transparent' }} onMouseMove={onMouseMove}>
            {sets.map(set =>
              set.data.map((point: DataPointRecord, k: number) => (
                <rect
                  key={k}
                  x={xLine(point.date.toDate()) - pad - x.bandwidth() / 2}
                  y={0}
                  data-index={k}
                  width={x.bandwidth() + 2 * pad}
                  height={getFullHeight()}
                />
              ))
            )}
          </g>
        </svg>
      </Wrapper>
    </div>
  )
}
