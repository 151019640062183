import * as React from 'react'

import { textLight } from 'components/styled/tokens/colors'
import { grayscale, purple } from 'components/styled/tokens/schemes'

import { type StatType } from 'com.batch/profilebase/infra/types/stat-type'
import { type ChartColor } from 'com.batch/shared/infra/types/chart-data'
import {
  STRIPE_PATTERN_REF,
  StripePattern,
} from 'com.batch/shared/ui/component/charts/donut-chart/patterns/stripe-pattern'

export const statTypeColors: { [key in StatType]: ChartColor } = {
  known: {
    default: grayscale[20],
    hover: '#D1D1D1',
    active: '#D1D1D1',
    middle: textLight,
    pattern: {
      id: STRIPE_PATTERN_REF,
      content: <StripePattern />,
    },
  },
  unsubscribed: {
    default: grayscale[20],
    hover: '#C9C9C9',
    active: '#C9C9C9',
    middle: textLight,
  },
  subscribed: {
    default: '#9476DE',
    hover: purple[40],
    active: purple[40],
    middle: purple[60],
  },
}
