import type { List, Map, OrderedMap, OrderedSet, RecordOf, Set } from 'immutable' // eslint-disable-line

import { type Dayjs } from 'dayjs'
import * as Immutable from 'immutable'

import { type availableIcons } from 'components/common/svg-icon'
import * as colorsLegacy from 'components/styled/colors'
import { colors } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { BillingFactory, type BillingRecord } from 'com.batch.redux/billing.records'
import { ContentStateFactory, type ContentStateRecord } from 'com.batch.redux/content.records'
import { type InjectedApi } from 'com.batch.redux/corelogic/gateways/api'

import { CampaignDataFactory, type CampaignDataRecord } from 'com.batch.redux/dataCampaign.records'
import { type EntityLogRecord, UserFactory, type UserRecord } from 'com.batch.redux/user.records'

import { VolumesFactory, type VolumesRecord } from 'com.batch/account/model/volumes.records'
import { type FCMConfigRecord } from 'com.batch/settings/models/fcm-config.records'
import { DELAY_MODE, STATUS } from 'constants/common'
import { type RootState } from 'components/common/app-wrapper'
import { type ThunkDispatch, type ThunkAction } from 'redux-thunk'
import { type Action } from 'redux'

export type pushCampaignSendType = 'now' | 'scheduled' | 'recurring' | 'trigger'

type CappingProps = {
  period: string
  capping: number | ''
  id: 'new' | number
}

export const TimeIntervalUnitLabels = {
  s: 'second',
  m: 'minute',
  d: 'day',
  h: 'hour',
}
export type intervalUnit = keyof typeof TimeIntervalUnitLabels

type AgeProps = {
  inputValue: string
  seconds: number
  valid: boolean
  unit: intervalUnit
  value: number
  label: string
  fullText: string
}

export const AgeFactory = Immutable.Record<AgeProps>({
  inputValue: '',
  seconds: 0,
  valid: true,
  unit: 's',
  value: 0,
  label: '',
  fullText: '',
} as AgeProps)

export type AgeRecord = RecordOf<AgeProps>

type SdkProps = {
  kind: string
  icon: availableIcons
  version: string | null | undefined
  packageManagerMinVersion: string | null | undefined
  color: string
  legacy: boolean
  allowedPlatforms: Set<Platforms>
}

export const SdkFactory = Immutable.Record<SdkProps>({
  kind: 'iOS',
  icon: 'ios',
  version: '',
  packageManagerMinVersion: '',
  color: '#142840',
  legacy: false,
  allowedPlatforms: Immutable.Set(),
} as SdkProps)

export type SdkRecord = RecordOf<SdkProps>

type SdkFeatureProps = {
  supported: boolean
  nb: number
  pcent: number
}
export const SdkFeatureFactory = Immutable.Record<SdkFeatureProps>({
  supported: false,
  nb: 0,
  pcent: 0,
} as SdkFeatureProps)

export type SdkFeatureRecord = RecordOf<SdkFeatureProps>

export type SdkSupportsProps = {
  landing: SdkFeatureRecord
  inapp: SdkFeatureRecord
  banner: SdkFeatureRecord
  scrolling: SdkFeatureRecord
  gif: SdkFeatureRecord
  html: SdkFeatureRecord
  modal: SdkFeatureRecord
  image: SdkFeatureRecord
  webview: SdkFeatureRecord
}
export const SdkSupportsFactory = Immutable.Record<SdkSupportsProps>({
  landing: SdkFeatureFactory(),
  inapp: SdkFeatureFactory(),
  banner: SdkFeatureFactory(),
  scrolling: SdkFeatureFactory(),
  gif: SdkFeatureFactory(),
  html: SdkFeatureFactory(),
  modal: SdkFeatureFactory(),
  image: SdkFeatureFactory(),
  webview: SdkFeatureFactory(),
} as SdkSupportsProps)

export type SdkSupportsRecord = RecordOf<SdkSupportsProps>

export type Variant = 'a' | 'b'

type CampaignABProps = {
  enabled: boolean
  activeVariants: Set<Variant>
}
export const CampaignABFactory = Immutable.Record<CampaignABProps>({
  enabled: false,
  activeVariants: Immutable.Set(['a']),
} as CampaignABProps)

export type CampaignABRecord = RecordOf<CampaignABProps>

type CustomAudienceProps = {
  id: number
  name: string
  description: string
  idsCount: number
  estimate: number | null | undefined | 'error'
  partial: boolean
  deleted: boolean
  type: 'custom_ids' | 'advertising_ids' | 'install_ids'
  isIndexing: boolean
  updatedAt: Dayjs
}

export const CustomAudienceFactory = Immutable.Record<CustomAudienceProps>({
  id: 0,
  name: '',
  description: '',
  idsCount: 0,
  estimate: null,
  partial: false,
  deleted: false,
  type: 'custom_ids',
  isIndexing: false,
  updatedAt: dayjs(),
} as CustomAudienceProps)

export type CustomAudienceRecord = RecordOf<CustomAudienceProps>

export type CustomAudienceConfigProps = {
  sortBy: string
  sortOrder: 'dsc' | 'asc'
  page: number
}
type CustomAudienceStateProps = {
  creating: boolean
  customAudienceLoadingState: fetchingState
  entities: Map<string, CustomAudienceRecord>
  count: number
  idsPerPage: Map<number, List<string>>
  status: fetchingState
}

export const CustomAudienceStateFactory = Immutable.Record<CustomAudienceStateProps>({
  creating: false,
  customAudienceLoadingState: STATUS.INIT,
  entities: Immutable.Map(),
  count: 0,
  idsPerPage: Immutable.Map(),
  status: STATUS.INIT,
})

export type CustomAudienceStateRecord = RecordOf<CustomAudienceStateProps>

export type TriggerConfigProps = {
  hasExitEvent: boolean
  hasGrace: boolean
  hasCapping: boolean
  hasStart: boolean
  hasEnd: boolean
  enterEvent: string
  enterEventQuery: Record<any, any> | null | undefined
  pushTimer: AgeRecord
  pushTimerReference: string
  pushTimerMode: 'event' | 'before' | 'after'
  instanceId: string
  hasInstanceId: boolean
  resetTimerOnEvent: boolean
  exitEvents: List<{
    eventId: string
    query: Record<any, any> | null | undefined
  }>
  grace: AgeRecord
  capping: number
  start: Dayjs | null | undefined
  end: Dayjs | null | undefined
  delayMode: 'TIMER' | 'IMMEDIATE'
  isHackForMultiStep: boolean
}
export const TriggerConfigFactory = Immutable.Record<TriggerConfigProps>({
  enterEvent: '',
  hasInstanceId: false,
  enterEventQuery: null,
  instanceId: '',
  pushTimer: AgeFactory({
    inputValue: '1',
    seconds: 3600,
    valid: true,
    unit: 'h',
    label: 'hour',
    fullText: '1 hour',
  }),
  pushTimerReference: '',
  pushTimerMode: 'event',
  resetTimerOnEvent: true,
  hasExitEvent: true,
  hasStart: false,
  hasEnd: false,
  hasGrace: true,
  hasCapping: false,
  exitEvents: Immutable.List().push({ eventId: '', query: null }),
  grace: AgeFactory({
    inputValue: '1',
    seconds: 3600,
    valid: true,
    unit: 'h',
    label: 'hour',
    fullText: '1 hour',
  }),
  capping: 0,
  start: null,
  end: null,
  delayMode: DELAY_MODE.TIMER,
  isHackForMultiStep: false,
} as TriggerConfigProps)
export type TriggerConfigRecord = RecordOf<TriggerConfigProps>

export type AbTestedThemeCodeProps = Readonly<{
  a: string | null | undefined
  b: string | null | undefined
}>

export const AbTestedThemeCodeFactory = Immutable.Record<AbTestedThemeCodeProps>({
  a: null,
  b: null,
} as AbTestedThemeCodeProps)

export type AbTestedThemeCodeRecord = RecordOf<AbTestedThemeCodeProps>

type CampaignProps = {
  id: string
  appId: number
  channels: Set<Channel>
  _id: string
  devOnly: boolean
  api: boolean
  archived: boolean
  stats: CampaignDataRecord
  token: string
  customAudiences: Set<CustomAudienceRecord>
  type: campaignType
  triggerConfig: TriggerConfigRecord
  inAppPriority: inAppPriority
  trigger: string
  triggerLabel: string | null | undefined
  defaultMessage: string
  defaultTitle: string
  grace: string
  data: ContentStateRecord
  name: string
  state: campaignStateType
  targeting: any
  variantsThemeCodes: AbTestedThemeCodeRecord
  inappJustInTime: boolean
  start: Dayjs | null | undefined
  end: Dayjs | null | undefined
  repeatFrequency: number
  repeatUnit: repeatUnitType
  sendType: pushCampaignSendType
  loading: boolean
  saving: boolean
  tzAware: boolean
  abtesting: CampaignABRecord
  pickedLabels: Set<CappingCategoryRecord>
  hasLanding: boolean
  capping: number | null | undefined
  gracePeriod: number
  translations: Immutable.OrderedSet<string>
  schedulingType: schedulingType
}

export const CampaignFactory = Immutable.Record<CampaignProps>(
  {
    id: '',
    appId: 0,
    channels: Immutable.Set(),
    _id: 'new',
    devOnly: false,
    api: false,
    archived: false,
    inappJustInTime: false,
    stats: CampaignDataFactory(),
    token: 'new',
    customAudiences: Immutable.Set(),
    type: 'push',
    triggerConfig: TriggerConfigFactory(),
    inAppPriority: 'standard',
    trigger: 'next_session',
    triggerLabel: null,
    defaultMessage: '',
    defaultTitle: '',
    grace: '',
    data: ContentStateFactory(),
    name: '',
    state: 'NEW',
    targeting: undefined,
    variantsThemeCodes: AbTestedThemeCodeFactory(),
    start: null,
    end: null,
    saving: false,
    repeatFrequency: 0,
    repeatUnit: 'DAILY',
    sendType: 'scheduled',
    loading: false,
    tzAware: true,
    abtesting: CampaignABFactory(),
    pickedLabels: Immutable.OrderedSet(),
    hasLanding: false,
    capping: null,
    gracePeriod: 0,
    translations: Immutable.OrderedSet(),
    schedulingType: 'campaigns',
  } as CampaignProps,
  'Campaign'
)

export type CampaignRecord = RecordOf<CampaignProps>

type CampaignFilterProps = {
  category: 'status' | 'sources' | 'when' | 'channels'
  name: string
  label: string
}
export const CampaignFilterFactory = Immutable.Record<CampaignFilterProps>({
  category: 'status',
  name: '',
  label: '',
} as CampaignFilterProps)

export type CampaignFilterRecord = RecordOf<CampaignFilterProps>

type DateRangeFilterProps = {
  from: Dayjs
  to: Dayjs
}
export const DateRangeFilterFactory = Immutable.Record<DateRangeFilterProps>({
  from: dayjs(),
  to: dayjs(),
} as DateRangeFilterProps)

export type DateRangeFilterRecord = RecordOf<DateRangeFilterProps>

type CampaignActiveFiltersProps = {
  query: string
  commons: Immutable.Set<CampaignFilterRecord>
  labels: Immutable.Set<number | 'new'>
  dateRange: DateRangeFilterRecord | null | undefined
}
export const CampaignActiveFiltersFactory = Immutable.Record<CampaignActiveFiltersProps>({
  query: '',
  commons: Immutable.Set(),
  labels: Immutable.Set(),
  dateRange: null,
})
export type CampaignActiveFiltersRecord = RecordOf<CampaignActiveFiltersProps>

export type CampaignConfigProps = {
  type: campaignType
  schedulingType: schedulingType
  activeLanguageId: string | null | undefined
  abtesting: Variant
  previewSourceKind: 'custom_id' | 'user_id'
  previewSourceProfileId: string
  previewSourceValue: string
  previewState: fetchingState
  advancedPaneOpened: boolean
  focusedLandingField: string
  editing: string
  queryParsed: boolean
  contentParsed: boolean
  sortBy: string
  sortOrder: 'dsc' | 'asc'
  page: number
  filters: CampaignActiveFiltersRecord
  filtersFilled: boolean
}
export const CampaignConfigFactory = Immutable.Record<CampaignConfigProps>({
  type: 'in-app',
  schedulingType: 'automations',
  activeLanguageId: 'default',
  abtesting: 'a',
  advancedPaneOpened: false,
  focusedLandingField: '',
  previewSourceKind: 'user_id',
  previewSourceProfileId: '',
  previewSourceValue: '',
  previewState: 'INIT',
  editing: 'new',
  queryParsed: true,
  contentParsed: false,
  sortBy: 'id',
  sortOrder: 'dsc',
  filters: CampaignActiveFiltersFactory(),
  filtersFilled: false,
  page: 1,
} as CampaignConfigProps)
export type CampaignConfigRecord = RecordOf<CampaignConfigProps>

type CampaignStateProps = {
  config: CampaignConfigRecord
  countTotal: number
  countFiltered: number
  loadingCount: boolean
  loadingTest: boolean
  replication: Immutable.List<any>
  loading: boolean
  pagerLoading: boolean
  entities: Immutable.Map<string, CampaignRecord>
  idsPerPage: Immutable.Map<number, Immutable.List<string>>
  failure: boolean
  data: Immutable.List<any>
  exportLoaded: boolean
}

export const CampaignStateFactory = Immutable.Record<CampaignStateProps>({
  config: CampaignConfigFactory(),
  countTotal: 0,
  countFiltered: 0,
  loadingCount: true,
  loadingTest: false,
  replication: Immutable.List(),
  loading: true,
  pagerLoading: false,
  entities: Immutable.Map([['new', CampaignFactory()]]),
  idsPerPage: Immutable.Map(),
  failure: false,
  data: Immutable.List(),
  exportLoaded: false,
} as CampaignStateProps)

export type CampaignStateRecord = RecordOf<CampaignStateProps>
type ReplicationProps = {
  appId: number | string
  companyId: number | null | undefined
  platform: availableIcons | null | undefined
  name: string
  icon: string
  kind: campaignType
  error: string
  loading: boolean
  token: string
  schedulingType: schedulingType
}
export const ReplicationFactory = Immutable.Record<ReplicationProps>({
  appId: '',
  companyId: null,
  platform: undefined,
  name: '',
  icon: '',
  kind: 'push',
  error: '',
  loading: true,
  pagerLoading: false,
  token: '',
  schedulingType: 'campaigns',
} as ReplicationProps)

export type ReplicationRecord = RecordOf<ReplicationProps>

type LanguageProps = {
  value: string
  label: string
  pretty: string
  nb: number
  valid: boolean
  tokens: number
  content: any
  pcent: string
}

export const LanguageFactory = Immutable.Record<LanguageProps>({
  value: '',
  label: '',
  pretty: '',
  nb: 0,
  content: undefined,
  valid: false,
  tokens: 0,
  pcent: '-',
} as LanguageProps)

export type LanguageRecord = RecordOf<LanguageProps>

type RegionProps = {
  value: string
  label: string
  pretty: string
  nb: number
  tokens: number
  pcent: string
}
export type RegionRecord = RecordOf<RegionProps>

export const RegionFactory = Immutable.Record<RegionProps>({
  value: '',
  label: '',
  pretty: '',
  nb: 0,
  tokens: 0,
  pcent: '-',
} as RegionProps)

type FieldProps = {
  disabled: boolean
  shown: boolean
  label: string
  data: string
}

export const FieldFactory = Immutable.Record<FieldProps>({
  disabled: false,
  shown: false,
  label: '',
  data: '',
} as FieldProps)

export type FieldRecord = RecordOf<FieldProps>

export const CappingFactory = Immutable.Record<CappingProps>({
  period: '',
  capping: '',
  id: 'new',
} as CappingProps)

export type CappingRecord = RecordOf<CappingProps>

type CappingCategoryProps = {
  id: 'new' | number
  count: number
  code: string
  name: string
  enabled: boolean
  internal: boolean
  dirty: boolean
  cappings: List<CappingRecord>
}
export const CappingCategoryFactory = Immutable.Record<CappingCategoryProps>({
  id: 'new',
  code: '',
  count: 0,
  name: '',
  enabled: true,
  internal: false,
  dirty: false,
  cappings: Immutable.List<CappingRecord>(),
} as CappingCategoryProps)

export type CappingCategoryRecord = RecordOf<CappingCategoryProps>

// ===================================
// PUSH CONFIG
// ===================================
type WnsConfigProps = {
  packageSID: string
  clientSecret: string
  loading: boolean
  checked: boolean
}
export const WnsConfigFactory = Immutable.Record<WnsConfigProps>({
  packageSID: '',
  clientSecret: '',
  loading: false,
  checked: false,
} as WnsConfigProps)

export type WnsConfigRecord = RecordOf<WnsConfigProps>

type VAPIDConfigProps = {
  publicKey: string
  privateKey: string
  subdomain: string
  loading: boolean
}
export const VAPIDConfigFactory = Immutable.Record<VAPIDConfigProps>({
  publicKey: '',
  loading: false,
  privateKey: '',
  subdomain: '',
} as VAPIDConfigProps)

export type VAPIDConfigRecord = RecordOf<VAPIDConfigProps>

export type SafariWebsitesProps = {
  id: string
  domain: string
  certificateName: string
  certificateExpirationDate: string
  uploadedAt: string
}
export const SafariWebsitesFactory = Immutable.Record<SafariWebsitesProps>({
  id: '',
  domain: '',
  certificateName: '',
  certificateExpirationDate: '',
  uploadedAt: '',
} as SafariWebsitesProps)

export type SafariWebsitesRecord = RecordOf<SafariWebsitesProps>

type GCMConfigProps = {
  senderId: string
  serverApiKey: string
}
export const GCMConfigFactory = Immutable.Record<GCMConfigProps>({
  senderId: '',
  serverApiKey: '',
} as GCMConfigProps)

export type GCMConfigRecord = RecordOf<GCMConfigProps>

type P12ConfigProps = {
  certificateName: string
  uploadedOn: Dayjs
  expireAt: Dayjs | null | undefined
  valid: boolean
  error: string | null | undefined
}
export const P12ConfigFactory = Immutable.Record<P12ConfigProps>({
  certificateName: '',
  uploadedOn: dayjs(),
  expireAt: undefined,
  valid: false,
  error: undefined,
} as P12ConfigProps)

export type P12ConfigRecord = RecordOf<P12ConfigProps>

type P8ConfigProps = {
  valid: boolean
  uploadedOn: Dayjs
  topic: string
  teamId: string
  keyId: string
  privateKey: string
  error: string | null | undefined
}

export const P8ConfigFactory = Immutable.Record<P8ConfigProps>({
  topic: '',
  teamId: '',
  uploadedOn: dayjs(),
  keyId: '',
  privateKey: '',
  valid: false,
  error: undefined,
} as P8ConfigProps)

export type P8ConfigRecord = RecordOf<P8ConfigProps>

type HMSConfigProps = {
  appId: string
  appKey: string
  callbackUrlToken: string
  callbackUsername: string
  callbackKey: string
}
export const HMSConfigFactory = Immutable.Record<HMSConfigProps>({
  appId: '',
  appKey: '',
  callbackUrlToken: '',
  callbackUsername: '',
  callbackKey: '',
} as HMSConfigProps)

export type HMSConfigRecord = RecordOf<HMSConfigProps>

type PushConfigProps = {
  loading: boolean
  defaultPriority: 'HIGH' | 'NORMAL'
  defaultTtl: number | null | undefined
  defaultCollapseKey: string
  maxRate: number | null | undefined
  loadingDevOrigins: boolean
  p12: P12ConfigRecord | null | undefined
  p8: P8ConfigRecord | null | undefined
  gcm: List<GCMConfigRecord>
  fcm: List<FCMConfigRecord>
  vapid: VAPIDConfigRecord | null | undefined
  wns: WnsConfigRecord | null | undefined
  hms: HMSConfigRecord | null | undefined
  allowedDevOrigins: Array<string>
  safariWebsiteName: string
  safariWebsiteIcon: string
  androidSmallIcon: string
  safariWebsites: List<SafariWebsitesRecord>
  safariOpenTracking: boolean
}

export const PushConfigFactory = Immutable.Record<PushConfigProps>({
  loading: false,
  defaultPriority: 'HIGH',
  maxRate: null,
  defaultTtl: undefined,
  defaultCollapseKey: 'default',
  p12: undefined,
  p8: undefined,
  gcm: Immutable.List(),
  fcm: Immutable.List(),
  vapid: undefined,
  wns: undefined,
  hms: undefined,
  loadingDevOrigins: false,
  allowedDevOrigins: [],
  safariWebsiteName: '',
  safariWebsiteIcon: '',
  androidSmallIcon: '',
  safariWebsites: Immutable.List(),
  safariOpenTracking: true,
} as PushConfigProps)

export type PushConfigRecord = RecordOf<PushConfigProps>

export type openRateAlgEnum = 'ACCURATE' | 'ACCURATE_DIRECT' | 'LEGACY' | 'LEGACY_DIRECT'
export type fetchingState = 'INIT' | 'LOADING' | 'LOADED' | 'ERROR'

type AppProps = {
  id: number
  projectKey: string | null | undefined
  demo: boolean
  bundleId: string
  webpushAuth: string | null | undefined
  openRateAlg: openRateAlgEnum
  apiKey: string
  devApiKey: string | null | undefined
  name: string
  loadingState: fetchingState
  labelsCountLoadingState: fetchingState
  archived: boolean
  platform: Platforms
  inboxSecret: string | null | undefined
  defaultLanguageId: string | null | undefined
  created: Dayjs | null | undefined
  updated: Dayjs | null | undefined
  pushConfig: PushConfigRecord
  icon: string
  lastPushTestProd: boolean
  sdk: string
  features: Set<FeatureCode>
  companyId?: number
  pushImported: boolean
  status: string
  pushToken: string
  validPushConfig: boolean
  ttlRetargeting: number | null | undefined
  ttlJourney: number | null | undefined
  cappingCategories: List<CappingCategoryRecord>
  safariSetupValid: boolean
  lastAccess: Dayjs | null
  showIntegrate: boolean
  logs: List<EntityLogRecord>
}

export const AppFactory = Immutable.Record<AppProps>({
  id: 0,
  projectKey: null,
  bundleId: '',
  loadingState: STATUS.INIT,
  labelsCountLoadingState: STATUS.INIT,
  openRateAlg: 'ACCURATE',
  webpushAuth: undefined,
  created: undefined,
  updated: undefined,
  demo: false,
  archived: false,
  apiKey: '',
  devApiKey: undefined,
  name: '',
  platform: '',
  inboxSecret: undefined,
  pushConfig: PushConfigFactory(),
  defaultLanguageId: null,
  icon: '',
  lastPushTestProd: true,
  sdk: '',
  features: Immutable.Set(),
  companyId: undefined,
  pushImported: false,
  status: 'free',
  pushToken: '',
  validPushConfig: false,
  ttlRetargeting: null,
  ttlJourney: null,
  cappingCategories: Immutable.List(),
  logs: Immutable.List(),
  lastAccess: null,
  showIntegrate: false,
  safariSetupValid: false,
} as AppProps)

export type AppRecord = RecordOf<AppProps>

type GDPRRecordProps = {
  name: string
  address: string
  address2: string
  city: string
  zip: string
  country: string
  dpoName: string
  dpoEmail: string
  categories: Set<string>
  disabled: boolean
  external: boolean
  created: Dayjs | null | undefined
  updated: Dayjs | null | undefined
  companyId: number | null | undefined
}

export const GDPRRecordFactory = Immutable.Record<GDPRRecordProps>({
  disabled: false,
  external: false,
  name: '',
  address: '',
  address2: '',
  city: '',
  zip: '',
  country: '',
  dpoName: '',
  dpoEmail: '',
  categories: Immutable.Set(),
  created: null,
  updated: null,
  companyId: null,
} as GDPRRecordProps)

export type GDPRRecord = RecordOf<GDPRRecordProps>

export type SAMLProps = {
  loginUrl: string | null | undefined
  certificate: string | null | undefined
  entityId: string | null | undefined
  isEnabled: boolean
  certificateExpiration: Dayjs | null | undefined
}

export const SAMLRecordFactory = Immutable.Record<SAMLProps>({
  loginUrl: '',
  certificate: '',
  entityId: '',
  isEnabled: false,
  certificateExpiration: null,
} as SAMLProps)

export type SAMLRecord = RecordOf<SAMLProps>

type CompanyProps = {
  id: number
  deletedAt: Dayjs | null | undefined
  externalId: string
  enforce2FA: boolean
  name: string
  avatarUrl: string | null | undefined
  loading: boolean
  hasEditorialDashboard: boolean
  plan: string | null | undefined
  disabledFeaturesCode: OrderedSet<FeatureCode>
  additionalFeaturesCode: OrderedSet<FeatureCode>
  planFeaturesCode: OrderedSet<FeatureCode>
  overridedSeats: number | null | undefined
  seats: number
  usedSeats: number
  webpushEnabled: boolean
  users: OrderedSet<number>
  restKey?: string
  gdpr: GDPRRecord
  sfid: string | null | undefined
  billing: BillingRecord
  volumes: VolumesRecord
  saml: SAMLRecord | null | undefined
  logs: List<EntityLogRecord>
}
export const CompanyFactory = Immutable.Record<CompanyProps>({
  id: 0,
  deletedAt: null,
  externalId: '',
  enforce2FA: false,
  hasEditorialDashboard: false,
  name: '',
  avatarUrl: null,
  plan: null,
  disabledFeaturesCode: Immutable.OrderedSet(),
  additionalFeaturesCode: Immutable.OrderedSet(),
  planFeaturesCode: Immutable.OrderedSet(),
  users: Immutable.OrderedSet(),
  overridedSeats: null,
  seats: 0,
  usedSeats: 0,
  webpushEnabled: false,
  restKey: undefined,
  loading: false,
  sfid: undefined,
  gdpr: GDPRRecordFactory(),
  billing: BillingFactory(),
  volumes: VolumesFactory(),
  saml: SAMLRecordFactory(),
  logs: Immutable.List(),
} as CompanyProps)

export type CompanyRecord = RecordOf<CompanyProps>

type TestDeviceProps = {
  loading: boolean
  loadingFor: 'save' | 'delete' | 'send' | ''
  dirty: boolean
  name: string
  kind: 'custom_id' | 'advertising_id' | 'installation_id' | 'token'
  value: string
  distribution: boolean
  id: number
  appId: number
  created: Dayjs | null | undefined
  updated: Dayjs | null | undefined
}

export const TestDeviceFactory = Immutable.Record<TestDeviceProps>({
  loading: false,
  loadingFor: '',
  dirty: false,
  name: '',
  kind: 'installation_id',
  value: '',
  distribution: true,
  id: 0,
  appId: 0,
  created: null,
  updated: null,
} as TestDeviceProps)

export type TestDeviceRecord = RecordOf<TestDeviceProps>

export type EventDataAttributeProps = {
  type:
    | 'URL'
    | 'STRING'
    | 'BOOLEAN'
    | 'INTEGER'
    | 'DATE'
    | '__LABEL__'
    | '__TAG__'
    | 'FLOAT'
    | 'UNKNOWN'
  kind: 'attribute' | 'tag' | 'label'
  name: string
  icon: availableIcons
  attributeKind?: 'PRIMITIVE' | 'ARRAY' | 'OBJECT'
}
export const EventDataAttributeFactory = Immutable.Record<EventDataAttributeProps>({
  type: 'STRING',
  kind: 'label',
  name: '',
  icon: 'text',
  attributeKind: undefined,
} as EventDataAttributeProps)

export type EventDataAttributeRecord = RecordOf<EventDataAttributeProps>

export type AttributeProp = {
  loading: boolean
  hidden: boolean
  lastCount: Dayjs | null | undefined
  lastUpdate: Dayjs | null | undefined
  name: string | null | undefined
  label: string
  id: string
  icon: availableIcons
  cleanId: string
  overridenType: string | null | undefined
  lastReceivedType: string
  type: string
  native: boolean
  category:
    | 'attribute'
    | 'event'
    | 'tag'
    | 'user_attribute'
    | 'user_tag'
    | 'user_event'
    | 'batch_attribute'
    | 'batch_event'
  allowedKeys: OrderedSet<EventDataAttributeRecord>
}

export const AttributeFactory = Immutable.Record<AttributeProp>({
  loading: false,
  hidden: false,
  lastCount: null,
  lastUpdate: null,
  name: null,
  label: '',
  id: '',
  icon: 'default',
  cleanId: '',
  overridenType: null,
  lastReceivedType: 'STRING',
  type: 'STRING',
  native: false,
  category: 'attribute',
  allowedKeys: Immutable.OrderedSet(),
} as AttributeProp)

export type AttributeRecord = RecordOf<AttributeProp>

export type AttributeCategory = {
  id: 'native' | 'custom' | 'user' | null
  count: number
  active: boolean
  icon: availableIcons
  label: string
  locked: boolean
}

type AttributeStateConfigProps = {
  attributeLoadingState: fetchingState
  valuesLoaded: boolean
  devMode: boolean
  filterTerm: string
  filterCategory: string | null | undefined
  profileDataMode: boolean
}

export const AttributeStateConfigFactory = Immutable.Record<AttributeStateConfigProps>({
  attributeLoadingState: STATUS.INIT,
  valuesLoaded: false,
  devMode: false,
  filterTerm: '',
  filterCategory: null,
  profileDataMode: false,
})

export type AttributeStateConfigRecord = RecordOf<AttributeStateConfigProps>

type AttributeStateProps = {
  config: AttributeStateConfigRecord
  entities: OrderedMap<string, AttributeRecord>
  profileDataMode: boolean
}

export const AttributeStateFactory = Immutable.Record<AttributeStateProps>({
  config: AttributeStateConfigFactory(),
  entities: Immutable.OrderedMap(),
  profileDataMode: false,
} as AttributeStateProps)

export type AttributeStateRecord = RecordOf<AttributeStateProps>

type AppStateProps = {
  current: AppRecord
  entities: List<AppRecord>
  sdks: List<SdkRecord>
  integratePopin: boolean
  loading: boolean
  loaded: boolean
  user: UserRecord
  newAppBackUrl: string
}

export const AppStateFactory = Immutable.Record<AppStateProps>({
  current: AppFactory(),
  entities: Immutable.List(),
  sdks: Immutable.List().push(
    SdkFactory({
      icon: 'ios',
      kind: 'iOS',
      allowedPlatforms: Immutable.Set(['ios']),
      color: '#333333',
    }),
    SdkFactory({
      icon: 'android',
      kind: 'Android',
      allowedPlatforms: Immutable.Set(['android']),
      color: '#7AC162',
    }),
    SdkFactory({
      icon: 'windows',
      kind: 'Windows',
      allowedPlatforms: Immutable.Set(['windows']),
      color: '#1666B4',
    }),
    SdkFactory({
      icon: 'browser',
      kind: 'Web',
      allowedPlatforms: Immutable.Set(['webpush']),
      color: '#22A0DC',
    }),
    SdkFactory({
      icon: 'react-native',
      kind: 'React Native',
      allowedPlatforms: Immutable.Set(['android', 'ios']),
      color: '#61dafb',
    }),
    SdkFactory({
      icon: 'cordova',
      kind: 'Cordova',
      allowedPlatforms: Immutable.Set(['android', 'ios']),
      color: '#333333',
    }),
    SdkFactory({
      icon: 'air',
      kind: 'Air',
      allowedPlatforms: Immutable.Set(['android', 'ios']),
      color: '#DE383D',
      legacy: true,
    }),
    SdkFactory({
      kind: 'Unity',
      allowedPlatforms: Immutable.Set(['android', 'ios']),
      color: '#000',
      legacy: true,
    }),
    SdkFactory({
      icon: 'flutter',
      kind: 'Flutter',
      allowedPlatforms: Immutable.Set(['ios', 'android']),
      color: '#0175CE',
    })
  ),
  loading: false,
  loaded: false,
  integratePopin: window.location.href.indexOf('popin=integrate') !== -1,
  newAppBackUrl: '',
  user: UserFactory(),
} as AppStateProps)

export type AppStateRecord = RecordOf<AppStateProps>

type ClusterDataProps = {
  installs: number | null | undefined
  tokens: number | null | undefined
  tokenRate: number | null | undefined
  optinRate: number | null | undefined
  notifsOn: number | null | undefined
}

export const ClusterDataFactory = Immutable.Record<ClusterDataProps>({
  installs: null,
  tokens: null,
  tokenRate: null,
  optinRate: null,
  notifsOn: null,
} as ClusterDataProps)

export type ClusterDataRecord = RecordOf<ClusterDataProps>

type ClusterRatioProps = {
  mode: 'tokenRate' | 'optinRate'
  desc: string
}

export const ClusterRatioFactory = Immutable.Record<ClusterRatioProps>({
  mode: 'tokenRate',
  desc: '% algorithm = opt-ins / installs',
} as ClusterRatioProps)

export type ClusterRatioRecord = RecordOf<ClusterRatioProps>

type ClusterProps = {
  name: string
  code: string
  desc: string | null | undefined
  color: string
  related: string | null | undefined
  active: boolean
  all: ClusterDataRecord
  alive: ClusterDataRecord
}

export const ClusterFactory = Immutable.Record<ClusterProps>({
  name: '',
  color: '',
  active: false,
  code: '',
  desc: '',
  related: '',
  all: ClusterDataFactory(),
  alive: ClusterDataFactory(),
} as ClusterProps)

export type ClusterRecord = RecordOf<ClusterProps>

type ClusterStateProps = {
  status: fetchingState
  loading: boolean
  showAlive: boolean
  all: ClusterDataRecord
  alive: ClusterDataRecord
  N: ClusterRecord
  E: ClusterRecord
  D: ClusterRecord
  Du: ClusterRecord
  Er: ClusterRecord
  Np: ClusterRecord
  Dp: ClusterRecord
  I: ClusterRecord
}

export const ClusterStateFactory = Immutable.Record<ClusterStateProps>({
  status: STATUS.INIT,
  loading: false,
  showAlive: false,
  all: ClusterDataFactory(),
  alive: ClusterDataFactory(),
  N: ClusterFactory({
    code: 'N',
    color: colors.fillTech,
    name: 'New',
    related: 'Np',
    desc: "Newcomers to your app that haven't yet established themselves as engaged users",
  }),
  E: ClusterFactory({
    code: 'E',
    color: colorsLegacy.good.shade.s1,
    name: 'Engaged',
    related: 'Er',
    desc: 'Regular users of your app that are actively using it.',
  }),
  D: ClusterFactory({
    code: 'D',
    color: colors.textWarning,
    name: 'Dormant',
    related: 'Dp',
    desc: 'Users who were engaged at one point, but have not launched your app for a long time.',
  }),
  Du: ClusterFactory({
    code: 'Du',
    color: colors.textDanger,
    name: 'One time',
    related: 'Du',
    desc: 'Users who did not come back after they first launched your app.',
  }),
  Np: ClusterFactory({
    code: 'Np',
    color: '#EFC954',
    name: 'New Promising',
    desc: 'Users who are about to become engaged.',
  }),
  Er: ClusterFactory({
    code: 'Er',
    color: '#67B758',
    name: 'Engaged risky',
    desc: 'Users with a high risk of becoming dormant.',
  }),
  Dp: ClusterFactory({
    code: 'Dp',
    color: '#C3562E',
    name: 'Dormant Promising',
    desc: 'Users who are about to become engaged.',
  }),
  I: ClusterFactory({
    code: 'I',
    color: colors.fillFeature,
    name: 'Imported',
    desc: 'Imported push tokens. These users are progressively analysed and transferred to other segments.',
  }),
} as ClusterStateProps)

export type ClusterStateRecord = RecordOf<ClusterStateProps>

type TemplateResultProps = {
  installId: string
  result: string
}

export const TemplateResultFactory = Immutable.Record<TemplateResultProps>({
  installId: '',
  result: '',
} as TemplateResultProps)

export type TemplateResultRecord = RecordOf<TemplateResultProps>

type TemplateProps = {
  text: string
  parsing: boolean
  error: string | null | undefined
  results: List<TemplateResultRecord>
}

export const TemplateFactory = Immutable.Record<TemplateProps>({
  text: '',
  parsing: false,
  error: '',
  results: Immutable.List(),
} as TemplateProps)

export type TemplateRecord = RecordOf<TemplateProps>

type EstimateTokensProps = {
  count: number
  biggestTimezone: number
  optIns: number
}
export const EstimateTokensFactory = Immutable.Record<EstimateTokensProps>({
  count: 0,
  biggestTimezone: 0,
  optIns: 0,
} as EstimateTokensProps)

export type EstimateTokensRecord = RecordOf<EstimateTokensProps>

type EstimateProps = {
  loading: boolean
  error: boolean
  installs: number
  matchingInstalls: number
  all: EstimateTokensRecord
  matching: EstimateTokensRecord
}

export const EstimateFactory = Immutable.Record<EstimateProps>({
  installs: 0,
  matchingInstalls: 0,
  error: false,
  loading: false,
  all: EstimateTokensFactory(),
  matching: EstimateTokensFactory(),
} as EstimateProps)

export type EstimateRecord = RecordOf<EstimateProps>

export type AttributeValueProps = {
  value: string | number | Dayjs
  pretty: string | boolean
  pcent: string
  installs: number
  tokens: number
  opacity: number | null | undefined
}
export const AttributeValueFactory = Immutable.Record<AttributeValueProps>({
  value: '',
  pretty: '',
  pcent: '',
  installs: 0,
  tokens: 0,
  opacity: 1,
} as AttributeValueProps)

export type AttributeValueRecord = RecordOf<AttributeValueProps>

type AttributeValuesListProps = {
  loading: boolean
  restricted: boolean
  canDisplayLabels: boolean
  count: number
  page: number
  mode: string
  values: Map<string, List<AttributeValueRecord>>
  shown: List<AttributeValueRecord>
}
export const AttributeValuesListFactory = Immutable.Record<AttributeValuesListProps>({
  loading: false,
  restricted: false,
  canDisplayLabels: false,
  count: 0,
  page: 1,
  mode: '__default',
  values: Immutable.Map(),
  shown: Immutable.List(),
})
export type AttributeValuesListRecord = RecordOf<AttributeValuesListProps>

export type AttributeValueState = Map<string, AttributeValuesListRecord>

export type State = RootState
export type Extract<T> = (state: State) => T
export type GetState = () => State
export type AppDispatch = ThunkDispatch<RootState, InjectedApi, Action>

export type BatchChoice = {
  value: string
  label: string
  icon?: availableIcons
  lock?: false | string
  empty?: false | string
}
export type ReduxAction<T, P> = Readonly<{
  type: T
  payload: P
}>
export type DispatchExtraBoundFn<P> = ThunkAction<P, State, InjectedApi, Action>

export type DispatchBoundFn<P> = ThunkAction<P, State, InjectedApi, Action>
export type DispatchOnlyBoundFn<P> = ThunkAction<P, State, InjectedApi, Action>
