import styled, { css } from 'styled-components'

import { Ellipsis } from 'components/styled/text'
import * as colors from 'components/styled/tokens/colors'
import * as schemes from 'components/styled/tokens/schemes'

type CodeContainerProps = {
  size: 'normal' | 'small'
  disabledCopy?: boolean
  accessNotAllowed?: boolean
}
export const CodeContainer = styled.code<CodeContainerProps>`
  position: relative;
  display: inline-grid;
  height: ${props => (props.size === 'small' ? 18 : 24)}px;
  padding: ${props => (props.size === 'small' ? '1px 3px' : '4px 6px')};
  font-size: 1em;
  line-height: 16px;
  background: ${props =>
    props.accessNotAllowed ? 'rgba(172, 177, 185, 0.12);' : colors.opacifyTech};

  span {
    display: inline-block;
    color: ${props => (props.accessNotAllowed ? '#AEB3B9' : colors.textTech)};
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-wrap: normal;

    &::selection {
      background-color: rgba(14, 185, 239, 0.2);
    }
  }

  &:hover {
    cursor: ${props => (props.disabledCopy ? 'initial' : 'pointer')};
  }
`
type HideableCodeContainerProps = {
  revelead?: boolean
}
export const HideableCodeContainer = styled(CodeContainer)<HideableCodeContainerProps>`
  display: flex;
  align-items: center;

  ${props =>
    props.revelead
      ? css`
          background-color: ${colors.opacifyTech};
          border: 1px dashed transparent;

          ${Ellipsis} {
            color: ${colors.textTech};
          }
        `
      : css`
          background-color: ${colors.fillBackground};
          border: 1px dashed ${schemes.darklucent['30']};
          border-radius: 6px;

          ${Ellipsis} {
            color: ${colors.textDisabled};
            filter: blur(3px);
          }
        `}
`
