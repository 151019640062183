import styled, { css } from 'styled-components'

import { Button } from 'components/common/button'
import * as colors from 'components/styled/tokens/colors'
import * as shadows from 'components/styled/tokens/shadows'

export type ToolbarProps = {
  vertical?: boolean
}
export const Toolbar = styled.div<ToolbarProps>`
  display: flex;
  gap: 4px;
  box-shadow: ${shadows.block};
  border-radius: 8px;
  backdrop-filter: blur(4px);
  background: white;
  height: 44px;
  padding: 4px;
  border: 1px solid ${colors.stroke};

  ${Button} {
    height: 36px;
    ${({ vertical }) =>
      vertical &&
      css`
        width: 100%;
      `};
  }

  align-items: center;
  ${({ vertical }) =>
    vertical
      ? css`
          height: fit-content;
          flex-direction: column;
        `
      : css`
          width: fit-content;
          align-items: center;
        `};
`

export const ToolbarDivider = styled.hr<ToolbarProps>`
  background: ${colors.stroke};
  margin: 0;

  ${({ vertical }) =>
    vertical
      ? css`
          width: 16px;
          height: 1px;
        `
      : css`
          height: 16px;
          width: 1px;
        `};
`
