import dayjs, { type Dayjs } from 'dayjs'
import Immutable, { type RecordOf } from 'immutable'

import { type fetchingState } from 'com.batch.redux/_records'

export type AllowedAudienceType = 'custom_ids' | 'advertising_ids' | 'install_ids'

type ProfileAudienceProps = {
  name: string
  displayName: string
  type: AllowedAudienceType
  updated: Dayjs
  created: Dayjs
  nbIdentifier: number
  loading: fetchingState
}

export const ProfileAudienceFactory = Immutable.Record<ProfileAudienceProps>({
  name: '',
  displayName: '',
  type: 'custom_ids',
  created: dayjs(),
  updated: dayjs(),
  nbIdentifier: 0,
  loading: 'LOADED',
} as ProfileAudienceProps)
export type ProfileAudienceRecord = RecordOf<ProfileAudienceProps>
