import styled, { css } from 'styled-components'

import { Button } from 'components/common/button'
import { colors } from 'components/styled/tokens'

export const Separator = styled.hr`
  width: 1px;
  height: 22px;
  border-radius: 1px;
  background-color: ${colors.strokeDark};
`

export const NavbarMenu = styled.div`
  display: flex;
  align-items: center;
`

export const NavbarAccountContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 14px;
  background-color: ${colors.fillAction};
  border-bottom: 1px solid ${colors.stroke};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.02);

  ${Button} {
    border-radius: 8px;
  }

  .navbar-logo {
    display: block;

    svg {
      height: 34px;
    }
  }

  ${Separator} {
    background-color: ${colors.stroke};
  }
`

export const RedBadge = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  right: 9px;
  z-index: 1;
  top: 12px;
  border-radius: 50px;
  background: #de4960;
`
type NavbarContainerProps = {
  $breakpoint: 'small' | 'large' | 'medium'
  $project?: boolean
}
export const NavbarContainer = styled.nav<NavbarContainerProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 64px;
  font-size: 14px;
  background: ${colors.fillAction};
  padding: 0 16px;

  ${props =>
    props.$project &&
    css`
      ${Button} {
        border-radius: 8px;
      }
    `}
  .navbar-navigation {
    flex: 1 1 auto;
  }

  .styled-react-to-breakpoint {
    ${Button} {
      height: 44px;
      margin-right: 12px;
    }

    ${props =>
      props.$breakpoint !== 'large' &&
      css`
        .styled-hide-on-medium,
        .styled-hide-on-small {
          display: none;
        }

        ${props.$breakpoint === 'small' &&
        css`
          .styled-hide-on-small {
            display: none;
          }
        `}
        ${Button} {
          min-width: 48px;
          margin-right: 4px;
          padding: 0;
          display: flex;

          i {
            margin: 0;
          }

          ${props.$breakpoint === 'small' &&
          css`
            min-width: 28px;
            height: 28px;

            i {
              font-size: 12px;
            }
          `}
        }
      `}
  }
`
