import Immutable, { type RecordOf } from 'immutable'

type SspConfigurationProps = {
  senders: Immutable.List<string>
  maxParts: number
}

export const SspConfigurationFactory = Immutable.Record<SspConfigurationProps>({
  senders: Immutable.List(),
  maxParts: 0,
})
export type SspConfigurationRecord = RecordOf<SspConfigurationProps>
