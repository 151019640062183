import styled, { css } from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

import { LoadingSvgStrokeAnim } from './../donut-chart/donut-chart.styles'

export const LoadingRect = styled.rect<{
  isEmpty: boolean
}>`
  ${props =>
    props.isEmpty
      ? css`
          fill: ${schemes.grayscale['30']};
        `
      : css`
          animation-name: ${LoadingSvgStrokeAnim};
          animation-duration: 2s;
          animation-iteration-count: infinite;
        `}
`

export const Legend = styled.button<{
  disabled?: boolean
  active?: boolean
}>`
  display: flex;
  text-align: center;
  align-items: center;
  border: 0;
  font-size: 14px;
  color: ${props => {
    if (props.disabled) return colors.textDisabled
    return props.active ? colors.textNeutral : colors.textLight
  }};
  font-weight: 500;
  padding: 0;
  gap: 4px;
`

export const LegendContainer = styled.div`
  display: block;
  margin-top: 20px;

  ${Legend}:not(:last-child) {
    margin-right: 8px;
  }
`
