import * as React from 'react'
import { useTheme } from 'styled-components'

import { ToggleSwitch } from './button.styles'
import { SwitchContainer, SwitchElement } from './switch.styles'

import { Tooltip } from '../tooltip'

type SwitchProps = {
  isActive: boolean
  disabled?: boolean
  switchTooltip?: React.ReactNode
  onChange?: (bool: boolean) => void
  children?: React.ReactNode
}

export const Switch = React.forwardRef<HTMLDivElement, SwitchProps>(
  ({ isActive, disabled, onChange, children, switchTooltip }: SwitchProps, ref) => {
    const invertBoolean = React.useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.currentTarget.blur()
        if (onChange) onChange(!isActive)
      },
      [isActive, onChange]
    )
    const theme = useTheme()
    const isDisabled = disabled || theme?.disabledMode

    return (
      <SwitchContainer disabled={isDisabled}>
        <Tooltip tooltip={switchTooltip} isTooltipEmpty={!switchTooltip} minWidth={210}>
          <span>
            <SwitchElement
              ref={ref}
              $isActive={isActive}
              disabled={isDisabled}
              style={{ marginRight: children ? 10 : 0 }}
            >
              <input
                type="checkbox"
                checked={isActive}
                disabled={isDisabled}
                onChange={invertBoolean}
              />
              <ToggleSwitch />
            </SwitchElement>
          </span>
        </Tooltip>
        {children}
      </SwitchContainer>
    )
  }
)
