import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Tooltip } from 'components/common/tooltip'

import { matchAudienceType } from 'com.batch.redux/audience.api'

import { type ProfileAudienceRecord } from 'com.batch/audience/model/audience-profile.records'

export const OptionAudience = (
  option: ProfileAudienceRecord,
  {
    context,
  }: {
    context: 'value' | 'menu'
  }
): React.ReactElement => {
  return context === 'value' ? (
    <div>
      {option.displayName ? (
        <Tooltip tooltip={option.name}>
          <span>{option.displayName}</span>
        </Tooltip>
      ) : (
        option.name
      )}
    </div>
  ) : (
    <Grid template="1fr auto">
      <span>{option.displayName || option.name}</span>
      <div style={{ textAlign: 'right' }}>
        <em>{matchAudienceType(option.type)}</em>
      </div>
    </Grid>
  )
}
