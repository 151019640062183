import Immutable, { type List, type Map, type Set } from 'immutable'
import { createSelector } from 'reselect'

import { type AbTestedThemeRecord, ThemeFactory } from './theme.records'

import {
  AttributeValuesListFactory,
  type AttributeValueRecord,
  type State,
  type Variant,
} from 'com.batch.redux/_records'
import { currentCampaign } from 'com.batch.redux/campaign.selector'
import {
  type AbTestedInAppRecord,
  type SdkActionChoiceRecord,
} from 'com.batch.redux/content.records'
import { inappSelector } from 'com.batch.redux/content.selector'
import { InAppVariantsThemeSelector } from 'com.batch.redux/theme.selector'

type SDK_STATE = 'ok' | 'warn' | 'danger' | 'loading'
type extract<T> = (arg1: State) => T

export type SdkFeatureState = {
  state: SDK_STATE
  mlvl: number
  public: string
  installs: number
  ratio: number
}

export const requiredMlvlForFeature = {
  'action:batch.ios_redirect_settings': 5,
  'action:batch.ios_request_notifications': 4,
  'action:batch.ios_smart_reoptin': 5,
  'action:batch.ios_tracking_consent': 8,
  'action:batch.android_request_notifications': 12,
  'action:batch.android_smart_reoptin': 12,
  'action:batch.android_redirect_settings': 12,
  'action:batch.user.event': 8,
  'action:batch.user.tag': 8,
  'action:batch.group': 8,
  'action:batch.deeplink': 1,
  'action:batch.dismiss': 1,
  'action:batch.clipboard': 10,
  'action:batch.rating': 10,
  'feature:landing': 1,
  'feature:inapp': 2,
  'theme:universal': 1,
  'theme:banner': 4,
  'theme:modal': 6,
  'theme:image': 6,
  'theme:webview': 10,
}
function getSdkPublicVersion(mlvl: number): string {
  switch (mlvl) {
    case 1:
      return '1.7'
    case 2:
      return '1.10'
    case 4:
      return '1.11'
    case 5:
      return '1.12'
    case 6:
      return '1.14'
    case 8:
      return '1.16'
    case 10:
      return '1.17'
    case 11:
      return '1.18'
    case 12:
      return '1.19.2' // c'est faux, mais le sdk android a pas bump sa version de mlvl pour les nouvelles actions
    default:
      return '1.19'
  }
}
export type TestableFeatureId = keyof typeof requiredMlvlForFeature

// all attributes values
const allValues = (state: State) => state.attrValue
const attributeLoadingStateSelector = (state: State) => state.attribute.config.attributeLoadingState
// all lvl values
const lvlSelector: extract<List<AttributeValueRecord>> = createSelector(allValues, values =>
  values
    .get('lvl', AttributeValuesListFactory())
    .get('values', Immutable.Map<string, List<AttributeValueRecord>>())
    .get('__default', Immutable.List<AttributeValueRecord>())
)

// all lvl values
export const lvlLoadingSelector: extract<boolean> = createSelector(
  allValues,
  attributeLoadingStateSelector,
  (values, fetchingState) =>
    Boolean(values.getIn(['lvl', 'loading'], true)) && fetchingState === 'LOADING'
)
// all mlvl values
const mlvlSelector: extract<List<AttributeValueRecord>> = createSelector(allValues, values =>
  values
    .get('b.mlvl', AttributeValuesListFactory())
    .get('values', Immutable.Map<string, List<AttributeValueRecord>>())
    .get('__default', Immutable.List<AttributeValueRecord>())
)
const mlvlLoadingSelector: extract<boolean> = createSelector(
  allValues,
  attributeLoadingStateSelector,
  (values, fetchingState) =>
    Boolean(values.getIn(['b.mlvl', 'loading'], true)) && fetchingState === 'LOADING'
)

// required mlvl for a given  SdkActionChoiceRecord
export function getMlvlForNativeAction(action: SdkActionChoiceRecord): number {
  return action.value === 'custom' ? 1 : requiredMlvlForFeature['action:' + action.value]
}

// required mlvl for a given theme & inappcontent
export function getRequiredMlvlFor({
  variantsThemes,
  contentByLanguage,
  activeVariants,
}: {
  variantsThemes: AbTestedThemeRecord
  contentByLanguage: Map<string, AbTestedInAppRecord>
  activeVariants: Set<Variant>
}): number {
  const themeA = variantsThemes.get('a', ThemeFactory()) ?? ThemeFactory()
  const themeB = variantsThemes.get('b', ThemeFactory()) ?? ThemeFactory()
  const mlvlA = themeA.payloadVars.kind
    ? requiredMlvlForFeature['theme:' + themeA.payloadVars.kind]
    : 1
  const mlvlB =
    activeVariants.has('b') && themeB.payloadVars.kind
      ? requiredMlvlForFeature['theme:' + themeB.payloadVars.kind]
      : 1
  let mlvl: number = Math.max(mlvlA, mlvlB)

  contentByLanguage.forEach(content => {
    activeVariants.forEach(variant => {
      const theme = variantsThemes.get(variant, ThemeFactory()) ?? ThemeFactory()
      if (theme.fields.has('cta1')) {
        mlvl = Math.max(mlvl, getMlvlForNativeAction(content.get(variant).mainButtonAction.action))
      }
      if (theme.fields.has('cta2')) {
        mlvl = Math.max(
          mlvl,
          getMlvlForNativeAction(content.get(variant).secondaryButtonAction.action)
        )
      }
      if (theme.fields.has('global')) {
        mlvl = Math.max(mlvl, getMlvlForNativeAction(content.get(variant).globalTapAction.action))
      }
    })
  })

  return mlvl
}

// select the required mlvl for the current campaign
export const requiredMlvlForCampaignSelector: extract<number> = createSelector(
  InAppVariantsThemeSelector,
  inappSelector,
  currentCampaign,
  (variantsThemes, contentByLanguage, campaign) => {
    return getRequiredMlvlFor({
      variantsThemes,
      contentByLanguage,
      activeVariants: campaign.abtesting.activeVariants,
    })
  }
)

// selector that returns a function that takes an mlvl as input and return a state
export const mlvlCheckerSelector: extract<(arg1: number) => SdkFeatureState> = createSelector(
  mlvlSelector,
  mlvlLoadingSelector,
  (mlvlValues, loading) => {
    const total: number = mlvlValues.reduce((acc, current) => acc + current.installs, 0)
    return (mlvl: number) => {
      const installs = mlvlValues.reduce((acc, current) => {
        switch (typeof current.value) {
          case 'string':
            return parseInt(current.value) >= mlvl ? acc + current.installs : acc
          case 'number':
            return current.value >= mlvl ? acc + current.installs : acc
          default:
            return acc
        }
      }, 0)
      const ratio = installs ? installs / total : 0
      return {
        installs,
        mlvl,
        public: getSdkPublicVersion(mlvl),
        ratio,
        state: loading ? 'loading' : ratio > 0.2 ? 'ok' : ratio > 0.01 ? 'warn' : 'danger',
      }
    }
  }
)

// il faudra probablement unifier plus tard pour matcher ce qu'on fait avec le mlvl
export const sdkMatchingTargetVersionSelector: (arg1: State) => (arg1: number) => number =
  createSelector(lvlSelector, sdkApiLevels => {
    return (sdkTargetApiLevel: number) => {
      if (sdkApiLevels.size === 0) return 1
      const total = sdkApiLevels.reduce((acc, current) => acc + current.installs, 0)
      const installs = sdkApiLevels.reduce((acc, current) => {
        switch (typeof current.value) {
          case 'string':
            return parseInt(current.value) >= sdkTargetApiLevel ? acc + current.installs : acc
          case 'number':
            return current.value >= sdkTargetApiLevel ? acc + current.installs : acc
          default:
            return acc
        }
      }, 0)
      return installs / total
    }
  })
export const sdkCountMatchingTargetVersionSelector: (arg1: State) => (arg1: number) => number =
  createSelector(lvlSelector, sdkApiLevels => {
    return (sdkTargetApiLevel: number) => {
      if (sdkApiLevels.size === 0) return Infinity
      return sdkApiLevels.reduce((acc, current) => {
        switch (typeof current.value) {
          case 'string':
            return parseInt(current.value) >= sdkTargetApiLevel ? acc + current.installs : acc
          case 'number':
            return current.value >= sdkTargetApiLevel ? acc + current.installs : acc
          default:
            return acc
        }
      }, 0)
    }
  })
