import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { percentage } from 'com.batch.common/utils'

import { type OrchestrationAnalyticsDateRangeRecord } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'

import { TrendContainer } from 'com.batch/shared/ui/component/trend/trend.styles'

type TrendTooltipProps = {
  metricContent: React.ReactNode
  dateRange: OrchestrationAnalyticsDateRangeRecord
}
export const TrendTooltip = ({
  metricContent,
  dateRange,
}: TrendTooltipProps): React.ReactElement => {
  const { from, to, diff } = React.useMemo(
    () => ({
      from: dateRange.from,
      to: dateRange.to,
      diff: dateRange.to.diff(dateRange.from, 'day') + 1,
    }),
    [dateRange]
  )

  return (
    <div style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
      <p>Compared to the previous period</p>
      <p>
        {from.subtract(diff, 'day').format('DD/MM/YYYY')} -{' '}
        {to.subtract(diff, 'day').format('DD/MM/YYYY')}: {metricContent}
      </p>
    </div>
  )
}

type TrendProps = {
  rate: number
  isGrowthPositive?: boolean
  tooltip?: React.ReactNode
}
export const Trend = ({
  rate,
  isGrowthPositive = true,
  tooltip,
}: TrendProps): React.ReactElement => {
  const color = React.useMemo(() => {
    switch (true) {
      case rate > 0:
        return isGrowthPositive ? 'success' : 'danger'
      case rate < 0:
        return isGrowthPositive ? 'danger' : 'success'
      default:
        return 'neutral'
    }
  }, [isGrowthPositive, rate])
  const icon = React.useMemo(() => (rate >= 0 ? 'trending-up' : 'trending-down'), [rate])

  const formattedRate = React.useMemo(() => {
    if (rate === Infinity) return '∞%'
    else if (Math.abs(rate) < 0.001 && rate > 0) return '<0.1%'
    else if (rate > 9.99) return '999+%'
    return percentage(rate)
  }, [rate])

  return (
    <Tooltip tooltip={tooltip} placement="bottom" arrow={false} style={{ overflow: 'visible' }}>
      <TrendContainer $color={color}>
        <Icon icon={icon} />
        <span>{formattedRate}</span>
      </TrendContainer>
    </Tooltip>
  )
}
