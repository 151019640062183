import dayjs, { type Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import timezone from 'dayjs/plugin/timezone'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(weekday)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(isoWeek)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(minMax)

type ParseDate = (str?: string, format?: string) => Date | undefined
type FormatDate = (date?: Date, format?: string) => string
export type DateRange = { from: Readonly<Dayjs>; to: Readonly<Dayjs> }
export const formatDate: FormatDate = (date, format = 'L') => {
  return date ? dayjs.utc(date).format(Array.isArray(format) ? format[0] : format) : ''
}

export const parseDate: ParseDate = (str, format = 'L') => {
  const m = dayjs.utc(str, Array.isArray(format) ? format[0] : format, true)

  if (m.isValid()) {
    return m.toDate()
  }
  return undefined
}

export const stringToUtcDayJs = (val?: string | null): null | dayjs.Dayjs => {
  if (typeof val === 'undefined' || val === null) {
    return null
  }
  return dayjs.utc(val.substring(0, 19))
}

export { dayjs, type Dayjs }
