import styled from 'styled-components'

import { colors } from 'components/styled/tokens'

export const Counter = styled.span`
  color: ${colors.textDisabled};
  font-weight: 500;
`

export const FilterCounterOptionContent = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
