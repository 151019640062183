import Immutable, { type RecordOf } from 'immutable'

type DataProps = {
  value?: number
  rate?: number
  unique?: number | null | undefined
}
type DataRecord = RecordOf<DataProps>
export const DataFactory = Immutable.Record<DataProps>({
  value: 0,
  rate: 0,
  unique: null,
})

type BounceDataProps = {
  value: number
  rate: number
  hardBounce: number
  softBounce: number
  blockBounce: number
}
export const BounceDataFactory = Immutable.Record<BounceDataProps>({
  value: 0,
  rate: 0,
  hardBounce: 0,
  softBounce: 0,
  blockBounce: 0,
})
export type BounceDataRecord = RecordOf<BounceDataProps>

export type EmailProviderStatsProps = {
  delivered: DataRecord
  open: DataRecord
  click: DataRecord
  bounce: BounceDataRecord
  unsubscribe: DataRecord
  mpp: DataRecord
  sent: number
}
export const EmailProviderStatsFactory = Immutable.Record<EmailProviderStatsProps>({
  delivered: DataFactory(),
  open: DataFactory(),
  click: DataFactory(),
  bounce: BounceDataFactory(),
  unsubscribe: DataFactory(),
  mpp: DataFactory(),
  sent: 0,
})
export type EmailProviderStatsRecord = RecordOf<EmailProviderStatsProps>

export type EmailProviderProps = {
  name: string
  stats: EmailProviderStatsRecord
}
export const EmailProviderFactory = Immutable.Record<EmailProviderProps>({
  name: '',
  stats: EmailProviderStatsFactory(),
})
export type EmailProviderRecord = RecordOf<EmailProviderProps>
