import * as React from 'react'

export const STRIPE_PATTERN_REF = 'stripe-pattern'

export const StripePattern = (): React.ReactElement => {
  return (
    <pattern
      id={STRIPE_PATTERN_REF}
      width="50"
      height="50"
      patternUnits="userSpaceOnUse"
      patternTransform="scale(.25)"
    >
      <path d="M-24 26L26 76" stroke="black" strokeWidth="18" />
      <path d="M0 0L50 50" stroke="black" strokeWidth="18" />
      <path d="M20 -30L70 20" stroke="black" strokeWidth="18" />
    </pattern>
  )
}
