import * as React from 'react'

export const KIND_LIST = ['image', 'audio', 'video'] as const

export const CONTENT_MEDIA_URL = {
  image: {
    formats: ['png', 'jpg'],
  },
  audio: {
    formats: ['mp3'],
  },
  video: {
    formats: ['mp4', 'gif'],
  },
}

export const replaceTemplateTag = (title: string): React.ReactNode => {
  const regexString = new RegExp(/(.*)({{2}[ \-*_%.|()'"`‘’\w]*}{2})(.*)/gm)
  const textNodesPart = title.split(regexString)
  return textNodesPart.map((text, index) => {
    return (
      <React.Fragment key={index}>
        {text.substr(0, 2) === '{{' && text.substr(-2) === '}}' ? (
          <span className="styled-url-template-tag">{text}</span>
        ) : (
          text
        )}
      </React.Fragment>
    )
  })
}
