import * as React from 'react'
import { useDispatch, useSelector } from 'components/console/react-redux'
import { NavLink } from 'react-router-dom'

import { Avatar } from 'components/common/avatar'
import { Button, ButtonLink, ButtonNavLink, Dropdown } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tag } from 'components/common/tag'
import { Tooltip } from 'components/common/tooltip'
import { SubNav, SubNavRouterLink } from 'components/styled/subnav'

import {
  resyncPlan,
  clearCache,
  updatePushConfig,
  changeCompany,
} from 'components/console/redux/app'
import {
  type ExportJobRecord,
  type DataExportJobRecord,
} from 'components/console/redux/console.records'
import { fetchExportJobs, createExportJob } from 'components/console/redux/export'
import { AppData } from './app-data'
import { AppExport } from './app-export'
import { ChangeCompany } from './change-company'

import { useToggle } from '../../_hooks/use-toggle'
import { Subtitle, Ellipsis } from '../console.style'
import { type AppRecord, type CompanyRecord } from 'com.batch.redux/_records'
import { type State } from 'components/console/redux/console.records'

type AppViewProps = {
  app: AppRecord
  company: CompanyRecord
  tab: 'general' | 'exports' | 'codes'
}
const exportSelector = (
  state: State & {
    export: ExportJobRecord
  }
) => state.export

export const AppView = ({ app, tab, company }: AppViewProps): React.ReactElement => {
  console.log(app.toJS())
  const dispatch = useDispatch()
  const exportJob = useSelector(exportSelector)

  const onChangeCompany = React.useCallback(
    ({ app, company }) => dispatch(changeCompany({ app, company })),
    [dispatch]
  )
  const onUpdatePushConfig = React.useCallback(
    props => dispatch(updatePushConfig(props)),
    [dispatch]
  )
  const onResyncPlan = React.useCallback(
    () =>
      dispatch(resyncPlan(app)).then(() => {
        alert('plan was synched')
      }),
    [app, dispatch]
  )
  const onClearCache = React.useCallback(
    () =>
      dispatch(clearCache(app)).then(() => {
        alert('estimate cache cleared')
      }),
    [app, dispatch]
  )
  const onFetchExportJobs = React.useCallback(
    ({ app }: { app: AppRecord }) => dispatch(fetchExportJobs({ app })),
    [dispatch]
  )
  const onCreateExportJobs = React.useCallback(
    ({ app, exportJob }: { app: AppRecord; exportJob: DataExportJobRecord }) =>
      dispatch(createExportJob({ app, exportJob })),
    [dispatch]
  )
  const popinState = useToggle()
  return (
    <React.Fragment>
      <ChangeCompany
        app={app}
        opened={popinState.value}
        close={popinState.close}
        changeCompany={onChangeCompany}
      />

      <Grid template="50px calc(100% - 940px) 10px 320px 220px 150px 130px" margin={[0, 0, 20, 0]}>
        <Avatar platform={app.platform} url={app.icon} placeholder={app.name.split(' ')[0]} />
        <Subtitle>
          <a href={`/${company.id}/apps/${app.id}`}>
            <Ellipsis title={app.name}>{app.name}</Ellipsis>
          </a>
        </Subtitle>
        <div>by</div>
        <Grid template="40px 1fr">
          <NavLink to={`/console/company/${company.id}`}>
            <Avatar url={company.avatarUrl} placeholder={company.name} color={'#1c2e43'} />
          </NavLink>
          <Subtitle>
            <NavLink to={`/console/company/${company.id}`}>
              {company.name ? company.name : company.id}&nbsp;
              <Tooltip tooltip="Company plan">
                <Tag>
                  {company.billing.trialIsActive && company.billing.trial
                    ? company.billing.trial.name
                    : company.billing.plan.name}{' '}
                  plan
                </Tag>
              </Tooltip>
            </NavLink>
          </Subtitle>
        </Grid>

        <Dropdown label="Actions">
          <Button onClick={onResyncPlan}>Resync plan</Button>
          <Button onClick={popinState.open}>Move to another company</Button>
          <Button onClick={onClearCache}>Clear estimate cache</Button>
        </Dropdown>
        <Dropdown label="Go to">
          <ButtonNavLink
            to={
              app.projectKey
                ? `/console/projects/${app.projectKey ?? ''}`
                : `/console/projects/new?appIds=${app.id}-${app.platform}`
            }
          >
            Project{!app.projectKey && ' (create)'}
          </ButtonNavLink>
          <ButtonNavLink to={`/console/apps?companyId=${company.id}`}>Other apps</ButtonNavLink>
          <ButtonNavLink to={`/console/users?companyId=${company.id}`}>Company users</ButtonNavLink>
          <ButtonLink href={`/${company.id}/apps/${app.id}/analytics`}>Analytic</ButtonLink>
          <ButtonLink href={`/${company.id}/apps/${app.id}/userbase`}>Userbase</ButtonLink>
          <ButtonLink href={`/${company.id}/apps/${app.id}/campaigns/push`}>Push</ButtonLink>
          <ButtonLink href={`/${company.id}/apps/${app.id}/campaigns/in-app`}>In-App</ButtonLink>
          <ButtonLink href={`/${company.id}/apps/${app.id}/settings/global`}>Settings</ButtonLink>
        </Dropdown>
        <ButtonLink
          kind="primary"
          intent="action"
          addOn="suffix"
          href={`/${company.id}/apps/${app.id}/analytics`}
          target="_blank"
        >
          <Icon icon="enter" />
          Dashboard
        </ButtonLink>
      </Grid>
      <SubNav>
        <SubNavRouterLink to={`/console/apps/${app.id}`} end>
          General information
        </SubNavRouterLink>
        <SubNavRouterLink
          disabled={!app.features.has('export-events') && !app.features.has('export-userbase')}
          to={`/console/apps/${app.id}/exports`}
        >
          Export jobs
        </SubNavRouterLink>

        <SubNavRouterLink to={`/console/apps/${app.id}/codes`}>Demo Codes</SubNavRouterLink>

        {app.archived && <Tag style={{ backgroundColor: 'crimson', color: '#FFF' }}>Archived</Tag>}
      </SubNav>

      {tab === 'general' && (
        <AppData app={app} company={company} updatePushConfig={onUpdatePushConfig} />
      )}

      {tab === 'exports' && (
        <AppExport
          app={app}
          exportJob={exportJob}
          fetchExportJobs={onFetchExportJobs}
          createExportJob={onCreateExportJobs}
        />
      )}
    </React.Fragment>
  )
}
