import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { FilterSelectMulti } from 'components/filter/filter-select-multi/filter-select-multi'
import { updateAnalyticsFilters } from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { type DiscoveryFilterRecord } from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { kformat } from 'com.batch.common/utils'
import { colors } from 'components/styled/tokens'

type MailboxProviderFilterProps = {
  token?: string
  mailboxProviders: List<DiscoveryFilterRecord>
}
export const MailboxProviderFilter = ({
  token,
  mailboxProviders,
}: MailboxProviderFilterProps): React.ReactNode => {
  const dispatch = useDispatch()
  const { filters } = useGetAnalyticsFilters(token)

  const selectedProviders = React.useMemo(() => {
    const providers = filters.get('mailbox_provider', Immutable.List<string>())
    return mailboxProviders.filter(mp => providers.includes(mp.name))
  }, [filters, mailboxProviders])

  const options = React.useMemo(
    () => mailboxProviders.sort((a, b) => (a.sent < b.sent ? 1 : -1)),
    [mailboxProviders]
  )

  const onProviderChange = React.useCallback(
    (l: List<DiscoveryFilterRecord>) => {
      if (token)
        dispatch(
          updateAnalyticsFilters({
            token,
            filters:
              l.size > 0
                ? filters.set(
                    'mailbox_provider',
                    l.map(mp => mp.name)
                  )
                : filters.delete('mailbox_provider'),
          })
        )
    },
    [dispatch, token, filters]
  )

  const optionToString = React.useCallback(
    (provider?: DiscoveryFilterRecord | null) => provider?.name ?? '',
    []
  )
  const optionFormatter = React.useCallback(
    (provider?: DiscoveryFilterRecord | null): React.ReactNode => (
      <div style={{ display: 'flex', gap: 8, maxWidth: 250 }}>
        <p
          style={{
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {provider?.name ?? ''}
        </p>
        <p style={{ fontWeight: 400, fontSize: 14, color: colors.textLight }}>
          {kformat(provider?.sent ?? 0)} sent
        </p>
      </div>
    ),
    []
  )
  const formatTerm = React.useCallback(
    (count: number) => {
      if (count === 1) {
        return selectedProviders.first()?.name ?? `${count} provider`
      } else if (count === 2) {
        return `${selectedProviders.first()?.name}, ${selectedProviders.last()?.name}`
      } else {
        return `${count} providers`
      }
    },
    [selectedProviders]
  )

  return (
    <FilterSelectMulti
      id="analytics-filter-mailbox_provider"
      options={options}
      value={selectedProviders}
      onChange={onProviderChange}
      optionFormatter={optionFormatter}
      optionToString={optionToString}
      placeholder="All mailbox providers"
      term={formatTerm}
      toggleButtonWidth="100%"
      menuStyle={{ width: '100%', maxWidth: 300 }}
    />
  )
}
