import * as React from 'react'
import { ThemeContext } from 'styled-components'

type PushOverviewMetricsProps = {
  emptyTemplate: React.ReactNode
  children: React.ReactNode
}

export function PushOverviewMetrics({
  emptyTemplate,
  children,
}: PushOverviewMetricsProps): React.ReactNode {
  const themeContext = React.useContext(ThemeContext)
  return themeContext.isEmpty || themeContext.isLoading ? emptyTemplate : children
}
