import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { type StateToggler } from 'components/_hooks'
import { ResetApiKeyModalContainer } from 'components/account/company/reset-api-key-modal.styles'
import {
  BoxBody,
  BoxFooter,
  BoxHeader,
  HeaderBoxTitle,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'

import { currentCompanySelector, resetCompanyRestApiKey } from 'com.batch.redux/company'

type Props = {
  state: StateToggler
  onReset: () => void
}

export const ResetApiKeyModal = ({ state, onReset }: Props): React.ReactElement => {
  const company = useSelector(currentCompanySelector)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const handleOnClick = React.useCallback(() => {
    setIsLoading(true)
    dispatch(resetCompanyRestApiKey(company))
      .then(() => {
        setIsLoading(false)
        state.close()
        onReset()
      })
      .catch(() => setIsLoading(false))
  }, [dispatch, company, state, onReset])

  const handleOnClose = React.useCallback(() => {
    if (!isLoading) state.close()
  }, [isLoading, state])

  return (
    <Popin close={handleOnClose} opened={state.value} style={{ overflowY: 'hidden', padding: 0 }}>
      <ResetApiKeyModalContainer>
        <BoxHeader>
          <HeaderBoxTitle title="Reset your company REST API Key?" />
          <HeaderBoxActions>
            <Button style={{ margin: 0 }} onClick={state.close} disabled={isLoading}>
              <Icon icon="close" />
            </Button>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody>
          <div>
            <h4>Risk of API calls failure</h4>
            <p>
              Resetting the Rest API key will result in all existing API calls using this key to
              fail.
            </p>
          </div>
          <div>
            <h4>Non reversible</h4>
            <p>You won’t be able to cancel or revert this action in the future.</p>
          </div>
          <div>
            <h4>Applied to all apps & projects</h4>
            <p>
              The REST API Key is shared by all apps and project within your company. Resetting it
              will have effect on all API calls targeting them.
            </p>
          </div>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" intent="neutral" onClick={state.close} disabled={isLoading}>
            Cancel
          </Button>
          <Button isLoading={isLoading} kind="primary" intent="danger" onClick={handleOnClick}>
            Yes, reset it
          </Button>
        </BoxFooter>
      </ResetApiKeyModalContainer>
    </Popin>
  )
}
