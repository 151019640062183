import * as React from 'react'

import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'

import { useCreateNotifier } from 'com.batch/shared/ui/hooks/use-create-notifier'

type CopyableWhenOverflowProps = {
  children: string
  className?: string
}
export const CopyableWhenOverflow = ({
  children,
  className,
}: CopyableWhenOverflowProps): React.ReactElement => {
  const ref = React.useRef<HTMLSpanElement | null>(null)
  const [isOverflowing, setIsOverflowing] = React.useState(false)
  const notifySuccess = useCreateNotifier({ kind: 'success', notificationText: 'Copied !' })
  const notifyFailure = useCreateNotifier({ kind: 'error', notificationText: 'Unable to copy' })
  const onClick = React.useCallback(async () => {
    try {
      await navigator.clipboard.writeText(children)
      notifySuccess()
    } catch {
      notifyFailure()
    }
  }, [children, notifyFailure, notifySuccess])
  const onResize = React.useCallback(() => {
    if (ref.current) {
      setIsOverflowing(ref.current.clientWidth < ref.current.scrollWidth)
    }
  }, [])
  React.useLayoutEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])
  return (
    <Tooltip tooltip={children} isTooltipEmpty={!isOverflowing} minWidth={400} placement="bottom">
      <Grid template={isOverflowing ? '1fr auto' : '1fr'}>
        <span
          className={className}
          ref={ref}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {children}
        </span>
        {isOverflowing && (
          <Button onClick={onClick}>
            <Icon icon="copy" />
          </Button>
        )}
      </Grid>
    </Tooltip>
  )
}
