import { List } from 'immutable'

import {
  type BounceCategoryRecord,
  BounceCategoryFactory,
  BounceClassificationFactory,
} from 'com.batch/orchestration-analytics/models/orchestration-stats.record'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'

const updateBounceCategory = (record: BounceCategoryRecord, count: number, name: string) =>
  record.set('count', record.count + count).update('classifications', classifications =>
    classifications.push(
      BounceClassificationFactory({
        name,
        count,
      })
    )
  )

export const parseBounceCategories = (
  stats?: Array<OrchestrationStatsDynamicDimension> | null
): List<BounceCategoryRecord> => {
  const classificationIndex = stats ? stats[0].dimensionNames.indexOf('bounce_classification') : -1

  if (stats && classificationIndex !== -1) {
    let [HARD, SOFT, BLOCK] = [
      BounceCategoryFactory({
        name: 'HARD',
        count: 0,
        rate: 0,
        classifications: List(),
      }),
      BounceCategoryFactory({
        name: 'SOFT',
        count: 0,
        rate: 0,
        classifications: List(),
      }),
      BounceCategoryFactory({
        name: 'BLOCK',
        count: 0,
        rate: 0,
        classifications: List(),
      }),
    ]
    stats.forEach(({ dimensionValues, currentPeriod }) => {
      const classification = dimensionValues[classificationIndex]
      if (currentPeriod.hardBounce) {
        HARD = updateBounceCategory(HARD, currentPeriod.hardBounce, classification)
      } else if (currentPeriod.softBounce) {
        SOFT = updateBounceCategory(SOFT, currentPeriod.softBounce, classification)
      } else if (currentPeriod.blockBounce) {
        BLOCK = updateBounceCategory(BLOCK, currentPeriod.blockBounce, classification)
      }
    })

    const totalBounce = HARD.count + SOFT.count + BLOCK.count
    return List([HARD, SOFT, BLOCK].map(cat => cat.set('rate', cat.count / totalBounce)))
  }
  return List()
}
