import Immutable, { type RecordOf } from 'immutable'

import { type MessageBuilderField } from 'com.batch/message-builder/models/message-builder-field'

type InlineEditorStateProps = {
  variant: 'a' | 'b'
  selection: [number, number]
  field: MessageBuilderField
  personalizationModalIsOpen: boolean
}
export type InlineEditorStateRecord = RecordOf<InlineEditorStateProps>

export const InlineEditorStateFactory = Immutable.Record<InlineEditorStateProps>({
  variant: 'a',
  selection: [0, 0],
  field: null,
  personalizationModalIsOpen: false,
} as InlineEditorStateProps)
