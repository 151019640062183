import * as React from 'react'

import { Icon } from 'components/common/svg-icon'
import { Ellipsis } from 'components/styled/text'

import { CopyableContainer, CopyableText, CopyableToken } from './copyable.styles'

import { useCreateCopyValueToClipboardCallback } from 'com.batch/shared/ui/hooks/use-create-copy-value-to-clipboard-callback'
import { useCreateNotifier } from 'com.batch/shared/ui/hooks/use-create-notifier'

type CopyableProps = {
  value: string
  label?: React.ReactNode
  notificationText: string
  type?: 'default' | 'token'
  isFirst?: boolean
  fullWidth?: boolean
  style?: any
}

export const Copyable = ({
  value,
  label,
  notificationText,
  type = 'default',
  isFirst,
  fullWidth,
  style,
}: CopyableProps): React.ReactElement => {
  const notifySuccess = useCreateNotifier({ notificationText, kind: 'success' })
  const copy = useCreateCopyValueToClipboardCallback({ notifySuccess })(value)

  switch (type) {
    case 'token':
      return (
        <CopyableToken onClick={copy}>
          <CopyableText isFirst={isFirst}>
            {value} <Icon icon="copy" />
          </CopyableText>
        </CopyableToken>
      )
    case 'default':
    default:
      return (
        <CopyableContainer onClick={copy} fullWidth={fullWidth} style={style}>
          {label}
          <CopyableText>
            <Ellipsis>{value}</Ellipsis>
            <Icon icon="copy" />
          </CopyableText>
        </CopyableContainer>
      )
  }
}
