import Immutable from 'immutable'
import { ofType } from 'redux-observable'
import { type Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import {
  catchError,
  debounceTime,
  delay,
  filter,
  map,
  mergeMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators'

import { generateUrl } from 'com.batch.common/router'

import {
  type FetchHashAction,
  type FetchProfileHashAction,
  type SetHashAction,
  type updateLangRegionAction,
  type updateSegmentAction,
} from './target'

import {
  type AddConditionAction,
  type QueryParsedAction,
  type UpdateConditionAction,
} from '../query/query'
import { EstimateFactory, EstimateTokensFactory, type State } from 'com.batch.redux/_records'
import { rawToRecord } from 'com.batch.redux/attribute.api'
import { type toggleChannelAction } from 'com.batch.redux/campaign.action'
import { currentCampaignAppsSelector } from 'com.batch.redux/campaign.selector'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { formatFilterPushTokens } from 'com.batch/orchestration/infra/formats/push-token-audience.target'
import { parseEstimateProfile } from 'com.batch/orchestration/infra/parses/estimate-profile.parse'
import { buildTriggerEstimateAction } from 'com.batch/orchestration/usecases/trigger-estimate'

type CampaignHasQueriesToParse = {
  type: 'CAMPAIGN_HAS_QUERIES_TO_PARSE'
  payload: boolean
}

type triggerEstimateActions =
  | AddConditionAction
  | UpdateConditionAction
  | updateLangRegionAction
  | updateSegmentAction
  | toggleChannelAction
  | QueryParsedAction
  | CampaignHasQueriesToParse

const QUERY_TRIGGERS = [
  'T_ADD_CONDITION',
  'T_UPDATE_CONDITION',
  'T_QUERY_PARSED',
  'T_UPDATE_NODE',
  'T_REMOVE_CONDITION',
]
const TARGET_TRIGGERS = [
  'TARGET_SEGMENT',
  'TARGET_LANG_REGION',
  'CAMPAIGN_TOGGLE_CHANNELS',
  'UPDATE_PUSH_MESSAGE_TOKEN_MODE_RECORD',
]
const DEBOUNCE = 900
const DEBOUNCE_CHANNEL_CHANGE = 400
const MAX_RETRIES = 3

// simple epic responsible of computing an hashCode from state values
// this hash will be used to decide wether or not we need to trigger an API call
// @TODO : add targeted apps to hash ?
// @TODO : handle audiences when we put them back on the main targeting
export const estimateHashEpic = (
  action$: Observable<triggerEstimateActions>,
  state$: Observable<State>
): Observable<SetHashAction | FetchHashAction | FetchProfileHashAction> => {
  return action$.pipe(
    filter(
      action =>
        action.type === 'CAMPAIGN_HAS_QUERIES_TO_PARSE' ||
        QUERY_TRIGGERS.includes(action.type) ||
        TARGET_TRIGGERS.includes(action.type)
    ),
    debounceTime(DEBOUNCE),
    withLatestFrom(state$),
    map(([action, state]: [any, any]) => {
      const targetingId = TARGET_TRIGGERS.includes(action.type)
        ? action.payload.id
        : action.payload?.queryId === 'targeting'
          ? 'default'
          : action.payload?.queryId ?? 'default'
      return buildTriggerEstimateAction(state, targetingId)
    })
  )
}

export const estimateFetchEpic = (
  action$: Observable<FetchHashAction>,
  state$: Observable<State>
) => {
  return action$.pipe(
    filter(
      action =>
        action.type === 'T_ESTIMATE_FETCH_HASH' || action.type === 'T_ESTIMATE_FETCH_PROFILE_HASH'
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]: [any, any]) => {
      const data = action.payload
      const project = currentProjectSelector(state)
      let url = generateUrl('api_data_estimate')
      let body: string = JSON.stringify(data)
      if (data.apps.length === 0) {
        url = generateUrl('api_grpc_oursql_service', {
          methodName: 'EstimateOC',
          hash: data.hash,
          id: action.payload.id,
        })
        const queryWhenPresent = data.query && data.query !== '""' ? { query: data.query } : {}
        body = JSON.stringify({
          projectKey: {
            textual: {
              text: project.projectKey,
            },
          },
          ...queryWhenPresent,
          regions: data.regions,
          languages: data.languages,
          filterPushTokens: data.filterPushTokens
            ? formatFilterPushTokens(data.filterPushTokens)
            : null,
        })
      }

      return ajax({
        url,
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
        body,
      }).pipe(
        map(xhr => {
          if (typeof xhr.response.results !== 'undefined') {
            return {
              type: 'T_ESTIMATE_FETCH_HASH_SUCCESS',
              payload: {
                id: 'default',
                hash: xhr.response.hash,
                estimate: EstimateFactory({
                  installs: xhr.response?.results?.results?.total ?? 0,
                  matchingInstalls: xhr.response?.results?.matching_installs ?? 0,
                  error: false,
                  loading: false,
                  all: EstimateTokensFactory({
                    count: xhr.response?.results?.results?.total ?? 0,
                    biggestTimezone: 0,
                    optIns: xhr.response?.results?.results?.total_reachable ?? 0,
                  }),
                  matching: EstimateTokensFactory({
                    count: xhr.response?.results?.results?.matching ?? 0,
                    biggestTimezone: parseInt(
                      Object.values(
                        (xhr.response?.results?.results?.matching_by_offset as number) ?? []
                      ).reduce((acc, cur) => (acc < parseInt(cur) ? parseInt(cur) : acc), 0)
                    ),
                    optIns: xhr.response?.results?.results?.matching_notif_on ?? 0,
                  }),
                }),
              },
            }
          } else {
            return {
              type: 'T_ESTIMATE_FETCH_PROFILE_HASH_SUCCESS',
              payload: {
                id: xhr.response?.id ?? 'default',
                hash: xhr.response.hash,
                estimate: parseEstimateProfile(xhr.response.response),
              },
            }
          }
        }),
        takeUntil(action$.pipe(ofType('T_ESTIMATE_FETCH_HASH'))),
        catchError(error => {
          return action.payload.retries < MAX_RETRIES
            ? of({
                type: 'T_ESTIMATE_FETCH_HASH',
                payload: { ...action.payload, retries: action.payload.retries + 1 },
              }).pipe(delay(action.payload.retries * DEBOUNCE + 1200))
            : of({
                type: 'T_ESTIMATE_FETCH_HASH_FAILURE',
                payload: { ...action.payload, error },
              })
        })
      )
    })
  )
}

// je mets ici mais ça devrait probablement être dans un autre fichier
export const handleChannelChangeEpic = (
  action$: Observable<toggleChannelAction>,
  state$: Observable<State>
) => {
  return action$.pipe(
    filter(action => action.type === 'CAMPAIGN_TOGGLE_CHANNELS'),
    debounceTime(DEBOUNCE_CHANNEL_CHANGE),
    withLatestFrom(state$),
    mergeMap(([, state]: [any, any]) => {
      const apps = currentCampaignAppsSelector(state)
      const body: string | null | undefined = JSON.stringify({
        apps: apps.map(c => c.id).toArray(),
      })
      const url = generateUrl('api_data_group_custom_data')
      const method = 'POST'
      return ajax({
        url,
        method,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
        body,
      }).pipe(
        map(xhr => {
          if (Array.isArray(xhr.response)) {
            return {
              type: 'FETCH_ATTRIBUTES_SUCCESS',
              payload: {
                data: Immutable.OrderedMap(
                  xhr.response.map(raw => {
                    return [raw.id, rawToRecord(raw)]
                  })
                ),
                profileData: false,
              },
            }
          } else {
            return {
              type: 'FETCH_ATTRIBUTES_FAILURE',
              payload: { error: 'Wrong format returned' },
            }
          }
        }),
        takeUntil(action$.pipe(ofType('CAMPAIGN_TOGGLE_APP'))),
        catchError(error => {
          return of({
            type: 'FETCH_ATTRIBUTES_FAILURE',
            payload: { error },
          })
        })
      )
    })
  )
}
