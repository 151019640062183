import * as React from 'react'
import { useDispatch, useSelector } from 'com.batch.common/react-redux'

import { MultiResultsBanner } from 'components/common/navigation/multi-results-banner'

import { nbProfilesSelector, profileIndexSelector } from 'com.batch/profile/store/profile.selector'

import { switchProfile } from 'com.batch/profile/usecases/switch-profile'

type Props = {
  isLoading: boolean
}

export const ProfileMultiResultsBanner = ({ isLoading }: Props): React.ReactNode => {
  const profileIndex = useSelector(profileIndexSelector)
  const nbProfiles = useSelector(nbProfilesSelector)
  const dispatch = useDispatch()
  const next = React.useCallback(() => {
    dispatch(switchProfile(profileIndex + 1))
  }, [dispatch, profileIndex])
  const prev = React.useCallback(() => {
    dispatch(switchProfile(profileIndex - 1))
  }, [dispatch, profileIndex])

  if (!isLoading && nbProfiles > 1)
    return (
      <MultiResultsBanner
        nbResults={nbProfiles}
        currentResult={profileIndex + 1}
        next={next}
        prev={prev}
      />
    )
}
