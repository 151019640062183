
import { type List } from 'immutable'
import { createSelector } from 'reselect'

import { type fetchingState, type State } from 'com.batch.redux/_records'
import {
  type SenderIdentityRecord,
  type SenderIdentityStateRecord,
} from 'com.batch.redux/corelogic/records/sender-identity.records'

type extract<T> = (arg1: State) => T

export const senderIdentitySelector = (state: State): SenderIdentityStateRecord => {
  return state.senderIdentity
}

export const senderIdentityLoadingStateSelector: extract<fetchingState> = createSelector(
  senderIdentitySelector,
  senderIdentityState => senderIdentityState.loadingState
)

export const selectedSenderIdentitySelector: extract<SenderIdentityRecord> = createSelector(
  senderIdentitySelector,
  senderIdentityState => senderIdentityState.selected.current
)

export const automationsUsingSelectedSenderIdentitySelector: extract<number> = createSelector(
  senderIdentitySelector,
  senderIdentityState => senderIdentityState.selected.usedByCount
)

export const previousSenderIdentitySelector: extract<SenderIdentityRecord> = createSelector(
  senderIdentitySelector,
  senderIdentityState => senderIdentityState.selected.previous
)

export const selectedSenderIdentityHasChangedSelector: extract<boolean> = createSelector(
  senderIdentitySelector,
  senderIdentityState => senderIdentityState.selected.hasChanged
)

export const creatingSenderIdentitySelector: extract<boolean> = createSelector(
  senderIdentitySelector,
  senderIdentityState => senderIdentityState.creating
)

export const senderIdentitiesSelector: extract<List<SenderIdentityRecord>> = createSelector(
  senderIdentitySelector,
  senderIdentityState => {
    return senderIdentityState.entities
      .toList()
      .sort((a, b) => (a.sendingName.toLowerCase() < b.sendingName.toLowerCase() ? -1 : 1))
  }
)

export const canSaveSelector: extract<boolean> = createSelector(
  creatingSenderIdentitySelector,
  selectedSenderIdentitySelector,
  selectedSenderIdentityHasChangedSelector,
  (creating, selectedItem, hasChanged) =>
    (creating &&
      selectedItem.sendingName !== '' &&
      selectedItem.sendingPrefix !== '' &&
      selectedItem.sendingDomain !== '') ||
    (!creating && hasChanged)
)

export const canCancelSelector: extract<boolean> = createSelector(
  creatingSenderIdentitySelector,
  selectedSenderIdentityHasChangedSelector,
  (creating, hasChanged) => creating || (!creating && hasChanged)
)
