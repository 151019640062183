import * as React from 'react'

import { Skeleton } from 'components/common/empty-states'
import { Icon, type availableIcons } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Ellipsis } from 'components/styled/text'

import { PushSubscriptionTitle, PushSubscriptionMessage } from './push-subscription-block.styles'

import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'
import { ProfileTooltip } from 'com.batch/profile/ui/components/profile-hero/profile-hero.styles'

type Props = {
  icon: availableIcons
  isLoading: boolean
  message: string
  subscriptionIcon: React.ReactNode
  subscriptionStatus: SubscriptionStatus
  title: string
  tooltipMessage?: string
  tooltipTitle?: string
}

export const PushSubscriptionBlock = ({
  icon,
  isLoading,
  message,
  subscriptionIcon,
  subscriptionStatus,
  title,
  tooltipMessage,
  tooltipTitle,
}: Props): React.ReactElement => {
  const titleBlock = React.useMemo(
    () => (
      <PushSubscriptionTitle>
        <Skeleton h={13} w={13}>
          <Icon icon={icon} />
        </Skeleton>
        <Skeleton h={13} w={50}>
          {title}
        </Skeleton>
        <Skeleton h={13} w={13}>
          {subscriptionIcon}
        </Skeleton>
      </PushSubscriptionTitle>
    ),
    [icon, title, subscriptionIcon]
  )

  return (
    <div>
      <PushSubscriptionTitle>
        {(tooltipTitle || tooltipMessage) && !isLoading ? (
          <Tooltip
            tooltip={
              <ProfileTooltip $minWidth={235}>
                <div className="profile-tooltip-title">{tooltipTitle}</div>
                <div className="profile-tooltip-message">{tooltipMessage}</div>
              </ProfileTooltip>
            }
            placement="bottom-start"
            minWidth={205}
          >
            {titleBlock}
          </Tooltip>
        ) : (
          titleBlock
        )}
      </PushSubscriptionTitle>
      <PushSubscriptionMessage subscriptionStatus={subscriptionStatus}>
        <Skeleton h={13} w={100}>
          <Ellipsis>{message}</Ellipsis>
        </Skeleton>
      </PushSubscriptionMessage>
    </div>
  )
}
