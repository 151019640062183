import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

type DeleteSenderIdentityAction = {
  type: 'DELETE_SENDER_IDENTITY'
}
export type DeleteSenderIdentitySuccessAction = {
  type: 'DELETE_SENDER_IDENTITY_SUCCESS'
  payload: number
}
type DeleteSenderIdentityFailureAction = {
  type: 'DELETE_SENDER_IDENTITY_FAILURE'
  payload: {
    error: string
  }
}

export type DeleteSenderIdentityActions =
  | DeleteSenderIdentityAction
  | DeleteSenderIdentitySuccessAction
  | DeleteSenderIdentityFailureAction

export const deleteSenderIdentity = (
  senderIdentityId: number
): DispatchExtraBoundFn<Promise<number>> => {
  return (dispatch, getState, { senderIdentityGateway }) => {
    const project = currentProjectSelector(getState())
    return promiseActionCreator({
      dispatch,
      promise: senderIdentityGateway.deleteSenderIdentity(project.projectKey, senderIdentityId),
      actionName: 'DELETE_SENDER_IDENTITY',
    })
  }
}
