import * as React from 'react'

import { Ellipsis } from 'components/styled/text'

import { AttributeRow } from 'com.batch/profile/ui/components/attributes-block/attribute-row'

type LabelAttributeProps = {
  name: string
  value?: string
  isCopyable?: boolean
}

export const LabelAttributeRow = ({
  name,
  value,
  isCopyable = true,
}: LabelAttributeProps): React.ReactElement => {
  return (
    <AttributeRow name={name} icon="label" copyableValue={value} isCopyable={isCopyable}>
      <Ellipsis title={value}>{value}</Ellipsis>
    </AttributeRow>
  )
}
