import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { Icon } from 'components/common/svg-icon'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { humanizeDayjs } from 'com.batch.common/utils'

import { browsers } from 'com.batch/profile/constants/browsers'
import { platforms } from 'com.batch/profile/constants/platforms'
import { type Platform, type Browser } from 'com.batch/profile/infra/debug/models/shared.model'
import { type SubscriptionStatus } from 'com.batch/profile/models/profile.records'
import {
  Debug,
  LastVisitDate,
  PlatformButtonContainer,
  PlatformName,
  PlatformDetails,
  PlatformStatus,
} from 'com.batch/profile/ui/components/profile-hero/profile-platforms/platform-button.styles'
import { ProfileMockupOptin } from 'com.batch/profile/ui/components/profile-hero/profile-platforms/profile-mockup-optin'

type Props = {
  browser?: Browser
  version?: string
  href?: string
  isImported?: boolean
  lastActivity: string
  name: string
  onClick?: () => void
  platform: Platform
  subscriptionStatus: SubscriptionStatus
}

export const PlatformButton = ({
  browser,
  href,
  isImported,
  lastActivity,
  name,
  onClick,
  platform,
  version,
  subscriptionStatus,
}: Props): React.ReactElement => {
  const kind = React.useMemo(() => (isImported ? 'imported' : 'native'), [isImported])

  const platformData = React.useMemo(
    () => (browser ? browsers[browser] : platforms[platform]),
    [platform, browser]
  )

  const containerProps: any = React.useMemo(
    () => (href ? { as: 'a', href, target: '_blank', className: 'fs-exclude' } : { onClick }),
    [href, onClick]
  )

  const lastActivityDate = React.useMemo(
    () =>
      lastActivity && dayjs(lastActivity).isValid()
        ? humanizeDayjs({ date: dayjs(lastActivity) })
        : null,
    [lastActivity]
  )

  const subtitle = React.useMemo(() => {
    if (isImported)
      return (
        <React.Fragment>
          <Icon icon="import" style={{ marginRight: 6 }} />
          Imported token
        </React.Fragment>
      )

    return lastActivityDate ? `Last visit ${lastActivityDate}` : 'No last visit'
  }, [isImported, lastActivityDate])

  return (
    <ThemeProvider theme={{ platformButtonKind: kind }}>
      <PlatformButtonContainer {...containerProps}>
        <PlatformDetails>
          <PlatformName platform={platformData.icon}>
            <Icon icon={platformData.icon} />
            <span>{name}</span>{' '}
            {version && <span className="profile_platform_version">{version}</span>}
          </PlatformName>
          <LastVisitDate>{subtitle}</LastVisitDate>
        </PlatformDetails>
        <PlatformStatus>
          <ProfileMockupOptin
            kind={kind}
            size="short"
            device={`${browser ? 'WEB' : ''}name`}
            subscriptionStatus={subscriptionStatus}
          />
          <Debug>
            <div>
              {platform === 'WEBPUSH' ? 'View in debug' : 'Inspect'}
              <Icon icon="arrow-long-right" />
            </div>
          </Debug>
        </PlatformStatus>
      </PlatformButtonContainer>
    </ThemeProvider>
  )
}
