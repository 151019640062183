import { type Map, type List } from 'immutable'
import { createSelector } from 'reselect'

import { type Dayjs, dayjs } from 'com.batch.common/dayjs.custom'

import {
  OrchestrationAnalyticsFactory,
  type OrchestrationAnalyticsRecord,
  type OrchestrationAnalyticsDateRangeRecord,
} from './orchestration-analytics.state'

import { type DynamicDimension } from '../usecases/fetch-dynamic-stats.helper'
import { type State } from 'com.batch.redux/_records'

import {
  getDailyRange,
  getTemporalityRange,
} from 'com.batch/orchestration-analytics/infra/formats/get-range'
import { type BarChartData } from 'com.batch/shared/infra/types/chart-data'

type Extract<T> = (arg1: State) => T

export const orchestrationAnalyticsMapSelector: Extract<
  Map<string, OrchestrationAnalyticsRecord>
> = state => state.orchestrationAnalytics

export const orchestrationAnalyticsFiltersSelector: (
  arg1: State
) => (arg1: string) => Map<DynamicDimension, List<string>> = createSelector(
  orchestrationAnalyticsMapSelector,
  orchestrationAnalytics => (token: string) =>
    orchestrationAnalytics.get(token, OrchestrationAnalyticsFactory()).filters
)
export const orchestrationAnalyticsDateRangeSelector: (
  arg1: State
) => (arg1: string) => OrchestrationAnalyticsDateRangeRecord | null | undefined = createSelector(
  orchestrationAnalyticsMapSelector,
  orchestrationAnalytics => (token: string) =>
    orchestrationAnalytics.get(token, OrchestrationAnalyticsFactory()).dateRange
)

export const bounceByDaysRangeSelector: (arg1: State) => (arg1: string) => BarChartData[] =
  createSelector(orchestrationAnalyticsMapSelector, orchestrationAnalytics => (token: string) => {
    const bounces = orchestrationAnalytics
      .get(token, OrchestrationAnalyticsFactory())
      .get('channels')
      .get('email')
      .get('bounces')
      .get('periods')

    const dateRange = orchestrationAnalytics
      .get(token, OrchestrationAnalyticsFactory())
      .get('dateRange')
    let from: Dayjs | null = null
    let to: Dayjs | null = dayjs.utc()

    if (dateRange) {
      from = dateRange.from
      to = dateRange.to
    } else {
      const bouncesDates = bounces.reduce<Array<any>>((acc, _, key) => [...acc, dayjs(key)], [])
      if (bouncesDates.length > 0) {
        from = dayjs.min(bouncesDates)
        to = dayjs.max(bouncesDates)
      }
    }

    if (!from || !to) return []

    const diff = to.diff(from, 'day')
    switch (true) {
      case diff < 31:
        return getDailyRange({ bounces, from, to })
      case diff < 77:
        return getTemporalityRange({ bounces, from, to, unit: 'week' })
      default:
        return getTemporalityRange({ bounces, from, to, unit: 'month' })
    }
  })
