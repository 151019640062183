import * as React from 'react'

import { InfoPanelPushCounter } from 'components/project/campaign/push/preview/info-panel-push-counter'

import { type MessageBuilderField } from 'com.batch/message-builder/models/message-builder-field'
import { PushMediaInfoPanel } from 'com.batch/message-builder/ui/components/push-media-info-panel'
import { SmsMessageInfoPanel } from 'com.batch/sms/ui/components/sms-message-info-panel/sms-message-info-panel'

type Props = {
  messageId: string
  field: MessageBuilderField
  value: string
  clearEditing?: () => void
}
export const FieldInfoPanel = ({
  field,
  value,
  messageId,
  clearEditing,
}: Props): React.ReactElement | null => {
  switch (field) {
    case 'image':
    case 'icon':
    case 'pushIcon':
    case 'pushPicture': {
      return <PushMediaInfoPanel field={field} messageId={messageId} clearEditing={clearEditing} />
    }
    case 'title':
    case 'message':
    case 'pushTitle':
    case 'pushBody':
      return <InfoPanelPushCounter field={field} value={value} />
    case 'smsMessage':
      return <SmsMessageInfoPanel value={value} />
    default:
      return null
  }
}
