import {
  SenderIdentityFactory,
  SenderIdentityStateFactory,
  type SenderIdentityStateRecord,
} from 'com.batch.redux/corelogic/records/sender-identity.records'
import { type CountBySenderIdentityActions } from 'com.batch.redux/corelogic/usecases/sender-identity/count-orchestrations-using-sender-identity'
import { type CreateSenderIdentityActions } from 'com.batch.redux/corelogic/usecases/sender-identity/create-sender-identities'
import { type DeleteSenderIdentityActions } from 'com.batch.redux/corelogic/usecases/sender-identity/delete-sender-identities'
import { type FetchSenderIdentitiesActions } from 'com.batch.redux/corelogic/usecases/sender-identity/fetch-sender-identities'
import { type SelectSenderIdentitiesActions } from 'com.batch.redux/corelogic/usecases/sender-identity/select-sender-identities'
import { type UpdateSenderIdentityActions } from 'com.batch.redux/corelogic/usecases/sender-identity/update-sender-identities'

import { STATUS } from 'constants/common'

const initialState = SenderIdentityStateFactory()

type SenderIdentityAction =
  | FetchSenderIdentitiesActions
  | CreateSenderIdentityActions
  | UpdateSenderIdentityActions
  | DeleteSenderIdentityActions
  | SelectSenderIdentitiesActions
  | CountBySenderIdentityActions

export function senderIdentityReducer(
  state: SenderIdentityStateRecord = initialState,
  action: SenderIdentityAction
) {
  switch (action.type) {
    case 'FETCH_SENDER_IDENTITIES':
    case 'CREATE_SENDER_IDENTITY':
    case 'UPDATE_SENDER_IDENTITY':
    case 'DELETE_SENDER_IDENTITY':
      return state.set('loadingState', STATUS.LOADING)
    case 'FETCH_SENDER_IDENTITIES_SUCCESS':
      return state
        .set('loadingState', STATUS.LOADED)
        .set('entities', action.payload.toMap())
        .setIn(['selected', 'current'], action.payload.toList().get(0, SenderIdentityFactory()))
        .setIn(['selected', 'previous'], action.payload.toList().get(0, SenderIdentityFactory()))
    case 'CREATE_SENDER_IDENTITY_SUCCESS':
      return state
        .set('loadingState', STATUS.LOADED)
        .setIn(['entities', action.payload.id], action.payload)
        .setIn(['selected', 'current'], action.payload)
        .setIn(['selected', 'previous'], action.payload)
        .setIn(['selected', 'hasChanged'], false)
        .setIn(['selected', 'usedByCount'], 0)
        .set('creating', false)

    case 'UPDATE_SENDER_IDENTITY_SUCCESS':
      return state
        .set('loadingState', STATUS.LOADED)
        .setIn(['entities', action.payload.id], action.payload)
        .setIn(['selected', 'current'], action.payload)
        .setIn(['selected', 'previous'], action.payload)
        .setIn(['selected', 'hasChanged'], false)
    case 'DELETE_SENDER_IDENTITY_SUCCESS':
      return state
        .set('loadingState', STATUS.LOADED)
        .deleteIn(['entities', action.payload])
        .setIn(['selected', 'current'], state.entities.toList().get(0, SenderIdentityFactory()))
    case 'COUNT_ORCHESTRATIONS_USING_SI_SUCCESS':
      return state.setIn(['selected', 'usedByCount'], action.payload)
    case 'FETCH_SENDER_IDENTITIES_FAILURE':
      return state.set('loadingState', STATUS.ERROR)
    case 'SELECT_SENDER_IDENTITY':
      return state
        .setIn(['selected', 'current'], action.payload)
        .setIn(['selected', 'previous'], action.payload)
        .setIn(['selected', 'hasChanged'], false)
    case 'UPDATE_SELECTED_SENDER_IDENTITY':
      return state
        .setIn(['selected', 'current'], action.payload)
        .setIn(['selected', 'hasChanged'], true)
    case 'INIT_CREATING_SENDER_IDENTITY':
      return state.set('creating', true).setIn(['selected', 'current'], SenderIdentityFactory())
    case 'CANCEL_CREATING_AND_SELECT_PREVIOUS':
      return state
        .set('creating', false)
        .setIn(['selected', 'current'], state.selected.previous)
        .setIn(['selected', 'hasChanged'], false)
    case 'CANCEL_CREATING_AND_SELECT_NEW':
      return state
        .set('creating', false)
        .setIn(['selected', 'current'], action.payload)
        .setIn(['selected', 'previous'], action.payload)
        .setIn(['selected', 'hasChanged'], false)
    default:
      return state
  }
}
