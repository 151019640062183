import Immutable from 'immutable'

import {
  OrchestrationListStateFactory,
  type OrchestrationListStateRecord,
} from 'com.batch/orchestration-list/store/orchestration-list.state'

import { type FetchOrchestrationsDataActions } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import { OrchestrationListStatsStateFactory } from 'com.batch/orchestration-list/models/orchestration-list-stats-state.records'
import { extractUrlParamsAndRestoreSavedSort } from 'com.batch/orchestration-list/ui/helpers/extract-url-params'
import { type ChangeOrchestrationStateActions } from 'com.batch/orchestration-list/usecases/change-orchestration-state'
import { type DeleteOrchestrationActions } from 'com.batch/orchestration-list/usecases/delete-orchestration'
import { type FetchCampaignsListActions } from 'com.batch/orchestration-list/usecases/fetch-orchestrations-list'

type Actions =
  | ChangeOrchestrationStateActions
  | FetchCampaignsListActions
  | FetchOrchestrationsDataActions
  | DeleteOrchestrationActions

export const orchestrationListReducer = (
  state: OrchestrationListStateRecord = OrchestrationListStateFactory(
    extractUrlParamsAndRestoreSavedSort(window.location)
  ),
  action: Actions
): OrchestrationListStateRecord => {
  switch (action.type) {
    case 'UPDATE_FILTER_STATES_ORCHESTRATIONS_LIST':
      return state.set('statuses', action.payload)
    case 'UPDATE_FILTER_CHANNELS_ORCHESTRATIONS_LIST':
      return state.set('channels', action.payload)
    case 'UPDATE_FILTER_LABELS_ORCHESTRATIONS_LIST':
      return state.set('labels', action.payload)
    case 'UPDATE_FILTER_SEGMENTS_ORCHESTRATIONS_LIST':
      return state.set('segments', action.payload)
    case 'UPDATE_FILTER_DATE_RANGE_ORCHESTRATIONS_LIST':
      return state.set('dateRange', action.payload)
    case 'UPDATE_SEARCH_ORCHESTRATIONS_LIST':
      return state.set('search', action.payload)
    case 'UPDATE_SORT_ORCHESTRATIONS_LIST':
      return state
        .set('sortBy', action.payload.sortBy)
        .set('sortDirection', action.payload.sortDirection)
    case 'SET_PAGE_ORCHESTRATIONS_LIST':
      return state.set('page', action.payload)
    case 'FETCH_ORCHESTRATIONS_LIST':
      return state.set('loadingState', 'LOADING')
    case 'FETCH_ORCHESTRATIONS_LIST_FAILURE':
      return state.set('loadingState', 'ERROR')
    case 'FETCH_ORCHESTRATIONS_LIST_SUCCESS':
      return state
        .set('page', action.payload.page)
        .set('loadingState', 'LOADED')
        .set('count', action.payload.count ?? 0)
        .set('countTotal', action.payload.countTotal ?? 0)
        .set(
          'entities',
          state.entities.merge(Immutable.Map(action.payload.entities.map(cpg => [cpg.token, cpg])))
        )
        .set(
          'tokensPerPage',
          state.tokensPerPage
            .filter((_, page) => {
              if (action.payload.cacheMode === 'KEEP') return true
              if (action.payload.cacheMode === 'TRASH_AFTER_CURRENT_PAGE')
                return page < action.payload.page
              return false
            })
            .set(
              action.payload.page,
              action.payload.entities.map(orc => orc.token)
            )
        )
    case 'FETCH_ORCHESTRATIONS_LIST_DATA': {
      let data = state.data
      action.payload.tokens?.forEach(token => {
        data = data.set(
          token,
          data.get(token, OrchestrationListStatsStateFactory()).set('loadingState', 'LOADING')
        )
      })
      return state.set('data', data)
    }
    case 'FETCH_ORCHESTRATIONS_LIST_DATA_FAILURE': {
      let data = state.data
      action.payload.tokens?.forEach(token => {
        data = data.set(
          token,
          data.get(token, OrchestrationListStatsStateFactory()).set('loadingState', 'ERROR')
        )
      })
      return state.set('data', data)
    }
    case 'FETCH_ORCHESTRATIONS_LIST_DATA_SUCCESS': {
      let data = state.data
      action.payload.tokens?.forEach(token => {
        data = data.set(
          token,
          data.get(token, OrchestrationListStatsStateFactory()).set('loadingState', 'LOADED')
        )
      })
      return state.set('data', data.merge(action.payload.parsed))
    }
    case 'CHANGE_ORCHESTRATION_STATE_SUCCESS':
      return state.set(
        'entities',
        state.entities.map(campaign =>
          campaign.token === action.payload.token
            ? campaign.set('state', action.payload.state).set('loadingState', 'LOADED')
            : campaign
        )
      )
    case 'CHANGE_ORCHESTRATION_STATE':
    case 'DELETE_ORCHESTRATION':
      return state.set(
        'entities',
        state.entities.map(campaign =>
          campaign.token === action.payload ? campaign.set('loadingState', 'LOADING') : campaign
        )
      )

    case 'DELETE_ORCHESTRATION_SUCCESS':
      return state
        .set(
          'tokensPerPage',
          state.tokensPerPage.map(tokens => tokens.filter(token => token !== action.payload))
        )
        .set(
          'entities',
          state.entities.filter(campaign => campaign.token !== action.payload)
        )
        .set('countTotal', state.countTotal ? state.countTotal - 1 : 0)
        .set('count', state.count ? state.count - 1 : 0)
    case 'DELETE_ORCHESTRATION_FAILURE':
      return state.set(
        'entities',
        state.entities.map(campaign =>
          campaign.token === action.payload.token
            ? campaign.set('loadingState', 'LOADED')
            : campaign
        )
      )

    case 'CHANGE_ORCHESTRATION_STATE_FAILURE':
      return state.set(
        'entities',
        state.entities.map(campaign =>
          campaign.token === action.payload.token
            ? campaign.set('loadingState', 'LOADED')
            : campaign
        )
      )

    default:
      return state
  }
}
