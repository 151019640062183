import { get as _get } from 'lodash-es'
import request from 'superagent-interface-promise'

import { generateUrl } from 'com.batch.common/router'

import { AnalyticByCompanyFactory, type AnalyticByCompanyRecord } from './console.records'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { AnalyticDataFactory } from 'com.batch.redux/stat.records'
// ====================== FETCH DATA BY COMPANY
export const fetchDataByCompany = ({
  company,
}: {
  company: CompanyRecord
}): Promise<AnalyticByCompanyRecord> => {
  return request.get(generateUrl('console_api_company_analytics', { companyId: company.id })).then(
    response => {
      const data = response.body
      const result = AnalyticByCompanyFactory({
        companyId: company.id,
        data: AnalyticDataFactory({
          daus: _get(data, 'daus', 0),
          maus: _get(data, 'maus', 0),
          starts: _get(data, 'starts', 0),
          installs: _get(data, 'installs', 0),
          transactions: _get(data, 'transactions', 0),
          tokens: _get(data, 'push_token', 0),
          pushes: _get(data, 'pushs_sent', 0),
          deletedTokens: _get(data, 'push_tokens_deleted', 0),
          optOut: _get(data, 'pushs_opt-out', 0),
        }),
      })

      return result
    },
    error => {
      throw error.body
    }
  )
}
