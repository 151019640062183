import styled from 'styled-components'

import { Button } from 'components/common/button'

export const FiltersButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  ${Button} {
    overflow: inherit;
  }
`
