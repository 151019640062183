import * as React from 'react'

import { type availableIcons, Icon } from 'components/common/svg-icon'

import { AttributePicker } from './query.styles'

import { type QueryAttributeRecord } from 'com.batch.redux/query/query.records'

export type AttFilter = {
  id: string
  icon?: availableIcons
  label: string
  filter: (arg1: QueryAttributeRecord) => boolean
  subFilters?: Array<AttFilter>
}

const FILTER_NATIVE: AttFilter = {
  filter: attr =>
    attr.api.substring(0, 2) === 'b.' ||
    attr.api.substring(0, 3) === 'bt.' ||
    attr.api.substring(0, 3) === 'be.',
  id: 'native',
  label: 'Built-in',
  subFilters: [
    {
      id: 'device',
      label: 'Device',
      filter: attr =>
        [
          'b.carrier_code',
          'b.city_code',
          'b.device_type',
          'b.app_version',
          'b.os_version',
          'b.install_date',
          'b.push_service',
        ].includes(attr.api),
    },
    {
      id: 'interaction',
      label: 'Interaction',
      filter: attr =>
        [
          'b.position',
          'b.last_email_marketing_open',
          'b.last_push_date',
          'b.transaction_tracked',
          'b.last_email_click_marketing',
          'b.last_visit_date',
        ].includes(attr.api),
    },
    {
      id: 'reachability',
      label: 'Reachability',
      filter: attr =>
        [
          'b.is_email_optin',
          'b.is_sms_optin',
          'b.is_push_optin',
          'b.has_custom_id',
          'b.email_domain',
        ].includes(attr.api),
    },
  ],
}
const FILTER_EVENT: AttFilter = {
  filter: attr => attr.api.substring(0, 2) === 'e.',
  id: 'event',
  label: 'Events',
}
const FILTER_PROFILE: AttFilter = {
  filter: attr =>
    attr.api.substring(0, 2) !== 'b.' &&
    attr.api.substring(0, 3) !== 'be.' &&
    attr.api.substring(0, 2) !== 'e.' &&
    attr.api.substring(0, 2) !== 'bt.',
  id: 'profile',
  label: 'Attributes',
  subFilters: [
    {
      id: 'array',
      label: 'Array',
      filter: attr => attr.type === 'TAG',
      icon: 'one-of',
    },
    {
      id: 'bool',
      label: 'Boolean',
      filter: attr => attr.type === 'BOOLEAN',
      icon: 'boolean',
    },
    {
      id: 'date',
      label: 'Date',
      filter: attr => attr.type === 'DATE',
      icon: 'calendar',
    },
    {
      id: 'string',
      label: 'Text',
      filter: attr => attr.type === 'STRING',
      icon: 'text',
    },
    {
      id: 'number',
      label: 'Number',
      filter: attr => attr.type === 'INTEGER' || attr.type === 'FLOAT',
      icon: 'integer',
    },
    {
      id: 'link',
      label: 'Links',
      filter: attr => attr.type === 'URL',
      icon: 'link',
    },
  ],
}
const FILTER_CUSTOM: AttFilter = {
  filter: attr => attr.api.substring(0, 1) === 'c' || attr.api.substring(0, 1) === 't',
  id: 'custom',
  label: 'Install data',
}
const FILTER_USER: AttFilter = {
  filter: attr => attr.api.substring(0, 1) === 'u',
  id: 'user',
  label: 'User data',
}
export const profileFilters = [FILTER_NATIVE, FILTER_PROFILE, FILTER_EVENT]
export const mepFakeOmniFormFilters = [FILTER_NATIVE, FILTER_CUSTOM, FILTER_USER, FILTER_EVENT]

type Props = {
  attribute: QueryAttributeRecord
  isSelected: boolean
  withIcon?: boolean
  onClick: () => void
}

export const AttributeButton = ({ attribute, isSelected, onClick, withIcon }: Props) => {
  return (
    <AttributePicker isSelected={isSelected} key={attribute.api} onClick={onClick}>
      {withIcon && <Icon icon={attribute.icon} thickness={1.3} />}
      <span id={`attribute-${attribute.label}`}>{attribute.label}</span>
    </AttributePicker>
  )
}
