import { type MessageNodeRecord } from '../models/journey.records'
import { isEmailContentComplete, isPushContentComplete } from '../models/journey.selectors'
import { messageNodesSelector } from 'com.batch/orchestration/store/orchestration.composed.selectors'
import { getSmsContentForActiveLanguageSelector } from 'com.batch/sms/store/sms.selector'
import { type DispatchBoundFn } from 'com.batch.redux/_records'
import { subscriptionStatusSelector } from 'com.batch.redux/target/target.selector'

import {
  type EmailContentRecord,
  EmailMessageFactory,
  PushMessageFactory,
} from 'com.batch/message/models/message.records'
import { countSmsMessage } from 'com.batch/sms/usecases/count-sms-message'
import { validateSmsFields } from 'com.batch/sms/usecases/validate-sms-fields'

type requiredEmailFieldsId = 'fromEmail' | 'name' | 'senderIdentityId' | 'html' | 'subject'
const requiredEmailFields: Array<requiredEmailFieldsId> = [
  'fromEmail',
  'name',
  'senderIdentityId',
  'html',
  'subject',
]
export const getMissingFields = (
  content?: EmailContentRecord | null
): Array<requiredEmailFieldsId> => {
  return requiredEmailFields.filter(field => !content || !content.get(field))
}

export const checkIncompleteMessageNode =
  (node: MessageNodeRecord): DispatchBoundFn<void> =>
  (dispatch, getState) => {
    const targetedUserbase = subscriptionStatusSelector(getState())
    let isIncomplete = false
    const currentlySeenAsIncomplete = node.errors.has('INCOMPLETE_MESSAGE')
    if (node.messageConfig.channel === 'sms') {
      const smsContent = getSmsContentForActiveLanguageSelector(getState())(
        node.messageConfig.messageTypedId
      )

      const { parts } = countSmsMessage({
        message: smsContent.smsMessage,
        countStop: targetedUserbase === 'marketing',
      })

      isIncomplete =
        validateSmsFields({
          message: smsContent.smsMessage,
          parts,
        }).size > 0
    }
    if (node.messageConfig.channel === 'email') {
      isIncomplete = !isEmailContentComplete(
        getState().message.email.get(node.messageConfig.messageTypedId, EmailMessageFactory())
          .localizedContent
      )
    }
    if (node.messageConfig.channel === 'push') {
      isIncomplete = !isPushContentComplete(
        getState().message.push.get(node.messageConfig.messageTypedId, PushMessageFactory())
          .localizedContent
      )
    }
    if (currentlySeenAsIncomplete !== isIncomplete) {
      dispatch({
        type: 'FLAG_INCOMPLETE',
        payload: { messageId: node.messageConfig.messageTypedId, incomplete: isIncomplete },
      })
    }
  }

export const checkIncompleteMessageNodes = (): DispatchBoundFn<void> => (dispatch, getState) => {
  const nodes = messageNodesSelector(getState())
  nodes.forEach((node: MessageNodeRecord) => {
    dispatch(checkIncompleteMessageNode(node))
  })
}
