import * as React from 'react'
import { ThemeProvider, ThemeContext } from 'styled-components'

import { Button } from 'components/common/button/button.styles'
import { Icon } from 'components/common/svg-icon'

import {
  EmptyCell,
  TableRow,
  TableContainer,
  TableOrderContainer,
  TableEmptyContent,
  TableTemplateCellWrapper,
} from './table.styles'

export type SortDirection = 'asc' | 'dsc'

type TableOrderProps = {
  sort?: false | SortDirection
  align?: 'left' | 'right'
  children?: React.ReactNode
  onClick: () => void
  style?: Record<any, any>
}

type TableBodyProps = {
  emptyTemplate?: React.ReactNode
  templateSize?: number
  children?: React.ReactNode
  style?: React.CSSProperties
}

export const TableBody = ({
  children,
  emptyTemplate,
  templateSize,
  ...rest
}: TableBodyProps): React.ReactElement => {
  const themeContext = React.useContext(ThemeContext)
  const lineList = React.useMemo(() => {
    const emptyTableSize = templateSize ?? 10
    const lineList: Array<undefined | React.ReactNode> = []
    for (let i = 0; i < emptyTableSize; i++) lineList.push(emptyTemplate)
    return lineList
  }, [emptyTemplate, templateSize])

  return (
    <div {...rest}>
      {themeContext.isEmpty || themeContext.isLoading
        ? lineList.map((l, e) => <TableRow key={e}>{l}</TableRow>)
        : children}
    </div>
  )
}

export const TableCellOrder = React.forwardRef<HTMLDivElement, TableOrderProps>(
  ({ children, sort, align = 'left', onClick, style }, ref): React.ReactElement => {
    return (
      <TableOrderContainer ref={ref} align={align} isActive={Boolean(sort)} style={style}>
        <Button onClick={onClick} addOn={children ? 'suffix' : undefined}>
          {children}
          <Icon icon={sort === 'asc' ? 'arrow-long-down' : 'arrow-long-up'} />
        </Button>
      </TableOrderContainer>
    )
  }
)

type TableProps = {
  template: string
  children: React.ReactNode
  style?: any
  striped?: boolean
  rowHeight?: number
}

export const Table = ({
  template,
  children,
  style,
  striped,
  rowHeight,
}: TableProps): React.ReactElement => {
  return (
    <ThemeProvider theme={{ template, rowHeight }}>
      <TableContainer striped={striped} style={style}>
        {children}
      </TableContainer>
    </ThemeProvider>
  )
}

// ======================= EMPTY STATE

type TableTemplateCellProps = {
  template: string
  gap?: number
  align?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end'
  minSize?: number
  maxSize?: number
  forceLoading?: boolean
  isMultiline?: boolean
}

export const TableTemplateCell = ({
  template,
  gap,
  align,
  minSize = 50,
  maxSize = 80,
  forceLoading,
  isMultiline,
}: TableTemplateCellProps): React.ReactElement => {
  const themeContext = React.useContext(ThemeContext)
  const isLoading = (!!themeContext && themeContext.isLoading) || !!forceLoading
  const templateRows = template.split(',')
  return (
    <TableTemplateCellWrapper isMultiline={isMultiline}>
      {templateRows.map((row, key) => (
        <TableEmptyContent key={key} align={align}>
          <TableTemplateCellContent
            row={row}
            gap={gap}
            minSize={minSize}
            maxSize={maxSize}
            isLoading={isLoading}
            style={{ height: themeContext.rowHeight }}
          />
        </TableEmptyContent>
      ))}
    </TableTemplateCellWrapper>
  )
}

export type TableTemplateCellContentProps = {
  row: string
  gap?: number
  minSize?: number
  maxSize?: number
  isLoading: boolean
} & React.ComponentProps<'div'>

const TableTemplateCellContent = ({
  gap,
  row,
  minSize = 50,
  maxSize = 80,
  isLoading,
}: TableTemplateCellContentProps): React.ReactNode => {
  const randomSize = React.useMemo(
    () => Math.floor(Math.random() * (maxSize - minSize)) + minSize,
    [maxSize, minSize]
  )
  const templateElems = row.trim().split(' ')
  return templateElems.map((n, i) => {
    return (
      <EmptyCell
        key={i}
        $width={n === '1fr' ? `${randomSize}%` : n}
        $marginRight={gap && i + 1 !== templateElems.length ? gap : 0}
        $theme={isLoading ? 'loading' : 'empty'}
      />
    )
  })
}
