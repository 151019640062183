import styled, { css } from 'styled-components'

import { EmptyField } from 'components/common/empty-states'
import * as colors from 'components/styled/tokens/colors'
import * as schemes from 'components/styled/tokens/schemes'
import * as shadows from 'components/styled/tokens/shadows'

// ====================== STYLED CSS
const ResetInput = css`
  &,
  &:focus,
  &:focus-visible,
  &:focus-within {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 1s ease-in-out 9999s;
  }

  &[type='number'] {
    appearance: textfield;
  }
`

export const focusedStyle = css`
  transition: all ease-out 0.2s;
  background: ${p => (p.theme.kind === 'filter' ? colors.opacifyActive : colors.fill)};
  box-shadow:
    0 0 0 1px ${p => (p.theme.kind === 'filter' ? 'transparent' : colors.strokeAction)},
    inset 0 0 0 1px ${p => (p.theme.kind === 'filter' ? 'transparent' : colors.strokeAction)};
`

type InputItemStyleProps = {
  customDisplayMode: 'transparent-unless-over'
  $delayHover: boolean
  invalid: boolean
  forcedFocus: boolean
  disabled: boolean
}

const InputItemStyles = css<InputItemStyleProps>`
  display: flex;
  align-items: center;
  min-height: ${p => (p.theme.size === 'small' ? 28 : 36)}px;
  padding: 0 ${p => (p.theme.size === 'small' ? 8 : 12)}px;
  font-weight: 400;
  color: ${colors.text};
  border-radius: 6px;
  /* Theme */
  ${p =>
    p.theme.kind === 'filter'
      ? css<InputItemStyleProps>`
          color: ${colors.textLight};
          background: ${p.customDisplayMode === 'transparent-unless-over'
            ? 'transparent'
            : colors.opacifyActive};
          box-shadow: ${p =>
            p.invalid ? `0 0 0 1px ${colors.strokeDanger}, ${shadows.raised}` : 'none'};
        `
      : css<InputItemStyleProps>`
          background: ${colors.fill};
          box-shadow:
            0 0 0 1px ${p => (p.invalid ? colors.strokeDanger : colors.stroke)},
            ${shadows.raised};
        `}

  &:hover {
    ${p =>
      p.$delayHover &&
      css<InputItemStyleProps>`
        transition: background 0.1s linear 0.15s;
      `}
    background: ${p => p.theme.kind === 'filter' && colors.opacifyHover};
    color: ${p => (p.theme.kind === 'filter' ? colors.textLight : colors.text)};
  }
  /* Focus state */

  &:focus-within {
    ${focusedStyle}
  }
  ${p => p.forcedFocus && focusedStyle}
  /* Disabled state */
  ${p =>
    p.disabled &&
    css`
      color: ${colors.text};
      -webkit-text-fill-color: ${colors.text};
      background: ${p =>
        p.theme.kind === 'filter' ? schemes.darklucent['10'] : colors.fillDisabled};
    `}
`

const InputContainer = styled.div<{
  disabled?: boolean
  $delayHover?: boolean
  invalid?: boolean
}>`
  transition: width 0.2s ease-in-out;
  &:focus,
  &:focus-visible,
  &:focus-within {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  ${InputItemStyles}
  padding: 0;
  cursor: text;
  /* Input */
  input,
  textarea,
  select {
    ${ResetInput}
    flex: 1 1 auto;
    width: 100%;
    align-self: stretch;
    padding: 0 ${p => (p.theme.size === 'small' ? 8 : 12)}px;
    min-height: ${p => (p.theme.size === 'small' ? 28 : 36)}px;

    &:disabled {
      cursor: not-allowed;
    }
    &::placeholder {
      color: ${colors.textLight};
      -webkit-text-fill-color: ${colors.textLight};
      opacity: 1;
    }
  }
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', Helvetica, Arial,
      serif;
    font-size: 1em;
    padding: 8px ${p => (p.theme.size === 'small' ? 8 : 12)}px;
  }

  input[type='search'] {
    padding-right: 0;
  }
  /* Empty Field */
  ${EmptyField} {
    margin: 0 ${p => (p.theme.size === 'small' ? 8 : 12)}px;
  }

  /* Input Container - Input Addons */
  .styled-input-addons {
    display: flex;
    font-weight: 500;

    &.styled-addon-pre {
      margin-left: 10px;
      margin-right: -4px;

      &.styled-addon-pre-separator {
        align-self: stretch;
        padding-right: ${p => (p.theme.size === 'small' ? 4 : 8)}px;
        border-right: 1px solid ${colors.stroke};
      }
    }

    > i {
      font-size: 1em;
      line-height: 13px;
      margin-top: -1px;
    }
  }

  /* Divider */
  hr {
    width: 100%;
    margin: 2px 0;
    border-top: 1px solid ${colors.stroke};
  }
  .styled-separator {
    flex: 0 0 1px;
    align-self: stretch;
    background: ${colors.stroke};
    margin: 7px 0;
  }
`

const FormActions = styled.div`
  ${p =>
    p.theme.horizontal &&
    css`
      padding: 0 0 0 132px;
      margin: 32px 0 0 0;
    `}
`

export { ResetInput, InputItemStyles, InputContainer, FormActions }
