import type Immutable from 'immutable'

import { type TransmissionType } from 'com.batch/shared/models/transmission-type'

export const getTransmissionType = (transmissionTypes: Immutable.Set<TransmissionType>): string => {
  if (transmissionTypes?.includes('TRANSACTIONAL') && transmissionTypes?.includes('MARKETING')) {
    return 'All'
  } else if (transmissionTypes?.includes('TRANSACTIONAL')) {
    return 'Transactional'
  } else if (transmissionTypes?.includes('MARKETING')) {
    return 'Marketing'
  } else {
    return 'Uncategorized'
  }
}
