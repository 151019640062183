import { type ReduxAction } from 'com.batch.redux/_records'

import { type OrchestrationCampaignRecord } from 'com.batch/orchestration-campaign/models/campaign.records'

export type InitOrchestrationCampaignAction = ReduxAction<
  'INIT_ORCHESTRATION_CAMPAIGN',
  OrchestrationCampaignRecord
>

export const initOrchestrationCampaign: (
  arg1: OrchestrationCampaignRecord
) => InitOrchestrationCampaignAction = campaign => ({
  type: 'INIT_ORCHESTRATION_CAMPAIGN',
  payload: campaign,
})
