import Immutable, { type List } from 'immutable'
import { get as _get, forEach as _forEach } from 'lodash-es'
import request from 'superagent-interface-promise'

import { generateUrl } from 'com.batch.common/router'

import { type CampaignRecord, type AppRecord } from 'com.batch.redux/_records'

export const fetchEventData = ({
  appId,
  eventId,
  eventDataType,
  eventDataAttributeKey,
  query,
}: {
  appId: number
  eventId: string
  eventDataType: 'label' | 'tag' | 'attribute'
  eventDataAttributeKey?: string
  query?: string
}): Promise<any> => {
  return request.get(
    `/api/app/${appId}/event-data/autocomplete/${eventId}/${eventDataType}${
      eventDataAttributeKey ? `/${eventDataAttributeKey}` : ''
    }?query=${query ? query : ''}`
  )
}

export const groupFetchEventData = ({
  appIds,
  eventId,
  eventDataType,
  eventDataAttributeName,
  query,
}: {
  appIds: Array<number>
  eventId: string
  eventDataType: 'label' | 'tag' | 'attribute'
  eventDataAttributeName?: string
  query?: string
}): Promise<any> => {
  return request
    .get(
      generateUrl('api_data_multi_event_autocomplete', {
        appIds: appIds.join(','),
        eventName: eventId,
        query,
        eventDataType,
        eventDataAttributeName: eventDataAttributeName || '',
      })
    )
    .then(response => {
      const data = _get(response.body.results, eventId, [])
      return Immutable.List(data.map(v => v.value))
    })
}

export const groupFetchAttributeValues = ({
  appIds,
  attributeId,
  query,
}: {
  appIds: Array<number>
  attributeId: string
  query?: string
}): Promise<List<string>> => {
  return request
    .get(
      generateUrl('api_data_multi_attribute_autocomplete', {
        appIds: appIds.join(','),
        attributeId,
        query,
      })
    )
    .then(response => {
      const data = _get(response.body.results, attributeId, [])
      return Immutable.List(data.map(v => v.value))
    })
}

// -----------------------------------------------------------------------
// fetchPushAnaltyics
// query stephane for both regional data @todo : type return value
// -----------------------------------------------------------------------
export const fetchAnalyticsByRegion = ({
  appId,
  start,
  end,
  devMode = false,
}: {
  appId: number
  start: string
  end: string
  devMode: boolean
}): Promise<Array<any>> => {
  return request
    .get(`/api/app/${appId}/data/analytics/${start}/${end}/region/${devMode ? 'dev' : 'prod'}`)
    .then(
      response => {
        const data = _get(response.body, 'results.data', [])
        let results: Array<
          | any
          | {
              region: any
            }
        > = []
        data.forEach(regionByDay => {
          let row = {
            region: regionByDay.dimensions.region,
          }
          _forEach(regionByDay, (byDate, key) => {
            if (key !== 'dimensions') {
              row[key] = byDate.reduce((a, b) => a + b, 0)
            }
          })
          results.push(row)
        })
        return results
      },
      error => {
        throw error.body
      }
    )
}
// la version originale est faite dans targeting.epic, cette méthode est là pour replicate
export const estimateForCampaign = ({
  appId,
  campaign,
  clusters,
  query,
  languages,
  regions,
  requiredMlvl = 0,
}: {
  appId: number
  campaign: CampaignRecord
  clusters: Array<any>
  query?: string | null | undefined
  languages: Array<any>
  regions: Array<any>
  requiredMlvl: number
}): Promise<any> => {
  return request.post(`/api/app/${appId}/data/pushtokens`, {
    clusters: clusters,
    regions: regions,
    customAudiencesOperator: 'UNION',
    customAudiences: campaign.customAudiences.map(aud => [aud.name, aud.type]),
    languages: languages,
    query: query,
    forInApp: campaign.type === 'in-app',
    mlvl: requiredMlvl,
  })
}

export const estimateRaw = (app: AppRecord): Promise<any> => {
  return request
    .post(`/api/app/${app.id}/data/pushtokens`, {
      clusters: [],
      regions: [],
      customAudiencesOperator: 'UNION',
      customAudiences: [],
      languages: [],
      query: null,
      forInApp: false,
    })
    .then(({ body }) => {
      return body
    })
}
