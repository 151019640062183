import * as React from 'react'

type LoaderProps = {
  loading: boolean
  overlay?: boolean
  size?: 'fat' | 'tiny'
  children?: React.ReactNode
  height?: number
  left?: boolean
  right?: boolean
  noPadding?: boolean
}
// ================================================================================================
const Loader = ({
  loading,
  overlay,
  size,
  children,
  height,
  left,
  right,
  noPadding,
}: LoaderProps): React.ReactNode => {
  const classes = ['cloader']
  const loaderStyle = {
    minHeight: !!height && loading ? `${height}px` : overlay ? '100%' : 'auto',
  }
  if (overlay) classes.push('cloader--overlay')
  if (size) classes.push(`cloader--${size}`)
  const st: {
    [key: string]: string | number
  } = {}
  if (left) {
    st.left = 0
  }
  if (right) {
    st.right = 0
    st.marginRight = 0
  }
  return (
    <div className={classes.join(' ')} style={loaderStyle}>
      {loading && overlay && (
        <div className="cloader__overlay" style={noPadding ? { padding: 0 } : {}}>
          <div className="cloader__overlay__loader" style={st} />
        </div>
      )}
      {loading && !overlay && <div className="cloader__overlay__loader" style={st} />}
      {(!loading || overlay) && <div className="cloader__content">{children}</div>}
    </div>
  )
}

export default Loader
