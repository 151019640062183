import styled, { css } from 'styled-components'

import * as colors from 'components/styled/tokens/colors'

// ====================== STYLED COMPONENTS

// type ThemeProps = {
//   horizontal: boolean
//   kind: string
//   labelWidth: number
// }
const InputWrapperContainer = styled.div`
  display: flex;
  flex-direction: ${p => (p.theme.horizontal ? 'row' : 'column')};
  align-items: baseline;
  width: 100%;
  position: relative;
  margin-top: 20px;
  &:first-of-type {
    margin-top: 0;
  }

  /* Input Wrapper - Header */
  > span {
    display: flex;
    align-items: center;
    min-height: 18px;

    ${p =>
      p.theme.horizontal
        ? css`
            width: ${props => props.theme.labelWidth ?? 156}px;
            margin: 0 16px 0 0;
          `
        : css`
            margin: 0 0 8px 0;
          `}

    /* Input Wrapper - Header Label */  
    > label {
      margin: 0;
      font-size: 1em;
      font-weight: 500;
      line-height: 16px;
      color: ${colors.text};
    }
  }

  /* Input wrapper messages - container feedback and additionnal message */
  .styled-input-wrapper-message {
    display: ${props => (props.theme.kind === 'filter' ? 'none' : 'block')};
    margin-top: 14px;
  }

  /* Input Wrapper - Row form */
  .styled-row-form {
    display: flex;
    flex-wrap: wrap;
    margin: -8px -6px;

    > div {
      flex: 1;
      margin: 8px 6px !important;
    }
  }
`
export { InputWrapperContainer }
