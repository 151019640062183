import Immutable, { type RecordOf } from 'immutable'

export type DurationUnit = 'HOURS' | 'DAYS'

export type CappingRuleProps = {
  labelCode: string
  durationValue: number
  durationUnit: DurationUnit
  capping: number
  isPersisted: boolean
}

export type CappingRuleRecord = RecordOf<CappingRuleProps>

export const CappingRuleFactory = Immutable.Record<CappingRuleProps>({
  labelCode: '',
  durationValue: 1,
  durationUnit: 'HOURS',
  capping: 1,
  isPersisted: false,
} as CappingRuleProps)
