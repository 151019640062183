import * as React from 'react'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type MessagingEventRecord } from 'com.batch/profile/models/event.records'
import {
  ChannelBadge,
  statusConfig,
} from 'com.batch/profile/ui/components/events-block/event-row/channel-badge'
import { LightMessagingEventContainer } from 'com.batch/profile/ui/components/events-block/event-row/event-row.styles'
import { Time } from 'com.batch/profile/ui/components/events-block/timeline/time'

type Props = {
  event: MessagingEventRecord
  nextEvent?: MessagingEventRecord
}

export const LightMessagingEvent = ({ event, nextEvent }: Props): React.ReactElement => {
  const sendDateUtc = React.useMemo(() => {
    return dayjs.unix(event.eventDate).utc()
  }, [event.eventDate])
  const eventDate = React.useMemo(() => {
    return sendDateUtc.local()
  }, [sendDateUtc])
  return (
    <LightMessagingEventContainer
      $variant={statusConfig[event.metric].variant}
      $nextVariant={nextEvent ? statusConfig[nextEvent?.metric]?.variant : undefined}
    >
      <Time date={eventDate} />
      <ChannelBadge event={event} />
    </LightMessagingEventContainer>
  )
}
