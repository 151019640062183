import * as React from 'react'

import { Button } from 'components/common/button'

type Props = {
  close: () => void
}

export const SuccessStep = ({ close }: Props): React.ReactElement => {
  return (
    <React.Fragment>
      <p>The company deletion is in progress.</p>
      <p>It will no longer be visible once done.</p>
      <Button kind="primary" intent="neutral" onClick={close}>
        Close
      </Button>
    </React.Fragment>
  )
}
