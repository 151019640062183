import * as React from 'react'

import { useCombinedRefs } from 'components/_hooks'
import { Button } from 'components/common/button'
import { TrackingContext, trackEvent } from 'components/common/page-tracker'
import { Icon } from 'components/common/svg-icon'
import { InputWrapper } from 'components/form/input-wrapper'

import { FilterSearchContainer } from '../filter-search.styles'

type SearchProps = {
  placeholder?: string
  fullPlaceholder?: boolean
  value: string
  clearButtonText?: string
  disabled?: boolean
  width?: number
  onChange: (value: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onKeyDown?: (arg1: React.KeyboardEvent<HTMLInputElement>) => any
  autoFocus?: boolean
  isFocusedFilter?: boolean
  isSensitive?: boolean
  containerStyle?: any
  style?: any
  isTransparent?: boolean
  expandable?: boolean
  expandedMaxWidth?: number
  isClearable?: boolean
  identifier?: string
}

const FilterSearch = React.forwardRef<HTMLInputElement, SearchProps>(
  (
    {
      placeholder,
      fullPlaceholder,
      clearButtonText,
      value,
      disabled,
      isSensitive,
      width = 95,
      onChange,
      onKeyDown,
      autoFocus,
      isFocusedFilter = false,
      containerStyle,
      expandable = true,
      expandedMaxWidth = 344,
      style,
      onBlur,
      onFocus,
      identifier,
      isClearable = true,
    }: SearchProps,
    ref
  ): React.ReactElement => {
    const [isExpanded, setExpanded] = React.useState(false)
    const [isFocused, setIsFocused] = React.useState(false)
    const innerRef = React.useRef<HTMLInputElement>(null)
    const inputRef = useCombinedRefs(ref, innerRef)
    const hasValue = !!value
    const { eventLocation, searchEventCode } = React.useContext(TrackingContext)

    const handleOnBlur = React.useCallback(() => {
      setIsFocused(false)
      if (onBlur) onBlur()
      if (eventLocation !== 'unset' && searchEventCode !== 'unset' && value)
        trackEvent(searchEventCode, { location: eventLocation, keywords: value })
      if (!value) setExpanded(false)
    }, [eventLocation, searchEventCode, value, onBlur])

    const handleOnFocus = React.useCallback(() => {
      setIsFocused(true)

      if (expandable) setExpanded(true)
      if (onFocus) onFocus()
    }, [expandable, onFocus])

    const handleOnChange = React.useCallback(
      ({ target }) => {
        onChange(target.value)
      },
      [onChange]
    )

    const handleOnClickClearBtn = React.useCallback(() => {
      onChange('')
      setExpanded(false)
    }, [onChange])

    const showClearButton = isClearable && hasValue

    const displayedPlaceholder = React.useMemo(() => {
      if (fullPlaceholder) {
        return placeholder
      }
      if (!isFocused && !isFocusedFilter) {
        return 'Search'
      }
      return placeholder ? placeholder : identifier ? `Search ${identifier}...` : 'Search'
    }, [fullPlaceholder, identifier, isFocused, isFocusedFilter, placeholder])

    return (
      <InputWrapper style={{ marginTop: 0, ...style }}>
        <FilterSearchContainer
          style={{
            width: expandable ? (isExpanded ? expandedMaxWidth : width) : '100%',
            paddingRight: 0,
            paddingLeft: 0,
            ...containerStyle,
          }}
          $delayHover
          disabled={disabled}
          $hasValue={!!value}
        >
          <div className="styled-input-addons styled-addon-pre">
            <Icon icon="search" size={11} thickness={1.3} />
          </div>
          <input
            placeholder={displayedPlaceholder}
            style={{
              fontWeight: 500,
              border: 'none',
              background: 'transparent',
              textOverflow: 'ellipsis',
              paddingLeft: 8,
            }}
            type="search"
            value={value}
            onChange={handleOnChange}
            onKeyDown={onKeyDown}
            onBlur={handleOnBlur}
            className={isSensitive ? 'fs-exclude' : ''}
            autoFocus={autoFocus}
            ref={inputRef}
            disabled={disabled}
            onFocus={handleOnFocus}
          />
          {showClearButton && (
            <div className="styled-input-addons">
              <Button type="button" kind="discreet" onClick={handleOnClickClearBtn} tabIndex={-1}>
                {clearButtonText ? clearButtonText : <Icon icon="close" />}
              </Button>
            </div>
          )}
        </FilterSearchContainer>
      </InputWrapper>
    )
  }
)
FilterSearch.displayName = 'Search'
export { FilterSearch }
