import * as React from 'react'

type RestrictedInputProps = {
  transform?: (text: string) => string
  drop: string
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>

class RestrictedInput extends React.PureComponent<RestrictedInputProps> {
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (typeof this.props.transform === 'function') {
      evt.target.value = this.props.transform.call(evt.target.value)
    }
    evt.target.value = evt.target.value.replace(new RegExp(this.props.drop, 'g'), '')
    this.props.onChange(evt)
  }
  render(): React.ReactElement {
    const { drop, transform, onChange, ...rest } = this.props //eslint-disable-line
    return <input {...rest} onChange={this.onChange} />
  }
}

export default RestrictedInput
