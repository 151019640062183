// will pass query build context event to child condition, so the autocomplete knows where to look

import Immutable, { type Set } from 'immutable'
import * as React from 'react'

export const QueryBuilderContext = React.createContext<QueryBuilderContextProps>({
  context: 'targeting',
  errors: Immutable.Set(),
  touched: false,
  isSample: false,
  isProfileMode: false,
  eventId: null,
  queryId: 'main',
  maxDepthReached: false,
})

export interface QueryBuilderContextProps {
  context: 'targeting' | 'event_filter'
  eventId: string | null | undefined
  isSample: boolean
  errors: Set<string>
  touched: boolean
  isProfileMode: boolean
  queryId: string
  maxDepthReached: boolean
}
