import Immutable, { type List, type Map } from 'immutable'
import { createSelector } from 'reselect'

import { type CompanyRecord, type State } from 'com.batch.redux/_records'
import { UserFactory, type UserRecord, type UserStateRecord } from 'com.batch.redux/user.records'

export const userStateSelector = (state: State): UserStateRecord => state.user
export const companyStateSelector = (state: State): CompanyRecord => state.company

const userIdsSelector = createSelector(companyStateSelector, c => c.users)
const usersMapSelector = createSelector(userStateSelector, u => u.entities)

export const currentUserSelector: (arg1: State) => UserRecord = createSelector(
  userStateSelector,
  (userState: UserStateRecord) =>
    userState.entities.get(userState.currentUserId ? userState.currentUserId : -1, UserFactory())
)
export const usersListSelector: (arg1: State) => List<UserRecord> = createSelector(
  usersMapSelector,
  map => map.toList()
)
export const usersForCompanySelector: (arg1: State) => Map<number, UserRecord> = createSelector(
  userIdsSelector,
  usersMapSelector,
  (ids, users) => {
    const arrOfUsers: Array<[number, UserRecord]> = []
    ids.forEach(id => {
      arrOfUsers.push([id, users.get(id, UserFactory())])
    })

    return Immutable.Map(arrOfUsers)
  }
)
