import styled from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

export const LanguageDeleteButton = styled.button`
  width: 26px;
  height: 28px;
  padding: 0 0 1px 0;
  color: ${colors.textLight};
  appearance: none;
  border: none;
  outline: none;

  &:hover {
    color: ${colors.text};
  }
`
type LanguageContainerProps = {
  isActive: boolean
  validity?: boolean
  isDeletable: boolean
}
export const LanguageContainer = styled.a<LanguageContainerProps>`
  display: flex;
  align-items: center;
  min-height: 28px;
  padding: 0 ${p => (p.isDeletable ? 0 : 18)}px 0 7px;
  font-weight: 500;
  margin-bottom: 8px;
  color: ${p => (p.isActive ? colors.text : colors.textLight)};
  user-select: none;
  appearance: none;
  border: none;
  outline: none;
  background-color: ${p => (p.isActive ? schemes.darklucent['20'] : 'transparent')};
  border-radius: 5px;

  .styled-icon {
    display: block;
    position: relative;
    width: 13px;
    height: 13px;
    margin-right: 8px;

    > i {
      display: block;
      margin-top: -2px;
      color: ${p =>
        p.validity === true
          ? colors.textSuccess
          : p.validity === false
            ? colors.textDanger
            : 'none'};
    }

    &:after {
      display: ${p => (p.validity === undefined ? 'block' : 'none')};
      position: absolute;
      top: -1px;
      left: -1px;
      width: 15px;
      height: 15px;
      content: '';
      background-color: ${schemes.darklucent['20']};
      border-radius: 15px;
    }
  }
  .styled-text {
    display: block;
    flex: 1 1 auto;
    max-width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ${LanguageDeleteButton} {
    margin-left: 6px;
    opacity: ${p => (p.isActive ? 1 : 0)};
  }

  &:hover {
    color: ${p => (p.isActive ? colors.text : colors.textLight)};
    background-color: ${p => (p.isActive ? schemes.darklucent['20'] : schemes.darklucent['10'])};

    ${LanguageDeleteButton} {
      opacity: 1;
    }
  }
`

export const LanguagePickerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 28px;

  ${LanguageContainer} {
    margin-right: 8px;
  }
  hr {
    height: 20px;
    margin: 0 8px 8px 0;
    border-right: 1px solid ${colors.stroke};
  }
`
