import styled, { css } from 'styled-components'

export const color = '#7c7d81'
// type ThemeProps = {
//   placeholder?: boolean
// }
type LabelPlaceholderProps = {
  placeholder_width?: number
}
export const LabelPlaceholder = styled.span<LabelPlaceholderProps>`
  ${props =>
    props.theme.placeholder &&
    css`
      position: relative;
      color: transparent !important;
      white-space: nowrap;
      overflow: hidden;
      letter-spacing: -10000px !important;

      * {
        display: none !important;
        visibility: hidden !important;
      }

      &:before {
        display: inline-block;
        content: 'p';
        width: ${props.placeholder_width || '64%'};
        max-width: 200px;
        min-width: 48px;
        background-color: ${color};
        border-radius: 2px;
      }
    `}
`

export const TextPlaceholder = styled.p`
  ${props =>
    props.theme.placeholder &&
    css`
      position: relative;
      color: transparent !important;
      letter-spacing: -1000000px !important;
      overflow: hidden;

      > * {
        display: none !important;
        visibility: hidden !important;
      }

      &:before {
        display: inline;
        position: relative;
        z-index: 5;
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sit amet odio et urna consectetur semper. Phasellus lobortis et odio in sollicitudin.';
        background-color: ${color};
        letter-spacing: 0;
      }
    `}
`

export const ButtonPlaceholder = styled.button`
  ${props =>
    props.theme.placeholder &&
    css`
      position: relative;
      background: hsla(228, 2%, 50%, 0.28) !important;
      box-shadow: none !important;
      color: transparent !important;
      overflow: hidden;

      &:after {
        position: absolute;
        top: calc(50% - 0.5em);
        left: 10px;
        right: 10px;
        height: 1em;
        content: '';
        background-color: ${color};
        border-radius: 2px;
      }
    `}
`

export const HiddenPlaceholder = styled.span`
  ${props =>
    props.theme.placeholder &&
    css`
      opacity: 0 !important;
    `}
`

export const ShapePlaceholder = styled.path`
  ${props =>
    props.theme.placeholder &&
    css`
      fill: ${color};
    `}
`
