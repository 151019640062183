import { type List } from 'immutable'

import { type CappingRulePayload } from '../../shared/infra/metadata-service/metadata-service.api'
import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type CappingRuleRecord } from 'com.batch/capping/model/capping-rule'

type SaveCappingRulesAction = ReduxAction<'SAVE_CAPPING_RULES', List<CappingRuleRecord>>

export type SaveCappingRulesFailureAction = ReduxAction<'SAVE_CAPPING_RULES_FAILURE', string>

export type SaveCappingRulesSuccessAction = ReduxAction<
  'SAVE_CAPPING_RULES_SUCCESS',
  List<CappingRuleRecord>
>

export type SaveCappingRulesActionType =
  | SaveCappingRulesAction
  | SaveCappingRulesFailureAction
  | SaveCappingRulesSuccessAction

export const saveCappingRules = (
  labelCode: string,
  cappingRules: List<CappingRuleRecord>
): DispatchExtraBoundFn<Promise<List<CappingRuleRecord>>> => {
  return async (dispatch, getState, { metadataService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    const cappingRulesArray: Array<CappingRulePayload> = cappingRules
      .map(cappingRule => {
        return {
          durationUnit: cappingRule.durationUnit,
          durationValue: cappingRule.durationValue,
          capping: cappingRule.capping,
          label: cappingRule.labelCode,
        }
      })
      .toArray()

    const cappingRulesMap: Map<string, Array<CappingRulePayload>> = new Map([
      [labelCode, cappingRulesArray],
    ])

    return promiseActionCreator({
      dispatch,
      promise: metadataService
        .setCappingRules({
          projectKey: project.projectKey,
          cappingRules: cappingRulesMap,
          label: labelCode,
        })
        .then(() => cappingRules),
      payload: cappingRules,
      actionName: 'SAVE_CAPPING_RULES',
    })
  }
}
