import * as React from 'react'

import { InputContainer } from 'components//form'
import { useToggle } from 'components/_hooks'
import { Button } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { Icon } from 'components/common/svg-icon'

import { type InputProps } from './helper'
import { PopinMap } from './popin-map'

import { FunctionParamsFactory } from 'com.batch.redux/query/query.records'

export const InputGoogleMap = ({ condition, updateCondition }: InputProps): React.ReactElement => {
  const mapPopinState = useToggle(false)
  const [search, setSearch] = React.useState('')
  const touchedState = useToggle()
  const fp = React.useMemo(
    () => condition.functionParams ?? FunctionParamsFactory(),
    [condition.functionParams]
  )
  const updateRadius = React.useCallback(
    evt =>
      updateCondition(
        condition.set('functionParams', fp.set('radius', parseInt(evt.target.value)))
      ),
    [condition, updateCondition, fp]
  )
  const updateLat = React.useCallback(
    evt =>
      updateCondition(condition.set('functionParams', fp.set('lat', Number(evt.target.value)))),
    [condition, updateCondition, fp]
  )
  const updateLng = React.useCallback(
    evt =>
      updateCondition(condition.set('functionParams', fp.set('lng', Number(evt.target.value)))),
    [condition, updateCondition, fp]
  )
  const updateLatLng = React.useCallback(
    (lat: number, lng: number) => {
      updateCondition(condition.set('functionParams', fp.set('lat', lat).set('lng', lng)))
    },
    [condition, updateCondition, fp]
  )
  return (
    <Grid template="120px 45px 1fr">
      <InputContainer>
        <input type="number" placeholder="radius" value={fp.radius} onChange={updateRadius} />
        <div style={{ paddingRight: 10, color: '#AEB3B9' }}>meters</div>
      </InputContainer>
      <span>around</span>
      <InputContainer
        onFocus={touchedState.open}
        invalid={touchedState.value && (isNaN(fp.lat) || isNaN(fp.lng))}
      >
        <input
          type="number"
          placeholder="lat"
          style={{ textAlign: 'left' }}
          onChange={updateLat}
          value={isNaN(fp.lat) ? '' : fp.lat.toString()}
        />

        <input
          type="number"
          placeholder="lng"
          onChange={updateLng}
          value={isNaN(fp.lng) ? '' : fp.lng.toString()}
          style={{ borderLeft: '1px solid #E9E9E9', textAlign: 'left' }}
        />
        <Button
          kind="inline"
          onClick={mapPopinState.open}
          addOn="suffix"
          style={{
            flexShrink: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          <Icon icon="location" />
          Pick location
        </Button>
      </InputContainer>

      <Popin
        opened={mapPopinState.value}
        close={mapPopinState.close}
        style={{ width: 900, pointerEvents: 'all' }}
      >
        <PopinMap
          search={search}
          setSearch={setSearch}
          updatePosition={updateLatLng}
          closeModal={mapPopinState.close}
          lat={fp.lat}
          lng={fp.lng}
          radius={fp.radius}
        />
      </Popin>
    </Grid>
  )
}
