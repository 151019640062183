import styled, { css } from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

export const StickyDayContent = styled.div<{
  firstElement: boolean
}>`
  background: rgba(255, 255, 255, 0.97);
  ${props =>
    !props.firstElement &&
    css`
      border-top: 1px solid rgba(15, 15, 15, 0.12);
    `}
  border-bottom: 1px solid ${schemes.grayscale['20']};
  padding: 9px 20px;
  position: sticky;
  font-weight: 500;
  top: -1px;
  z-index: 1;
  color: ${colors.text};

  span {
    color: ${colors.textLight};
    font-weight: 400;
    margin-left: 4px;
  }
`
