import { type List } from 'immutable'
import * as React from 'react'
import { ThemeContext } from 'styled-components'

import { BarChart } from 'components/charts/bar-chart'
import {
  DataSetFactory,
  getChartFakeData,
  type DataSetRecord,
} from 'components/charts/chart-helper'
import { BoxBody } from 'components/common/box'

type AnalyticsProps = {
  series: Array<List<DataSetRecord>>
}

export const AnalyticsChart = ({ series }: AnalyticsProps): React.ReactElement => {
  const [overIndex, setOverIndex] = React.useState<number | null>(null)
  const themeContext = React.useContext(ThemeContext)
  const hasSkeletonScreen = (themeContext?.isLoading ?? false) || (themeContext?.isEmpty ?? false)

  const updateOverIndex: (overIndex: null | number) => void = React.useCallback(overIndex => {
    window.requestAnimationFrame(() => {
      setOverIndex(overIndex)
    })
  }, [])
  return (
    <BoxBody style={{ overflow: 'hidden' }}>
      {series.map((serie, index) => {
        const total = serie.reduce((sum, set) => sum + set.total, 0)
        return (
          <BarChart
            clamp
            height={96}
            key={index}
            label={serie
              .get(0, DataSetFactory())
              .label.toLowerCase()
              .replace(' ', '')
              .replace('-', '')}
            overIndex={overIndex}
            loading={hasSkeletonScreen}
            setOverIndex={updateOverIndex}
            sets={
              hasSkeletonScreen
                ? emptyChartsWithOverlay[index % emptyChartsWithOverlay.length]
                : total === 0
                  ? emptyCharts[index % emptyCharts.length]
                  : serie
            }
            timelineMode={index === 0 ? 'top' : 'none'}
            hasSeparator={index !== series.length - 1}
          />
        )
      })}
    </BoxBody>
  )
}

const emptyChartsWithOverlay: Array<List<DataSetRecord>> = new Array(4).fill(
  getChartFakeData({ total: 100 })
)
const emptyCharts: Array<List<DataSetRecord>> = new Array(4).fill(getChartFakeData({ total: 0 }))
