import * as React from 'react'
import styled from 'styled-components'

import { colors, schemes } from 'components/styled/tokens'

import { type SenderIdentityRecord } from 'com.batch.redux/corelogic/records/sender-identity.records'

const OptionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  color: ${colors.textNeutral};
  height: auto;
`

const OptionText = styled.div`
  color: ${colors.textNeutral};
  font-weight: 500;
`

const OptionDesc = styled.div`
  color: ${colors.textLight};
  font-weight: 400;
`

export const JourneyConnector = ({
  noArrow = false,
  isHorizontal = false,
  style = { margin: '20px 0px' },
  height = 46,
}: {
  noArrow?: boolean
  isHorizontal?: boolean
  style?: {
    [key: string]: string | number
  }
  height?: number
}): React.ReactElement =>
  isHorizontal ? (
    <svg width="22" height="22" viewBox="0 0 22 22">
      <line x1="1" y1="18" x2="22" y2="18" stroke={schemes.grayscale['30']} strokeWidth="2" />
    </svg>
  ) : (
    <div style={style}>
      <svg
        width="16"
        height={height}
        viewBox={`0 0 16 ${height}`}
        fill="none"
        style={{ display: 'block' }}
      >
        {noArrow ? (
          <line x1="6" y1="0" x2="6" y2={height} stroke={schemes.grayscale['30']} strokeWidth="2" />
        ) : (
          <path
            d="M7.29298 45.7071C7.68351 46.0976 8.31667 46.0976 8.7072 45.7071L15.0711 39.3431C15.4616 38.9526 15.4616 38.3194 15.0711 37.9289C14.6806 37.5384 14.0474 37.5384 13.6569 37.9289L8.00007 43.5858L2.34318 37.929C1.95265 37.5385 1.31948 37.5385 0.928964 37.929C0.538442 38.3195 0.538447 38.9527 0.928974 39.3432L7.29298 45.7071ZM9.00016 1.00001C9.00017 0.447727 8.55246 6.25849e-06 8.00017 0C7.44789 -6.31809e-06 7.00017 0.447704 7.00016 0.999989L9.00016 1.00001ZM7.99992 22.4634L6.99992 22.4634V22.4634L7.99992 22.4634ZM9.00008 45L8.99992 22.4634L6.99992 22.4634L7.00008 45L9.00008 45ZM8.99992 22.4634L9.00016 1.00001L7.00016 0.999989L6.99992 22.4634L8.99992 22.4634Z"
            fill="#C9C9C9"
          />
        )}
      </svg>
    </div>
  )

export const DoorIcon = (): React.ReactElement => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" rx="6" fill="rgba(15, 15, 15, 0.08)" fillOpacity="0.08" />
    <g clipPath="url(#clip0_2827_12252)">
      <path
        d="M19.5102 6.47059H9.48975V19.0729H19.5102V6.47059Z"
        fill="#212121"
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5103 19.0729L13.261 21.5294V8.92706L19.5103 6.47059V19.0729Z"
        fill="#ECECEC"
        stroke="#383838"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.1416 14V15.28" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2827_12252">
        <rect width="11" height="16" fill="white" transform="translate(9 6)" />
      </clipPath>
    </defs>
  </svg>
)
export const EmailIcon = (): React.ReactElement => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" rx="6" fill="#E7F1FC" />
    <g clipPath="url(#clip0_2827_12292)">
      <path
        d="M20.7903 7.5785L5.8479 9.44751L7.20994 20.3368L22.1523 18.4678L20.7903 7.5785Z"
        stroke="#0B7AD5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8479 9.44751L20.7903 7.5785L14.1929 15.4986L5.8479 9.44751Z"
        fill="#0B7AD5"
        stroke="#0B7AD5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.631 13.877L22.1524 18.4678L7.21002 20.3368L12.3997 14.2811"
        stroke="#0B7AD5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2827_12292">
        <rect
          width="16"
          height="11.9153"
          fill="white"
          transform="translate(5.32251 9.03897) rotate(-7.12958)"
        />
      </clipPath>
    </defs>
  </svg>
)
export const WaitIcon = (): React.ReactElement => (
  <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2827_12440)">
      <path
        d="M11.3759 2.25236L3.504 1.00557C3.0567 0.934728 2.63666 1.2399 2.56582 1.6872L2.54391 1.82548C2.47307 2.27278 2.77824 2.69281 3.22554 2.76366L11.0974 4.01044C11.5447 4.08129 11.9648 3.77611 12.0356 3.32881L12.0575 3.19054C12.1283 2.74324 11.8232 2.3232 11.3759 2.25236Z"
        stroke="#7552D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33437 15.1445L1.46249 13.8977C1.01519 13.8269 0.595157 14.1321 0.524312 14.5794L0.502411 14.7177C0.431566 15.1649 0.736741 15.585 1.18404 15.6558L9.05592 16.9026C9.50321 16.9735 9.92325 16.6683 9.9941 16.221L10.016 16.0827C10.0868 15.6354 9.78167 15.2154 9.33437 15.1445Z"
        stroke="#7552D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.74254 2.84477L10.6465 3.93824L10.3117 6.0519C10.0098 7.95813 8.21352 9.2632 6.30728 8.96128C4.40105 8.65937 3.09598 6.86309 3.3979 4.95685L3.73267 2.8432L3.74254 2.84477Z"
        stroke="#7552D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.32043 8.95617C8.22667 9.25809 9.53174 11.0544 9.22982 12.9606L8.89505 15.0743L1.98123 13.9792L2.316 11.8656C2.61792 9.95932 4.4142 8.65426 6.32043 8.95617Z"
        stroke="#7552D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.31999 12.33H9.22999L8.88999 14.55L1.98999 13.79L2.31999 12.33Z"
        fill="#7552D5"
        stroke="#7552D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.05006 10.93C6.32068 10.93 6.54006 10.7106 6.54006 10.44C6.54006 10.1694 6.32068 9.95 6.05006 9.95C5.77944 9.95 5.56006 10.1694 5.56006 10.44C5.56006 10.7106 5.77944 10.93 6.05006 10.93Z"
        fill="#7552D5"
      />
      <path
        d="M5.08009 12.23C5.35071 12.23 5.57009 12.0106 5.57009 11.74C5.57009 11.4694 5.35071 11.25 5.08009 11.25C4.80947 11.25 4.59009 11.4694 4.59009 11.74C4.59009 12.0106 4.80947 12.23 5.08009 12.23Z"
        fill="#7552D5"
      />
      <path
        d="M3.46997 6.34C3.46997 6.34 8.79997 6.34 10.22 6.34C10.04 7.93 8.19997 9.08 6.95997 9C5.71997 8.92 3.99997 8.94 3.46997 6.34Z"
        fill="#7552D5"
        stroke="#7552D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2827_12440">
        <rect width="12.57" height="16.91" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)
export const RightBottomArrow = (): React.ReactElement => (
  <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke={schemes.grayscale['30']} strokeWidth="2">
      <path d="M0 18 L23 18 Q 70 18, 65 60" />
      <line x1="59" y1="52" x2="66" y2="59" />
      <line x1="71" y1="52" x2="64" y2="59" />
    </g>
  </svg>
)

export const HTMLIcon = (): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="32" viewBox="0 0 29 32" fill="none">
      <path
        d="M4 8C4 4.22876 4 2.34315 5.17157 1.17157C6.34315 0 8.22876 0 12 0H14.0294C16.4819 0 17.7082 0 18.8108 0.456723C19.9134 0.913446 20.7805 1.78054 22.5147 3.51472L25.4853 6.48529C27.2195 8.21946 28.0866 9.08655 28.5433 10.1892C29 11.2918 29 12.5181 29 14.9706V24C29 27.7712 29 29.6569 27.8284 30.8284C26.6569 32 24.7712 32 21 32H12C8.22876 32 6.34315 32 5.17157 30.8284C4 29.6569 4 27.7712 4 24V8Z"
        fill="#E3E3E3"
      />
      <rect y="14" width="26.0122" height="12" rx="3" fill="url(#paint0_linear_2913_47619)" />
      <path
        d="M2 22.8184H3.17969V20.4434H5.71875V22.8184H6.89844V17.1816H5.71875V19.4707H3.17969V17.1816H2V22.8184Z"
        fill="white"
      />
      <path
        d="M9.47281 22.8184H10.6525V18.1543H12.3439V17.1816H7.78141V18.1543H9.47281V22.8184Z"
        fill="white"
      />
      <path
        d="M13.2269 22.8184H14.2777V18.9824H14.3558L15.8011 22.8184H16.5394L17.9847 18.9824H18.0628V22.8184H19.1136V17.1816H17.762L16.2073 21.3066H16.137L14.5784 17.1816H13.2269V22.8184Z"
        fill="white"
      />
      <path d="M20.3247 22.8184H24.0122V21.8457H21.5044V17.1816H20.3247V22.8184Z" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_2913_47619"
          x1="11.241"
          y1="14"
          x2="12.5804"
          y2="26.1997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F7681" />
          <stop offset="1" stopColor="#6A717C" />
          <stop offset="1" stopColor="#6A717C" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const SenderIdentityOptionFormatter = (
  action: SenderIdentityRecord | null | undefined,
  opt: {
    context: 'menu' | 'value'
  }
): React.ReactNode | null => {
  const { context } = opt
  if (!action) return null
  const email = `${action.sendingPrefix}@${action.sendingDomain}`
  return context === 'menu' ? (
    <OptionGrid>
      <div>
        <OptionText>{action.sendingName}</OptionText>
        <OptionDesc>{email}</OptionDesc>
      </div>
    </OptionGrid>
  ) : (
    `${action.sendingName} (${email})`
  )
}
