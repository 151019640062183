import * as React from 'react'

import { LinkArrow } from 'components/styled/text'

import {
  AccountSection,
  SectionTitle,
  SectionIntro,
} from 'com.batch/account/ui/components/account.styles'

export const BillingSection = (): React.ReactElement => (
  <AccountSection>
    <SectionIntro>
      <SectionTitle>Billing</SectionTitle>
      <p>A question about your last invoice? Feel free to contact us.</p>
      <LinkArrow
        intent="action"
        target="_blank"
        href="mailto:billing@batch.com"
        style={{ marginTop: 16 }}
      >
        billing@batch.com
      </LinkArrow>
    </SectionIntro>
  </AccountSection>
)
