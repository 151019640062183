import Immutable, { type Map } from 'immutable'
import { createSelector } from 'reselect'

import { dayjs } from 'com.batch.common/dayjs.custom'

import { type State, type fetchingState } from 'com.batch.redux/_records'

import {
  type ReachabilityTimeserieRecord,
  type ChannelsReachabilityStateRecord,
} from 'com.batch/profilebase/models/reachability-timeserie.records'

type extract<T> = (arg1: State) => T

const channelsReachabilitySelector = (state: State): ChannelsReachabilityStateRecord =>
  state.profilebase.channelsReachability

export const channelLastMonthReachSelector = (
  channel: 'push' | 'email' | 'sms' | 'webpush'
): extract<{
  loadingState: fetchingState
  subsCounter: number
  subsReach: Map<string, ReachabilityTimeserieRecord>
}> =>
  createSelector(channelsReachabilitySelector, channelsReachability => ({
    loadingState: channelsReachability[channel].loadingState,
    subsCounter: channelsReachability[channel].counter.subscriptionsLastMonth,
    // Map of new subscriptions by day (subscription Growth)
    subsReach: channelsReachability[channel].timeserie
      .filter(f => f.subscriptions > 0)
      .reduce(
        (acc, m) => acc.set(dayjs.utc(m.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'), m),
        Immutable.Map()
      ),
  }))
