import {
  DiscoveryFilterFactory,
  type DiscoveryFilterRecord,
} from 'com.batch/orchestration-analytics/store/orchestration-analytics.state'
import { type DynamicDimension } from 'com.batch/orchestration-analytics/usecases/fetch-dynamic-stats.helper'
import { type OrchestrationStatsDynamicDimension } from 'com.batch/shared/infra/types/grpc-stats-service'
import Immutable, { type List } from 'immutable'

export const parseFilterValues = ({
  stats,
  dimension,
}: {
  stats?: Array<OrchestrationStatsDynamicDimension> | null
  dimension: DynamicDimension
}): List<DiscoveryFilterRecord> => {
  const dimensionIndex = stats ? stats[0].dimensionNames.indexOf(dimension) : -1
  return Immutable.List(
    stats && dimensionIndex !== -1
      ? stats.map(({ currentPeriod, dimensionValues }) =>
          DiscoveryFilterFactory({
            name: dimensionValues[dimensionIndex],
            sent: currentPeriod.sent,
          })
        )
      : []
  )
}
