import styled, { css, keyframes, type Keyframes } from 'styled-components'

import { schemes, colors } from 'components/styled/tokens'

export type ScrollbarProps = {
  color?: string
  background?: string
}

export type BorderType = 'top' | 'right' | 'bottom' | 'left'
export type BorderConfig = {
  offset?: number
  color?: string
  tag?: 'before' | 'after'
  rootPositionning?: string
}

export const Scrollbar = (props: ScrollbarProps) => css`
  &::-webkit-scrollbar {
    width: 14px;
    height: 4px;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 6px;
    height: 6px;
    background: ${props.color || schemes.darklucent['30']};
    border-radius: 16px;
    border: 4px solid ${props.background || '#f8f8f8'};
  }
`

export const Ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CustomBorder = (type: BorderType, config: BorderConfig) => css`
  position: ${config.rootPositionning || 'relative'};

  &:before, &:${config.tag} {
    /* &:${config.tag || 'before'} { */
    display: block;
    position: absolute;
    content: '';
    background: ${config.color || colors.stroke};

    ${type}: 0px;
    ${type === 'top' || type === 'bottom'
      ? css`
          left: ${config.offset || 0}px;
          right: ${config.offset || 0}px;
          height: 1px;
        `
      : css`
          top: ${config.offset || 0}px;
          bottom: ${config.offset || 0}px;
          width: 1px;
        `}
  }
`

// LOADING ANIMATION
const LoadingAnimation: Keyframes = keyframes`
0% { background-color: ${schemes.darklucent['05']}}
50% { background-color: ${colors.stroke} }
100% { background-color: ${schemes.darklucent['05']} }
`

export const LoadingAnimationStyle = () => css`
  animation-name: ${LoadingAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`

export type SeparatorProps = {
  _margin?: string
  display?: string
  fullHeight?: boolean
}
export const Separator = styled.div<SeparatorProps>`
  width: 1px;
  height: ${p => (p.fullHeight ? 'auto' : '20px')};
  margin: ${p => (p._margin ? p._margin : '8px 0')};
  background-color: ${colors.stroke};
  display: ${p => p.display ?? 'block'};
`

export const HorizontalDivider = styled.div<{
  margin?: string
  color?: string
}>`
  width: auto;
  height: 1px;
  margin: ${p => p.margin ?? 0};
  background-color: ${p => p.color ?? colors.stroke};
`
