import * as React from 'react'

import { Icon } from 'components/common/svg-icon'

import { type MessageBuilderEditableField } from 'com.batch/message-builder/models/message-builder-field'
import { InfoPanelCounterContainer } from 'com.batch/message-builder/ui/components/info-panel/info-panel.styles'

interface InfoPanelCounterProps {
  field: MessageBuilderEditableField
  value: string
}

export const InfoPanelPushCounter: React.ComponentType<InfoPanelCounterProps> = ({
  field,
  value,
}: InfoPanelCounterProps) => {
  const maxCount = React.useMemo(() => (['title', 'pushTitle'].includes(field) ? 40 : 160), [field])
  const count = React.useMemo(() => value.length, [value])

  const state = React.useMemo(() => {
    if (count === 0) {
      return 'default'
    } else if (count > maxCount) {
      return 'error'
    } else {
      return 'success'
    }
  }, [count, maxCount])

  return (
    <InfoPanelCounterContainer $state={state}>
      <Icon icon="arrow-double-horizontal" thickness={1.3} className="section-icon" />
      <span className="styled-counter">{`${count} character${count > 1 ? 's' : ''}`}</span>
      <span className="styled-hint">
        {['title', 'pushTitle'].includes(field) ? '10-40' : '140-160'} recommended
      </span>
    </InfoPanelCounterContainer>
  )
}
