import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useSelector } from 'components/console/react-redux'
import request from 'superagent-interface-promise'

import { AppAvatar as Avatar } from 'components/common/avatar'
import { Grid } from 'components/common/grid'
import { Tag } from 'components/common/tag'
import { Select } from 'components/form'
import { LightText } from 'components/styled/text'

import { generateUrl } from 'com.batch.common/router'

import { Ellipsis } from '../console.style'
import { type State } from '../redux/console.records'
import { type AppRecord } from 'com.batch.redux/_records'
import { normalizeApp } from 'com.batch.redux/app.api'
import { getPlanFromCode } from 'com.batch.redux/billing.api'

type AppPickerProps = {
  isClearable?: boolean
  width?: number
  noProject?: boolean
  companyId?: number
  platform?: ProjectPlatforms
  placeholder?: string
  appId?: number
  setApp: (c?: AppRecord | null | undefined) => void
}

const appToString = (opt?: AppRecord | null) => opt?.id?.toString() ?? ''

const appsSelector = (state: State) => state.app.entities
export const AppPicker = ({
  appId,
  width = 300,
  platform,
  noProject = false,
  setApp,
  placeholder = 'Search for an app',
  isClearable = false,
  companyId,
}: AppPickerProps): React.ReactElement => {
  const apps = useSelector(appsSelector)
  const app = React.useMemo(
    () => (appId && apps.has(appId) ? apps.get(appId) : null),
    [appId, apps]
  )
  const fetchApps = React.useCallback(
    (inputValue: string): Promise<List<AppRecord>> => {
      const params: {
        query: string
        noProject: string
        companyId?: number
        platform?: ProjectPlatforms
      } = {
        query: inputValue,
        noProject: noProject ? 'true' : 'false',
      }
      if (companyId) params.companyId = companyId
      if (platform) params.platform = platform
      return request.get(generateUrl('console_api_apps', params)).then(({ body }) => {
        const apps = Immutable.List(body.entities.map(normalizeApp))
        // loadAppsSuccess({ count: parseInt(body.count), entities:  Immutable.List(apps) })
        return apps
      })
    },
    [companyId, noProject, platform]
  )
  return (
    <Select
      style={{ width }}
      loadOptions={fetchApps}
      value={app}
      onChange={setApp}
      optionMenuHeight={53}
      optionMenuShownCount={7}
      placeholder={placeholder}
      isClearable={isClearable}
      optionToString={appToString}
      isSearchable={true}
      optionFormatter={AppOption}
    />
  )
}

const AppOption = (app: AppRecord) => {
  return (
    <Grid template="40px 1fr 70px 40px">
      <Avatar placeholder={app.name.split(' ')[0]} platform={app.platform} url={app.icon} />
      <Ellipsis>{app.name}</Ellipsis>
      <Tag>{getPlanFromCode(app.status).name}</Tag>
      <LightText>{app.id}</LightText>
    </Grid>
  )
}
