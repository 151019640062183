import styled, { css } from 'styled-components'

import { type StyledPreviewTheme } from 'components/campaign/preview/preview.theme'
import { IconContainer } from 'components/common/svg-icon'

const Editable = styled.div``

const editablePart = css<{ editingField?: string; field?: string; placeholder?: string }>`
  ${props =>
    props.placeholder &&
    props.editingField !== props.field &&
    css`
      ::before {
        color: #9197a3;
        font-weight: 600;
        display: inline;
        content: attr(placeholder);
      }
    `}
  ${props =>
    props.editingField === props.field &&
    css`
      -webkit-line-clamp: unset;
    `}
`

export const DebugDevice = styled.div`
  background-size: 1000px 1000px;
  background-position: -260px -60px;
  position: relative;
`
export const PreviewDevicePadding = styled.div`
  opacity: 1;
  width: 400px;
  height: ${props => (props.theme as unknown as StyledPreviewTheme).height};
  padding: ${props => (props.theme as unknown as StyledPreviewTheme).containerPadding};
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'android' &&
    css`
      font-family: Roboto, Arial, sans-serif;
    `};
`
export const PreviewDevice = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush' ? 0 : 2};
  border-radius: ${props => (props.theme as unknown as StyledPreviewTheme).borderRadius};
  box-shadow: ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush'
      ? 'none'
      : '0px 1px 6px rgba(0, 0, 0, 0.16), 0px 2px 28px rgba(0, 0, 0, 0.08)'};
  background-size: cover;
  background-position: top left;
  background-image: ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush'
      ? 'none'
      : `url(/medias/img/notif-preview/legacy/mockups/${
          (props.theme as unknown as StyledPreviewTheme).platform
        }_light.png)`};
  width: 400px;
  height: ${props => (props.theme as unknown as StyledPreviewTheme).height};

  /* Webpush only */
  &:before,
  &:after {
    display: ${props =>
      (props.theme as unknown as StyledPreviewTheme).platform === 'webpush' ? 'block' : 'none'};
    position: absolute;
    top: 0;
    border-radius: 0 10px 0 0;
    content: '';
  }
  &:after {
    width: 100%;
    height: 100%;
    left: 0;
    background-image: url(/medias/img/notif-preview/legacy/mockups/webpush.png);
    background-size: cover;
    background-position: 100% 0;
    box-shadow:
      inset 0 -2px 4px rgba(0, 0, 0, 0.01),
      inset 0 4px 12px rgba(0, 0, 0, 0.02);
    border-top: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    overflow: hidden;
    mask-image: url(/medias/img/notif-preview/legacy/mockups/webpush_mask.png);
    mask-size: 100% 100%;
  }
  &:before {
    width: 60%;
    height: 60%;
    right: 0;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04);
  }
`
export const PreviewContent = styled.div`
  flex-grow: 1;
  border-radius: ${props => (props.theme as unknown as StyledPreviewTheme).containerRadius};
  position: relative;
  .landingPreview {
    transform-origin: 0 1px;
    width: ${props => (props.theme as unknown as StyledPreviewTheme).landingWidth};
    height: ${props => (props.theme as unknown as StyledPreviewTheme).landingHeight};
    transform: scale(
      ${props => (props.theme as unknown as StyledPreviewTheme).landingScale},
      ${props => (props.theme as unknown as StyledPreviewTheme).landingScale}
    );
  }
  background-color: ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush'
      ? 'transparent'
      : 'HSL(0, 0%, 18%)'};
  color: #fff;
  text-align: center;
`

export const LockScreen = styled.div`
  pointer-events: none;
  padding: ${props => (props.theme as unknown as StyledPreviewTheme).ls.padding};
  font-weight: ${props => (props.theme as unknown as StyledPreviewTheme).ls.fontWeight};
  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'windows' &&
    css`
      text-align: left;
      position: absolute;
      bottom: 0;
      left: 0;
    `};
`
export const LockScreenDate = styled.div`
  font-size: ${props => (props.theme as unknown as StyledPreviewTheme).ls.date.fontSize};
  font-weight: ${props => (props.theme as unknown as StyledPreviewTheme).ls.date.fontWeight};
  line-height: ${props => (props.theme as unknown as StyledPreviewTheme).ls.date.lineHeight};
`
export const LockScreenTime = styled.div`
  font-size: ${props => (props.theme as unknown as StyledPreviewTheme).ls.time.fontSize};
  letter-spacing: ${props => (props.theme as unknown as StyledPreviewTheme).ls.time.letterSpacing};
  line-height: ${props => (props.theme as unknown as StyledPreviewTheme).ls.time.lineHeight};
`
type NotifCloseProps = {
  fixed?: boolean
}
export const NotifClose = styled.div<NotifCloseProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.fixed
      ? css`
          position: fixed;
          top: 20px;
          right: 20px;
          z-index: 1102;
          cursor: pointer;
        `
      : css`
          position: absolute;
          top: 55px;
          right: 20px;
        `}
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  color: #000000;
  text-align: center;

  ${IconContainer} {
    line-height: 1em;
  }
`
type NotifAreaProps = {
  os?: string
  browser?: string
  style?: Record<any, any>
  isProject?: boolean
  hasMedia?: boolean
}
export const NotifArea = styled.div<NotifAreaProps>`
  text-align: left;
  overflow: hidden;
  position: relative;
  color: ${props => (props.theme as unknown as StyledPreviewTheme).notif.color};

  ${props =>
    !props.isProject &&
    css`
      display: flex;
      flex-direction: ${(props.theme as unknown as StyledPreviewTheme).expanded && props.hasMedia
        ? 'column'
        : 'row'};
      border-radius: ${p =>
        (p.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
        !(p.theme as unknown as StyledPreviewTheme).expanded
          ? '25px'
          : '10px'};
    `}

  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush'
      ? props.os === 'mac'
        ? props.browser === 'safari'
          ? css`
              padding: 12px;
              background: #f2f2f2;
              border-radius: 10px;
              border: 1px solid #e9e9e9;
            `
          : css`
              border-radius: 14px;
              padding: 10px;
              background-color: HSL(0, 0%, 94%);
              box-shadow: rgb(203, 203, 203) 0px 0px 10px 0px;

              ${NotifContentText} {
                padding: 2px;
              }

              ${NotifAppName} {
                // padding: 0 3px;
              }
            `
        : props.browser === 'chrome'
          ? css`
              background-color: HSL(36, 4%, 21%);
              box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.16);
              padding: 0px;
              display: flex;
              flex-direction: column;
              ${NotifContent} {
                padding: 15px 14px 13px 14px;
                align-items: flex-start;
                order: 2;
              }
              ${NotifContentText} {
                margin-top: -5px;
                padding: 2px;
              }
              ${NotifAppName} {
                color: HSL(26, 1%, 61%);
                font-size: 11px;
                // padding: 0 3px;

                &:before {
                  content: 'Google Chrome • ';
                }
              }
              ${NotifContentTextTitle} {
                color: #fff;
                font-weight: 600;
                font-size: 13px;
                white-space: pre-line;

                &::before {
                  color: rgba(255, 255, 255, 0.94);
                }
              }
              ${NotifContentTextMessage} {
                font-weight: 400;
                font-size: 13px;
                white-space: pre-line;

                &::before {
                  font-weight: 400;
                }
              }
              color: HSL(31, 1%, 72%);
            `
          : css`
              border-radius: 0px;
              padding: 10px;
              box-shadow: rgb(203, 203, 203) 0px 0px 10px 0px;
              background-color: HSL(0, 0%, 100%);

              ${NotifAppName} {
                // padding: 0 3px;
              }

              ${NotifContentText} {
                padding: 2px;
              }
            `
      : css`
          border-radius: ${(props.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
          !props.isProject &&
          !(props.theme as unknown as StyledPreviewTheme).expanded
            ? '25px'
            : (props.theme as unknown as StyledPreviewTheme).notif.borderRadius};
          background-color: ${(props.theme as unknown as StyledPreviewTheme).expanded
            ? (props.theme as unknown as StyledPreviewTheme).notif.expanded.backgroundColor
            : (props.theme as unknown as StyledPreviewTheme).notif.backgroundColor};
        `}

  
  margin: ${props =>
    (props.theme as unknown as StyledPreviewTheme).expanded
      ? (props.theme as unknown as StyledPreviewTheme).notif.expanded.margin
      : (props.theme as unknown as StyledPreviewTheme).notif.margin};
`
type NotifHeadProps = {
  isProject?: boolean
  hasIcon?: boolean
}
export const NotifHead = styled.div<NotifHeadProps>`
  margin-bottom: ${p => (p.hasIcon ? '10px' : 'unset')};
  ${props =>
    props.isProject
      ? css`
          display: flex;
          align-items: center;
          white-space: nowrap;
          width: 100%;
          text-overflow: ellipsis;
        `
      : css`
          display: ${(props.theme as unknown as StyledPreviewTheme).platform === 'ios'
            ? 'flex'
            : 'unset'};
          align-items: ${(props.theme as unknown as StyledPreviewTheme).platform === 'ios'
            ? 'center'
            : 'unset'};
          width: ${(props.theme as unknown as StyledPreviewTheme).platform === 'android'
            ? '100%'
            : 'unset'};
        `}

  padding: ${props =>
    (props.theme as unknown as StyledPreviewTheme).expanded
      ? (props.theme as unknown as StyledPreviewTheme).notif.head.expanded.padding
      : (props.theme as unknown as StyledPreviewTheme).platform === 'ios'
        ? '14px'
        : '12px 14px 9px'};

  ${IconContainer} {
    margin-right: 8px;
  }
`
type NotifAppIconProps = {
  icon: string
  isProject?: boolean
}
export const NotifAppIcon = styled.div<NotifAppIconProps>`
  height: ${p => (p.icon ? '34px' : '37px')};
  width: ${p => (p.icon ? '34px' : '37px')};
  border-radius: ${p => (p.icon ? '7px' : '10px')};
  margin-right: ${p => (p.isProject ? 'unset' : '12px')};
  align-self: center;
  background: ${p => (!p.icon ? '#ACB1B9' : 'unset')};
  background-size: cover;
  background-image: url(${props => props.icon});
  ${editablePart}
`
type NotifAppNameProps = {
  browser?: string
}
export const NotifAppName = styled.div<NotifAppNameProps>`
  flex-grow: ${props => (props.theme as unknown as StyledPreviewTheme).notif.head.appName.grow};
  font-size: ${props => (props.theme as unknown as StyledPreviewTheme).notif.head.appName.fontSize};
  color: ${props => (props.theme as unknown as StyledPreviewTheme).notif.head.appName.color};
  text-transform: ${props =>
    props.browser === 'safari'
      ? 'uppercase'
      : (props.theme as unknown as StyledPreviewTheme).notif.head.appName.textTransform};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 6px;

  ${props =>
    props.browser === 'safari' &&
    css`
      display: flex;
      justify-content: space-between;

      span {
        text-transform: lowercase;
      }
    `}
  ${editablePart}
`
type NotifDateProps = {
  isProject?: boolean
} & React.HTMLAttributes<HTMLDivElement>
export const NotifDate = styled.div<NotifDateProps>`
  margin-left: ${p =>
    p.isProject && (p.theme as unknown as StyledPreviewTheme).platform === 'ios'
      ? '8px'
      : !p.isProject && (p.theme as unknown as StyledPreviewTheme).platform === 'ios'
        ? '20px '
        : 'unset'};
  flex: 0 0 auto;
  align-self: flex-end;
  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
    css`
      align-self: flex-start;
      line-height: ${props =>
        (props.theme as unknown as StyledPreviewTheme).expanded ? 'normal' : '17px'};
    `}
  i {
    line-height: 10px;
    font-weight: 600;
  }
  color: ${props =>
    (props.theme as unknown as StyledPreviewTheme).expanded
      ? (props.theme as unknown as StyledPreviewTheme).notif.head.date.expanded.color
      : (props.theme as unknown as StyledPreviewTheme).notif.head.date.color};
  letter-spacing: ${props =>
    (props.theme as unknown as StyledPreviewTheme).expanded
      ? (props.theme as unknown as StyledPreviewTheme).notif.head.date.expanded.letterSpacing
      : (props.theme as unknown as StyledPreviewTheme).notif.head.date.letterSpacing};
  font-size: ${props =>
    (props.theme as unknown as StyledPreviewTheme).expanded
      ? (props.theme as unknown as StyledPreviewTheme).notif.head.date.expanded.fontSize
      : (props.theme as unknown as StyledPreviewTheme).notif.head.date.fontSize};
  font-weight: ${props =>
    (props.theme as unknown as StyledPreviewTheme).notif.head.date.fontWeight};
  ${editablePart}
`

type NotifContentProps = {
  browser?: string
  hasMedia?: boolean
  isProject?: boolean
}
export const NotifContent = styled.div<NotifContentProps>`
  display: flex;
  max-width: 100%;

  ${props =>
    props.isProject &&
    css`
      display: flex;
      align-items: center;
      padding: ${(props.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
      props.hasMedia &&
      !(props.theme as unknown as StyledPreviewTheme).expanded
        ? '10px 11px 17px 11px'
        : (props.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
            (props.theme as unknown as StyledPreviewTheme).expanded &&
            props.hasMedia
          ? '10px 11px 10px 11px'
          : (props.theme as unknown as StyledPreviewTheme).platform === 'ios'
            ? '10px 11px 12px 11px'
            : '0px 11px 12px 11px'};
    `}

  // -------------------------

${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'android' && !props.isProject
      ? css`
          align-items: center;
          width: ${props.hasMedia && !(props.theme as unknown as StyledPreviewTheme).expanded
            ? '280px'
            : '100%'};
        `
      : !props.isProject && (props.theme as unknown as StyledPreviewTheme).platform === 'ios'
        ? css`
            align-items: center;
            width: ${props.hasMedia && !(props.theme as unknown as StyledPreviewTheme).expanded
              ? '220px'
              : 'calc(100% - 150px)'};
          `
        : css``}




  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush' &&
    css`
      align-items: ${props.browser === 'safari' ? 'start' : 'center'};
      font-size: 14px;
      line-height: 1.5em;
    `}
  padding: ${props => props.browser === 'safari' && '0 10px 10px 0'};
`

type NotifContentTextProps = {
  isProject?: boolean
  hasMedia?: boolean
}
export const NotifContentText = styled.div<NotifContentTextProps>`
  flex-grow: 1;
  order: 1;
  unicode-bidi: plaintext;

  ${props =>
    props.isProject &&
    css`
      margin-left: ${(props.theme as unknown as StyledPreviewTheme).platform === 'ios' && '8px'};
      max-width: ${props.hasMedia &&
      !(props.theme as unknown as StyledPreviewTheme).expanded &&
      (props.theme as unknown as StyledPreviewTheme).platform === 'ios'
        ? '264px'
        : '100%'};
      width: ${props.hasMedia &&
      !(props.theme as unknown as StyledPreviewTheme).expanded &&
      (props.theme as unknown as StyledPreviewTheme).platform === 'ios'
        ? 200
        : 'calc(100% - 200px)'};
    `};

  font-size: ${props => (props.theme as unknown as StyledPreviewTheme).notif.content.fontSize}px;
  line-height: ${props =>
    (props.theme as unknown as StyledPreviewTheme).notif.content.lineHeight}px;
  color: ${props =>
    (props.theme as unknown as StyledPreviewTheme).expanded
      ? (props.theme as unknown as StyledPreviewTheme).notif.content.expanded.color
      : (props.theme as unknown as StyledPreviewTheme).notif.content.color};
  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush' &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `};
  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
    (props.theme as unknown as StyledPreviewTheme).expanded &&
    css`
      font-size: ${props =>
        (props.theme as unknown as StyledPreviewTheme).notif.content?.expanded?.fontSize ?? 17}px;
      line-height: 17px;
    `};
`
type NotifContentTextTitleProps = {
  isProject?: boolean
  hasMessage?: boolean
  hasMedia?: boolean
  limit?: boolean
  browser?: string
}
export const NotifContentTextTitle = styled(Editable)<NotifContentTextTitleProps>`
  ${props =>
    props.isProject && (props.theme as unknown as StyledPreviewTheme).platform === 'android'
      ? css`
          max-width: ${props.hasMedia && !(props.theme as unknown as StyledPreviewTheme).expanded
            ? 258
            : 300}px;
          width: ${props.hasMedia && !(props.theme as unknown as StyledPreviewTheme).expanded
            ? 258
            : 300}px;
        `
      : !props.isProject && (props.theme as unknown as StyledPreviewTheme).platform === 'android'
        ? css`
            max-width: unset;
            width: ${props.hasMedia && !(props.theme as unknown as StyledPreviewTheme).expanded
              ? 340
              : 332}px;
          `
        : css``}

  width: ${p =>
    !p.hasMedia && (p.theme as unknown as StyledPreviewTheme).platform === 'android' && 330}px;
  margin-bottom: ${p =>
    !p.hasMessage && (p.theme as unknown as StyledPreviewTheme).platform === 'ios' && !p.isProject
      ? 18
      : 0}px;
  font-weight: 600;
  unicode-bidi: plaintext;
  text-align: start;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: ${props =>
    (props.theme as unknown as StyledPreviewTheme).notif.content.title.fontWeight};

  color: ${props => (props.theme as unknown as StyledPreviewTheme).notif.content.title.color};
  ${props =>
    props.limit &&
    css`
      max-width: 290px;
    `}

  ${props =>
    !(props.theme as unknown as StyledPreviewTheme).expanded &&
    css`
      white-space: nowrap;
    `}

  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush' &&
    props.browser === 'chrome'
      ? css`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          white-space: normal;
          margin: 2px 0 4px 0;
        `
      : css`
          flex-grow: 2;
          cursor: text;
        `};
  ${editablePart}
`

const TextMessageClamp = {
  mac: { firefox: 1, chrome: 1, safari: 1 },
  win: { firefox: 'none', chrome: 4 },
}

type NotifContentTextMessageProps = {
  isProject?: boolean
  hasIcon?: boolean
  hasMedia?: boolean
  browser?: string
  os?: 'mac' | 'win'
}
export const NotifContentTextMessage = styled(Editable)<NotifContentTextMessageProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  unicode-bidi: plaintext;
  text-align: start;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-box-orient: vertical;

  ${props =>
    props.isProject
      ? css`
          width: ${props.hasMedia &&
          !(props.theme as unknown as StyledPreviewTheme).expanded &&
          (props.theme as unknown as StyledPreviewTheme).platform === 'ios'
            ? '225px'
            : !(props.theme as unknown as StyledPreviewTheme).expanded &&
                (props.theme as unknown as StyledPreviewTheme).platform === 'android'
              ? 'calc(100% - 10px)'
              : '100%'};
        `
      : !props.isProject && (props.theme as unknown as StyledPreviewTheme).platform === 'android'
        ? css`
            width: ${(!(props.theme as unknown as StyledPreviewTheme).expanded && props.hasMedia) ||
            props.hasIcon
              ? 290
              : 332}px;
            margin-top: 4px;
          `
        : css`
            width: ${!(props.theme as unknown as StyledPreviewTheme).expanded && props.hasMedia
              ? 233
              : 260}px;
          `};

  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'android' &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${props.hasMedia
        ? !(props.theme as unknown as StyledPreviewTheme).expanded
          ? 1
          : 3
        : !(props.theme as unknown as StyledPreviewTheme).expanded
          ? 1
          : 8};
      -webkit-box-orient: vertical;
    `}
  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
    css`
      -webkit-line-clamp: ${(props.theme as unknown as StyledPreviewTheme).expanded ? 7 : 4};
      -webkit-box-orient: vertical;
    `};
  color: ${props =>
    (props.theme as unknown as StyledPreviewTheme).expanded
      ? (props.theme as unknown as StyledPreviewTheme).notif.content.expanded.color
      : (props.theme as unknown as StyledPreviewTheme).notif.content.color};
  ${props =>
    (props.theme as unknown as StyledPreviewTheme) &&
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush' &&
    props.os &&
    props.browser
      ? css`
          display: -webkit-box;
          -webkit-line-clamp: ${TextMessageClamp[props.os][props.browser]};
          -webkit-box-orient: vertical;
        `
      : ''}
  ${editablePart}
`

type NotifContentMediaThumbProps = {
  os: string
  src: string
  hasMedia?: boolean
  isProject?: boolean
  hasIcon?: boolean
}
export const NotifContentMediaThumb = styled.div<NotifContentMediaThumbProps>`
  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'android' &&
    css`
      width: 45px;
      height: 45px;
      margin-right: ${props.isProject ? '4px' : '10px'};
      flex-basis: 45px;
      position: ${props.hasIcon ? 'absolute' : 'unset'};
      right: ${props.hasIcon ? '0px' : 'unset'};
      top: ${props.hasIcon ? '20px' : 'unset'};
    `}

  margin-top: ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
    props.hasMedia &&
    !(props.theme as unknown as StyledPreviewTheme).expanded
      ? '24px'
      : props.isProject
        ? '0px'
        : '10px'};
  border-radius: ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
    !(props.theme as unknown as StyledPreviewTheme).expanded
      ? '7px'
      : '10px'};

  display: ${props =>
    (props.theme as unknown as StyledPreviewTheme).expanded &&
    (props.theme as unknown as StyledPreviewTheme).platform !== 'android'
      ? 'none'
      : 'block'};

  flex-basis: ${props => (props.theme as unknown as StyledPreviewTheme).notif.media.thumb.size};
  flex-shrink: 0;
  order: ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush' && props.os === 'win'
      ? 0
      : 2};

  width: ${props => (props.theme as unknown as StyledPreviewTheme).notif.media.thumb.size};
  height: ${props => (props.theme as unknown as StyledPreviewTheme).notif.media.thumb.size};
  border-radius: ${props =>
    (props.theme as unknown as StyledPreviewTheme).notif.media.thumb.borderRadius};
  background-image: url(${props => props.src});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  ${editablePart}
`

type NotifContentVideoThumbProps = {
  os?: string
}
export const NotifContentVideoThumb = styled.div<NotifContentVideoThumbProps>`
  display: block;
  position: relative;
  flex-basis: ${props => (props.theme as unknown as StyledPreviewTheme).notif.media.thumb.size};
  flex-shrink: 0;
  order: ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'webpush' && props.os === 'win'
      ? 0
      : 2};
  margin: ${props => {
    if ((props.theme as unknown as StyledPreviewTheme).platform === 'webpush' && props.os === 'win')
      return '0 15px 0 0'
    if (
      (props.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
      !(props.theme as unknown as StyledPreviewTheme).expanded
    )
      return '24px 0 0 3px'
    return '0 0 0 3px'
  }};
  width: ${props => (props.theme as unknown as StyledPreviewTheme).notif.media.thumb.size};
  height: ${props => (props.theme as unknown as StyledPreviewTheme).notif.media.thumb.size};
  border-radius: ${props =>
    (props.theme as unknown as StyledPreviewTheme).notif.media.thumb.borderRadius};
  overflow: hidden;

  ${IconContainer} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${editablePart}
`

type NotifMediaProps = {
  hasMedia?: boolean
  imgSrc?: string
}
export const NotifMedia = styled.div<NotifMediaProps>`
  line-height: 0;
  padding: ${props => (props.theme as unknown as StyledPreviewTheme).notif.media.expanded.padding};
  background: #fff;
  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'android'
      ? css`
          background-image: url(${props.imgSrc});
          background-size: ${props.hasMedia ? 'cover' : 'initial'};
          background-position: center center;
          background-repeat: no-repeat;
          width: calc(100% - 20px);
          margin: 0 10px 10px 10px;
          height: ${340 / 2.1}px;
        `
      : ''}
  ${props =>
    (props.theme as unknown as StyledPreviewTheme).platform === 'ios'
      ? css`
          margin-right: -1;
        `
      : ''}

  img,
  audio,
  video {
    width: 100%;
    max-height: ${props =>
      (props.theme as unknown as StyledPreviewTheme).platform === 'ios' ? '28em' : '14em'};
    max-width: 100%;
    object-fit: cover;
    object-position: center;
  }
  audio {
    width: 100%;
  }
  ${editablePart}
`

type NotifContentBrowserThumbProps = {
  icon?: string
  browser: string
  os: string
}
export const NotifContentBrowserThumb = styled.div<NotifContentBrowserThumbProps>`
  background-image: ${props =>
    props.icon
      ? `url(${props.icon})`
      : `url(/medias/img/notif-preview/legacy/${props.browser}.svg)`};

  background-size: 30px 30px;
  flex: 0 0 30px;
  height: 30px;
  margin-right: 15px;
  order: 0;

  ${props =>
    props.os === 'win' && props.browser === 'firefox'
      ? css`
          flex: 0 0 60px;
          height: 60px;
          background-size: 60px 60px;
        `
      : props.browser === 'safari'
        ? css`
            background-size: 18px 18px;
            background-repeat: no-repeat;
            margin-right: 5px;
            height: 18px;
            flex: 0 0 18px;
          `
        : ''}
  ${editablePart}
`

type StatusBarProps = {
  light: boolean
}
export const StatusBar = styled.div<StatusBarProps>`
  pointer-events: none;
  position: absolute;
  margin: ${props => (props.theme as unknown as StyledPreviewTheme).containerPadding};
  padding: 4px 8px;
  top: 5px;
  height: 16px;
  left: 0;
  width: 374px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${props => (props.light ? '#FFF' : '#000')};
`
export const StatusBarOperator = styled.div`
  display: flex;
  align-items: baseline;
  padding-left: 4px;
`
export const StatusBarMain = styled.div`
  text-align: center;
  padding-left: 8px;
  flex-grow: 1;
`

const getBatteryColor = (charging: boolean, level: number, light: boolean) => {
  if (level < 0.1) {
    return 'crimsom'
  }
  if (level < 0.2) {
    return 'orange'
  }
  if (charging && level < 1) {
    return 'HSL(127, 100%, 50%)'
  }
  return light ? '#FFF' : '#000'
}

type StatusBarBatteryProps = {
  light: boolean
  charging: boolean
  level: number
}
export const StatusBarBattery = styled.div<StatusBarBatteryProps>`
  border: 1px solid rgba(${props => (props.light ? '255,255,255,' : '0,0,0,')} 0.3);
  flex: 0 0 22px;
  height: 10px;
  border-radius: 3px;
  display: flex;
  padding: 1px;
  div {
    background: ${props => getBatteryColor(props.charging, props.level, props.light)};
    flex: 0 0 ${props => Math.floor(props.level * 100)}%;
    border-radius: 2px ${props => (props.level < 0.9 ? ' 0 0 ' : ' 2px 2px ')} 2px;
  }
  position: relative;
  &:after {
    position: absolute;
    top: 1.5px;
    right: -4px;
    content: '';
    display: block;
    border-radius: 0 50px 50px 0;
    width: 1.6px;
    height: 4px;
    background: rgba(${props => (props.light ? '255,255,255,' : '0,0,0,')} 0.3);
  }
`

type OpBarProps = {
  num: number
  off?: boolean
}
export const OpBar = styled.div<OpBarProps>`
  border-radius: 1px;
  background-color: currentColor;
  flex: 0 0 3px;
  margin-right: 1px;
  height: ${props => props.num * 2 + 2}px;
  opacity: ${props => (props.off ? 0.5 : 1)};
`

export const AndroidTime = styled.div`
  flex: 0 0 40px;
`

export const AndroidBattery = styled.div`
  background: currentColor;
  display: block;
  position: relative;
  margin-right: 4px;
  width: 7px;
  height: 11px;
  border-radius: 1px;
  &:before {
    border-radius: 1px 1px 0 0;
    width: 3px;
    height: 1px;
    background: currentColor;
    position: absolute;
    content: '';
    top: -1px;
    left: 2px;
  }
`
export const AndroidSvg = styled.svg`
  fill: currentColor;
`
export const CommandCenter = styled.div`
  background: #202020;
  border-radius: 8px;
  z-index: 3;
  padding: 8px 12px;
  position: absolute;
  top: 100px;
  left: 20px;
  right: 20px;
  width: 360px;
  height: 110px;
`
export const CommandCenterTitle = styled.h1`
  display: flex;
  color: #fff;
  font-size: 11px;
  div {
    flex: 1 0 0;
  }
`

export const CommandCenterBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`

type CommandCenterButtonProps = {
  active?: boolean
} & React.HTMLAttributes<HTMLDivElement>
export const CommandCenterButton = styled.div<CommandCenterButtonProps>`
  background: ${props => (props.active ? '#2780E1' : '#4E4E4E')};
  border-radius: 50%;
  width: 38px;
  height: 38px;
  flex-basis: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.active ? '#2C2D24' : '#CBCBCB')};
`

export const CommandCenterHandle = styled.div`
  background: #e9e9e9;
  width: 30px;
  height: 5px;
  border-radius: 5px;
  margin: 10px auto;
`

type ImageLoaderContainerProps = {
  hasIcon?: boolean
  hasMedia?: boolean
}
export const ImageLoaderContainer = styled.div<ImageLoaderContainerProps>`
  display: ${p => (p.hasMedia ? 'flex' : 'unset')};
  align-items: ${p => (p.hasMedia ? 'center' : 'unset')};
  position: ${p => (p.hasIcon ? 'relative' : 'unset')};
  right: ${p => (p.hasIcon ? 2 : 0)};
  margin-top: ${p => (p.hasIcon ? 10 : 0)};
  padding: ${p =>
    (p.theme as unknown as StyledPreviewTheme).platform === 'ios' &&
    !(p.theme as unknown as StyledPreviewTheme).expanded
      ? 10
      : 0}px;
`

type InnerNotifHeadContainerProps = {
  hasIcon?: boolean
  hasMedia?: boolean
}
export const InnerNotifHeadContainer = styled.div<InnerNotifHeadContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const NotifImageLoaderContainerIOS = styled.div`
  position: absolute;
  right: 10px;
  top: 30px;
`
export const NotifDateContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: normal;
  gap: 10;
`
