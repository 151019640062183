import { type SendTestRecipientId } from './send-test-push'

import { restoreValuesFromLocalStorage } from 'com.batch/shared/usecases/save-restore-local'

export const saveTestIdsInLocalStorage = ({
  key,
  value,
  max = 3,
}: {
  key: string
  value: SendTestRecipientId | null | undefined
  max?: number
}) => {
  if (value) {
    const currentValues = restoreValuesFromLocalStorage<SendTestRecipientId>(key)
    const isAlreadyStored = currentValues.some(stored => stored.id === value.id)

    if (!isAlreadyStored) {
      if (currentValues.length >= max) currentValues.shift()
      currentValues.push(value)
      localStorage.setItem(key, JSON.stringify(currentValues))
    }
  }
}
