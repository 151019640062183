import styled, { css } from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import { colors } from 'components/styled/tokens'
import { fillAction, stroke } from 'components/styled/tokens/colors'
import { block } from 'components/styled/tokens/shadows'

const EmbeddedButtonHeight = 60
const EmbeddedButtonWidth = 74
const ButtonHeight = 72
const ButtonWidth = 86
const ButtonMargin = 8
export const LayerBarWrapper = styled.div<{
  $itemsCount: number
}>`
  display: flex;
  flex-direction: column;
  width: ${ButtonWidth}px;
  gap: ${ButtonMargin}px;
  position: fixed;
  top: calc(
    220px - ${p => (p.$itemsCount * ButtonHeight + (p.$itemsCount - 1) * ButtonMargin) / 2}px
  );
  left: calc(50% - 388px);
  margin-left: 10px;
  z-index: 5;
`

export const LayerBarButtonAdd = styled(Icon).attrs({
  icon: 'add',
  thickness: 2,
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  padding-bottom: 3px;
  font-size: 11px;
  background-color: ${fillAction};
  border-radius: 18px;
  color: white;
`

const sharedLayerBarButtonStyle = css`
  width: ${ButtonWidth}px;
  padding: 6px;
  border: 1px solid ${colors.stroke};
  box-shadow: ${block};
  appearance: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DoubleLayerBarButton = styled.div`
  ${sharedLayerBarButtonStyle};

  height: fit-content;
  background-color: white;
  border-radius: 10px;
  gap: 6px;
`

export const LayerBarDivider = styled.hr`
  background: ${colors.stroke};
  height: 1px;
  width: 100%;
  margin: 0;
  border: none;
`

type LayerBarButtonContainerProps = {
  active?: boolean
  addable?: boolean
  $rightAdd?: number
  embedded?: boolean
}
export const LayerBarButtonContainer = styled.button<LayerBarButtonContainerProps>`
  ${p => !p.embedded && sharedLayerBarButtonStyle};

  ${p =>
    p.embedded
      ? css`
          border: none;
          height: ${EmbeddedButtonHeight}px;
          width: ${EmbeddedButtonWidth}px;
          padding: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `
      : css`
          height: ${ButtonHeight}px;
        `};

  border-radius: 10px;

  position: relative;
  font-size: 14px;
  background-color: ${p => (p.active ? colors.opacifyActive : 'white')};

  > svg {
    display: block;
    width: 28px;
    height: 28px;
    overflow: hidden;
    margin: 6px auto 2px auto;
  }

  ${LayerBarButtonAdd} {
    position: absolute;
    z-index: 1;
    ${p =>
      p.embedded
        ? css`
            top: 0;
            right: ${(p: LayerBarButtonContainerProps) => p.$rightAdd ?? 12}px;
          `
        : css`
            top: 10px;
            right: ${(p: LayerBarButtonContainerProps) => p.$rightAdd ?? 17}px;
          `}
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: ${colors.textLight};
    margin-top: 6px;

    i {
      margin: 0 -14px 0 3px;
      font-size: 14px;
      color: ${colors.textWarning};
    }
  }

  &:hover {
    background-color: ${colors.fillBackground};
  }
  &:active {
    background-color: ${colors.fillBackground};
  }
`

export const LayerBarDropdownOptions = styled.div<{
  opened?: boolean
}>`
  display: flex;
  flex-direction: column;
  padding: 6px;
  z-index: 200;
  background-color: white;
  border: 1px solid ${stroke};
  border-radius: 7px;
  box-shadow: ${block};
  visibility: ${p => (p.opened ? 'visible' : 'hidden')};
  pointer-events: all;

  button {
    width: 100%;
    text-align: left;
    justify-content: start;
    padding-right: 32px;
    padding-left: 14px;

    .styled-button-prefix {
      margin-right: 8px;
    }
    i svg path {
      stroke-width: 1.3;
    }
    .styled-icon-loading,
    .styled-icon-active {
      position: absolute;
      right: 10px;

      svg path {
        stroke-width: 1.5;
      }
    }
  }
  .styled-default-option {
    margin-top: 6px;
    padding-top: 6px;
    border-top: 1px solid ${colors.stroke};

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 14px;
    }
  }
  button + button {
    margin-top: 4px;
  }
`
