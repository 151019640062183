import * as React from 'react'
import styled from 'styled-components'

import { Icon } from 'components/common/svg-icon'
import { TableToggle, TableToggleItem } from 'components/common/tabletoggle'
import { Tooltip } from 'components/common/tooltip'

type PreviewMode = 'normal' | 'expanded' | 'landing'
type PreviewModeToggleProps = {
  mode: PreviewMode
  vertical?: boolean
  update: (mode: 'normal' | 'expanded' | 'landing') => void
  hasLanding: boolean
}

export const PreviewModeToggle = ({
  mode,
  update,
  hasLanding,
  vertical,
}: PreviewModeToggleProps): React.ReactElement => {
  const createUpdater = React.useCallback((m: PreviewMode) => () => update(m), [update])
  return (
    <Container vertical={vertical}>
      <TableToggle kind="filter">
        <Tooltip tooltip="Lockscreen" placement={vertical ? 'right' : 'top'}>
          <TableToggleItem active={mode === 'normal'} onClick={createUpdater('normal')}>
            <Icon icon="push-compact" size={18} />
          </TableToggleItem>
        </Tooltip>
        <Tooltip tooltip="Expanded" placement={vertical ? 'right' : 'top'}>
          <TableToggleItem active={mode === 'expanded'} onClick={createUpdater('expanded')}>
            <Icon icon="push-expanded" size={18} />
          </TableToggleItem>
        </Tooltip>
        {hasLanding && (
          <Tooltip tooltip="Mobile landing" placement={vertical ? 'right' : 'top'}>
            <TableToggleItem active={mode === 'landing'} onClick={createUpdater('landing')}>
              <Icon icon="in-app" size={18} />
            </TableToggleItem>
          </Tooltip>
        )}
      </TableToggle>
    </Container>
  )
}

type ContainerProps = {
  vertical?: boolean
}
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
`
