import { type Map } from 'immutable'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { Wrapper } from 'components/common/empty-states'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Title } from 'components/styled/text'

import { type fetchingState } from 'com.batch.redux/_records'

import { CHANNEL_THEMES } from 'com.batch/profilebase/constants/channel-themes'
import { formatChannelName } from 'com.batch/profilebase/infra/formats/format-channel-name'
import { type AnalyticsChannel } from 'com.batch/profilebase/infra/types/channel-type'
import { type ReachabilityTimeserieRecord } from 'com.batch/profilebase/models/reachability-timeserie.records'
import { type SubDistributionRecord } from 'com.batch/profilebase/models/sub-distribution.records'
import { ImportedTokensBlock } from 'com.batch/profilebase/ui/components/imported-tokens-block/imported-tokens-block'
import { MarketingSubscriptionBlock } from 'com.batch/profilebase/ui/components/marketing-subscription-block/marketing-subscription-block'
import { SubscriptionGrowthBlock } from 'com.batch/profilebase/ui/components/subscription-growth-block/subscription-growth-block'
import { type PushPlatformFilterType } from 'com.batch/shared/infra/types/push-platform-filter.type'
import { ChannelSectionTitle } from 'com.batch/shared/ui/component/channel-section-title.styles'
import { STATUS } from 'constants/common'

type Props = {
  channel: AnalyticsChannel
  filter?: React.ReactNode
  importedTokens?: number | null
  link?: React.ReactNode
  loadingState: fetchingState
  platformFilter?: PushPlatformFilterType
  subsByDay: Map<string, ReachabilityTimeserieRecord>
  subsCounter: number
  subsDistribution: SubDistributionRecord
}

export const ChannelOverview = ({
  channel,
  filter,
  importedTokens = null,
  link,
  loadingState,
  platformFilter,
  subsByDay,
  subsCounter,
  subsDistribution,
}: Props): React.ReactElement => {
  const channelTheme = React.useMemo(() => CHANNEL_THEMES[channel], [channel])
  const theme = useTheme()

  const channelName = React.useMemo(
    () => formatChannelName(channel, platformFilter, true),
    [channel, platformFilter]
  )

  const noData = React.useMemo(
    () =>
      loadingState === 'LOADED' &&
      subsDistribution.unknown.value === 0 &&
      subsDistribution.subscribed.value === 0 &&
      subsDistribution.unsubscribed.value === 0,
    [loadingState, subsDistribution]
  )

  return (
    <React.Fragment>
      <ChannelSectionTitle>
        <div>
          <Title>
            <Icon icon={channelTheme.icon} />
            {channelTheme.title}
          </Title>
          {filter && filter}
        </div>
        {link && link}
      </ChannelSectionTitle>
      <Wrapper
        isEmpty={noData || Boolean(theme.isEmpty)}
        isLoading={loadingState === STATUS.LOADING || Boolean(theme.isLoading)}
        isOverlayShown={!theme.isEmpty && noData}
        overlayContainerStyle={{
          background:
            'radial-gradient(76.01% 50% at 50% 50%, rgba(248, 248, 248, 0.94) 0%, rgba(248, 248, 248, 0.9) 50%, rgba(248, 248, 248, 0.60) 100%)',
        }}
        overlayProps={{
          status: 'empty',
          title: `No ${channelName} data available`,
          description:
            'We haven’t been able to find any data yet. Please check if your SDK is up and running to fill your userbase.',
          links: [
            {
              name: `Install the ${channelName} SDK`,
              href: 'https://doc.batch.com/dashboard/analytics/troubleshooting',
              intent: 'action',
            },
          ],
        }}
      >
        {importedTokens !== null && <ImportedTokensBlock value={importedTokens} />}
        <Grid gap={16} template="484px minmax(400px, 1fr)" alignItems="stretch">
          <MarketingSubscriptionBlock
            channel={channel}
            distribution={subsDistribution}
            platformFilter={platformFilter}
            theme={channelTheme}
          />
          <SubscriptionGrowthBlock
            channel={channel}
            cumulLastMonth={subsCounter}
            loadingState={loadingState}
            platformFilter={platformFilter}
            subscriptionsByDay={subsByDay}
            theme={channelTheme}
          />
        </Grid>
      </Wrapper>
    </React.Fragment>
  )
}
