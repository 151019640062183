import { type DispatchExtraBoundFn, type ReduxAction } from 'com.batch.redux/_records'
import { promiseActionCreator } from 'com.batch.redux/actionCreator'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

import { type SegmentRecord } from 'com.batch/segments/models/segment.records'
import { fetchSegmentList } from 'com.batch/segments/usecases/fetch-segment-list'

type UpdateSegmentAction = ReduxAction<'UPDATE_SEGMENT', undefined>

export type UpdateSegmentFailureAction = ReduxAction<'UPDATE_SEGMENT_FAILURE', undefined>

export type UpdateSegmentSuccessAction = ReduxAction<'UPDATE_SEGMENT_SUCCESS', undefined>

export type UpdateSegmentActionType =
  | UpdateSegmentAction
  | UpdateSegmentSuccessAction
  | UpdateSegmentFailureAction

export const updateSegment = (segment: SegmentRecord): DispatchExtraBoundFn<Promise<void>> => {
  return async (dispatch, getState, { segmentsService }) => {
    const state = getState()
    const project = currentProjectSelector(state)

    return promiseActionCreator<void>({
      dispatch,
      promise: segmentsService
        .updateSegment({
          projectKey: project.projectKey,
          segmentRequest: {
            displayName: segment.displayName,
            name: segment.name,
            query: segment.query,
          },
        })
        .then(() => {
          dispatch(
            fetchSegmentList({
              page: state.segments.page,
              pageSize: 10,
              search: state.segments.search,
              sortDirection: state.segments.sortDirection,
              sortField: state.segments.sortBy,
              forceTrashCache: true,
            })
          )
        }),
      payload: segment,
      actionName: 'UPDATE_SEGMENT',
    })
  }
}
