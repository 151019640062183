import Immutable, { type List } from 'immutable'
import * as React from 'react'
import { useDispatch } from 'com.batch.common/react-redux'

import { FilterSelectMulti } from 'components/filter/filter-select-multi/filter-select-multi'

import { languages as defaultLanguages } from 'com.batch/message/store/message.selector'
import { type LanguageRecord } from 'com.batch.redux/_records'

import { updateAnalyticsFilters } from 'com.batch/orchestration-analytics/usecases/analytics-filters'
import { useGetAnalyticsFilters } from 'com.batch/orchestration-analytics/ui/hooks/use-get-analytics-filters'
import { type langId } from 'com.batch/message/store/message.state'

type LanguageFilterProps = {
  token?: string
  languages: List<langId>
  context: 'automations' | 'campaigns' | 'project'
}
export const LanguageFilter = ({
  token,
  languages,
  context,
}: LanguageFilterProps): React.ReactNode => {
  const dispatch = useDispatch()
  const { filters } = useGetAnalyticsFilters(token)
  const selectedLanguages: List<LanguageRecord> = React.useMemo(
    () =>
      defaultLanguages.filter(language =>
        filters.get('content_language', Immutable.Map()).includes(language.value)
      ),
    [filters]
  )

  const sortedLanguages = React.useMemo(() => {
    const defaultLang = defaultLanguages.find(language => language.value === 'default')
    return Immutable.List().push(
      defaultLang,
      ...defaultLanguages
        .filter(language => language.value !== 'default' && languages.includes(language.value))
        .sortBy(language => language.label)
    )
  }, [languages])

  const onLanguageChange = React.useCallback(
    (list: List<LanguageRecord>) => {
      if (token)
        dispatch(
          updateAnalyticsFilters({
            token,
            filters:
              list.size > 0
                ? filters.set(
                    'content_language',
                    list.map(language => language.value)
                  )
                : filters.delete('content_language'),
          })
        )
    },
    [dispatch, token, filters]
  )
  const optionToString = React.useCallback(
    (language?: LanguageRecord | null) => language?.label ?? '',
    []
  )
  const formatTerm = React.useCallback(
    (count: number) => {
      if (count === 1) {
        return selectedLanguages.first()?.label ?? `${count} language`
      } else if (count === 2) {
        return `${selectedLanguages.first()?.label}, ${selectedLanguages.last()?.label}`
      } else {
        return `${count} languages`
      }
    },
    [selectedLanguages]
  )

  return (
    <FilterSelectMulti
      id="analytics-filter-lang"
      options={sortedLanguages}
      value={selectedLanguages}
      onChange={onLanguageChange}
      optionToString={optionToString}
      placeholder={'All languages'}
      term={formatTerm}
      toggleButtonWidth={context === 'automations' ? 321 : '100%'}
      menuStyle={{ maxWidth: 300 }}
    />
  )
}
