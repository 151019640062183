import * as React from 'react'

import { FormGroup } from './wrap-label.styles'

type WrapLabelProps = {
  label: React.ReactNode
  children: React.ReactNode
  checkbox?: boolean
  id?: string
} & React.HTMLAttributes<HTMLDivElement>
export const WrapLabel = ({
  label,
  children,
  checkbox = false,
  id,
  ...props
}: WrapLabelProps): React.ReactElement => {
  if (checkbox) {
    return (
      <div className="checkbox">
        <label>
          {children}
          {label}
        </label>
      </div>
    )
  }
  return (
    <FormGroup {...props}>
      {id ? (
        <label htmlFor={id} style={{ marginBottom: 8 }}>
          {label}
        </label>
      ) : (
        <label style={{ marginBottom: 8 }}>{label}</label>
      )}
      {children}
    </FormGroup>
  )
}
