import Immutable, { type Map, type Set } from 'immutable'
import { get as _get } from 'lodash-es'
import request from 'superagent-interface-promise'

import { HEXtoRGB } from 'components/styled/tokens'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { generateUrl } from 'com.batch.common/router'
import { hexToHsl, shiftHex } from 'com.batch.common/utils'

import { type AppRecord, type CampaignRecord } from 'com.batch.redux/_records'
import campaignAPI from 'com.batch.redux/campaign.api'
import {
  FieldFactory,
  type FieldKind,
  type FieldRecord,
  PayloadFactory,
  type PayloadProps,
  type StyleProperty,
  ThemeFactory,
  type ThemeRecord,
} from 'com.batch.redux/theme.records'
import { normalizeUser } from 'com.batch.redux/user.api'

export const STYLES = {
  FONT_SIZE: 'fontSize',
  FONT_COLOR: 'color',
  FONT_WEIGHT: 'fontWeight',
  BACKGROUND_COLOR: 'backgroundColor',
  BACKGROUND_TRANSPARENCY: 'backgroundOpacity',
  BACKGROUND_SIZE: 'backgroundSize',
  PADDING: 'padding',
  MARGIN: 'margin',
  BORDER_RADIUS: 'borderRadius',
  BORDER_WIDTH: 'borderWidth',
  BORDER_COLOR: 'borderColor',
}

const dropPx = (text: string) => (text ? text.replace(/px/g, '') : '')

export const computeArgbColor = (cssHexColor: string, transparency: number): string => {
  const [r, g, b] = HEXtoRGB(cssHexColor)
  return `#${Math.ceil((transparency / 100) * 255)
    .toString(16)
    .padStart(2, '0')}${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`
}
export const computeRGBA = (cssHexColor: string, transparency: number): string => {
  const [r, g, b] = HEXtoRGB(cssHexColor)
  return `rgba(${r},${g},${b}, ${transparency / 100})`
}

const setMobileStyleFor = (theme: ThemeRecord) => {
  const vars: {
    [key: string]: any
  } = {}
  const kind = theme.payloadVars.kind
  if (kind === 'banner' || kind === 'modal') {
    if (kind === 'banner') {
      vars['--valign'] = theme.payloadVars.banner_bottom ? 'bottom' : 'top'
      vars['--countdown-valign'] = theme.payloadVars.banner_bottom ? 'top' : 'bottom'
    } else {
      vars['--countdown-valign'] = 'top'
      vars['--image-bs'] =
        theme.fields.getIn(['image', 'style', 'backgroundSize'], 'cover') === 'cover'
          ? 'fill'
          : 'fit'
    }
    vars['--countdown-color'] = theme.fields.getIn(
      ['dismissIndicator', 'style', 'backgroundColor'],
      ''
    )

    vars['--ios-shadow'] = '15 0.5 #000000'
    vars['--android-shadow'] = '10'
    vars['--title-color'] = theme.fields.getIn(['title', 'style', 'color'], '')
    vars['--body-color'] = theme.fields.getIn(['text', 'style', 'color'], '')
    vars['--bg-color'] = theme.fields.getIn(['general', 'style', 'backgroundColor'], '')
    vars['--close-color'] = theme.fields.getIn(['close', 'style', 'color'], 'transparent')
    vars['--close-bg-color'] = theme.fields.getIn(
      ['close', 'style', 'backgroundColor'],
      'transparent'
    )
    vars['--cta-android-shadow'] = 'auto'
    vars['--cta1-color'] = theme.fields.getIn(['cta1', 'style', 'backgroundColor'], '')
    vars['--cta1-text-color'] = theme.fields.getIn(['cta1', 'style', 'color'], '')
    vars['--cta2-color'] = theme.fields.getIn(['cta2', 'style', 'backgroundColor'], '')
    vars['--cta2-text-color'] = theme.fields.getIn(['cta2', 'style', 'color'], '')
    vars['--margin'] = !theme.payloadVars.detached ? '0' : '15'
    vars['--corner-radius'] = !theme.payloadVars.detached ? 0 : 5
    vars['--cta1-br'] = dropPx(String(theme.fields.getIn(['cta1', 'style', 'borderRadius'], '0')))
    vars['--cta2-br'] = dropPx(String(theme.fields.getIn(['cta2', 'style', 'borderRadius'], '0')))
    vars['--mode'] =
      !theme.payloadVars.detached || theme.payloadVars.banner_bottom
        ? 'auto'
        : theme.dark
          ? 'light'
          : 'dark'
  }
  if (kind === 'universal') {
    vars['--general-bg'] = theme.fields.getIn(['general', 'style', 'backgroundColor'], null)
    vars['--image-bs'] =
      theme.fields.getIn(['image', 'style', 'backgroundSize'], 'cover') === 'cover' ? 'fill' : 'fit'
    vars['--header-fs'] = dropPx(String(theme.fields.getIn(['header', 'style', 'fontSize'], '13')))
    vars['--header-fw'] = theme.fields.getIn(['header', 'style', 'fontWeight'], 'bold')
    vars['--header-m'] = dropPx(
      String(theme.fields.getIn(['header', 'style', 'margin'], '0 0 10 0'))
    )
    vars['--header-c'] = theme.fields.getIn(['header', 'style', 'color'], '')
    vars['--title-fs'] = dropPx(String(theme.fields.getIn(['title', 'style', 'fontSize'], '24')))
    vars['--title-fw'] = theme.fields.getIn(['title', 'style', 'fontWeight'], 'bold')
    vars['--title-m'] = dropPx(String(theme.fields.getIn(['title', 'style', 'margin'], '0 0 0 10')))
    vars['--title-c'] = theme.fields.getIn(['title', 'style', 'color'], '')
    vars['--text-fs'] = dropPx(String(theme.fields.getIn(['text', 'style', 'fontSize'], '15')))
    vars['--text-fw'] = theme.fields.getIn(['text', 'style', 'fontWeight'], 'normal')
    vars['--text-m'] = dropPx(String(theme.fields.getIn(['text', 'style', 'margin'], '0 10 0 10')))
    vars['--text-c'] = theme.fields.getIn(['text', 'style', 'color'], '')
    vars['--separator-bc'] = theme.fields.getIn(['separator', 'style', 'borderColor'], '')
    vars['--separator-bw'] = '0'
    vars['--cta1-c'] = theme.fields.getIn(['cta1', 'style', 'color'], '')
    vars['--cta1-bg'] = theme.fields.getIn(['cta1', 'style', 'backgroundColor'], '')
    vars['--cta1-fw'] = theme.fields.getIn(['cta1', 'style', 'fontWeight'], 'bold')
    vars['--cta1-br'] = dropPx(String(theme.fields.getIn(['cta1', 'style', 'borderRadius'], '0')))
    vars['--cta2-c'] = theme.fields.getIn(['cta2', 'style', 'color'], '')
    vars['--cta2-bg'] = theme.fields.getIn(['cta2', 'style', 'backgroundColor'], '')
    vars['--cta2-fw'] = theme.fields.getIn(['cta2', 'style', 'fontWeight'], 'bold')
    vars['--cta2-br'] = dropPx(String(theme.fields.getIn(['cta2', 'style', 'borderRadius'], '0')))
    vars['--close-bg'] = theme.fields.getIn(['close', 'style', 'backgroundColor'], '')
    vars['--close-c'] = theme.fields.getIn(['close', 'style', 'color'], '')
    vars['--mode'] = theme.statusBarMode
    if (!theme.payloadVars.stack_cta_h) {
      vars['--cta1-m'] = '10 10 10 10'
      vars['--cta2-m'] = '5 10 10 10'
    }
  }
  if (kind === 'image') {
    vars['--image-bs'] =
      theme.fields.getIn(['image', 'style', 'backgroundSize'], 'cover') === 'cover' ? 'fill' : 'fit'
    vars['--close-color'] = theme.fields.getIn(['close', 'style', 'color'], 'transparent')
    vars['--close-bg-color'] = theme.fields.getIn(
      ['close', 'style', 'backgroundColor'],
      'transparent'
    )
    vars['--container-bg-color'] = theme.fields.getIn(
      ['general', 'style', 'backgroundColor'],
      '#000000'
    )
  }
  if (kind === 'webview') {
    vars['--mode'] = theme.statusBarMode
    vars['--ios-loader-size'] = 'large'
    // vars['--android-statusbar-bg'] = '#000000'
    // vars['--android-statusbar-fg'] = 'light'
    const bgOpacity = theme.fields.get('general')?.get('style').get('backgroundOpacity')
    vars['--bg-color'] = computeArgbColor(
      String(theme.fields.getIn(['general', 'style', 'backgroundColor'], '#FFFFFF')),
      // parseInt(theme.fields.getIn(['general', 'style', 'backgroundOpacity'], 100))
      typeof bgOpacity === 'string'
        ? parseInt(bgOpacity, 100)
        : typeof bgOpacity === 'number'
          ? bgOpacity
          : 100
    )
    vars['--close-bg-color'] = theme.fields.getIn(['close', 'style', 'backgroundColor'])
    vars['--close-color'] = theme.fields.getIn(['close', 'style', 'color'])
  }
  for (const key in vars) {
    if (vars[key] === '') {
      delete vars[key]
    }
  }
  return vars
}

// ====================== normalize Theme (init)
export function initTheme(raw: {
  theme: {
    payloadVars: string
    dashboardVars: string
    stylePreview: string
    styleVars: string
    code: string
    appIds: Array<number>
    id: number
  }
}): ThemeRecord {
  try {
    const rawTheme = raw.theme
    const payloadVarsRaw = JSON.parse(rawTheme.payloadVars).common
    const dashboard = JSON.parse(rawTheme.dashboardVars)
    payloadVarsRaw.kind = dashboard.kind ?? 'universal'
    payloadVarsRaw.detached = dashboard.banner_detached ?? false
    payloadVarsRaw.banner_bottom = _get(dashboard, 'banner_bottom', true)
    if (payloadVarsRaw.kind !== 'universal') {
      payloadVarsRaw.stack_cta_h = _get(payloadVarsRaw, 'cta_direction', 'h') === 'h'
    }
    const payloadVars = PayloadFactory(payloadVarsRaw)
    const userStyles = JSON.parse(rawTheme.stylePreview)
    const st = JSON.parse(rawTheme.styleVars)

    const upd = _get(raw, 'theme.updated', null)
    const updated = upd ? dayjs(upd) : upd

    const updBy = _get(raw, 'theme.updatedBy', null)
    const updatedBy = updBy ? normalizeUser(updBy) : null

    let theme = buildFieldsForTheme(
      ThemeFactory({
        payloadVars,
        name: dashboard.name,
        code: rawTheme.code,
        id: rawTheme.id,
        color: dashboard.color,
        apps: Immutable.Set(rawTheme.appIds),
        dark: dashboard.dark,
        statusBarMode: _get(st, 'common[--mode]', 'light'),
        nbCampaignsRunning: _get(raw, 'nbRunningCampaign', 0),
        updated,
        updatedBy,
      })
    )

    theme = theme.set(
      'fields',
      theme.fields.map(field => {
        return (
          field
            .set('hidden', dashboard.fields.indexOf(field.id) === -1)
            // $FlowExpectedError moche
            .set('style', field.style.merge(Immutable.fromJS(_get(userStyles, field.id, {}))))
        )
      })
    )

    return ensureClosability(theme)
  } catch (err: any) {
    console.log(err)
    throw 'unable to parse theme'
  }
}

const saveTheme: (
  app: AppRecord,
  theme: ThemeRecord,
  isDuplication: boolean
) => Promise<{
  theme: ThemeRecord
  isDuplication: boolean
}> = (app, theme, isDuplication) => {
  const data = formatThemeForSave(theme)

  return request
    .post(`/api/app/${app.id}/theme`, data)
    .then(
      () => {
        return { theme, isDuplication }
      },
      err => {
        throw { error: err.body }
      }
    )
    .catch(error => {
      throw error
    })
}
export const formatThemeForSave = (originalTheme: ThemeRecord): Record<any, any> => {
  /* dashboard vars is used for saving "dashboard only" vars temporary stored in payloadVars
    this is lame. we should use dashboard vars all along for config vars the mobile does not need
    but changing this will require a pretty big code & database update
  */

  // legacy : we did not always have a kind, not sure if still usefull
  const theme = !originalTheme.payloadVars.kind
    ? originalTheme.set('payloadVars', originalTheme.payloadVars.set('kind', 'universal'))
    : originalTheme

  const stVars = setMobileStyleFor(theme)
  const fields: Array<FieldKind> = []
  theme.fields.forEach(f => {
    if (!f.hidden) fields.push(f.id)
  })
  const payload: PayloadProps = theme.payloadVars.toJS()
  delete payload.banner_bottom
  if (theme.payloadVars.kind === 'banner' || theme.payloadVars.kind === 'modal') {
    payload.cta_direction = theme.payloadVars.stack_cta_h ? 'h' : 'v'
  }
  if (theme.payloadVars.kind !== 'image') {
    delete payload.fullscreen
  }
  if (theme.payloadVars.kind !== 'universal') {
    delete payload.flip_hero_h
    delete payload.flip_hero_v
    delete payload.hero_split_ratio
    delete payload.attach_cta_bottom
    delete payload.stretch_cta_h
    delete payload.stack_cta_h
  }
  if (theme.payloadVars.kind !== 'universal' && theme.payloadVars.kind !== 'webview') {
    payload.auto_close = theme.fields.get('dismissIndicator')?.hidden ? 0 : 10000
  } else {
    delete payload.auto_close
  }
  payload.close = !theme.fields.get('close', FieldFactory()).hidden
  let mobileStyle = ''
  const kind = theme.payloadVars.kind

  if (kind === 'universal') {
    mobileStyle = theme.payloadVars.stack_cta_h
      ? '@import sdk("generic1-h-cta");@ios {.ctas-h-sep{width: 0;}}'
      : '@import sdk("generic1-v-cta");'
  }
  if (kind === 'banner') {
    mobileStyle = '@import sdk("banner1");'
  }
  if (kind === 'image') {
    mobileStyle = theme.payloadVars.fullscreen
      ? '@import sdk("image1-fullscreen");'
      : '@import sdk("image1-detached");'
  }
  if (kind === 'modal') {
    mobileStyle = '@import sdk("modal1");'
    if (!theme.fields.get('image', FieldFactory()).hidden) {
      mobileStyle += '@import sdk("modal-icon");#title{padding-top:20}'
    }
  }
  if (kind === 'webview') {
    mobileStyle = '@import sdk("webview1");'
  }
  if (theme.fields.getIn(['cta1', 'style', 'borderRadius'])) {
    mobileStyle += '#cta1{border-radius: var(--cta1-br);}'
  }
  if (theme.fields.getIn(['cta2', 'style', 'borderRadius'])) {
    mobileStyle += '#cta2{border-radius: var(--cta2-br);}'
  }
  return {
    id: theme.id !== 'new' ? theme.id : null,
    fromDuplication: theme.fromDuplication,
    dashboardVars: {
      fields,
      kind: theme.payloadVars.kind,
      banner_detached: theme.payloadVars.detached, // stored as banner_detached (legacy), but as "detached" in payloadVars
      banner_bottom: theme.payloadVars.banner_bottom,
      code: theme.code,
      color: theme.color,
      dark: theme.dark,
      name: theme.name,
    },
    payloadVars: { common: payload },
    stylePreview: theme.fields.map(field => field.style.toJS()).toJS(),
    styleVars: {
      common: stVars,
    },
    mobileStyle,
  }
}

const ensureClosability: (theme: ThemeRecord) => ThemeRecord = theme => {
  const closeOptions = [
    theme.fields.get('cta1'),
    theme.fields.get('cta2'),
    theme.fields.get('close'),
  ]
  if (theme.payloadVars.kind === 'modal' || theme.payloadVars.kind === 'banner') {
    closeOptions.push(theme.fields.get('dismissIndicator'))
  }
  const visibleClose = closeOptions.filter(f => !!f && !f.hidden)
  switch (visibleClose.length) {
    case 1:
      closeOptions.forEach(field => {
        if (field) {
          const fieldRecord = theme.fields.get(field.id)
          if (fieldRecord) {
            theme = theme.set(
              'fields',
              theme.fields.set(field.id, fieldRecord.set('removable', field.hidden))
            )
          }
        }
      })
      break
    default:
      closeOptions.forEach(field => {
        if (field) {
          if (field.hidden) {
            theme = theme.set(
              'fields',
              theme.fields
                .setIn(
                  [field.id, 'removable'],
                  field.id !== 'cta1' || theme.fields.get('cta2')?.hidden
                )
                .setIn(
                  [field.id, 'hidden'],
                  field.id === 'cta1' && !theme.fields.get('cta2')?.hidden ? false : field.hidden
                )
            )
          } else {
            // CTA 2 doit être masqué avant CTA1
            if (field.id === 'cta1' && !theme.fields.get('cta2')?.hidden) {
              theme = theme.set(
                'fields',
                theme.fields.setIn(['cta1', 'removable'], false).setIn(['cta1', 'hidden'], false)
              )
            } else {
              theme = theme.set('fields', theme.fields.setIn([field.id, 'removable'], true))
            }
          }
        }
      })
  }
  return theme
}

const buildFieldsForTheme: (theme: ThemeRecord) => ThemeRecord = theme => {
  const gray = '#212121'
  // not to dark for corect shceme generation
  const color = hexToHsl(theme.color)
  if (color.l > 0.5) {
    color.l = 0.5
  }
  const kind = theme.payloadVars.kind
  const isBanner = kind === 'banner'
  const isImage = kind === 'image'
  const isModal = kind === 'modal'
  const isWebview = kind === 'webview'

  const isWebviewStyle: [StyleProperty, string | number][] = isWebview
    ? [['backgroundOpacity', 100]]
    : []
  const style: [StyleProperty, string | number][] = [
    ['backgroundColor', theme.dark ? shiftHex(theme.color, 0.74, 1.5) : '#FFFFFF'],
    ...isWebviewStyle,
  ]
  let fields = Immutable.Map<FieldKind, FieldRecord>().set(
    'general',
    FieldFactory({
      id: 'general',
      icon: 'settings',
      configurable: true,
      selected: false,
      hidden: false,
      label: 'General',
      style: Immutable.Map<StyleProperty, string | number>(style),
      editableStyles: Immutable.List(
        isWebview ? ['backgroundColor', 'backgroundOpacity'] : ['backgroundColor']
      ),
    })
  )

  if (isImage) {
    fields = fields.set(
      'global',
      FieldFactory({
        id: 'global',
        data: 'global',
        icon: 'link',
        configurable: isModal,
        selected: false,
        hidden: isModal,
        label: 'Global action',
        editableStyles: Immutable.List(),
      })
    )
  }
  if (theme.payloadVars.kind === 'universal') {
    fields = fields.set(
      'header',
      FieldFactory({
        id: 'header',
        data: 'header',
        icon: 'text',
        configurable: true,
        hidden: false,
        label: 'Header',
        style: Immutable.Map([
          ['color', theme.dark ? shiftHex(theme.color, 0.68, 2.4) : shiftHex(gray, 1, 2.92)],
          ['margin', '0px 0px 0px 0px'],
          ['fontSize', '13px'],
          ['fontWeight', 'bold'],
        ]),
        editableStyles: Immutable.List(['color']),
      })
    )
  }
  if (!isBanner && !isWebview) {
    fields = fields.set(
      'image',
      FieldFactory({
        id: 'image',
        data: 'imageUrl',
        icon: 'image',
        hidden: isModal,
        configurable: true,
        removable: !isImage,
        label: 'Image',
        style: Immutable.Map([['backgroundSize', isModal ? 'contain' : 'cover']]),
        editableStyles: Immutable.List(['backgroundSize']),
      })
    )
  }
  if (!isImage && !isWebview) {
    fields = fields
      .set(
        'title',
        FieldFactory({
          id: 'title',
          data: 'title',
          icon: 'text',
          removable: !isModal,
          configurable: true,
          hidden: false,
          label: 'Title',
          style: Immutable.Map([
            ['color', theme.dark ? shiftHex(theme.color, 0, 50) : shiftHex(theme.color, 0.82, 1.3)],
            ['margin', '0px 10px 10px 10px'],
            ['fontSize', isBanner || isModal ? '14px' : '24px'],
            ['fontWeight', 'bold'],
          ]),
          editableStyles: Immutable.List(['color']),
        })
      )
      .set(
        'text',
        FieldFactory({
          id: 'text',
          data: 'text',
          icon: 'text',
          configurable: true,
          hidden: false,
          removable: !isModal,
          label: 'Body',
          style: Immutable.Map([
            ['color', theme.dark ? shiftHex(theme.color, 0.65, 3.1) : shiftHex(gray, 0.65, 3.1)],
            ['margin', '0px 10px 0px 10px'],
            ['fontSize', isBanner || isModal ? '12px' : '15px'],
            ['fontWeight', 'normal'],
          ]),
          editableStyles: Immutable.List(['color']),
        })
      )
      .set(
        'cta1',
        FieldFactory({
          id: 'cta1',
          data: 'cta1Button',
          icon: 'link',
          configurable: true,
          hidden: false,
          removable: false,
          label: 'Main button',
          style: Immutable.Map([
            ['color', theme.dark ? shiftHex(theme.color, 0.65, 5) : '#FFFFFF'],
            [
              'backgroundColor',
              theme.dark ? shiftHex(theme.color, 0.65, 1.8) : shiftHex(theme.color, 0.67, 1.13),
            ],
            ['fontSize', isBanner || isModal ? '11px' : '13px'],
            ['borderRadius', isBanner || isModal ? '5px' : '0px'],
            ['margin', isBanner || isModal ? '5px 5px 5px 5px' : '0px 0px 0px 0px'],
          ]),
          editableStyles: Immutable.List(['color', 'backgroundColor', 'borderRadius']),
        })
      )
      .set(
        'cta2',
        FieldFactory({
          id: 'cta2',
          data: 'cta2Button',
          icon: 'link',
          hidden: false,
          configurable: true,
          removable: true,
          label: 'Secondary button',
          style: Immutable.Map([
            [
              'color',
              theme.dark ? shiftHex(theme.color, 0.65, 1.5) : shiftHex(theme.color, 0.67, 1.92),
            ],
            [
              'backgroundColor',
              theme.dark ? shiftHex(theme.color, 0.68, 2.4) : shiftHex(theme.color, 0.67, 3.1),
            ],
            ['borderRadius', isBanner || isModal ? '5px' : '0px'],
            ['fontSize', isBanner || isModal ? '11px' : '13px'],
            ['margin', isBanner || isModal ? '5px 5px 5px 5px' : '0px 0px 0px 0px'],
          ]),
          editableStyles: Immutable.List(['color', 'backgroundColor', 'borderRadius']),
        })
      )
  }
  fields = fields.set(
    'close',
    FieldFactory({
      id: 'close',
      icon: 'close',
      configurable: true,
      removable: !isImage && !isWebview,
      hidden: false,
      label: 'Close button',
      style: Immutable.Map([
        [
          'backgroundColor',
          isBanner || isModal
            ? null
            : theme.dark
              ? shiftHex(theme.color, 0.8, 2.8)
              : shiftHex(gray, 0.1, 0.86),
        ],
        [
          'color',
          isBanner || isModal
            ? theme.dark
              ? shiftHex(theme.color, 0.65, 3.1)
              : shiftHex(gray, 0.65, 3.1)
            : theme.dark
              ? shiftHex(theme.color, 0.65, 1.5)
              : '#FFFFFF',
        ],
      ]),
      editableStyles: Immutable.List(['color', 'backgroundColor']),
    })
  )
  if (isBanner || isModal || isImage) {
    fields = fields.set(
      'dismissIndicator',
      FieldFactory({
        id: 'dismissIndicator',
        icon: 'timer',
        hidden: true,
        configurable: true,
        removable: true,
        label: 'Auto dismiss',
        style: isImage
          ? Immutable.Map()
          : Immutable.Map([
              [
                'backgroundColor',
                theme.dark ? shiftHex(theme.color, 2, 3) : shiftHex(theme.color, 2, 0.8),
              ],
            ]),
        editableStyles: isImage ? Immutable.List() : Immutable.List(['backgroundColor']),
      })
    )
  }
  return theme.set('fields', fields)
}

const linkTheme = (
  appId: number,
  theme: ThemeRecord,
  link: boolean
): Promise<{
  appId: number
  theme: ThemeRecord
  link: boolean
}> => {
  return request
    .post(`/api/app/${appId}/theme/${theme.id}/${link ? 'attach' : 'dettach'}`)
    .then(() => {
      return {
        appId,
        theme,
        link,
      }
    })
    .catch(error => {
      throw error
    })
}

const linkThemes = (
  appId: number,
  themes: Map<number | 'new', ThemeRecord>,
  link: boolean
): Promise<{
  themes: Map<number, ThemeRecord>
}> => {
  const promiseArray: Array<any> = []

  themes.forEach(theme =>
    promiseArray.push(
      request
        .post(`/api/app/${appId}/theme/${theme.id}/${link ? 'attach' : 'dettach'}`)
        .then(() => {
          const updatedTheme = link
            ? theme.set('apps', theme.apps.add(appId))
            : theme.set('apps', theme.apps.delete(appId))
          return [theme.id, updatedTheme]
        })
        .catch(error => {
          throw error
        })
    )
  )

  return Promise.all(promiseArray)
    .then(themes => ({
      themes: Immutable.Map<number, ThemeRecord>(themes),
    }))
    .catch(err => {
      throw err
    })
}

const archiveTheme = (
  app: AppRecord,
  theme: ThemeRecord
): Promise<{
  theme: ThemeRecord
}> => {
  return request.delete(`/api/app/${app.id}/theme/${theme.id}`).then(
    () => {
      return { theme }
    },
    error => {
      throw error
    }
  )
}

export default {
  fetchLinkedCampaigns: (
    theme: ThemeRecord
  ): Promise<{
    theme: ThemeRecord
    campaigns: Set<CampaignRecord>
  }> => {
    return request
      .get(`/api/theme/${theme.id}/campaigns`)
      .then(response => {
        return {
          theme,
          campaigns: Immutable.Set(
            response.body.result.map(raw => campaignAPI.rawCampaignToRecord(raw, Immutable.Set()))
          ),
        }
      })
      .catch(error => {
        throw error
      })
  },
  fetchThemes: (
    app: AppRecord
  ): Promise<{
    themes: Array<ThemeRecord>
    appId: number
  }> => {
    return request
      .get(generateUrl('api_theme_list_by_company', { companyId: app.companyId }))
      .then(response => {
        return { themes: response.body.result.map(initTheme), appId: app.id }
      })
      .catch(error => {
        throw error
      })
  },
  archiveTheme,
  linkTheme,
  linkThemes,
  saveTheme,
  buildFieldsForTheme,
  ensureClosability,
}
