import styled from 'styled-components'

import { colors, schemes, shadows } from 'components/styled/tokens'

export const ConfirmHeader = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 1fr) auto;
  grid-gap: 5px;

  padding: 16px 8px 16px 20px;

  & > button {
    margin-top: -7px;
    margin-bottom: -7px;
  }
`

export const ConfirmTitle = styled.h3<any>`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${colors.text};

  & > i:first-child {
    font-size: 14px;
  }
`

export const ConfirmContent = styled.div`
  margin: 0 28px 16px 20px;
  line-height: 20px;
  font-weight: 400;
  color: ${colors.text};

  p {
    margin-bottom: 8px;
  }

  code {
    color: #665a32;
    background: rgba(239, 195, 55, 0.12);
  }
`

export const ConfirmFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
`

export const ConfirmWarning = styled.p`
  color: ${colors.textWarning};
  font-weight: 500;
`

export const ConfirmHighlight = styled.span`
  padding: 1px 3px;
  color: ${schemes.grayscale['80']};
  background-color: rgba(172, 177, 185, 0.16);
  border-radius: 3px;
`

export const ConfirmContainer = styled.div`
  width: 420px;
  background-color: ${colors.fill};
  border-radius: 8px;
  box-shadow: ${shadows.overlay};
  padding: 0;

  &.mfa {
    padding: 0;

    ${ConfirmFooter} {
      padding: 8px;
    }

    ${ConfirmTitle} {
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${colors.textWarning};
    }
  }
`
