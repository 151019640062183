/* eslint-disable react/jsx-no-bind */
/*
  @todo rewrite complet, c'est très crappy...
  on mélange un cache local avec le state redux
*/

import Immutable, { type Map, type Set } from 'immutable'
import * as React from 'react'
import { useSelector, useDispatch } from 'com.batch.common/react-redux'

import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { CampaignOption } from 'components/common/option-row-campaign'
import { Icon } from 'components/common/svg-icon'
import { Select } from 'components/form'

import { generateCampaignUrl } from 'com.batch.common/router'
import { getSchedulingType } from 'com.batch.common/utils'

import { CampaignFactory, type CampaignRecord } from 'com.batch.redux/_records'
import { currentAppSelector } from 'com.batch.redux/app'
import { buildCampaignsFromOptions } from 'com.batch.redux/campaign.action'
import api from 'com.batch.redux/campaign.api'
import { currentCampaign, campaignEntitiesSelector } from 'com.batch.redux/campaign.selector'

// on an infinite scale, this will leak.
let alreadyFetchedTokens: Set<string> = Immutable.Set()
type CampaignPickerProps = {
  token: string
  pickCampaign: (token: string) => void
  optionsKind: 'PUSH' | 'LOCAL'
}

const fetchCampaigns = ({
  query,
  appId,
  kind,
  currentToken,
}: {
  query: string
  appId: number
  kind: 'PUSH' | 'LOCAL'
  currentToken: string
}): Promise<{
  type: string
  payload: Map<string, CampaignRecord>
}> => {
  return api
    .findCampaigns({
      appId,
      query,
      kind,
    })
    .then(response => buildCampaignsFromOptions(response.filter(raw => raw.token !== currentToken)))
}

export const CampaignPicker = ({
  token,
  pickCampaign,
  optionsKind,
}: CampaignPickerProps): React.ReactElement | null => {
  const [fetching, setFetching] = React.useState(false)
  const dispatch = useDispatch()
  const app = useSelector(currentAppSelector)
  const campaign = useSelector(currentCampaign)
  const campaigns = useSelector(campaignEntitiesSelector)
  // get CampaignRecord from token & redux state
  const value: CampaignRecord | null = React.useMemo(() => {
    if (!token) return null
    const match = campaigns.get(token)
    return match
      ? match
      : CampaignFactory({ token: token, name: token + ' [no matching campaign found]' })
  }, [campaigns, token])
  // trigger a fetch if the token is not in the redux state
  React.useEffect(() => {
    if (token && !fetching && !alreadyFetchedTokens.has(token) && !campaigns.get(token)) {
      setFetching(true)
      fetchCampaigns({
        query: campaign.token,
        appId: app.id,
        kind: optionsKind,
        currentToken: token,
      })
        .then(action => {
          dispatch(action)
        }, console.log)
        .finally(() => {
          setFetching(false)
          alreadyFetchedTokens = alreadyFetchedTokens.add(token)
        })
    }
  }, [app.id, campaign.token, campaigns, dispatch, fetching, optionsKind, token])

  const loadOptions = React.useCallback(
    (query: string) => {
      return fetchCampaigns({
        query,
        appId: app.id,
        kind: optionsKind,
        currentToken: token,
      }).then(action => {
        dispatch(action)
        return Immutable.List(action.payload.toArray().map(arr => arr[1]))
      })
    },
    [app.id, dispatch, optionsKind, token]
  )
  const campaignSelected = useSelector(currentCampaign)
  const schedulingType = getSchedulingType(campaignSelected)
  return (
    <div className="condi__builder__campaign">
      <FlexLine>
        <FlexLineItem grow={1}>
          {
            <Select
              optionFormatter={CampaignOption}
              optionToString={option => option?.name ?? ''}
              isClearable={false}
              isSearchable
              loadOptions={loadOptions}
              placeholder={`Search a campaign sent in the last ${
                app.ttlRetargeting ?? '14'
              } days by name or token`}
              onChange={opt => {
                if (opt) pickCampaign(opt.token)
              }}
              value={value}
            />
          }
        </FlexLineItem>
        <FlexLineItem width={45}>
          <Button
            as={!value ? 'span' : 'a'}
            disabled={!value}
            target="_blank"
            href={
              value
                ? generateCampaignUrl('data', schedulingType, {
                    companyId: app.companyId,
                    appId: app.id,
                    campaignType: value.type,
                    id: value.token,
                    tab: 'review',
                  })
                : ''
            }
          >
            <Icon icon="view" size={12} style={{ lineHeight: 'normal', verticalAlign: -2 }} />
          </Button>
        </FlexLineItem>
      </FlexLine>
    </div>
  )
}
