/* eslint-disable react/jsx-no-bind */
import Immutable, { type OrderedMap, type List, type Set } from 'immutable'
import * as React from 'react'

import {
  Box,
  BoxHeader,
  HeaderBoxTitle,
  BoxBody,
  BoxFooter,
  HeaderBoxActions,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { FlexLine, FlexLineItem } from 'components/common/flexline'
import { WrapLabel } from 'components/common/form/wrap-label'
import { Grid } from 'components/common/grid'
import { Popin } from 'components/common/popin/popin'
import { type availableIcons, Icon } from 'components/common/svg-icon'
import { Input } from 'components/form/fields/input'
import { Select } from 'components/form/fields/select'
import { TabButton } from 'components/form/fields/tab-button'
import { TabButtonItem } from 'components/form/fields/tab-button-item'
import { LinkDocumentation } from 'components/styled/text'
import { colors } from 'components/styled/tokens'
import {
  FormattingBtn,
  FormattingToolbar,
  Placeholder,
} from 'components/template/template-formating'

import Types from 'com.batch.common/legacy-query-types'

import { type AttributeRecord, AttributeFactory } from 'com.batch.redux/_records'

type TemplateWizardProps = {
  opened: boolean
  appendText: (text: string) => void
  attributes: OrderedMap<string, AttributeRecord>
  contextEventId: string
  close: () => void
}
type AttributeOption = {
  readonly label: string
  readonly value: string
  readonly type: string
}
type DataType = 'userData' | 'triggerData'

const Formatters = {
  TAG: {
    id: 'tags',
    optional: false,
    items: [
      {
        ex: '[a,b,c] → a,b,c',
        label: 'Join',
        id: "join(', ')",
      },
      {
        ex: '[a,b,c] → a',
        label: 'First',
        id: 'first()',
      },
      {
        ex: '[a,b,c] → c',
        label: 'Last',
        id: 'last()',
      },
      {
        ex: '[a,b,c] → 3',
        label: 'Count',
        id: 'count()',
      },
    ],
  },
  DATE: {
    id: 'date',
    optional: true,
    items: [
      {
        ex: '2018-12-31',
        label: 'YYYY-MM-DD',
        id: "formatDate(pattern: 'yyyy-MM-dd')",
      },
      {
        ex: '31/12/2018',
        label: 'DD/MM/YYYY',
        id: "formatDate(pattern: 'dd/MM/yyyy')",
      },
      {
        ex: '23h59',
        label: 'HH[H]MM',
        id: "formatDate(pattern: 'HH''h''mm')",
      },
      {
        ex: '11h59',
        label: 'H[H]MM',
        id: "formatDate(pattern: 'h''h''mm')",
      },
      {
        ex: '11h59 AM',
        label: 'H[H]MM A',
        id: "formatDate(pattern: 'h''h''mm a')",
      },
    ],
  },
  FLOAT: {
    id: 'all',
    optional: true,
    items: [
      {
        label: 'Abs',
        ex: '-148 → 148',
        id: 'abs',
      },
      {
        label: 'Rounded',
        ex: '46,8 → 47',
        id: 'round',
      },
      {
        label: 'Ceil',
        ex: '46,2 → 47',
        id: 'ceil',
      },
      {
        label: 'Floor',
        ex: '46,8 → 47',
        id: 'floor',
      },
    ],
  },
  INTEGER: {
    id: 'all',
    optional: true,
    items: [
      {
        label: 'Abs',
        ex: '-148 → 148',
        id: 'abs',
      },
    ],
  },
  STRING: {
    id: 'case',
    optional: true,
    items: [
      {
        label: 'Capitalize words',
        ex: 'john doe → John Doe',
        id: 'title',
      },
      {
        label: 'Capitalize',
        ex: 'john doe → John doe',
        id: 'capitalize',
      },
      {
        label: 'Uppercase',
        ex: 'john doe → JOHN DOE',
        id: 'upper',
      },
      {
        label: 'Lowercase',
        ex: 'John DOE → john doe',
        id: 'lower',
      },
    ],
  },
  BOOLEAN: {
    id: 'boolean',
    optional: true,
    items: [],
  },
  URL: {
    id: 'url',
    optional: true,
    items: [],
  },
}

const typeStyleMapping = new Map<string, availableIcons>([
  ['__LABEL__', 'label'],
  ['__TAG__', 'one-of'],
  ['TAG', 'one-of'],
  ['STRING', 'text'],
  ['BOOLEAN', 'boolean'],
  ['DATE', 'calendar'],
  ['INTEGER', 'integer'],
  ['FLOAT', 'number'],
  ['URL', 'link'],
])

// FIXME: Types: 'LONG', 'BOOL','DOUBLE', should not be present in this list.
//  This is a temporary fix to display attributes in personalization modal.
//  Currently we receive different enum types according to the API.
//  This should be removed when data will be harmonized.
//  Slack: https://batchers.slack.com/archives/C0293AP6D/p1694684679743669
const handledTypes = [
  'STRING',
  'DATE',
  'INTEGER',
  'FLOAT',
  'BOOLEAN',
  'TAG',
  'URL',
  'LONG',
  'BOOL',
  'DOUBLE',
]

const regexTriggerEvent = /triggerEventAttr\('([0-9a-zA-Z_.]{1,50})'\)/

const TemplateWizardRaw = ({
  opened,
  appendText,
  attributes,
  contextEventId,
  close,
}: TemplateWizardProps): React.ReactElement | null => {
  const [attrId, setAttrId] = React.useState<string>('')
  const [defaultValue, setDefaultValue] = React.useState<string>('')
  const [formatters, setFormatters] = React.useState<Set<string>>(Immutable.Set())
  const [dataType, setDataType] = React.useState<DataType>('userData')

  const submit = React.useCallback((): void => {
    const filters = formatters.size > 0 ? `|${formatters.join('|')}` : ''

    let attributeIsTypeURL: boolean = false
    if (dataType === 'userData') {
      const userDataSelected = attributes.find(attr => attr.get('id') === attrId)
      attributeIsTypeURL = userDataSelected?.get('type') === Types.URL
    } else {
      const regexResult = regexTriggerEvent.exec(attrId)
      if (regexResult) {
        const triggerDataSelected = attributes
          .get(contextEventId, AttributeFactory())
          .allowedKeys.find(d => d.name === regexResult[1])

        attributeIsTypeURL = triggerDataSelected?.type === Types.URL
      }
    }

    const defaultValueTmp = defaultValue
      ? !isNaN(parseInt(defaultValue)) || !isNaN(parseFloat(defaultValue))
        ? defaultValue
        : `'${defaultValue.replace("'", "''")}'`
      : false

    appendText(
      `{{ ${attrId}${filters}${
        defaultValueTmp ? `|default(${defaultValueTmp})${attributeIsTypeURL ? ' | url' : ''}` : ''
      } }}`
    )
    close()
  }, [appendText, attrId, attributes, close, contextEventId, dataType, defaultValue, formatters])

  const toggleFormatter = React.useMemo(
    () =>
      (
        formatterId: string,
        allItemsInGroup: Array<{
          id: string
          label: string
        }>
      ): void => {
        let formattersTmp = formatters
        if (formatters.has(formatterId)) {
          formattersTmp = formattersTmp.remove(formatterId)
        } else {
          formattersTmp = formattersTmp.add(formatterId)
          allItemsInGroup
            .filter(item => item.id !== formatterId)
            .forEach(item => {
              formattersTmp = formattersTmp.remove(item.id)
            })
        }
        setFormatters(formattersTmp)
      },
    [formatters]
  )

  const getFormattersKey = React.useMemo(
    () =>
      (optionValue: string): string => {
        if (optionValue === 'triggerEventLabel()') {
          return 'STRING'
        }
        if (optionValue === 'triggerEventTags()') {
          return 'TAG'
        }
        const regexResult = regexTriggerEvent.exec(optionValue)
        if (regexResult !== null) {
          const data = attributes
            .get(contextEventId, AttributeFactory())
            .allowedKeys.find(d => d.name === regexResult[1])
          if (data && data.type !== '__LABEL__' && data.type !== '__TAG__') {
            return data.type
          }
        }

        const type = attributes.get(optionValue, AttributeFactory()).type

        return handledTypes.includes(type) ? type : 'STRING'
      },
    [attributes, contextEventId]
  )

  const options: List<AttributeOption> = React.useMemo(() => {
    const eventAttributes = attributes.get(contextEventId, AttributeFactory())

    setAttrId('')
    const optionsTmp: Array<{
      readonly label: string
      readonly type: string
      readonly value: string
    }> = []
    if (dataType === 'triggerData') {
      eventAttributes.allowedKeys.forEach(key => {
        optionsTmp.push({
          label:
            key.type === '__LABEL__' ? 'Event label' : key.type === '__TAG__' ? 'Tags' : key.name,
          value:
            key.type === '__LABEL__'
              ? 'triggerEventLabel()'
              : key.type === '__TAG__'
                ? 'triggerEventTags()'
                : `triggerEventAttr('${key.name}')`,
          type: key.type,
        })
      })
    } else {
      attributes
        .filter(attr => handledTypes.indexOf(attr.get('lastReceivedType')) !== -1)
        .forEach(attr => {
          optionsTmp.push({
            label: attr.get('label', attr.get('name') ?? ''),
            value: attr.get('id'),
            type: attr.get('type'),
          })
        })
    }

    optionsTmp.sort((a, b) => {
      if (
        !['__LABEL__', '__TAG__'].includes(a.type) &&
        !['__LABEL__', '__TAG__'].includes(b.type)
      ) {
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
      }
      if (a.type === '__LABEL__') {
        return -1
      }
      if (b.type === '__LABEL__') {
        return 1
      }
      if (a.type === '__TAG__') {
        return -1
      }
      return 1
    })

    return Immutable.List(optionsTmp)
  }, [contextEventId, attributes, dataType])

  return (
    <Popin opened={opened} close={close} style={{ width: '720px' }}>
      <Box>
        <BoxHeader>
          <HeaderBoxTitle title="Add Dynamic Content" />
          <HeaderBoxActions>
            <span>
              <Button onClick={close} kind="inline" intent="neutral">
                <Icon icon="close" />
              </Button>
            </span>
          </HeaderBoxActions>
        </BoxHeader>
        <BoxBody $padding>
          <WrapLabel label="Dynamic data">
            <FlexLine>
              {contextEventId && (
                <FlexLineItem>
                  <TabButton>
                    <TabButtonItem
                      isActive={dataType === 'userData'}
                      onClick={() => setDataType('userData')}
                    >
                      User data
                    </TabButtonItem>
                    <TabButtonItem
                      isActive={dataType === 'triggerData'}
                      onClick={() => setDataType('triggerData')}
                    >
                      Trigger data
                    </TabButtonItem>
                  </TabButton>
                </FlexLineItem>
              )}
              <FlexLineItem grow={1}>
                <Select
                  isSearchable
                  optionToString={opt => opt?.label ?? ''}
                  options={options}
                  value={attrId ? options.find(o => o.value === attrId) : null}
                  isDisabled={options.count() < 1}
                  placeholder={
                    dataType === 'userData'
                      ? 'Pick a user attribute'
                      : options.count() > 0
                        ? 'Pick a trigger event attribute (' +
                          attributes.get(contextEventId, AttributeFactory()).label +
                          ')'
                        : 'No event data available'
                  }
                  onChange={o => {
                    if (typeof o === 'object' && o !== null && typeof o.value === 'string') {
                      setAttrId(o.value)
                      setFormatters(
                        getFormattersKey(attrId) === getFormattersKey(o.value)
                          ? formatters
                          : Immutable.Set()
                      )
                      if (getFormattersKey(o.value) === 'TAG') {
                        toggleFormatter(Formatters.TAG.items[0].id, Formatters.TAG.items)
                      }
                    }
                  }}
                  optionFormatter={(o, { context }) => {
                    if (typeof o === 'object' && o !== null && typeof o.value === 'string') {
                      return (
                        <Grid template="20px 1fr">
                          <Icon
                            style={{ marginLeft: context === 'value' ? 2 : 0 }}
                            color={colors.textLight}
                            icon={typeStyleMapping.get(o.type) ?? 'default'}
                          />
                          {o.label}
                        </Grid>
                      )
                    }
                    return null
                  }}
                />
              </FlexLineItem>
            </FlexLine>
          </WrapLabel>
          <WrapLabel label="Type a default value">
            <Input
              type="text"
              value={defaultValue}
              placeholder="Default value (shown if we don't have a value for the installation)"
              onChange={evt => setDefaultValue(evt.target.value)}
            />
          </WrapLabel>
          <WrapLabel
            style={{ marginBottom: 0 }}
            label={
              Formatters[getFormattersKey(attrId)] && Formatters[getFormattersKey(attrId)].optional
                ? 'Formatting (optional)'
                : 'Formatting'
            }
          >
            {attrId ? (
              <div>
                {Formatters[getFormattersKey(attrId)] &&
                Formatters[getFormattersKey(attrId)].items.length > 0 ? (
                  <React.Fragment key={Formatters[getFormattersKey(attrId)].id}>
                    <FormattingToolbar key={Formatters[getFormattersKey(attrId)].id}>
                      {Formatters[getFormattersKey(attrId)].items.map(formatter => (
                        <FormattingBtn
                          key={formatter.id}
                          active={formatters.has(formatter.id)}
                          onClick={() =>
                            toggleFormatter(
                              formatter.id,
                              Formatters[getFormattersKey(attrId)].items
                            )
                          }
                        >
                          <span>{formatter.label}</span>
                          <span>{formatter.ex}</span>
                        </FormattingBtn>
                      ))}
                    </FormattingToolbar>
                    <p style={{ paddingTop: '10px' }}>
                      <LinkDocumentation
                        intent="action"
                        href="https://doc.batch.com/guides/message-personalization/advanced#filters"
                        target="_blank"
                        style={{ fontWeight: '500' }}
                      >
                        Learn about more formatting options
                      </LinkDocumentation>
                    </p>
                  </React.Fragment>
                ) : (
                  Formatters[getFormattersKey(attrId)] && (
                    <Placeholder
                      height={76}
                      padding={16}
                      key={Formatters[getFormattersKey(attrId)].id}
                    >
                      <FlexLine vertical>
                        <FlexLineItem style={{ marginBottom: '8px' }}>
                          No {Formatters[getFormattersKey(attrId)].id} quick formatting options
                        </FlexLineItem>
                        <FlexLineItem>
                          <LinkDocumentation
                            intent="action"
                            href="https://doc.batch.com/guides/message-personalization/advanced#filters"
                            target="_blank"
                            style={{ fontWeight: 'normal' }}
                          >
                            View more formatting options
                          </LinkDocumentation>
                        </FlexLineItem>
                      </FlexLine>
                    </Placeholder>
                  )
                )}
              </div>
            ) : (
              <Placeholder height={76} padding={30}>
                Select a dynamic data to access formatting options
              </Placeholder>
            )}
          </WrapLabel>
        </BoxBody>
        <BoxFooter isEditable>
          <Button kind="inline" intent="neutral" onClick={close}>
            Cancel
          </Button>

          <Button
            kind="primary"
            intent="action"
            onClick={() => {
              setAttrId('')
              setFormatters(formatters.clear())
              submit()
            }}
            disabled={
              !attrId ||
              (Formatters[getFormattersKey(attrId)] &&
                !Formatters[getFormattersKey(attrId)].optional &&
                formatters.isEmpty())
            }
          >
            Insert code
          </Button>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
export const TemplateWizard: React.ComponentType<TemplateWizardProps> =
  React.memo(TemplateWizardRaw)
