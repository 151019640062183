import clamp from 'lodash/clamp'

/**
 * @deprecated
 */
export const stroke: {
  lighter: string
} = {
  lighter: 'rgba(233,233,233,1)',
}

export const good: {
  shade: {
    s1: string
    s2: string
    s4: string
  }
  translucent: {
    t1: string
    t2: string
  }
} = {
  shade: {
    s1: 'rgba(98,187,98,1)',
    s2: 'rgba(77,178,77,1)',
    s4: 'rgba(58,134,58,1)',
  },
  translucent: {
    t1: 'rgba(148,209,148,0.06)',
    t2: 'rgba(148,209,148,0.12)',
  },
}

/**
 * @deprecated
 */
export const ParseColor = (color: string): number[] => {
  const value = color
    .replace(/[\(\) (rgb(a)?)]/g, '')
    .split(',')
    .map(parseFloat)

  if (value.length !== 4) return [0, 0, 0, 1]
  else return value
}
/**
 * @deprecated
 */
export const PrintColor = (color: number[]): string =>
  `rgba(${color[0] || 0}, ${color[1] || 0}, ${color[2] || 0}, ${color[3] || 1})`
/**
 * @deprecated
 */
export const Opacity = (color: string, opacity: number = 1): string => {
  let value = ParseColor(color)
  value[3] = value[3] * opacity

  return PrintColor(value)
}
/**
 * @deprecated
 */
export const Tint = (color: string, amount: number): string =>
  PrintColor(ParseColor(color).map(i => clamp(i - amount, 0, 255)))
