import Immutable, { type RecordOf, type List, type Map, type Set, type OrderedSet } from 'immutable' // eslint-disable-line

import { type AttributeRecord, AttributeFactory } from 'com.batch.redux/_records'

type QbType =
  | 'AGE'
  | 'BOOLEAN'
  | 'DATE'
  | 'EVENT'
  | 'FLOAT'
  | 'INTEGER'
  | 'POSITION'
  | 'RETARGETING'
  | 'SET'
  | 'STRING'
  | 'TAG'
  | 'VERSION'

type QbFuncVariantProps = {
  regex: RegExp
  api: string
  args: OrderedSet<string>
}

export const QbFuncVariantFactory = Immutable.Record<QbFuncVariantProps>({
  regex: /^[A-Z_]+$/,
  api: '',
  args: Immutable.OrderedSet(),
} as QbFuncVariantProps)

export type QbFuncVariantRecord = RecordOf<QbFuncVariantProps>

type QbFuncProps = {
  label: string
  value: string
  returns: QbType
  for: Set<QbType>
  variants: List<QbFuncVariantRecord>
}

export const QbFuncFactory = Immutable.Record<QbFuncProps>({
  label: '',
  value: '',
  returns: 'STRING',
  for: Immutable.Set(),
  variants: Immutable.List(),
} as QbFuncProps)

export type QbFuncRecord = RecordOf<QbFuncProps>

type QbOperatorProps = {
  value: string
  api: string
  for: Set<QbType>
}
export const QbOperatorFactory = Immutable.Record<QbOperatorProps>({
  value: '',
  api: '',
  for: Immutable.Set(),
} as QbOperatorProps)

export type QbOperatorRecord = RecordOf<QbOperatorProps>

type QbConditionProps = {
  operator: QbOperatorRecord
  func: QbFuncRecord | null | undefined
  args: Map<string, string | number>
  attribute: AttributeRecord
  valid: boolean
  negate: boolean
  value: any
  isLogical: false
}
export const QbConditionFactory = Immutable.Record<QbConditionProps>({
  operator: QbOperatorFactory(),
  func: null,
  args: Immutable.Map(),
  attribute: AttributeFactory(),
  valid: false,
  negate: false,
  value: null,
  isLogical: false,
} as QbConditionProps)

export type QbConditionRecord = RecordOf<QbConditionProps>

type QbLogicalProps = {
  children: List<QbConditionRecord | QbLogicalRecord>
  kind: 'and' | 'or'
  isLogical: true
}

export const QbLogicalFactory = Immutable.Record<QbLogicalProps>({
  children: Immutable.List(),
  kind: 'and',
  isLogical: true,
} as QbLogicalProps)

export type QbLogicalRecord = RecordOf<QbLogicalProps>
