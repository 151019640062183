import Immutable from 'immutable'

import { type FetchSubDistributionResponse } from 'com.batch/profilebase/infra/ucp-data-service.interface'
import {
  type SubDistributionRecord,
  SubDistributionFactory,
} from 'com.batch/profilebase/models/sub-distribution.records'

export const parseEmailSubDistributionResponse = (
  distribution: FetchSubDistributionResponse
): SubDistributionRecord => {
  const subscribed = parseInt(distribution.subscribed, 10) || 0
  const unsubscribed = parseInt(distribution.unsubscribed, 10) || 0
  const total = parseInt(distribution.total, 10) || 0
  const unknown = total - unsubscribed - subscribed
  const subscribedRate = Math.floor((subscribed / total) * 100)
  const unsubscribedRate = Math.floor((unsubscribed / total) * 100)
  const unknownRate = 100 - subscribedRate - unsubscribedRate
  return SubDistributionFactory({
    subscribed: Immutable.Record({
      value: subscribed,
      rate: subscribedRate,
    })(),
    unsubscribed: Immutable.Record({
      value: unsubscribed,
      rate: unsubscribedRate,
    })(),
    unknown: Immutable.Record({
      value: unknown,
      rate: unknownRate,
    })(),
  })
}
