import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { type DynamicDimension } from './fetch-dynamic-stats.helper'
import { currentProjectSelector } from 'com.batch.redux/project.selector'
import { type List } from 'immutable'

export const fetchDimensionValues = ({
  dimension,
  channel,
}: {
  dimension: DynamicDimension
  channel: ChannelUntilCleanup
}): DispatchExtraBoundFn<Promise<unknown>> => {
  return async (dispatch, getState, { dataService }) => {
    const state = getState()
    const project = currentProjectSelector(state)
    return dataService
      .fetchDimensionValues({
        project,
        dimension,
        channel,
      })
      .then(values => {
        dispatch({
          type: 'FETCH_DIMENSION_VALUES_SUCCESS',
          payload: {
            values,
            dimension,
            projectKey: project.projectKey,
          },
        })
      })
      .catch(() => {
        dispatch({
          type: 'FETCH_DIMENSION_VALUES_FAILURE',
          payload: {
            dimension,
          },
        })
      })
  }
}

type FetchDimensionValuesSuccess = {
  type: 'FETCH_DIMENSION_VALUES_SUCCESS'
  payload: {
    values: List<string>
    dimension: DynamicDimension
    projectKey: string
  }
}
type FetchDimensionValuesFailure = {
  type: 'FETCH_DIMENSION_VALUES_FAILURE'
  payload: {
    dimension: DynamicDimension
  }
}
export type FetchDimensionValuesActions = FetchDimensionValuesSuccess | FetchDimensionValuesFailure
