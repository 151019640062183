import * as React from 'react'

import { Grid } from 'components/common/grid'
import { Icon, type availableIcons } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { CapsLabel } from 'components/styled/text'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { ucFirst } from 'com.batch.common/utils'

import {
  CertifSettingsInfo,
  CertifExpirRelative,
  CertifExpirDate,
  CertifExpirStatus,
  CertifSettingsExtra,
} from './settings.styles'

import { type P12ConfigRecord } from 'com.batch.redux/_records'

type SettingsReviewP12Props = {
  config: P12ConfigRecord
}

const ExpirationStatusColor: ['success', 'error', 'warning'] = ['success', 'error', 'warning']
const ExpirationStatusIcon: availableIcons[] = ['success', 'error', 'danger']
const ExpirationStatusMessages = [
  'Your certificate is up to date',
  'This certificate is no longer valid',
  'Pay attention: this certificate is expiring soon!',
]
const ExpirationIsExpired = [false, true, false]
const now = dayjs()

export const SettingsReviewP12: React.ComponentType<SettingsReviewP12Props> = React.memo(
  ({ config }: SettingsReviewP12Props) => {
    let status: 0 | 1 | 2 = 0

    if (config.expireAt && config.expireAt.isBefore(now)) {
      status = 1
    } else if (config.expireAt && now.isAfter(config.expireAt.subtract(1, 'month'))) {
      status = 2
    }

    return (
      <Grid template="55% 1fr" alignItems="stretch">
        <div style={{ borderRight: '1px solid #eff0f4' }}>
          <CertifSettingsInfo>
            <CapsLabel>Name</CapsLabel>
            <span>{config.certificateName}</span>
          </CertifSettingsInfo>

          <CertifSettingsInfo>
            <CapsLabel>{ExpirationIsExpired[status] ? 'Expired' : 'Expires in'}</CapsLabel>
            {config.expireAt && (
              <div>
                <CertifExpirRelative>
                  {ucFirst(
                    dayjs
                      .duration(config.expireAt.valueOf() - now.valueOf())
                      .humanize(ExpirationIsExpired[status])
                  )}
                </CertifExpirRelative>

                <CertifExpirDate intent={ExpirationStatusColor[status]}>
                  {ExpirationIsExpired[status] ? 'Expired on ' : 'Valid until '}
                  {config.expireAt ? config.expireAt.format('DD/MM/YYYY') : ''}
                </CertifExpirDate>

                <CertifExpirStatus intent={ExpirationStatusColor[status]}>
                  <Tooltip tooltip={ExpirationStatusMessages[status]}>
                    <Icon
                      icon={ExpirationStatusIcon[status]}
                      style={{ padding: 4, marginTop: -4 }}
                    />
                  </Tooltip>
                </CertifExpirStatus>
              </div>
            )}
          </CertifSettingsInfo>

          <CertifSettingsInfo>
            <CapsLabel>Format</CapsLabel>
            <span>Universal .p12 APN Certificate</span>
          </CertifSettingsInfo>

          <CertifSettingsInfo noBorder>
            <CapsLabel>Uploaded on</CapsLabel>
            <span>{config.uploadedOn.format('DD/MM/YYYY')}</span>
          </CertifSettingsInfo>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', padding: 40, height: 382 }}>
          <CertifSettingsExtra empty>
            <Icon icon="box-valid" size={34} thickness={2} style={{ margin: '0 0 8px 0' }} />
            <p>No additional information required for this certificate.</p>
          </CertifSettingsExtra>
        </div>
      </Grid>
    )
  }
)
