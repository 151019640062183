import styled, { css } from 'styled-components'

import { IconContainer } from 'components/common/svg-icon'

import { MetricContainer, MetricLabel } from './metric'

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  overflow: hidden;
  background-color: #fcfcfd;

  @media (max-width: 1040px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`
type TabProps = {
  color?: string
  active?: boolean
}
export const Tab = styled.div<TabProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex: 1;
  z-index: ${props => (props.active ? 20 : 10)};
  ${props =>
    props.theme.isEmpty &&
    css`
      z-index: 0;
    `}
  padding: 28px;
  background-color: ${props => (props.active ? '#ffffff' : '#fbfbfb')};
  border-top: 1px solid #f2f2f8;
  border-bottom: ${props => (props.active ? '1px solid #f2f2f8' : 'none')};
  border-bottom: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.03);
  cursor: pointer !important;

  &:hover {
    background-color: ${props => (props.active ? '#ffffff' : '#f8f8f8')};
  }

  ${IconContainer} {
    display: block;
    margin: 4px 0 24px 0;
    font-size: 24px;
    color: ${props => props.color};
  }

  ${MetricContainer}, ${MetricLabel} {
    cursor: pointer;
  }

  @media (max-width: 1040px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    padding: 24px 25px 20px 25px;
    border-bottom: none;
    border-left: 1px solid #f2f2f8;

    border-top: none;

    ${IconContainer} {
      margin: -2px 24px 0 0;
    }

    ${MetricContainer} {
      flex: 1;
    }

    &:first-child {
      border-left: none;
    }
  }
`

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 200px;
  width: 200px;
  position: relative;
  overflow: hidden;
  border-right: 1px solid #f2f3f8;

  ${Tab}:first-child {
    border-top: none;
  }

  &:after {
    display: block;
    position: absolute;
    z-index: 15;
    top: 0;
    right: 0;
    width: calc(100% + 20px);
    height: 100%;
    content: '';
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.04);
    pointer-events: none;

    @media (max-width: 1040px) {
      box-shadow: none;
    }
  }

  @media (max-width: 1250px) {
    width: 160px;
    flex: 0 0 160px;
  }
  @media (max-width: 1040px) {
    flex-direction: row;
    flex: 0 0 100%;
    width: 100%;
    border-bottom: 1px solid #f2f3f8;
    border-right: none;
  }
`
type ContentDetailProps = {
  color: string
  empty?: boolean
  index: number
}
export const ContentDetail = styled.div<ContentDetailProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 22px 14px 22px 18px;
  flex: 1;
  width: 50%;
  background-color: #ffffff;
  border-right: 1px solid #f2f3f8;

  ${props =>
    props.index <= 1 &&
    css`
      border-bottom: 1px solid #f2f3f8;
    `}
  ${MetricContainer} {
    flex: 1;
  }

  strong:after {
    display: ${props => (props.empty ? 'none' : 'inline-block')};
    margin: 0 0 0 8px;
    content: '●';
    color: ${props => props.color};
    font-size: 12px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  background-color: #ffffff;
  border-right: 1px solid #f2f3f8;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.02);

  ${ContentDetail} {
    position: relative;
    z-index: 10;

    &:nth-child(2n) {
      border-right: none;
    }

    &:nth-child(5),
    &:nth-child(6) {
      border-bottom: none;
    }
  }

  @media (max-width: 1040px) {
    width: 100%;
    flex: 0 0 100%;
    border-right: none;
    border-bottom: 1px solid #f2f3f8;
  }
`
export const ContentHeader = styled.div`
  padding: 26px 23px 24px 23px;
  flex-grow: 0;
  border-bottom: 1px solid #f2f3f8;

  h4 {
    margin: 0 0 6px 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
  }

  p {
    color: #777f8a;
    line-height: 1.4;
  }

  @media (max-width: 1250px) {
    padding: 24px 23px 20px 23px;
  }
  @media (max-width: 1040px) {
    border-bottom: none;
    padding: 28px 22px 10px 22px;
  }
`
export const ContentBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  ${ContentDetail} {
    width: 50%;
    flex: 0 0 50%;

    @media (max-width: 1040px) {
      flex: 1;
      border-right: none;
      border-bottom: none;
    }
    @media (max-width: 1000px) and (min-width: 980px) {
      width: 50%;
      flex: 0 0 50%;
    }
    @media (max-width: 700px) {
      width: 50%;
      flex: 0 0 50%;
    }
  }
`

export const TooltipDetail = styled.div`
  display: block;
  min-width: 160px;
  padding: 10px 12px 11px 12px;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);

  &:last-child {
    border-bottom: none;
  }

  strong {
    display: block;
    margin: 0;
    font-size: 18px;
    font-weight: 200;
    color: #fefefe;
  }

  label {
    display: block;
    margin: 2px 0 0 0;
    font-size: 13px;
    color: #bdc1c6;
    overflow: hidden;
  }

  a {
    display: block;
    color: #808690;
    font-size: 14px;
    padding: 2px 0 5px 0;

    &:hover {
      color: #ffffff;
    }

    ${IconContainer} {
      vertical-align: middle;
      margin: 0 0 0 8px;
    }
  }
`

export const Chart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 400px;
  max-width: 400px;
  min-height: 320px;
  padding: 38px 56px;
  position: relative;

  ${MetricContainer} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  @media (max-width: 1340px) {
    flex: 0 0 36%;
    width: 36%;
    padding: 18px 14px;
  }
  @media (max-width: 1200px) {
    flex: 0 0 28%;
    width: 28%;
  }
  @media (max-width: 1040px) {
    flex: 0 0 100%;
    width: 100%;
    padding: 10px;
    margin: 0 auto;
  }
`
type TooltipMetricProps = {
  color: string
}
export const TooltipMetric = styled.div<TooltipMetricProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 12px 12px 12px;

  label,
  strong {
    display: block;
    line-height: 1;
    margin: 0;
  }

  label {
    margin: 0 0 1px 0;
    font-size: 11px;
    font-weight: 500;
    color: #d0d2d6;
    text-transform: uppercase;
    letter-spacing: 0.6px;

    &:before {
      display: inline-block;
      margin: 0 8px 0 0;
      transform: translate3d(0, 1px, 0);
      content: '●';
      font-size: 14px;
      color: ${props => props.color};
    }
  }

  strong {
    margin: 0 0 0 36px;
    font-size: 15px;
    font-weight: 500;
  }
`

export const TooltipDescription = styled.p`
  display: block;
  padding: 10px 12px 12px 12px;
  font-size: 12px;
  color: #d0d2d6;
  line-height: 1.4;
  text-align: left;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
`
