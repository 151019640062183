import { type Set } from 'immutable'
import * as React from 'react'

import {
  InvitationTag,
  UserEmailInline,
  UserIdentifierInline,
  UserNameInline,
} from 'components/account/team/team.styles'
import { UserAvatar } from 'components/account/team/user-avatar'
import {
  getPermissionsForUserAndCompany,
  permissionNameFormatter,
  userCanAdministrate,
  ROLES,
} from 'components/account/team/utils'
import { Grid } from 'components/common/grid'
import { TableRow, TableCell } from 'components/common/table'
import { Tag } from 'components/common/tag'
import { Tooltip } from 'components/common/tooltip'
import { Checkbox } from 'components/form'

import { ucFirst } from 'com.batch.common/utils'

import { type CompanyRecord } from 'com.batch.redux/_records'
import { type UserRecord } from 'com.batch.redux/user.records'

export const UserRow = ({
  user,
  company,
  allowed,
  setAllowed,
}: {
  user: UserRecord
  company: CompanyRecord
  allowed: Set<number>
  setAllowed: (arg1: Set<number>) => any
}): React.ReactElement => {
  const userPerm = getPermissionsForUserAndCompany(user, company)

  const handleOnChange = React.useCallback(() => {
    setAllowed(
      allowed.has(Number(user.id)) ? allowed.remove(Number(user.id)) : allowed.add(Number(user.id))
    )
  }, [allowed, setAllowed, user.id])

  return (
    <TableRow key={user.id}>
      <TableCell kind="checkbox">
        {userCanAdministrate(user, company) ? (
          <Tooltip
            tooltip="Users with administrate permissions can access all apps"
            placement="top"
          >
            <div>
              <Checkbox disabled checked onChange={console.log} />
            </div>
          </Tooltip>
        ) : (
          <Checkbox checked={allowed.has(Number(user.id))} onChange={handleOnChange} />
        )}
      </TableCell>
      <TableCell align="left">
        <Grid template="21px minMax(0,1fr)">
          <UserAvatar user={user} size={24} style={{ marginLeft: -3 }} />
          <UserIdentifierInline>
            {user.isInvite ? (
              <React.Fragment>
                <Tooltip placement="right" tooltip="The account is awaiting confirmation.">
                  <InvitationTag>Invite</InvitationTag>
                </Tooltip>
                <UserEmailInline
                  title={user.email}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {user.email}
                </UserEmailInline>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <UserNameInline
                  title={`${ucFirst(user.firstName)} ${ucFirst(user.lastName)}`}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {ucFirst(user.firstName)} {ucFirst(user.lastName)}
                </UserNameInline>
                <UserEmailInline
                  title={user.email}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {user.email}
                </UserEmailInline>
              </React.Fragment>
            )}
          </UserIdentifierInline>
        </Grid>
      </TableCell>
      <TableCell align="left">
        {ROLES.filter(
          r =>
            userPerm.has(r.id) &&
            !r.autoGrantedWith.reduce((acc, current) => acc || userPerm.has(current), false)
        ).map((p, k) => (
          <Tag type="label" key={k}>
            {permissionNameFormatter(p.id)}
          </Tag>
        ))}
      </TableCell>
    </TableRow>
  )
}
