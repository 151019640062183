import * as React from 'react'
import styled from 'styled-components'

import { Grid } from 'components/common/grid'
import { Ellipsis } from 'components/styled/text'
import { colors } from 'components/styled/tokens'

import { type ThemeRecord } from 'com.batch.redux/theme.records'

const OperatorSympbol = styled.div`
  background: ${colors.fillContrast};
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  font-weight: 500;
  height: 16px;
  line-height: 12px;
  padding: 2px 2px;
  text-align: center;
  text-transform: uppercase;
`
const labelForKind = {
  universal: 'Fullscreen',
  banner: 'Banner',
  modal: 'Modal',
  image: 'Image',
  webview: 'Webview',
}

export const OptionThemeRenderer = (
  option: ThemeRecord,
  {
    context,
  }: {
    context: 'menu' | 'value'
  }
): React.ReactNode => {
  return context === 'value' ? (
    option.name
  ) : (
    <Grid template={'1fr 86px'}>
      <Ellipsis>{option.name}</Ellipsis>
      <OperatorSympbol>
        {option.payloadVars.kind ? labelForKind[option.payloadVars.kind] : 'Fullscreen'}
      </OperatorSympbol>
    </Grid>
  )
}
