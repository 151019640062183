// @ts-nocheck

import * as Immutable from 'immutable'
import { find } from 'lodash-es'
import request from 'superagent-interface-promise'

import { config as baseConfig } from 'com.batch.common/config'
import { dayjs } from 'com.batch.common/dayjs.custom'
import { get, getSchedulingType } from 'com.batch.common/utils'

import { parseTriggerConfig } from './campaign.api.trigger'

import {
  AbTestedThemeCodeFactory,
  CampaignABFactory,
  CampaignFactory,
  CustomAudienceFactory,
  TriggerConfigFactory,
} from 'com.batch.redux/_records'
import { matchAudienceType } from 'com.batch.redux/audience.api'
import { normalizeCta } from 'com.batch.redux/content.api'
import {
  AbTestedInAppFactory,
  AbTestedPushFactory,
  ContentStateFactory,
  InAppContentFactory,
  PushContentLegacyFactory,
  PushSettingsFactory,
  StringValueFactory,
} from 'com.batch.redux/content.records'
import { initialState } from 'com.batch.redux/targeting'

const CONFIG = baseConfig.campaign

const extractFormData = (rawCampaign: unknown) => {
  const translations = ['default']
  let inapp = Immutable.Map()

  if (Array.isArray(rawCampaign.landings)) {
    rawCampaign.landings.forEach(tr => {
      if (translations.indexOf(tr.language.id) === -1) {
        translations.push(tr.language.id)
      }
      if (!inapp.has(tr.language.id)) {
        inapp = inapp.set(tr.language.id, AbTestedInAppFactory())
      }

      const inAppContent = InAppContentFactory({
        title: tr.title || '',
        trackingId: tr.trackingId || '',
        text: (tr.bodyHtml ? tr.bodyHtml : tr.body) || '',
        header: tr.header || '',
        imageAlt: tr.imageDescription || '',
        imageUrl: tr.imageUrl || '',
        imageIsGif: !!tr.imageUrl && tr.imageUrl.indexOf('.gif') !== -1,
        mainButtonLabel: tr.cta1Label || '',
        secondaryButtonLabel: tr.cta2Label || '',
        webviewUrl: tr.webviewUrl || '',
        linkOpenTarget: tr.linkOpenTarget === 'in-app' ? 'in-app' : 'browser',
        mainButtonAction: normalizeCta(tr.cta1Action, tr.cta1Args),
        secondaryButtonAction: normalizeCta(tr.cta2Action, tr.cta2Args),
        globalTapAction: normalizeCta(tr.globalAction, tr.globalArgs),
      })

      const variantId = tr.variantId === 2 ? 'b' : 'a'
      inapp = inapp.set(tr.language.id, inapp.get(tr.language.id).set(variantId, inAppContent))
    })
  }

  let pushSettings = PushSettingsFactory({
    payload: get(rawCampaign, 'customPayload', '') || '',
    priority: get(rawCampaign, 'transportInformation.priority', 'HIGH'),
    hasExpiration: !!get(rawCampaign, 'transportInformation.timeToLive', false),
    expiration: !get(rawCampaign, 'transportInformation.timeToLive', false)
      ? 24
      : get(rawCampaign, 'transportInformation.timeToLive', 24),
    hasCollapseKey: !!get(rawCampaign, 'transportInformation.gcmCollapseKey', false),
    collapseKey: get(rawCampaign, 'transportInformation.gcmCollapseKey', false)
      ? get(rawCampaign, 'transportInformation.gcmCollapseKey', 'default')
      : 'default',
  })

  let push = Immutable.Map()
  if (Array.isArray(rawCampaign.messages)) {
    rawCampaign.messages.forEach(tr => {
      if (translations.indexOf(tr.language.id) === -1) {
        translations.push(tr.language.id)
      }
      if (!push.has(tr.language.id)) {
        push = push.set(tr.language.id, AbTestedPushFactory())
      }
      const pushContent = PushContentLegacyFactory({
        message: StringValueFactory({ value: tr.content || '' }),
        title: StringValueFactory({ value: tr.title || '' }),
        deeplink: tr.deeplink ? tr.deeplink : rawCampaign.deeplink ? rawCampaign.deeplink : '',
        mediaUrl: tr.audioUrl ? tr.audioUrl : tr.videoUrl ? tr.videoUrl : tr.pictureUrl,
        mediaKind: tr.audioUrl ? 'audio' : tr.videoUrl ? 'video' : 'image',
      })
      const variantId = tr.variantId === 2 ? 'b' : 'a'
      push = push.set(tr.language.id, push.get(tr.language.id).set(variantId, pushContent))
      pushSettings = pushSettings.set('iconUrl', tr.iconUrl || '')
    })
  }
  return {
    content: ContentStateFactory({
      campaignName: rawCampaign.name,
      push,
      pushSettings,
      inapp,
    }),
    translations,
  }
}

export default {
  archiveCampaign: ({ appId, id, token }) => {
    return request.get(CONFIG.urls.push.archive.replace('{appId}', appId).replace('{id}', id)).then(
      response => {
        return { state: response.body.state, token }
      },
      error => {
        throw { token, err: error.res.text }
      }
    )
  },
  saveCampaignForProject: ({ campaignAPI, companyId }) => {
    return request.post(`/api/company/${companyId}/campaigns`, { campaign: campaignAPI }).then(
      response => {
        return {
          apiCampaign: campaignAPI,
          tokens: response.body,
        }
      },
      error => {
        throw get(
          error,
          'body.status',
          get(error, 'body.errors[0].message', 'An unexpected error occurs. Contact us.')
        )
      }
    )
  },
  saveCampaign: ({ appId, campaign, token, kind, sendType }) => {
    return request
      .post(`/api/app/${appId}/${kind === 'push' ? 'push' : 'local'}-campaign/persist`, {
        campaign,
        token,
      })
      .then(
        response => ({ ...response.body, live: campaign.live, sendType }),
        error => {
          throw get(
            error,
            'body.status',
            get(error, 'body.errors[0].message', 'An unexpected error occurs. Contact us.')
          )
        }
      )
  },

  play: ({ appId, id, token }) =>
    request.post(CONFIG.urls.push.play.replace('{appId}', appId).replace('{id}', id)).then(
      response => {
        return { state: response.body.state, token }
      },
      error => {
        throw {
          token,
          err: get(error, 'body.errors[0].message', 'An unexpected error occurs. Contact us.'),
        }
      }
    ),

  pause: ({ appId, id, token }) =>
    request.post(CONFIG.urls.push.pause.replace('{appId}', appId).replace('{id}', id)).then(
      response => {
        return { state: response.body.state, token }
      },
      error => {
        throw {
          token,
          err: get(error, 'body.errors[0].message', 'An unexpected error occurs. Contact us.'),
        }
      }
    ),

  fetchCampaign: ({ appId, token }) => {
    return request.get(`/api/app/${appId}/push-campaign/${token}`).then(
      response => response.body,
      error => {
        throw get(error.body, 'error', 'Unknown error')
      }
    )
  },
  findCampaigns: ({ appId, query, kind }) => {
    return request
      .get(`/api/app/${appId}/push-campaign/search/${kind}?q=${encodeURIComponent(query)}`)
      .then(
        response => response.body,
        error => {
          throw get(error.body, 'error', 'Unknown error')
        }
      )
  },

  rawCampaignToRecord: (raw, labels) => {
    const { content, translations } = extractFormData(raw)
    const query = JSON.parse(raw.query)
    let abtesting = CampaignABFactory({
      enabled: raw.experimentType === 'ABTesting' || raw.landingExperimentType === 'ABTesting',
      activeVariants: Immutable.Set(),
    })

    if (abtesting.enabled) {
      let exp
      if (raw.experimentType === 'ABTesting') {
        exp = raw.experiments.find(e => e.id === raw.experimentId)
      }
      if (raw.landingExperimentType === 'ABTesting') {
        exp = raw.currentLandingExperiment
      }

      if (typeof exp !== 'undefined' && Array.isArray(exp.variants)) {
        exp.variants.forEach(variant => {
          if (variant.activated) {
            abtesting = abtesting.set(
              'activeVariants',
              abtesting.activeVariants.add(variant.id === 2 ? 'b' : 'a')
            )
          }
        })
      }
    } else {
      abtesting = abtesting.set('activeVariants', Immutable.Set(['a']))
    }
    let audiences = Immutable.Set()
    if (Array.isArray(raw.customAudiences) && raw.customAudiences.length > 0) {
      raw.customAudiences.forEach(audience => {
        audiences = audiences.add(
          CustomAudienceFactory({
            name: audience.name,
            description: audience.description,
            type: matchAudienceType(audience.type),
            deleted: Boolean(audience.deleted),
          })
        )
      })
    }
    let pickedLabels = Immutable.OrderedSet()
    if (Array.isArray(raw.categories)) {
      raw.categories.forEach(cat => {
        const label = labels.find(l => l.id === cat.id)
        if (label) {
          pickedLabels = pickedLabels.add(label)
        }
      })
    }
    const start = raw.startDate ? dayjs.utc(raw.startDate, 'YYYY/MM/DD HH:mm') : null
    const end = raw.endDate ? dayjs.utc(raw.endDate, 'YYYY/MM/DD HH:mm') : null
    const campaignType = raw.type === 'PUSH' || raw.type === 'TRIGGER' ? 'push' : 'in-app'

    let record = new CampaignFactory({
      appId: get(raw, 'appId', 0),
      inAppPriority: (raw.inappPriority || 'standard').toLowerCase(),
      id: raw.id,
      _id: raw.id,
      template: raw.template,
      inappJustInTime: Boolean(raw?.inappJustInTime),
      devOnly: raw.devOnly,
      customAudiences: audiences,
      api: raw.source === 'API',
      archived: get(raw, 'archived', false),
      state: raw.state,
      triggerConfig:
        raw.type !== 'TRIGGER' || !raw.triggerConfig
          ? TriggerConfigFactory()
          : parseTriggerConfig({
              config: raw.triggerConfig,
              start,
              end,
              capping: typeof raw.capping === 'number' ? raw.capping : 0,
            }),
      pickedLabels,
      capping: raw.capping && raw.capping > 0 ? raw.capping : 0,
      name: raw.name,
      defaultMessage: raw.defaultMessage,
      defaultTitle: raw.defaultTitle,
      type: campaignType,
      token: raw.token,
      value: raw.token,
      repeatFrequency: raw.repeatFrequency,
      repeatUnit: raw.repeatUnit,
      sendType:
        raw.type === 'TRIGGER'
          ? 'trigger'
          : raw.repeatFrequency !== null
            ? 'recurring'
            : raw.state === 'DRAFT' && start && start.isBefore(dayjs.utc())
              ? 'now'
              : 'scheduled',
      gracePeriod: !raw.minDisplayIntervalValue
        ? null
        : raw.minDisplayIntervalValue *
          ((raw.minDisplayIntervalUnit ?? 'HOURS') === 'HOURS' ? 1 : 24),
      tzAware: raw.timezoneDependant,
      start: raw.type !== 'TRIGGER' ? start : null,
      end: raw.type !== 'TRIGGER' ? end : null,
      translations: Immutable.OrderedSet(translations),
      hasLanding: !!(Array.isArray(raw.landings) && raw.landings.length),
      data: content,
      variantsThemeCodes:
        Array.isArray(raw.landings) && raw.landings.length
          ? abtesting.enabled && campaignType === 'in-app'
            ? AbTestedThemeCodeFactory({
                a: raw.landings.find(landing => landing.variantId === 1)?.theme.code,
                b: raw.landings.find(landing => landing.variantId === 2)?.theme.code,
              })
            : AbTestedThemeCodeFactory({
                a: raw.landings.find(landing => landing.variantId === 0)?.theme.code,
                b: null,
              })
          : AbTestedThemeCodeFactory(),
      abtesting,
      targeting: Immutable.Map({
        loading: false,
        awaitingParse: !!query,
        query,
        conditions: Immutable.Map(),
        tree: initialState.get('tree'),
        clusters: Array.isArray(raw.clusters)
          ? Immutable.Set(raw.clusters.map(c => c.code))
          : initialState.get('clusters'),
        regions: Array.isArray(raw.regions)
          ? Immutable.Set(raw.regions.map(r => r.id))
          : initialState.get('regions'),
        languages: Array.isArray(raw.languages)
          ? Immutable.Set(raw.languages.map(l => l.id))
          : initialState.get('languages'),
      }),
      schedulingType: getSchedulingType(raw),
    })

    if (raw.type === 'LOCAL') {
      record = record
        .set(
          'trigger',
          raw.inappTriggerType === 'NEXT_SESSION' || raw.inappTriggerType === 'NOW'
            ? raw.inappTriggerType.toLowerCase()
            : raw.inappTriggerEvent
        )
        .set('triggerLabel', raw.inappTriggerLabel)
    }

    return record
  },
}
