/* eslint-disable react/jsx-no-bind */
import Immutable, { type List, type Map, type Set, type OrderedSet } from 'immutable'
import * as React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'

import { Button, ButtonLink } from 'components/common/button'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { Form, InputWrapper, Checkbox, Select } from 'components/form'
import { type SelectProps } from 'components/form/fields/select/select'
import { schemes, colors } from 'components/styled/tokens'

import { dayjs, formatDate, parseDate } from 'com.batch.common/dayjs.custom'

import { featureDescription, hiddenFeatures } from './company-plan-helper'

import { Section, SectionTitle } from '../console.style'
import { type CompanyRecord } from 'com.batch.redux/_records'
import { plans, getPlanFromCode } from 'com.batch.redux/billing.api'
import { type BillingRecord } from 'com.batch.redux/billing.records'

const planList = plans
  .toIndexedSeq()
  .map(rec => ({
    value: rec.code,
    label: `${rec.name} (${rec.code})`,
    weight: rec.weight,
  }))
  .toList()

export const getStringFromOpt = (opt: any): string =>
  opt !== null && typeof opt === 'object' && typeof opt.value === 'string' ? opt.value : ''

type CompanyPlanProps = {
  company: CompanyRecord
  featureList: List<{
    code: FeatureCode
    description: string
  }>
  updatePlan: (arg1: { company: CompanyRecord; billing: BillingRecord }) => Promise<CompanyRecord>
  updateCompanyFeatures: (arg1: {
    company: CompanyRecord
    disabled: OrderedSet<string>
    additionals: OrderedSet<string>
  }) => Promise<CompanyRecord>
}

// ====================== Select Type
type planOption = {
  value: string
  label: string
  weight: number
  isDisabled: boolean
}
const PlanSelect: (props: SelectProps<planOption>) => React.ReactElement = Select

// ====================== CompanyPlan COMPONENT
export const CompanyPlan = ({
  company,
  featureList,
  updateCompanyFeatures,
  updatePlan,
}: CompanyPlanProps): React.ReactElement => {
  // ====================== Component states
  const [loading, setLoading] = React.useState<Set<'plan' | 'feature'>>(Immutable.Set())
  const [additionalFeatures, setAdditionalFeatures] = React.useState(company.additionalFeaturesCode)
  const [disabledFeatures, setDisabledFeatures] = React.useState(company.disabledFeaturesCode)
  const [billing, setBilling] = React.useState<BillingRecord>(company.billing)
  const [errors, setErrors] = React.useState<Map<string, string>>(Immutable.Map())

  // ====================== Component constants
  const planIsStripable = billing.plan.code !== 'enterprise' && billing.plan.code !== 'premier'
  const handledByStripe =
    planIsStripable &&
    billing.subscriptionStatus !== 'none' &&
    billing.subscriptionStatus !== 'canceled'
  const inputTrail = React.useRef<DayPickerInput>(null)
  const dateIsValid = !!billing.trialUntil && billing.trialUntil.isValid()
  const dateIsEmpty = !inputTrail.current || inputTrail.current.getInput().value.length === 0
  const getFeaturesDesc = (feature: string) => {
    const found = featureList.find(f => f.code === feature)
    return found ? found.description : feature
  }

  const planOption = planList.map(rec => ({
    ...rec,
    isDisabled: rec.weight > 0 && !company.sfid,
  }))

  const trialOption = planList.map(rec => ({
    ...rec,
    isDisabled: rec.weight <= billing.plan.weight,
  }))

  // ====================== Render
  return (
    <Grid template="1fr 1fr" alignItems="stretch" margin={[12, 0, 0, 0]}>
      <Section style={{ borderTop: '1px solid #e9e9e9' }}>
        <SectionTitle>Plan</SectionTitle>

        <Form
          onSubmit={() => {
            const err: [string, string][] = []
            const planHasChanged = billing.plan !== company.billing.plan

            if (planHasChanged && !company.sfid && billing.plan.weight > 0)
              err.push([
                'plan',
                'You need to have a Salesforce ID if you want to set a other plan than free one.',
              ])

            if (!!billing.trial && billing.trial.weight < billing.plan.weight)
              err.push(['trial', 'Your trial plan have to be higher than your plan.'])

            if (err.length === 0) {
              setErrors(Immutable.Map())
              setLoading(loading.add('plan'))
              updatePlan({ company, billing }).then(
                () => {
                  setLoading(loading.remove('plan'))
                },
                resp => {
                  alert(
                    'Error while saving / syncing with backend : ”' +
                      resp.error +
                      '”. Please try saving again / check console log / contact @front'
                  )
                  setLoading(loading.remove('plan'))
                }
              )
            } else {
              setErrors(Immutable.Map(err))
            }
          }}
        >
          <InputWrapper label="Plan" htmlFor="plan" feedback={errors.get('plan')}>
            <PlanSelect
              id="plan"
              optionToString={opt => opt?.label ?? ''}
              isDisabled={handledByStripe}
              invalid={errors.has('plan')}
              value={planOption.find(opt => opt.value === billing.plan.code)}
              options={planOption}
              onChange={opt => {
                setBilling(billing.set('plan', getPlanFromCode(getStringFromOpt(opt))))
              }}
            />
          </InputWrapper>

          <InputWrapper label="Trial plan" htmlFor="trial" feedback={errors.get('trial')}>
            <PlanSelect
              id="trial"
              optionToString={opt => opt?.label ?? ''}
              isClearable={true}
              invalid={errors.has('trial')}
              value={
                billing.trial === null
                  ? null
                  : trialOption.find(
                      opt => Boolean(billing.trial) && opt.value === billing.trial?.code
                    )
              }
              options={trialOption}
              onChange={opt => {
                setBilling(
                  billing.set('trial', opt === null ? null : getPlanFromCode(getStringFromOpt(opt)))
                )
              }}
            />
          </InputWrapper>

          <InputWrapper
            label="Trial expiry date"
            htmlFor="trialDate"
            feedback={!dateIsValid && !dateIsEmpty && 'Invalid date'}
          >
            <DayPickerInput
              ref={inputTrail}
              // locale="en"
              onDayChange={day => {
                const date = dayjs.utc(day)
                const dayIsValid = date.isValid()
                return setBilling(billing.set('trialUntil', dayIsValid ? date : null))
              }}
              inputProps={{
                style: {
                  border: `1px solid ${
                    !dateIsValid && !dateIsEmpty ? colors.textDanger : colors.stroke
                  }`,
                },
                className: 'form-control',
                id: 'trialDate',
                placeholder: `E.g. ${dayjs().locale('en').format('DD/MM/YYYY')}`,
              }}
              format={'DD/MM/YYYY'}
              formatDate={formatDate}
              parseDate={parseDate}
              value={billing.trialUntil ? billing.trialUntil.format('DD/MM/YYYY') : undefined}
            />
          </InputWrapper>
          <InputWrapper label="Lock account">
            <Checkbox
              label="This is mean, but sometimes mean is what is needed to get paid"
              checked={billing.overcharge}
              onChange={() => setBilling(billing.set('overcharge', !billing.overcharge))}
            />
          </InputWrapper>
          <Grid template="1fr 1fr 1fr" style={{ marginTop: 10 }}>
            {company.billing.subscriptionId ? (
              <ButtonLink
                kind="primary"
                addOn="suffix"
                style={{ width: '100%' }}
                intent="action"
                href={`https://dashboard.stripe.com/subscriptions/${company.billing.subscriptionId}`}
              >
                View on Stripe
                <Icon icon="money" />
              </ButtonLink>
            ) : (
              <span />
            )}
            <ButtonLink
              addOn="suffix"
              style={{ width: '100%' }}
              kind="secondary"
              href={`/${company.id}/billing`}
            >
              Plan page
              <Icon icon="view" />
            </ButtonLink>
            <Button
              style={{ width: '100%' }}
              disabled={
                company.billing.plan === billing.plan &&
                company.billing.trial === billing.trial &&
                (company.billing.trialUntil
                  ? company.billing.trialUntil.format('YYYY-MM-DD')
                  : null) ===
                  (billing.trialUntil ? billing.trialUntil.format('YYYY-MM-DD') : null) &&
                company.billing.overcharge === billing.overcharge
              }
              isLoading={loading.includes('plan')}
              intent={billing.overcharge ? 'danger' : 'action'}
              kind="primary"
            >
              save changes
            </Button>
          </Grid>
        </Form>
      </Section>

      <Section style={{ backgroundColor: schemes.grayscale['10'], padding: 40 }}>
        <SectionTitle>Features</SectionTitle>
        <div style={{ marginBottom: 10 }}>PLAN</div>
        <Grid template="1fr 1fr">
          {company.planFeaturesCode
            .sortBy(feature => getFeaturesDesc(feature))
            .map((feature, index) => {
              const label = getFeaturesDesc(feature)

              return (
                <Tooltip
                  key={index}
                  minWidth={280}
                  tooltip={
                    <div style={{ textAlign: 'left', lineHeight: '21px' }}>
                      <div style={{ fontSize: 14 }}>
                        {featureDescription[feature] ? featureDescription[feature] : feature}
                      </div>
                      <div style={{ textAlign: 'right', marginTop: 7 }}>
                        <code>{feature}</code>
                      </div>
                    </div>
                  }
                  placement="left"
                >
                  <span style={{ display: hiddenFeatures.includes(feature) ? 'none' : 'block' }}>
                    <Checkbox
                      key={feature}
                      label={label}
                      checked={!disabledFeatures.includes(feature)}
                      onChange={() =>
                        setDisabledFeatures(
                          disabledFeatures.includes(feature)
                            ? disabledFeatures.remove(feature)
                            : disabledFeatures.add(feature)
                        )
                      }
                      style={{ marginBottom: 5 }}
                    />
                  </span>
                </Tooltip>
              )
            })}
        </Grid>

        <div style={{ marginBottom: 10, marginTop: 20 }}>ADDITIONAL</div>
        <Grid template="1fr 1fr">
          {!!featureList &&
            featureList
              .sortBy(feature => feature.description)
              .filter(feature => !company.planFeaturesCode.has(feature.code))
              .map((feature, index) => (
                <Tooltip
                  key={index}
                  minWidth={280}
                  tooltip={
                    <div style={{ textAlign: 'left', lineHeight: '21px' }}>
                      <div style={{ fontSize: 14 }}>
                        {featureDescription[feature.code]
                          ? featureDescription[feature.code]
                          : feature.code}
                      </div>
                      <div style={{ textAlign: 'right', marginTop: 7 }}>
                        <code>{feature.code}</code>
                      </div>
                    </div>
                  }
                  placement="left"
                >
                  <span
                    style={{ display: hiddenFeatures.includes(feature.code) ? 'none' : 'block' }}
                  >
                    <Checkbox
                      key={feature.code}
                      label={feature.description}
                      checked={additionalFeatures.includes(feature.code)}
                      onChange={() =>
                        setAdditionalFeatures(
                          additionalFeatures.includes(feature.code)
                            ? additionalFeatures.remove(feature.code)
                            : additionalFeatures.add(feature.code)
                        )
                      }
                      style={{ marginBottom: 5 }}
                    />
                  </span>
                </Tooltip>
              ))}
        </Grid>
        <div style={{ marginTop: 20, textAlign: 'right' }}>
          <Button
            disabled={
              additionalFeatures.subtract(company.additionalFeaturesCode).size +
                company.additionalFeaturesCode.subtract(additionalFeatures).size +
                disabledFeatures.subtract(company.disabledFeaturesCode).size +
                company.disabledFeaturesCode.subtract(disabledFeatures).size ===
              0
            }
            isLoading={loading.includes('feature')}
            onClick={() => {
              setLoading(loading.add('feature'))
              updateCompanyFeatures({
                company,
                additionals: additionalFeatures,
                disabled: disabledFeatures,
              }).then(
                () => {
                  setLoading(loading.remove('feature'))
                },
                resp => {
                  alert(
                    'Error while saving / syncing with backend : ”' +
                      resp.error +
                      '”. Please try saving again / check console log / contact @front'
                  )
                  setLoading(loading.remove('feature'))
                }
              )
            }}
            intent="action"
            kind="primary"
          >
            Save changes
          </Button>
        </div>
      </Section>
    </Grid>
  )
}
