import Immutable, { type List, type RecordOf } from 'immutable'

import { type VariantId } from 'com.batch/message/models/message.records'

type VariantDataProps = {
  value: number
  rate: number
  winner: boolean
}
export type VariantDataRecord = RecordOf<VariantDataProps>
export const VariantDataFactory = Immutable.Record<VariantDataProps>({
  value: 0,
  rate: 0,
  winner: false,
})

export type VariantStatsProps = {
  delivered: VariantDataRecord
  open: VariantDataRecord
  click: VariantDataRecord
  bounce: VariantDataRecord
  unsubscribe: VariantDataRecord
}
export const VariantStatsFactory = Immutable.Record<VariantStatsProps>({
  delivered: VariantDataFactory(),
  open: VariantDataFactory(),
  click: VariantDataFactory(),
  bounce: VariantDataFactory(),
  unsubscribe: VariantDataFactory(),
})
export type VariantStatsRecord = RecordOf<VariantStatsProps>

export type OrchestrationVariantProps = {
  variantId: VariantId
  stats: VariantStatsRecord
}
export const OrchestrationVariantFactory = Immutable.Record<OrchestrationVariantProps>({
  variantId: 1,
  stats: VariantStatsFactory(),
})
export type OrchestrationVariantRecord = RecordOf<OrchestrationVariantProps>

export type OrchestrationStatsByVariantRecord = List<OrchestrationVariantRecord>
