import { type DispatchExtraBoundFn } from 'com.batch.redux/_records'
import { currentProjectSelector } from 'com.batch.redux/project.selector'

export type SeekDynamicContentProfileIdSuccessAction = {
  type: 'SEEK_DYNAMIC_CONTENT_PROFILE_ID_SUCCESS'
  payload: {
    kind: string
    value: string
    profileId: string
  }
}

export const updatePreviewProfile = (
  kind: 'user_id',
  value: string
): DispatchExtraBoundFn<Promise<'FOUND' | 'NOT_FOUND' | 'ERROR'>> => {
  return async (dispatch, getState, { ucpGateway }) => {
    const project = currentProjectSelector(getState())

    if (value === '') {
      dispatch({
        type: 'SEEK_DYNAMIC_CONTENT_PROFILE_ID_SUCCESS',
        payload: {
          kind: 'user_id',
          value: '',
          profileId: '',
        },
      } as SeekDynamicContentProfileIdSuccessAction)
      return 'FOUND'
    }
    try {
      const profileId = await ucpGateway.lookUpProfileId({
        project,
        value,
      })

      if (!profileId) {
        dispatch({
          type: 'SEEK_DYNAMIC_CONTENT_PROFILE_ID_FAILURE',
          payload: null,
        })
        return 'NOT_FOUND'
      } else {
        dispatch({
          type: 'SEEK_DYNAMIC_CONTENT_PROFILE_ID_SUCCESS',
          payload: {
            kind: 'user_id',
            value: value,
            profileId: profileId,
          },
        } as SeekDynamicContentProfileIdSuccessAction)
        return 'FOUND'
      }
    } catch (error: any) {
      console.log(error)
      dispatch({
        type: 'SEEK_DYNAMIC_CONTENT_PROFILE_ID_FAILURE',
        payload: null,
      })
      return 'ERROR'
    }
  }
}

// LookUpProfileIdAction | LookUpProfileIdSuccessAction | LookUpProfileIdFailureAction
