import styled from 'styled-components'

import { colors, schemes, shadows } from 'components/styled/tokens'

type ChannelLabelContainerProps = {
  checked: boolean
  isDisabled: boolean
  channel?: string
  channelColor: [string, string]
}
export const ChannelLabelContainer = styled.label<ChannelLabelContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  margin: 6px;
  width: 245px;
  padding: 12px;
  color: ${p =>
    p.checked
      ? colors.textContrast
      : p.channel === 'Email'
        ? colors.textNeutral
        : colors.textLight};
  background-color: ${p =>
    p.checked ? p.channelColor[0] : p.isDisabled ? colors.fillDisabled : schemes.grayscale['01']};
  box-shadow: ${p =>
    p.checked ? `0 0 0 1px ${p.channelColor[0]}` : `0 0 0 1px ${colors.stroke}, ${shadows.raised}`};
  border-radius: 6px;
  cursor: ${p => (p.isDisabled ? 'cursor' : 'pointer')};
  .styled-option-symbol {
    background: ${p => (p.checked ? `${colors.opacifyContrastActive} !important` : null)};
    border: ${p => (p.checked ? 'none !important' : null)};
    box-shadow: ${p => (p.checked ? `0 0 0 1px ${schemes.darklucent['50']}` : null)};
  }
  p {
    color: ${p => !p.checked && colors.textNeutral};
    margin-left: 4px;
    font-weight: 500;
  }
  &:hover {
    background-color: ${p =>
      p.checked ? p.channelColor[1] : p.isDisabled ? colors.fillDisabled : schemes.grayscale['05']};
  }
  &:focus {
    color: white;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ChannelText = styled.span`
  display: flex;
  padding: 18px 0 2px 0;
  font-size: 1em;
  font-weight: 500;
  letter-spacing: -0.01em;
  user-select: none;
`
