import * as React from 'react'

import { useBlurOnClickCallback } from 'components/_hooks'

import { Button, SwitchButtonContainer, ToggleSwitch } from './button.styles'

type SwitchButtonProps = React.ComponentProps<typeof Button> & {
  addOn?: 'suffix' | 'prefix'
  children?: React.ReactNode
  isActive?: boolean
  onChange?: ((arg1: boolean) => void) | ((arg1: boolean) => Promise<void>)
  disabled?: boolean
}

export const SwitchButton = ({
  addOn = 'prefix',
  children,
  isActive = false,
  onChange,
  ...props
}: SwitchButtonProps): React.ReactElement => {
  const invertBoolean = useBlurOnClickCallback(
    () => {
      if (typeof onChange === 'function') onChange(!isActive)
    },
    [isActive, onChange],
    true
  )
  return (
    <Button
      {...props}
      kind="inline"
      type="button"
      isSwitchActive={isActive}
      intent="neutral"
      onClick={invertBoolean}
    >
      {addOn === 'prefix' && (
        <SwitchButtonContainer isActive={isActive} style={{ marginRight: 8 }}>
          <ToggleSwitch />
        </SwitchButtonContainer>
      )}
      {children}
      {addOn === 'suffix' && (
        <SwitchButtonContainer isActive={isActive} style={{ marginLeft: 8 }}>
          <ToggleSwitch />
        </SwitchButtonContainer>
      )}
    </Button>
  )
}
