/* eslint-disable react/jsx-no-bind */
import Immutable from 'immutable'
import * as React from 'react'

import { filters } from 'components/campaign/campaign-list-utils'
import { Filter } from 'components/campaign/filters/filter'
import { FilterSelectMulti } from 'components/filter'
import { Checkbox } from 'components/form'

import {
  type CampaignActiveFiltersRecord,
  type CampaignFilterRecord,
} from 'com.batch.redux/_records'

type ChannelFilterProps = {
  localActiveFilters: CampaignActiveFiltersRecord
  onChange: (arg1?: any) => void
  isEmbedded: boolean
}
const optToString = opt => opt?.label ?? ''
export const ChannelFilter = ({
  localActiveFilters,
  onChange,
  isEmbedded,
}: ChannelFilterProps): React.ReactElement => {
  const channels = React.useMemo(() => {
    return filters.filter(f => f.category === 'channels')
  }, [])
  const channelsList = React.useMemo(() => channels.toList(), [channels])
  const selectedChannels = React.useMemo(() => {
    let selected = Immutable.List<CampaignFilterRecord>()
    channels.map(st => {
      if (localActiveFilters.commons.has(st)) {
        selected = selected.push(st)
      }
    })
    return selected
  }, [localActiveFilters, channels])

  return (
    <Filter title="Channels" isEmbedded={isEmbedded}>
      {isEmbedded ? (
        channels.map(channel => (
          <Checkbox
            key={channel.name}
            name={channel.name}
            label={channel.label}
            checked={localActiveFilters.commons.has(channel)}
            onChange={() => onChange(channel)}
            className={`channel_${channel.name}`}
          />
        ))
      ) : (
        <FilterSelectMulti
          options={channelsList}
          value={selectedChannels}
          onChange={onChange}
          isSearchable={false}
          placeholder="Any channel"
          optionToString={optToString}
          term={count =>
            count === 1 ? selectedChannels.first()?.label ?? '' : `${count} channels`
          }
        />
      )}
    </Filter>
  )
}
