import * as React from 'react'

type Props = {
  channel: 'sms' | 'email' | 'push'
}

export const EventIllustration = ({ channel }: Props): React.ReactElement => {
  if (channel === 'email')
    return (
      <img
        alt="Email"
        src="/medias/img/illustrations/profile/message_email.svg"
        width={166}
        height={92}
      />
    )
  if (channel === 'sms')
    return (
      <img
        alt="SMS"
        src="/medias/img/illustrations/profile/message_sms.svg"
        width={166}
        height={92}
      />
    )
  return (
    <img
      alt="Push notification"
      src="/medias/img/illustrations/profile/message_push.svg"
      width={166}
      height={92}
    />
  )
}
