import { type Dayjs } from 'dayjs'
import * as React from 'react'
import { useTheme } from 'styled-components'

import { useClickOutsideOnExistingRefs, usePopper, useToggle } from 'components/_hooks'
import { Grid } from 'components/common/grid'
import { Icon } from 'components/common/svg-icon'

import { dayjs } from 'com.batch.common/dayjs.custom'
import { numberOnly } from 'com.batch.common/preventKeyPress'

import { InputContainer } from './../../styles'
import { DatePickerPopover } from './date-picker-popover'
import { DayPickerContainer } from './date-picker.styles'

import { type DateInputRecord, DateInputFactory } from 'com.batch.redux/query/query.records'

const DATE_FMT = 'DD/MM/YYYY'

type DateTimePickerProps = {
  date: Dayjs | null | undefined
  invalid?: boolean
  position?: 'left' | 'right'
  onFocus?: () => void
  onChange: (date?: Dayjs | null | undefined) => any
  disabledDays?: (day: Date) => boolean
}

// ====================== DATE PICKER COMPONENTS
export const DateTimePicker = ({
  disabledDays,
  invalid,
  position,
  onChange,
  onFocus,
  date,
  ...rest
}: DateTimePickerProps): React.ReactElement => {
  // ====================== Component state
  const datePickerState = useToggle(false)

  const [focused, setFocused] = React.useState(false)
  const [dateInput, setDateInput] = React.useState<DateInputRecord>(
    DateInputFactory({
      value: date,
      inputValue: date?.format(DATE_FMT) ?? '',
      hourValue: date?.format('HH') ?? '',
      minuteValue: date?.format('mm') ?? '',
      valid: Boolean(date?.isValid()),
    })
  )
  const [typedHours, setTypedHours] = React.useState(dateInput.hourValue)
  const [typedMinutes, setTypedMinutes] = React.useState(dateInput.minuteValue)

  const theme = useTheme()
  // ====================== Component constants
  const datePickerProps: {
    disabledDays?: (arg1: Date) => boolean
  } = React.useMemo(() => {
    return disabledDays ? { disabledDays: (day: Date) => disabledDays(day) } : {}
  }, [disabledDays])
  const invalidAndNotFocused = React.useMemo(() => invalid && !focused, [invalid, focused])

  // ====================== Component Ref

  const containerRef = React.useRef(null)
  const [inputRef, popperRef] = usePopper({
    modifiers: [
      { name: 'applyStyles' },
      { name: 'eventListeners' },
      {
        name: 'offset',
        options: {
          offset: [28, 4],
        },
      },
    ],
    strategy: 'absolute',
    placement: 'bottom',
  })
  const hoursRef = React.useRef<HTMLInputElement>(null)
  const minutesRef = React.useRef<HTMLInputElement>(null)
  const clickOutsideRefs = React.useMemo(() => [containerRef, popperRef], [popperRef])
  const closeAndFocusHour = React.useCallback(() => {
    datePickerState.close()
    hoursRef.current?.focus()
  }, [datePickerState, hoursRef])
  useClickOutsideOnExistingRefs(closeAndFocusHour, clickOutsideRefs)
  // ====================== Callbacks
  const propagateWhenChange = React.useCallback(
    (di: DateInputRecord) => {
      if (di.valid && di.hourValue !== '' && di.minuteValue !== '') {
        const targetDate = di.value?.hour(parseInt(di.hourValue))?.minute(parseInt(di.minuteValue))
        if ((targetDate?.unix() ?? 0) !== (date?.unix() ?? 0)) onChange(targetDate)
      } else {
        if (date) {
          onChange(null)
        }
      }
    },
    [onChange, date]
  )

  const onDateInputChange = React.useCallback(
    e => {
      const dateIsValid = dayjs(e.target.value, DATE_FMT, 'fr', true).isValid()
      const dateToPass = dateIsValid ? dayjs.utc(e.target.value, DATE_FMT, true) : null
      const di = dateInput
        .set('inputValue', e.target.value)
        .set('valid', dateIsValid)
        .set('value', dateToPass)
      setDateInput(di)
      propagateWhenChange(di)
    },
    [dateInput, propagateWhenChange]
  )

  const onHoursChange = React.useCallback(evt => {
    setTypedHours(evt.target.value.slice(-2))
  }, [])

  const onHoursBlur = React.useCallback(() => {
    const hours = Number(typedHours)
    if (typedHours !== '' && hours >= 0 && hours < 24) {
      const di = dateInput
        .set('hourValue', hours.toString())
        .set('valid', Boolean(dateInput.value?.isValid()) && dateInput.minuteValue !== '')
      setTypedHours(hours.toString().padStart(2, '0'))
      setDateInput(di)
      propagateWhenChange(di)
    } else {
      const di = dateInput.set('hourValue', '').set('valid', false)
      setDateInput(di)
      setTypedHours('')
      propagateWhenChange(di)
    }
    setFocused(false)
  }, [dateInput, propagateWhenChange, typedHours])

  const onMinutesChange = React.useCallback(evt => {
    setTypedMinutes(evt.target.value.slice(-2))
  }, [])

  const onMinutesBlur = React.useCallback(() => {
    const minutes = Number(typedMinutes)
    if (typedMinutes !== '' && minutes >= 0 && minutes < 60) {
      const di = dateInput
        .set('minuteValue', minutes.toString().padStart(2, '0'))
        .set('valid', Boolean(dateInput.value?.isValid()) && dateInput.hourValue !== '')
      setTypedMinutes(minutes.toString().padStart(2, '0'))
      setDateInput(di)
      propagateWhenChange(di)
    } else {
      const di = dateInput.set('minuteValue', '').set('valid', false)
      setDateInput(di)
      setTypedMinutes('')
      propagateWhenChange(di)
    }
    setFocused(false)
    datePickerState.close()
  }, [typedMinutes, datePickerState, dateInput, propagateWhenChange])

  const setDay = React.useCallback(
    (day: Dayjs) => {
      const di = dateInput
        .set('inputValue', day.format(DATE_FMT))
        .set('value', day)
        .set('valid', true)

      setDateInput(di)
      propagateWhenChange(di)
      datePickerState.close()
      hoursRef.current?.focus()
    },
    [dateInput, propagateWhenChange, datePickerState, hoursRef]
  )

  const onDayInputBlur = React.useCallback(() => {
    if (!datePickerState.value) {
      setFocused(false)
      hoursRef.current?.focus()
    }
  }, [datePickerState.value, hoursRef])

  const createOnFocus = React.useCallback(
    (openCalendar: boolean) => () => {
      if (openCalendar) {
        datePickerState.open()
      }
      if (!focused) {
        setFocused(true)
        if (onFocus) onFocus()
      }
    },
    [datePickerState, onFocus, focused]
  )

  React.useEffect(() => {
    if (dateInput.value && (!dateInput.hourValue || !dateInput.minuteValue)) {
      const hours = dayjs().hour() + 1
      const minutes = dateInput.minuteValue && dateInput.hourValue ? dayjs().minute() : 15

      const di = dateInput
        .set('hourValue', hours.toString())
        .set('minuteValue', minutes.toString())
        .set('valid', Boolean(dateInput.value?.isValid()))
      setTypedHours(hours.toString().padStart(2, '0'))
      setTypedMinutes(minutes.toString().padStart(2, '0'))
      setDateInput(di)
      propagateWhenChange(di)
      hoursRef.current?.blur()
      minutesRef.current?.blur()
    }
  }, [
    dateInput.value,
    dateInput.minuteValue,
    dateInput.hourValue,
    dateInput,
    focused,
    invalid,
    onMinutesBlur,
    propagateWhenChange,
    hoursRef,
    minutesRef,
  ])

  // ====================== Render

  return (
    <Grid template="1fr 120px" gap={12}>
      <DayPickerContainer {...rest} ref={containerRef} position={position ?? 'left'}>
        <InputContainer invalid={invalidAndNotFocused}>
          <input
            ref={inputRef}
            disabled={theme?.disabledMode}
            data-testid="DayPicker_input"
            placeholder={DATE_FMT}
            value={dateInput.inputValue}
            onChange={onDateInputChange}
            onBlur={onDayInputBlur}
            onFocus={createOnFocus(true)}
          />
          <button
            className="styled-dp-dropdown-icon"
            data-testid="DayPicker_button"
            onClick={datePickerState.close}
          >
            <Icon icon="select" />
          </button>
        </InputContainer>

        {datePickerState.value && (
          <DatePickerPopover
            ref={popperRef}
            position={position}
            datePickerProps={datePickerProps}
            value={date}
            setDay={setDay}
          />
        )}
      </DayPickerContainer>
      <InputContainer invalid={invalidAndNotFocused} disabled={theme?.disabledMode}>
        <input
          ref={hoursRef}
          disabled={theme?.disabledMode}
          value={typedHours}
          type="number"
          min={0}
          max={24}
          style={{ width: 59, textAlign: 'left' }}
          placeholder="hh"
          onKeyDown={numberOnly}
          onChange={onHoursChange}
          onFocus={createOnFocus(false)}
          onBlur={onHoursBlur}
        />
        <input
          disabled={theme?.disabledMode}
          value={typedMinutes}
          ref={minutesRef}
          type="number"
          min={0}
          max={60}
          style={{
            width: 60,
            borderLeft: '1px solid #E9E9E9',
            textAlign: 'left',
            height: 34,
            minHeight: 34,
            margin: '1px 0',
          }}
          placeholder="mm"
          onKeyDown={numberOnly}
          onBlur={onMinutesBlur}
          onFocus={createOnFocus(false)}
          onChange={onMinutesChange}
        />
      </InputContainer>
    </Grid>
  )
}
