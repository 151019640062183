import styled, { css } from 'styled-components'

import { DayPickerContainer } from 'components/form/fields/date-picker/date-picker.styles'
import { colors, shadows, schemes } from 'components/styled/tokens'

type FiltersContainerProps = {
  $isStuck: boolean
}
export const FiltersContainer = styled.div<FiltersContainerProps>`
  ${props =>
    props.$isStuck
      ? css`
          background: ${schemes.lightlucent['95']};
          padding: 8px 20px;
          margin: 0;
          box-shadow: ${shadows.block};
        `
      : css`
          padding: 8px 2px;
          margin: 0 18px;
        `}

  display: flex;
  gap: 5px;
  border-bottom: 1px solid ${colors.stroke};

  ${DayPickerContainer} {
    & input {
      flex: unset;
      width: 80px;
    }

    & .styled-input-addons {
      margin-right: 0;
    }
  }
`
