import styled from 'styled-components'

import * as colors from 'components/styled/tokens/colors'

export const NavigatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
`
type EllipseProps = {
  active: boolean
}
export const Ellipse = styled.div<EllipseProps>`
  background-color: ${props => (props.active ? colors.textContrast : colors.strokeDark)};
  width: 7px;
  height: 7px;
  border-radius: 50%;
`
