export const parseFilterPushTokens = (
  filterPushTokens?: 'PUSH_TOKEN_COLLECTED' | 'PUSH_TOKEN_ALL' | 'PUSH_TOKEN_IMPORTED'
): 'collected' | 'all' | 'imported' => {
  switch (filterPushTokens) {
    case 'PUSH_TOKEN_COLLECTED':
      return 'collected'
    case 'PUSH_TOKEN_IMPORTED':
      return 'imported'
    case 'PUSH_TOKEN_ALL':
      return 'all'
    default:
      return 'collected'
  }
}
