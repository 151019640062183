import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import {
  Box,
  BoxBody,
  HeaderBoxTitle,
  BoxSection,
  BoxFooter,
  BoxHeader,
} from 'components/common/box'
import { Button } from 'components/common/button'
import { Popin } from 'components/common/popin/popin'
import { InputWrapper } from 'components/form'
import { LightText } from 'components/styled/text'

import { CompanyPicker } from '../company/company-picker'
import { type AppRecord, type CompanyRecord } from 'com.batch.redux/_records'

type ChangeCompanyProps = {
  close: () => void
  opened: boolean
  app: AppRecord
  changeCompany: (arg1: { app: AppRecord; company: CompanyRecord }) => Promise<AppRecord>
}

export const ChangeCompany = ({
  app,
  opened,
  close,
  changeCompany,
}: ChangeCompanyProps): React.ReactElement => {
  const [targetCompany, setTargetCompany] = React.useState<CompanyRecord | null | undefined>(null)
  const [btnCompanyLoading, setBtnCompanyLoading] = React.useState(false)
  const onClick = React.useCallback(() => {
    if (targetCompany) {
      setBtnCompanyLoading(true)
      changeCompany({ app, company: targetCompany }).then(
        () => {
          setBtnCompanyLoading(false)
          close()
        },
        error => {
          setBtnCompanyLoading(false)
          console.warn(error)
          alert('there was an error, chech the console')
        }
      )
    }
  }, [app, changeCompany, close, targetCompany])
  return (
    <Popin opened={opened} close={close}>
      <Box style={{ width: 510 }}>
        <BoxHeader>
          <HeaderBoxTitle title="Change company" />
        </BoxHeader>
        <BoxBody>
          <BoxSection $padding>
            <ThemeProvider theme={{ kind: 'capture' }}>
              <InputWrapper label="Target company">
                <CompanyPicker
                  creatable
                  companyId={targetCompany ? targetCompany.id : null}
                  setCompany={setTargetCompany}
                />
              </InputWrapper>
            </ThemeProvider>
          </BoxSection>
          <BoxSection $padding>
            <p>
              This allows you to change the company of the app &laquo;<strong>{app.name}</strong>
              &raquo; [#{app.id}]. Please note that :
            </p>
            <ul style={{ padding: '10px 0 60px 30px' }}>
              <li>
                The <strong>REST API Key</strong> is linked to the company. If the customer uses any
                of our API, he will need to update its backend code with the target company REST API
                key.
              </li>
              <li style={{ paddingTop: 10 }}>
                <strong>Themes</strong> are also linked to the company, it the app already has
                in-app campaigns it shall not be moved. Probably.
              </li>
              <li style={{ paddingTop: 10 }}>
                If the target company is on a cheaper plan, the app could lose{' '}
                <strong>features</strong>.
              </li>
            </ul>
            <LightText>This whitespace is not a bug, it's a feature.</LightText>
          </BoxSection>
        </BoxBody>
        <BoxFooter>
          <Button
            onClick={onClick}
            kind="primary"
            intent="danger"
            isLoading={btnCompanyLoading}
            disabled={!targetCompany || targetCompany.id === app.companyId}
          >
            I know what I am doing, move this app
          </Button>
        </BoxFooter>
      </Box>
    </Popin>
  )
}
