import styled from 'styled-components'

import { schemes } from 'components/styled/tokens'

type TriggerBadgeProps = {
  kind: 'updated' | 'deprecated'
}
export const TriggerBadge = styled.div<TriggerBadgeProps>`
  border-radius: 3px;
  text-transform: uppercase;
  color: ${schemes.lightlucent['100']};
  font-size: 10px;
  margin-right: 21px;
  padding: 2px 3px;
  background: ${props =>
    props.kind === 'updated' ? schemes.purple['90'] : schemes.grayscale['40']};
`
