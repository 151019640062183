import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { EmptyField } from 'components/common/empty-states'
import { Icon } from 'components/common/svg-icon'
import { Tooltip } from 'components/common/tooltip'
import { colors } from 'components/styled/tokens'

import { kformat } from 'com.batch.common/utils'

import { EstimateContainer, TokenCellsSize } from './audience.styles'

type AudienceEstimateDataProps = {
  estimate: number | null | undefined | 'error'
  name: string
}

type AudienceEstimateProps = AudienceEstimateDataProps & {
  count: number
  index?: number
}

export const AudienceEstimateRaw = ({
  estimate,
  name,
  count,
  index,
}: AudienceEstimateProps): React.ReactElement => {
  return estimate === null ? (
    <ThemeProvider theme={{ isLoading: true }}>
      <EmptyField _width={index ? `${TokenCellsSize[index]}%` : '50%'} />
    </ThemeProvider>
  ) : (
    <EstimateContainer style={{ paddingRight: 5 }}>
      <AudienceEstimateData estimate={estimate} name={name} />
      <span>/ {kformat(count)}</span>
    </EstimateContainer>
  )
}

export const AudienceEstimateDataRaw = ({
  estimate,
  name,
}: AudienceEstimateDataProps): React.ReactElement => {
  switch (estimate) {
    case 'error':
      return (
        <Tooltip
          tooltip={
            <div style={{ textAlign: 'left', maxWidth: 141 }}>
              Unable to estimate push tokens reach for this audience
            </div>
          }
          key={name}
          placement="right"
        >
          <span style={{ paddingRight: 5 }}>
            <Icon icon="error" size={13} color={colors.textDanger} />
          </span>
        </Tooltip>
      )

    case null:
      return (
        <span style={{ paddingRight: 8 }}>
          <Icon icon="spinner" size={13} style={{ color: colors.textAction }} />
        </span>
      )

    default:
      return (
        <span style={{ paddingRight: 5 }}>
          {typeof estimate === 'number' ? kformat(estimate) : 0}
        </span>
      )
  }
}

// ====================== EXPORTS
export const AudienceEstimate: React.ComponentType<AudienceEstimateProps> =
  React.memo<AudienceEstimateProps>(AudienceEstimateRaw)

export const AudienceEstimateData: React.ComponentType<AudienceEstimateDataProps> =
  React.memo<AudienceEstimateDataProps>(AudienceEstimateDataRaw)
