import {
  CustomEventFactory,
  EventFactory,
  type EventRecord,
  MessagingEventFactory,
} from 'com.batch/profile/models/event.records'
import Immutable from 'immutable'

export const eventsSample = Immutable.OrderedMap<string, Immutable.List<EventRecord>>([
  [
    'toto',
    Immutable.List<EventRecord>([
      EventFactory({
        customEvent: CustomEventFactory({
          id: 'cevent1',
          name: 'cevent1',
          label: 'cevent1',
          sendDate: '2024-02-02T00:00:00Z',
        }),
      }),
      EventFactory({
        messagingEvent: MessagingEventFactory({
          eventID: 'mevent1',
          metric: 'SENT',
          eventDate: 3,
          channel: 'email',
        }),
      }),
      EventFactory({
        customEvent: CustomEventFactory({
          id: 'cevent2',
          name: 'cevent2',
          label: 'cevent2',
          sendDate: '2021-01-01T00:00:00Z',
        }),
      }),
      EventFactory({
        messagingEvent: MessagingEventFactory({
          eventID: 'mevent2',
          metric: 'DELIVERED',
          eventDate: 2,
          channel: 'sms',
        }),
      }),
      EventFactory({
        customEvent: CustomEventFactory({
          id: 'cevent3',
          name: 'cevent3',
          label: 'cevent3',
          sendDate: '2021-01-01T00:00:00Z',
        }),
      }),
      EventFactory({
        customEvent: CustomEventFactory({
          id: 'cevent4',
          name: 'cevent4',
          label: 'cevent4',
          sendDate: '2021-01-01T00:00:00Z',
        }),
      }),
      EventFactory({
        messagingEvent: MessagingEventFactory({
          eventID: 'mevent3',
          metric: 'SENT',
          eventDate: 3,
          channel: 'push',
        }),
      }),
    ]),
  ],
])
